import {performGQL} from "../functions";
import {
    deleteAdminMortgageStageNotifications,
    deleteAdminMortgageStageNotified,
    deleteUserApplicantActivity,
    deleteUserMortgageQuery
} from "../../../graphql/mutations";
import {useContext} from "react";
import {Mortgages} from "../../providers/MortgagesProvider";

export const deleteUserApplicant = /* GraphQL */ `
    mutation DeleteUserApplicant(
        $input: DeleteUserApplicantInput!
        $condition: ModelUserApplicantConditionInput
    ) {
        deleteUserApplicant(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _version
            _lastChangedAt
        }
    }
`;
export const deleteUserApplicantTax = /* GraphQL */ `
    mutation DeleteUserApplicantTax(
        $input: DeleteUserApplicantTaxInput!
        $condition: ModelUserApplicantTaxConditionInput
    ) {
        deleteUserApplicantTax(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserApplicantPersonal = /* GraphQL */ `
    mutation DeleteUserApplicantPersonal(
        $input: DeleteUserApplicantPersonalInput!
        $condition: ModelUserApplicantPersonalConditionInput
    ) {
        deleteUserApplicantPersonal(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserApplicantIncome = /* GraphQL */ `
    mutation DeleteUserApplicantIncome(
        $input: DeleteUserApplicantIncomeInput!
        $condition: ModelUserApplicantIncomeConditionInput
    ) {
        deleteUserApplicantIncome(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteUserApplicantFinancial = /* GraphQL */ `
    mutation DeleteUserApplicantFinancial(
        $input: DeleteUserApplicantFinancialInput!
        $condition: ModelUserApplicantFinancialConditionInput
    ) {
        deleteUserApplicantFinancial(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteUserApplicantEmployment = /* GraphQL */ `
    mutation DeleteUserApplicantEmployment(
        $input: DeleteUserApplicantEmploymentInput!
        $condition: ModelUserApplicantEmploymentConditionInput
    ) {
        deleteUserApplicantEmployment(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteUserApplicantAsset = /* GraphQL */ `
    mutation DeleteUserApplicantAsset(
        $input: DeleteUserApplicantAssetInput!
        $condition: ModelUserApplicantAssetConditionInput
    ) {
        deleteUserApplicantAsset(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteUserApplicantDebt = /* GraphQL */ `
    mutation DeleteUserApplicantDebt(
        $input: DeleteUserApplicantDebtInput!
        $condition: ModelUserApplicantDebtConditionInput
    ) {
        deleteUserApplicantDebt(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserApplicantProperty = /* GraphQL */ `
    mutation DeleteUserApplicantProperty(
        $input: DeleteUserApplicantPropertyInput!
        $condition: ModelUserApplicantPropertyConditionInput
    ) {
        deleteUserApplicantProperty(input: $input, condition: $condition) {
            id

            owner
        }
    }
`;
export const deleteUserApplicantAlternateEmailAddresses = /* GraphQL */ `
    mutation DeleteUserApplicantAlternateEmailAddresses(
        $input: DeleteUserApplicantAlternateEmailAddressesInput!
        $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
    ) {
        deleteUserApplicantAlternateEmailAddresses(
            input: $input
            condition: $condition
        ) {
            id

        }
    }
`;
export const deleteUserApplicantEmploymentIncome = /* GraphQL */ `
    mutation DeleteUserApplicantEmploymentIncome(
        $input: DeleteUserApplicantEmploymentIncomeInput!
        $condition: ModelUserApplicantEmploymentIncomeConditionInput
    ) {
        deleteUserApplicantEmploymentIncome(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const deleteUserApplicantCurrentAccount = /* GraphQL */ `
    mutation DeleteUserApplicantCurrentAccount(
        $input: DeleteUserApplicantCurrentAccountInput!
        $condition: ModelUserApplicantCurrentAccountConditionInput
    ) {
        deleteUserApplicantCurrentAccount(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const deleteUserApplicantLastHere = /* GraphQL */ `
    mutation DeleteUserApplicantLastHere(
        $input: DeleteUserApplicantLastHereInput!
        $condition: ModelUserApplicantLastHereConditionInput
    ) {
        deleteUserApplicantLastHere(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const deleteUserApplicantHealth = /* GraphQL */ `
    mutation DeleteUserApplicantHealth(
        $input: DeleteUserApplicantHealthInput!
        $condition: ModelUserApplicantHealthConditionInput
    ) {
        deleteUserApplicantHealth(input: $input, condition: $condition) {
            id
            weight
            height
            smoker
            everSmoked
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;

export const deleteAdminTextractedStatement = /* GraphQL */ `
    mutation DeleteAdminTextractedStatement(
        $input: DeleteAdminTextractedStatementInput!
        $condition: ModelAdminTextractedStatementConditionInput
    ) {
        deleteAdminTextractedStatement(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteAdminMortgageAccountSettings = /* GraphQL */ `
    mutation DeleteAdminMortgageAccountSettings(
        $input: DeleteAdminMortgageAccountSettingsInput!
        $condition: ModelAdminMortgageAccountSettingsConditionInput
    ) {
        deleteAdminMortgageAccountSettings(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteAdminMortgageTasks = /* GraphQL */ `
    mutation DeleteAdminMortgageTasks(
        $input: DeleteAdminMortgageTasksInput!
        $condition: ModelAdminMortgageTasksConditionInput
    ) {
        deleteAdminMortgageTasks(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteAdminMortgageNotes = /* GraphQL */ `
    mutation DeleteAdminMortgageNotes(
        $input: DeleteAdminMortgageNotesInput!
        $condition: ModelAdminMortgageNotesConditionInput
    ) {
        deleteAdminMortgageNotes(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteAdminMortgageStageTask = /* GraphQL */ `
    mutation DeleteAdminMortgageStageTask(
        $input: DeleteAdminMortgageStageTaskInput!
        $condition: ModelAdminMortgageStageTaskConditionInput
    ) {
        deleteAdminMortgageStageTask(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteAdminMortgageDocumentContent = /* GraphQL */ `
    mutation DeleteAdminMortgageDocumentContent(
        $input: DeleteAdminMortgageDocumentContentInput!
        $condition: ModelAdminMortgageDocumentContentConditionInput
    ) {
        deleteAdminMortgageDocumentContent(input: $input, condition: $condition) {
            id

        }
    }
`;
export const deleteAdminMortgageSettings = /* GraphQL */ `
    mutation DeleteAdminMortgageSettings(
        $input: DeleteAdminMortgageSettingsInput!
        $condition: ModelAdminMortgageSettingsConditionInput
    ) {
        deleteAdminMortgageSettings(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgage = /* GraphQL */ `
    mutation DeleteUserMortgage(
        $input: DeleteUserMortgageInput!
        $condition: ModelUserMortgageConditionInput
    ) {
        deleteUserMortgage(input: $input, condition: $condition) {
            _deleted
            id
            createdAt
            updatedAt
            _version
            _lastChangedAt
        }
    }
`;
export const deleteUserMortgageInvitation = /* GraphQL */ `
    mutation DeleteUserMortgageInvitation(
        $input: DeleteUserMortgageInvitationInput!
        $condition: ModelUserMortgageInvitationConditionInput
    ) {
        deleteUserMortgageInvitation(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageDocuments = /* GraphQL */ `
    mutation DeleteUserMortgageDocuments(
        $input: DeleteUserMortgageDocumentsInput!
        $condition: ModelUserMortgageDocumentsConditionInput
    ) {
        deleteUserMortgageDocuments(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageProgress = /* GraphQL */ `
    mutation DeleteUserMortgageProgress(
        $input: DeleteUserMortgageProgressInput!
        $condition: ModelUserMortgageProgressConditionInput
    ) {
        deleteUserMortgageProgress(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageProgressNotes = /* GraphQL */ `
    mutation DeleteUserMortgageProgressNotes(
        $input: DeleteUserMortgageProgressNotesInput!
        $condition: ModelUserMortgageProgressNotesConditionInput
    ) {
        deleteUserMortgageProgressNotes(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageDynamicSection = /* GraphQL */ `
    mutation DeleteUserMortgageDynamicSection(
        $input: DeleteUserMortgageDynamicSectionInput!
        $condition: ModelUserMortgageDynamicSectionConditionInput
    ) {
        deleteUserMortgageDynamicSection(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageUploads = /* GraphQL */ `
    mutation DeleteUserMortgageUploads(
        $input: DeleteUserMortgageUploadsInput!
        $condition: ModelUserMortgageUploadsConditionInput
    ) {
        deleteUserMortgageUploads(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageRequirement = /* GraphQL */ `
    mutation DeleteUserMortgageRequirement(
        $input: DeleteUserMortgageRequirementInput!
        $condition: ModelUserMortgageRequirementConditionInput
    ) {
        deleteUserMortgageRequirement(input: $input, condition: $condition) {
            id
            _deleted
            createdAt
            updatedAt
            _version
            _lastChangedAt
        }
    }
`;
export const deleteUserMortgageProperty = /* GraphQL */ `
    mutation DeleteUserMortgageProperty(
        $input: DeleteUserMortgagePropertyInput!
        $condition: ModelUserMortgagePropertyConditionInput
    ) {
        deleteUserMortgageProperty(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageExisting = /* GraphQL */ `
    mutation DeleteUserMortgageExisting(
        $input: DeleteUserMortgageExistingInput!
        $condition: ModelUserMortgageExistingConditionInput
    ) {
        deleteUserMortgageExisting(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageNeeds = /* GraphQL */ `
    mutation DeleteUserMortgageNeeds(
        $input: DeleteUserMortgageNeedsInput!
        $condition: ModelUserMortgageNeedsConditionInput
    ) {
        deleteUserMortgageNeeds(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgageSolicitor = /* GraphQL */ `
    mutation DeleteUserMortgageSolicitor(
        $input: DeleteUserMortgageSolicitorInput!
        $condition: ModelUserMortgageSolicitorConditionInput
    ) {
        deleteUserMortgageSolicitor(input: $input, condition: $condition) {
            id
        }
    }
`;
export const deleteUserMortgagePropertyOnMortgage = /* GraphQL */ `
    mutation DeleteUserMortgagePropertyOnMortgage(
        $input: DeleteUserMortgagePropertyOnMortgageInput!
        $condition: ModelUserMortgagePropertyOnMortgageConditionInput
    ) {
        deleteUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
            id
        }
    }
`;

export async function deleteMortgage(mortgages, mortgage) {


    let moreThanOne1 = mortgages.ALL.find(it => it.applicant1ID === mortgage.applicant1ID).length > 1
    let moreThanOne2 = mortgages.ALL.find(it => it.applicant2ID === mortgage.applicant2ID).length > 1

    const doMutation = (row, mutation) => {
        if (!row) {

            return new Promise(resolve => true)
        }
        return performGQL({
            input: {
                id      : row.id,
                _version: row._version
            }
        }, mutation)
    }
    const doMultipleMutation = (array, mutation) => {
        if (Array.isArray(array)) {
            return array.map(row => doMutation(row, mutation))
        }
        return []
    }
    const doProgressNotesMutation = () => {
        if (mortgage.progress && mortgage.progress.length) {
            return mortgage.progress.flatMap(section => {
                return doMultipleMutation(section.notes, deleteUserMortgageProgressNotes)
            })
        }
        return []
    }
    const doPropertyMutation = () => {
        if (mortgage.property) {
            return [doMutation(mortgage.property, deleteUserMortgageProperty)]
        }
        return []
    }
    const doExistingMutation = () => {
        if (mortgage.existing) {
            return [doMutation(mortgage.existing, deleteUserMortgageExisting)]
        }
        return []
    }
    const promises = [
        ...doProgressNotesMutation(),
        ...doMultipleMutation(mortgage.queries, deleteUserMortgageQuery),
        ...doMultipleMutation(mortgage.uploads, deleteUserMortgageUploads),
        ...doMultipleMutation(mortgage.properties, deleteUserMortgagePropertyOnMortgage),
        ...doMultipleMutation(mortgage.dynamicSections, deleteUserMortgageDynamicSection),
        ...doMultipleMutation(mortgage.progress, deleteUserMortgageProgress),
        ...doMultipleMutation(mortgage.UserMortgageDocuments, deleteUserMortgageDocuments),
        ...doMultipleMutation(mortgage.invitations, deleteUserMortgageInvitation),
        ...doMultipleMutation(mortgage.stageTasks, deleteAdminMortgageStageTask),
        ...doMultipleMutation(mortgage.notes, deleteAdminMortgageNotes),
        ...doMultipleMutation(mortgage.tasks, deleteAdminMortgageTasks),
        ...doMultipleMutation(mortgage.notifications, deleteAdminMortgageStageNotifications),
        ...doMultipleMutation(mortgage.notifications.flatMap(note => note.history.items).map(item => ({
            id      : item.id,
            _version: item._version
        })), deleteAdminMortgageStageNotified),
        ...doPropertyMutation(),
        ...doExistingMutation(),
        doMutation(mortgage.needs, deleteUserMortgageNeeds),
        doMutation(mortgage.solicitor, deleteUserMortgageSolicitor),
        doMutation(mortgage.settings, deleteAdminMortgageSettings),
        ...(() => {
            if (!moreThanOne1) {
                return [
                    doMutation(mortgage.applicant1.employment, deleteUserApplicantEmployment),
                    doMutation(mortgage.applicant1.financial, deleteUserApplicantFinancial),
                    doMutation(mortgage.applicant1.income, deleteUserApplicantIncome),
                    doMutation(mortgage.applicant1.personal, deleteUserApplicantPersonal),
                    doMutation(mortgage.applicant1.tax, deleteUserApplicantTax),
                    doMutation(mortgage.applicant1.health, deleteUserApplicantHealth),
                    doMutation(mortgage.applicant1.lastHereTime, deleteUserApplicantLastHere),
                    ...doMultipleMutation(mortgage.applicant1.alternateEmailAddresses, deleteUserApplicantAlternateEmailAddresses),
                    ...doMultipleMutation(mortgage.applicant1.properties, deleteUserApplicantProperty),
                    ...doMultipleMutation(mortgage.applicant1.debts, deleteUserApplicantDebt),
                    ...doMultipleMutation(mortgage.applicant1.assets, deleteUserApplicantAsset),
                    ...doMultipleMutation(mortgage.applicant1.activity, deleteUserApplicantActivity),
                    ...doMultipleMutation(mortgage.applicant1.employmentIncome, deleteUserApplicantEmploymentIncome),
                    ...doMultipleMutation(mortgage.applicant1.accounts, deleteUserApplicantCurrentAccount),
                    doMutation(mortgage.applicant1, deleteUserApplicant),
                ]
            }
            return []
        })(),
        ...(() => {
            if (!moreThanOne2) {
                return [
                    doMutation(mortgage.applicant2.employment, deleteUserApplicantEmployment),
                    doMutation(mortgage.applicant2.financial, deleteUserApplicantFinancial),
                    doMutation(mortgage.applicant2.income, deleteUserApplicantIncome),
                    doMutation(mortgage.applicant2.personal, deleteUserApplicantPersonal),
                    doMutation(mortgage.applicant2.tax, deleteUserApplicantTax),
                    doMutation(mortgage.applicant2.health, deleteUserApplicantHealth),
                    doMutation(mortgage.applicant2.lastHereTime, deleteUserApplicantLastHere),
                    ...doMultipleMutation(mortgage.applicant2.alternateEmailAddresses, deleteUserApplicantAlternateEmailAddresses),
                    ...doMultipleMutation(mortgage.applicant2.properties, deleteUserApplicantProperty),
                    ...doMultipleMutation(mortgage.applicant2.debts, deleteUserApplicantDebt),
                    ...doMultipleMutation(mortgage.applicant2.assets, deleteUserApplicantAsset),
                    ...doMultipleMutation(mortgage.applicant2.activity, deleteUserApplicantActivity),
                    ...doMultipleMutation(mortgage.applicant2.employmentIncome, deleteUserApplicantEmploymentIncome),
                    ...doMultipleMutation(mortgage.applicant2.accounts, deleteUserApplicantCurrentAccount),
                    doMutation(mortgage.applicant2, deleteUserApplicant),
                ]
            }
            return []
        })(),
        doMutation(mortgage, deleteUserMortgage),
    ]
    return await Promise.all(promises)
}