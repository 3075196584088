import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import {Badge, Button, Card, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {grey, orange, red} from "@ant-design/colors";
import {ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import useBackendUsers from "../../../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import {LONGDATE} from "../../../../../../../../assets/scripts/constants";
import {currencyFormatter, currencyParser} from "../../../../../../../../assets/scripts/parser-functions";
import useMortgage from "../../../../../../../providers/mortgage/useMortgage";

export function getClientValue(value, type) {
    switch (type) {
        case 'BOOLEAN':
            return value === 'true'
        case 'NUMBER':
            return parseFloat(value)
        case 'DATE':
            return !!value ? dayjs.utc(value, 'YYYY-MM-DD') : null
        case 'NULL':
            return null
        default:

            return value
    }
}
export const askInForm = {
    date    : (name, label, disabled) => {
        const handleBlur = (e) => {
            e.preventDefault();
            e.stopPropagation();
            return false
        };
        return (<Form.Item
                key={name}
                label={label}
                name={name}
                className="mb-0">
                <DatePicker
                    disabled={disabled}
                    onBlur={handleBlur}
                    allowClear={true}
                    style={{width: '100%'}}
                    format={"D MMM YYYY"}
                />
            </Form.Item>

        )
    },
    int     : (name, label, disabled) => {
        return (<Form.Item
                key={name}
                label={label}
                name={name}
                className="mb-0">
                <InputNumber disabled={disabled} className="w-100"/>
            </Form.Item>

        )
    },
    text    : (name, label, disabled) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <Input disabled={disabled} className="w-100"/>
        </Form.Item>)
    },
    textarea: (name, label, disabled) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <Input.TextArea disabled={disabled} className="w-100"/>
        </Form.Item>)
    },
    currency: (name, label, disabled) => {

        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <InputNumber
                disabled={disabled}
                className="w-100"
                parser={currencyParser}
                formatter={currencyFormatter}
            />
        </Form.Item>)
    },
    select  : (name, label, options, disabled) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <Select options={options} disabled={disabled}/>
        </Form.Item>)
    },
    check   : (name, label, disabled) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0" valuePropName="checked">
            <Checkbox disabled={disabled}/>
        </Form.Item>)
    }
}

export function Render({manager, disabled=false}) {
    if (!askInForm.hasOwnProperty(manager.item.type)) {
        console.error(manager.item)
    }
    if (manager.item.type === 'select') {
        return askInForm.select(manager.item.name, manager.item.label, manager.item.options, disabled)
    }
    return askInForm[manager.item.type](manager.item.name, manager.item.label, disabled)
}

function MyLittleForm({item: managerFunction}) {

    //const [initialValue, setInitialValue] = useState(!!row ? getClientValue(row.verifiedValue,row.verifiedValueType) : null )
    const mortgage = useMortgage()
    const manager = managerFunction(mortgage)
    const [form] = Form.useForm()
    let [saving, setSaving] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)

    let initialValues = {[manager.item.name]: !!manager.data ? getClientValue(manager.data.verifiedValue, manager.data.verifiedValueType) : null}
    // useEffect(() => {
    //     if (manager.data && manager.data._version !== mortgageRowVersion) {
    //         console.log('setting new version ' + mortgageRowVersion , manager )
    //         //item.setAttribute('_version', mortgageRowVersion)
    //         // item.setAttribute(item.data.id, 'verifiedValue', mortgageRowValue)
    //     }
    // }, [mortgageRowVersion]);

    const handleItemBlur = async (name, value, setSaving) => {

        if (!!manager.data && initialValues[name] === value) {

            setSaving(false)
            return
        }
        setSaving(true)

        await manager.save(value)
        setSaving(false)


    }
    let timer = useRef(null)
    const handleValuesChange = (changedValues, allValues) => {
        // for use with datepicker, cos i cant get onBlur to work with it
        if (manager.item.type === 'date') {
            clearTimeout(timer.current)
            timer.current = setTimeout(async () => {
                let nameLocal = Object.keys(changedValues)[0]
                if (!!nameLocal) {
                    setSaving(true)
                    let value = allValues[nameLocal]
                    handleItemBlur(nameLocal, value, setSaving)
                }
            }, 50)
        }
    }
    const handleFormBlur = async (e) => {
        if (manager.item.type === 'date') {
            return
        }
        clearTimeout(timer.current)
        timer.current = setTimeout(async () => {
            let nameLocal = e.target.id
            if (!!nameLocal) {

                setSaving(true)
                let value = await form.getFieldValue(nameLocal)

                handleItemBlur(nameLocal, value, setSaving)
            }
            else {
                console.log(e.target)
            }
        }, 50)

    }
    const handleSettingsClick = async () => {
        if (!manager.data) {
            await handleItemBlur(manager.name, null, setSaving)
        }
        setInfoOpen(true)
    }
    const addMessage = async (text) => {
        await manager.createMessage(manager.data, text)

    }
    const setSupervisorCheck = (bool) => {
        manager.update({supervisorCheckRequired: bool ? 1 : 0})

    }

    let exlamColor = manager.data && manager.data.supervisorCheckRequired ? red[5] : manager.data && manager.data.verificationNotes.items.length ? orange[5] : '#d9d9d9'
    return <>

        <Form key={`form${manager.name}${manager.data?.id}`} labelAlign="left" labelCol={{span: 10}} form={form}
              onBlur={handleFormBlur} initialValues={initialValues} onValuesChange={handleValuesChange}>
            <div className="d-row gap-3">
                <div className="grow">
                    <Render manager={manager} disabled={saving}/>
                </div>

                <div>
                    {saving ? (<LoadingOutlined/>) : (<ExclamationCircleOutlined style={{
                        cursor: 'pointer',
                        color : exlamColor
                    }} onClick={handleSettingsClick}/>)}
                </div>

            </div>
        </Form>
        {!!infoOpen && !!manager.data && (<FormInModal key={`formmodal${manager.data.id}`} handleCancel={() => setInfoOpen(false)}
                                                       messages={manager.data.verificationNotes.items}
                                                       addMessage={addMessage} isSupervisorChecked={manager.data.supervisorCheckRequired}
                                                       setSupervisorCheck={setSupervisorCheck}/>)}
    </>

}
const FormInModal = ({
    handleCancel,
    messages = [],
    addMessage,
    setSupervisorCheck,
    isSupervisorChecked
}) => {
    const [form1, form2] = Form.useForm();
    const backendUsers = useBackendUsers()
    const messageFormRef = useRef(null)
    const handleMessageFinish = async (values) => {
        messageFormRef.current.resetFields()
        await addMessage(values.textMessage);
    };
    const handleSupervisorNeededFinish = (item) => {
        setSupervisorCheck(item.supervisorCheckRequired)
    }
    const handlePressEnter = (e) => {
        e.preventDefault();
        messageFormRef.current.submit();
    };

    function TextMessage({message}) {
        let mine = message.authorID === backendUsers.me.sub

        function MyCard() {
            return (<Card
                style={{
                    backgroundColor: mine ? '#f1f1f1' : '#f3f8fd',
                }}
                className="ms-auto my-3" styles={{body: {padding: 6}}}>
                {message.string}
            </Card>)
        }
        if (mine) {
            return <MyCard/>
        }
        const user = backendUsers.getBySub(message.authorID)

        return (<Badge offset={[
            15,
            4
        ]} color={user?.color || grey[3]} count={user?.firstName || 'Huh?'} size={"small"}>
            <MyCard/>
        </Badge>)
    }
    const sortMessagesByDate = (messages) => {
        const sortedMessages = {};

        messages.forEach(message => {
            const date = dayjs(message.timestamp).format(LONGDATE);
            if (!sortedMessages[date]) {
                sortedMessages[date] = [];
            }
            sortedMessages[date].push(message);
        });

        Object.keys(sortedMessages).forEach(date => {
            sortedMessages[date].sort((a, b) => dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? 1 : -1);
        });

        return sortedMessages;
    };
    let sortedMessages = sortMessagesByDate(messages)

    return (<>
        <Modal
            open={true}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={form1} onValuesChange={handleSupervisorNeededFinish}>
                <Form.Item
                    name="supervisorCheckRequired" valuePropName="checked" initialValue={isSupervisorChecked}
                >
                    <Checkbox>Supervisor Needed</Checkbox>
                </Form.Item>
            </Form>
            <div className="d-col gap-3">

                {Object.keys(sortedMessages).map(date => {
                    return (<>
                        <div key={date} className="d-row j-center a-center gap-3 mb-15" style={{
                            fontSize: 8,
                            color   : grey[3]
                        }}>
                            ---- <strong>{date}</strong> ----
                        </div>
                        {sortedMessages[date].map(message => {
                            return (<div className={`d-row`}>
                                <TextMessage key={message.id} message={message}/>
                            </div>)
                        })}

                    </>)
                })}


            </div>
            <Form ref={messageFormRef} form={form2} onFinish={handleMessageFinish}>
                <div className="d-row p-3" style={{
                    border      : '1px dashed #ccc',
                    borderRadius: 5
                }}>
                    <Form.Item noStyle name="textMessage">
                        <Input.TextArea onPressEnter={handlePressEnter} placeholder="Leave a message ..." style={{
                            outline        : 'none',
                            border         : 'none',
                            boxShadow      : 'none',
                            padding        : 5,
                            margin         : 0,
                            backgroundColor: 'transparent'
                        }}/>

                    </Form.Item>
                    <div className="d-col">
                        <Button htmlType="submit" className="mt-auto">Send</Button>
                    </div>
                </div>
            </Form>
        </Modal>
    </>);
};

export default MyLittleForm;