import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {countryOfResidence, irishResidenceStartDate, nationality, otherCountryOfResidenceInPast3Years} from "../../../../application/forms/sections/ApplicantPersonal";
import MortgageForm from "../../../../../data-capture/MortgageForm";

function Residence({applicant}) {
    const mortgage = useMortgage()
    const betterProps = {
        mortgage,
        applicant,
        you: mortgage[`applicant${applicant}`].strings
    }
    const questions = [
        nationality(betterProps),
        countryOfResidence(betterProps),
        otherCountryOfResidenceInPast3Years(betterProps),
        irishResidenceStartDate(betterProps),
    ]
    return (
        <div>
            {mortgage.twoApplicants && (<h3><strong>{mortgage[`applicant${applicant}`].firstName}</strong></h3>)}
            <MortgageForm questions={questions}/>
        </div>
    )
}

export default Residence;