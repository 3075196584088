import React from 'react';
import {Avatar, Segmented} from "antd";
import {ApplicationTypes, BoolLike, MortgageTypes} from "../../../../../../../../models";
import {green, orange, red} from "@ant-design/colors";
import {calcMonthlyPayment} from "../../../../../../../providers/mortgage/useRates";

function SegmentsMortgage({
    mortgage,
    figures,
    rate,
    activeSegment,
    setActiveSegment
})
{
    const segmentedSections = [
        {
            label: <MortgageLabel mortgage={mortgage} figures={figures}/>,
            value: 'Funding'
        },
        {
            label: <SalaryLabel mortgage={mortgage} figures={figures}/>,
            value: 'Salary'
        },
        {
            label: <PRA praTotal={figures.praTotal} mortgagePayment={calcMonthlyPayment(figures.provisionalLoan, figures.provisionalTerm, rate)}/>,
            value: 'PRA'
        },
        {
            label: <FundsAvailable fundsAvailable={mortgage.figures.verified.fundsTotal.total} fundsRequired={figures.requiredSavings}/>,
            value: 'Live Funds',
            disabled: true
        },
    ]
    return <Segmented options={segmentedSections} value={activeSegment} onChange={setActiveSegment}/>
}

function LabelHeading({
    dotColor,
    label
})
{
    return (<div className="d-row a-center gap-3">
        <Avatar size="small" style={{backgroundColor: dotColor}}></Avatar>
        <h4 className="m-0">{label}</h4>
    </div>)
}
function MortgageLabel({
    mortgage,
    figures
})
{
    let hasWarning = false
    let hasError = false
    let ltv = figures.ltv
    let lti = figures.lti
    let loan = figures.provisionalLoan
    // let price = state.formValues.price
    let factor = 3.5
    let htbNotSet = mortgage.applicationType === ApplicationTypes.FIRSTTIME && mortgage.requirement.helpToBuyScheme !== BoolLike.FALSE && !mortgage.requirement.helpToBuySchemeAmount
    if (mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME) {
        factor = 4
    }
    hasError = ltv > 90 || lti > factor
    hasWarning = !!htbNotSet
    return (<>
        <LabelHeading dotColor={hasError ? red[4] : hasWarning ? orange[4] : green[4]} label="Mortgage"/>

        <table style={{textAlign: 'left'}} cellPadding={0} cellSpacing={3}>
            <tbody>
            <tr>
                <td>
                    Loan
                </td>
                <td style={{textAlign: 'right'}}>
                    {loan?.toEuro()}
                </td>
            </tr>
            <tr>
                <td>LTV</td>
                <td style={{textAlign: 'right'}}>
                    {ltv > 90 ? <span className="text-danger">{ltv} %</span> : <span className="text-success">{ltv} %</span>}
                </td>
            </tr>
            <tr>
                <td>LTI</td>
                <td style={{textAlign: 'right'}}>
                    {lti > factor ? <span className="text-danger">{lti}</span> : <span className="text-success">{lti}</span>}
                </td>
            </tr>
            {htbNotSet && (<tr>
                <td colSpan={2}>
                    <span style={{color: orange[5]}}>Help to Buy not set!</span>
                </td>
            </tr>)}
            </tbody>
        </table>
    </>)
}
function SalaryLabel({
    mortgage,
    figures
})
{
    let totalBasic = figures.basicTotal
    let totalVariable = figures.variableTotal
    let basicWarning = mortgage.figures.verified.basic1.warning || mortgage.figures.verified?.basic2?.warning
    let warning = basicWarning || totalVariable > 0
    let danger = !totalBasic
    return (<>
        <LabelHeading dotColor={danger ? red[4] : warning ? orange[4] : green[4]} label="Salary"/>
        <table style={{textAlign: 'left'}} className="m-0">
            <tbody>
            <tr>
                <td>
                    Basic
                </td>
                <td align="right">
                    {totalBasic ? <span className="text-success">{totalBasic.toEuro()}</span> : <span className="text-danger">No Basic!</span>}

                </td>
            </tr>
            {totalVariable > 0 && (<tr>
                <td style={{color: orange[5]}}>Variable</td>
                <td align="right" style={{color: orange[5]}}>
                    {totalVariable?.toEuro()}
                </td>
            </tr>)}
            {basicWarning && (<tr>
                <td colSpan={2}>
                    <span style={{color: orange[5]}}>Unconfirmed Basic!</span>
                </td>
            </tr>)}
            </tbody>
        </table>
    </>)
}
function PRA({
    mortgagePayment,
    praTotal
})
{

    let excess = praTotal - mortgagePayment
    let error = excess < 0
    return (<>
        <LabelHeading dotColor={error ? red[4] : green[4]} label="PRA"/>
        <table style={{textAlign: 'left'}} className="m-0">
            <tbody>
            <tr>
                <td>
                    Total PRA
                </td>
                <td align="right">
                    {praTotal?.toEuro()}
                </td>
            </tr>
            <tr>
                <td>
                    Est. Payments
                </td>
                <td align="right">
                    {mortgagePayment?.toEuro()}
                </td>
            </tr>
            <tr>
                <td>
                    {error ? 'Deficit' : 'Surplus'}
                </td>
                <td align="right" className={error ? 'text-danger' : 'text-success'}>
                    {Math.abs(excess)?.toEuro()}
                </td>
            </tr>
            </tbody>
        </table>
    </>)
}
function FundsAvailable({
    fundsAvailable,
    fundsRequired
})
{
    let excess = fundsAvailable - fundsRequired
    let error = excess < 0
    return (<>
        <LabelHeading dotColor={error ? red[4] : green[4]} label="Live Funding"/>
        <table style={{textAlign: 'left'}} className="m-0">
            <tbody>
            <tr>
                <td>
                    Available
                </td>
                <td align="right">
                    {fundsAvailable?.toEuro()}
                </td>
            </tr>
            <tr>
                <td>
                    Required
                </td>
                <td align="right">
                    {fundsRequired?.toEuro()}
                </td>
            </tr>
            <tr>
                <td>
                    {error ? 'Deficit' : 'Surplus'}
                </td>
                <td align="right" className={error ? 'text-danger' : 'text-success'}>
                    {Math.abs(excess)?.toEuro()}
                </td>
            </tr>
            </tbody>
        </table>
    </>)
}
export default SegmentsMortgage;