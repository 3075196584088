import {dataPoint, get, getInitialValues, getNullCells} from './index'
import dayjs from "dayjs";
import {
    ApplicationTypes, AssetTypes, BuildType, DebtTypes, EmploymentTypes, Frequencies, IncomeSources, PersonalAccommodationSituations, PersonalMaritalStatuses
} from "../../../models";
import commonAnswers from "./_commonAnswers";
import {useState} from "react";
import {
    getMortgageFigures
} from "../../../app/components/client/application-stages/_common/lender-tests/usePlayground";

export const havenCoverNoteCoords = {
    basicAndAllowances1    : 'H26',
    additionalIncome1      : 'H27',
    basicAndAllowances2    : 'I26',
    additionalIncome2      : 'I27',
    monthlyChildcare       : 'D25',
    monthlyDebt            : 'D26',
    propertyValue          : 'D33',
    loanAmount             : 'D36',
    loanTerm               : 'D37',
    repaymentCapacity1Name : 'C63',
    repaymentCapacity1Value: 'D63',
    repaymentCapacity2Name : 'C64',
    repaymentCapacity2Value: 'D64',
    repaymentCapacity3Name : 'C65',
    repaymentCapacity3Value: 'D65',
    repaymentCapacity4Name : 'C66',
    repaymentCapacity4Value: 'D66',
    repaymentCapacity5Name : 'C67',
    repaymentCapacity5Value: 'D67',
    repaymentCapacity6Name : 'C68',
    repaymentCapacity6Value: 'D68',
    repaymentCapacity7Name : 'C69',
    repaymentCapacity7Value: 'D69',
}
export const havenRules = (data) => {
    return {
        ...data,
        basic1           : (data.basic1 || 0) + (data.allowances1 || 0) + (data.other1 || 0),
        basic2           : (data.basic2 || 0) + (data.allowances2 || 0) + (data.other2 || 0),
        additionalIncome1: (data.overtime1 || 0) + (data.year1Bonus1 || 0) + (data.commission1 || 0),
        additionalIncome2: (data.overtime2 || 0) + (data.year1Bonus2 || 0) + (data.commission2 || 0),
    }
}
//const url = 'https://script.google.com/macros/s/AKfycbwAMxBqxVHgZTOnqb7wO8BXmh2CGXjuxNuJ3N3jDcZo7ogjxVcUq2GpPEWKXVbdSGS7/exec' //1.6
const url = 'https://script.google.com/macros/s/AKfycbyiQYfN6tW_-WWExhWVmFh76O37LcQwL_FNmjbLq3iIfqwe1kh94aLptuizlfw5C2A/exec' //1.7
const sheetName = 'Home Loans'
//const spreadsheetId = '1tNR3TV_55PzH_APfOF8_is_AOsTx6Xu3JAchPDQa0gA' //1.6
const spreadsheetId = '1E7TcjrIqek7krneam6WAkAeObtVTqHXAA8c9GGr1y7U' //1.7

function haven(mortgage, useVerifiedFigures = false) {

    const legacy = !mortgage.applicant1.employmentIncome?.length || !mortgage.applicant1.employmentIncome[0].employmentType

    const repaymentCapacityOptions = [
        'Rent',
        'Savings',
        'Loan discontinuing',
        'Reduced  loan repayments',
        'Monthly surplus',
        'AN other'
    ]
    const repaymentCapacities = []
    const customCapacities = []
    const answers = commonAnswers(mortgage)
    // ACCOMMODATION
    console.log({answers})
    if (answers.rent > 0) {
        repaymentCapacities.push({
            name  : 'Rent',
            amount: answers.rent
        })
    }
    if (answers.boarding > 0) {
        customCapacities.push({
            name  : 'Boarding',
            amount: answers.boarding
        })
    }

    // SAVINGS
    if (answers.savings > 0) {
        repaymentCapacities.push({
            name  : 'Savings',
            amount: answers.savings
        })
    }

    // CLEARING LOANS
    if (answers.clearingLoans > 0) {
        repaymentCapacities.push({
            name  : 'Loan discontinuing',
            amount: answers.clearingLoans
        })
    }

    // Last P60
    const getLastP60 = (applicant) => {
        let verifiedSectionSalaryCert = mortgage.mortgageUploads.setup.find(s => s.name === `employment-summary-${applicant}`)
        if (verifiedSectionSalaryCert && verifiedSectionSalaryCert.verified.uploads.active.length) {
            let verifiedCertsUploads = verifiedSectionSalaryCert.verified.uploads.active
            let certs = []
            verifiedCertsUploads.forEach(verifiedUpload => {
                let values = verifiedUpload.verified.values;
                certs.push({
                    date: values.year,
                    pay1: values.pay1 || 0,
                    pay2: values.pay2 || 0,
                    pay3: values.pay3 || 0,
                })
            })
            certs.sort((a, b) => b.year - a.year)
            if (certs.length) {
                return certs[0].pay1 + certs[0].pay2 + certs[0].pay3
            }
        }
        return 0
    }

    const liveCapacities = (formData) => {
        const betterRepaymentCapacities = []

        betterRepaymentCapacities.push({
            name  : 'Rent',
            amount: answers.rent
        })

        // SAVINGS
        betterRepaymentCapacities.push({
            name  : 'Savings',
            amount: (formData.savings1 || 0) + (formData.savings2 || 0)
        })

        // CLEARING LOANS
        betterRepaymentCapacities.push({
            name  : 'Loan discontinuing',
            amount: (formData.clearingLoans1 || 0) + (formData.clearingLoans2 || 0)
        })

        // Other PRA
        betterRepaymentCapacities.push({
            name  : 'Other PRA',
            amount: (formData.otherPRA1 || 0) + (formData.otherPRA2 || 0)
        })

        return betterRepaymentCapacities
    }

    const dataPoints = {
        'Application'       : [
            dataPoint('Name', 'D5:E5', {
                type : 'text',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a name'
                    },
                ]
            }, () => {
                return mortgage.applicant1.fullName
            }),
            dataPoint('Joint', 'D10', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select an application type'
                    },
                ]
            }, () => {
                return mortgage.twoApplicants ? 'y' : 'n'
            }),
            dataPoint('First Time Buyer', 'D22', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select an FTB'
                    },
                ]
            }, () => {
                return mortgage.applicationType === ApplicationTypes.FIRSTTIME ? 'y' : 'n'
            }),
            dataPoint('Account Number', 'D7:E7', {
                type : 'text',
                rules: [
                    {
                        required: true,
                        message : 'Please enter an account number'
                    },
                ]
            }, () => {
                return mortgage.requirement.mortgageAccountNo
            }),
            dataPoint('Tracker Transfer', 'H40', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select a tracker transfer'
                    },
                ]
            }, () => {
                return 'n'
            })
        ],
        'Applicant 1'       : [
            dataPoint('Applicant 1 Age', 'D13', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter an age'
                    }
                ]
            }, () => {
                if (!mortgage.applicant1.personal.dateOfBirth) {
                    return ''
                }
                const today = dayjs();
                const birthDate = dayjs(mortgage.applicant1.personal.dateOfBirth);
                return today.diff(birthDate, 'year')
            }),
            dataPoint('Applicant 1 Self-Employed', 'D16', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select if self-employed'
                    },
                ]
            }, () => {
                return legacy ? mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED ? 'y' : 'n' : mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED) > 0 ? 'y' : 'n'
            }, (formData) => {
                return formData.sector1 === 'self' ? 'y' : 'n'
            }, (newFormData) => {
                return newFormData.sector1 === 'self' ? 'y' : 'n'
            }),
            dataPoint('Basic & Allowances', 'H26', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a basic & allowances'
                    }
                ]
            }, () => {
                let tot = 0
                if ([
                    EmploymentTypes.EMPLOYED,
                    EmploymentTypes.SELFEMPLOYED
                ].includes(mortgage.applicant1.employment.employmentType))
                {
                    tot += mortgage.applicant1.income.grossBasic
                }
                if (!!mortgage.applicant1.income.incomeChecks?.includes('ALLOWANCES')) {
                    tot += mortgage.applicant1.income.allowances
                }
                return tot
            }, (formData) => {
                let tot = formData.basic1 || 0
                tot += formData.allowances1 || 0
                return tot
            }, (newFormData) => {
                let tot = newFormData.basic1 || 0
                tot += newFormData.allowances1 || 0
                return tot
            }),
            dataPoint('Additional Income', 'H27', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter an additional income'
                    }
                ]
            }, () => {
                let tot = 0
                if (!!mortgage.applicant1.income.incomeChecks) {
                    mortgage.applicant1.income.incomeChecks.forEach(type => {
                        switch (type) {
                            case 'OVERTIME':
                                tot += mortgage.applicant1.income.overtime;
                                break
                            case 'BONUS':
                                tot += mortgage.applicant1.income.bonus;
                                break
                            case 'COMMISSION':
                                tot += mortgage.applicant1.income.commission;
                                break
                            case 'SECONDJOB':
                                tot += mortgage.applicant1.income.secondjob;
                                break
                            case 'RENT':
                                tot += mortgage.applicant1.income.rentalIncome;
                                break
                            case 'DIVIDENDS':
                                tot += mortgage.applicant1.income.dividends;
                                break
                            case 'OTHER':
                                tot += mortgage.applicant1.income.other;
                                break
                        }
                    })
                }
                return tot
            }, (formData) => {

                let tot = formData.overtime1 || 0
                tot += formData.commission1 || 0
                tot += formData.year1Bonus1 || 0
                tot += formData.other1 || 0
                return tot
            }, (newFormData) => {
                let tot = newFormData.overtime1 || 0
                tot += newFormData.commission1 || 0
                tot += newFormData.year1Bonus1 || 0
                tot += newFormData.other1 || 0
                return tot
            }),
            dataPoint('Last P60 amount', 'H32', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a P60 amount'
                    }
                ]
            }, () => {
                return getLastP60(1)
                // const section = mortgage.progress.find(it => it.sectionName === 'employment-summary-1' && it.sectionType === 'UPLOAD' && it.applicationStage === 'APPLY')
                // console.log({section})
                // if (section && !!section.metaData) {
                //     const lastYear = new Date().getFullYear() - 1
                //     const data = JSON.parse(section.metaData)
                //     if (data.hasOwnProperty('yearsIncluded') && data.yearsIncluded.hasOwnProperty(lastYear)) {
                //         return data.yearsIncluded[lastYear]
                //     }
                // }
                // return 0
            }),
            dataPoint('Pension Contribution', 'H35', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a pension contribution'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                return formData.pension1 || 0
            }, (newFormData) => {
                return newFormData.pension1 || 0
            })
        ],
        'Applicant 2'       : [
            dataPoint('Applicant 2 Age', 'E13', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter an age'
                    }
                ]
            }, () => {
                if (!mortgage.applicant2.personal.dateOfBirth) {
                    return ''
                }
                const today = dayjs();
                const birthDate = dayjs(mortgage.applicant2.personal.dateOfBirth);
                return today.diff(birthDate, 'year')
            }),
            dataPoint('Applicant 2 Self-Employed', 'E16', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select if self-employed'
                    },
                ]
            }, () => {
                return legacy ? mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED ? 'y' : 'n' : mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED) > 0 ? 'y' : 'n'
            }, (formData) => {
                return formData.sector2 === 'self' ? 'y' : 'n'
            }, (newFormData) => {
                return newFormData.sector2 === 'self' ? 'y' : 'n'
            }),
            dataPoint('Basic & Allowances', 'I26', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a basic & allowances'
                    }
                ]
            }, () => {
                let tot = 0
                if (mortgage.twoApplicants) {
                    if ([
                        EmploymentTypes.EMPLOYED,
                        EmploymentTypes.SELFEMPLOYED
                    ].includes(mortgage.applicant2.employment.employmentType))
                    {
                        tot += mortgage.applicant2.income.grossBasic
                    }
                    if (!!mortgage.applicant2.income.incomeChecks?.includes('ALLOWANCES')) {
                        tot += mortgage.applicant2.income.allowances
                    }
                }
                return tot
            }, (formData) => {
                let tot = formData.basic2 || 0
                tot += formData.allowances2 || 0
                return tot
            }, (newFormData) => {
                let tot = newFormData.basic2 || 0
                tot += newFormData.allowances2 || 0
                return tot
            }),
            dataPoint('Additional Income', 'I27', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter an additional income'
                    }
                ]
            }, () => {
                let tot = 0
                if (mortgage.twoApplicants) {
                    if (!!mortgage.applicant2.income.incomeChecks) {
                        mortgage.applicant2.income.incomeChecks.forEach(type => {
                            switch (type) {
                                case 'OVERTIME':
                                    tot += mortgage.applicant2.income.overtime;
                                    break
                                case 'BONUS':
                                    tot += mortgage.applicant2.income.bonus;
                                    break
                                case 'COMMISSION':
                                    tot += mortgage.applicant2.income.commission;
                                    break
                                case 'SECONDJOB':
                                    tot += mortgage.applicant2.income.secondjob;
                                    break
                                case 'RENT':
                                    tot += mortgage.applicant2.income.rentalIncome;
                                    break
                                case 'DIVIDENDS':
                                    tot += mortgage.applicant2.income.dividends;
                                    break
                                case 'OTHER':
                                    tot += mortgage.applicant2.income.other;
                                    break
                            }
                        })
                    }
                }
                return tot
            }, (formData) => {

                let tot = formData.overtime2 || 0
                tot += formData.commission2 || 0
                tot += formData.year1Bonus2 || 0
                tot += formData.other2 || 0
                return tot
            }, (newFormData) => {
                let tot = newFormData.overtime2 || 0
                tot += newFormData.commission2 || 0
                tot += newFormData.year1Bonus2 || 0
                tot += newFormData.other2 || 0
                return tot
            }),
            dataPoint('Last P60 amount', 'I32', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a P60 amount'
                    }
                ]
            }, () => {
                if (mortgage.twoApplicants) {
                    return getLastP60(2)
                    // const section = mortgage.progress.find(it => it.sectionName === 'employment-summary-2' && it.sectionType === 'UPLOAD' && it.applicationStage === 'APPLY')
                    //
                    // if (section && !!section.metaData) {
                    //
                    //     const lastYear = new Date().getFullYear() - 1
                    //     const data = JSON.parse(section.metaData)
                    //     if (data.hasOwnProperty('yearsIncluded') && data.yearsIncluded.hasOwnProperty(lastYear)) {
                    //         return data.yearsIncluded[lastYear]
                    //     }
                    // }
                    // return 0
                }
                return 0
            }),
            dataPoint('Pension Contribution', 'I35', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a pension contribution'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                return formData.pension2 || 0
            })

        ],
        'Personal Details'  : [
            dataPoint('Married', 'D19', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select if married'
                    },
                ]
            }, () => {
                return mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.MARRIED ? 'y' : 'n'
            }),
            dataPoint('Children', 'D20', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please enter a number of children'
                    }
                ]
            }, () => {
                return (mortgage.applicant1.personal.countDependents > 0 || (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0)) ? 'y' : 'n'
            }),
            dataPoint('No. Of Children', 'D21', {
                type: 'number',
            }, () => {
                let count = 0
                if (mortgage.applicant1.personal.countDependents > 0) {
                    count += mortgage.applicant1.personal.countDependents
                }
                if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
                    count += mortgage.applicant2.personal.countDependents
                }
                return count
            }),
        ],
        'Short Term Debt'   : [
            dataPoint('Monthly Childcare', 'D25', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a short term debt'
                    }
                ]
            }, () => {
                let cost = 0
                if (mortgage.applicant1.personal.countDependents > 0) {
                    cost += mortgage.applicant1.financial.monthlyChildcareCosts
                }
                if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
                    cost += mortgage.applicant2.financial.monthlyChildcareCosts
                }
                return cost
            }),
            dataPoint('(incl. maintenance) Short Term Debt Monthly', 'D26', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a short term debt'
                    }
                ]
            }, () => {
                const filter = debt => debt.debtType === DebtTypes.LOAN && !debt.clearedBeforeMortgage
                let tot = 0
                mortgage.applicant1.debts.filter(filter).forEach(it => {
                    tot += it.monthlyPayment
                })
                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(it => {
                        tot += it.monthlyPayment
                    })
                }
                if (mortgage.applicant1.financial.paysMaintenance) {
                    tot += mortgage.applicant1.financial.maintenanceCosts
                }
                if (mortgage.applicant2.financial.paysMaintenance) {
                    tot += mortgage.applicant2.financial.maintenanceCosts
                }
                return tot
            }, (formData) => {
                const filter = debt => debt.debtType === DebtTypes.LOAN
                let tot = 0
                mortgage.applicant1.debts.filter(filter).filter(it => !formData.clearingLoans1.includes(it.id)).forEach(it => {
                    tot += it.monthlyPayment
                })
                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).filter(it => !formData.clearingLoans2.includes(it.id)).forEach(it => {
                        tot += it.monthlyPayment
                    })
                }
                if (mortgage.applicant1.financial.paysMaintenance) {
                    tot += mortgage.applicant1.financial.maintenanceCosts
                }
                if (mortgage.applicant2.financial.paysMaintenance) {
                    tot += mortgage.applicant2.financial.maintenanceCosts
                }
                return tot
            }),
            dataPoint('Monthly Shortfall from Properties', 'D27', {
                type: 'number',
            }, () => {
                return 0
            })
        ],
        'Property Details'  : [
            dataPoint('One Bed Property', 'D30', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select a property type'
                    }
                ]
            }, () => {
                return mortgage.properties[0].bedrooms === 1 ? 'y' : 'n'
            }),
            dataPoint('Self-Build', 'D31', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select a property type'
                    }
                ]
            }, () => {
                return mortgage.properties[0].buildType === BuildType.SELFBUILD ? 'y' : 'n'
            }),
            dataPoint('Property Value', 'D33', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a property price'
                    }
                ]
            }, () => {
                return mortgage.properties[0].propertyValue
            }, (formData) => {
                return formData.propertyValue
            }, (newFormData) => {
                return newFormData.provisionalValue
            }),
            dataPoint('Site & Build Cost', 'D32', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a property price'
                    }
                ]
            }, () => {
                return ''
            }),
        ],
        'Loan Details'      : [
            dataPoint('Loan Amount', 'D36', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a loan amount'
                    }
                ]
            }, () => {
                return mortgage.requirement.loanRequired || 0
            }, (formData) => {
                return formData.mortgageAmount
            }, (newForData) => {
                return newForData.provisionalLoan
            }),
            dataPoint('Loan Term', 'D37', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please enter a loan term'
                    }
                ]
            }, () => {
                return mortgage.requirement.loanTerm || 0
            }, (formData) => {
                return formData.term
            }, (newFormData) => {
                return newFormData.provisionalTerm
            }),
            dataPoint('Negative Equity Mortgage', 'D38', {
                type   : 'select',
                options: [
                    'y',
                    'n'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select a property type'
                    }
                ]
            }, () => {
                return 'n'
            }),
        ],
        'Repayment Capacity': [
            dataPoint('Repayment Capacity 1', 'C63', {
                type   : 'select',
                options: repaymentCapacityOptions,
                rules  : []
            }, () => {
                console.log({repaymentCapacities})
                return repaymentCapacities.length > 0 ? repaymentCapacities[0].name : ''
            }, (formData) => {
                console.log({formData})
                const lC = liveCapacities(formData)
                console.log({lC})
                return lC.length > 0 ? lC[0].name : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[0].name
            }),
            dataPoint('Amount 1', 'D63', {
                type : 'number',
                rules: []
            }, () => {
                return repaymentCapacities.length > 0 ? repaymentCapacities[0].amount : 0
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 0 ? lC[0].amount : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[0].amount
            }),
            dataPoint('Repayment Capacity 2', 'C64', {
                type   : 'select',
                options: repaymentCapacityOptions,
                rules  : []
            }, () => {
                return repaymentCapacities.length > 1 ? repaymentCapacities[1].name : ''
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 1 ? lC[1].name : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[1].name
            }),
            dataPoint('Amount 2', 'D64', {
                type : 'number',
                rules: []
            }, () => {
                return repaymentCapacities.length > 1 ? repaymentCapacities[1].amount : 0
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 1 ? lC[1].amount : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[1].amount
            }),
            dataPoint('Repayment Capacity 3', 'C65', {
                type   : 'select',
                options: repaymentCapacityOptions,
                rules  : []
            }, () => {
                return repaymentCapacities.length > 2 ? repaymentCapacities[2].name : ''
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 2 ? lC[2].name : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[2].name
            }),
            dataPoint('Amount 3', 'D65', {
                type : 'number',
                rules: []
            }, () => {
                return repaymentCapacities.length > 2 ? repaymentCapacities[2].amount : 0
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 2 ? lC[2].amount : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[2].amount
            }),
            dataPoint('Repayment Capacity 4', 'C66', {
                type   : 'select',
                options: repaymentCapacityOptions,
                rules  : []
            }, () => {
                return repaymentCapacities.length > 3 ? repaymentCapacities[3].name : ''
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 3 ? lC[3].name : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[3].name
            }),
            dataPoint('Amount 4', 'D66', {
                type : 'number',
                rules: []
            }, () => {
                return repaymentCapacities.length > 3 ? repaymentCapacities[3].amount : 0
            }, (formData) => {
                const lC = liveCapacities(formData)
                return lC.length > 3 ? lC[3].amount : ''
            }, (newFormData) => {
                return liveCapacities(newFormData)[3].amount
            }),
            dataPoint('Custom 1', 'C67', {
                type : 'text',
                rules: []
            }, () => {
                return customCapacities.length > 0 ? customCapacities[0].name : ''
            }),
            dataPoint('Custom Amount 1', 'D67', {
                type : 'number',
                rules: []
            }, () => {
                return customCapacities.length > 0 ? customCapacities[0].amount : 0
            }),
            dataPoint('Custom 2', 'C68', {
                type : 'text',
                rules: []
            }, () => {
                return customCapacities.length > 1 ? customCapacities[1].name : ''
            }),
            dataPoint('Custom Amount 2', 'D68', {
                type : 'number',
                rules: []
            }, () => {
                return customCapacities.length > 1 ? customCapacities[1].amount : 0
            }),
            dataPoint('Custom 3', 'C69', {
                type : 'text',
                rules: []
            }, () => {
                return customCapacities.length > 2 ? customCapacities[2].name : ''
            }),
            dataPoint('Custom Amount 3', 'D69', {
                type : 'number',
                rules: []
            }, () => {
                return customCapacities.length > 2 ? customCapacities[2].amount : 0
            }),
        ]
    }
    const dataToReceive = {
        'MonthlyPaymentStandard'   : 'Data|B18',
        'MonthlyPaymentStandardAdd': 'Data|B108',
        'Message'                  : 'C52',
        'Exception'                : 'D52',
        'Loan To Income'           : 'D54',
        'Required NDI'             : 'H51',
        'LTV'                      : 'D57',
        'Actual NDI'               : 'H50',
        'NDI %'                    : 'H52',
        'Stressed Payments'        : 'H59',
        'Repayment Deficit/Surplus': 'H67',
        'Repayment Capacity'       : 'D70',
    }
    return {
        name         : 'haven',
        dataPoints,
        get          : (values, shouldSaveOutput) => {
            const blankedData = getNullCells(dataPoints)
            return get(url, spreadsheetId, sheetName, {...blankedData, ...values}, dataToReceive, mortgage,  shouldSaveOutput)
        },
        initialValues: (data) => getInitialValues(dataPoints, data),
    }
}
export default haven;