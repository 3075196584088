import React, {useState} from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";
import {Button, Descriptions} from "antd";
import ButtonJumboAsync from "../../../../elements/ButtonJumboAsync";
import royal from './../../../../../../assets/images/royal-london.png'
function RoyalLondonQuote(props) {
    const [results,setResults] = useState(null)
    const mortgage = useMortgage()
    const ApiPath = 'https://quotes.royallondon.ie/QEWebApiLocal/api/Quotation/GetQuote';
    const request = {
        Token: 'EqE/983zEEnmrVwru7XbYDUKcmS4+He7l4RULqiq9So=',
        Data : {
            Input: {
                ProductType     : "MT",
                BasisType       : mortgage.twoApplicants ? "Joint":"Single",
                Term            : mortgage.activeSubmission?.provisionalTerm ? mortgage.activeSubmission.provisionalTerm : mortgage.requirement.loanTerm,
                MortgageInterest: "6.0",
                Indexation      : "N",
                Frequency       : "Monthly",
                CBR             : "C458123",
                LifeOnly        : "Y",
                LifeData        : []
            }
        }
    }
    const generateLifeData = (n) => {
        let dob = dayjs(mortgage[`applicant${n}`].personal.dateOfBirth)
        return {
            LifeNo     : n.toString(),
            DOBDay     : dob.format('DD'),
            DOBMonth   : dob.format('MM'),
            DOBYear    : dob.format('YYYY'),
            Gender     : mortgage[`applicant${n}`].isMale ? "Male" : "Female",
            Smoker     : mortgage[`applicant${n}`].health.smoker ? "Smoker" : "NonSmoker",
            BenefitData: [
                {
                    Type : "LifeCover",
                    Value: mortgage.activeSubmission?.provisionalLoan ? mortgage.activeSubmission.provisionalLoan : mortgage.requirement.loanRequired
                },
                {
                    Type : "IllnessCover",
                    Value: "0"
                }
            ]
        }
    }
    const test = async () => {
        setResults(null)
        const lifeData = [generateLifeData(1)]
        if (mortgage.twoApplicants) {
           lifeData.push(generateLifeData(2))
        }
        request.Data.Input.LifeData = lifeData

        const response = await fetch(ApiPath, {
            method : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body   : JSON.stringify(request)
        })
        const data = await response.json()
        if (data.AcknowledgeType !== "Failure") {
            setResults(data)
        }
    }
    let items = results ? [
        {label: "Company", children: results.Data.Output.Result.Company},
        {label: "Cover Type", children: results.Data.Output.Result.CoverType},
        {label: "Total Premium", children: results.Data.Output.Result.Premium},
        {label: "Summary", children: results.Data.Output.Summary.Summary},
        ...results.Data.Output.Summary.LifeSummaryData.map((item, index) => {
            return {label: `Life No ${item.LifeNo}`, children: item.Summary}
        })
    ] : null

    return (<div className="d-col gap-15">
        <ButtonJumboAsync
            className="me-auto"
            onClick={test}
            label={"Get Royal London Quote"}
            subText={"Get a quote from Royal London"}
            icon={<img style={{
                height: 40,
                width: 40,
                borderRadius: 20
            }} src={royal} alt="Royal London Logo"/>}
        />
        {!!items && <Descriptions size="small" column={1} bordered items={items}/>}
    </div>);

}

export default RoyalLondonQuote;