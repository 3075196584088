import React, {useContext, useState} from 'react';
import {Button, Checkbox, DatePicker, Descriptions, Modal, Popconfirm, Select, Skeleton, Spin, Tag} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage"

import {LONGDATE} from "../../../../assets/scripts/constants";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import {blue, red} from "@ant-design/colors";
import {Auth} from "aws-amplify";
import {ApplicationStages, ApplicationStatuses} from "../../../../models";
import {deleteMortgage} from "../../../api-functions/mortgage/deleteMortgage";
import {useNavigate} from "react-router-dom";
import {Mortgages} from "../../../providers/MortgagesProvider";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";

function Settings(props) {
    const [deleting, setDeleting] = useState(false)
    const navigate = useNavigate()
    const backendUsers = useBackendUsers()
    const mortgages = useContext(Mortgages)
    const mortgage = useMortgage()

    const items = mortgage && !!backendUsers?.getBySub ? [
        {
            key: '1a',
            label: 'Assigned To',
            children: <AssignedTo/>,
        },
        {
            key: '1b',
            label: 'Setup Done',
            children: <CheckboxItem item='markedSetupDone'/>,
        },
        {
            key: '2a',
            label: 'Stage',
            children: <ApplicationScreenSelect/>,
        },

        {
            key: '2b',
            label: 'Apply Done',
            children: <CheckboxItem item='markedApplyDone'/>,
        },
        {
            key: '3a',
            label: 'Status',
            children: <ApplicationStatusSelect/>,

        },
        {
            key: '3b',
            label: 'Docs Signed',
            children: <CheckboxItem item='markedDocsSigned'/>,
        },
        {
            key: '4a',
            label: mortgage.applicationStatus === ApplicationStatuses.DELAYED ? 'Until' : '',
            children: mortgage.applicationStatus === ApplicationStatuses.DELAYED ? <DelayDatePicker/> : '',
        },
        {
            key: '4b',
            label: 'Pre Approved',
            children: <CheckboxItem item='markedPreApproved'/>,
        },
        {
            key: '5a',
            label: '',
            children: '',
        },
        {
            key: '5b',
            label: 'Fully Approved',
            children: <CheckboxItem item='markedApproved'/>,
        },
        {
            key: '6a',
            label: '',
            children: '',
        },
        {
            key: '6b',
            label: 'Completed',
            children: <CheckboxItem item='markedComplete'/>,
        },
        {
            key: '7a',
            label: '',
            children: null,
        },
        {
            key: '7b',
            label: '',
            children: null,
        },
        {
            key: '8a',
            label: 'Permanently Delete',
            children: <DeleteConfirm/>,

        },
        {
            key: '8b',
            label: '',
            children: null,
        }

    ] : [];

    function AssignedTo() {
        const handleSelect = async (val) => {
            await mortgage.mutate({
                update: {
                    target: 'advisorID',
                    value: val
                }
            })
        }
        return <Select defaultValue={mortgage.advisorID} onSelect={handleSelect} style={{width: '100%'}}>
            {backendUsers.users.map(user => <Select.Option key={`advisor-${user.sub}`}
                                                           value={user.sub}> {user.firstName} </Select.Option>)}
        </Select>
    }

    function ApplicationScreenSelect() {
        const handleSelect = async (val) => {
            let userSub = (await Auth.currentAuthenticatedUser()).attributes.sub
            let today = new Date().toISOString()
            let change = {
                target: 'settings',
                pairs: {}
            }
            const update = []
            update.push({
                target: 'applicationStage',
                value: val
            })
            switch (val) {
                case ApplicationStages.APPLY:
                    if (!mortgage.settings.markedSetupDone) {
                        change.pairs.markedSetupDone = today
                        change.pairs.markedSetupDoneBy = userSub
                    }
                    break
                case ApplicationStages.SIGN:
                    if (!mortgage.settings.markedApplyDone) {
                        change.pairs.markedApplyDone = today
                        change.pairs.markedApplyDoneBy = userSub
                    }
                    break
                case ApplicationStages.PREAPPROVAL:
                    if (!mortgage.settings.markedDocsSigned) {
                        change.pairs.markedDocsSigned = today
                        change.pairs.markedDocsSignedBy = userSub
                    }
                    break
                case ApplicationStages.APPROVAL:
                    if (!mortgage.settings.markedPreApproved) {
                        change.pairs.markedPreApproved = today
                        change.pairs.markedPreApprovedBy = userSub
                    }
                    break
            }
            if (Object.keys(change.pairs).length > 0) {
                update.push(change)
            }
            await mortgage.mutate({update})
        }
        return <Select defaultValue={mortgage.applicationStage} onSelect={handleSelect} style={{width: '100%'}}>
            <Select.Option value={ApplicationStages.SETUP}> Setup </Select.Option>
            <Select.Option value={ApplicationStages.APPLY}> Apply </Select.Option>
            <Select.Option value={ApplicationStages.SIGN}> Sign </Select.Option>
            <Select.Option value={ApplicationStages.PREAPPROVAL}> Pre-approval </Select.Option>
            <Select.Option value={ApplicationStages.APPROVAL}> Approval </Select.Option>

        </Select>
    }
    function DelayDatePicker() {
        let defaultValue = mortgage.applicationFutureDate ? dayjs(mortgage.applicationFutureDate, "YYYY-MM-DD") : null
        const onChange = async (e) => {

            await mortgage.mutate({
                update: {
                    target: 'applicationFutureDate',
                    value: e.format('YYYY-MM-DD')
                }
            })
        }
        return <DatePicker style={{width: '100%'}} format={"D MMM YYYY"} defaultValue={defaultValue}
                           onChange={onChange}/>
    }
    function ApplicationStatusSelect() {
        const handleSelect = async (val) => {
            const update = []
            update.push(
                {
                    target: 'applicationStatus',
                    value: val
                }
            )
            if (val === ApplicationStatuses.COMPLETED) {
                update.push({
                    target: 'settings',
                    pairs: {
                        markedCompleted: new Date().toISOString(),
                        markedCompletedBy: (await Auth.currentAuthenticatedUser()).attributes.sub
                    }
                })
            }
            await mortgage.mutate({update})
        }
        return <Select defaultValue={mortgage.applicationStatus} onSelect={handleSelect} style={{width: '100%'}}>
            <Select.Option value={ApplicationStatuses.DELAYED}> Delayed </Select.Option>
            <Select.Option value={ApplicationStatuses.ACTIVE}> Active </Select.Option>
            <Select.Option value={ApplicationStatuses.SUSPENDED}> Suspended </Select.Option>
            <Select.Option value={ApplicationStatuses.COMPLETED}> Completed </Select.Option>
        </Select>
    }

    function CheckboxItem({item, danger}) {
        const [editing, setEditing] = useState(false)
        const toggleEditing = () => setEditing(p=>!p)

        const handleDateChange = async (date) => {
            await mortgage.mutate({
                update: {
                    target: `settings.${item}`,
                    value: date.toISOString()
                }
            })
        }
        const checked = !!mortgage.settings[item]
        let label = ''
        if (checked) {
            const by = `${item}By`
            return  (
                <div className="d-row gap-9">
                    <Tag color="green"> {backendUsers.getBySub(mortgage.settings[by]).firstName}</Tag>

                    {editing &&
                        <DatePicker size={"small"} format={LONGDATE} defaultValue={dayjs(mortgage.settings[item])}
                                    onChange={handleDateChange}/>}
                    {!editing && <>{dayjs(mortgage.settings[item]).format(LONGDATE)}</>}
                    <EditOutlined onClick={toggleEditing}/>

                </div>
            )
        }
        return null
        }
    function DeleteConfirm() {
        const mortgages = useContext(Mortgages)
        const handleDeleteClick = async () => {
            setDeleting(true)
            const result = await deleteMortgage(mortgages, mortgage)

            navigate('/')
        }
        return <Popconfirm
            title="Delete the account"
            description="Are you sure to delete this account?"
            okText="Delete"
            onConfirm={handleDeleteClick}
            icon={
                <QuestionCircleOutlined
                    style={{
                        color: red.primary,
                    }}
                />
            }
        >
            <Button danger>Delete</Button>
        </Popconfirm>
    }
    return (
        <Skeleton loading={!mortgage || !mortgage.settings || !backendUsers}>
            {mortgage.settings && (
                <Descriptions className="radius-0" column={2} bordered items={items}/>
            )}
            {deleting && (
                <Modal
                    title="Deleting..."
                    footer={null}
                    closable={false}
                    maskClosable={false}
                >
                    <Spin size="large"/>
                </Modal>
            )}
        </Skeleton>
    );
}

export default Settings;