import React, {useContext, useMemo, useRef, useState} from 'react';
import {
    CloseCircleOutlined, FileExclamationOutlined, FileImageOutlined, FilePdfOutlined, FileUnknownOutlined, LoadingOutlined,
} from "@ant-design/icons";
import {red, gray, green, geekblue, orange, lime} from "@ant-design/colors";
import {UploadStatuses} from "../../../../../../../../models";
import dayjs from "dayjs";
import RowTags from "./RowTags";
import ChangedIcons from "./ChangedIcons";
import DataButton from "./upload-buttons/DataButton";
import PopoverButton from "./upload-buttons/PopoverButton";
import OpenButton from "./upload-buttons/OpenButton";
import QueryButton from "./upload-buttons/QueryButton";
import ToPdffer from "./upload-buttons/popover-items/ToPdffer";
import {DataButtonContext} from "../DataButtonToggleProvider";

function NewUploadRow({
    file,
    section,
    sections
})
{
    const [state, setState] = useState({
        busy         : false,
        stamping     : false,
        omitting     : false,
        writingReason: false
    })

    const icon = <ToPdffer upload={file}/>
    // const icon = useMemo(() => {
    //     return <RowIcon/>
    // }, [file.status])
    let uploaded = [
        UploadStatuses.ACCEPTABLE,
        UploadStatuses.PENDING,
        UploadStatuses.REVIEWING,
        UploadStatuses.REJECTED,
        UploadStatuses.EXPIRED
    ].includes(file.status)

    let name = section.verified.isVerifiable ? file.verified.getLabel() : file.logicalName || file.name
    let className = file.examined ? "upload-row-examined" : ""
    let summaryRows = section.verified.isVerifiable ? section.verified.rows : []
    let correspondingRowWithTooOldProp = summaryRows.find(it => it.upload.id === file.id)
    let tooOld = correspondingRowWithTooOldProp && correspondingRowWithTooOldProp.tooOld
    if (tooOld){
        className = "upload-row-expired"
    }

    // find repeated sections
    let repeats = []
    sections.forEach((item) => {
        let allItemsLikeThis = sections.filter(it => {
            if (it.name === item.name) {
                if (it.index && item.index !== it.index) {
                    return false
                }
                return true
            }
            return false
        })
        if (allItemsLikeThis.length > 1) {
            repeats.push({allItemsLikeThis})
        }
    })

    return (<NUR id={file.id} busy={state.busy} icon={icon} name={name} createdAt={file.createdAt} fileName={file.fileName}
                 uploaded={uploaded} section={section} sections={sections} className={className}>
        {section.verified.isVerifiable && (<StaticDataButton keu={`hello-${file.id}`} upload={file} title={section.name}/>)}
    </NUR>)

}
const NUR = React.memo(UploadRow, (prevProps, nextProps) => {
    return prevProps.id === nextProps.id
})
function UploadRow({
    id,
    busy,
    icon,
    name,
    createdAt,
    uploaded,
    section,
    sections,
    className,
    children,
    fileName
})
{

    return (<div key={`upload-row${id}`} className={`d-row grow a-center ${className}`} >
            <span className={"mx-3"}>
                {!busy && icon}
                {!!busy && (<LoadingOutlined style={{
                    fontSize: 24,
                    color   : gray[3]
                }}/>)}
            </span>

        <div className="d-row p-15 gap-15 grow">
            <div className="d-col">
                    <span style={{color: geekblue.primary}}>
                        {name}
                    </span>
                <small style={{color: gray[4]}}>Uploaded {dayjs(createdAt).format('D MMM YYYY')}: {fileName}</small>
                <RowTags uploadId={id}/>
            </div>
        </div>
        {uploaded && (<div className="ms-auto d-row gap-9 me-9">

            <ChangedIcons uploadId={id}/>
            {children}
            <PopoverButton uploadId={id} section={section} sections={sections}/>
            <OpenButton uploadId={id}/>
            <QueryButton uploadId={id} section={section}/>
        </div>)}
    </div>)
}
function StaticDataButton({
    upload,
    title
})
{

    const {
              close,
              open,
              toggle,
              isOpen
          } = useContext(DataButtonContext)
    return <DataButton key={`data-button-${upload.id}`} upload={upload} title={title} closePopover={close} onToggle={toggle} popoverOpen={isOpen(upload.id)}/>
}

export default React.memo(NewUploadRow);