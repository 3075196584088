import React from 'react';
import {Alert, Button, Card, } from "antd";
import useApplicationUploads from "../../../../../providers/mortgage/useApplicationUploads";
import V2Uploads from "../../_common/uploads/v2/V2Uploads";

function ApprovedCustomerUploads() {
    const {approval: sections} = useApplicationUploads()
    const groups = []
    groups.push({
        name: "other",
        label: "Other",
        icon: 'pre',
        sections
    })
    return (
        <div className="d-col gap-15">
        <Card>
            {1 === 1 ? (<Alert className="mb-15" message={"Uploads Outstanding"} type="warning"/>) : (
                <Alert className="mb-15" message={"Uploads has been done"} type="success"/>)}

            <V2Uploads groups={groups} stage="PREAPPROVAL" extra={<div className="d-row f-wrap gap-15">
                <Button>Uploads Completed Email</Button>
                <Button>Uploads Outstanding Email</Button>

            </div>}/>

        </Card>




    </div>)
    // return <ApplicationUploadList sections={sections}/>


    // const items = useUploads()
    // return <ApplicationUploadList sections={items} generalOnly />
    // const backendUsers = useBackendUsers()
    // const expanded = items.map(o => {
    //     let label = capitaliseWords(o.title)
    //     return {
    //         key: `mk-${o.name}/${o?.index || ''}`,
    //         label,
    //         children: <UploadCollapsedPanel section={o} extra={<UploadDetailsForm section={o}/>}/>,
    //         extra: <RowExtra section={o}/>
    //     }
    // })
    // function RowExtra({section}){
    //     if (!section || !section.record) return null
    //     let completed = section?.record && section?.record.sectionChecked
    //     let completedBy = section?.record && section?.record.sectionCheckedBy ? backendUsers.getBySub(section.record.sectionCheckedBy).firstName : null
    //     let completedOn = section?.record && section?.record.sectionChecked ? dayjs(section.record.sectionChecked).format(LONGDATE) : null
    //     let uploads = section.uploads.length
    //     let pending = section.uploads.filter(upload => upload.status===UploadStatuses.PENDING).length
    //     let missingFigures = isMissingFigures(section)
    //     return (
    //         <div className="d-row a-center gap-15">
    //             {
    //                 missingFigures && (<Tag color="volcano" className="m-0">Missing Figures</Tag>)
    //             }
    //             {
    //                 (!completed && !!section.record.alertBody) && ( <Tag color="gold" className="m-0">More Requested</Tag>)
    //             }
    //             {
    //                 pending > 0 && (<Tag className="m-0" color={"purple"}>{pending} Pending</Tag>)
    //             }
    //             {
    //                 (!completed && uploads > 0) && (<Tag className="m-0" color={"cyan"}>{uploads} Uploads</Tag>)
    //             }
    //             {
    //                 !!completed && (
    //                     <>
    //                         <Tag className="m-0">{completedBy}</Tag>
    //                         <>{completedOn}</>
    //                         <Avatar size="small" icon={<CheckOutlined/>} style={{backgroundColor: green[4]}}/>
    //                     </>
    //                 )
    //             }
    //         </div>
    //     )
    // }
    //
    // return (
    //     <ConfigProvider theme={{
    //         components: {
    //             Collapse: {
    //                 contentPadding: 0,
    //             },
    //         },
    //     }}>
    //         <Collapse rootClassName="highlighter" destroyInactivePanel className="radius-0" accordion items={expanded}/>
    //     </ConfigProvider>
    // );
}

export default ApprovedCustomerUploads;