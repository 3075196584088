import React, {useEffect} from 'react';
import createOfflineMortgage from "../../api-functions/mortgage/createOfflineMortgage";
import {Spin} from "antd";
import {useNavigate} from "react-router-dom";

function CreateNewMortgage(props) {
    const navigate = useNavigate()

    const handelCreateOfflineMortgage = async ()=>{
       const id = await createOfflineMortgage()
       navigate(`/client/${id}`)
    }
    useEffect(() => {
        handelCreateOfflineMortgage()
    }, []);
    return (
        <div className="d-row a-center j-center" style={{height:'100vh'}}>
            <Spin size="large"/>
        </div>
    );
}

export default CreateNewMortgage;