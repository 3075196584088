import React from 'react';
import {Alert, Card, Table} from "antd";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import {RenderErrors} from "../../../../../../assets/scripts/verifiable-section/verifiableSection";
import UploadCollapsedPanel from "./standardised-upload/UploadCollapsedPanel";
import {ProgressViewState, UploadStatuses} from "../../../../../../models";

function MissingVerifiedUploads({stage}) {

    const mortgage = useMortgage()
    const uploads = mortgage.mortgageUploads[stage];

    let getErrors = (section) => {
        if (!section.verified || !section.verified?.isVerifiable) {
            return []
        }
        if (section.record.viewState === 'HIDDEN') {
                return []
        }

        if (!section.verified.uploads.active.length) {
            return ['No uploads']
        }
        if (section.verified.uploads.active.some(it => [
            UploadStatuses.PENDING,
            UploadStatuses.REVIEWING
        ].includes(it.status)))
        {
            return ['Pending uploads']
        }
        let moreErrors = []
        if (section.verified.uploads.active.some(it => !it.pdffed && ( it.contentType !== "application/pdf")) ) {

            moreErrors.push('Contains non-pdf uploads')
        }
        return [...moreErrors, ...section.verified.errors]
    }

    let applicant1 = uploads.filter(it => it.applicant === 1 && getErrors(it).length > 0)
    let applicant2 = uploads.filter(it => it.applicant === 2 && getErrors(it).length > 0)
    let general = uploads.filter(it => !it.applicant && getErrors(it).length > 0)

    const generateDataSource = (uploads, header) => {
        if (!uploads.length) {
            return [];
        }
        return [
            {
                key     : `${header}-header`,
                errors  : [],
                isHeader: true,
                section : <strong>{header}</strong>,
                uploadSection  : null
            },
            ...uploads.filter(u=>u.record.viewState!==ProgressViewState.HIDDEN).map((upload, index) => ({
                key     : `${header}-${index}`,
                errors  : getErrors(upload),
                isHeader: false,
                section : `${capitaliseWords(upload.title)} ${upload.statementGroup ? `(${capitaliseWords(upload.statementGroup)})` : ``} `,
                uploadSection  : upload
            }))
        ];
    };

    const dataSource = [
        ...generateDataSource(applicant1, 'Applicant 1'),
        ...generateDataSource(applicant2, 'Applicant 2'),
        ...generateDataSource(general, 'General')
    ];
    const columns = [
        {
            title    : 'Section',
            dataIndex: 'section',
            key      : 'section',
        },
        Table.EXPAND_COLUMN,
        {
            title    : 'Problems',
            dataIndex: 'errors',
            key      : 'errors',
            render   : (text, record) =>  {

                console.log({record})
                let problems = record?.uploadSection?.problems || []
                if (record?.uploadSection?.verified?.isVerifiable) {
                    if (record?.uploadSection.verified.uploads.active.length === 0) {
                        problems.push({
                            label: 'No Active Uploads',
                        })
                    }
                }
                if (!problems.length) {
                        return null
                }
                return (<Alert size="small" message={problems.map(it => {

                    return (<div key={it.label}>
                        <strong>{it.label}</strong>
                        {!!it.errors?.length && (
                        <ul className="d-col gap-3 m-0 ps-18">
                            {it.errors.map((it, i) => <li key={it}>{it}</li>)}
                        </ul>
                        )}
                    </div>)
                })} type="error"/>)
            }
        },
        {
            title: 'Internal Note',
            dataIndex: 'internalNote',
            key    : 'internalNote',
            render : (text, record) => {
                return record?.uploadSection?.record?.internalNote
            }
        },

    ];

    return <div className="d-row grow">
        <Table style={{width: '100%'}} size="small" expandable={{

            expandedRowRender: (record) => (
                <UploadCollapsedPanel sections={uploads} section={record.uploadSection}/>
            ),
            rowExpandable    : (record) => {

                return record.uploadSection && record.uploadSection.verified.uploads.active.length > 0
            }
        }}
               columns={columns} dataSource={dataSource} pagination={false}/>
    </div>
}

export default MissingVerifiedUploads;