import {buildToDo, isStepDone, updateCompletedFlowItems} from "./index";
import {ApplicationStages} from "../../../models";
import dayjs from "dayjs";
import {currentApplyOutstandingItemsEmailBody} from "../emails/useCurrentOutstandingItemsEmail";

export const chatComplete = (mortgage) => {
    return buildToDo('APPLY', 'chatComplete', {
        mortgage,
        toDo: {
            title: 'Kevin - Have a chat with the customer',
            tab: 'Have a Chat',
            emails: [
                {
                    name: 'reminder1',
                    title: 'Send Reminder Email',
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Reminder to Schedule a Call',
                            body: `
                                   <p>Dear ${mortgage.applicant1.firstName}</p>
                                  `
                        }
                    },
                },
                {
                    name: 'reminder2',
                    title: 'Send 2nd Reminder Email',
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Gentle Reminder: Schedule a Call',
                            body: `
                                    <p>Dear ${mortgage.applicant1.firstName}</p>
                                    <p>I hope this email finds you well.</p>
                                    
                                    <p>Thank you for your attention to this matter.</p>
                                    <p>Best regards,<br />
                                    The Suss'd Team</p>`
                        }
                    },
                    dueAfter: 3,
                }
            ],
            doneIf: (m) => {
                return m.completedFlowItems?.hasOwnProperty('ApplyChatComplete')
            }
        }
    })
}
export const sendMissingItemsEmail = (mortgage, {}) => {
    let dueAfter = dayjs().add(1, 'day')
    let uploadsVerified = isStepDone(mortgage, 'SETUP', 'verifyUploads.button.verified')
    if (uploadsVerified) {
        dueAfter = dayjs(uploadsVerified.on).add(5, 'day')
    }

    return buildToDo('APPLY', 'sendMissingItemsEmail', {
        mortgage,
        dueAfter,
        toDo: {
            for:'support',
            title: `Send outstanding items email`,
            emails: [
                {
                    name: 'initial',
                    title: "Send Outstanding Items Email",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
                          //  body: currentApplyOutstandingItemsEmailBody(mortgage),
                            from : 'help'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: "Send Outstanding Items Reminder",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
                      //      body: currentApplyOutstandingItemsEmailBody(mortgage),
                            from : 'help'
                        }
                    },
                    dueAfter: 6,
                }
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'verifyUploads.button.verified')
               // return m.completedFlowItems.hasOwnProperty('ApplyUploadsVerified')
            }
        }
    })
}

export const verifyUploads = (mortgage, props) => {

    return buildToDo('APPLY', 'verifyUploads', {
        mortgage,
        toDo: {
            title: `Verify uploads`,
            tab: 'Uploads',
            buttons: [
                {
                    label: "Mark Uploads Verified",
                    name: 'verified',
                    onClick: async () => {
                        await updateCompletedFlowItems(mortgage, 'ApplyUploadsVerified')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'verifyUploads.button.verified')
               // return m.completedFlowItems.hasOwnProperty('ApplyUploadsVerified')
            }
        }
    })
}
export const haveAChat = (mortgage) => {
    return buildToDo('SETUP', 'sendMoveToApplyEmail', {
        mortgage,
        toDo: {
            title: `Schedule a call`,
            emails: [
                {
                    name: 'initial',
                    title: "Send Schedule Call Email",
                    email: () => {
                        // toDo: add in if there are queries, options prepared and stuff about scheduling call

                        return {
                            onSent: async () => {
                                await mortgage.mutate({
                                    update:{
                                        target: 'applicationStage',
                                        value: ApplicationStages.APPLY
                                    }
                                })
                            },
                            to: [mortgage.applicant1.email],
                            subject: 'Invitation to Proceed',
                            body: 'Hi! We\'ve verified your information and now we\'d love the opportunity to set up a meeting and discuss your mortgage application in more detail. Please let us know when you are available.'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: "Send Schedule Call Reminder",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'You have not checked out  your options',
                            body: 'Please have a look at your options and schedule a call if interested'
                        }
                    },
                    dueAfter: 6,
                }
            ],
            doneIf: (m) => {
                let test = 'ApplyCallScheduled'
                return m.completedFlowItems.hasOwnProperty(test)
            }
        }
    })

}
export const packageApplication = (mortgage) => {
    return buildToDo('APPLY', 'packageApplication', {
        mortgage,
        toDo: {
            title: 'Kevin - Package Application',
            tab: 'Bundle & Submit',
            for:'advisor',
            buttons: [
                {
                    label: "Packaged",
                    name: 'packaged',
                    onClick: async () => {
                     //   await updateCompletedFlowItems(mortgage, 'ApplicationPackaged')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'packageApplication.button.packaged')
             //   return m.completedFlowItems?.hasOwnProperty('ApplicationPackaged')
            }
        }
    })
}
export const submitToBrokerAssist = (mortgage) => {
    return buildToDo('APPLY', 'submitToBrokerAssist', {
        mortgage,
        toDo: {
            for:'advisor',
            title: 'Kevin - Submit to Broker Assist',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Submitted To BA",
                    name: 'submitted',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'ApplicationSubmitted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'submitToBrokerAssist.button.submitted')
               // return m.completedFlowItems?.hasOwnProperty('ApplicationSubmitted')
            }
        }
    })
}
export const confirmBrokerAssistAccepted = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'APPLY', 'submitToBrokerAssist.button.submitted')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(1,'day') : 0
    return buildToDo('APPLY', 'confirmBrokerAssistAccepted', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Kevin - Confirm Accepted By Broker Assist',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Accepted By BA",
                    name: 'accepted',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'ApplicationAccepted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'confirmBrokerAssistAccepted.button.accepted')
              //  return m.completedFlowItems?.hasOwnProperty('ApplicationAccepted')
            }
        }
    })
}

export const havenReceivedApplication = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'APPLY', 'confirmBrokerAssistAccepted.button.accepted')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(4,'day') : 0
    return buildToDo('APPLY', 'havenReceivedApplication', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Received',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Received By Haven",
                    name: 'receivedByLender',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'LenderReceived')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'havenReceivedApplication.button.receivedByLender')
              //  return m.completedFlowItems?.hasOwnProperty('LenderReceived')
            }
        }
    })
}
export const havenAcceptedApplication = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'APPLY', 'havenReceivedApplication.button.receivedByLender')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(5,'day') : 0
    return buildToDo('APPLY', 'havenAcceptedApplication', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Accepted',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Accepted By Haven",
                    name: 'acceptedByLender',
                    onClick: async () => {
                       // await updateCompletedFlowItems(mortgage, 'LenderAccepted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'havenAcceptedApplication.button.acceptedByLender')
              //  return m.completedFlowItems?.hasOwnProperty('LenderAccepted')
            }
        }
    })
}
export const havenConfirmedAcceptable = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'APPLY', 'havenAcceptedApplication.button.acceptedByLender')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(4,'day') : 0
    return buildToDo('APPLY', 'havenConfirmedAcceptable', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Confirmed',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Confirmed By Haven",
                    name: 'confirmedByLender',
                    onClick: async () => {
                       // await updateCompletedFlowItems(mortgage, 'LenderConfirmed')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPLY', 'havenConfirmedAcceptable.button.confirmedByLender')
              //  return m.completedFlowItems?.hasOwnProperty('LenderConfirmed')
            }
        }
    })
}



export const toDos = [
  //  chatComplete,
    sendMissingItemsEmail,
    verifyUploads,
    packageApplication,
    submitToBrokerAssist,
    confirmBrokerAssistAccepted,
    havenReceivedApplication,
    havenAcceptedApplication,
    havenConfirmedAcceptable
]