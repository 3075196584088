import React, {useContext} from 'react';
import useApplyTimelineItems from "./useApplyTimelineItems";

import UploadsForApply from "./parts/UploadsForApply";
import Funding from "../_common/funding/Funding";
import Index from "../_common/lender-tests";
import QueryItems from "../_common/queries/QueryItems";
import Lender from "./parts/Lender";
import ApplyCustomerDocuments from "./parts/ApplyCustomerDocuments";
import ApplyAdminDocuments from "./parts/ApplyAdminDocuments";
import StagesLayout from "../_common/StagesLayout";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import HaveAChat from "../_common/have-a-chat/HaveAChat";
import Bundle from "./parts/Bundle";
import {Button} from "antd";
import useCurrentOutstandingItemsEmail from "../../../../../config-files/mortgage/emails/useCurrentOutstandingItemsEmail";
import MissingVerifiedUploads from "../_common/uploads/MissingVerifiedUploads";

function ManagementApply(props) {
    let [activeKey, setActiveKey] = React.useState('Uploads')
    const mortgage = useMortgage()
    const queries = mortgage ? mortgage.queries.filter(it => it.stage === 'APPLY') : []
    const {composeOutstandingEmail} = useCurrentOutstandingItemsEmail()

    const tabs = [

        {
            key: 'Uploads',
            label: 'Uploads',
            children: <UploadsForApply/>,
        },
        {
            key: 'Queries',
            label: 'Queries',
            children: <QueryItems queries={queries}/>,
        },
        {
            key: 'Upload Problems',
            label: 'Upload Problems',
            children: <MissingVerifiedUploads stage={'apply'}/>,
        }
        // {
        //     key: 'Lender Tests',
        //     label: 'Lender Tests',
        //     children: <Index/>,
        // },
        // {
        //     key: 'Funding',
        //     label: 'Funding',
        //     children: <Funding/>,
        // },


    ];
    return <StagesLayout title="Apply - Verifying Client Details" tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey} extras={[
        <Button key="Send Outstanding Items Email" onClick={()=>composeOutstandingEmail('APPLY')}> Send Outstanding Items Email</Button>
    ]}/>
}

export default ManagementApply;