
export default function (mortgage, config) {

    const part1  = () =>{
        let map={}
        let ask = []

        ask.push({
            label: 'Mortgage Reference',
            name: 'mortgageReference',
            type: 'TEXT',
            group: 'Part 1'
        })
        if (!config.hasOwnProperty('mortgageReference')){

            // Home loan application
        }
     //   map['Text Field 74'] = config.hasOwnProperty('homeLoanDescription') ? {string: config.homeLoanDescription} : {string: finalString}



    }


    const page1 = () => {
        let map = {}
        // map['Text Field 194'] = {string: mortgage.requirement.mortgageAccountNo} // Home loan application
        // map['Text Field 769'] = {string: "1"} // IBAN
        // map['Text Field 86'] = {string: "2"}
        return {map}
    }

    return {sections: [page1()]}
}