import React from 'react';
import {Button, Form, Input} from "antd";
import {joinWithNl} from "../../../../../../assets/scripts/string-functions";
import {Auth} from "aws-amplify";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function QueryItemAnswer(props) {
    const mortgage = useMortgage()
    const [form] = Form.useForm()
    const handleFinish = async (values) => {
        try{
            await form.validateFields()

            const user = await Auth.currentAuthenticatedUser()
            const update = {
                target: `queries[${props.item.id}]`,
                pairs: {
                    adminAnswered: 1,
                    lenderAnswer: values.lenderAnswer ? values.lenderAnswer.split('\n'):null,
                    lenderAnswerOn: (new Date()).toISOString(),
                    lenderAnswerBy: user.attributes.sub,
                }
            }
            await mortgage.mutate({update})
        }
        catch (e) {
            console.log({e})
        }

    }

    return (
        <Form
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            requiredMark={false}
            form={form}
            onFinish={handleFinish}
            initialValues={{
                userAnswer: joinWithNl(props.item.userAnswer),
                lenderAnswer: joinWithNl(props.item.lenderAnswer),
            }}
        >
            <Form.Item
                label="User Answer"
                name={"userAnswer"}
            >
                <Input.TextArea readOnly
                />
            </Form.Item>
            <Form.Item
                label="Lender Answer"
                name="lenderAnswer"
                rules={[{required: true, message: 'Please provide an answer'}]}
            >
                <Input.TextArea
                />
            </Form.Item>
            <Form.Item
                className="d-row j-end">
                <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
        </Form>
    );
}

export default QueryItemAnswer;