import React from 'react';
import useCustomerPreApprovalDocuments from "../useCustomerPreApprovalDocuments";
import {ApplicationStages} from "../../../../../../models";
import DocumentsTable from "../../_common/documents/DocumentsTable.js"
import {Alert, Button, Card, Popconfirm, Table} from "antd";
import MortgageForm from "../../../../data-capture/MortgageForm";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import {CloseCircleOutlined, FilePdfOutlined} from "@ant-design/icons";
import {green, red} from "@ant-design/colors";
import SimpleDocumentUploadButton from "../../_common/uploads/simple/SimpleDocumentUploadButton";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
function CustomerDocuments() {
    const mortgage = useMortgage()
    const items = useCustomerPreApprovalDocuments()

    let allGenerated = items.every(it => !!it.generated)
    const {open} = useMortgageDocumentsService()
    const handleDelete = async (record) => {
        await mortgage.mutate({
            delete: {
                target: 'UserMortgageDocuments',
                id: record.uploaded.id
            }
        })
    }
    const columns = [
        {
            title: 'Name',
            render: (text, record) => {
                return capitaliseWords(record.name.replace(/-/g, ' '))
            },
            key: 'name'
        },
        {
            title: 'Folder',
            render: (text, record) => {
                return capitaliseWords(record.folder.replace(/-/g, ' '))
            },
            key: 'folder'
        },
        {
            title: 'Upload',
            key: 'upload',
            align: 'center',
            render: (text, record) => {
                if (record.uploaded) {
                    return (
                        <FilePdfOutlined style={{cursor:'pointer', color: green[5]}} onClick={() => open(record.uploaded)}>Open</FilePdfOutlined>
                    )
                }
                return <CloseCircleOutlined style={{color:red[5]}}/>
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                if (!record.uploaded) return (<SimpleDocumentUploadButton name={record.name} folder={record.folder} stage={record.stage} isPrivate={true}/>)
                return <>
                    <Popconfirm title="Are you sure?" onConfirm={()=>{handleDelete(record)}}>
                        <Button danger size="small">Delete</Button>
                    </Popconfirm>

                </>
            },
        }
    ];
    const rows = [
        {
            name: 'insurance-transcript',
            folder: 'insurance-documents',
            stage: ApplicationStages.PREAPPROVAL,
            uploaded: mortgage.UserMortgageDocuments.find(it => it.name === 'insurance-transcript' && it.stage === ApplicationStages.PREAPPROVAL)
        }
    ]

    return (<Card>
        <div className="d-col gap-15">

            {!allGenerated ?
                (<Alert message={"Customer documents need to be generated"} type="warning"/>)
                :
                (<Alert  message={"Customer documents have been generated"} type="success"/>)
            }


            <DocumentsTable items={items} stage={ApplicationStages.PREAPPROVAL} done="createAIPCustomerDocuments"/>

            <Table columns={columns} dataSource={rows} pagination={false} />
            <div className="d-row j-end gap-15">
                <Button disabled={true}>Send Docusign Invitation</Button>
                <Button>Start Your Uploads Email</Button>
            </div>
        </div>

    </Card>)
}
export default CustomerDocuments;