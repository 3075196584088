import React, {useContext, useRef, useState} from 'react';
import {AutoComplete, ConfigProvider, Form, Input} from "antd";
import {Mortgages} from "../../providers/MortgagesProvider";
import {useNavigate} from "react-router-dom";
import {grey} from "@ant-design/colors";

function Search(props) {
    const mortgages = useContext(Mortgages)
    const navigate = useNavigate()
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('');
    const autoCompleteRef = useRef();

    const searchForEmail = (val)=>{
        const opts = []
        const addToOption = (m,  value)=>{
            opts.push({
                value: m.id,
                label: value
                })
        }
        mortgages.ALL.forEach(m=>{
            let email = m.applicant1.email
            if (!!email && email.includes(val)){
                addToOption(m,  `${m.applicant1.fullName} [${email}]`)
            }
            email = m.applicant2.email
            if (!!email && email.includes(val)){
                addToOption(m,  `${m.applicant2.fullName} [${email}]`)
            }
        })
        setOptions(opts)
    }
    const searchForPhone = ( val) =>{
        const opts = []
        const addToOption = (m,  value)=>{
            opts.push({
                value: m.id,
                label:value
            })
        }
        mortgages.ALL.forEach(m=>{
            let mobile = m.applicant1.mobile
            if (!!mobile && mobile.includes(val)){
                addToOption(m,  `${m.applicant1.fullName} [${mobile}]`)
            }
            mobile = m.applicant2.mobile
            if (!!mobile && mobile.includes(val)){
                addToOption(m,  `${m.applicant2.fullName} [${mobile}]`)
            }
        })
        setOptions(opts)
    }
    const searchForName = ( val) =>{

        setOptions(s=>{
            const opts = []
            const addToOption = (m,  value)=>{
                opts.push({
                    value: m.id,
                    label: value
                })
            }
            mortgages.ALL.forEach(m=>{
                let fullName1 = m?.applicant1?.fullName
                let fullName2 = m?.applicant2?.fullName
                let str = fullName1
                if (m.twoApplicants){
                    str += ` / ${fullName2}`
                }
                str += ` [${m.applicationStage}]`
                if (m.friendlyName){
                    str += ` (${m.friendlyName})`
                }
                if (str.toLowerCase().includes(val.toLowerCase())){
                    addToOption(m,  str)
                }
            })
            return opts
        })
    }

    const handleChange = (val) => {
        if (val.length > 2) {
            if (isNaN(parseInt(val))) {
                if (val.includes('@')) {
                    return searchForEmail(val)
                }
                return searchForName(val)
            }
            return searchForPhone(val)
        }
    }
    const handleSelect = (id)=>{
        setOptions([])
        setInputValue('')

        navigate(`/client/${id}`)
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: grey[2]
                },
            }}
        >
            <AutoComplete
                className="m-9"
                options={options}

                onSearch={handleChange}
                onSelect={handleSelect}

                value={inputValue}
                onChange={(value) => setInputValue(value)}
                popupMatchSelectWidth={400}
            >
                <Input.Search size="large" placeholder="Search..." enterButton/>
            </AutoComplete>
        </ConfigProvider>

    );
}

export default Search;