import {isFuture} from "date-fns";

export const monthYearFormat = (_, value) => {
    if (value && !/^\d{2}\s\/\s\d{4}$/.test(value)) {
        return Promise.reject('Enter a valid date in the format "MM / YYYY"');
    }
    return Promise.resolve();
};
const makeDate  = (value) =>{
    let parts = value.split(' / ').reverse()
    if (parts.length === 2){
        parts.push('01')
    }
    let str = parts.join('-')
    return new Date(str)

}

export const isInPast = (_, value) => {
    const vDate = makeDate(value)
    if (isFuture(vDate)){
        return Promise.reject('Enter a date in the past');
    }
    return Promise.resolve();
};

export const isInFuture = (_, value) => {
    try{
        const vDate = makeDate(value)
        if (!isFuture(vDate)){
            return Promise.reject('Enter a date in the future');
        }
        return Promise.resolve();
    }
    catch (e){
        return Promise.reject()
    }

};