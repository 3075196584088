import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Popover} from "antd";
import {CloseCircleOutlined} from "@ant-design/icons";
import {capitaliseFirst} from "../../../../../../../../../assets/scripts/string-functions";
import {UploadStatuses} from "../../../../../../../../../models";

const DataButton = ({
    upload,
    title,
    onToggle,
    closePopover,
    popoverOpen
}) => {
    let sectionTitle = capitaliseFirst(title);
    const Form = useMemo(() => {
        const UploadDataForm = upload.verified.UploadForm
        return <UploadDataForm key={upload.id}/>
    }, [upload.id])


    useEffect(() => {
        return () => {
            if (popoverOpen) {
                closePopover(upload.id)
            }
        }
    }, []);
    if (!Form) {
        return null;
    }
    let props = {}
    let isAnswered = upload.verified.answered

    if (!isAnswered || isAnswered.none) {
        props = {
            type : 'primary',
            style: {
                backgroundColor: '#f5222d',
                borderColor    : '#f5222d'
            }
        }
    }
    else if (isAnswered.all) {
        props = {
            type : 'primary',
            style: {
                backgroundColor: '#52c41a',
                borderColor    : '#52c41a'
            }
        }
    }
    else if (isAnswered.some) {
        props = {
            type : 'primary',
            style: {
                backgroundColor: '#faad14',
                borderColor    : '#faad14'
            }
        }
    }

    if (popoverOpen) {
        props = {type: 'primary'}
    }

    let popUpContent = <PopupContent Form={Form} upload={upload} sectionTitle={sectionTitle} closePopover={closePopover}/>




    return (<Popover  overlayStyle={{width: '600px'}} open={popoverOpen} placement="topLeft" title={false} content={popUpContent}>
        <Button  {...props} size="small" onClick={()=>onToggle(upload.id)}>Data</Button>
    </Popover>);
}

function PopupContent({Form, upload, sectionTitle, closePopover}) {
    return <>
        <div className="d-row j-between py-3">
            <h4 className="m-0">{capitaliseFirst(sectionTitle)}</h4>
            <CloseCircleOutlined onClick={()=>closePopover(upload.id)}/>
        </div>
        <small>{upload.id}</small>
        <hr style={{color: '#f5f5f5'}}/>
        <div className="d-col gap-6">
            {Form}
        </div>

    </>
}

export default DataButton