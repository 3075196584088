import React, {useContext, useEffect} from 'react';
import {listAllAndSetItems, performGQL} from "../api-functions/functions";
import useSubscription from "./useSubscription";
import {
    adminMortgageTasksByAuthor, adminMortgageTasksByCompletedBy,
    adminMortgageTasksByCurrentOwner,
    adminMortgageTasksByTarget,
    listAdminMortgageTasks
} from "../../graphql/queries";
import {Auth} from "aws-amplify";
import {
    onCreateAdminMortgageTasks,
    onDeleteAdminMortgageTasks,
    onUpdateAdminMortgageTasks
} from "../../graphql/subscriptions";
import {createAdminMortgageTasks, deleteAdminMortgageTasks, updateAdminMortgageTasks} from "../../graphql/mutations";
import {Mortgages} from "./MortgagesProvider";
import useBackendUsers from "./mortgage/useBackendUsers";
import {Card} from "antd";

export const MyAdminTasks = React.createContext({});
export const expandTasks = (tasks, mortgages=[]) => {
    return tasks.map(task=> {
        let mortgage = null
        if (task.usermortgageID){
            let m = mortgages.find(m => m.id === task.usermortgageID)
            if (m) {
                mortgage = {
                    id: m.id,
                    title: m.applicationTitle,
                }
            }
        }
        return {
            ...task,
            pauses: JSON.parse(task.pauses || '[]'),
            mortgage
        }
    }).map(task => {
        let userFolder = !!task.userFolder ? JSON.parse(task.userFolder) : {}
        let userSortPosition = !!task.userSortPosition ? JSON.parse(task.userSortPosition) : {}
        return {
            ...task,
            userFolder,
            userSortPosition
        }
    })
}
function MyAdminTasksProvider(props) {
    const [tasks, setTasks] = React.useState([])
    const mortgages = useContext(Mortgages)
    const operations = {
        update: (input) => {
            return performGQL({input}, updateAdminMortgageTasks)
        },
        create: (input) => {
            return performGQL({input}, createAdminMortgageTasks)
        },
        delete: (input) => {
            return performGQL({input}, deleteAdminMortgageTasks)
        },
    }
    const customSetTasks = (tasks) => {
        let updatedTasks = tasks.filter(it=>!it._deleted)
        setTasks(s => {
            //if empty
            if (!s.length) {
                return updatedTasks
            }
            //orig without new
            let orig = s.filter(t => {
                return !updatedTasks.find(it => it.id === t.id)
            })
            // if not empty and not already present
            return [...orig, ...updatedTasks]
        })
    }
    const load = async () => {
        const user = await Auth.currentAuthenticatedUser()
        await listAllAndSetItems({completedBy:'null'}, adminMortgageTasksByCompletedBy, customSetTasks)
       // await listAllAndSetItems({author: user.attributes.sub}, adminMortgageTasksByAuthor, customSetTasks)
      //  await listAllAndSetItems({currentOwner: user.attributes.sub}, adminMortgageTasksByCurrentOwner, customSetTasks)
    }
    useEffect(() => {
        load()
    }, [])
    useSubscription({
        subscription: {
            name: 'AdminMortgageTasks',
            onUpdate: onUpdateAdminMortgageTasks,
            onCreate: onCreateAdminMortgageTasks,
            onDelete: onDeleteAdminMortgageTasks,
        },
        setState: setTasks,
        filterForNullExceptions: ['completedOn', 'completedBy', 'closedOn', 'closedBy', 'targetStarted']
    })
    return (
        <MyAdminTasks.Provider value={{
            tasks: expandTasks(tasks, mortgages.ALL),
            operations
        }}>
            {props.children}

            {/*<WorkingOnBox />*/}

        </MyAdminTasks.Provider>
    );
}

function WorkingOnBox(){
    const {tasks: jobs} = useContext(MyAdminTasks)
    const backendUsers = useBackendUsers()
    const workingOn = jobs.filter(j => !!j.targetStarted && !j.completedOn && !j.closedOn && j.currentOwner === backendUsers.me.sub).filter(j => {
        let pauses = j.pauses || []
        let activePause = pauses.find(p => !p.completedOn)
        return !activePause
    })
     return (
        <div className="d-row" style={{width: '100%', position: 'fixed', bottom: 0, left: 0, zIndex: 98, paddingLeft:250}}>
            {workingOn.map(j => {
                return (
                    <Card styles={{body:{padding:4}}} key={'ab'+j.id} className="me-15"   >
                        {j.body.join(' ')}
                    </Card>
                )
            })}
        </div>
    )


}

export default MyAdminTasksProvider;