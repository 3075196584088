export function checklistItem({section, isRequired}){
    const errors = []
    const warnings = []
    if (!section.section){
        return null
    }
    if (!section.section.record){
        errors.push('Section has not been created')
    }
    else if (section.section.verified.errors.length){
        errors.push(...section.section.verified.errors)
    }
    else if (!section.section.record.sectionCheckedBy || section.section.record.sectionCheckedBy === 'null'){
        errors.push('Section has not been checked')
    }
    return {
        errors,
        warnings,
        section,
        isRequired
    }
}