import React from 'react';
import ConditionsTable from "../../components/client/conditions/ConditionsTable";
import PageHeader from "../../components/PageHeader";
import {capitaliseWords} from "../../../assets/scripts/string-functions";

function ConditionsPage(props) {
    return (
        <>
            <PageHeader title={capitaliseWords(props.stage.toLowerCase()) + " Uploadable Tasks"} className="p-15" back="./../"/>
            <ConditionsTable stage={props.stage}/>
        </>

    );
}

export default ConditionsPage;