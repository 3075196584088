import {API, graphqlOperation} from "aws-amplify";
import {performGQL} from "../functions";

const userMortgageUploadsByMortgageID = /* GraphQL */ `
    query UserMortgageUploadsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageUploadsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageUploadsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                contentType
                fileName
                fileSize
                fileExtension
                stage
                section
                index
                analysis
                status
                mortgageID
                observeMID
                reason
                identityID
                stampedOn
                pdffed
                seenOn
                seenBy
                omit
                exclude
                examined
                verifiedData {
                    items {
                        id
                        uploadID
                        mortgageID
                        name
                        verifiedValue
                        verifiedValueType
                        verificationOn
                        verificationBy
                        verificationNotes {
                            items {
                                id
                                verificationID
                                authorID
                                string
                                createdAt
                                updatedAt
                                _version
                                _deleted
                                _lastChangedAt
                                __typename
                            }
                            nextToken
                            startedAt
                            __typename
                        }
                        supervisorCheckRequired
                        supervisorCheckOn
                        supervisorCheckBy
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                }
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userMortgagePropertyOnMortgagesByMortgageID = /* GraphQL */ `
    query UserMortgagePropertyOnMortgagesByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgagePropertyOnMortgageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgagePropertyOnMortgagesByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                addressKnown
                address
                propertyType
                newlyBuilt
                bedrooms
                bathrooms
                berScore
                buildType
                propertyValue
                origPurchasePrice
                yearOfPurchase
                residingHere
                adultsBoarding
                adultsBoardingCount
                isRented
                monthlyRent
                isMortgaged
                isNewAccount
                isClosed
                remainingTerm
                lender
                accountNumber
                monthlyPayment
                currentRate
                outstanding
                mortgageID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userMortgageProgressesByMortgageID = /* GraphQL */ `
    query UserMortgageProgressesByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageProgressFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageProgressesByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                aiAnalysisTime
                applicationStage
                sectionType
                sectionName
                sectionIndex
                percent
                mortgageID
                percentUpdatedAt
                alertTitle
                alertBody
                alertAuthor
                alertDismissed
                alertDone
                alertPending
                sectionChecked
                sectionCheckedBy
                expiresDate
                isNewAccount
                note
                submittedOn
                submittedBy
                arrangement
                metaData
                verifiedData
                excludeFromCalculations
                internalNote
                internalNoteBy
                internalNoteOn
                lastDocumentDate
                lastDocumentDateDismissed
                viewState
viewStateBy
viewStateOn
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userMortgageDynamicSectionsByMortgageID = /* GraphQL */ `
    query UserMortgageDynamicSectionsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageDynamicSectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageDynamicSectionsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                sectionStage
                sectionType
                sectionName
                sectionIndex
                sectionTitle
                sectionInstructions
                mortgageID
                observeMID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userApplicantAssetsByApplicantID = /* GraphQL */ `
    query UserApplicantAssetsByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantAssetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantAssetsByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                assetType
                institution
                description
                balance
                increasesFrequency
                increases
                applicantID
                observeMID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userApplicantDebtsByApplicantID = /* GraphQL */ `
    query UserApplicantDebtsByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantDebtFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantDebtsByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                debtType
                lender
                outstanding
                monthlyPayment
                creditLimit
                inArrears
                arrears
                endDate
                clearedBeforeMortgage
                applicantID
                accountNumber
                observeMID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userApplicantEmploymentIncomesByApplicantID = /* GraphQL */ `
    query UserApplicantEmploymentIncomesByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantEmploymentIncomeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantEmploymentIncomesByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                applicantID
                employmentType
                employmentBasis
                positionHeld
                currentEmployerName
                currentEmployerAddress
                currentEmployerPhone
                currentEmployerContact
                currentEmployerStartDate
                previousEmployerStartDate
                previousEmployerEndDate
                nameOfBusiness
                natureOfBusiness
                businessAddress
                accountantName
                accountantAddress
                accountantPhone
                businessEstablished
                limitedCompany
                isDirector
                percentageOfShares
                selfIncomeSource
                grossBasic
                incomes
                publicSector
                nonEuroCurrency
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userApplicantPropertiesByApplicantID = /* GraphQL */ `
    query UserApplicantPropertiesByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantPropertyFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantPropertiesByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                propertyType
                address
                estimatedValue
                isRented
                rentPerAnnum
                isMortgaged
                isNewAccount
                isClosed
                accountNumber
                outstanding
                monthlyRepayments
                remainingTerm
                lender
                inArrears
                arrears
                applicantID
                bedrooms
                bathrooms
                isRestructured
                observeMID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const userMortgageInvitationsByMortgageID = /* GraphQL */ `
    query UserMortgageInvitationsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageInvitationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageInvitationsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                applicant2ID
                email
                inviter
                sent
                accepted
                invalidated
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const adminMortgageNotesByUsermortgageID = /* GraphQL */ `
    query AdminMortgageNotesByUsermortgageID(
        $usermortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageNotesFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageNotesByUsermortgageID(
            usermortgageID: $usermortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                body
                author
                pinned
                deleted
                usermortgageID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
const userMortgageDocumentsByMortgageID = /* GraphQL */ `
    query UserMortgageDocumentsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageDocumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageDocumentsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                title
                description
                identityID
                folder
                fileName
                fileExtension
                isDynamic
                mortgageID
                submissionID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
    }
`;
const adminMortgageTasksByUsermortgageID = /* GraphQL */ `
    query AdminMortgageTasksByUsermortgageID(
        $usermortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageTasksFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageTasksByUsermortgageID(
            usermortgageID: $usermortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                body
                author
                pinned
                due
                deleted
                target
                completedOn
                completedBy
                usermortgageID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
const adminMortgageStageTasksByMortgageID = /* GraphQL */ `
    query AdminMortgageStageTasksByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageStageTaskFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageStageTasksByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                stage
                name
                deleted
                due
                completedOn
                completedBy
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
const adminEmailsByUserApplicant = /* GraphQL */ `
    query AdminEmailsByUserApplicant(
        $userapplicantID: ID!
        $sortDate: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelAdminEmailsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminEmailsByUserApplicant(
            userapplicantID: $userapplicantID
            sortDate: $sortDate
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                from
                to
                bcc
                subject
                hasAttachments
                attachments
                sortDate
                datetimeReceived
                datetimeSent
                messageId
                originalId
                isReceived
                isReceivedInt
                readOn
                readBy
                appsyncVersion
                actionedOn
                actionedBy
                userapplicantID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
const adminMortgageLenderTestsByMortgageID = /* GraphQL */ `
    query AdminMortgageLenderTestsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageLenderTestFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageLenderTestsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                lenderCode
                mortgageID
                results
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
const adminMortgageLenderTestPlaygroundTestsByMortgageID = /* GraphQL */ `
    query AdminMortgageLenderTestPlaygroundTestsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageLenderTestPlaygroundTestFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageLenderTestPlaygroundTestsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                results
                data
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
const adminMortgageDocumentContentsByMortgageID = /* GraphQL */ `
    query AdminMortgageDocumentContentsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageDocumentContentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageDocumentContentsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                title
                folder
                document
                sequence
                html
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
const getOperation = (path) => {
    switch (path) {
        case "docContents":
            return adminMortgageDocumentContentsByMortgageID
        case 'playgroundTests':
            return adminMortgageLenderTestPlaygroundTestsByMortgageID
        case 'lenderTests':
            return adminMortgageLenderTestsByMortgageID
        case 'uploads':
            return userMortgageUploadsByMortgageID
        case 'properties':
            return userMortgagePropertyOnMortgagesByMortgageID
        case 'progress':
            return userMortgageProgressesByMortgageID
        case 'dynamicSections':
            return userMortgageDynamicSectionsByMortgageID
        case 'applicant1.employmentIncome':
        case 'applicant2.employmentIncome':
            return userApplicantEmploymentIncomesByApplicantID
        case 'applicant1.emails':
        case 'applicant2.emails':
            return adminEmailsByUserApplicant
        case 'applicant1.assets':
        case 'applicant2.assets':
            return userApplicantAssetsByApplicantID
        case 'applicant1.debts':
        case 'applicant2.debts':
            return userApplicantDebtsByApplicantID
        case 'applicant1.properties':
        case 'applicant2.properties':
            return userApplicantPropertiesByApplicantID
        case 'invitations':
            return userMortgageInvitationsByMortgageID
        case 'notes':
            return adminMortgageNotesByUsermortgageID
        case 'tasks':
            return adminMortgageTasksByUsermortgageID
        case 'stageTasks':
            return adminMortgageStageTasksByMortgageID
        case'UserMortgageDocuments':
            return userMortgageDocumentsByMortgageID
    }
}
async function getMoreItems(vars, path, setItems, allDoneCallback) {

    const operation = getOperation(path)
    const {items, nextToken: newNextToken} = await performGQL(vars, operation);
    if (items.length) {
        setItems(path, items)
    }
    if (newNextToken) {
        await getMoreItems({
            ...vars,
            nextToken: newNextToken
        }, path, setItems)
    } else {
        allDoneCallback(path)
    }
}
export default getMoreItems