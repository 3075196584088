import React from 'react';
import {Tabs} from "antd";
import QueryItemAnswer from "./QueryItemAnswer";
import QueryItemForm from "./QueryItemForm";

function QueryItemTabs(props) {


    const items = [
        {
            key: '1',
            label: 'Answers',
            children: <QueryItemAnswer item={props.item}/>,
        },
        {
            key: '2',
            label: 'Config',
            children: <QueryItemForm item={props.item}/>,
        },
    ];

    return (
        <Tabs defaultActiveKey="1" items={items} />
    );
}

export default QueryItemTabs;