import dayjs from "dayjs";

export const buildToDo = (STAGE, stepName, builder) => {

    const expandedEmailsWithStageAndName = (builder.toDo.emails || []).map(email => {
            if (Array.isArray(email)) {
                return email.map(groupEmail => {
                    return {
                        ...groupEmail,
                        name: `${stepName}.email.${groupEmail.name}`,
                        STAGE
                    }
                })
            } else {
                return {
                    ...email,
                    name: `${stepName}.email.${email.name}`,
                    STAGE
                }
            }
        })

    const expandedButtonsWithNameAndStage = (builder.toDo?.buttons || []).map(button => {

        return {
            ...button,
            name: `${stepName}.button.${button.name}`,
            STAGE
        }
    })
    let toDo = {
        builder,
        name: stepName,
        key: `${stepName}[${builder.mortgage.id}]`,
        title: builder.toDo.title,
        emails: expandedEmailsWithStageAndName,
        buttons: expandedButtonsWithNameAndStage,
        tab: builder.toDo.tab || null,
        mortgage: builder.mortgage,
        doneIf: builder.toDo.doneIf || null,
        skipIf: builder.toDo.skipIf || null,
        for: builder.toDo.for || null,
        STAGE
    }
    return toDo
}
export const updateCompletedFlowItems = async (mortgage, key, remove=false) => {
    let updated = {...mortgage.completedFlowItems}
    if (remove){
        delete updated[key]
    }
    else{
        updated[key] = (new Date()).toISOString()
    }
    await mortgage.mutate({
        update: {
            target: 'completedFlowItems',
            value: JSON.stringify(updated)
        }
    })
}
export const toDoList = (mortgage, funcs, props) => {
    const toDos = []
    let activeEncountered = false
    let test = '9ee2547b-f9ee--a3e4-a9c12dde1d08'
    let logIfDebug = (log)=>{
        if (mortgage.id === test) {
            console.log(log)
        }
    }

    for (let i = 0; i < funcs.length; i++) {
        let toDo = funcs[i](mortgage, props)
        if (!toDo){
            continue
        }
        if (toDo.hasOwnProperty('skipIf') && !!toDo.skipIf) {
            if (toDo.skipIf(mortgage)){
                continue
            }
        }
        toDo.is = {}
        if (toDo.hasOwnProperty('doneIf') && !!toDo.doneIf) {
            toDo.is.done = toDo.doneIf(mortgage)
        }
        logIfDebug(toDo)
        if (!toDo.is.done && !activeEncountered){
            let dueAfter = toDo.builder.dueAfter
            toDo.is.due=true
            logIfDebug(dueAfter)
            activeEncountered=true
            if (!!dueAfter){
                toDo.is.due = dayjs().isAfter(dueAfter)
                logIfDebug({dueAfter:toDo.builder.dueAfter, isDue:toDo.is.due})
            }
            if (toDo.is.due){
                logIfDebug({'active toDo':toDo})
                if (toDo.hasOwnProperty('emails') && toDo.emails.length > 0) {
                    const lastEmailSent = toDo.emails.findIndex(e => `${e.STAGE}.${e.name}` === mortgage.lastFlowItem)
                    const nextIndex = lastEmailSent + 1
                    let nextEmail = toDo.emails[nextIndex]

                    if (nextEmail) {

                            logIfDebug('&&&&&&&&&&&&&&&&&&')
                            logIfDebug({nextEmail})

                        if (nextEmail.hasOwnProperty('dueAfter')) {
                            let shouldShowOn = dayjs(mortgage.lastFlowItemTime).add(nextEmail.dueAfter, 'days')

                            logIfDebug({shouldShowOn})

                            if (shouldShowOn.isAfter(dayjs())) {
                                toDo.is.due = false
                            }
                        }
                        if (toDo.is.due){
                            toDo.nextItem = nextEmail
                        }
                    }
                    else {
                        toDo.is.due = false
                    }
                }
            }
        }
        toDos.push(toDo)
    }
    const currentToDo = toDos.find(toDo=>{return !!toDo.is.due})
    return {toDos,currentToDo}

}
export const isStepDone = (mortgage, STAGE, stepName) => {
    let history = mortgage.stageTasks.find(h => h.stage === STAGE && h.name === stepName)
    let done = false
    if (history) {
        done = {on: history.completedOn, by: history.completedBy}
    }
    return done
}

export const expandToDoNames = (step, toDo, STAGE) => {
    const expandedEmails = (toDo.emails || []).map(email=>{
        if (Array.isArray(email)){
            return email.map(groupEmail=>{
                return {
                    ...groupEmail,
                    name: `${step.name}.email.${groupEmail.name}`,
                    STAGE
                }
            })
        }
        else{
            return {
                ...email,
                name: `${step.name}.email.${email.name}`,
                STAGE
            }
        }
    })
    const expandedButtons = (toDo.buttons || []).map(button=>{
        return {
            ...button,
            name: `${step.name}.button`,
            STAGE
        }
    })
    return {
        ...toDo,
        name: step.name,
        key: `${step.name}[${toDo.mortgage.id}]`,
        emails: expandedEmails,
        buttons: expandedButtons,
    }
}
