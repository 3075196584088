import React, {useContext, useEffect, useState} from 'react';
import {BackendUsers} from "../../../providers/BackendUsersProvider";
import {Button, Checkbox, DatePicker, Form, Input, Select, Skeleton} from "antd";
import {useNavigate} from "react-router-dom";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {Auth} from "aws-amplify";
import dayjs from "dayjs";
import {FileAddOutlined, LoadingOutlined} from "@ant-design/icons";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";

function TaskDrawerContent(props) {
    const backendUsers = useBackendUsers()
    const [form] = Form.useForm()
    const [state, setState] = useState({saving: false})
    const mortgage = useMortgage()
    const handleFormFinish = async (e) => {
        const valid = await form.validateFields()
        const user = await Auth.currentAuthenticatedUser()
        if (valid) {
            setState(s => ({...s, saving: true}))

            const pairs = {
                author: user.attributes.sub,
                target: valid.taskFor,
                currentOwner: valid.taskFor,
                body: valid.taskText.split(/\r?\n/),
                userSortPosition: JSON.stringify({[valid.taskFor]: 0.95}),
                usermortgageID: mortgage.id,
                pinned: valid.pinned || false,
                due: valid.taskDue.toISOString()
            }
            if (props?.id) {
                await mortgage.mutate({
                    update: {
                        target: `tasks[${props.id}]`,
                        pairs
                    }
                })
            }
            else{
                pairs.completedBy= "null"
                await mortgage.mutate({
                    create: {
                        target: 'tasks',
                        pairs
                    }
                })
            }
            setState(s => ({...s, saving: false}))
            form.resetFields()
            props.close()
        }
    }
    const disabledDateTime = () => ({
        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 5, 19, 20, 21, 22, 23],
        disabledMinutes: () => range(0, 60).filter(i => i % 10 !== 0),
    });
    const range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };
    useEffect(() => {
        if (props.id) {
            const item = mortgage.tasks.find(task => task.id === props.id)
            const initialValues = {
                taskText: item.body.join('\n'),
                taskFor: item.target,
                taskDue: dayjs(item.due),
                pinned: item.pinned
            }
            form.setFieldsValue(initialValues)
        }
    }, [props.id, mortgage.tasks, form]);

    return (
        <div className="pt-15 pe-15">
            <Skeleton loading={!mortgage || !backendUsers.users}>
                <Form
                    key={`TaskWriter${props?.id}`}
                    form={form}
                    requiredMark={false}
                    onFinish={handleFormFinish}>
                    <Form.Item
                        name="taskText"
                        rules={[{required: true, message: `A note is required`}]}
                        className="mb-6">
                        <Input.TextArea placeholder="Create a task..." rows={12}/>
                    </Form.Item>
                    <div className="d-row gap-12 a-center j-end">
                        <Form.Item
                            rules={[{required: true, message: `A note is required`}]}
                            name="taskFor"
                            label="For"
                            className="mb-0 grow">
                            <Select>
                                {!!backendUsers.users && backendUsers.users.map(u =>
                                    <Select.Option value={u.sub} key={`${u.sub}-task`}>
                                        {u.firstName}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            rules={[{required: true, message: `A note is required`}]}
                            name="taskDue"
                            label="Due"
                            className="mb-0">
                            <DatePicker
                                format={"D MMM YYYY HH:mm"}
                                disabledTime={disabledDateTime}
                                showTime={{
                                    format: 'HH:mm',
                                    hideDisabledOptions: true,
                                }}

                            />
                        </Form.Item>
                    </div>
                    <div className="d-row a-center j-end mt-12 gap-12 a-center">
                        <Form.Item
                            name="pinned"
                            valuePropName="checked"
                            className="mb-0"
                        >
                            <Checkbox>Pin</Checkbox>
                        </Form.Item>
                        <Form.Item className="mb-0">
                            <Button type="primary" htmlType="submit">
                                {!state.saving && <FileAddOutlined/>}
                                {state.saving && <LoadingOutlined/>}
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Skeleton>
        </div>

    );
}

export default TaskDrawerContent;