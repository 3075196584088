import {addBunchToMap} from "../overlay-utils";

export default function (mortgage, config) {
    const applicantNames = () => {
        let map = {}
        let errors=[]
        let app1Name = mortgage.applicant1.fullName
        if (!app1Name) errors.push('No applicant 1 name')
        map['applicant-name-1'] = app1Name
        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.fullName
            if (!app2Name) errors.push('No applicant 2 name')
            map['applicant-name-2'] = app2Name
        }
        return {map, errors}
    }
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let ask = []
        let errors=[]
        ask.push({
            label: 'Mortgage Account Sort Code',
            name: 'mortgageLoanSortCode',
            type: 'TEXT',
            group: 'DOCUMENT CONFIG'
        })
        const digits = mortgage.requirement.mortgageAccountNo
        if (!digits) errors.push('No mortgage account number')
        map['mortgage-account-number'] = ''
        if (config.hasOwnProperty('mortgageLoanSortCode')) {
            map['mortgage-account-number'] = config.mortgageLoanSortCode + '      '
        }
        map['mortgage-account-number'] += digits
        return {map, ask, errors}
    }

    const todaysDate = () => {
        let map = {}
        let day = new Date().getDate().toString().padStart(2, '0').split('')
        map['day-1'] = day[0]
        map['day-2'] = day[1]

        let month = (new Date().getMonth() + 1).toString().padStart(2, '0').split('')
        map['month-1'] = month[0]
        map['month-2'] = month[1]

        let year = new Date().getFullYear().toString().split('')
        map['year-1'] = year[2]
        map['year-2'] = year[3]
        return {map}
    }

    const sections = [
        applicantNames(),
        mortgageLoanAccountNumber(),
        todaysDate()
    ]
    return {
        sections
    }
}