import {addBunchToMap} from "../overlay-utils";
const elements=[
        {
            "name": "applicant-name-1",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-1",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-2",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-2",
            "type": "TEXT"
        },
        {
            "name": "broker-company-name",
            "type": "TEXT"
        },
        {
            "name": "broker-name",
            "type": "TEXT"
        },
        {
            "name": "customer-met-face-to-face-no",
            "type": "CHECK"
        },
        {
            "name": "customer-met-face-to-face-yes",
            "type": "CHECK"
        },
        {
            "name": "loan-account-number-1",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-2",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-3",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-4",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-5",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-6",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-7",
            "type": "TEXT"
        },
        {
            "name": "loan-account-number-8",
            "type": "TEXT"
        }
    ]
export default function (mortgage, config) {
    const applicantNames = () => {
        let map = {}
        let errors=[]
        let app1Name = mortgage.applicant1.fullName
        if (!app1Name) errors.push('No applicant 1 name')
        map['applicant-name-1'] = app1Name.toUpperCase()
        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.fullName
            if (!app2Name) errors.push('No applicant 2 name')
            map['applicant-name-2'] = app2Name.toUpperCase()
        }
        return {map,errors}
    }
    const metCustomer = () => {
        let map = {}
        //const truey =  config.hasOwnProperty('metCustomer') && !!config.metCustomer
        map['customer-met-face-to-face-yes'] = true
        map['customer-met-face-to-face-no'] = false
        return {map}
    }
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let ask = []
        const digits = config.hasOwnProperty('mortgageLoanAccountNumber') ? config.mortgageLoanAccountNumber : mortgage.requirement.mortgageAccountNo
        ask.push({
            label: 'Mortgage Loan Account Number',
            name: 'mortgageLoanAccountNumber',
            type: 'TEXT',
            group: 'ACCOUNT NUMBER'
        })
        if (digits){
            addBunchToMap(map, 'mortgage-account-', digits.split(''))
        }
        return {map,ask}
    }
    const brokerDetails = () => {
        let map = {}
        map['broker-company-name'] = 'Truss Mortgage Services Ltd T/A Suss\'d'.toUpperCase()
        map['broker-name'] = 'Kevin Coakley'.toUpperCase()
        return {map}
    }
    const sections = [
        applicantNames(),
        brokerDetails(),
        mortgageLoanAccountNumber(),
        metCustomer(),
    ]
    return {sections}

}