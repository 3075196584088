import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useS3 from "../../providers/useS3";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import PdfCoordinates from "./PDFCoordinates";
import {Card, Skeleton} from "antd";
import {listAllAndSetItems} from "../../api-functions/functions";
import {adminPdfOverlayTemplateItemsByTemplateId} from "../../../graphql/queries";
import {hyphenToCamelCase} from "../../../assets/scripts/string-functions";

LenderSourceDocOverlay.propTypes = {
    
};

function LenderSourceDocOverlay(props) {
    const [url,setUrl] = useState(false)
    const [points,setPoints] = useState([])
    const s3 = useS3()

    const setExpandedPoints = (items)=>{
        setPoints(s=>([...s, ...items.filter(it=>!it._deleted).map(item=>{
            return {
                ...item,
                style: JSON.parse(item.style)
            }
        })]))
    }
    useEffect(() => {
        if (props.file){
            setPoints([]);
            (async function(){
                listAllAndSetItems({templateId: props.file.id}, adminPdfOverlayTemplateItemsByTemplateId, setExpandedPoints)
                const result = await s3.getS3Url({path: props.file.bucketKey, config: {
                    contentType: 'application/pdf',
                    level: "private",
                    identityId: SYSTEMIDENTITYID
                }})
                setUrl(result)
            })()
        }
    }, [props.file]);
    if (!props.file){
        return null
    }
    if (!url){
        return <Skeleton loading active/>
    }


    const map = {
        elements:[],
        id: props.file.id,
    }
    points.sort((a, b) => {
        if (a.pageNo < b.pageNo) {return -1}
        if (a.pageNo > b.pageNo) {return 1}

        const nameA = a.name
        const nameB = b.name
        if (nameA < nameB) {return -1}
        if (nameA > nameB) {return 1}
        return 0;
    }).forEach(point=>{
        map.elements.push( {
            name: point.name,
            type: point.type,
            page: point.pageNo,
        })
    })
    return (
        <div className="d-col">
            <PdfCoordinates key={`coord${props.file.id}`} url={url} id={props.file.id} points={points} />
        <Card>


            <pre>
                const elements =
            {
                JSON.stringify(map, null, 2)
            }
            </pre>
        </Card>
        </div>

    )
}

export default LenderSourceDocOverlay;