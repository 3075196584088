import React, {useMemo, useState} from 'react';
import {Button, Form, Card} from "antd";
import PropTypes from "prop-types";
import SectionQuestion from "../client/application/forms/SectionQuestion";
import {LoadingOutlined} from "@ant-design/icons";
import {filterObjectForNull} from "../../../assets/scripts/functions";
import {correctValueForDb, correctValueForSys, getItemFromPath} from "../../providers/mortgage/MortgageProvider";
import useMortgage from "../../providers/mortgage/useMortgage";
import {red} from "@ant-design/colors";

MortgageForm.propTypes = {
        questions: PropTypes.array.isRequired,
}
function MortgageForm(props) {
        const mortgage = useMortgage()
        const [form] = Form.useForm();
        const [state, setState] = useState({
                saving : false,
                altered: false
        })
        const initialValues = useMemo(() => {
                const iV = {}
                const processQuestion = (question) => {
                        try {
                                let val = getItemFromPath(mortgage, question.target)
                                iV[question.name] = correctValueForSys(mortgage, question, val)
                        }
                        catch (e) {
                                console.log('!! NO DATA PRESENT FOR: ')
                        }
                }
                props.questions.forEach(question => {
                        if (Array.isArray(question)) {
                                question.forEach(q => {
                                        processQuestion(q)
                                })
                        }
                        else {
                                processQuestion(question)
                        }
                })

                return iV
        }, [mortgage])

        const checkIfAltered = (field, myForm) => {
                if (JSON.stringify(myForm) !== JSON.stringify(initialValues)) {
                        setState({
                                ...state,
                                altered: true
                        })
                }
                else {
                        setState({
                                ...state,
                                altered: false
                        })
                }
                let name = Object.keys(field)[0]
                let allQuestions = props.questions.flatMap(q => {
                        if (Array.isArray(q)) {
                                return q
                        }
                        return [q]
                })
                let quest = allQuestions.find(q => {
                        return q.name === name
                })
                if (!!quest.answer.onChange) {
                        quest.answer.onChange(form)
                }
        }

        const handleFinish = async () => {
                try {
                        const valid = await form.validateFields()
                        const object = valid //filterObjectForNull(valid)

                        if (Object.keys(object).length > 0) {
                                let updates = []
                                for (let [key, uiValue] of Object.entries(object)) {
                                        let allQuestions = props.questions.flatMap(q => {
                                                if (Array.isArray(q)) {
                                                        return q
                                                }
                                                return [q]
                                        })
                                        let question = allQuestions.find(q => {
                                                return q.name === key
                                        })
                                        if (question) {

                                                let value = uiValue === null || uiValue === "" ? null : correctValueForDb(question.answer.type, uiValue)

                                                updates.push({
                                                        target: question.target,
                                                        value
                                                })
                                                if (!!question.additionalMutation) {
                                                        let {update} = question.additionalMutation(valid)
                                                        if (update) {
                                                                if (Array.isArray(update)) {
                                                                        update.forEach(u => updates.push(u))
                                                                }
                                                                else {
                                                                        updates.push(update)
                                                                }
                                                        }
                                                }
                                                if (!!question.answer.copyTarget) {
                                                        updates.push({
                                                                target: question.answer.copyTarget,
                                                                value
                                                        })
                                                }
                                        }

                                }
                                let mutation = {
                                        update: updates
                                }

                                mortgage.mutate(mutation)
                                setState({
                                        ...state,
                                        altered: false
                                })
                        }
                }
                catch (e) {
                        console.log({e})
                }
        }

        const questions = useMemo(() => {
                const renderQuestion = (question) => {
                        if (question?.custom) {
                                return question.custom
                        }
                        return <SectionQuestion
                        onBlur={() => {
                        }}
                        key={question.name + question.text}
                        question={question}
                        />
                }

                return props.questions.map(question => {
                        if (Array.isArray(question)) {
                                return (<div key={question[0].text + question[0].label + 'combined row'} className="d-row gap-9 j-equal">
                                        {question.map((q, i) => renderQuestion(q))}
                                </div>)
                        }
                        return renderQuestion(question)
                })
        }, [props.questions])

        const style = state.altered ? {backgroundColor: 'rgba(255, 204, 199, 0.5)'} : {}

        if (!props.title) {
                return <Form {...props.formProps || {}} form={form} onFinish={handleFinish} initialValues={initialValues}
                             onValuesChange={checkIfAltered}>
                        <div style={{
                                height         : 3,
                                backgroundColor: state.altered ? red.primary : 'transparent'
                        }} className="mb-3"/>
                        {questions}
                        {state.altered && (<Button className="mb-9" onClick={e => e.stopPropagation()} htmlType="submit" danger>Save {state.saving && (<LoadingOutlined/>)}</Button>)}
                </Form>
        }

        return (<Card title={props.title} style={style}>
                <div className="d-row">
                        <Form {...props.formProps || {}} form={form} onFinish={handleFinish} initialValues={initialValues} style={props.style || {}}
                              onValuesChange={checkIfAltered}>
                                {questions}
                                {state.altered && (<Button onClick={e => e.stopPropagation()} htmlType="submit" danger>Save {state.saving && (<LoadingOutlined/>)}</Button>)}
                        </Form>
                </div>
        </Card>);

}

export default MortgageForm;