import React, {useContext, useEffect} from 'react';
import {Lenders} from "../LendersProvider";
import {MortgageTypes} from "../../../models";
function calcPMT(rate, nperiod, pv, fv, type) {
    if (!fv) {
        fv = 0;
    }
    if (!type) {
        type = 0;
    }
    if (rate === 0) {
        return -(pv + fv) / nperiod;
    }
    var pvif = Math.pow(1 + rate, nperiod);
    var pmt = rate / (pvif - 1) * -(pv * pvif + fv);
    if (type === 1) {
        pmt /= 1 + rate;
    }
    return pmt;
}

export function calcMonthlyPayment(required, term, rate) {
    let trueRate = parseFloat(rate) / 100 / 12;
    const pmt = calcPMT(trueRate, term * 12, required) * -1;
    return Math.round(pmt);
}
function useRates(props) {
    const {lenders} = useContext(Lenders)


    function ltv(loan, value) {
        // to 1 dec place
        return Math.round((loan / value) * 100 * 10) / 10
    }
    const addPromotionsToRates = (rates, {
        loan,
        switcher,
        term
    }) => {
        return rates.map(item => {
            // add offers to rates
            switch (item.lenderCode) {
                case 'avant':
                   // if (switcher) {
                        item.offers = {
                            cashback:{
                                name: 'Cashback',
                                label: 'Cashback',
                                value:  loan * 0.01,
                                description: `The product you have selected qualifies for  1% of Loan Cashback on Drawdown.`
                            }
                        }
                 //   }
                    break
                case 'haven':
                    let fiveGrandUnavailable = loan < 250000 || item.term === 0 || item?.conditions?.includes('BER B3 or better required') || item?.conditions?.includes('Green')
                    if (switcher) {
                        item.offers = {
                            cashback: {
                                name: 'Cashback',
                                title: `${fiveGrandUnavailable ? (3000).toEuro() : (5000).toEuro()} Cashback on Drawdown`,
                                value: fiveGrandUnavailable ? 3000 : 5000,
                                description: `The product you have selected qualifies for ${fiveGrandUnavailable ? (3000).toEuro() : (5000).toEuro()} Cashback on Drawdown.`
                            }
                        }
                    }
                    else {
                        if (!fiveGrandUnavailable) {
                            item.offers = {
                                cashback: {
                                    name: 'Cashback',
                                    title: '€5000 Cashback on Drawdown',
                                    value: 5000,
                                    description: `The product you have selected qualifies for ${(5000).toEuro()} Cashback on Drawdown.`
                                }
                            }
                        }
                    }
                    break
                case 'ptsb':
                    item.offers = {
                        monthlyCashback: {
                            name: 'Monthly Cashback',
                            title: '2% Cashback on monthly Mortgage Repayments from PTSB Explore Account',
                            value: Math.round(calcMonthlyPayment(loan, term, item.rate) * 0.02),
                            description: 'You will be making your mortgage repayments from a PTSB Explore Account so will qualify for 2% monthly cashback on your mortgage repayments. '
                        }
                    }
                    if (!item.product.includes('4 Year Fixed Rate New Business')) {
                        item.offers.cashback = {
                            name: 'Cashback',
                            title: '2% of Loan Cashback on Drawdown',
                            value: loan * 0.02,
                            description: 'The product you have selected qualifies for 2% cashback on drawdown.'
                        }
                    }
                    break
            }
            return item
        })
    }
    const getApprovedRates = (rates, propertyValue, loanRequired, loanTerm, green) => {
        if (!rates) {
            return []
        }
        const myLTV = ltv(loanRequired, propertyValue)

        let lastError = false


        const r = rates.filter(item => {
            if (!!item?.buyToLet) {
                lastError = 'BTL only'
                return false
            }
            if (myLTV > item.maxLTV) {

                lastError = 'Try decreasing the loan amount'

                return false
            }
            if (item?.minLoan && item.minLoan > loanRequired) {

                lastError = 'Try increasing the loan amount'

                return false
            }
            if (item?.maxLoan && item.maxLoan < loanRequired) {
                lastError = 'Try reducing the loan amount'

                return false
            }
            if (item?.minTerm && item.minTerm > loanTerm) {
                lastError = 'Try increasing the loan term'

                return false
            }
            if (item?.maxTerm && item.maxTerm < loanTerm) {
                lastError = 'Try decreasing the loan term'

                return false
            }
            if (!green && item.conditions && (item.conditions.includes('BER B3 or better required') || item.conditions.includes('Green'))) {

                return false
            }
            return true
        })

        if (!r.length > 0) {

            return {error: lastError}
        }
        // remove ltv matches that are higher than the lowest ltv match

        const objectified = {}
        r.forEach(item => {
            if (!objectified.hasOwnProperty(item.lenderCode)) {
                objectified[item.lenderCode] = {}
            }
            if (!objectified[item.lenderCode].hasOwnProperty(item.term)) {
                objectified[item.lenderCode][item.term] = []
            }
            objectified[item.lenderCode][item.term].push(item)
        })
        // filter out any results whose ltv is higher than the lowest unless the rate it better  in all lender/term combinations
        let revised = []

        Object.keys(objectified).forEach(lender => {
            Object.keys(objectified[lender]).forEach(term => {


                //first sort array by lowest rate at top and then by lowest ltv
                objectified[lender][term].sort((a, b) => {
                    if (a.rate === b.rate) {
                        return a.maxLTV - b.maxLTV
                    }
                    return a.rate - b.rate
                })


                //get the rate of lowest ltv and exclude any rates not as good from the same lender
                let lowestRate = objectified[lender][term].reduce((prev, curr) => {
                    if (prev.rate === curr.rate) {
                        return prev.maxLTV < curr.maxLTV ? prev : curr
                    }
                    return prev.rate < curr.rate ? prev : curr
                })
                revised.push(lowestRate)
            })
        })


        return revised

        //return r.length > 0 ? r : {error: lastError}
    }

    const getProductsForMortgage = (mortgage) => {
        let lender = mortgage?.activeSubmission?.lenderCode ? mortgage.activeSubmission.lenderCode : mortgage.requirement.lenderCode
        let figures =  mortgage?.activeSubmission?.provisionalXlsInput?.figures

        let propertyValue = figures ? figures.provisionalValue : mortgage.properties[0].propertyValue
        let loanRequired = figures ? figures.provisionalLoan : mortgage.requirement.loanRequired
        let loanTerm = figures ? figures.provisionalTerm : mortgage.requirement.loanTerm
        let ber = mortgage.properties[0].berScore
        let green = ber && (ber.startsWith('A') || ber.startsWith('B'))

        let rates = lenders.find(lr=>lr.lenderCode === lender)?.rates

        let approvedRates = getApprovedRates(rates, propertyValue, loanRequired, loanTerm, green)

        if (approvedRates.error) {
            return {error: approvedRates.error}
        }
        return addPromotionsToRates(approvedRates, {loan: loanRequired, switcher: mortgage.mortgageType === MortgageTypes.REFINANCE, term: loanTerm})
    }

    if (!lenders.length) {
        return {
            getProductsForMortgage: () => []
        }
    }
    return {
        getProductsForMortgage
    }
}

export default useRates;