import React from 'react';
import usePreApprovalAdminUploads from "./admin-uploads/usePreApprovalAdminUploads";
import SimpleDocumentUploadButton from "../../_common/uploads/simple/SimpleDocumentUploadButton";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import {CloseCircleOutlined, FilePdfOutlined, UploadOutlined} from "@ant-design/icons";
import {green, red} from "@ant-design/colors";
import {Button, Popconfirm, Table, Upload} from "antd";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function PreApprovedAdminUploads({sections}) {


    const mortgage = useMortgage()
    const {open} = useMortgageDocumentsService()
    const handleDelete = async (record) => {
        await mortgage.mutate({
            delete: {
                target: 'UserMortgageDocuments',
                id: record.uploaded.id
            }
        })
    }


    const columns = [
        {
            title: 'Name',
            render: (text, record) => {
                return capitaliseWords(record.name.replace(/-/g, ' '))
            },
            key: 'name'
        },
        {
            title: 'Folder',
            render: (text, record) => {
                return capitaliseWords(record.folder.replace(/-/g, ' '))
            },
            key: 'folder'
        },
        {
            title: 'Upload',
            key: 'upload',
            align: 'center',
            render: (text, record) => {
                if (record.uploaded) {
                    return (
                        <FilePdfOutlined style={{cursor:'pointer', color: green[5]}} onClick={() => open(record.uploaded)}>Open</FilePdfOutlined>
                    )
                }
                return <CloseCircleOutlined style={{color:red[5]}}/>
            }
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                if (!record.uploaded) return (<SimpleDocumentUploadButton name={record.name} folder={record.folder} stage={record.stage} isPrivate={true}/>)
                return <>
                    <Popconfirm title="Are you sure?" onConfirm={()=>{handleDelete(record)}}>
                        <Button danger size="small">Delete</Button>
                    </Popconfirm>

                </>
            },
        }
    ];
    return <Table columns={columns} dataSource={sections} pagination={false} />
}

export default PreApprovedAdminUploads;