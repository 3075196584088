import React, {useContext} from 'react';
import {Mortgages} from "../../../providers/MortgagesProvider";
import {Checkbox, Switch, Typography, Form} from "antd";
import {Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {ApplicationStages, ApplicationStatuses} from "../../../../models";

function RegistrationsByMonth(props) {
    const [includingSuspended, setIncludingSuspended] = React.useState(true)
    const [includingDelayed, setIncludingDelayed] = React.useState(true)
    const mortgages = useContext(Mortgages)
    const all = mortgages.ALL.filter(it=>it.applicationStatus !== ApplicationStatuses.OFFLINE)
    function getMonthsSinceOctober2023() {
        const startYear = 2023;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // Note: getMonth() returns 0 for January, 1 for February, etc.
        const months = [];
        for (let year = startYear; year <= currentYear; year++) {
            const startMonth = year === startYear ? 9 : 0; // Start from Oct for the first year
            const endMonth = year === currentYear ? currentMonth : 11; // Go up to the current month for the current year
            for (let month = startMonth; month <= endMonth; month++) {
                let monthName = new Date(year, month, 1).toLocaleString('default', { month: 'short', year: 'numeric' })
                let registrations = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0))
                let suspended = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort)=> mort.applicationStatus === ApplicationStatuses.SUSPENDED)
                let completed = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort)=> mort.applicationStatus === ApplicationStatuses.COMPLETED)
                let delayed = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort)=> mort.applicationStatus === ApplicationStatuses.DELAYED)
                let omitGroup = [ApplicationStatuses.SUSPENDED,ApplicationStatuses.COMPLETED, ApplicationStatuses.DELAYED]
                let applying = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort) => !omitGroup.includes(mort.applicationStatus) && [ApplicationStages.APPLY].includes(mort.applicationStage) )
                let settingUp = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort) => !omitGroup.includes(mort.applicationStatus) && [ApplicationStages.SETUP].includes(mort.applicationStage) )
                let approved = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort) => !omitGroup.includes(mort.applicationStatus) && [ApplicationStages.APPROVAL].includes(mort.applicationStage) )
                let submitted = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort) => !omitGroup.includes(mort.applicationStatus) && [ApplicationStages.SIGN].includes(mort.applicationStage) )
                let preApproved = getNumberOfRegistrationsBetweenDates(new Date(year, month, 1), new Date(year, month + 1, 0), (mort) => !omitGroup.includes(mort.applicationStatus) && [ApplicationStages.PREAPPROVAL].includes(mort.applicationStage) )

                let applying1 = registrations - (suspended + submitted + completed + approved + preApproved + delayed)
                if (applying1 !== applying + settingUp){
                    console.warn('Mismatched customer applying')
                }
                if (!includingSuspended){
                    registrations = registrations - suspended
                }
                if (!includingDelayed){
                    registrations = registrations - delayed
                }
                months.push({monthName,registrations,submitted,suspended, applying, approved,  completed, preApproved, delayed, settingUp})

            }
        }
        return months;
    }



    const getNumberOfRegistrationsBetweenDates = (startDate, endDate, includingTestFunction=false) => {
        const filteredMortgages = all.filter(mortgage => {
            const created = new Date(mortgage.createdAt);
            let include = created >= startDate && created <= endDate;
            if (!!includingTestFunction) {
                return include && includingTestFunction(mortgage)
            }
            return include
        });
        return filteredMortgages.length;
    }
    const data = getMonthsSinceOctober2023()

    return (
        <>
            <div className="d-row j-center"><Typography.Title>Registrations Per Month </Typography.Title></div>
            <div className="d-row j-center a-center gap-15">
                <Form.Item label="Suspended">
                    <Switch checked={includingSuspended} onChange={setIncludingSuspended} checkedChildren="Include" unCheckedChildren="Exclude"/>
                </Form.Item>
                <Form.Item label="Delayed">
                    <Switch checked={includingDelayed} onChange={setIncludingDelayed} checkedChildren="Include" unCheckedChildren="Exclude"/>
                </Form.Item>
            </div>
            <div className="d-row j-center">
                <AreaChart
                    width={1100}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="monthName"/>
                    <YAxis dataKey="registrations"/>
                    <Tooltip/>
                    <Legend/>
                    <Area type="monotone" dataKey="registrations" stackId="0" stroke="#93c4d6" fill="#93c4d6"/>
                    {includingSuspended && <Area type="monotone" dataKey="suspended" stackId="1" stroke="#ff2a00" fill="#ff2a00"/>}
                    {includingDelayed && <Area type="monotone" dataKey="delayed" stackId="1" stroke="#fb7b62" fill="#fb7b62"/>}
                    <Area type="monotone" dataKey="settingUp" stackId="1" stroke="#d6ab3d" fill="#d6ab3d" />
                    <Area type="monotone" dataKey="applying" stackId="1" stroke="#956f29" fill="#956f29"/>

                    <Area type="monotone" dataKey="submitted" stackId="1" stroke="#4491d8" fill="#4491d8"/>
                    <Area type="monotone" dataKey="preApproved" stackId="1" stroke="#5861d8" fill="#5861d8"/>
                    <Area type="monotone" dataKey="approved" stackId="1" stroke="#72dac2" fill="#72dac2"/>
                    <Area type="monotone" dataKey="completed" stackId="1" stroke="#19d829" fill="#19d829"/>
                </AreaChart>
            </div>
        </>
    );
}

export default RegistrationsByMonth;