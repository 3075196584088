import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {balanceOfFundsCosts} from "../../funding/BalanceOfFunds";
import {cyan} from "@ant-design/colors";
import {Table} from "antd";
import {TableHeading} from "../FiguresUsed";
import {MortgageTypes} from "../../../../../../../models";

function Costs({override={}}) {
    const mortgage = useMortgage()
    let {price, stampDuty, legalFees, propertyValuation,  totalCosts: totalCostSum} = balanceOfFundsCosts(mortgage, override)
    const costsDatasource = [
        {
            key: 'price',
            label: 'Property Price',
            value: price,
        },
        {
            key: 'legalFees',
            label: 'Legal Fees',
            value: legalFees,
        },
        {
            key: 'propertyValuation',
            label: 'Valuation',
            value: propertyValuation,
        }
    ]
    if (mortgage.mortgageType !== MortgageTypes.REFINANCE) {
        costsDatasource.push({
            key: 'stampDuty',
            label: 'Stamp Duty',
            value: stampDuty,
        })
    }
    const costColumns = [
        {
            key      : 'label',
            title    : '',
            dataIndex: 'label',
            className: 'align-top',
        },
        {
            key      : 'value',
            title    : '',
            dataIndex: 'value',
            align    : 'right',
            render   : (text) =>  text?.toLocaleString('en-us'),
        }
    ]

    return (
        <Table title={() => <TableHeading title="Costings" color={cyan.primary} total={totalCostSum}/>} size="small"  showHeader={false}
               dataSource={costsDatasource} columns={costColumns}
               pagination={false}/>
    );
}

export default Costs;