import React, {lazy, useEffect, useState} from 'react';
import SuitabilityLetter from "./SuitabilityLetter";
import useSussdPdfObjects from "../../../app/providers/useSussdPdfObjects";
import useMortgage from "../../../app/providers/mortgage/useMortgage";
import useRates from "../../../app/providers/mortgage/useRates";

function useSuitabilityLetter() {
    const mortgage = useMortgage();
    const {getProductsForMortgage} = useRates()

    const [key, setKey] = useState(0);
    const rates = getProductsForMortgage(mortgage)

    const reset = () => {
        setKey(prevKey => prevKey + 1);
    };


    return {
        ...useSussdPdfObjects(SuitabilityLetter, { mortgage, keyName:key, rates }),
        reset
    };
}

export default useSuitabilityLetter;