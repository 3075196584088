//import fill_haven_aipc from './haven/pdf-fills/approval-in-principle-checklist'
import haven_aipc from './haven/approval-in-principle-checklist'
import haven_daa from './haven/declaration-and-authorisations'
import haven_ddm from './haven/direct-debit-mandate'
import haven_iraf from './haven/interest-rate-application-form'
import ptsb_mac from './ptsb/mortgage-application-for-credit'
import ptsb_msc from './ptsb/minimum-standards-checklist'
import ptsb_sod from './ptsb/sight-of-original-declaration'
import ptsb_loc from './ptsb/loan-offer-confirmation'
import ptsb_pdp from './ptsb/pre-drawdown-paye'
import ptsb_pdse from './ptsb/pre-drawdown-self-employed'
import ptsb_arf from './ptsb/amendment-request-form'
import ptsb_ddm from './ptsb/direct-debit-mandate'
import avant_maduc from './avant/mortgage-application-document-upload-checklist'
import avant_bc from './avant/broker-checklist'
import avant_doi from './avant/declaration-of-identity'
import avant_acn from './avant/pdf-fills/application-cover-note'
import avant_ddm from './avant/pdf-fills/direct-debit-mandate'
//import haven_cn1app from './haven/cover-note-1-app'
//import haven_cn2apps from './haven/cover-note-2-apps'
//import ptsb_cn1app from './ptsb/cover-note-1-app'
//import ptsb_cn2apps from './ptsb/cover-note-2-apps'
import haven_cbf from './haven/cashback-form'
import haven_loc from './haven/loan-offer-checklist'
import haven_doa from './haven/deed-of-assignment'
//import sussd_cnp12apps from './sussd/cover-note-page-1-2apps'
//import sussd_cnp11app from './sussd/cover-note-page-1-1app'
const templates = {
    haven:{
        'approval-in-principle-checklist': haven_aipc,
        'declaration-and-authorisations': haven_daa,
        'direct-debit-mandate': haven_ddm,
        'interest-rate-application-form': haven_iraf,
       // 'cover-note-1-app': haven_cn1app,
      //  'cover-note-2-apps': haven_cn2apps,
        'cashback-form': haven_cbf,
        'loan-offer-checklist': haven_loc,
        'deed-of-assignment': haven_doa
    },
    ptsb:{
        'mortgage-application-for-credit': ptsb_mac,
        'minimum-standards-checklist': ptsb_msc,
        'sight-of-original-declaration': ptsb_sod,
        'loan-offer-confirmation': ptsb_loc,
        'pre-drawdown-paye': ptsb_pdp,
        'pre-drawdown-self-employed': ptsb_pdse,
        'direct-debit-mandate': ptsb_ddm,
     //   'cover-note-1-app': ptsb_cn1app,
     //   'cover-note-2-apps': ptsb_cn2apps,
        'amendment-request-form': ptsb_arf
    },
    avant:{
        'mortgage-application-document-upload-checklist': avant_maduc,
        'broker-checklist': avant_bc,
        'declaration-of-identity': avant_doi,
        'application-cover-note': avant_acn,
        'direct-debit-mandate': avant_ddm
    },
    sussd:{
    //    'cover-note-page-1-2apps': sussd_cnp12apps,
   //     'cover-note-page-1-1app': sussd_cnp11app,
    }
}
export default templates