import baseRowModel, {makeChainable} from "../../../system/model/row/baseRowModel";
import {updateUserApplicant} from "../../../../graphql/mutations";
import {onUpdateUserApplicant} from "../../../../graphql/subscriptions";
import parse from "graphql"
function applicant({
    request,
    initialState
})
{
    const attribs = {
        writable : {
            firstName: 'String',
            surname: 'String',
            isMale: 'Boolean'
        },
        readable:[
            'email'
        ]
    }
    const graphQl = {
        mutation: {
            update: updateUserApplicant,
        },
        subscription: {
            update: onUpdateUserApplicant,
        }
    }

    let model = baseRowModel({
        request,
        initialState,
        graphQl,
        attribs
    })
    let fields = model.fields
    return fields

}
export default applicant