import React from 'react';
import {Button} from "antd";
import useSussdPdf, {gridCell, green} from "../../providers/useSussdPdf";

function PdfGen(props) {

    const {initialise, save} = useSussdPdf()

    const gen = async () => {
        const pdfDoc = await initialise()
        const page = await pdfDoc.addPage()
        await page.add.header('Cover Note Here')
        await page.add.border()
        await page.add.grid([
            [
                gridCell('Heading 1', {color: green, textAlign: 'center', fontWeight:'bold'}),
                gridCell('Heading 2',{color: green, textAlign: 'center', fontWeight:'bold'}),
                gridCell('Heading 3',{color: green, textAlign: 'center', fontWeight:'bold'})
            ],
            [
                gridCell('Application Type:', {color: green}),
                gridCell({
                    type: 'grid',
                    rows:[
                        [
                            gridCell({type:'check',label:'Yes',checked: true},{color: green}),
                            gridCell({type:'check',label:'No',checked: false},{color: green }),
                            gridCell({type:'check',label:'Maybe',checked: false},{color: green, textAlign: 'right', fontWeight:'bold'})
                        ],
                        [
                            gridCell({type:'check',label:'Yes',checked: true},{color: green}),
                            gridCell({type:'check',label:'No',checked: false},{color: green }),
                            gridCell({type:'check',label:'Maybe',checked: false},{color: green, textAlign: 'right', fontWeight:'bold'})
                        ],
                        [
                            gridCell({type:'check',label:'Yes',checked: true},{color: green}),
                            gridCell({type:'check',label:'No',checked: false},{color: green }),
                            gridCell({type:'check',label:'Maybe',checked: false},{color: green, textAlign: 'right', fontWeight:'bold'})
                        ],
                        [
                            gridCell({type:'check',label:'Yes',checked: true},{color: green}),
                            gridCell({type:'check',label:'No',checked: false},{color: green }),
                            gridCell({type:'check',label:'Maybe',checked: false},{color: green, textAlign: 'right', fontWeight:'bold'})
                        ],
                    ],
                    columnGrid: [8, 8, 8]
                }),
                gridCell({
                    type: 'grid',
                    rows:[
                        [
                            gridCell({type:'check',label:'Yes',checked: true},{color: green, fontWeight:'bold' }),
                            gridCell({type:'check',label:'No',checked: false},{color: green,  fontWeight:'bold' }),
                            gridCell({type:'check',label:'Maybe',checked: false},{color: green, textAlign: 'right', fontWeight:'bold'})
                        ],
                    ],
                    columnGrid: [8, 8, 8]
                }),
            ],
            [
                gridCell('Name:',{color: green}),
                gridCell('Stephen Breslin'),
                gridCell('Mary Rourke')
            ],
            [
                gridCell('Age:',{color: green}),
                gridCell('24'),
                gridCell('25')
            ],

        ], {columnGrid: [6, 9, 9], verticalSeparators: true})
        await page.add.border()
        await save()
    }


    return (
        <div>

            <Button onClick={gen}>Generate</Button>

        </div>
    );
}

export default PdfGen;