export const chatGptMessages = {
    customer:{
        email: {
            compose: (itemsToConvey, caseDetails) =>([
                {
                    role   : "system",
                    content: `You are an AI assistant with indepth expect knowledge about the Irish Mortgage Market. You specialize in drafting friendly, professional and concise email responses. `
                },
                {
                    role   : "user",
                    content: `Please compose an email in which I need the following ideas:\n\n ${itemsToConvey}\n\nWhich I need to be conveyed  to this customer:\n\n${caseDetails}\n\n `
                },
            ]),
            reply  : (emailContent, caseDetails) => ([
                {
                    role   : "system",
                    content: `You are an AI assistant with indepth expect knowledge about the Irish Mortgage Market. You specialize in drafting friendly, professional and concise email responses. If and where appropriate you should incorporate case-specific details into your replies to ensure they are accurate and helpful.`
                },
                {
                    role   : "user",
                    content: `Here is the email to respond to:\n\n"${emailContent}"\n\nAnd here is the progress information about the sender's case:\n\n"${caseDetails}"\n\nPlease write a response that addresses their inquiry and incorporates the case details where relevant.`
                },
            ])
        },
    }
}