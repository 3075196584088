import React, {useEffect} from 'react';
import {App, Descriptions, Form} from "antd";
import {green, volcano} from "@ant-design/colors";
import {getDataSent, getInitialValues, getInitialValuesNew} from "../../../../../../assets/scripts/lender-calculators";

export function useXlsNew(api, getProcessedResults, mortgage) {
    const getConfig = (figures) => {
        return getInitialValuesNew(api.dataPoints, figures)
    }
    const test = async (config, shouldSaveOutput = false) => {
        let results = await api.get(config, shouldSaveOutput)
        if (results.error) {
            return {
                error: results.error
            }
        }
        else {
            const processedResults = getProcessedResults(results.data)
            const spreadsheetUrl = results.spreadSheetUrl ? results.spreadSheetUrl.trim() : null
            return {
                results,
                processedResults,
                spreadsheetUrl
            }
        }
    }
    const [form] = Form.useForm();
    const app = App.useApp()
    const setForm = (figures) => {
        let values = getInitialValuesNew(api.dataPoints, figures)
        form.setFieldsValue(values)
    }
    const setAnswer = (key, value) => {
        app.notification.error({message: 'Not allowed anymore'})
    }
    return {
        setAnswer,
        setForm,
        form,
        test,
        getConfig,
        dataPoints: api.dataPoints
    }
}

function useXls(api, getProcessedResults, mortgage) {
    const app = App.useApp()
    const [playgroundData, setPlaygroundData] = React.useState({})

    const {unverified: claimed, verified} = mortgage.figures

    const [form] = Form.useForm();
    const [state, setState] = React.useState({
        results: null,
        loading: false,
        error  : null,
        values : {}
    })
    const setAnswer = (key, value) => {
        // const keys = key.split(':')
        // const coord = keys.pop()
        const coord = key
        setState(s => ({
            ...s,
            values: {
                ...s.values,
                [coord]: value
            }
        }))
    }

    const forceSet = ({
        results,
        values
    }) => {
        setState(s => ({
            ...s,
            results,
            values
        }))
        form.setFieldsValue(values)
    }
    const getResults = async () => {
        setState(s => ({
            ...s,
            loading: true
        }))
        try {
            const results = await api.get(state.values)
            if (results.error) {
                setState(s => ({
                    ...s,
                    error  : results.error,
                    loading: false
                }))
            }
            else {
                const processedResults = getProcessedResults(results.data)
                setState(s => ({
                    ...s,
                    results: processedResults,
                    loading: false
                }))
                return processedResults
            }
        }
        catch (error) {
            setState(s => ({
                ...s,
                error,
                loading: false
            }))
        }
    }
    const testPassed = async (data) => {
        let {results} = getTestResult(data)
        return results.every(item => !item.hasOwnProperty('passed') || item.passed)
    }
    const getTestResult = async (data) => {
        console.log({data})
        const values = getInitialValues(api.dataPoints, data)
        const sent = getDataSent(api.dataPoints, data)
        let response = await api.get(values)
        if (response.error) {
            throw new Error(response.error)
        }
        return {
            sent,
            results: getProcessedResults(response.data)
        }
    }
    const adjustValues = (data, adjustment) => {
        let orig = {...data}
        Object.keys(adjustment).forEach(key => {
            switch (key) {
                case "withVariable":
                    if (!adjustment[key]) { // if not with variable (default)

                        // set all variables to 0
                        data.overtime1 = 0
                        data.overtime2 = 0
                        data.allowances1 = 0
                        data.allowances2 = 0
                        data.bonus1 = 0
                        data.bonus2 = 0
                        data.other1 = 0
                        data.other2 = 0
                        data.commission1 = 0
                        data.commission2 = 0
                        data.hasOtherIncome1 = false
                        data.hasOtherIncome2 = false
                    }
                    break
                case "withClearingAllLoans":
                    if (adjustment[key]) { // if not with clearing loans (default)
                        data.clearingLoans1 += data.loans1
                        data.clearingLoans2 += data.loans2
                        data.loans1 = 0
                        data.loans2 = 0
                    }
                    break
                default:
            }
        })
    }
    function StringToDivs({ text }) {
        const lines = text.split('\n');
        return (
            <div>
                {lines.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        );
    }
    const doTest = async (utem) => {
        let {useVerifiedFigures, ...item} = utem
        let data = useVerifiedFigures ? verified : claimed
        let slimData = {}
        for (let key in data) {
            slimData[key] = data[key].total
            // if (Array.isArray(data[key].value)) {
            //     slimData[key] = arrayValue(data[key].value)
            // }
        }
        if (item.options) {
           adjustValues(slimData, item.options)
        }


        const values = getInitialValues(api.dataPoints, {
            ...slimData,
            term          : item.term,
            propertyValue : item.value,
            mortgageAmount: item.loan,
        })


        const sent = getDataSent(api.dataPoints, slimData)

        let response = await api.get(values)
        if (response.error) {
            throw new Error(response.error)
        }
        return {
            sent,
            formData: slimData,
            results: getProcessedResults(response.data)
        }
    }

    const resultComponent = () => {
        const items = (!!state.results ? state.results : []).map(item => {
            return {
                key     : item.key,
                label   : item.label,
                children: (<div className="d-row j-end a-center">
                        {!!item.hasOwnProperty('passed') && (<span style={{
                                marginRight    : 'auto',
                                display        : "inline-block",
                                backgroundColor: item.passed ? green.primary : volcano.primary,
                                height         : 10,
                                width          : 10,
                                borderRadius   : 5
                            }}/>)}
                        <span style={{marginLeft: 8}}>{item.value}</span>


                    </div>)
            }

        })

        return (<Descriptions style={{minWidth: 730}} size="small" column={2} bordered items={items}/>)
    }

    useEffect(() => {

        const values = getInitialValues(api.dataPoints, playgroundData)

        setState(s => {
            return {
                ...s,
                values
            }
        })

        form.setFieldsValue(values)
    }, [playgroundData]);

    // update the initialValue with the playgroundData
    const updateLiveAnswer = (formData, obj) => {
        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                obj[key].forEach(item => {
                    if (item.getFormValue && Object.keys(formData).length > 0) {
                        item.defaultValue = item.getFormValue(formData);
                    }
                });
            }
            else if (typeof obj[key] === 'object' && obj[key] !== null) {
                updateLiveAnswer(formData, obj[key]);
            }
        }
    }
    const dataPoints = {...api.dataPoints}
    updateLiveAnswer(playgroundData, dataPoints);

    return {
        api,
        forceSet,
        dataPoints,
        setAnswer,
        getResults,
        resultComponent,
        state,
        allPassed: state.results?.every(item => !item.hasOwnProperty('passed') || item.passed),
        form,
        setPlaygroundData,
        playgroundData,
        testPassed,
        getTestResult,
        doTest,
    }
}
export default useXls;