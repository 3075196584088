import React from 'react';
import MortgageForm from "../../../../data-capture/MortgageForm";
import {getQuestionsPercentCompleted} from "../../../../../providers/mortgage/useApplicationForms";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Alert, Card} from "antd";

function Solicitor(props) {
    const mortgage = useMortgage()
    const questions = [
        {
            name: 'firmName',
            label: 'Firm Name',
            text: "Firm Name",
            answer: {
                type: "text",
            },
            model: "solicitor",
            rules: [],
            target: `solicitor.firmName`
        },
        {
            name: 'firmAddress',
            label: 'Firm Address',
            text: "Firm Address",
            answer: {
                type: "address",
            },
            model: "solicitor",
            rules: [],
            target: `solicitor.firmAddress`
        },
        {
            name: 'contactName',
            label: 'Contact Name',
            text: "Contact Name",
            answer: {
                type: "text",
            },
            model: "solicitor",
            rules: [],
            target: `solicitor.contactName`
        },
        {
            name: 'contactEmail',
            label: 'Contact Email',
            text: "Contact Email",
            answer: {
                type: "email",
            },
            model: "solicitor",
            rules: [],
            target: `solicitor.contactEmail`
        },
        {
            name: 'contactPhone',
            label: 'Contact Phone',
            text: "Contact Phone",
            answer: {
                type: "phone",
            },
            model: "solicitor",
            rules: [],
            target: `solicitor.contactPhone`
        },
    ]


    let percent = getQuestionsPercentCompleted(mortgage, questions)

    return (
        <Card>
            {
                percent!==100 && (<Alert className="mb-15" message={"Solicitor details missing"} type="warning"/>)
            }
            {
                percent===100 && (<Alert className="mb-15" message={"Solicitor details completed"} type="success"/>)
            }
            <MortgageForm questions={questions} title="Solicitor Details"/>
        </Card>
    )
}

export default Solicitor;