import React, {useContext, useEffect, useRef, useState} from 'react';
import getInitialMortgages, {
    onCreateAdminMortgageTasks,
    onCreateUserApplicant,
    onCreateUserMortgage,
    onCreateUserMortgageRequirement,
    onCreateUserMortgageSubmission,
    onDeleteAdminMortgageTasks,
    onDeleteUserApplicant,
    onDeleteUserMortgage,
    onDeleteUserMortgageRequirement, onDeleteUserMortgageSubmission,
    onUpdateAdminMortgageTasks,
    onUpdateUserApplicant,
    onUpdateUserApplicantLastHere,
    onUpdateUserMortgage,
    onUpdateUserMortgageRequirement,
    onUpdateUserMortgageSubmission,
} from "../api-functions/mortgages/getInitialMortgages";
import {ApplicationStages, ApplicationStatuses} from "../../models";
import useSubscription from "./useSubscription";
import {useNavigate} from "react-router-dom";
import {listAllAndSetItems, performGQL} from "../api-functions/functions";
import {adminMortgageTasksByUsermortgageID, userMortgageSubmissionsByMortgageID} from "../../graphql/queries";
import {ActiveMortgagesStageTasks} from "./ActiveMortgagesStageTasksProvider";
import dayjs from "dayjs";

export const Mortgages = React.createContext()
export const categories = [
    {
        to: 'INCOMPLETE',
        key: 'INCOMPLETE',
        label: 'Incomplete',
        color: 'gray'
    },
    {
        to: 'NEW',
        key: 'NEW',
        label: 'New',
        color: 'volcano'
    },
    {
        to: 'SETUP',
        key: 'SETUP',
        label: 'Setup',
        color: 'pink'
    },
    {
        to: 'APPLY',
        key: 'APPLY',
        label: 'Apply',
        color: 'pink'
    },
    {
        to: 'SIGN',
        key: 'SIGN',
        label: 'Signing',
        color: 'lime'
    },
    {
        to: 'PREAPPROVAL',
        key: 'PREAPPROVAL',
        label: 'Approved in Principle',
        color: 'gold'
    },
    {
        to: 'APPROVAL',
        key: 'APPROVAL',
        label: 'Approved',
        color: 'orange'
    },
    {
        to: 'DELAYED',
        key: 'DELAYED',
        label: 'Delayed',
        color: 'yellow'
    },
    {
        to: 'OFFLINE',
        key: 'OFFLINE',
        label: 'Offline Leads',
        color: 'yellow'
    },
    {
        to: 'ACTIVE',
        key: 'ACTIVE',
        label: 'Active',
        color: 'blue'
    },
    {
        to: 'COMPLETED',
        key: 'COMPLETED',
        label: 'Completed',
        color: 'green'
    },
    {
        to: 'SUSPENDED',
        key: 'SUSPENDED',
        label: 'Suspended',
        color: 'geekblue'
    },
    {
        to: 'PROBLEM',
        key: 'PROBLEM',
        label: 'Problems',
        color: 'red'
    },
]
export const expandActiveSubmission = (submission) => {
   // try{
        let activeSubmission = {
            ...submission,
        }
        activeSubmission.loanOfferState = null
        if (activeSubmission.loanOfferExpiryDate){
            let loanOfferExpiry = dayjs(activeSubmission.loanOfferExpiryDate, 'YYYY-MM-DD')
            if (loanOfferExpiry.isBefore(dayjs())){
                activeSubmission.loanOfferState = 'expired'
            }
            else if (loanOfferExpiry.subtract(3, 'week').isBefore(dayjs())){
                activeSubmission.loanOfferState = 'expiring'
            }
        }

        return activeSubmission
    // }
    // catch (e) {
    //     console.log({submission, e})
    //
    // }


}
function MortgagesProvider(props) {
    const {history} = useContext(ActiveMortgagesStageTasks)
    const navigate = useNavigate()
    const [mortgageState, setMortgageState] = useState({
        mortgages: [],
        requirements: [],
        applicants: [],
        tasks: [],
        submissions: []
    })
    const [lastHereApplicants, setLastHereApplicants] = useState([])
    const expandMortgage = (item) => {
        // ADD APPLICANT TITLE
        if (item.applicant1 && item.applicant2) {
            let name1 = item.applicant1.firstName || 'Applicant 1'
            if (item.applicant1.surname) {
                name1 += ` ${item.applicant1.surname}`
            }
            let name2 = item.applicant2.firstName || 'Applicant 2'
            if (item.applicant2.surname) {
                name2 += ` ${item.applicant2.surname}`
            }
            let mortgageTitle = name1
            if (item.twoApplicants) {
                mortgageTitle += ` & ${name2}`
            }
            item.applicationTitle = mortgageTitle

            // ADD LAST HERE TO MORTAGAGE
            const getAppLastHere = (n) => {
                let index = `applicant${n}`
                let mortRecentApplicantTime = lastHereApplicants.find(it => it.id === item[index].userApplicantLastHereId)
                if (mortRecentApplicantTime) {
                    mortRecentApplicantTime = new Date(mortRecentApplicantTime.time)
                } else {
                    mortRecentApplicantTime = item[index].lastHereTime ? new Date(item[index].lastHereTime.time) : false
                }
                if (!mortRecentApplicantTime) {
                    mortRecentApplicantTime = item[index].lastHere ? new Date(item.applicant1.lastHere) : false
                }
                return mortRecentApplicantTime
            }

            let lh1 = getAppLastHere(1)
            let lh2 = getAppLastHere(2)
            const dates = [lh1, lh2].filter(Boolean);
            // Find the most recent date using Math.max() and the spread operator
            if (dates.length) {
                const mostRecentDate = new Date(Math.max(...dates));
                item.lastHere = mostRecentDate.toISOString()
            }

            // ADD AFFORDABILITY COMPLETE
            // let affordabilityComplete = false
           //  let d1 = JSON.parse(item.applicant1.data || '{}')
           //  let d2 = JSON.parse(item.applicant2.data || '{}')
            // if (item.twoApplicants) {
            //     affordabilityComplete = d1.hasOwnProperty('affordabilityComplete') && d2.hasOwnProperty('affordabilityComplete')
            // } else {
            //     affordabilityComplete = d1.hasOwnProperty('affordabilityComplete')
            // }
            return {
                ...item,
                // affordabilityComplete,
                completedFlowItems: JSON.parse(item.completedFlowItems || '{}'),
                completedFlowItemsForAdmin: JSON.parse(item.completedFlowItemsForAdmin || '{}'),
                applicant1: {
                    ...item.applicant1,
                    fullName: name1,
                    lastHere: lh1,
                },
                applicant2: {
                    ...item.applicant2,
                    fullName: name2,
                    lastHere: lh2,
                }
            }
        }
        return item
    }
    const addItemsToMortgageState = (items, existingState) => {
        let filtered = items.filter(item => !!item && !item._deleted)
        if (existingState.length > 0) {
            // already items in the state
            let copy = [...existingState]
            // ensure that the items in the state are not duplicated
            filtered.forEach(item => {
                const index = copy.findIndex(it => it.id === item.id)
                if (index >= 0) {
                    copy[index] = item
                } else {
                    copy.push(item)
                }
            })
            return copy
        } else {
            return filtered
        }
    }
    const setAndContinueToPopulate = (responseObject, operation, variables, settingFunction) => {
        const {items, nextToken} = responseObject
        if (nextToken) {
            listAllAndSetItems({
                ...variables,
                nextToken
            }, operation, settingFunction)
        }
        return items.filter(it => !it._deleted) || []
    }
    const tasksSettingFunction = (items) => {
        // sets the mortgage manually created tasks, so that their due date can be monitored
        setMortgageState(s => {
            return {
                ...s,
                tasks: [...s.tasks, ...items.filter(it => !it._deleted)]
            }
        })
    }
    const submissionsSettingFunction = (items) => {
        // sets the mortgage submissions, so that the most recent can be displayed
        setMortgageState(s => {
            return {
                ...s,
                submissions: [...s.submissions, ...items.filter(it => !it._deleted)]
            }
        })
    }

    const destructureMortgages = (items) => {
        // get the children of the mortgages and set them each in their own state variable which can be updates with subscriptions
        const mortgageOnlys = []
        const requirements = []
        const applicants = []
        const mortgageTasks = []
        const submissionsArray = []
        items.forEach(item => {
            const {applicant1, applicant2, requirement, tasks, submissions, ...mortgage} = item
            //let submissionsResult = submissions.items || []
            mortgageOnlys.push(mortgage)
            requirements.push(item.requirement)
            applicants.push(item.applicant1)
            applicants.push(item.applicant2)
            //submissionsArray.push(...submissionsResult)
            submissionsArray.push(...setAndContinueToPopulate(submissions, userMortgageSubmissionsByMortgageID, {mortgageID: mortgage.id}, submissionsSettingFunction))
            mortgageTasks.push(...setAndContinueToPopulate(tasks, adminMortgageTasksByUsermortgageID, {usermortgageID: mortgage.id}, tasksSettingFunction))
        })
        setMortgageState(s => {
            return {
                mortgages: addItemsToMortgageState(mortgageOnlys, s.mortgages),
                requirements: addItemsToMortgageState(requirements, s.requirements),
                applicants: addItemsToMortgageState(applicants, s.applicants),
                tasks: [...s.tasks, ...mortgageTasks],
                submissions: [...s.submissions, ...submissionsArray]
            }
        })
    }

    const fetchMortgages = async (getMoreToken) => {
        try {
            const {nextToken, items} = await getInitialMortgages(getMoreToken)
            destructureMortgages(items)
            if (nextToken) {
                await fetchMortgages(nextToken)
            }
        } catch (e) {
            console.log({e})
            navigate('/signout')
        }
    }

    const setMortgageStateWithSubscription = (updatingFunction, propertyInState) => {
        // updatingFunction is a function that expects the previous state and returns the next state

        if (typeof updatingFunction === 'function') {
            setMortgageState(prevState => {
                // First, get the new state based on the previous state
                const newState = updatingFunction(prevState[propertyInState]);
                const copy = {...prevState}
                copy[propertyInState] = newState
                return copy
            });
        }
    }
    useSubscription({
        subscription: {
            name: 'UserMortgage',
            onUpdate: onUpdateUserMortgage,
            onCreate: onCreateUserMortgage,
            onDelete: onDeleteUserMortgage,
        },
        setState: (updatingFunction) => setMortgageStateWithSubscription(updatingFunction, 'mortgages')
    })
    useSubscription({
        subscription: {
            name: 'UserApplicant',
            onUpdate: onUpdateUserApplicant,
            onCreate: onCreateUserApplicant,
            onDelete: onDeleteUserApplicant,
        },
        setState: (updatingFunction) => setMortgageStateWithSubscription(updatingFunction, 'applicants')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageRequirement',
            onUpdate: onUpdateUserMortgageRequirement,
            onCreate: onCreateUserMortgageRequirement,
            onDelete: onDeleteUserMortgageRequirement,
        },
        setState: (updatingFunction) => setMortgageStateWithSubscription(updatingFunction, 'requirements')
    })
    useSubscription({
        subscription: {
            name: 'AdminMortgageTasks',
            onUpdate: onUpdateAdminMortgageTasks,
            onCreate: onCreateAdminMortgageTasks,
            onDelete: onDeleteAdminMortgageTasks
        },
        setState: (updatingFunction) => setMortgageStateWithSubscription(updatingFunction, 'tasks')
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageSubmission',
            onUpdate: onUpdateUserMortgageSubmission,
            onCreate: onCreateUserMortgageSubmission,
            onDelete: onDeleteUserMortgageSubmission,
        },
        setState: (updatingFunction) => setMortgageStateWithSubscription(updatingFunction, 'submissions')
    })

    const categoriseMortgages = (all) => {
        const categories = {
            INCOMPLETE: [],
            NEW: [],
            SETUP: [],
            APPLY: [],
            SIGN: [],
            PREAPPROVAL: [],
            APPROVAL: [],
            ACTIVE: [],

            DELAYED: [],
            SUSPENDED: [],
            OFFLINE: [],
            COMPLETED: [],
            PROBLEM: [],
            ALL: [],
            ONLINENOW: []
        }

        if (all.length) {
            all.forEach(mort => {
                switch (mort.applicationStatus) {
                    case ApplicationStatuses.OFFLINE:
                    case ApplicationStatuses.ACTIVE:
                        let affordabilityComplete = mort.twoApplicants ? mort.completedFlowItems?.hasOwnProperty('app1AffordabilityComplete') && mort.completedFlowItems?.hasOwnProperty('app2AffordabilityComplete') : mort.completedFlowItems?.hasOwnProperty('app1AffordabilityComplete')
                        if (affordabilityComplete || mort.applicationStage !== ApplicationStages.SETUP){
                            categories.ACTIVE.push(mort)
                        }

                        if (mort.applicationStage) {
                            if (mort.applicationStage === ApplicationStages.SETUP) {


                                if (affordabilityComplete) {
                                    if (!mort.advisorID) {
                                        categories.NEW.push(mort)
                                    }
                                    else{
                                        categories[ApplicationStages.SETUP].push(mort)
                                    }
                                }
                                else{
                                    categories.INCOMPLETE.push(mort)
                                }
                            }
                            else{
                                categories[mort.applicationStage].push(mort)
                            }
                        }
                        else{
                            categories.PROBLEM.push(mort)
                        }
                        break
                    case ApplicationStatuses.SUSPENDED:
                        categories.SUSPENDED.push(mort)
                        break
                    case ApplicationStatuses.COMPLETED:
                        categories.COMPLETED.push(mort)
                        break
                    case ApplicationStatuses.DELAYED:
                        categories.DELAYED.push(mort)
                        break
                    default:
                        categories.PROBLEM.push(mort)
                }
                categories.ALL.push(mort)
                let now = new Date()
                let diff = 5
                let then = mort.lastHere ? new Date(mort.lastHere) : false
                if (then) {
                    let diffInMs = Math.abs(now.getTime() - then.getTime());
                    diff = diffInMs / (1000 * 60);
                }
                if (diff <= 3) {
                    categories.ONLINENOW.push(mort)
                }
            })
        }
        return categories
    }
    let priorConnectionState = null;

    // useEffect(() => {
    //     Hub.listen('api', (data) => {
    //         const {payload} = data;
    //         if (payload.event === 'ConnectionStateChange') {


    //         } else {
    //             let str = payload.data.query.split('(')[0]

    //         }
    //
    //         //if (payload.event === CONNECTION_STATE_CHANGE) {
    //         // if (priorConnectionState === ConnectionState.Connecting && payload.data.connectionState === ConnectionState.Connected) {
    //         //
    //         //     fetchRecentData();
    //         //
    //         // }
    //         //
    //         // priorConnectionState = payload.data.connectionState;
    //         // }
    //
    //     })
    //     const updateApplicantLastHereSub = API.graphql({query: onUpdateUserApplicantLastHere}).subscribe({
    //         next: ({provider, value}) => {
    //             const item = value.data[`onUpdateUserApplicantLastHere`]
    //             if (item) {
    //                 const cleanItem = filterObjectForNull(item)
    //
    //                 setLastHereApplicants(s => {
    //                     if (s.find(it => it.id === item.id)) {
    //                         return s.map(it => {
    //                             if (it.id === item.id) {
    //                                 return {
    //                                     ...it,
    //                                     ...cleanItem
    //                                 }
    //                             }
    //                             return it
    //                         })
    //                     } else {
    //                         return [...s, item]
    //                     }
    //                 })
    //             }
    //         },
    //         error: (error) => console.warn(error)
    //     });
    //     return () => {
    //         updateApplicantLastHereSub.unsubscribe()
    //     }
    // }, [])
    const reassembleMortgages = () => {
        return mortgageState.mortgages.map(mortgage => {
            if (mortgage.applicant1ID && mortgage.applicant2ID) {
                const applicant1 = mortgageState.applicants.find(applicant => applicant.id === mortgage.applicant1ID)
                const applicant2 = mortgageState.applicants.find(applicant => applicant.id === mortgage.applicant2ID)
                const requirement = mortgageState.requirements.find(requirement => requirement.id === mortgage.userMortgageRequirementId)
                const tasks = mortgageState.tasks.filter(task => task.usermortgageID === mortgage.id)
                const stageTasks = history.filter(task => !!task && task.mortgageID === mortgage.id)
                const submissions = mortgageState.submissions.filter(a=>a.mortgageID=== mortgage.id && !a.invalidatedReason).sort((a,b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt)
                }).map(submission => {
                    return {
                        ...submission,
                        completedFlowItems: JSON.parse(submission.completedFlowItems || '{}'),
                        completedFlowItemsForAdmin: JSON.parse(submission.completedFlowItemsForAdmin || '{}'),
                        aipSettings: JSON.parse(submission?.aipSettings || '{}'),
                        loanOfferSettings: JSON.parse(submission?.loanOfferSettings || '{}')
                    }
                })
                const activeSubmission = submissions.length ? submissions[0] : null
                return expandMortgage(
                    {
                        ...mortgage,
                        applicant1,
                        applicant2,
                        requirement,
                        tasks,
                        stageTasks,
                        submissions,
                        activeSubmission: activeSubmission ? expandActiveSubmission(activeSubmission) : null
                    }
                )
            }
            return mortgage
        })
    }
    let timer = useRef(null)

    const loadMortgages = () => {
        if (mortgageState.mortgages.length === 0) {
            // if (domainName === 'localhost') {
            //     const local = localStorage.getItem('mortgages')
            //     if (local) {
            //         setMortgageState(JSON.parse(local))
            //     } else {
            //         setMortgages(null)
            //     }
            // } else {
                fetchMortgages(null)
            // }
        } else {
            // if (domainName === 'localhost') {
            //     clearTimeout(timer.current)
            //     timer.current = setTimeout(() => {
            //         localStorage.setItem('mortgages', JSON.stringify(mortgageState))
            //     }, 10000)
            // }
        }
    }
    useEffect(() => {
        loadMortgages()
    }, [mortgageState.mortgages.length])
    const allMortgages = reassembleMortgages()
    const categorised = categoriseMortgages(allMortgages)
    return (

        <Mortgages.Provider value={categorised}>
            {props.children}
        </Mortgages.Provider>
    );
}

export default MortgagesProvider;