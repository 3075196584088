import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";

function PermissionWrapper(props) {
    const navigate = useNavigate()
    let [state,setState] = useState({ok:false})
    const redirectIfNotPermitted = async ()=>{
        try{
            let user = await Auth.currentAuthenticatedUser()

            const groups = user.signInUserSession.idToken.payload['cognito:groups'];
            if (!groups.includes('admins') && !groups.includes('supporters') && !groups.includes('advisors')){
                await Auth.signOut()
                navigate('/signin')
            }
            //TODO: disallow not supporters and admins
            Auth.currentSession()
                .then(session => {

                    // Use the session information as needed
                })
                .catch(err => {
                    if (err === 'No current user') {
                        // User is not signed in, redirect to sign-in page or similar action
                    }
                    console.error(err);
                });
            setState(s=>({...s, ok:true}))
        }
        catch (e) {
            navigate('/signin')
        }
    }
    useEffect(()=>{
        redirectIfNotPermitted()
    },[])
    if (!state.ok){
        return null
    }


    return props.children;
}

export default PermissionWrapper;