import React, {useEffect, useState} from 'react';
import {FileTextOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button, Card} from "antd";

function ButtonJumboAsync(props) {
    const [loading,setLoading] = useState(false)
    const {className, onClick, icon, label, subText, ...rest} = props
    const onButtonClick= async (e)=>{
        setLoading(e)
    }
    const handleClick= async ()=>{
        await onClick(loading)
        setLoading(false)
    }
    useEffect(() => {
        if (loading){
            handleClick()
        }
    }, [loading]);

    let buttonIcon = loading ? <LoadingOutlined/> : icon || null
    let newClasses = className ? className.split(' ') : []

    newClasses.push('h-auto')
    let newClassName = newClasses.join(' ')
    return (

        <Button className={newClassName}  onClick={onButtonClick} {...rest}>
            <div className="d-row a-center gap-9">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                    width : 40,
                    borderRadius: 20,
                    overflow: "hidden",
                    border: '1px solid rgb(160, 160, 160)',
                    flexShrink: 0
                }}>
                    {buttonIcon}
                </div>
                <div className=" d-col j-start a-start">
                    <div style={{fontSize: 17}}>{label}</div>
                    <div style={{fontSize: 14}}>{subText}</div>
                </div>
            </div>
        </Button>

    )
}

export default ButtonJumboAsync;