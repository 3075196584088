import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {accumulateMortgageFigures, CellRender, mortgageFiguresColumns, TableHeading} from "../FiguresUsed";
import {geekblue} from "@ant-design/colors";
import {Table} from "antd";

function Employment({verified}) {
    const mortgage = useMortgage()
    const figures = !!verified ? mortgage.figures.verified : mortgage.figures.unverified
    let prefixes = [
        'sector',
        'basic',
        `year1Bonus`,
        'overtime',
        'commission',
        'allowances',
        'other'
    ]
    let rows = prefixes.map(key => {
        let row = {
            key: key,
            label: key,
            app1value: figures[`${key}1`]
        }
        if (mortgage.twoApplicants) {
            row.app2value = figures[`${key}2`]
        }
        return row
    }).filter(row => {
        if (row.app2value) {
            return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning && row.app2value.value === 0 && !row.app2value.error && !row.app2value.warning)
        }
        return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning)
    })

    let totalEmployment = accumulateMortgageFigures(rows)
    return (
        <Table title={() => <TableHeading title="Employment" color={geekblue.primary}
                                          total={totalEmployment / 12}/>} dataSource={rows} size="small"
               columns={mortgageFiguresColumns(mortgage)}
               pagination={false}/>
    );
}

export default Employment;