import React, {useContext} from 'react';
import {CheckOutlined, DeleteOutlined, RobotOutlined, RollbackOutlined, RotateRightOutlined, UserOutlined} from "@ant-design/icons";
import {Menu, Tooltip} from "antd";
import {green} from "@ant-design/colors";
import {AdminEmails} from "../../providers/AdminEmailsProvider";
import updateActioned from "../../api-functions/emails/updateActioned";
import deleteEmail from "../../api-functions/emails/deleteEmail";
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import {AdminDrawer} from "../../providers/AdminDrawerProvider";
import * as htmlparser2 from "htmlparser2";
import {chatGptMessages} from "../../api-functions/chat-gpt/messages";
import {askChatGPT} from "../../api-functions/chat-gpt/chat-gpt";
function ActionBar({email}) {
    const items = []
    const navigate = useNavigate()
    const adminEmails = useContext(AdminEmails)
    const adminDrawer = useContext(AdminDrawer)
    if (!!email) {
        items.push({
            label: <ActionLabel email={email}/>,
            key: 'action'
        })
        items.push({
            label: 'Reply',
            key: 'reply',
            icon: <RollbackOutlined/>
        })
        // items.push({
        //     label: 'Reply with AI',
        //     key: 'replyWithAI',
        //     icon: <RobotOutlined/>
        // })
        items.push({
            label: 'More',
            key: 'more',
            children: [
                {
                    label: 'Delete',
                    key: 'delete',
                    icon: <DeleteOutlined/>
                }
            ],
        })
    }
    function ActionLabel() {
        if (email.actionedBy === 'null') {
            return <>Action</>
        }
        return <Tooltip title="TBA">
            <CheckOutlined style={{color: green.primary}}/> Actioned
        </Tooltip>
    }

    const handleActionMenuClick = async (e) => {

        switch (e.key) {
            case 'action' :
                let user = await Auth.currentAuthenticatedUser();
                let {id, _version} = email
                let actionedBy = (email.actionedBy === 'null') ? user.attributes.sub : 'null'
                let actionedOn = (email.actionedBy === 'null') ? new Date().toISOString() : null
                let update = {
                    id,
                    actionedBy,
                    actionedOn,
                    _version
                }
                return updateActioned(update)
            case 'back' :
                navigate('/emails')
                return
            case 'replyWithAI' :
                const addresses1 = email.to.filter(to => !to.includes('@sussd.ie'))
                //prepend the from address to addressed
                addresses1.unshift(email.from)
                const config1 = {
                    to: addresses1,
                    subject: `RE: ${email.subject}`,
                    reply: email,
                }
                if (email.cc && email.cc.length > 0) {
                    config1.cc = email.cc
                }
                // get the text from the email
                let plainText = '';

                const parser = new htmlparser2.Parser({
                    ontext(text) {
                        plainText += text + '\n';
                    }
                });
                parser.write(email.html);
                parser.end()
                const delimiters = [
                    'From:', 'Sent:', 'To:', 'Subject:', '-----Original Message-----'
                ];

                const lines = plainText.split('\n');
                const filteredLines = [];

                for (const line of lines) {

                    if (delimiters.some(delimiter => line.startsWith(delimiter))) {
                        break;
                    }
                    filteredLines.push(line);
                }
                let basictext = filteredLines.join('\n').trim();
                let caseDetails = `Firstname: Joe\nSurname: Bloggs\nPolicy Number: 123456\nLast completed action: Customer had a call with his financial advisor\nNext action: Customer to send in his bank statements, payslips and Employment Details Summary\nProvisional Loan Amount Sought: €300000\nProvisional Property Price:€360000\nCurrent Stage in Process: Income Verification\nNext Stage in Process: Discuss Lender Providers\n`;
                basictext = 'Hi Kev\nCan you tell me what I need to do next\nThanks Joe';
                let message = chatGptMessages.customer.email.reply(basictext, caseDetails )

                let choices = await askChatGPT(message)
                adminDrawer.composeEmail(config1, choices)
                return
            case 'reply' :
                const addresses = email.to.filter(to => !to.includes('@sussd.ie'))
                //prepend the from address to addressed
                addresses.unshift(email.from)

                const config = {
                    to: addresses,
                    subject: `RE: ${email.subject}`,
                    reply: email,
                }
                if (email.cc && email.cc.length > 0) {
                    config.cc = email.cc
                }
                adminDrawer.composeEmail(config)
                // setState(s=>({...s,composing: true}))
                return
            case 'delete' :
                if (email) {
                    deleteEmail({id: email.id, _version: email._version})
                    navigate('/emails')
                }
        }
    }

    return <div className="d-row j-end mb-6" style={{backgroundColor: '#FFF'}}>
        {!!email && (
            <Menu className="me-auto" onClick={handleActionMenuClick} mode="horizontal" items={[{
                label: 'Back',
                key: 'back',
            }]}/>
        )}

        <Menu onClick={handleActionMenuClick} mode="horizontal" items={items}/>
    </div>
}

export default ActionBar;