import React, {useContext, useEffect, useRef} from 'react';
import {differenceInDays, format} from "date-fns";
import {Link} from "react-router-dom";
import { Mortgages} from "../../providers/MortgagesProvider";
import {categories} from "../../providers/MortgagesProvider";
import {Card, Tag} from "antd";
import {ApplicationStatuses} from "../../../models";

function BarChartMortgages(props) {
    const mortgages = useContext(Mortgages)
    const widthOfOneDay = 8
    const startDate = new Date('2023-01-16');
    const today = new Date();
    today.setHours(23, 59, 59, 999)
    const mondays = [];
    // Iterate through the dates between startDate and today
    let currentDate = new Date(startDate);
    while (currentDate <= today) {
        if (currentDate.getDay() === 1) {
            mondays.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    const scroll = useRef(null)

    const generateBars = () => {
        const realMortgages = mortgages.ALL.filter(m => {
            return m.applicationStatus !== ApplicationStatuses.SUSPENDED
        })
        const items = realMortgages.map(mortgage => {
            let start = new Date(mortgage.createdAt)

            let end = today
            if (!!mortgage.requirement?.drawdownDate){
                let drawDown = new Date(mortgage.requirement?.drawdownDate)
                if (drawDown < end){
                    end = drawDown
                }
            }
            let width = differenceInDays(end, start) * widthOfOneDay
            let height = (mortgage.requirement.loanRequired || 100000) / 10000
            let left = (differenceInDays(start, startDate) + 7) * widthOfOneDay

            let stage = mortgage.applicationStage
            let details = categories.find(item => item.key === stage)
            return {
                start,
                end,
                width,
                height,
                left,
                color: details.color,
                id: mortgage.id,
                name: `${mortgage.applicant1.firstName} ${mortgage.applicant1.surname || ''}`,
                loan: mortgage.requirement.loanRequired || 0
            }
        })
        items.sort((a, b) => b.start - a.start)
        return items.map(item => {
            return <div key={'graph-item-' + item.start.toString()}>

                <Link to={`/client/${item.id}`} title={item.loan?.toLocaleString('en-us') || ''}>
                    <Tag color={item.color}
                         style={{
                             height: item.height,
                             width: item.width,
                             marginLeft: item.left,
                             marginTop: 6,
                             position: 'relative',
                         }}>
                        {item.name}
                    </Tag>

                </Link>

            </div>

        })

    }
    useEffect(() => {
        // start the graph on the right
        if (scroll.current) {
            scroll.current.scrollLeft = scroll.current.scrollWidth - scroll.current.clientWidth;
        }
    }, [scroll.current])
    return (

        <div ref={scroll} className="p-relative" style={{width: '100%', overflow: 'auto', heigth: '100vh'}}>
            <div className="d-row p-absolute inset-0 mb-24">
                {
                    mondays.map(cell => {
                        let style = {
                            minHeight: 300,
                            width: widthOfOneDay * 7,
                            borderRight: '1px solid rgba(200,200,200,0.5)'
                        }
                        return <div key={'mortgage-graph' + cell.toString()} className="d-col a-start shrink-not"
                                    style={style}>
                            <small style={{color: "lightgray"}} className="d-block ps-3">{format(cell, "d MMM")}</small>
                            <small style={{color: "lightgray"}}
                                   className="d-block ps-3 pb-3 mt-auto">{format(cell, "d MMM")}</small>
                        </div>
                    })

                }


            </div>
            <div style={{marginBottom: 48}}>
                {generateBars()}
            </div>

        </div>

    );
}

export default BarChartMortgages;