import React from 'react';
import Funding from "../_common/funding/Funding";
import StagesLayout from "../_common/StagesLayout";
import SelectLender from "./parts/SelectLender";
import FiguresUsed from "../_common/verified-figures/FiguresUsed";
import ApplyCustomerDocuments from "../apply/parts/ApplyCustomerDocuments";
import ApplyAdminDocuments from "../apply/parts/ApplyAdminDocuments";
//import Bundle from "../apply/parts/Bundle";
import VerifiedLenderTests from "../_common/lender-tests/v2/VerifiedLenderTests";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import MissingVerifiedUploads from "../_common/uploads/MissingVerifiedUploads";
import Bundle from "./parts/Bundle";
function ManagementSign(props) {
    let [activeKey, setActiveKey] = React.useState('Lender Test')
    const mortgage = useMortgage()
    let problemUploads = mortgage.mortgageUploads.apply.filter(upload => {
        return !!(upload.verified && upload.verified?.isVerifiable && upload.verified?.errors?.length > 0);

    })
    let tabs = []
    if (problemUploads.length > 0) {
        tabs.push({
            key     : 'Upload Problems',
            label   : 'Upload Problems',
            children: <MissingVerifiedUploads stage="apply"/>
        })
    }
    tabs.push(
        {
            key: 'Lender Test',
            label: 'Preparation Config',
            children: <VerifiedLenderTests/>,
        },
        {
            key: 'Figures',
            label: 'Figures',
            children: <FiguresUsed useVerified={true}/>,
        },
        {
            key: 'Funding',
            label: 'Funding',
            children: <Funding/>,
        },
        {
            key: 'Lender Selection',
            label: 'Select Lender',
            children: <SelectLender/>,
        },
        {
            key: 'Cust. Documents',
            label: 'Cust. Documents',
            children: <ApplyCustomerDocuments/>
        },
        {
            key: 'Admin Documents',
            label: 'Admin Documents',
            children: <ApplyAdminDocuments/>
        },
        {
            key: 'Bundle & Submit',
            label: 'Bundle & Submit',
            children: <Bundle/>
        })
    return <StagesLayout title="Signing - Preparing Application For Submission" tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey}/>
}

export default ManagementSign;