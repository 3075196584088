import {addBunchToMap} from "../overlay-utils";

export default function (mortgage, config) {
    let map = {}
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let errors = []
        const digits =  mortgage.requirement.mortgageAccountNo
        if (!digits){
            errors.push('No mortgage loan account number')
        }
        else {
            addBunchToMap(map, 'account-no-', digits.toUpperCase().split(''))
        }
        return {map, errors}
    }

    const namesAndAddressed =() =>{
        let map = {}
        let errors = []
        map['borrower1'] = mortgage.applicant1.fullName
        if (mortgage.twoApplicants){
            map['borrower2'] = mortgage.applicant2.fullName
        }
        map['borrow1-address'] = mortgage.applicant1.personal.addressCurrent
        if (mortgage.twoApplicants){
            if (!mortgage.applicant2.personal.addressCurrent && mortgage.requirement.cohabiting){
                map['borrow2-address'] = mortgage.applicant1.personal.addressCurrent
            }
            else{
                map['borrow2-address'] = mortgage.applicant2.personal.addressCurrent
            }
        }
        return {map, errors}
    }

    const formDetails = () => {
        let map = {}
        let errors = []
        map['firm-name'] = `Truss Mortgages Ltd T/A Suss'd Mortgages`
        map['firm-address-1'] = `20 Harcourt Street`
        map['firm-address-2'] = `Dublin 2`
        map['witness-name'] = `Kevin Coakley`
        return {map, errors}
    }
    const elements = [
        {
            "name": "witness-name",
            "type": "TEXT",
            "page": 1
        }
    ]

    return {sections: [
        mortgageLoanAccountNumber(),
        namesAndAddressed(),
        formDetails()]}
 }