import React from 'react';
import useMortgage from "../../../../../../../providers/mortgage/useMortgage";
import {UploadStatuses} from "../../../../../../../../models";
import {Tag} from "antd";

function RowTags({uploadId}) {
    const mortgage = useMortgage()
    const upload = mortgage.uploads.find(item => item.id === uploadId)

    const tags = []
    switch (upload?.status?.toUpperCase()) {
        case UploadStatuses.PENDING:
            tags.push({title: "Pending Review", color: "orange"})
            break
        case UploadStatuses.ACCEPTABLE:
            tags.push({title: "Acceptable", color: "green"})
            break
        case UploadStatuses.REVIEWING:
            tags.push({title: "Needs Review", color: "orange"})
            break
        case UploadStatuses.REJECTED:
            tags.push({title: `Returned: ${upload.reason|| 'No reason'}`, color: "red"})
            break
        default:
    }
    return (
        <div className="d-row gap-6">
            {tags.map(tag => <Tag color={tag.color} key={`${tag.title}/${uploadId}`}>{tag.title}</Tag>)}
        </div>
    );
}

export default RowTags;