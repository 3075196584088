import React from 'react';
import {Input} from "antd";
import useMortgage from "../../../../../../../providers/mortgage/useMortgage";
import useBackendUsers from "../../../../../../../providers/mortgage/useBackendUsers";
import {blue} from "@ant-design/colors";

function InternalNote(props) {
    const mortgage = useMortgage()
    const {me} = useBackendUsers()
    const handleNoteBlur = async (e) => {
        let val = e.target.value
        let pairs={}
        if (val === ""){
            pairs.internalNote= null
            pairs.internalNoteBy= null
            pairs.internalNoteOn= null
        }
        else{
            pairs.internalNote= val.split('\n')
            pairs.internalNoteBy= me.sub
            pairs.internalNoteOn= new Date().toISOString()
        }
        await mortgage.mutate({
            update: {
                target: `progress[${props.section.record.id}]`,
                pairs
            }
        })
    }
    let defaultValue = (props.section.record.internalNote || []).join('\n')

    return (

        <Input.TextArea rows={2} placeholder="Internal Note ..." onClick={e=>e.stopPropagation()} style={{width:'100%', color: blue[6], fontWeight:'bold'}} onBlur={handleNoteBlur} defaultValue={defaultValue}/>
    )

}

export default InternalNote;