import {performGQL} from "../functions";

async function getInitialMortgages(nextToken) {

    const listUserMortgages = /* GraphQL */ `
        query ListUserMortgages(
            $filter: ModelUserMortgageFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listUserMortgages(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    id
                    twoApplicants
                    mortgageType
                    applicant1ID
                    applicant2ID
                    qualification
                    activeAgent
                    qualificationConfirmed
                    uploadsPercentSetup
                    uploadsPercentApply
                    applicant1 {
                        id
                        email
                        mobile
                        firstName
                        surname
                        isMale
                        lastHere
                        lastHereTime{
                            id
                            time
                            _version
                        }
                       data
                        userApplicantLastHereId
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                    }
                    applicant2 {
                        id
                        email
                        mobile
                        firstName
                        surname
                        isMale
                        lastHere
                        lastHereTime{
                            id
                            time
                            _version
                        }
                       data
                        userApplicantLastHereId
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                    }
                    applicationStage
                    applicationStatus
                    applicationFutureDate
                    lastSeen
                    advisorID
                    caseNumber
                    requirement {
                        id
                        loanRequired
                        lenderCode
                        mortgageAccountNo
                        drawdownDate
                        soft
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                        __typename
                    }
                    tasks{
                        items{
                            id
                            body
                            due
                            completedOn
                            usermortgageID
                        }
                        nextToken
                        startedAt
                    }
                    friendlyName
                    lastSeen
                    lastFlowItem
                    lastFlowItemTime
                    completedFlowItems
                    completedFlowItemsForAdmin
                    advisorID
                    adminMessage
                    adminMessageBy
                    userMortgageRequirementId
                    submissions {
                        items {
                            id
                            lenderCode
                            provisionalRateId
                            soft
                            aipExpiryDate
                            aipSettings
                            loanOfferExpiryDate
                            loanOfferSettings
                            drawdownDate
                            completedFlowItems
                            completedFlowItemsForAdmin
                            mortgageID
                            createdAt
                            _version
                            _deleted
                            _lastChangedAt
                        }
                        nextToken
                        startedAt
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    owner
                }
                nextToken
                startedAt
            }
        }
    `;
    const variables = {}
    if (nextToken) {
        variables.nextToken = nextToken
    }
    return performGQL(variables, listUserMortgages)
}

/* ITEMS TO FET FETCHED / SUBSCRIBED
    UserMortgage
    id
    twoApplicants
    mortgageType
    applicant1ID
    applicant2ID
    applicationStage
    applicationStatus
    lastSeen
    advisorID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt


    UserApplicant
    id
    email
    mobile
    firstName
    surname
    isMale
    lastHere
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    owner

    ApplicantActivity
          id
          actions
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename

    Requirement
     loanRequired
    lenderCode
    mortgageAccountNo
    drawdownDate



    items {
                    id
                    twoApplicants
                    mortgageType
                    applicant1ID
                    applicant2ID
                    applicant1 {
                        id
                        email
                        mobile
                        firstName
                        surname
                        isMale
                        lastHere
                        userApplicantPersonalId
                        userApplicantIncomeId
                        userApplicantFinancialId
                        userApplicantEmploymentId
                        userApplicantTaxId
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                    }
                    applicant2 {
                        id
                        email
                        mobile
                        firstName
                        surname
                        isMale
                        lastHere
                        userApplicantPersonalId
                        userApplicantIncomeId
                        userApplicantFinancialId
                        userApplicantEmploymentId
                        userApplicantTaxId
                        readAccessGroup
                        editAccessGroup
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        owner
                    }
                    applicationStage
                    applicationStatus
                    settings {
                        id
                        initialContactOn
                        initialContactBy
                        mortgageID
                        contactTimes
                        markedLead
                        markedLeadBy
                        markedSetupDone
                        markedSetupDoneBy
                        markedComplete
                        markedCompleteBy
                        markedPreApproved
                        markedPreApprovedBy
                        markedApproved
                        markedApprovedBy
                        markedDocsSigned
                        markedDocsSignedBy
                        markedSubmitted
                        markedSubmittedBy
                        markedSuspended
                        markedSuspendedBy
                        offlineLead
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                    }
                    requirement {
                        loanRequired
                        lenderCode
                        mortgageAccountNo
                        drawdownDate
                    }
                    lastSeen
                    advisorID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    userMortgageSettingsId
                    owner
                }






 */


export const onCreateUserMortgageRequirement = /* GraphQL */ `
    subscription OnCreateUserMortgageRequirement(
        $filter: ModelSubscriptionUserMortgageRequirementFilterInput
        $owner: String
    ) {
        onCreateUserMortgageRequirement(filter: $filter, owner: $owner) {
            id
            loanRequired
            lenderCode
            mortgageAccountNo
            drawdownDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onUpdateUserMortgageRequirement = /* GraphQL */ `
    subscription OnUpdateUserMortgageRequirement(
        $filter: ModelSubscriptionUserMortgageRequirementFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageRequirement(filter: $filter, owner: $owner) {
            id
            loanRequired
            lenderCode
            mortgageAccountNo
            drawdownDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onDeleteUserMortgageRequirement = /* GraphQL */ `
    subscription OnDeleteUserMortgageRequirement(
        $filter: ModelSubscriptionUserMortgageRequirementFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageRequirement(filter: $filter, owner: $owner) {
            id
            loanRequired
            lenderCode
            mortgageAccountNo
            drawdownDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onCreateUserApplicant = /* GraphQL */ `
    subscription OnCreateUserApplicant(
        $filter: ModelSubscriptionUserApplicantFilterInput
        $owner: String
    ) {
        onCreateUserApplicant(filter: $filter, owner: $owner) {
            id
            email
            mobile
            firstName
            surname
            isMale
            lastHere
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onUpdateUserApplicant = /* GraphQL */ `
    subscription OnUpdateUserApplicant(
        $filter: ModelSubscriptionUserApplicantFilterInput
        $owner: String
    ) {
        onUpdateUserApplicant(filter: $filter, owner: $owner) {
            id
            email
            mobile
            firstName
            surname
            isMale
            lastHere
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onDeleteUserApplicant = /* GraphQL */ `
    subscription OnDeleteUserApplicant(
        $filter: ModelSubscriptionUserApplicantFilterInput
        $owner: String
    ) {
        onDeleteUserApplicant(filter: $filter, owner: $owner) {
            id
            email
            mobile
            firstName
            surname
            isMale
            lastHere
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onCreateUserApplicantActivity = /* GraphQL */ `
    subscription OnCreateUserApplicantActivity(
        $filter: ModelSubscriptionUserApplicantActivityFilterInput
        $owner: String
    ) {
        onCreateUserApplicantActivity(filter: $filter, owner: $owner) {
            id
            actions
            actionedOn
            actionedBy
            userAgent
            applicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onUpdateUserApplicantActivity = /* GraphQL */ `
    subscription OnUpdateUserApplicantActivity(
        $filter: ModelSubscriptionUserApplicantActivityFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantActivity(filter: $filter, owner: $owner) {
            id
            actions
            actionedOn
            actionedBy
            userAgent
            applicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onDeleteUserApplicantActivity = /* GraphQL */ `
    subscription OnDeleteUserApplicantActivity(
        $filter: ModelSubscriptionUserApplicantActivityFilterInput
        $owner: String
    ) {
        onDeleteUserApplicantActivity(filter: $filter, owner: $owner) {
            id
            actions
            actionedOn
            actionedBy
            userAgent
            applicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
export const onCreateUserMortgage = /* GraphQL */ `
    subscription OnCreateUserMortgage(
        $filter: ModelSubscriptionUserMortgageFilterInput
        $owner: String
    ) {
        onCreateUserMortgage(filter: $filter, owner: $owner) {
            id
            twoApplicants
            mortgageType
            applicant1ID
            applicant2ID
            applicationStage
            applicationStatus
            friendlyName
            lastSeen
            lastFlowItem
            lastFlowItemTime
            completedFlowItems
            advisorID
            qualification
            adminMessage
            adminMessageBy
            qualificationConfirmed
            userMortgageRequirementId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
    }
`;
export const onUpdateUserMortgage = /* GraphQL */ `
    subscription OnUpdateUserMortgage(
        $filter: ModelSubscriptionUserMortgageFilterInput
        $owner: String
    ) {
        onUpdateUserMortgage(filter: $filter, owner: $owner) {
            id
            twoApplicants
            mortgageType
            applicant1ID
            applicant2ID
            applicationStage
            applicationStatus
            friendlyName
            lastSeen
            lastFlowItem
            lastFlowItemTime
            completedFlowItems
            completedFlowItemsForAdmin
            advisorID
            qualification
            adminMessage
            adminMessageBy
            qualificationConfirmed
            userMortgageRequirementId
            uploadsPercentSetup
            uploadsPercentApply
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
    }
`;
export const onDeleteUserMortgage = /* GraphQL */ `
    subscription OnDeleteUserMortgage(
        $filter: ModelSubscriptionUserMortgageFilterInput
        $owner: String
    ) {
        onDeleteUserMortgage(filter: $filter, owner: $owner) {
            id
            twoApplicants
            mortgageType
            applicant1ID
            applicant2ID
            applicationStage
            applicationStatus
            friendlyName
            lastSeen
            lastFlowItem
            lastFlowItemTime
            completedFlowItems
            advisorID
            qualification
            adminMessage
            adminMessageBy
            qualificationConfirmed
            userMortgageRequirementId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
    }
`;
export const onUpdateUserApplicantLastHere = /* GraphQL */ `
    subscription OnUpdateUserApplicantLastHere(
        $filter: ModelSubscriptionUserApplicantLastHereFilterInput
        $owner: String
    ) {
        onUpdateUserApplicantLastHere(filter: $filter, owner: $owner) {
            id
            time
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onCreateAdminMortgageTasks = /* GraphQL */ `
    subscription OnCreateAdminMortgageTasks(
        $filter: ModelSubscriptionAdminMortgageTasksFilterInput
    ) {
        onCreateAdminMortgageTasks(filter: $filter) {
            id
            body
            author
            pinned
            due
            deleted
            target
            completedOn
            completedBy
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
export const onUpdateAdminMortgageTasks = /* GraphQL */ `
    subscription OnUpdateAdminMortgageTasks(
        $filter: ModelSubscriptionAdminMortgageTasksFilterInput
    ) {
        onUpdateAdminMortgageTasks(filter: $filter) {
            id
            body
            author
            pinned
            due
            deleted
            target
            completedOn
            completedBy
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
export const onDeleteAdminMortgageTasks = /* GraphQL */ `
    subscription OnDeleteAdminMortgageTasks(
        $filter: ModelSubscriptionAdminMortgageTasksFilterInput
    ) {
        onDeleteAdminMortgageTasks(filter: $filter) {
            id
            body
            author
            pinned
            due
            deleted
            target
            completedOn
            completedBy
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
export const onCreateUserMortgageSubmission = /* GraphQL */ `
    subscription OnCreateUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onCreateUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalRateId
            soft
            aipExpiryDate
            aipSettings
            loanOfferExpiryDate
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            mortgageID
            createdAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onUpdateUserMortgageSubmission = /* GraphQL */ `
    subscription OnUpdateUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onUpdateUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalRateId
            soft
            aipExpiryDate
            aipSettings
            loanOfferExpiryDate
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            mortgageID
            createdAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export const onDeleteUserMortgageSubmission = /* GraphQL */ `
    subscription OnDeleteUserMortgageSubmission(
        $filter: ModelSubscriptionUserMortgageSubmissionFilterInput
        $owner: String
    ) {
        onDeleteUserMortgageSubmission(filter: $filter, owner: $owner) {
            id
            lenderCode
            provisionalRateId
            soft
            aipExpiryDate
            aipSettings
            loanOfferExpiryDate
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            mortgageID
            createdAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
export default getInitialMortgages