function ProviderRecommendation({mortgage}) {
    return <p>Enter provider recommendation in Document Composer</p>
}
function RateDetails({mortgage}) {
    return <p>Enter rate details in Document Composer</p>
}
function MortgageTermExplanation({mortgage}) {
    return <p>Enter mortgage term explanation in Document Composer</p>
}
export default {
    key  : 'statementOfSuitability',
    label: 'Statement of Suitability', //  folder: 'Lender CustomerDocuments',
    contents: [
        {
            key     : 'provider-recommendation',
            title   : 'Provider Recommendation',
            generate: ProviderRecommendation,
            sequence: 0.1,
        },
        {
            key     : 'rate-details',
            title   : 'Rate Details',
            generate: RateDetails,
            sequence: 0.2,
        },
        {
            key     : 'mortgage-term-explanation',
            title   : 'Mortgage Term Explanation',
            generate: MortgageTermExplanation,
            sequence: 0.3,
        },
        {
            key     : 'product-recommendation',
            title   : 'Product Recommendation',
            generate: () => <p>Enter Product Recommendation in Document Composer</p>,
            sequence: 0.4,
        },
        {
            key     : 'conclusion',
            title   : 'Conclusion',
            generate: () => <p>Enter Conclusion in Document Composer</p>,
            sequence: 0.5,
        }
    ]
}