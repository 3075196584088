import React from 'react';
import {Upload} from "antd";
import {capitaliseWords} from "../../../../../../../assets/scripts/string-functions";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import useMortgageDocumentsService from "../../../../../../providers/mortgage/useMortgageDocumentsService";

function SimpleDocumentUploadButton({name, folder, stage, isPrivate, isDynamic=false}) {
    const [busy,setBusy] = React.useState(false)
    const { put} = useMortgageDocumentsService()
    const beforeUpload = (file) => {
        return new Promise((resolve, reject) => {
            let modifiedFIle = new File([file], `${name}.${file.name.split('.').pop()}`, {type: file.type});
            resolve(modifiedFIle)
        })
    };
    const customRequest = async ({file, onSuccess, onError, onProgress}) => {
        setBusy(true)
        try {
            const config = {
                completeCallback: (event)=>{
                    setBusy(false)
                },
                errorCallback: (event)=>{
                    setBusy(false)

                },
            }
            let title = capitaliseWords(name.replace(/-/g, ' '))
            await put({file, config, stage, isPrivate, folder, isDynamic, title   })
            onSuccess();
        } catch (error) {
            onError(error);

        }
    }
    return <Upload.Dragger
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        showUploadList={false}
        disabled={busy}
    >
        {busy ? (<LoadingOutlined/>) : (  <UploadOutlined />)}


    </Upload.Dragger>
}

export default SimpleDocumentUploadButton;