import React, {useEffect, useState} from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Alert, App, Button, Checkbox, Modal, Table} from "antd";
import {sortDateSorter} from "../../_common/uploads/standardised-upload/UploadsTable";
import {UploadStatuses} from "../../../../../../models";
import {FileZipOutlined} from "@ant-design/icons";
import useMortgageDocumentsService, {getUserDocumentKey} from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import ButtonAsync from "../../../../elements/ButtonAsync";
import {getUserUploadKey} from "../../../../../providers/mortgage/useMortgageUploadService";

function Bundle(props) {
        const [state,setState] = useState({
                errors: [],
                zipUrl: null,
                modalOpen: false
        })
        const mortgage = useMortgage()
        let friendlyName = (mortgage.friendlyName || mortgage.applicationTitle).replace(/[^a-zA-Z0-9]/g, '')

        const sections = mortgage.mortgageUploads.apply
        const problems = []

        sections.forEach(section => {
                // Build Prolems
                const nameForTitle = (section.applicant === 1 ? mortgage.applicant1.firstName + " " : section.applicant === 2 ? mortgage.applicant2.firstName + " " : '')
                section.ownerTitle = `${nameForTitle}${section.title}`
                if (section.verified.uploads.active.length === 0) {
                        if (!section.title.includes('(optional)')) {
                                problems.push(`No active uploads in ${section.title}`)
                        }

                }
                section.problems.forEach(problem => {
                        if (problem.label === 'Document Errors') {
                                problem.errors.forEach(err => {
                                        if (err.startsWith('Missing ')) {
                                                problems.push(`${section.ownerTitle} - ${err}`)
                                        }
                                })
                        }
                })

               //Check if the existing bundle is older than the most recent upload
                let existingBundle = mortgage.UserMortgageDocuments.find(it => {
                        return it.folder === 'bundled-uploads' && it.stage === 'APPLY' && it.submissionID === mortgage.activeSubmission.id && it.title.toLowerCase() === section.title.toLowerCase()
                })


                if (existingBundle) {
                        let existingBundleExpired = false
                        let mostRecentUploadDate = false
                        section.verified.uploads.active.forEach(upload => {
                                let uploadedDate = dayjs(upload.createdAt)
                                if (!mostRecentUploadDate || (dayjs.isDayjs(mostRecentUploadDate) && uploadedDate.isAfter(mostRecentUploadDate))) {
                                        mostRecentUploadDate = uploadedDate
                                }
                        })
                        let existingCreatedDate = dayjs(existingBundle.createdAt)
                        if ((dayjs.isDayjs(mostRecentUploadDate) && mostRecentUploadDate.isAfter(existingCreatedDate))) {
                                existingBundleExpired = true
                        }
                        if (!existingBundleExpired) {
                                section.existingBundle = existingBundle
                        }

                }
        })

        const app = App.useApp()

        const handleSubmit = async () => {
                const zipFiles = []
                let count = 0
                sections.forEach(section => {
                        let folder = !!section.applicant ? section.applicant === 1 ? 'applicant1' : 'applicant2' : 'general'
                        if (section.existingBundle) {
                                let path = getUserDocumentKey(section.existingBundle)
                                let key = `private/${section.existingBundle.identityID}/${path}`
                                let fileName = section.existingBundle.fileName
                                count ++
                                zipFiles.push({
                                        key,
                                        fileName//: `${folder}/${fileName}`
                                })
                        }
                        else {
                                let files = section.verified.isVerifiable ?
                                 section.verified.rows.map(row=>row.upload).filter(upload=>upload.fileExtension === 'pdf' || upload.pdffed).map(upload => {
                                         count ++
                                                let path = getUserUploadKey(upload)
                                                let key = `private/${upload.identityID}/${path}`
                                                let fileName = upload.fileName
                                                return {key, omit: upload.omit, fileName}
                                        })
                               : section.verified.uploads.active.map(upload => {
                                        count ++
                                        let path = getUserUploadKey(upload)
                                                let key = `private/${upload.identityID}/${path}`
                                                let fileName = upload.fileName
                                                return {key, omit: upload.omit, fileName}
                                        })


                                if (files.length){
                                        zipFiles.push({
                                                files      ,
                                                fileName: section.ownerTitle+'.pdf'
                                        })
                                }

                        }
                })

                // Handle form submission with selectedIds
                try {
                        const response = await API.post("sussdAdminAPI", "/operations/s3/zip", {
                                body: {
                                        files: zipFiles,
                                        friendlyName,
                                        name: 'mortgage-application-uploads'
                                }
                        })
                        setState({zipUrl: response.downloadUrl, errors: response.errors, modalOpen: response.errors.length > 0})

                        // const a = document.createElement('a');
                        // a.style.display = 'none';
                        // a.href = url;
                        // a.download = `${friendlyName}.zip`;
                        // document.body.appendChild(a);
                        // a.click();
                        // window.URL.revokeObjectURL(url);
                }
                catch (e) {
                        app.notification.error({message: e.message})
                }

        };
        const openZip = () => {
                if (!!state.zipUrl) {
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = state.zipUrl;
                        a.download = `${friendlyName}.zip`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(state.zipUrl);
                        setState(s=>({...s,zipUrl:null}))
                }
        }
        useEffect(() => {
                if (!!state.zipUrl && !state.errors.length) {
                        openZip()
                }

        }, [state.zipUrl, state.errors]);


        return <>

                <Alert message={(
                <div>
                        {problems.map(p=><div key={p}>{p}</div>)}
                        <ButtonAsync className="mt-9" size="large" onClick={handleSubmit}>Generate Zip</ButtonAsync>
                </div>
                )} type={problems.length ? "warning":"success"}/>

                <Modal
                title="The zip file has warnings"
                open={state.modalOpen}
                okText="Open Zip"
                onCancel={()=>setState(s=>({...s,modalOpen: false}))}
                onClose={()=>setState(s=>({...s,modalOpen: false}))}
                onOk={()=>{
                        openZip()
                        setState(s=>({...s,modalOpen: false}))
                }}>
                       <div className="d-col gap-3">
                               {
                                       state.errors.map(err=><Alert key={err} message={err} type="warning"/>)
                               }
                       </div>

                </Modal>

        </>
}

export default Bundle;