import React, {useEffect} from 'react';
import QuestionTabs from "../../_common/QuestionTabs";
import {blue} from "@ant-design/colors";
import {Tabs} from "antd";
import LenderResults from "./LenderResults";
import LenderMoreOptions from "./LenderMoreOptions";

function LenderTabs({mortgage,lenders,lenderName, lenderCode, noCurrentSubmission, currentTest}) {
    const api = lenders.find(e => e.key === lenderCode).api
    useEffect(() => {
        (async () => {
            if (api.hasOwnProperty('setForm')) {
                api.setForm(currentTest.data.figures)
            }
        })()
    }, [currentTest.data.figures]);
    if (!currentTest || !currentTest.results || !currentTest.results[lenderCode]) {
        return null
    }

    const items = [
        {
            key: '0', label: 'Results', children: <LenderResults lenderName={lenderName} spreadsheetUrl={currentTest.results[lenderCode]?.spreadsheetUrl} results={currentTest.results[lenderCode].items} mortgage={mortgage}/>,
        },
        {
            key: '1', label: 'Xls Config', children: <QuestionTabs readOnly={true} dataPoints={api.dataPoints} setAnswer={api.setAnswer} form={api.form}/>,
        },
        {
            key: '2', label: 'More Options', children: <LenderMoreOptions currentTest={currentTest} lender={lenderCode} lenderName={lenderName} results={currentTest.results[lenderCode]} mortgage={mortgage} noCurrentSubmission={noCurrentSubmission} />,
        }
    ];

    return (<>
        <div className="d-row gap-12">
            <h4 style={{
                margin: '13px 4px', color: blue[4]
            }}>{lenderName}</h4>
            <Tabs className="w-100 m-0" items={items} defaultActiveKey={['0']}/>
        </div>


    </>)
}

export default LenderTabs;