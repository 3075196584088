import React from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";
import {LONGDATE} from "../../../../../../assets/scripts/constants";
import QueryItemForm from "./QueryItemForm";
import {Avatar, Collapse, Table, Tag} from "antd";
import {CheckCircleOutlined, CheckOutlined, DownCircleOutlined} from "@ant-design/icons";
import {green, volcano} from "@ant-design/colors";
import QueryItemTabs from "./QueryItemTabs";

function QueriesInSectionCollapse({queries, referenceId}) {
    const columns = [
        {title: 'Created Date', dataIndex: 'createdAt', key: 'createdAt'},
        {title: 'Title', dataIndex: 'title', key: 'title'},
        {title: 'Description', dataIndex: 'description', key: 'description'},
        {title: 'Answered',
            render: (value,record)=>{
                if (record.lenderAnswered){
                    return <CheckCircleOutlined style={{color: green[5]}}/>
                }
                if (record.userAnswered){
                    return <CheckCircleOutlined style={{color: volcano[5]}}/>
                }
                return ''
            }, key: 'answered', align: 'center'},
        {title: 'Query', dataIndex: 'query', key: 'query'},
    ]

    // queries already restricted to a section

    let items2 = queries.map(item => {
        let userAnswered = !!item.userAnswer && item.userAnswer.length > 0
        let lenderAnswered = !!item.lenderAnswer && item.lenderAnswer.length > 0
        let tag = lenderAnswered ? <Avatar size="small" icon={<CheckOutlined/>} style={{backgroundColor: green[5]}}/> : userAnswered ? <Avatar  size="small"  icon={<CheckOutlined/>} style={{backgroundColor: volcano[5]}}/> : null
        let asterix = item.referenceId === referenceId ? <Tag color="orange">Selected Upload</Tag> : ''
        return {
            key: `group-${item.id}`,
            createdAt: dayjs(item.createdAt).format(LONGDATE),
            label: <>{asterix}{item.query}</>,
            extra: tag,
            //children: <Collapse accordion className="radius-0" items={innerItems} expandIcon={()=><DownCircleOutlined/>}/>,
            children: <div className="p-15"> <QueryItemTabs item={item}/> </div>
        }
    })
    return (

        <>
            <Collapse className="radius-0" items={items2} defaultActiveKey={['1']} rootClassName="content-m-0"/>
        </>
    );
}

export default QueriesInSectionCollapse;