import React, {useContext, useEffect} from 'react';
import {Mortgage} from "./MortgageProvider";
import {useMortgageFigures} from "../../components/client/application-stages/_common/verified-figures/useMortgageFigures";
import useApplicationUploads from "./useApplicationUploads";
import useBackendUsers from "./useBackendUsers";
import dayjs from "dayjs";
import {expandActiveSubmission} from "../MortgagesProvider";
import boiDocumentChecklist from "../../../assets/scripts/lender-checklists/boi/document-checklist";
import {particulars} from "./particulars";

function useMortgage(props) {
    const mortgage = useContext(Mortgage)
    const {me} = useBackendUsers()
    // get the last submission by createdAt
    const mortgageUploads = useApplicationUploads()

    const betterMortgage = {
        ...mortgage,
        mortgageUploads,
    }
    betterMortgage.particulars = particulars(betterMortgage)

    const checklists = {}

    if (betterMortgage.activeSubmission && betterMortgage.activeSubmission.lenderCode) {
        switch( betterMortgage.activeSubmission.lenderCode){
            case 'boi':
                checklists.SIGN = boiDocumentChecklist(betterMortgage)
                break
            default:

        }
    }

    const verifiedFigures = useMortgageFigures(betterMortgage, true)
    const unverifiedFigures = useMortgageFigures(betterMortgage, false)

    return {
        ...betterMortgage,
        figures                   : {
            verified  : verifiedFigures,
            unverified: unverifiedFigures
        },
        checklists,
        completedFlowItems        : {
            ...mortgage.completedFlowItems,
            getMarkCompleteUpdateMutation: (key, submissionID = false) => {
                if (submissionID) {
                    const submission = mortgage.submissions.find(a => a.id === submissionID)
                    let prevSubmissionItems = submission ? submission.completedFlowItemsForAdmin : {}
                    return {
                        target: `submissions[${submissionID}].completedFlowItems`,
                        value : JSON.stringify({
                            ...prevSubmissionItems,
                            [key]: new Date().toISOString()
                        })
                    }
                }
                return {
                    target: 'completedFlowItems',
                    value : JSON.stringify({
                        ...mortgage.completedFlowItems,
                        [key]: new Date().toISOString()
                    })
                }
            },
        },
        completedFlowItemsForAdmin: {
            ...mortgage.completedFlowItemsForAdmin,
            // the admin items can be faked
            getMarkCompleteUpdateMutation: (key, submissionID=false, faking = false) => {
                let record = {
                    on: new Date().toISOString(),
                    by: me.sub
                }
                if (faking) {
                    record.faked = true
                }

                if (submissionID) {
                    const submission = mortgage.submissions.find(a => a.id === submissionID)
                    let prevSubmissionItems = submission ? submission.completedFlowItemsForAdmin : {}
                    return {
                        target: `submissions[${submissionID}].completedFlowItemsForAdmin`,
                        value : JSON.stringify({
                            ...prevSubmissionItems,
                            [key]: record
                        })
                    }
                }
                return {
                    target: 'completedFlowItemsForAdmin',
                    value : JSON.stringify({
                        ...mortgage.completedFlowItemsForAdmin,
                        [key]: record
                    })
                }
            },
        },
    }
}

export default useMortgage;