import React, {useContext} from 'react';
import {Mortgages} from "../../../providers/MortgagesProvider";
import {Card, Dropdown, Typography} from "antd";
import {Link} from "react-router-dom";

function ByStage(props) {
    const mortgages = useContext(Mortgages)
    const genData = (collection) => {
        const loanValues = []
        const noLoanValue = []
        if (!collection){
            return {length:0, total:0, avg:0, noLoanValue:[]}
        }
        const loanAmounts = collection.forEach(mortgage => {
            if (!mortgage.requirement) {

            }
            else if (!mortgage.requirement.loanRequired) {
                noLoanValue.push(mortgage)
            }
            else{
                loanValues.push(mortgage.requirement.loanRequired)
            }
        })
        const total = loanValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        let avg = ((total / loanValues.length) || 0)
        if (avg){
            avg = parseInt(avg.toFixed(0))
        }
        let length = loanValues.length
        return {length, total, avg, noLoanValue}
    }

    function DataCard({title, items}){
        const {length, total, avg, noLoanValue} = genData(items)
        const probs = noLoanValue.map(mortgage=>({
            key:mortgage.id,
            label: (<Link to={`/client/${mortgage.id}`}>{mortgage.applicationTitle}</Link>)
        }))
        return (
            <Card title={title}>

                <div className="d-row j-between"><span>Total:</span> <strong>{total.toEuro()}</strong></div>
                <div className="d-row j-between"><span>Avg:</span> <strong>{avg.toEuro()}</strong></div>
                <div className="d-row j-between"><span>Count:</span> <strong>{length}</strong></div>
                {noLoanValue.length > 0 && (
                    <Dropdown menu={{items: probs}} >
                        <a onClick={(e) => e.preventDefault()}>
                            {noLoanValue.length} without loan amount
                        </a>
                    </Dropdown>
                )}
            </Card>
        )
    }
    return (
        <>
            <div className="d-row j-center"> <Typography.Title>By Stage</Typography.Title> </div>
            <div className="d-row gap-15 j-center">
                <Card title="Active Clients">
                    <div className="d-row j-between"><span>Total:</span> <strong>{mortgages.ACTIVE.length - mortgages.INCOMPLETE.length}</strong></div>
                </Card>
                <DataCard title="Loans in Setup" items={[...mortgages.SETUP,...mortgages.NEW]}/>
                <DataCard title="Loans in Apply" items={mortgages.APPLY}/>
                <DataCard title="Submitted Loans" items={mortgages.SIGN}/>
                <DataCard title="Pre-Approved Loans" items={mortgages.PREAPPROVAL}/>
                <DataCard title="Approved Loans" items={mortgages.APPROVAL}/>
                <DataCard title="Completed Loans" items={mortgages.COMPLETED}/>
            </div>
        </>
    );
}

export default ByStage;