import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Checkbox, DatePicker, Form, Input, InputNumber, Select} from "antd";
import {addressToBlocks, capitaliseFirst, capitaliseWords, wordLimit} from "../../../assets/scripts/string-functions";
import {
    ApplicationStages, BoolLike, BuildType, EmploymentBases, MortgageTypes, PersonalAccommodationSituations, PersonalMaritalStatuses, PropertyTypes, UploadStatuses,
} from "../../../models";

import dayjs from "dayjs";
import {red} from "@ant-design/colors";
import countries from "../../../assets/scripts/countries";
import {
    extendedHelpToBuySchemeDetailsQuestions, helpToBuySchemeDetailsQuestions, monthsBetweenDates
} from "../../components/client/application-stages/_common/funding/Funding";
import MortgageForm from "../../components/data-capture/MortgageForm";
import {
    customerPreApprovalDocumentsToCreate
} from "../../components/client/application-stages/pre-approval/useCustomerPreApprovalDocuments";
import {
    currentEmployerName, currentEmployerStartDate, previousEmployerName
} from "../../components/client/application/forms/sections/ApplicantEmploymentIncome";
import {ppsNumber} from "../../components/client/application/forms/sections/ApplicantIncome";

import {ApplicationUploadSections} from "./ApplicationUploadsProvider";
import {VerifiableSection} from "../../../assets/scripts/verifiable-section/verifiableSection";
import {customerApprovalDocumentsToCreate} from "../../components/client/application-stages/approval/parts/useCustomerApprovalDocuments";
import {legacyIncome} from "../../components/client/application-stages/_common/lender-tests/useXlsTests";
import {LONGDATE} from "../../../assets/scripts/constants";

useApplicationUploads.propTypes = {};
// const MetaDatePicker = (props) => {
//     return (<Form.Item noStyle
//                        {...props}
//     >
//         <DatePicker style={{width: '100%'}} format={"D MMM YYYY"}/>
//     </Form.Item>)
// }
// const MetaRangePicker = (props) => {
//
//     const {
//         dateRangeEnd, dateRangeStart, minRange, minDate, noOfMonths: numberOfMonths, ...rest
//     } = props
//     const [noOfMonths, setNoOfMonths] = useState(numberOfMonths)
//     let tooOld = false
//     if (!!minDate && !!dateRangeEnd) {
//         tooOld = minDate > new Date(dateRangeEnd)
//     }
//     let tooShort = false
//     if (!!minRange && !!dateRangeStart && !!dateRangeEnd) {
//         let diffInDays = (new Date(dateRangeEnd) - new Date(dateRangeStart)) / (1000 * 60 * 60 * 24)
//         tooShort = diffInDays < minRange
//     }
//     const updateMonths = (e) => {
//         const months = monthsBetweenDates(e[0], e[1])
//         setNoOfMonths(months)
//     }
//
//     return (<>
//             <div className="d-row a-center mb-3 gap-9" style={{color: red.primary}}>
//                 {(tooShort || tooOld) && <ExclamationCircleOutlined/>}
//                 {tooShort && <span>Not Enough</span>}
//                 {tooOld && <span className="ms-auto">Expired</span>}
//             </div>
//             <Form.Item
//                 {...rest}
//                 help={noOfMonths ? `${noOfMonths} months` : null}
//             >
//                 <DatePicker.RangePicker onChange={updateMonths} disabledDate={null} style={{width: '100%'}}
//                                         format={"D MMM YYYY"}/>
//             </Form.Item>
//
//         </>
//
//     )
// }
// const MetaEuro = (props) => {
//     return (<Form.Item noStyle
//                        {...props}>
//             <InputNumber prefix="€"
//                          className="input-number-right w-100"
//                          formatter={currencyFormatter}
//                          parser={currencyParser}/>
//         </Form.Item>
//
//     )
// }
// const MetaEuroRange = (props) => {
//     const {name} = props
//
//     return (<div className="d-row j-equal gap-9">
//         <Form.Item noStyle
//                    name={name + 'Start'}
//                    className="mb-0"
//         >
//             <InputNumber prefix="€"
//                          placeholder="Start balance"
//                          className="input-number-right w-100"
//                          formatter={currencyFormatter}
//                          parser={currencyParser}/>
//         </Form.Item>
//         <Form.Item noStyle
//                    name={name + 'End'}
//                    className="mb-0"
//         >
//             <InputNumber prefix="€"
//                          placeholder="End balance"
//                          className="input-number-right w-100"
//                          formatter={currencyFormatter}
//                          parser={currencyParser}/>
//         </Form.Item>
//     </div>)
// }
const shortFrequencies = [
    "WEEKLY",
    "FORTNIGHTLY",
    "MONTHLY",
    "QUARTERLY"
]
// const MetaPayFrequency = (props) => {
//
//     return (<Form.Item noStyle
//                        name="payFrequency"
//                        {...props}>
//         <Select style={{width: '100%'}}>
//             {shortFrequencies.map(i => (<Select.Option key={i} value={i}>{i}</Select.Option>))}
//         </Select>
//     </Form.Item>)
// }
// const MetaPayAmounts = ({index, mortgage, n}) => {
//     const name = "payAmounts"
//     return (<div className="d-col">
//         <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => {
//             return prevValues.payFrequency !== currentValues.payFrequency
//         }}>
//             {({getFieldValue}) => {
//                 let freq = getFieldValue('payFrequency')
//                 // get inputs for each payslip between now and 6 months ago
//                 const sixMonthsAgo = new Date()
//                 sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6)
//                 sixMonthsAgo.setDate(1)
//                 // const daysDifference = Math.ceil((new Date() - sixMonthsAgo)) / (1000 * 60 * 60 * 24)
//                 let inputs = []
//                 let label = ""
//                 switch (freq) {
//                     case "FORTNIGHTLY":
//                         inputs = new Array(2).fill(0)
//                         label = [
//                             "First",
//                             "Last"
//                         ]
//                         break
//                     case "WEEKLY":
//                         //const diff = Math.floor( daysDifference / 7 )
//                         label = [
//                             "First",
//                             "Last"
//                         ]
//                         inputs = new Array(2).fill(0)
//                         break;
//                     case "MONTHLY":
//                         inputs = new Array(4).fill(0)
//                         break;
//                     case "QUARTERLY":
//                         inputs = new Array(2).fill(0)
//                         break;
//                     default:
//                 }
//                 //For weekly - we need to confirm we have 13 payslips - ask for date of first pay slip and last payslip only.
//                 //For fortnightly - we need to confirm we have 7 payslips - ask for date of first payslip and last payslip only
//                 return (<>
//                     {inputs.map((item, index) => {
//                         return (<div className="d-row j-between gap-9 a-center" key={`input-row` + name + index}>
//                             {label[index]}
//                             <Form.Item
//                                 key={'gross' + name + index}
//                                 name={[
//                                     name + "Gross",
//                                     index.toString()
//                                 ]}
//                                 className="mb-3"
//                             >
//                                 <InputNumber prefix="€"
//                                              placeholder="Gross"
//                                              className="input-number-right w-100"
//                                              formatter={currencyFormatter}
//                                              parser={currencyParser}/>
//                             </Form.Item>
//                             <Form.Item
//                                 key={'pension' + name + index}
//                                 name={[
//                                     name + "Pension",
//                                     index.toString()
//                                 ]}
//                                 className="mb-3"
//                             >
//                                 <InputNumber prefix="€"
//                                              placeholder="Pension"
//                                              className="input-number-right w-100"
//                                              formatter={currencyFormatter}
//                                              parser={currencyParser}/>
//                             </Form.Item>
//                             <Form.Item
//                                 key={'avc' + name + index}
//                                 name={[
//                                     name + "AVC",
//                                     index.toString()
//                                 ]}
//                                 className="mb-3"
//                             >
//                                 <InputNumber prefix="€"
//                                              placeholder="AVC"
//                                              className="input-number-right w-100"
//                                              formatter={currencyFormatter}
//                                              parser={currencyParser}/>
//                             </Form.Item>
//                             <Form.Item
//                                 key={name + index}
//                                 name={[
//                                     name,
//                                     index.toString()
//                                 ]}
//                                 className="mb-3"
//                             >
//                                 <InputNumber prefix="€"
//                                              placeholder="Net"
//                                              className="input-number-right w-100"
//                                              formatter={currencyFormatter}
//                                              parser={currencyParser}/>
//                             </Form.Item>
//                             <Form.Item
//                                 key={'date' + name + index}
//                                 name={[
//                                     name + "Date",
//                                     index.toString()
//                                 ]}
//                                 className="mb-3"
//                             >
//                                 <DatePicker style={{width: '110px'}} format={"D MMM YYYY"}/>
//                             </Form.Item>
//                         </div>)
//                     })}
//                 </>)
//             }}
//         </Form.Item>
//         <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => {
//
//             return JSON.stringify(prevValues[name]) !== JSON.stringify(currentValues[name]) || currentValues['payFrequency'] !== prevValues['payFrequency']
//         }}>
//             {({getFieldValue}) => {
//
//                 let result = null
//                 let label = null
//                 let resultGross = null
//                 let labelGross = null
//                 let factor
//                 let amounts = getFieldValue(name)
//                 let payslipAmountsGross = getFieldValue(name + "Gross")
//                 let freq = getFieldValue('payFrequency')
//                 let amountsGross = payslipAmountsGross ? Object.values(payslipAmountsGross).filter(i => !!i) : false
//                 let sumGross = amountsGross ? amountsGross.reduce((a, b) => a + b, 0) : 0
//                 let avgGross = (sumGross / amountsGross.length)
//                 if (avgGross > 0) {
//                     resultGross = avgGross.toEuro()
//                     labelGross = "Average Gross"
//                 }
//                 if (freq === "MONTHLY") {
//                     factor = 12
//                     amounts = amounts ? Object.values(amounts).filter(i => !!i) : false
//                     let sum = amounts ? amounts.reduce((a, b) => a + b, 0) : 0
//                     let avg = (sum / amounts.length)
//                     if (avg > 0) {
//                         result = avg.toEuro()
//                         label = "Average Net"
//                     }
//
//                 }
//                 else if (freq === "FORTNIGHTLY") {
//                     factor = 26
//                     let requiredItems = 7
//                     if (getFieldValue(name + "Date")) {
//                         let {
//                             0: start, 1: end
//                         } = getFieldValue(name + "Date")
//                         if (!!start && !!end) {
//                             let daysBetween = end.diff(start, 'day');
//                             let fortnightsBetween = daysBetween / 14
//                             if (fortnightsBetween < requiredItems) {
//                                 result = `Not enough payslips - ${Math.ceil(requiredItems - fortnightsBetween)} missing`
//                             }
//                             else {
//                                 result = `${fortnightsBetween} / ${requiredItems}  payslips provided`
//                             }
//                         }
//                     }
//                 }
//                 else if (freq === "WEEKLY") {
//                     factor = 52
//                     let requiredItems = 13
//                     if (getFieldValue(name + "Date")) {
//                         let {
//                             0: start, 1: end
//                         } = getFieldValue(name + "Date")
//                         if (!!start && !!end) {
//                             let daysBetween = end.diff(start, 'day');
//                             let weeksBetween = daysBetween / 7
//                             if (weeksBetween < requiredItems) {
//                                 result = `Not enough payslips - ${Math.ceil(requiredItems - weeksBetween)} missing`
//                             }
//                             else {
//                                 result = `${weeksBetween} / ${requiredItems}  payslips provided`
//                             }
//                         }
//                     }
//                 }
//                 let claimedGrossBasic = !index ? mortgage[`applicant${n}`].income.grossBasic : mortgage[`applicant${n}`].employmentIncome.find(e => e.id === index).grossBasic
//
//                 let salaryCert = mortgage.progress.find(it => it.sectionName === `salary-cert-${n}` && it.sectionType === 'UPLOAD')
//                 let salaryData = salaryCert ? JSON.parse(salaryCert.metaData || '{}') : {}
//                 let salaryGross = salaryData.grossPay || 0
//                 return (<>
//                     {result && (<Form.Item label={label} className="mb-0">
//                         <span className="d-row j-end">{result}</span>
//                     </Form.Item>)}
//                     {resultGross && (<Form.Item label={labelGross} className="mb-0">
//                         <span className="d-row j-end">{resultGross}</span>
//                     </Form.Item>)}
//                     <table>
//                         <tbody>
//                         <tr>
//                             <td>Payslips</td>
//                             <td>{(avgGross * factor).toEuro()}</td>
//                         </tr>
//                         <tr>
//                             <td>Salary Cert</td>
//                             <td>{(salaryGross).toEuro()}</td>
//                         </tr>
//                         <tr>
//                             <td>Application</td>
//                             <td>{claimedGrossBasic.toEuro()}</td>
//                         </tr>
//                         </tbody>
//                     </table>
//                 </>)
//             }}
//         </Form.Item>
//     </div>)
// }
// const MetaYearsIncluded = (props) => {
//     const name = "yearsIncluded"
//     let currentYear = (new Date()).getFullYear()
//     const yearOptions = [];
//     for (let i = 0; i < 4; i++) {
//         const year = currentYear - i;
//         yearOptions.push({
//             label: `${year}`, value: `${year}`
//         });
//     }
//     return (<div>
//         {yearOptions.map(year => {
//             return (<Form.Item
//                 key={name + year.value}
//                 label={year.label}
//                 name={[
//                     name,
//                     year.value.toString()
//                 ]}
//                 className="mb-3"
//                 valuePropName="checked"
//             >
//                 <Checkbox/>
//             </Form.Item>)
//         })}
//     </div>)
// }
// const MetaEmploymentSummary = (props) => {
//     const name = "yearsIncluded"
//     let currentYear = (new Date()).getFullYear() - 1
//     const yearOptions = [];
//     for (let i = 0; i < 3; i++) {
//         const year = currentYear - i;
//         yearOptions.push({
//             label: `${year}`, value: `${year}`
//         });
//     }
//     return (<div>
//         <div>
//             {yearOptions.map(year => {
//                 return (<Form.Item
//                     key={name + year.value}
//                     label={year.label}
//                     name={[
//                         name,
//                         year.value.toString()
//                     ]}
//                     className="mb-3"
//                 >
//                     <InputNumber prefix="€"
//                                  className="input-number-right w-100"
//                                  formatter={currencyFormatter}
//                                  parser={currencyParser}/>
//                 </Form.Item>)
//             })}
//         </div>
//         <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => {
//             return JSON.stringify(prevValues[name]) !== JSON.stringify(currentValues[name])
//         }}>
//             {({getFieldValue}) => {
//                 let data = getFieldValue(name)
//                 let avg = 0
//                 if (data) {
//                     let values = Object.keys(data).map(key => data[key]).filter(i => !!i)
//                     let sum = values.reduce((a, b) => a + b, 0)
//                     avg = (sum / values.length).toFixed(2)
//                 }
//                 return (<Form.Item label="Average">
//                     <InputNumber value={avg} prefix="€"
//                                  className="input-number-right w-100"
//                                  formatter={currencyFormatter}
//                                  parser={currencyParser}/>
//                 </Form.Item>)
//             }}
//         </Form.Item>
//     </div>)
// }
// const MetaIBAN = (props) => {
//     return (<Form.Item noStyle
//                        name="iban"
//                        {...props}>
//         <Input className="w-100"/>
//     </Form.Item>)
//
// }
// const MetaSalariedAccount = (props) => {
//     const handleChange = async (e) => {
//         const target = `applicant${props.appIndex}.accounts[${props.account.id}].salaried`
//         await props.mortgage.mutate({
//             update: {
//                 target, value: e.target.checked
//             }
//         })
//     }
//     return (
//
//         <Form.Item
//             className="mb-0"
//             label="Salaried Account"
//             valuePropName="checked">
//             <Checkbox onChange={handleChange} defaultChecked={props.account.salaried}/>
//         </Form.Item>)
// }
const CustomHelpToBuyForm = (props) => {
    const questions = extendedHelpToBuySchemeDetailsQuestions
    return (<div className="d-row"
                 style={{borderBottom: '1px solid lightgray'}}>
        <div style={{
            fontWeight: 600,
            width     : '170px'
        }} className="p-15 shrink-not">
            Current Employment Details
        </div>
        <div className="p-15 grow"><MortgageForm questions={questions}/></div>
    </div>)

}
const CustomEmploymentSummaryDetails = ({
    mortgage,
    applicant
}) => {
    let appIndex = 'applicant' + applicant
    const legacyEmployment = !mortgage[appIndex].employmentIncome?.length || !mortgage[appIndex].employmentIncome[0].employmentType
    let lovelyProps = {
        mortgage,
        applicant,
        you: mortgage[appIndex].strings
    }
    let questions = []
    let {
            hides,
            ...rest
        } = ppsNumber(lovelyProps)
    questions.push(rest)

    if (!legacyEmployment) {
        mortgage[appIndex].employmentIncome.forEach(emp => {

            lovelyProps.index = emp.id
            let {
                    hides: h1,
                    ...rest1
                } = currentEmployerName(lovelyProps)
            questions.push({
                ...rest1,
                name: rest1.name + emp.id
            })
            let {
                    hides: h2,
                    ...rest2
                } = currentEmployerStartDate(lovelyProps)
            questions.push({
                ...rest2,
                name: rest2.name + emp.id
            })

        })
        return (<div className="d-row"
                     style={{borderBottom: '1px solid lightgray'}}>
            <div style={{
                fontWeight: 600,
                width     : '170px'
            }} className="p-15 shrink-not">
                Employment Details
            </div>
            <div className="p-15 grow">
                <MortgageForm questions={questions}/>
            </div>
        </div>)
    }

}
// const MetaRentAccount = (props) => {
//
//     const handleChange = async (e) => {
//         const target = `applicant${props.appIndex}.accounts[${props.account.id}].accommodationDD`
//         await props.mortgage.mutate({
//             update: {
//                 target, value: e.target.checked
//             }
//         })
//     }
//
//     return (
//
//         <Form.Item
//             className="mb-0"
//             label="Rent/Mortgage Account"
//
//             valuePropName="checked">
//             <Checkbox onChange={handleChange} defaultChecked={props.account.accommodationDD}/>
//         </Form.Item>)
// }
function Headings() {
}
function NoData() {
    return <span style={{color: red.primary}}>No Data</span>
}
function isWithinRange(num, ofInt) {
    const difference = Math.abs(num - ofInt);
    return difference <= 50;
}
Headings.SalaryCertHeading = ({
    mortgage,
    n,
    metaData,
    index
}) => {

    let grossBasic = !index ? mortgage[`applicant${n}`].income.grossBasic : mortgage[`applicant${n}`].employmentIncome.find(e => e.id === index).grossBasic
    const difference = !!metaData.grossPay ? Math.abs(metaData.grossPay - grossBasic) : 0;
    return (<div className="d-col gap-6">
        <div className="d-row j-between">
            <span>PPSN:</span>
            {mortgage[`applicant${n}`].tax.ppsNumber || <NoData/>}
        </div>
        <div className="d-row j-between">
            <span>Gross Basic:</span>
            {grossBasic?.toEuro() || <NoData/>}
        </div>
        {!index && !!mortgage[`applicant${n}`].income.incomeChecks && mortgage[`applicant${n}`].income.incomeChecks.map(item => {
            return (<div key={mortgage[`applicant${n}`].id + item}
                         className="d-row j-between">
                <span>{item}:</span>
                {mortgage[`applicant${n}`].income[item.toLowerCase()]?.toEuro() || <NoData/>}
            </div>)
        })}
        {!!index && !!mortgage[`applicant${n}`].employmentIncome.find(e => e.id === index).incomes && mortgage[`applicant${n}`].employmentIncome.find(e => e.id === index).incomes.filter(item => item.amount > 0).map(item => {
            return (<div key={mortgage[`applicant${n}`].id + index + item} className="d-row j-between gap-3">
                <span>{capitaliseFirst(item.name)} {item.guaranteed ? "(G)" : ""}:</span>
                <span>{item.amount.toEuro()}</span>
            </div>)
        })}
        <div style={{color: red.primary}}>
            {(difference > 50) && `€${difference.toLocaleString('en-us')} difference (basic)`}
        </div>
    </div>)
}

export const applicationUploadsSections = (mortgage, me) => {
    const getMonthsFromRange = (name, index) => {
        let section = mortgage.progress.find(it => it.sectionName === name && it.sectionIndex === index && it.sectionType === 'UPLOAD')

        if (section && section.metaData) {
            let data = JSON.parse(section.metaData)
            if (data.dateRangeStart && data.dateRangeEnd) {
                let start = dayjs(data.dateRangeStart, 'YYYY-MM-DD')
                let end = dayjs(data.dateRangeEnd, 'YYYY-MM-DD')
                return monthsBetweenDates(start, end)
            }
        }
    }

    const preApprovalCustomerDocuments = customerPreApprovalDocumentsToCreate(mortgage)
    const approvalCustomerDocuments = customerApprovalDocumentsToCreate(mortgage)
    const getQueries4Section = ({
        stage,
        name,
        index
    }) => {
        return mortgage.queries.filter(item => {
            if (item.stage === stage && item.section === name) {
                if (index) {
                    return item.index === index
                }
                return true
            }
            return false
        })
    }
    const getUploads4Section = ({
        stage,
        name,
        index
    }) => {

        const uploadsForSection = mortgage.uploads.filter(item => {
            let stageToCheck = stage
            // if ([ApplicationStages.PREAPPROVAL, ApplicationStages.APPROVAL].includes(mortgage.applicationStage) && mortgage?.activeSubmission?.soft && stageToCheck === ApplicationStages.APPLY){
            //     stageToCheck =  ApplicationStages.PREAPPROVAL
            // }
            // console.log(stageToCheck)

            if (item.stage === stageToCheck && item.section === name) {
                if (index) {
                    return item.index === index
                }
                return true
            }
            return false
        }).map(upload => {
            let queries = mortgage.queries.filter(it => it.referenceId === upload.id && it.referenceType === 'UPLOAD')

            if (queries.length) {

                let missingUserAnswers = queries.some(it => !it.private && !it.userAnswered)
                let missingLenderAnswers = queries.some(it => !it.excludedOn && !it.adminAnswered)

                return {
                    ...upload,
                    referencedQueries: {
                        are: {
                            missingUserAnswers,
                            missingLenderAnswers
                        },
                        queries
                    }
                }
            }
            return upload
        })


        return uploadsForSection

    }
    const getProgress4Section = (section) => {
        // AS OF YET, ONLY USED FOR UPLOADS
        const found = mortgage.progress.find(item => {
            if (item.deleted) {
                return false
            }
            if (item.sectionType === 'UPLOAD' && item.applicationStage === section.stage && item.sectionName === section.name) {
                if (section?.index) {
                    return item.sectionIndex === section.index
                }
                return true
            }
            return false
        })
        return found
    }
    const dynamicUploads = (stage) => mortgage ? mortgage.dynamicSections.filter(item => item.sectionStage === stage).map(it => {
        let applicant = it.sectionName.endsWith('-1') || it.sectionApplicant === 1 ? 1 : it.sectionName.endsWith('-2') || it.sectionApplicant === 2 ? 2 : false
        return {
            custom  : it.id,
            applicant,
            type    : 'UPLOAD',
            name    : it.sectionName,
            stage   : it.sectionStage,
            title   : it.sectionTitle,
            index   : it.sectionIndex,
            extras  : [],
            section : it.sectionName,
            children: it.description,
            upload  : true,
        }
    }) : []

    const applicantUploads = (n) => {
        let address = mortgage[`applicant${n}`].personal.addressCurrent
        if (mortgage.requirement.cohabiting && n === 2) {
            address = mortgage[`applicant1`].personal.addressCurrent
        }
        const uploadList = [
            {
                name : 'proof-of-address',
                title: 'proof of address', // extras: [
                //     {
                //         label: 'Ensure address matches', children: (<div className="d-col">
                //             {addressToBlocks(address).map(item => <div
                //                 key={item}>{item}</div>)}
                //         </div>)
                //     },
                //     {
                //         type     : 'date',
                //         name     : 'documentDate',
                //         label    : 'Enter date of document',
                //         component: <MetaDatePicker name="documentDate"/>
                //     },
                //     {
                //         label: 'Must be', children: (<>
                //             Mobile, electricity, gas, insurance bill, bank account, debit card statements,
                //             revenue
                //             documents
                //         </>)
                //     }
                // ]
            },
            {
                name : 'identification',
                title: 'identification', // extras: [
                //     {
                //         label: 'Ensure name & DOB match', children: (<div className="d-col gap-6">
                //             <div className="d-row j-between">
                //                 <span>Name:</span>
                //                 {mortgage[`applicant${n}`].fullName}
                //             </div>
                //             <div className="d-row j-between">
                //                 <span>Date of Birth: </span>
                //                 {mortgage[`applicant${n}`].personal.dateOfBirth ? dayjs(mortgage[`applicant${n}`].personal.dateOfBirth).format(LONGDATE) : ''}
                //             </div>
                //         </div>)
                //     },
                //     {
                //         label: 'Must be', children: (<>
                //             Stamped
                //         </>)
                //     }
                // ]
            }
        ]
        const appIndex = 'applicant' + n
        const legacyEmployment = legacyIncome(mortgage)
        //const legacyEmployment = !mortgage[appIndex].employment.employmentType

        mortgage[appIndex].accounts.forEach(account => {
            let noOfMonths = getMonthsFromRange(`current-accounts-${n}`, account.id)
            let extras = []
            const salariedAccount = mortgage[appIndex].accounts.find(it => it.salaried)
            if (!salariedAccount || salariedAccount.id === account.id) {
                let gb = (legacyEmployment) ? mortgage[appIndex].income.grossBasic || 1 : mortgage[appIndex].employmentIncome[0].grossBasic || 1
                let employer = (legacyEmployment) ? mortgage[appIndex].employment.currentEmployerName : mortgage[appIndex].employmentIncome[0].currentEmployerName || 1
                // extras.push({
                //     type     : 'salariedAccount',
                //     label    : 'Is this the salaried account (' + ((gb) / 12).toEuro() + ' / ' + employer + ')',
                //     component: <MetaSalariedAccount mortgage={mortgage} name="salariedAccount" account={account}
                //                                     appIndex={n}/>
                // })
            }
            const accommodationAccount = mortgage[appIndex].accounts.find(it => it.accommodationDD)
            if (!accommodationAccount || accommodationAccount.id === account.id) {
                let amount = (mortgage[appIndex].financial.monthlyAccommodationCosts || 0).toEuro()
                let label
                switch (mortgage[appIndex].personal.accommodationSituation) {
                    case PersonalAccommodationSituations.OTHER:
                        label = `${mortgage[appIndex].personal.accommodationSituationOther} / ${amount}`
                        break
                    case PersonalAccommodationSituations.RENTED:
                        label = `Renting / ${amount}`
                        break
                    case PersonalAccommodationSituations.OWNED:
                        label = `Has Mortgage / ${amount}`
                        break
                    case PersonalAccommodationSituations.RELATIVES:
                        label = `Living with relatives / ${amount}`
                        break

                }

                // extras.push({
                //     type     : 'rentAccount',
                //     label,
                //     component: <MetaRentAccount mortgage={mortgage} name="rentAccount" account={account}
                //                                 appIndex={n}/>
                // })
            }
            // extras.push({
            //     type: 'iban', name: 'iban', label: 'IBAN', component: <MetaIBAN/>
            // }, {
            //     type     : 'dateRange',
            //     name     : 'dateRange',
            //     label    : 'Enter start & end dates',
            //     component: <MetaRangePicker noOfMonths={noOfMonths} name="dateRange"
            //                                 minDate={new Date().setDate(new Date().getDate() - 42)}
            //                                 minRange={30 * 6}/>
            // }, {
            //     type     : 'euroRange',
            //     name     : 'euroRange',
            //     label    : 'Enter balances',
            //     component: <MetaEuroRange name="euroRange"/>
            // }, {
            //     label: 'Must be', children: (<>six consecutive months</>)
            // })
            uploadList.push({
                name          : 'current-accounts',
                title         : (account.institution || 'New Current Account'),
                index         : account.id,
                statementGroup: 'Current Accounts',
                data          : {
                    ...account,
                    typeName: 'accounts'
                }, // extras
            })
        })
        mortgage[appIndex].assets.forEach(asset => {
            let title
            // let noOfMonths = getMonthsFromRange(`assets-${n}`, asset.id)
            let isInvestment = false
            switch (asset['assetType']) {
                case 'SAVING':
                    title = (asset.institution || 'New') + ' savings'
                    break
                case 'INVESTMENT':
                    isInvestment = true
                    title = (asset.description || asset.institution || 'New') + ' investment'
                    break
                default:
                    title = 'Unspecified Asset'
            }
            // let extras = []
            // if (asset['assetType'] === 'SAVING') {
            //     extras.push({
            //         type: 'iban', name: 'iban', label: 'IBAN', component: <MetaIBAN/>
            //     },)
            // }
            // extras.push({
            //     type     : 'dateRange',
            //     name     : 'dateRange',
            //     label    : 'Enter start & end dates',
            //     component: <MetaRangePicker noOfMonths={noOfMonths} name="dateRange"
            //                                 minDate={new Date().setDate(new Date().getDate() - 42)}
            //                                 minRange={30 * 6}/>
            // }, {
            //     type     : 'euroRange',
            //     name     : 'euroRange',
            //     label    : 'Enter balances',
            //     component: <MetaEuroRange name="euroRange"/>
            // })
            uploadList.push({
                isInvestment,
                statementGroup: `${capitaliseFirst((asset['assetType'] || 'New Asset').toLowerCase())} Accounts`,
                name          : 'assets',
                title         : title,
                index         : asset.id,
                data          : {
                    ...asset,
                    typeName: 'assets'
                }, // extras
            })
        })
        mortgage[appIndex].debts.forEach(debt => {
            let title = debt.lender
            let isCredit = false
            switch (debt['debtType']) {
                case 'CREDITCARD':
                    title += ' credit card'
                    isCredit = true
                    break
                case 'STORECARD':
                    title += ' store card'
                    isCredit = true
                    break
                case 'LOAN':
                    title += ' loan'
                    break
                case 'OVERDRAFT':
                    title += ' overdraft'
                    break
                default:
                    title = 'Unspecified Debt'
            }
            let noOfMonths = getMonthsFromRange(`debts-${n}`, debt.id)
            uploadList.push({
                name          : 'debts',
                isCredit,
                title         : title,
                statementGroup: !debt['debtType'] ? 'Unspecified Account' : `${capitaliseFirst(debt['debtType'].toLowerCase())} Accounts`,
                index         : debt.id,
                data          : {
                    ...debt,
                    typeName: 'debts'
                }, // extras        : [
                //     {
                //         label: 'Monthly Payment', children: debt.monthlyPayment?.toEuro()
                //     },
                //     {
                //         type     : 'dateRange',
                //         name     : 'dateRange',
                //         label    : 'Enter start & end dates',
                //         component: <MetaRangePicker noOfMonths={noOfMonths} name="dateRange"
                //                                     minDate={new Date().setDate(new Date().getDate() - 42)}
                //                                     minRange={30 * 6}/>
                //     },
                //     {
                //         type     : 'euro',
                //         label    : 'Enter end balance',
                //         name     : 'endBalance',
                //         component: <MetaEuro name="endBalance"/>
                //     }
                // ]
            })
        })
        const selfEmployedUploads = (job) => {
            let index = job.hasOwnProperty('incomes') ? job.id : undefined
            let extraTitle = index ? ` (Self-Employed)` : ''
            return [
                {
                    name : 'payslips',
                    title: `payslips${extraTitle}`,
                    data : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    }, // extras: [
                    //     {
                    //         name     : 'payFrequency',
                    //         type     : 'payFrequency',
                    //         label    : 'Select pay frequency',
                    //         component: <MetaPayFrequency/>
                    //     },
                    //     {
                    //         name     : 'payAmounts',
                    //         type     : 'payAmounts',
                    //         label    : 'Enter net amounts',
                    //         component: <MetaPayAmounts mortgage={mortgage} n={n} index={index}/>
                    //     }
                    // ],
                    index
                },
                {
                    name : 'business-statements',
                    title: 'business bank statements',
                    data : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    }, // extras: [
                    //     {
                    //         type     : 'dateRange',
                    //         name     : 'dateRange',
                    //         label    : 'Enter start & end dates',
                    //         component: <MetaRangePicker name="dateRange"
                    //                                     minDate={new Date().setDate(new Date().getDate() - 42)}
                    //                                     minRange={30 * 6}/>
                    //     },
                    //     {
                    //         type     : 'euroRange',
                    //         name     : 'euroRange',
                    //         label    : 'Enter balances',
                    //         component: <MetaEuroRange name="euroRange"/>
                    //     },
                    //     {
                    //         label: 'Must be', children: (<>
                    //             six consecutive months
                    //         </>)
                    //     }
                    // ],
                    index
                },
                {
                    name : 'accounts',
                    title: 'financial accounts',
                    data : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    }, // extras: [
                    //     {
                    //         label   : `Business established in`,
                    //         children: !!mortgage[appIndex].employment.businessEstablished ? dayjs(mortgage[appIndex].employment.businessEstablished, 'YYYY-MM-DD').format('MMM YYYY') :
                    //             <NoData/>
                    //     },
                    //     {
                    //         type     : 'yearsArray',
                    //         name     : 'yearsIncluded',
                    //         label    : '3yrs Certified Accounts',
                    //         component: <MetaYearsIncluded/>
                    //     }
                    // ],
                    index
                },
                {
                    name : 'form11',
                    title: 'form 11',
                    data : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    }, // extras: [
                    //     {
                    //         label   : `Business established in`,
                    //         children: !!mortgage[appIndex].employment.businessEstablished ? dayjs(mortgage[appIndex].employment.businessEstablished, 'YYYY-MM-DD').format('MMM YYYY') :
                    //             <NoData/>
                    //     },
                    //     {
                    //         type     : 'yearsArray',
                    //         name     : 'yearsIncluded',
                    //         label    : '3yrs Certified Accounts',
                    //         component: <MetaYearsIncluded/>
                    //     }
                    // ],
                    index
                }, // {
                //     name: 'tax-clearance', title: 'tax clearance certificate',
                // },
                {
                    name : 'accountant-letter',
                    title: 'Accountant In Order Letter',
                    data : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    }, // extras: [
                    //     {
                    //         type     : 'date',
                    //         label    : 'Enter date of document',
                    //         name     : 'signedDate',
                    //         component: <MetaDatePicker name="signedDate"/>
                    //     }
                    // ],
                    index
                }
            ]
        }
        const employedUploads = (job) => {

            let index = job.hasOwnProperty('incomes') ? job.id : undefined
            let list = []
            let extraTitle = index ? ` (${job.employmentBasis})` : ''
            list.push({
                name : 'salary-cert',
                title: `salary certificate${extraTitle}`,
                data : {
                    ...job,
                    typeName: index ? 'employmentIncome' : 'employment'
                }, // extras: [
                //
                //     {
                //         label: 'Compare PPSN & Income', function: (metaData) => {
                //             return <Headings.SalaryCertHeading mortgage={mortgage} n={n} metaData={metaData}
                //                                                index={index}/>
                //         }
                //     },
                //     {
                //         label: 'Have you considered', children: 'Maternity Leave'
                //     },
                //     {
                //         type     : 'euro',
                //         name     : 'grossPay',
                //         label    : 'Enter Gross Basic pay',
                //         component: <MetaEuro name="grossPay"/>
                //     },
                //     {
                //         type     : 'date',
                //         label    : 'Enter date of signature',
                //         name     : 'signedDate',
                //         component: <MetaDatePicker name="signedDate"/>
                //     }
                // ],
                index
            }, {
                name : 'payslips',
                title: `payslips${extraTitle}`, // extras: [
                //     {
                //         name     : 'payFrequency',
                //         type     : 'payFrequency',
                //         label    : 'Select pay frequency',
                //         component: <MetaPayFrequency/>
                //     },
                //     {
                //         name     : 'payAmounts',
                //         type     : 'payAmounts',
                //         label    : 'Enter net amounts',
                //         component: <MetaPayAmounts mortgage={mortgage} n={n} index={index}/>
                //     }
                // ],
                data: {
                    ...job,
                    typeName: index ? 'employmentIncome' : 'employment'
                },
                index
            })
            if (job.employmentBasis === EmploymentBases.CONTRACT) {
                uploadList.push({
                    name    : 'employment-contract',
                    title   : 'Employment Contract',
                    children: 'Upload the contract you have with your employer',
                    data    : {
                        ...job,
                        typeName: index ? 'employmentIncome' : 'employment'
                    },
                    index
                })
            }
            return list
        }
        const eds = () => {
            // employment summary meta

            let jobs = legacyEmployment ? [mortgage[appIndex].employment] : mortgage[appIndex].employmentIncome
            //let extras = []
            // let extras = jobs.map(job => {
            //     return {
            //         key: job?.id || 'emp',
            //         label: 'Does start date match',
            //         children: job.currentEmployerStartDate ? dayjs(job.currentEmployerStartDate).format(LONGDATE) : '',
            //     }
            // })

            let startDateVar = jobs[0].currentEmployerStartDate
            let startDate = startDateVar ? dayjs(startDateVar) : false
            //if (startDate && !startDate.isBefore(dayjs().subtract(3, 'years'))) {
            // extras.push({
            //     type     : 'dateRange',
            //     name     : 'dateRange',
            //     label    : 'Previous employment start & end',
            //     component: <MetaRangePicker name="dateRange"/>
            // })
            // //  }
            // extras.push({
            //     type     : "yearsArray",
            //     name     : "yearsIncluded",
            //     label    : 'Enter gross basic pay',
            //     component: <MetaEmploymentSummary/>
            // })

            return {
                name : `employment-summary`,
                title: 'employment summary', //   extras,
                // customExtra: <CustomEmploymentSummaryDetails mortgage={mortgage} applicant={n}/>
            }
        }

        if (legacyEmployment) {
            switch (mortgage[appIndex].employment.employmentType) {
                case 'EMPLOYED' :
                    if (!uploadList.find(i => i.name === `employment-summary`)) {
                        uploadList.push(eds())
                    }
                    uploadList.push(...employedUploads(mortgage[appIndex].employment))
                    break
                case 'SELFEMPLOYED' :
                    uploadList.push(eds())
                    uploadList.push(...selfEmployedUploads(mortgage[appIndex].employment))
                    break
                default:
            }
        }
        else {
            if (mortgage[appIndex].employmentIncome?.length) {
                mortgage[appIndex].employmentIncome.forEach(job => {
                    switch (job.employmentType) {
                        case 'EMPLOYED' :
                            if (!uploadList.find(i => i.name === `employment-summary`)) {
                                uploadList.push(eds())
                            }
                            uploadList.push(...employedUploads(job))
                            break
                        case 'SELFEMPLOYED' :
                            if (!uploadList.find(i => i.name === `employment-summary`)) {
                                uploadList.push(eds())
                            }
                            uploadList.push(...selfEmployedUploads(job))
                            break
                        default:
                    }
                })
            }
        }

        switch (mortgage[appIndex].personal.maritalStatus) {
            case 'MARRIED':
            case 'REMARRIED':
                // are married to eachother?
                if (!(n===2 && mortgage.twoApplicants && mortgage.requirement.cohabiting && ['MARRIED','REMARRIED'].includes(mortgage.applicant1.personal.maritalStatus))) {
                    uploadList.push({
                        name : 'marriage-cert',
                        title: 'marriage certificate',
                    })
                }
                break
            case 'DIVORCED':
                uploadList.push({
                    name : 'divorce-decree',
                    title: 'decree of divorce',
                })
                break
            case PersonalMaritalStatuses.SEPARATED:
                uploadList.push({
                    name : 'separation-agreement',
                    title: 'separation agreement',
                })
                break
            default:
        }

        mortgage[appIndex].properties.forEach(property => {
            if (property.isMortgaged) {
                let title = wordLimit(property.address || 'New property')
                uploadList.push({
                    name          : 'properties',
                    statementGroup: 'Mortgage Accounts',
                    title         : title + ' mortgage',
                    relatedTo     : [`properties-${n}`],
                    index         : property.id,
                    data          : {
                        ...property,
                        typeName: 'properties'
                    }, // extras        : [
                    //     {
                    //         type     : 'dateRange',
                    //         name     : 'dateRange',
                    //         label    : 'Enter start & end dates',
                    //         component: <MetaRangePicker name="dateRange"
                    //                                     minDate={new Date().setDate(new Date().getDate() - 42)}
                    //                                     minRange={30 * 6}/>
                    //     },
                    //     {
                    //         type     : 'euroRange',
                    //         name     : 'euroRange',
                    //         label    : 'Enter balances',
                    //         component: <MetaEuroRange name="euroRange"/>
                    //     },
                    // ]
                })
            }
        })

        let answer = mortgage[appIndex].personal.nationality
        let country = !!answer ? countries.find(item => item.alpha_2_code === answer) : false
        if (!!country && !country.is_EU) {
            uploadList.push({
                name : 'residence-permit',
                title: 'Residence Permit', // extras: [
                //     {
                //         label: 'Must be', children: (<>3 months from expiry</>)
                //     }
                // ]
            })
        }
        if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
            // credit checks
            if (mortgage[appIndex].personal.otherCountryOfResidenceInPast3Years) {
                const foreign = {
                    name : 'foreign-credit-report',
                    title: 'Foreign Credit Report',
                }
                uploadList.push(foreign)
            }
            const report = {
                name : 'credit-report',
                title: 'Irish Credit Report (optional)',
            }
            uploadList.push(report)
        }
        const expandedList = uploadList.map(item => {
            item.applicant = n
            item.name = `${item.name}-${n}`
            item.stage = 'APPLY'
            item.type = 'UPLOAD'
            return item
        })
        return expandedList

    }
    const mortgageUploads = () => {
        try {
            const uploadList = []
            if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
                mortgage.properties.forEach(property => {
                    let title = wordLimit(property.address || 'Mortgaging property')

                    let obj = {
                        statementGroup: 'Mortgage Accounts',
                        name          : 'mortgage-statements',
                        title         : `${title} Mortgage`,
                        index         : property.id, // extras: [
                        //     {
                        //         type     : 'dateRange',
                        //         name     : 'dateRange',
                        //         label    : 'Enter start & end dates',
                        //         component: <MetaRangePicker name="dateRange"
                        //                                     minDate={new Date().setDate(new Date().getDate() - 42)}
                        //                                     minRange={30 * 6}/>
                        //     },
                        //     {
                        //         type     : 'euroRange',
                        //         name     : 'euroRange',
                        //         label    : 'Enter balances',
                        //         component: <MetaEuroRange name="euroRange"/>
                        //     },
                        // ]
                    }
                    obj.uploads = getUploads4Section(obj)
                    obj.record = getProgress4Section(obj)
                    uploadList.push(obj)
                })
            }
            if (mortgage.properties[0].propertyValue <= 500000 && mortgage.properties[0].buildType !== BuildType.SECONDHAND && mortgage.requirement.helpToBuyScheme === BoolLike.TRUE) {
                const htb = {
                    name       : 'htb-scheme-forms',
                    title      : 'Help to Buy Scheme ScreenShot',
                    customExtra: <CustomHelpToBuyForm/>

                }
                htb.uploads = getUploads4Section(htb)
                htb.record = getProgress4Section(htb)
                uploadList.push(htb)
            }

            if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
                const deposit = {
                    name : 'deposit',
                    title: 'Deposit Receipt',
                }
                deposit.uploads = getUploads4Section(deposit)
                deposit.record = getProgress4Section(deposit)

                if (!!mortgage.requirement.receivingGifts) {
                    const gift = {
                        name : 'gift-certificate',
                        title: 'Gift Certificate',

                    }
                    gift.uploads = getUploads4Section(gift)
                    gift.record = getProgress4Section(gift)
                    uploadList.push(gift)
                }
            }

            return uploadList.map(item => {
                return {
                    ...item,
                    applicant: false,
                    stage    : 'APPLY',
                    type     : 'UPLOAD'
                }
            })
        }
        catch (e) {
            console.log(e)
            return []
        }
    }

    // builders
    const getSetupUploads = () => {
        let setupSections = [
            'employment-summary',
            'payslips',
            'accountant-letter',
            'current-accounts',
            'form11',
            'accounts', //'tax-clearance',
            'business-statements',
            'bank-statements',
            'assets',
        ]
        let u = getApplyUploads()

        let f = u.filter(s => {
            return setupSections.some(prefix => s.name.startsWith(prefix));
        })

        let dy = dynamicUploads('APPLY')

        return [
            ...f,
            ...dy
        ]
    }
    const getApplyUploads = () => {

        let u1 = applicantUploads(1)
        let u2 = mortgage.twoApplicants ? applicantUploads(2) : []
        let m = mortgageUploads()
        let dy = dynamicUploads('APPLY')
        return [
            ...u1,
            ...u2,
            ...m,
            ...dy
        ]
    }
    const getPreApprovalUploads = () => {

        let items = []
        if (mortgage.activeSubmission?.soft) {
            items = getApplyUploads()
            console.log({items})
        }
        else {

            items.push({
                name    : 'payslip-1',
                section : 'payslip-1',
                title   : `Most Recent Payslip for ${mortgage.applicant1.firstName}`,
                children: `You need to upload ${mortgage.applicant1.firstName}'s most recent payslip`,
                stage   : ApplicationStages.PREAPPROVAL,
                upload  : true
            })
            if (mortgage.twoApplicants) {
                items.push({
                    name    : 'payslip-2',
                    section : 'payslip-2',
                    title   : `Most Recent Payslip for ${mortgage.applicant2.firstName}`,
                    children: `You need to upload ${mortgage.applicant2.firstName}'s most recent payslip`,
                    stage   : ApplicationStages.PREAPPROVAL,
                    upload  : true
                })
            }
            items.push({
                name    : 'bank-statement-1',
                section : 'bank-statement-1',
                title   : `Most Recent Bank Statement for ${mortgage.applicant1.firstName}`,
                children: `You need to upload ${mortgage.applicant1.firstName}'s most recent bank statement (into which salary is paid)`,
                stage   : ApplicationStages.PREAPPROVAL,
                upload  : true
            })
            if (mortgage.twoApplicants) {
                items.push({
                    name    : 'bank-statement-2',
                    section : 'bank-statement-2',
                    title   : `Most Recent Bank Statement for ${mortgage.applicant2.firstName}`,
                    children: `You need to upload ${mortgage.applicant2.firstName}'s most recent bank statement (into which salary is paid)`,
                    stage   : ApplicationStages.PREAPPROVAL,
                    upload  : true
                })
            }
        }

        items.push({
            name    : 'deposit-receipt',
            section : 'deposit-receipt',
            title   : `Receipt for Payment of Booking Deposit`,
            children: `You need to upload the receipt for the deposit you paid on the property`,
            stage   : ApplicationStages.PREAPPROVAL,
            upload  : true
        })

        if (mortgage.requirement.depositSavings) {
            items.push({
                name    : 'proof-of-deposit',
                section : 'proof-of-deposit',
                title   : `Proof of Deposit`,
                children: `You need to upload a statement(s) showing you have the deposit amount available`,
                stage   : ApplicationStages.PREAPPROVAL,
                upload  : true
            })
        }

        dynamicUploads('PREAPPROVAL').forEach(it => {
            items.push(it)
        })
        preApprovalCustomerDocuments.forEach(doc => {
            if (doc.folder === 'downloads') {
                items.push({
                    name    : doc.name,
                    section : doc.name,
                    title   : capitaliseWords(doc.name.replace(/-/g, ' ')),
                    children: '',
                    stage   : ApplicationStages.PREAPPROVAL,
                    upload  : true
                })
            }
        })

        return items
    }
    const getApprovalUploads = () => {
        let items = dynamicUploads('APPROVAL')
        if ([
            PropertyTypes.TERRACE,
            PropertyTypes.DETACHED,
            PropertyTypes.SEMIDETACHED
        ].includes(mortgage.properties[0].propertyType))
        {
            items.push({
                name     : 'letter-of-indemnity',
                section  : 'letter-of-indemnity',
                title    : `Home Insurance`,
                children : `You need to upload a copy of your home insurance (Letter of Indemnity), noting the lender's interest in the property`,
                applicant: false,
                stage    : ApplicationStages.APPROVAL,
                upload   : true
            })
        }
        approvalCustomerDocuments.forEach(doc => {
            if (doc.folder === 'downloads') {
                items.push({
                    name    : doc.name,
                    section : doc.name,
                    title   : capitaliseWords(doc.name.replace(/-/g, ' ')),
                    children: '',
                    stage   : ApplicationStages.APPROVAL,
                    upload  : true
                })
            }
        })
        return items
    }

    const expandAll = (items) => {
        return items.map(uploadSection => {
            let percent = 0
            let status = 'Not Started'
            return {
                ...uploadSection,
                requiresStamping: uploadSection.name.startsWith('identification') || uploadSection.name.startsWith('proof-of-address'),
                record          : getProgress4Section(uploadSection),
                uploads         : getUploads4Section(uploadSection),
                queries         : getQueries4Section(uploadSection),
                percent,
                status,
            }
        }).map(section => {
            let verified = getSuperDuperDataObject(section, mortgage, me)
            let problems = []
            let noRecordCreated = !section.record
            if (noRecordCreated) {
                problems.push({
                    label   : 'Record Not Created For Section',

                })
            }

            let reviewing = section.uploads.filter(upload => upload.status === UploadStatuses.REVIEWING)
            let pending = section.uploads.filter(upload => upload.status === UploadStatuses.PENDING)
            if (reviewing.length || pending.length) {
                problems.push({
                        label   : 'Uploads Not Checked',
                        errors: [...reviewing.map((it, i) => {
                                return `${it.fileName} is in Reviewing State`
                        }), ...pending.map((it, i) => {
                                return `${it.fileName} is in Pending State`
                        })]
                })
            }
            let activeMoreRequested = section?.record?.alertBody && section.record.alertBody.length
            if (activeMoreRequested){
                problems.push({
                    label: 'More Uploads Requested Still Active',
                    errors: section.record.alertBody
                })
            }
            let missingUserAnswers = section.queries.filter(it => !it.private && !it.userAnswered)
            if (missingUserAnswers.length){
                problems.push({
                    label: 'Queries Missing User Answer',
                    errors: missingUserAnswers.map((it, i) => {
                        return it.description
                    })
                })
            }
            let missingLenderAnswers = section.queries.filter(it => !it.excludedOn && !it.adminAnswered && (it.private || it.userAnswered))
            if (missingLenderAnswers.length){
                problems.push({
                    label: 'Queries Missing Advisor Answer',
                    errors: missingLenderAnswers.map((it, i) => {
                        return it.description
                    })
                })
            }
            if (verified.isVerifiable){
                let uploadErrors = verified.getErrors()
                if (uploadErrors.length){
                        problems.push({
                                label: 'Document Errors',
                                errors: uploadErrors
                        })
                }
                if (verified.uploads.active.some(it => !it.pdffed && ( it.contentType !== "application/pdf")) ) {
                    problems.push({
                        label: 'Uploads Not PDF',
                        errors: verified.uploads.active.filter(it => !it.pdffed && ( it.contentType !== "application/pdf")).map((it, i) => {
                            return `${it.fileName} is not a pdf`
                        })
                    })
                }
                if (section.typeName === 'accounts'){
                    let salariedAccount = !!section.applicant ? mortgage[`applicant${section.applicant}`].accounts.find(a => !!a.salaried) : false
                    if (!salariedAccount){
                        problems.push({
                            label: 'No Salaried Account Identified',
                        })
                    }
                    let accommodationAccount = !!section.applicant ? mortgage[`applicant${section.applicant}`].accounts.find(a => !!a.accommodationDD) : false
                        if (!accommodationAccount){
                                problems.push({
                                label: 'No Accommodation DD Account Identified',
                                })
                        }
                }
            }
                // if (problems.length){
                //         return {
                //                 ...section,
                //             verified,
                //                 problems
                //         }
                // }
            // if (section?.record?.sectionCheckedBy || section?.record?.submittedBy) {
            //     return {
            //         ...section,
            //         percent: 100,
            //         status : 'Complete',
            //         verified: {
            //             ...verified,
            //
            //         }
            //     }
            // }
            return {
                ...section,
                problems,
                verified
            }
        })
    }

    return {
        setup      : expandAll(getSetupUploads()),
        apply      : expandAll(getApplyUploads()),
        preapproval: expandAll(getPreApprovalUploads()),
        approval   : expandAll(getApprovalUploads()),
    }

}

function useApplicationUploads() {

    const sections = useContext(ApplicationUploadSections)

    return {
        ...sections,
       // extractedData: getUploadExtractedData(sections)
    }
}
//
// function getUploadExtractedData(sections) {
//     const data = {
//         jobs: [
//             {
//                 payslips:{
//                     index: '',
//                     avcDeduction: '',
//                     pensionDeduction: '',
//                     documentDate: '',
//                     netPay: '',
//                     grossPay: '',
//                     grossBasic: '',
//                 },
//                 salaryCert:{
//                     documentDate: '',
//                     grossBasic: '',
//                 }
//
//             },
//
//         ]
//     }
//     let {setup, apply, preapproval, approval} = sections;
//     [...apply, ...preapproval, ...approval].forEach(section => {
//         if (section.verified?.isVerifiable && section.verified.uploads.active.length) {
//
//
//             data[section.name] = section.verified.values
//         }
//     })
//     console.log({data})
// }
function getSuperDuperDataObject(section, mortgage, me) {

    return new VerifiableSection(mortgage, section, me)
    // const uploadTypes = {
    //     cardStatement             : {
    //         DataForm    : CardStatement,
    //         MortgageForm: CardApplicationData,
    //         Summary     : CardSummary,
    //         template    : cardStatement,
    //         sortDate    : cardSortDate,
    //         rowLabel    : cardRowLabel,
    //
    //     }, loanStatement          : {
    //         DataForm    : LoanStatement,
    //         MortgageForm: LoanApplicationData,
    //         Summary     : LoanSummary,
    //         template    : loanStatement,
    //         sortDate    : loanSortDate,
    //         rowLabel    : loanRowLabel
    //     }, mortgageStatement      : {
    //         DataForm    : MortgageStatement,
    //         MortgageForm: MortgageApplicationData,
    //         Summery     : MortgageSummary,
    //         template    : loanStatement,
    //         sortDate    : loanSortDate,
    //         rowLabel    : loanRowLabel
    //     }, proofOfAddress         : {
    //         DataForm    : ProofOfAddress,
    //         MortgageForm: ProofOfAddressApplicationData,
    //         Summary     : ProofOfAddressSummary,
    //         template    : proofOfAddressTemplate,
    //         sortDate    : proofOfAddressSortDate,
    //         rowLabel    : proofOfAddressRowLabel
    //     }, accountStatement       : {
    //         DataForm    : AccountStatement,
    //         MortgageForm: AccountApplicationData,
    //         Summary     : AccountSummary,
    //         template    : accountStatementTemplate,
    //         sortDate    : accountSortDate,
    //         rowLabel    : accountRowLabel,
    //         loadScan    : accountLoadScan
    //     }, payslip                : {
    //         DataForm    : Payslip,
    //         MortgageForm: PayslipApplicationData,
    //         Summary     : PayslipSummary,
    //         template    : payslipTemplate,
    //         sortDate    : payslipSortDate,
    //         rowLabel    : payslipRowLabel
    //     }, employmentDetailSummary: {
    //         DataForm    : EmploymentDetailSummary,
    //         MortgageForm: EmploymentDetailSummaryApplicationData,
    //         Summary     : EmploymentDetailSummarySummary,
    //         template    : eds,
    //         rowLabel    : (row) => 'EDS'
    //     }, giftCertificate        : {
    //         DataForm: GiftCertificate, template: giftCertificateTemplate
    //     }, salaryCertificate      : {
    //         DataForm    : SalaryCertificate,
    //         MortgageForm: SalaryCertificateApplicationData,
    //         Summary     : SalaryCertificateSummary,
    //         template    : salaryCert
    //     }
    // }
    // const getLoaderBySection = (section) => {
    //     if (section.name.startsWith('assets') && !section.isInvestment) {
    //         return uploadTypes.accountStatement
    //     }
    //     if (section.name.startsWith('debts') && section.isCredit) {
    //         return uploadTypes.cardStatement
    //     }
    //     if (section.name.startsWith('properties')) {
    //         return uploadTypes.loanStatement
    //     }
    //     if (section.name.startsWith('debts') && !section.isCredit) {
    //         return uploadTypes.loanStatement
    //     }
    //     if (section.name.startsWith('proof-of-address')) {
    //         return uploadTypes.proofOfAddress
    //     }
    //     if (section.name.startsWith('current-accounts')) {
    //         return uploadTypes.accountStatement
    //     }
    //     if (section.name.startsWith('payslips')) {
    //         return uploadTypes.payslip
    //     }
    //     if (section.name.startsWith('employment-summary')) {
    //         return uploadTypes.employmentDetailSummary
    //     }
    //     if (section.name.startsWith('gift-certificate')) {
    //         return uploadTypes.giftCertificate
    //     }
    //     if (section.name.startsWith('salary-cert')) {
    //         return uploadTypes.salaryCertificate
    //     }
    //     return null
    // }
    // let loader = getLoaderBySection(section)
    // let uploads = section.uploads.map(upload => {
    //     if (!!loader) {
    //         let values = loader.template.items.reduce((acc, tItem) => {
    //             let row = dataPointRow(upload, tItem.name)
    //             acc[tItem.name] = !!row ? getClientValue(row.verifiedValue, row.verifiedValueType) : undefined
    //             return acc
    //         }, {})
    //         let sortingColumn = loader.sortDate
    //         let answered = {
    //             all                  : Object.values(values).every(it => ![
    //                 null,
    //                 undefined
    //             ].includes(it)), none: Object.values(values).every(it => [
    //                 null,
    //                 undefined
    //             ].includes(it)), some: Object.values(values).some(it => ![
    //                 null,
    //                 undefined
    //             ].includes(it))
    //         }
    //         return {
    //             ...upload, //   manager: ()=>dataPointsManager(mortgage, me, loader.template, upload.id),
    //             values, answered, sortDate: values[sortingColumn] || null
    //         }
    //     }
    //     return upload
    // })
    // let components = {}
    // if (loader) {
    //     if (loader.hasOwnProperty('DataForm')) {
    //         components.DataForm = ({uploadId}) => <loader.DataForm key={`loader${uploadId}`} uploadId={uploadId}/>
    //     }
    //     if (!!loader && loader.hasOwnProperty('MortgageForm')) {
    //         components.MortgageForm = () => <loader.MortgageForm section={section}/>
    //     }
    //     if (!!loader && loader.hasOwnProperty('Summary')) {
    //         components.Summary = () => <loader.Summary section={{...section, uploads}}/>
    //     }
    //     if (loader.hasOwnProperty('template')) {
    //         components.template = loader.template
    //     }
    //     if (loader.hasOwnProperty('sortDate')) {
    //         components.sortDate = loader.sortDate
    //     }
    //     if (loader.hasOwnProperty('rowLabel')) {
    //         components.rowLabel = loader.rowLabel
    //     }
    //     if (loader.hasOwnProperty('loadScan')) {
    //         components.loadScan = (data) => loader.loadScan(mortgage, section, data)
    //     }
    // }

    //
    // if (loader && loader.sortDate) {
    //
    //     uploads = uploads.sort(sorterByDateName(loader.sortDate))
    //
    // }
    // return {components, uploads}

}

export default useApplicationUploads