import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {EmploymentTypes, MortgageTypes} from "../../../../../../../models";

function useApplyCustomerDocuments(props) {

    // 1. Items to copy into customer lender documents folder
    // 2. Items to create for use as download template for customer uploads
    // 3. Items to create to be docusigned by customer

    const mortgage = useMortgage()
    const items = []

    // 1. Lender Checklist

    switch (mortgage.activeSubmission.lenderCode) {
        case 'avant':
            items.push({
                name: 'terms-of-business',
                overlay: false,
                folder : 'lender-documents',
            })
            if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0) {
                items.push({
                    name  : 'avant-gift-letter',
                    copy  : true,
                    folder: 'customer-docusign'
                })
            }
            break;
        case 'ptsb':
            if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
                items.push({
                    name: 'mortgage-switcher-guide',
                    overlay: false,
                    folder : 'lender-documents',
                })
            } else {
                items.push({
                    name: 'mortgage-guide',
                    overlay: false,
                    folder : 'lender-documents',
                })
                if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0) {
                    items.push({
                        name  : 'ptsb-gift-letter',
                        copy  : true,
                        folder: 'customer-docusign'
                    })
                }
            }
            items.push({
                name: 'variable-rate-policy-statement',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'terms-conditions',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'terms-of-business',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'data-protection-notice',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'data-protection-summary',
                overlay: false,
                folder : 'lender-documents',
            })
            break;
        case 'ics':
            items.push({
                name: 'ics-mortgage-brochure',
                overlay: false,
                folder : 'lender-documents',
            })
            break
        case 'haven':
            if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
                items.push({
                    name: 'guide-to-switching-your-mortgage',
                    overlay: false,
                    folder : 'lender-documents',
                })
            }
            if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0){
                items.push({
                    name:'haven-gift-letter',
                    copy: true,
                    folder: 'customer-docusign'
                })
            }
            items.push({
                name: 'home-loan-mortgage-general-and-regulatory-information',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'homeloan-conditions',
                overlay: false,
                folder : 'lender-documents',
            })
            items.push({
                name: 'terms-of-business',
                overlay: false,
                folder : 'lender-documents'
            })
            if (mortgage.requirement.lenderProduct?.toLowerCase()?.includes('green')) {
                items.push({
                    name: 'green-mortgage-brochure',
                    overlay: false,
                    folder : 'lender-documents'
                })
            }
            break;
    }


    return  [
        {
            name: 'terms-of-business',
            key: 'terms-of-business',
            overlay: false,
            private: false,
            sussd: true,
            folder: 'sussd-documents',
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === 'sussd-documents' && doc.name === 'terms-of-business'),
        },{
            name: 'privacy-policy',
            key: 'privacy-policy',
            overlay: false,
            private: false,
            sussd: true,
            folder: 'sussd-documents',
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === 'sussd-documents' && doc.name === 'privacy-policy'),
        },
        ...items.map(item => {
            return {
                ...item,
                key: item.name,
                private: false,
                generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === item.folder && doc.name === item.name && doc.submissionID === mortgage.activeSubmission.id),
            }
        })
    ]
}

export default useApplyCustomerDocuments;