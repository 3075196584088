import React, {useEffect, useState} from "react";
import {performGQL} from "../api-functions/functions";
import {API, Hub} from "aws-amplify";
import useSubscription from "./useSubscription";

export const AdminEmails = React.createContext({})
const onCreateAdminEmails = /* GraphQL */ `
    subscription OnCreateAdminEmails(
        $filter: ModelSubscriptionAdminEmailsFilterInput
    ) {
        onCreateAdminEmails(filter: $filter) {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            appsyncVersion
            actionedOn
            actionedBy
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
const onUpdateAdminEmails = /* GraphQL */ `
    subscription OnUpdateAdminEmails(
        $filter: ModelSubscriptionAdminEmailsFilterInput
    ) {
        onUpdateAdminEmails(filter: $filter) {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            appsyncVersion
            actionedOn
            actionedBy
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
const onDeleteAdminEmails = /* GraphQL */ `
    subscription OnDeleteAdminEmails(
        $filter: ModelSubscriptionAdminEmailsFilterInput
    ) {
        onDeleteAdminEmails(filter: $filter) {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            appsyncVersion
            actionedOn
            actionedBy
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
function AdminEmailsProvider(props) {
    const [state, setState] = useState({
        items: [],
        nextToken: null
    })
    function getReceivedUnActionedEmails(nextToken) {
        const emailsByActionedSorted = /* GraphQL */ `
            query EmailsByActionedSorted(
                $actionedBy: ID!
                $sortDate: ModelStringKeyConditionInput
                $sortDirection: ModelSortDirection
                $filter: ModelAdminEmailsFilterInput
                $limit: Int
                $nextToken: String
            ) {
                emailsByActionedSorted(
                    actionedBy: $actionedBy
                    sortDate: $sortDate
                    sortDirection: $sortDirection
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                ) {
                    items {
                        id
                        from
                        to
                        cc
                        bcc
                        subject
                        hasAttachments
                        v2Attachments
                        attachments
                        sortDate
                        datetimeReceived
                        datetimeSent
                        messageId
                        originalId
                        isReceived
                        isReceivedInt
                        readOn
                        readBy
                        appsyncVersion
                        actionedOn
                        actionedBy
                        userapplicantID
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                }
            }
        `;
        const variables = {
            actionedBy: "null",
            limit: 30,
            sortDirection: 'DESC',
            filter: {isReceived: {eq: true},_deleted: {ne: true}}
        }
        if (nextToken) {
            variables.nextToken = nextToken
        }
        return performGQL(variables, emailsByActionedSorted)
    }

    const fetchEmails = async () => {
        const {items, nextToken} = await getReceivedUnActionedEmails(state.nextToken)
        if (window.location.hostname === 'localhost') {
            localStorage.setItem('emails', JSON.stringify({items, nextToken}))
        }
        setState(s => {
            let newState = {...s}
            if (s.nextToken === null) {
                newState.items = items
            } else {
                newState.items = [...newState.items, ...items]
            }
            newState.nextToken = nextToken
            return newState
        })
    }
    const setEmailStateWithSubscription = (updatingFunction) => {
        // updatingFunction is a function that expects the previous state and returns the next state
        if (typeof updatingFunction === 'function') {
            setState(s => {
                const newState = updatingFunction(s.items);

                return {
                    ...s,
                    items: newState
                }
            })
        }
    }
    useSubscription({subscription: {
            onCreate: onCreateAdminEmails,
            onUpdate: onUpdateAdminEmails,
            onDelete: onDeleteAdminEmails,
            name: 'AdminEmails'
        }, setState: setEmailStateWithSubscription})

    useEffect(() => {
        if (window.location.hostname === 'localhost'){
            const existing = localStorage.getItem('emails')
            if (existing){
                setState(JSON.parse(existing))
                return
            }
        }
        if (!state.items.length) {
            fetchEmails()
        }
    }, []);
    // useEffect(() => {
    //
    //     // Hub.listen('api', (data) => {
    //     //
    //     //     const {payload} = data;

    //     //
    //     // });
    //
    //     // Subscribe to update
    //     const updateSub = API.graphql({query: subscriptions.onUpdateAdminEmails}).subscribe({
    //         next: ({provider, value}) => {


    //             const item = value.data.onUpdateAdminEmails
    //             if (item) {
    //                 setState(s => {
    //                     if (s.items.find(it => it.id === item.id)){
    //                         return {
    //                             ...s,
    //                             items: s.items.map(it => {
    //                                 if (it.id === item.id) {
    //                                     return item
    //                                 }
    //                                 return it
    //                             })
    //                         }
    //                     }
    //                     else{
    //                         return {
    //                             ...s,
    //                             items: [...s.items, item]
    //                         }
    //                     }
    //
    //                 })
    //             }
    //         },
    //         error: (error) => console.warn(error)
    //     });
    //     // Subscribe to creation
    //     const createSub = API.graphql({query: subscriptions.onCreateAdminEmails}).subscribe({
    //         next: ({provider, value}) => {


    //             setState(s => ({
    //                 ...s,
    //                 items: [...s.items, value.data.onCreateAdminEmails]
    //             }))
    //         },
    //         error: (error) => console.warn(error)
    //     });
    //     // Subscribe to deletion
    //     const deleteSub = API.graphql({query: subscriptions.onDeleteAdminEmails}).subscribe({
    //         next: ({provider, value}) => {


    //             setState(s => ({
    //                 items: [...s.items].filter(it => it.id !== value.data.onDeleteAdminEmails.id)
    //             }))
    //         },
    //         error: (error) => console.warn(error)
    //     });
    //     return () => {
    //         createSub.unsubscribe();
    //         updateSub.unsubscribe();
    //         deleteSub.unsubscribe();
    //     }
    //
    // }, []);
    return (
        <AdminEmails.Provider value={{
            items: state.items.sort((a, b) => {
                if (a.sortDate > b.sortDate) {
                    return -1;
                }
                if (a.sortDate < b.sortDate) {
                    return 1;
                }
                return 0;
            }),
            fetchMoreEmails: fetchEmails,
            hasMore: !!state.nextToken
        }}>
            {props.children}
        </AdminEmails.Provider>
    );
}
export default AdminEmailsProvider