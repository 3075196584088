import React, {useContext, useState} from 'react';
import {AdminEmails} from "./AdminEmailsProvider";
import {ApplicationStages, UploadStatuses} from "../../models";
import useApplicationUploads from "./mortgage/useApplicationUploads";

export const Sidebar = React.createContext({});

function SidebarProvider(props) {
    const [stateBadges,setStateBadges] = useState({})
    const setBadge = (key,value)=>{
        setStateBadges(b=>({...b, [key]:value}))
    }
    const setBadges = (pairs)=>{
        setStateBadges(b=>({...b, ...pairs}))
    }
    const [mortgage, setMortgage] = useState(null)

    let clientKey = null
    if (mortgage) {
        switch (mortgage.applicationStage) {
            case ApplicationStages.APPLY:
                clientKey = 'stages.apply'
                break
            case ApplicationStages.SETUP:
                clientKey = 'stages.on-boarding'
                break
            case ApplicationStages.SIGN:
                clientKey = 'stages.sign'
                break
            case ApplicationStages.PREAPPROVAL:
                clientKey = 'stages.pre-approval';
                break;
            case ApplicationStages.APPROVAL:
                clientKey = 'stages.approval';
                break;
            default:
                clientKey = 'stages.on-boarding'
        }
    }
    return (
        <Sidebar.Provider
            value={{
                badges:stateBadges,
                clientKey,
                id: !!mortgage ? mortgage.id : null,
                setMortgage,
                setBadge,
                setBadges
            }}>
            {props.children}
        </Sidebar.Provider>
    );
}

export default SidebarProvider;