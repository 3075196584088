import {performGQL} from "../functions";

const adminEmailsByUserApplicant = /* GraphQL */ `
    query AdminEmailsByUserApplicant(
        $userapplicantID: ID!
        $sortDate: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelAdminEmailsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminEmailsByUserApplicant(
            userapplicantID: $userapplicantID
            sortDate: $sortDate
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                from
                to
                cc
                bcc
                subject
                hasAttachments
                v2Attachments
                attachments
                sortDate
                datetimeReceived
                datetimeSent
                messageId
                originalId
                isReceived
                isReceivedInt
                readOn
                readBy
                appsyncVersion
                actionedOn
                actionedBy
                userapplicantID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
        }
    }
`;
export function getApplicantEmails(applicantId, nextToken) {
    const variables = {
        userapplicantID: applicantId,
        limit: 20,
        sortDirection: 'DESC',
    }
    if (nextToken) {
        variables.nextToken = nextToken
    }
    return performGQL(variables, adminEmailsByUserApplicant)
}