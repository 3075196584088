import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {balanceOfFundsFunds} from "../../funding/BalanceOfFunds";
import {Badge, Table} from "antd";
import {TableHeading} from "../FiguresUsed";
import {cyan} from "@ant-design/colors";

function Funds({override={}}) {
    const mortgage = useMortgage()
    const {
              loan,
              gifts,
              helpToBuy,
              firstHome,
              requiredSavings,
              bookingDepositPaid,
              contractDepositPaid,
              totalFunds
          } = balanceOfFundsFunds(mortgage, override)
    const fundsColumns = [
        {
            key      : 'label',
            title    : '',
            dataIndex: 'label',
            className: 'align-top',
        },
        {
            key      : 'value',
            title    : '',
            dataIndex: 'value',
            align    : 'right',
            render   : (text) => text?.toLocaleString('en-us'),
        }
    ]
    const fundsDatasource = [{
        key  : 'savings',
        label: <strong>Own Contrib</strong>,
        value: requiredSavings,
    }]

    if (helpToBuy > 0) {
        let noRealHelpToBuyAmount = !mortgage.requirement.helpToBuySchemeAmount
        let itemToPush = {
            key  : 'helpToBuy',
            label: 'Help to Buy',
            value: helpToBuy,
        }
        if (noRealHelpToBuyAmount) {
            itemToPush.label = <Badge size="small" offset={[10,0]} count="Est">Help To Buy</Badge>
        }
        fundsDatasource.push(itemToPush)
    }
    if (firstHome > 0) {
        fundsDatasource.push({
            key  : 'firstHome',
            label: 'First Home',
            value: firstHome,
        })
    }
    if (gifts > 0) {
        fundsDatasource.push({
            key  : 'gifts',
            label: 'Gifts',
            value: gifts,
        })
    }
    if (bookingDepositPaid > 0) {
        fundsDatasource.push({
            key  : 'bookingDepositPaid',
            label: 'Booking Deposit Paid',
            value: bookingDepositPaid,
        })
    }
    if (contractDepositPaid > 0) {
        fundsDatasource.push({
            key  : 'contractDepositPaid',
            label: 'Contract Deposit Paid',
            value: contractDepositPaid,
        })
    }
    fundsDatasource.push({
        key  : 'loan',
        label: 'Mortgage',
        value: loan,
    })
    return (
        <Table title={() => <TableHeading title="Funding" color={cyan.primary} total={totalFunds}/>} size="small"  showHeader={false}
               dataSource={fundsDatasource} columns={fundsColumns}
               pagination={false}/>
    );
}

export default Funds;