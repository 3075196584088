import React from 'react';

function DifferenceHighlighter({ str1, str2 }) {
    const getWords = (str) => (str||'').replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").split(' ');

    const highlightDifferences = (words1, words2) => {
        let wordsWithHighlight =  words2.map((word, index) => {
            if (words1[index] !== word) {
                return <span key={index} style={{ backgroundColor: '#ffcebe', borderRadius:3, margin: '0 -3px', padding: '0 3px' }}>{word}</span>;
            }
            return <span key={index}>{word}</span>;
        });
        let len = wordsWithHighlight.length;
        return wordsWithHighlight.flatMap((word, index) => {
            if (index === len - 1) {
                return [word];
            }
            return [word, ' '];
        });
    };

    const words1 = getWords(str2);
    const words2 = getWords(str1);

    return (
        <span>
            {highlightDifferences(words1, words2)}
        </span>
    );
}

export default DifferenceHighlighter;