import React, {useContext, useEffect, useRef, useState} from 'react';
import {Mortgages} from "../../../providers/MortgagesProvider";
import {Typography} from "antd";
import {Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis} from "recharts";
import {ApplicationStatuses} from "../../../../models";

function Forecast(props) {


    const mortgages = useContext(Mortgages)
    const getDrawdownDate = (mort) =>{
        if (mort.activeSubmission?.drawdownDate) {
            return mort.activeSubmission.drawdownDate
        }
        return mort.requirement.drawdownDate
    }
    const all = mortgages.ALL.filter(it => it.applicationStatus !== ApplicationStatuses.SUSPENDED && !!getDrawdownDate(it))


    const getLastDrawdownDate = () => {
        const dates = all.map(mortgage => getDrawdownDate(mortgage))
        return dates.reduce((a, b) => {
            const aD = new Date(a)
            const bD = new Date(b)
            return aD > bD ? aD : bD
        });
    }

    const getDateOfFirstDayOfEveryMonthBetweenNowAndEndDate = (endDate) => {
        let currentDate = new Date();
        currentDate.setDate(1)
        currentDate.setMonth(currentDate.getMonth() - 3)
        let months = [];
        while (currentDate <= endDate) {
            months.push(new Date(currentDate));
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        return months;
    }

    const getDateOfFirstAndLastDateInMonth = (date) => {
        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
        const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
        return [firstDate, lastDate];
    }

    const getMortgagesBetweenDates = (firstDate, lastDate) => {
        return all.filter(mortgage => {
            const drawdown = new Date(getDrawdownDate(mortgage));
            return drawdown >= firstDate && drawdown <= lastDate;
        })
    }
    
    const filterAllByMonth= (dateInMonth) => {
        const [firstDate, lastDate] = getDateOfFirstAndLastDateInMonth(dateInMonth);
        const filteredMortgages = getMortgagesBetweenDates(firstDate, lastDate)

        const amounts = filteredMortgages.map(mortgage => mortgage.requirement.loanRequired)

        const total = amounts.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        return {
            names: filteredMortgages.map(it=>it.applicant1.fullName),
            total: total
        }
    }

    function getForecastedByClosingMonths() {
        const date= getLastDrawdownDate()
        const months = getDateOfFirstDayOfEveryMonthBetweenNowAndEndDate(date)
        return  months.map(monthDate=> {
            let total= filterAllByMonth(monthDate)
            return {
                name:monthDate.toLocaleString('default', {month: 'short', year: 'numeric'}),
                ...total,
            }
        })
    }



    const data = all.length ? getForecastedByClosingMonths() : []

    const total = data.reduce((acc, current) => acc + current.total, 0);
    const CustomTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffffe0', padding: '10px', border: '1px solid #cccccc' }}>
                    <strong>{`${label}. €${payload[0].value?.toLocaleString('en-US')}`} </strong>
                    {payload[0].payload.names.map(name=>
                        <p key={name} className="intro">{name}</p>
                    )}
                </div>
            );
        }

        return null;
    };
    return (
        <>
            <div className="d-row j-center"><Typography.Title>Drawdown Forecast </Typography.Title></div>
            <div className="d-row j-center">
                <BarChart
                    width={1100}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <Tooltip content={<CustomTooltip />} />
                    <YAxis />

                    <Bar dataKey="total" fill="#8884d8"/>
                </BarChart>


            </div>

        </>
    );
}

export default Forecast;