import React from 'react';
import {purple} from "@ant-design/colors";
import {Table} from "antd";
import {accumulateMortgageFigures, CellRender, mortgageFiguresColumns, TableHeading} from "../FiguresUsed";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";

function RepaymentCapacity({verified}) {
    const mortgage = useMortgage()
    const figures = verified ? mortgage.figures.verified: mortgage.figures.unverified

    let prefixes = [
        'savings',
        'accommodationCosts',
        'clearingLoans',
        'otherPRA'
    ]
    let rows = prefixes.map(key => {
        let row = {
            key: key,
            label: key,
            app1value: figures[`${key}1`]
        }
        if (mortgage.twoApplicants) {
            row.app2value = figures[`${key}2`]
        }
        return row
    }).filter(row => {
        if (row.app2value) {
            return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning && row.app2value.value === 0 && !row.app2value.error && !row.app2value.warning)
        }
        return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning)
    })
    let totalRepaymentCapacity = accumulateMortgageFigures(rows)

    return (<Table title={() => <TableHeading title="Repayment Capacity" color={purple.primary}
                                              total={totalRepaymentCapacity}/>} size="small"
                   dataSource={rows} columns={mortgageFiguresColumns(mortgage)}
                   pagination={false}/>);
}

export default RepaymentCapacity;