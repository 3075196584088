import React from 'react';
import NoteWrite from "../../components/client/notes/NoteWrite";
import NotesList from "../../components/client/notes/NotesList";
import {Col, Row} from "antd";

function NotesPage(props) {
    return (
        <Row>
            <Col md={12}>
                <NotesList/>
            </Col>
            <Col md={12}>
                <div  className="p-sticky">
                    <NoteWrite/>
                </div>

            </Col>
        </Row>
    );
}

export default NotesPage;