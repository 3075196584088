import React from 'react';
import DocumentsTable from "../../_common/documents/DocumentsTable";
import {ApplicationStages} from "../../../../../../models";
import useApplyCustomerDocuments from "./customer-documents/useApplyCustomerDocuments";

function ApplyCustomerDocuments(props) {
    const items = useApplyCustomerDocuments()
    return <DocumentsTable items={items} stage={ApplicationStages.APPLY} done="createApplyCustomerDocuments"/>
}

export default ApplyCustomerDocuments;