import React, {useEffect} from 'react';
import {listAllAndSetItems, performGQL} from "../../../../../api-functions/functions";
import {adminMortgageTaskToDosByTaskID} from "../../../../../../graphql/queries";
import {
    createAdminMortgageTaskToDo,
    deleteAdminMortgageTaskToDo,
    updateAdminMortgageTaskToDo
} from "../../../../../../graphql/mutations";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";

function useToDos(props) {
    const backendUsers = useBackendUsers()
    const [toDos, setToDos] = React.useState([]);
    const customSetTodos = (newToDos) => {
        setToDos(s => {
            return [...s, ...newToDos.filter(td=>!td._deleted).filter(td => !s.find(t => t.id === td.id))]
        })
    }
    const load = async () => {
        await listAllAndSetItems({taskID: props.taskID}, adminMortgageTaskToDosByTaskID, customSetTodos)
    }
    const saveToDo = async (todo) => {
        const query = createAdminMortgageTaskToDo
        const variables = {
            input: {
                toDoItem: todo,
                taskID: props.taskID,
                author: backendUsers.me.sub
            }
        }
        const result = await performGQL(variables, query)
        if (result) {
            setToDos(s => [...s.filter(td => td.id !== "temp"), result])
        }
    }
    const updateToDo = async (input) => {
        const query = updateAdminMortgageTaskToDo
        const variables = {
            input
        }
        setToDos(s => s.map(td => {
            if (td.id === input.id) {
                return {
                    ...td,
                    ...input
                }
            }
            return td
        }))
        const result = await performGQL(variables, query)
        if (result) {
            setToDos(s => s.map(td => {
                if (td.id === input.id) {
                    return result
                }
                return td
            }))
        }
    }
    const updateTick = (todoID, _version, bool) => {
        const input = {
            id: todoID,
            _version,
            completedOn: bool ? new Date().toISOString() : null,
            completedBy: bool ? backendUsers.me.sub : null
        }
        return updateToDo(input)
    }
    const updateItemText = (todoID, _version, text) => {
        const input = {
            id: todoID,
            _version,
            toDoItem: text
        }
        return updateToDo(input)
    }
    const deleteToDo = async (todoID, _version) => {
        setToDos(s => s.filter(td => td.id !== todoID))
        await performGQL({
            input: {
                id: todoID,
                _version
            }
        }, deleteAdminMortgageTaskToDo)


    }
    useEffect(() => {
        if (backendUsers.me) {
            load()
        }
    }, [backendUsers.me]);
    return {toDos, setToDos, saveToDo, updateTick, updateItemText, deleteToDo}
}

export default useToDos;