import React, {useState} from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import useApplicationUploads from "../../../../../providers/mortgage/useApplicationUploads";
import {Badge, Button, Calendar, Card, Checkbox, Form, Input, InputNumber, Popover, Select, Switch, Table} from "antd";
import {balanceOfFundsFunds} from "./BalanceOfFunds";
import {currencyFormatter, currencyParser} from "../../../../../../assets/scripts/parser-functions";
import {ArrowDownOutlined, ArrowsAltOutlined, ArrowUpOutlined, CalendarOutlined, ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {blue, cyan, orange} from "@ant-design/colors";
import "./danger-row.css"
import {verifiedAccounts, verifiedInvestments} from "./verifiedAccounts";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {updateUploadVerifiedRow} from "../../../../../../assets/scripts/verifiable-section/verifiableSection";

dayjs.extend(utc);
dayjs.extend(timezone);

function SavingsAnalysis(props) {
    const mortgage = useMortgage()
    const {apply: sections} = useApplicationUploads()

    const excludedAccounts = mortgage.progress.filter(item => !!item.excludeFromCalculations).map(item => item.id)
    const initialState = {}
    excludedAccounts.forEach(item => {
        initialState[item] = true
    })
    if (!mortgage) {
        return (<div/>)
    }
    const handleExcludeCheckClick = (id, checked) => {
        mortgage.mutate({
            update: {
                target: `progress[${id}].excludeFromCalculations`,
                value : checked
            }
        })
    }
    const handleFormChange = (value) => {
        for (let key in value) {
            handleExcludeCheckClick(key, value[key])
        }
    }
    const TransfersToInvestments = ({record}) => {
        let investmentSections = sections.filter(item => item.isInvestment)

        let transfersToInvestments = record.toInvestments
        // transfersToInvestments is the array of transfers to investments on each upload of this section
        let result = {}
        transfersToInvestments.forEach(keyValePair => {
            let key = Object.keys(keyValePair)[0]
            let value = keyValePair[key]
            if (!result.hasOwnProperty(key)) {
                result[key] = {
                    title: investmentSections.find(item => item.data.id === key).title,
                    transfers:[]
                }
            }
            result[key].transfers.push(value)
        })
        let dataSource = Object.keys(result).map(key => {
            let item = result[key]
            return {
                title: item.title,
                value: item.transfers.reduce((acc, item) => acc + item, 0)
            }
        })
        const cols = [
            {
                title    : 'Investment',
                dataIndex: 'title',
                key      : 'title'
            },
            {
                title    : 'Amount',
                dataIndex: 'value',
                key      : 'value',
            }
        ]
        return <Table size="small" dataSource={dataSource} columns={cols} pagination={false}/>
    }
    // get all the bank accounts
    const columns = [
        {
            title : "Exclude",
            render: (value, record) => {
                if (record.bottomRow) {
                    return null
                }
                return (<Form.Item
                    valuePropName="checked"
                    noStyle name={record.id}>
                    <Checkbox/>
                </Form.Item>)
            },
            key   : "exclude"
        },

        {
            title    : "Owner",
            dataIndex: "owner",
            key      : "owner"
        },
        {
            title    : "Account",
            dataIndex: "account",
            key      : "account"
        },
        {
            title : '<',
            key   : 'editStart',
            render: (value, record) => {
                if (record.isNew && !record.error) {
                    return <NewOrClosedAdjustmentCalendar type="editStart" record={record}/>
                }
            }
        },
        {
            title    : "Range",
            dataIndex: "range",
            key      : "range",
            render   : (value, row) => {
                if (row.error) {
                    return row.error
                }
                return value + (!!row.diffInMonths ? ` (${row.diffInMonths} mth)` : '')
            }
        },
        {
            title : '>',
            key   : 'editEnd',
            render: (value, record) => {
                if (record.isClosed && !record.error) {
                    return <NewOrClosedAdjustmentCalendar type="editEnd" record={record}/>
                }

            }
        },
        {
            title    : "Opening Balance",
            dataIndex: "openingBalance",
            key      : "openingBalance",
            align    : "right",
        },
        {
            title    : "Closing Balance",
            dataIndex: "closingBalance",
            key      : "closingBalance",
            align    : "right",
        },
        {
            title : "Adjust",
            render: (value, record) => {

                if (record.bottomRow) {
                    return null
                }

                const content = <>
                    <Form
                        onFinish={async (values) => {
                            const progress = mortgage.progress.find(item => item.id === record.id)
                            let metaData = JSON.parse(progress?.metaData || '{}')
                            delete metaData.adjustment
                            if (values.adjustment.amount > 0) {
                                metaData.balanceAdjustment = values.adjustment
                            }
                            else {
                                delete metaData.balanceAdjustment
                            }
                            await mortgage.mutate({
                                update: {
                                    target: `progress[${record.id}].metaData`,
                                    value : JSON.stringify(metaData)
                                }
                            })
                        }}
                        initialValues={{adjustment: record.adjustment}}
                    >
                        <div className="d-row gap-3 a-center mb-3">
                            <Form.Item
                                name={[
                                    'adjustment',
                                    'adjust'
                                ]}
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message : 'Please select an adjustment'
                                    }
                                ]}
                            >
                                <Select placeholder="Select adjustment" style={{width: 200}}
                                        options={[
                                            {
                                                value: "up",
                                                label: "Up"
                                            },
                                            {
                                                value: "down",
                                                label: "Down"
                                            }
                                        ]}/>
                            </Form.Item>
                            <Form.Item
                                name={[
                                    'adjustment',
                                    'amount'
                                ]}
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message : 'Please input the amount'
                                    }
                                ]}
                            >
                                <InputNumber style={{width: '100%'}} prefix="€"
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </div>
                        <div className="d-row gap-3 a-center">
                            <Form.Item
                                noStyle
                                required
                                name={[
                                    'adjustment',
                                    'explanation'
                                ]}>
                                <Input.TextArea placeholder="Explanation for Cover Note (required)" autoSize={{
                                    minRows: 3,
                                    maxRows: 10
                                }}/>
                            </Form.Item>
                            <Form.Item
                                noStyle>
                                <Button type="primary" htmlType="submit">Adjust</Button>
                            </Form.Item>
                        </div>
                    </Form>
                    <div className="mt-3">
                        <Button danger onClick={async () => {
                            const progress = mortgage.progress.find(item => item.id === record.id)
                            let metaData = JSON.parse(progress?.metaData || '{}')
                            delete metaData.balanceAdjustment
                            await mortgage.mutate({
                                update: {
                                    target: `progress[${record.id}].metaData`,
                                    value : JSON.stringify(metaData)
                                }
                            })
                        }}>Delete</Button>
                    </div>
                </>

                return (<Popover
                        content={content}
                        title="Make Closing Balance Adjustment Up or Down"
                        trigger="click"
                        className="mx-auto"
                    >
                        <div className="d-row gap-9 j-center">
                            {!!record.adjustment && record.adjustment.adjust === 'up' && <><ArrowUpOutlined
                                style={{color: cyan.primary}}/>{record.adjustment.amount.toLocaleString('en-us')}</>}
                            {!!record.adjustment && record.adjustment.adjust === 'down' && <><ArrowDownOutlined style={{color: blue.primary}}/>{record.adjustment.amount.toLocaleString('en-us')}</>}
                            {!record.adjustment && <ArrowsAltOutlined/>}
                        </div>
                    </Popover>

                )
            },
            key   : "adjust"
        },
        {
            title    : "Total Difference",
            dataIndex: "totalDifference",
            key      : "totalDifference",
            align    : "right",

        },
        {
            title    : "Average Monthly",
            dataIndex: "averageMonthly",
            key      : "averageMonthly",
            align    : "right",
            render   : (value, record) => {
                if (record.hasOwnProperty('toInvestments') && record.toInvestments.length > 0) {
                    return (<Popover title={<div className="text-center"> Reversed Transfers to Investments</div>} content={<TransfersToInvestments record={record}/>}>
                            <span style={{
                                whiteSpace: 'nowrap',
                                color     : orange[6]
                            }}><ExclamationCircleOutlined/> {value}</span>
                        </Popover>)
                }
                return value
            }
        },
    ]

    //TODO: refactor this to include investment accounts

    //let source1 = viewingNewFunding ? verifiedFunds(mortgage, sectionsWithoutInvestmentAccounts) :savingsDataSource(mortgage, sectionsWithoutInvestmentAccounts)
    // let {dataSource, averageMonthlySavings, totalClosingBalance,totalOpeningBalance,  totalTotalDifference, maxMonths} = source1
    let accountByCurrency = verifiedAccounts(mortgage, sections)
    let investmentsByCurrency = verifiedInvestments(mortgage, sections)

    const cumulativeRow = (openingBalance, closingBalance, averageMonthly, bottomRow = false, totalDifference = '', currency) => {
        return {
            currency,
            owner  : '',
            account: '',
            range  : '',
            openingBalance,
            closingBalance,
            totalDifference,
            averageMonthly,
            bottomRow
        }
    }

    const addSymbolsToAccount = (account) => {
        let symbol = account.currency
        let currencyOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }

        return {
            ...account,
            openingBalance : `${symbol}${account.openingBalance.toLocaleString('en-us', currencyOptions)}`,
            closingBalance : `${symbol}${account.closingBalance.toLocaleString('en-us', currencyOptions)}`,
            totalDifference: `${symbol}${account.totalDifference.toLocaleString('en-us', currencyOptions)}`,
            averageMonthly : `${symbol}${account.averageMonthly.toLocaleString('en-us', currencyOptions)}`
        }
    }
    const addSymbolsToInvestment = (account) => {
        let symbol = account.currency
        let currencyOptions = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
        return {
            ...account,
            closingBalance: `${symbol}${account.closingBalance.toLocaleString('en-us', currencyOptions)}`,
        }
    }

    if (Object.keys(accountByCurrency).length === 0) {
        return null
    }
    let allAccounts = JSON.parse(JSON.stringify(accountByCurrency['€']))

    delete accountByCurrency['€']

    let avgMonthlyString = allAccounts.averageMonthlySavings

    const lastRow = cumulativeRow(allAccounts.totalOpeningBalance, allAccounts.totalClosingBalance, avgMonthlyString, true, allAccounts.totalTotalDifference, '€')
    allAccounts.dataSource.push(lastRow)

    const {requiredSavings} = balanceOfFundsFunds(mortgage)

    let shortfall = requiredSavings - allAccounts.totalClosingBalance
    if (shortfall < 0) {
        shortfall = 0
    }

    allAccounts.dataSource = allAccounts.dataSource.map(item => addSymbolsToAccount(item))
    // allAccounts.dataSource.push(cumulativeRow('Asserted', (mortgage.requirement.depositSavings || 0).toEuro(), ' ', true, ' '))
    // allAccounts.dataSource.push(cumulativeRow('Shortfall', shortfall.toEuro(), ' ', true, ' '))

    Object.keys(accountByCurrency).forEach(key => {
        const lastRow = cumulativeRow(accountByCurrency[key].totalOpeningBalance, accountByCurrency[key].totalClosingBalance, accountByCurrency[key].averageMonthlySavings, true, accountByCurrency[key].totalTotalDifference, accountByCurrency[key].currency)
        accountByCurrency[key].dataSource.push(lastRow)
        accountByCurrency[key].dataSource = accountByCurrency[key].dataSource.map(item => addSymbolsToAccount({
            ...item,
            currency: key
        }))
        allAccounts.dataSource.push(...accountByCurrency[key].dataSource)
    })
    const investColumns = [
        {
            title : "Exclude",
            render: (value, record) => {
                if (record.bottomRow) {
                    return null
                }
                return (<Form.Item
                    valuePropName="checked"
                    noStyle name={record.id}>
                    <Checkbox/>
                </Form.Item>)
            },
            key   : "exclude"
        },
        {
            title    : "Owner",
            dataIndex: "owner",
            key      : "owner"
        },
        {
            title    : "Account",
            dataIndex: "account",
            key      : "account"
        },
        {
            title    : "Closing Balance",
            dataIndex: "closingBalance",
            key      : "closingBalance",
            align    : "right",
        },
        {
            title : "Adjust",
            render: (value, record) => {

                if (record.bottomRow) {
                    return null
                }

                const content = <>
                    <Form
                        onFinish={async (values) => {
                            const progress = mortgage.progress.find(item => item.id === record.id)
                            let metaData = JSON.parse(progress?.metaData || '{}')
                            delete metaData.adjustment
                            if (values.adjustment.amount > 0) {
                                metaData.balanceAdjustment = values.adjustment
                            }
                            else {
                                delete metaData.balanceAdjustment
                            }
                            await mortgage.mutate({
                                update: {
                                    target: `progress[${record.id}].metaData`,
                                    value : JSON.stringify(metaData)
                                }
                            })
                        }}
                        initialValues={{adjustment: record.adjustment}}
                    >
                        <div className="d-row gap-3 a-center mb-3">
                            <Form.Item
                                name={[
                                    'adjustment',
                                    'adjust'
                                ]}
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message : 'Please select an adjustment'
                                    }
                                ]}
                            >
                                <Select placeholder="Select adjustment" style={{width: 200}}
                                        options={[
                                            {
                                                value: "up",
                                                label: "Up"
                                            },
                                            {
                                                value: "down",
                                                label: "Down"
                                            }
                                        ]}/>
                            </Form.Item>
                            <Form.Item
                                name={[
                                    'adjustment',
                                    'amount'
                                ]}
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message : 'Please input the amount'
                                    }
                                ]}
                            >
                                <InputNumber style={{width: '100%'}} prefix="€"
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </div>
                        <div className="d-row gap-3 a-center">
                            <Form.Item
                                noStyle
                                name={[
                                    'adjustment',
                                    'explanation'
                                ]}>
                                <Input placeholder="Explanation"/>
                            </Form.Item>
                            <Form.Item
                                noStyle>
                                <Button type="primary" htmlType="submit">Adjust</Button>
                            </Form.Item>
                        </div>
                    </Form>
                    <div className="mt-3">
                        <Button danger onClick={async () => {
                            const progress = mortgage.progress.find(item => item.id === record.id)
                            let metaData = JSON.parse(progress?.metaData || '{}')
                            delete metaData.balanceAdjustment
                            await mortgage.mutate({
                                update: {
                                    target: `progress[${record.id}].metaData`,
                                    value : JSON.stringify(metaData)
                                }
                            })
                        }}>Delete</Button>
                    </div>
                </>

                return (<Popover
                        content={content}
                        title="Make Closing Balance Adjustment Up or Down"
                        trigger="click"
                        className="mx-auto"
                    >
                        <div className="d-row gap-9 j-center">
                            {!!record.adjustment && record.adjustment.adjust === 'up' && <><ArrowUpOutlined
                                style={{color: cyan.primary}}/>{record.adjustment.amount.toLocaleString('en-us')}</>}
                            {!!record.adjustment && record.adjustment.adjust === 'down' && <><ArrowDownOutlined style={{color: blue.primary}}/>{record.adjustment.amount.toLocaleString('en-us')}</>}
                            {!record.adjustment && <ArrowsAltOutlined/>}
                        </div>
                    </Popover>

                )
            },
            key   : "adjust"
        },
    ]

    function NewOrClosedAdjustmentCalendar({
        type,
        record
    })
    {
        const {me} = useBackendUsers()
        let newDate = null
        let typeName = ''
        switch (type) {
            case 'editStart':
                newDate = dayjs(record.startDate, "YYYY-MM-DD")
                typeName = 'Start Date For New Account'
                break
            case 'editEnd':
                newDate = dayjs(record.endDate, "YYYY-MM-DD")
                typeName = 'End Date For Closed Account'
                break
            default:
        }
        const [visible, setVisible] = useState(false);
        const [busy, setBusy] = useState(false);
        const handleVisibleChange = (newVisible) => {
            setVisible(newVisible);
        };
        const handleSelect = async (date, {source}) => {
            if (source === "date") {
                setBusy(true)
                setVisible(false)
                switch (type) {
                    case 'editStart':

                        await updateUploadVerifiedRow(mortgage, record.startDateRow, {
                            verifiedValue : date.format('YYYY-MM-DD'),
                            verificationOn: new Date().toISOString(),
                            verificationBy: me.sub
                        })
                        break
                    case 'editEnd':
                        await updateUploadVerifiedRow(mortgage, record.endDateRow, {
                            verifiedValue : date.format('YYYY-MM-DD'),
                            verificationOn: new Date().toISOString(),
                            verificationBy: me.sub
                        })
                        break
                    default:
                }
                setBusy(false)
            }
        }
        if (busy) {
            return <LoadingOutlined/>
        }
        return (<Popover
                title={`Change ${typeName}`}
                trigger="click"
                open={visible}
                onOpenChange={handleVisibleChange}
                content={<div style={{width: 300}}>
                    <Calendar
                        defaultValue={newDate}
                        fullscreen={false}
                        onSelect={handleSelect}
                    />
                </div>}
            >
                <CalendarOutlined style={{cursor: 'pointer'}}/>
            </Popover>)
    }
    return (<>
            <Card title="Savings Overview" styles={{body: {padding: 0}}}>
                <Form
                    onValuesChange={handleFormChange}
                    initialValues={initialState}
                >


                    <Table
                        dataSource={allAccounts.dataSource}
                        columns={columns}
                        pagination={false}
                        size="small"
                        rowClassName={(record, index) => {
                            if (record.error) {
                                return 'danger-row'
                            }
                            if (record.account === '') {
                                return 'bold-row'
                            }
                            //if (record.openingBalance === 'Shortfall') return 'red-row'
                            return ''
                        }}
                    />

                </Form>
            </Card>
            {Object.keys(investmentsByCurrency).map(key => {
                let dataSource = investmentsByCurrency[key].dataSource.map(item => addSymbolsToInvestment({
                    ...item,
                    currency: key
                }))
                return (<Card title="Investments Overview" styles={{body: {padding: 0}}}>
                    <Table
                        dataSource={dataSource}
                        columns={investColumns}
                        pagination={false}
                        size="small"
                        rowClassName={(record, index) => {
                            if (record.error) {
                                return 'danger-row'
                            }
                            if (record.account === '') {
                                return 'bold-row'
                            }
                            return ''
                        }}
                    />
                </Card>)
            })}
        </>

    );
}

export default SavingsAnalysis;