import React, {useState} from 'react';
import {AutoComplete, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Typography} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../assets/scripts/parser-functions";
import {Geo} from "aws-amplify";
import countries from "../../../../../assets/scripts/countries.json";
import {correctValueForDb} from "../../../../providers/mortgage/MortgageProvider";
import {isFuture} from "date-fns";

const {TextArea} = Input;
function Address(props) {
    const minWords = 2
    const maxWords = 5
    const [state, setState] = useState({
        options: [],
        fetching: false,
        string: ''
    })

    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const onSelect = (data) => {

    };
    const onSearch = (text) => {
        setState(prev => ({...prev, string: text}))
        if (!state.fetching) {
            const noResults = !state.options.filter(address => address.value.startsWith(text)).length
            if (noResults) {
                const words = text.split(" ");
                if (words.length > minWords && words.length < maxWords) {
                    getItems(text)
                }
            }
        }

    }
    const getItems = async (v) => {
        setState(prev => ({...prev, fetching: true}))

        const results = await Geo.searchByText(v, {
            countries: ["IRL"], // Alpha-3 country codes
            maxResults: 50, // 50 is the max and the default
        })
        const opts = results.map(item => ({value: item.label}))
        setState(prev => ({...prev, fetching: false, options: opts}))
    }
    return <AutoComplete
        {...props}
        options={state.options.filter(opt => opt.value.startsWith(state.string))}
        onSelect={onSelect}
        onSearch={onSearch}
    />
}
function DOBArray(props) {

    return <Form.Item
        {...props}
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
            return prevValues.countDependents !== currentValues.countDependents
        }}
    >
        {({getFieldValue}) => {
            return (
                <Form.List
                    name={props.id}
                >
                    {(fields, {add, remove}, {errors}) => {
                        return (
                            <Row key={`ages-${fields.length}`}>
                                {fields.map((field, index) => {
                                    return (<Col xs={24} key={`col-${field.key}`}>
                                        <Form.Item
                                            {...field}
                                            noStyle
                                            rules={[
                                                {
                                                    validator: async (_, value) => {
                                                        if (!value) {
                                                            return Promise.reject(new Error('Missing Date of Birth'));
                                                        }
                                                        let err = false
                                                        let str = correctValueForDb('ddmmyyyy', value)
                                                        if (str) {
                                                            let dte = new Date(str)
                                                            if (isFuture(dte)) {
                                                                return Promise.reject(new Error('Future Date Not Permitted'));
                                                            }
                                                            if (err) {
                                                                return Promise.reject(new Error('Invalid Date of Birth'));
                                                            }
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                },
                                            ]}
                                        >
                                            <DateString onBlur={props.onBlur} style={{marginBottom: 3}}/>
                                        </Form.Item>

                                    </Col>)
                                })}
                            </Row>)
                    }
                    }
                </Form.List>
            )
        }}

    </Form.Item>

}
export function DateString(props) {
    function formatDate(str) {
        const correctDay = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 3) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 31) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctMonth = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 1) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 12) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctYear = (str) => {
            const maxYear = ((new Date()).getFullYear() + 49).toString().slice(0, -1)
            // detect and correct YY format to YYYY
            if ([5, 6, 7, 8, 9].includes(parseInt(str))) {
                str = 190 + parseInt(str)
            }
            if ([0].includes(parseInt(str))) {
                str = 200 + parseInt(str)
            }
            str = str.toString()
            // prevent starting a year incorrectly
            if (
                (str.length === 1 && ![1, 2].includes(parseInt(str))) ||
                (str.length === 2 && ![19, 20].includes(parseInt(str))) ||
                (str.length === 3 && (parseInt(str) < 195 || parseInt(str) > maxYear))) {
                str = str.slice(0, -1)
            }
            return str
        }
        const parts = str.split('/').map(item => item.trim())
        const newParts = parts.map((part, i) => {
            if (props?.noDays) {
                if (i === 0) {
                    return correctMonth(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctYear(part)
                }
            } else {
                if (i === 0) {
                    return correctDay(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctMonth(part, parts.length > 2)
                }
                if (i === 2) {
                    return correctYear(part)
                }
            }
        })
        // add next part when part is completed
        if ((newParts.length === 1 && newParts[0].length === 2)) {
            newParts.push('')
        }
        if (!props?.noDays) {
            if (newParts.length === 2 && newParts[1].length === 2) {
                newParts.push('')
            }
        }
        return newParts.join(' / ')
    }
    let {noDays, ...other} = props
    let elementProps = {...other}
    elementProps.onKeyPress = function (e) {
        let maxLength = props?.noDays ? 9 : 14
        if (e.key === 'Backspace' || e.keyCode === 8) {
            return true
        }

        if (e.target.value.length >= maxLength) {
            e.preventDefault()
            return false
        }

        //if the cursor is at the end of the field, format to a date like string
        if (e.target.value.length === e.target.selectionStart && e.target.selectionEnd - e.target.selectionStart === 0) {
            e.preventDefault()

            const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);

            // prevent if not a digit or slash
            if (!(/\d/.test(charStr)) && charStr !== '/') {
                return false
            }

            let orig = e.target.value

            // if slash, make sure there isn't a previous one
            if (charStr === '/' && orig.trimRight().endsWith('/')) {
                return false
            }

            const temp = orig + '' + charStr

            //only allow a slash if the day or month is 1 char len
            // if (charStr === '/') {
            //     //if ([1, 7].includes(orig.length)) {
            //         formatDate(temp)
            //    // } else {
            //    //     return false
            //    // }
            // }
            e.target.value = formatDate(temp)
            props.onChange(e)
            return true
        }

    }
    elementProps.placeholder = props?.noDays ? 'MM / YYYY' : 'DD / MM / YYYY'
    // if (!elementProps.hasOwnProperty('style')){
    //     elementProps.style={}
    // }
    // elementProps.style.width='100%'
    return (<Input {...elementProps} />)
}
function Phone(props) {
    const prefix = ['0402', '0404', '0504', '0505', '01', '071', '074', '083', '085', '086', '087', '089', '090']
    const array = ['2', '4', '5', '6', '9']
    array.forEach(secondDigit => {
        let i = 1
        while (i < 10) {
            let newPfx = `0${secondDigit}${i}`
            if (!['092', '054', '055'].includes(newPfx)) {
                prefix.push(newPfx)
            }
            i += 1
        }
    })
    let onKeyPress = function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            return e.target.blur()
        }
        if (e.key === 'Backspace' || e.keyCode === 8) {
            return
        }

        const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        const charStr = String.fromCharCode(charCode);
        const digit = (/\d/.test(charStr) || ['(', ')', '-'].includes(charStr))
        if (!digit) return

        // if the cursor is at the end of the field, format to a date like string
        if (e.target.value.length === e.target.selectionStart && e.target.selectionEnd - e.target.selectionStart === 0) {
            let orig = e.target.value
            const charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
            const charStr = String.fromCharCode(charCode);
            let temp = orig + '' + charStr
            if (temp === '00') {
                e.preventDefault()
                e.target.value = "+"
                return
            }
            if (prefix.includes(temp)) {
                e.preventDefault()
                temp = `(${temp}) `
                e.target.value = temp
                return
            }

            const dashLen = temp.includes('(01)') ? 8 : 9
            const l = temp.split('')
            if (l.includes('(') && l.includes(')') && l.length === dashLen) {
                e.preventDefault()
                e.target.value = `${temp}-`
                return
            }
        }
    }
    return (<Input {...props} onKeyPress={onKeyPress}/>)
}
function Country(props) {
    let keyName = props?.nationality ? "nationality" : "en_short_name"
    let options = countries.map(item => {
        return {
            label: item[keyName],
            value: item.alpha_2_code
        }
    })
    let {nationality, ...other} = props
    return <Select
        showSearch
        {...other}
        options={options}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
        filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
    />
}

export function fakeForm(vars){
    return {
        getFieldValue: (name) => {
            return vars[name]
        }
    }
}
function SectionQuestion(props) {
    const renderLabel = (question, f) => {
        let t = question.text
        if (typeof question.text === 'function') {
            t = question.text(f)
        }
        return <div key={t} style={{display: 'flex', flexDirection: 'column'}}>
            <Typography.Text style={{margin: 0}}>{question.label}</Typography.Text>
            {/*<Typography>{t}</Typography>*/}
        </div>
    }
    const renderQuestion = (question, f) => {
        if (Array.isArray(question)){
            return (
                <div className="d-row gap-9 j-equal">

                    {question.map((q, i) => renderQuestion(q, f))}

                </div>
            )
        }

        let rules = question.rules
        if (typeof question.rules === "function") {
            rules = question.rules(f)
        }
        let disabled = !!question.answer.disabled
        switch (question.answer.type) {
            case 'weight':
                return <Form.Item
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                    validateFirst
                    onBlur={handleOnBlur}
                >
                    <InputNumber addonAfter={"kg"} precision={1} style={{width: '100%'}} onBlur={handleOnBlur}/>
                </Form.Item>
            case 'height':
                return <Form.Item
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                    validateFirst
                    onBlur={handleOnBlur}
                >
                    <InputNumber addonAfter={"cm"} precision={1} style={{width: '100%'}} onBlur={handleOnBlur}/>
                </Form.Item>
            case 'phone':
                return <Form.Item
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                    validateFirst
                    onBlur={handleOnBlur}
                >
                    <Phone
                        precision={0}
                    />
                </Form.Item>
            case 'euro':
                return <Form.Item
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    //tooltip="Enter the value/price of the property"
                    rules={rules}
                    validateFirst
                >
                    <InputNumber
                        addonBefore={"€"}
                        precision={0}
                        formatter={currencyFormatter}
                        parser={currencyParser}
                        style={{width: '100%'}}
                        onBlur={handleOnBlur}
                    />
                </Form.Item>
            case 'int':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}

                >
                    <InputNumber
                        precision={0}
                        style={{width: '100%'}}
                        onBlur={handleOnBlur}
                    />
                </Form.Item>
            case 'float':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <InputNumber
                        precision={2}
                        style={{width: '100%'}}
                        onBlur={handleOnBlur}
                    />
                </Form.Item>
            case 'text':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Input
                        onBlur={handleOnBlur}
                        style={{width: '100%'}}
                    />
                </Form.Item>
            case 'choice':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Radio.Group style={{display: 'flex', width: '100%'}}>
                        {
                            question.answer.choices.map(item => <Radio.Button onClick={handleOnBlur} style={{flex: 1}}
                                                                              value={item.value}
                                                                              key={`${question.text}${item.value}`}>{item.text}</Radio.Button>)
                        }
                    </Radio.Group>
                </Form.Item>
            case 'select':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Select onSelect={handleOnBlur} disabled={disabled}>
                        {
                            question.answer.choices.map(item => <Select.Option
                                value={item.value} key={`${question.text}${item.value}`}>{item.text}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
            case 'address':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Address onBlur={handleOnBlur}/>
                </Form.Item>
            case 'ddmmyyyy':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <DateString onBlur={handleOnBlur}/>
                </Form.Item>
            case 'date':
            return <Form.Item
                validateFirst
                key={question.text}
                name={question.name}
                label={renderLabel(question, f)}
                rules={rules}
            >
                <DatePicker format="D MMM YYYY" style={{width: '100%'}} onBlur={handleOnBlur}/>
            </Form.Item>
            case 'mmyyyy':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <DateString onBlur={handleOnBlur} noDays={true}/>
                </Form.Item>
            case 'country' :
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Country onSelect={handleOnBlur}/>
                </Form.Item>
            case 'nationality' :
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Country onSelect={handleOnBlur} nationality={true}/>
                </Form.Item>
            case 'textarea':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <TextArea onBlur={handleOnBlur}/>
                </Form.Item>
            case 'agearray':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <DOBArray onBlur={handleOnBlur}/>
                </Form.Item>
            case 'multichoice':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}
                >
                    <Checkbox.Group style={{display: 'flex', flexDirection: 'column'}}>
                        <Row>
                            {
                                question.answer.choices.map(item => <Col span={12} style={{marginBottom: 3}}
                                                                         key={question.answer.label + item.value}>
                                    <Checkbox onChange={handleOnBlur} size='large'
                                              value={item.value}>{item.text}</Checkbox>
                                </Col>)
                            }
                        </Row>
                    </Checkbox.Group>

                </Form.Item>
            case 'email':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid e-mail',
                        }
                    ]}>
                    <Input type="email" onBlur={handleOnBlur}/>
                </Form.Item>
            case 'datepicker':
                return <Form.Item
                    validateFirst
                    key={question.text}
                    name={question.name}
                    label={renderLabel(question, f)}
                    rules={rules}

                >
                    <DatePicker
                        format="DD MMM YYYY"
                        style={{width: '100%'}}
                        onBlur={handleOnBlur}
                    />
                </Form.Item>
            default:
                return <div key={question.text}
                            style={{padding: 5, backgroundColor: 'red'}}>{question.answer.type}</div>
        }
    }
    const renderStyledQuestion = (question, f) => {
        return <div className="styler" style={props.style}>
            {renderQuestion(question, f)}
        </div>
    }
    const handleOnBlur = () => {
        props.onBlur(props.question)
    }
    if (!!props.question?.hides || typeof props.question.text === "function" || typeof props.question.label === "function" || typeof props.question.rules === "function") {
        return <Form.Item
            noStyle
            shouldUpdate={true}>
            {(f) => {
                if (props.question?.hides && props.question.hides(f)) {
                    return <></>
                }
                return renderStyledQuestion(props.question, f)
            }}
        </Form.Item>
    }

    return renderStyledQuestion(props.question)
}

export default SectionQuestion;