import React from 'react';
import {ApplicationStages} from "../../../../../../models";
import DocumentsTable from "../../_common/documents/DocumentsTable.js"
import {Alert, Button, Card} from "antd";
import useCustomerApprovalDocuments from "./useCustomerApprovalDocuments";
function CustomerDocuments() {
    const items = useCustomerApprovalDocuments()

    let allGenerated = items.every(it => !!it.generated)
    return (<Card>
        <div className="d-col gap-15">

            {/*{!allGenerated ?*/}
            {/*    (<Alert message={"Customer documents need to be generated"} type="warning"/>)*/}
            {/*    :*/}
            {/*    (<Alert  message={"Customer documents have been generated"} type="success"/>)*/}
            {/*}*/}


            <DocumentsTable items={items} stage={ApplicationStages.APPROVAL}/>


            <div className="d-row j-end gap-15">

                <Button>Start Your Uploads Email</Button>
            </div>
        </div>

    </Card>)
}
export default CustomerDocuments;