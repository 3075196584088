import {AssetTypes, BuildType, DebtTypes, EmploymentBases, EmploymentTypes, Frequencies, PersonalMaritalStatuses} from "../../../../models";
import countries from "../../countries.json";
import {legacyIncome} from "../../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";
import {balanceOfFundsCosts, balanceOfFundsFunds} from "../../../../app/components/client/application-stages/_common/funding/BalanceOfFunds";

export default function (mortgage, config) {
    // --------------------------------------------------------------------------------------------
    // OVERLAY SECTIONS

    // Balance of Funds
    const {
              price,
              stampDuty,
              legalFees,
              propertyValuation,
              totalCosts
          } = balanceOfFundsCosts(mortgage)
    const costs = [
        {
            key   : 1,
            name  : "Price",
            amount: price
        },
        {
            key   : 2,
            name  : "Stamp Duty",
            amount: stampDuty
        },
        {
            key   : 3,
            name  : "Legal Fees",
            amount: legalFees
        },
        {
            key   : 4,
            name  : "Property Valuation",
            amount: propertyValuation
        }
    ]
    const {
              loan,
              gifts,
              helpToBuy: htbScheme,
              firstHome,
              requiredSavings,
              bookingDepositPaid,
              contractDepositPaid
          } = balanceOfFundsFunds(mortgage)

    const funds = []
    if (loan > 0) {
        funds.push({
            key   : 0,
            name  : "Mortgage",
            amount: loan
        })
    }
    if (gifts > 0) {
        funds.push({
            key   : 1,
            name  : "Gifts",
            amount: gifts
        })
    }
    if (htbScheme > 0) {
        funds.push({
            key   : 2,
            name  : "Help to Buy",
            amount: htbScheme
        })
    }
    if (firstHome > 0) {
        funds.push({
            key   : 3,
            name  : "First Home",
            amount: firstHome
        })
    }
    if (bookingDepositPaid > 0){
        funds.push({
            key   : 4,
            name  : "Booking Deposit Paid",
            amount: bookingDepositPaid
        })
    }
    if (contractDepositPaid > 0){
        funds.push({
            key   : 5,
            name  : "Contract Deposit Paid",
            amount: contractDepositPaid
        })
    }
    funds.push({
        key   : 6,
        name  : "Savings",
        amount: requiredSavings
    })

    //Nicely space the cells so totals are at the bottom
    if (funds.length > costs.length) {
        let diff = funds.length - costs.length
        for (let i = 0; i < diff; i++) {
            costs.push({
                key   : `c.${i}`,
                name  : "",
                amount: ""
            })
        }
    }
    else if (costs.length > funds.length) {
        let diff = costs.length - funds.length
        for (let i = 0; i < diff; i++) {
            funds.push({
                key   : `f.${i}`,
                name  : "",
                amount: ""
            })
        }
    }
    costs.push({
        key   : 'tF',
        name  : "TOTAL",
        amount: totalCosts
    })
    funds.push({
        key   : 'tC',
        name  : "TOTAL",
        amount: totalCosts
    })
    let  map = {}
    costs.forEach((cost, index) => {
        map[`costs${index + 1}`] = cost.name
        map[`costs${index + 1}-a`] = cost.amount.toString()
    })
    funds.forEach((fund, index) => {
        map[`funds${index + 1}`] = fund.name
        map[`funds${index + 1}-a`] = fund.amount.toString()
    })
    
    // --------------------------------------------------------------------------------------------
    // FILL SECTIONS
    
    let legacyEmployment = legacyIncome(mortgage)
    let paye
    let selfEmployed
    let payeMonthly
    let payeWeekly
    let contract
    if (legacyEmployment) {
        paye = mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED || mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED
        selfEmployed
            = mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED || mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED
        payeMonthly = paye && ( mortgage.applicant1.income.payFrequency === Frequencies.MONTHLY || mortgage.twoApplicants && mortgage.applicant2.income.payFrequency === Frequencies.MONTHLY )
        payeWeekly = paye && ([Frequencies.WEEKLY, Frequencies.FORTNIGHTLY].includes( mortgage.applicant1.income.payFrequency)  || [Frequencies.WEEKLY, Frequencies.FORTNIGHTLY].includes( mortgage.applicant2.income.payFrequency))
        contract = false
    }
    else {
        paye
            = mortgage.applicant1.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED)
        selfEmployed
            = mortgage.applicant1.employmentIncome.find(e => e.employmentType === EmploymentTypes.SELFEMPLOYED) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e => e.employmentType === EmploymentTypes.SELFEMPLOYED)
        payeMonthly = paye && ( mortgage.applicant1.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && e.payFrequency === Frequencies.MONTHLY) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && e.payFrequency === Frequencies.MONTHLY) )
        payeWeekly = paye && ( mortgage.applicant1.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && [Frequencies.WEEKLY, Frequencies.FORTNIGHTLY].includes(e.payFrequency)) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && [Frequencies.WEEKLY, Frequencies.FORTNIGHTLY].includes(e.payFrequency)) )
        contract = mortgage.applicant1.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && e.employmentBasis === EmploymentBases.CONTRACT) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e => e.employmentType === EmploymentTypes.EMPLOYED && e.employmentBasis === EmploymentBases.CONTRACT)
    }
    let selfBuild = mortgage.properties[0].buildType === BuildType.SELFBUILD


    let fill = {}
    let ask = []

    // proof of identity
    fill['undefined'] = {check: true}

    // Proof of address
    fill['Proof of address dated within last 6 months'] = {check: true}

    // Broker declaration identity form
    fill['undefined_4'] = {check: true}

    // Marriage Certificate
    let anybodyMarried = [
        PersonalMaritalStatuses.MARRIED,
        PersonalMaritalStatuses.REMARRIED
    ].includes(mortgage.applicant1.personal.maritalStatus)
    if (!anybodyMarried && mortgage.twoApplicants) {
        anybodyMarried = [
            PersonalMaritalStatuses.MARRIED,
            PersonalMaritalStatuses.REMARRIED
        ].includes(mortgage.applicant2.personal.maritalStatus)
    }
    fill['undefined_2'] = {check: !!anybodyMarried}

    // Residency Permit
    let permitRequired = false
    let answer = mortgage.applicant1.personal.nationality
    let country = !!answer ? countries.find(item => item.alpha_2_code === answer) : false
    if (!!country && !country.is_EU) {
        permitRequired = true
    }
    if (!permitRequired && mortgage.twoApplicants) {
        answer = mortgage.applicant2.personal.nationality
        country = !!answer ? countries.find(item => item.alpha_2_code === answer) : false
        if (!!country && !country.is_EU) {
            permitRequired = true
        }
    }
    fill['undefined_3'] = {check: !!permitRequired}

    //National ID Card
    ask.push({
        name: 'nationalIdCard',
        label: 'National ID Card',
        type: 'CHECK',
        group: 'CHECKS'
    })
    if (config.hasOwnProperty('nationalIdCard')) {
        fill['undefined_5'] = {check: !!config.nationalIdCard}
    }
    else{
        fill['undefined_5'] = {check: false}
    }


    //Critical skills employment permit
    ask.push({
        name: 'criticalSkillsPermit',
        label: 'Critical Skills Permit',
        type: 'CHECK',
        group: 'CHECKS'
    })
    if (config.hasOwnProperty('criticalSkillsPermit')) {
        fill['undefined_6'] = {check: !!config.criticalSkillsPermit}
    }
    else {
        fill['undefined_6'] = {check: false}
    }

    // *** PAYE ***

    //Salary Cert
    fill['toggle_9'] = {check: !!paye}

    //Payslips - Monthly
    fill['3 payslips monthly and not older than 6 weeks'] = {check: !!payeMonthly}

    //Payslips - Weekly/Fortnightly
    fill['4 payslips weeklyfortnightly and not older'] = {check: !!payeWeekly}

    //EDS
    fill['Latest Employment Detail Summary EDS or if'] = {check: !!paye}

    //Contract
    fill['undefined_7'] = {check: !!contract}

    // Employer letter
    ask.push({
        name: 'employerLetter',
        label: 'Employer Letter',
        type: 'CHECK',
        group: 'CHECKS'
    })
    if (config.hasOwnProperty('employerLetter')) {
        fill['undefined_8'] = {check: !!config.employerLetter}
    }
    else{
        fill['undefined_8'] = {check: false}
    }

    // Social protection income statement
    fill['undefined_9'] = {check: false}

    // *** Self Employed ***

    // Certified/audited accounts x2 most
    fill['toggle_16'] = {check: !!selfEmployed}

    // Form 11 or chapter 4 x2 most recent years
    fill['Form 11 or chapter 4 x2 most recent years'] = {check: !!selfEmployed}

    // ROS charges payments statement or
    fill['ROS charges  payments statement or'] = {check: !!selfEmployed}

    // Management accounts
    fill['undefined_10'] = {check: !!selfEmployed}

    // Evidence of contracts
    fill['undefined_11'] = {check: !!selfEmployed}

    // Form 11 confirming rental income
    let rentalIncome = false
    if (mortgage.applicant1.properties.find(p => p.isRented) || (mortgage.twoApplicants && mortgage.applicant2.properties.find(p => p.isRented))) {
        rentalIncome = true
    }
    fill['undefined_12'] = {check: !!rentalIncome}

    // P21 (statement of liability)
    fill['undefined_13'] = {check: false}


    // *** Personal Bank Statements ***
    fill['Personal current account statements must'] = {check: true}

    // Business current account statements (must cover 6 months
    fill['Business current account statements must cover 6 months'] = {check: !!selfEmployed}

    // Other current account statements
    let otherAccounts = mortgage.applicant1.accounts.length > 1 || mortgage.twoApplicants && mortgage.applicant2.accounts.length > 1
    fill['Other current account statements eg Revolut'] = {check: !!otherAccounts}

    // Loan Statements
    let hasLoans = mortgage.applicant1.debts.find(d => d.debtType === DebtTypes.LOAN) || mortgage.twoApplicants && mortgage.applicant2.debts.find(d => d.debtType === DebtTypes.LOAN)
    fill['Loan facility statement for the past 12 months if not evident'] = {check: !!hasLoans}

    // Gift Letter
    let receivingGift = mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0
    fill['undefined_14'] = {check: !!receivingGift}

    // Investment account statements
    let hasInvestments = mortgage.applicant1.assets.find(a => a.assetType === AssetTypes.INVESTMENT) || mortgage.twoApplicants && mortgage.applicant2.assets.find(a => a.assetType === AssetTypes.INVESTMENT)
    fill['Investment account'] = {check: !!hasInvestments}

    // Personal savings account
    let hasSavings = mortgage.applicant1.assets.find(a => a.assetType === AssetTypes.SAVING) || mortgage.twoApplicants && mortgage.applicant2.assets.find(a => a.assetType === AssetTypes.SAVING)
    fill['Personal savings account statements within the last 6'] = {check: !!hasSavings}

    // Booking deposit receipt
    let hasPaidBooking = mortgage.requirement.depositPaid > 0
    fill['undefined_15'] = {check: !!hasPaidBooking}

    // Help to Buy confirmation
    let helpToBuy = mortgage.requirement.helpToBuyScheme && mortgage.requirement.helpToBuySchemeAmount > 0
    fill['undefined_16'] = {check: !!helpToBuy}

    // Cover letter
    fill['undefined_17'] = {check: true}

    // Application form
    fill['undefined_20'] = {check: true}

    // Broker checklist
    fill['undefined_23'] = {check: true}

    // Broker calculator
    fill['undefined_18'] = {check: true}

    // Other supporting  documentation
    ask.push({
        name: 'otherSupportingDocs',
        label: 'Other Supporting Documentation',
        type: 'CHECK',
        group: 'CHECKS'
    })
    if (config.hasOwnProperty('otherSupportingDocs')) {
        fill['undefined_24'] = {check: !!config.otherSupportingDocs}
    }
    else{
        fill['undefined_24'] = {check: false}
    }

    // *** Self Build ? ***

    // Estimates of works
    fill['undefined_19'] = {check: !!selfBuild}


    // International credit report
    fill['undefined_22'] = {check: !!permitRequired}

    // Divorce/separation/maintenance agreement
    let needsAgreement =  (
        [PersonalMaritalStatuses.DIVORCED, PersonalMaritalStatuses.REMARRIED].includes( mortgage.applicant1.personal.maritalStatus) ||
        (mortgage.twoApplicants && [PersonalMaritalStatuses.DIVORCED, PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)) ||
        mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED ||
        (mortgage.twoApplicants && mortgage.applicant2.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED))
    fill['undefined_25'] = {check: !!needsAgreement}


    return {sections:[{fill, ask, map}]}


}