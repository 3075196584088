import React, {useRef, useState, useEffect} from 'react';

import {Select} from "antd";
import PdfBubble from "./PdfBubble";
import {v4} from "uuid";
import {graphqlOperation} from "aws-amplify";
import {performGQL} from "../../api-functions/functions";
import {
    createAdminPdfOverlayTemplateItem,
    deleteAdminPdfOverlayTemplateItem,
    updateAdminPdfOverlayTemplateItem
} from "../../../graphql/mutations";
import {hyphenToCamelCase} from "../../../assets/scripts/string-functions";
import * as pdfjsLib from "pdfjs-dist/build/pdf"
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.min.mjs');
const pdfjsWorkerBlob = new Blob([pdfjsWorker]);
const pdfjsWorkerBlobURL = URL.createObjectURL(pdfjsWorkerBlob);
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerBlobURL


function PdfCoordinates(props) {
    const [pageNo, setPageNo] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(null)
    const [items, setItems] = useState([])

    const pdfCanvas = useRef()
    const pdfDocument = useRef()
    //let pdfDocument = null;

    function handleFileUpload(event) {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileReader = new FileReader();

            fileReader.onload = function (e) {
                const pdfData = e.target.result;

                // Render the PDF on the canvas
                renderPdfToCanvas(pdfData);
            };

            fileReader.readAsArrayBuffer(selectedFile);
        }
    }
    const handlePdfClick = (e) => {
        const canvas = pdfCanvas.current;
        const rect = canvas.getBoundingClientRect();
        let x = Math.round(e.clientX - rect.left);
        const y1 = Math.round(e.clientY - rect.top)
        let y = canvas.height - y1
        const tolerance = 4
        const existingYsCloseBy = items.find(item=>{
            return item.y >= y - tolerance && item.y <= y + tolerance
        })
        if (existingYsCloseBy){
            y = existingYsCloseBy.y
        }
        const existingXsCloseBy = items.find(item=>{
            return item.x >= x - tolerance && item.x <= x + tolerance
        })
        if (existingXsCloseBy){
            x = existingXsCloseBy.x
        }
        setItems(s => ([...s, {
            x,
            y,
            type: 'text',
            pageNo,
            style: {},
            uid: v4()
        }]))
    };


    async function renderPage(pageNumber) {
        if (!pdfDocument.current) return
        try {
            const page = await pdfDocument.current.getPage(pageNumber);
            const viewport = page.getViewport({scale: 1});
            pdfCanvas.current.width = viewport.width
            pdfCanvas.current.height = viewport.height

            const canvasContext = pdfCanvas.current.getContext('2d');
            const renderContext = {
                canvasContext,
                viewport,
            };
            await page.render(renderContext).promise;
        } catch (error) {
            console.error('Error rendering PDF page:', error);
        }
    }
    async function renderPdfToCanvas() {
        try {
            // Load the PDF document if it's not already loaded

            pdfDocument.current = await pdfjsLib.getDocument(props.url).promise
            // if (!pdfDocument) {
            //     pdfDocument = await pdfjsLib.getDocument(props.url).promise
            // }

            // Render the current page
            setNumberOfPages(pdfDocument.current.numPages)
            renderPage(1)
        } catch (error) {
            console.error('Error rendering PDF:', error);
        }
    }
    useEffect(() => {
        renderPdfToCanvas()
    }, [props.id]);
    useEffect(() => {
        if (pdfDocument.current) {
            renderPage(pageNo)
        }
    }, [pageNo]);
    useEffect(() => {
        setItems(props.points)
    }, [props.points])
    let options = []
    if (!!numberOfPages) {
        options = (Array.from({length: numberOfPages})).map((e, i) => ({label: (i + 1).toString(), value: (i + 1)}))
    }
    // const options = numberOfPages ? (new Array(numberOfPages)) : []
    const itemRemove = async (item) => {

        if (item?.uid) {
            setItems(s => ([...s.filter(it => it.uid !== item.uid)]))
        }
        if (item?.id) {
            setItems(s => ([...s.filter(it => it.id !== item.id)]))
            await performGQL({input: item}, deleteAdminPdfOverlayTemplateItem)
        }
    }
    const itemCreate = async (item) => {

        const style = {
            size: item.size ? item.size : 9,
            // color: [0.043137254901960784, 0.3137254901960784, 0.6901960784313725],
        }
        if (item.right){
            style.textAlign = 'right'
        }
        if (item.width) {
            style.maxWidth = item.width
            style.lineHeight = 1
        }
        const x = {
            name: item.name,
            pageNo,
            x: item.x,
            y: item.y,
            type: item.type,
            style: JSON.stringify(style),
            templateId: props.id
        }

        const result = await performGQL({input: x}, createAdminPdfOverlayTemplateItem)
        const itemStyle = {
            fontSize: item.size ? item.size : 9,
        }
        if (item.width) {
            style.width = item.width
        }
        setItems(s => {
            return s.map(it => {
                if (it.uid === item.uid) {

                    return {
                        ...result,
                        style: JSON.parse(result.style)
                    }
                }
                return it
            })
        })


    }
    const itemUpdate = async (item) => {
        const style = {
            size: item.size ? item.size : 9,
        }
        if (item.right){
            style.textAlign = 'right'
        }
        if (item.width) {
            style.maxWidth = item.width
        }
        const x = {
            id: item.id,
            name: item.name,
            x: item.x,
            y: item.y,
            type: item.type,
            style: JSON.stringify(style),
            _version: item._version
        }

        const result = await performGQL({input: x}, updateAdminPdfOverlayTemplateItem)


    }

    return (
        <>
            <div className="d-row gap-15">
                <div className="p-relative">
                    <canvas key={props.url} onClick={handlePdfClick} ref={pdfCanvas}/>
                    {
                        items.filter(it => it.pageNo === pageNo).map(item => (
                            <PdfBubble
                                key={`pdfo-${item.uid || item.id}`}
                                remove={itemRemove}
                                update={itemUpdate}
                                create={itemCreate}
                                {...item}
                            />))
                    }
                </div>
                {options.length > 0 && (
                    <Select defaultValue={1} options={options} onSelect={(v) => setPageNo(v)}/>
                )}

            </div>
        </>
    )

}

export default PdfCoordinates;