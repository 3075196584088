import React, {useContext} from 'react';
import {Avatar, Button, Table, Tooltip, Typography} from "antd";
import PageHeader from "../PageHeader";
import {cyan, geekblue, orange, red} from "@ant-design/colors";
import {MortgageActiveAgents} from "../../../models";
import {FlagFilled} from "@ant-design/icons";
import {formatDistanceToNow} from "date-fns";
import {Mortgages} from "../../providers/MortgagesProvider";
import useBackendUsers from "../../providers/mortgage/useBackendUsers";
import {useNavigate} from "react-router-dom";

function ApplicationsWithLender(props) {
    const mortgages = useContext(Mortgages)
    const navigate = useNavigate()
    console.log({mortgages})
    const backendUsers = useBackendUsers()
    let dataSource = mortgages.ACTIVE.filter(it=>it.requirement.lenderCode === props.lender.lenderCode || it.activeSubmission?.lenderCode === props.lender.lenderCode)
    console.log({dataSource, props})
    const columns = [
        {
            title: 'Name',
            key: 'applicationTitle',
            render: (text, record) => (
                <Tooltip title={record.applicant1?.email}>{record.applicationTitle}</Tooltip>
            ),
        },
        {
            title: 'Message',
            render: (text, record) =>{
                return <>
                    {!!record.adminMessageBy && (<Avatar size="small" icon={backendUsers.getBySub(record.adminMessageBy).firstName[0]} />)}
                    <Typography.Text style={{color:geekblue.primary}}>{record.adminMessage}</Typography.Text>
                </>
            },
            key: 'message',
        },
        {
            title: 'Agent',
            render: (text, record) => {
                switch (record.activeAgent) {
                    case MortgageActiveAgents.BROKER:
                        return <FlagFilled style={{color: red.primary}}/>
                    case MortgageActiveAgents.LENDER:
                        return <FlagFilled style={{color: orange.primary}}/>
                    case MortgageActiveAgents.USER:
                        return <FlagFilled style={{color: cyan.primary}}/>
                    default:
                        return null
                }
            },
            key: 'activeAgent',
        },
        {
            title: 'Registered On',
            render: (text, record) =>
                formatDistanceToNow(new Date(record.createdAt), { addSuffix: true }).replace("about", "almost"),
            key: 'registeredAgo',
        },
        {
            title: 'Mortgage Type',
            dataIndex: 'mortgageType',
            key: 'mortgageType',
        },
        {
            title: 'Loan Amount',
            render: (text, record) =>record.requirement.loanRequired?.toLocaleString('en-us'),
            key: 'loanAmount'
        },
        {
            title: 'Application Stage',
            dataIndex: 'applicationStage',
            key: 'applicationStage',
        },
        {
            title: 'Application Status',
            dataIndex: 'applicationStatus',
            key: 'applicationStatus',
        },
    ];
    return (<>

        <Table
            title={()=><PageHeader title={`${props.lender.lender} Applications`} back={"./../"}/>}
            pagination={{ pageSize: 20 }}
            dataSource={dataSource}
            columns={columns}
            onRow={(record) => ({
                onClick: () => navigate(`/client/${record.id}`)
            })}
        />
    </>
    )
}

export default ApplicationsWithLender;