import {buildToDo, isStepDone, updateCompletedFlowItems} from "./index";
import dayjs from "dayjs";

export const arrangeMortgageProtection = (mortgage, props) => {
    let dueAfter = dayjs().add(1, 'day')
    return buildToDo('APPROVAL', 'arrangeMortgageProtection', {
        mortgage,
        dueAfter,
        toDo: {
            title: `Arrange Mortgage Protection`,
            tab: 'AIP Details',
            buttons: [
                {
                    label: "Mark Mortgage Protection Arranged",
                    name: 'arranged',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'MortgageProtectionArranged')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'arrangeMortgageProtection.button.arranged')
             //   return m.completedFlowItems.hasOwnProperty('MortgageProtectionArranged')
            }
        }
    })
}
// Affect the MPI 2 weeks before closing !!!!!!!

export const arrangeHouseInsurance = (mortgage, props) => {

    return buildToDo('APPROVAL', 'arrangeHouseInsurance', {
        mortgage,
        toDo: {
            title: `Arrange House Insurance`,
            tab: 'AIP Details',
            buttons: [
                {
                    label: "Mark House Insurance Arranged",
                    name: 'arranged',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'HouseInsuranceArranged')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'arrangeHouseInsurance.button.arranged')
             //   return m.completedFlowItems.hasOwnProperty('HouseInsuranceArranged')
            }
        }
    })
}
export const activateMortgageProtection = (mortgage, props) => {

    return buildToDo('APPROVAL', 'activateMortgageProtection', {
        mortgage,
        toDo: {
            title: `Activate Mortgage Protection`,
            tab: 'AIP Details',
            buttons: [
                {
                    label: "Mark Mortgage Protection Activated",
                    name: 'activated',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'MortgageProtectionActivated')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'activateMortgageProtection.button.activated')
             //   return m.completedFlowItems.hasOwnProperty('MortgageProtectionActivated')
            }
        }
    })
}
export const sendAreSnagsDoneEmail = (mortgage, props) => {
    return buildToDo('APPROVAL', 'sendAreSnagsDoneEmail', {
        mortgage,
        toDo: {
            title: `Send Are Snags Done Email`,
            tab: 'AIP Details',
        }
    })
}
export const arrangeSecondValuation = (mortgage, props) => {
    return buildToDo('APPROVAL', 'arrangeSecondValuation', {
        mortgage,
        toDo: {
            title: `Arrange 2nd Valuation`,
            tab: 'AIP Details',
        }
    })
}
export const submitLoanRequest = (mortgage) => {
    return buildToDo('APPROVAL', 'submitLoanRequest', {
        mortgage,
        toDo: {
            title: 'Request the Loan',
            tab: 'Bundle & Submit',
            for:'advisor',
            buttons: [
                {
                    label: "Submitted",
                    name: 'submitted',
                    onClick: async () => {
                        //await updateCompletedFlowItems(mortgage, 'LoanRequestSubmitted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'submitLoanRequest.button.submitted')
               // return m.completedFlowItems?.hasOwnProperty('LoanRequestSubmitted')
            }
        }
    })
}
export const verifyUploads = (mortgage) => {

    return buildToDo('APPROVAL', 'verifyUploads', {
        mortgage,
        toDo: {
            title: `Verify uploads & record info`,
            tab: 'Uploads',
            buttons: [
                {
                    label: "Uploads Verified",
                    name: 'verified',
                    onClick: async () => {
                       // await updateCompletedFlowItems(mortgage, 'ApprovalUploadsVerified')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'verifyUploads.button.verified')
              //  return m.completedFlowItems.hasOwnProperty('ApprovalUploadsVerified')
            }
        }
    })
}
export const packageLoanRequest = (mortgage) => {
    return buildToDo('APPROVAL', 'packageLoanRequest', {
        mortgage,
        toDo: {
            title: 'Package Loan Request',
            tab: 'Bundle & Submit',
            for:'advisor',
            buttons: [
                {
                    label: "Packaged",
                    name: 'packaged',
                    onClick: async () => {
                    //    await updateCompletedFlowItems(mortgage, 'LoanRequestPackaged')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'APPROVAL', 'packageLoanRequest.button.packaged')
              //  return m.completedFlowItems?.hasOwnProperty('LoanRequestPackaged')
            }
        }
    })
}
export const toDos=[
    arrangeMortgageProtection,
    arrangeHouseInsurance,
    activateMortgageProtection,
    sendAreSnagsDoneEmail,
    arrangeSecondValuation,
    verifyUploads,
    packageLoanRequest,
    submitLoanRequest

]