import React from 'react';
import dayjs from "dayjs";
import {Select, Tag} from "antd";
import {CheckCircleFilled, CheckCircleOutlined} from "@ant-design/icons";
import {green} from "@ant-design/colors";
function TestHistory({mortgage,lenders, currentTestId, onLoadLenderTest, submissionTestId}) {
    function Label({test}) {
        let date = dayjs(test.createdAt)
        let dateString
        if (date.isAfter(dayjs().subtract(1, 'hour'))) {
            dateString = date.fromNow()
        }
        else {
            dateString = date.format('D MMM YYYY HH:mm')
        }

        let lenderResults = test.results
        let lenderData = test.data
        if (!lenderResults || !lenderData) {
            return <div>{dateString}</div>
        }

        return (<div className="d-row a-center gap-15">
            <div style={{
                width: 125, flexShrink: 0
            }}>{dateString}</div>
            <div style={{
                width: 60, flexShrink: 0
            }}><Tag color='default'>{lenderData.figures.mortgageAmount?.toEuro()}</Tag></div>
            <div className="d-row a-center gap-6">

                {lenders.flatMap(lender => {
                    let result = lenderResults[lender.key]
                    let color = 'default'
                    if (!!result) {
                        color = result.passed ? 'green' : result.review? 'orange': 'red'
                    }
                    return [
                        <Tag key={lender.key} color={color}>{lender.label}</Tag>

                    ]
                })}
                {test.id === submissionTestId && <CheckCircleFilled style={{color: green[4]}}/>}
            </div>
        </div>)
    }

    return (<Select
        placeholder="Test History"
        onSelect={onLoadLenderTest}
        style={{width: '100%'}}
        value={currentTestId}
        options={mortgage.lenderTests.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
        }).map(test => {
            return {
                label: <Label test={test}/>, value: test.id
            }
        })}/>)
}

export default TestHistory;