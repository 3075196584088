import React from 'react';
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../../../../../../../providers/mortgage/useMortgageUploadService";
import {LoadingOutlined, ScanOutlined} from "@ant-design/icons";
import {App, Button} from "antd";
import {API} from "aws-amplify";
import useStatementScanning from "../../../../../../../../../providers/useStatementScanning";
import {performGQL} from "../../../../../../../../../api-functions/functions";
import {
    createAdminMortgageUploadStatementBasicInfo,
    updateAdminMortgageUploadStatementBasicInfo
} from "../../../../../../../../../../graphql/mutations";
import dayjs from "dayjs";

function Scanner({uploadId, section}) {
    const app = App.useApp()
    const [scanning, setScanning] = React.useState(false)
    const mortgage = useMortgage()
    const upload = mortgage.uploads.find(item => item.id === uploadId)
    const scanner = useStatementScanning()
    let myUpload = section.uploads.find(it => it.id === uploadId)

    const handleScan = async () => {
        if (upload.contentType !== 'application/pdf') {
            app.message.error('Only PDFs can be scanned')
            return
        }
        setScanning(true)
        //let command = !!upload.basicInfo ? updateAdminMortgageUploadStatementBasicInfo : createAdminMortgageUploadStatementBasicInfo

        let data = await scanner.read(upload)
        if (!data) {
            app.message.error('Scanning failed')
            setScanning(false)
            return
        }

        // first update the upload with the data
        //handle scan data
        if (section.verified.isVerifiable){
            await section.verified.handleScan(data)
            let myUpload  = [...section.verified.uploads.active, ...section.verified.uploads.inactive].find(it=>it.id === uploadId)
            if (myUpload) {

                myUpload.verified.handleScan(data)
            }
        }


        // then update the section's row, if not there

        // then update the upload omit

        //update the omit
        let promises=[]
        if (data.omit.length){
            promises.push(mortgage.mutate({update: {
                target: `uploads[${uploadId}].omit`,
                value: data.omit
            }}))
        }

        await Promise.all(promises)

        //
        // let creation
        //
        // if (data) {
        //     let {pdfType, transactions, isSequential, ...create} = data
        //     create.error= null
        //     create.startDate = create.startDate.format('YYYY-MM-DD')
        //     create.endDate = create.endDate.format('YYYY-MM-DD')
        //     create.accountAddress = create.accountAddress.split('\n')
        //     create.id = uploadId
        //     if (!isSequential) {
        //         create.error = "Not in sequence"
        //     }
        //     if (!transactions.length) {
        //         create.error = "No transactions found"
        //     }
        //
        //     creation = create
        // } else {
        //     creation = {
        //         id: uploadId,
        //         error: "Could not scan"
        //     }
        // }
        // if (!!upload.basicInfo) {
        //     creation._version = upload.basicInfo._version
        // }
        // let result = await performGQL({input: creation}, command)
        // await mortgage.localMutate({
        //     update: {
        //         target: `uploads[${uploadId}].basicInfo`,
        //         value: result
        //     }
        // })
        setScanning(false)
    }
    // if (!!upload.basicInfo) return 'Scanned'

    return (
        <Button size="small" onClick={handleScan}>
            Scan
            {scanning ? <LoadingOutlined/> : <ScanOutlined/>}
        </Button>
    );
}

export default Scanner;