import React from 'react';
import {Card, Table} from "antd";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {BoolLike, BuildType, MortgageTypes} from "../../../../../../models";
import {extraCosts} from "../lender-tests/useXlsTests";
export function balanceOfFundsCosts(mortgage, override = {}) {
    let {buildType, propertyValue: price} = mortgage.properties[0]
    if (override.hasOwnProperty('price')) {
        price = override.price
    }
    let {stampDuty, legalFees, totalCosts, propertyValuation} = extraCosts(buildType, price, mortgage.mortgageType===MortgageTypes.REFINANCE)
    return {price, stampDuty, legalFees, propertyValuation, totalCosts}
}

export function balanceOfFundsFunds(mortgage, override = {}) {
    const {totalCosts} = balanceOfFundsCosts(mortgage, override)
    let loan = mortgage.requirement.loanRequired ||0
    if (override.hasOwnProperty('loan')) {
        loan = override.loan
    }
    let gifts = mortgage.requirement.receivingGifts ? mortgage.requirement.depositGifts || 0 : 0
    let helpToBuy = mortgage.mortgageType !== MortgageTypes.REFINANCE && mortgage.requirement.helpToBuyScheme !== BoolLike.FALSE ? !!mortgage.requirement.helpToBuySchemeAmount ? mortgage.requirement.helpToBuySchemeAmount : mortgage.twoApplicants ? 30000: 25000  : 0
    let firstHome = mortgage.mortgageType !== MortgageTypes.REFINANCE && mortgage.requirement.firstHomeScheme !== BoolLike.FALSE ? !!mortgage.requirement.firstHomeSchemeAmount ? mortgage.requirement.firstHomeSchemeAmount : 0 : 0
    let bookingDepositPaid = mortgage.properties[0].bookingDepositPaid || 0
    let contractDepositPaid = mortgage.properties[0].contractDepositPaid || 0
    let fixedFunds = gifts + helpToBuy + firstHome + loan + bookingDepositPaid + contractDepositPaid
    let requiredSavings = totalCosts - fixedFunds
    return {loan, gifts, helpToBuy, firstHome, requiredSavings, bookingDepositPaid, contractDepositPaid, totalFunds:totalCosts, fixedFunds}
}
function BalanceOfFunds(props) {
    const mortgage = useMortgage()
    if (!mortgage) return (<div/>)
    //Costs
    const {price, stampDuty, legalFees, totalCosts, propertyValuation} = balanceOfFundsCosts(mortgage)

    const costs = [
        {key: 1, name: "Purchase Price", amount: price},
        {key: 3, name: "Legal Fees", amount: legalFees},
        {key: 4, name: "Valuation", amount: propertyValuation},
    ]
    if (mortgage.mortgageType !== MortgageTypes.REFINANCE) {
        costs.push({key: 2, name: "Stamp Duty", amount: stampDuty})
    }

    //Funds
    const {loan, gifts, helpToBuy, firstHome, bookingDepositPaid, contractDepositPaid, requiredSavings} = balanceOfFundsFunds(mortgage)

    const funds = []
    if (loan > 0) funds.push({key: 0, name: "Mortgage", amount: loan})
    if (gifts > 0) funds.push({key: 1, name: "Gifts", amount: gifts})
    if (helpToBuy > 0 ) funds.push({key: 2, name: "Help to Buy", amount: helpToBuy})
    if (firstHome > 0 ) funds.push({key: 3, name: "First Home", amount: firstHome})
    if (bookingDepositPaid > 0) funds.push({key: 4, name: "Booking Deposit Paid", amount: bookingDepositPaid})
    if (contractDepositPaid > 0) funds.push({key: 5, name: "Contract Deposit Paid", amount: contractDepositPaid})

    funds.push({key: 6, name: "Savings", amount: requiredSavings})


    //Nicely space the cells so totals are at the bottom
    if (funds.length > costs.length) {
        let diff = funds.length - costs.length
        for (let i = 0; i < diff; i++) {
            costs.push({key: `c.${i}`, name: "", amount: ""})
        }
    }
    else if (costs.length > funds.length) {
        let diff = costs.length - funds.length
        for (let i = 0; i < diff; i++) {
            funds.push({key: `f.${i}`, name: "", amount: ""})
        }
    }
    costs.push({key: 5, name: "TOTAL", amount: totalCosts})
    funds.push({key: 5, name: "TOTAL", amount: totalCosts})

    const columnsCost = [
        {title: "", dataIndex: "name", key: "name"},
        {title: "Costs", dataIndex: "amount", key: "amount", align: "right", render: (text, item) => !text ? '\u00A0': text?.toEuro()}
    ]

    const columnsFunds = [
        {title: "", dataIndex: "name", key: "name"},
        {title: "Funds", dataIndex: "amount", key: "amount", align: "right", render: (text, item) => !text ? '\u00A0' :text?.toEuro()},
    ]
    if (props.contentOnly){
        return (
            <div className="d-row gap-15">
                <Table size="small" dataSource={costs} columns={columnsCost} pagination={false}/>
                <Table size="small" dataSource={funds} columns={columnsFunds} pagination={false}/>
            </div>
        )
    }
    return (

            <Card styles={{body:{padding:0}}} title={ "Balance of Funds"} >
                <div className="d-row gap-15">
                <Table size="small" dataSource={costs} columns={columnsCost} pagination={false}/>
                <Table size="small" dataSource={funds} columns={columnsFunds} pagination={false}/>
                </div>
            </Card>

    );
}

export default BalanceOfFunds;
