import React, {useContext} from 'react';
import {Avatar, Table, Tooltip, Typography} from "antd";
import {OutstandingItems} from "../../providers/mortgage/OutstandingItemsProvider";
import {cyan, geekblue, orange, red} from "@ant-design/colors";
import {MortgageActiveAgents} from "../../../models";
import {FlagFilled} from "@ant-design/icons";
import {formatDistanceToNow} from "date-fns";
import {Mortgages} from "../../providers/MortgagesProvider";
import {useNavigate} from "react-router-dom";

function PendingUploads(props) {
    const os = useContext(OutstandingItems)
    const {ALL} = useContext(Mortgages)
    const nav = useNavigate()
    const deleted = []
    const dataSource = []
    Object.keys(os).forEach(key => {
        let m = ALL.find(it=>it.id === key)
        if (!m) {
            deleted.push(key)
        }
        else{
            dataSource.push(
                {
                    id: key,
                    applicationTitle: m.applicationTitle,
                    applicationStage: m.applicationStage,
                    applicationStatus: m.applicationStatus,
                    pending: os[key].uploads.length
                }
            )
        }
    })

    const columns = [
        {
            title: 'Name',
            key: 'applicationTitle',
            dataIndex: 'applicationTitle',
        },
        {
            title: 'Application Stage',
            dataIndex: 'applicationStage',
            key: 'applicationStage',
        },
        {
            title: 'Application Status',
            dataIndex: 'applicationStatus',
            key: 'applicationStatus',
        },
        {
            title: 'Pending Uploads',
            dataIndex: 'pending',
            key: 'pending',
        },
    ];
    console.log({deleted})
    return (
        <Table
            title={()=><Typography.Title level={5}>Outstanding Pending Uploads</Typography.Title>}
            onRow={(record) => ({
                onClick: () => nav('/client/'+record.id)
            })}
            dataSource={dataSource}
            columns={columns} />
    );
}

export default PendingUploads;