import React, {useState} from 'react';
import {Button, Form, Input, Select} from "antd";
import {ApplicationStages, SectionTypes} from "../../../../models";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import useMortgage from "../../../providers/mortgage/useMortgage";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";

function ConditionEditPanel(props) {
    const [state, setState] = useState({
        saving: false
    })

    const [form] = Form.useForm()
    const mortgage = useMortgage()
    const initialValues = () =>{
        let w = props.section.sectionInstructions ? JSON.parse(props.section.sectionInstructions) : {}
        return {
            stage:  props.section.sectionStage,
            title: props.section.sectionTitle,
            type: props.section.sectionType,
            name: props.section.sectionName,
            children: w?.children,
            time: w?.time,
            requirements: w?.instructions?.specialRequirements,
            downloads: w?.instructions.downloads,
        }
    }

    const handleFormFinish = async () => {
        try {
            const valid = await form.validateFields()
            setState(s => ({...s, saving: true}))

            const specialInstructions = valid.requirements

            const update = {
                target: `dynamicSections[${props.section.id}]`,
                pairs: {
                    sectionTitle: valid.title,
                    sectionInstructions: JSON.stringify({
                        children: valid.children,
                        time: valid.time,
                        instructions: {
                            specialRequirements: valid.requirements,
                            downloads: valid.templates,
                        }
                    }),
                }
            }
            await mortgage.mutate({update})
            setState(s => ({...s, saving: false}))
        } catch (e) {

        }
    }

    return (


        <Form
            className="p-15"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            key={`ConditionWriter`}
            form={form}
            requiredMark={false}
            initialValues={initialValues()}
            onFinish={handleFormFinish}>

            <div className="d-row j-equal gap-15">
                <div className="d-col gap-9">
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[{required: true, message: `A type is required`}]}
                        className="mb-0">
                        <Select disabled style={{width: '100%'}}>
                            {[SectionTypes.UPLOAD, SectionTypes.QUESTIONS].map(u =>
                                <Select.Option value={u} key={`${u}`}>
                                    {u}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: `A name is required`}]}
                        className="mb-0">
                        <Input disabled style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Stage"
                        name="stage"
                        rules={[{required: true, message: `A stage is required`}]}
                        className="mb-0">
                        <Select disabled style={{width: '100%'}}>
                            {[ApplicationStages.APPLY, ApplicationStages.PREAPPROVAL, ApplicationStages.APPROVAL].map(u =>
                                <Select.Option value={u} key={`${u}`}>
                                    {u}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Time"
                        name="time"
                        rules={[{required: true, message: `A time is required`}]}
                        className="mb-0">
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{required: true, message: `A title is required`}]}
                        className="mb-0">
                        <Input style={{width: '100%'}}/>
                    </Form.Item>
                </div>
                <div className="d-col gap-9">

                    <Form.Item
                        label="Description"
                        name="children"
                        rules={[{required: true, message: `A description is required`}]}
                        className="mb-0">
                        <Input.TextArea style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Instructions"
                        className="mb-0">
                        <Form.List name="requirements">
                            {(fields, {add, remove}) => (
                                <div className="d-col gap-6">
                                    {fields.map((field) => (
                                        <div className="d-row gap-6" key={`my-${field.key}`}>
                                            <Form.Item
                                                className="mb-0 grow"
                                                {...field}>
                                                <Input style={{width: '100%'}}/>
                                            </Form.Item>
                                            <CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </div>
                                    ))}

                                    <Button type="dashed" onClick={() => add()} block>
                                        + Add Paragraph
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Form.Item>
                    <Form.Item
                        label="Downloads"
                        className="mb-0">
                        <Form.List name="downloads">
                            {(fields, {add, remove}) => (
                                <div className="d-col gap-6">
                                    {fields.map((field) => (
                                        <div className="d-row gap-6"  key={`my-${field.key}`}>
                                            <Form.Item
                                                className="mb-0 grow"
                                                {...field}>
                                                <Input style={{width: '100%'}}/>
                                            </Form.Item>
                                            <CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </div>
                                    ))}

                                    <Button type="dashed" onClick={() => add()} block>
                                        + Add Download
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Form.Item>
                </div>
            </div>
            <div className="d-row j-end mt-15">
                <Button htmlType="submit"> Save {state.saving && <LoadingOutlined className="ms-6"/>} </Button>
            </div>
        </Form>


    );
}

export default ConditionEditPanel;