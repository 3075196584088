import React, {useContext, useEffect, useRef, useState} from "react";
import getMortgage from "../../api-functions/mortgage/getMortgage";
import getMoreItems from "../../api-functions/mortgage/getMoreItems";
import {format, isValid} from "date-fns";
import {App, Skeleton} from "antd";
import {Auth} from "aws-amplify";
import {performGQL} from "../../api-functions/functions";
import generateMutation from "../../../graphql/generate-mutation";
import {v4} from "uuid"
import {createAdminLastViewedMortgage} from "../../../graphql/mutations";
import dayjs from "dayjs";
import {Sidebar} from "../SidebarProvider";
import {capitaliseWords} from "../../../assets/scripts/string-functions";
import {expandActiveSubmission} from "../MortgagesProvider";
import {ProgressViewState} from "../../../models";

export const Mortgage = React.createContext()
export const extractTypeAndId = (str) => {
    const pattern = /^(.*?)\[(\w+-\w+-\w+-\w+-\w+)]$/;
    const match = str.match(pattern);
    if (match && match[1] && match[2]) {
        return {
            type: match[1],
            id  : match[2]
        };
    }
    return null;
}
export const setItemFromPath = (obj, propertyPath, newValue, methodToSet) => {
    let keys = propertyPath.split('.');
    let currentObj = obj;
    // Traverse the object until the second-to-last key
    if (propertyPath !== "") {
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i]
            const repeater1 = extractTypeAndId(key)
            if (repeater1) {
                currentObj = currentObj[repeater1.type].find(item => item.id === repeater1.id);
            }
            else {
                currentObj = currentObj[key];
            }
        }

        // Handle the last key separately to set the new value
        const lastKey = keys[keys.length - 1];
        switch (methodToSet) {
            case 'arrayAdd' :
                currentObj[lastKey].push(newValue)
                break
            case 'objectMerge' :
                const repeater2 = extractTypeAndId(lastKey)
                let indexedObj
                if (repeater2) {
                    indexedObj = currentObj[repeater2.type].find(item => item.id === repeater2.id);
                }
                else {
                    indexedObj = currentObj[lastKey]
                }
                let valKeys = Object.keys(newValue)
                for (let i = 0; i < valKeys.length; i++) {
                    indexedObj[valKeys[i]] = newValue[valKeys[i]]
                }
                break
            default:
                currentObj[lastKey] = newValue
        }
    }
    else {
        // operation on mortgage
        let valKeys = Object.keys(newValue)
        for (let i = 0; i < valKeys.length; i++) {
            currentObj[valKeys[i]] = newValue[valKeys[i]]
        }
    }

    return obj;
}
export const getItemFromPath = (obj, propertyPath) => {
    let currentObj = obj;
    if (propertyPath === '') {
        return currentObj
    }
    let keys = propertyPath.split('.');

    keys.forEach((key) => {
        let searchKey = key
        //propertyPath items may contain repeaters like applicant1.debts[adf12ed-23234...]
        let repeater = extractTypeAndId(key)

        if (repeater) {
            searchKey = repeater.type
        }

        if (currentObj.hasOwnProperty(searchKey)) {
            if (repeater) {

                currentObj = currentObj[searchKey].find(item => item.id === repeater.id);

            }
            else {
                currentObj = currentObj[searchKey];
            }
        }
        else {
            currentObj = null
        }
    })

    return currentObj;
}

export const correctValueForDb = (type, value) => {
    switch (type) {
        case 'textarea' :
            return value.split('\n')

        case 'ddmmyyyy' :
            let st1 = value.split(' / ').reverse().join('-')
            let d1 = new Date(st1)
            if (isValid(d1)) {
                return format(d1, "y-MM-dd")
            }
            return null
        case 'mmyyyy' :
            let st2 = `01 / ${value}`.split(' / ').reverse().join('-')
            let d2 = new Date(st2)
            if (isValid(d2)) {
                return format(d2, "y-MM-dd")
                //return d2.toISOString()
            }
            return null
        case 'datepicker':
            const date = dayjs(value)
            if (dayjs.isDayjs(date) && date.isValid()) {
                return date.format('YYYY-MM-DD')
            }

            return null
        case 'agearray' :
            return value.map(d => {
                let st3 = d.split(' / ').reverse().join('-')
                let d3 = dayjs(st3)
                if (dayjs.isDayjs(d3) && d3.isValid()) {
                    return d3.format("YYYY-MM-DD")
                }
                return null
            })
        case 'date':

            return value.format('YYYY-MM-DD')
        default:
            return value
    }
}
export const correctValueForSys = (mortgage, question, value) => {
    if (question.answer.type === 'agearray') {
        // ensure the fields match the count
        if (!value) {
            let parts = question.target.split('.')
            parts.pop()
            parts.push('countDependents')
            let cD = getItemFromPath(mortgage, parts.join('.'))
            if (parseInt(cD) > 0) {
                value = Array(cD).fill(null)
            }
        }
        else {
            value = value.map(iso => dayjs(iso).format("DD / MM / YYYYY"))
        }
    }
    if (value && value !== false) {
        if (question.answer.type === 'ddmmyyyy') {
            value = value.split("-").reverse().join(" / ")
        }
        if (question.answer.type === 'date') {
            value = dayjs(value, 'YYYY-MM-DD')
        }
        if (question.answer.type === 'mmyyyy') {
            value = dayjs(value).format("MM / YYYY")
        }
        if (question.answer.type === 'datepicker') {
            value = dayjs(value, 'YYYY-MM-DD')
        }
        if (question.answer.type === 'textarea' && Array.isArray(value)) {
            value = value.join('\n')
        }
    }

    return value
}
export const readAccessGroup = (mortgage, path) => {
    if (path.startsWith('applicant1.')) {
        return [
            `${mortgage.id}-app1`,
            `${mortgage.id}-app2-read`
        ]
    }
    if (path.startsWith('applicant2.')) {
        return [
            `${mortgage.id}-app2`,
            `${mortgage.id}-app1-read`
        ]
    }
    return [
        `${mortgage.id}-app1`,
        `${mortgage.id}-app2`
    ]
}
export const editAccessGroup = (mortgage, path) => {
    if (path.startsWith('applicant1.')) {
        return [
            `${mortgage.id}-app1`,
            `${mortgage.id}-app2-edit`
        ]
    }
    if (path.startsWith('applicant2.')) {
        return [
            `${mortgage.id}-app2`,
            `${mortgage.id}-app1-edit`
        ]
    }
    return [
        `${mortgage.id}-app1`,
        `${mortgage.id}-app2`
    ]
}

function getModelName(path) {

    let repeaterPath = extractTypeAndId(path)
    if (repeaterPath) {

        path = repeaterPath.type
    }

    switch (path) {
        case "" :
            return 'UserMortgage'
        case "submissions":
            return 'UserMortgageSubmission'
        case "docContents":
            return 'AdminMortgageDocumentContent'
        case "playgroundTests" :
            return 'AdminMortgageLenderTestPlaygroundTest'
        case "lenderTests" :
            return 'AdminMortgageLenderTest'
        case "replyRequests" :
            return "AdminEmailReplyRequest"
        case "notifications" :
            return 'AdminMortgageStageNotifications'
        case "queries":
            return 'UserMortgageQuery'
        case "properties" :
            return 'UserMortgagePropertyOnMortgage'
        case "invitations" :
            return 'UserMortgageInvitation'
        case "progress" :
            return 'UserMortgageProgress'
        case "uploads" :
            return 'UserMortgageUploads'
        case "dynamicSections" :
            return 'UserMortgageDynamicSection'
        case "requirement" :
            return 'UserMortgageRequirement'
        case "property" :
            return 'UserMortgageProperty'
        case "existing" :
            return 'UserMortgageExisting'
        case "needs" :
            return 'UserMortgageNeeds'
        case "notes" :
            return 'AdminMortgageNotes'
        case "settings" :
            return 'AdminMortgageSettings'
        case "solicitor" :
            return 'UserMortgageSolicitor'
        case "tasks" :
            return 'AdminMortgageTasks'
        case "stageTasks" :
            return 'AdminMortgageStageTask'
        case "UserMortgageDocuments":
            return 'UserMortgageDocuments'
        case "applicant1" :
        case "applicant2" :
            return 'UserApplicant'
        case "applicant1.employmentIncome":
        case "applicant2.employmentIncome":
            return 'UserApplicantEmploymentIncome'
        case "applicant1.accounts":
        case "applicant2.accounts":
            return 'UserApplicantCurrentAccount'
        case "applicant1.personal" :
        case "applicant2.personal" :
            return 'UserApplicantPersonal'
        case "applicant1.health":
        case "applicant2.health":
            return "UserApplicantHealth"
        case "applicant1.income" :
        case "applicant2.income" :
            return 'UserApplicantIncome'
        case "applicant1.financial" :
        case "applicant2.financial" :
            return 'UserApplicantFinancial'
        case "applicant1.employment" :
        case "applicant2.employment" :
            return 'UserApplicantEmployment'
        case "applicant1.tax" :
        case "applicant2.tax" :
            return 'UserApplicantTax'
        case "applicant2.emails" :
            return 'AdminEmails'
        case "applicant1.emails" :
        case "applicant1.assets":
        case "applicant2.assets":
            return 'UserApplicantAsset'
        case "applicant1.debts":
        case "applicant2.debts":
            return 'UserApplicantDebt'
        case "applicant1.properties":
        case "applicant2.properties":
            return 'UserApplicantProperty'
        case "applicant1.alternateEmailAddresses":
        case "applicant2.alternateEmailAddresses":
            return 'UserApplicantAlternateEmailAddresses'
        default:
            throw new Error('Unknown model path: ' + path)
    }
}

const addMortgageToLastViewedList = async (mortgageId) => {
    const user = await Auth.currentAuthenticatedUser()
    const input = {
        mortgageID  : mortgageId,
        lastViewedOn: new Date().toISOString(),
        lastViewedBy: user.attributes.sub
    }
    await performGQL({input}, createAdminLastViewedMortgage)
}
const vitalAttributesForSync = {
    "PublicCouncils"                      : [
        "fullName",
        "shortName"
    ],
    "AdminPdfOverlayTemplate"             : [
        "lender",
        "lenderCode"
    ],
    "AdminPdfOverlayTemplateItem"         : [
        "name",
        "pageNo",
        "x",
        "y",
        "type",
        "templateId"
    ],
    "AdminEmails"                         : [
        "userapplicantID"
    ],
    "AdminEmailAttachments"               : [
        "adminemailsID"
    ],
    "AdminMortgageTasks"                  : [
        "usermortgageID"
    ],
    "AdminMortgageNotes"                  : [
        "usermortgageID"
    ],
    "AdminMortgageStageTask"              : [
        "mortgageID",
        "name"
    ],
    "AdminMortgageStageNotifications"     : [
        "mortgageID",
        "name"
    ],
    "AdminMortgageDocumentContent"        : [
        "mortgageID"
    ],
    "AdminLastViewedMortgage"             : [
        "mortgageID",
        "lastViewedOn",
        "lastViewedBy"
    ],
    "UserMortgageQuery"                   : [
        "mortgageID",
        "userAnswered",
        "adminAnswered"
    ],
    "UserMortgageInvitation"              : [
        "mortgageID",
        "applicant2ID"
    ],
    "UserMortgageDocuments"               : [
        "mortgageID"
    ],
    "UserMortgageProgress"                : [
        "mortgageID"
    ],
    "UserMortgageProgressNotes"           : [
        "userMortgageProgressID"
    ],
    "UserMortgageDynamicSection"          : [
        "mortgageID"
    ],
    "UserMortgageUploads"                 : [
        "mortgageID"
    ],
    "UserMortgageSubmission"                 : [
        "mortgageID"
    ],
    "UserApplicantCurrentAccount"         : [
        "applicantID"
    ],
    "UserApplicantAsset"                  : [
        "applicantID"
    ],
    "UserApplicantDebt"                   : [
        "applicantID"
    ],
    "UserApplicantProperty"               : [
        "applicantID"
    ],
    "UserApplicantAlternateEmailAddresses": [
        "applicantID"
    ],
    "UserApplicantActivity"               : [
        "actionedBy",
        "applicantID"
    ]
}
function MortgageProvider(props) {

    const sidebar = useContext(Sidebar)
    const app = App.useApp()
    const [mortgage, setMortgage] = useState(false)
    const [allSectionsLoaded, setAllSectionsLoaded] = useState(false)
    // ON LOAD INITIALISATION

    const fetchMortgage = async () => {
        setMortgage('fetching')

        const domainName = window.location.hostname;
        // if (domainName === 'locaelhost') {
        //     const local = localStorage.getItem('mortgage')
        //     if (local) {
        //         setMortgage(JSON.parse(local))
        //         return
        //     }
        // }
        try {
            const result = await getMortgage(props.id)
            if (result) {
                addMortgageToLastViewedList(props.id)
            }
            const m = prepareMortgageForState(result)

            setMortgage(m)
        }
        catch (e) {
            console.log(e)
            setMortgage(false)
        }
    }
    const ageFromDate = (date) => {
        if (!date) {
            return null
        }
        let dj = dayjs(date, "YYYY-MM-DD")

        if (dayjs.isDayjs(dj) && !dj.isValid()) {
            return null
        }
        return dayjs().diff(dj, 'years')
    }
    const prepareMortgageForState = (mortgage) => {

        return {
            ...mortgage,
            applicant1           : {
                ...mortgage.applicant1,
                accounts        : setAndContinueToPopulate(mortgage, 'applicant1.accounts', mortgage.applicant1.accounts),
                emails          : setAndContinueToPopulate(mortgage, 'applicant1.emails', mortgage.applicant1.emails),
                debts           : setAndContinueToPopulate(mortgage, 'applicant1.debts', mortgage.applicant1.debts),
                assets          : setAndContinueToPopulate(mortgage, 'applicant1.assets', mortgage.applicant1.assets),
                properties      : setAndContinueToPopulate(mortgage, 'applicant1.properties', mortgage.applicant1.properties),
                employmentIncome: setAndContinueToPopulate(mortgage, 'applicant1.employmentIncome', mortgage.applicant1.employmentIncome),

            },
            applicant2           : {
                ...mortgage.applicant2,
                accounts        : setAndContinueToPopulate(mortgage, 'applicant2.accounts', mortgage.applicant2.accounts),
                emails          : setAndContinueToPopulate(mortgage, 'applicant2.emails', mortgage.applicant2.emails),
                debts           : setAndContinueToPopulate(mortgage, 'applicant2.debts', mortgage.applicant2.debts),
                assets          : setAndContinueToPopulate(mortgage, 'applicant2.assets', mortgage.applicant2.assets),
                properties      : setAndContinueToPopulate(mortgage, 'applicant2.properties', mortgage.applicant2.properties),
                employmentIncome: setAndContinueToPopulate(mortgage, 'applicant2.employmentIncome', mortgage.applicant2.employmentIncome),

            },
            submissions          : setAndContinueToPopulate(mortgage, 'submissions', mortgage.submissions),
            docContents          : setAndContinueToPopulate(mortgage, 'docContents', mortgage.docContents),
            playgroundTests      : setAndContinueToPopulate(mortgage, 'playgroundTests', mortgage.playgroundTests),
            lenderTests          : setAndContinueToPopulate(mortgage, 'lenderTests', mortgage.lenderTests),
            replyRequests        : setAndContinueToPopulate(mortgage, 'replyRequests', mortgage.replyRequests),
            dynamicSections      : setAndContinueToPopulate(mortgage, 'dynamicSections', mortgage.dynamicSections),
            uploads              : setAndContinueToPopulate(mortgage, 'uploads', mortgage.uploads),
            progress             : setAndContinueToPopulate(mortgage, 'progress', mortgage.progress),
            notifications        : setAndContinueToPopulate(mortgage, 'notifications', mortgage.notifications),
            properties           : setAndContinueToPopulate(mortgage, 'properties', mortgage.properties),
            invitations          : setAndContinueToPopulate(mortgage, 'invitations', mortgage.invitations),
            notes                : setAndContinueToPopulate(mortgage, 'notes', mortgage.notes),
            queries              : setAndContinueToPopulate(mortgage, 'queries', mortgage.queries),
            tasks                : setAndContinueToPopulate(mortgage, 'tasks', mortgage.tasks),
            stageTasks           : setAndContinueToPopulate(mortgage, 'stageTasks', mortgage.stageTasks),
            UserMortgageDocuments: setAndContinueToPopulate(mortgage, 'UserMortgageDocuments', mortgage.UserMortgageDocuments),
        }
    }
    const allDoneCallback = (path) => {
        if (path === 'progress') {
            setAllSectionsLoaded(true)
        }
    }
    const setAndContinueToPopulate = (mortgage, path, response) => {
        if (response) {
            let {
                    items,
                    nextToken
                } = response
            //accept and set itital items, and if theres a nextToken, start process to retrieve all

            if (nextToken) {
                switch (path) {
                    case 'docContents':
                    case 'submissions':
                    case 'playgroundTests':
                    case 'properties':
                    case 'UserMortgageDocuments':
                    case 'invitations':
                    case 'progress':
                    case 'uploads':
                    case 'notifications':
                    case 'lenderTests':
                    case 'dynamicSections':
                        getMoreItems({
                            mortgageID: mortgage.id,
                            nextToken
                        }, path, setItems, allDoneCallback)
                        break
                    case 'applicant1.emails':
                    case 'applicant1.assets':
                    case 'applicant1.debts':
                    case 'applicant1.properties':
                    case 'applicant1.employmentIncome':
                        getMoreItems({
                            applicantID: mortgage.applicant1.id,
                            nextToken
                        }, path, setItems)
                        break
                    case 'applicant2.emails':
                    case 'applicant2.assets':
                    case 'applicant2.debts':
                    case 'applicant2.properties':
                    case 'applicant2.employmentIncome':
                        getMoreItems({
                            applicantID: mortgage.applicant2.id,
                            nextToken
                        }, path, setItems)
                        break
                    default:
                }
            }
            else {
                allDoneCallback(path)
            }

            return (items || []).filter(item => !item._deleted)
        }
        return null
    }
    const setItems = (path, items) => {
        let newItems = items
        if (path === 'progress') {
            newItems = newItems.map(item => {
                if (item.notes) {
                    return {
                        ...item,
                        notes: (item.notes.items)
                    }
                }
                return item

            })
        }
        setMortgage(s => {

            let copy = {...s}
            let existingItems = getItemFromPath(s, path)
            setItemFromPath(copy, path, [
                ...existingItems,
                ...newItems.filter(item => !item._deleted)
            ])
            return copy
        })
    }
    const applicantStrings = (applicant) => {
        const userIsApplicant = false
        const appName = applicant.firstName ? applicant.firstName : 'the other applicant'
        return {
            I      : userIsApplicant ? 'I' : appName,
            you    : userIsApplicant ? 'you' : appName,
            areYou : userIsApplicant ? 'are you' : 'is ' + appName,
            doYou  : userIsApplicant ? 'do you' : 'does ' + appName,
            wereYou: userIsApplicant ? 'were you' : 'was ' + appName,
            haveYou: userIsApplicant ? 'have you' : 'has ' + appName,
            your   : userIsApplicant ? 'your' : appName + `'s`,
        }
    }

    // MUTATION HANDLERS
    const getDefaultReturnItems = (name) => {
        const defaultReturnItems = [
            '_lastChangedAt',
            '_deleted',
            'createdAt',
            'updatedAt'
        ]
        if (name.startsWith('User')) {
            defaultReturnItems.push('readAccessGroup', 'editAccessGroup', 'owner')
        }
        const vital = vitalAttributesForSync[name]
        if (vital && vital.length) {
            vital.forEach(v => {
                if (!defaultReturnItems.includes(v)) {
                    defaultReturnItems.push(v)
                }
            })
        }
        return defaultReturnItems
    }
    async function reversibleUpdate(update) {
        // get orig values of what is about to be updated
        let orig = update.origPairs
        // save them to the server
        try {

            const variables = {
                input: {
                    id      : update.id,
                    _version: update._version, ...update.pairs
                }
            }

            const mutationName = 'Update' + update.name
            const defaultReturnItems = getDefaultReturnItems(update.name)

            const itemString = [
                ...Object.keys(variables.input),
                ...defaultReturnItems
            ].join('\n')

            const query = generateMutation(mutationName, itemString)

            const result = await performGQL(variables, query)
            setMortgage(s => {

                let {
                        id,
                        ...rest
                    } = result

                setItemFromPath(s, update.path, rest, 'objectMerge')
                return s
            })
            return result
        }
        catch (e) {
            console.log('Sorry! I have to undo that because ' + e.message)
            setMortgage(prevMortgage => {

                let copy = {...prevMortgage}
                setItemFromPath(copy, update.path, orig, 'objectMerge')
                app.message.error('Request failed! Your changes did not save')
                return copy
            })
        }
    }
    async function reversibleCreate(creation) {
        // save object to the server
        const variables = {
            input: {
                ...creation.pairs,
                id: creation.id
            }
        }
        const mutationName = 'Create' + creation.name
        const defaultReturnItems = getDefaultReturnItems(creation.name)
        if (!defaultReturnItems.includes('_version')) {
            defaultReturnItems.push('_version')
        }
        if (!defaultReturnItems.includes('id')) {
            defaultReturnItems.push('id')
        }
        if (!defaultReturnItems.includes('_lastChangedAt')) {
            defaultReturnItems.push('_lastChangedAt')
        }
        const itemString = [
            ...Object.keys(variables.input),
            ...defaultReturnItems
        ].join('\n')

        const query = generateMutation(mutationName, itemString)

        // on success replace the old object
        try {

            const result = await performGQL(variables, query)
            setMortgage(prevState => {
                let updatedState = {...prevState}
                let array = getItemFromPath(updatedState, creation.path).map(item => {
                    if (item.id === creation.id) {
                        return result
                    }
                    return item
                })
                setItemFromPath(updatedState, creation.path, array)
                return updatedState
            })

            return result
        }
            // on failure delete the old object
        catch (e) {
            app.message.error('Request failed! Your item did not save')
            console.log(e)
            setMortgage(prevState => {
                let updatedState = {...prevState}
                let array = getItemFromPath(updatedState, creation.path).filter(item => {
                    return item.id !== creation.id;
                })
                setItemFromPath(updatedState, creation.path, array)
                return updatedState
            })
        }
    }
    async function reversibleDelete(deletion) {

        const mutationName = 'Delete' + deletion.name
        const defaultReturnItems = getDefaultReturnItems(deletion.name)
        if (!defaultReturnItems.includes('id')) {
            defaultReturnItems.push('id')
        }
        if (!defaultReturnItems.includes('_version')) {
            defaultReturnItems.push('_version')
        }
        const query = generateMutation(mutationName, defaultReturnItems.join('\n'))
        const variables = {
            input: {
                id      : deletion.origItem.id,
                _version: deletion.origItem._version
            }
        }
        try {
            await performGQL(variables, query)
        }
            // on failure put back the old object
        catch (e) {
            //console.log(e)
            setMortgage(prevState => {

                let updatedState = {...prevState}
                let {type: path} = extractTypeAndId(deletion.path)
                let array = getItemFromPath(updatedState, path)

                setItemFromPath(updatedState, path, [
                    ...array,
                    deletion.origItem
                ])
                app.message.error('Request failed! Your item did not delete')
                return updatedState
            })
        }
    }
    const optimisticUpdate = (mortgageState, promises, update) => {

        /*
       updates = [
           {
           target: 'applicant1.personal.dateOfBirth',
           value: newValue,
           type: (optional) type of question that will do conversions prior to saving
           }
       ]
        */

        const modelsToUpdate = {}
        function addFieldToModelsToUpdate(field) {
            // only adds a promise to update the db and mortgage if the value has changed
            if (typeof field.target === "function") {
                field.target = field.target(mortgageState)
            }

            // if passed pairs props and model target
            if (field.hasOwnProperty('pairs')) {
                Object.keys(field.pairs).forEach(key => {
                    addFieldToModelsToUpdate({
                        target: `${field.target}.${key}`,
                        value : field.pairs[key]
                    })
                })
            }
            else {

                // if passed a value prop and full target
                let parts = field.target.split('.')
                let column = parts.pop()
                let path = parts.join('.')
                let modelName = 'mortgage.' + path
                let model = getItemFromPath(mortgageState, path)

                let oldFieldValue = model[column]
                let newFieldValue = field.value
                if (JSON.stringify(oldFieldValue) !== JSON.stringify(newFieldValue)) {
                    if (!modelsToUpdate.hasOwnProperty(modelName)) {
                        modelsToUpdate[modelName] = {
                            id       : model.id,
                            pairs    : {},
                            origPairs: {},
                            path     : path,
                            name     : getModelName(path),
                            _version : model._version,
                        }
                    }
                    modelsToUpdate[modelName].pairs[column] = newFieldValue
                    modelsToUpdate[modelName].origPairs[column] = oldFieldValue
                }
            }
        }
        if (Array.isArray(update)) {
            update.forEach((field, i) => {
                addFieldToModelsToUpdate(field)
            })
        }
        else {
            addFieldToModelsToUpdate(update)
        }
        // Immediately update the local mortgage and save to server
        Object.keys(modelsToUpdate).forEach(key => {
            let updateDetails = modelsToUpdate[key]
            if (!!promises) {
                promises.push(reversibleUpdate(updateDetails))
            }
            setItemFromPath(mortgageState, updateDetails.path, updateDetails.pairs, 'objectMerge')
        })
    }
    const optimisticDelete = (mortgageState, promises, deletion) => {
        const modelsToDelete = []
        function addFieldToModelsToDelete(field) {
            if (typeof field.target === "function") {
                field.target = field.target(mortgageState)
            }
            let origItem = getItemFromPath(mortgageState, `${field.target}[${field.id}]`)

            let tempObject = {
                path: field.target,
                name: getModelName(field.target),
                origItem
            }

            modelsToDelete.push(tempObject)
        }
        if (Array.isArray(deletion)) {
            deletion.forEach((field, i) => {
                addFieldToModelsToDelete(field)
            })
        }
        else {
            addFieldToModelsToDelete(deletion)
        }
        // Immediately delete from local mortgage and delete from server
        modelsToDelete.forEach(deleteDetails => {
            promises.push(reversibleDelete(deleteDetails))
            let array = getItemFromPath(mortgageState, deleteDetails.path)

            let arrayWithoutDeleted = array.filter(item => item.id !== deleteDetails.origItem.id)
            setItemFromPath(mortgageState, deleteDetails.path, arrayWithoutDeleted)
        })
    }
    const optimisticCreate = async (mortgageState, promises, creation) => {
        // create temp object in the state
        // create on the server
        // on error, delete the temp object
        // on success, replace the temp object with the real one,

        const modelsToCreate = []
        function addFieldToModelsToCreate(field) {
            if (typeof field.target === "function") {
                field.target = field.target(mortgageState)
            }
            let path = field.target

            // create a new temporary object with fake id and add it to mortgage
            let newId = field.pairs.hasOwnProperty('id') ? field.pairs.id : v4()

            const tempObject = {
                id   : newId,
                pairs: field.pairs,
                path : path,
                name : getModelName(path),
            }
            if (tempObject.name.startsWith('User')) {
                if (!tempObject.pairs.hasOwnProperty('readAccessGroup')) {
                    tempObject.pairs.readAccessGroup = readAccessGroup(mortgageState, path)
                }
                if (!tempObject.pairs.hasOwnProperty('editAccessGroup')) {
                    tempObject.pairs.editAccessGroup = editAccessGroup(mortgageState, path)
                }
            }

            modelsToCreate.push(tempObject)
        }
        if (Array.isArray(creation)) {
            creation.forEach((field, i) => {
                addFieldToModelsToCreate(field)
            })
        }
        else {
            addFieldToModelsToCreate(creation)
        }

        // Immediately update the local mortgage and save to server
        modelsToCreate.forEach(createDetails => {
            promises.push(reversibleCreate(createDetails))
            let fakeObject = {
                id       : createDetails.id,
                createdAt: (new Date()).toISOString(), // _version: 1,
                ...createDetails.pairs
            }

            setItemFromPath(mortgageState, createDetails.path, fakeObject, 'arrayAdd')
        })
    }
    const optimisticMutate = async (obj) => {
        let promises = []
        setMortgage(prevMortgage => {

            let copyMortgage = {...prevMortgage}
            if (obj?.update) {
                optimisticUpdate(copyMortgage, promises, obj.update,)
            }
            if (obj?.create) {
                optimisticCreate(copyMortgage, promises, obj.create)
            }
            if (obj?.delete) {
                optimisticDelete(copyMortgage, promises, obj.delete)
            }
            return copyMortgage
        })
        try {
            const resultArray = await Promise.all(promises)
            return resultArray
        }
        catch (e) {
            //console.log(e)

        }
    }
    const localMutate = (obj) => {
        setMortgage(prevMortgage => {

            let copyMortgage = {...prevMortgage}
            if (obj?.update) {
                optimisticUpdate(copyMortgage, null, obj.update,)
            }
            return copyMortgage
        })
    }

    // RENDER
    useEffect(() => {
        if (!mortgage) {
            fetchMortgage()
        }
        return () => {
            app.notification.destroy()
        }
    }, [mortgage.id])
    const timer = useRef(null)
    useEffect(() => {
        if (allSectionsLoaded) {
            sidebar.setMortgage(mortgage)
            const domainName = window.location.hostname;
            if (domainName === 'localhost') {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                    localStorage.setItem('mortgage', JSON.stringify(mortgage))
                }, 1000)
            }
        }
    }, [allSectionsLoaded]);
    const getMortgageValue = () => {
        let app1incomes = mortgage.applicant1.employmentIncome?.length ? mortgage.applicant1.employmentIncome.map(item => ({
            ...item,
            incomes: item.incomes ? JSON.parse(item.incomes) : undefined
        })) : undefined
        let app2incomes = mortgage.applicant2.employmentIncome?.length ? mortgage.applicant2.employmentIncome.map(item => ({
            ...item,
            incomes: item.incomes ? JSON.parse(item.incomes) : undefined
        })) : undefined
        let betterMortgage = {
            ...mortgage,
            applicant1: {
                ...mortgage.applicant1,
                employmentIncome: app1incomes,
                strings         : applicantStrings(mortgage.applicant1),
                fullName        : capitaliseWords(`${mortgage.applicant1.firstName} ${mortgage.applicant1.surname || ''}`.trim()),
                age             : ageFromDate(mortgage.applicant1.personal.dateOfBirth)
            },
            applicant2                : {
                ...mortgage.applicant2,
                employmentIncome: app2incomes,
                strings         : applicantStrings(mortgage.applicant2),
                fullName        : capitaliseWords(`${mortgage.applicant2.firstName} ${mortgage.applicant2.surname || ''}`.trim()),
                age             : ageFromDate(mortgage.applicant2.personal.dateOfBirth)
            },
        }
        const submissions = mortgage.submissions.filter(a => !a.invalidatedReason).sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
        }).map(item => {
            return {
                ...item,
                completedFlowItems        : JSON.parse(item.completedFlowItems || '{}'),
                completedFlowItemsForAdmin: JSON.parse(item.completedFlowItemsForAdmin || '{}'),
                provisionalXlsOutput      : item.provisionalXlsOutput ? JSON.parse(item.provisionalXlsOutput) : null,
                provisionalXlsInput       : item.provisionalXlsInput ? JSON.parse(item.provisionalXlsInput) : null,
                aipSettings: JSON.parse(item?.aipSettings || '{}'),
                loanOfferSettings: JSON.parse(item?.loanOfferSettings || '{}')
            }
        })
        const activeSubmission = submissions.length ? submissions[0] : null
        const _getAccountNumbers = () => {
            let accountNumbers = []
            const getRecord = (name, index)=>{
                return mortgage.progress.find(item => {
                    if (item.deleted) {
                        return false
                    }
                    if (item.sectionType === 'UPLOAD' && item.applicationStage === 'APPLY' && item.sectionName === name) {
                        if (index) {
                            return item.sectionIndex === index
                        }
                        return true
                    }
                    return false
                })
            }
            mortgage.applicant1.accounts.forEach(account => {
                const record = getRecord('current-accounts-1', account.index)
                if (!record || record?.viewState !== ProgressViewState.HIDDEN) {
                    accountNumbers.push({
                        source: account.institution,
                        applicant:1,
                        accountNumber: account.accountNumber
                    })
                }

            })
            mortgage.applicant1.assets.forEach(asset => {
                const record = getRecord('assets-1', asset.index)
                if (!record || record?.viewState !== ProgressViewState.HIDDEN) {
                    accountNumbers.push({
                        source       : asset.description || asset.institution,
                        applicant    : 1,
                        accountNumber: asset.accountNumber
                    })
                }
            })
            if (mortgage.twoApplicants){

                mortgage.applicant2.accounts.forEach(account => {
                    const record = getRecord('current-accounts-2', account.index)
                    if (!record || record?.viewState !== ProgressViewState.HIDDEN) {
                        accountNumbers.push({
                            source       : account.institution,
                            applicant    : 2,
                            accountNumber: account.accountNumber
                        })
                    }
                })
                mortgage.applicant2.assets.forEach(asset => {
                    const record = getRecord('assets-2', asset.index)
                    if (!record || record?.viewState !== ProgressViewState.HIDDEN) {
                        accountNumbers.push({
                            source       : asset.description || asset.institution,
                            applicant    : 2,
                            accountNumber: asset.accountNumber
                        })
                    }
                })
            }
            return accountNumbers
        }

        return {
            ...betterMortgage,
            completedFlowItems        : JSON.parse(mortgage.completedFlowItems || '{}'),
            completedFlowItemsForAdmin: JSON.parse(mortgage.completedFlowItemsForAdmin || '{}'),
            requirement               : {
                ...mortgage.requirement,
                proposals: JSON.parse(mortgage.requirement.proposals || '{}'),
            },
            progress                  : mortgage.progress.map(item => {
                return {
                    ...item,
                    verifiedData: JSON.parse(item.verifiedData || '{}'),
                }
            }),
            lenderTests               : mortgage.playgroundTests.flatMap(item => {
                let results = JSON.parse(item.results || '{}')
                let data = JSON.parse(item.data || '{}')
                let lenders = Object.keys(data)
                if (!lenders.length) {
                    return []
                }
                if (!data.hasOwnProperty('figures')) {
                    return []
                }
                return [
                    {
                        ...item,
                        results,
                        data
                    }
                ]
            }),
            playgroundTests           : mortgage.playgroundTests.filter(item => {

                let data = JSON.parse(item.data || '{}')
                let lenders = Object.keys(data)
                if (!lenders.length) {
                    return false
                }
                if (data.hasOwnProperty('figures')) {
                    return false
                }
                return true
            }),
            lostUploads               : mortgage.uploads.filter(it => it.status === 'PENDING' || it.status === 'REVIEWING'),
            applicationTitle          : betterMortgage.applicant1.fullName + (betterMortgage.twoApplicants ? ' & ' + betterMortgage.applicant2.fullName : ''),
            submissions               ,
            mutate                    : optimisticMutate,
            localMutate               : localMutate,
            activeSubmission: !!activeSubmission ? expandActiveSubmission(activeSubmission) : null,
            setMortgage,
            bankAccountNumbers: _getAccountNumbers(),
        }
    }
    const lastSeenUpdated = useRef(null)
    useEffect(() => {
        if (!lastSeenUpdated.current && !!allSectionsLoaded) {

            lastSeenUpdated.current = true
            optimisticMutate({
                update: {
                    target: 'lastSeen',
                    value : new Date().toISOString()
                }
            })
        }
    }, [allSectionsLoaded]);
    const mortgageValue = mortgage && mortgage !== 'fetching' && allSectionsLoaded ? getMortgageValue() : false

    if (!mortgageValue) {
        return <Skeleton loading={true}/>
    }

    return <Mortgage.Provider value={mortgageValue}>
        {props.children}
        <Repairs mortgage={mortgageValue}/>
    </Mortgage.Provider>
}
export default MortgageProvider

function Repairs({mortgage}) {

    const createUserApplicantHealth = /* GraphQL */ `
        mutation CreateUserApplicantHealth(
            $input: CreateUserApplicantHealthInput!
            $condition: ModelUserApplicantHealthConditionInput
        ) {
            createUserApplicantHealth(input: $input, condition: $condition) {
                id
                weight
                height
                smoker
                everSmoked
                stoppedSmokingDate
                alcoholUnits
                dangerousActivities
                familyDeath
                diagnosedConditions
                diagnosedConditionsDetails
                prescribedMedication
                prescribedMedicationDetails
                hasGp
                gpName
                gpAddress
                gpLastVisit
                yearsWithGp
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
            }
        }
    `;
    const repairHealth = async () => {

        let promises = []
        if (!mortgage.applicant1.health) {
            promises.push(performGQL({
                input: {
                    id             : mortgage.applicant1.id,
                    readAccessGroup: [`${mortgage.id}-app2`],
                    editAccessGroup: [
                        `${mortgage.id}-app1`,
                        `${mortgage.applicant1.id}-appId`
                    ],
                }
            }, createUserApplicantHealth))
            promises.push(performGQL({
                input: {
                    id             : mortgage.applicant2.id,
                    readAccessGroup: [],
                    editAccessGroup: [
                        `${mortgage.id}-app1`,
                        `${mortgage.id}-app2`,
                        `${mortgage.applicant2.id}-appId`
                    ],
                }
            }, createUserApplicantHealth))
        }
        if (promises.length) {
            let result = await Promise.all(promises)
            mortgage.setMortgage(s => {
                return {
                    ...s,
                    applicant1: {
                        ...s.applicant1,
                        health: result[0]
                    },
                    applicant2: {
                        ...s.applicant2,
                        health: result[1]
                    }
                }
            })
        }
    }

    const repairCompletedFlowItemsForAdmin = async () => {

        let newVal = {...mortgage.completedFlowItemsForAdmin}

        let old_incompleteEmailSent = mortgage.stageTasks.find(item => item.name === 'setupIncomplete.email.initial')
        if (old_incompleteEmailSent) {
            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('incompleteEmailSent')) {
                newVal.incompleteEmailSent = {
                    on: old_incompleteEmailSent.completedOn,
                    by: old_incompleteEmailSent.completedBy
                }
            }
        }

        let old_incompleteReminderEmailSent = mortgage.stageTasks.find(item => item.name === 'setupIncomplete.email.reminder1')
        if (old_incompleteReminderEmailSent) {
            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('incompleteReminderEmailSent')) {
                newVal.incompleteReminderEmailSent = {
                    on: old_incompleteReminderEmailSent.completedOn,
                    by: old_incompleteReminderEmailSent.completedBy
                }
            }
        }

        let old_qualificationEmailSent = mortgage.stageTasks.find(item => item.name === 'sendQualificationEmail.email.initial')
        if (old_qualificationEmailSent) {

            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('qualificationEmailSent')) {
                newVal.qualificationEmailSent = {
                    on: old_qualificationEmailSent.completedOn,
                    by: old_qualificationEmailSent.completedBy
                }
            }
        }

        let old_qualificationReminderEmailSent = mortgage.stageTasks.find(item => item.name === 'sendQualificationEmail.email.reminder1')
        if (old_qualificationReminderEmailSent) {
            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('qualificationReminderEmailSent')) {
                newVal.qualificationReminderEmailSent = {
                    on: old_qualificationReminderEmailSent.completedOn,
                    by: old_qualificationReminderEmailSent.completedBy
                }
            }
        }

        let old_discussionEmailSent = mortgage.stageTasks.find(item => item.name === 'sendMoveToApplyEmail.email.initial')
        if (old_discussionEmailSent) {
            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('discussionEmailSent')) {
                newVal.discussionEmailSent = {
                    on: old_discussionEmailSent.completedOn,
                    by: old_discussionEmailSent.completedBy
                }
            }
        }

        let old_discussionReminderEmailSent = mortgage.stageTasks.find(item => item.name === 'sendMoveToApplyEmail.email.reminder1')
        if (old_discussionReminderEmailSent) {
            if (!mortgage.completedFlowItemsForAdmin.hasOwnProperty('discussionReminderEmailSent')) {
                newVal.discussionReminderEmailSent = {
                    on: old_discussionReminderEmailSent.completedOn,
                    by: old_discussionReminderEmailSent.completedBy
                }
            }
        }

        let update = {
            target: 'completedFlowItemsForAdmin',
            value : JSON.stringify(newVal)
        }

        await mortgage.mutate({update})
        console.log({update})
    }

    useEffect(() => {
        if (!mortgage.applicant1.health) {
            repairHealth()
        }
        // repairCompletedFlowItemsForAdmin()
    }, []);

    return null
}