import React, {useEffect, useRef} from 'react';
import {Skeleton, Typography, App, Button} from "antd";
import ActionBar from "./ActionBar";
import {useNavigate, useParams} from "react-router-dom";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {ArrowRightOutlined} from "@ant-design/icons";
import AttachmentButtonGroups, {getAttachmentPath} from "../../emails-manager/AttachmentButtonGroups";
import {magenta, purple} from "@ant-design/colors";
import dayjs from "dayjs";
import useApplicationUploads from "../../../providers/mortgage/useApplicationUploads";
import {capitaliseWords} from "../../../../assets/scripts/string-functions";
import useMortgageUploadService, {getUserUploadKey} from "../../../providers/mortgage/useMortgageUploadService";
import {getAdminEmails} from "../../../../graphql/queries";
import {performGQL} from "../../../api-functions/functions";
import {ApplicationStages, ReplyRequestTypes} from "../../../../models";
import preapprovalUploads from "../application-stages/pre-approval/useUploads";
import useMortgageDocumentsService from "../../../providers/mortgage/useMortgageDocumentsService";
import ApprovalInPrincipleModal from "./identifiers/ApprovalInPrincipleModal";
import LoanOfferModal from "./identifiers/LoanOfferModal";
import useCurrentOutstandingItemsEmail from "../../../../config-files/mortgage/emails/useCurrentOutstandingItemsEmail";
import PropertyValuation from "./identifiers/PropertyValuation";
import ESISModal from "./identifiers/ESISModal";
//import approvalUploads from "../application-stages/approval/useUploads";
const approvalUploads = []
function EmailReader(props) {
    const app = App.useApp()
    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const [email, setEmail] = React.useState(false)
    const [attachmentStatus, setAttachmentStatus] = React.useState({})
    const [vitalDocument, setVitalDocument] = React.useState(false)
    const {composeOutstandingEmail} = useCurrentOutstandingItemsEmail()
    const params = useParams()
    const applicationUploads = useApplicationUploads()
    const documentService = useMortgageDocumentsService()
    const navigate = useNavigate()
    const iframe = useRef(null)
    /* ACTIONS */
    const fetchEmail = async () => {
        if (params?.emailId) {
            const email = await performGQL({id: params.emailId}, getAdminEmails)
            // Replace all occurrences of "http://" with "https://"

            setEmail(email)
        }
    }

    /* EFFECT */
    useEffect(() => {
        if (params.emailId) {
            setEmail(false)
            fetchEmail()
        }
    }, [params.emailId])

    useEffect(() => {
        if (iframe.current) {
            let htmlString = email.html
            if (htmlString) {
                htmlString = htmlString.replace(/http:\/\/(?!s)/g, 'https://');
            }
            iframe.current.srcdoc = htmlString
        }
    }, [iframe.current, email])

    if (!params.emailId) {
        return (
            <div className="d-col gap-15 p-15">
                <Button onClick={()=>composeOutstandingEmail(mortgage.applicationStage)}>Email Outstanding Items</Button>
            </div>
        )
    }
    function EmailFrom({item}) {
        const styleProp = {fontSize: 11}
        if (item.isReceived) {
            styleProp.color = magenta[5]
            styleProp.transform = 'rotate(45deg)'
        } else {
            styleProp.color = purple[5]
            styleProp.transform = 'rotate(315deg)'
        }
        return <div className="d-col">
            <div className="d-row gap-6 a-start mb-6">
                {item.from}
                <ArrowRightOutlined className="mx-6 mt-6" style={styleProp}/>
                <div className="d-col">
                    {item.to.map((to, i) => <span key={to}>{to}</span>)}
                </div>
                {
                    (item.cc && item.cc.length > 0) && (
                        <>
                            <span className="mx-6" style={{fontSize: 11, fontWeight: 'bold', color: magenta[5]}}>cc</span>
                            <div className="d-col">
                                {item.cc.map((to, i) => <span key={to}>{to}</span>)}
                            </div>

                        </>
                    )
                }

            </div>
        </div>

    }

    const updateAttachmentStatus = (key, status) => {
        setAttachmentStatus(s => {
            return {
                ...s,
                [key]: status
            }
        })
    }
    const copyAttachmentToUploads = async (attachment, stage, section, index) => {
        updateAttachmentStatus(attachment.bucketKey, 'busy')
        const source = getAttachmentPath(attachment, email.v2Attachments)
        const file = {
            name: attachment.name,
            type: attachment.contentType,
            size: parseInt(attachment.size) * 1024 * 1024
        }

        await uploadService.copyAttachment(source, file, stage, section, index)
        updateAttachmentStatus(attachment.bucketKey, 'success')
    }

    const markAttachmentAsVitalDocument = async (attachment, vitalDocumentType) => {
        setVitalDocument({attachment, vitalDocumentType})
    }
    const menuItems = (attachment) => {
        const menu = []
        switch (mortgage.applicationStage) {
            case ApplicationStages.SIGN:
            case ApplicationStages.APPLY:
                menu.push({
                    key: '1',
                    type: 'group',
                    label: `Copy To ${mortgage.applicationStage} Uploads`,
                    children: [
                        {
                            key: '1-1',
                            label: mortgage.applicant1.firstName,
                            children: applicationUploads.apply.filter(item => item.applicant === 1).map(item => {
                                return {
                                    key: `1-1-${item.name}`,
                                    label: capitaliseWords(item.title),
                                    onClick: async () => {
                                        await copyAttachmentToUploads(attachment, item.stage, item.name, item?.index)
                                    }
                                }
                            })
                        }
                    ]
                })
                if (mortgage.twoApplicants) {
                    menu[0].children.push({
                        key: '1-2',
                        label: mortgage.applicant2.firstName || 'Other applicant',
                        children: applicationUploads.apply.filter(item => item.applicant === 2).map(item => {
                            return {
                                key: `1-2-${item.name}`,
                                label: capitaliseWords(item.title),
                                onClick: async () => {
                                    await copyAttachmentToUploads(attachment, item.stage, item.name, item?.index)
                                }
                            }
                        })
                    })
                }
                menu[0].children.push({
                    key: '1-3',
                    label: 'Mortgage',
                    children: applicationUploads.apply.filter(item => !item.applicant).map(item => {
                        return {
                            key: `1-3-${item.name}`,
                            label: capitaliseWords(item.title),
                            onClick: async () => {
                                await copyAttachmentToUploads(attachment, item.stage, item.name, item?.index)
                            }
                        }
                    })
                })
                menu.push({
                    key: '9',
                    type: 'group',
                    label: `Identify Document`,
                    children: [
                        {
                            key: '9-1',
                            label: 'Approval in Principle',
                            onClick: () => {
                                markAttachmentAsVitalDocument(attachment, 'ApprovalInPrinciple')
                            }
                        }
                    ]
                })
                break
            case ApplicationStages.PREAPPROVAL:
                menu.push({
                    key: '1',
                    type: 'group',
                    label: `Copy To ${mortgage.applicationStage} Uploads`,
                    children: applicationUploads.preapproval.map(item => {
                        return {
                            key: item.name,
                            label: capitaliseWords(item.title),
                            onClick: async () => {
                                await copyAttachmentToUploads(attachment, item.stage, item.name, item?.index)
                            }
                        }
                    })
                })
                menu.push({
                    key: '9',
                    type: 'group',
                    label: `Identify Document`,
                    children: (function(){
                        let items =  [
                            {
                                key: '9-1',
                                label: 'Property Valuation',
                                onClick: () => {
                                    markAttachmentAsVitalDocument(attachment, 'PropertyValuation')
                                }
                            }
                        ]
                        if (mortgage.activeSubmission.lenderCode === 'avant'){
                            items.push({
                                key: '9-2',
                                label: 'ESIS',
                                onClick: () => {
                                    markAttachmentAsVitalDocument(attachment, 'ESIS')
                                }
                            })
                        }
                        items.push({
                            key: '9-3',
                            label: 'Loan Offer',
                            onClick: () => {
                                markAttachmentAsVitalDocument(attachment, 'LoanOffer')
                            }
                        })
                        return items
                    })()
                })
                break
            case ApplicationStages.APPROVAL:
                menu.push({
                    key: '1',
                    type: 'group',
                    label: `Copy To ${mortgage.applicationStage} Uploads`,
                    children: applicationUploads.approval.map(item => {
                        return {
                            key: item.name,
                            label: capitaliseWords(item.title),
                            onClick: async () => {
                                await copyAttachmentToUploads(attachment, item.stage, item.name, false)
                            }
                        }
                    })
                })
                menu.push({
                    key: '9',
                    type: 'group',
                    label: `Identify Document`,
                    children: (function(){
                        let items =  [
                            {
                                key: '9-1',
                                label: 'Property Valuation',
                                onClick: () => {
                                    markAttachmentAsVitalDocument(attachment, 'PropertyValuation')
                                }
                            }
                        ]
                        if (mortgage.activeSubmission.lenderCode === 'avant'){
                            items.push({
                                key: '9-2',
                                label: 'ESIS',
                                onClick: () => {
                                    markAttachmentAsVitalDocument(attachment, 'ESIS')
                                }
                            })
                        }
                        return items
                    })()
                })
                break
        }
        return menu
    }
    function AttachmentDecider() {
        if (!!email.hasAttachments) {
            const data = JSON.parse(email.attachments)
            return (
                <div className="py-6">
                    <AttachmentButtonGroups menuItems={email.isReceived ? menuItems:false} data={data} attachmentStatus={attachmentStatus}/>
                </div>
            )
        }
        return null
    }

    return (
        <Skeleton loading={!mortgage || !email}>
            {(!!mortgage && !!email) && (
                <div key={`email-read${email.id}`}>

                    <div style={{backgroundColor: '#fafafa'}}>
                        <div className="mx-15 py-6">
                            <div className="d-row j-between">
                                <div><EmailFrom item={email}/></div>
                                <div
                                    style={{textAlign: 'right'}}>{dayjs(email.createdAt).format('DD MMM YYYY, HH:mm')}</div>
                            </div>

                        </div>

                    </div>
                    <div className="d-flex px-15 mb-6">
                        <Typography.Title level={5} style={{margin: 0}}>{email.subject}</Typography.Title>
                    </div>
                    {
                        email.isReceived &&
                        <ActionBar key={'adminEmail' + email.id} email={email} markActioned={props.markActioned}/>
                    }

                    <AttachmentDecider/>


                    <div className="mx-6" style={{userSelect: 'all', flexGrow: 1}}>
                        <iframe ref={iframe} title="read-message"
                                style={{border: 0, width: '100%', height: 'calc(100vh - 300px)'}}
                        />
                    </div>

                    {vitalDocument && (
                        <>
                            {vitalDocument.vitalDocumentType === 'PropertyValuation' && (
                                <PropertyValuation email={email} attachment={vitalDocument.attachment} onCancel={()=>setVitalDocument(null)}/>
                            )}
                            {vitalDocument.vitalDocumentType === 'ApprovalInPrinciple' && (
                                <ApprovalInPrincipleModal email={email} attachment={vitalDocument.attachment} onCancel={()=>setVitalDocument(null)}/>
                            )}
                            {vitalDocument.vitalDocumentType === 'LoanOffer' && (
                                <LoanOfferModal  email={email} attachment={vitalDocument.attachment}  onCancel={()=>setVitalDocument(null)}/>
                            )}
                            {vitalDocument.vitalDocumentType === 'ESIS' && (
                                <ESISModal  email={email} attachment={vitalDocument.attachment}  onCancel={()=>setVitalDocument(null)}/>
                            )}
                        </>
                    )}
                    {/*{*/}
                    {/*    !!state.linkModalOpen && <LinkToApplicantModal email={props.email} removeEmail={props.removeEmail} closeModal={()=>{setState(s=>({...s,linkModalOpen: false}))}} />*/}
                    {/*}*/}

                </div>
            )}
        </Skeleton>
    );
}



export default EmailReader;