import avant from "./avant";
import ptsb from "./ptsb";
import haven from "./haven";
import ics from "./ics";
export const set = (coord, value) => {
    return {
        c: coord,
        v: value
    }
}
export const get = async (url, spreadsheetId, sheetName, set, get, mortgage, saveOutputFile) => {
    try {
        let fileName = mortgage.friendlyName || mortgage.applicationTitle.replace(/[^a-zA-Z0-9]/g, '')
        fileName += '.xls'
        const data = {
            spreadsheetId,
            sheetName,
            set,
            get,
            mortgageId: mortgage.id,
            fileName,
        }
        if (saveOutputFile){
            data.save = true
        }
        console.log({data})

        const encodedParams = encodeURIComponent(JSON.stringify(data));
        const loc = `${url}?data=${encodedParams}`;
        const response = await fetch(loc, {
            method: 'GET',
            redirect: 'follow',
            headers: {
                "Content-Type": "text/plain;charset=utf-8",
            },
        });
        let ret = await response.json();
       // ret.data.sentValues = sentValues
        return ret
    } catch (error) {
        // Handle errors
        console.error('There was a problem with the fetch operation:', error);
    }
}
export const dataPoint = (name, coord, question, getDefaultValue, getFormValue, getFormValueNew) => {
    return {
        name,
        coord,
        question,
        defaultValue: typeof getDefaultValue === 'function' ? getDefaultValue() : getDefaultValue,
        getFormValue,
        getFormValueNew
    }
}
export const getNullCells = (object, map = {}) => {
    Object.keys(object).forEach(key => {
        if (Array.isArray(object[key])) {
            object[key].forEach((item, index) => {
                map[item.coord] = ''
            })
        } else {
            getNullCells(object[key], map)
        }
    })
    return map
}
export const getInitialValues = (object, data, map = {}) => {
    Object.keys(object).forEach(key => {
        if (Array.isArray(object[key])) {
            object[key].forEach((item, index) => {

                if (!!item.getFormValue && typeof item.getFormValue === 'function' && Object.keys(data).length > 0){
                    map[item.coord] = item.getFormValue(data)
                } else {
                    map[item.coord] = item.defaultValue || ''
                }

            })
        } else {
            getInitialValues(object[key], data, map)
        }
    })
    return map
}
export const getInitialValuesNew = (object, data, map = {}) => {
    Object.keys(object).forEach(key => {
        if (Array.isArray(object[key])) {
            object[key].forEach((item, index) => {

                if (!!item.getFormValueNew && typeof item.getFormValueNew === 'function' && Object.keys(data).length > 0){
                    map[item.coord] = item.getFormValueNew(data)
                } else {
                    map[item.coord] = (!!item.defaultValue || item.defaultValue === 0) ? item.defaultValue : ''
                }

            })
        } else {
            getInitialValuesNew(object[key], data, map)
        }
    })
    return map
}
export const getDataSent = (object, data) => {
    const deepCopyWithFunctions = obj => {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map(deepCopyWithFunctions);
        }
        const copy = {};
        Object.keys(obj).forEach(key => {
            copy[key] = deepCopyWithFunctions(obj[key]);
        });
        return copy;
    };

    const reduceObject = (obj, data) => {
        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
                obj[key].forEach(item => {
                    if (item.getFormValue && typeof item.getFormValue === 'function' && Object.keys(data).length > 0) {
                        item.formValue = item.getFormValue(data);
                    } else {
                        item.formValue = item.defaultValue || '';
                    }
                });
            } else {
                obj[key] = reduceObject(obj[key], data);
            }
        });
        return obj;
    };

    return reduceObject(deepCopyWithFunctions(object), data);
};
export {avant, ptsb, haven, ics}