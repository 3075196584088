Number.prototype.toEuro = function() {
    if (isNaN(this)) {
        return this;
    }
    return this.toLocaleString('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
};