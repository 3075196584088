import React, {useEffect} from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import nua from "../../../../../../../assets/scripts/lender-calculators/nua";
import {Form} from "antd";
import {getInitialValues, getInitialValuesNew} from "../../../../../../../assets/scripts/lender-calculators";

function useNuaWebForm(props) {
    const mortgage = useMortgage()
    const api = nua({mortgage})
    const [form] = Form.useForm();

    const [playgroundData, setPlaygroundData] = React.useState({}) // for use with legacy tests
    const [state, setState] = React.useState({
        results: null,
        loading: false,
        error  : null,
        values : {}
    }) // for use with legacy tests

    const getResults = async () => { // for use with legacy tests
        setState(s => ({
            ...s,
            loading: true
        }))
        try {
            let valuesToUse = {
                ...state.values,
                term: state.values.loanTerm,
                mortgageAmount: state.values.loanAmount,
            }
            const config = getConfig(valuesToUse, mortgage.twoApplicants, true)

            const results = await test(config)
            if (results.error) {
                setState(s => ({
                    ...s,
                    error  : results.error.message,
                    loading: false
                }))
            }
            else {
                setState(s => ({
                    ...s,
                    results: results.processedResults,
                    loading: false
                }))
                return results.processedResults
            }
        }
        catch (error) {
            setState(s => ({
                ...s,
                error,
                loading: false
            }))
        }
    }
    const getProcessedResults = (results) => {
        console.log({results})
        return results.map(result => {
            let o ={
                key  : result.label,
                label: result.label,
                value: result.value,
            }
            if (result.testMessage){
                if ( result.testMessage.toLowerCase().trim() === 'pass'){
                    o.passed = true
                }
                if ( result.testMessage.toLowerCase().trim() === 'fail'){
                    o.passed = false

                }
                if ( result.testMessage.toLowerCase().trim() === 'review'){
                    o.review = true
                    o.passed = false
                }
            }
            return o
        })
    }
    const getConfig = (formValues, twoApplicants, $forLegacyTest = false)=>{

        let config = $forLegacyTest ? getInitialValues(api.dataPoints, formValues) : getInitialValuesNew(api.dataPoints, formValues)

        let object = {
            borrower1: {},
        }
        if (twoApplicants){
            object.borrower2 = {}
        }
        Object.keys(config).forEach(key=>{
            let s1 = 'applicant1.'
            if (key.startsWith(s1)){
                object.borrower1[key.replace(s1, '')] = config[key]
                return
            }
            if (twoApplicants){
                let s2 = 'applicant2.'
                if (key.startsWith(s2)){
                    object.borrower2[key.replace(s2, '')] = config[key]
                    return
                }

            }
            object[key] = config[key]
        })

        return object
    }
    const setForm = (figures) => {
        let config = getInitialValuesNew(api.dataPoints, figures)
        form.setFieldsValue(config)
    }
    const test = async (config) => {

        let response = await api.get(config)

        if (!response.success) {
            return {
                error: response.error
            }
        }
        else {
            const processedResults = getProcessedResults(response.results)
            // const newWindow = window.open('', '_blank');
            // newWindow.document.open();
            // newWindow.document.write(response.htmlContent);
            // newWindow.document.close();

            return {
                results:response.results,
                processedResults
            }
        }
    }

    const setAnswer = (key, value) => {
        console.log({setAnswer: {key, value}})
        // const keys = key.split(':')
        // const coord = keys.pop()
        const coord = key
        setState(s => ({
            ...s,
            values: {
                ...s.values,
                [coord]: value
            }
        }))
    } // for use with legacy tests

    const forceSet = ({ // for use with legacy tests
        results,
        values
    }) => {
        console.log({forceSet: {results, values}})
        setState(s => ({
            ...s,
            results,
            values
        }))
        form.setFieldsValue(values)
    }
    useEffect(() => { // for use with legacy tests

        const values = getInitialValues(api.dataPoints, playgroundData)

        setState(s => {
            return {
                ...s,
                values
            }
        })

        form.setFieldsValue(values)
    }, [playgroundData]);
    return {
        //setAnswer, inputs are read only
        setForm,
        form,
        test,
        getConfig,
        dataPoints: api.dataPoints,
        //required for legacy lender test
        setPlaygroundData,
        state,
        getResults,
        forceSet,
        setAnswer
    }
}

export default useNuaWebForm;