import React, {useEffect} from 'react';
import useMortgage from "../../../providers/mortgage/useMortgage";
import {Alert, AutoComplete, Button, Card, Select, Skeleton, Table, Tabs} from "antd";
import {CheckOutlined, DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import useMortgageDocumentsService from "../../../providers/mortgage/useMortgageDocumentsService";
import {capitaliseWords} from "../../../../assets/scripts/string-functions";
import dayjs from "dayjs";
import {LONGDATETIME12} from "../../../../assets/scripts/constants";
import PageHeader from "../../PageHeader";
import {ApplicationStages} from "../../../../models";
import LenderSubmissionDocumentTable from "./LenderSubmissionDocumentTable";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import {Auth} from "aws-amplify";
import DocumentUploader from "./DocumentUploader";
function MortgageDocuments(props) {

    const mortgage = useMortgage()
    const [stage,setStage] = React.useState(false)

    const documentService = useMortgageDocumentsService()
    useEffect(() => {
        if (mortgage && mortgage.applicationStage !== stage) {
            setStage(mortgage.applicationStage)
        }
    }, [mortgage])
    if (!mortgage) {
        return <Skeleton loading={true}/>
    }
    // const masterSource = mortgage.UserMortgageDocuments.filter(it => {
    //     return it.stage === stage
    // }).map(it => ({...it, key: it.id + 'document'}))
    const masterSource = mortgage.UserMortgageDocuments.map(it => ({...it, key: it.id + 'document'})).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))

    function DocumentActions({document}) {
        const deleteDocument = () => {
            mortgage.mutate({
                delete: {target: 'UserMortgageDocuments', id: document.id}
            })
        }
        const openDocument = async () => {
            await documentService.open(document)
        }
        return <div className="j-end d-row gap-9">
            <Button onClick={openDocument}>Open</Button>
            <Button icon={<DeleteOutlined/>} onClick={deleteDocument}/>
        </div>
    }

    const columns = [

        {
            title: 'Date',
            key: 'date',
            render: (text, record) => {
               return dayjs(record.createdAt).format(LONGDATETIME12)
            }
        },
        {
            title: 'Stage',
            key: 'stage',
            dataIndex: 'stage',
        },
        {
            title: 'Document Title',
            key: 'name',
            dataIndex: 'title',
        },
        {
            title: 'Folder',
            key: 'folder',
            dataIndex: 'folder'
        },
        {
            title: 'Available To Client',
            className: 'text-center',
            key: 'availabletoC',
            render: (text, record) => !!record.private ? '' : <CheckOutlined/>
        },
        {
            title: '',
            key: 'created',
            render: (text, record) => <DocumentActions key={record.id} document={record}/>
        },
    ];
    const folders = {}
    masterSource.forEach(it => {
        if (!folders.hasOwnProperty(it.folder)) {
            folders[it.folder] = []
        }
        folders[it.folder].push(it)
    })

    let items = Object.keys(folders).map(folder => {
        const label = capitaliseWords(folder.split('-').join(' '))

        return {
            key: folder,
            label,
            children: <Table key={`Table${folder}`} pagination={false} dataSource={folders[folder]} columns={columns}/>
        }
    })

    items.push ({
        key: 'upload',
        label: 'Upload',
        icon: <UploadOutlined/>,
        children: <DocumentUploader stage={stage} key={stage}/>
    })





    function StageSelection(){
        return <Select value={stage} onChange={setStage}>
            <Select.Option value={ApplicationStages.APPLY}>Apply Documents</Select.Option>
            <Select.Option value={ApplicationStages.PREAPPROVAL}>Pre-Approval Documents</Select.Option>
            <Select.Option value={ApplicationStages.APPROVAL}>Approval Documents</Select.Option>
        </Select>
    }



    let lenderCode = mortgage?.activeSubmission?.lenderCode
    if (!lenderCode) {
        lenderCode = mortgage.requirement.lenderCode
    }

    return (
        <>
            <PageHeader  title={`${stage} Documents (${lenderCode ? lenderCode: ''})`} back="./../../" className="p-15"/>
            <Tabs defaultActiveKey="1" items={items}/>
            {/*{!!stage && (*/}
            {/*    <LenderSubmissionDocumentTable stage={stage}/>*/}
            {/*)}*/}

        </>

    );
}

export default MortgageDocuments;