import React from 'react';
import PropTypes from 'prop-types';
import {Button, Collapse, Descriptions, Form, Input} from "antd";
import PageHeader from "../../PageHeader";
import useMortgage from "../../../providers/mortgage/useMortgage";
import {capitaliseWords, joinWithBr} from "../../../../assets/scripts/string-functions";
import QueryItemForm from "./QueryItemForm";
import useMortgageUploadService from "../../../providers/mortgage/useMortgageUploadService";

import {LONGDATE} from "../../../../assets/scripts/constants";
import dayjs from "dayjs";

Queries.propTypes = {};

function Queries(props) {
    const mortgage = useMortgage()

    const getLabel = (item) => {

        let d = item.date ? dayjs(item.date).format( LONGDATE) : ''

        return (
            <div className="d-row gap-15">
                <div style={{width: 100}}>{d}</div>
                <div>{item.description}</div>
            </div>
        )
    }
    const getChildren = (item) => {
        const handleDelete = async () => {
            await mortgage.mutate({
                delete: {
                    target: `queries`,
                    id: item.id
                }
            })
        }
        return (
            <>
                <QueryItemForm handleDelete={handleDelete} item={item}/>
            </>

        )
    }
    let items = []

    if (mortgage) {
        const sections = {}
        mortgage.queries.filter(it=>it.stage === props.stage).forEach(it => {
            if (!sections.hasOwnProperty(it.title)) {
                sections[it.title] = []
            }
            sections[it.title].push(it)
        })
        items = Object.keys(sections).map(key => {
            const innerItems = sections[key].map(item => {
                return {
                    key: `query-${item.id}`,
                    label: getLabel(item),
                    children: getChildren(item),
                }
            })
            return {
                key: `group-${key}`,
                label: (
                    <div className="d-row gap-15">
                        <strong>{key}</strong>
                    </div>
                ),
                children: <Collapse accordion className="radius-0" items={innerItems}/>,
            }
        })
    }

    return (

        <>
            <PageHeader title={capitaliseWords(props.stage.toLowerCase()) + " Queries"} className="p-15" back="./../"/>

            <Collapse className="radius-0" items={items} defaultActiveKey={['1']}/>
        </>
    );
}

export default Queries;