import React, {useEffect, useRef, useState} from 'react';
import {magenta, green, purple, blue, orange, grey} from "@ant-design/colors";

function SlidingDivider(props) {
    const [col1width, setCol1width] = useState(600);
    const separatorRef = useRef(null)
    let isDragging = false;

    const handleMouseMove = (event) => {
        if (isDragging) {
            const separatorX = separatorRef.current.getBoundingClientRect().left;
            const mouseX = event.pageX;
            setCol1width(p => {
                return p + (mouseX - separatorX)
            })
        }
    };

    const handleMouseDown = () => {
        isDragging = true;
    };

    const handleMouseUp = () => {
        isDragging = false;
    };

    useEffect(() => {
        if (separatorRef.current) {
            separatorRef.current.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('mousemove', handleMouseMove);

        }
        return () => {
            if (separatorRef.current) {
                separatorRef.current.removeEventListener('mousedown', handleMouseDown);
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, [separatorRef]);
    return (
        <div className="d-row p-relative" >
            <div className="shrink-not" style={{width: col1width + 'px', userSelect: 'none'}}>
                <div className="p-sticky">
                    {props.left}
                </div>

            </div>
            <div className="grow p-relative">
                <div style={{userSelect: 'none'}} className="ms-9">
                    {props.right}
                </div>
            </div>

            <div
                ref={separatorRef}
                className="p-absolute separator"
                style={{
                    top: 0,
                    bottom: 0,
                    width: 9,
                    left: col1width + 'px',
                    backgroundColor: grey[0],
                    cursor: 'col-resize'
                }}/>
        </div>
    )
}
export default SlidingDivider;