import {AssetTypes, DebtTypes, EmploymentTypes, MortgageTypes} from "../../../models";

export const setupAffordabilityErrors = (mortgage) => {
    let table = {
        app0Errors: {
            "Mortgage Setup": [],
        },
        app1Errors: {
            "Personal Details" : [],
            "Income & Employment": [],
            "Financial Commitments"     : [],
            "Savings"         : [],
        }
    }
    if (mortgage.twoApplicants) {
        table.app2Errors = {
            "Personal Details"     : [],
            "Income & Employment"  : [],
            "Financial Commitments": [],
            "Savings"              : [],
        }
    }
    let app1Possessive = mortgage.applicant1.firstName + "'s"
    let app2Possessive = mortgage.applicant2.firstName + "'s"
    if (!mortgage.applicant2.firstName){
        app2Possessive = `The other applicant's`
    }
    let testApp = (app, section, valueLabel, value) => {
        if ([
            null,
            undefined
        ].includes(value))
        {
            let owner = app === 1 ? app1Possessive : app === 2 ? app2Possessive : ''
            table[`app${app}Errors`][section].push((`${owner} ${valueLabel}`).trim())
        }
    }
    // Tests
    function applicantPersonalDetailsTest(app) {
        let key = 'Personal Details'
        let applicant = mortgage[`applicant${app}`]
        testApp(app, key, 'First Name', applicant.firstName)
        testApp(app, key, 'Surname', applicant.surname)
        testApp(app, key, 'Email Address', applicant.email)
        testApp(app, key, 'Phone Number', applicant.mobile)
        testApp(app, key, 'Date of Birth', applicant.personal.dateOfBirth)
        testApp(app, key, 'Marital Status', applicant.personal.maritalStatus)
        testApp(app, key, 'Number of Dependents', applicant.personal.countDependents)
    }
    function applicantEmploymentIncomeTest(app) {
        let key = 'Income & Employment'
        let applicant = mortgage[`applicant${app}`]
        applicant.employmentIncome.forEach((job, index) => {
            testApp(app, key, 'Employment Type', job.employmentType)
            if (job.employmentType === EmploymentTypes.EMPLOYED) {
                testApp(app, key, 'Employment Basis', job.employmentBasis)
                testApp(app, key, 'Employment Sector', job.publicSector)
            }
            testApp(app, key, 'Gross Basic Pay', job.grossBasic)
        })
    }
    function applicantCommitmentsTest(app) {
        let key = 'Financial Commitments'
        let applicant = mortgage[`applicant${app}`]
        testApp(app, key, 'Loan Commitments', applicant.financial.hasLoans)
        if (applicant.financial.hasLoans) {
            applicant.debts.filter(debt=>debt.debtType === DebtTypes.LOAN).forEach((debt, index) => {
                testApp(app, key, 'Loan Provider', debt.lender)
                if (debt.lender){
                    testApp(app, key, `${debt.lender} Monthly Payment`, debt.monthlyPayment)
                }
            })
        }
    }
    function applicantSavingsTest(app) {
        let key = 'Savings'
        let applicant = mortgage[`applicant${app}`]

        testApp(app, key, 'Savings Details', applicant.financial.hasSavings)
        if (applicant.financial.hasSavings) {
            applicant.assets.filter(asset => asset.assetType === AssetTypes.SAVING).forEach((asset, index) => {
                testApp(app, key, 'Savings Institution', asset.institution)
                if (asset.institution){
                    testApp(app, key, `${asset.institution} Monthly Deposits`, asset.increases)
                }
            })
        }
        if (applicant.hasSavings === false){
            testApp(app, key, 'Savings Rate', applicant.financial.hasCurrentAccountSavings)
            if (applicant.financial.hasCurrentAccountSavings){
                testApp(app, key, 'Savings Rate', applicant.financial.monthlySavings)
            }
        }
    }
    function mortgageSetupTest() {
        let key = 'Mortgage Setup'
        testApp(0, key, 'Mortgage Type', mortgage.mortgageType)
        if (mortgage.mortgageType === MortgageTypes.PURCHASE){
            testApp(0, key, 'Application Type', mortgage.applicationType)
            testApp(0, key, 'Property Value', mortgage.properties[0].propertyValue)
            testApp(0, key, 'Loan Required', mortgage.requirement.loanRequired)
            testApp(0, key, 'Mortgage Term', mortgage.requirement.loanTerm)
            testApp(0, key, 'Deposit Amount', mortgage.requirement.depositSavings)
            testApp(0, key, 'Number of Applicants', mortgage.twoApplicants)
            testApp(0, key, 'Gifts Expected', mortgage.requirement.receivingGifts)
            if (mortgage.requirement.receivingGifts){
                testApp(0, key, 'Gift Amount', mortgage.requirement.depositGifts)
            }
        }
        testApp(0, key, 'Mortgage Term', mortgage.mortgageTerm)
        testApp(0, key, 'Mortgage Amount', mortgage.mortgageAmount)
        testApp(0, key, 'Property Value', mortgage.propertyValue)
        testApp(0, key, 'Deposit Amount', mortgage.depositAmount)
        testApp(0, key, 'Deposit Source', mortgage.depositSource)
        testApp(0, key, 'Interest Rate', mortgage.interestRate)
        testApp(0, key, 'Repayment Type', mortgage.repaymentType)
        testApp(0, key, 'Repayment Frequency', mortgage.repaymentFrequency)
        testApp(0, key, 'Mortgage Term', mortgage.mortgageTerm)
    }
    applicantPersonalDetailsTest(1)
    applicantEmploymentIncomeTest(1)
    applicantCommitmentsTest(1)
    applicantSavingsTest(1)
    if (mortgage.twoApplicants) {
        applicantPersonalDetailsTest(2)
        applicantEmploymentIncomeTest(2)
        applicantCommitmentsTest(2)
        applicantSavingsTest(2)
    }
    return table
}