import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, InputNumber, Popconfirm, Radio, Switch} from "antd";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {OverlayItemTypes} from "../../../models";

PdfBubble.propTypes = {};

function PdfBubble(props) {
   const SVG = (

       <svg width="19.81" height="18.195" version="1.1" viewBox="0 0 19.81 18.195" xmlns="http://www.w3.org/2000/svg">
           <path transform="translate(1.404 1.405)" d="M0,9l4.873,6.181L17,0" fill="none" stroke="#0b50b0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
       </svg>


   )
    const [form] = Form.useForm()
    const getInitialState = ()=>{
        const obj = {
            type: props.id ? props.type : OverlayItemTypes.TEXT,
            x: props.x,
            y: props.y,
            style: {size: 9},
            open: false,
            saving: false
        }
        if (props.id){
            if (props.style.maxWidth){
                obj.style.width = props.style.maxWidth
            }
            if (props.style.size){
                obj.style.size = props.style.size
            }
            if (props.style.textAlign === 'right'){
                obj.right = true
            }
        }
        return obj
    }
    const [state, setState] = useState(getInitialState())
    const initialValues = {
        x: props.x,
        y: props.y,
        size: props.id && props.style.size ? props.style.size : 9,
        width: props.id && props.style.maxWidth ? props.style.maxWidth : null,
        name: props.id ? props.name : '',
        type: props.id ? props.type : OverlayItemTypes.TEXT,
        right: props.id && props.style.textAlign === 'right' ? true : false
    }
    const onValuesChange = (item, map) => {
        if (item?.type) {
            setState(s => ({...s, type: item.type}))
        }
        if (item?.x) {
            setState(s => ({...s, x: item.x}))
        }
        if (item?.y) {
            setState(s => ({...s, y: item.y}))
        }
        if (item?.name) {
            const better = item.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')
            setState(s => ({...s, name: better}))
            form.setFieldValue('name', better)
        }
        if (item?.width) {
            setState(s => ({
                ...s,
                style: {
                    ...s.style,
                    width: item.width
                }
            }))
        }
        if (item?.right){
            setState(s => ({
                ...s,
                style: {
                    ...s.style,
                    textAlign: 'right',
                }
            }))
        }
        if (item?.size) {
            setState(s => ({
                ...s,
                style: {
                    ...s.style,
                    width: item.size
                }
            }))
        }
        return true
    }
    function Description() {
        return <Form
            form={form}
            key={`bubble-props`}
            initialValues={initialValues}
            onValuesChange={onValuesChange}
            className="d-col gap-6"
        >
            <div className="d-row gap-15 j-between">
                <Form.Item
                    rules={[{required: true, message:'required'}]}
                    label="x"
                    name="x"
                    className="mb-0"
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    label="size"
                    name="size"
                    className="mb-0"
                >
                    <InputNumber/>
                </Form.Item>


            </div>
            <div className="d-row gap-15 j-between">
                <Form.Item
                    rules={[{required: true, message:'required'}]}
                    label="y"
                    name="y"
                    className="mb-0"
                >
                    <InputNumber/>
                </Form.Item>
                <Form.Item
                    label="width"
                    name="width"
                    className="mb-0"
                >
                    <InputNumber/>
                </Form.Item>

            </div>

            <Form.Item
                rules={[{required: true, message:'required'}]}
                label="name"
                name="name"
                className="mb-0"
            >
                <Input style={{width: '100%'}}/>
            </Form.Item>
            <div className="d-row a-center">
                <Form.Item
                    rules={[{required: true, message:'required'}]}
                    label="type"
                    name="type"
                    className="mb-0"
                >
                    <Radio.Group options={[{label: 'check', value:OverlayItemTypes.CHECK},{label: 'text', value:OverlayItemTypes.TEXT}]}/>
                </Form.Item>
                {!!props.id && (
                    <Popconfirm title="Sure?" okText="Delete" onConfirm={()=>{props.remove({id: props.id, _version: props._version})}}>
                        <Button size="small" className="ms-auto"><DeleteOutlined/></Button>
                    </Popconfirm>
                )}

            </div>
            <Form.Item name="right" label="Align Right" valuePropName="checked">
                <Switch />
            </Form.Item>

        </Form>
    }
    const onConfirm = async ()=>{
        try{
            const valid = await form.validateFields()

            setState(s=>({...s, saving: true}))
            if (props?.uid){
                await props.create({...valid, uid: props.uid})
            }
            if (props?.id){
               await props.update({...valid, id: props.id, _version: props._version})
            }
            setState(s=>({...s, saving: false, open: false}))
        }
        catch (e) {
            //console.log(e)
        }
    }
    const confirmMemo = useMemo(()=>{
        const onCancel = ()=>{
            setState(s=>({...s,open:false}))
            setTimeout(()=>{
                if (props?.uid){
                    props.remove({uid: props.uid})
                }
            },500)

        }
        return  (<Popconfirm
            okText={<>Save {state.saving && <LoadingOutlined className="ms-6"/>}</>}
            onCancel={onCancel}
            onConfirm={onConfirm}
            placement="bottom"
            open={state.open}
            icon={false}
            title={false}
            description={<Description/>}
        >

        </Popconfirm>)
    },[state.open])
    return (
        <>

        <div style={{position: "absolute", bottom: state.y, left: state.x}}>
            <div onClick={()=>setState(s=>({...s,open:true}))} style={{...state.style, backgroundColor: "rgba(160,196,218,0.49)", padding: 2, color: '#0b50b0'}}>
                {state.type === OverlayItemTypes.TEXT && "T"}
                {state.type === OverlayItemTypes.CHECK && SVG}
            </div>
        </div>
        <div style={{position: "absolute", bottom: props.y, left: props.x}}>
            {confirmMemo}
        </div>
        </>
    );
}

export default PdfBubble;