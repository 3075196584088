import {performGQL} from "../functions";
import * as uuid from "uuid";
const createUserMortgage = /* GraphQL */ `
    mutation CreateUserMortgage(
        $input: CreateUserMortgageInput!
        $condition: ModelUserMortgageConditionInput
    ) {
        createUserMortgage(input: $input, condition: $condition) {
            id
            twoApplicants
            mortgageType
            applicant1ID
            applicant2ID
            applicationStage
            applicationStatus
            lastSeen
            advisorID
            userMortgageRequirementId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
    }
`;
const createUserMortgagePropertyOnMortgage = /* GraphQL */ `
    mutation CreateUserMortgagePropertyOnMortgage(
        $input: CreateUserMortgagePropertyOnMortgageInput!
        $condition: ModelUserMortgagePropertyOnMortgageConditionInput
    ) {
        createUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            updatedAt
        }
    }
`;
const createUserMortgageSolicitor = /* GraphQL */ `
    mutation CreateUserMortgageSolicitor(
        $input: CreateUserMortgageSolicitorInput!
        $condition: ModelUserMortgageSolicitorConditionInput
    ) {
        createUserMortgageSolicitor(input: $input, condition: $condition) {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            updatedAt
        }
    }
`;
const createUserMortgageNeeds = /* GraphQL */ `
    mutation CreateUserMortgageNeeds(
        $input: CreateUserMortgageNeedsInput!
        $condition: ModelUserMortgageNeedsConditionInput
    ) {
        createUserMortgageNeeds(input: $input, condition: $condition) {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            updatedAt
        }
    }
`;
const createUserMortgageRequirement = /* GraphQL */ `
    mutation CreateUserMortgageRequirement(
        $input: CreateUserMortgageRequirementInput!
        $condition: ModelUserMortgageRequirementConditionInput
    ) {
        createUserMortgageRequirement(input: $input, condition: $condition) {
            id
            loanRequired
            lenderCode
            mortgageAccountNo
            drawdownDate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`;
const createAdminMortgageSettings = /* GraphQL */ `
    mutation CreateAdminMortgageSettings(
        $input: CreateAdminMortgageSettingsInput!
        $condition: ModelAdminMortgageSettingsConditionInput
    ) {
        createAdminMortgageSettings(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicant = /* GraphQL */ `
    mutation CreateUserApplicant(
        $input: CreateUserApplicantInput!
        $condition: ModelUserApplicantConditionInput
    ) {
        createUserApplicant(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicantTax = /* GraphQL */ `
    mutation CreateUserApplicantTax(
        $input: CreateUserApplicantTaxInput!
        $condition: ModelUserApplicantTaxConditionInput
    ) {
        createUserApplicantTax(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicantPersonal = /* GraphQL */ `
    mutation CreateUserApplicantPersonal(
        $input: CreateUserApplicantPersonalInput!
        $condition: ModelUserApplicantPersonalConditionInput
    ) {
        createUserApplicantPersonal(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
            owner
        }
    }
`;
const createUserApplicantIncome = /* GraphQL */ `
    mutation CreateUserApplicantIncome(
        $input: CreateUserApplicantIncomeInput!
        $condition: ModelUserApplicantIncomeConditionInput
    ) {
        createUserApplicantIncome(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicantFinancial = /* GraphQL */ `
    mutation CreateUserApplicantFinancial(
        $input: CreateUserApplicantFinancialInput!
        $condition: ModelUserApplicantFinancialConditionInput
    ) {
        createUserApplicantFinancial(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicantEmployment = /* GraphQL */ `
    mutation CreateUserApplicantEmployment(
        $input: CreateUserApplicantEmploymentInput!
        $condition: ModelUserApplicantEmploymentConditionInput
    ) {
        createUserApplicantEmployment(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _deleted
            _lastChangedAt
            _version
        }
    }
`;
const createUserApplicantCurrentAccount = /* GraphQL */ `
    mutation CreateUserApplicantCurrentAccount(
        $input: CreateUserApplicantCurrentAccountInput!
        $condition: ModelUserApplicantCurrentAccountConditionInput
    ) {
        createUserApplicantCurrentAccount(input: $input, condition: $condition) {
            id
            applicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
const createUserApplicantEmploymentIncome = /* GraphQL */ `
    mutation CreateUserApplicantEmploymentIncome(
        $input: CreateUserApplicantEmploymentIncomeInput!
        $condition: ModelUserApplicantEmploymentIncomeConditionInput
    ) {
        createUserApplicantEmploymentIncome(input: $input, condition: $condition) {
            id
            applicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
const createUserApplicantLastHere = /* GraphQL */ `
    mutation CreateUserApplicantLastHere(
        $input: CreateUserApplicantLastHereInput!
        $condition: ModelUserApplicantLastHereConditionInput
    ) {
        createUserApplicantLastHere(input: $input, condition: $condition) {
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
const adjectives = [
    "Bold",
    "Wise",
    "Kind",
    "Warm",
    "Cool",
    "Deep",
    "Fair",
    "Fine",
    "Rich",
    "Loud",
    "Soft",
    "Brave",
    "Neat",
    "Calm",
    "Clear",
    "Dark",
    "Light",
    "Sharp",
    "Sweet",
    "Tight",
    "Clean",
    "Fresh",
    "Green",
    "Quiet",
    "Rough",
    "Shy",
    "Slow",
    "Slim",
    "Thin",
    "Tough",
    "Wide",
    "Hard",
    "Cold",
    "Dull",
    "Lowly",
    "Deadly",
    "Sound",
    "Brisk",
    "Round",
    "Sunny",
    "White",
    "Black",
    "Brown",
    "Orange",
    "Purple",
    "Yellow",
    "Silver",
    "Golden",
    "Copper",
    "Bronze",
    "Steel",
    "Iron",
    "Upset",
    "Angry",
    "Happy",
    "Silly",
    "Funny",
    "Crazy",
    "Lucky",
    "Jolly",
    "Merry",
    "Weird",
    "Tired",
    "Sleepy",
    "Drowsy",
    "Dozy",
    "Dizzy",
    "Giddy",
    "Queer",
    "Sore",
    "Sick",
    "Ill",
    "Weak",
    "Poor",
    "Wealthy",
    "Broke",
    "Bare",
    "Empty",
    "Full",
    "Heavy",
    "Bright",
    "Cloudy",
    "Foggy",
    "Misty",
    "Damp",
    "Wet",
    "Dry",
    "Hot",
    "Free",
    "Busy",
    "Idle",
    "Lazy",
    "Active",
    "Grey",
    "Red",
    "Blue",
    "Pink",
    "Gold",
    "Gray",
    "Vast",
    "Long"
]
const nouns = [
    "Cat",
    "Dog",
    "Car",
    "Bus",
    "Book",
    "Tree",
    "Fish",
    "Bird",
    "Bear",
    "Moon",
    "Sun",
    "Desk",
    "Door",
    "Room",
    "Ball",
    "Girl",
    "Boy",
    "Star",
    "Duck",
    "Toad",
    "Lamp",
    "Shoe",
    "Bike",
    "Bell",
    "Rose",
    "Leaf",
    "Frog",
    "Hand",
    "Baby",
    "Cake",
    "Apple",
    "Wolf",
    "Glove",
    "Pear",
    "Gold",
    "Chair",
    "Train",
    "Heart",
    "Snake",
    "Mouse",
    "Fruit",
    "Knife",
    "Fork",
    "Milk",
    "Rain",
    "Snow",
    "Palm",
    "Pen",
    "Boat",
    "Ship",
    "Ring",
    "Hook",
    "Bath",
    "Country",
    "Family",
    "Father",
    "Mother",
    "Brother",
    "Sister",
    "Friend",
    "School",
    "House",
    "Table",
    "Paper",
    "Trade",
    "Study",
    "Club",
    "Farm",
    "City",
    "Army",
    "Band",
    "Song",
    "Wind",
    "Gate",
    "Price",
    "Union",
    "Value",
    "Voice",
    "Stage",
    "Light",
    "Board",
    "Month",
    "Music",
    "field",
    "award",
    "issue",
    "basis",
    "front",
    "Force",
    "Model",
    "Space",
    "Hotel",
    "Floor",
    "Style",
    "Event",
    "Doubt",
    "Blood",
    "Sound",
    "Title",
    "Glass",
    "Earth",
    "river",
    "Whole",
    "Piece",
    "Mouth",
    "Radio",
    "Start",
    "Truth",
    "Stone",
    "Queen",
    "Stock",
    "Horse",
    "Plant",
    "Scale",
    "Image"
];
function generateFriendlyName() {
    const capitaliseFirst = (string) => {
        if (string.length === 0) return string;
        if (string) {
            return string.toString().charAt(0).toUpperCase() + string.slice(1);
        }
        return ''
    }

    // Randomly select an adjective and a noun
    const adjective = capitaliseFirst(adjectives[Math.floor(Math.random() * adjectives.length)]);
    const noun = capitaliseFirst(nouns[Math.floor(Math.random() * nouns.length)]);

    // Concatenate them with a dash
    return `${adjective}${noun}`;
}

export const createSecondMortgage = async (origMortgage) => {
    const handleCreateSecondMortgageRows = async ()=>{
        const
            userMortgageId = uuid.v4(),
            userMortgageRequirementId = uuid.v4(),
            userMortgagePropertyId = uuid.v4(),
            userMortgageExistingId = uuid.v4(),
            userMortgageNeedsId = uuid.v4(),
            userMortgageSettingsId = uuid.v4(),
            userMortgageSolicitorId = uuid.v4()
        // SET PERMISSION GROUPS
        const app1group = userMortgageId + `-app1`
        const app2group = userMortgageId + `-app2`

        const friendlyName = generateFriendlyName()
        // RUN MUTATIONS
        const promises = [
            performGQL( {
                input: {
                    id: userMortgageId,
                    applicant1ID: origMortgage.applicant1.id,
                    applicant2ID: origMortgage.applicant2.id,
                    applicationStage: 'SETUP',
                    applicationStatus: 'ACTIVE',
                    userMortgageRequirementId,
                    userMortgagePropertyId,
                    userMortgageExistingId,
                    userMortgageNeedsId,
                    userMortgageSettingsId,
                    userMortgageSolicitorId,
                    friendlyName,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgage),
            performGQL({
                input: {
                    id: userMortgageNeedsId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgageNeeds),
            performGQL( {
                input: {
                    id: userMortgageRequirementId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserMortgageRequirement),
            performGQL({
                input: {
                    id: userMortgageSettingsId,
                    mortgageID: userMortgageId
                }
            }, createAdminMortgageSettings),
            performGQL({
                input: {
                    id: userMortgageSolicitorId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgageSolicitor),
            performGQL({
                input: {
                    id: userMortgagePropertyId,
                    mortgageID: userMortgageId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserMortgagePropertyOnMortgage),
        ]
        const result = await Promise.all(promises);
        return result[0].id
    }
    return await handleCreateSecondMortgageRows()
}

export default async function createOfflineMortgage() {


    const handleCreateInitialRows = async ()=>{
        // SET IDs
        const
            userMortgageId = uuid.v4(),
            userMortgageRequirementId = uuid.v4(),
            userMortgagePropertyId = uuid.v4(),
            userMortgageExistingId = uuid.v4(),
            userMortgageNeedsId = uuid.v4(),
            userMortgageSettingsId = uuid.v4(),
            userMortgageSolicitorId = uuid.v4(),

            userApplicant1Id = uuid.v4(),
            userApplicant1PersonalId = uuid.v4(),
            userApplicant1IncomeId = uuid.v4(),
            userApplicant1FinancialId = uuid.v4(),
            userApplicant1EmploymentId = uuid.v4(),
            userApplicant1TaxId = uuid.v4(),
            userApplicant1LastHereId = uuid.v4(),
            userApplicant1CurrentAccountId = uuid.v4(),
            userApplicant1EmploymentIncomeId = uuid.v4(),

            userApplicant2Id = uuid.v4(),
            userApplicant2PersonalId = uuid.v4(),
            userApplicant2IncomeId = uuid.v4(),
            userApplicant2FinancialId = uuid.v4(),
            userApplicant2EmploymentId = uuid.v4(),
            userApplicant2TaxId = uuid.v4(),
            userApplicant2LastHereId = uuid.v4(),
            userApplicant2CurrentAccountId = uuid.v4(),
            userApplicant2EmploymentIncomeId = uuid.v4()

        // SET PERMISSION GROUPS
        const app1group = userMortgageId + `-app1`
        const app2group = userMortgageId + `-app2`

        const friendlyName = generateFriendlyName()
        // RUN MUTATIONS
        const promises = [
            performGQL( {
                input: {
                    id: userMortgageId,
                    applicant1ID: userApplicant1Id,
                    applicant2ID: userApplicant2Id,
                    applicationStage: 'SETUP',
                    applicationStatus: 'OFFLINE',
                    userMortgageRequirementId,
                    userMortgagePropertyId,
                    userMortgageExistingId,
                    userMortgageNeedsId,
                    userMortgageSettingsId,
                    userMortgageSolicitorId,
                    friendlyName,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgage),
            performGQL({
                input: {
                    id: userMortgageNeedsId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgageNeeds),
            performGQL( {
                input: {
                    id: userMortgageRequirementId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserMortgageRequirement),
            performGQL({
                input: {
                    id: userMortgageSettingsId,
                    mortgageID: userMortgageId
                }
            }, createAdminMortgageSettings),
            performGQL({
                input: {
                    id: userMortgageSolicitorId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserMortgageSolicitor),
            performGQL({
                input: {
                    id: userMortgagePropertyId,
                    mortgageID: userMortgageId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserMortgagePropertyOnMortgage),

            performGQL( {
                input: {
                    id: userApplicant1Id,
                    provider: null,
                    userApplicantPersonalId: userApplicant1PersonalId,
                    userApplicantIncomeId: userApplicant1IncomeId,
                    userApplicantFinancialId: userApplicant1FinancialId,
                    userApplicantEmploymentId: userApplicant1EmploymentId,
                    userApplicantTaxId: userApplicant1TaxId,
                    userApplicantLastHereId: userApplicant1LastHereId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicant),
            performGQL( {
                input: {
                    id: userApplicant1CurrentAccountId,
                    applicantID: userApplicant1Id,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserApplicantCurrentAccount),
            performGQL({
                input: {
                    id: userApplicant1EmploymentIncomeId,
                    applicantID: userApplicant1Id,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicantEmploymentIncome),
            performGQL( {
                input: {
                    id: userApplicant1TaxId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserApplicantTax),
            performGQL({
                input: {
                    id: userApplicant1PersonalId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicantPersonal),
            performGQL( {
                input: {
                    id: userApplicant1IncomeId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicantIncome),
            performGQL( {
                input: {
                    id: userApplicant1FinancialId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicantFinancial),
            performGQL( {
                input: {
                    id: userApplicant1EmploymentId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            },createUserApplicantEmployment),
            performGQL({
                input: {
                    id: userApplicant1LastHereId,
                    readAccessGroup: [app2group],
                    editAccessGroup: [app1group]
                }
            }, createUserApplicantLastHere),

            performGQL( {
                input: {
                    id: userApplicant2Id,
                    userApplicantPersonalId: userApplicant2PersonalId,
                    userApplicantIncomeId: userApplicant2IncomeId,
                    userApplicantFinancialId: userApplicant2FinancialId,
                    userApplicantEmploymentId: userApplicant2EmploymentId,
                    userApplicantTaxId: userApplicant2TaxId,
                    userApplicantLastHereId: userApplicant2LastHereId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            },createUserApplicant),
            performGQL( {
                input: {
                    id: userApplicant2CurrentAccountId,
                    applicantID: userApplicant2Id,
                    readAccessGroup: [],
                    editAccessGroup: [app1group,app2group]
                }
            }, createUserApplicantCurrentAccount),
            performGQL({
                input: {
                    id: userApplicant2EmploymentIncomeId,
                    applicantID: userApplicant2Id,
                    readAccessGroup: [],
                    editAccessGroup: [app1group,app2group]
                }
            },createUserApplicantEmploymentIncome),
            performGQL( {
                input: {
                    id: userApplicant2TaxId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            }, createUserApplicantTax),
            performGQL( {
                input: {
                    id: userApplicant2PersonalId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            }, createUserApplicantPersonal),
            performGQL( {
                input: {
                    id: userApplicant2IncomeId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            },createUserApplicantIncome),
            performGQL( {
                input: {
                    id: userApplicant2FinancialId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            },createUserApplicantFinancial),
            performGQL( {
                input: {
                    id: userApplicant2EmploymentId,
                    readAccessGroup: [],
                    editAccessGroup: [app1group, app2group]
                }
            },createUserApplicantEmployment),
            performGQL({
                input: {
                    id: userApplicant2LastHereId,
                    readAccessGroup: [app1group],
                    editAccessGroup: [app2group]
                }
            }, createUserApplicantLastHere),
        ]
        const result = await Promise.all(promises);
        return result[0].id
    }
    return await handleCreateInitialRows()
}