import {differenceInYears, format} from "date-fns";
import {joinWithConjunction, capitaliseFirst} from "../../../../assets/scripts/string-functions";
import {
    EmploymentBases,
    Frequencies,
    EmploymentTypes,
    MortgageTypes,
    PersonalAccommodationSituations, ApplicationTypes, AssetTypes
} from "../../../../models";
import countries from "../../../../assets/scripts/countries.json"
import {accountTitle} from "../../../../app/components/client/application/forms/sections/ApplicantAccounts";
import {assetTitle} from "../../../../app/components/client/application/forms/sections/ApplicantAssets";

const monthlyAmountFromFreq = (freq, amt) => {
    let monthly = null
    switch (freq) {
        case "YEARLY" :
            monthly = amt / 12;
            break;
        case "STATIC" :
            monthly = 0;
            break;
        case "QUARTERLY" :
            monthly = amt / 3;
            break;
        case "MONTHLY" :
            monthly = amt;
            break;
        case "FORTNIGHTLY" :
            monthly = (amt / 2) * 4.33;
            break;
        case "WEEKLY" :
            monthly = amt * 4.33;
            break;
        case "OCCASIONALLY" :
            monthly = amt / 12;
            break;
    }
    return monthly
}
const Background = ({mortgage}) => {
    const items = []

    // Names and ages
    let today = new Date()
    let string = `${mortgage.applicant1.firstName} ${mortgage.applicant1.surname} aged ${differenceInYears(today, new Date(mortgage.applicant1.personal.dateOfBirth))}`
    if (mortgage.twoApplicants) {
        string += ` and ${mortgage.applicant2.firstName} ${mortgage.applicant2.surname} aged ${differenceInYears(today, new Date(mortgage.applicant2.personal.dateOfBirth))}`
    }
    items.push(string)

    // Dependents
    let ages = []
    if (mortgage.applicant1.personal.countDependents > 0) {
        let ages1 = mortgage.applicant1.personal.dependentAges
        if (!ages1 || !ages1.length) {
            ages1 = mortgage.applicant1.personal.dependentDOBs.map(dob => differenceInYears(today, new Date(dob)))
        }
        ages = [...ages, ...ages1]
    }
    if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
        let ages2 = mortgage.applicant2.personal.dependentAges
        if (!ages2.length) {
            ages2 = mortgage.applicant2.personal.dependentDOBs.map(dob => differenceInYears(today, new Date(dob)))
        }
        ages = [...ages, ...ages2]
    }
    string = 'No Dependents'
    if (ages.length) {
        ages.sort((a, b) => a - b)
        string = `${ages.length} Dependent${ages.length > 1 ? 's' : ''}, aged ${joinWithConjunction(ages, 'and')}`
    }
    items.push(string)

    // Employment
    string = ''

    if (mortgage.twoApplicants && mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
        string = 'Both PAYE employees. '
    }
    if (mortgage.twoApplicants && mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
        string = 'Both Self-employed. '
    }
    switch (mortgage.applicant1.employment.employmentType) {
        case EmploymentTypes.EMPLOYED :
            string += `${mortgage.applicant1.firstName} is a ${mortgage.applicant1.employment.positionHeld} `
            break
        case EmploymentTypes.SELFEMPLOYED :
            string += `${mortgage.applicant1.firstName} is self employed in ${mortgage.applicant1.employment.natureOfBusiness} `
            break
        case EmploymentTypes.STUDENT :
            string += `${mortgage.applicant1.firstName} is a Student `
            break
        case EmploymentTypes.HOMEMAKER :
            string += `${mortgage.applicant1.firstName} is a Homemaker `
            break
        default:
    }
    if (mortgage.twoApplicants) {
        switch (mortgage.applicant2.employment.employmentType) {
            case EmploymentTypes.EMPLOYED :
                string += `and ${mortgage.applicant2.firstName} is a ${mortgage.applicant2.employment.positionHeld} `
                break
            case EmploymentTypes.SELFEMPLOYED :
                string += `and ${mortgage.applicant2.firstName} is self employed in ${mortgage.applicant2.employment.natureOfBusiness} `
                break
            case EmploymentTypes.STUDENT :
                string += `and ${mortgage.applicant2.firstName} is a Student `
                break
            case EmploymentTypes.HOMEMAKER :
                string += `and ${mortgage.applicant2.firstName} is a Homemaker `
                break
            default:
        }
    }
    items.push(string)

    // Citizenship
    if (mortgage.applicant1.personal.nationality) {
        let land1 = countries.find(country => country.alpha_2_code === mortgage.applicant1.personal.nationality).en_short_name
        if (mortgage.twoApplicants && mortgage.applicant2.personal.nationality) {
            let land2 = countries.find(country => country.alpha_2_code === mortgage.applicant2.personal.nationality).en_short_name
            if (land1 === land2) {
                string = `Both citizens of ${land1}`
            } else {
                string = `${mortgage.applicant1.firstName} is a citizen of ${land1} and  ${mortgage.applicant2.firstName} of ${land2}`
            }
        } else {
            string = `${mortgage.applicant1.firstName} is a citizen of ${land1}`
        }
        items.push(string)
    }

    // Proposal
    if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
        string = `Refinancing `
    } else {
        switch (mortgage.applicationType) {
            case ApplicationTypes.LANDLORD:
                string = `BTL purchasing a `
                break
            case ApplicationTypes.FIRSTTIME:
                string = `FTB purchasing a `
                break
            case ApplicationTypes.MOVINGHOME:
                string = `Moving Home to a `
                break
        }
        if (mortgage.properties[0].addressKnown) {
            string += mortgage.properties[0].newlyBuilt ? `newly built property` : `second-hand property`
        } else {
            string += `property not yet identified`
        }
    }
    items.push(string)

    // Living Situation
    if (mortgage.twoApplicants) {
        let costs
        if (mortgage.requirement.cohabiting) {
            costs = mortgage.applicant1.financial.monthlyAccommodationCosts || 0

            switch (mortgage.applicant1.personal.accommodationSituation) {
                case PersonalAccommodationSituations.RENTED :
                    string = `Currently renting €${costs.toLocaleString('en-us')} per month`
                    break
                case PersonalAccommodationSituations.OWNED :
                    string = `Owns current home, paying mortgage of €0,000 per month`
                    break
                case PersonalAccommodationSituations.RELATIVES :
                    string = `Currently living with family`
                    if (mortgage.applicant1.financial.contributeToRelatives) {
                        string += ` contributing €${costs.toLocaleString('en-us')} per month`
                    }
                    break
                case PersonalAccommodationSituations.OTHER :
                    string = `${mortgage.applicant1.firstName}: ${mortgage.applicant1.personal.accommodationSituationOther}`
                    break
            }
        } else {
            costs = mortgage.applicant1.financial.monthlyAccommodationCosts || 0
            switch (mortgage.applicant1.personal.accommodationSituation) {
                case PersonalAccommodationSituations.RENTED :
                    string = `Currently ${mortgage.applicant1.firstName} is renting €${costs.toLocaleString('en-us')} per month`
                    break
                case PersonalAccommodationSituations.OWNED :
                    string += `. ${mortgage.applicant1.firstName} owns ${mortgage.applicant1.isMale ? 'his' : 'her'} home. Currently paying mortgage of €0,000 per month`
                    break
                case PersonalAccommodationSituations.RELATIVES :
                    string = `Currently ${mortgage.applicant1.firstName} is living with family`
                    if (mortgage.applicant1.financial.contributeToRelatives) {
                        string += ` contributing €${costs.toLocaleString('en-us')} per month`
                    }
                    break
                case PersonalAccommodationSituations.OTHER :
                    string = `${mortgage.applicant1.firstName}: ${mortgage.applicant1.personal.accommodationSituationOther}`
                    break
            }
            costs = mortgage.applicant2.personal.monthlyAccommodationCosts || 0
            switch (mortgage.applicant2.personal.accommodationSituation) {
                case PersonalAccommodationSituations.RENTED :
                    string += `. ${mortgage.applicant2.firstName} is renting €${costs.toLocaleString('en-us')} per month`
                    break
                case PersonalAccommodationSituations.OWNED :
                    string += `. ${mortgage.applicant2.firstName} owns ${mortgage.applicant2.isMale ? 'his' : 'her'} home. Currently paying mortgage of €0,000 per month`
                    break
                case PersonalAccommodationSituations.RELATIVES :
                    string += `. Currently ${mortgage.applicant2.firstName} is living with family`
                    if (mortgage.applicant2.financial.contributeToRelatives) {
                        string += ` contributing €${costs.toLocaleString('en-us')} per month`
                    }
                    break
                case PersonalAccommodationSituations.OTHER :
                    string += `. ${mortgage.applicant2.firstName}: ${mortgage.applicant2.personal.accommodationSituationOther}`
                    break
            }
        }
    } else {
        switch (mortgage.applicant1.personal.accommodationSituation) {
            case PersonalAccommodationSituations.RENTED :
                string = `Currently renting €${mortgage.applicant1.financial.monthlyAccommodationCosts.toLocaleString('en-us')} per month`
                break
            case PersonalAccommodationSituations.OWNED :
                string = `Owns ${mortgage.applicant2.isMale ? 'his' : 'her'} home. Currently paying mortgage of €0,000 per month`
                break
            case PersonalAccommodationSituations.RELATIVES :
                string = `Currently living with family`
                if (mortgage.applicant1.financial.contributeToRelatives) {
                    string += ` contributing €${mortgage.applicant1.personal.monthlyAccommodationCosts.toLocaleString('en-us')} per month`
                }
                break
            case PersonalAccommodationSituations.OTHER :
                string = mortgage.applicant1.personal.accommodationSituationOther
                break
        }
    }
    items.push(string)

    // Regular Savings
    let amt = 0
    let people = ['applicant1']
    if (mortgage.twoApplicants) {
        people.push('applicant2')
    }
    people.forEach(person => {
        mortgage[person].assets.forEach(item => {
            if (item.assetType === "SAVING") {
                if ([Frequencies.WEEKLY, Frequencies.MONTHLY, Frequencies.FORTNIGHTLY].includes(item.increasesFrequency)) {
                    amt += monthlyAmountFromFreq(item.increasesFrequency, item.increases)
                }
            }
        })
    })
    if (amt > 0) {
        items.push(`Regular Savings of €${amt.toLocaleString('en-us')} per month${people.length > 1 ? ', combined' : ''}`)
    }

    // Deposit
    let gift = mortgage.requirement.receivingGifts ? mortgage.requirement.depositGifts : false
    let own = mortgage.requirement.depositSavings > 0 ? mortgage.requirement.depositSavings : false
    let apos1 = mortgage.twoApplicants ? `` : `'`
    let apos2 = mortgage.twoApplicants ? `'` : ``
    if (gift && own) {
        items.push(`Deposit €${(own + gift).toLocaleString('en-us')} funded from Applicant${apos1}s${apos2} savings of €${own.toLocaleString('en-us')} and gift of €${gift.toLocaleString('en-us')}`)
    } else if (gift) {
        items.push(`Deposit entirely funded from gift of €${gift.toLocaleString('en-us')}`)
    } else if (own) {
        items.push(`Deposit entirely funded from Applicant${apos1}s${apos2} own savings of €${own.toLocaleString('en-us')}`)
    }

    return <>
        {items.map(item => <p key={item}>{item}</p>)}
    </>

}
const VariableIncome1 = ({mortgage}) => {
    return <></>
}
const VariableIncome2 = ({mortgage}) => {
    return <></>
}
const Income = ({mortgage}) => {
    const items1 = []
    const items2 = []

    if (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
        items1.push(`Self employed`)
    } else if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED) {
        let string = ``
        switch (mortgage.applicant1.employment.employmentBasis) {
            case EmploymentBases.FULLTIME:
                string += `Full time employment`
                break
            case EmploymentBases.PARTTIME:
                string += `Part time employment`
                break
            case EmploymentBases.TEMPFULLTIME:
                string += `Temporary full time employment`
                break
            case EmploymentBases.TEMPPARTTIME:
                string += `Temporary part time employment`
                break
            case EmploymentBases.CONTRACT:
                string += `Contracted`
                break
            default:
                string += `Employed`
                break
        }
        let startDate = new Date(mortgage.applicant1.employment.currentEmployerStartDate)
        string += ` with ${mortgage.applicant1.employment.currentEmployerName}, ${mortgage.applicant1.employment.currentEmployerAddress} since ${format(startDate, "MMMM yyyy")}`
        items1.push(string)

        let incomes = [...new Set(mortgage.applicant1.income.incomeChecks)];
        let sum = 0
        incomes.forEach(inc => {
            if (mortgage.applicant1.income.hasOwnProperty(inc.toLowerCase())) {
                sum += mortgage.applicant1.income[inc.toLowerCase()]
            }
        })
        let sources = incomes.map(inc => {
            return capitaliseFirst(inc)
        }).join(' / ')
        string = `Basic Salary Per Annum is €${mortgage.applicant1.income?.grossBasic?.toLocaleString('en-us')}`
        if (incomes.includes('COMMISSION')) {
            string += ` + entitlement to commission`
        }
        string += `. `
        if (incomes.length) {
            string += `${sources} of approx. €${sum.toLocaleString('en-us')} per annum`
        }
        items1.push(string)
        items1.push(`Payslip proved confirms pay level`)
        items1.push(`EDS provided for last 3 years`)
    }

    if (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
        items2.push(`Self employed`)
    } else if (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
        let string = ``
        switch (mortgage.applicant2.employment.employmentBasis) {
            case EmploymentBases.FULLTIME:
                string += `Full time employment`
                break
            case EmploymentBases.PARTTIME:
                string += `Part time employment`
                break
            case EmploymentBases.TEMPFULLTIME:
                string += `Temporary full time employment`
                break
            case EmploymentBases.TEMPPARTTIME:
                string += `Temporary part time employment`
                break
            case EmploymentBases.CONTRACT:
                string += `Contracted`
                break
            default:
                string += `Employed`
                break
        }
        let startDate = new Date(mortgage.applicant2.employment.currentEmployerStartDate)
        string += ` with ${mortgage.applicant2.employment.currentEmployerName}, ${mortgage.applicant2.employment.currentEmployerAddress} since ${format(startDate, "MMMM yyyy")}`
        items2.push(string)

        let incomes = [...new Set(mortgage.applicant2.income.incomeChecks)];
        let sum = 0
        incomes.forEach(inc => {
            if (mortgage.applicant2.income.hasOwnProperty(inc.toLowerCase())) {
                sum += mortgage.applicant2.income[inc.toLowerCase()]
            }
        })
        let sources = incomes.map(inc => {
            return capitaliseFirst(inc)
        }).join(' / ')
        string = `Basic Salary Per Annum is €${mortgage.applicant2.income?.grossBasic?.toLocaleString('en-us')}`
        if (incomes.includes('COMMISSION')) {
            string += ` + entitlement to commission`
        }
        string += `. `
        if (incomes.length) {
            string += `${sources} of approx. €${sum.toLocaleString('en-us')} per annum`
        }
        items2.push(string)
        items2.push(`Payslip proved confirms pay level`)
        items2.push(`EDS provided for last 3 years`)
    }

    let year = new Date().getFullYear()
    let app2hasIncome = mortgage.twoApplicants && [EmploymentTypes.SELFEMPLOYED, EmploymentTypes.EMPLOYED].includes(mortgage.applicant2.employment.employmentType)
    const currentMonth = new Date().getMonth();
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const lastThreeMonths = [];
    for (let i = 1; i <= 3; i++) {
        const monthIndex = (currentMonth - i + 12) % 12;
        lastThreeMonths.unshift(monthNames[monthIndex]);
    }
    return <>
        {app2hasIncome && <h4>{mortgage.applicant1.firstName}</h4>}
        <ol>
            {items1.map(item =>
                <li key={item}>{item}</li>
            )}
        </ol>
        <figure className="table" style={{width: '100%'}}>
        <table>
            <tbody>
            <tr>
                <td/>
                <td style={{width:'20%',textAlign:'right'}}><strong>{year - 3}</strong></td>
                <td style={{width:'20%',textAlign:'right'}}><strong>{year - 2}</strong></td>
                <td style={{width:'20%',textAlign:'right'}}><strong>{year - 1}</strong></td>
            </tr>
            <tr>
                <td>Gross Pay</td>
                <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                <td style={{width:'20%',textAlign:'right'}}>0,000</td>
            </tr>
            </tbody>
        </table>
        </figure>
        {app2hasIncome && (
            <>
                <h4>{mortgage.applicant2.firstName}</h4>
                <ol>
                    {items2.map(item =>
                        <li key={item}>{item}</li>
                    )}
                </ol>
                <figure className="table" style={{width: '100%'}}>
                    <table>
                        <tbody>
                        <tr>
                            <td/>
                            <td style={{width:'20%',textAlign:'right'}}><strong>{year - 3}</strong></td>
                            <td style={{width:'20%',textAlign:'right'}}><strong>{year - 2}</strong></td>
                            <td style={{width:'20%',textAlign:'right'}}><strong>{year - 1}</strong></td>
                        </tr>
                        <tr>
                            <td>Gross Pay</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                        </tr>
                        </tbody>
                    </table>
                </figure>
            </>)
        }

        <h4>Net Monthly Take Home Pay</h4>
        <figure className="table" style={{width: '100%'}}>
            <table>
                <tbody>
                <tr>
                    <td></td>
                    <td style={{width:'20%',textAlign:'right'}}><strong>{lastThreeMonths[0]}</strong></td>
                    <td style={{width:'20%',textAlign:'right'}}><strong>{lastThreeMonths[1]}</strong></td>
                    <td style={{width:'20%',textAlign:'right'}}><strong>{lastThreeMonths[2]}</strong></td>
                </tr>
                <tr>
                    <td>{app2hasIncome ? mortgage.applicant1.firstName : 'Net Monthly'}</td>
                    <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                    <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                    <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                </tr>
                {app2hasIncome && (
                    <>
                        <tr>
                            <td>{mortgage.applicant2.firstName}</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                        </tr>
                        <tr>
                            <td>Combined Net Monthly</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                            <td style={{width:'20%',textAlign:'right'}}>0,000</td>
                        </tr>
                    </>
                )}
                </tbody>
            </table>
        </figure>
    </>

}
const BankingFacilities = ({mortgage}) => {
    let setupSections = [
        'employment-summary',
        'payslips',
        'accountant-letter',
        'current-accounts',
        'form11',
        'tax-clearance',
        'business-statements',
        'bank-statements',
        'assets',
    ]
    let uploadList = []
    mortgage.applicant1.accounts.forEach(account => {
        uploadList.push({
            name: 'current-accounts-1',
            title: `${mortgage.applicant1.strings.your} ${accountTitle(account)}`,
            index: account.id,
        })
    })
    mortgage.applicant1.assets.filter(a=>a.assetType===AssetTypes.SAVING).forEach(account => {
        uploadList.push({
            name: 'assets-1',
            title: `${mortgage.applicant1.strings.your} ${assetTitle(account)}`,
            index: account.id,
        })
    })
    if (mortgage.twoApplicants){
        mortgage.applicant2.accounts.forEach(account => {
            uploadList.push({
                name: 'current-accounts-2',
                title: `${mortgage.applicant2.strings.your} ${accountTitle(account)}`,
                index: account.id,
            })
        })
        mortgage.applicant2.assets.filter(a=>a.assetType===AssetTypes.SAVING).forEach(account => {
            uploadList.push({
                name: 'assets-2',
                title: `${mortgage.applicant2.strings.your} ${assetTitle(account)}`,
                index: account.id,
            })
        })
    }
    uploadList = uploadList.map(a=>{
        a.stage = 'APPLY'
        a.type = 'UPLOAD'
        return a
    }).map(a=>{
        const record = mortgage.progress.find(item => {
            if (item.sectionType === 'UPLOAD' && item.applicationStage === a.stage && item.sectionName === a.name) {
                if (a?.index) {
                    return item.sectionIndex === a.index
                }
                return true
            }
            return false
        })
        const queries = mortgage.queries.filter(item => {
            if (!!item.excludedOn) return false
            if (item.stage === a.stage && item.section === a.name) {
                if (a?.index) {
                    return item.index === a.index
                }
                return true
            }
            return false
        })
        return {
            ...a,
            record,
            queries
        }
    })
    return <div key={mortgage.id}>
        {uploadList.map(acc=>{
            return <>
                <h4>{acc.title}</h4>
                <p>Something something ...</p>
                {
                    !!acc.queries.length && (
                        <>
                            {
                                acc.queries.flatMap(q=>{
                                    return q.lenderAnswer.map(a=><p key={a}> {a || 'No Answer'} </p>)
                                })
                            }
                        </>
                    )
                }
            </>
        })}


    </div>
}
const Proposal = ({mortgage}) => {
    return null
}
const RepaymentCapacity = ({mortgage}) => {
    return null
}
const Recommendation = ({mortgage}) => {
    return null
}

export default {
    key: 'coverLetter',
    label: 'Lender Cover Letter',
    //  folder: 'Lender CustomerDocuments',
    contents: [
        {
            key: 'background',
            title: 'Background',
            generate: Background,
            sequence: 0.1,
        },
        {
            key: 'app1VariableIncome',
            title: 'Employment App 1',
            generate: VariableIncome1,
            sequence: 0.2,
        },
        {
            key: 'app2VariableIncome',
            title: 'Employment App 2',
            generate: VariableIncome2,
            sequence: 0.2,
        },
        {
            key: 'income',
            title: 'Income',
            generate: Income,
            sequence: 0.2,
        },
        {
            key: 'bankingFacilities',
            title: 'Banking Facilities',
            generate: BankingFacilities,
            sequence: 0.3,
        },
        {
            key: 'proposal',
            title: 'Proposal',
            generate: Proposal,
            sequence: 0.4,
        },
        {
            key: 'repaymentCapacity',
            title: 'Repayment Capacity',
            generate: RepaymentCapacity,
            sequence: 0.5,
        },
        {
            key: 'recommendation',
            title: 'Recommendation',
            generate: Recommendation,
            sequence: 0.6,
        }
    ]
}