import React from 'react';
import {Button, Dropdown} from "antd";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import useS3 from "../../providers/useS3";
import {CloseOutlined, CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import {green, red} from "@ant-design/colors";
export const getAttachmentPath = (attachment, v2=true) => {
    let path = ''
    let sliceBy = !!v2 ? 2 : 1
    const parts = attachment.bucketKey.split('/');
    if (parts.length > sliceBy) {
        path = parts.slice(sliceBy).join('/');
    }
    return path
}
function AttachmentButtonGroups({menuItems, data, attachmentStatus}) {
    const s3 = useS3()
    const openAttachment = async (att) => {
        let path = getAttachmentPath(att)
        const url = await s3.getS3Url({
            path,
            config: {
                level: 'private',
                identityId: SYSTEMIDENTITYID,
                contentType: att.contentType
            }
        })
        s3.openDocument(url)
    }
    function Attachment({attachment}) {

        let status = attachmentStatus ? attachmentStatus[attachment.bucketKey] : null
        if (!!menuItems) {
            return <Dropdown.Button
                size="small"
                onClick={() => openAttachment(attachment)}
                style={{width: 'auto'}}
                key={`${attachment.bucketKey}-dropdown`}
                menu={{
                    items: menuItems(attachment),
                }}
            >
                {status=== 'success' && <span className="pe-3" style={{color: green.primary}}><CopyOutlined/></span>}
                {status === 'failed' && <span className="pe-3" style={{color: red.primary}}><CloseOutlined/></span>}
                {status === 'busy' && <span className="pe-3"><LoadingOutlined/></span>}
                {attachment.name}
            </Dropdown.Button>
        } else {
            return <Button key={`${attachment.bucketKey}-button`} size="small" onClick={() => openAttachment(attachment)}>
                {attachment.name}
            </Button>
        }
    }

    return (
        <div className="d-row j-start gap-9 my-6 mx-15 f-wrap">
            {
               !!data && data.map(attachment => <Attachment key={`${attachment.bucketKey}-attach`} attachment={attachment}/>)
            }
        </div>
    )
}

export default AttachmentButtonGroups;