import React, {useContext} from 'react';
import {Mortgages} from "../MortgagesProvider";
import dayjs from "dayjs";
import {
    discussionEmail, discussionReminderEmail, setupIncompleteEmail, incompleteReminderEmail, qualificationEmail, qualificationReminderEmail,
} from "./emails/setup";
import {ApplicationStages, ApplicationStatuses, MortgageQualificationStatus} from "../../../models";
import {OutstandingItems} from "../mortgage/OutstandingItemsProvider";
import MortgageScheduleCall from "./modals/MortgageScheduleCall";
//import activeSubmissions from "../../components/dashboard/landing-page/active-submissions/ActiveSubmissions";
import {Button, Dropdown} from "antd";
import {CaretDownOutlined, SettingOutlined} from "@ant-design/icons";

export const MortgagesMessaging = React.createContext(null);
function MortgagesMessagingProvider(props) {
    const categories = useContext(Mortgages)

    const messaging = {
        incomplete : categories.INCOMPLETE.map(mortgage => {

            // setup flow
            let incompleteEmailSent = mortgage.completedFlowItemsForAdmin?.hasOwnProperty('incompleteEmailSent') && mortgage.completedFlowItemsForAdmin.incompleteEmailSent
            let incompleteReminderEmailSent = mortgage.completedFlowItemsForAdmin?.hasOwnProperty('incompleteReminderEmailSent') && mortgage.completedFlowItemsForAdmin.incompleteReminderEmailSent

            let incompleteItems = []

            // send incomplete email
            incompleteItems.push({
                due        : true,
                for        : 'support',
                title      : 'Send Incomplete Email',
                description: 'Send email to remind client to complete their initial figures to allow us to perform initial lender tests',
                done       : incompleteEmailSent,
                buttons    : [
                    {
                        name   : 'incompleteEmailSent',
                        label  : 'Send Email',
                        actions: [
                            {
                                type  : 'sendEmail',
                                email : setupIncompleteEmail,
                                onSent: [
                                    {
                                        type         : 'mortgageCompletedFlowItemsForAdmin',
                                        completedItem: 'incompleteEmailSent',
                                        submissionID : false
                                    },
                                    {
                                        type  : 'mutate',
                                        update: [
                                            {
                                                target: 'applicationStatus',
                                                value : ApplicationStatuses.SUSPENDED
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            })

            // setup incomplete reminder email
            if (incompleteEmailSent && dayjs(incompleteEmailSent.on).startOf('day').add(7, 'day').isBefore(dayjs())) {
                incompleteItems.push({
                    for        : 'support',
                    title      : 'Send Incomplete Reminder Email',
                    description: `Send reminder email as client has not responded to reminder email on ${dayjs(incompleteEmailSent.on).format('D MMM YYYY')}. After sending this email, client will be marked as Suspended.`,
                    done       : incompleteReminderEmailSent,
                    buttons    : [
                        {
                            name   : 'incompleteReminderEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type  : 'sendEmail',
                                    email : incompleteReminderEmail,
                                    onSent: [
                                        {
                                            type         : 'mortgageCompletedFlowItemsForAdmin',
                                            completedItem: 'incompleteReminderEmailSent',
                                            submissionID : false
                                        },
                                        {
                                            type  : 'mutate',
                                            update: [
                                                {
                                                    target: 'applicationStatus',
                                                    value : ApplicationStatuses.SUSPENDED
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                })
            }

            return {
                mortgage,
                flows: [incompleteItems]
            }
        }),
        setup      : categories.SETUP.map(mortgage => {
            let questionsCompletedTestName = 'AffordabilityComplete'
            let setupQuestionsComplete = mortgage.twoApplicants ? mortgage.completedFlowItems.hasOwnProperty(`app1${questionsCompletedTestName}`) && mortgage.completedFlowItems.hasOwnProperty(`app2${questionsCompletedTestName}`) : mortgage.completedFlowItems.hasOwnProperty(`app1${questionsCompletedTestName}`)
            let affordabilityTested = mortgage.completedFlowItemsForAdmin.hasOwnProperty('initialAffordabilityTested') && mortgage.completedFlowItemsForAdmin.initialAffordabilityTested
            let qualificationNotTested = !mortgage.qualification
            let qualificationByAdvisorNeeded = mortgage.qualification === MortgageQualificationStatus.MAYBE
            let qualificationConfirmedByAdvisor = mortgage.qualificationConfirmed
            let qualificationEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('qualificationEmailSent') && mortgage.completedFlowItemsForAdmin.qualificationEmailSent
            let qualificationReminderEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('qualificationReminderEmailSent') && mortgage.completedFlowItemsForAdmin.qualificationReminderEmailSent
            let uploadsStartedTestName = 'SetupUploadsStarted'

            let setupUploadsStarted = mortgage.twoApplicants ? mortgage.completedFlowItems.hasOwnProperty(`app1${uploadsStartedTestName}`) || mortgage.completedFlowItems.hasOwnProperty(`app2${uploadsStartedTestName}`) : mortgage.completedFlowItems.hasOwnProperty(`app1${uploadsStartedTestName}`)
            let setupUploadsCompleted = mortgage.completedFlowItems.hasOwnProperty('SetupUploadsVerified')
            let discussionEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('discussionEmailSent') && mortgage.completedFlowItemsForAdmin.discussionEmailSent
            let discussionReminderEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('discussionReminderEmailSent') && mortgage.completedFlowItemsForAdmin.discussionReminderEmailSent
            let callScheduled = mortgage.completedFlowItems.hasOwnProperty('ApplyCallScheduled')
            let chatComplete = mortgage.completedFlowItems.hasOwnProperty('ApplyChatComplete') && mortgage.completedFlowItems.ApplyChatComplete
            // console.log({
            //     setupQuestionsComplete,
            //     affordabilityTested,
            //     qualificationByAdvisorNeeded,
            //     qualificationConfirmedByAdvisor,
            //     qualificationEmailSent,
            //     qualificationReminderEmailSent,
            //     setupUploadsStarted,
            //     setupUploadsCompleted,
            //     discussionEmailSent,
            //     discussionReminderEmailSent,
            //     callScheduled,
            //     chatComplete
            //
            // })

            let setupItems = [
                {
                    due        : setupQuestionsComplete,
                    for        : 'support',
                    title      : 'Test Qualification',
                    description: 'Use the clients claimed figures to perform Lender Test',
                    done       : affordabilityTested,
                    buttons    : [
                        {
                            name   : 'affordabilityTestLink',
                            label  : 'Lender Test',
                            actions: [
                                {
                                    type: 'setActiveKey',
                                    key : 'Lender Test'
                                }
                            ]
                        }
                    ]
                },
                {
                    due        : affordabilityTested,
                    for        : 'support',
                    title      : 'Update Client Qualification Status',
                    description: 'Set the client to either Qualified or Not Qualified. If not qualified and income ≥ 85k or loan ≥ 350 mark Maybe',
                    done       : !qualificationNotTested,
                    buttons    : (bigMortgage) => {
                        let list = [
                            {
                                name   : 'markQualified',
                                label  : 'Mark Qualified',
                                actions: [
                                    {
                                        type  : 'mutate',
                                        mutate: {
                                            update: {
                                                target: '',
                                                pairs : {
                                                    qualification: MortgageQualificationStatus.QUALIFIED,
                                                    advisorID    : 'ac6b7714-82ba-4a43-8d22-b652e9444b00'//Kevin
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                name   : 'markMaybeQualified',
                                label  : 'Mark Maybe',
                                actions: [
                                    {
                                        type  : 'mutate',
                                        mutate: {
                                            update: {
                                                target: '',
                                                pairs : {
                                                    qualification: MortgageQualificationStatus.MAYBE,
                                                    advisorID    : 'ac6b7714-82ba-4a43-8d22-b652e9444b00'//Kevin
                                                }
                                            }
                                        }
                                    }
                                ]
                            }
                        ]
                        let basic = bigMortgage.figures.unverified.basic1.total
                        if (bigMortgage.twoApplicants) {
                            basic += bigMortgage.figures.unverified.basic2.total
                        }
                        let shouldNotShowNotQualified = basic >= 85000 || bigMortgage.requirement.loanRequired >= 350000
                        if (!shouldNotShowNotQualified) {
                            list.push({
                                name   : 'markNotQualified',
                                label  : 'Mark Not Qualified',
                                actions: [
                                    {
                                        type  : 'mutate',
                                        mutate: {
                                            update: {
                                                target: '',
                                                pairs : {
                                                    qualification    : MortgageQualificationStatus.UNQUALIFIED,
                                                    applicationStatus: ApplicationStatuses.SUSPENDED
                                                }
                                            }
                                        }
                                    }
                                ]
                            })
                        }
                        return list
                    }
                },
                {
                    due        : setupQuestionsComplete && qualificationByAdvisorNeeded,
                    for        : 'advisor',
                    title      : 'Manual Qualification Needed',
                    description: 'Client needs manual review to determine qualification status. Please review and set qualification status',
                    done       : qualificationConfirmedByAdvisor,
                    buttons    : [
                        {
                            name   : 'markQualified',
                            label  : 'Mark Qualified',
                            actions: [
                                {
                                    type  : 'mutate',
                                    mutate: {
                                        update: {
                                            target: '',
                                            pairs : {
                                                qualificationConfirmed: true,
                                                qualification         : MortgageQualificationStatus.QUALIFIED
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                        {
                            name   : 'markNotQualified',
                            label  : 'Mark Not Qualified',
                            actions: [
                                {
                                    type  : 'mutate',
                                    mutate: {
                                        update: {
                                            target: '',
                                            pairs : {
                                                qualificationConfirmed: true,
                                                qualification         : MortgageQualificationStatus.UNQUALIFIED,
                                                applicationStatus     : ApplicationStatuses.SUSPENDED
                                            }
                                        }
                                    }
                                }
                            ]
                        },
                    ]
                },
                {
                    due        : setupQuestionsComplete && !qualificationNotTested && !qualificationByAdvisorNeeded,
                    for        : 'support',
                    title      : 'Send Qualification Email',
                    description: 'Send email to inform client of their qualification status',
                    done       : qualificationEmailSent,
                    buttons    : [
                        {
                            name   : 'qualificationEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type  : 'sendEmail',
                                    email : qualificationEmail,
                                    onSent: [
                                        {
                                            type         : 'mortgageCompletedFlowItemsForAdmin',
                                            completedItem: 'qualificationEmailSent',
                                            submissionID : false
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    due        : qualificationReminderEmailSent || (!setupUploadsStarted && qualificationEmailSent && dayjs(qualificationEmailSent.on).startOf('day').add(7, 'day').isBefore(dayjs())),
                    for        : 'support',
                    title      : 'Send Qualification Reminder Email',
                    description: `Send reminder email as client has not responded to qualification email on ${dayjs(qualificationEmailSent.on).format('D MMM YYYY')}. After sending this email, client will be marked as Suspended.`,
                    done       : qualificationReminderEmailSent,
                    buttons    : [
                        {
                            name   : 'qualificationReminderEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type  : 'sendEmail',
                                    email : qualificationReminderEmail,
                                    onSent: [
                                        {
                                            type         : 'mortgageCompletedFlowItemsForAdmin',
                                            completedItem: 'qualificationReminderEmailSent',
                                            submissionID : false
                                        },
                                        {
                                            type  : 'mutate',
                                            update: [
                                                {
                                                    target: 'applicationStatus',
                                                    value : ApplicationStatuses.SUSPENDED
                                                }
                                            ]
                                        }
                                    ]
                                },
                            ],
                        }
                    ]
                },
                {
                    due        : setupUploadsCompleted,
                    for        : 'support',
                    title      : 'Send Discussion Appointment Email',
                    description: 'Send email to client to arrange a discussion to review their options',
                    done       : discussionEmailSent,
                    buttons    : [
                        {
                            name   : 'discussionEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type  : 'sendEmail',
                                    email : discussionEmail,
                                    onSent: [
                                        {
                                            type         : 'mortgageCompletedFlowItemsForAdmin',
                                            completedItem: 'discussionEmailSent',
                                            submissionID : false
                                        }
                                    ]
                                },
                            ],
                        }
                    ]
                },
                {
                    due        : discussionReminderEmailSent || (!callScheduled && discussionEmailSent && dayjs(discussionEmailSent.on).startOf('day').add(3, 'day').isBefore(dayjs())),
                    for        : 'support',
                    title      : 'Send Discussion Reminder Email',
                    description: `Send reminder email as client has not responded to discussion email on ${dayjs(discussionEmailSent.on).format('D MMM YYYY')}. After sending this email, client will be marked as Suspended.`,
                    done       : discussionReminderEmailSent,
                    buttons    : [
                        {
                            name   : 'discussionReminderEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type  : 'sendEmail',
                                    email : discussionReminderEmail,
                                    onSent: [
                                        {
                                            type         : 'mortgageCompletedFlowItemsForAdmin',
                                            completedItem: 'discussionReminderEmailSent',
                                            submissionID : false
                                        },
                                        {
                                            type  : 'mutate',
                                            update: [
                                                {
                                                    target: 'applicationStatus',
                                                    value : ApplicationStatuses.SUSPENDED
                                                }
                                            ]
                                        }
                                    ]
                                },
                            ],
                        }
                    ]
                },
                {
                    due        : discussionEmailSent,
                    for        : 'support',
                    title      : 'Schedule Call',
                    description: 'Schedule a call with the client to discuss their options',
                    done       : callScheduled,
                    buttons    : [
                        {
                            name   : 'scheduleCallManually',
                            label  : 'Schedule Call',
                            actions: [
                                {
                                    type : 'openModal',
                                    modal: MortgageScheduleCall, //which triggers mortgageCompletedFlowItems: 'ApplyCallScheduled'
                                }
                            ]
                        }
                    ]
                },
                {
                    due        : callScheduled,
                    for        : 'support',
                    title      : 'Mark Chat Complete',
                    description: 'Mark chat as complete to allow client to to client to next stage',
                    done       : chatComplete,
                    buttons    : [
                        {
                            name   : 'markChatComplete',
                            label  : 'Have The Chat',
                            actions: [
                                {
                                    type         : 'mortgageCompletedFlowItems',
                                    completedItem: 'ApplyChatComplete',
                                }
                            ]
                        }
                    ]
                }
            ]

            return {
                mortgage,
                flows: [setupItems]
            }
        }),
        apply      : categories.APPLY.map(mortgage => {

            let applyStartedTestName = 'ApplicationUploadsStarted'
            let applyUploadsStarted = mortgage.twoApplicants ? mortgage.completedFlowItems.hasOwnProperty(`app1${applyStartedTestName}`) || mortgage.completedFlowItems.hasOwnProperty(`app2${applyStartedTestName}`) : mortgage.completedFlowItems.hasOwnProperty(`app1${applyStartedTestName}`)
            let startApplicationUploadsEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('startApplicationUploadsEmailSent') && mortgage.completedFlowItemsForAdmin.startApplicationUploadsEmailSent
            let startApplicationUploadsReminderEmailSent = mortgage.completedFlowItemsForAdmin.hasOwnProperty('startApplicationUploadsReminderEmailSent') && mortgage.completedFlowItemsForAdmin.startApplicationUploadsReminderEmailSent
            let applyCompletedTestName = 'ApplicationUploadsComplete'
            let applyUploadsCompleted = mortgage.twoApplicants ? mortgage.completedFlowItems.hasOwnProperty(`app1${applyCompletedTestName}`) || mortgage.completedFlowItems.hasOwnProperty(`app2${applyCompletedTestName}`) : mortgage.completedFlowItems.hasOwnProperty(`app1${applyCompletedTestName}`)
            let applyUploadsVerified = mortgage.completedFlowItems.hasOwnProperty('ApplyUploadsVerified') && mortgage.completedFlowItems.ApplyUploadsVerified
            let applyItems = [
                {
                    due        : !applyUploadsStarted,
                    for        : 'support',
                    title      : 'Start Application Uploads',
                    description: 'Client has not started uploading their application documents, so send outstanding items email',
                    done       : startApplicationUploadsEmailSent,
                    buttons    : [
                        {
                            name   : 'startApplicationUploadsEmailSent',
                            label  : 'Send Start Uploads Email',
                            actions: [
                                {
                                    type : 'sendEmail',
                                    email: null //TODO

                                }
                            ]
                        }
                    ]
                },
                {
                    due        : startApplicationUploadsReminderEmailSent || (startApplicationUploadsEmailSent && dayjs(startApplicationUploadsEmailSent.on).startOf('day').add(7, 'day').isBefore(dayjs())),
                    for        : 'support',
                    title      : 'Send Start Uploads Reminder Email',
                    description: `Send reminder email as client has not responded to start uploads email on ${dayjs(startApplicationUploadsEmailSent.on).format('D MMM YYYY')}. After sending this email, client will be marked as Suspended.`,
                    done       : startApplicationUploadsReminderEmailSent,
                    buttons    : [
                        {
                            name   : 'startApplicationUploadsReminderEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type : 'sendEmail',
                                    email: null //TODO
                                },
                            ],
                        }
                    ]
                },
                {
                    due        : applyUploadsCompleted,
                    for        : 'support',
                    title      : 'Verify Application Uploads',
                    description: 'Verify that client has uploaded all required documents',
                    done       : applyUploadsVerified,
                    buttons    : [
                        {
                            name   : 'verifyApplicationUploads',
                            label  : 'Verify Uploads',
                            actions: [
                                {
                                    type         : 'mortgageCompletedFlowItems',
                                    completedItem: 'ApplyUploadsVerified',
                                },
                                {
                                    type  : 'mutate',
                                    mutate: {
                                        update: {
                                            target: '',
                                            pairs : {
                                                applicationStage: ApplicationStages.SIGN
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]

            return {
                mortgage,
                flows: [applyItems]
            }
        }),
        signing    : categories.SIGN.map(mortgage => {
            let signingItems = []
            if (!mortgage.activeSubmission) {
                signingItems.push({
                    due        : true,
                    for        : 'support',
                    title      : 'Open client to update Submissions',
                    description: 'Client account needs updating',
                    done       : false,
                })
            }
            else {
                let affordabilityTested = mortgage.activeSubmission.completedFlowItemsForAdmin?.signingAffordabilityTested
                signingItems.push({
                    due        : true,
                    for        : 'advisor',
                    title      : 'Verify Lender Test',
                    description: 'All uploads are now verified. Use the clients verified figures to perform Lender Test',
                    done       : affordabilityTested,
                    buttons    : [
                        {
                            name   : 'signingAffordabilityTestLink',
                            label  : 'Lender Test',
                            actions: [
                                {
                                    type: 'setActiveKey',
                                    key : 'Lender Test'
                                }
                            ]
                        }
                    ]
                })
            }
            return {
                mortgage,
                flows: [signingItems]
            }
        }),
        preapproval: categories.PREAPPROVAL.map(mortgage => {
            let preapprovalItems = []
            if (!mortgage.activeSubmission) {
                preapprovalItems.push({
                    due        : true,
                    for        : 'support',
                    title      : 'Open client to update Submissions',
                    description: 'Client account needs updating',
                    done       : false,
                    buttons    : [
                        {
                            name : 'startAIP',
                            label: 'Start AIP',

                            actions: [
                                {
                                    type: 'navigate',
                                    to  : '/client/' + mortgage.id + '/aip'
                                }
                            ]
                        }
                    ]
                })
            }
            else {
                let aipExpiry = dayjs(mortgage.activeSubmission.aipExpiryDate, 'YYYY-MM-DD')
                let aipAboutToExpiryEmailSent = mortgage.activeSubmission.completedFlowItemsForAdmin.hasOwnProperty('aipAboutToExpiryEmailSent') && mortgage.activeSubmission.completedFlowItemsForAdmin.aipAboutToExpiryEmailSent

                preapprovalItems.push({
                    due        : aipExpiry.subtract(3, 'week').isBefore(dayjs()),
                    for        : 'advisor',
                    title      : `Send AIP to Expire on ${aipExpiry.format('D MMM YYYY')} Email`,
                    description: 'Send email to client to inform them that their AIP is about to expire',
                    done       : aipAboutToExpiryEmailSent,
                    buttons    : [
                        {
                            name   : 'aipAboutToExpiryEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type : 'sendEmail',
                                    email: null //TODO
                                }
                            ]
                        }
                    ]
                })
            }

            // aip about to expire email
            return {
                mortgage,
                flows: [preapprovalItems]
            }
        }),
        approval   : categories.APPROVAL.map(mortgage => {
            let approvalItems = []

            if (!mortgage.activeSubmission) {
                approvalItems.push({
                    due        : true,
                    for        : 'support',
                    title      : 'Open client to update Submissions',
                    description: 'Client account needs updating',
                    done       : false,
                    buttons    : [
                        {
                            name   : 'startAIP',
                            label  : 'Start AIP',
                            actions: [
                                {
                                    type: 'navigate',
                                    to  : '/client/' + mortgage.id + '/aip'
                                }
                            ]
                        }
                    ]
                })
            }
            else {
                let loanOfferExpiry = dayjs(mortgage.activeSubmission.loanOfferExpiryDate, 'YYYY-MM-DD')
                let loanOfferAboutToExpiryEmailSent = mortgage.activeSubmission.completedFlowItemsForAdmin.hasOwnProperty('loanOfferAboutToExpiryEmailSent') && mortgage.activeSubmission.completedFlowItemsForAdmin.loanOfferAboutToExpiryEmailSent

                approvalItems.push({
                    due        : loanOfferAboutToExpiryEmailSent || mortgage.activeSubmission.loanOfferState === 'expiring',
                    for        : 'advisor',
                    title      : `Send Loan Offer to Expire on ${loanOfferExpiry.format('D MMM YYYY')} Email`,
                    description: 'Send email to client to inform them that their Loan Offer is about to expire',
                    done       : loanOfferAboutToExpiryEmailSent,
                    buttons    : [
                        {
                            name   : 'loanOfferAboutToExpiryEmailSent',
                            label  : 'Send Email',
                            actions: [
                                {
                                    type : 'sendEmail',
                                    email: null //TODO
                                }
                            ]
                        }
                    ]
                })

                if (mortgage.activeSubmission.drawdownDate) {
                    let drawdownDate = dayjs(mortgage.activeSubmission.drawdownDate, 'YYYY-MM-DD')
                    if (drawdownDate.isBefore(dayjs())) {
                        approvalItems.push({
                            due        : true,
                            for        : 'advisor',
                            title      : `Update Drawdown Date or Mark Complete`,
                            description: 'Drawdown date has passed, please update or mark complete',
                            done       : false,
                        })
                    }
                }
            }
            // loan offer about to expire email
            return {
                mortgage,
                flows: [approvalItems]
            }
        }),
    }
    return (<MortgagesMessaging.Provider value={messaging}>
        {props.children}
    </MortgagesMessaging.Provider>);
}
export default MortgagesMessagingProvider;
export function MessageAlertContent({
    mortgage,
    item,
    toolsDrawer,
    setActiveKey
})
{
    const performActions = (actions, mutationHolder, faking) => {
        console.log({
            actions,
            faking,
            mutationHolder
        })
        actions.forEach(action => {
            switch (action.type) {
                case 'sendEmail':
                    if (faking && action.onSent) {
                        performActions(action.onSent, mutationHolder, faking)
                    }
                    else {
                        let config = action.email(mortgage)
                        if (action.onSent) {
                            config.onSent = () => {
                                handleActions(action.onSent)
                            }
                        }
                        toolsDrawer.composeEmail(config)
                    }
                    break;
                case 'mutate':
                    if (action.hasOwnProperty('update')) {
                        if (Array.isArray(action.update)) {
                            mutationHolder.update.push(...action.update)
                        }
                        else {
                            mutationHolder.update.push(action.update)
                        }
                    }
                    if (action.hasOwnProperty('create')) {
                        if (Array.isArray(action.create)) {
                            mutationHolder.create.push(...action.create)
                        }
                        else {
                            mutationHolder.create.push(action.create)
                        }
                    }
                    break;
                case 'setActiveKey':
                    setActiveKey(action.key)
                    break;
                case 'mortgageCompletedFlowItems':
                    mutationHolder.update.push(mortgage.completedFlowItems.getMarkCompleteUpdateMutation(action.completedItem))
                    break;
                case 'mortgageCompletedFlowItemsForAdmin':

                    mutationHolder.update.push(mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation(action.completedItem, action?.submissionID, faking))
                    break;
                case 'navigate':
                    break;
                case 'openModal':
                    break;
                default:

            }
        })
    }
    const handleActions = (actions, faking = false) => {
        console.log({actions})
        let superMutation = {
            update: [],
            create: [],
        }
        performActions(actions, superMutation, faking)
        console.log({superMutation})
        if (superMutation.update.length > 0 || superMutation.create.length > 0) {
            mortgage.mutate(superMutation)
        }
    }
    const items = [
        {
            key     : '1',
            label   : 'Options',
            disabled: true,
        },
        {
            type: 'divider',
        },
        {
            key  : '3',
            label: 'Postpose',
        }
    ]
    if (item.buttons) {

        let list = item.buttons
        if (typeof item.buttons === "function") {
            list = item.buttons(mortgage)
        }

        list.forEach(button => {
            items.push({
                key    : `sendEmail:${button.name}`,
                label  : `Fake ${button.label}`,
                onClick: () => {
                    handleActions(button.actions, true)
                }
            })
        })
    }
    const buttons = typeof item.buttons === "function" ? item.buttons(mortgage) : item.buttons
    return <>
        <div className="d-row j-end">
            <Dropdown
                placement="bottomRight"
                trigger={['click']}
                menu={{
                    items,
                }}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <SettingOutlined/>
                    <CaretDownOutlined/>
                </a>
            </Dropdown>
        </div>
        <span>{item.description}</span>
        <div className="d-row f-wrap gap-3 mt-15">
            {!!buttons && buttons.map(button => {
                return <Button key={button.name} onClick={() => handleActions(button.actions)}>{button.label}</Button>
            })}
        </div>
    </>

}

export function useMessages() {
    const categories = useContext(Mortgages)

    const forAdvisor = []
    const forSupport = []
    const forRest = []

    // outstanding items for uploads
    const outstandingItems = useContext(OutstandingItems)

    const activePendingMortgageIds = Object.keys(outstandingItems).filter(mortgageId => categories.ACTIVE.find(m => m.id === mortgageId))
    activePendingMortgageIds.forEach(mortgageId => {
        const mortgage = categories.ACTIVE.find(m => m.id === mortgageId)
        let uploads = outstandingItems[mortgageId].uploads
        let queries = outstandingItems[mortgageId].queries
        let needsReview = outstandingItems[mortgageId].needsReview
        if (needsReview.length > 0) {
            const sortedArray = needsReview.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            forAdvisor.push({
                key  : `itemNeedsReview[${mortgageId}]`,
                title: `Uploads needs advisor review`,
                mortgage
            })
        }
        if (uploads.length > 0) {
            const sortedArray = uploads.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const due = sortedArray.length ? dayjs(sortedArray[0].createdAt) : null
            forSupport.push({
                key  : `itemUploaded[${mortgageId}]`,
                title: `Review uploaded items`,
                mortgage
            })
        }
        if (queries.length > 0) {
            const sortedArray = queries.sort((a, b) => new Date(b.userAnswerOn) - new Date(a.userAnswerOn));
            const due = sortedArray.length ? dayjs(sortedArray[0].userAnswerOn) : null
            forAdvisor.push({
                key  : `queryAnswered[${mortgageId}]`,
                title: `Attend to answered query`,
                mortgage
            })
        }
    })

    // Flow Messages
    const {
              incomplete,
              setup,
              apply,
              signing,
              preapproval,
              approval
          } = useContext(MortgagesMessaging)
    const allMessages = [
        ...incomplete,
        ...setup,
        ...apply,
        ...signing,
        ...preapproval,
        ...approval
    ]
    allMessages.forEach(message => {
        message.flows.forEach(flow => {
            if (flow.length) {
                let arrayToPush = null
                // last item that is not done
                let ItemNotDoneArray = flow.filter(item => !item.done && item.due)
                let lastItem = ItemNotDoneArray[ItemNotDoneArray.length - 1]

                //let lastItem = flow[flow.length - 1]
                if (lastItem) {
                    switch (lastItem.for) {
                        case 'advisor':
                            arrayToPush = forAdvisor
                            break
                        case 'support':
                            arrayToPush = forSupport
                            break
                        default:
                            arrayToPush = forRest
                            break
                    }
                    arrayToPush.push({
                        mortgage: message.mortgage,
                        title   : lastItem.title,
                        key     : message.mortgage.id + lastItem.title
                    })

                }
            }
        })
        return false
    })

    // Delayed Mortgage Coming Active
    let today = dayjs()
    categories.DELAYED.filter(mortgage => {
        let due = dayjs(mortgage.applicationFutureDate, 'YYYY-MM-DD')
        return due.isBefore(today)
    }).forEach(mortgage => {
        let due = dayjs(mortgage.applicationFutureDate, 'YYYY-MM-DD')
        forAdvisor.push({
            mortgage,
            title: `Delayed mortgage should be contacted (${due.format('D MMM YYYY')})`,
            key  : `delayedMortgage[${mortgage.id}]`
        })
    })

    const getHistoryByMortgageId = (mortgageId) => allMessages.filter(m => m.mortgage.id === mortgageId)
    return {
        forAdvisor,
        forSupport,
        getHistoryByMortgageId
    }

}