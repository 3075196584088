import React, {useContext, useEffect, useRef, useState} from 'react';
import {Lenders} from "../../../../../providers/LendersProvider";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import MortgageForm from "../../../../data-capture/MortgageForm";
import {Alert, Button, Card, Collapse, Form, Input, Select} from "antd";
import useRates from "../../../../../providers/mortgage/useRates";

function SelectLender(props) {
        const lendersContext = useContext(Lenders)
        const {getProductsForMortgage} = useRates()
        const mortgage = useMortgage()
        useEffect(() => {
                const createNewIfNeeded = async () => {
                        if (!mortgage.activeSubmission) {
                                await mortgage.mutate({
                                        create: {
                                                target: 'submissions',
                                                pairs : {
                                                        mortgageID: mortgage.id
                                                }
                                        }
                                })
                        }
                        // if (mortgage.activeSubmission && !!mortgage.activeSubmission.lenderCode && mortgage.requirement.lenderCode !== mortgage.activeSubmission.lenderCode) {
                        //     await mortgage.mutate({
                        //         update: {
                        //             target: `requirement.lenderCode`,
                        //             value: mortgage.activeSubmission.lenderCode
                        //         }
                        //     })
                        // }
                }
                createNewIfNeeded()
        }, [mortgage]);
        // update the product and rate if the rateId Changes
        let products = getProductsForMortgage(mortgage)

        if (!mortgage.activeSubmission) {
                return <Alert message="Please stand by - creating lender flow" type="info" showIcon/>
        }

        let questions = [
                {
                        label : "Confirm Lender",
                        name  : "lenderCode",
                        answer: {
                                type    : "select",
                                choices : [
                                        ...lendersContext.lenders.map(lender => ({
                                                value: lender.lenderCode,
                                                text : lender.lender
                                        }))
                                ],
                                disabled: !!mortgage.activeSubmission.lenderCode,
                        },

                        target: `submissions[${mortgage.activeSubmission.id}].lenderCode`
                },
                {
                        label : 'Soft AIP',
                        text  : 'Soft Approval',
                        name  : 'soft',
                        answer: {
                                type   : 'select',
                                choices: [
                                        {
                                                value: true,
                                                text : 'Yes'
                                        },
                                        {
                                                value: false,
                                                text : 'No'
                                        }
                                ]
                        },
                        target: `submissions[${mortgage.activeSubmission.id}].soft`
                },
                {
                        label : 'Lender Recommendation',
                        name  : 'lenderSelectionReason',
                        answer: {
                                type: 'textarea'
                        },
                        target: `submissions[${mortgage.activeSubmission.id}].lenderRecommendation`,
                },
        ]
        if (mortgage.activeSubmission.lenderCode) {
                questions.push({

                        name              : 'provisionalRateId',
                        label             : 'Provisional Rate',
                        answer            : {
                                type   : 'select',
                                choices: products.map(rate => ({
                                        value: rate.id,
                                        text : `${rate.rate.toFixed(2)}% ${rate.term === 0 ? 'Variable' : `${rate.term}yr`} ${rate.maxLTV}% LTV`
                                }))
                        },
                        target            : `submissions[${mortgage.activeSubmission.id}].provisionalRateId`,
                        additionalMutation: (values) => {
                                const rate = products.find(it => it.id === values.provisionalRateId)
                                return {
                                        update: [
                                                {
                                                        target: `submissions[${mortgage.activeSubmission.id}]`,
                                                        pairs : {
                                                                provisionalRate   : rate.rate,
                                                                provisionalProduct: rate.product
                                                        }
                                                }
                                        ]
                                }
                        }
                })
        }
        const CollapsiblePanel = () => {
                const [activeKey, setActiveKey] = useState(null);

                const togglePanel = () => {
                        setActiveKey(activeKey === null ? '1' : null);
                };
                const discontinueLenderQuestion = {
                        label : 'Reason',
                        text  : 'Discontinue Lender',
                        name  : 'discontinueLender',
                        answer: {
                                type: 'textarea',
                        },
                        model : 'submissions',
                        target: `submissions[${mortgage.activeSubmission.id}].invalidatedReason`
                }

                return (<div className="d-col mt-15 gap-15">
                        {activeKey !== null && (<MortgageForm questions={[discontinueLenderQuestion]} title="Discontinue with Lender" style={{minWidth: 600}}/>)}
                        <div className="d-row">
                                <Button onClick={togglePanel}>
                                        {activeKey === null ? 'Change Lender' : 'Cancel Change'}
                                </Button>
                        </div>


                </div>);
        };
        let selectRateNotAvailable = products.find(it => it.id === mortgage.activeSubmission.provisionalRateId) === undefined
        return <>
                <MortgageForm questions={questions} title="Lender Selection" style={{minWidth: 600}}/>
                {selectRateNotAvailable && <Alert message={(<>
                        <div>Selected rate not available anymore;</div>
                        <div><strong>Saved Product:</strong> {mortgage.activeSubmission.finalProduct || mortgage.activeSubmission.provisionalProduct} </div>
                        <div><strong>Saved Rate:</strong> {mortgage.activeSubmission.finalRate || mortgage.activeSubmission.provisionalRate} </div>
                </>)} type="warning" showIcon/>}
                {mortgage.activeSubmission && !!mortgage.activeSubmission.lenderCode && (<CollapsiblePanel/>)}
        </>

}

export default SelectLender;