import React from 'react';
import useMortgage from "../../../../../../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../../../../../../providers/mortgage/useMortgageUploadService";
import {Button} from "antd";

function OpenButton({uploadId}) {

    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const upload = mortgage.uploads.find(item => item.id === uploadId)
    const open = async () => {
        await uploadService.open(upload)
    }
    return (
        <Button size="small" onClick={open}>Open</Button>
    );
}

export default OpenButton;