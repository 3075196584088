import React from 'react';
import {CheckCircleFilled, CheckOutlined, LoadingOutlined} from "@ant-design/icons";
import {Avatar, Badge, Checkbox, ConfigProvider, Segmented} from "antd";
import {blue, green, orange, purple, red} from "@ant-design/colors";
const b = blue[5]
const r = red[5]
const g = green[5]
const gr = 'rgb(207, 207, 207)'
const o = orange[5]
function SegmentsLenders({
    lenders,
    state,
    setState,
    setActiveSegment,
    mortgage,
})
{
    const lenderOptions = lenders.map(lender => {
        let myCurrentTest = state.currentTest?.data?.tests?.[lender.key]
        let myCurrentResults = state.currentTest?.results?.[lender.key]

        let loading = myCurrentResults?.loading
        let failed = myCurrentResults?.failed
        let passed = myCurrentResults?.passed
        let needsReview = myCurrentResults?.review
        let selected = state.selectedLenders.includes(lender.key)

        //loading= true
        let icon = null
        let avatarStyle = {backgroundColor: b}
        let checkboxToken = {
            controlInteractiveSize: 30,
            borderRadiusSM        : 15
        }

        let segmentStyleProps = {}
        if (loading) {
            icon = <LoadingOutlined/>
            avatarStyle = {backgroundColor: gr}
            checkboxToken.colorBgContainer = gr
            checkboxToken.colorBorder = gr
            checkboxToken.colorPrimary = gr
            checkboxToken.colorPrimaryBorder = gr

            // colorBorder: blue[4],
            // colorPrimary: blue[4],
            // colorPrimaryBorder: blue[4],
        }
        else {
            if (!selected) {
                avatarStyle = {backgroundColor: gr}
            }
            else {
                avatarStyle = {backgroundColor: b}
                if (failed) {
                    segmentStyleProps = {
                        style: {
                            backgroundColor: r
                        }
                    }
                    avatarStyle = {backgroundColor: r}
                    checkboxToken.colorBgContainer = r
                    checkboxToken.colorBorder = r
                    checkboxToken.colorPrimary = r
                    checkboxToken.colorPrimaryBorder = r
                }
                else if (passed) {
                    avatarStyle = {backgroundColor: g}
                    checkboxToken.colorBgContainer = g
                    checkboxToken.colorBorder = g
                    checkboxToken.colorPrimary = g
                    checkboxToken.colorPrimaryBorder = g
                }
                else if (needsReview) {
                        avatarStyle = {backgroundColor: o}
                        checkboxToken.colorBgContainer = o
                        checkboxToken.colorBorder = o
                        checkboxToken.colorPrimary = o
                        checkboxToken.colorPrimaryBorder = o
                }
                else if (myCurrentResults && !passed){
                    avatarStyle = {backgroundColor: r}
                    checkboxToken.colorBgContainer = r
                    checkboxToken.colorBorder = r
                    checkboxToken.colorPrimary = r
                    checkboxToken.colorPrimaryBorder = r
                }

            }
            icon = (<ConfigProvider
                theme={{
                    token: checkboxToken,
                }}
            >
                <Checkbox className="square-check" style={{padding: 8}} checked={state.selectedLenders.includes(lender.key)} onChange={e => {
                    if (e.target.checked) {
                        setState(s => {
                            return {
                                ...s,
                                selectedLenders: [
                                    ...s.selectedLenders,
                                    lender.key
                                ]
                            }
                        })
                    }
                    else {
                        setState(s => {
                            return {
                                ...s,
                                selectedLenders: s.selectedLenders.filter(key => key !== lender.key)
                            }
                        })
                    }

                }}/>

            </ConfigProvider>)
        }

        let savedLenderTest = mortgage?.activeSubmission?.provisionalXlsOutput?.testId === state.currentTest.id && mortgage?.activeSubmission?.lenderCode === lender.key
        let count = savedLenderTest ? <CheckCircleFilled style={{color: b}}/> : 0

        return {
            label: (<div style={{padding: 12}}>
                <Badge size="large" count={count} offset={[-3,3]}>
                    <Avatar size="large" style={avatarStyle} icon={icon}/>
                </Badge>
                <div>{lender.label}</div>
            </div>),
            value: lender.key, ...segmentStyleProps
        }
    })
    return <Segmented options={lenderOptions} onChange={setActiveSegment} value={state.ui.activeSegment}/>
}

export default SegmentsLenders;