import {useContext} from 'react';
import {Lenders} from "../../../providers/LendersProvider";
import {App} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage";
import {capitaliseWords, fileNameAndExtension} from "../../../../assets/scripts/string-functions";
import {API} from "aws-amplify";
import templates from "../../../../assets/scripts/overlay-maps";
import {TemplateTypes} from "../../../../models";
import {SUSSDASLENDERID, SYSTEMIDENTITYID} from "../../../../assets/scripts/constants";

export function getMappings(name, mortgage, config) {
    let func
    if (templates.sussd.hasOwnProperty(name)) {
        func = templates.sussd[name]
    } else {
        func = templates[mortgage.activeSubmission.lenderCode][name]
    }
    if (!func) {
        return {error: `No mapping for ${mortgage.activeSubmission.lenderCode} ${name} - have you added to \\assets\\scripts\\overlay-maps\\index.js`}
    }
    if (!config) {
        config = {}
    }
    return func(mortgage, config)
}
export function getTempDocumentRow(app, mortgage, stage, name, folder, isPrivate, isDynamic, submissionID) {
    // ADD UPLOAD DETAILS TO UserMortgageDocuments
    const identityID = mortgage.applicant1.identityID
    if (!identityID) {
        app.notification.error({message: `You can't access the customers uploads folder. Have them sign into the app`})
        return false

    }
    const identityID2 = (mortgage.twoApplicants && !!mortgage.applicant2.identityID) ? mortgage.applicant2.identityID : null
    const readAccessGroup = isPrivate ? [] : [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
    const editAccessGroup = []
    const title = capitaliseWords(name.replace(/-/g, ' '))

    return {
        stage,
        fileName: `${name}.pdf`,
        name,
        folder,
        identityID,
        identityID2,
        title,
        private: isPrivate,
        isDynamic,
        mortgageID: mortgage.id,
        submissionID,
        readAccessGroup,
        editAccessGroup
    }
}
export const getUserDocumentKey = (mortgageID, folder, fileName, submissionID) => {
    if (!!submissionID) {
        return `files/mortgage/${mortgageID}/documents/submission:${submissionID}/${folder}/${fileName}`
    }
    return `files/mortgage/${mortgageID}/documents/${folder}/${fileName}`
}

function useLenderDocumentGenerator() {
    const app = App.useApp()
    const {lenders} = useContext(Lenders)
    const mortgage = useMortgage()

    const generate = async ({stage, name, folder: destinationFolder, private: isPrivate, fill, overlay, sussd}) => {
        // 1. Get the template from the lender overlayTemplates (overlayTemplate is mis-named - should be documentTemplate)


        if (!mortgage.activeSubmission.lenderCode) {
            app.notification.error({message: 'No lender selected'})
            return false
        }
        let submissionID = mortgage.activeSubmission.id
        let lenderDoc
        if (sussd) {
            lenderDoc = {
                id: null,
                bucketKey: `lenders/sussd/source-documents/${name}.pdf`
            }
        } else {
            const selectedLender = lenders.find(it => it.lenderCode === mortgage.activeSubmission.lenderCode)

            lenderDoc = selectedLender.overlayTemplates.find(it => it.stage === stage && it.fileName === `${name}.pdf`)

            if (!lenderDoc) {
                app.notification.error({message: 'No lender document found'})
                return false
            }
        }

        if (!!overlay) {
            return generateOverlay(stage, name, destinationFolder, isPrivate, lenderDoc.id, submissionID)
        }
        if (!!fill) {
            return generateFilled(stage, name, destinationFolder, isPrivate, lenderDoc.bucketKey, submissionID)
        }

        return generateCopy(stage, name, destinationFolder, isPrivate, lenderDoc.bucketKey, submissionID)

    }

    const generateFilled = async (stage, name, destinationFolder, isPrivate, sourceBucketKey, submissionID) => {
        const row = getTempDocumentRow(app, mortgage, stage, name, destinationFolder, isPrivate, true, submissionID)
        const identityIds = []
        if (!!row.identityID) {
            identityIds.push(row.identityID)
        }
        if (!!row.identityID2) {
            identityIds.push(row.identityID2)
        }
        let destination = getUserDocumentKey(mortgage.id, row.folder, row.fileName, submissionID)
        const targetKeys = identityIds.map(identityID => `private/${identityID}/${destination}`)

        let fieldsFunc = templates[mortgage.activeSubmission.lenderCode][name]
        const data = !!mortgage.data ? JSON.parse(mortgage.data) : {}
        const config = data?.overlayData?.[`${name}.pdf`] || {}

        let dataMap = fieldsFunc(mortgage, config)
        const sectionsMap = dataMap.sections.reduce((acc, section) => {
            return {...acc, ...section.map};
        }, {});
        console.log({sectionsMap})

        const result = await API.post("sussdAdminAPI", "/operations/pdfs/fill", {
            body: {
                bucketKey: sourceBucketKey,
                targetKeys,
                fields: sectionsMap
            }
        })
        if (!result.success) {
            app.notification.error({message: `Failed to create ${row.fileName}`})
            return false
        }

        // 4. If creation was successful, return the temporary document row
        return row
    }
    const generateCopy = async (stage, name, destinationFolder, isPrivate, sourceBucketKey, submissionID) => {
        // 1. Create a temporary document row
        const row = getTempDocumentRow(app, mortgage, stage, name, destinationFolder, isPrivate, false, submissionID)
        if (!row) {
            return false
        }

        // 2. Copy the file to the each user's private folder
        const promises = []

        let destination = getUserDocumentKey(mortgage.id, row.folder, row.fileName, submissionID)

        if (!!row.identityID) {
            promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                body: {
                    source: `private/${SYSTEMIDENTITYID}/${sourceBucketKey}`,
                    destination: `private/${row.identityID}/${destination}`
                }
            }))
        }
        if (!!row.identityID2) {
            promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                body: {
                    source: `private/${SYSTEMIDENTITYID}/${sourceBucketKey}`,
                    destination: `private/${row.identityID2}/${destination}`
                }
            }))
        }
        const result = await Promise.all(promises)

        // 3. Check for failures
        const failed = result.filter(it => !it.success)
        if (failed.length) {
            app.notification.error({message: `Failed to create ${row.fileName}`})
            return false
        }

        // 4. If creation was successful, return the temporary document row
        return row
    }
    const generateOverlay = async (stage, name, destinationFolder, isPrivate, lenderDocId, submissionID) => {
        // 1. Get the mappings for the document
        const data = !!mortgage.data ? JSON.parse(mortgage.data) : {}
        const config = data?.overlayData?.[`${name}.pdf`] || {}
        const dataMap = getMappings(name, mortgage, config)

        const errorsMap = dataMap.sections.reduce((acc, section) => {
            return [...acc, ...section.errors || []];
        }, []);
        if (errorsMap.length) {
            errorsMap.forEach(error => {
                app.notification.error({message: error})
                return false
            })
        }
        const overlayMap = dataMap.sections.reduce((acc, section) => {
            return {...acc, ...section.map};
        }, {});
        const fillMap = dataMap.sections.reduce((acc, section) => {
            return {...acc, ...section.fill};
        }, {});



        // 2. Create a temporary document row
        const row = getTempDocumentRow(app, mortgage, stage, name, destinationFolder, isPrivate, true, submissionID)
        if (!row) {
            return false
        }

        // 3. Generate a s3 document for each identityID
        const identityIds = []
        if (!!row.identityID) {
            identityIds.push(row.identityID)
        }
        if (!!row.identityID2) {
            identityIds.push(row.identityID2)
        }
        let destination = getUserDocumentKey(mortgage.id, row.folder, row.fileName, submissionID)
        const targetKeys = identityIds.map(identityID => `private/${identityID}/${destination}`)
        const result = await API.post("sussdAdminAPI", "/operations/pdfs/overlay", {
            body: {
                id: lenderDocId,
                elements: overlayMap,
                fields: fillMap,
                targetKeys
            }
        })
        if (!result.success) {
            app.notification.error({message: `Failed to create ${row.fileName}`})
            return false
        }

        // 4. If creation was successful, return the temporary document row
        return row
    }
    return {
        generate
    }
}

export default useLenderDocumentGenerator;