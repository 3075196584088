import MortgageForm from "../../../../data-capture/MortgageForm";
import React from "react";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Alert, Card, Table} from "antd";
import {getQuestionsPercentCompleted} from "../../../../../providers/mortgage/useApplicationForms";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {AlertOutlined} from "@ant-design/icons";

function LoanOffer() {
    const mortgage = useMortgage()
    const {open} = useMortgageDocumentsService()
    const questions = mortgage.activeSubmission ? [
        {
            name  : 'loanOfferDate',
            label : 'Loan Offer Date',
            text  : "Loan Offer Date",
            answer: {
                type: "datepicker",
            },
            model : "requirement",
            rules : [],
            target: `submissions[${mortgage.activeSubmission.id}].loanOfferDate`
        },
        {
            name  : 'loanOfferExpiryDate',
            label : 'Loan Offer Expiry Date',
            text  : "Loan Offer Expiry Date",
            answer: {
                type: "datepicker",
            },
            model : "requirement",
            rules : [],
            target: `submissions[${mortgage.activeSubmission.id}].loanOfferExpiryDate`
        },
        {
            name  : 'drawdownDate',
            label : 'Drawdown Date',
            text  : "Drawdown Date",
            answer: {
                type: "datepicker",
            },
            model : "requirement",
            rules : [],
            target: `submissions[${mortgage.activeSubmission.id}].drawdownDate`
        },
        {
            name  : 'finalTerm',
            label : 'Confirmed Term in Years',
            text  : "Final Term",
            answer: {
                type: "int",
            },
            target: `submissions[${mortgage.activeSubmission.id}].finalTerm`
        },
        {
            name  : 'finalRate',
            label : 'Confirmed Rate',
            text  : "Final Rate",
            answer: {
                type: "float",
            },
            target: `submissions[${mortgage.activeSubmission.id}].finalRate`
        },
        {
            name  : 'finalProduct',
            label : 'Confirmed Product',
            text  : "Final Product",
            answer: {
                type: "text",
            },
            target: `submissions[${mortgage.activeSubmission.id}].finalProduct`
        },
        {
            name  : 'finalLoan',
            label : 'Confirmed Loan',
            text  : "Final Loan",
            answer: {
                type: "euro",
            },
            target: `submissions[${mortgage.activeSubmission.id}].finalLoan`
        },
        {
            name  : 'loanOfferNotes',
            label : 'Notes',
            text  : "Notes",
            answer: {
                type: "textarea",
                asArray: true
            },
            target: `submissions[${mortgage.activeSubmission.id}].loanOfferNotes`
        },

    ] : []
    let doc = mortgage.UserMortgageDocuments.find(doc => doc.name === 'loan-offer' && doc.folder === 'lender-documents' && doc.submissionID === mortgage.activeSubmission.id)
    let percent = mortgage.activeSubmission ? getQuestionsPercentCompleted(mortgage, questions) : 0
    if (!mortgage.activeSubmission) return (
        <Alert message={"No active submission"} type="error"/>
    )

    let dataSource = []
    if (!!mortgage.activeSubmission.finalTerm && mortgage.activeSubmission.provisionalTerm !== mortgage.activeSubmission.finalTerm){
        dataSource.push({
            requestedValue: mortgage.activeSubmission.provisionalTerm,
            finalValue: mortgage.activeSubmission.finalTerm,
            name: 'Term',
        })
    }
    if (!!mortgage.activeSubmission.finalRate && mortgage.activeSubmission.provisionalRate !== mortgage.activeSubmission.finalRate){
        dataSource.push({
            requestedValue: mortgage.activeSubmission.provisionalRate,
            finalValue: mortgage.activeSubmission.finalRate,
            name: 'Rate',
        })

    }
    if (!!mortgage.activeSubmission.finalProduct && mortgage.activeSubmission.provisionalProduct !== mortgage.activeSubmission.finalProduct){
        dataSource.push({
            requestedValue: mortgage.activeSubmission.provisionalProduct,
            finalValue: mortgage.activeSubmission.finalProduct,
            name: 'Product',
        })

    }
    if (!!mortgage.activeSubmission.finalLoan && mortgage.activeSubmission.provisionalLoan !== mortgage.activeSubmission.finalLoan){
        dataSource.push({
            requestedValue: mortgage.activeSubmission.provisionalLoan,
            finalValue: mortgage.activeSubmission.finalLoan,
            name: 'Loan',
        })
    }





    return (<Card>
            <div className="d-col gap-15">
                {(() => {
                    switch (mortgage.activeSubmission.loanOfferState) {
                        case 'expiring':
                            return <>

                                <Alert message={"Loan Offer Expiring"} type="warning" showIcon={true} icon={<AlertOutlined/>}/>
                                {/* PUT MODAL HERE */}

                            </>
                        case 'expired':
                            return <>
                                <Alert message={"Loan Offer Expired"} type="error"  showIcon={true} icon={<AlertOutlined/>}/>
                            </>
                        default:
                            return null;
                    }
                })()}
                {!doc && (<Alert message={"Approval in principle not received"} type="warning"/>)}

                {!!doc && (<Alert message={<>
                    Loan offer received
                    <a className="mx-15" onClick={() => open(doc)}>View Document</a>
                </>} type="success"/>)}

                {dataSource.length > 0 && (<Alert type="error" message={<Table rowHoverable={false} size="small" className="transparent-table" dataSource={dataSource} pagination={false} columns={[
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: 'Requested Value',
                        dataIndex: 'requestedValue',
                        key: 'requestedValue',
                    },
                    {
                        title: 'Different Confirmed Value',
                        dataIndex: 'finalValue',
                        key: 'finalValue',
                    },
                ]}/>}/>)}
                {percent === 100 && (<Alert message={"Offer details completed"} type="success"/>)}
                <MortgageForm questions={questions} title="Mortgage Details"/>

            </div>
        </Card>)

}
export default LoanOffer;