import React, {useEffect, useRef, useState} from 'react';
import useMortgage from "./useMortgage";
import {performGQL} from "../../api-functions/functions";
import {
    createAdminMortgageSettings,
    createUserApplicantCurrentAccount,
    createUserApplicantEmploymentIncome,
    createUserApplicantLastHere,
    createUserMortgagePropertyOnMortgage,
    createUserMortgageSolicitor,
    updateUserApplicant,
    updateUserApplicantEmploymentIncome,
    updateUserMortgage,
    updateUserMortgageProgress,
    updateUserMortgagePropertyOnMortgage
} from "../../../graphql/mutations";
import {Button, Modal} from "antd";
import {ApplicationStatuses, ReplyRequestTypes} from "../../../models";
import {v4} from "uuid";

function MortgageV1ToV2(props) {
    // after a couple of months remove this
    const mortgage = useMortgage()
    const [state, setState] = useState({
        fixing: false,
        done  : false
    })
    const fixSettings = async () => {

        let init = {
            mortgageID: mortgage.id,
        }
        const result = await performGQL({input: init}, createAdminMortgageSettings)
        if (result) {
            const input = {
                id                    : mortgage.id,
                userMortgageSettingsId: result.id,
                _version              : mortgage._version
            }
            await performGQL({input}, updateUserMortgage)
        }
    }
    const fixSubjectId = async () => {
        let input = {
            id       : mortgage.applicant1.id,
            subjectId: mortgage.owner,
            _version : mortgage.applicant1._version
        }
        await performGQL({input}, updateUserApplicant)
    }
    const fixProperty = async (m) => {
        // fix intendedUse too on mortgage.mortgageType

        let {
                createdAt,
                updatedAt,
                _lastChangedAt,
                _deleted,
                _version,
                id,
                purchasePrice,
                spendAmount,
                intendedUse,
                purchaseStage,
                owner,
                ...newProperty
            } = mortgage.property
        if (mortgage?.existing) {
            let {
                    createdAt,
                    updatedAt,
                    _lastChangedAt,
                    _deleted,
                    _version,
                    id,
                    owner,
                    propertyId,
                    topUpRequired,
                    topUpPurpose,
                    topUpAmount,
                    ...rest
                } = mortgage.existing
            newProperty = {...newProperty, ...rest}
        }

        newProperty.origPurchasePrice = purchasePrice
        newProperty.readAccessGroup = []
        newProperty.editAccessGroup = [
            `${mortgage.id}-app1`,
            `${mortgage.id}-app2`
        ]
        newProperty.mortgageID = mortgage.id

        function omitNullProperties(sourceObj) {
            return Object.keys(sourceObj)
                .filter(key => sourceObj[key] !== null)
                .reduce((newObj, key) => {
                    newObj[key] = sourceObj[key];
                    return newObj;
                }, {});
        }
        const better = omitNullProperties(newProperty)

        await performGQL({input: better}, createUserMortgagePropertyOnMortgage)
    }
    const fixNoProperty = async (m) => {
        // fix intendedUse too on mortgage.mortgageType

        let newProperty = {}
        newProperty.readAccessGroup = []
        newProperty.editAccessGroup = [
            `${mortgage.id}-app1`,
            `${mortgage.id}-app2`
        ]
        newProperty.mortgageID = mortgage.id

        await performGQL({input: newProperty}, createUserMortgagePropertyOnMortgage)
    }
    const fixPropertyReadAccessGroup = async () => {

        let newProperty = {
            id             : mortgage.properties[0].id,
            editAccessGroup: [
                `${mortgage.id}-app1`,
                `${mortgage.id}-app2`
            ],
            _version       : mortgage.properties[0]._version
        }
        await performGQL({input: newProperty}, updateUserMortgagePropertyOnMortgage)
    }
    const fixApplicationType = async () => {

        if (mortgage.property && !mortgage.applicationType) {
            let val = mortgage.property.intendedUse;
            if (val === 'MOVING') {
                val = 'MOVINGHOME'
            }
            if (val === 'HOLIDAYHOME') {
                val = 'SECONDTIME'
            }
            if (val === 'BUYTOLET') {
                val = 'LANDLORD'
            }
            let input = {
                id             : mortgage.id,
                applicationType: val,
                _version       : mortgage._version
            }
            await performGQL({input}, updateUserMortgage)
        }
    }
    const fixSolicitor = async () => {
        const input = {
            readAccessGroup: [],
            editAccessGroup: [
                `${mortgage.id}-app1`,
                `${mortgage.id}-app2`
            ]
        }
        const result = await performGQL({input}, createUserMortgageSolicitor)

        if (result) {
            const input = {
                id                     : mortgage.id,
                userMortgageSolicitorId: result.id,
                _version               : mortgage._version
            }
            await performGQL({input}, updateUserMortgage)
        }
    }
    const fixMortgageAccountNumber = async () => {
        //console.log('running fixMortgageAccountNumber')
        //console.log(mortgage)
        const ac = mortgage.requirement.mortgageAccountNo
        if (ac && !mortgage.caseNumber) {
            let input = {
                id        : mortgage.id,
                caseNumber: ac,
                _version  : mortgage._version
            }
            await performGQL({input}, updateUserMortgage)
        }
    }
    const fixIdentityID = async () => {
        const upload = mortgage.uploads.find(item => !!item.identityID)
        if (upload) {
            //console.log('should fix identityID')
            let input = {
                id        : mortgage.applicant1.id,
                identityID: upload.identityID,
                _version  : mortgage.applicant1._version
            }
            await performGQL({input}, updateUserApplicant)
        }
    }
    const fixLastHere = async () => {
        if (!mortgage.applicant1.lastHereTime && !!mortgage.applicant1.userApplicantLastHereId) {
            let input = {
                id             : mortgage.applicant1.userApplicantLastHereId,
                editAccessGroup: [`${mortgage.id}-app1`],
            }
            await performGQL({input}, createUserApplicantLastHere)
        }
        if (!mortgage.applicant2.lastHereTime && !!mortgage.applicant2.userApplicantLastHereId) {
            let input = {
                id             : mortgage.applicant2.userApplicantLastHereId,
                editAccessGroup: [`${mortgage.id}-app2`],
            }
            await performGQL({input}, createUserApplicantLastHere)
        }
    }
    const fixNoLastHere = async () => {
        if (!mortgage.applicant1.userApplicantLastHereId) {
            let id = v4()
            let input = {
                id             : v4(),
                editAccessGroup: [`${mortgage.id}-app1`],
            }
            await performGQL({input}, createUserApplicantLastHere)
            await mortgage.mutate({
                update: {
                    target: `applicant1.userApplicantLastHereId`,
                    value : id
                }
            })
        }
        if (!mortgage.applicant2.userApplicantLastHereId) {
            let id = v4()
            let input = {
                id             : v4(),
                editAccessGroup: [`${mortgage.id}-app2`],
            }
            await performGQL({input}, createUserApplicantLastHere)
            await mortgage.mutate({
                update: {
                    target: `applicant2.userApplicantLastHereId`,
                    value : id
                }
            })
        }
    }
    const fixProgressAccessGroup = async () => {
        //console.log('fixing progress access group')
        const progress = mortgage.progress.filter(it => !it.editAccessGroup)
        let promises = []
        for (let i = 0; i < progress.length; i++) {
            const item = progress[i]
            let input = {
                id             : item.id,
                editAccessGroup: [
                    `${mortgage.id}-app1`,
                    `${mortgage.id}-app2`
                ],
                _version       : item._version
            }
            promises.push(performGQL({input}, updateUserMortgageProgress))
        }
        await Promise.all(promises)
    }
    const fixEmplymentIncome = async () => {

        // create or update employment income using data from previously separate employment and income

        const getMutationForApp = (n) => {
            let app = mortgage[`applicant${n}`]
            let employment = app.employment
            let income = app.income
            let pairs = {
                applicantID: app.id,

                employmentType : employment.employmentType,
                employmentBasis: employment.employmentBasis,
                publicSector   : false,

                positionHeld: employment.positionHeld,

                currentEmployerName   : employment.currentEmployerName,
                currentEmployerAddress: employment.currentEmployerAddress,
                currentEmployerPhone  : employment.currentEmployerPhone,
                currentEmployerContact: employment.currentEmployerContact,

                currentEmployerStartDate: employment.currentEmployerStartDate,

                previousEmployerName   : employment.previousEmployerName,
                previousEmployerAddress: employment.previousEmployerAddress,

                nameOfBusiness     : employment.nameOfBusiness,
                natureOfBusiness   : employment.natureOfBusiness,
                businessAddress    : employment.businessAddress,
                accountantName     : employment.accountantName,
                accountantAddress  : employment.accountantAddress,
                accountantPhone    : employment.accountantPhone,
                businessEstablished: employment.businessEstablished,
                limitedCompany     : employment.limitedCompany,
                isDirector         : employment.isDirector,
                percentageOfShares : employment.percentageOfShares,
                selfIncomeSource   : income.selfIncomeSource,

                grossBasic     : income.grossBasic,
                nonEuroCurrency: income.nonEuroCurrency,

                readAccessGroup: [],
                editAccessGroup: [
                    `${mortgage.id}-app1`,
                    `${mortgage.id}-app2`
                ]
            }

            const possibleIncomes = [
                {
                    name : 'bonus',
                    label: 'Bonus',
                    text : 'Enter the value of your Bonus in a year'
                },
                {
                    name : 'commission',
                    label: 'Commission',
                    text : 'Enter your total Commission in a year'
                },
                {
                    name : 'overtime',
                    label: 'Overtime',
                    text : 'Enter your total Overtime in a year'
                },
                {
                    name : 'shiftAllowance',
                    label: 'Shift Allowance',
                    text : 'Enter your total Shift Allowance for a year'
                },
                {
                    name : 'otherAllowance',
                    label: 'Other Allowances',
                    text : 'Enter the total of any other allowance received in a year'
                },
            ]
            let defaultIncomes = possibleIncomes.map(it => ({
                name      : it.name,
                amount    : null,
                guaranteed: false
            }))
            if (!!income.incomeChecks) {
                income.incomeChecks.forEach((check, i) => {
                    switch (check) {
                        case 'BONUS':
                            defaultIncomes[0].amount = income.bonus
                            break
                        case 'COMMISSION':
                            defaultIncomes[1].amount = income.commission
                            break
                        case 'OVERTIME':
                            defaultIncomes[2].amount = income.overtime
                            break
                        case 'OTHER':
                            defaultIncomes[4].amount = income.other
                            break
                    }
                })
                pairs.incomes = JSON.stringify(defaultIncomes)
            }

            if (app.employmentIncome?.length) {
                pairs.id = app.employmentIncome[0].id
                pairs._version = app.employmentIncome[0]._version
                return performGQL({input: pairs}, updateUserApplicantEmploymentIncome)
            }
            return performGQL({input: pairs}, createUserApplicantEmploymentIncome)
        }
        let app1 = getMutationForApp(1)
        let app2 = getMutationForApp(2)
        // let promises = [getMutationForApp(1), getMutationForApp(2)]
        // await Promise.all(promises)
    }
    const fixApplicantAccounts = async () => {
        let promises = []
        if (!mortgage.applicant1.accounts?.length) {
            const input = {
                applicantID    : mortgage.applicant1.id,
                readAccessGroup: [],
                editAccessGroup: [
                    `${mortgage.id}-app1`,
                    `${mortgage.id}-app2`
                ]
            }
            promises.push(performGQL({input}, createUserApplicantCurrentAccount))
        }
        if (!mortgage.applicant2.accounts?.length) {
            const input = {
                applicantID    : mortgage.applicant2.id,
                readAccessGroup: [],
                editAccessGroup: [
                    `${mortgage.id}-app1`,
                    `${mortgage.id}-app2`
                ]
            }
            promises.push(performGQL({input}, createUserApplicantCurrentAccount))
        }
        await Promise.all(promises)

    }
    const fixes = async () => {
        //TODO: add banking
        if (mortgage) {
            if (!mortgage.userMortgageSettingsId) {
                //console.log('should fix settings')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing mortgage settings'
                }))
            }
            else if ((!mortgage.applicant1.lastHereTime && !!mortgage.applicant1.userApplicantLastHereId) || (!mortgage.applicant2.lastHereTime && !!mortgage.applicant2.userApplicantLastHereId)) {
                //console.log('should fix last here')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing last here'
                }))
            }
            else if (!mortgage.applicant1.userApplicantLastHereId || !mortgage.applicant2.userApplicantLastHereId) {
                //console.log('should fix no last here')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing NO last here'
                }))
            }
            else if (!mortgage.settings) {
                //console.log('should fix settings')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Adding mortgage settings'
                }))
            }
            else if (mortgage.applicationStatus !== ApplicationStatuses.OFFLINE && !mortgage.applicant1?.subjectId && !!mortgage.owner && !!mortgage.applicant1._version) { // is registered
                //console.log('should fix subjectId')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing applicant1 subjectId'
                }))
            }
            else if (mortgage.hasOwnProperty('property') && !!mortgage.property && !mortgage.properties.length) {
                //console.log('should fix property')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing property'
                }))
            }
            else if (!mortgage?.applicationType && mortgage.hasOwnProperty('property') && !!mortgage.property && !!mortgage.property.intendedUse) {
                //console.log('should fix applicationType')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing application type'
                }))
            }
            else if (mortgage.properties.length === 0) {
                console.log('should fix no property')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing no property'
                }))
            }
            else if (!mortgage?.userMortgageSolicitorId) {
                //console.log('should fix solicitor')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing solicitor'
                }))
            }
            else if (!!mortgage.requirement.mortgageAccountNo && !mortgage.caseNumber) {
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing mortgage account number'
                }))
            }
            else if (mortgage.applicationStatus !== ApplicationStatuses.OFFLINE && !mortgage?.applicant1.identityID) {
                const upload = mortgage.uploads.find(item => !!item.identityID)
                if (upload) {
                    //console.log('should fix identityID')
                    setState(s => ({
                        ...s,
                        fixing : true,
                        message: 'Fixing identityID'
                    }))
                }
            }
            else if (!mortgage.properties[0].editAccessGroup || !mortgage.properties[0].editAccessGroup.length) {
                //console.log('should fix property read access group')
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing property read access group'
                }))
            }
            else if (mortgage.progress.filter(it => !it.editAccessGroup).length > 0) {
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing progress access group'
                }))
            }
            else if (mortgage.applicationStage === 'SETUP' && (!mortgage.applicant1.employmentIncome || mortgage.applicant1.employmentIncome.length === 0)) {
                // Ready for updating old mortgages
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing employment income'
                }))
            }
            else if (!mortgage.applicant1.accounts?.length || !mortgage.applicant2.accounts?.length) {
                setState(s => ({
                    ...s,
                    fixing : true,
                    message: 'Fixing applicant accounts'
                }))
            }
            else {
                setState(s => ({
                    ...s,
                    done: true
                }))
            }
        }
    }
    const doFix = async () => {
        try {
            switch (state.message) {
                case 'Fixing progress access group':
                    await fixProgressAccessGroup()
                    break;
                case 'Fixing property read access group':
                    await fixPropertyReadAccessGroup()
                    break;
                case 'Fixing NO last here':
                    await fixNoLastHere()
                    break;
                case 'Fixing last here':
                    await fixLastHere()
                    break;
                case 'Fixing no property':
                    console.log('fixing no property')
                    await fixNoProperty()
                    break;
                case 'Fixing identityID':
                    await fixIdentityID()
                    break
                case 'Fixing mortgage account number':
                    await fixMortgageAccountNumber()
                    break
                case 'Fixing mortgage settings':
                    await fixSettings();
                    break;
                case 'Fixing applicant1 subjectId':
                    await fixSubjectId();
                    break;
                case 'Fixing property':
                    await fixProperty();
                    break;
                case 'Fixing application type':
                    await fixApplicationType();
                    break;
                case 'Fixing solicitor':
                    await fixSolicitor();
                    break;
                case 'Fixing employment income':
                    await fixEmplymentIncome()
                    break;
                case 'Adding mortgage settings':
                    await fixSettings();
                    break;
                case 'Fixing applicant accounts':
                    await fixApplicantAccounts()
                    break
                default:
            }
            setState(s => ({
                ...s,
                done: true
            }))
        }
        catch (e) {
            //console.log(e.message)
        }
    }
    let prevObj = useRef(null)
    function getDifferences(obj1, obj2) {
        const differences = {};

        for (const key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                if (JSON.stringify(obj2[key]) !== JSON.stringify(obj1[key])) {
                    differences[key] = JSON.stringify(obj2[key]) + '\n\n' + JSON.stringify(obj1[key])
                }
            }
        }

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
                differences[key] = obj2[key];
            }
        }

        return differences;
    }

    useEffect(() => {
        if (!state.fixing && !!mortgage) {
            fixes()
        }
    }, []);

    useEffect(() => {
        doFix()
    }, [state.message]);

    let objects = {}
    const scanner = (object, keyName) => {
        if (Array.isArray(object)) {
            object.forEach((it, i) => scanner(it, keyName + `[${i}]`))
        }
        else {
            if (typeof object === 'object') {
                if (object.hasOwnProperty('editAccessGroup')) {
                    objects[keyName] = {
                        id             : object.id,
                        editAccessGroup: object.editAccessGroup,
                        readAccessGroup: object.readAccessGroup
                    }
                }
                for (let key in object) {
                    if (!!object[key]) {
                        scanner(object[key], `${keyName}.${key}`)
                    }
                }
            }

        }
    }
    // scanner( mortgage, 'mortgage')
    // console.log({objects})
    // Object.keys(objects).forEach(key=>{
    //     if (!objects[key].editAccessGroup.includes('880dafd7-3d75-4a60-98f4-992697ccaa26-app1')){
    //         console.log({[key]:objects[key]})
    //     }
    // })

    // REPAIRS TO ACTIVE SUBMISSION
    const activeSubmission = mortgage.activeSubmission
    const submissions = mortgage.submissions
    const repairActiveSubmission = async () => {
        const update = []
        const create = []
        // if (mortgage.requirement.lenderCode) {
        //     if (!activeSubmission || (submissions.length === 1 && !activeSubmission.aipDate && !!mortgage.requirement.aipDate)) {
        //         const pairs = {}
        //         const submissionID = activeSubmission ? activeSubmission.id : v4()
        //         pairs.lenderCode = mortgage.requirement.lenderCode
        //         if (mortgage.requirement.lenderProduct) {
        //             pairs.provisionalProduct = mortgage.requirement.lenderProduct
        //         }
        //         if (mortgage.requirement.rateId) {
        //             pairs.provisionalRateId = mortgage.requirement.rateId
        //         }
        //         if (mortgage.requirement.rate) {
        //             pairs.provisionalRate = mortgage.requirement.rate
        //         }
        //         if (mortgage.requirement.mortgageAccountNo) {
        //             pairs.mortgageAccountNo = mortgage.requirement.mortgageAccountNo
        //         }
        //         if (mortgage.requirement.loanTerm) {
        //             pairs.provisionalTerm = mortgage.requirement.loanTerm
        //         }
        //         if (mortgage.requirement.loanRequired) {
        //             pairs.provisionalLoan = mortgage.requirement.loanRequired
        //         }
        //         if (mortgage.properties[0].propertyValue) {
        //             pairs.provisionalValue = mortgage.properties[0].propertyValue
        //         }
        //         if (mortgage.requirement.soft) {
        //             pairs.soft = mortgage.requirement.soft
        //         }
        //         if (mortgage.requirement.propertyReference) {
        //             pairs.aipPropertyReference = mortgage.requirement.propertyReference
        //         }
        //         if (mortgage.requirement.aipDate) {
        //             pairs.aipDate = mortgage.requirement.aipDate
        //         }
        //         if (mortgage.requirement.aipExpiryDate) {
        //             pairs.aipExpiryDate = mortgage.requirement.aipExpiryDate
        //         }
        //         // get most recent AIP request
        //         let aipRequests = mortgage.replyRequests.filter(a => a.requestType = ReplyRequestTypes.PREAPPROVAL).sort((a, b) => {
        //             return new Date(b.receivedOn) - new Date(a.receivedOn)
        //         })
        //         let aipRequest = aipRequests.length ? aipRequests[0] : null
        //         if (aipRequest) {
        //             pairs.aipEmailId = aipRequest.emailId
        //             pairs.aipNotes = aipRequest.noteBody.join('\n')
        //         }
        //         if (mortgage.requirement.loanOfferDate) {
        //             pairs.loanOfferDate = mortgage.requirement.loanOfferDate
        //         }
        //         if (mortgage.requirement.loanOfferExpiryDate) {
        //             pairs.loanOfferExpiryDate = mortgage.requirement.loanOfferExpiryDate
        //         }
        //         if (mortgage.requirement.drawdownDate) {
        //             pairs.drawdownDate = mortgage.requirement.drawdownDate
        //         }
        //         let loanOfferRequests = mortgage.replyRequests.filter(a => a.requestType = ReplyRequestTypes.APPROVAL).sort((a, b) => {
        //             return new Date(b.receivedOn) - new Date(a.receivedOn)
        //         })
        //         let loanOfferRequest = loanOfferRequests.length ? loanOfferRequests[0] : null
        //         if (loanOfferRequest) {
        //             pairs.loanOfferEmailId = loanOfferRequest.emailId
        //             pairs.loanOfferNotes = loanOfferRequest.noteBody.join('\n')
        //         }
        //
        //         //completedFlowItems
        //         //completedFlowItemsForAdmin
        //         if (mortgage.properties[0].reinstatementValue) {
        //             pairs.reinstatementValue = mortgage.properties[0].reinstatementValue
        //         }
        //         if (mortgage.properties[0].valuationValue) {
        //             pairs.valuationValue = mortgage.properties[0].valuationValue
        //         }
        //         if (mortgage.properties[0].valuationInstructed) {
        //             pairs.valuationInstructed = mortgage.properties[0].valuationInstructed
        //         }
        //         if (mortgage.properties[0].valuationDate) {
        //             pairs.valuationDate = mortgage.properties[0].valuationDate
        //         }
        //         if (mortgage.properties[0].valuationExpiryDate) {
        //             pairs.valuationExpiryDate = mortgage.properties[0].valuationExpiryDate
        //         }
        //         if (mortgage.properties[0].anotherValuationNeeded) {
        //             pairs.anotherValuationNeeded = mortgage.properties[0].anotherValuationNeeded
        //         }
        //         if ([
        //             true,
        //             false
        //         ].includes(mortgage.properties[0].hasValuationIssues))
        //         {
        //             pairs.hasValuationIssues = mortgage.properties[0].hasValuationIssues
        //         }
        //         if (mortgage.properties[0].valuationIssues) {
        //             pairs.valuationIssues = mortgage.properties[0].valuationIssues
        //         }
        //         if ([
        //             true,
        //             false
        //         ].includes(mortgage.properties[0].surveyRequired))
        //         {
        //             pairs.surveyRequired = mortgage.properties[0].surveyRequired
        //         }
        //         if (mortgage.properties[0].surveyDate) {
        //             pairs.surveyDate = mortgage.properties[0].surveyDate
        //         }
        //         let userData = {}
        //         let adminData = {}
        //
        //         let old_createdApplicationCustomerDocuments = mortgage.stageTasks.find(a => a.name === 'createApplyCustomerDocuments')
        //         if (old_createdApplicationCustomerDocuments) {
        //             adminData.createdApplicationCustomerDocuments = {
        //                 on: old_createdApplicationCustomerDocuments.completedOn,
        //                 by: old_createdApplicationCustomerDocuments.completedBy
        //             }
        //         }
        //         let old_createdApplicationAdminDocuments = mortgage.stageTasks.find(a => a.name === 'createApplyAdminDocuments')
        //         if (old_createdApplicationAdminDocuments) {
        //             adminData.createdApplicationAdminDocuments = {
        //                 on: old_createdApplicationAdminDocuments.completedOn,
        //                 by: old_createdApplicationAdminDocuments.completedBy
        //             }
        //         }
        //         let old_packagedApplication = mortgage.stageTasks.find(a => a.name === 'packageApplication.button.packaged')
        //         if (old_packagedApplication) {
        //             adminData.packagedApplication = {
        //                 on: old_packagedApplication.completedOn,
        //                 by: old_packagedApplication.completedBy
        //             }
        //         }
        //         let old_submittedApplicationToBroker = mortgage.stageTasks.find(a => a.name === 'submitToBrokerAssist.button.submitted')
        //         if (old_submittedApplicationToBroker) {
        //             adminData.submittedApplicationToBroker = {
        //                 on: old_submittedApplicationToBroker.completedOn,
        //                 by: old_submittedApplicationToBroker.completedBy
        //             }
        //         }
        //         let old_edBrokerAcceptedApplication = mortgage.stageTasks.find(a => a.name === 'BrokerAssistAccepted.button.accepted')
        //         if (old_edBrokerAcceptedApplication) {
        //             adminData.brokerAcceptedApplication = {
        //                 on: old_edBrokerAcceptedApplication.completedOn,
        //                 by: old_edBrokerAcceptedApplication.completedBy
        //             }
        //         }
        //         let old_edLenderReceivedApplication = mortgage.stageTasks.find(a => a.name === 'havenReceivedApplication.button.receivedByLender')
        //         if (old_edLenderReceivedApplication) {
        //             adminData.lenderReceivedApplication = {
        //                 on: old_edLenderReceivedApplication.completedOn,
        //                 by: old_edLenderReceivedApplication.completedBy
        //             }
        //         }
        //         let old_LenderAcceptedApplication = mortgage.stageTasks.find(a => a.name === 'havenAcceptedApplication.button.acceptedByLender')
        //         if (old_LenderAcceptedApplication) {
        //             adminData.lenderAcceptedApplication = {
        //                 on: old_LenderAcceptedApplication.completedOn,
        //                 by: old_LenderAcceptedApplication.completedBy
        //             }
        //         }
        //         pairs.completedFlowItems = JSON.stringify(userData)
        //         pairs.completedFlowItemsForAdmin = JSON.stringify(adminData)
        //         if (!activeSubmission) {
        //             console.warn('!!!!NO ACTIVE SUBMISSION!!!!!!')
        //             pairs.id = submissionID
        //             pairs.mortgageID = mortgage.id
        //             create.push({
        //                 target: 'submissions',
        //                 pairs
        //             })
        //         }
        //         else {
        //             update.push({
        //                 target: `submissions[${submissionID}]`,
        //                 pairs
        //             })
        //         }
        //         mortgage.UserMortgageDocuments.forEach(doc => {
        //             if (doc.folder !== 'sussd-documents') {
        //                 update.push({
        //                     target: `UserMortgageDocuments[${doc.id}].submissionID`,
        //                     value : submissionID
        //                 })
        //             }
        //         })
        //         console.log({
        //             mutate: {
        //                 create,
        //                 update
        //             }
        //         })
        //
        //     }
        // }
        // if (activeSubmission && submissions.length === 1) {
        //     if (!mortgage.requirement.lenderCode && activeSubmission.lenderCode) {
        //         update.push({
        //             target: `requirement.lenderCode`,
        //             value : activeSubmission.lenderCode
        //         })
        //     }
        //     if (!mortgage.properties[0].reinstatementValue && activeSubmission.reinstatementValue) {
        //         update.push({
        //             target: `properties[${mortgage.properties[0].id}].reinstatementValue`,
        //             value : activeSubmission.reinstatementValue
        //         })
        //     }
        //     if (!mortgage.properties[0].valuationInstructed && activeSubmission.valuationInstructed) {
        //         update.push({
        //             target: `properties[${mortgage.properties[0].id}].valuationInstructed`,
        //             value : activeSubmission.valuationInstructed
        //         })
        //     }
        //     if (!mortgage.requirement.soft && activeSubmission.soft) {
        //         update.push({
        //             target: `requirement.soft`,
        //             value : activeSubmission.soft
        //         })
        //     }
        //     if (!!mortgage.requirement.soft && !activeSubmission.soft) {
        //         update.push({
        //             target: `submissions[${activeSubmission.id}].soft`,
        //             value : mortgage.requirement.soft
        //         })
        //     }
        //     if (mortgage.requirement.mortgageAccountNo && !activeSubmission.mortgageAccountNo) {
        //         update.push({
        //             target: `submissions[${activeSubmission.id}].mortgageAccountNo`,
        //             value : mortgage.requirement.mortgageAccountNo
        //         })
        //     }
        //     if (mortgage.requirement.lenderProduct && !activeSubmission.provisionalProduct) {
        //         update.push({
        //             target: `submissions[${activeSubmission.id}].provisionalProduct`,
        //             value : mortgage.requirement.lenderProduct
        //         })
        //     }
        //     if (mortgage.requirement.rateId && !activeSubmission.provisionalRateId) {
        //         update.push({
        //             target: `submissions[${activeSubmission.id}].provisionalRateId`,
        //             value : mortgage.requirement.rateId
        //         })
        //     }
        // }
        if (!activeSubmission){
                console.warn('NO ACTIVE SUBMISSION')
            create.push({
                target: 'submissions',
                pairs : {
                    id: v4(),
                    mortgageID: mortgage.id,
                    lenderCode: mortgage.requirement.lenderCode || null
                }
            })
        }
        // CAN REMOVE THE FOLLOWNG BLOCK AFTER Sep 2025
        mortgage.mortgageUploads.apply.forEach(section => {
            if (section.record && section.data) {
                if (section.record.isNewAccount && !section.data.isNewAccount) {
                    let app = section.applicant
                    let type = section.data.typeName
                    let id = section.data.id

                    update.push({
                        target: `applicant${app}.${type}[${id}].isNewAccount`,
                        value : true
                    })
                    console.log('UPDATING NEW ACCOUNT', {
                        update,
                        section
                    })
                }
            }
        })
        return {
            create,
            update
        }

    }

    let setupLength = mortgage.mortgageUploads.setup.length
    let completedSetup = mortgage.mortgageUploads.setup.filter(a => !!a?.record?.sectionChecked).length
    let setupPercent = Math.round((completedSetup / setupLength) * 100)

    let applyLength = mortgage.mortgageUploads.apply.length
    let completedApply = mortgage.mortgageUploads.apply.filter(a => !!a?.record?.sectionChecked).length
    let applyPercent = Math.round((completedApply / applyLength) * 100)

    const addUploadsPercentIfDifferent = async () => {
        const update = []
        const create = []
        // UPDATE SETUP UPLOADS PERCENT


        if (!isNaN(setupPercent) && setupPercent !== mortgage.uploadsPercentSetup) {
            update.push({
                target: `uploadsPercentSetup`,
                value : setupPercent
            })
        }

        // UPDATE APPLY UPLOADS PERCENT

        if (!isNaN(applyPercent) && applyPercent !== mortgage.uploadsPercentApply) {
            update.push({
                target: `uploadsPercentApply`,
                value : applyPercent
            })
        }

        return {
            create,
            update
        }
    }

    const timerRef = React.useRef(null)
    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(async () => {
            let promises = [
                repairActiveSubmission(),
                addUploadsPercentIfDifferent(),
            ]
            let results = await Promise.all(promises)

            let {
                    create: c1,
                    update: u1
                } = results[0]
            let {
                    create: c2,
                    update: u2
                } = results[1]
            const create = []
            const update = []
            if (!!c1?.length) {
                create.push(...c1)
            }
            if (!!u1?.length) {
                update.push(...u1)
            }
            if (!!c2?.length) {
                create.push(...c2)
            }
            if (!!u2?.length) {
                update.push(...u2)
            }
            console.log({creating: create, updating: update})
            if (create.length || update.length) {
                await mortgage.mutate({
                    create,
                    update
                })
            }
        }, 2000)
        return () => {
            clearTimeout(timerRef.current)
        }
    }, [applyPercent,setupPercent]);

    return <>


        {!!state.fixing && (<Modal open={true} closable={false}
                                   footer={state.done ? <Button onClick={() => window.location.reload()}>Reload</Button> : null}
                                   title={'Updating mortgage'}>
            {state.message}
        </Modal>)}
        {(!!state.done && !state.fixing) && props.children}
    </>
}

export default MortgageV1ToV2;