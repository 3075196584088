import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import createOfflineMortgage, {createSecondMortgage} from "../../api-functions/mortgage/createOfflineMortgage";
import {Spin} from "antd";
import useMortgage from "../../providers/mortgage/useMortgage";

function CreateSecondMortgage(props) {
    const mortgage = useMortgage()
    const navigate = useNavigate()

    const handelSecondMortgage = async ()=>{
        const id = await createSecondMortgage(mortgage)
        navigate(`/client/${id}`)
    }

    useEffect(() => {
        handelSecondMortgage(mortgage)
    }, []);

    return (
        <div className="d-row a-center j-center" style={{height:'100vh'}}>
            <Spin size="large"/>
        </div>
    );
}

export default CreateSecondMortgage;