import {
    ApplicationTypes,
    MortgageTypes, PersonalAccommodationSituations,
    PersonalMaritalStatuses
} from "../../../../../models";
import dayjs from "dayjs";
import {convertHtmlToPlainText} from "../../../string-functions";

export default function (mortgage, config) {

    const page1 = () => {
        let map = {}
        let ask = []

        // home loan description
        ask.push({
            label: 'Home Loan Description',
            name: 'homeLoanDescription',
            type: 'TEXT',
            group: 'Page 1 - Mortgage Details'
        })
        let app1DOB = dayjs(mortgage.applicant1.personal.dateOfBirth, "YYYY-MM-DD")
        let app1Age = dayjs().diff(app1DOB, 'years')
        let finalString = `${mortgage.applicant1.fullName} (${app1Age})`
        if (mortgage.twoApplicants) {
            let app2DOB = dayjs(mortgage.applicant2.personal.dateOfBirth, "YYYY-MM-DD")
            let app2Age = dayjs().diff(app2DOB, 'years')
            finalString += `& ${mortgage.applicant2.fullName} (${app2Age})`
        }

        map['Text Field 74'] = config.hasOwnProperty('homeLoanDescription') ? {string: config.homeLoanDescription} : {string: finalString}

        // first time buyer
        map['Check Box 11'] = {check: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME}

        // second time
        map['Check Box 12'] = {check: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType !== ApplicationTypes.FIRSTTIME}

        // straight switcher
        map['Check Box 13'] = {check: mortgage.mortgageType === MortgageTypes.REFINANCE}

        // switcher with equity release
        map['Check Box 14'] = {check: false}

        // personal circumstances
        ask.push({
            label: 'Personal Circumstances',
            name: 'personalCircumstances',
            type: 'TEXT',
            group: 'Page 1 - Marital Status'
        })

        function getPersonalCircumstances(){
            if (mortgage.twoApplicants){
                if ([PersonalMaritalStatuses.MARRIED, PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus) && [PersonalMaritalStatuses.MARRIED, PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)) {
                   return 'Married'
                }
                if (mortgage.requirement.cohabiting){
                    return 'Cohabiting'
                }
            }
            else{
                if ([PersonalMaritalStatuses.MARRIED, PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus)) {
                    return 'Married'
                }
            }
            return ''
        }

        map['Text Field 1']  = config.hasOwnProperty('personalCircumstances') ? {string: config.personalCircumstances} : {string: getPersonalCircumstances()}

        // marital status - married
        ask.push({
            label: 'Married',
            name: 'maritalStatusMarried',
            type: 'CHECK',
            group: 'Page 1 - Marital Status'
        })
        let anybodyMarried = [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus)
        if (!anybodyMarried && mortgage.twoApplicants) {
            anybodyMarried = [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)
        }
        map['Check Box 15'] = config.hasOwnProperty('maritalStatusMarried') ? {check: config.maritalStatusMarried} : {check: anybodyMarried}

        // marital status - single
        ask.push({
            label: 'Single',
            name: 'maritalStatusSingle',
            type: 'CHECK',
            group: 'Page 1 - Marital Status'
        })
        let anybodySingle = [PersonalMaritalStatuses.SINGLE].includes(mortgage.applicant1.personal.maritalStatus)
        if (!anybodySingle && mortgage.twoApplicants) {
            anybodySingle = [PersonalMaritalStatuses.SINGLE].includes(mortgage.applicant2.personal.maritalStatus)
        }
        map['Check Box 16'] = config.hasOwnProperty('maritalStatusSingle') ? {check: config.maritalStatusSingle} : {check: anybodySingle}


        // marital status - separated
        ask.push({
            label: 'Separated',
            name: 'maritalStatusSeparated',
            type: 'CHECK',
            group: 'Page 1 - Marital Status'
        })
        let anybodySeparated = [PersonalMaritalStatuses.SEPARATED].includes(mortgage.applicant1.personal.maritalStatus)
        if (!anybodySeparated && mortgage.twoApplicants) {
            anybodySeparated = [PersonalMaritalStatuses.SEPARATED].includes(mortgage.applicant2.personal.maritalStatus)
        }
        map['Check Box 18'] = config.hasOwnProperty('maritalStatusSeparated') ? {check: config.maritalStatusSeparated} : {check: anybodySeparated}

        // marital status - other
        ask.push({
            label: 'Other',
            name: 'maritalStatusOther',
            type: 'CHECK',
            group: 'Page 1 - Marital Status'
        })
        let otherDescription = ''
        let anybodyOther = ![PersonalMaritalStatuses.SEPARATED,PersonalMaritalStatuses.SINGLE,PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus)
        if (anybodyOther){
            otherDescription = mortgage.applicant1.personal.maritalStatus
        }
        if (!anybodyOther && mortgage.twoApplicants) {
            anybodyOther = ![PersonalMaritalStatuses.SEPARATED,PersonalMaritalStatuses.SINGLE,PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)
            if (anybodyOther) {
                otherDescription = mortgage.applicant2.personal.maritalStatus
            }
        }
        map['Check Box 19'] = config.hasOwnProperty('maritalStatusOther') ? {check: config.maritalStatusOther} : {check: anybodyOther}

        // marital status other description
        ask.push({
            label: 'Marital Status Other Description',
            name: 'maritalStatusOtherDescription',
            type: 'TEXT',
            group: 'Page 1 - Marital Status'
        })
        map['Text Field 75'] = config.hasOwnProperty('maritalStatusOtherDescription') ? {string: config.maritalStatusOtherDescription} : {string: otherDescription}

        // residential status
        let anybodyRenting = mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RENTED
        if (!anybodyRenting && mortgage.twoApplicants) {
            anybodyRenting = mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RENTED
        }
        map['Check Box 21'] = {check: anybodyRenting}

        let anybodyOwns = mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.OWNED
        if (!anybodyOwns && mortgage.twoApplicants) {
            anybodyOwns = mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.OWNED
        }
        map['Check Box 22'] = {check: anybodyOwns}

        let anyoneWithRelatives = mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES
        if (!anyoneWithRelatives && mortgage.twoApplicants) {
            anyoneWithRelatives = mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES
        }
        map['Check Box 23'] = {check: anyoneWithRelatives}

        let anyoneOtherText = false
        let anyoneOther = [PersonalAccommodationSituations.OTHER].includes(mortgage.applicant1.personal.accommodationSituation)
        if (anyoneOther){
            anyoneOtherText = mortgage.applicant1.personal.accommodationSituationOther
        }
        if( !anyoneOther && mortgage.twoApplicants){
            anyoneOther = [PersonalAccommodationSituations.OTHER].includes(mortgage.applicant2.personal.accommodationSituation)
            if (anyoneOther){
                anyoneOtherText = mortgage.applicant2.personal.accommodationSituationOther
            }
        }
        map['Check Box 24'] = {check: anyoneOther}
        if (anyoneOtherText){
            map['Text Field 79'] = {string: anyoneOtherText}
        }

        // dependants
        let existing1 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'dependants')
        console.log({existing1})
        if (existing1) {
            map['Text Field 76'] = {string: convertHtmlToPlainText(existing1.html)}
        }

        // outlined proposal
        let existing2 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'outlinedProposal')
        if (existing2) {
            map['Text Field 2016'] = {string: convertHtmlToPlainText(existing2.html)}
        }

        // purchase price
        map['Text Field 208'] = {string: mortgage.properties[0].propertyValue.toString()}

        // loan amount required
        map['Text Field 207'] = {string: mortgage.requirement.loanRequired.toString()}

        // terms required years
        ask.push({
            label: 'Terms Required/Years',
            name: 'termRequiredYears',
            type: 'TEXT',
            group: 'Page 1 - Product Details'
        })
        console.log({config})
        map['Text Field 209'] = {string: config.hasOwnProperty('termRequiredYears') ? config.termRequiredYears.toString() :  mortgage.requirement.loanTerm.toString()}

        //terms required months
        ask.push({
            label: 'Term Required/Months',
            name: 'termRequiredMonths',
            type: 'TEXT',
            group: 'Page 1 - Product Details'
        })
        map['Text Field 2014'] = {string: config.hasOwnProperty('termRequiredMonths') ? config.termRequiredMonths.toString() :  '0'}

        // fixed rate 3 years
        ask.push({
            label: 'Fixed Rate/3 Years',
            name: 'fixedRate3Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        console.log({config})
        map['Check Box 30'] = {check: config.hasOwnProperty('fixedRate3Years') ? config.fixedRate3Years : false}

        // fixed rate 4 years
        ask.push({
            label: 'Fixed Rate/4 Years',
            name: 'fixedRate4Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 31'] = {check: config.hasOwnProperty('fixedRate4Years') ? config.fixedRate4Years : false}

        // fixed rate 5 years
        ask.push({
            label: 'Fixed Rate/5 Years',
            name: 'fixedRate5Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 32'] = {check: config.hasOwnProperty('fixedRate5Years') ? config.fixedRate5Years : false}

        // fixed rate 7 years
        ask.push({
            label: 'Fixed Rate/7 Years',
            name: 'fixedRate7Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 33'] = {check: config.hasOwnProperty('fixedRate7Years') ? config.fixedRate7Years : false}

        // fixed rate 10 years
        ask.push({
            label: 'Fixed Rate/10 Years',
            name: 'fixedRate10Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 34'] = {check: config.hasOwnProperty('fixedRate10Years') ? config.fixedRate10Years : false}

        // one mortgage 15 years
        ask.push({
            label: 'One Mortgage/15 Years',
            name: 'oneMortgage15Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 38'] = {check: config.hasOwnProperty('oneMortgage15Years') ? config.oneMortgage15Years : false}

        // one mortgage 20 years
        ask.push({
            label: 'One Mortgage/20 Years',
            name: 'oneMortgage20Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 42'] = {check: config.hasOwnProperty('oneMortgage20Years') ? config.oneMortgage20Years : false}

        // one mortgage 25 years
        ask.push({
            label: 'One Mortgage/25 Years',
            name: 'oneMortgage25Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 37'] = {check: config.hasOwnProperty('oneMortgage25Years') ? config.oneMortgage25Years : false}

        // one mortgage 30 years
        ask.push({
            label: 'One Mortgage/30 Years',
            name: 'oneMortgage30Years',
            type: 'CHECK',
            group: 'Page 1 - Product Details'
        })
        map['Check Box 36'] = {check: config.hasOwnProperty('oneMortgage30Years') ? config.oneMortgage30Years : false}

        // ltv
        map['Text Field 2015'] = {string:`${((mortgage.requirement.loanRequired / mortgage.properties[0].propertyValue)* 100).toFixed(2)}%`}

        // property over 100 years old
        ask.push({
            label: 'Is the property over 100 years old: Yes',
            name: 'propertyOver100YearsOldYes',
            type: 'CHECK',
            group: 'Page 1 - Property Details'
        })
        map['Check Box 40'] = {check: config.hasOwnProperty('propertyOver100YearsOldYes') ? config.propertyOver100YearsOldYes : false}
        map['Check Box 41'] = {check: config.hasOwnProperty('propertyOver100YearsOldYes') ? !config.propertyOver100YearsOldYes : true}

        console.log({map})
        return {map, ask}
    }
    const page2 = () => {
        let ask=[]
        let map = {}
        let existing1 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'employmentDetails')
        if (existing1) {
            map['Text Field 78'] = {string: convertHtmlToPlainText(existing1.html)}
        }

        let existing2 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'grossIncomeDetails')
        if (existing2) {
            map['Text Field 180'] = {string: convertHtmlToPlainText(existing2.html)}
        }

        ask.push({
            label: 'Banking-number of unpaids/referrals',
            name: 'bankingNumberUnpaids',
            type: 'TEXT',
            group: 'Page 2'
        })
        map['Text Field 173'] = {string: config.hasOwnProperty('bankingNumberUnpaids') ? config.bankingNumberUnpaids : '0'}


        let existing3 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'accountExplanations')
        if (existing3) {
            map['Text Field 189'] = {string: convertHtmlToPlainText(existing3.html)}
        }

        ask.push({
            label: 'Are there any Social Welfare payments?',
            name: 'socialWelfarePaymentsYes',
            type: 'CHECK',
            group: 'Page 2'
        })
        map['Check Box 25'] = {check: config.hasOwnProperty('socialWelfarePaymentsYes') ? config.socialWelfarePaymentsYes : false}
        map['Check Box 26'] = {check: config.hasOwnProperty('socialWelfarePaymentsYes') ? !config.socialWelfarePaymentsYes : true}

        let existing4 = mortgage.docContents.find(it => it.document === 'avantCoverLetter' && it.paragraph === 'existingDebtCommitments')
        if (existing4) {
            map['Text Field 182'] = {string: convertHtmlToPlainText(existing4.html)}
        }

        return {map, ask}
    }
    const page3 = () => {
        let map = {}
        let ask = []

        ask.push({
            label: 'Repayment on proposed facility',
            name: 'repaymentOnProposedFacility',
            type: 'TEXT',
            group: 'Page 3'
        })
        map['Text Field 190'] = {string: config.hasOwnProperty('repaymentOnProposedFacility') ? config.repaymentOnProposedFacility : '0'}

        ask.push({
            label: 'Repayment Ability',
            name: 'repaymentAbility',
            type: 'TEXT',
            group: 'Page 3'
        })
        map['Text Field 192'] = {string: config.hasOwnProperty('repaymentAbility') ? config.repaymentAbility : '0'}


        let existing1 = mortgage.docContents.find(it=>it.document === 'avantCoverLetter' && it.paragraph === 'repaymentCapacity')
        if (existing1){
            map['Text Field 193'] = {string: convertHtmlToPlainText(existing1.html)}
        }

        ask.push({
            label: 'Balance of funds required',
            name: 'balanceOfFundsRequired',
            type: 'TEXT',
            group: 'Page 3'
        })
        map['Text Field 194'] = {string: config.hasOwnProperty('balanceOfFundsRequired') ? config.balanceOfFundsRequired : '0'}

        ask.push({
            label: 'Total Balance of funds on file',
            name: 'totalBalanceOfFundsOnFile',
            type: 'TEXT',
            group: 'Page 3'
        })
        map['Text Field 195'] = {string: config.hasOwnProperty('totalBalanceOfFundsOnFile') ? config.totalBalanceOfFundsOnFile : '0'}

        ask.push({
            label: 'Source of remaining balance of funds',
            name: 'sourceOfRemainingBalanceOfFunds',
            type: 'TEXT',
            group: 'Page 3'
        })
        map['Text Field 196'] = {string: config.hasOwnProperty('sourceOfRemainingBalanceOfFunds') ? config.sourceOfRemainingBalanceOfFunds : '0'}


        let existing2 = mortgage.docContents.find(it=>it.document === 'avantCoverLetter' && it.paragraph === 'proposedSecurityDetails')
        if (existing2){
            map['Text Field 205'] = {string: convertHtmlToPlainText(existing2.html)}
        }

        let existing3 = mortgage.docContents.find(it=>it.document === 'avantCoverLetter' && it.paragraph === 'investmentProperties')
        if (existing3){
            map['Text Field 206'] = {string: convertHtmlToPlainText(existing3.html)}
        }

        let existing4 = mortgage.docContents.find(it=>it.document === 'avantCoverLetter' && it.paragraph === 'recommendation')
        if (existing4){
            map['Text Field 2010'] = {string: convertHtmlToPlainText(existing4.html)}
        }

        map['Text Field 2011'] = {string:'Kevin Coakley'}
        map['Text Field 2012'] = {string:'Truss Mortgages Ltd T/A Sussd Mortgages'}
        map['Text Field 2013'] = {string:'01 685-4888\nkevin@sussd.ie'}

        return {ask, map}
    }

    return {
        sections: [
            page1(),
            page2(),
            page3()
        ]
    }
}

function old(mortgage){
    let  map={}

    map['Text Field 74'] = {string: mortgage.applicant1.fullName} // Home loan application app1 and app2 names and age
    map['Check Box 11']
        = {check: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME}// FTB
    map['Check Box 12']
        = {check: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType !== ApplicationTypes.FIRSTTIME}
    map['Check Box 13'] = {check: mortgage.mortgageType === MortgageTypes.REFINANCE} // Switcher
    map['Check Box 14'] = {check: false} // Switch with Equity Release
    map['Text Field 1'] = {} // Personal Circumstance
    map['Check Box 15'] = {} // Married
    map['Check Box 16'] = {} // Single
    map['Check Box 17'] = {} // Divorced
    map['Check Box 18'] = {} // Separated
    map['Check Box 19'] = {} // Other
    map['Text Field 75'] = {} // Personal Circumstance Other Description
    map['Check Box 21'] = {} // Residental Status Renting
    map['Check Box 22'] = {} // Residental Status Home Owner
    map['Check Box 23'] = {} // Residental Status Lives with Relatives
    map['Check Box 24'] = {} // Residental Status Other
    map['Text Field 79'] = {} // Residental Status Other Description

    map['Text Field 2016'] = {} // Outlined proposal
    map['Text Field 208'] = {} // Number of dependents, ages and childccare arrangements ????????
    map['Text Field 207'] = {} // Loan amount required
    map['Text Field 209'] = {} // Terms Required/Years
    map['Text Field 2014'] = {} // Term Required/Months
    map['Check Box 30'] = {} // Fixed Rate/3 Years
    map['Check Box 31'] = {} //  Fixed Rate/4 Years
    map['Check Box 32'] = {} //  Fixed Rate/5 Years
    map['Check Box 33'] = {} //  Fixed Rate/7 Years
    map['Check Box 34'] = {} //  Fixed Rate/10 Years
    map['Check Box 38'] = {} // One Mortgage/15 Years
    map['Check Box 42'] = {} // One Mortgage/20 Years
    map['Check Box 37'] = {} // One Mortgage/25 Years
    map['Check Box 36'] = {} // One Mortgage/30 Years
    map['Text Field 2015'] = {} // LTV
    map['Check Box 40'] = {} // Is the property over 100 years old: Yes
    map['Check Box  41'] = {} // Is the property over 100 years old: No
    map['Text Field 78'] = {} // Employment details
    map['Text Field 180'] = {} // Gross income details
    map['Text Field 173'] = {} // Banking-number of unpaids/referrals
    map['Text Field 189'] = {} // Explanation of any out of course/Large lodgements or withdrawals
    map['Check Box 25'] = {} // Are there any Social Welfare payments? Yes
    map['Check Box 26'] = {} // Are there any Social Welfare payments? No
    map['Text Field 182'] = {} // Existing debt-commitments
    map['Text Field 190'] = {} // Repayment on proposed facility
    map['Text Field 192'] = {} // Repayment ability amount evident
    map['Text Field 193'] = {} // Where is the repayment ability demonstrated
    map['Text Field 194'] = {} // Balance of funds required
    map['Text Field 195'] = {} // Total Balance of funds on file
    map['Text Field 196'] = {} // Source of remaining balance of funds
    map['Text Field 205'] = {} // Proposed security details
    map['Text Field 206'] = {} // Investment properties
    map['Text Field 2010'] = {} // Recommendation/comments
    map['Text Field 2011'] = {} // Broker Name
    map['Text Field 2012'] = {} // Firm
    map['Text Field 2013'] = {} // Contact Details
}