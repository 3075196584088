import {App} from "antd";
import useMortgage from "./useMortgage";
import {fileNameAndExtension, sanitizeFileName} from "../../../assets/scripts/string-functions";
import {SectionTypes, UploadStatuses} from "../../../models";
import {API} from "aws-amplify";
import useS3 from "../useS3";
import {getAttachmentPath} from "../../components/emails-manager/AttachmentButtonGroups";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";

useMortgageUploadService.propTypes = {};

export const getUserUploadKey = ({
    id,
    fileName,
    fileExtension,
    mortgageID,
    stage,
    section,
    index,
    stampedOn,
    pdffed
}) => {
    //console.log({id, fileName, fileExtension, mortgageID, stage, section, index})
    let fN = encodeURIComponent(fileName)
    let path = `files/mortgage/${mortgageID}/uploads/${stage}/${section}/`
    if (index) {
        path += `${index}/`
    }
    path += `${id}/${fileName}`

    if (stampedOn) {
        path += '-stamped'
    }
    if (pdffed){
        path += `.pdf`
    }
    else{
        path += `.${fileExtension}`
    }
    return path
}

function useMortgageUploadService() {
    const app = App.useApp()
    const s3 = useS3()
    const mortgage = useMortgage()
    /*



    Types of documents to be kept here are, from a portal POV
    a) mortgage

        * Mortgage Upload Definition
            contentType: String
            fileName: String
            fileSize: Int
            fileExtension: String
            stage: ApplicationStages
            section: String
            index: ID
            status: UploadStatuses
            mortgageID: ID! @index(name: "byUserMortgage")
            observeMID: String
            reason: String
            identityID: String

        * Mortgage Document Definition
            id: ID!
            title: String
            description: String
            identityID: String
            folder: String
            fileName: String
            fileExtension: String
            isDynamic: Boolean
            mortgageID: ID! @index(name: "byUserMortgage")


     Params to receive

     */

    const createUserUploadRow = async (contentType, fileSize, dangerousFileName, stage, section, status, index = null) => {

        const sanitized = sanitizeFileName(dangerousFileName)
        const {
                  fileName,
                  fileExtension
              } = fileNameAndExtension(sanitized)
        // ADD UPLOAD DETAILS TO UserMortgageUploads
        const match = section.match(/-(1|2)$/)
        const applicant = match ? parseInt(match[0].replace('-', '')) : false
        let ident = mortgage.applicant1.identityID
        if (applicant === 2 && mortgage.applicant2.identityID) {
            ident = mortgage.applicant2.identityID
        }
        if (!ident) {
            app.notification.error({message: `You can't access the customers uploads folder. Have them sign into the app`})
            return false
        }

        const pairs = {
            contentType,
            fileName,
            fileSize,
            fileExtension,
            stage,
            section,
            identityID: ident,
            status,
            mortgageID: mortgage.id,
            observeMID: mortgage.id,
        }
        if (index) {
            pairs.index = index
        }

        let newUploadResult = await mortgage.mutate({
            create: {
                target: `uploads`,
                pairs
            }
        })

        return newUploadResult[0]
    }
    const copyAttachmentToUserUploads = async (sourcePath, file, stage, section, index) => {
        let newUploadRow = await createUserUploadRow(file.type, file.size, file.name, stage, section, UploadStatuses.PENDING, index)
        if (newUploadRow) {

            const path = getUserUploadKey(newUploadRow)

            const destination = `private/${newUploadRow.identityID}/${path}`
            const source = encodeURIComponent(`private/${SYSTEMIDENTITYID}/${sourcePath}`)
            try {
                const result = API.post("sussdAdminAPI", "/operations/emails/copyAttachmentToMortgageUploads", {
                    body: {
                        source,
                        destination
                    }
                })

                // find or create progress
                const sectionFinder = progress => {
                    if (progress.sectionType === 'UPLOAD' && progress.applicationStage === stage && progress.sectionName === section) {
                        if (index) {
                            return progress.sectionIndex === index
                        }
                        return true
                    }
                    return false
                }

                let progress = mortgage.progress.find(sectionFinder)
                if (!progress) {
                    const pairs = {
                        applicationStage: stage,
                        sectionType     : 'UPLOAD',
                        sectionName     : section,
                        mortgageID      : mortgage.id
                    }
                    if (index) {
                        pairs.sectionIndex = index
                    }
                    const create = {
                        target: `progress`,
                        pairs
                    }
                    await mortgage.mutate({create})
                }

            }
            catch (e) {
                console.log(e)
            }
        }
    }

    const addToUserUploads = async ({
        stage,
        section,
        index,
        file,
        progressCallback,
        completeCallback,
        errorCallback
    }) => {
        //console.log({stage, section, index, file, progressCallback, completeCallback, errorCallback})
        let newUploadRow = await createUserUploadRow(file.type, file.size, file.name, stage, section, UploadStatuses.PENDING, index)
        if (!newUploadRow) {
            return false
        }
        // ADD FILE TO S3
        const uuid = newUploadRow.id
        const config = {
            contentType     : file.type,
            level           : "private",
            identityId      : newUploadRow.identityID,
            resumable       : true,
            progressCallback,
            completeCallback: (event) => {
                mortgage.mutate({
                    update: {
                        target: `uploads[${newUploadRow.id}].status`,
                        value : UploadStatuses.PENDING
                    }
                })
                return completeCallback({
                    ...event,
                    item: newUploadRow
                })
            },
            errorCallback
        }
        //console.log(newUploadRow)
        let path = getUserUploadKey(newUploadRow)
        //console.log({path})
        try {
            const s3Result = s3.put(path, file, config);
            //console.log({s3Result})
        }
        catch (e) {
            //console.log(e)
        }

        //console.log('File uploaded successfully:', result);
        //return result.key; // Return the key or the S3 URL of the uploaded file
        return uuid

    }
    const getUserUploadPathAndConfig = (upload, downloadDontOpen) => {

        let config = {
            level      : 'private',
            download   : downloadDontOpen || false,
            contentType: upload.contentType
        }
        if (upload.pdffed){
            config.contentType = 'application/pdf'
        }
        let path

        // modern uploads have a fileExtension & identityId attributes, and are stored in private folder
        if (upload.fileExtension && upload.identityID) {
            config.identityId = upload?.identityID
            path = getUserUploadKey(upload)
        }

        // legacy : User uploads, no file name in s3, in protected folder, must supply content type
        else if (upload?.identityID) {
            config.level = 'protected'
            config.identityId = upload?.identityID
            path = `${upload.mortgageID}/${upload.section}/`
            if (upload?.index) {
                path += `${upload.index}/`
            }
            path += `${upload.id}`
            if (upload?.pdffed) {
                path += '-pdffed'
            }
            if (upload?.stampedOn) {
                path += '-stamped'
            }

        }
        else {
            // i think this is for legacy admin-uploaded user documents
            config.level = 'public'
            config.contentType = upload.contentType
            path = `files/${upload.mortgageID}/uploads/APPLY/${upload.section}/`
            if (upload.index) {
                path += `${upload.index}/`
            }
            path += upload.id
            path += `/${upload.fileName}.${upload.fileExtension}`
        }
        return {
            path,
            config
        }

    }
    const deleteUserUpload = async (id) => {
        let upload = mortgage.uploads.find(upload => upload.id === id)
        let oldPathAndConfig = getUserUploadPathAndConfig(upload)
        await s3.remove(oldPathAndConfig.path, {level: oldPathAndConfig.config.level})

        let result = await mortgage.mutate({
            delete: [
                {
                    target: `uploads`,
                    id    : id
                }
            ]
        })
        return result

    }
    const renameUserUpload = async (id, fileName) => {
        // only necessary in the public/files s3 folder (known because fileExtensiong didn't exist in legacy
        let v = sanitizeFileName(fileName)
        let oldUpload = mortgage.uploads.find(upload => upload.id === id)

        const update = {
            target: `uploads[${id}].fileName`,
            value : v,
        }

        // Only new uploads to change name in s3 (legacy items have no Filename in S3, so just the DB is updated)
        let rename = null
        if (oldUpload.fileExtension) {
            const oldPathAndConfig = getUserUploadPathAndConfig(oldUpload)
            const newPathAndConfig = getUserUploadPathAndConfig({
                ...oldUpload,
                fileName: v
            })
            //console.log({oldPathAndConfig, newPathAndConfig})
            if (oldPathAndConfig.path !== newPathAndConfig.path) {

                const response = await API.post("sussdAdminAPI", "/operations/uploads/rename", {
                    body: {
                        source     : `private/${oldUpload.identityID}/${oldPathAndConfig.path}`,
                        destination: `private/${oldUpload.identityID}/${newPathAndConfig.path}`
                    }
                })
                if (response.error) {
                    //console.log(response.error)
                }
                rename = response.success
                // const r1 = await Storage.copy({
                //     key: oldPathAndConfig.path,
                //     level: oldPathAndConfig.config.level,
                //     identityId: oldPathAndConfig.config.identityId
                // }, {
                //     key: newPathAndConfig.path,
                //    // level: oldPathAndConfig.config.level,
                //    // identityId: oldPathAndConfig.config.identityId
                // });
                //console.log({YOYYOYOYOYO: rename})
            }
            else {
                //console.log('names are same')
            }
            // await Storage.remove(oldPathAndConfig.path, {level: oldPathAndConfig.config.level});
        }
        else {
            const {
                      fileName,
                      fileExtension
                  } = fileNameAndExtension(oldUpload.fileName)
            rename = fileName !== update.value
            if (fileExtension) {
                update.value += `.${fileExtension}`
            }
        }
        //console.log({rename})
        if (rename) {
            let result = await mortgage.mutate({update})
            //console.log({result})
            let name = result[0].fileName
            if (oldUpload.fileExtension) {
                name += `.${oldUpload.fileExtension}`
            }
            return name
        }
    }
    const openUpload = async (upload) => {
        const {
                  path,
                  config
              } = getUserUploadPathAndConfig(upload)
        //console.log({upload, path, config})
        const url = await s3.getS3Url({
            path,
            config
        })
        //console.log(url)
        s3.openDocument(url)
    }
    const getFile = async (upload) => {

        const {
                  path,
                  config
              } = getUserUploadPathAndConfig(upload)
        const url = await s3.getS3Url({
            path,
            config
        })
        let response = await fetch(url)
        const contentType = response.headers.get('Content-Type');
        let fileType = null
        if (contentType === 'image/png') {
            fileType = 'png';
        } else if (contentType === 'image/jpeg') {
            fileType = 'jpg';
        }
        let buffer = await response.arrayBuffer()
        return {
            imageBytes: async () => {
                return buffer
            },
            base64    : async () => {
                // const blob = await responseClone.blob();
                // return await new Promise((resolve, reject) => {
                //     const reader = new FileReader();
                //     reader.onloadend = () => resolve(reader.result.split(',')[1]);
                //     reader.onerror = reject;
                //     reader.readAsDataURL(blob);
                // });
                let binary = '';
                const bytes = new Uint8Array(buffer);
                const len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return btoa(binary);
            },
            fileType,
            image     : async () => {
                return await new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve(img);
                    img.onerror = reject;
                    img.src = url;
                });
            }
        }

    }
    const covertToPDF = async (upload, pdfBlob) => {
        const {
                  path,
                  config
              } = getUserUploadPathAndConfig(upload)
        let realPath = path


        // if already pdffed, remove the -stamped from the filename

        // if (upload.pdffed) {
        //     const parts = path.split('/')
        //     const filename = parts.pop()
        //     const newFilename = filename.replace('-pdffed', '')
        //     parts.push(newFilename)
        //     realPath = parts.join('/')
        // }

        // change the file extension to pdf after the final dot
        const pieces = realPath.split('.')
        pieces.pop()
        realPath = pieces.join('.') + '.pdf'

       // let pathToSaveTo = `${config.level}/${config.identityId}/${realPath}`
        const expandedConfig = {
            completeCallback: (event) => {

               // createDocumentRow({stage, title, folder, fullFilename: file.name, isDynamic, isPrivate})
            },
            contentType: 'application/pdf',
            level: "private",
            identityId: upload.identityID,
            resumable: true,
        }
        let del = await s3.remove(realPath, expandedConfig)

        try {

            let result = await s3.put(realPath, pdfBlob, expandedConfig)
            await mortgage.mutate({
                update: {
                    target: `uploads[${upload.id}].pdffed`,
                    value : true
                }
            })
            return result
        }
        catch (e) {
            console.log(e)
            app.message.error(e.message)
        }
    }
    const moveUpload = async (uploadId, {
        stage,
        section,
        index = null
    }) => {
        if (index === "") {
            index = null
        }
        let origUpload = mortgage.uploads.find(item => item.id === uploadId)
        let pairs = {
            section,
            index,
            stage
        }
        let newUpload = {...origUpload, ...pairs}
        const oldPathAndConfig = getUserUploadPathAndConfig(origUpload)
        const newPathAndConfig = getUserUploadPathAndConfig(newUpload)
        if (oldPathAndConfig.path !== newPathAndConfig.path) {
            const response = await API.post("sussdAdminAPI", "/operations/uploads/rename", {
                body: {
                    source     : `private/${origUpload.identityID}/${oldPathAndConfig.path}`,
                    destination: `private/${origUpload.identityID}/${newPathAndConfig.path}`
                }
            })
            if (response.error) {
                //console.log(response.error)
            }
            if (response.success) {
                await mortgage.mutate({
                    update: {
                        target: `uploads[${uploadId}]`,
                        pairs
                    }
                })
            }
        }
    }

    return {
        add           : addToUserUploads,
        get           : getUserUploadPathAndConfig,
        delete        : deleteUserUpload,
        rename        : renameUserUpload,
        open          : openUpload,
        copyAttachment: copyAttachmentToUserUploads,
        move          : moveUpload,
        getFile,
        covertToPDF
    };
}

export default useMortgageUploadService;