import React, {useRef, useState, useEffect, useContext} from 'react';
import {Form, Input, Modal, Select, Tag, Typography} from "antd";
import ActionBar from "./ActionBar";
import dayjs from "dayjs";
import {Mortgages} from "../../providers/MortgagesProvider";
import ApplicantLinker from "./ApplicantLinker";
import {Link} from "react-router-dom";
import AttachmentButtonGroups, {getAttachmentPath} from "./AttachmentButtonGroups";
import {magenta, purple} from "@ant-design/colors";
import {ArrowRightOutlined, LoadingOutlined} from "@ant-design/icons";
import {performGQL} from "../../api-functions/functions";
import {getAdminEmails} from "../../../graphql/queries";

import {capitaliseWords, fileNameAndExtension} from "../../../assets/scripts/string-functions";
import {Lenders} from "../../providers/LendersProvider";
import {API} from "aws-amplify";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import {createAdminPdfOverlayTemplate} from "../../../graphql/mutations";

function Read(props) {
    const {lenders, updateOrAddLenderOverlayTemplate} = useContext(Lenders)
    const [state, setState] = useState({fetching: false, email: null})
    const iframe = useRef()
    const attachments = useRef([])
    const [linkingConfig, setLinkingConfig] = React.useState(null)
    const [linkingBusy, setLinkingBusy] = React.useState(false)
    const [linkingForm] = Form.useForm()
    // mark as policy doc
    const copyAttachmentToLenderPolicyDocs = async () => {
        setLinkingBusy(true)
        const {lender:lenderID, fileName: customFileName} = linkingForm.getFieldsValue()
        const sourcePath = getAttachmentPath(linkingConfig, state.email.v2Attachments)
        const lender = lenders.find(l=>l.id === lenderID)
        const {fileExtension} = fileNameAndExtension(linkingConfig.name)
        const newFileName = `${customFileName.replace(/[^A-Za-z0-9]/g, '-')}.${fileExtension}`.toLowerCase()
        let destinationPath = `lenders/${lender.lenderCode}/source-documents/${newFileName}`
        const destination = `private/${SYSTEMIDENTITYID}/${destinationPath}`
        const source = `private/${SYSTEMIDENTITYID}/${sourcePath}`

        try {
            const result = await API.post("sussdAdminAPI", "/operations/emails/copyAttachmentToMortgageUploads", {
                body: {
                    source,
                    destination
                }
            })
            if (result?.success){
                const newOverLayTemplate = {
                    lender: lender.id,
                    lenderCode: lender.lenderCode,
                    fileName: newFileName,
                    bucketKey: destinationPath,
                    defaultFolder: 'policy-documents'
                }

                const newDoc = await performGQL({input: newOverLayTemplate}, createAdminPdfOverlayTemplate)

                updateOrAddLenderOverlayTemplate(newDoc)
            }


            setLinkingBusy(false)
            setLinkingConfig(null)
        } catch (e) {
            console.log(e)
        }

    }
    const menuItems = (attachment) => {
        const menu = [
            {
                key: '0',
                label: 'Copy To Policy Docs',
                onClick: () => {
                    let {fileName} = fileNameAndExtension(attachment.name)
                    linkingForm.setFieldValue('fileName', fileName)
                    setLinkingConfig(attachment)
                }
            }
        ]
        return menu
    }
    const mortgages = useContext(Mortgages)

    const fetchEmail = async () => {


        setState(s => ({...s, fetching: true}))


        const fetchedEmail = await performGQL({id: props.emailId}, getAdminEmails)
        setState(s => ({...s, email: fetchedEmail, fetching: false}))
        // Replace all occurrences of "http://" with "https://"
    }
    const updateEmail = (details) => {
        setState(s => ({...s, email: {...s.email, ...details}}))
    }
    useEffect(() => {
        if (iframe.current) {
            let htmlString = state.email.html
            if (htmlString) {
                htmlString = htmlString.replace(/http:\/\/(?!s)/g, 'https://');
            }
            iframe.current.srcdoc = htmlString
        }
    }, [iframe.current, state.email])
    useEffect(() => {
        setState(s => ({...s, email: null}))
        if (iframe.current) {
            iframe.current.srcdoc = ''
        }
        if (attachments.current) {
            attachments.current = []
        }
        if (props.emailId && !state.fetching) {
            fetchEmail()
        }
    }, [props.emailId])

    if (!state.email) return null
    const date = state.email.isReceived ? state.email.datetimeReceived : state.email.datetimeSent
    function EmailFrom({item}) {
        const styleProp = {fontSize: 11}
        if (item.isReceived){
            styleProp.color =  magenta[5]
            styleProp.transform = 'rotate(45deg)'
        }
        else{
            styleProp.color =  purple[5]
            styleProp.transform = 'rotate(315deg)'
        }
        let tag = null
        if (item.userapplicantID !== 'null'){

            const mortgage = mortgages.ALL.find(m=>m.applicant1ID===item.userapplicantID)

            if (mortgage){
                tag = <Link to={`/client/${mortgage.id}/emails/${item.id}`}><Tag color="purple">{mortgage.applicationTitle}</Tag></Link>
            }
        }


        return <div className="d-col">
            {tag}
            <div className="d-row gap-6 a-start mb-6">
                {item.from}
                <ArrowRightOutlined className="mx-6 mt-6" style={styleProp}/>
                <div className="d-col">
                    {item.to.map((to, i) => <span key={i}>{to}</span>)}
                </div>
                {
                    (item.cc && item.cc.length > 0) && (
                        <>
                            <span className="mx-6" style={{fontSize: 11, fontWeight:'bold', color: magenta[5]}}>cc</span>
                            <div className="d-col">
                                {item.cc.map((to, i) => <span key={i}>{to}</span>)}
                            </div>

                        </>
                    )
                }

            </div>
        </div>


    }
    return (
        <div>

            <ActionBar email={state.email}/>

            <div className="d-col">

                    {state.email.userapplicantID === 'null' && (
                        <ApplicantLinker email={state.email} updateEmail={updateEmail}/>
                    )}


                <div className="d-row j-between px-15">
                    <EmailFrom item={state.email}/>
                    <div>{dayjs(date).format("DD MMM YYYY, HH:mm")}</div>
                </div>

                <div className="d-flex px-15">
                    <Typography.Title level={5} style={{margin: 0}}>{state.email.subject}</Typography.Title>
                </div>
                {!!state.email.hasAttachments && (
                    <AttachmentButtonGroups data={JSON.parse(state.email.attachments)} menuItems={menuItems} />
                )}
                <div className="p-15" style={{userSelect: 'all', flexGrow: 1}}>
                    <iframe title="read-message" style={{border: 0, width: '100%', height: 'calc(100vh - 120px)'}}
                            ref={iframe}/>
                </div>
            </div>
            <Modal okButtonProps={{icon:linkingBusy? <LoadingOutlined/> : null}} destroyOnClose={true} open={!!linkingConfig} title="Link Attachemnt to Lender Docs" closable={true} onCancel={()=>setLinkingConfig(null)} onOk={copyAttachmentToLenderPolicyDocs}>
                <Form
                    form={linkingForm}>
                    <Form.Item label="Lender" name="lender">
                        <Select options={lenders.filter(l=>l.lenderCode!=='sussd').map(l=>({value: l.id, label: l.lender}))}/>
                    </Form.Item>
                    <Form.Item label="New File Name" name="fileName">
                        <Input type="text" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default Read;