import dayjs from "dayjs";
import {addressToBlocks, convertHtmlToPlainText, toEuroString} from "../../../assets/scripts/string-functions";
import {calcMonthlyPayment} from "../../../app/providers/mortgage/useRates";

function SuitabilityLetter({
    mortgage,
    keyName,
    rates,
    app,
})
{
    const submission = mortgage.activeSubmission && mortgage.activeSubmission?.provisionalXlsOutput ? {
        data  : {
            figures: mortgage.activeSubmission.provisionalXlsInput.figures,
            test   : mortgage.activeSubmission.provisionalXlsInput.test,
        },
        result: mortgage.activeSubmission.provisionalXlsOutput
    } : null
    if (!submission) {
        app.notification.error({message: 'Submission is required to generate a suitability letter'})
    }
    let figures = {
        ...submission.data.figures,
        savingsTotal: (submission.data.figures.savings1 || 0) + (submission.data.figures?.savings2 || 0),
        accommodationCostsTotal: (submission.data.figures.accommodationCosts1 || 0) + (submission.data.figures?.accommodationCosts2 || 0),
        clearingLoansTotal: (submission.data.figures.clearingLoans1 || 0) + (submission.data.figures?.clearingLoans2 || 0),
        otherPRATotal: (submission.data.figures.otherPRA1 || 0) + (submission.data.figures?.otherPRA2 || 0),
        basicTotal: (submission.data.figures.basic1 || 0) + (submission.data.figures?.basic2 || 0),
    }
    let lenderTitle
    let cashForLiving = 0

    switch (mortgage.activeSubmission.lenderCode) {
        case 'fi':
            lenderTitle = "Finance Ireland";
            break;
        case 'ics':
            lenderTitle = "ICS Mortgages";
            cashForLiving = submission.result.items.find(it => it.key === 'NDI Surplus/Shortfall').value.replace(/[^\d.]/g, "")
            break;
        case 'ptsb':
            lenderTitle = "Permanent TSB";
            cashForLiving = submission.result.items.find(it => it.key === 'Cash For Living').value.replace(/[^\d.]/g, "")
            break;
        case 'haven':
            lenderTitle = "Haven Mortgages";
            cashForLiving = submission.result.items.find(it => it.key === 'Actual NDI').value.replace(/[^\d.]/g, "")
            break;
        case 'avant':
            lenderTitle = "Avant Money";
            cashForLiving = submission.result.items.find(it => it.key === 'Net Disposable Income').value.replace(/[^\d.]/g, "")
            break;
        case 'nua':
            lenderTitle = "Nua Mortgages";
            break;
        case 'boi':
            lenderTitle = "Bank of Ireland";
            cashForLiving = submission.result.items.find(it => it.key === 'NDI Surplus / Shortfall').value.replace(/[^\d.]/g, "")
            break;

    }
    let ltv = (Math.round(figures.provisionalLoan / (figures.provisionalValue) * 100))
    let rateId = mortgage.activeSubmission.finalRateId ? mortgage.activeSubmission.finalRateId : mortgage.activeSubmission?.provisionalRateId ? mortgage.activeSubmission.provisionalRateId : mortgage.requirement.rateId
    console.log({rateId})
    let selectedRate = rates.find(r => r.id === rateId)
    console.log({selectedRate, rates})
    if (!selectedRate) {
        app.notification.error({message: 'Rate is required to generate a suitability letter'})
        return <container fontSize="33">Rate is required </container>
    }
    let mortgageAmountPerMonth = calcMonthlyPayment(figures.provisionalLoan, figures.provisionalTerm, selectedRate.rate)
    let stressedAmountPerMonth = calcMonthlyPayment(figures.provisionalLoan, figures.provisionalTerm, parseFloat(selectedRate.rate) + 2)
    if (!mortgage.properties[0].berScore) {
        app.notification.error({message: 'BER Score is required to generate a suitability letter'})
        return null
    }






    function Address() {
        let address1 = addressToBlocks(mortgage.applicant1.personal.addressCurrent)
        let address2 = addressToBlocks(mortgage.applicant2.personal.addressCurrent)
        return (<group>

            <string> {dayjs().format('D MMMM YYYY')}</string>
            <gap/>
            {mortgage.twoApplicants && !mortgage.requirement.cohabiting ? (<>
                <string>{dayjs().format('D MMMM YYYY')}</string>
                <grid columns={[
                    8,
                    8
                ]}>
                    <row>
                        <cell>
                            <string>{mortgage.applicant1.fullName}</string>
                            {address1.map(it => <string>{it}</string>)}
                        </cell>
                        <cell>
                            <string>{mortgage.applicant2.fullName}</string>
                            {address2.map(it => <string>{it}</string>)}
                        </cell>
                    </row>

                </grid>
            </>) : (<>
                <string>{mortgage.applicationTitle}</string>
                {address1.map(it => <string>{it}</string>)}
            </>)}
        </group>);
    }
    function FirstParagraph() {
        let name = mortgage.twoApplicants ? `${mortgage.applicant1.firstName} and ${mortgage.applicant2.firstName}` : mortgage.applicant1.firstName
        return (<group>
            <border height={3}/>
            <gap/>
            <string align="center" font-size="11">Important Notice - Statement of Suitability</string>
            <gap/>
            <container padding-x={70}>
                <string align="center">This is an important document which sets out the reasons why the mortgage
                    product we
                    have recommended is considered suitable or the most suitable, based on your particular
                    needs, objectives
                    and circumstances.
                </string>
            </container>
            <gap/>
            <border height={3}/>

            <gap size={30}/>
            <string>Dear {name}</string>
            <gap/>
            <string>Thank you for taking the time to discuss your mortgage requirements with me. Based on the
                information you’ve provided and our detailed analysis of your situation, I am pleased to outline why
                your chosen mortgage product is best suited to your needs.
            </string>
            <gap/>
            <string> You should read this document carefully and contact me if there is anything that is not clear to
                you.
            </string>
        </group>);
    }
    function YourMortgageDetails() {

        function calcPMT(rate, nperiod, pv, fv, type) {
            if (!fv) {
                fv = 0;
            }
            if (!type) {
                type = 0;
            }
            if (rate === 0) {
                return -(pv + fv) / nperiod;
            }
            var pvif = Math.pow(1 + rate, nperiod);
            var pmt = rate / (pvif - 1) * -(pv * pvif + fv);
            if (type === 1) {
                pmt /= 1 + rate;
            }
            return pmt;
        }
        function calcMonthlyPayment(required, term, rate) {
            let trueRate = parseFloat(rate) / 100 / 12;
            const pmt = calcPMT(trueRate, term * 12, required) * -1;
            return Math.round(pmt);
        }

        let existing = mortgage.docContents.find(it => it.document === 'statementOfSuitability' && it.paragraph === 'rate-details')
        let details = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : mortgage.activeSubmission.provisionalProduct

        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="1." font-size={8} offset={15}>Your Mortgage Details</bullet>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Mortgage Amount:</bullet>
                    </cell>
                    <cell>
                        <string>{toEuroString(figures.provisionalLoan)}</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Property Address:</bullet>
                    </cell>
                    <cell>
                        <string>{mortgage.properties[0].address}</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Property Value:</bullet>
                    </cell>
                    <cell>
                        <string>{toEuroString(figures.provisionalValue)} (to be confirmed by bank valuation)</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Mortgage Term:</bullet>
                    </cell>
                    <cell>
                        <string>{figures.provisionalTerm} years</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Loan to Value Ratio:</bullet>
                    </cell>
                    <cell>
                        <string>{ltv} %</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Product / Rate</bullet>
                    </cell>
                    <cell>
                        <string>{details}</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Monthly Repayment:</bullet>
                    </cell>
                    <cell>

                        <string>{toEuroString(mortgageAmountPerMonth)}</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22}>
                        <bullet>Stressed Monthly Repayment (if interest rates increase by
                            2%):
                        </bullet>
                    </cell>
                    <cell>
                        <string>{toEuroString(stressedAmountPerMonth)}</string>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function AffordabilityAndRiskAssessment() {
        let surplus = cashForLiving

        return (<group>

            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24,
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="2." font-size={8} offset={15}>Affordability and Risk Assessment</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>Sussd’s objective is to find you the most competitive mortgage product that is suitable
                            to your needs. As part of our application process we have obtained detailed financial
                            information from you which we have then assessed to determine the level of mortgage that you
                            can afford.
                        </string>
                        <string>This is detailed in the Fact Find document that you received from us.</string>
                        <gap/>
                        <string>
                            We have carefully assessed your financial situation, including your monthly income and
                            expenses. After all expenses, you are expected to have a surplus of {surplus} each
                            month. This indicates that the recommended mortgage of {toEuroString(figures.provisionalLoan)} is
                            affordable for you, and
                            you are well-positioned to manage the repayments comfortably.

                        </string>
                        <string font-weight="bold">
                            Stress Testing:
                        </string>
                        <string>
                            Even under a stressed scenario, were interest rates to rise by 2%, your repayments would
                            remain manageable at {toEuroString(stressedAmountPerMonth)}.
                        </string>

                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function MortgageProviderRecommendation() {
        let existing = mortgage.docContents.find(it => it.document === 'statementOfSuitability' && it.paragraph === 'provider-recommendation')
        let recommendation = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : "NO COMMENT"
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="3." font-size={8} offset={15}>Mortgage Provider Recommendation</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>We assessed your mortgage options with Finance Ireland, ICS, PTSB, Haven, Nua , Bank of Ireland and
                            Avant Mortgages
                        </string>
                        <gap/>
                        <string>{recommendation}
                        </string>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function MortgageTypeAndTerms() {
        let existing = mortgage.docContents.find(it => it.document === 'statementOfSuitability' && it.paragraph === 'mortgage-term-explanation')
        let explanation = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : "NO COMMENT"
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24,
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="4." font-size={8} offset={15}>Mortgage Type and Terms</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>Your mortgage is a capital and interest (annuity) mortgage. This means that if you meet all repayments as required, the mortgage will be fully repaid by the end of the
                            term.
                        </string>
                        <gap/>
                        <string>{explanation}</string>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function InterestRateProductRecommendation() {
        let lowestLtv = Math.ceil(ltv / 10) * 10
        let berPassed = mortgage.properties[0].berScore.startsWith('A') || mortgage.properties[0].berScore.startsWith('B')

        let existing = mortgage.docContents.find(it => it.document === 'statementOfSuitability' && it.paragraph === 'product-recommendation')
        let recommendation = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : "NO COMMENT"

        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="5." font-size={8} offset={15}>Interest Rate Product Recommendation</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string font-weight="bold">When taking out a mortgage there are two types of interest rates
                            to choose from;
                        </string>
                        <gap/>
                        <bullet bullet-color="green">
                            <string font-weight="bold">Variable Rate</string>
                            <string>when you are on a variable rate, your mortgage provider can amend the rate from
                                time to time. This may or may not be in line with European Central Bank (ECB) rates.
                                Variable rates offer greater flexibility, for example allowing you to make lump sum
                                repayments without having to incur a penalty and /or fine. It also provides the
                                option to increase monthly repayments at any stage which will reduce the overall
                                interest charge on your mortgage. However, with a variable rate it is important to
                                keep your mortgage options under regular review due to the risk of your repayments
                                increasing.
                            </string>
                        </bullet>
                        <bullet bullet-color="green">
                            <string font-weight="bold">Fixed Rate</string>
                            <string>where your monthly repayments are fixed for an agreed period. This provides you
                                with the security of knowing your monthly repayments wont increase. There is less
                                flexibility with regards to overpayment compared with Variable Rates and ability to
                                overpay is dependent on your mortgage providers policies for overpayment. If you pay
                                off your fixed rate mortgage early there may be a break cost fee applied. When you
                                are near to the end of a fixed term you should review your mortgage options as you
                                will be required to select a new interest rate.
                            </string>
                        </bullet>
                        <gap/>
                        <string font-weight="bold">Factors that influence your interest rate;</string>
                        <gap/>
                        <bullet bullet-color="green">
                            <string font-weight="bold">Loan to Value Ratio</string>
                            <string>The LTV or Loan to Value. This is the value of your loan to the value of your
                                property with lower interest rates available for lower LTV’s. Your LTV is {ltv}
                                which means you qualify for interest rate products with LTV of {lowestLtv}% and above.
                            </string>
                        </bullet>
                        <bullet bullet-color="green">
                            <string font-weight="bold">BER of Property</string>
                            <string>The higher your BER score may allow you to qualify for green mortgage rates
                                where the BER is B3 or better. Your BER is {mortgage.properties[0].berScore} and
                                therefore you {berPassed ? '' : 'do not '}qualify for reduced interest rate offerings for green mortgages.
                            </string>
                        </bullet>
                        <gap/>
                        <string font-weight="bold">You have chosen the following product
                            with {lenderTitle}:
                        </string>
                        <string>{mortgage.activeSubmission.finalProduct ? mortgage.activeSubmission.finalProduct : mortgage.activeSubmission.provisionalProduct}</string>
                        <gap/>
                        <string font-weight="bold">Recommendation:</string>
                        <string>{recommendation} </string>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function SpecialOffersAvailable() {

        let offers = []
        if (selectedRate?.offers) {
            offers = Object.values(selectedRate.offers)
        }
        console.log({selectedRate})

        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="6." font-size={8} offset={15}>Special Offers Available</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        {offers.map(o => <bullet bullet-color="green">
                            <string>{o.title}</string>
                            <string>{o.description}</string>
                        </bullet>)}
                        {offers.length === 0 && <string>No special offers available on your selected product</string>}

                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function ImportantNotices() {
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="7." font-size={8} offset={15}>Important Notices</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>It is very important that you keep your mortgage under regular review. For fixed rates, please ensure you review your options well in advance of the end of your fixed
                            term. We will contact you close to the end of your fixed rate term. For variable rates, your repayments may change if the lender adjusts the variable rate so it is very
                            important that you regularly review your interest rate options. You can choose at any time to select a fixed rate product. We will keep your interest rate under review for
                            you but if you require any advice or assistance at any time you should contact us.
                        </string>
                        <gap/>
                        <string>If you do not keep up with payments, your home may be at risk. It is important that if you are concerned over your ability to meet your repayments that you contact your
                            lender to discuss your options. If you miss repayments can affect your credit rating which may limit future access to credit.
                        </string>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function Conclusion() {
        let existing = mortgage.docContents.find(it => it.document === 'statementOfSuitability' && it.paragraph === 'conclusion')
        let details = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : ""
        if (details !== '') {
            details += '\n \n'
        }
        details += 'We will keep your mortgage under periodic review and will contact you if your fixed rate term is nearing its end or if you are on a variable rate and rates increase.'
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="8." font-size={8} offset={15}>Conclusion</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string font-weight="bold">Please review this document carefully. If you have any questions
                            or need further clarification, don’t hesitate to contact me. Once you're ready to
                            proceed, please sign below to confirm your understanding and agreement with the
                            recommendation.
                        </string>
                        <gap/>
                        <string>{details}</string>
                        <gap/>
                        <string>In the mean time, please don’t hesitate to contact us if you require any assistance.
                        </string>
                        <gap/>
                        <string>Prepared by</string>
                        <gap size={21}/>
                        <kevinsignature/>
                        <string>___________________________</string>
                        <string>Kevin Coakley</string>
                        <gap/>
                        <string>Director</string>
                        <string>Suss'd Mortgages</string>


                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function Declarations() {
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="9." font-size={8} offset={15}>Declaration</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>We confirm that we have read and understood the details outlined in this statement.
                            We agree that the recommended mortgage product is suitable for our needs.
                        </string>
                        <gap/>
                        <string>I/We have read the related application form(s) carefully. I/We confirm that all the
                            information disclosed on it, whether completed by me/us or not, is accurate and is to be
                            treated as if the form had been completed by me/us.
                        </string>
                        <gap/>
                        <string>I/We confirm that we fully understand all of the questions and answers dealt with in
                            the process of my/our application . I/We have disclosed everything, which might be
                            relevant to this transaction, whether or not specifically raised during the application
                            and approval process.
                        </string>
                        <gap/>

                        <string>I/We are happy for Sussd Mortgages to keep our mortgage options under regular review
                            and contact us by phone or email in the future.
                        </string>
                        <gap size={21}/>
                        <string>Applicant 1 Signature: ____________________________________________________________
                            Date: ___________________________
                        </string>
                        <gap size={21}/>
                        <string>Applicant 2 Signature: ____________________________________________________________
                            Date: ___________________________
                        </string>
                        <gap/>
                    </cell>
                </row>
            </grid>
            <border height={1} color="grey"/>
        </group>)
    }
    function ConsumerCreditAct1995() {
        return (<group>
                <grid vertical-bars="outside" horizontal-bars={1} columns={[
                    24
                ]}>
                    <row bg-color="shaded">
                        <cell>
                            <string font-size={10} color="green">Consumer Credit Act 1995</string>
                        </cell>
                    </row>
                    <row>
                        <cell padding-left={22} padding-top={7} padding-bottom={7}>
                            <string>All loans are subject to the satisfactory appraisal of status and financial standing and
                                require
                                security over the property and appropriate mortgage protection/life assurance and home
                                insurance.
                            </string>
                            <gap/>
                            <string>
                                WARNING: YOUR HOME IS AT RISK IF YOU DO NOT KEEP UP PAYMENTS ON A MORTGAGE OR ANY OTHER LOAN
                                SECURED ON IT.
                            </string>
                            <gap/>
                            <string>
                                VARIABLE RATE HOMELOAN: THE REPAYMENT RATES ON THIS HOUSING LOAN MAY BE ADJUSTED BY THE
                                LENDER FROM TIME TO TIME.
                            </string>
                            <gap/>
                            <string>
                                IF YOU CHOOSE AN ENDOWMENT LOAN, AND THE INSURER DOES NOT GUARANTEE THAT THE PROCEEDS OF THE
                                POLICY WILL BE ENOUGH TO REPAY THE LOAN WHEN DUE:
                            </string>
                            <gap/>
                            <string>
                                WARNING: THERE IS NO GUARANTEE THAT THE PROCEEDS OF THE INSURANCE POLICY WILL BE SUFFICIENT
                                TO REPAY THE LOAN IN FULL WHEN IT BECOMES DUE FOR REPAYMENT. IT MAY BE NECESSARY TO INCREASE
                                THE PREMIUM AMOUNT PAYABLE IN ORDER TO SECURE AN INCREASE IN THE PROCEEDS OF THE POLICY ON
                                MATURITY. THE EARLY SURRENDER OF AN ENDOWMENT POLICY IN RESPECT OF AN ENDOWMENT LOAN MAY
                                YIELD A LOWER RETURN TO THE BORROWER THAT THAT PAID IN PREMIA AND OTHER CHARGES.
                            </string>
                        </cell>
                    </row>
                </grid>
            </group>)
    }
    function ConsumerProtectionCode2012() {
        return (<group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                24
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <string font-size={10} color="green">Consumer Protection Code 2012</string>
                    </cell>
                </row>
                <row>
                    <cell padding-left={22} padding-top={7} padding-bottom={7}>
                        <string>
                            All loans are subject to the satisfactory appraisal of status and financial standing and require
                            security over the property and appropriate mortgage protection/life assurance and home insurance.
                        </string>
                        <gap/>
                        <string>
                            WARNING: IF YOU DO NOT MEET THE REPAYMENTS ON YOUR CREDIT AGREEMENT, YOUR ACCOUNT WILL GO INTO ARREARS.
                            THIS MAY AFFECT YOUR CREDIT RATING, WHICH MAY LIMIT YOUR ABILITY TO ACCESS CREDIT IN THE FUTURE.
                        </string>
                        <gap/>
                        <string>
                            WARNING: IF YOU DO NOT MEET THE REPAYMENTS ON YOUR LOAN, YOUR ACCOUNT WILL GO INTO ARREARS. THIS MAY AFFECT YOUR CREDIT RATING, WHICH MAY LIMIT YOUR ABILITY TO ACCESS
                            CREDIT IN THE FUTURE.
                        </string>
                        <gap/>
                        <string>
                            WARNING: IF YOU DO NOT KEEP UP YOUR REPAYMENTS YOU MAY LOSE YOUR HOME IF YOUR MORTGAGE IS EVER ON A VARIABLE RATE
                        </string>
                        <gap/>
                        <string>
                            WARNING: THE PAYMENT RATES ON THIS HOUSING LOAN MAY BE ADJUSTED BY THE LENDER FROM TIME TO TIME.
                        </string>
                        <gap/>
                        <string>
                            IF YOUR MORTGAGE IS EVER ON A FIXED RATE: WARNING: YOU MAY HAVE TO PAY CHARGES IF YOU PAY OFF A FIXED-RATE LOAN EARLY.
                        </string>
                        <gap/>
                        <string>
                            IF YOUR MORTGAGE IS ON AN INTEREST ONLY TERM: WARNING: THE ENTIRE AMOUNT THAT YOU HAVE BORROWED WILL STILL BE OUTSTANDING AT THE END OF THE INTEREST ONLY PERIOD.
                        </string>
                        <gap/>
                        <string>
                            IF YOUR MORTGAGE IS AN EQUITY RELEASE MORTGAGE AND IS BEING USED FOR DEBT CONSOLIDATION PURPOSES:
                        </string>
                        <gap/>
                        <string>
                            WARNING: THIS NEW LOAN MAY TAKE LONGER TO PAY OFF THAN YOUR PREVIOUS LOANS. THIS MEANS YOU MAY PAY MORE THAN IF YOU PAID OVER A SHORTER TERM.
                        </string>
                    </cell>
                </row>
            </grid>
        </group>)
    }
    return (<document height="a4" group-gap={15}>
        <group>
            <header>Statement of Suitability</header>
            <border height={3} color="green"/>
        </group>

        <Address/>
        <FirstParagraph/>
        <YourMortgageDetails/>
        <AffordabilityAndRiskAssessment/>
        <MortgageProviderRecommendation/>
        <MortgageTypeAndTerms/>
        <InterestRateProductRecommendation/>
        <SpecialOffersAvailable/>
        <ImportantNotices/>
        <Conclusion/>
        <Declarations/>
        <ConsumerCreditAct1995/>
        <ConsumerProtectionCode2012/>

    </document>);
}
function old() {
    return (<group>

        <group>

            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="3." font-size={8} offset={15}>Reasons for Recommendation</bullet>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Green Mortgage Benefits:</bullet>
                        <cell padding-left={11}>
                            <string>
                                The selected property qualifies for a green mortgage product due to its energy
                                rating
                                (B3 or
                                higher). This entitles you to a lower interest rate, which is beneficial over the
                                long
                                term.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Fixed Rate Security:</bullet>
                        <cell padding-left={11}>
                            <string>
                                The 4-year fixed interest rate provides stability, ensuring your monthly repayments
                                remain
                                unchanged during this period.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="4." font-size={8} offset={15}>Interest Rate Types Available</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Variable Rate:</bullet>
                        <cell padding-left={11}>
                            <string>
                                Your interest rate may fluctuate with market conditions, offering flexibility to
                                make
                                additional payments without penalties. This option is generally more unpredictable
                                but
                                allows
                                for greater repayment flexibility.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Fixed Rate:</bullet>
                        <cell padding-left={11}>
                            <string>As chosen, this rate remains stable for the fixed term, providing peace of mind
                                that
                                your
                                repayments will not increase unexpectedly.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="5." font-size={8} offset={15}>Affordability and Risk Assessment</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Affordability Check:</bullet>
                        <cell padding-left={11}>
                            <string>We have carefully assessed your financial situation, including your monthly
                                income
                                and
                                expenses. After all expenses, you are expected to have a surplus of €_______________
                                each
                                month. This indicates that the recommended mortgage is affordable for you, and you
                                are
                                well-positioned to manage the repayments comfortably.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Stress Testing:</bullet>
                        <cell padding-left={11}>
                            <string>Even under a stressed scenario, where interest rates rise by 2%, your repayments
                                would
                                remain manageable at €4,069.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="6." font-size={8} offset={15}>Available Mortgage Providers</bullet>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>In addition to Permanent TSB, you also qualify with the
                            following
                            mortgage providers:
                        </string>
                        <cell>
                            <bullet>Haven
                            </bullet>
                            <bullet>ICS
                            </bullet>
                            <bullet>Avant
                            </bullet>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>After careful comparison, Permanent TSB offered the most competitive rate and best
                            suited
                            your
                            requirements, particularly with the green mortgage benefits.
                        </string>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="7." font-size={8} offset={15}>Special Offers and Considerations</bullet>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Green Rate Mortgage:</bullet>
                        <cell padding-left={11}>
                            <string>This product offers a lower interest rate due to the energy efficiency of your
                                property.
                                However, please note that no cashback or special offers are included with this rate.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <bullet>Future Rate Changes:</bullet>
                        <cell padding-left={11}>
                            <string>While the fixed rate provides stability, it's important to remember that lenders
                                can
                                adjust rates before the mortgage is drawn down. Therefore, it’s crucial to complete
                                the
                                process promptly.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="8." font-size={8} offset={15}>Important Notices and Warnings</bullet>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>Mortgage Type:
                        </string>
                        <cell>
                            <bullet>Capital and Interest Mortgage (Annuity): This ensures that if all repayments are
                                met,
                                the mortgage will be fully repaid by the
                                end of the term.
                            </bullet>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>Potential Rate Changes:
                        </string>
                        <cell>
                            <bullet>Variable Rate Adjustments: Your repayments may change if the lender adjusts the
                                variable
                                rate.
                            </bullet>
                            <bullet>Fixed Rate Charges: You may incur charges if you pay off a fixed-rate mortgage
                                early.
                            </bullet>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>Risks:
                        </string>
                        <cell>
                            <bullet>Your Home is at Risk: If you do not keep up with payments, your home may be at
                                risk.
                            </bullet>
                            <bullet>Impact on Credit Rating: Missed repayments can affect your credit rating,
                                limiting
                                future credit access.
                            </bullet>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <bullet dot="9." font-size={8} offset={15}>Next Steps</bullet>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={25}>
                        <string>Please review this document carefully. If you have any questions or need further
                            clarification, don’t hesitate to contact me. Once you're ready to proceed, please sign
                            below
                            to
                            confirm your understanding and agreement with the recommendation.
                        </string>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <grid columns={[
                1,
                1.5,
                19,
                1.5,
                1,
            ]} important={1}>
                <row>

                    <cell>
                    </cell>
                    <cell>

                    </cell>
                    <cell bg-color="red" padding-left={10} padding-right={10} padding-top={10} padding-bottom={10}>
                        <container bg-color="light" padding-x={3} padding-y={3}>
                            <string align="left">Client Declaration</string>
                            <gap size={20}></gap>
                            <string>We confirm that we have read and understood the details outlined in this
                                statement.
                                We
                                agree that the recommended mortgage product is suitable for our needs.
                            </string>
                            <gap size={40}></gap>

                            <string align="left">Applicant 1 Signature:
                                ____________________________________________________________ Date:
                                ___________________________
                            </string>
                            <gap size={40}></gap>
                            <string align="left">Applicant 2 Signature:
                                ____________________________________________________________ Date:
                                ___________________________
                            </string>
                            <gap size={10}></gap>
                        </container>
                    </cell>
                    <cell>
                    </cell>
                    <cell>

                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <grid>
                <row>
                    <cell padding-left={25}>
                        <string>Prepared By:</string>
                        <string>Kevin Coakley</string>
                        <string>Regulated By: Central Bank of Ireland</string>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="green"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <string font-size={8} offset={15}>Additional Notes</string>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={5}>
                        <bullet>Consumer Credit Act 1995:</bullet>
                        <cell padding-left={11}>
                            <string>Loans are subject to status and financial standing appraisals,
                                requiring security over the property, mortgage protection, life assurance, and home
                                insurance.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={5}>
                        <bullet>Consumer Protection Code 2012:</bullet>
                        <cell padding-left={11}>
                            <string>Non-repayment could lead to account arrears, impacting
                                your
                                credit rating and future credit access.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>

        </group>
        <group>
            <border height={1} color="grey"/>
            <grid vertical-bars="outside" horizontal-bars={1} columns={[
                12,
                12
            ]}>
                <row bg-color="shaded">
                    <cell>
                        <string font-size={8} offset={15}>Specific Warnings</string>
                    </cell>
                    <cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={5}>
                        <bullet>Variable Rate Home Loan:</bullet>
                        <cell padding-left={11}>
                            <string>The repayment rates on this loan may be adjusted by the lender.</string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={5}>
                        <bullet>Interest-Only Term:</bullet>
                        <cell padding-left={11}>
                            <string>The entire loan amount will still be outstanding at the end of an interest-only
                                period.
                            </string>
                        </cell>
                    </cell>
                </row>
                <row>
                    <cell padding-left={5}>
                        <bullet>Equity Release Mortgage:</bullet>
                        <cell padding-left={11}>
                            <string>If used for debt consolidation, the loan may take longer to pay off, potentially
                                increasing overall
                                costs.
                            </string>
                        </cell>
                    </cell>
                </row>
            </grid>
            <border height={1} color="green"/>
        </group>
    </group>)
}

export default SuitabilityLetter;
