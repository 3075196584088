import {performGQL} from "../functions";
import generateQuery from "../../../graphql/generate-query";

function getMortgage(id) {
    const query = generateQuery('GetUserMortgage', `
    id
    twoApplicants
    mortgageType
    applicationType
    activeAgent
    subs
    applicant1ID
    applicant2ID
    friendlyName
    data
    applicationStage
    applicationStatus
    applicationFutureDate
    qualification
    adminMessage
    adminMessageBy
    adminMessageOn
    qualificationConfirmed
    lastSeen
    lastFlowItem
    lastFlowItemTime,
    completedFlowItems
    completedFlowItemsForAdmin
    advisorID
    caseNumber
    uploadsPercentSetup,
    uploadsPercentApply,
    docContents {
        items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
        startedAt
        __typename
    }
    submissions {
        items {
          id
          lenderCode
          provisionalProduct
          provisionalRateId
          provisionalRate
          provisionalTerm
          provisionalLoan
          provisionalValue
          provisionalSecondaryProduct
          provisionalSecondaryRate
          provisionalSecondaryRateId
          provisionalXlsInput
          provisionalXlsOutput
          finalProduct
          finalRate
          finalRateId
          finalTerm
          finalLoan
          finalValue
          finalSecondaryProduct
          finalSecondaryRate
          finalSecondaryRateId
          finalXlsInput
          finalXlsOutput
          soft
          aipEmailId
          aipMaxLoan
          aipPropertyReference
          aipExpiryDate
          aipDate
          aipNotes
          aipSettings
          loanOfferEmailId
          loanOfferDate
          loanOfferExpiryDate
          loanOfferNotes
          loanOfferSettings
          drawdownDate
          completedFlowItems
          completedFlowItemsForAdmin
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          valuation2ReinstatementValue
          reinstatement2Value
          valuation2Value
          hasValuation2Issues
          valuation2Issues
          surveyRequired
          surveyDate
          lenderRecommendation
          productRecommendation
          mortgageAccountNo
          termRecommendation
          invalidatedReason
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      
   playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
    lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
    replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          requestBody
          requestType
          assocEmails
          noteBody
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
    notifications{
        items{
            id
            mortgageID
            stage
            name
            history{
                items{
                    id
                    sentTime
                    sentBy
                    data
                    notificationId
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    queries {
        items {
          id
          mortgageID
          createdBy
          referenceType
          referenceId
          section
          index
          title
          date
          description
          amount
          query
          stage
          adminAnswered
          userAnswered
          userAnswer
          userAnswerOn
          userAnswerBy
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          emailReplyID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
    requirement {
        id
        ddBankAccountID
        applicationElsewhere
        loanRequired
        lenderCode
        mortgageAccountNo
        drawdownDate
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        proposals
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        lenderId
        lenderProduct
        loanOfferDate
        loanOfferExpiryDate
        aipDate
        aipExpiryDate
        drawdownDate
        propertyReference
        mortgageAccountNo
        contactMethod
        soft
        _version
        _deleted
        _lastChangedAt
        owner
    }
    property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    properties {
        items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            origPurchasePrice
            yearOfPurchase
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isNewAccount
            isClosed
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            valuationValue
            reinstatementValue
            valuationDate
            valuationExpiryDate
            valuationInstructed
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            bookingDepositPaid
            contractDepositPaid
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        nextToken
        startedAt
    }
    existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    uploads {
        items {
            id
            contentType
            fileName
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            exclude
            logicalName
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
              verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
            basicInfo {
              id
              institution
              accountName
              accountAddress
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              startDate
              endDate
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
        }
        nextToken
        startedAt
    }
    progress {
        items {
            id
            aiAnalysisTime
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            sectionChecked
            sectionCheckedBy
            expiresDate
            isNewAccount
            note
            submittedOn
            submittedBy
            arrangement
            metaData
            verifiedData
            excludeFromCalculations
            internalNote
            internalNoteBy
            internalNoteOn
            lastDocumentDate
            lastDocumentDateDismissed
            viewState
viewStateBy
viewStateOn
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        nextToken
        startedAt
    }
    applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        lastHere
        data
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        lastHereTime{
            id
            time
            createdAt
            updatedAt
            _version
        }
        personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        health {
            id
            weight
            height
            smoker
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            everSmoked
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
        }
        income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            selfIncomeSource
            salariedInstitution
            incomeChecks
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasCurrentAccountSavings
            hasLoans
            hasSavings
            hasAssets
            hasDebts
            hasIncome
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmployerName
            previousEmployerAddress
            previousPositionHeld
            previousEmploymentBasis
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            payFrequency
            publicSector
            nonEuroCurrency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
      
          }
          nextToken
         
        }
        tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            isNewAccount
            isClosed
            accommodationDD
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
        }
        assets {
            items {
                id
                assetType
                institution
                description
                balance
                joint
                increasesFrequency
                increases
                applicantID
                observeMID
                currency
                accountNumber
                accountName
                accountAddress
                lastDocumentDate
                readAccessGroup
                editAccessGroup
                isNewAccount
                isClosed
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        properties {
            items {
                id
                propertyType
                address
                estimatedValue
                isRented
                rentPerAnnum
                rentPerMonth
                isMortgaged
                isNewAccount
                isClosed
                accountNumber
                outstanding
                monthlyRepayments
                remainingTerm
                lender
                inArrears
                arrears
                applicantID
                bedrooms
                bathrooms
                isRestructured
                originalAmount
                observeMID
                endDate
                currency
                lastDocumentDate
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        debts {
            items {
                id
                debtType
                lender
                outstanding
                monthlyPayment
                creditLimit
                inArrears
                arrears
                endDate
                originalAmount
                currency
                clearedBeforeMortgage
                applicantID
                observeMID
                accountNumber
                isNewAccount
                isClosed
                lastDocumentDate
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
     
        alternateEmailAddresses {
            items {
                id
                email
                applicantID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        lastHere
        data
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        lastHereTime{
            id
            time
            createdAt
            updatedAt
            _version
        }
        personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        health {
            id
            weight
            height
            smoker
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            everSmoked
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
        }
        income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            selfIncomeSource
            salariedInstitution
            incomeChecks
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasLoans
            hasSavings
            hasAssets
            hasDebts
            hasIncome
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmployerName
            previousEmployerAddress
            previousPositionHeld
            previousEmploymentBasis
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            payFrequency
            publicSector
            nonEuroCurrency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
      
          }
          nextToken
         
        }
        tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            readAccessGroup
            editAccessGroup
            currency
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            isNewAccount
            isClosed
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
        }
        assets {
            items {
                id
                assetType
                joint
                institution
                description
                balance
                increasesFrequency
                increases
                applicantID
                observeMID
                currency
                accountNumber
                accountName
                accountAddress
                lastDocumentDate
                isNewAccount
                isClosed
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        properties {
            items {
                id
                propertyType
                address
                estimatedValue
                isRented
                rentPerAnnum
                rentPerMonth
                isMortgaged
                accountNumber
                outstanding
                monthlyRepayments
                remainingTerm
                
                lender
                inArrears
                arrears
                applicantID
                bedrooms
                bathrooms
                isRestructured
                originalAmount
                
                observeMID
                endDate
                currency
                lastDocumentDate
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        debts {
            items {
                id
                debtType
                lender
                outstanding
                monthlyPayment
                creditLimit
                inArrears
                arrears
                endDate
                originalAmount
                currency
                clearedBeforeMortgage
                applicantID
                accountNumber
                observeMID
                accountNumber
                isNewAccount
                isClosed
                lastDocumentDate
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        alternateEmailAddresses {
            items {
                id
                email
                applicantID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
            }
            nextToken
            startedAt
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    notes {
        items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
    }
    solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
    }
    dynamicSections {
        items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionInstructions
            mortgageID
            observeMID
            emailReplyID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        nextToken
        startedAt
    }
    tasks {
        items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            pauses
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    stageTasks {
        items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    invitations {
        items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        nextToken
        startedAt
    }
    UserMortgageDocuments {
        items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension 
            isDynamic       
            name
            stage
            private
            mortgageID
            submissionID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
        }
        nextToken
        startedAt
    }
    readAccessGroup
    editAccessGroup
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    userMortgageRequirementId
    userMortgagePropertyId
    userMortgageExistingId
    userMortgageNeedsId
    userMortgageSettingsId
    userMortgageSolicitorId
    owner
`)
    const variables = {id}

    return performGQL(variables, query)
}
export default getMortgage