import React, {useContext, useEffect, useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import ClientHeader from "../../components/client/dashboard/ClientHeader";
import MortgageProvider from "../../providers/mortgage/MortgageProvider";
import ToolDrawerProvider from "../../providers/mortgage/ToolDrawerProvider";
import MortgageV1ToV2 from "../../providers/mortgage/MortgageV1ToV2";
import MortgageSidebarUpdater from "../../providers/mortgage/MortgageSidebarUpdater";
import {CacheProvider} from "../../../api/mortgage/OnDemandCache";
import ApplicationUploadsProvider from "../../providers/mortgage/ApplicationUploadsProvider";
import {VerifiedLenderTestFiguresProvider} from "../../components/client/application-stages/_common/lender-tests/v2/VerifiedLenderTests";

function Dashboard(props) {
    const params = useParams()
    return (

        <MortgageProvider key={params.mortgageId} id={params.mortgageId}>
            <VerifiedLenderTestFiguresProvider>
            <ApplicationUploadsProvider>
                <CacheProvider>
                    <MortgageSidebarUpdater/>
                    <MortgageV1ToV2>
                        <ToolDrawerProvider>

                            <div className="d-col h-100">
                                <ClientHeader/>
                                <Outlet/>
                            </div>
                        </ToolDrawerProvider>
                    </MortgageV1ToV2>
                </CacheProvider>
            </ApplicationUploadsProvider>
            </VerifiedLenderTestFiguresProvider>
        </MortgageProvider>

    );
}

export default Dashboard;