import React, {useContext, useEffect, useState} from 'react';

//import SteppingForm from "../../../ui/SteppingForm";
import {correctValueForDb} from "../../../../../providers/mortgage/MortgageProvider";
import {differenceInYears} from "date-fns";
import {EmploymentTypes} from "../../../../../../models";
import {isInPast, monthYearFormat} from "./validations";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";

export function employmentType(props) {
    return {
        label: "Employment Status",
        text: "What is " + props.you.your + " current employment status?",
        name: "employmentType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Employed",
                    value: "EMPLOYED"
                },
                {
                    text: "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text: "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text: "Student",
                    value: "STUDENT"
                }
            ]
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employment.employmentType`,
        saveOnChange: true
    }
}
export function employmentBasis(props) {
    return {
        text: "On what basis " + props.you.areYou + " employed?",
        label: "Employment Basis",
        name: "employmentBasis",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Full-time",
                    value: "FULLTIME"
                },
                {
                    text: "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text: "Part-time",
                    value: "PARTTIME"
                },
                {
                    text: "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
                {
                    text: "Contract Basis",
                    value: "CONTRACT"

                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employment.employmentBasis`
    }
}
export function positionHeld(props) {
    return {
        name: "positionHeld",
        label: "Position",
        text: "What is " + props.you.your + " position there?",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A job title is required"},
        ],
        target: `applicant${props.applicant}.employment.positionHeld`
    }
}
export function currentEmployerName(props) {
    return {
        label: "Employer",
        text: "With whom " + props.you.areYou + " currently employed?",
        name: "currentEmployerName",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A company name is required"},
        ],
        target: `applicant${props.applicant}.employment.currentEmployerName`
    }
}
export function currentEmployerAddress(props) {
    return {
        label: "Employer Address",
        text: "What is " + props.you.your + " employer's address?",
        name: "currentEmployerAddress",
        answer: {
            type: "address"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "An address is required"},
        ],
        target: `applicant${props.applicant}.employment.currentEmployerAddress`
    }
}
export function currentEmployerStartDate(props) {
    //TODO: change to MMYYYY
    return {
        label: "Employment Start Date",
        text: "When did " + props.you.you + " start employment there",
        name: "currentEmployerStartDate",
        answer: {
            type: "mmyyyy",
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employment.currentEmployerStartDate`
    }
}
export function currentEmployerPhone(props) {
    return {
        text: "What is " + props.you.your + " employer's contact number?",
        name: "currentEmployerPhone",
        label: "Phone Number",
        answer: {
            type: "phone"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A phone number is required`},
        ],
        target: `applicant${props.applicant}.employment.currentEmployerPhone`
    }
}
export function previousEmployerName(props) {
    return {
        label: "Previous Employer",
        text: "With whom " + props.you.wereYou + " previously employed?",
        name: "previousEmployerName",
        answer: {
            type: "text"
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                let d = correctValueForDb('mmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model: "employment",
        rules: [
            {required: true, message: `A company name is required`},
        ],
        target: `applicant${props.applicant}.employment.previousEmployerName`
    }
}
export function previousEmployerAddress(props) {
    return {
        label: "Previous Employer Address",
        text: "What was " + props.you.your + " previous employer's address?",
        name: "previousEmployerAddress",
        answer: {
            type: "address"
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                let d = correctValueForDb('mmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model: "employment",
        rules: [
            {required: true, message: `An address is required`},
        ],
        target: `applicant${props.applicant}.employment.previousEmployerAddress`
    }
}
export function limitedCompany(props) {
    return {
        text: "Is " + props.you.your + " business a limited company?",
        label: "Limited Company",
        name: "limitedCompany",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employment.limitedCompany`
    }
}
export function isDirector(props) {
    return {
        text: capitaliseFirst(props.you.areYou) + " a director or shareholder in the company?",
        label: "Director/Shareholder",
        name: "isDirector",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED){
                return true
            }
            return form.getFieldValue('limitedCompany') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employment.isDirector`
    }
}
export function percentageOfShares(props) {
    return {
        label: "Percentage Of Shares",
        text: "What percentage of shares " + props.you.doYou + " hold in the company?",
        name: "percentageOfShares",
        answer: {
            type: "float"
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED){
                return true
            }
            if (form.getFieldValue('limitedCompany') === false){
                return true
            }
            return form.getFieldValue('isDirector') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A percentage is required`},
            {type: 'number', min: 1, message: 'This must be a value greater than zero'},
            {type: 'number', max: 100, message: 'This must be a value maximum 100'},
        ],
        target: `applicant${props.applicant}.employment.percentageOfShares`
    }
}
export function nameOfBusiness(props) {
    return {
        label: "Business Name",
        text: "What is the business's trading name?",
        name: "nameOfBusiness",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A business name is required`},
        ],
        target: `applicant${props.applicant}.employment.nameOfBusiness`
    }
}
export function natureOfBusiness(props) {
    return {
        label: "Nature of Business",
        text: "What is the nature of " + props.you.your + " business?",
        name: "natureOfBusiness",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `applicant${props.applicant}.employment.natureOfBusiness`
    }
}
export function businessAddress(props) {
    return {
        label: "Business Address",
        text: "What is the business's address?",
        name: "businessAddress",
        answer: {
            type: "address"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `An address is required`},
        ],
        target: `applicant${props.applicant}.employment.businessAddress`
    }
}
export function businessEstablished(props) {
    return {
        label: "Business Established",
        text: "When was the business established?",
        name: "businessEstablished",
        answer: {
            type: "mmyyyy"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInPast},
        ],
        target: `applicant${props.applicant}.employment.businessEstablished`

    }
}
export function accountantName(props) {
    return {
        label: "Accountant",
        text: "Who is " + props.you.your + " accountant?",
        name: "accountantName",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `An accountant name is required`}
        ],
        target: `applicant${props.applicant}.employment.accountantName`
    }
}
export function accountantAddress(props) {
    return {
        label: "Accountant Address",
        text: "What is " + props.you.your + " accountant's address?",
        name: "accountantAddress",
        answer: {
            type: "address"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `An address is required`}
        ],
        target: `applicant${props.applicant}.employment.accountantAddress`
    }
}
export function accountantPhone(props) {
    return {
        label: "Accountant's Phone",
        text: "What is their phone number?",
        name: "accountantPhone",
        answer: {
            type: "phone"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A phone number is required`}
        ],
        target: `applicant${props.applicant}.employment.accountantPhone`
    }
}

export const applicantEmploymentSteps = ({mortgage,applicant})=>{
    const myEmploymentType = mortgage[`applicant${applicant}`].employment.employmentType
    const betterProps = {
        mortgage,
        applicant,
        you: mortgage[`applicant${applicant}`].strings
        //you: you(applicant === 1 ? false : mortgage.applicant2?.firstName ? mortgage.applicant2.firstName : 'the other applicant')
    }
    const steps = []
    if (myEmploymentType === EmploymentTypes.SELFEMPLOYED) {
        steps.push({
            label: 'Employment',
            title: `Tell us about ${betterProps.you.your} work`,
            questions: [
                employmentType(betterProps),
                limitedCompany(betterProps),
                isDirector(betterProps),
                percentageOfShares(betterProps),
            ]
        })
        steps.push({
            label: 'Business',
            title: `Tell us about ${betterProps.you.your} business`,
            questions: [
                nameOfBusiness(betterProps),
                natureOfBusiness(betterProps),
                businessAddress(betterProps),
                businessEstablished(betterProps),
            ]
        })
        steps.push({
            label: 'Accountant',
            title: `Tell us about ${betterProps.you.your} accountant`,
            questions: [
                accountantName(betterProps),
                accountantAddress(betterProps),
                accountantPhone(betterProps)
            ]
        })
    } else if (myEmploymentType === EmploymentTypes.EMPLOYED) {
        steps.push({
            label: 'Employment',
            title: `Tell us about ${betterProps.you.your} work`,
            questions: [
                employmentType(betterProps),
                employmentBasis(betterProps),
                positionHeld(betterProps),
            ]
        })
        steps.push({
            label: 'Employer',
            title: `Tell us about ${betterProps.you.your} employer`,
            questions: [
                currentEmployerName(betterProps),
                currentEmployerAddress(betterProps),
                currentEmployerPhone(betterProps),
                currentEmployerStartDate(betterProps),
                previousEmployerName(betterProps),
                previousEmployerAddress(betterProps),
            ]
        })
    } else {
        steps.push({
            label: 'Employment',
            title: `Tell us about ${betterProps.you.your} work`,
            questions: [
                employmentType(betterProps),
            ]
        })
    }
    return steps
}
function ApplicantEmployment(props) {
    const steps = applicantEmploymentSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    return <SectionForm key="ApplicantEmployment" {...props} questions={questions}/>

}

export default ApplicantEmployment;