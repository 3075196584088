import React from 'react';
import ApplicantsRowBuilder from "./ApplicantsRowBuilder";
import {CustomInput, CustomInputNumber} from "./CustomInputs";

function EditSalary({mortgage, figures}) {

    return <div>
        <table>
            <thead>
            <tr>
                <th></th>
                <th>{mortgage.twoApplicants && "Applicant 1"}</th>
                {mortgage.twoApplicants && <th>Applicant 2</th>}
                {mortgage.twoApplicants && <th>Total</th>}
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Sector</td>
                <td>
                    <CustomInput name="sector1" disabled={true}/>
                </td>
                {mortgage.twoApplicants && <td>
                    <CustomInput name="sector2" disabled={true}/>
                </td>}
                {mortgage.twoApplicants && <td>
                </td>}
            </tr>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Basic Salary" name="basic" type="euro" warning1={mortgage.figures.verified.basic1.warning} warning2={mortgage.figures.verified?.basic2?.warning} totalValue={figures.basicTotal}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Shift Allowance" name="allowances" type="euro" totalValue={figures.allowances1 + (figures?.allowances2||0) }/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Other Allowance" name="other" type="euro" totalValue={figures.other1 + (figures?.other2 || 0)}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Commission" name="commission" type="euro" totalValue={figures.commission1 + (figures?.commission2 || 0)}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Overtime" name="overtime" type="euro" totalValue={figures.overtime1 + (figures?.overtime2 || 0)}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Year 1 Bonus" name="year1Bonus" type="euro" totalValue={figures.year1Bonus1 + (figures.year1Bonus2 || 0)}/>
            <tr>
                <td>Total Variable</td>
                <td>{!mortgage.twoApplicants && <CustomInputNumber disabled={true} value={figures.variableTotal}/>}</td>
                {mortgage.twoApplicants && <td></td>}
                {mortgage.twoApplicants && <td><CustomInputNumber disabled={true}  value={figures.variableTotal}/></td>}
            </tr>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Year 2 Bonus" name="year2Bonus" type="euro"  totalValue={figures.year2Bonus1 + (figures.year2Bonus2 || 0)}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Year 3 Bonus" name="year3Bonus" type="euro"  totalValue={figures.year3Bonus1 + (figures.year3Bonus2 || 0)}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Pension" name="pension" type="euro"  totalValue={figures.pensionTotal}/>
            </tbody>
        </table>
    </div>
}

export default EditSalary;