import React from 'react';
import {Button, Popconfirm} from "antd";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../../../../providers/mortgage/useMortgageUploadService";
import useBackendUsers from "../../../../../../providers/mortgage/useBackendUsers";

function Problems(props) {
    const mortgage = useMortgage()
    const {me} = useBackendUsers()
    const undefinedProb = mortgage.progress.filter(p => !p.sectionName || p.sectionName.startsWith('undefined'))
    const noNameProb = mortgage.progress.filter(p => !p.sectionName)
    const uploadService = useMortgageUploadService()
    function findDuplicatesBySection(arr) {
        const groupedBySection = arr.reduce((acc, obj) => {
            // Create a composite key from 'sectionName' and 'sectionIndex'
            const key = `${obj.sectionType}-${obj.applicationStage}-${obj.sectionName}-${obj.sectionIndex}`;
            acc[key] = acc[key] || [];
            acc[key].push(obj);
            return acc;
        }, {});

        const duplicates = {};
        for (const key in groupedBySection) {

            if (groupedBySection[key].length > 1) {
                // If more than one object with the same key, add them to the duplicates array
                if (!duplicates.hasOwnProperty(key)) {
                    duplicates[key] = []
                }
                duplicates[key].push(...groupedBySection[key]);
            }
        }

        return duplicates;
    }

    function DuplicateItem({dup}) {
        const deleteDuplicate = () => {
            mortgage.mutate({
                delete: [
                    {target: 'progress', id: dup.id}
                ]
            })
        }
        return (
            <div key={dup.id}>
                {dup.id}
                <Button className="ms-15" onClick={deleteDuplicate}>Delete</Button>
                <div>More Requested: <strong>{dup.alertBody}</strong></div>
                <div>Checked: <strong>{dup.sectionChecked}</strong></div>
                <div>Submitted By User: <strong>{dup.submittedOn}</strong></div>
                <div>User Note: <strong>{dup.note?.join('    |     ')}</strong></div>
                <pre>{JSON.stringify(dup.metaData || {}, true, 2)}</pre>
            </div>
        )

    }

    const duplicates = findDuplicatesBySection(mortgage.progress);
    if (me.firstName === "Kevin") return null
    return (
        <div>
            {
                undefinedProb.length > 0 && (
                    <>
                        {
                            undefinedProb.map(item => (
                                <div key={item.id} className="p-15">
                                    <strong>Problem with undefined section {item.id}</strong>
                                    <DuplicateItem dup={item}/>
                                </div>

                            ))
                        }
                    </>
                )
            }
            {
                Object.keys(duplicates).length > 0 && (
                    <>
                        {
                            Object.keys(duplicates).map(item => (
                                <div key={item.id} className="p-15">
                                    <strong>Duplicate sections for: {item}</strong>
                                    {duplicates[item].map(dup => (<DuplicateItem dup={dup}/>))}
                                </div>
                            ))
                        }
                    </>
                )
            },
            {
                noNameProb.length > 0 && (
                    <>
                        {
                            noNameProb.map(item => (
                                <div key={item.id} className="p-15">
                                    <strong>Section without name {item.id}</strong>
                                </div>

                            ))
                        }
                    </>
                )

            }
            {
                mortgage.lostUploads.length > 0 && (
                    <>
                        {
                            mortgage.lostUploads.map(item => {
                                const open = () => uploadService.open(item)
                                const del = ()=>{
                                    mortgage.mutate({
                                        delete: [
                                            {target: 'uploads', id: item.id}
                                        ]
                                    })
                                }
                                return (
                                    <div key={item.id} className="p-15 d-row gap-9">
                                        <div className="d-col">
                                            <strong>Upload ({item.section}) - {item.status}</strong>
                                            FileName: {item.fileName}
                                        </div>
                                        <Button size="small" onClick={open}>Open</Button>
                                        <Popconfirm title={"Are you sure?"} onConfirm={del}>
                                            <Button size="small">Delete Upload</Button>
                                        </Popconfirm>

                                    </div>
                                )
                            })
                        }
                    </>
                )
            }
        </div>
    );
}

export default Problems;