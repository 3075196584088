import React, {useEffect} from 'react';
import {ApplicationTypes, AssetTypes, MortgageTypes} from "../../../../../../models";
import SectionForm from "../SectionForm";
import {applicantIncomeSteps} from "./ApplicantIncome";
import {newlyBuilt} from "./MortgageProperties";

// SETUP
export function mortgageType(props) {
    return {
        label: "Type of Mortgage",
        text: "Select your requirement",
        subtext: "Select the type of mortgage you need",
        name: "mortgageType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "New mortgage",
                    value: MortgageTypes.PURCHASE,
                },
                {
                    text: "Refinance an existing mortgage",
                    value: MortgageTypes.REFINANCE,
                }
            ]
        },
        model: "mortgage",
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `mortgageType`
    }
}
export function applicationTypeRefinance(props) {
    return {
        label: 'On Which Property',
        name: 'applicationTypeRefinance',
        text: 'What property will be on the mortgage?',
        model: 'mortgage',
        answer: {
            type: 'select',
            choices: [
                {
                    text: "My own home",
                    value: ApplicationTypes.OWNHOME,
                },
                {
                    text: "A home I rent out",
                    value: ApplicationTypes.LANDLORD,
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('mortgageType') === MortgageTypes.PURCHASE
        },
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `applicationType`
    }
}
export function applicationTypePurchase(props) {
    return {
        label: 'Application Type',
        name: 'applicationTypePurchase',
        text: 'What type of purchaser are you?',
        model: 'mortgage',
        hides: (form) => {
            return form.getFieldValue('mortgageType') === MortgageTypes.REFINANCE
        },
        answer: {
            type: 'select',
            choices: [
                {
                    text: "First Time Buyer",
                    value: ApplicationTypes.FIRSTTIME,
                },
                {
                    text: "Moving Home",
                    value: ApplicationTypes.MOVINGHOME,
                },
                {
                    text: "Buying To Let",
                    value: ApplicationTypes.LANDLORD,
                },
                {
                    text: "Second Time Buyer",
                    value: ApplicationTypes.SECONDTIME,
                }
            ]
        },
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `applicationType`
    }
}
export function propertyValue(props) {
    return {
        label: 'Property Value',
        name: 'propertyValue',
        text: `Enter the value/price of the property`,
        model: `properties`,
        answer: {
            type: 'euro',
            step: 1000
        },
        rules: [
            {required: true, message: `A value is required`},
            {type: 'number', min: 125000, message: 'Minimum amount is 125,000'},
            {type: 'number', max: 3000000, message: 'Maximum amount is 3,000,000'},
        ],
        target: `properties[${props.mortgage.properties[0].id}].propertyValue`,
    }
}
export function loanRequired(props) {
    const rules = (form) => {
        let r = [
            {type: 'number', min: 75000, message: 'Minimum amount is 75,000'},
        ]
        let val = form.getFieldValue('propertyValue')
        let maxLoan = val ? Math.round(val * 0.9) : 3000000
        r.push({
            type: 'number',
            max: maxLoan,
            message: 'Maximum amount is ' + maxLoan.toLocaleString('en-us')
        })
        return r
    }
    return {
        label: 'Loan Required',
        name: 'loanRequired',
        text: `Enter the loan amount required`,
        model: `properties`,
        answer: {
            type: 'euro',
            step: 1000
        },
        rules,
        target: `requirement.loanRequired`,
    }
}
export function ddBankAccountID(props) {
    const choices = []
    const accounts = []
    props.mortgage.applicant1.accounts.forEach(account=>{
        accounts.push({...account, owner: props.mortgage.applicant1.firstName})
    })
    props.mortgage.applicant1.assets.filter(it=>it.assetType===AssetTypes.SAVING).forEach(account=>{
        accounts.push({...account, owner: props.mortgage.applicant1.firstName})
    })
    if (props.mortgage.twoApplicants){
        props.mortgage.applicant2.accounts.forEach(account=>{
            accounts.push({...account, owner: props.mortgage.applicant2.firstName})
        })
        props.mortgage.applicant2.assets.filter(it=>it.assetType===AssetTypes.SAVING).forEach(account=>{
            accounts.push({...account, owner: props.mortgage.applicant2.firstName})
        })
    }

    accounts.forEach(account=>{
        let progress = props.mortgage.progress.find(it=>it.sectionIndex === account.id)
        if (progress && !!progress.metaData) {
            let meta = JSON.parse(progress.metaData)
            if (meta.hasOwnProperty('iban')){
                let text = `${meta.iban.slice(-8)}  ${account.institution} (${account.owner})`;
                choices.push({
                    value: account.id,
                    text
                })
            }
        }
    })

    return {
        label: 'DD Bank Account',
        name: 'ddBankAccountID',
        text: `Account For DD`,
        model: `requirement`,
        answer: {
            type: 'select',
            choices
        },
        target: `requirement.ddBankAccountID`,
    }
}
export function loanTerm(props) {
    return {
        label: 'Loan Term',
        name: 'loanTerm',
        text: `Enter the term of the loan`,
        model: `requirement`,
        answer: {
            type: 'int',
        },
        rules: [
            {type: 'number', max: 35, min: 5, message: 'Number between 5 and 35 required'}
        ],
        target: `requirement.loanTerm`,
    }
}
export function twoApplicants(props) {
    return {
        label: 'Number Of Applicants',
        name: 'twoApplicants',
        text: 'Choose whether there will be a secondary Applicant on the application',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: true,
                    text: 'Two',
                },
                {
                    value: false,
                    text: 'One',
                }
            ]
        },
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `twoApplicants`,
    }
}
export function applicant2Income(props) {
    return {
        label: 'Applicant 2 Income',
        name: 'applicant2Income',
        text: 'Does the other applicant have an income',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: true,
                    text: 'Yes',
                },
                {
                    value: false,
                    text: 'No',
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('twoApplicants') === false
        },
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `applicant2.financial.hasIncome`,
    }
}
export function cohabiting(props) {
    return {
        label: 'Cohabiting',
        name: 'cohabiting',
        text: 'Do you live with the other applicant?',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: true,
                    text: 'Yes',
                },
                {
                    value: false,
                    text: 'No',
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('twoApplicants') === false
        },
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `requirement.cohabiting`,
    }
}




// DEPENDENTS
export function countDependents(props) {
    return {
        label: "Number Dependents",
        text: (form) => {
            return `How many dependent children do you ${form.getFieldValue('twoApplicants') ? 'or the other applicant' : ''} have`
        },
        name: "countDependents",
        answer: {
            type: "select",
            choices: [
                {
                    text: "None",
                    value: 0
                },
                {
                    text: "One",
                    value: 1
                },
                {
                    text: "Two",
                    value: 2
                },
                {
                    text: "Three",
                    value: 3
                },
                {
                    text: "Four",
                    value: 4
                },
                {
                    text: "Five",
                    value: 5
                },
                {
                    text: "Six",
                    value: 6
                }
            ]
        },
        model: "personal",
        target: `applicant1.personal.countDependents`,
        rules: [
            {required: true, message: `A number of dependents is required`},
        ],
        watch: (form, value) => {
            let existing = form.getFieldValue('dependentDOBs') || []
            if (Array.isArray(existing)) {
                if (value > existing.length) {
                    let additional = Array(value - existing.length).fill(null)
                    form.setFieldValue('dependentDOBs', [...existing, ...additional])
                } else if (value < existing.length) {
                    let removal = existing.length - value
                    let nonEmpty = existing.filter(item => item !== "")
                    if (nonEmpty.length < existing.length) {
                        removal -= (existing.length - nonEmpty.length)
                    }
                    let remaining = nonEmpty.slice(0, nonEmpty.length - removal)
                    form.setFieldValue('dependentDOBs', remaining)
                }
            }
        }
    }
}
export function hasChildcareCosts() {
    return {
        label: "Has Child Minding Costs",
        text: (form) => {
            return `Do you  ${form.getFieldValue('twoApplicants') ? 'or the other applicant' : ''} have any child-minding costs?`
        },
        name: "hasChildcareCosts",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form) => {
            let value = form.getFieldValue('countDependents')
            return parseInt(value) === 0
        },
        model: "financial",
        target: `applicant1.financial.hasChildcareCosts`,
        rules: [
            {required: true, message: `An answer is required`},
        ]
    }
}
export function monthlyChildcareCosts(props) {
    return {
        label: 'Monthly Child-minding Costs',
        name: 'monthlyChildcareCosts',
        text: 'Enter the total amount of the monthly child-minding/creche/after care costs',
        model: 'mortgage',
        answer: {
            type: 'euro'
        },
        hides: (form) => {
            return form.getFieldValue('countDependents') === 0 || form.getFieldValue('hasChildcareCosts') !== true
        },
        rules: [
            {required: true, message: 'Enter your child-minding costs'},
            {type: 'number', min: 0, message: 'Minimum amount is 0'},
            {type: 'number', max: 12000, message: 'Maximum amount is 12,000'},
        ],
        target: `applicant1.financial.monthlyChildcareCosts`,
    }
}
export function dependentDOBs(props) {
    return {
        label: "Dependent DOBs",
        text: (form) => {
            let value = form.getFieldValue('countDependents')
            return value > 1 ? "What is the date of birth for each?" : "What is the date of birth"
        },
        name: "dependentDOBs",
        answer: {
            type: "agearray",
        },
        hides: (form) => {
            let value = form.getFieldValue('countDependents')
            return parseInt(value) === 0 || isNaN(parseInt(value))
        },
        model: "personal",
        target: `applicant1.personal.dependentDOBs`,
        rules: [
            {required: true, message: `Dates of birth are required`},
        ]
    }
}
// INCOMES
export function grossBasic(n, props) {
    let your = n === 1 ? 'your' : "the other applicant's"
    return {
        label: `Gross Basic Pay ${n === 2 ? n : ""}`,
        text: `What is ${your} gross basic salary per year?`,
        name: "grossBasic",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return n === 2 && (form.getFieldValue('twoApplicants') !== true || form.getFieldValue('applicant2Income') !== true);
        },
        model: "income",
        rules: [
            {type: 'number', min: 5000, message: 'Must be greater than €5,000'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${n}.income.grossBasic`
    }
}
export function overtime(n, props) {
    let doYou = n === 1 ? 'do you' : "does the other applicant"
    return {
        label: `Overtime Amount ${n === 2 ? n : ""}`,
        text: `How much over-time pay ${doYou} earn in a year?`,
        name: "overtime",
        answer: {
            type: "euro",
        },
        model: "income",
        hides: (form) => {
            return n === 2 && (form.getFieldValue('twoApplicants') !== true || form.getFieldValue('applicant2Income') !== true);
        },
        rules: [
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${n}.income.overtime`
    }
}
export function bonus(n, props) {
    let doYou = n === 1 ? 'do you' : "does the other applicant"
    return {
        label: `Bonus Amount ${n === 2 ? n : ""}`,
        text: "How much bonus " + doYou + " receive in a year?",
        name: "bonus",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return n === 2 && (form.getFieldValue('twoApplicants') !== true || form.getFieldValue('applicant2Income') !== true);
        },
        model: "income",
        rules: [
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${n}.income.bonus`
    }
}
export function commission(n, props) {
    let doYou = n === 1 ? 'do you' : "does the other applicant"
    return {
        label: `Commission Amount ${n === 2 ? n : ""}`,
        text: "How much commission " + doYou + " receive in a year?",
        name: "commission",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return n === 2 && (form.getFieldValue('twoApplicants') !== true || form.getFieldValue('applicant2Income') !== true);
        },
        model: "income",
        rules: [
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${n}.income.commission`
    }
}
export function other(n, props) {
    let doYou = n === 1 ? 'do you' : "does the other applicant"
    return {
        label: `Other Incomes Amount ${n === 2 ? n : ""}`,
        text: "How much from any other source " + doYou + " receive in a year?",
        name: "other",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return n === 2 && (form.getFieldValue('twoApplicants') !== true || form.getFieldValue('applicant2Income') !== true);
        },
        model: "income",
        rules: [
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${n}.income.other`
    }
}
// COMPONENT
export const mortgageSetupSteps = ({mortgage}) => {
    const betterProps = {
        mortgage,
        applicant: false,
    }

    let questions = []
    if (mortgage){

        const firstProperty = mortgage.properties.length > 0 ? mortgage.properties[0]: false
        if (firstProperty) {
            const propertyType = newlyBuilt({...betterProps, index: firstProperty.id})

            questions = [
                mortgageType(betterProps),
                applicationTypeRefinance(betterProps),
                applicationTypePurchase(betterProps),
                propertyType,
                loanTerm(betterProps),
                loanRequired(betterProps),
                twoApplicants(betterProps),
                cohabiting(betterProps)
            ]
        }
    }
    return [
        {
            label: 'Setup',
            title: `Mortgage Setup`,
            questions
        }
    ]

}
function MortgageSetup(props) {
    const steps = mortgageSetupSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    return <SectionForm title="Mortgage Setup" key="MortgageSetup" {...props} questions={questions}/>
}

export default MortgageSetup;