import React from 'react';
import PageHeader from "../../components/PageHeader";
import {useParams} from "react-router-dom";
import LenderSubmissionDocumentTable from "../../components/client/documents/LenderSubmissionDocumentTable";
import useMortgage from "../../providers/mortgage/useMortgage";
import MortgageDocuments from "../../components/client/documents/MortgageDocuments";
function ApplicationDocumentsPage() {
    return (
        <div>
            <div className="d-col gap-15 p-15">
                <MortgageDocuments />


            </div>

        </div>
    );
}

export default ApplicationDocumentsPage;