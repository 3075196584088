import React, {useEffect, useState} from 'react';
import {AssetTypes, DebtTypes, EmploymentTypes, Frequencies} from "../../../../../../models";
import {Alert, Badge, Button, Checkbox, Form, InputNumber, Popconfirm, Select} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../../assets/scripts/parser-functions";
import usePtsbXls from "./ptsb/usePtsbXls";
import useHavenXls from "./haven/useHavenXls";
import useAvantXls from "./avant/useAvantXls";
import useIcsXls from "./ics/useIcsXls";
import dayjs from "dayjs";
import {green, volcano} from "@ant-design/colors";
import {LoadingOutlined} from "@ant-design/icons";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";
import {useBoiXls} from "./boi/useBoiXls";
//import useNuaWebForm from "./nua/useNuaWebForm";

export const getMortgageFigures = (mortgage, useVerifiedFigures) => {
    const legacy = !mortgage.applicant1.employmentIncome?.length || !mortgage.applicant1.employmentIncome[0].employmentType
    let basic1, basic2, overtime1, overtime2, allowances1, allowances2, bonus1, bonus2, commission1, commission2, other1, other2, sector1, sector2
    let jobs1 = []
    let jobs2 = []
    if (legacy) {
        sector1 = mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED ? 'self' : 'private'
        sector2 = mortgage.twoApplicants && mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED ? 'self' : 'private'

        basic1 = mortgage.applicant1.income.grossBasic
        basic2 = mortgage.twoApplicants && [
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(mortgage.applicant2.employment.employmentType) ? mortgage.applicant2.income.grossBasic : null

        overtime1 = mortgage.applicant1.income?.incomeChecks?.includes('OVERTIME') ? mortgage.applicant1.income.overtime : 0
        overtime2 = mortgage.twoApplicants && mortgage.applicant2.income?.incomeChecks?.includes('OVERTIME') ? mortgage.applicant2.income.overtime : 0

        allowances1 = mortgage.applicant1.income?.incomeChecks?.includes('ALLOWANCES') ? mortgage.applicant1.income.allowances : 0
        allowances2 = mortgage.twoApplicants && mortgage.applicant2.income?.incomeChecks?.includes('ALLOWANCES') ? mortgage.applicant2.income.allowances : 0

        bonus1 = mortgage.applicant1.income?.incomeChecks?.includes('BONUS') ? mortgage.applicant1.income.bonus : 0
        bonus2 = mortgage.twoApplicants && mortgage.applicant2.income?.incomeChecks?.includes('BONUS') ? mortgage.applicant2.income.bonus : 0

        commission1 = mortgage.applicant1.income?.incomeChecks?.includes('COMMISSION') ? mortgage.applicant1.income.commission : 0
        commission2 = mortgage.twoApplicants && mortgage.applicant2.income?.incomeChecks?.includes('COMMISSION') ? mortgage.applicant2.income.commission : 0

        other1 = mortgage.applicant1.income?.incomeChecks?.includes('OTHER') ? mortgage.applicant1.income.other : 0
        other2 = mortgage.twoApplicants && mortgage.applicant2.income?.incomeChecks?.includes('OTHER') ? mortgage.applicant2.income.other : 0
    }
    else {
        let cumulative1 = {}

        mortgage.applicant1.employmentIncome.forEach(it => {
            if ([
                EmploymentTypes.EMPLOYED,
                EmploymentTypes.SELFEMPLOYED
            ].includes(it.employmentType) && it.incomes)
            {
                let myInnerObject = {basic: it.grossBasic || 0}
                it.incomes.forEach(income => {
                    if (income.guaranteed) {
                        if (!cumulative1.hasOwnProperty(income.name)) {
                            cumulative1[income.name] = 0
                        }
                        myInnerObject[income.name] = income.amount
                        cumulative1[income.name] += income.amount
                    }

                })
                jobs1.push(myInnerObject)
            }
        })
        let cumulative2 = {}

        mortgage.applicant2.employmentIncome.forEach(it => {
            if ([
                EmploymentTypes.EMPLOYED,
                EmploymentTypes.SELFEMPLOYED
            ].includes(it.employmentType) && it.incomes)
            {
                let myInnerObject = {basic: it.grossBasic || 0}
                it.incomes.forEach(income => {
                    if (income.guaranteed) {
                        if (!cumulative2.hasOwnProperty(income.name)) {
                            cumulative2[income.name] = 0
                        }
                        myInnerObject[income.name] = income.amount
                        cumulative2[income.name] += income.amount
                    }
                })
                jobs2.push(myInnerObject)
            }
        })
        let basics1 = mortgage.applicant1.employmentIncome.map(it => it.grossBasic || 0)
        basic1 = basics1.reduce((a, b) => a + b, 0)
        let basics2 = mortgage.twoApplicants ? mortgage.applicant2.employmentIncome.map(it => it.grossBasic || 0) : []
        basic2 = basics2.reduce((a, b) => a + b, 0)

        sector1
            = mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !!it.publicSector) ? 'public' : mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !it.publicSector) ? 'private' : 'self'
        sector2
            = mortgage.twoApplicants ? mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !!it.publicSector) ? 'public' : mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !it.publicSector) ? 'private' : 'self' : null

        overtime1 = cumulative1['overtime'] || 0
        overtime2 = cumulative2['overtime'] || 0

        allowances1 = cumulative1['shiftAllowance'] || 0
        allowances2 = cumulative2['shiftAllowance'] || 0

        bonus1 = cumulative1['bonus'] || 0
        bonus2 = cumulative2['bonus'] || 0

        commission1 = cumulative1['commission'] || 0
        commission2 = cumulative2['commission'] || 0

        other1 = cumulative1['otherAllowance'] || 0
        other2 = cumulative2['otherAllowance'] || 0
    }

    let savings1 = mortgage.applicant1.financial.hasCurrentAccountSavings ? mortgage.applicant1.financial.monthlySavings || 0 : 0
    let savings2 = mortgage.twoApplicants && mortgage.applicant2.financial.hasCurrentAccountSavings ? mortgage.applicant2.financial.monthlySavings || 0 : 0

    let filter = asset => asset.assetType === AssetTypes.SAVING && [
        Frequencies.MONTHLY,
        Frequencies.WEEKLY
    ].includes(asset.increasesFrequency)
    mortgage.applicant1.assets.filter(filter).forEach(asset => {
        if (asset.increasesFrequency === Frequencies.MONTHLY) {
            savings1 += asset.increases
        }
        else {
            savings1 += (asset.increases * 52 / 12)
        }
    })
    if (mortgage.twoApplicants) {
        mortgage.applicant2.assets.filter(filter).forEach(asset => {
            if (asset.increasesFrequency === Frequencies.MONTHLY) {
                savings2 += asset.increases
            }
            else {
                savings2 += (asset.increases * 52 / 12)
            }
        })
    }

    const loans1 = mortgage.applicant1.debts.filter(debt => debt.debtType === DebtTypes.LOAN)
    const loans2 = mortgage.twoApplicants ? mortgage.applicant2.debts.filter(debt => debt.debtType === DebtTypes.LOAN) : []

    const clearingLoans1 = loans1.filter(loan => !!loan.clearedBeforeMortgage).map(loan => loan.id)
    const clearingLoans2 = loans2.filter(loan => !!loan.clearedBeforeMortgage).map(loan => loan.id)

    const pension1 = 0
    const pension2 = 0
    return {
        propertyValue : mortgage.properties[0].propertyValue,
        mortgageAmount: mortgage.requirement.loanRequired,
        term          : mortgage.requirement.loanTerm,
        sector1,
        sector2,
        basic1,
        basic2,
        overtime1,
        overtime2,
        allowances1,
        allowances2,
        bonus1,
        bonus2,
        other1,
        other2,
        commission1,
        commission2,
        savings1,
        savings2,
        loans1,
        loans2,
        clearingLoans1,
        clearingLoans2,
        pension1,
        pension2,
        jobs1,
        jobs2,
        rest1         : overtime1 + allowances1 + bonus1 + commission1 + other1,
        rest2         : overtime2 + allowances2 + bonus2 + commission2 + other2

    }
}
function usePlayground({mortgage}) {
    const [playgroundForm] = Form.useForm()
    const apis = {
        ptsb : usePtsbXls(),
        haven: useHavenXls(),
        avant: useAvantXls(),
        ics  : useIcsXls(),
        boi: useBoiXls(),
      //  nua: useNuaWebForm()
    }
    const [state, setState] = React.useState({
        formData       : null,
        selectedLenders: [],
        playground     : null,
        estimating     : true
    })
    const {me} = useBackendUsers()

    const [updating, setUpdating] = useState(false)
    useEffect(() => {
        // switch between live view and previous test
        let selectedTest = state.playground === 'live' ? null : mortgage.playgroundTests.find(it => it.id === state.playground)
        if (selectedTest) {
            let data = JSON.parse(selectedTest.data || '{}')
            const {
                      playground,
                      ...rest
                  } = data
            if (playground) {
                playgroundForm.setFieldsValue(playground)
            }
            let results = JSON.parse(selectedTest.results || '{}')
            Object.keys(results).forEach((lenderName, index) => {
                apis[lenderName].forceSet({
                    results: results[lenderName],
                    values : rest[lenderName]
                })
            })
            setState(s => ({
                ...s,
                selectedLenders: Object.keys(results),
            }))
        }
        else {
            const data = getMortgageFigures(mortgage)
            playgroundForm.setFieldsValue(data)
            Object.keys(apis).forEach(lenderName => {
                apis[lenderName].setPlaygroundData(data)
            })
            setState(s => ({
                ...s,
                selectedLenders: [
                    'ptsb',
                    'haven',
                    'avant',
                    'ics',
                    'boi',
                 //   'nua'
                ],
            }))
        }
    }, [state.playground]);
    useEffect(() => {
        let exisitingPlayground = mortgage.playgroundTests?.length ? mortgage.playgroundTests.reduce((prev, current) => {
            return new Date(prev.updatedAt) > new Date(current.updatedAt) ? prev : current;
        }) : null;
        if (exisitingPlayground) {
            exisitingPlayground.d = JSON.parse(exisitingPlayground.data)
            exisitingPlayground.r = JSON.parse(exisitingPlayground.results)
            exisitingPlayground.d.playground.loans1 = mortgage.applicant1.debts.filter(debt => debt.debtType === DebtTypes.LOAN)
            exisitingPlayground.d.playground.loans2 = mortgage.twoApplicants ? mortgage.applicant2.debts.filter(debt => debt.debtType === DebtTypes.LOAN) : []

            const {
                      playground,
                      ...rest
                  } = exisitingPlayground.d

            playgroundForm.setFieldsValue(playground)
            setState(s => ({
                formData       : playground,
                selectedLenders: Object.keys(rest),
                playground     : exisitingPlayground.id
            }))
        }
        else {
            setState(s => ({
                ...s,
                playground: 'live',
                formData  : getMortgageFigures(mortgage)
            }))
        }
    }, []);
    const setPlayground = (playground) => {
        setState(s => ({
            ...s,
            playground
        }))
    }
    const handleDeleteSelectedTest = async () => {
        if (state.playground !== 'live') {
            mortgage.mutate({
                delete: {
                    target: 'playgroundTests',
                    id    : state.playground
                }
            })
            setState(s => ({
                ...s,
                playground: 'live'
            }))
        }
    }
    const setSelectedLenders = (lenders) => {
        setState(s => ({
            ...s,
            selectedLenders: lenders
        }))
    }
    const lenders = state.selectedLenders.map(lender => {
        return {
            key  : lender,
            label: lender,
            api  : apis[lender]
        }
    })
    const Playground = () => {
        const playgroundsOptions = [
            {
                label: 'Live',
                value: 'live'
            },
            ...mortgage.playgroundTests.filter(it => !!it.results).sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
            }).map(it => {
                const date = dayjs(it.updatedAt).format('DDMMMYY H:mm')
                let results = JSON.parse(it.results)
                let icons = Object.keys(results).map(lenderCode => {
                    let allPassed = results[lenderCode]?.every(item => !item.hasOwnProperty('passed') || item.passed)
                    let color = allPassed ? green[4] : volcano[4]
                    return <Badge key={it.name} style={{fontWeight: 'bold'}} size="small" count={lenderCode[0]} color={color}/>
                })
                return {
                    label: (<div className="d-row a-center gap-3 j-between">
                        {date}
                        <div className="d-row gap-3">
                            {icons}
                        </div>
                    </div>),
                    value: it.id,
                }
            })
        ]
        const handleSubmit = async (values) => {

            try {
                const valid = await playgroundForm.validateFields()

                let promises = []
                let data = {
                    playground: valid
                }
                state.selectedLenders.forEach(lender => {
                    const api = apis[lender.toLowerCase()]
                    data[lender.toLowerCase()] = api.state.values
                    promises.push(api.getResults())
                })

                const test = await mortgage.mutate({
                    create: {
                        target: 'playgroundTests',
                        pairs : {
                            data      : JSON.stringify(data),
                            mortgageID: mortgage.id
                        }
                    }
                })

                const promised = await Promise.all(promises)

                const results = {}
                state.selectedLenders.forEach((lender, index) => {
                    results[lender] = promised[index]
                })
                let updates = [
                    {
                        target: `playgroundTests[${test[0].id}].results`,
                        value : JSON.stringify(results)
                    }
                ]
                if (mortgage.applicationStage === 'SETUP') {
                    updates.push({
                        target: 'completedFlowItemsForAdmin',
                        value : JSON.stringify({
                            ...mortgage.completedFlowItemsForAdmin,
                            initialAffordabilityTested: {
                                on: new Date().toISOString(),
                                by: me.sub
                            }
                        })
                    })
                }
                await mortgage.mutate({
                    update: updates
                })
                setState(s => ({
                    ...s,
                    playground: test[0].id
                }))
            }
            catch (e) {
                console.log({e})

            }
        }

        const handleFormChange = async () => {
            setUpdating(true)
            const allValues = await playgroundForm.validateFields()
            lenders.forEach((lender, i) => {

                lender.api.setPlaygroundData(allValues)
            })
            await async function () {
                return new Promise(resolve => {
                    setTimeout(resolve, 600)
                })
            }()
            setUpdating(false)

            // clearTimeout(formChangeTimer.current)
            // formChangeTimer.current = setTimeout(() => {
            //     lenders.forEach(lender => {
            //         lender.api.setPlaygroundData(allValues)
            //     })
            // },300)
        }

        if (!state.formData) {
            return null
        }
        return (<div>
            <Form
                form={playgroundForm}
                layout="horizontal"
            >

                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td style={{whiteSpace: 'nowrap'}}>
                            Test
                        </td>
                        <td colSpan={2}>
                            <Select style={{width: '100%'}} options={playgroundsOptions} value={state.playground} onSelect={setPlayground}/>
                        </td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: 'nowrap'}}>
                            Loan Amount
                        </td>
                        <td colSpan={2}>
                            <Form.Item
                                noStyle
                                name="mortgageAmount"
                            >
                                <InputNumber style={{width: '100%'}} prefix="€"
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td style={{whiteSpace: 'nowrap'}}>
                            Property Value
                        </td>
                        <td colSpan={2}>
                            <Form.Item
                                noStyle
                                name="propertyValue"
                            >
                                <InputNumber style={{width: '100%'}} prefix="€"

                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Term
                        </td>
                        <td colSpan={2}>
                            <Form.Item
                                noStyle
                                name="term"
                            >
                                <InputNumber style={{width: '100%'}} suffix="years"

                                />
                            </Form.Item>
                        </td>
                    </tr>
                    {mortgage.twoApplicants && (

                        <tr>
                            <th>

                            </th>
                            <th>
                                App 1
                            </th>

                            <th>
                                App 2
                            </th>

                        </tr>)}

                    <tr>
                        <td>
                            Employment
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="sector1"
                            >
                                <Select style={{width: '100%'}} options={[
                                    {
                                        label: 'Public',
                                        value: 'public'
                                    },
                                    {
                                        label: 'Private',
                                        value: 'private'
                                    },
                                    {
                                        label: 'Self',
                                        value: 'self'
                                    },
                                ]}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="sector2"
                            >
                                <Select style={{width: '100%'}} options={[
                                    {
                                        label: 'Public',
                                        value: 'public'
                                    },
                                    {
                                        label: 'Private',
                                        value: 'private'
                                    },
                                    {
                                        label: 'Self',
                                        value: 'self'
                                    },
                                ]}/>
                            </Form.Item>
                        </td>)}

                    </tr>
                    <tr>
                        <td>
                            Basic
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="basic1"
                            >
                                <InputNumber prefix="€" style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>

                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="basic2"
                            >
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}


                    </tr>

                    <tr>
                        <td>
                            Overtime
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="overtime1"
                            >
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="overtime2"
                            >
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}
                    </tr>
                    <tr>
                        <td>
                            Allowances
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="allowances1"
                            >
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="allowances2"
                            >
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}

                    </tr>
                    <tr>
                        <td>
                            Commission
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="commission1">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="commission2">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}
                    </tr>
                    <tr>
                        <td>
                            Bonus
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="bonus1">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="bonus2">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}
                    </tr>
                    <tr>
                        <td>
                            Other
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="other1">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="other2">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}
                    </tr>
                    <tr>
                        <td>
                            Savings
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="savings1">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="savings2">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}

                    </tr>
                    <tr>
                        <td>
                            Pension
                        </td>
                        <td>
                            <Form.Item
                                noStyle
                                name="pension1">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item
                                noStyle
                                name="pension2">
                                <InputNumber prefix="€"
                                             style={{width: '100%'}}
                                             formatter={currencyFormatter}
                                             parser={currencyParser}/>
                            </Form.Item>
                        </td>)}

                    </tr>
                    <tr>
                        <td>
                            Clear Loans
                        </td>
                        <td>
                            <Form.Item name="clearingLoans1" label={false} className="mb-0">
                                <Checkbox.Group>
                                    {state.formData.loans1.map((option) => (<Checkbox key={option.id}
                                                                                      value={option.id}>{option.monthlyPayment?.toEuro()}</Checkbox>))}
                                </Checkbox.Group>
                            </Form.Item>
                        </td>
                        {mortgage.twoApplicants && (<td>
                            <Form.Item name="clearingLoans2" label={false} className="mb-0">
                                <Checkbox.Group>
                                    {state.formData.loans2.map((option) => (<Checkbox key={option.id}
                                                                                      value={option.id}>{option.monthlyPayment.toEuro()}</Checkbox>))}
                                </Checkbox.Group>
                            </Form.Item>
                        </td>)}
                    </tr>
                    </tbody>
                </table>
            </Form>
            <Form
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Lenders"
                    className="mb-0"
                >
                    <Checkbox.Group options={[
                        {
                            label: 'PTSB',
                            value: 'ptsb'
                        },
                        {
                            label: 'Haven',
                            value: 'haven'
                        },
                        {
                            label: 'Avant',
                            value: 'avant'
                        },
                        {
                            label: 'ICS',
                            value: 'ics'
                        },
                        {
                            label: 'BOI',
                            value: 'boi'
                        },
                        // {
                        //     label: 'Nua',
                        //     value: 'nua'
                        // }
                    ]} value={state.selectedLenders} onChange={setSelectedLenders}/>
                </Form.Item>
                <Alert message="Click update to load values" type="warning" className="my-6"/>
                <div className="d-row j-between">

                    {state.playground !== 'live' && (<Popconfirm title="Are you sure you want to delete this test?" onConfirm={handleDeleteSelectedTest}>
                        <Button danger>Delete</Button>
                    </Popconfirm>)}
                    <Button.Group>
                        {updating && <LoadingOutlined className="me-12"/>}
                        <Button onClick={handleFormChange}>
                            Update
                        </Button>
                        <Button htmlType="submit" className="ms-auto" type="primary">Test</Button>
                    </Button.Group>

                </div>

            </Form>

        </div>)
    }

    return {
        selected: state.playground,
        Playground,
        lenders
    }
}

export default usePlayground;