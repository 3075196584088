import React from 'react';
import usePreApprovalAdminDocuments from "./admin-documents/usePreApprovalAdminDocuments";
import DocumentsTable from "../../_common/documents/DocumentsTable";
import {ApplicationStages} from "../../../../../../models";
import {Alert, Button, Card, Popconfirm} from "antd";
import MortgageForm from "../../../../data-capture/MortgageForm";
import PreApprovedAdminUploads from "./PreApprovedAdminUploads";
import usePreApprovalAdminUploads from "./admin-uploads/usePreApprovalAdminUploads";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function PreApprovalAdminDocuments(props) {
    const items = usePreApprovalAdminDocuments()
    const mortgage = useMortgage()
    let sections = usePreApprovalAdminUploads()
    const generationsDone = items.every(it => it.generated)
    const uploadsDone = sections.every(it => it.uploaded) && sections.length > 0

    function ProductSelectedSuccessAlert() {
        const handleClearProduct = () => {
            mortgage.mutate({
                update: {
                    target: 'requirement',
                    pairs : {
                        lenderProduct: null,
                        rateId       : null
                    }
                }
            })
        }
        let product = mortgage.requirement.lenderProduct
        if (mortgage.activeSubmission?.provisionalProduct) {
            product = mortgage.activeSubmission.provisionalProduct
        }

        const content = (<div className="d-row j-between">
                <div>Product selected: {product}</div>
                <Popconfirm title={"Are you sure?"} onConfirm={handleClearProduct}>
                    <Button size="small">Delete Selected Product</Button>
                </Popconfirm>
            </div>)

        return <Alert message={content} type="success"/>
    }

    return <Card>
        <div className="d-col gap-15">
            {!!mortgage.requirement.lenderProduct ? (<ProductSelectedSuccessAlert/>) : (
                <Alert message={"Product has not been selected"} type="warning"/>)}


            {uploadsDone ? <Alert message={"All documents have been uploaded"} type="success"/> : <Alert
                message={"Upload your docusigned documents here"} type="info"/>}

            <PreApprovedAdminUploads sections={sections}/>

            {generationsDone ? <Alert message={"All documents have been generated"} type="success"/> : <Alert
                message={"Generate the loan offer request application"} type="info"/>}


            <Card styles={{body: {padding: 0}}}>
                <DocumentsTable items={items} stage={ApplicationStages.PREAPPROVAL}
                                done="createPreApprovalAdminDocuments"/>
            </Card>
            <div className="d-row j-end">
                <Button>Loan Offer Request Email</Button>
            </div>
        </div>

    </Card>

}

export default PreApprovalAdminDocuments;