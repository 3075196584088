import React, {useMemo} from 'react';
import useMortgage from "../../../../providers/mortgage/useMortgage";
import {Col, Row, Skeleton} from "antd";
import SectionsList from "./SectionsList";
import ApplicantPersonal from "./sections/ApplicantPersonal";
import ApplicantEmployment from "./sections/ApplicantEmployment";
import ApplicantIncome from "./sections/ApplicantIncome";
import ApplicantDebts from "./sections/ApplicantDebts";
import ApplicantAssets from "./sections/ApplicantAssets";
import ApplicantProperties from "./sections/ApplicantProperties";
import MortgageSetup from "./sections/MortgageSetup";
import MortgageDeposit from "./sections/MortgageDeposit";
import MortgageProperties from "./sections/MortgageProperties";
import MortgageNeeds from "./sections/MortgageNeeds";
import ApplicantAccounts from "./sections/ApplicantAccounts";
import ApplicantEmploymentIncome from "./sections/ApplicantEmploymentIncome";

function SectionsDashboard(props) {
    const mortgage = useMortgage()

    const memoizedOutlet = useMemo(()=>{
        switch (props.sectionName) {
            case "personal-1" :
                return <ApplicantPersonal key="personal-1" mortgage={mortgage} applicant={1}/>
            case "personal-2" :
                return <ApplicantPersonal key="personal-2" mortgage={mortgage} applicant={2}/>
            case "employment-income-1":
                return <ApplicantEmploymentIncome key="employment-income-1" mortgage={mortgage} applicant={1} index={props?.sectionIndex}/>
            case "employment-income-2":
                return <ApplicantEmploymentIncome key="employment-income-2" mortgage={mortgage} applicant={2} index={props?.sectionIndex}/>
            case "employment-1" :
                return <ApplicantEmployment key="employment-1" mortgage={mortgage} applicant={1}/>
            case "employment-2" :
                return <ApplicantEmployment key="employment-2" mortgage={mortgage} applicant={2}/>
            case "income-1" :
                return <ApplicantIncome key="income-1" mortgage={mortgage} applicant={1}/>
            case "income-2" :
                return <ApplicantIncome key="income-2" mortgage={mortgage} applicant={2}/>
            case "current-accounts-1" :
                return <ApplicantAccounts key="current-accounts-1" mortgage={mortgage} applicant={1} index={props?.sectionIndex}/>
            case "current-accounts-2" :
                return <ApplicantAccounts key="current-accounts-2" mortgage={mortgage} applicant={2} index={props?.sectionIndex}/>
            case "debts-1" :
                return <ApplicantDebts key="debts-1" mortgage={mortgage} applicant={1} index={props?.sectionIndex}/>
            case "debts-2" :
                return <ApplicantDebts key="debts-2" mortgage={mortgage} applicant={2} index={props?.sectionIndex}/>
            case "assets-1" :
                return <ApplicantAssets key="assets-1" mortgage={mortgage} applicant={1} index={props?.sectionIndex}/>
            case "assets-2" :
                return <ApplicantAssets key="assets-2" mortgage={mortgage} applicant={2} index={props?.sectionIndex}/>
            case "properties-1" :
                return <ApplicantProperties key="properties-1" mortgage={mortgage} applicant={1} index={props?.sectionIndex}/>
            case "properties-2" :
                return <ApplicantProperties key="properties-2" mortgage={mortgage} applicant={2} index={props?.sectionIndex}/>
            case "setup" :
                return <MortgageSetup key="setup" mortgage={mortgage} applicant={false}/>
            case "deposit" :
                return <MortgageDeposit key="deposit" mortgage={mortgage} applicant={false}/>
            case "properties" :
                return <MortgageProperties key="properties" mortgage={mortgage} applicant={false} index={props?.sectionIndex}/>
            case "needs" :
                return <MortgageNeeds key="needs" mortgage={mortgage} applicant={false} />

            default:
                return null
        }
    },[props.sectionName,props.sectionIndex])

    return <Skeleton loading={!mortgage || mortgage==='fetching' }>
        <div className="d-row gap-12">
            {!!mortgage && mortgage!=='fetching' && (
                <Row className="w-100">
                    <Col lg={10}>
                        <SectionsList key="sectionsList123" active={props?.sectionName}/>
                    </Col>
                    <Col lg={14}>
                        {
                            !!props.sectionName && (
                                <div className="grow  p-sticky m-24">
                                    {memoizedOutlet}
                                </div>

                            )
                        }

                    </Col>


                </Row>
            )}


        </div>
    </Skeleton>
}

export default SectionsDashboard;