import React from 'react';
import useMortgage from "./useMortgage";
import useBackendUsers from "./useBackendUsers";
import {Auth} from "aws-amplify";
import {Avatar, Dropdown, Tag} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {gray, green} from "@ant-design/colors";

import {LONGDATE} from "../../../assets/scripts/constants";
import dayjs from "dayjs";

function useAdminStages(props) {
    const mortgage = useMortgage()
    const backendUsers = useBackendUsers()
    const getComplete = (stage, name) => {
        if (mortgage && mortgage.stageTasks) {
            let row = mortgage.stageTasks.find(item => item.stage === stage && item.name.startsWith(name))
            if (row && row.completedOn) {
                return {on: row.completedOn, by: backendUsers.getBySub(row.completedBy)}
            }
        }
        return false
    }
    const exists = (stage, key) => {
        return !!mortgage.stageTasks.find(item => item.stage === stage && item.name.startsWith(key))
    }
    const setComplete = async (stage, key, updateLastFlowItem = false) => {
        const me =backendUsers.me
        let row = mortgage.stageTasks.find(item => item.stage === stage && item.name.startsWith(key))
        let updateMortgageLastFlowItem = false
        if (updateLastFlowItem) {
            updateMortgageLastFlowItem ={
                target: ``,
                pairs: {
                    lastFlowItem: `${stage}.${key}`,
                    lastFlowItemTime: new Date().toISOString()
                }
            }
        }

        if (row) {
            const update = [
                {
                    target: `stageTasks[${row.id}]`,
                    pairs: {
                        completedOn: new Date().toISOString(),
                        completedBy: me.sub
                    }
                }
            ]
            if (!!updateMortgageLastFlowItem){
                update.push(updateMortgageLastFlowItem)
            }
            mortgage.mutate({update})
        } else {
            const mutation = {
                create: {
                    target: 'stageTasks',
                    pairs: {
                        mortgageID: mortgage.id,
                        stage: stage,
                        name: key,
                        completedOn: new Date().toISOString(),
                        completedBy: me.sub
                    }
                }
            }
            if (!!updateMortgageLastFlowItem){
                mutation.update = updateMortgageLastFlowItem
            }
            mortgage.mutate(mutation)
        }
    }
    const setInComplete = async (stage, key) => {
        let row = mortgage.stageTasks.find(item => item.stage === stage && item.name.startsWith(key))
        mortgage.mutate({
            update: {
                target: `stageTasks[${row.id}]`,
                pairs: {
                    completedOn: null,
                    completedBy: null
                }
            }
        })
    }
    const getReminderHistory = (stage, key) => {
        let i = 1
        let indexedKey = `${key}.${i}`

        return [{
            key: indexedKey,
            label: `Reminder will send on`,
            completed: false,
        }]
    }
    function DoneButton({stage, name}) {
        let complete = getComplete(stage, name)
        const doIncomplete = () => {
            setInComplete(stage, name)
        }
        const doComplete = () => {
            setComplete(stage, name)
        }
        return <div className="d-row a-center gap-15">
            {!!complete && (
                <>
                    <Tag className="m-0">{complete.by.firstName}</Tag>
                    <>{dayjs(complete.on).format(LONGDATE)}</>
                </>
            )}

            <Dropdown menu={{
                items: [{
                    key: 'markDone',
                    label: complete ? 'Mark Incomplete' : 'Mark Complete',
                    onClick: complete ? doIncomplete : doComplete
                }]
            }}>


                <Avatar icon={<CheckOutlined/>} size="small" style={{backgroundColor: complete ? green[4] : gray[4]}}/>


            </Dropdown>

        </div>
    }
    return {
        exists,
        setComplete,
        getComplete,
        getReminderHistory,
        DoneButton
    };
}

export default useAdminStages;