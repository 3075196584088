import dayjs from "dayjs";
import {addBunchToMap, getBunch, getMeta} from "../overlay-utils";
import {
    BuildType, DebtTypes, EmploymentBases, EmploymentTypes, MortgageTypes, PersonalMaritalStatuses
} from "../../../../models";
import {legacyIncome} from "../../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";

const elements = [
    {
        "name": "affordability-calculator-included",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app-1-signed-declaration",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app-2-signed-declaration",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-3years-eds",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-contract",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-cv",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-eds",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-payslips",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-poa",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-salary-cert",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-salary-cert",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app1-valid-id",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-3years-eds",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-contract",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-cv",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-payslips",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-poa",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "app2-valid-id",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "applicant-1-payslips-eds",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "applicant-2-eds-year-1",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "applicant-2-payslips-eds",
        "type": "CHECK",
        "page": 1
    },
    {
        "name": "applicant-name-1",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "applicant-name-2",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "broker-email",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "broker-name",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "broker-phone",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-1",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-2",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-3",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-4",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-5",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-6",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-7",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "mortgage-account-8",
        "type": "TEXT",
        "page": 1
    },
    {
        "name": "app1-switch-ca-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-eds",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-payslips",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-salary-cert",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-se-certified-accounts",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-se-form11",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-se-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app1-switch-se-tax-confirmation",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-ca-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-eds",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-payslips",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-salary-cert",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-se-certified-accounts",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-se-form11",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-se-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "app2-switch-se-tax-confirmation",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-business-loan-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-business-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-certified-accounts",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-current-account-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-form-11",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-mortgage-statements-se",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-savings-account-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-1-tax-confirmation",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-business-loan-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-business-statements",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-certified-accounts",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-current-account-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-form-11",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-mortgage-statements-se",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-savings-account-statement",
        "type": "CHECK",
        "page": 2
    },
    {
        "name": "applicant-2-tax-confirmation",
        "type": "CHECK",
        "page": 2
    },
]
export default function (mortgage, config) {
    let legacy = legacyIncome(mortgage)
    let app1PAYE = legacy ? mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED : !!mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED)
    let app2PAYE = mortgage.twoApplicants ? legacy ? mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED : !!mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED) : false
    let app1Contract = legacy ? mortgage.applicant1.employment.employmentBasis === EmploymentBases.CONTRACT : mortgage.applicant1.employmentIncome.find(it => it.employmentBasis === EmploymentBases.CONTRACT)
    let app2Contract = mortgage.twoApplicants ? legacy ? mortgage.applicant2.employment.employmentBasis === EmploymentBases.CONTRACT : mortgage.applicant2.employmentIncome.find(it => it.employmentBasis === EmploymentBases.CONTRACT) : false
    let app1SelfEmployed = legacy ? mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED : !!mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED)
    let app2SelfEmployed = mortgage.twoApplicants ? legacy ? mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED : !!mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED) : false

    const brokerDetails = () => {
        let map = {}
        map['broker-name'] = `Truss Mortgages Ltd T/A Suss'd Mortgages`
        map['broker-email'] = `help@sussd.ie`
        map['broker-phone'] = `01 685-4888`
        return {map}
    }

    const mortgageLoanAccountNumber = () => {
        let map = {}
        let ask = []
        const digits = config.hasOwnProperty('mortgageLoanAccountNumber') ? config.mortgageLoanAccountNumber : mortgage.requirement.mortgageAccountNo
        ask.push({
            label: 'Mortgage Loan Account Number',
            name : 'mortgageLoanAccountNumber',
            type : 'TEXT',
            group: 'ACCOUNT NUMBER'
        })
        if (digits) {
            addBunchToMap(map, 'mortgage-account-', digits.split(''))
        }
        return {
            map,
            ask
        }
    }
    const applicantNames = () => {
        let map = {}
        let errors = []
        let app1Name = mortgage.applicant1.fullName
        if (!app1Name) {
            errors.push('No applicant 1 name')
        }
        else {
            map['applicant-name-1'] = app1Name
        }

        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.fullName
            if (!app2Name) {
                errors.push('No applicant 2 name')
            }
            else {
                map['applicant-name-2'] = app2Name
            }

        }
        return {
            map,
            errors
        }
    }
    const todaysDate = () => {
        let map = {}
        let str = config.hasOwnProperty('todaysDate') ? config.todaysDate : dayjs().format('YYYY-MM-DD')
        const parts = str.split('-')
        addBunchToMap(map, 'date-y-', parts[0].split(''))
        addBunchToMap(map, 'date-m-', parts[1].split(''))
        addBunchToMap(map, 'date-d-', parts[2].split(''))
        return {map}
    }

    // STANDARD DOCUMENTS
    const signedDeclarations = () => {
        let map = {}
        map['app-1-signed-declaration'] = true
        if (mortgage.twoApplicants) {
            map['app-2-signed-declaration'] = true
        }
        return {map}
    }
    const affordabilityCalculatorIncluded = () => {
        let map = {}
        map['affordability-calculator-included'] = true
        return {map}
    }
    const stampedCCRDocuments = () => {
        let map = {}
        let errors = []
        if (legacy) {
            if (!mortgage.applicant1.employment.employmentType) {
                errors.push('No employment type for applicant 1')
            }
            else {
                map['applicant-1-payslips-eds'] = mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED
            }

            if (mortgage.twoApplicants) {
                if (!mortgage.applicant2.employment.employmentType) {
                    errors.push('No employment type for applicant 2')
                }
                else {
                    map['applicant-2-payslips-eds'] = mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED
                }
            }
        }
        else {
            map['applicant-1-payslips-eds'] = !!mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED)
            if (mortgage.twoApplicants) {
                map['applicant-2-payslips-eds'] = !!mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED)
            }
        }
        return {
            map,
            errors
        }
    }

    // IDENTITY DOCUMENTS
    const validId = () => {
        let map = {}
        map['app1-valid-id'] = true
        map['app1-poa'] = true
        if (mortgage.twoApplicants) {
            map['app2-valid-id'] = true
            map['app2-poa'] = true
        }
        return {map}
    }

    // PAYE EMPLOYMENT
    const salaryCert = () => {
        let map = {}
        let errors = []
        if (app1PAYE) {
            map['app1-salary-cert'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-salary-cert'] = true
            }
        }
        return {
            map,
            errors
        }
    }
    const applicantPayslips = () => {
        let map = {}
        let errors = []
        if (app1PAYE) {
            map['app1-payslips'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-payslips'] = true
            }
        }
        return {
            map,
            errors
        }
    }
    const mostRecentEDS = () => {
        let map = {}
        let errors = []
        if (app1PAYE) {
            map['app1-eds'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-eds'] = true
            }
        }
        return {
            map,
            errors
        }
    }

    // CONTACTED EMPLOYMENT
    const employmentContract = () => {
        let map = {}
        let ask = []
        if (app1PAYE && app1Contract) {
            map['app1-contract'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE && app2Contract) {
                map['app2-contract'] = true
            }
        }
        return {
            map,
            ask
        }
    }
    const cvStyleBackground = () => {

        let map = {}
        let ask = []
        if (app1PAYE && app1Contract) {
            map['app1-cv'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE && app2Contract) {
                map['app2-cv'] = true
            }

            return {
                map,
                ask
            }
        }
    }
    const contractorsRecentEDS = () => {
        let map = {}
        let errors = []
        if (app1PAYE && app1Contract) {
            map['app1-3years-eds'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE && app2Contract) {
                map['app2-3years-eds'] = true
            }
        }
        return {
            map,
            errors
        }
    }

    // SELF EMPLOYED
    const certifiedAccounts = () => {
        let errors = []
        let map = {}
        if (app1SelfEmployed) {
            map['applicant-1-certified-accounts'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['applicant-2-certified-accounts'] = true
            }
        }
        return {
            map,
            errors
        }
    }
    const form11 = () => {
        let errors = []
        let map = {}
        if (app1SelfEmployed) {
            map['applicant-1-form-11'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['applicant-2-form-11'] = true
            }
        }
        return {
            map,
            errors
        }
    }
    const businessLoans = () => {
        let map = {}
        if (app1SelfEmployed) {
            map['applicant-1-business-loan-statement'] = true

        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['applicant-2-business-loan-statement'] = true

            }
        }
        return {map}
    }
    const businessBankStatements = () => {
        let map = {}
        let errors = []
        if (app1SelfEmployed) {

            map['applicant-1-business-statements'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {

                map['applicant-2-business-statements'] = true
            }
        }
        return {
            map,
            errors
        }

    }
    const taxConfirmation = () => {
        let map = {}
        let errors = []
        if (app1SelfEmployed) {
            map['applicant-1-tax-confirmation'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['applicant-2-tax-confirmation'] = true
            }
        }
        return {
            map,
            errors
        }
    }

    // PERSONAL FINANCES
    const currentAccounts = () => {
        let map = {}
        let errors = []
        map['applicant-1-current-account-statement'] = true
        if (mortgage.twoApplicants) {
            map['applicant-2-current-account-statement'] = true
        }
        return {
            map,
            errors
        }
    }
    const savingsAccounts = () => {
        let map = {}
        let errors = []
        if (mortgage.applicant1.assets.length > 0) {
            map['applicant-1-savings-account-statement'] = true
        }
        if (mortgage.twoApplicants) {
            if (mortgage.applicant2.assets.length > 0) {
                map['applicant-2-savings-account-statement'] = true
            }
        }
        return {
            map,
            errors
        }
    }
    const mortgageAccounts = () => {
        let errors = []
        let map = {}
        if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
            map['applicant-1-mortgage-statements-se'] = true
        }
        else {
            if (mortgage.applicant1.properties.length > 0) {
                map['applicant-1-mortgage-statements-se'] = true
            }
            if (mortgage.twoApplicants) {
                if (mortgage.applicant2.properties.length > 0) {
                    map['applicant-2-mortgage-statements-se'] = true
                }
            }
        }
        return {
            map,
            errors
        }
    }
    // const loanStatements = () => {
    //     let errors = []
    //     let map = {}
    //     const app1From = []
    //     const app1To = []
    //     mortgage.applicant1.debts.filter(it => it.debtType === DebtTypes.LOAN).forEach((account, i) => {
    //         const data1 = getMeta(mortgage, 'APPLY', `debts-1`, 'dateRangeStart', account.id)
    //         if (!data1) {
    //             errors.push(`No loan account (${account.title}) date for applicant 1`)
    //         }
    //         else {
    //             app1From.push(dayjs(data1, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //
    //         }
    //
    //         const data2 = getMeta(mortgage, 'APPLY', `debts-1`, 'dateRangeEnd', account.id)
    //         if (!data2) {
    //             errors.push(`No loan account (${account.title}) date for applicant 1`)
    //         }
    //         else {
    //             app1To.push(dayjs(data2, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //         }
    //     })
    //     if (app1From.length === 0) {
    //         map[`applicant-1-loan-statements-date-from`] = 'N/A'
    //     }
    //     else {
    //         map[`applicant-1-loan-statements-date-from`] = app1From.join('   ')
    //         map[`applicant-1-loan-statements-date-to`] = app1To.join('   ')
    //     }
    //     if (mortgage.twoApplicants) {
    //         const app2From = []
    //         const app2To = []
    //         mortgage.applicant2.debts.filter(it => it.debtType === DebtTypes.LOAN).forEach((account, i) => {
    //             const data1 = getMeta(mortgage, 'APPLY', `debts-2`, 'dateRangeStart', account.id)
    //             if (!data1) {
    //                 errors.push(`No loan account (${account.title}) date for applicant 2`)
    //             }
    //             else {
    //                 app2From.push(dayjs(data1, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //             }
    //
    //             const data2 = getMeta(mortgage, 'APPLY', `debts-2`, 'dateRangeEnd', account.id)
    //             if (!data2) {
    //                 errors.push(`No loan account (${account.title}) date for applicant 2`)
    //             }
    //             else {
    //                 app2To.push(dayjs(data2, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //             }
    //         })
    //         if (app2From.length === 0) {
    //             map[`applicant-2-loan-statements-date-from`] = 'N/A'
    //         }
    //         else {
    //             map[`applicant-2-loan-statements-date-from`] = app2From.join('   ')
    //             map[`applicant-2-loan-statements-date-to`] = app2To.join('   ')
    //         }
    //     }
    //     return {
    //         map,
    //         errors
    //     }
    // }
    // const creditCardStatements = () => {
    //     let map = {}
    //     let errors = []
    //     const app1From = []
    //     const app1To = []
    //     mortgage.applicant1.debts.filter(it => it.debtType === DebtTypes.CREDITCARD).forEach((account, i) => {
    //         const data1 = getMeta(mortgage, 'APPLY', `debts-1`, 'dateRangeStart', account.id)
    //         if (!data1) {
    //             errors.push(`No credit card (${account.title}) date for applicant 1`)
    //         }
    //         else {
    //             app1From.push(dayjs(data1, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //         }
    //
    //         const data2 = getMeta(mortgage, 'APPLY', `debts-1`, 'dateRangeEnd', account.id)
    //         if (!data2) {
    //             errors.push(`No credit card (${account.title}) date for applicant 1`)
    //         }
    //         else {
    //             app1To.push(dayjs(data2, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //         }
    //     })
    //     if (app1From.length === 0) {
    //         map[`applicant-1-creditcard-statements-date-from`] = 'N/A'
    //     }
    //     else {
    //         map[`applicant-1-creditcard-statements-date-from`] = app1From.join('   ')
    //         map[`applicant-1-creditcard-statements-date-to`] = app1To.join('   ')
    //     }
    //     if (mortgage.twoApplicants) {
    //         const app2From = []
    //         const app2To = []
    //         mortgage.applicant2.debts.filter(it => it.debtType === DebtTypes.CREDITCARD).forEach((account, i) => {
    //             const data1 = getMeta(mortgage, 'APPLY', `debts-2`, 'dateRangeStart', account.id)
    //             if (!data1) {
    //                 errors.push(`No credit card (${account.title}) date for applicant 2`)
    //             }
    //             else {
    //                 app2From.push(dayjs(data1, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //             }
    //
    //             const data2 = getMeta(mortgage, 'APPLY', `debts-2`, 'dateRangeEnd', account.id)
    //             if (!data2) {
    //                 errors.push(`No credit card (${account.title}) date for applicant 2`)
    //             }
    //             else {
    //                 app2To.push(dayjs(data2, 'YYYY-MM-DD').format('DD/MM/YYYY'))
    //             }
    //         })
    //         if (app2From.length === 0) {
    //             map[`applicant-2-creditcard-statements-date-from`] = 'N/A'
    //         }
    //         else {
    //             map[`applicant-2-creditcard-statements-date-from`] = app2From.join('   ')
    //             map[`applicant-2-creditcard-statements-date-to`] = app2To.join('   ')
    //         }
    //
    //     }
    //     return {
    //         map,
    //         errors
    //     }
    // }
    //
    // // SELF BUILD
    // const letterOfSupervision = () => {
    //     let map = {}
    //     let ask = []
    //     if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
    //         let resultOfYesNo = 'YES'
    //         let val = config.hasOwnProperty('letterOfSupervision') ? config.letterOfSupervision : resultOfYesNo
    //         map['letter-of-supervision'] = val
    //         ask.push({
    //             label: 'Letter of supervision',
    //             name : 'letterOfSupervision',
    //             type : 'TEXT',
    //             group: 'SELF BUILD'
    //         })
    //     }
    //     else {
    //         map['letter-of-supervision'] = 'N/A'
    //     }
    //     return {
    //         map,
    //         ask
    //     }
    // }
    // const fixedPriceContract = () => {
    //     let map = {}
    //     let ask = []
    //     if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
    //         let resultOfYesNo = 'YES'
    //         let val = config.hasOwnProperty('fixedPriceContract') ? config.fixedPriceContract : resultOfYesNo
    //         map['fixed-price-contract'] = val
    //         ask.push({
    //             label: 'Fixed price contract',
    //             name : 'fixedPriceContract',
    //             type : 'TEXT',
    //             group: 'SELF BUILD'
    //         })
    //     }
    //     else {
    //         map['fixed-price-contract'] = 'N/A'
    //     }
    //     return {
    //         map,
    //         ask
    //     }
    // }
    // const engineersPi = () => {
    //     let map = {}
    //     let ask = []
    //     if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
    //         let resultOfYesNo = 'YES'
    //         let val = config.hasOwnProperty('engineersPi') ? config.engineersPi : resultOfYesNo
    //         map['engineers-pi'] = val
    //         ask.push({
    //             label: 'Engineers PI',
    //             name : 'engineersPi',
    //             type : 'TEXT',
    //             group: 'SELF BUILD'
    //         })
    //     }
    //     else {
    //         map['engineers-pi'] = 'N/A'
    //     }
    //     return {
    //         map,
    //         ask
    //     }
    // }
    // const notificationGrantWithConditions = () => {
    //     let map = {}
    //     let ask = []
    //     if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
    //         let resultOfYesNo = 'YES'
    //         let val = config.hasOwnProperty('notificationGrantWithConditions') ? config.notificationGrantWithConditions : resultOfYesNo
    //         map['notification-grant-with-conditions'] = val
    //         ask.push({
    //             label: 'Notification grant with conditions',
    //             name : 'notificationGrantWithConditions',
    //             type : 'TEXT',
    //             group: 'SELF BUILD'
    //         })
    //     }
    //     else {
    //         map['notification-grant-with-conditions'] = 'N/A'
    //     }
    //     return {
    //         map,
    //         ask
    //     }
    // }
    // const finalGrantOfPlanningPermission = () => {
    //     let map = {}
    //     let ask = []
    //     if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
    //         let resultOfYesNo = 'YES'
    //         let val = config.hasOwnProperty('finalGrantOfPlanningPermission') ? config.finalGrantOfPlanningPermission : resultOfYesNo
    //         map['final-grant-of-planning-permission'] = val
    //         ask.push({
    //             label: 'Final grant of planning permission',
    //             name : 'finalGrantOfPlanningPermission',
    //             type : 'TEXT',
    //             group: 'SELF BUILD'
    //         })
    //     }
    //     return {
    //         map,
    //         ask
    //     }
    // }

    //SWITCHERS - PAYE
    const switcherPayeSalaryCert = () => {
        let map = {}
        if (app1PAYE) {
            map['app1-switch-salary-cert'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-switch-salary-cert'] = true
            }
        }
        return {map}
    }
    const switcherPayePayslips = () => {
        let map = {}
        if (app1PAYE) {
            map['app1-switch-payslips'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-switch-payslips'] = true
            }
        }
        return {map}
    }
    const switcherPayeEDS = () => {
        let map = {}
        if (app1PAYE) {
            map['app1-switch-eds'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-switch-eds'] = true
            }
        }
        return {map}
    }
    const switcherPayeCurrentAccounts = () => {
        let map = {}
        if (app1PAYE) {
            map['app1-switch-ca-statements'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2PAYE) {
                map['app2-switch-ca-statements'] = true
            }
        }
        return {map}
    }
    // const switcherPayeLoanStatements = () => {
    //     let map = {}
    //     if (mortgage.mortgageType === MortgageTypes.REFINANCE && mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED) {
    //         map['switch-applicant-1-six-mortgage-and-loan-statements'] = 'YES'
    //     }
    //     else {
    //         map['switch-applicant-1-six-mortgage-and-loan-statements'] = 'N/A'
    //     }
    //     if (mortgage.twoApplicants) {
    //         if (mortgage.mortgageType === MortgageTypes.REFINANCE && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
    //             map['switch-applicant-2-six-mortgage-and-loan-statements'] = 'YES'
    //         }
    //         else {
    //             map['switch-applicant-2-six-mortgage-and-loan-statements'] = 'N/A'
    //         }
    //     }
    //     return {map}
    // }

    //SWITCHER - SELFEMOPLYED
    const switcherSelfEmployedCertifiedAccounts = () => {
        let map = {}
        if (app1SelfEmployed) {
            map['app1-switch-se-certified-accounts'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['app2-switch-se-certified-accounts'] = true
            }
        }
        return {map}
    }
    const switcherSelfEmployedForm11 = () => {
        let map = {}
        if (app1SelfEmployed) {
            map['app1-switch-se-form11'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['app2-switch-se-form11'] = true
            }
        }
        return {map}
    }
    const switcherSelfEmployedCurrentAccounts = () => {
        let map = {}
        if (app1SelfEmployed) {
            map['app1-switch-se-statements'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['app2-switch-se-statements'] = true
            }
        }
        return {map}
    }
    // const switcherSelfEmployedLoanStatements = () => {
    //     let map = {}
    //     if (mortgage.mortgageType === MortgageTypes.REFINANCE && mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
    //         map['switch-applicant-1-six-months-mortgage-and-loan-statements'] = 'YES'
    //     }
    //     else {
    //         map['switch-applicant-1-six-months-mortgage-and-loan-statements'] = 'N/A'
    //     }
    //     if (mortgage.twoApplicants) {
    //         if (mortgage.mortgageType === MortgageTypes.REFINANCE && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
    //             map['switch-applicant-2-six-months-mortgage-and-loan-statements'] = 'YES'
    //         }
    //         else {
    //             map['switch-applicant-2-six-months-mortgage-and-loan-statements'] = 'N/A'
    //         }
    //     }
    //     return {map}
    // }
    const switcherSelfEmployedTaxConfirmation = () => {
        let map = {}
        if (app1SelfEmployed) {
            map['app1-switch-se-tax-confirmation'] = true
        }
        if (mortgage.twoApplicants) {
            if (app2SelfEmployed) {
                map['app2-switch-se-tax-confirmation'] = true
            }
        }

        return {map}
    }

    const sections = [
        brokerDetails(),
        mortgageLoanAccountNumber(),
        applicantNames(),
        // todaysDate(),

        //STANDARD
        signedDeclarations(),
        affordabilityCalculatorIncluded(),
        stampedCCRDocuments(),

        //ID
        validId(),
    ]
    if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
        sections.push(switcherPayeSalaryCert(), switcherPayePayslips(), switcherPayeEDS(), switcherPayeCurrentAccounts())
        sections.push(switcherSelfEmployedCertifiedAccounts(), switcherSelfEmployedForm11(), switcherSelfEmployedCurrentAccounts(), switcherSelfEmployedTaxConfirmation())
    }
    else {
        if (app1PAYE || app2PAYE) {
            //PAYE
            sections.push(salaryCert(), applicantPayslips(), mostRecentEDS())
        }
        if ((app1PAYE && app1Contract) || (app2PAYE && app2Contract)) {
            //CONTRACTORS
            sections.push(employmentContract(), cvStyleBackground(), contractorsRecentEDS())
        }
        if (app1SelfEmployed || app2SelfEmployed) {
            //SELF EMPLOYED
            sections.push(certifiedAccounts(), form11(), businessLoans(), businessBankStatements(), taxConfirmation())
        }
        sections.push(currentAccounts(), savingsAccounts(), mortgageAccounts())
    }
    //CONTRACTORS
    //
    // //PERSONAL STATEMENTS
    // currentAccounts(),
    // savingsAccounts(),
    // mortgageAccounts(),
    // loanStatements(),
    // creditCardStatements(),

    //SELFBUILD
    // letterOfSupervision(),
    // fixedPriceContract(),
    // engineersPi(),
    // notificationGrantWithConditions(),
    // finalGrantOfPlanningPermission(),

    //ADDITIONAL DOCS
    // returnToWorkNoSalaryChangeLetter(),

    //FX DOCS
    // foreignCreditCheck(),
    // fxCvStyleBackground(),
    // fxRecentEDS(),

    //SEPARATED OR DIVORCED
    // separationAgreement(),

    //DEPOSIT
    // depositSavings(),
    // depositReceipts(),
    // depositSharesCertificate(),
    // giftLetter(),
    // helpToBuyScheme(),

    //RESIDENCY
    // stamp1(),
    // stamp4(),

    //IDENTITY
    // photoID(),
    // proofOfAddress(),

    //NON PAYE TAX POSITION
    // rosChargesAndPayments(),
    // letterOfConfirmationFromAccountant(),
    // payeMostRecentStatementOfLiabilities(),
    // selfEmployedAccountsLetterOfNoDebt(),

    //SWITCHERS - PAYE
    // switcherPayeSalaryCert(),
    // switcherPayePayslips(),
    // switcherPayeCurrentAccounts(),
    // switcherPayeLoanStatements(),

    //SWITCHERS - SELF EMPLOYED
    // switcherSelfEmployedCertifiedAccounts(),
    // switcherSelfEmployedForm11(),
    // switcherSelfEmployedCurrentAccounts(),
    // switcherSelfEmployedLoanStatements(),
    // switcherSelfEmployedTaxConfirmation()

    return {sections}
}

//
// // ADDITIONAL DOCUMENTS
// const returnToWorkNoSalaryChangeLetter = () => {
//     let map = {}
//     let ask = []
//     if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED) {
//         map['applicant-1-return-to-work-letter'] = config.hasOwnProperty('app1ReturnToWorkNoSalaryChangeLetter') ? config.app1ReturnToWorkNoSalaryChangeLetter : 'N/A'
//         ask.push({
//             label: 'Applicant 1 return to work letter',
//             name : 'app1ReturnToWorkNoSalaryChangeLetter',
//             type : 'TEXT',
//             group: 'ADDITIONAL DOCUMENTS'
//         })
//     }
//     else {
//         map['applicant-1-return-to-work-letter'] = 'N/A'
//     }
//     if (mortgage.twoApplicants) {
//         if (mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
//             map['applicant-2-return-to-work-letter'] = config.hasOwnProperty('app2ReturnToWorkNoSalaryChangeLetter') ? config.app2ReturnToWorkNoSalaryChangeLetter : 'N/A'
//             ask.push({
//                 label: 'Applicant 2 return to work letter',
//                 name : 'app2ReturnToWorkNoSalaryChangeLetter',
//                 type : 'TEXT',
//                 group: 'ADDITIONAL DOCUMENTS'
//             })
//         }
//         else {
//             map['applicant-2-return-to-work-letter'] = 'N/A'
//         }
//     }
//     return {
//         map,
//         ask
//     }
// }
//
// // FX DOCUMENTS
// const foreignCreditCheck = () => {
//     let map = {}
//     let ask = []
//
//     map['applicant-1-foreign-credit-check'] = config.hasOwnProperty('app1ForeignCreditCheck') ? config.app1ForeignCreditCheck : 'N/A'
//     ask.push({
//         label: 'Applicant 1 foreign credit check',
//         name : 'app1ForeignCreditCheck',
//         type : 'TEXT',
//         group: 'FX DOCUMENTS'
//     })
//
//     if (mortgage.twoApplicants) {
//
//         map['applicant-2-foreign-credit-check'] = config.hasOwnProperty('app2ForeignCreditCheck') ? config.app2ForeignCreditCheck : 'N/A'
//         ask.push({
//             label: 'Applicant 2 foreign credit check',
//             name : 'app2ForeignCreditCheck',
//             type : 'TEXT',
//             group: 'FX DOCUMENTS'
//         })
//
//     }
//     return {
//         map,
//         ask
//     }
// }
// const fxCvStyleBackground = () => {
//     let map = {}
//     let ask = []
//
//     map['applicant-1-cv-style-fx-background'] = config.hasOwnProperty('app1FxCvStyleBackground') ? config.app1FxCvStyleBackground : 'N/A'
//     ask.push({
//         label: 'Applicant 1 fx cv style background',
//         name : 'app1FxCvStyleBackground',
//         type : 'TEXT',
//         group: 'FX DOCUMENTS'
//     })
//
//     if (mortgage.twoApplicants) {
//
//         map['applicant-2-cv-style-fx-background'] = config.hasOwnProperty('app2FxCvStyleBackground') ? config.app2FxCvStyleBackground : 'N/A'
//         ask.push({
//             label: 'Applicant 2 fx cv style background',
//             name : 'app2FxCvStyleBackground',
//             type : 'TEXT',
//             group: 'FX DOCUMENTS'
//         })
//
//     }
//     return {
//         map,
//         ask
//     }
// }
// const fxRecentEDS = () => {
//     let map = {}
//     let ask = []
//
//     ask.push({
//         label: 'Applicant 1 fx recent eds',
//         name : 'app1fxRecentEDS',
//         type : 'TEXTAREA',
//         group: 'FX DOCUMENTS'
//
//     })
//     if (config.hasOwnProperty('app1fxRecentEDS')) {
//         let years = config.app1fxRecentEDS.split('\n')
//         addBunchToMap(map, 'applicant-1-fx-eds-year-', years)
//     }
//     else {
//         map['applicant-1-fx-eds-year-1'] = 'N/A'
//     }
//
//     if (mortgage.twoApplicants) {
//         ask.push({
//             label: 'Applicant 2 fx recent eds',
//             name : 'app2fxRecentEDS',
//             type : 'TEXT',
//             group: 'FX DOCUMENTS'
//         })
//         if (config.hasOwnProperty('app2fxRecentEDS')) {
//             let years = config.app1fxRecentEDS.split('\n')
//             addBunchToMap(map, 'applicant-2-fx-eds-year-', years)
//         }
//         else {
//             map['applicant-2-fx-eds-year-1'] = 'N/A'
//         }
//     }
//     return {
//         map,
//         ask
//     }
// }
//
// // SEPARATED OR DIVORCED
// const separationAgreement = () => {
//     let map = {}
//     let ask = []
//     if (mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED) {
//         map['applicant-1-separation-agreement'] = config.hasOwnProperty('app1SeparationAgreement') ? config.app1SeparationAgreement : 'N/A'
//         ask.push({
//             label: 'Applicant 1 separation agreement',
//             name : 'app1SeparationAgreement',
//             type : 'TEXT',
//             group: 'SEPARATED OR DIVORCED'
//         })
//     }
//     else {
//         map['applicant-1-separation-agreement'] = 'N/A'
//     }
//     if (mortgage.twoApplicants) {
//         if (mortgage.applicant2.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED) {
//             map['applicant-2-separation-agreement'] = config.hasOwnProperty('app2SeparationAgreement') ? config.app2SeparationAgreement : 'N/A'
//             ask.push({
//                 label: 'Applicant 2 separation agreement',
//                 name : 'app2SeparationAgreement',
//                 type : 'TEXT',
//                 group: 'SEPARATED OR DIVORCED'
//             })
//         }
//         else {
//             map['applicant-2-separation-agreement'] = 'N/A'
//         }
//     }
//     return {
//         map,
//         ask
//     }
// }
//
// //SOURCE OF FUNDS
// const depositReceipts = () => {
//     let map = {}
//     let errors = []
//     if (!mortgage.requirement.depositSavings) {
//         errors.push('No cash deposit')
//     }
//     else {
//         map['source-of-deposit'] = 'YES'
//     }
//     return {
//         map,
//         errors
//     }
// }
// const depositSharesCertificate = () => {
//     let map = {}
//     let ask = []
//     ask.push({
//         label: 'Deposit shares certificate',
//         name : 'depositSharesCertificate',
//         type : 'TEXT',
//         group: 'SOURCE OF FUNDS'
//     })
//     map['share-certificates'] = config.hasOwnProperty('depositSharesCertificate') ? config.depositSharesCertificate : 'N/A'
//     return {
//         map,
//         ask
//     }
// }
// const giftLetter = () => {
//     let map = {}
//     if (mortgage.requirement.receivingGifts) {
//         map['gift-letter-template'] = 'YES'
//     }
//     else {
//         map['gift-letter-template'] = 'N/A'
//     }
//     return {map}
// }
// const depositSavings = () => {
//     let map = {}
//     let errors = []
//     if (!mortgage.requirement.depositSavings) {
//         errors.push('No cash deposit')
//     }
//     else {
//         map['applicant-1-savings'] = 'YES'
//     }
//     return {
//         map,
//         errors
//     }
// }
// const helpToBuyScheme = () => {
//     let map = {}
//     if (mortgage.requirement.helpToBuyScheme === "TRUE") {
//         map['help-to-buy-scheme-details'] = 'YES'
//     }
//     else {
//         map['help-to-buy-scheme-details'] = 'N/A'
//     }
//     return {map}
// }
//
// //RESIDENCY
// const stamp1 = () => {
//     let map = {}
//     let ask = []
//     ask.push({
//         label: 'Applicant 1 Stamp 1',
//         name : 'app1stamp1',
//         type : 'TEXT',
//         group: 'RESIDENCY'
//     })
//     map['applicant-1-residency-stamp-1'] = config.hasOwnProperty('app1stamp1') ? config.app1stamp1 : 'N/A'
//     if (mortgage.twoApplicants) {
//         ask.push({
//             label: 'Applicant 2 Stamp 1',
//             name : 'app2stamp1',
//             type : 'TEXT',
//             group: 'RESIDENCY'
//         })
//         map['applicant-2-residency-stamp-1'] = config.hasOwnProperty('app2stamp1') ? config.app2stamp1 : 'N/A'
//     }
//     return {
//         map,
//         ask
//     }
// }
// const stamp4 = () => {
//     let map = {}
//     let ask = []
//     ask.push({
//         label: 'Applicant 1 Stamp 4',
//         name : 'app1stamp4',
//         type : 'TEXT',
//         group: 'RESIDENCY'
//     })
//     map['applicant-1-residency-stamp-4'] = config.hasOwnProperty('app1stamp4') ? config.app1stamp4 : 'N/A'
//     if (mortgage.twoApplicants) {
//         ask.push({
//             label: 'Applicant 2 Stamp 4',
//             name : 'app2stamp4',
//             type : 'TEXT',
//             group: 'RESIDENCY'
//         })
//         map['applicant-2-residency-stamp-4'] = config.hasOwnProperty('app2stamp4') ? config.app2stamp4 : 'N/A'
//     }
//     return {
//         map,
//         ask
//     }
// }
// const photoID = () => {
//     let map = {}
//     map['applicant-1-identification'] = 'YES'
//     if (mortgage.twoApplicants) {
//         map['applicant-2-identification'] = 'YES'
//     }
//     return {map}
// }
// const proofOfAddress = () => {
//     let map = {}
//     map['applicant-1-proof-of-address'] = 'YES'
//     if (mortgage.twoApplicants) {
//         map['applicant-2-proof-of-address'] = 'YES'
//     }
//     return {map}
// }
//
// //NON PAYE TAX POSITION
// const rosChargesAndPayments = () => {
//     let map = {}
//     let ask = []
//     ask.push({
//         label: 'Applicant 1 ROS Charges and Payments',
//         name : 'app1rosChargesAndPayments',
//         type : 'TEXT',
//         group: 'NON PAYE TAX POSITION'
//     })
//     map['applicant-1-ros-charges-and-payments-statement'] = config.hasOwnProperty('app1rosChargesAndPayments') ? config.app1rosChargesAndPayments : 'N/A'
//     if (mortgage.twoApplicants) {
//         ask.push({
//             label: 'Applicant 2 ROS Charges and Payments',
//             name : 'app2rosChargesAndPayments',
//             type : 'TEXT',
//             group: 'NON PAYE TAX POSITION'
//         })
//         map['applicant-2-ros-charges-and-payments-statement'] = config.hasOwnProperty('app2rosChargesAndPayments') ? config.app2rosChargesAndPayments : 'N/A'
//     }
//     return {
//         map,
//         ask
//     }
// }
// const letterOfConfirmationFromAccountant = () => {
//     let map = {}
//     let ask = []
//     ask.push({
//         label: 'Applicant 1 Letter of confirmation from accountant',
//         name : 'app1letterOfConfirmationFromAccountant',
//         type : 'TEXT',
//         group: 'NON PAYE TAX POSITION'
//     })
//     map['applicant-1-letter-of-tax-affiars-in-order'] = config.hasOwnProperty('app1letterOfConfirmationFromAccountant') ? config.app1letterOfConfirmationFromAccountant : 'N/A'
//     if (mortgage.twoApplicants) {
//         ask.push({
//             label: 'Applicant 2 Letter of confirmation from accountant',
//             name : 'app2letterOfConfirmationFromAccountant',
//             type : 'TEXT',
//             group: 'NON PAYE TAX POSITION'
//         })
//         map['applicant-2-letter-of-tax-affiars-in-order'] = config.hasOwnProperty('app2letterOfConfirmationFromAccountant') ? config.app2letterOfConfirmationFromAccountant : 'N/A'
//     }
//     return {
//         map,
//         ask
//     }
// }
// const payeMostRecentStatementOfLiabilities = () => {
//     let map = {}
//     let ask = []
//     if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED) {
//         ask.push({
//             label: 'Applicant 1 Statement of Liabilities',
//             name : 'app1payeMostRecentStatementOfLiabilities',
//             type : 'TEXT',
//             group: 'NON PAYE TAX POSITION'
//         })
//         map['applicant-1-statement-of-liability'] = config.hasOwnProperty('app1payeMostRecentStatementOfLiabilities') ? config.app1payeMostRecentStatementOfLiabilities : 'N/A'
//     }
//     else {
//         map['applicant-1-statement-of-liability'] = 'N/A'
//     }
//     if (mortgage.twoApplicants) {
//         if (mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
//             ask.push({
//                 label: 'Applicant 2 Statement of Liabilities',
//                 name : 'app2payeMostRecentStatementOfLiabilities',
//                 type : 'TEXT',
//                 group: 'NON PAYE TAX POSITION'
//             })
//             map['applicant-2-statement-of-liability'] = config.hasOwnProperty('app2payeMostRecentStatementOfLiabilities') ? config.app2payeMostRecentStatementOfLiabilities : 'N/A'
//         }
//         else {
//             map['applicant-2-statement-of-liability'] = 'N/A'
//
//         }
//     }
//     return {
//         map,
//         ask
//     }
// }
// const selfEmployedAccountsLetterOfNoDebt = () => {
//     let map = {}
//     let ask = []
//     if (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
//         ask.push({
//             label: 'Applicant 1 Accountant Letter of no debt',
//             name : 'app1selfEmployedAccountsLetterOfNoDebt',
//             type : 'TEXT',
//             group: 'NON PAYE TAX POSITION'
//         })
//         map['applicant-1-accountant-letter-of-no-debt'] = config.hasOwnProperty('app1selfEmployedAccountsLetterOfNoDebt') ? config.app1selfEmployedAccountsLetterOfNoDebt : 'N/A'
//     }
//     else {
//         map['applicant-1-accountant-letter-of-no-debt'] = 'N/A'
//     }
//     if (mortgage.twoApplicants) {
//         if (mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
//             ask.push({
//                 label: 'Applicant 2 Accountant Letter of no debt',
//                 name : 'app2selfEmployedAccountsLetterOfNoDebt',
//                 type : 'TEXT',
//                 group: 'NON PAYE TAX POSITION'
//             })
//             map['applicant-2-accountant-letter-of-no-debt'] = config.hasOwnProperty('app2selfEmployedAccountsLetterOfNoDebt') ? config.app2selfEmployedAccountsLetterOfNoDebt : 'N/A'
//         }
//         else {
//             map['applicant-2-accountant-letter-of-no-debt'] = 'N/A'
//         }
//     }
//     return {
//         map,
//         ask
//     }
// }
