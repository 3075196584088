import React, {useContext, useEffect, useState} from 'react';
import {BackendUsers, Me} from "../BackendUsersProvider";

function useBackendUsers(props) {
    const users = useContext(BackendUsers)
    const me = useContext(Me)
    const getBySub = (sub)=>{
        if (sub==="system"){
                return {sub: "system", firstName: "System", email: ""}
        }
        return users.find(item=>item.sub === sub)
    }

    return {
        users,
        me,
        getBySub
    }
}

export default useBackendUsers;