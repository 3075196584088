import React, {useContext} from 'react';
import {Mortgages} from "../../../../providers/MortgagesProvider";
import {LoadingOutlined} from "@ant-design/icons";
import {List, Tag} from "antd";
import {Link} from "react-router-dom";
import MortgageTag from "../../../elements/mortgage/MortgageTag";
import OverflowedCard from "../../_common/OverflowedCard";

function OnlineNow(props) {
    const mortgages = useContext(Mortgages)
    const online = mortgages.ONLINENOW
    if (!online.length) return null
    return (
        <OverflowedCard title=" Online Now" style={{maxHeight:'50%'}}>
        <List
            header={null}
            footer={null}
            bordered={false}
            dataSource={online}
            renderItem={(item) => (

                <List.Item >
                    <MortgageTag mortgage={item}/>
                </List.Item>

            )}
        />
        </OverflowedCard>
    );
}

export default OnlineNow;