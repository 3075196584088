import React, {useContext} from 'react';
import OverflowedCard from "../../_common/OverflowedCard";
import {List, Typography} from "antd";
import MortgageTag from "../../../elements/mortgage/MortgageTag";
import {Mortgages} from "../../../../providers/MortgagesProvider";
import {LoadingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {gray} from "@ant-design/colors";
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
function AttentionMortgages(props) {
    const isDateBefore3NextWeeks = (date, noOfWeeks) => {
        const today = dayjs(); // Today's date
        const threeWeeksFromNow = dayjs().add(noOfWeeks, 'week'); // Date 8 weeks from now
        return dayjs(date, 'YYYY-MM-DD').isBetween(today, threeWeeksFromNow, null, '[]'); // '[]' includes both start and end dates in the comparison
    };
    // const isDateWithinNext3Weeks = (date) => {
    //     const threeWeeksFromNow = dayjs().add(3, 'week'); // Date 8 weeks from now
    //     return dayjs(date, 'YYYY-MM-DD').isBefore(threeWeeksFromNow); // '[]' includes both start and end dates in the comparison
    // };
    const mortgages = useContext(Mortgages)

    // const upcomingMortgages = mortgages.DELAYED.filter(it => {
    //     if (!!it.applicationFutureDate) {
    //         return isDateBefore3NextWeeks(it.applicationFutureDate)
    //     }
    //     return false
    // }).map(mortgage => ({
    //     mortgage,
    //     date: dayjs(mortgage.applicationFutureDate),
    //     title: 'Reactivating soon '
    // }))

    const closingMortgages = mortgages.ALL.filter(it => {
        if (it.requirement && it.requirement.drawdownDate) {
            return isDateBefore3NextWeeks(it.requirement.drawdownDate, 8)
        }
        return false
    }).map(mortgage => ({
        mortgage,
        date: dayjs(mortgage.requirement.drawdownDate),
        title: 'Closing soon '
    }))

    const newMortgages = mortgages.NEW.map(mortgage => {
        return {
            mortgage,
            date: dayjs(mortgage.lastHere),
            title: 'New mortgage '
        }
    })

    const data = [...closingMortgages,...newMortgages].sort((a, b) => {
        return dayjs(a.date).isBefore(b.date) ? -1 : 1
    })

    return (
        <OverflowedCard title=" Mortgages For Attention" style={{maxHeight: props.maxHeight}}>
            <List
                header={null}
                footer={null}
                bordered={false}
                dataSource={data}
                renderItem={(item) => (

                    <List.Item>
                        <div className="d-col grow">
                            <div className="d-row j-between gap-15 mb-3">
                                <Typography.Text style={{fontSize: 16, color: gray[4]}}>{item.title}</Typography.Text>
                                <Typography.Text
                                    style={{color: gray[4]}}>{item.date ? item.date.fromNow() : 'Not sure when'}</Typography.Text>
                            </div>
                            <MortgageTag mortgage={item.mortgage}/>
                        </div>
                    </List.Item>

                )}
            />
        </OverflowedCard>
    );
}

export default AttentionMortgages;