import React from 'react';
import {Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import useMortgageDocumentsService from "../../../../providers/mortgage/useMortgageDocumentsService";
import {currencyFormatter, currencyParser} from "../../../../../assets/scripts/parser-functions";
import {getAttachmentPath} from "../../../emails-manager/AttachmentButtonGroups";
import dayjs from "dayjs";
import {PropertyTypes} from "../../../../../models";

function PropertyValuation(props) {
    const [saving,setSaving] = React.useState(false)
    const mortgage = useMortgage()
    const [form] = Form.useForm()
    const [open, setOpen] = React.useState(true)
    const documentService = useMortgageDocumentsService()
    const handleValuationSubmit = async () => {
        const update = []
        const create = []
        try{
            let valid = await form.validateFields()
                setSaving(true)
            let propertyId = mortgage.properties[0].id
            update.push({
                target: `properties[${propertyId}]`,
                pairs: {
                    berScore: valid.BER || null,
                    propertyType: valid.propertyType,
                    address: valid.address,
                }
            })
            update.push({
                target: `submissions[${mortgage.activeSubmission.id}]`,
                pairs: {
                    reinstatementValue: valid.value,
                    valuationDate: valid.date.format("YYYY-MM-DD"),
                    valuationExpiryDate: valid.expiry ? valid.expiry.format("YYYY-MM-DD") : null,
                    anotherValuationNeeded: valid.anotherValuationNeeded,
                    hasValuationIssues: valid.hasValuationIssues,
                    valuationIssues: valid.valuationIssues || null,
                    surveyRequired: valid.surveyRequired,
                    valuationValue: valid.valuationValue
                }
            })
            const source = getAttachmentPath(props.attachment, props.email.v2Attachments)
            let promises = []
            promises.push(documentService.copyAttachmentToUserDocs({
                folder: 'lender-documents',
                title: 'Property Valuation',
                isPrivate: false,
                fullFilename: 'property-valuation.pdf',
                stage: 'PREAPPROVAL',
                bucketKey: source,
                submissionID: mortgage.activeSubmission.id
            }))
            promises.push(mortgage.mutate({update}))
            await Promise.all(promises)
            setSaving(false)
            setOpen(false)
        }
        catch (e) {

        }
    }
    const handleFormChange = (val, all)=>{
        if (Object.keys(val)[0] === 'date'){
            form.setFieldValue('expiry', val.date.add(4, 'month'))
        }
    }
    return (
        <Modal
            okButtonProps={{icon: saving ? <LoadingOutlined/>:null}}
            afterClose={props.onCancel}
            destroyOnClose
            open={open}
            title="Mark as Property Valuation"
            onCancel={()=>setOpen(false)}
            onOk={handleValuationSubmit}
        >
            <Form
                form={form}
                requiredMark={false}
                initialValues={{
                    date: mortgage.activeSubmission.valuationDate ? dayjs(mortgage.activeSubmission.valuationDate) : null,
                    expiry: mortgage.activeSubmission.valuationExpiryDate ? dayjs(mortgage.activeSubmission.valuationExpiryDate) : null,
                    BER: mortgage.properties[0].berScore,
                    value: mortgage.activeSubmission.reinstatementValue,
                    anotherValuationNeeded: mortgage.activeSubmission.anotherValuationNeeded,
                    hasValuationIssues: mortgage.activeSubmission.hasValuationIssues,
                    valuationIssues: mortgage.activeSubmission.valuationIssues,
                    surveyRequired: mortgage.activeSubmission.surveyRequired,
                    propertyType: mortgage.properties[0].propertyType,
                    address: mortgage.properties[0].address,
                    valuationValue: mortgage.activeSubmission.valuationValue
                }}
                onValuesChange={handleFormChange}
            >
                <div className="d-row gap-15 j-equal mt-15">
                    <Form.Item name="date" label="Valuation Date" rules={[{required:true, message: 'Missing Date'}]}>
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                    <Form.Item name="expiry" label="Expry Date">
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                </div>
                <Form.Item name="propertyType" label="Property Type">
                    <Select options={[
                        {label: 'Semi Detached', value: PropertyTypes.SEMIDETACHED},
                        {label: 'Detached', value: PropertyTypes.DETACHED},
                        {label: 'Terrace', value: PropertyTypes.TERRACE},
                        {label: 'Apartment', value: PropertyTypes.APARTMENT},
                        {label: 'Flat', value: PropertyTypes.FLAT},
                    ]}/>
                </Form.Item>
                <Form.Item name="address" label="Address">
                    <Input/>
                </Form.Item>
                <Form.Item name="valuationValue" label="Valuation Value" help={<>Sale Price: {mortgage.properties[0].propertyPrice?.toEuro()}</>}>
                    <InputNumber parser={currencyParser} formatter={currencyFormatter} prefix="€" style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item name="BER" label="BER Rating">
                    <Select options={[

                            {
                                value: 'A1',
                                label: 'A1',
                            },
                            {
                                value: 'A2',
                                label: 'A2',
                            },
                            {
                                value: 'A3',
                                label: 'A3',
                            },
                            {
                                value: 'B1',
                                label: 'B1',
                            },
                            {
                                value: 'B2',
                                label: 'B2',
                            },
                            {
                                value: 'B3',
                                label: 'B3',

                            },
                            {
                                value: 'C1',
                                label: 'C1',

                            },
                            {
                                value: 'C2',
                                label: 'C2',
                            },
                            {
                                value: 'C3',
                                label: 'C3',

                            },
                            {
                                value: 'D1',
                                label: 'D1',

                            },
                            {
                                value: 'D2',
                                label: 'D2',
                            },
                            {
                                value: 'E1',
                                label: 'E1',
                            },
                            {
                                value: 'E2',
                                label: 'E2',
                            },
                            {
                                value: 'F',
                                label: 'F',
                            },
                            {
                                value: 'G',
                                label: 'G',
                            }
                    ]}/>
                </Form.Item>
                {
                     ![PropertyTypes.FLAT, PropertyTypes.APARTMENT].includes(mortgage.properties[0].propertyType) && (
                        <Form.Item name="value" label="Reinstatement Value">
                            <InputNumber parser={currencyParser} formatter={currencyFormatter} prefix="€" style={{width: '100%'}}/>
                        </Form.Item>
                    )
                }


                <Form.Item name="hasValuationIssues" label="Has Valuation Issues">
                    <Select options={[
                        {label:'Yes', value:true},
                        {label:'No', value:false}
                    ]}/>
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.hasValuationIssues !== currentValues.hasValuationIssues}>
                    {({getFieldValue}) => getFieldValue('hasValuationIssues') ? (
                        <Form.Item name="valuationIssues" label="Valuation Issues" rules={[{required:true, message: 'Missing Issues'}]}>
                            <Input.TextArea/>
                        </Form.Item>
                    ) : null}
                </Form.Item>
                <Form.Item name="anotherValuationNeeded" label="Another Valuation Needed">
                    <Select options={[
                        {label:'Yes', value:true},
                        {label:'No', value:false}
                    ]}/>
                </Form.Item>
                <Form.Item name="surveyRequired" label="Survey Required">
                    <Select options={[
                        {label:'Yes', value:true},
                        {label:'No', value:false}
                    ]}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default PropertyValuation;