import {SYSTEMIDENTITYID} from "../constants";
import * as pdfjsLib from "pdfjs-dist/build/pdf"
import useS3 from "../../../app/providers/useS3";
import {SectionTypes} from "../../../models";
const pdfjsWorker = require('pdfjs-dist/build/pdf.worker.min.mjs');
const pdfjsWorkerBlob = new Blob([pdfjsWorker]);
const pdfjsWorkerBlobURL = URL.createObjectURL(pdfjsWorkerBlob);
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorkerBlobURL

export const addBunchToMap = (map, prefix, items)=>{
    items.forEach((item,i) => {
        map[`${prefix}${i+1}`] = item
    })
}

export const getBunch = (prefix, items)=>{
    const bunch = {}
    items.forEach((item,i) => {
        bunch[`${prefix}${i+1}`] = item
    })
    return bunch
}
export const getMeta = (mortgage, stage, name, key, index)=>{
    const progress = mortgage.progress.find(p=>{
        if (p.sectionType !== SectionTypes.UPLOAD || p.applicationStage !== stage) return false

        if (!!index){
            return  p.sectionName === name && p.sectionIndex === index
        }

        return p.sectionName === name
    })
    if (!progress){
        console.log(`No progress for ${name} ${index}`)
    }
    if (!!progress.metaData){
        const meta = JSON.parse(progress.metaData)
        return meta[key]
    }
    return null
}