import React from 'react';
import {Skeleton, Tabs} from "antd";
import PageHeader from "../../../PageHeader";

import useMortgage from "../../../../providers/mortgage/useMortgage";

import usePreApprovalTimelineItems from "./usePreApprovalTimelineItems";
import Details from "./parts/Details";
import CustomerDocuments from "./parts/CustomerDocuments";
import Uploads from "./parts/Uploads";
import Property from "./parts/Property";
import Solicitor from "./parts/Solicitor";

import PreApprovalAdminDocuments from "./parts/PreApprovalAdminDocuments";
import StageTimeline from "../_common/stage-timeline/StageTimeline";
import StagesLayout from "../_common/StagesLayout";
import PreApprovedAdminUploads from "./parts/PreApprovedAdminUploads";
import MpiInfo from "../_common/mortgage-protection-insurance/MpiInfo";
import Quotes from "../_common/mortgage-protection-insurance/Quotes";

function ManagementPreApproval(props) {
    const mortgage = useMortgage()
    let [activeKey, setActiveKey] = React.useState('AIP Details')

    if (!mortgage) {
        return <div className="m-15"><Skeleton active loading/></div>
    }

    const tabs = [
        {
            key: 'AIP Details',
            label: 'AIP Details',
            children: <Details/>,
        },
        {
            key: 'Property',
            label: 'Property',
            children:  <Property/>,
        },
        {
            key: 'Enter Solicitor',
            label: 'Solicitor',
            children: <Solicitor/>
        },
        {
            key: 'Medical',
            label: 'Medical',
            children:  <MpiInfo/>,
        },
        {
            key: 'Quotes',
            label: 'Quotes',
            children:  <Quotes/>,
        },
        {
            key: 'Cust. Documents',
            label: 'Prepare Documents',
            children: <CustomerDocuments/>,
        },
        {
            key: 'Uploads',
            label: 'Customer Uploads',
            children: <Uploads/>,
        },
        {
            key: 'Admin Documents',
            label: 'Prepare & Submit',
            children: <PreApprovalAdminDocuments/>
        },
        // {
        //     key: 'UploadsAdmin',
        //     label: 'Admin Uploads',
        //     children: <PreApprovedAdminUploads/>,
        // },
    ];
    return <StagesLayout title="Preapproval - Preparing Loan Offer Request" tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey}/>
}

export default ManagementPreApproval;