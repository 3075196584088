import {AssetTypes, Frequencies, UploadStatuses} from "../../../models";
import {VerifiableUpload} from "./verifiableUpload";
import {LONGDATE} from "../constants";
import MyLittleForm, {getClientValue} from "../../../app/components/client/application-stages/_common/uploads/v2/section-details/MyLittleForm";
import React, {useEffect, useMemo} from "react";
import MortgageForm from "../../../app/components/data-capture/MortgageForm";
import dayjs from "dayjs";
import {getItemFromPath} from "../../../app/providers/mortgage/MortgageProvider";
import {capitaliseFirst, joinWithConjunction} from "../string-functions";
import {red} from "@ant-design/colors";
import {Alert, Button, Table} from "antd";
import {performGQL} from "../../../app/api-functions/functions";
import {createAdminMortgageSectionVerificationNote, createAdminMortgageUploadVerifiedPoint, updateAdminMortgageUploadVerifiedPoint} from "../../../graphql/mutations";
import {legacyIncome} from "../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";
import ButtonAsync from "../../../app/components/elements/ButtonAsync";
import InternalNote from "../../../app/components/client/application-stages/_common/uploads/v2/section-details/InternalNote";
import DifferenceHighlighter from "../../../app/components/difference-highlighter/DifferenceHighlighter";
import useBackendUsers from "../../../app/providers/mortgage/useBackendUsers";

export const currentYear = new Date().getFullYear();
export const lastYear1 = currentYear - 1;
export const lastYear2 = currentYear - 2;
export const lastYear3 = currentYear - 3;
export async function updateUploadVerifiedRow(mortgage, row, pairs) {
        let input = {
                id      : row.id, ...pairs,
                _version: row._version
        }
        let result = await performGQL({input}, updateAdminMortgageUploadVerifiedPoint)
        return result

}
export function sorterByDateName(name) {
        return (a, b) => {
                if (!a[name] || !dayjs.isDayjs(a[name])) {
                        return -1
                }
                if (!b[name] || !dayjs.isDayjs(b[name])) {
                        return 1
                }
                return a[name].isBefore(b[name]) ? -1 : a[name].isAfter(b[name]) ? 1 : 0;
                // if (a[name] < b[name]) return -1
                // if (a[name] > b[name]) return 1
                // return 0
        }
}
export const uploadExcludeFilter = it => !it.exclude && ![
        UploadStatuses.EXPIRED,
        UploadStatuses.REJECTED
].includes(it.status)
export function dataPointRow(upload, name) {
        if (!upload.verifiedData) {
                return null
        }
        return upload.verifiedData.items.find(item => item.name === name)
}
export function valueOrNoData(upload, name) {
        let row = dataPointRow(upload, name)
        return !!row ? getClientValue(row.verifiedValue, row.verifiedValueType) : null
}
export function getDataPointRow(upload, name) {
        let row = dataPointRow(upload, name)
        let value = !!row ? getClientValue(row.verifiedValue, row.verifiedValueType) : null
        return {
                row,
                name,
                value
        }
}
export const askInFormAnswerTypes = {
        date    : 'DATE',
        int     : 'NUMBER',
        text    : 'STRING',
        textarea: 'STRING',
        currency: 'NUMBER',
        select  : 'STRING',
        check   : 'BOOLEAN'
}
export function getVerificationValueType(value, type) {
        switch (type) {
                case 'DATE':
                        if (!!value) {
                                if (value.isValid()) {
                                        return [
                                                value.format('YYYY-MM-DD'),
                                                'DATE'
                                        ]
                                }
                        }

                        return [
                                null,
                                'DATE'
                        ]
                case 'NUMBER':
                        return [
                                value ? value.toString() : 0,
                                'NUMBER'
                        ]
                case 'BOOLEAN':
                        return [
                                !value ? "FALSE" : "TRUE",
                                'BOOLEAN'
                        ]
                case 'STRING':
                        return [
                                value ? value.toString() : '',
                                'STRING'
                        ]
                default:
                        if (value !== 0 && !value) {
                                return [
                                        null,
                                        'NULL'
                                ]
                        }
                        if (typeof value === 'number') {
                                return [
                                        value.toString(),
                                        'NUMBER'
                                ]
                        }
                        if (typeof value === 'boolean') {
                                return [
                                        !value ? "FALSE" : "TRUE",
                                        'BOOLEAN'
                                ]
                        }
                        if (dayjs.isDayjs(value)) {
                                if (value.isValid()) {
                                        return [
                                                value.format('YYYY-MM-DD'),
                                                'DATE'
                                        ]
                                }
                                return [
                                        null,
                                        'NULL'
                                ]
                        }
                        if (typeof value === 'string') {
                                return [
                                        value,
                                        'STRING'
                                ]
                        }
        }

}
const getMinimumsForSection = (item) => {
        // the requirements for the section, based on the lender, whether on soft aip in Preapproval or in Apply

        // current accounts and saving statements
        if (item.name.startsWith('current-accounts')) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }
        // saving statements
        if (item.name.startsWith('assets') && !item.isInvestment) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }
        // investment statements
        if (item.name.startsWith('assets') && item.isInvestment) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }

        // credit card statements
        if (item.name.startsWith('debts') && item.isCredit) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }
        // loan statements
        if (item.name.startsWith('debts') && !item.isCredit) {

                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }

        // mortgage statements
        if (item.name.startsWith('properties')) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                6,
                                'months'
                        ]
                }
        }

        // proof of address
        if (item.name.startsWith('proof-of-address')) {
                return {
                        maxAge: [
                                6,
                                'month'
                        ],
                }
        }

        // identification
        if (item.name.startsWith('identification')) {
                return {
                        maxAge: [
                                6,
                                'month'
                        ],
                }
        }

        // salary cert
        if (item.name.startsWith('salary-cert')) {
                return {
                        maxAge: [
                                6,
                                'month'
                        ],
                }
        }

        // employment summary
        if (item.name.startsWith('employment-summary')) {
                return {
                        maxAge       : [
                                2,
                                'year'
                        ],
                        requiredRange: [
                                3,
                                'year'
                        ]
                }
        }

        // payslips
        if (item.name.startsWith('payslips')) {
                return {
                        maxAge       : [
                                5,
                                'week'
                        ],
                        requiredRange: [
                                3,
                                'months'
                        ]
                }
        }

        // our gift certificate
        if (item.name.startsWith('gift-certificate')) {
                return {
                        maxAge: [
                                6,
                                'month'
                        ],
                }
        }

        // real gift certificate
        if (item.name.startsWith('lender-gift-certificate')) {
                return {
                        maxAge: [
                                6,
                                'month'
                        ],
                }
        }

        // residence permit
        if (item.name.startsWith('residence-permit')) {
                return {
                        maxAge: [
                                -2,
                                'month'
                        ],
                }
        }
        return {
                maxAge: [
                        6,
                        'month'
                ]
        }
}
export const RenderErrors = ({errors}) => {
        if (!errors.length) {
                return null
        }
        let message = (<ul className="d-col gap-3 m-0 ps-15">
                {errors.map((it, i) => <li key={it}>{it}</li>)}
        </ul>)
        return (<Alert size="small" key={errors.join('|')} message={message} type="error"/>)
}
const compressString = (address) => {
        if (!address) {
                return ''
        }
        return address.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
}

const getFirstRequiredDate = (section, rows) => {
        let last = rows[rows.length - 1]
        let requiredFirst = null
        if (last) {
                let dateToUse = last.hasOwnProperty('endDate') ? last.endDate : last.hasOwnProperty('documentDate') ? last.documentDate : last.expiryDate
                if (dayjs.isDayjs(dateToUse)) {
                        let {
                                    maxAge,
                                    requiredRange
                            } = getMinimumsForSection(section)
                        let betterEndDate = dateToUse.add(1, 'day')
                        let [m, ageUnit] = maxAge
                        if (!requiredRange) {
                                requiredFirst = dayjs().subtract(m, ageUnit)
                        }
                        else {
                                let [n, rangeUnit] = requiredRange
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                let lastDateToWorkWith = age > m ? dayjs().subtract(2, 'week') : dateToUse.add(1, 'day')
                                requiredFirst = lastDateToWorkWith.subtract(n, rangeUnit)
                        }

                }

        }
        return requiredFirst
}
export function getConfigurationTemplates() {
        const investmentStatementConfig = {
                template              : {
                        items: [
                                {
                                        name : 'accountNumber',
                                        label: 'Account Number',
                                        type : 'text'
                                },
                                {
                                        name : 'documentDate',
                                        label: 'Start Date',
                                        type : 'date',
                                },
                                {
                                        name : 'closingBalance',
                                        label: 'Closing Balance',
                                        type : 'currency',
                                },
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Account Number',
                                key      : 'accountNumber',
                                dataIndex: 'accountNumber'
                        },
                        {
                                title    : 'Closing Balance',
                                key      : 'closingBalance',
                                dataIndex: 'closingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key           : it.id,
                                accountNumber : valueOrNoData(it, 'accountNumber'),
                                documentDate  : valueOrNoData(it, 'documentDate'),
                                closingBalance: valueOrNoData(it, 'closingBalance'),
                                upload        : it
                        })).map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Date missing`)
                                }
                                if (typeof item.accountNumber !== 'string') {
                                        errors.push(`Account Number missing`)
                                }
                                if (isNaN(item.closingBalance)) {
                                        errors.push(`Closing Balance missing`)
                                }
                                return {
                                        ...item,
                                        errors
                                }
                        }).sort(sorterByDateName('documentDate'))

                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        return []
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && `${row.documentDate.format(LONGDATE)}`,
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`investment-statement-documentDate-${uploadId}`}
                                      item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`investment-statement-accountNumber-${uploadId}`}
                                      item={managerGet('accountNumber')}/>,
                        <MyLittleForm key={`investment-statement-closingBalance-${uploadId}`}
                                      item={managerGet('closingBalance')}/>
                ],
                SectionApplicationForm: ({section}) => {
                        let target = section.applicant === 1 ? `applicant1.assets[${section.index}]` : `applicant2.assets[${section.index}]`
                        let questions = [
                                {
                                        name  : 'currency',
                                        label : 'Currency',
                                        answer: {
                                                type: 'text'
                                        },
                                        target: `${target}.currency`
                                }
                        ]
                        return <MortgageForm questions={questions}/>
                }
        }
        const accountStatementConfig = {
                template              : {
                        items: [
                                {
                                        name : 'iban',
                                        label: 'IBAN',
                                        type : 'text',
                                        scan : 'iban'
                                },
                                {
                                        name : 'openingBalance',
                                        label: 'Opening Balance',
                                        type : 'currency',
                                        scan : 'openingBalance'
                                },
                                {
                                        name : 'closingBalance',
                                        label: 'Closing Balance',
                                        type : 'currency',
                                        scan : 'closingBalance'
                                },
                                {
                                        name : 'startDate',
                                        label: 'Start Date',
                                        type : 'date',
                                        scan : 'startDate'
                                },
                                {
                                        name : 'endDate',
                                        label: 'End Date',
                                        type : 'date',
                                        scan : 'endDate'
                                },
                                {
                                        name    : 'vault1OpeningBalance',
                                        label   : 'Opening Balance',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'vault1ClosingBalance',
                                        label   : 'Closing Balance',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'vault2OpeningBalance',
                                        label   : 'Opening Balance',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'vault2ClosingBalance',
                                        label   : 'Closing Balance',
                                        type    : 'currency',
                                        optional: true
                                }
                        ]
                }, // add custom items to the template
                customTemplate        : (that) => {

                        let applicant = that.section.applicant === 1 ? that.mortgage.applicant1 : that.mortgage.applicant2

                        let investments = applicant.assets.filter(it => it.assetType === AssetTypes.INVESTMENT)
                        return {
                                items          : investments.map((it, i) => ({
                                        name    : `toInvestment-${it.id}`,
                                        label   : `Total To ${it.description}`,
                                        type    : 'currency',
                                        optional: true
                                })),
                                uploadDataForms: investments.map((it, i) => `toInvestment-${it.id}`)
                        }
                },
                sortDate              : 'startDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Start Date',
                                key      : 'startDate',
                                dataIndex: 'startDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'End Date',
                                key      : 'endDate',
                                dataIndex: 'endDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Opening Balance',
                                key      : 'openingBalance',
                                dataIndex: 'openingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Closing Balance',
                                key      : 'closingBalance',
                                dataIndex: 'closingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Mths',
                                key      : 'monthsInBetween',
                                dataIndex: 'monthsInBetween',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {

                        let applicant = section.applicant === 1 ? mortgage.applicant1 : mortgage.applicant2
                        let investments = applicant.assets.filter(it => it.assetType === AssetTypes.INVESTMENT)
                        let iban = section.data.accountNumber
                        let rows = uploads.map(it => {
                                let totalToInvestments = 0
                                let toInvestments = {}
                                investments.forEach(investment => {
                                        let name = `toInvestment-${investment.id}`
                                        let value = valueOrNoData(it, name)
                                        if (!!value || value === 0) {
                                                totalToInvestments += value
                                                toInvestments[investment.id] = value
                                        }
                                })
                                return {
                                        key                 : it.id, // iban: valueOrNoData (it, 'iban'),
                                        upload              : it,
                                        startDate           : valueOrNoData(it, 'startDate'),
                                        endDate             : valueOrNoData(it, 'endDate'),
                                        startDateRow        : getDataPointRow(it, 'startDate').row,
                                        endDateRow          : getDataPointRow(it, 'endDate').row,
                                        openingBalance      : valueOrNoData(it, 'openingBalance'),
                                        closingBalance      : valueOrNoData(it, 'closingBalance'),
                                        vault1OpeningBalance: valueOrNoData(it, 'vault1OpeningBalance'),
                                        vault1ClosingBalance: valueOrNoData(it, 'vault1ClosingBalance'),
                                        vault2OpeningBalance: valueOrNoData(it, 'vault2OpeningBalance'),
                                        vault2ClosingBalance: valueOrNoData(it, 'vault2ClosingBalance'),
                                        iban                : valueOrNoData(it, 'iban'),
                                        totalToInvestments,
                                        toInvestments
                                }
                        }).sort(sorterByDateName('startDate'))

                        let requiredFirst = getFirstRequiredDate(section, rows)

                        rows = rows.map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.startDate)) {
                                        errors.push(`Start Date missing`)
                                }
                                if (!dayjs.isDayjs(item.endDate)) {
                                        errors.push(`End Date missing`)
                                }
                                if (isNaN(item.openingBalance)) {
                                        errors.push(`Opening Balance missing`)
                                }
                                if (isNaN(item.closingBalance)) {
                                        errors.push(`Closing Balance missing`)
                                }
                                if (typeof item.iban !== 'string') {
                                        errors.push(`IBAN missing`)
                                }
                                if (!iban || (typeof item.iban === 'string' && item.iban.replace(/[^a-zA-Z0-9]/g, '') !== iban.replace(/[^a-zA-Z0-9]/g, ''))) {
                                        let str = !iban ? 'No Iban in application form' : ' should be ' + iban
                                        errors.push(`IBAN mismatch: ${str}`)
                                }
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.endDate) && item.endDate.isBefore(requiredFirst) : false

                                if (rows.length > 1) {
                                        let nextRow = rows[i + 1]
                                        let nextOpenBalance = nextRow?.openingBalance
                                        let nextStartDate = nextRow?.startDate
                                        if (!isNaN(item.endDate) && nextStartDate && nextStartDate.isBefore(item.endDate)) {
                                                errors.push(`New End Date Needed: ${nextStartDate.subtract(1, 'day').format(LONGDATE)}`)
                                        }
                                        else if (nextOpenBalance && !isNaN(nextOpenBalance) && !isNaN(item.closingBalance)) {
                                                let diff = Math.abs(nextOpenBalance - item.closingBalance)
                                                if (diff > 1) {
                                                        errors.push(`Missing statement after`)
                                                }
                                        }
                                }
                                let trueOpeningBalance = (item.openingBalance || 0) + (item.vault1OpeningBalance || 0) + (item.vault2OpeningBalance || 0)
                                let trueClosingBalance = (item.closingBalance || 0) + (item.vault1ClosingBalance || 0) + (item.vault2ClosingBalance || 0)

                                return {
                                        ...item,
                                        openingBalance : trueOpeningBalance,
                                        closingBalance : trueClosingBalance,
                                        monthsInBetween: dayjs.isDayjs(item.endDate) && dayjs.isDayjs(item.startDate) && item.endDate.add(1, 'day').diff(item.startDate, 'months', true).toFixed(1),
                                        errors,
                                        tooOld
                                }

                        })
                        return rows
                },
                getRowLabel           : (values) => {
                        return !!values.startDate && dayjs.isDayjs(values.endDate) && `${values.startDate.format(LONGDATE)} - ${values.endDate.format(LONGDATE)}`
                },
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('startDate'))
                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]
                        if (dayjs.isDayjs(first.startDate) && dayjs.isDayjs(last.endDate)) {

                                // then check the range of the documents for
                                // a) the last doc is not too old - use doc date
                                // b) the last doc is too old - use today
                                let thisAccount = section.data
                                // if (section.name === 'current-accounts-1') {
                                //     thisAccount = mortgage.applicant1.accounts.find(it => it.id === section.index)
                                // }
                                // if (section.name === 'current-accounts-2') {
                                //     thisAccount = mortgage.applicant2.accounts.find(it => it.id === section.index)
                                // }
                                // if (section.name === 'assets-1') {
                                //     thisAccount = mortgage.applicant1.assets.find(it => it.id === section.index)
                                // }
                                // if (section.name === 'assets-2') {
                                //     thisAccount = mortgage.applicant2.assets.find(it => it.id === section.index)
                                // }

                                let isNewAccount = thisAccount.isNewAccount
                                let isClosed = thisAccount.isClosed
                                let betterEndDate = last.endDate.add(1, 'day')
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [n, rangeUnit] = requiredRange
                                let [m, ageUnit] = maxAge
                                // Error on endDate too old
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                if (age > m && !isClosed) {
                                        errors.push(`Most recent statement is older than ${m} ${ageUnit} (already in app)`)
                                }

                                //Error on gaps between statements
                                let lastDateToWorkWith = age > m ? dayjs().subtract(2, 'week') : last.endDate.add(1, 'day')

                                let today = dayjs()
                                let rowsWithGapInDaysToNext = sorted.map((it, ix) => {
                                        let next = sorted[ix + 1]
                                        let daysToNext = 0
                                        if (next) {
                                                daysToNext = next.startDate.diff(it.endDate, 'days')
                                        }
                                        else {
                                                // if the most recent statement error is not already there, show the missing days to today
                                                if (!(age > m && !isClosed)) {
                                                        // daysToNext = today.diff(it.endDate, 'days')
                                                }

                                        }
                                        return {
                                                ...it,
                                                daysToNext
                                        }
                                })

                                let requiredFirst = lastDateToWorkWith.subtract(n, rangeUnit)
                                let missingRanges = []
                                if (!isNewAccount) {
                                        if (requiredFirst.isBefore(first.startDate)) {
                                                missingRanges.push(`[ ${requiredFirst.format('D MMM')} - ${first.startDate.format('D MMM')} ]`)
                                        }
                                }
                                missingRanges = [
                                        ...missingRanges,
                                        ...rowsWithGapInDaysToNext.flatMap((it, ix) => {
                                                if (it.daysToNext > 1) {
                                                        return [`[ ${it.endDate.add(1, 'day').format('D MMM')} - ${it.endDate.add(it.daysToNext - 1, 'days').format('D MMM')} ]`]
                                                }
                                                return []
                                        })
                                ]

                                if (missingRanges.length) {
                                        errors.push(`Missing statement${missingRanges > 1 ? 's' : ''} for ${joinWithConjunction(missingRanges, 'and')}`)
                                }
                        }
                        return errors
                },
                saveScanDataToSection : async (mortgage, section, data) => {
                        let target = section.applicant === 1 ? `applicant1` : `applicant2`
                        if (section.name.startsWith('assets')) {
                                target = `${target}.assets`
                        }
                        else {
                                target = `${target}.accounts`
                        }
                        let source = `${target}[${section.index}].accountNumber`
                        let val = getItemFromPath(mortgage, source)
                        if (!val) {
                                let update = {
                                        target: `${target}[${section.index}]`,
                                        pairs : {
                                                accountNumber : data.iban,
                                                accountAddress: data.accountAddress,
                                        }
                                }
                                await mortgage.mutate({update})
                        }
                },
                SummaryMessages       : ({
                        rows,
                        mortgage,
                        minimums,
                        section
                }) => {
                        let errors = accountStatementConfig.getSectionErrors(rows, mortgage, minimums, section)
                        return errors.map((it, i) => <Alert key={it} message={it} type="error"/>)
                },
                UploadDataForm        : ({
                        uploadId,
                        managerGet,
                        restOfForm = []
                }) => [
                        <MyLittleForm key={`account-statement-iban${uploadId}`} item={managerGet('iban')}/>,
                        <div key={`account-statement-group1-${uploadId}`} className="d-row gap-15 j-equal">
                                <MyLittleForm key={`account-statement-startDate${uploadId}`} item={managerGet('startDate')}/>
                                <MyLittleForm key={`account-statement-openingBalance${uploadId}`} item={managerGet('openingBalance')}/>
                        </div>,
                        <div key={`account-statement-group2-${uploadId}`} className="d-row gap-15 j-equal">
                                <MyLittleForm key={`account-statement-endDate${uploadId}`} item={managerGet('endDate')}/>
                                <MyLittleForm key={`account-statement-closingBalance${uploadId}`} item={managerGet('closingBalance')}/>
                        </div>,
                        <div key={`account-statement-group3-${uploadId}`} className="d-row gap-15 j-equal">
                                <div className="d-col gap-3">
                                        <strong className="text-center">Vault 1</strong>
                                        <MyLittleForm key={`account-statement-vault1OpeningBalance${uploadId}`} item={managerGet('vault1OpeningBalance')}/>
                                        <MyLittleForm key={`account-statement-vault1ClosingBalance${uploadId}`} item={managerGet('vault1ClosingBalance')}/>
                                </div>
                                <div className="d-col gap-3">
                                        <strong className="text-center">Vault 2</strong>
                                        <MyLittleForm key={`account-statement-vault2OpeningBalance${uploadId}`} item={managerGet('vault2OpeningBalance')}/>
                                        <MyLittleForm key={`account-statement-vault2ClosingBalance${uploadId}`} item={managerGet('vault2ClosingBalance')}/>
                                </div>
                        </div>,
                        ...restOfForm
                ],
                SectionApplicationForm: ({
                        section,
                        mortgage
                }) => {
                        let target = section.applicant === 1 ? `applicant1` : `applicant2`
                        if (section.name.startsWith('assets')) {
                                target = `${target}.assets`
                        }
                        else {
                                target = `${target}.accounts`
                        }
                        let salariedAcExists = section.applicant === 1 ? mortgage.applicant1.accounts.find(it => !!it.salaried) : mortgage.applicant2.accounts.find(it => !!it.salaried)
                        let jointQuestions = [
                                {
                                        name  : 'joint',
                                        label : 'Joint Acc',
                                        answer: {
                                                type   : 'select',
                                                choices: [
                                                        {
                                                                value: true,
                                                                text : 'Yes'
                                                        },
                                                        {
                                                                value: false,
                                                                text : 'No'
                                                        }
                                                ]

                                        },
                                        target: `${target}[${section.index}].joint`
                                },
                                {
                                        name  : 'currency',
                                        label : 'Currency',
                                        answer: {
                                                type: 'text'
                                        },
                                        target: `${target}[${section.index}].currency`
                                },
                        ]
                        if (!section.name.startsWith('assets')) {
                                if (!salariedAcExists || salariedAcExists.id === section.index) {
                                        jointQuestions.push({
                                                name  : 'salaried',
                                                label : 'Salaried',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]
                                                },
                                                target: `${target}[${section.index}].salaried`
                                        })
                                }
                        }
                        let questions = [
                                {
                                        name  : 'accountNumber',
                                        label : 'IBAN',
                                        answer: {
                                                type: 'text',
                                        },
                                        target: `${target}[${section.index}].accountNumber`
                                },
                                jointQuestions,
                                [

                                        {
                                                name  : 'isClosed',
                                                label : 'Is Closed',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]
                                                },
                                                target: `${target}[${section.index}].isClosed`
                                        },
                                        {
                                                name  : 'isNewAccount',
                                                label : 'Is New Account',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]

                                                },
                                                target: `${target}[${section.index}].isNewAccount`
                                        }

                                ]
                        ]
                        if (section.name.startsWith('current-accounts')) {
                                let hasOverdraftRow = [
                                        {
                                                name  : 'hasOverdraft',
                                                label : 'Has Overdraft',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]
                                                },
                                                target: `${target}[${section.index}].hasOverdraft`
                                        },
                                        {
                                                name  : 'overdraftLimit',
                                                label : 'Overdraft Limit',
                                                answer: {
                                                        type: 'euro'
                                                },
                                                target: `${target}[${section.index}].overdraftLimit`,
                                                hides : (form) => form.getFieldValue('hasOverdraft') !== true
                                        }
                                ]
                                questions.push(hasOverdraftRow)

                                let rentAc = section.applicant === 1 ? mortgage.applicant1.accounts.find(it => !!it.accommodationDD) : mortgage.applicant2.accounts.find(it => !!it.accommodationDD)
                                if (!rentAc || rentAc.id === section.index) {
                                        let target2 = section.applicant === 1 ? `applicant1` : `applicant2`
                                        let lastTwo = [
                                                {
                                                        name  : 'accommodationDD',
                                                        label : 'Accommodation DD',
                                                        answer: {
                                                                type   : 'select',
                                                                choices: [
                                                                        {
                                                                                value: true,
                                                                                text : 'Yes'
                                                                        },
                                                                        {
                                                                                value: false,
                                                                                text : 'No'
                                                                        }
                                                                ]
                                                        },
                                                        target: `${target}[${section.index}].accommodationDD`
                                                },
                                                {
                                                        name  : 'monthlyAccommodationCosts',
                                                        label : 'Monthly Accommodation Costs',
                                                        answer: {
                                                                type: 'euro'
                                                        },
                                                        target: `${target2}.financial.monthlyAccommodationCosts`,
                                                }
                                        ]
                                        questions.push(lastTwo)
                                }
                        }

                        return <MortgageForm questions={questions}/>
                },
        }
        const cardStatementConfig = {
                template              : {
                        items: [
                                {
                                        name : 'accountNumber',
                                        label: 'Account Number',
                                        type : 'text',
                                },
                                {
                                        name : 'documentDate',
                                        label: 'Document Date',
                                        type : 'date',
                                },
                                {
                                        name : 'outstandingBalance',
                                        label: 'Outstanding',
                                        type : 'currency',
                                },
                                {
                                        name : 'creditLimit',
                                        label: 'Credit Limit',
                                        type : 'currency',
                                }
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Account Number',
                                key      : 'accountNumber',
                                dataIndex: 'accountNumber'
                        },
                        {
                                title    : 'Outstanding',
                                key      : 'outstandingBalance',
                                dataIndex: 'outstandingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Credit Limit',
                                key      : 'creditLimit',
                                dataIndex: 'creditLimit',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key               : it.id,
                                accountNumber     : valueOrNoData(it, 'accountNumber'),
                                documentDate      : valueOrNoData(it, 'documentDate'),
                                outstandingBalance: valueOrNoData(it, 'outstandingBalance'),
                                creditLimit       : valueOrNoData(it, 'creditLimit'),
                                upload            : it
                        })).sort(sorterByDateName('documentDate'))
                        let iban = section.data.accountNumber

                        rows = rows.map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Doc Date missing`)
                                }
                                if (isNaN(item.outstandingBalance)) {
                                        errors.push(`Outstanding Balance missing`)
                                }
                                if (typeof item.accountNumber !== 'string') {
                                        errors.push(`Account Number missing`)
                                }
                                if (!iban || (typeof item.accountNumber === 'string' && item.accountNumber.replace(/[^a-zA-Z0-9]/g, '') !== iban.replace(/[^a-zA-Z0-9]/g, ''))) {
                                        errors.push(`Account No mismatch`)
                                }

                                if (rows.length > 1) {
                                        let nextStartDate = rows[i + 1]?.documentDate
                                        if (nextStartDate && dayjs.isDayjs(nextStartDate) && dayjs.isDayjs(item.documentDate)) {
                                                let diff = nextStartDate.diff(item.documentDate, "months", true)
                                                if (diff > 1.1) {
                                                        errors.push(`Missing statement after`)
                                                }
                                        }
                                }

                                return {
                                        ...item,
                                        monthsInBetween: 1,
                                        errors
                                }

                        })
                        let requiredFirst = getFirstRequiredDate(section, rows, 'documentDate')
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && `${row.documentDate.format(LONGDATE)}`,
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('documentDate'))
                        let errors = []
                        let additionalQuestions = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]
                        let account = section.data
                        if (last.creditLimit !== account.creditLimit) {
                                errors.push(`Credit Limit mismatch`)
                                additionalQuestions.push({
                                        name  : 'creditLimit',
                                        label : 'Credit Limit',
                                        answer: {
                                                type: 'euro',
                                        },
                                        target: `applicant${section.applicant}.debts[${section.index}].creditLimit`
                                })
                        }
                        if (dayjs.isDayjs(first.documentDate)) {
                                let betterEndDate = last.documentDate.add(1, 'day')
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [n, rangeUnit] = requiredRange
                                let [m, ageUnit] = maxAge
                                // test the age of the most recent document
                                let age = dayjs().diff(betterEndDate, ageUnit, true)

                                if (age > m && !account.isClosed) {
                                        errors.push(`Most recent statement is older than ${m} ${ageUnit} (already in app)`)
                                }
                                // then check the range of the documents for
                                // a) the last doc is not too old - use doc date
                                // b) the last doc is too old - use today

                                if (!account.isNewAccount) {
                                        let lastDateToWorkWith = age > m && !account.isClosed ? dayjs().subtract(2, 'week') : last.documentDate.add(1, 'day')
                                        let requiredFirst = lastDateToWorkWith.subtract(n, rangeUnit)
                                        let whatWeHave = sorted.map(it => it.documentDate.format('MMMM'))
                                        if (n > 0) {
                                                let missingMonths = []
                                                for (let i = 1; i <= n; i++) {
                                                        let date = requiredFirst.add(i, 'months')
                                                        if (!whatWeHave.includes(date.format('MMMM'))) {
                                                                missingMonths.push(date.format('MMMM'))
                                                        }
                                                }
                                                if (missingMonths.length) {
                                                        errors.push(`Missing statements for ${joinWithConjunction(missingMonths, 'and')}`)
                                                }
                                        }
                                }
                        }
                        if (additionalQuestions.length) {
                                return {
                                        errors,
                                        additionalQuestions
                                }
                        }
                        return errors
                },
                SummaryMessages       : ({
                        rows,
                        mortgage,
                        minimums,
                        section
                }) => {
                        let errors = cardStatementConfig.getSectionErrors(rows, mortgage, minimums, section)
                        return errors.map((it, i) => <Alert key={it} message={it} type="error"/>)
                },
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`card-statement-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`card-statement-accountNumber-${uploadId}`}
                                      item={managerGet('accountNumber')}/>,
                        <MyLittleForm key={`card-statement-outstandingBalance-${uploadId}`}
                                      item={managerGet('outstandingBalance')}/>,
                        <MyLittleForm key={`card-statement-creditLimit-${uploadId}`} item={managerGet('creditLimit')}/>
                ],
                SectionApplicationForm: ({
                        section,
                        additionalQuestions
                }) => {
                        let target = section.applicant === 1 ? `applicant1.debts[${section.index}]` : `applicant2.debts[${section.index}]`
                        let questions = [
                                {
                                        name  : 'accountNumber',
                                        label : 'Account Number',
                                        answer: {
                                                type: 'text'
                                        },
                                        target: `${target}.accountNumber`
                                }
                        ]
                        let mainRow = [
                                {
                                        name  : 'isNewAccount',
                                        label : 'Is New Account',
                                        answer: {
                                                type   : 'select',
                                                choices: [
                                                        {
                                                                value: true,
                                                                text : 'Yes'
                                                        },
                                                        {
                                                                value: false,
                                                                text : 'No'
                                                        }
                                                ]

                                        },
                                        target: `${target}.isNewAccount`
                                },
                                {
                                        name  : 'isClosed',
                                        label : 'Is Closed',
                                        answer: {
                                                type   : 'select',
                                                choices: [
                                                        {
                                                                value: true,
                                                                text : 'Yes'
                                                        },
                                                        {
                                                                value: false,
                                                                text : 'No'
                                                        }
                                                ]
                                        },
                                        target: `${target}.isClosed`
                                },
                                {
                                        name  : 'currency',
                                        label : 'Currency',
                                        answer: {
                                                type: 'text'
                                        },
                                        target: `${target}.currency`
                                }
                        ]
                        if (additionalQuestions && additionalQuestions.length) {
                                questions.push(additionalQuestions)
                        }
                        questions.push(mainRow)
                        return <MortgageForm questions={questions}/>
                },
        }
        const loanStatementConfig = {
                template             : {
                        items: [
                                {
                                        name : 'iban',
                                        label: 'IBAN',
                                        type : 'text',
                                        scan : 'iban'
                                },
                                {
                                        name : 'openingBalance',
                                        label: 'Opening Balance',
                                        type : 'currency',
                                        scan : 'openingBalance'
                                },
                                {
                                        name : 'closingBalance',
                                        label: 'Closing Balance',
                                        type : 'currency',
                                        scan : 'closingBalance'
                                },
                                {
                                        name : 'startDate',
                                        label: 'Start Date',
                                        type : 'date',
                                        scan : 'startDate'
                                },
                                {
                                        name : 'endDate',
                                        label: 'End Date',
                                        type : 'date',
                                        scan : 'endDate'
                                },
                        ]
                },
                sortDate             : 'startDate',
                uploadsSummaryColumns: [
                        {
                                title    : 'Start Date',
                                key      : 'startDate',
                                dataIndex: 'startDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'End Date',
                                key      : 'endDate',
                                dataIndex: 'endDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Opening Balance',
                                key      : 'openingBalance',
                                dataIndex: 'openingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Closing Balance',
                                key      : 'closingBalance',
                                dataIndex: 'closingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Mths',
                                key      : 'monthsInBetween',
                                dataIndex: 'monthsInBetween',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let thisAccount = section.data
                        let iban = thisAccount.accountNumber
                        let rows = uploads.map(it => ({
                                key           : it.id, // iban: valueOrNoData (it, 'iban'),
                                startDate     : valueOrNoData(it, 'startDate'),
                                endDate       : valueOrNoData(it, 'endDate'),
                                startDateRow  : getDataPointRow(it, 'startDate').row,
                                endDateRow    : getDataPointRow(it, 'endDate').row,
                                openingBalance: valueOrNoData(it, 'openingBalance'),
                                closingBalance: valueOrNoData(it, 'closingBalance'),
                                iban          : valueOrNoData(it, 'iban'),
                                upload        : it
                        })).sort(sorterByDateName('startDate'))
                        rows = rows.map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.startDate)) {
                                        errors.push(`Start Date missing`)
                                }
                                if (!dayjs.isDayjs(item.endDate)) {
                                        errors.push(`End Date missing`)
                                }
                                if (isNaN(item.openingBalance)) {
                                        errors.push(`Opening Balance missing`)
                                }
                                if (isNaN(item.closingBalance)) {
                                        errors.push(`Closing Balance missing`)
                                }
                                if (typeof item.iban !== 'string') {
                                        errors.push(`IBAN missing`)
                                }
                                if (!iban || (typeof item.iban === 'string' && item.iban.replace(/[^a-zA-Z0-9]/g, '') !== iban.replace(/[^a-zA-Z0-9]/g, ''))) {
                                        errors.push(`IBAN mismatch`)
                                }
                                if (rows.length > 1) {
                                        let nextRow = rows[i + 1]
                                        let nextOpenBalance = nextRow?.openingBalance
                                        let nextStartDate = nextRow?.startDate
                                        if (!isNaN(item.endDate) && nextStartDate && nextStartDate.isBefore(item.endDate)) {
                                                errors.push(`New End Date: ${nextStartDate.subtract(1, 'day').format(LONGDATE)}`)
                                        }
                                        else if (nextOpenBalance && !isNaN(nextOpenBalance) && !isNaN(item.closingBalance)) {
                                                let diff = Math.abs(nextOpenBalance - item.closingBalance)
                                                if (diff > 1) {
                                                        errors.push(`Missing statement after`)
                                                }
                                        }

                                }
                                return {
                                        ...item,
                                        monthsInBetween: dayjs.isDayjs(item.endDate) && dayjs.isDayjs(item.startDate) && item.endDate.add(1, 'day').diff(item.startDate, 'months', true).toFixed(1),
                                        errors
                                }

                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                        return rows
                        // let rows =  uploads.map(it => ({
                        //     key          : it.id,
                        //     accountNumber: valueOrNoData(it, 'accountNumber'),
                        //     documentDate : valueOrNoData(it, 'documentDate'),
                        // })).sort(sorterByDateName('documentDate'))
                        // rows = rows.map((item, i) => {
                        //     let errors = []
                        //     if (!dayjs.isDayjs(item.documentDate)) {
                        //         errors.push(`Doc Date missing`)
                        //     }
                        //     if (typeof item.accountNumber !== 'string') {
                        //         errors.push(`Account Number missing`)
                        //     }
                        //     if (rows.length > 1) {
                        //         let nextStartDate = rows[i + 1]?.documentDate
                        //         if (nextStartDate && dayjs.isDayjs(nextStartDate) && dayjs.isDayjs(item.documentDate)) {
                        //             let diff = nextStartDate.diff(item.endDate, "days")
                        //             if (diff > 2) {
                        //                 errors.push(`Missing statement?`)
                        //             }
                        //         }
                        //     }
                        //
                        //     return {
                        //         ...item,
                        //         monthsInBetween: 1,
                        //         errors
                        //     }
                        //
                        // })
                        // return rows
                },
                getRowLabel          : (values) => {
                        return !!values.startDate && !!values.endDate && `${values.startDate.format(LONGDATE)} - ${values.endDate.format(LONGDATE)}`
                },
                UploadDataForm       : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`account-statement-iban${uploadId}`} item={managerGet('iban')}/>,
                        <div key={`account-statement-group1-${uploadId}`} className="d-row gap-15 j-equal">
                                <MyLittleForm key={`account-statement-startDate${uploadId}`} item={managerGet('startDate')}/>
                                <MyLittleForm key={`account-statement-openingBalance${uploadId}`} item={managerGet('openingBalance')}/>
                        </div>,
                        <div key={`account-statement-group2-${uploadId}`} className="d-row gap-15 j-equal">
                                <MyLittleForm key={`account-statement-endDate${uploadId}`} item={managerGet('endDate')}/>
                                <MyLittleForm key={`account-statement-closingBalance${uploadId}`} item={managerGet('closingBalance')}/>
                        </div>
                ], // getSectionErrors             : (rows, mortgage, minimums, section) => {
                //     if (!rows.length) {
                //         return []
                //     }
                //     let sorted = rows.sort(sorterByDateName('documentDate'))
                //     let errors = []
                //     let first = sorted[0]
                //     let last = sorted[sorted.length - 1]
                //     if (dayjs.isDayjs(first.documentDate)) {
                //         let betterEndDate = last.documentDate.add(1,'day')
                //         let {maxAge, requiredRange} = minimums
                //         let [n, rangeUnit] = requiredRange
                //         let [m, ageUnit] = maxAge
                //         // test the age of the most recent document
                //         let age = dayjs().diff(betterEndDate, ageUnit, true)
                //
                //         if (age > m) {
                //             errors.push(`Most recent statement is older than ${m} ${ageUnit} (already in app)`)
                //         }
                //         // then check the range of the documents for
                //         // a) the last doc is not too old - use doc date
                //         // b) the last doc is too old - use today
                //
                //         let requiredFirst = age > m ? dayjs().subtract(n, rangeUnit) : last.documentDate.subtract(n, rangeUnit)
                //         if (!section.record.isNewAccount && first.documentDate.isAfter(requiredFirst)) {
                //             let missingMonthsCount = first.documentDate.diff(requiredFirst, 'months')
                //             if (missingMonthsCount > 0) {
                //                 let missingMonths = []
                //                 missingMonths.push(requiredFirst.format('MMMM'))
                //                 for (let i = 1; i <= missingMonthsCount; i++) {
                //                     let date = requiredFirst.add(i, 'months')
                //                     if (first.documentDate.format('D MM YYYY') !== date.format('D MM YYYY')) {
                //                     missingMonths.push(date.format('MMMM'))
                //                         }
                //                 }
                //                 errors.push(`Missing statements for ${joinWithConjunction(missingMonths, 'and')}`)
                //             }
                //         }
                //     }
                //
                //     let allAnswered = rows.every(it => it.errors.length === 0)
                //     if (!allAnswered) {
                //         errors.push(`Some data are not correct`)
                //     }
                //     return errors
                // },
                getSectionErrors      : (rows, mortgage, minimums, section) => {

                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('startDate'))
                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]
                        if (dayjs.isDayjs(first.startDate) && dayjs.isDayjs(last.endDate)) {
                                let thisAccount = section.data
                                let isNewAccount = thisAccount.isNewAccount
                                let isClosed = thisAccount.isClosed
                                let betterEndDate = last.endDate.add(1, 'day')
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [n, rangeUnit] = requiredRange
                                let [m, ageUnit] = maxAge
                                // Error on endDate too old
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                if (age > m && !isClosed) {
                                        errors.push(`Most recent statement is older than ${m} ${ageUnit} (already in app)`)
                                }
                                //Error on gaps between statements
                                let lastDateToWorkWith = age > m ? dayjs().subtract(2, 'week') : last.endDate.add(1, 'day')
                                let requiredFirst = lastDateToWorkWith.subtract(n, rangeUnit)

                                let rowsWithGapInDaysToNext = sorted.map((it, ix) => {
                                        let next = sorted[ix + 1]
                                        let daysToNext = 0
                                        if (next) {
                                                daysToNext = next.startDate.diff(it.endDate, 'days')
                                        }
                                        return {
                                                ...it,
                                                daysToNext
                                        }
                                })
                                let missingRanges = []
                                if (!isNewAccount) {
                                        if (requiredFirst.isBefore(first.startDate)) {
                                                missingRanges.push(`[ ${requiredFirst.format('D MMM')} - ${first.startDate.format('D MMM')} ]`)
                                        }
                                }
                                missingRanges = [
                                        ...missingRanges,
                                        ...rowsWithGapInDaysToNext.flatMap((it, ix) => {
                                                if (it.daysToNext > 1) {
                                                        return [`[ ${it.endDate.add(1, 'day').format('D MMM')} - ${it.endDate.add(it.daysToNext - 1, 'days').format('D MMM')} ]`]
                                                }
                                                return []
                                        })
                                ]

                                if (missingRanges.length) {
                                        errors.push(`Missing statement${missingRanges > 1 ? 's' : ''} for ${joinWithConjunction(missingRanges, 'and')}`)
                                }
                        }
                        return errors
                },
                SectionApplicationForm: ({section}) => {

                        let target = section.applicant === 1 ? `applicant1.debts[${section.index}]` : `applicant2.debts[${section.index}]`
                        let questions = [
                                {
                                        name  : 'accountNumber',
                                        label : 'IBAN',
                                        answer: {
                                                type: 'text',
                                        },
                                        target: `${target}.accountNumber`
                                },
                                [
                                        {
                                                name  : 'originalAmount',
                                                label : 'Original Loan',
                                                answer: {
                                                        type: 'euro',
                                                },
                                                target: `${target}.originalAmount`,
                                                rules : [{required: false}]
                                        },
                                        {
                                                name  : 'outstanding',
                                                label : 'Outstanding',
                                                answer: {
                                                        type: 'euro'
                                                },
                                                target: `${target}.outstanding`,
                                                rules : [{required: false}]
                                        }
                                ],
                                [
                                        {
                                                name  : 'monthlyPayment',
                                                label : 'Monthly Payment',
                                                answer: {
                                                        type: 'euro'
                                                },
                                                target: `${target}.monthlyPayment`,
                                                rules : [{required: false}]
                                        },
                                        {
                                                name  : 'endDate',
                                                label : 'Final Payment Date',
                                                answer: {
                                                        type: 'date'
                                                },
                                                target: `${target}.endDate`,
                                                rules : [{required: false}]
                                        }
                                ],
                                [
                                        {
                                                name  : 'isNewAccount',
                                                label : 'Is New Account',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]

                                                },
                                                target: `${target}.isNewAccount`
                                        },
                                        {
                                                name  : 'isClosed',
                                                label : 'Is Closed',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]

                                                },
                                                target: `${target}.isClosed`
                                        },
                                ],
                                {
                                        name  : 'currency',
                                        label : 'Currency',
                                        answer: {
                                                type: 'text'
                                        },
                                        target: `${target}.currency`,
                                        rules : [{required: false}]
                                },
                        ]
                        return <MortgageForm questions={questions}/>
                },
        }
        const mortgageStatementConfig = {
                template              : {
                        items: [
                                {
                                        name : 'accountNumber',
                                        label: 'Account Number',
                                        type : 'text'
                                },
                                {
                                        name : 'outstandingBalance',
                                        label: 'Balance',
                                        type : 'currency',
                                },
                                {
                                        name : 'startDate',
                                        label: 'Start Date',
                                        type : 'date',
                                },
                                {
                                        name : 'endDate',
                                        label: 'End Date',
                                        type : 'date',
                                }
                        ]
                },
                sortDate              : 'startDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Start Date',
                                key      : 'startDate',
                                dataIndex: 'startDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'End Date',
                                key      : 'endDate',
                                dataIndex: 'endDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Account Number',
                                key      : 'accountNumber',
                                dataIndex: 'accountNumber'
                        },
                        {
                                title    : 'Outstanding',
                                key      : 'outstandingBalance',
                                dataIndex: 'outstandingBalance',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let thisAccount = section.data
                        let rows = uploads.map(it => ({
                                key               : it.id,
                                accountNumber     : valueOrNoData(it, 'accountNumber'),
                                startDate         : valueOrNoData(it, 'startDate'),
                                endDate           : valueOrNoData(it, 'endDate'),
                                outstandingBalance: valueOrNoData(it, 'outstandingBalance'),
                                upload            : it
                        })).map((item, i) => {
                                let errors = []

                                if (!dayjs.isDayjs(item.startDate)) {
                                        errors.push(`Start Date missing`)
                                }
                                if (!dayjs.isDayjs(item.endDate)) {
                                        errors.push(`End Date missing`)
                                }
                                if (isNaN(item.outstandingBalance)) {
                                        errors.push(`Outstanding Balance missing`)
                                }
                                if (typeof item.accountNumber !== 'string') {
                                        errors.push(`Account Number missing`)
                                }
                                if (!thisAccount.accountNumber || thisAccount.accountNumber !== item.accountNumber) {
                                        errors.push(`Account No mismatch`)
                                }
                                return {
                                        ...item,
                                        errors
                                }
                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('startDate'))
                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]
                        let thisAccount = section.data
                        let isNewAccount = thisAccount.isNewAccount
                        let isClosed = thisAccount.isClosed
                        if (dayjs.isDayjs(first.startDate) && dayjs.isDayjs(last.endDate)) {
                                let betterEndDate = last.endDate.add(1, 'day')
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [n, rangeUnit] = requiredRange
                                let [m, ageUnit] = maxAge
                                // Error on endDate too old
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                if (age > m && !isClosed) {
                                        errors.push(`Most recent statement is older than ${m} ${ageUnit} (already in app)`)
                                }
                                //Error on gaps between statements
                                let lastDateToWorkWith = age > m ? dayjs().subtract(2, 'week') : last.endDate.add(1, 'day')
                                let requiredFirst = lastDateToWorkWith.subtract(n, rangeUnit)

                                let rowsWithGapInDaysToNext = sorted.map((it, ix) => {
                                        let next = sorted[ix + 1]
                                        let daysToNext = 0
                                        if (next) {
                                                daysToNext = next.startDate.diff(it.endDate, 'days')
                                        }
                                        return {
                                                ...it,
                                                daysToNext
                                        }
                                })
                                let missingRanges = []
                                if (!isNewAccount) {
                                        if (requiredFirst.isBefore(first.startDate)) {
                                                missingRanges.push(`[ ${requiredFirst.format('D MMM')} - ${first.startDate.format('D MMM')} ]`)
                                        }
                                }
                                missingRanges = [
                                        ...missingRanges,
                                        ...rowsWithGapInDaysToNext.flatMap((it, ix) => {
                                                if (it.daysToNext > 1) {
                                                        return [`[ ${it.endDate.add(1, 'day').format('D MMM')} - ${it.endDate.add(it.daysToNext - 1, 'days').format('D MMM')} ]`]
                                                }
                                                return []
                                        })
                                ]

                                if (missingRanges.length) {
                                        errors.push(`Missing statement${missingRanges > 1 ? 's' : ''} for ${joinWithConjunction(missingRanges, 'and')}`)
                                }
                        }
                        return errors
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && `${row.documentDate.format(LONGDATE)}`,
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`loan-statement-accountNumber-${uploadId}`}
                                      item={managerGet('accountNumber')}/>,
                        <div className={'d-row gap-15 j-equal'} key={`loan-statement-group1-${uploadId}`}>
                                <MyLittleForm key={`loan-statement-startDate-${uploadId}`}
                                              item={managerGet('startDate')}/>
                                <MyLittleForm key={`loan-statement-endDate-${uploadId}`}
                                              item={managerGet('endDate')}/>
                        </div>,
                        <div className={'d-row gap-15 j-equal'} key={`loan-statement-group2-${uploadId}`}>
                                <div/>
                                <MyLittleForm key={`loan-statement-outstandingBalance-${uploadId}`}
                                              item={managerGet('outstandingBalance')}/>
                        </div>,

                ],
                SectionApplicationForm: ({section}) => {
                        let target = section.applicant === 1 ? `applicant1.properties[${section.index}]` : `applicant2.properties[${section.index}]`
                        let questions = [
                                [
                                        {
                                                name  : 'accountNumber',
                                                label : 'Account Number',
                                                answer: {
                                                        type: 'text',
                                                },
                                                target: `${target}.accountNumber`
                                        },
                                        {
                                                name  : 'originalAmount',
                                                label : 'Original Loan',
                                                answer: {
                                                        type: 'euro',
                                                },
                                                target: `${target}.originalAmount`
                                        }
                                ],
                                [
                                        {
                                                name  : 'monthlyRepayments',
                                                label : 'Monthly Payment',
                                                answer: {
                                                        type: 'euro'
                                                },
                                                target: `${target}.monthlyRepayments`
                                        },
                                        {
                                                name  : 'endDate',
                                                label : 'Final Payment Date',
                                                answer: {
                                                        type: 'date'
                                                },
                                                target: `${target}.endDate`,
                                                rules : [{required: false}]
                                        }
                                ],
                                [
                                        {
                                                name  : 'isNewAccount',
                                                label : 'Is New Account',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]
                                                },
                                                target: `${target}.isNewAccount`
                                        },
                                        {
                                                name  : 'isClosed',
                                                label : 'Is Closed',
                                                answer: {
                                                        type   : 'select',
                                                        choices: [
                                                                {
                                                                        value: true,
                                                                        text : 'Yes'
                                                                },
                                                                {
                                                                        value: false,
                                                                        text : 'No'
                                                                }
                                                        ]
                                                },
                                                target: `${target}.isClosed`
                                        }
                                ]
                        ]
                        return <MortgageForm questions={questions}/>
                },
        }
        const proofOfAddressConfig = {
                template              : {
                        items: [
                                {
                                        name : 'documentDate',
                                        label: 'Document Date',
                                        type : 'date',
                                },
                                {
                                        name : 'address',
                                        label: 'Address',
                                        type : 'text'
                                }
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Address',
                                key      : 'address',
                                dataIndex: 'address'
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let applicant = section.applicant === 1 ? mortgage.applicant1 : mortgage.applicant2
                        let rows = uploads.map(it => ({
                                key         : it.id,
                                address     : valueOrNoData(it, 'address'),
                                documentDate: valueOrNoData(it, 'documentDate'),
                                upload      : it
                        })).map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Doc Date missing`)
                                }
                                if (compressString(item.address) !== compressString(applicant.personal.addressCurrent)) {
                                        errors.push(<>Address does not match: <DifferenceHighlighter str2={item.address} str1={applicant.personal.addressCurrent}/> </>)
                                }
                                if (typeof item.address !== 'string') {
                                        errors.push(`Address missing`)
                                }

                                return {
                                        ...item,
                                        errors
                                }
                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let applicant = section.applicant === 1 ? mortgage.applicant1 : mortgage.applicant2
                        let sorted = rows.sort(sorterByDateName('documentDate'))
                        let last = sorted[sorted.length - 1]
                        let errors = []
                        let noneStamped = !last.upload.stampedOn
                        if (noneStamped) {
                                errors.push(`Document not stamped`)
                        }
                        let additionalQuestions = []
                        let today = dayjs()
                        if (dayjs.isDayjs(last.documentDate)) {
                                let mths = today.diff(last.documentDate, 'months', true)
                                if (mths > 3) {
                                        errors.push(`Over three months old`)
                                }
                        }

                        if (typeof last.address === 'string') {
                                if (compressString(last.address) !== compressString(applicant.personal.addressCurrent)) {
                                        errors.push('Address does not match')
                                        additionalQuestions.push({
                                                name  : 'currentAddress',
                                                label : 'Current Address',
                                                answer: {
                                                        type: 'text'
                                                },
                                                target: `applicant${section.applicant}.personal.addressCurrent`
                                        })
                                }
                        }
                        if (additionalQuestions.length) {
                                return {
                                        errors,
                                        additionalQuestions
                                }
                        }

                        return errors
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && `${row.documentDate.format(LONGDATE)}`,
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`poa-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`poa-address-${uploadId}`} item={managerGet('address')}/>
                ],
                SectionApplicationForm: ({
                        section,
                        additionalQuestions
                }) => {
                        if (!additionalQuestions || !additionalQuestions.length) {
                                return null
                        }
                        return <MortgageForm questions={additionalQuestions}/>
                }
        }
        const payslipConfig = {
                template              : {
                        items: [
                                {
                                        name : 'documentDate',
                                        label: 'Payslip Date',
                                        type : 'date',
                                },
                                {
                                        name : 'grossBasic',
                                        label: 'Gross Basic',
                                        type : 'currency',

                                },
                                {
                                        name : 'netPay',
                                        label: 'Net Pay',
                                        type : 'currency',
                                },
                                {
                                        name : 'grossPay',
                                        label: 'Gross Pay',
                                        type : 'currency',
                                },
                                {
                                        name    : 'pensionDeduction',
                                        label   : 'Pension Deduction',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'avcDeduction',
                                        label   : 'AVC Deduction',
                                        type    : 'currency',
                                        optional: true,
                                }
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Gross Basic',
                                key      : 'grossBasic',
                                dataIndex: 'grossBasic',
                                align    : 'right'
                        },
                        {
                                title    : 'Net Pay',
                                key      : 'netPay',
                                dataIndex: 'netPay',
                                align    : 'right',
                        },
                        {
                                title    : 'Gross Pay',
                                key      : 'grossPay',
                                dataIndex: 'grossPay',
                                align    : 'right',
                        },
                        {
                                title    : 'Pension',
                                key      : 'pensionDeduction',
                                dataIndex: 'pensionDeduction',
                                align    : 'right',
                        },
                        {
                                title    : 'AVC',
                                key      : 'avcDeduction',
                                dataIndex: 'avcDeduction',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>//text.join(', ')
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {

                        let employment = section.data
                        let freq = employment.payFrequency

                        let rows = uploads.map(it => ({
                                key             : it.id,
                                netPay          : valueOrNoData(it, 'netPay'),
                                grossBasic      : valueOrNoData(it, 'grossBasic'),
                                grossPay        : valueOrNoData(it, 'grossPay'),
                                documentDate    : valueOrNoData(it, 'documentDate'),
                                pensionDeduction: valueOrNoData(it, 'pensionDeduction'),
                                avcDeduction    : valueOrNoData(it, 'avcDeduction'),
                                upload          : it
                        })).sort(sorterByDateName('documentDate'))
                        let numberRequired = 0
                        switch (freq) {
                                case Frequencies.MONTHLY:
                                        numberRequired = 3;
                                        break;
                                case Frequencies.FORTNIGHTLY:
                                        numberRequired = 5;
                                        break;
                                case Frequencies.WEEKLY:
                                        numberRequired = 10;
                                        break;
                        }
                        rows = rows.map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Doc Date missing`)
                                }
                                if (isNaN(item.netPay)) {
                                        errors.push(`Net Pay missing`)
                                }
                                if (isNaN(item.grossPay)) {
                                        errors.push(`Gross Pay missing`)
                                }
                                if (isNaN(item.grossBasic)) {
                                        errors.push(`Gross Basic missing`)
                                }
                                if (rows.length > 1) {
                                        let nextDate = rows[i + 1]?.documentDate
                                        if (freq && nextDate && dayjs.isDayjs(nextDate) && dayjs.isDayjs(item.documentDate)) {
                                                let diff = nextDate.diff(item.documentDate, "days")
                                                let maxDiff
                                                switch (freq) {
                                                        case Frequencies.MONTHLY:
                                                                maxDiff = 35;
                                                                break
                                                        case Frequencies.FORTNIGHTLY:
                                                                maxDiff = 18;
                                                                break
                                                        case Frequencies.WEEKLY:
                                                                maxDiff = 10;
                                                                break;
                                                }
                                                if (diff > maxDiff) {
                                                        errors.push(`Missing payslip after`)
                                                }
                                        }
                                }
                                return {
                                        ...item,
                                        errors
                                }

                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)

                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel           : (row) => !!row && dayjs.isDayjs(row.documentDate) && `${row.documentDate.format(LONGDATE)}`,
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <div className="d-row j-equal" key={`payslip-group-1-${uploadId}`}>
                                <MyLittleForm key={`payslip-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                                <MyLittleForm key={`payslip-grossBasic-${uploadId}`} item={managerGet('grossBasic')}/>
                        </div>,
                        <div className="d-row j-equal" key={`payslip-group-1-${uploadId}`}>
                                <MyLittleForm key={`payslip-grossPay-${uploadId}`} item={managerGet('grossPay')}/>
                                <MyLittleForm key={`payslip-netPay-${uploadId}`} item={managerGet('netPay')}/>
                        </div>,
                        <div className="d-row j-equal" key={`payslip-group-2-${uploadId}`}>
                                <MyLittleForm key={`payslip-pensionDeduction-${uploadId}`} item={managerGet('pensionDeduction')}/>
                                <MyLittleForm key={`payslip-avcDeduction-${uploadId}`} item={managerGet('avcDeduction')}/>
                        </div>
                ],
                getSectionErrors      : (rows, mortgage, minimums, section) => {

                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('documentDate'))
                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]
                        if (!section.data.payFrequency) {
                                let oldIncome = section.applicant === 1 ? mortgage.applicant1.income : mortgage.applicant2.income
                                if (!oldIncome.payFrequency) {
                                        errors.push('Pay Frequency missing')
                                }
                                else {
                                        section.data.payFrequency = oldIncome.payFrequency
                                }
                        }
                        let errorAllowance
                        if (section.data.payFrequency) {
                                let increment
                                let minRange
                                switch (section.data.payFrequency) {
                                        case Frequencies.MONTHLY:
                                                increment = [
                                                        1,
                                                        'month'
                                                ]
                                                minRange = 3
                                                errorAllowance = 14 // can be 14 days off
                                                break;
                                        case Frequencies.FORTNIGHTLY:
                                                increment = [
                                                        2,
                                                        'weeks'
                                                ]
                                                errorAllowance = 10 // can be 10 days off
                                                minRange = 5
                                                break;
                                        case Frequencies.WEEKLY:
                                                increment = [
                                                        1,
                                                        'week'
                                                ]
                                                errorAllowance = 4 // can be 4 days off
                                                minRange = 10
                                                break;
                                        default:
                                }

                                // get the expected payslip timeframe;
                                let today = dayjs()
                                let effectiveLastPayslipDate = today.subtract(1, 'month').endOf('month')
                                if (dayjs.isDayjs(last.documentDate) && effectiveLastPayslipDate.isBefore(last.documentDate)) {
                                        effectiveLastPayslipDate = last.documentDate
                                }
                                let noPayslipsNeededBeforeDate = effectiveLastPayslipDate.subtract(3, 'months').endOf('month')
                                let expectedPayslipDates = []
                                let currentDate = effectiveLastPayslipDate.clone()
                                while (currentDate.isAfter(noPayslipsNeededBeforeDate)) {
                                        expectedPayslipDates.push(currentDate)
                                        currentDate = currentDate.subtract(...increment)
                                }
                                let missingPayslips = expectedPayslipDates.filter(date => !sorted.some(it => dayjs.isDayjs(it.documentDate) && Math.abs(it.documentDate.diff(date, 'days')) <= errorAllowance));
                                if (missingPayslips.length) {
                                        errors.push(`Missing payslip${missingPayslips.length > 1 ? 's' : ''} for ${joinWithConjunction(missingPayslips.reverse().map(it => it.format('D MMM')), 'and')}`)
                                }

                                // set standard most recent ot of date
                                let betterEndDate = last.documentDate.add(1, 'day')
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [m, ageUnit] = maxAge
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                if (age > m) {
                                        errors.push(`Most recent payslip is older than ${m} ${ageUnit} (already in app)`)
                                }

                                // if (dayjs.isDayjs(last.documentDate)) {
                                //
                                //     // find possible dates after the last payslip
                                //     let possibleDatesPostLast = []
                                //
                                //     let lastTestDate = last.documentDate.add(...increment)
                                //     while (lastTestDate.isBefore(today)) {
                                //         possibleDatesPostLast.push(lastTestDate)
                                //         lastTestDate = lastTestDate.add(...increment)
                                //     }
                                //     let practicalLastDate = possibleDatesPostLast.length ? possibleDatesPostLast[possibleDatesPostLast.length - 1] : last.documentDate
                                //
                                //     // if there are no more payslips possible in this month, include the current month
                                //
                                //     let nextAfterLast = practicalLastDate.add(...increment)
                                //
                                //     let includeCurrentMonth = practicalLastDate.isBefore(today)
                                //     // set the required months to the previous 3, unless no more payslips are possible in this month
                                //     let monthsToInclude = includeCurrentMonth ? [
                                //         0,
                                //         1,
                                //         2
                                //     ] : [
                                //         1,
                                //         2,
                                //         3
                                //     ]
                                //     let requiredMonths = monthsToInclude.map(n => {
                                //         return today.subtract(n, 'month').format('MMM')
                                //     })
                                //     let requiredPayslips = []
                                //     let testDate = practicalLastDate
                                //     console.log({requiredMonths, test: testDate.format('MMM')})
                                //     while (requiredMonths.includes(testDate.format('MMM'))) {
                                //         if (section.applicant ===1){
                                //             console.log({section, testDate, requiredMonths,test: testDate.format('D MMM')})
                                //
                                //         }
                                //
                                //         requiredPayslips.push(testDate)
                                //         testDate = testDate.subtract(...increment)
                                //     }
                                //     if (section.applicant ===1) {
                                //         console.log({requiredPayslips})
                                //     }
                                //     let missingPayslips = requiredPayslips.filter(date => !sorted.some(it => dayjs.isDayjs(it.documentDate) && Math.abs(it.documentDate.diff(date, 'days')) <= 5));
                                //
                                //     if (missingPayslips.length) {
                                //         errors.push(`Missing payslip${missingPayslips.length > 1 ? 's' : ''} for ${joinWithConjunction(missingPayslips.reverse().map(it => it.format('D MMM')), 'and')}`)
                                //     }
                                //
                                //
                                //
                                //
                                //
                                // }
                        }

                        return errors
                },
                SectionApplicationForm: ({section}) => {

                        let target = !!section.index ? section.applicant === 1 ? `applicant1.employmentIncome[${section.index}]` : `applicant2.employmentIncome[${section.index}]` : section.applicant === 1 ? `applicant1.income` : `applicant2.income`

                        let questions = [
                                {
                                        name  : 'payFrequency',
                                        label : 'Pay Frequency',
                                        answer: {
                                                type   : 'select',
                                                choices: [
                                                        Frequencies.WEEKLY,
                                                        Frequencies.MONTHLY,
                                                        Frequencies.FORTNIGHTLY
                                                ].map(it => ({
                                                        value: it,
                                                        label: capitaliseFirst(it.toLowerCase())
                                                }))
                                        },
                                        target: `${target}.payFrequency`
                                }
                        ]

                        return <MortgageForm questions={questions}/>
                }
        }
        const edsConfig = {
                template              : {
                        items: [
                                {
                                        name : 'year',
                                        label: 'Year',
                                        type : 'text',
                                },
                                {
                                        name    : 'grossBasic',
                                        label   : 'Gross Basic',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'grossBasic1',
                                        label   : 'Gross Basic 2',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'grossBasic2',
                                        label   : 'Gross Basic 3',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'employer1',
                                        label   : 'Employer 1',
                                        type    : 'text',
                                        optional: true,
                                },
                                {
                                        name : 'pay1',
                                        label: 'Pay 1',
                                        type : 'currency',
                                },
                                {
                                        name : 'start1',
                                        label: 'Start 1',
                                        type : 'date',
                                },
                                {
                                        name    : 'end1',
                                        label   : 'End 1',
                                        type    : 'date',
                                        optional: true,
                                },
                                {
                                        name    : 'employer2',
                                        label   : 'Employer 2',
                                        type    : 'text',
                                        optional: true,
                                },
                                {
                                        name    : 'pay2',
                                        label   : 'Pay 2',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'start2',
                                        label   : 'Start 2',
                                        type    : 'date',
                                        optional: true,
                                },
                                {
                                        name    : 'end2',
                                        label   : 'End 2',
                                        type    : 'date',
                                        optional: true,
                                },
                                {
                                        name    : 'employer3',
                                        label   : 'Employer 3',
                                        type    : 'text',
                                        optional: true,
                                },
                                {
                                        name    : 'pay3',
                                        label   : 'Pay 3',
                                        type    : 'currency',
                                        optional: true,
                                },
                                {
                                        name    : 'start3',
                                        label   : 'Start 3',
                                        type    : 'date',
                                        optional: true,
                                },
                                {
                                        name    : 'end3',
                                        label   : 'End 3',
                                        type    : 'date',
                                        optional: true,
                                }
                        ]
                },
                sortDate              : 'fakeYearDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Year',
                                key      : 'year',
                                dataIndex: 'year',
                        },
                        {
                                title    : 'Gross Basic',
                                key      : 'grossBasic',
                                dataIndex: 'grossBasic',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => {
                                //let year = valueOrNoData(it, 'year')
                                // let gb = valueOrNoData(it, 'grossBasic')
                                // let gb1 = valueOrNoData(it, 'grossBasic1')
                                // let gb2 = valueOrNoData(it, 'grossBasic2')
                                let pay1 = valueOrNoData(it, 'pay1')
                                let pay2 = valueOrNoData(it, 'pay2')
                                let pay3 = valueOrNoData(it, 'pay3')

                                let sum = 0
                                // if (!isNaN(gb)) {
                                //     sum += gb
                                // }
                                // if (!isNaN(gb1)) {
                                //     sum += gb1
                                // }
                                // if (!isNaN(gb2)) {
                                //     sum += gb2
                                // }
                                if (!isNaN(pay1)) {
                                        sum += pay1
                                }
                                if (!isNaN(pay2)) {
                                        sum += pay2
                                }
                                if (!isNaN(pay3)) {
                                        sum += pay3
                                }
                                return {
                                        key         : it.id,
                                        fakeYearDate: dayjs(valueOrNoData(it, 'year'), 'YYYY'),
                                        year        : valueOrNoData(it, 'year'),
                                        grossBasic  : sum > 0 ? Number(sum.toFixed(2)) : <span style={{color: red.primary}}>No Data</span>,
                                        upload      : it
                                }
                        }).sort(sorterByDateName('fakeYearDate'))
                        rows = rows.map((item, i) => {
                                let errors = []
                                if (typeof item.year !== 'string') {
                                        errors.push(`Year missing`)
                                }
                                if (isNaN(item.grossBasic)) {
                                        errors.push(`Gross Basic missing`)
                                }
                                return {
                                        ...item,
                                        errors
                                }
                        })
                        return rows
                },
                getRowLabel           : (row) => !!row && !!row.year && `${row.year}`,
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <div className="d-col gap-3">
                                <MyLittleForm key={`eds-year-${uploadId}`} item={managerGet('year')}/>
                                <div className="w-100" style={{borderBottom: '1px solid #a3a3a3'}}/>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-employer1-${uploadId}`} item={managerGet('employer1')}/>
                                        <MyLittleForm key={`eds-start1-${uploadId}`} item={managerGet('start1')}/>

                                </div>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-pay1-${uploadId}`} item={managerGet('pay1')}/>
                                        <MyLittleForm key={`eds-end1-${uploadId}`} item={managerGet('end1')}/>
                                </div>
                                <div className="w-100" style={{borderBottom: '1px solid #a3a3a3'}}/>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-employer2-${uploadId}`} item={managerGet('employer2')}/>
                                        <MyLittleForm key={`eds-start2-${uploadId}`} item={managerGet('start2')}/>

                                </div>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-pay2-${uploadId}`} item={managerGet('pay2')}/>
                                        <MyLittleForm key={`eds-end2-${uploadId}`} item={managerGet('end2')}/>
                                </div>
                                <div className="w-100" style={{borderBottom: '1px solid #a3a3a3'}}/>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-employer3-${uploadId}`} item={managerGet('employer3')}/>
                                        <MyLittleForm key={`eds-start3-${uploadId}`} item={managerGet('start3')}/>
                                </div>
                                <div className="d-row j-equal">
                                        <MyLittleForm key={`eds-pay3-${uploadId}`} item={managerGet('pay3')}/>
                                        <MyLittleForm key={`eds-end3-${uploadId}`} item={managerGet('end3')}/>
                                </div>
                        </div>
                        // <div className="d-row j-equal" key={`eds-group1-${uploadId}`}>
                        //     <MyLittleForm key={`eds-year-${uploadId}`} item={managerGet('year')}/>
                        //     <MyLittleForm key={`eds-grossBasic-${uploadId}`} item={managerGet('grossBasic')}/>
                        // </div>,
                        // <div className="d-row j-equal" key={`eds-group3-${uploadId}`}>
                        //     <div/>
                        //     <MyLittleForm key={`eds-grossBasic1-${uploadId}`} item={managerGet('grossBasic1')}/>
                        // </div>,
                        // <div className="d-row j-equal" key={`eds-group4-${uploadId}`}>
                        //     <div/>
                        //     <MyLittleForm key={`eds-grossBasic2-${uploadId}`} item={managerGet('grossBasic2')}/>
                        // </div>,

                ],
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('fakeYearDate'))

                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]

                        if (dayjs.isDayjs(first.fakeYearDate)) {

                                let betterEndDate = last.fakeYearDate

                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [n, rangeUnit] = requiredRange
                                let [m, ageUnit] = maxAge
                                // test the age of the most recent document
                                let age = dayjs().diff(betterEndDate, ageUnit, true)

                                if (age > m) {
                                        errors.push(`Most recent EDS is too old (already in app)`)
                                }
                                // then check the range of the documents for
                                // a) the last doc is not too old - use doc date
                                // b) the last doc is too old - use today

                                let required = [
                                        lastYear1,
                                        lastYear2,
                                        lastYear3
                                ]

                                required.forEach((year, i) => {
                                        let found = rows.find(it => Number(it.fakeYearDate.format("YYYY")) === year)
                                        if (!found) {
                                                errors.push(`Missing EDS for ${year}`)
                                        }
                                })
                        }
                        return errors
                },
                SectionApplicationForm: ({
                        section,
                        mortgage
                }) => {
                        let target = section.applicant === 1 ? `applicant1` : `applicant2`
                        let legacy = legacyIncome(mortgage)
                        let target2 = legacy ? `${target}.employment` : `${target}.employmentIncome[${section.applicant === 1 ? mortgage.applicant1.employmentIncome[0].id : mortgage.applicant2.employmentIncome[0].id}]`
                        let questions = [
                                {
                                        name  : 'ppsNumber',
                                        label : 'PPS Number',
                                        answer: {
                                                type: 'text',
                                        },
                                        target: `${target}.tax.ppsNumber`
                                },
                                {
                                        name  : 'currentEmployerName',
                                        label : 'Current Employer Name',
                                        answer: {
                                                type: 'text',
                                        },
                                        target: `${target2}.currentEmployerName`
                                },
                                {
                                        name  : 'currentEmployerStartDate',
                                        label : 'Start Date',
                                        answer: {
                                                type: 'date',
                                        },
                                        target: `${target2}.currentEmployerStartDate`
                                },
                                {
                                        name  : 'previousEmployerName',
                                        label : 'Previous Employer Name',
                                        answer: {
                                                type: 'text',
                                        },
                                        hides : (form) => {
                                                let day = form.getFieldValue('currentEmployerStartDate')
                                                if (day && dayjs.isDayjs(day)) {
                                                        return dayjs().diff(day, 'years') >= 3
                                                }
                                                return true
                                        },
                                        target: `${target2}.previousEmployerName`
                                },
                                {
                                        name  : 'previousEmployerStartDate',
                                        label : 'Start Date',
                                        answer: {
                                                type: 'date',
                                        },
                                        hides : (form) => {
                                                let day = form.getFieldValue('currentEmployerStartDate')
                                                if (day && dayjs.isDayjs(day)) {
                                                        return dayjs().diff(day, 'years') >= 3
                                                }
                                                return true
                                        },
                                        target: `${target2}.previousEmployerStartDate`
                                },
                                {
                                        name  : 'previousEmployerEndDate',
                                        label : 'End Date',
                                        answer: {
                                                type: 'date',
                                        },
                                        hides : (form) => {
                                                let day = form.getFieldValue('currentEmployerStartDate')
                                                if (day && dayjs.isDayjs(day)) {
                                                        return dayjs().diff(day, 'years') >= 3
                                                }
                                                return true
                                        },
                                        target: `${target2}.previousEmployerEndDate`
                                }
                        ]
                        return <MortgageForm questions={questions}/>
                }
        }
        const salaryCertConfig = {
                template              : {
                        items: [
                                {
                                        name : 'documentDate',
                                        label: 'Document Date',
                                        type : 'date',
                                },
                                {
                                        name : 'grossBasic',
                                        label: 'Gross Basic',
                                        type : 'currency',
                                },
                                {
                                        name    : 'overtime',
                                        label   : 'Overtime',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'overtimeGuaranteed',
                                        label   : 'Guaranteed',
                                        type    : 'check',
                                        optional: true
                                },
                                {
                                        name    : 'commission',
                                        label   : 'Commission',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'commissionGuaranteed',
                                        label   : 'Guaranteed',
                                        type    : 'check',
                                        optional: true
                                },
                                {
                                        name    : 'allowances',
                                        label   : 'Allowances',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'allowancesGuaranteed',
                                        label   : 'Guaranteed',
                                        type    : 'check',
                                        optional: true
                                },
                                {
                                        name    : 'other',
                                        label   : 'Other',
                                        type    : 'currency',
                                        optional: true
                                },
                                {
                                        name    : 'otherGuaranteed',
                                        label   : 'Guaranteed',
                                        type    : 'check',
                                        optional: true
                                },
                                ...(() => {
                                        return [
                                                lastYear1,
                                                lastYear2,
                                                lastYear3
                                        ].flatMap(year => {
                                                if (year === lastYear1) {
                                                        return [
                                                                {
                                                                        name    : `${year}bonus`,
                                                                        label   : `Bonus ${year}`,
                                                                        type    : 'currency',
                                                                        optional: true
                                                                },
                                                                {
                                                                        name    : `${year}bonusGuaranteed`,
                                                                        label   : `Guaranteed`,
                                                                        type    : 'check',
                                                                        optional: true
                                                                }
                                                        ]
                                                }
                                                return [
                                                        {
                                                                name    : `${year}bonus`,
                                                                label   : `Bonus ${year}`,
                                                                type    : 'currency',
                                                                optional: true
                                                        }
                                                ]
                                        })
                                })()
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Gross Basic',
                                key      : 'grossBasic',
                                dataIndex: 'grossBasic',
                                align    : 'right',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key         : it.id,
                                grossBasic  : valueOrNoData(it, 'grossBasic'),
                                documentDate: valueOrNoData(it, 'documentDate'),
                                upload      : it
                        })).sort(sorterByDateName('documentDate'))
                        rows = rows.map((item, i) => {
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Doc Date missing`)
                                }
                                if (isNaN(item.grossBasic)) {
                                        errors.push(`Gross Basic missing`)
                                }
                                return {
                                        ...item,
                                        errors
                                }
                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && row.documentDate.format(LONGDATE),
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <div className={'d-row j-equal'} key={`salary-cert-group1-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-documentDate-${uploadId}`} item={managerGet('documentDate')}/>
                                <MyLittleForm key={`salary-cert-grossBasic-${uploadId}`} item={managerGet('grossBasic')}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group2-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-overtime-${uploadId}`} item={managerGet('overtime')}/>
                                <MyLittleForm key={`salary-cert-overtimeGuaranteed-${uploadId}`}
                                              item={managerGet('overtimeGuaranteed')}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group3-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-commission-${uploadId}`} item={managerGet('commission')}/>
                                <MyLittleForm key={`salary-cert-commissionGuaranteed-${uploadId}`}
                                              item={managerGet('commissionGuaranteed')}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group4-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-allowances-${uploadId}`} item={managerGet('allowances')}/>
                                <MyLittleForm key={`salary-cert-allowancesGuaranteed-${uploadId}`}
                                              item={managerGet('allowancesGuaranteed')}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group5-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-other-${uploadId}`} item={managerGet('other')}/>
                                <MyLittleForm key={`salary-cert-otherGuaranteed-${uploadId}`} item={managerGet('otherGuaranteed')}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group6-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-${lastYear1}bonus-${uploadId}`} item={managerGet(`${lastYear1}bonus`)}/>
                                <MyLittleForm key={`salary-cert-${lastYear1}bonusGuaranteed\`-${uploadId}`}
                                              item={managerGet(`${lastYear1}bonusGuaranteed`)}/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group7-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-${lastYear2}bonus-${uploadId}`} item={managerGet(`${lastYear2}bonus`)}/>
                                <div/>
                        </div>,
                        <div className={'d-row j-equal'} key={`salary-cert-group8-${uploadId}`}>
                                <MyLittleForm key={`salary-cert-${lastYear3}bonus-${uploadId}`} item={managerGet(`${lastYear3}bonus`)}/>
                                <div/>
                        </div>
                ],
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('documentDate'))

                        let errors = []
                        let first = sorted[0]
                        let last = sorted[sorted.length - 1]

                        if (dayjs.isDayjs(last.documentDate)) {

                                let betterEndDate = last.documentDate
                                let {
                                            maxAge,
                                            requiredRange
                                    } = minimums
                                let [m, ageUnit] = maxAge
                                // test the age of the most recent document
                                let age = dayjs().diff(betterEndDate, ageUnit, true)

                                if (age > m) {
                                        errors.push(`Most recent Salary Cert is older than ${m} ${ageUnit} (already in app)`)
                                }
                                // then check the range of the documents for
                                // a) the last doc is not too old - use doc date
                                // b) the last doc is too old - use today
                        }
                        return errors
                },
                SectionApplicationForm: ({
                        section,
                        mortgage
                }) => {
                        let target = section.applicant === 1 ? `applicant1` : `applicant2`
                        let questions = [
                                {
                                        name  : 'positionHeld',
                                        label : 'Position Held',
                                        answer: {
                                                type: 'text',
                                        },
                                        target: !section.index ? `${target}.employment.positionHeld` : `${target}.employmentIncome[${section.index}].positionHeld`
                                },
                                {
                                        name  : 'publicSector',
                                        label : 'Sector',
                                        answer: {
                                                type   : 'select',
                                                choices: [
                                                        {
                                                                value: true,
                                                                text : 'Public'
                                                        },
                                                        {
                                                                value: false,
                                                                text : 'Private'
                                                        }
                                                ]
                                        },
                                        target: !section.index ? `${target}.employment.publicSector` : `${target}.employmentIncome[${section.index}].publicSector`
                                },
                        ]
                        return <MortgageForm questions={questions}/>
                }

        }
        const giftCertificateConfig = {
                template             : {
                        items: [
                                {
                                        name : 'amount',
                                        label: 'Amount',
                                        type : 'currency',
                                },
                                {
                                        name : 'donor',
                                        label: 'Donor',
                                        type : 'text',
                                },
                                {
                                        name : 'documentDate',
                                        label: 'Document Date',
                                        type : 'date',
                                }
                        ]
                },
                sortDate             : 'documentDate',
                uploadsSummaryColumns: [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Amount',
                                key      : 'amount',
                                dataIndex: 'amount'
                        },
                        {
                                title    : 'Donor',
                                key      : 'donor',
                                dataIndex: 'donor'
                        }
                ],
                getUploadSummaryRows : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key         : it.id,
                                amount      : valueOrNoData(it, 'amount'),
                                donor       : valueOrNoData(it, 'donor'),
                                documentDate: valueOrNoData(it, 'documentDate'),
                                upload      : it
                        }))
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel          : (row) => !!row && !!row.documentDate && row.documentDate.format(LONGDATE),
                UploadDataForm       : ({
                        uploadId,
                        managerGet
                }) => [
                        <div className="d-row j-equal">
                                <MyLittleForm key={`gift-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                                <MyLittleForm key={`gift-amount-${uploadId}`} item={managerGet('amount')}/>,
                        </div>,
                        <MyLittleForm key={`gift-donor-${uploadId}`} item={managerGet('donor')}/>
                ],
        }
        const identificationConfig = {
                template              : {
                        items: [
                                {
                                        name : 'documentDate',
                                        label: 'Expiry Date',
                                        type : 'date',
                                },
                                {
                                        name : 'dob',
                                        label: 'Date of Birth',
                                        type : 'date',
                                },
                                {
                                        name : 'fullName',
                                        label: 'Full Name',
                                        type : 'text',
                                }
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Expiry Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'DOB',
                                key      : 'dob',
                                dataIndex: 'dob',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Name',
                                key      : 'fullName',
                                dataIndex: 'fullName',
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => {
                                // set up the rows
                                let item = {
                                        key         : it.id,
                                        documentDate: valueOrNoData(it, 'documentDate'),
                                        dob         : valueOrNoData(it, 'dob'),
                                        fullName    : valueOrNoData(it, 'fullName'),
                                        upload      : it
                                }

                                // add errors to row
                                let errors = []
                                if (!dayjs.isDayjs(item.documentDate)) {
                                        errors.push(`Expiry Date missing`)
                                }
                                if (!dayjs.isDayjs(item.dob)) {
                                        errors.push(`DOB missing`)
                                }
                                if (typeof item.fullName !== 'string') {
                                        errors.push(`Full Name missing`)
                                }
                                return {
                                        ...item,
                                        errors
                                }
                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && row.documentDate.format(LONGDATE),
                getSectionErrors      : (rows, mortgage, minimums, section) => {
                        let errors = []
                        let additionalQuestions = []
                        if (rows.length) {
                                let sorted = rows.sort(sorterByDateName('documentDate'))

                                let noneStamped = !sorted.some(it => !!it.upload.stampedOn)
                                if (noneStamped) {
                                        errors.push(`Document not stamped`)
                                }
                                let last = sorted[sorted.length - 1]
                                let applicant = section.applicant === 1 ? mortgage.applicant1 : mortgage.applicant2

                                // check if the document is not going to expiry in the next six months
                                if (dayjs.isDayjs(last.documentDate)) {
                                        let testExpiryDate = dayjs().add(6, 'months')
                                        if (last.documentDate.isBefore(testExpiryDate)) {
                                                errors.push(`Document is going to expire in the next 6 months`)
                                        }
                                }

                                // check does the application DOB match the document DOB
                                if (dayjs.isDayjs(last.dob)) {
                                        if (last.dob.format("YYYY-MM-DD") !== applicant.personal.dateOfBirth) {
                                                additionalQuestions.push({
                                                        name  : 'dateOfBirth',
                                                        label : 'Date of Birth',
                                                        answer: {
                                                                type: 'date',
                                                        },
                                                        target: section.applicant === 1 ? `applicant1.personal.dateOfBirth` : `applicant2.personal.dateOfBirth`
                                                })
                                                errors.push(`DOB does not match`)
                                        }
                                }

                                // check names match
                                let type = typeof last.fullName
                                if (type === 'string') {
                                        if (applicant.fullName !== last.fullName) {
                                                errors.push(`Full Name does not match`)
                                                additionalQuestions.push([
                                                        {
                                                                name  : 'firstName',
                                                                text  : `Applicant ${section.applicant} First Name`,
                                                                label : 'FirstName',
                                                                answer: {
                                                                        type: 'text',
                                                                },
                                                                target: section.applicant === 1 ? `applicant1.firstName` : `applicant2.firstName`
                                                        },
                                                        {
                                                                name  : 'surname',
                                                                text  : `Applicant ${section.applicant} Surname`,
                                                                label : 'Surname',
                                                                answer: {
                                                                        type: 'text',
                                                                },
                                                                target: section.applicant === 1 ? `applicant1.surname` : `applicant2.surname`
                                                        }
                                                ])
                                        }
                                }

                        }
                        if (additionalQuestions.length) {
                                return {
                                        errors,
                                        additionalQuestions
                                }
                        }
                        return errors
                },
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`identification-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`identification-fullName-${uploadId}`} item={managerGet('fullName')}/>,
                        <MyLittleForm key={`identification-dob-${uploadId}`} item={managerGet('dob')}/>
                ],
                SectionApplicationForm: ({
                        section,
                        additionalQuestions
                }) => {
                        if (!additionalQuestions || !additionalQuestions.length) {
                                return null
                        }
                        return <MortgageForm questions={additionalQuestions}/>
                },
        }
        const proofOfDepositAvailableConfig = {
                template             : {
                        items: [
                                {
                                        name : 'endDate',
                                        label: 'End Date',
                                        type : 'date',
                                },
                                {
                                        name : 'closingBalance',
                                        label: 'Closing Balance',
                                        type : 'currency',
                                }
                        ]
                },
                sortDate             : 'endDate',
                uploadsSummaryColumns: [
                        {
                                title    : 'Date',
                                key      : 'endDate',
                                dataIndex: 'endDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Closing Balance',
                                key      : 'closingBalance',
                                dataIndex: 'closingBalance'
                        }
                ],
                getUploadSummaryRows : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key           : it.id,
                                endDate       : valueOrNoData(it, 'endDate'),
                                closingBalance: valueOrNoData(it, 'closingBalance'),
                                upload        : it
                        }))
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.endDate) && item.endDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getRowLabel          : (row) => !!row && !!row.endDate && row.endDate.format(LONGDATE),
                UploadDataForm       : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`pod-available-endDate-${uploadId}`} item={managerGet('endDate')}/>,
                        <MyLittleForm key={`pod-available-closingBalance-${uploadId}`} item={managerGet('closingBalance')}/>
                ],
        }
        const residencePermitConfig = {
                template             : {
                        items: [
                                // {
                                //     name : 'documentDate',
                                //     label: 'Document Date',
                                //     type : 'date',
                                // },
                                {
                                        name : 'expiryDate',
                                        label: 'Expiry Date',
                                        type : 'date',
                                }
                        ]
                },
                sortDate             : 'expiryDate',
                uploadsSummaryColumns: [
                        {
                                title    : 'Expiry Date',
                                key      : 'expiryDate',
                                dataIndex: 'expiryDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Errors',
                                key      : 'errors',
                                dataIndex: 'errors',
                                render   : (text) => <RenderErrors errors={text}/>
                        }
                        // {
                        //     title    : 'Document Date',
                        //     key      : 'documentDate',
                        //     dataIndex: 'documentDate',
                        //     render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        // }
                ],
                getUploadSummaryRows : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {

                        let rows = uploads.map(it => {
                                let errors = []
                                let obj = {
                                        key       : it.id,
                                        expiryDate: valueOrNoData(it, 'expiryDate'), // documentDate: valueOrNoData(it, 'documentDate'),
                                        upload    : it
                                }
                                if (!dayjs.isDayjs(obj.expiryDate)) {
                                        errors.push(`Expiry Date missing`)
                                }
                                return {
                                        ...obj,
                                        errors
                                }
                        })
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.expiryDate) && item.expiryDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getSectionErrors     : (rows, mortgage, minimums, section) => {
                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('expiryDate'))
                        let errors = []
                        let last = sorted[sorted.length - 1]
                        if (dayjs.isDayjs(last.expiryDate)) {
                                let betterEndDate = last.expiryDate.add(1, 'day')
                                let {maxAge} = minimums
                                let [m, ageUnit] = maxAge
                                // test the age of the most recent document
                                let age = dayjs().diff(betterEndDate, ageUnit, true)
                                if (age > m) {
                                        errors.push(`Permit will expire in less than 2 months - NOT IN APP - plz confirm if 2 months is fine`)
                                }
                        }
                        return errors
                },
                getRowLabel          : (row) => !!row && !!row.expiryDate && row.expiryDate.format(LONGDATE),
                UploadDataForm       : ({
                        uploadId,
                        managerGet
                }) => [
                        // <MyLittleForm key={`residencePermit-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`residencePermit-expiryDate-${uploadId}`} item={managerGet('expiryDate')}/>
                ],
        }
        const depositReceiptConfig = {
                template              : {
                        items: [
                                {
                                        name : 'documentDate',
                                        label: 'Document Date',
                                        type : 'date',
                                },
                                {
                                        name : 'amount',
                                        label: 'Amount',
                                        type : 'currency',
                                }
                        ]
                },
                sortDate              : 'documentDate',
                uploadsSummaryColumns : [
                        {
                                title    : 'Date',
                                key      : 'documentDate',
                                dataIndex: 'documentDate',
                                render   : (text) => dayjs.isDayjs(text) ? text.format(LONGDATE) : text
                        },
                        {
                                title    : 'Amount',
                                key      : 'amount',
                                dataIndex: 'amount'
                        }
                ],
                getUploadSummaryRows  : (uploads, {
                        mortgage,
                        section,
                        me
                }) => {
                        let rows = uploads.map(it => ({
                                key         : it.id,
                                documentDate: valueOrNoData(it, 'documentDate'),
                                amount      : valueOrNoData(it, 'amount'),
                                upload      : it
                        }))
                        let requiredFirst = getFirstRequiredDate(section, rows)
                        return rows.map((item, i) => {
                                let tooOld = requiredFirst ? dayjs.isDayjs(item.documentDate) && item.documentDate.isBefore(requiredFirst) : false
                                return {
                                        ...item,
                                        tooOld
                                }
                        })
                },
                getSectionErrors      : (rows, mortgage, minimums, section) => {

                        if (!rows.length) {
                                return []
                        }
                        let sorted = rows.sort(sorterByDateName('documentDate'))
                        let errors = []
                        let last = sorted[sorted.length - 1]
                        let applicationAmount = mortgage.properties[0].bookingDepositPaid
                        if (applicationAmount !== last.amount) {
                                errors.push(`Amount paid does not match application`)
                        }
                        return errors
                },
                getRowLabel           : (row) => !!row && !!row.documentDate && row.documentDate.format(LONGDATE),
                UploadDataForm        : ({
                        uploadId,
                        managerGet
                }) => [
                        <MyLittleForm key={`depositReceipt-documentDate-${uploadId}`} item={managerGet('documentDate')}/>,
                        <MyLittleForm key={`depositReceipt-amount-${uploadId}`} item={managerGet('amount')}/>
                ],
                SectionApplicationForm: ({
                        section,
                        mortgage
                }) => {
                        let propertyId = mortgage.properties[0].id
                        let questions = [
                                {
                                        label : "Booking Deposit",
                                        text  : "How much was the deposit you paid?",
                                        name  : "bookingDepositPaid",
                                        answer: {
                                                type: "euro",
                                        },
                                        model : "property",
                                        target: `properties[${propertyId}].bookingDepositPaid`
                                }
                        ]
                        return <MortgageForm questions={questions}/>
                },
        }
        return {
                investmentStatementConfig,
                cardStatementConfig,
                loanStatementConfig,
                mortgageStatementConfig,
                proofOfAddressConfig,
                accountStatementConfig,
                payslipConfig,
                edsConfig,
                salaryCertConfig,
                giftCertificateConfig,
                identificationConfig,
                proofOfDepositAvailableConfig,
                residencePermitConfig,
                depositReceiptConfig
        }
}

export class VerifiableSection {

        constructor(mortgage, section, me) {
                this.mortgage = mortgage
                this.section = section
                this.config = null
                this.me = me
                this.isVerifiable = true // reset in set setSectionConfig
                this.setSectionConfig()
                this.setUploads()
                if (this.isVerifiable) {
                        this.updateMortgageTimer = null
                        this.minimums = getMinimumsForSection(this.section)
                        this.additionalQuestions = []
                        this.setRows()
                        this.setErrors()
                }

        }
        uploadDataGetter = (upload) => { // the 'manager' in managerGet
                const createVerifiedRow = async (name, verifiedValue, verifiedValueType) => {
                        let result = await performGQL({
                                input: {
                                        uploadID         : upload.id,
                                        mortgageID       : this.mortgage.id,
                                        name,
                                        verifiedValue,
                                        verifiedValueType,
                                        verificationOn   : dayjs().toISOString(),
                                        verificationBy   : this.me.sub,
                                        supervisorCheckBy: 'null'
                                }
                        }, createAdminMortgageUploadVerifiedPoint)
                        return result
                }
                const updateVerifiedRow = async (row, pairs) => {
                        return await updateUploadVerifiedRow(this.mortgage, row, pairs)

                }
                const createMessage = async (verificationID, message) => {
                        let result = await performGQL({
                                input: {
                                        verificationID,
                                        authorID: this.me.sub,
                                        string  : message
                                }
                        }, createAdminMortgageSectionVerificationNote)
                        this.mortgage.setMortgage(prevMortgage => {
                                return {
                                        ...prevMortgage,
                                        uploads: prevMortgage.uploads.map(item => {
                                                if (item.id === upload.id) {
                                                        return {
                                                                ...item,
                                                                verifiedData: {
                                                                        ...item.verifiedData,
                                                                        items: item.verifiedData.items.map(it => {
                                                                                if (it.id === verificationID) {
                                                                                        return {
                                                                                                ...it,
                                                                                                verificationNotes: {
                                                                                                        ...it.verificationNotes,
                                                                                                        items: [
                                                                                                                ...it.verificationNotes.items,
                                                                                                                result
                                                                                                        ]
                                                                                                }
                                                                                        }
                                                                                }
                                                                                return it
                                                                        })
                                                                }
                                                        }
                                                }
                                                return item
                                        })
                                }
                        })
                }
                let customTemplate = this.config.hasOwnProperty('customTemplate') ? this.config.customTemplate(this) : null
                let customItems = customTemplate ? customTemplate.items : []

                return (name) => {
                        let item = [
                                ...this.config.template.items,
                                ...customItems
                        ].find(item => item.name === name)
                        let all = !!upload.verifiedData ? upload.verifiedData.items.filter(item => item.name === name) : []
                        let data = all.length ? all[0] : null

                        return (mortgage) => {
                                const mortgageUpload = data ? mortgage.uploads.find(u => u.id === upload.id) : null
                                const verifiedItems = mortgageUpload ? mortgageUpload.verifiedData.items : []
                                const mortgageRows = verifiedItems ? verifiedItems.filter(r => r.name === name) : []
                                const mortgageRow = mortgageRows.length ? mortgageRows[0] : null
                                if (mortgageRows.length > 1) {
                                        console.log('hase too many rows')
                                }
                                return {
                                        item,
                                        data,
                                        save         : async (value) => {

                                                let [verifiedValue, verifiedValueType] = getVerificationValueType(value, askInFormAnswerTypes[item.type])
                                                if (data) {
                                                        let result = await updateVerifiedRow(data, {
                                                                verifiedValue,
                                                                verifiedValueType,
                                                                verificationOn: dayjs().toISOString(),
                                                                verificationBy: this.me.sub,
                                                                _version      : mortgageRow._version
                                                        })
                                                        mortgage.setMortgage(prevMortgage => {
                                                                return {
                                                                        ...prevMortgage,
                                                                        uploads: prevMortgage.uploads.map(item => {
                                                                                if (item.id === result.uploadID) {
                                                                                        return {
                                                                                                ...item,
                                                                                                verifiedData: {
                                                                                                        ...item.verifiedData,
                                                                                                        items: item.verifiedData.items.map(it => {
                                                                                                                if (it.id === result.id) {
                                                                                                                        return result
                                                                                                                }
                                                                                                                return it
                                                                                                        })
                                                                                                }
                                                                                        }
                                                                                }
                                                                                return item
                                                                        })
                                                                }
                                                        })
                                                        data = result
                                                }
                                                else {
                                                        let result = await createVerifiedRow(name, verifiedValue, verifiedValueType)
                                                        mortgage.setMortgage(prevMortgage => {
                                                                return {
                                                                        ...prevMortgage,
                                                                        uploads: prevMortgage.uploads.map(item => {

                                                                                if (item.id === upload.id) {
                                                                                        let oldVerifiedData = item.verifiedData || {items: []}
                                                                                        return {
                                                                                                ...item,
                                                                                                verifiedData: {
                                                                                                        ...oldVerifiedData,
                                                                                                        items: [
                                                                                                                ...item.verifiedData?.items || [],
                                                                                                                result
                                                                                                        ]
                                                                                                }
                                                                                        }
                                                                                }
                                                                                return item
                                                                        })
                                                                }
                                                        })
                                                        data = result
                                                }
                                        },
                                        update       : async (pairs) => {
                                                await updateVerifiedRow(data, pairs)
                                        },
                                        createMessage: async (message) => {
                                                await createMessage(data.id, message)
                                        }
                                }
                        }
                }
        }
        getActiveRows = () => {
                return this.rows
        }
        setRows = () => {
                let config = {
                        mortgage: this.mortgage,
                        section : this.section,
                        me      : this.me
                }
                this.rows = this.config.getUploadSummaryRows(this.uploads.active, config).sort(sorterByDateName(this.config.sortDate))

        }
        setErrors = () => {
                if (!this.config.hasOwnProperty('getSectionErrors')) {
                        this.errors = []
                }
                else {
                        let errors = this.config.getSectionErrors(this.rows, this.mortgage, this.minimums, this.section)
                        if (errors.hasOwnProperty('additionalQuestions')) {
                                this.additionalQuestions = errors.additionalQuestions
                                this.errors = errors.errors
                        }
                        else {
                                this.errors = errors
                        }

                        // remove the Invalid Data error, as it's included already
                        let allAnswered = this.rows.every(it => !it.errors || it.errors.length === 0 || (it.errors.length === 1 && typeof it.errors[0] === 'string' && it.errors[0].startsWith('Missing statement')))
                        if (!allAnswered) {
                                this.errors.push(`Invalid data`)
                        }
                }
        }
        getErrors = () => {
                return this.errors
        }
        setSectionConfig() {
                const templates = getConfigurationTemplates()
                if (this.section.name.startsWith('assets') && !this.section.isInvestment) {
                        this.config = templates.accountStatementConfig
                }
                if (this.section.name.startsWith('assets') && this.section.isInvestment) {
                        this.config = templates.investmentStatementConfig
                }
                if (this.section.name.startsWith('debts') && this.section.isCredit) {
                        this.config = templates.cardStatementConfig
                }
                if (this.section.name.startsWith('properties')) {
                        this.config = templates.mortgageStatementConfig
                }
                if (this.section.name.startsWith('debts') && !this.section.isCredit) {
                        this.config = templates.loanStatementConfig
                }
                if (this.section.name.startsWith('proof-of-address')) {
                        this.config = templates.proofOfAddressConfig
                }
                if (this.section.name.startsWith('current-accounts')) {
                        this.config = templates.accountStatementConfig
                }
                if (this.section.name.startsWith('payslips')) {
                        this.config = templates.payslipConfig
                }
                if (this.section.name.startsWith('employment-summary')) {
                        this.config = templates.edsConfig
                }
                if (this.section.name.startsWith('gift-certificate')) {
                        this.config = templates.giftCertificateConfig
                }
                if (this.section.name.endsWith('gift-letter')) {
                        this.config = templates.giftCertificateConfig
                }
                if (this.section.name.startsWith('salary-cert')) {
                        this.config = templates.salaryCertConfig
                }
                if (this.section.name.startsWith('identification')) {
                        this.config = templates.identificationConfig
                }
                if (this.section.name.startsWith('proof-of-deposit')) {
                        this.config = templates.proofOfDepositAvailableConfig
                }
                if (this.section.name.startsWith('deposit-receipt')) {
                        this.config = templates.depositReceiptConfig
                }
                if (this.section.name.startsWith('residence-permit')) {
                        this.config = templates.residencePermitConfig
                }
                if (!this.config) {
                        this.isVerifiable = false
                }
        }
        setUploads() {
                const extendUpload = (upload) => {
                        let verified = new VerifiableUpload(upload, this)
                        let sortDate = verified.values[this.config.sortDate]
                        if (!sortDate && this.config.sortDate === 'fakeYearDate') {
                                sortDate = dayjs(verified.values.year, 'YYYY')
                        }
                        return ({
                                ...upload,
                                verified,
                                sortDate
                        })
                }
                if (this.isVerifiable) {
                        this.uploads = {
                                inactive: this.section.uploads.filter(it => !uploadExcludeFilter(it)).map(it => extendUpload(it)),
                                active  : this.section.uploads.filter(uploadExcludeFilter).map(it => extendUpload(it))
                        }

                }
                else {
                        this.uploads = {
                                inactive: this.section.uploads.filter(it => !uploadExcludeFilter(it)),
                                active  : this.section.uploads.filter(uploadExcludeFilter)
                        }
                }

        }
        refreshUploads = () => {

                this.setUploads()
                this.setRows()
                this.setErrors()

        }
        ApplicationQuestions = () => {
                if (!this.section.uploads.length || !this.config || !this.config.SectionApplicationForm) {
                        return null
                }
                return this.config.SectionApplicationForm({
                        additionalQuestions: this.additionalQuestions,
                        section            : this.section,
                        mortgage           : this.mortgage
                })
        }
        useUploadsSummary = () => {

                if (!this.uploads || !this.uploads.active.length || !this.config) {
                        return {
                                columns   : [],
                                dataSource: []
                        }
                }

                const columns = useMemo(() => {
                        let cols = this.config.uploadsSummaryColumns
                        let extraColumn = {
                                title    : 'Actions',
                                key      : 'actions',
                                align    : 'right',
                                dataIndex: 'actions',
                        }
                        let anyWithError = this.rows.some(it => it.errors && it.errors.length)
                        if (!anyWithError) {
                                // remove the errors column, if there are no errors
                                let errorIndex = cols.findIndex(it => it.key === 'errors')
                                if (errorIndex >= 0) {
                                        cols.splice(errorIndex, 1)
                                }
                        }
                        return [
                                ...cols,
                                extraColumn
                        ]

                }, [])
                const rowClassName = (record, index) => {
                        // Apply the 'shaded-row' class to every other row
                        if (record?.tooOld) {
                                return 'upload-row-expired'
                        }
                        if (record?.upload?.examined) {
                                return 'upload-row-examined'
                        }
                        return '';
                };
                let dataSource = this.rows
                useEffect(() => {
                        this.updateLastDocDate(this.rows)
                }, [this.rows]);
                return {
                        columns,
                        dataSource,
                        rowClassName
                }
                // return (<Table className="my-3" columns={cols} dataSource={dataSource} pagination={false}/>)

        }
        SummaryMessages2 = () => {
                console.log('SUMMARY MESSAGES!!!!')
                const {me} = useBackendUsers()
                return (<>
                        {!!this.errors && !!this.errors.length && (<div className="py-15 px-6 d-col gap-6">
                                {this.errors.map((it, i) => {
                                        if (typeof it === 'string' && it.includes('already in app')) {
                                                let message = (<div className="d-row j-between">
                                                        {it}
                                                        <ButtonAsync onClick={() => {
                                                                this.mortgage.mutate({
                                                                        update: {
                                                                                target: `progress[${this.section.record.id}]`,
                                                                                pairs : {
                                                                                        lastDocumentDateDismissed: !this.section.record.lastDocumentDateDismissed
                                                                                }
                                                                        }
                                                                })
                                                        }}>{this.section.record.lastDocumentDateDismissed ? 'Unblock Msg' : 'Block Msg'}</ButtonAsync>
                                                </div>)
                                                return (<Alert key={it} message={message} type={this.section.record.lastDocumentDateDismissed ? 'warning' : 'error'}/>)
                                        }

                                        if (typeof it === 'string' && it.startsWith('Missing ')) {
                                                let message = (<div className="d-row j-between">
                                                        {it}
                                                        <ButtonAsync onClick={() => {
                                                                this.mortgage.mutate({
                                                                        update: {
                                                                                target: `progress[${this.section.record.id}]`,
                                                                                pairs : {
                                                                                        alertTitle    : `We need another ${this.section.title}`,
                                                                                        alertBody     : [it],
                                                                                        alertDismissed: false,
                                                                                        alertAuthor   : me.sub,
                                                                                }
                                                                        }
                                                                })
                                                        }}>Create Request Another Upload</ButtonAsync>
                                                </div>)
                                                return (<Alert key={it} message={message} type={"error"}/>)
                                        }
                                        return (<Alert key={it} message={it} type="error"/>)
                                })}
                        </div>)}

                </>)
        }
        InternalNote = () => {
                return (<div className="d-row"
                             style={{borderBottom: '1px solid lightgray'}}>
                        <div style={{
                                fontWeight: 600,
                                width     : '170px'
                        }} className="p-15 shrink-not">
                                Internal Note:
                        </div>
                        <div className="p-15 grow">
                                <InternalNote section={this.section}/>
                        </div>
                </div>)
        }
        updateLastDocDate = async (rows) => {
                clearTimeout(this.updateMortgageTimer)
                this.updateMortgageTimer = setTimeout(async () => {
                        let last = rows[rows.length - 1]
                        let date = last.hasOwnProperty('endDate') ? last.endDate : last[this.config.sortDate]
                        if (dayjs.isDayjs(date) && date.isValid()) {
                                let val = this.section.record.lastDocumentDate
                                if (val !== date.format('YYYY-MM-DD')) {
                                        let update = {
                                                target: `progress[${this.section.record.id}]`,
                                                pairs : {
                                                        lastDocumentDate: date.format('YYYY-MM-DD')
                                                }
                                        }
                                        await this.mortgage.mutate({update})
                                }
                        }
                }, 100)
        }

        handleScan = async (data) => {
                if (this.config.hasOwnProperty('saveScanDataToSection')) {
                        await this.config.saveScanDataToSection(this.mortgage, this.section, data)
                }
        }
}

