import React, {useEffect, useState} from 'react';
import useBackendUsers from "../../providers/mortgage/useBackendUsers";
import {Form} from "antd";
import {getMyTimeSlots} from "../../api-functions/timeslots/getMyTimeSlots";

export const createDefaultTimeSlots = (startDate,endDate)=>{
    const times = [
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:30'
    ]
    // create array of DateObjects for each time between 00:00 on start date and 23:59 on end date

    let startTime = new Date(startDate)
    startTime.setHours(0,0,0,0)
    let endTime = new Date(endDate)
    endTime.setHours(23,59,59, 999)

    const timeSlots = [];
    while (startTime < endTime){
        times.forEach(time=>{
            const [hours, minutes] = time.split(':').map(Number);
            startTime.setHours(hours, minutes, 0, 0);
            timeSlots.push(new Date(startTime))
        })
        let d = startTime.getDate()
        startTime.setDate( startTime.getDate() + 1)
        startTime.setHours(0,0,0,0)
    }

    return timeSlots;

}

function MyTimeslots(props) {
    const backendUsers = useBackendUsers()
    const [state,setState] = useState({
        timeSlots: []
    })
    const setTimeSlots = (timeSlots)=>{
        setState( s=>({
            ...state,
            timeSlots: [...s.timeSlots, ...timeSlots]
        }))
    }
    useEffect(() => {
        if (backendUsers.me){
            getMyTimeSlots(backendUsers.me.id, setTimeSlots)
        }
    }, [backendUsers.me]);





    createDefaultTimeSlots('2024-03-27T15:00:00.000Z', '2024-03-28T17:00:00.000Z')
    return (
        <Form>

        </Form>
    );
}

export default MyTimeslots;