import React, {useContext} from 'react';
import {Button, Card, Col, Form, InputNumber, Row} from "antd";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {CheckCircleFilled, FundProjectionScreenOutlined, LinkOutlined} from "@ant-design/icons";
import {green} from "@ant-design/colors";
import {UpcomingAppointments} from "../../../../../providers/UpcomingAppointmentProvider";
import dayjs from "dayjs";
import MortgageForm from "../../../../data-capture/MortgageForm";
import {firstHomeSchemeDetailsQuestions, helpToBuySchemeDetailsQuestions} from "../funding/Funding";
import {newlyBuilt, propertyType, propertyValue} from "../../../application/forms/sections/MortgageProperties";
import BalanceOfFunds from "../funding/BalanceOfFunds";
import {ddBankAccountID, loanRequired, loanTerm} from "../../../application/forms/sections/MortgageSetup";
import {depositSavings} from "../../../application/forms/sections/MortgageDeposit";
import QueryItems from "../queries/QueryItems";
import Lender from "../../apply/parts/Lender";
import EmploymentHistory from "./employment/EmploymentHistory";
import PayslipHistory from "./employment/PayslipHistory";
import Residence from "./foreigners/Residence";
import {ApplicationTypes, MortgageTypes} from "../../../../../../models";
import useApplicationUploads from "../../../../../providers/mortgage/useApplicationUploads";
import {verifiedAccounts} from "../funding/verifiedAccounts";
import {currencyFormatter, currencyParser} from "../../../../../../assets/scripts/parser-functions";

function HaveAChat(props) {
    const formProps={
        labelCol:{span:12},
        wrapperCol:{span:12}
    }
    const mortgage = useMortgage()
    const appointments = useContext(UpcomingAppointments)
    function ChatCompleteButton() {
        const completeChat = async () => {
            await mortgage.mutate(
                {
                    update: {
                        target: 'completedFlowItems',
                        value: JSON.stringify({
                            ...mortgage.completedFlowItems,
                            ApplyChatComplete: new Date().toISOString()
                        })
                    }
                }
            )
        }
        return (
            <Button onClick={completeChat}>Mark Chat
                Complete {!!mortgage.completedFlowItems.hasOwnProperty('ApplyChatComplete') &&
                    <CheckCircleFilled style={{color: green.primary}}/>} </Button>
        )
    }

    function Header() {
        const appointment = appointments.find(a => a.mortgageID === mortgage.id)
        let bookedString = 'No appointments booked'

        if (!!appointment) {
            let djs = dayjs(appointment.appointmentDate)
            let dateStr = djs.format('D MMMM h:mm a')
            if (djs.isToday()) {
                dateStr = 'Today ' + djs.format('h:mm a')
            }
            if (djs.isTomorrow()) {
                dateStr = 'Tomorrow ' + djs.format('h:mm a')
            }
            bookedString = `Appointment booked for ${dateStr}`
        }
        let link = (window.location.protocol === 'http:') ? `/user-app/${mortgage.id}` : `https://admin.sussd.ie/user-app/${mortgage.id}`
        return (
            <Card title="Chat Appointment">
                <div className="d-row j-between">
                    <div className="d-col">

                        <ChatCompleteButton/>
                        {bookedString}
                    </div>
                    <a href={link} target="myTab">
                        <Button>User App <FundProjectionScreenOutlined/></Button>
                    </a>
                </div>
            </Card>)
    }

    function MortgageOptions() {
        const questions = [
            ddBankAccountID({mortgage})
        ]

        return (
            <>
                <Row>
                    <Col lg={8}>
                        <p>Items to cover</p>
                        <ul className={"list-disc list-inside"}>
                            <li>One</li>
                            <li>Two</li>
                            <li>Three</li>
                        </ul>
                    </Col>
                    <Col lg={16}>
                        <MortgageForm questions={questions} formProps={formProps} />
                    </Col>
                </Row>

            </>
        )
    }

    function SchemesForm() {
        const qsProps = {mortgage, index: mortgage.properties[0].id}
        const qs = [
            propertyType(qsProps),
            newlyBuilt(qsProps),
            propertyValue(qsProps)
        ]
        const questions = [...qs,...helpToBuySchemeDetailsQuestions, ...firstHomeSchemeDetailsQuestions,]

        return (
            <>
                <Row>
                    <Col lg={12}>
                        <p>Items to cover</p>
                        <ul className={"list-disc list-inside"}>
                            <li>If buying a second-hand apartment, expect at lease 3 month closing time frame</li>

                        </ul>
                    </Col>
                    <Col lg={12}>
                        <MortgageForm questions={questions} formProps={formProps} />
                    </Col>
                </Row>

            </>
        )
    }

    function NextSteps(){
        return (
            <>
                <Row>
                    <Col lg={12}>
                        {/*<p>Items to cover</p>*/}
                        {/*<ul className={"list-disc list-inside"}>*/}
                        {/*    <li>One</li>*/}
                        {/*    <li>Two</li>*/}
                        {/*    <li>Three</li>*/}
                        {/*</ul>*/}
                    </Col>
                    <Col lg={12}>

                    </Col>
                </Row>

            </>
        )
    }

    function MortgageQueries(){
        const queries = mortgage ? mortgage.queries.filter(it => ['SETUP','APPLY'].includes(it.stage) ) : []
        return (
            <QueryItems queries={queries}/>
        )
    }
    function Funding(){
        const questions = [
            loanTerm({mortgage}),
            loanRequired({mortgage}),
            {...depositSavings({mortgage}), label: 'Claimed Savings'}
        ]
        const loan = mortgage.requirement.loanRequired
        let income = mortgage.figures.verified.basic1.total
        if (mortgage.twoApplicants){
            income += mortgage.figures.verified.basic2.total
        }
        let multiplier = 3.5
        if (mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME) {
            multiplier = 4
        }
        let lti = Math.round(loan/income * 100) / 100
        let ltv = Math.round(loan/mortgage.properties[0].propertyValue * 1000) / 10

        const {apply: sections} = useApplicationUploads()
        let accountByCurrency = verifiedAccounts(mortgage, sections)

        let euroAccounts = accountByCurrency['€'] || []
        let available = euroAccounts.totalClosingBalance
        return <Row>
            <Col lg={12}>
                <BalanceOfFunds contentOnly={true}/>
                <Form {...formProps} className="mt-15">
                    <Form.Item label={"Cash Available"}>
                        <InputNumber className="right-aligned-input" prefix={'€'} disabled value={available}  formatter={currencyFormatter}/>
                    </Form.Item>
                </Form>
            </Col>
            <Col lg={12}>
                <MortgageForm questions={questions} formProps={formProps}/>
                <Form {...formProps}>
                    <Form.Item label={"LTV"}>
                        <InputNumber disabled value={ltv}/>
                    </Form.Item>
                    <Form.Item label={"LTI"}>
                        <InputNumber disabled value={lti}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    }
    return (
        <div className="d-col gap-6">
            <Header/>

            <Card title="EMPLOYMENT">
                <div className="d-row j-equal" style={{gap: 40}}>
                <EmploymentHistory applicant={1}/>
                    {mortgage.twoApplicants && <EmploymentHistory applicant={2}/>}
                </div>
                <PayslipHistory applicant={1}/>
                {mortgage.twoApplicants && <PayslipHistory applicant={2}/>}
                <Row>
                    <Col lg={12}>
                        <p>Items to cover</p>
                        <ul className={"list-disc list-inside"}>
                            <li>Anyone on maternity leave / expecting?</li>
                            <li>Anyone still in probation period?</li>
                        </ul>
                    </Col>
                    <Col lg={12}>
                    </Col>
                </Row>
            </Card>
            <Card title="SCHEMES & PROPERTY">
                <SchemesForm/>
            </Card>
            <Card title="FOREIGN RESIDENTS">
                <div className="d-row j-equal">
                    <Residence applicant={1}/>
                    {mortgage.twoApplicants && <Residence applicant={2}/>}
                </div>
                <div>
                    <p>Items to cover</p>
                    <ul className={"list-disc list-inside"}>
                        <li>Foreign Accounts</li>
                        <li>Foreign Properties</li>
                        <li>Foreign Income</li>
                    </ul>
                </div>
            </Card>

            <Card title="FUNDING">
                <Funding/>
                <a href={`/user-funding/${mortgage.id}`} target="myTab" >
                    <Button className="mt-15">Statement Analysis <FundProjectionScreenOutlined/></Button>
                </a>
            </Card>
            <Card title="MORTGAGE OPTIONS">
                <MortgageOptions/>
            </Card>
            <Card title="QUERIES" stlyes={{body: {padding: 0}}}>
                <MortgageQueries/>
            </Card>
            <Card title="LENDER">
                <Lender />
            </Card>
            <Card title="NEXT STEPS">
                <NextSteps/>
            </Card>

        </div>
    );
}

export default HaveAChat;
