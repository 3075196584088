import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Checkbox, Dropdown, Form, Input, List, Space} from "antd";
import {DownOutlined, SaveOutlined, SettingOutlined, SmileOutlined} from "@ant-design/icons";
import useToDos from "./useToDos";

function ItemToDo(props) {
    const {toDos, setToDos, saveToDo, updateTick, updateItemText, deleteToDo} = useToDos(props)
    const [form] = Form.useForm()
    const handleToggle = (todoId, _version, bool) => {
        updateTick(todoId,_version, bool)
    };
    const handleAddToDo = ({todo}) => {
        saveToDo(todo)
        form.resetFields()
    }
    const handleDeleteToDo = (todoId, _version) => {
        if (todoId === 'temp') return setToDos([])
        deleteToDo(todoId, _version)
    }
    const handleCreateToDoList = () => {
        setToDos([
            {id: 'temp', toDoItem: 'Create To-Do ...', completed: false},
        ])
    }

    function RenderedItem({todo}){
        const [editing, setEditing] = useState(false)
        const items = [
            {
                key: 'edit',
                label: 'Edit',
                onClick:() => setEditing(true)
            },
            {
                key: 'delete',
                danger: true,
                label: 'Delete',
                onClick:() => handleDeleteToDo(todo.id, todo._version)
            }
        ];
        const handleChangeToDoText = ({todoText}) => {
            updateItemText(todo.id, todo._version, todoText)
            setEditing(false)
        }
        const actions = [
            <Dropdown
                menu={{
                    items,
                }}
            >

                <SettingOutlined />

            </Dropdown>,
            <Checkbox
                key="checkbox"
                checked={!!todo.completedOn}
                onChange={(e) => handleToggle(todo.id, todo._version, e.target.checked)}
            />,
        ]
        return (<List.Item
            key={`ri-${todo.id}`}
                actions={editing ? null : actions}
            style={{display:'flex',textDecoration: !!todo.completedOn ? 'line-through' : 'none'}}
        >
            {!editing && todo.toDoItem}
                {editing && (
                    <Form
                        initialValues={{todoText: todo.toDoItem}}
                        onFinish={handleChangeToDoText}>
                        <Space.Compact>
                            <Form.Item name="todoText" noStyle>
                                <Input style={{width:'100%'}}  />
                            </Form.Item>
                            <Button htmlType="submit"><SaveOutlined/></Button>
                        </Space.Compact>
                    </Form>
                )}
        </List.Item>
        )
    }
    if (!toDos.length) {
        return <Button onClick={handleCreateToDoList}>Create a todo list</Button>
    }
    return (
        <>

            <List
                bordered
                dataSource={toDos}
                renderItem={todo => <RenderedItem key={`rendered-item-${todo.id}`} todo={todo}/>}
            >
                <List.Item className="p-0">
                    <Form
                        style={{width:'100%'}}
                        form={form}
                    onFinish={handleAddToDo}>
                    <Space.Compact style={{display:'flex', marginRight: -1, marginLeft: -1, marginBottom: -1}}>
                        <Form.Item name="todo" noStyle>
                            <Input size="large" rootClassName="br-tl-0" placeholder="A new to-do item ..." />
                        </Form.Item>
                        <Button size="large" htmlType="submit" className="br-tr-0">+</Button>
                    </Space.Compact>
                    </Form>
                </List.Item>
            </List>
        </>
    );
}

export default ItemToDo;