import dayjs from "dayjs";
import {monthsBetweenDates} from "../../app/components/client/application-stages/_common/funding/Funding";
import {capitaliseWords} from "./string-functions";
import {
    Badge, Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Table
} from "antd";
import React, {useRef, useState} from "react";
import {currencyFormatter, currencyParser} from "./parser-functions";
import {CaretDownOutlined, ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {Frequencies, UploadStatuses} from "../../models";
import {grey, orange, red} from "@ant-design/colors";
import useBackendUsers from "../../app/providers/mortgage/useBackendUsers";
import {LONGDATE} from "./constants";

export function getClientValue(value, type) {
    switch (type) {
        case 'BOOLEAN':
            return value === 'true'
        case 'NUMBER':
            return parseFloat(value)
        case 'DATE':
            return dayjs(value, 'YYYY-MM-DD')
        default:
            return value
    }
}
export const askInForm = {
    date  : (name, label, loading) => {
        return (<Form.Item
                key={name}
                label={label}
                name={name}
                className="mb-0">
                <DatePicker allowClear={false} style={{width: '100%'}} format={"D MMM YYYY"} id={name}/>
            </Form.Item>

        )
    },
    int   : (name, label, loading) => {
        return (<Form.Item
                key={name}
                label={label}
                name={name}
                className="mb-0">
                <InputNumber className="w-100"/>
            </Form.Item>

        )
    },
    text  : (name, label, loading) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <Input className="w-100"/>
        </Form.Item>)
    },
    euro  : (name, label, loading) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <InputNumber
                className="w-100"
                parser={currencyParser}
                formatter={currencyFormatter}
            />
        </Form.Item>)
    },
    select: (name, label, options, loading) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0">
            <Select className="w-100" style={{minWidth: 200}}
                    suffixIcon={loading ? <LoadingOutlined/> : <CaretDownOutlined/>} options={options}/>
        </Form.Item>)
    },
    check : (name, label, loading) => {
        return (<Form.Item
            key={name}
            label={label}
            name={name}
            className="mb-0" valuePropName="checked">
            <Checkbox/>
        </Form.Item>)
    }
}
export const currentYear = new Date().getFullYear();
export const lastYear1 = currentYear - 1;
export const lastYear2 = currentYear - 2;
export const lastYear3 = currentYear - 3;
function MyLittleForm({
    figuresStore,
    progressID,
    name,
    render,
})
{
    const [form] = Form.useForm()
    let [saving, setSaving] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)
    let row = figuresStore.getNameBySection(name, progressID)
    const initialValues = {[name]: !!row ? getClientValue(row.verifiedValue, row.verifiedValueType) : null}
    let timer = useRef(null)
    const handleItemBlur = async (name, value, setSaving) => {

        if (!!row && initialValues[name] === value) {
            console.log('not changed', value)
            return
        }
        setSaving(true)
        await figuresStore.save(progressID, name, value)
        setSaving(false)

    }
    const handleFormBlur = async (e) => {
        clearTimeout(timer.current)
        timer.current = setTimeout(async () => {

            let nameLocal = e.target.id

            if (!!nameLocal) {
                setSaving(true)
                let value = await form.getFieldValue(nameLocal)
                handleItemBlur(nameLocal, value, setSaving)
            }

        }, 100)

    }
    const handleSettingsClick = async () => {
        if (!row) {
            await handleItemBlur(name, null, setSaving)
        }
        setInfoOpen(true)
    }
    const addMessage = async (text) => {
        await figuresStore.createMessage(row, text)

    }
    const setSupervisorCheck = (bool) => {
        figuresStore.update(row, {supervisorCheckRequired: bool ? 1 : 0})

    }

    const children = render(saving)

    let exlamColor = row && row.supervisorCheckRequired ? red[5] : row && row.verificationNotes.items.length ? orange[5] : '#d9d9d9'

    return <>

        <Form labelCol={{span: 12}} form={form} onBlur={handleFormBlur} initialValues={initialValues}>
            <div className="d-row gap-3">
                <div className="grow">
                    {children}
                </div>

                <div>
                    {saving ? (<LoadingOutlined/>) : (<ExclamationCircleOutlined style={{
                        cursor: 'pointer',
                        color : exlamColor
                    }} onClick={handleSettingsClick}/>)}
                </div>

            </div>

        </Form>
        {!!infoOpen && !!row && (
            <FormInModal handleCancel={() => setInfoOpen(false)} messages={row.verificationNotes.items}
                         addMessage={addMessage} isSupervisorChecked={row.supervisorCheckRequired}
                         setSupervisorCheck={setSupervisorCheck}/>)}
    </>

}
const FormInModal = ({
    handleCancel,
    messages = [],
    addMessage,
    setSupervisorCheck,
    isSupervisorChecked
}) => {
    const [form1, form2] = Form.useForm();
    const backendUsers = useBackendUsers()
    const messageFormRef = useRef(null)
    const handleMessageFinish = async (values, come) => {
        console.log({
            form2,
            come,
            messageFormRef
        })
        messageFormRef.current.resetFields()
        await addMessage(values.textMessage);
    };
    const handleSupervisorNeededFinish = (item) => {
        setSupervisorCheck(item.supervisorCheckRequired)
    }
    const handlePressEnter = (e) => {
        e.preventDefault();
        messageFormRef.current.submit();
    };

    function TextMessage({message}) {
        let mine = message.authorID === backendUsers.me.sub

        function MyCard() {
            return (<Card
                style={{
                    backgroundColor: mine ? '#f1f1f1' : '#f3f8fd',
                }}
                className="ms-auto my-3" styles={{body: {padding: 6}}}>
                {message.string}
            </Card>)
        }
        if (mine) {
            return <MyCard/>
        }
        const user = backendUsers.getBySub(message.authorID)

        return (<Badge offset={[
            15,
            4
        ]} color={user?.color || grey[3]} count={user?.firstName || 'Huh?'} size={"small"}>
            <MyCard/>
        </Badge>)
    }
    const sortMessagesByDate = (messages) => {
        const sortedMessages = {};

        messages.forEach(message => {
            const date = dayjs(message.timestamp).format(LONGDATE);
            if (!sortedMessages[date]) {
                sortedMessages[date] = [];
            }
            sortedMessages[date].push(message);
        });

        Object.keys(sortedMessages).forEach(date => {
            sortedMessages[date].sort((a, b) => dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? 1 : -1);
        });

        return sortedMessages;
    };
    let sortedMessages = sortMessagesByDate(messages)

    return (<>
        <Modal
            open={true}
            onCancel={handleCancel}
            footer={null}
        >
            <Form form={form1} onValuesChange={handleSupervisorNeededFinish}>
                <Form.Item
                    name="supervisorCheckRequired" valuePropName="checked" initialValue={isSupervisorChecked}
                >
                    <Checkbox>Supervisor Needed</Checkbox>
                </Form.Item>
            </Form>
            <div className="d-col gap-3">

                {Object.keys(sortedMessages).map(date => {
                    return (<>
                        <div key={date} className="d-row j-center a-center gap-3 mb-15" style={{
                            fontSize: 8,
                            color   : grey[3]
                        }}>
                            ---- <strong>{date}</strong> ----
                        </div>
                        {sortedMessages[date].map(message => {
                            return (<div className={`d-row`}>
                                <TextMessage key={message.id} message={message}/>
                            </div>)
                        })}

                    </>)
                })}


            </div>
            <Form ref={messageFormRef} form={form2} onFinish={handleMessageFinish}>
                <div className="d-row p-3" style={{
                    border      : '1px dashed #ccc',
                    borderRadius: 5
                }}>
                    <Form.Item noStyle name="textMessage">
                        <Input.TextArea onPressEnter={handlePressEnter} placeholder="Leave a message ..." style={{
                            outline        : 'none',
                            border         : 'none',
                            boxShadow      : 'none',
                            padding        : 5,
                            margin         : 0,
                            backgroundColor: 'transparent'
                        }}/>

                    </Form.Item>
                    <div className="d-col">
                        <Button htmlType="submit" className="mt-auto">Send</Button>
                    </div>
                </div>
            </Form>
        </Modal>
    </>);
};
export function verifiedFigures(sections, forms = false, verifiedFiguresStore = false) {

    const figuresResult = (section, field, type = "euro") => {

        //fa99b942-c71e-414e-9175-c556870d77f3 - no uploads salary cert -1
        //
        if (!section) {
            return {
                error: `Section not started`,
                value: 0
            }
        }
        if (!section.record) {
            return {
                error: `Section not started`,
                value: 0
            }
        }
        if (section.record.alertBody?.length) {
            return {
                error: `Waiting for outstanding uploads`,
                value: 0
            }
        }
        if (!section.uploads.length) {
            return {
                error: `No uploads`,
                value: 0
            }
        }
        if (section.uploads.some(it => [
            UploadStatuses.REVIEWING,
            UploadStatuses.PENDING
        ].includes(it.status)))
        {
            return {
                error: `Uploads still pending`,
                value: 0
            }
        }

        let data = verifiedFiguresStore.getNameBySection(field, section.record.id)

        if (!data) {
            return {
                error: `Verified data not entered`,
                value: 0
            }
        }

        if (!section.record.sectionCheckedBy || section.record.sectionCheckedBy === "null") {
            return {
                warning: `${section.title} has not been finalised`,
                value  : getClientValue(data.verifiedValue, data.verifiedValueType)
            }
        }

        return {
            value: getClientValue(data.verifiedValue, data.verifiedValueType),
            type
        }
    }

    const dataForms = {
        eds            : (section) => {
            let data0 = data.eds(section)
            const root = "grossBasic"
            let currentYear = (new Date()).getFullYear() - 1
            const yearOptions = [];

            for (let i = 0; i < 3; i++) {
                const year = currentYear - i;
                yearOptions.push({
                    year,
                    label: `${year}`,
                    name : `${root}${year}`
                });
            }
            return [
                {
                    label     : 'Enter gross basic pay',
                    progressID: section.record.id,
                    render    : () => (<div className="d-col gap-3">
                        {Object.keys(yearOptions).map(yO => <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data0.grossBasic(yearOptions[yO].year).name}
                            render={data0.grossBasic(yearOptions[yO].year).render}
                        />)}
                    </div>)
                },
                {
                    label     : 'Enter previous employer name',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data0.previousEmployerName().name}
                        render={data0.previousEmployerName().render}
                    />
                },
                {
                    label     : 'Enter previous employment dates',
                    progressID: section.record.id,
                    render    : () => (<div className="d-row gap-3 j-equal">
                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data0.previousEmploymentStartDate().name}
                            render={data0.previousEmploymentStartDate().render}
                        />
                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data0.previousEmploymentEndDate().name}
                            render={data0.previousEmploymentEndDate().render}
                        />
                    </div>)
                },
                {
                    label     : 'Enter current employer name',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data0.currentEmployerName().name}
                        render={data0.currentEmployerName().render}
                    />
                },
                {
                    label     : 'Enter current employment start date',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data0.currentEmploymentStartDate().name}
                        render={data0.currentEmploymentStartDate().render}
                    />
                },
                {
                    label     : 'Enter PPS Number',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data0.ppsNumber().name}
                        render={data0.ppsNumber().render}
                    />
                }
            ]
            // return {
            //     grossBasicByYear       : () => {
            //         const root = "grossBasic"
            //         let currentYear = (new Date()).getFullYear() - 1
            //         const yearOptions = [];
            //         for (let i = 0; i < 3; i++) {
            //             const year = currentYear - i;
            //             yearOptions.push({
            //                 label: `${year}`,
            //                 name : `${root}${year}`
            //             });
            //         }
            //         return {
            //             label     : "Enter gross basic pay",
            //             progressID: section.record.id,
            //             itemsNames: yearOptions.map(year => year.name),
            //             items     : yearOptions.map(year => {
            //                 return {
            //                     name  : year.name,
            //                     value : figuresResult(section, year.name, "euro"),
            //                     render: (loading) => askInForm.euro(year.name, year.label, loading)
            //                 }
            //             })
            //         }
            //     },
            //     previousEmploymentDates: () => {
            //         return {
            //             label     : "Enter previous employment dates",
            //             progressID: section.record.id,
            //             across    : true,
            //             items     : [
            //                 {
            //                     name  : "previousEmploymentStartDate",
            //                     value : figuresResult(section, 'previousEmploymentStartDate', "date"),
            //                     render: (loading) => askInForm.date('previousEmploymentStartDate', ' Start Date', loading),
            //                 },
            //                 {
            //                     name  : "previousEmploymentEndDate",
            //                     value : figuresResult(section, 'previousEmploymentEndDate', "date"),
            //                     render: (loading) => askInForm.date('previousEmploymentEndDate', 'End Date', loading)
            //                 }
            //             ]
            //         }
            //     },
            //     currentEmploymentDates : () => {
            //         if (forms) {
            //             return {
            //                 label     : "Enter current employment start date",
            //                 progressID: section.record.id,
            //                 across    : true,
            //                 items     : [
            //                     {
            //                         name  : "currentEmploymentStartDate",
            //                         value : figuresResult(section, 'currentEmploymentStartDate', "date"),
            //                         render: (loading) => askInForm.date('currentEmploymentStartDate', 'Start Date', loading)
            //                     }
            //
            //                 ]
            //             }
            //         }
            //     },
            //     ppsNumber              : () => {
            //         return {
            //             label     : "Enter PPS Number",
            //             progressID: section.record.id,
            //             items     : [
            //                 {
            //                     value : figuresResult(section, 'ppsNumber', "string"),
            //                     name  : "ppsNumber",
            //                     render: (loading) => askInForm.text('ppsNumber', 'PPS Number', loading)
            //                 }
            //             ]
            //         }
            //     },
            //     currentEmployerName    : () => {
            //         return {
            //             label     : "Enter current employer name",
            //             progressID: section.record.id,
            //             items     : [
            //                 {
            //                     name  : "currentEmployerName",
            //                     value : figuresResult(section, 'currentEmployerName', "string"),
            //                     render: (loading) => askInForm.text('currentEmployerName', 'Current Employer Name', loading)
            //                 }
            //             ]
            //         }
            //     }
            // }
        },
        payslips       : (section) => {
            let data1 = data.payslips(section)
            let freq = data1.payFrequency().value

            let returnSections = [
                {
                    label     : 'Enter pay frequency ',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name="payFrequency"
                        render={data1.payFrequency().render}
                    />
                },
                {
                    label     : 'Enter payslip details',
                    progressID: section.record.id,
                    error     : freq.error ? 'Pay frequency not selected' : null,
                }
            ]
            // create a table for the payslips
            if (!freq.error) {
                let needed = 3
                let enter = 3
                let title = 'Month'
                if (freq.value === "FORTNIGHTLY") {
                    title = 'Fortnight'
                    needed = 7
                    enter = 2
                }
                else if (freq.value === "WEEKLY") {
                    title = 'Week'
                    needed = 13
                    enter = 2
                }
                let dataSource = [
                    'payslipDate',
                    'grossPay',
                    'netPay',
                    'pensionDeduction',
                    'avc'
                ].map(item => {
                    let retObj = {}
                    let description
                    Array(enter).fill(0).forEach((_, i) => {
                        retObj[`payslip.${i + 1}`] = data1[item](i + 1)
                        description = retObj[`payslip.${i + 1}`].label
                    })
                    return {
                        key: item,
                        description, ...retObj
                    }
                })
                let columns = [
                    {
                        title    : '',
                        dataIndex: `description`,
                        key      : `description`,
                    }
                ]

                let columns1 = Array(enter).fill(0).map((_, i) => {
                    let myTitle = ``
                    if (i === 0) {
                        myTitle = "Oldest"
                    }
                    else if (i === enter - 1) {
                        myTitle = "Newest"
                    }
                    let k = `payslip.${i + 1}`
                    return {
                        title : myTitle,
                        key   : k,
                        align : 'center',
                        render: (value) => {
                            console.log({value})
                            return <MyLittleForm
                                figuresStore={verifiedFiguresStore}
                                progressID={section.record.id}
                                name={value[k].name}
                                render={value[k].render}
                            />
                        }
                    }
                })
                columns.push(...columns1)
                returnSections[1].render = () => <Table size={"small"} key={`table${columns.length}`}
                                                        dataSource={dataSource}
                                                        columns={columns} pagination={false}/>
            }

            return returnSections
        },
        salaryCert     : (section) => {
            let data2 = data.salaryCert(section)
            return [
                {
                    label     : 'Enter certificate date',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data2.certDate().name}
                        render={data2.certDate().render}/>
                },
                {
                    label     : 'Enter sector',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data2.sector().name}
                        render={data2.sector().render}
                    />
                },
                {
                    label     : 'Enter annual gross basic',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data2.annualGrossBasic().name}
                        render={data2.annualGrossBasic().render}
                    />
                },

                {
                    label     : 'Enter bonus for past 3 years',
                    progressID: section.record.id,
                    render    : () => {
                        return (<div className="d-col gap-3 w-100">
                            <Row gutter={15}>
                                <Col span={12}>
                                    <MyLittleForm
                                        figuresStore={verifiedFiguresStore}
                                        progressID={section.record.id}
                                        name={data2[`${lastYear1}bonus`]().name}
                                        render={data2[`${lastYear1}bonus`]().render}/>
                                </Col>
                                <Col span={8}>
                                    <MyLittleForm
                                        figuresStore={verifiedFiguresStore}
                                        progressID={section.record.id}
                                        name={data2[`${lastYear1}bonusGuaranteed`]().name}
                                        render={data2[`${lastYear1}bonusGuaranteed`]().render}/>
                                </Col>
                            </Row>
                            <Row gutter={15}>
                                <Col span={12}>

                                    <MyLittleForm
                                        figuresStore={verifiedFiguresStore}
                                        progressID={section.record.id}
                                        name={data2[`${lastYear2}bonus`]().name}
                                        render={data2[`${lastYear2}bonus`]().render}/>

                                </Col>
                                <Col span={12}></Col>
                            </Row>
                            <Row gutter={15}>
                                <Col span={12}>

                                    <MyLittleForm
                                        figuresStore={verifiedFiguresStore}
                                        progressID={section.record.id}
                                        name={data2[`${lastYear3}bonus`]().name}
                                        render={data2[`${lastYear3}bonus`]().render}/>

                                </Col>
                                <Col span={12}></Col>
                            </Row>
                        </div>)
                    }
                },
                {
                    label     : 'Enter variable income',
                    progressID: section.record.id,
                    render    : () => (<div className="d-col gap-3 w-100">
                        {[
                            'allowances',
                            'other',
                            'overtime',
                            'commission',
                        ].map(type => <Row gutter={15}>
                            <Col span={12}>
                                <MyLittleForm
                                    key={type}
                                    figuresStore={verifiedFiguresStore}
                                    progressID={section.record.id}
                                    name={data2[type]().name}
                                    render={data2[type]().render}/>
                            </Col>
                            <Col cpan={8}>
                                <MyLittleForm
                                    key={`${type}Guaranteed`}
                                    figuresStore={verifiedFiguresStore}
                                    progressID={section.record.id}
                                    name={data2[`${type}Guaranteed`]().name}
                                    render={data2[`${type}Guaranteed`]().render}/>
                            </Col>
                        </Row>)}

                    </div>)
                }
            ]
        },
        proofOfAddress : (section) => {
            let data3 = data.proofOfAddress(section)
            return [
                {
                    label     : 'Enter document date',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data3.documentDate().name}
                        render={data3.documentDate().render}
                    />
                }
            ]
        },
        statement      : (section) => {
            let data4 = data.statements(section)
            return [
                {
                    label     : 'Enter IBAN',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data4.iban().name}
                        render={data4.iban().render}
                    />
                },
                {
                    label     : 'Enter dates & balances ',
                    progressID: section.record.id,
                    render    : () => <div className="d-col w-100 gap-3">

                        <div className="d-row gap-15 j-equal">
                            <MyLittleForm
                                figuresStore={verifiedFiguresStore}
                                progressID={section.record.id}
                                name={data4.startDate().name}
                                render={data4.startDate().render}
                            />
                            <MyLittleForm
                                figuresStore={verifiedFiguresStore}
                                progressID={section.record.id}
                                name={data4.openingBalance().name}
                                render={data4.openingBalance().render}
                            />

                        </div>
                        <div className="d-row gap-15 j-equal">
                            <MyLittleForm
                                figuresStore={verifiedFiguresStore}
                                progressID={section.record.id}
                                name={data4.endDate().name}
                                render={data4.endDate().render}
                            />
                            <MyLittleForm
                                figuresStore={verifiedFiguresStore}
                                progressID={section.record.id}
                                name={data4.closingBalance().name}
                                render={data4.closingBalance().render}
                            />
                        </div>
                    </div>
                },
                {
                    label     : 'Enter accommodation costs',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data4.accommodationCosts().name}
                        render={data4.accommodationCosts().render}
                    />
                },
                {
                    label     : 'Enter salary confirmed',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data4.salaryConfirmed().name}
                        render={data4.salaryConfirmed().render}
                    />
                },
                {
                    label     : 'Enter currency',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data4.currency().name}
                        render={data4.currency().render}
                    />
                }
            ]
        },
        creditStatement: (section) => {
            let data5 = data.creditStatements(section)
            return [
                {
                    label     : 'Enter credit limit',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data5.creditLimit().name}
                        render={data5.creditLimit().render}
                    />
                },
                {
                    label     : 'Enter currency',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data5.currency().name}
                        render={data5.currency().render}
                    />
                }
            ]
        },
        loanStatement  : (section) => {
            let data6 = data.loanStatement(section)
            return [
                {
                    label     : 'Enter statement dates',
                    progressID: section.record.id,
                    render    : () => <div className="d-row gap-15 j-equal">
                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data6.startDate().name}
                            render={data6.startDate().render}
                        />

                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data6.endDate().name}
                            render={data6.endDate().render}
                        />


                    </div>
                },
                {
                    label     : 'Enter loan amounts',
                    progressID: section.record.id,
                    render    : () => <div className="d-row gap-15 j-equal">

                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data6.originalLoanAmount().name}
                            render={data6.originalLoanAmount().render}
                        />
                        <MyLittleForm
                            figuresStore={verifiedFiguresStore}
                            progressID={section.record.id}
                            name={data6.balanceOutstanding().name}
                            render={data6.balanceOutstanding().render}
                        />
                    </div>
                },
                {
                    label     : 'Enter monthly payment',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data6.monthlyPayment().name}
                        render={data6.monthlyPayment().render}/>
                },
                {
                    label     : 'Enter currency',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data6.currency().name}
                        render={data6.currency().render}
                    />
                }
            ]
        },
        giftCertificate: (section) => {
            let data7 = data.giftCertificate(section)
            return [
                {
                    label     : 'Enter gift amount',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data7.giftAmount().name}
                        render={data7.giftAmount().render}
                    />
                }
            ]
        },
        helpToBuy      : (section) => {
            let data8 = data.helpToBuy(section)
            return [
                {
                    label     : 'Enter help to buy amount',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data8.amount().name}
                        render={data8.amount().render}
                    />
                },
                {
                    label     : 'Enter the expiry date',
                    progressID: section.record.id,
                    render    : () => <MyLittleForm
                        figuresStore={verifiedFiguresStore}
                        progressID={section.record.id}
                        name={data8.expiryDate().name}
                        render={data8.expiryDate().render}
                    />
                }
            ]
        }
    }

    const data = {
        creditStatements: (section) => {
            return {
                creditLimit: () => ({
                    name  : 'creditLimit',
                    value : figuresResult(section, 'creditLimit'),
                    render: (loading) => askInForm.euro('creditLimit', 'Credit Limit', loading)
                }),
                currency   : () => ({
                    name  : 'currency',
                    value : figuresResult(section, 'currency'),
                    render: (loading) => askInForm.text('currency', 'Currency', loading)
                })
            }
        },
        statements      : (section) => {
            return {
                iban              : () => ({
                    name  : 'iban',
                    value : figuresResult(section, 'iban'),
                    render: (loading) => askInForm.text('iban', 'IBAN', loading)
                }),
                openingBalance    : () => ({
                    name  : 'openingBalance',
                    value : figuresResult(section, 'openingBalance'),
                    render: (loading) => askInForm.euro('openingBalance', 'Opening Balance', loading)
                }),
                closingBalance    : () => ({
                    name  : 'closingBalance',
                    value : figuresResult(section, 'closingBalance'),
                    render: (loading) => askInForm.euro('closingBalance', 'Closing Balance', loading)
                }),
                startDate         : () => ({
                    name  : 'startDate',
                    value : figuresResult(section, 'startDate', "date"),
                    render: (loading) => askInForm.date('startDate', 'Start Date', loading)
                }),
                endDate           : () => ({
                    name  : 'endDate',
                    value : figuresResult(section, 'endDate', "date"),
                    render: (loading) => askInForm.date('endDate', 'End Date', loading)
                }),
                currency          : () => ({
                    name  : 'currency',
                    value : figuresResult(section, 'currency'),
                    render: (loading) => askInForm.text('currency', 'Currency', loading)
                }),
                accommodationCosts: () => ({
                    name  : 'accommodationCosts',
                    value : figuresResult(section, 'accommodationCosts'),
                    render: (loading) => askInForm.euro('accommodationCosts', 'Accommodation Costs', loading)
                }),
                salaryConfirmed   : () => ({
                    name  : 'salaryConfirmed',
                    value : figuresResult(section, 'salaryConfirmed'),
                    render: (loading) => askInForm.check('salaryConfirmed', 'Salary Confirmed', loading)
                }),
                accountName       : () => ({
                    name  : 'accountName',
                    value : figuresResult(section, 'accountName'),
                    render: (loading) => askInForm.text('accountName', 'Account Name', loading)
                }),
                accountAddress    : () => ({
                    name  : 'accountAddress',
                    value : figuresResult(section, 'accountAddress'),
                    render: (loading) => askInForm.text('accountAddress', 'Account Address', loading)
                }),
                city              : () => ({
                    name  : 'city',
                    value : figuresResult(section, 'city'),
                    render: (loading) => askInForm.text('city', 'City', loading)
                }),
                postCode          : () => ({
                    name  : 'postCode',
                    value : figuresResult(section, 'postCode'),
                    render: (loading) => askInForm.text('postCode', 'Post Code', loading)
                }),
                country           : () => ({
                    name  : 'country',
                    value : figuresResult(section, 'country'),
                    render: (loading) => askInForm.text('country', 'Country', loading)
                }),

            }
        },
        loanStatement   : (section) => {
            return {
                startDate         : () => ({
                    name  : 'startDate',
                    value : figuresResult(section, 'startDate', "date"),
                    render: (loading) => askInForm.date('startDate', 'Start Date', loading)
                }),
                endDate           : () => ({
                    name  : 'endDate',
                    value : figuresResult(section, 'endDate', "date"),
                    render: (loading) => askInForm.date('endDate', 'End Date', loading)
                }),
                originalLoanAmount: () => ({
                    name  : 'originalLoanAmount',
                    value : figuresResult(section, 'originalLoanAmount'),
                    render: (loading) => askInForm.euro('originalLoanAmount', 'OriginalLoan Amount', loading)
                }),
                balanceOutstanding: () => ({
                    name  : 'balanceOutstanding',
                    value : figuresResult(section, 'balanceOutstanding'),
                    render: (loading) => askInForm.euro('balanceOutstanding', 'Balance Outstanding', loading)
                }),
                currency          : () => ({
                    name  : 'currency',
                    value : figuresResult(section, 'currency'),
                    render: (loading) => askInForm.text('currency', 'Currency', loading)
                }),
                monthlyPayment    : () => ({
                    name  : 'monthlyPayment',
                    value : figuresResult(section, 'monthlyPayment'),
                    render: (loading) => askInForm.euro('monthlyPayment', 'Monthly Payment', loading)
                })
            }
        },
        proofOfAddress  : (section) => {
            return {
                documentDate: () => ({
                    name  : 'documentDate',
                    value : figuresResult(section, 'documentDate', "date"),
                    render: (loading) => askInForm.date('documentDate', 'Document Date', loading)
                })
            }
        },
        eds             : (section) => {
            let grossBasic = (year) => ({
                name  : `grossBasic.${year}`,
                value : figuresResult(section, `grossBasic.${year}`),
                render: (loading) => askInForm.euro(`grossBasic.${year}`, year.toString(), loading)
            })
            let currentEmploymentStartDate = () => ({
                name  : `currentEmploymentStartDate`,
                value : figuresResult(section, `currentEmploymentStartDate`),
                render: (loading) => askInForm.date(`currentEmploymentStartDate`, 'Start Date', loading)
            })
            let previousEmploymentStartDate = () => ({
                name  : `previousEmploymentStartDate`,
                value : figuresResult(section, `previousEmploymentStartDate`),
                render: (loading) => askInForm.date(`previousEmploymentStartDate`, 'Start Date', loading)
            })
            let previousEmploymentEndDate = () => ({
                name  : `previousEmploymentEndDate`,
                value : figuresResult(section, `previousEmploymentEndDate`),
                render: (loading) => askInForm.date(`previousEmploymentEndDate`, 'End Date', loading)
            })
            let ppsNumber = () => ({
                name  : `ppsNumber`,
                value : figuresResult(section, `ppsNumber`),
                render: (loading) => askInForm.text(`ppsNumber`, 'PPS Number', loading)
            })
            let currentEmployerName = () => ({
                name  : `currentEmployerName`,
                value : figuresResult(section, `currentEmployerName`),
                render: (loading) => askInForm.text(`currentEmployerName`, 'Current Employer Name', loading)
            })
            let previousEmployerName = () => ({
                name  : `previousEmployerName`,
                value : figuresResult(section, `previousEmployerName`),
                render: (loading) => askInForm.text(`previousEmployerName`, 'Prev Employer Name', loading)
            })

            return {
                grossBasic,
                currentEmploymentStartDate,
                previousEmploymentStartDate,
                previousEmploymentEndDate,
                ppsNumber,
                currentEmployerName,
                previousEmployerName
            }
        },
        payslips        : (section) => {
            let options = [
                Frequencies.MONTHLY,
                Frequencies.WEEKLY,
                Frequencies.FORTNIGHTLY
            ].map(it => ({
                label: capitaliseWords(it.toLowerCase()),
                value: it
            }))
            let payFrequency = () => ({
                name  : "payFrequency",
                value : figuresResult(section, 'payFrequency'),
                render: (loading) => askInForm.select('payFrequency', 'Pay Frequency', options, loading)
            })
            let payslipDate = (i) => ({
                name  : `payslip.${i}.payslipDate`,
                label : `Payslip Date`,
                value : figuresResult(section, `payslip.${i}.payslipDate`),
                render: (loading) => askInForm.date(`payslip.${i}.payslipDate`, '', loading)
            })
            let netPay = (i) => ({
                name  : `payslip.${i}.netBasic`,
                label : 'Net Basic',
                value : figuresResult(section, `payslip.${i}.netBasic`),
                render: (loading) => askInForm.euro(`payslip.${i}.netBasic`, '', loading)
            })
            let grossPay = (i) => ({
                name  : `payslip.${i}.grossBasic`,
                label : 'Gross Basic',
                value : figuresResult(section, `payslip.${i}.grossBasic`),
                render: (loading) => askInForm.euro(`payslip.${i}.grossBasic`, '', loading)
            })
            let pensionDeduction = (i) => ({
                name  : `payslip.${i}.pensionDeduction`,
                label : 'Pension Deduction',
                value : figuresResult(section, `payslip.${i}.pensionDeduction`),
                render: (loading) => askInForm.euro(`payslip.${i}.pensionDeduction`, '', loading)
            })
            let avc = (i) => ({
                name  : `payslip.${i}.avc`,
                label : 'AVC',
                value : figuresResult(section, `payslip.${i}.avc`),
                render: (loading) => askInForm.euro(`payslip.${i}.avc`, '', loading)
            })
            return {
                payFrequency,
                payslipDate,
                netPay,
                grossPay,
                pensionDeduction,
                avc
            }
        },
        salaryCert      : (section) => {
            let lastThreeYearBonus = {
                [`${lastYear1}bonus`]          : () => ({
                    name  : `${lastYear1}bonus`,
                    value : figuresResult(section, `${lastYear1}bonus`),
                    render: (loading) => askInForm.euro(`${lastYear1}bonus`, `${lastYear1} Bonus`, loading)
                }),
                [`${lastYear1}bonusGuaranteed`]: () => ({
                    name  : `${lastYear1}bonusGuaranteed`,
                    value : figuresResult(section, `${lastYear1}bonusGuaranteed`),
                    render: (loading) => askInForm.check(`${lastYear1}bonusGuaranteed`, `Guaranteed`, loading)
                }),
                [`${lastYear2}bonus`]          : () => ({
                    name  : `${lastYear2}bonus`,
                    value : figuresResult(section, `${lastYear2}bonus`),
                    render: (loading) => askInForm.euro(`${lastYear2}bonus`, `${lastYear2} Bonus`, loading)
                }),
                [`${lastYear3}bonus`]          : () => ({
                    name  : `${lastYear3}bonus`,
                    value : figuresResult(section, `${lastYear3}bonus`),
                    render: (loading) => askInForm.euro(`${lastYear3}bonus`, `${lastYear3} Bonus`, loading)
                }),
            }
            return {
                certDate            : () => ({
                    name  : 'certDate',
                    value : figuresResult(section, 'certDate', "date"),
                    render: (loading) => askInForm.date('certDate', 'Certification Date', loading)
                }),
                sector              : () => ({
                    name  : 'sector',
                    value : figuresResult(section, 'sector', "string"),
                    render: (loading) => askInForm.select('sector', 'Sector', [
                        {
                            value: 'public',
                            label: 'Public'
                        },
                        {
                            value: 'private',
                            label: 'Private'
                        },
                        {
                            value: 'self',
                            label: 'Self'
                        }
                    ], loading),
                }),
                annualGrossBasic    : () => ({
                    name  : 'annualGrossBasic',
                    value : figuresResult(section, 'annualGrossBasic'),
                    render: (loading) => askInForm.euro('annualGrossBasic', 'Annual Gross Basic', loading)
                }),
                overtime            : () => ({
                    name  : 'overtime',
                    value : figuresResult(section, 'overtime'),
                    render: (loading) => askInForm.euro('overtime', 'Overtime', loading)
                }),
                overtimeGuaranteed  : () => ({
                    name  : 'overtimeGuaranteed',
                    value : figuresResult(section, 'overtimeGuaranteed'),
                    render: (loading) => askInForm.check('overtimeGuaranteed', 'Guaranteed', loading)
                }), ...lastThreeYearBonus,
                bonusGuaranteed     : () => ({
                    name  : 'bonusGuaranteed',
                    value : figuresResult(section, 'bonusGuaranteed'),
                    render: (loading) => askInForm.check('bonusGuaranteed', 'Guaranteed', loading)
                }),
                allowances          : () => ({
                    name  : 'allowances',
                    value : figuresResult(section, 'allowances'),
                    render: (loading) => askInForm.euro('allowances', 'Allowances', loading)
                }),
                allowancesGuaranteed: () => ({
                    name  : 'allowancesGuaranteed',
                    value : figuresResult(section, 'allowancesGuaranteed'),
                    render: (loading) => askInForm.check('allowancesGuaranteed', 'Guaranteed', loading)
                }),
                other               : () => ({
                    name  : 'other',
                    value : figuresResult(section, 'other'),
                    render: (loading) => askInForm.euro('other', 'Other Allowance', loading)
                }),
                otherGuaranteed     : () => ({
                    name  : 'otherGuaranteed',
                    value : figuresResult(section, 'otherGuaranteed'),
                    render: (loading) => askInForm.check('otherGuaranteed', 'Guaranteed', loading)
                }),
                commission          : () => ({
                    name  : 'commission',
                    value : figuresResult(section, 'commission'),
                    render: (loading) => askInForm.euro('commission', 'Commission', loading)
                }),
                commissionGuaranteed: () => ({
                    name  : 'commissionGuaranteed',
                    value : figuresResult(section, 'commissionGuaranteed'),
                    render: (loading) => askInForm.check('commissionGuaranteed', 'Guaranteed', loading)
                }),
            }
        },
        bookingDeposit  : (section) => {
            return {
                bookingDeposit : () => ({
                    name  : 'amount',
                    value : figuresResult(section, 'amount'),
                    render: (loading) => askInForm.euro('amount', 'Amount', loading)
                }),
                contractDeposit: () => ({
                    name  : 'amount',
                    value : figuresResult(section, 'amount'),
                    render: (loading) => askInForm.euro('amount', 'Amount', loading)
                })
            }
        },
        maintenancePaid : (section) => {
            return {
                maintenancePaid: () => {
                    if (forms) {
                        return [
                            {
                                type : 'euro',
                                name : 'maintenancePaid',
                                label: 'Maintenance Paid',

                            }
                        ]
                    }
                    return figuresResult(section, 'maintenancePaid')
                }
            }
        },
        helpToBuy       : (section) => {
            return {
                amount    : () => ({
                    name  : 'amount',
                    value : figuresResult(section, 'amount'),
                    render: (loading) => askInForm.euro('amount', 'Amount', loading)
                }),
                expiryDate: () => ({
                    name  : 'expiryDate',
                    value : figuresResult(section, 'expiryDate'),
                    render: (loading) => askInForm.date('expiryDate', 'Expiry Date', loading)
                })
            }
        },
        giftCertificate : (section) => {
            return {
                giftAmount: () => ({
                    name  : 'amount',
                    value : figuresResult(section, 'giftAmount'),
                    render: (loading) => askInForm.euro('giftAmount', 'Amount', loading)
                })
            }
        },
        firstHome       : (section) => {
            return {
                amount: () => {
                    if (forms) {
                        return [
                            {
                                type : 'euro',
                                name : 'amount',
                                label: 'Amount',
                            }
                        ]
                    }
                    return figuresResult(section, 'amount')
                },
            }
        },

    }

    const dataBySection = (section) => {
        if (['proof-of-address'].some(it => section.name.startsWith(it))) {
            return dataForms.proofOfAddress(section)
        }
        // if (['identification'].some(it => section.name.startsWith(it))) {
        //     return data.statements(section)
        // }
        if ([
            'current-accounts',
            'assets'
        ].some(it => section.name.startsWith(it)))
        {
            return dataForms.statement(section)
        }
        if (['payslips'].some(it => section.name.startsWith(it))) {
            return dataForms.payslips(section)
        }
        if (['employment-summary'].some(it => section.name.startsWith(it))) {
            return dataForms.eds(section)
        }
        if (['salary-cert'].some(it => section.name.startsWith(it))) {
            return dataForms.salaryCert(section)
        }
        if (['debts'].some(it => section.name.startsWith(it) && section.isCredit)) {
            return dataForms.creditStatement(section)
        }
        if (['debts'].some(it => section.name.startsWith(it) && !section.isCredit)) {
            return dataForms.loanStatement(section)
        }
        if (['gift-certificate'].some(it => section.name.startsWith(it))) {
            return dataForms.giftCertificate(section)
        }
        if (['htb-scheme-forms'].some(it => section.name.startsWith(it))) {
            return dataForms.helpToBuy(section)
        }
        // if (['first-home-scheme-forms'].some(it => section.name.startsWith(it))) {
        //     return data.firstHome(section)
        // }
        if (['properties'].some(it => section.name.startsWith(it))) {
            return dataForms.loanStatement(section)
        }
    }

    const docs = {
        salaryCert         : (appN, employmentId) => {
            let section
            let sectionName = 'salary-cert'
            if (employmentId) {
                section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === employmentId)
            }
            else {
                section = sections.find(it => it.name === `${sectionName}-${appN}`)
            }
            return data.salaryCert(section)
        },
        separationAgreement: (appN) => {
            let section = sections.find(it => it.name === `separation-agreement-${appN}`)
            return data.maintenancePaid(section)
        },
        divorceDecree      : (appN) => {
            let section = sections.find(it => it.name === `divorce-decree-${appN}`)
            return data.maintenancePaid(section)
        },
        bankStatements     : (appN, accountId) => {
            let sectionName = 'current-accounts'
            let section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === accountId)
            return data.statements(section)
        },
        savingStatements   : (appN, assetId) => {
            let sectionName = 'assets'
            let section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === assetId)
            return data.statements(section)
        },
        funding            : (appN) => {
            return {
                monthlySavings: () => {

                },
                closingBalance: () => {

                }
            }
        }, // not done yet
        payslips           : (appN, employmentId) => {
            let section
            let sectionName = 'payslips'
            if (employmentId) {
                section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === employmentId)
            }
            else {
                section = sections.find(it => it.name === `${sectionName}-${appN}`)
            }
            return {
                pensionDeduction   : () => {
                    return figuresResult(section, 'pensionDeduction')
                },
                avc                : () => {
                    return figuresResult(section, 'avc')
                },
                averageMonthlyBasic: () => {
                    return figuresResult(section, 'averageMonthlyBasic')
                }
            }
        },
        creditStatements   : (appN, creditId) => {
            let sectionName = 'debts'
            let section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === creditId)
            return data.creditStatements(section)
        },
        loanStatement      : (appN, debtId) => {
            let sectionName = 'debts'
            let section = sections.find(it => it.name === `${sectionName}-${appN}` && it.index === debtId)
            return data.loanStatement(section)
        },
        giftCertificate    : () => {
            let section = sections.find(it => it.name === `gift-certificate` && !it.index)
            return data.giftCertificate(section)
        },
        helpToBuy          : () => {
            let section = sections.find(it => it.name === `htb-scheme-forms` && !it.index)
            return data.helpToBuy(section)
        },
        firstHome          : () => {
            let section = sections.find(it => it.name === `first-home-scheme-forms` && !it.index)
            return data.firstHome(section)
        },
    }
    return {
        docs,
        dataBySection
    }
}
export const savingsDataSource = (mortgage, sections) => {
    let accountLike = sections.filter(item => {
        if (item.name === 'current-accounts-1') {
            return true
        }
        if (item.name === 'assets-1') {
            return true
        }
        if (mortgage.twoApplicants) {
            if (item.name === 'current-accounts-2') {
                return true
            }
            if (item.name === 'assets-2') {
                return true
            }
        }
        return false
    })

    let maxMonths = 0
    const dataSource = accountLike.map(item => {
        let defaultObject = {
            name           : item.name,
            index          : item.index,
            id             : item?.record?.id || `error-${item.name}-${item.index}`,
            key            : item.name + item.index,
            owner          : item.applicant === 1 ? mortgage.applicant1.firstName : item.applicant === 2 ? mortgage.applicant2.firstName : '?',
            applicant      : item.applicant,
            account        : item.title,
            queries        : item.queries,
            openingBalance : 0,
            closingBalance : 0,
            totalDifference: 0
        }
        const makeError = (message) => {
            return {
                ...defaultObject,
                error: `${message}`
            }
        }
        if (!item.record) {
            return makeError('No record')
        }
        // if (!item.uploads?.length) {
        //     console.log({NOuPLAODS:item})
        //     return makeError('No uploads')
        // }
        if (!item.record.metaData) {
            return makeError('No data')
        }
        const data = JSON.parse(item.record.metaData)
        if (!data.dateRangeStart) {
            return makeError('No start date')
        }
        if (!data.dateRangeEnd) {
            return makeError('No end date')
        }
        if (!data.euroRangeStart && data.euroRangeStart !== 0) {
            return makeError('No start balance')
        }
        if (!data.euroRangeEnd && data.euroRangeEnd !== 0) {
            return makeError('No end balance')
        }

        // DATE RANGE
        const startDate = dayjs(data.dateRangeStart, 'YYYY-MM-DD')
        const endDate = dayjs(data.dateRangeEnd, 'YYYY-MM-DD')
        const diffInMonths = monthsBetweenDates(startDate, endDate)
        if (diffInMonths > maxMonths) {
            maxMonths = diffInMonths
        }
        const rangeString = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`

        // BALANCES
        let endBalance = data.euroRangeEnd
        let adjustedEndBalance = data.euroRangeEnd
        if (!!data.balanceAdjustment) {
            if (data.balanceAdjustment.adjust === 'up') {
                adjustedEndBalance += data.balanceAdjustment.amount
            }
            if (data.balanceAdjustment.adjust === 'down') {
                adjustedEndBalance -= data.balanceAdjustment.amount
            }
        }
        const diffInEuro = endBalance - data.euroRangeStart
        const diffInEuroAdjusted = adjustedEndBalance - data.euroRangeStart
        const excluded = item.record.excludeFromCalculations

        // AVERAGE MONTHLY
        let averageMonthly = excluded || diffInMonths === 0 ? 0 : Math.floor(diffInEuroAdjusted / diffInMonths)
        return {
            ...defaultObject,
            range          : rangeString,
            startDate,
            endDate,
            diffInMonths,
            openingBalance : excluded ? 0 : data.euroRangeStart,
            closingBalance : excluded ? 0 : endBalance,
            totalDifference: excluded ? 0 : diffInEuro,
            averageMonthly,
            excluded,
            salaried       : !!item.salaried,
            accommodationDD: !!item.accommodationDD,
            adjustment     : !!data.balanceAdjustment ? data.balanceAdjustment : null,

        }
    })
    const totalClosingBalance = dataSource.reduce((acc, item) => acc + (item.closingBalance || 0), 0)
    const averageMonthlySavings = dataSource.reduce((acc, item) => acc + (item.averageMonthly || 0), 0)
    const totalTotalDifference = dataSource.reduce((acc, item) => acc + (item.totalDifference || 0), 0)
    const totalOpeningBalance = dataSource.reduce((acc, item) => acc + (item.openingBalance) || 0, 0)

    return {
        dataSource,
        averageMonthlySavings,
        totalOpeningBalance,
        totalClosingBalance,
        totalTotalDifference,
        maxMonths,
    }
}