import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, ConfigProvider, DatePicker, Form, Input, InputNumber} from "antd";
import {joinWithBr, joinWithNl} from "../../../../assets/scripts/string-functions";
import dayjs from "dayjs";

import {LONGDATE} from "../../../../assets/scripts/constants";
import useMortgage from "../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../providers/mortgage/useMortgageUploadService";
import {LoadingOutlined} from "@ant-design/icons";
import {Auth} from "aws-amplify";
import 'dayjs/locale/en-ie'

dayjs.locale('en-ie')
QueryItemForm.propTypes = {
    item: PropTypes.object.isRequired
};

function QueryItemForm(props) {
    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const [state, setState] = useState({editing: false, saving: false})
    const dateDefault = props.item.date ? dayjs(props.item.date, "YYYY-MM-DD") : null

    const initialValues = {
        lenderAnswer: props.item.lenderAnswer?.join('\n'),
        date: dateDefault,
        description: props.item.description,
        query: props.item.query.join('\n'),
        private: !!props.item.private,
        exclude: !!props.item.excludedBy,
        amount: props.item.amount
    }

    const handleValuesChange = (pair, map) => {
        if (pair.hasOwnProperty('editing')) {
            const update = {
                pairs: {}
            }
            if (!pair.editing) {
                // save changes
                if (map.description !== props.item.description) {
                    update.pairs.description = map.description
                }
                if (map.query !== props.item.query) {
                    update.pairs.query = map.query.split('\n')
                }
                if (map.amount !== props.item.amount) {
                    update.pairs.amount = map.amount
                }
                if (map.date) {
                    let d = map.date.format('YYYY-MM-DD')
                    if (d !== props.item.date) {
                        update.pairs.date = d
                    }
                }
                if (Object.keys(update.pairs).length > 0) {
                    update.target = `queries[${props.item.id}]`
                    mortgage.mutate({update})
                }
            }

            setState(s => ({...s, editing: pair.editing}))

        }
    }

    function Editor({value, children}) {
        if (state.editing) {
            return children
        }
        return <div>{value}</div>
    }
    const handleDelete = async () => {

       // await props.handleDelete(props.item.id)
    }
    const handleLenderAnswerSave = async (form) => {
        setState(s => ({...s, saving: true}))
        const user = await Auth.currentAuthenticatedUser()
        const update = {
            target: `queries[${props.item.id}]`,
            pairs: {
                lenderAnswer: form.lenderAnswer ? form.lenderAnswer.split('\n'):null,
                lenderAnswerOn: (new Date()).toISOString(),
                lenderAnswerBy: user.attributes.sub,
                excludedOn: form.exclude ? (new Date()).toISOString() : null,
                excludedBy: form.exclude ? user.attributes.sub : null,
                private: form.private
            }
        }
        await mortgage.mutate({update})
        setState(s => ({...s, saving: false}))
    }
    function LinkToItem() {
        switch (props.item.referenceType) {
            case "UPLOAD":
                const upload = mortgage.uploads.find(it => it.id === props.item.referenceId)
                if (upload) {
                    const handleUploadOpen = async () => {
                        await uploadService.open(upload)
                    }
                    return (
                        <Form.Item
                            label="File"
                        >
                            <a onClick={handleUploadOpen}>{upload.fileName}</a>
                        </Form.Item>
                    )
                }
                return null

            default:
                return null
        }

    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        labelColor: '#8c8c8c'
                    },
                },
            }}
        >

            <Form requiredMark={false} onValuesChange={handleValuesChange} initialValues={initialValues}
                  onFinish={handleLenderAnswerSave}>
                <div className="d-row j-end">
                    <div className="d-row j-equal grow gap-15">

                        {props.item.date && state.editing && (
                            <Form.Item
                                label="Date"
                                name="date">
                                <DatePicker
                                 //   defaultValue={dateDefault}
                                    disabledTime={true}
                                    format={"D MMM YYYY"}
                                />
                            </Form.Item>
                        )}
                        {props.item.date && !state.editing && (
                            <Form.Item label="Date">
                                {dayjs(props.item.date).format( LONGDATE)}
                            </Form.Item>
                        )}

                        {state.editing && (
                            <Form.Item
                                label="Description"
                                name="description">
                                <Input
                                  //  defaultValue={props.item.description}
                                />
                            </Form.Item>
                        )}
                        {!state.editing && (
                            <Form.Item
                                label="Description"
                            > {props.item.description}
                            </Form.Item>
                        )}

                        {state.editing && (
                            <Form.Item
                                label="Amount"
                                name="amount">
                                <InputNumber
                                    prefix={"€"}
                                    //  defaultValue={props.item.description}
                                />
                            </Form.Item>
                        )}
                        {!state.editing && (
                            <Form.Item
                                label="Amount"
                            > {props.item?.amount?.toEuro()}
                            </Form.Item>
                        )}

                        <LinkToItem/>

                    </div>
                    <Form.Item
                        label={state.editing ? 'Uncheck to save' : 'Edit'}
                        name="editing"
                        valuePropName="checked"
                    >
                        <Checkbox/>
                    </Form.Item>
                </div>


                    {state.editing && (
                        <Form.Item
                            label="Query Asked"
                            name="query">
                        <Input.TextArea
                           // defaultValue={joinWithNl(props.item.query)}
                        />
                        </Form.Item>
                    )}
                    {!state.editing && (
                        <Form.Item
                            label="Query Asked"
                        >{joinWithBr(props.item.query)}
                        </Form.Item>
                    )}

                <Form.Item
                    label="User Answer"
                    >
                    {joinWithBr(props.item.userAnswer)}
                </Form.Item>

                {
                    !state.editing && (
                        <>
                            <Form.Item
                                label="Your Answer"
                                name="lenderAnswer"
                            >
                                <Input.TextArea/>
                            </Form.Item>
                            <div className="d-row j-end gap-15">
                                <Form.Item
                                    name="exclude"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                   //     defaultChecked={!!props.item.excludedBy}
                                    > Exclude from application</Checkbox>

                                </Form.Item>
                                <Form.Item
                                    name="private"
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                    //    defaultChecked={!!props.item.private}
                                    >Hide from user</Checkbox>

                                </Form.Item>


                                <Button htmlType="submit" className="ms-24"> Save
                                    {state.saving && <LoadingOutlined className="ms-6"/>}
                                </Button>
                            </div>

                        </>
                    )
                }


                <Editor>
                    <Button onClick={handleDelete}>
                        Delete Query

                    </Button>
                </Editor>
            </Form>
        </ConfigProvider>
    );
}

export default QueryItemForm;