import React from 'react';
import {Button, Form, Input, Select, Space} from "antd";
import {FilterOutlined, FolderOutlined} from "@ant-design/icons";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";
import {useNavigate} from "react-router-dom";
import {v4} from "uuid";
import {gray} from "@ant-design/colors";

function Nav(props) {
    const navigate = useNavigate()
    const backendUsers = useBackendUsers()
    const [taskForm] = Form.useForm()
    const startATask = async () =>{
        const input = {
            usermortgageID: "null",
            author: backendUsers.me.sub,
        }
        const result = await props.operations.create(input)
        if (input.author !== input.target) {
            props.setFilterByDirection('by')
        }
        navigate(`/my-tasks/${result.id}`)
    }
    if (!backendUsers.me) return null
    return (
        <div className="d-row gap-15 p-15 a-center p-sticky zi-6" style={{backgroundColor:"#e7e7e7"}}>
            <Button disabled={props.filterByDirection!=='for'} type={props.sorting ? "primary":"default"} onClick={()=>props.setSorting(s=>!s)}>Sort</Button>
            Filter:
            <Select
                disabled={props.sorting}
                suffixIcon={<FolderOutlined/>}
                options={[{label: 'All', value: 'All'}, ...props.folders.map(f=>({label: f, value: f}))]}
                style={{ width: 120 }}
                value={props.filterByFolder}
                onSelect={props.setFilterByFolder}/>

            <Select
                disabled={props.sorting}
                suffixIcon={<FilterOutlined/>}
                options={[
                {label: 'For me', value: 'for'},
                {label: 'By me', value: 'by'},
            ]}
                style={{ width: 120 }}
                value={props.filterByDirection}
                onChange={props.setFilterByDirection}/>


            <Button className="ms-auto" onClick={startATask}>Start a task</Button>

        </div>
    );
}

export default Nav;