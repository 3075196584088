import React, {useState} from 'react';
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";
import {Link, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {Badge, Card, Tag, Typography} from "antd";
import {gold, gray} from "@ant-design/colors";
import {AimOutlined, StarFilled} from "@ant-design/icons";
import ItemModal from "./ItemModal";

function TaskItem({task}) {
    const backendUsers = useBackendUsers()
    let date = dayjs(task.createdAt).format('D MMM, H:mm a')
    let string = task.body && task.body.length ? task.body.join(' ') : 'New Task ...'

    const [state, setState] = useState({open: false})

    const paused = !!task.pauses.find(pause => !pause.completedOn)

    const handleClose = (e) => {
        e.stopPropagation()
        setState({open: false})
    }
    function BasicContent(props) {
        let author = backendUsers.getBySub(task.author).firstName

        return (
            <>
                {
                    !!task.mortgage && (

                        <Link style={{color: gray[1]}}
                                  to={`/client/${task.mortgage.id}`}><small>{task.mortgage.title}</small> </Link>

                    )
                }
                <div className="d-row gap-6 a-center w-100">
                    {/*<ItemOrigins task={task} />*/}


                    <small className="d-block">{author} on {date}</small>
                    {
                        !!task.completedOn && <Tag color="green">Completed</Tag>
                    }
                    {
                        !!paused && <Tag color="orange">Paused</Tag>
                    }
                    {
                        !paused && !task.completedOn && !!task.targetStarted && <Tag color="geekblue">Working ...</Tag>
                    }
                    <span className="ms-auto">
                        {
                            props.hasOwnProperty('withHasTheBall') && <HasTheBallCard/>
                        }
                    </span>
                </div>




            </>
        )
    }

    function HasTheBallCard(){
        if (task.completedOn){
            return null
        }
        const user = backendUsers.getBySub(task.currentOwner)
        let color = user ? user.color : 'default'
        let name = user ? user.firstName : 'No ball owner'
        return (
            <Tag color={color} className="d-row a-center gap-6 me-0" style={{backgroundColor:'transparent', marginInlineEnd:0}}> <AimOutlined/> {name} </Tag>
        )
    }

    function FolderBadge(){
        if(task.userFolder.hasOwnProperty(backendUsers.me.sub)){
            return (
                <Badge color="gray" count={task.userFolder[backendUsers.me.sub]}/>
            )
        }
        if (task.folder){
            return (
                <Badge color="gray" count={task.folder}/>
            )
        }
        return null
    }

    return (
        <div onClick={(e) => setState({open: true})} className="px-15 w-100" >


                <BasicContent withHasTheBall key={task.id+'basic'}/>


                <Typography.Text style={{whiteSpace:'wrap'}} >{string}</Typography.Text>
                {
                    !!task.lastMessage && (
                        <div className="d-row gap-12">
                            {
                                task.lastMessageBy !== backendUsers.me.sub && (
                                    <StarFilled style={{color: gold[3]}}/>
                                )
                            }


                            <span className="d-row">“<Typography.Text style={{color: gray.primary, whiteSpace: 'wrap'}}>{task.lastMessage}</Typography.Text>”</span>
                        </div>

                    )
                }
            <ItemModal task={task} open={state.open} onCancel={handleClose} key={task.id}>
                <BasicContent key={task.id+'basic2'}/>
            </ItemModal>

        </div>
    )
}

export default TaskItem;