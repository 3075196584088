
    export const currencyFormatter= (value) => {
        if (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '';
    }
    export const currencyParser= (value)=>{
        if (value) {
            return value.replace(/,/g, '');
        }
        return '';
    }
    export const monthlyAmountFromFreq = (freq, amt) => {
        let monthly = null
        switch (freq) {
            case "YEARLY" :
                monthly = amt / 12;
                break;
            case "STATIC" :
                monthly = 0;
                break;
            case "QUARTERLY" :
                monthly = amt / 3;
                break;
            case "MONTHLY" :
                monthly = amt;
                break;
            case "FORTNIGHTLY" :
                monthly = (amt / 2) * 4.33;
                break;
            case "WEEKLY" :
                monthly = amt * 4.33;
                break;
            case "OCCASIONALLY" :
                monthly = amt / 12;
                break;
        }
        return monthly
    }