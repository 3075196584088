import {performGQL} from "../functions";
const updateAdminEmails = /* GraphQL */ `
    mutation UpdateAdminEmails(
        $input: UpdateAdminEmailsInput!
        $condition: ModelAdminEmailsConditionInput
    ) {
        updateAdminEmails(input: $input, condition: $condition) {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            appsyncVersion
            actionedOn
            actionedBy
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
async function updateActioned(update){
    const variables = {
        input: update
    }
    return await performGQL(variables, updateAdminEmails)
}
export default updateActioned