import {ApplicationStages, ApplicationTypes, BuildType, EmploymentTypes} from "../../../../../models";
import useMortgage from "../../../../providers/mortgage/useMortgage";

export function customerPreApprovalDocumentsToCreate(mortgage) {
    const submissions = mortgage.submissions.filter(a => !a.invalidatedReason).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
    })
    const activeSubmission = submissions.length ? submissions[0] : null

    const items = []
    if (!activeSubmission) {
        return items
    }
    // 1. Lender Checklist
    switch (activeSubmission.lenderCode) {
        case 'avant':
            items.push({
                name  : 'direct-debit-mandate',
                fill  : true,
                folder: 'downloads'
            })
            if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0) {
                items.push({
                    name  : 'avant-gift-letter',
                    folder: 'downloads'
                })
            }
            break;
        case 'ptsb':
            items.push({
                name   : 'direct-debit-mandate',
                overlay: true,
                folder : 'customer-docusign'
            })
            if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0) {
                items.push({
                    name  : 'ptsb-gift-letter',
                    folder: 'downloads'
                })
            }
            if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
                items.push({
                    name  : 'self-build-projected-costing',
                    folder: 'downloads'
                }, {
                    name  : 'certificate-of-inspection-statutory',
                    folder: 'downloads'
                }, {
                    name  : 'certificate-of-inspection-opt-out',
                    folder: 'downloads'
                })
            }
            break;
        case 'haven':
            items.push({
                name   : 'interest-rate-application-form',
                overlay: true,
                folder : 'customer-docusign'
            })
            if (mortgage.requirement.receivingGifts && mortgage.requirement.depositGifts > 0) {
                items.push({
                    name  : 'haven-gift-letter',
                    folder: 'downloads'
                })
            }
            if (!(mortgage.requirement.lenderProduct || '').toLowerCase().includes('green')) {
                items.push({
                    name   : 'cashback-brochure',
                    overlay: false,
                    folder : 'lender-documents',
                })
                items.push({
                    name   : 'cashback-form',
                    overlay: true,
                    folder : 'customer-docusign',
                })
            }
            items.push({
                name   : 'direct-debit-mandate',
                overlay: true,
                folder : 'customer-docusign',
            })
            break;
    }
    return items.map(item => {
        return {
            ...item,
            key      : item.name,
            private  : false,
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === item.folder && doc.name === item.name && doc.submissionID === activeSubmission.id),
        }
    })
}

function useCustomerPreApprovalDocuments(props) {
    // 1. Items to copy into customer lender documents folder
    // 2. Items to create for use as download template for customer uploads
    // 3. Items to create to be docusigned by customer

    const mortgage = useMortgage()
    return customerPreApprovalDocumentsToCreate(mortgage)
}

export default useCustomerPreApprovalDocuments;