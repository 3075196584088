import React from 'react';

import {BuildType, MortgageTypes} from "../../../../../../models";
import SectionForm from "../SectionForm";
import {wordLimit} from "../../../../../../assets/scripts/string-functions";
import dayjs from "dayjs";

export function currentMortgageProvider(props) {
    return {
        name  : 'currentMortgageProvider',
        label : 'Current Mortgage Provider',
        text  : 'Who is your current mortgage provider?',
        answer: {
            type: 'text'
        },
        model : 'requirement',
        rules : [
            {
                required: true,
                message : `A mortgage provider is required`
            }
        ],
        // hides : (form) => {
        //     return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        // },
        target: `properties[${props.index}].lender`
    }
}
export function propertyPrice(props){
    return {
        label: "Sale Price",
        text: "What is price of the property?",
        name: "propertyPrice",
        answer: {
            type: "euro",
        },
        model: "property",
        target: `properties[${props.index}].propertyPrice`
    }
}
export function siteOwned(props){
    return {
        label: "Site Owned",
        text: "Do you own the site on which the property is built?",
        name: "siteOwned",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].siteOwned`
    }
}
export function siteValue(props){
    return {
        label: "Site Value",
        text: "What is the value of the site?",
        name: "siteValue",
        answer: {
            type: "euro",
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].siteValue`
    }
}
export function constructionCosts(props){
    return {
        label: "Construction Costs",
        text: "What are the construction costs?",
        name: "constructionCosts",
        answer: {
            type: "euro",
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].constructionCosts`
    }
}
export function constructionStarted(props){
    return {
        label: "Construction Started",
        text: "Has construction started?",
        name: "constructionStarted",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form)=>form.getFieldValue('buildType') !== BuildType.SELFBUILD,
        model: "property",
        target: `properties[${props.index}].constructionStarted`
    }
}
export function propertyTitle(property) {
    let title
    if (property.address) {
        title = wordLimit(property.address)
    } else {
        title = 'New Property'
    }
    return title

}

export function addressKnown(props) {
    return {
        label: "Property Found",
        text: "Have you found a property?",
        name: "addressKnown",
        answer: {
            type: "choice",
            choices: [
                {
                    value: true,
                    text: "Yes",
                },
                {
                    value: false,
                    text: "No",
                }
            ]
        },
        model: "property",
        rules: [

        ],
        target: `properties[${props.index}].addressKnown`
    }
}
export function address(props) {
    return {
        text: "What is the address of the property?",
        name: "address",
        label: "Address",
        answer: {
            type: "address",
        },
        model: "properties",
        rules: [
        ],
        target: `properties[${props.index}].address`
    }
}
export function propertyType(props) {
    return {
        label: "Property Type",
        text: "What type of property is this?",
        name: "propertyType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Detached",
                    value: "DETACHED"
                },
                {
                    text: "Semi-detached",
                    value: "SEMIDETACHED"
                },
                {
                    text: "Terrace",
                    value: "TERRACE"
                },
                {
                    text: "Apartment",
                    value: "APARTMENT"
                },
                {
                    text: "Flat",
                    value: "FLAT"
                }
            ]
        },
        model: "properties",
        rules: [

        ],
        target: `properties[${props.index}].propertyType`
    }
}
export function newlyBuilt(props) {
    return {
        label: "Property Build",
        text: "What type of property are you buying?",
        name: "buildType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Newly Built",
                    value: BuildType.NEW
                },
                {
                    text: "Second Hand",
                    value: BuildType.SECONDHAND
                },
                {
                    text: "Self Build",
                    value: BuildType.SELFBUILD
                },
                {
                    text: "Unknown",
                    value: BuildType.UNKNOWN
                }
            ]
        },
        model: "property",
        rules: [

        ],
        target: `properties[${props.index}].buildType`
    }
}
export function bathrooms(props) {
    return {
        label: "Bathrooms",
        text: "How many bathroom has the property?",
        name: "bathrooms",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 20, message: "The maximum value here is 20"}

        ],
        target: `properties[${props.index}].bathrooms`
    }
}
export function bedrooms(props) {
    return {
        label: "Bedrooms",
        text: "How many bedrooms has the property?",
        name: "bedrooms",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 20, message: "The maximum value here is 20"}

        ],
        target: `properties[${props.index}].bedrooms`
    }
}
export function berScore(props) {
    return {
        label: "BER Score",
        text: "What is the property's BER score?",
        name: 'berScore',
        answer: {
            type: 'select',
            choices: [
                {
                    value: 'A1',
                    text: 'A1',

                },
                {
                    value: 'A2',
                    text: 'A2',

                },
                {
                    value: 'A3',
                    text: 'A3',

                },
                {
                    value: 'B1',
                    text: 'B1',

                },
                {
                    value: 'B2',
                    text: 'B2',

                },
                {
                    value: 'B3',
                    text: 'B3',

                },
                {
                    value: 'C1',
                    text: 'C1',

                },
                {
                    value: 'C2',
                    text: 'C2',
                },
                {
                    value: 'C3',
                    text: 'C3',

                },
                {
                    value: 'D1',
                    text: 'D1',

                },
                {
                    value: 'D2',
                    text: 'D2',
                },
                {
                    value: 'E1',
                    text: 'E1',
                },
                {
                    value: 'E2',
                    text: 'E2',
                },
                {
                    value: 'F',
                    text: 'F',
                },
                {
                    value: 'G',
                    text: 'G',
                },
                {
                    value: 'UNKNOWN',
                    text: "I don't know",
                }
            ]
        },
        rules: [
        ],
        target: `properties[${props.index}].berScore`
    }
}
export function propertyValue(props) {
    return {
        label: "Property Value",
        text: "What is the estimated value of the property?",
        name: "propertyValue",
        answer: {
            type: "euro",
        },
        model: "properties",
        rules: [
            {type: 'number', min: 50000, message: "The minimum value here is €50,000"},
            {type: 'number', max: 5000000, message: "The maximum value here is €5,000,000"}
        ],
        target: `properties[${props.index}].propertyValue`
    }
}
export function origPurchasePrice(props) {
    return {
        label: "Original Value",
        text: "What was the original purchase price  of the property?",
        name: "origPurchasePrice",
        answer: {
            type: "euro",
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 50000, message: "The minimum value here is €50,000"},
            {type: 'number', max: 5000000, message: "The maximum value here is €5,000,000"}
        ],
        target: `properties[${props.index}].origPurchasePrice`
    }
}
export function yearOfPurchase(props) {
    const max = dayjs().format('YYYY')
    //const max = format(new Date(), "yyyy")
    return {
        text: "In what year was this purchased?",
        name: "yearOfPurchase",
        label: "Year of Purchase",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A year is required`},
            {type: 'number', min: 1990, message: "The minimum value here is 1990"},
            {type: 'number', max: max, message: "The maximum value here is " + max}
        ],
        target: `properties[${props.index}].yearOfPurchase`
    }
}
export function residingHere(props) {
    return {
        text: `Is this the property in which you currently reside?`,
        name: "residingHere",
        label: "Residing Here",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "property",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].residingHere`
    }
}
export function adultsBoarding(props) {
    return {
        name: "adultsBoarding",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Other Adults Residing",
        text: "Will there be any other person over 17 who will occupy the property?",
        model: "property",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].adultsBoarding`
    }
}
export function adultsBoardingCount(props) {
    return {
        name: "adultsBoardingCount",
        answer: {
            type: "int"
        },
        label: "How Many Adults",
        text: "How many adults over the age of 17 will occupy the property?",
        model: "property",
        hides: (form) => {
            return form.getFieldValue('adultsBoarding') !== true
        },
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 10, message: "The maximum value here is 10"}
        ],
        target: `properties[${props.index}].adultsBoardingCount`
    }
}
export function isRented(props) {
    return {
        text: "Is this property rented out?",
        label: "Rented",
        name: "isRented",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].isRented`
    }
}
export function monthlyRent(props) {
    return {
        label: "Rent per month",
        text: "How much rent does the property yield per month?",
        name: "monthlyRent",
        answer: {
            type: "euro",
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 50, message: "The minimum value here is €50"},
            {type: 'number', max: 50000, message: "The maximum value here is €50,000"}
        ],
        target: `properties[${props.index}].monthlyRent`
    }
}
export function isMortgaged(props) {
    return {
        text: "Is there a mortgage on the property?",
        label: "Mortgaged",
        name: "isMortgaged",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `properties[${props.index}].isMortgaged`

    }
}
export function remainingTerm(props) {
    return {
        text: "How many years until fully repaid?",
        label: "Remaining Term",
        name: "remainingTerm",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 0, message: "The minimum value here is 0"},
            {type: 'number', max: 35, message: "The maximum value here is 35"}
        ],
        target: `properties[${props.index}].remainingTerm`
    }
}
export function lender(props) {
    return {
        text: "What lender provided this mortgage?",
        label: "Lender",
        name: "lender",
        answer: {
            type: "text",
        },
        // hides: (form) => {
        //     return form.getFieldValue('isMortgaged') !== true
        // },
        model: "properties",
        rules: [
            {required: true, message: `An name is required`},
        ],
        target: `properties[${props.index}].lender`
    }
}
export function accountNumber(props) {
    return {
        text: "What is the mortgage account number?",
        label: "Account Number",
        name: "accountNumber",
        answer: {
            type: "text",
        },
        // hides: (form) => {
        //     return form.getFieldValue('isMortgaged') !== true
        // },
        model: "properties",
        rules: [
            {required: true, message: `An account number is required`},
        ],
        target: `properties[${props.index}].accountNumber`
    }
}
export function monthlyPayment(props) {
    return {
        text: "What are the monthly repayments?",
        label: "Monthly Repayments",
        name: "monthlyPayment",
        answer: {
            type: "euro",
        },
        // hides: (form) => {
        //     return form.getFieldValue('isMortgaged') !== true
        // },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 50000, message: "The maximum value here is €50,000"}
        ],
        target: `properties[${props.index}].monthlyPayment`
    }
}
export function currentRate(props) {
    return {
        text: "What is your current rate?",
        label: "Current Rate",
        name: "currentRate",
        answer: {
            type: "float",
        },
        // hides: (form) => {
        //     return form.getFieldValue('isMortgaged') !== true
        // },
        model: "properties",
        rules: [
            {required: true, message: `A rate is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 15, message: "The maximum value here is 15"}
        ],
        target: `properties[${props.index}].currentRate`
    }
}
export function outstanding(props) {
    return {
        label: "Mortgage Balance",
        text: "What is the balance of the mortgage on this property?",
        name: "outstanding",
        answer: {
            type: "euro",
        },
        // hides: (form) => {
        //     return form.getFieldValue('isMortgaged') !== true
        // },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 2000000, message: "The maximum value here is €2,000,000"}
        ],
        target: `properties[${props.index}].outstanding`
    }
}
export function bookingDepositPaid(props){
    return {
        label: "Booking Deposit",
        text: "How much was the deposit you paid?",
        name: "bookingDepositPaid",
        answer: {
            type: "euro",
        },
        model: "property",
        target: `properties[${props.index}].bookingDepositPaid`
    }
}
export function contractDepositPaid(props){
    return {
        label: "Contract Deposit",
        text: "How much was the deposit you paid?",
        name: "contractDepositPaid",
        answer: {
            type: "euro",
        },
        optional: true,
        model: "property",
        target: `properties[${props.index}].contractDepositPaid`
    }
}
export const mortgagePropertiesSteps=(props)=>{
    const property = props.mortgage.properties.find(item=>item.id === props.index)

    if ([MortgageTypes.REFINANCE].includes(props.mortgage.mortgageType)){

        return [
            {
                label: 'Property Details',
                title: propertyTitle(property),
                index: props.index,
                questions: [
                    residingHere(props),
                    address(props),
                    propertyType(props),
                    propertyValue(props),
                    yearOfPurchase(props),
                    origPurchasePrice(props),
                    // currentMortgageProvider(props),
                ]
            },
            {
                label: 'Legal Claim',
                title: `Tell us about anyone other than family who will occupy the property`,
                questions: [
                    adultsBoarding(props),
                    adultsBoardingCount(props)
                ]
            },
            {
                label: 'Current Mortgage',
                title: `Tell us the current mortgage`,
                questions: [
                    remainingTerm(props),
                    lender(props),
                    accountNumber(props),
                    monthlyPayment(props),
                    currentRate(props),
                    outstanding(props),
                ]
            }
        ]
    }
    return [
        {
            label: 'Property Details',
            title: propertyTitle(property),
            index: props.index,
            questions: [
                propertyType(props),
                address(props),
                newlyBuilt(props),
                siteOwned(props),
                siteValue(props),
                constructionCosts(props),
                constructionStarted(props),
                propertyValue(props),
                bedrooms(props),
                bathrooms(props),

            ]
        },
        {
            label: 'Legal Claim',
            title: `Tell us about anyone other than family who will occupy the property`,
            questions: [
                adultsBoarding(props),
                adultsBoardingCount(props)
            ]
        }
    ]
}
export const mortgageProperties = ({mortgage, applicant}) => {
    return mortgage.properties.filter(property => !property._deleted).map(property => ({
        to: property.id,
        index: property.id,
        title: 'PROPERTY',
        subtitle: wordLimit(property.address) || 'New Property',
        footer: `€${property.propertyValue?.toLocaleString('en-us') || 0}`,
        delete: {delete: `properties`, id: property.id}
    }))
}
function MortgageProperties(props) {
    const steps = mortgagePropertiesSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    const repeater = `properties`
    return <SectionForm title="Mortgaging Property"  repeater={repeater} key={`MortgageProperties${props.index}`} {...props} questions={questions}/>
}

export default MortgageProperties;