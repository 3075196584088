import React, {useEffect, useMemo, useRef, useState} from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {Alert, Avatar, Button, Card, Checkbox, Collapse, ConfigProvider, Descriptions, Form, Input, InputNumber, Popover, Segmented, Select, Tabs, Tag} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../../../assets/scripts/parser-functions";
import {ArrowRightOutlined, CalendarOutlined, ExclamationCircleOutlined, FileTextOutlined, LoadingOutlined, SaveOutlined} from "@ant-design/icons";
import {balanceOfFundsFunds} from "../../funding/BalanceOfFunds";
import {ApplicationTypes, BoolLike, MortgageTypes} from "../../../../../../../models";
import {usePtsbXlsNew} from "../ptsb/usePtsbXls";
import {useHavenXlsNew} from "../haven/useHavenXls";
import {useAvantXlsNew} from "../avant/useAvantXls";
import {useIcsXlsNew} from "../ics/useIcsXls";
import QuestionTabs from "../_common/QuestionTabs";
import {blue, orange, red, green, magenta, limeDark, gray, volcano} from "@ant-design/colors";
import {calcMonthlyPayment} from "../../../../../../providers/mortgage/useRates";
import Funds from "../../verified-figures/balance-of-funds/Funds";
import Costs from "../../verified-figures/balance-of-funds/Costs";
import "./square-check.css"
import dayjs from "dayjs";
import ButtonAsync from "../../../../../elements/ButtonAsync";
import useSussdCoverNote from "../../../../../../../config-files/mortgage/documents/useSussdCoverNote";
import ButtonJumboAsync from "../../../../../elements/ButtonJumboAsync";
import selectLender from "../../../sign/parts/SelectLender";
import SubmissionWarnings from "./components/SubmissionWarnings";
import LenderTabs from "./components/LenderTabs";
import SegmentsMortgage from "./components/SegmentsMortgage";
import TestHistory from "./components/TestHistory";
import SegmentsLenders from "./components/SegmentsLenders";
import Differences from "./components/Differences";
import EditPRA from "./components/EditPRA";
import EditSalary from "./components/EditSalary";
import EditMortgage from "./components/EditMortgage";
import {useBoiXlsNew} from "../boi/useBoiXls";
import useNuaWebForm from "../nua/useNuaWebForm";
import {API} from "aws-amplify";

export const VerifiedLenderTestFiguresContext = React.createContext({})

export function VerifiedLenderTestFiguresProvider({children}) {
    const [form] = Form.useForm();
    // This is the form that should be loaded with figures which if tested will save to LenderTests
    const [state, setState] = useState({
        // for storing unsaved figures eg lti & tlv
        // currentTest    : {
        //     id     : null,
        //     data   : {
        //         figures: {},
        //         tests  : []
        //     },
        //     results: null
        // },
        currentTest    : null,
        ui             : {
            activeSegment: 'Funding',
        },
        selectedLenders: [
            'ptsb',
            'haven',
            'avant',
            'ics'
        ],
    })
    return <VerifiedLenderTestFiguresContext.Provider value={{
        form,
        state,
        setState
    }}>
        {children}
    </VerifiedLenderTestFiguresContext.Provider>
}

function VerifiedLenderTests(props) {
    const {
              form,
              state,
              setState
          } = React.useContext(VerifiedLenderTestFiguresContext)
    const [shouldSaveOutput,setShouldSaveOutput] = useState(false)
    const mortgage = useMortgage();
    // set rete for monthly payment
    let rate = 4.25
    // live figures (not saves as part of the test)
    const figures = mortgage.figures.verified
    let ownFunds = mortgage.figures.verified.funds1.total
    if (mortgage.twoApplicants && mortgage.figures.verified.funds2) {
        ownFunds += mortgage.figures.verified.funds2.total
    }

    const noCurrentSubmission = !mortgage.activeSubmission || !mortgage.activeSubmission.lenderCode || !mortgage.activeSubmission.provisionalXlsOutput
    const lenders = [
        {
            key  : 'ptsb',
            label: 'PTSB',
            api  : usePtsbXlsNew(),
        },
        {
            key  : 'haven',
            label: 'Haven',
            api  : useHavenXlsNew(),
        },
        {
            key  : 'avant',
            label: 'Avant',
            api  : useAvantXlsNew(),
        },
        {
            key  : 'ics',
            label: 'ICS',
            api  : useIcsXlsNew(),
        },
        {
            key  : 'boi',
            label: 'BOI',
            api  : useBoiXlsNew(),
        },
        {
            key  : 'nua',
            label: 'Nua',
            api  : useNuaWebForm()
        }
    ]
    const onChangeTimer = useRef()

    // UI HANDLERS
    const onFinish = () => {
        setState(s => {
            return {
                ...s,
                currentTest: {
                    ...s.currentTest,
                    id     : null,
                    data   : {
                        ...s.currentTest.data,
                        tests: {}
                    },
                    results: {}
                }
            }
        })
        const figures = state.currentTest.data.figures
        const testPromise = async (lender) => {
            const api = lenders.find(e => e.key === lender).api
            console.log({api})
            const config = api.getConfig(figures, mortgage.twoApplicants)
            console.log({config})
            const test = {
                overrides: {},
                sent     : config,
            }
            const results = {
                loading         : true,
                passed          : null,
                review: null,
                failed          : false,
                processedResults: null,
                results         : null,
            }
            setState(s => ({
                ...s,
                currentTest: {
                    ...s.currentTest,
                    data   : {
                        ...s.currentTest.data,
                        tests: {
                            ...s.currentTest.data.tests,
                            [lender]: test
                        }
                    },
                    results: {
                        ...s.currentTest.results,
                        [lender]: results
                    }
                }
            }))
            try {
                console.log({api})
                let {processedResults, spreadsheetUrl} = await api.test(config, shouldSaveOutput)
                console.log({processedResults})
                let passed = processedResults.every(item => !item.hasOwnProperty('passed') || item.passed)
                let review = processedResults.some(item => item.hasOwnProperty('review') && item.review)
                setState(s => {
                    return {
                        ...s,
                        currentTest: {
                            ...s.currentTest,
                            results: {
                                ...s.currentTest.results,
                                [lender]: {
                                    loading: false,
                                    passed,
                                    review,
                                    failed : false,
                                    items  : processedResults,
                                    spreadsheetUrl
                                }
                            }
                        }
                    }
                })
                return {
                    lender,
                    test,
                    result: {
                        passed,
                        review,
                        items: processedResults,
                        spreadsheetUrl
                    }
                }
            }
            catch (e) {
                setState(s => ({
                    ...s,
                    currentTest: {
                        ...s.currentTest,
                        results: {
                            ...s.currentTest.results,
                            [lender]: {
                                loading: false,
                                passed : false,
                                review: false,
                                failed : e.message,
                                items  : null,
                            }
                        }
                    }
                }))
                return {
                    lender,
                    test,
                    result: {
                        passed: false,
                        review: false,
                        failed: e.message,
                        items : null,
                    }

                }
            }
        }
        let promises = state.selectedLenders.map(lender => testPromise(lender))
        console.log({figures})
        Promise.all(promises).then(testPromiseResult => {

            let allData = {
                figures,
                tests: {}
            }
            let allResults = {}

            testPromiseResult.forEach(lenderResult => {
                console.log({lenderResult})
                const {
                          lender,
                          result,
                          test
                      } = lenderResult
                allData.tests[lender] = test
                allResults[lender] = result
            })
            let updateToDO =  mortgage?.activeSubmission?.id ? mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('signingAffordabilityTested', mortgage?.activeSubmission?.id) : []
            mortgage.mutate({
                create: {
                    target: 'playgroundTests',
                    pairs : {
                        mortgageID: mortgage.id,
                        data      : JSON.stringify(allData),
                        results   : JSON.stringify(allResults)
                    }
                },
                update: updateToDO
            }).then(mutations => {
                setState(s => ({
                    ...s,
                    currentTest: {
                        ...s.currentTest,
                        id: mutations[0].id
                    }
                }))
            })

        })

    };
    const onLoadLenderTest = (id) => {
        console.log(id)
        const lenderTest = getLenderTestById(id)

        if (lenderTest) {
            form.setFieldsValue(lenderTest.data.figures)
            setState(s => {
                return {
                    ...s,
                    currentTest    : {
                        id     : lenderTest.id,
                        data   : lenderTest.data,
                        results: lenderTest.results
                    },
                    selectedLenders: Object.keys(lenderTest.data.tests),
                }
            })
        }

    }
    const onResetToSubmission = () => {
        console.log('resetting to submission')
        onLoadLenderTest(mortgage.activeSubmission.provisionalXlsOutput.testId)
    }
    const onResetToLive = () => {
        form.setFieldsValue(liveFigures)
        setState(s => {
            return {
                ...s,
                currentTest: {
                    id     : null,
                    data   : {
                        figures: liveFigures,
                        tests  : {}
                    },
                    results: null
                }
            }
        })
    }
    const onValuesChange = (changedValues, allValues) => {
        // update the value is state and remove the current test id
        if (onChangeTimer.current) {
            clearTimeout(onChangeTimer.current)
        }
        onChangeTimer.current = setTimeout(() => {
            setState(s => {
                return {
                    ...s,
                    currentTest: {
                        id     : null,
                        data   : {
                            ...s.currentTest.data,
                            figures: allValues
                        },
                        results: null
                    },
                }
            })
        }, 700)
    }

    // FUNCTIONS
    // add totals to figures
    const setActiveSegment = key => {
        setState(s => {
            return {
                ...s,
                ui: {
                    ...s.ui,
                    activeSegment: key
                }
            }
        })
    }
    const expandFigures = (figures, twoApplicants) => {
        // add variable total
        const addTotals = (key) => {
            if (figures) {
                let total = figures[`${key}1`] || 0
                if (twoApplicants) {
                    total += figures[`${key}2`] || 0
                }
                figures[`${key}Total`] = total
            }
        }
        if (figures) {
            figures.variable1 = figures.overtime1 + figures.allowances1 + figures.other1 + figures.commission1 + figures.year1Bonus1
            if (twoApplicants) {
                figures.variable2 = figures.overtime2 + figures.allowances2 + figures.other2 + figures.commission2 + figures.year1Bonus2
            }
        }

        addTotals('variable')
        addTotals('savings')
        addTotals('accommodationCosts')
        addTotals('clearingLoans')
        addTotals('otherPRA')
        addTotals('basic')
        addTotals('other')
        addTotals('allowances')
        addTotals('overtime')
        addTotals('commission')
        addTotals('year1Bonus')
        addTotals('year2Bonus')
        addTotals('year3Bonus')
        addTotals('loans')
        addTotals('pension')
        addTotals('funds')

        // add ltv and lti
        if (figures) {
            figures.ltv = Math.round(figures.provisionalLoan / figures.provisionalValue * 1000) / 10
            figures.lti = Math.round(figures.provisionalLoan / (figures.basicTotal + figures.variableTotal) * 100) / 100
            figures.praTotal = figures.otherPRATotal + figures.accommodationCostsTotal + figures.clearingLoansTotal + figures.savingsTotal
            const {requiredSavings} = balanceOfFundsFunds(mortgage, {
                loan : figures.provisionalLoan,
                price: figures.provisionalValue,
            })
            figures.requiredSavings = requiredSavings
        }

        return figures
    }
    const getSubmissionFiguresById = (id) => {
        let lenderTest = mortgage.lenderTests.find(e => e.id === id)
        return lenderTest.data.figures
    }
    const getLenderTestById = (id) => {
        return mortgage.lenderTests.find(e => e.id === id)
    }
    const liveFigures = useMemo(() => {
        let initialValues = {
            provisionalLoan : !!mortgage?.activeSubmission?.provisionalLoan ? mortgage.activeSubmission.provisionalLoan : mortgage.requirement.loanRequired,
            provisionalTerm : !!mortgage?.activeSubmission?.provisionalTerm ? mortgage.activeSubmission.provisionalTerm : mortgage.requirement.loanTerm,
            provisionalValue: !!mortgage?.activeSubmission?.provisionalValue ? mortgage.activeSubmission.provisionalValue : mortgage.properties[0].propertyValue,

            sector1            : figures.sector1.value,
            basic1             : figures.basic1.total,
            allowances1        : figures.allowances1.total,
            other1             : figures.other1.total,
            commission1        : figures.commission1.total,
            overtime1          : figures.overtime1.total,
            year1Bonus1        : figures.year1Bonus1.total,
            year2Bonus1        : figures.year2Bonus1.total,
            year3Bonus1        : figures.year3Bonus1.total,
            pension1           : figures.pension1.total,
            otherPRA1          : figures.otherPRA1.total,
            accommodationCosts1: figures.accommodationCosts1.total,
            clearingLoans1     : figures.clearingLoans1.total,
            savings1           : figures.savings1.total,
            loans1             : figures.loans1.total,
            variable1          : figures.variable1.total,
        }
        if (mortgage.twoApplicants) {
            initialValues = {
                ...initialValues,
                sector2            : figures.sector2.value,
                basic2             : figures.basic2.total,
                allowances2        : figures.allowances2.total,
                other2             : figures.other2.total,
                commission2        : figures.commission2.total,
                overtime2          : figures.overtime2.total,
                year1Bonus2        : figures.year1Bonus2.total,
                year2Bonus2        : figures.year2Bonus2.total,
                year3Bonus2        : figures.year3Bonus2.total,
                pension2           : figures.pension2.total,
                otherPRA2          : figures.otherPRA2.total,
                accommodationCosts2: figures.accommodationCosts2.total,
                clearingLoans2     : figures.clearingLoans2.total,
                savings2           : figures.savings2.total,
                loans2             : figures.loans2.total,
                variable2          : figures.variable2.total,
            }
        }
        return expandFigures(initialValues, mortgage.twoApplicants)
    }, [figures])
    const submissionFigures = useMemo(() => {
        if (!noCurrentSubmission) {
            return expandFigures(mortgage.activeSubmission.provisionalXlsInput.figures, mortgage.twoApplicants)
        }
        return null
    }, [
        noCurrentSubmission,
        mortgage?.activeSubmission?.provisionalXlsOutput?.testId
    ])
    const timeoutRef = useRef(null);
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (!state.currentTest) {
            if (mortgage.activeSubmission?.provisionalXlsOutput) {
                onResetToSubmission()
            }
            else {
                timeoutRef.current = setTimeout(() => {
                    onResetToLive();
                }, 1000);
            }
        }
        return () => {
            clearTimeout(timeoutRef.current);
        }
    }, [liveFigures]);
    if (!state.currentTest) {
        return null
    }

    const currentTestFigures = expandFigures(state.currentTest.data.figures, mortgage.twoApplicants)

    return (

        <>
            <SubmissionWarnings mortgage={mortgage} noCurrentSubmission={noCurrentSubmission}/>
            <div className="d-col gap-15">
                {/*<Button onClick={async ()=>{*/}
                {/*    let update = mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('signingAffordabilityTested', mortgage.activeSubmission.id)*/}
                {/*    await mortgage.mutate({update})*/}
                {/*}}>Dismiss Verify Lender Test</Button>*/}
                <Form
                    form={form}
                    requiredMark={false}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                >
                    <div className="d-col gap-15">
                        <div className={"d-row gap-15 j-between a-end"}>
                            <div className="d-col gap-3">
                                <SegmentsMortgage setActiveSegment={setActiveSegment} activeSegment={state.ui.activeSegment} mortgage={mortgage} rate={rate} figures={currentTestFigures}/>
                                {mortgage.lenderTests.length > 0 &&
                                    <TestHistory submissionTestId={mortgage.activeSubmission ? mortgage.activeSubmission.provisionalXlsOutput?.testId : false} lenders={lenders}
                                                 currentTestId={state.currentTest.id} mortgage={mortgage}
                                                 onLoadLenderTest={onLoadLenderTest}/>}
                            </div>

                            <div className="d-col gap-3">
                                <div className={"d-row gap-15"}>
                                    <Button block onClick={onResetToLive}>
                                        Live Figures
                                    </Button>
                                    {mortgage?.activeSubmission?.provisionalXlsOutput && <Button block onClick={onResetToSubmission}>
                                        Submission Figures
                                    </Button>}
                                </div>

                                <SegmentsLenders mortgage={mortgage} state={state} setState={setState} lenders={lenders} setActiveSegment={setActiveSegment}/>

                                <div className="d-row j-equal mx-27 gap-6">

                                    <Button htmlType="submit" block>
                                        Test
                                    </Button>
                                    <div className="d-row a-center">
                                        <Checkbox onClick={()=>setShouldSaveOutput(!shouldSaveOutput)} checked={shouldSaveOutput}>Save Output</Checkbox>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <Differences currentTestFigures={currentTestFigures} liveFigures={liveFigures} submissionFigures={submissionFigures}/>
                        <div>
                            <Card title="PRA" style={{display: state.ui.activeSegment === 'PRA' ? 'block' : 'none'}}>
                                <EditPRA rate={rate} mortgage={mortgage} figures={currentTestFigures}/></Card>
                            <Card title="Salary" style={{display: state.ui.activeSegment === 'Salary' ? 'block' : 'none'}}>
                                <EditSalary mortgage={mortgage} figures={currentTestFigures}/></Card>
                            <Card title="Mortgage Test" style={{display: state.ui.activeSegment === 'Funding' ? 'block' : 'none'}}>
                                <EditMortgage loan={currentTestFigures.provisionalLoan} value={currentTestFigures.provisionalValue}/>
                            </Card>
                        </div>
                    </div>

                </Form>
                <div>
                    <Card style={{display: state.ui.activeSegment === 'ptsb' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="PTSB" lenderCode="ptsb" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                    <Card style={{display: state.ui.activeSegment === 'haven' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="Haven" lenderCode="haven" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                    <Card style={{display: state.ui.activeSegment === 'avant' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="Avant" lenderCode="avant" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                    <Card style={{display: state.ui.activeSegment === 'ics' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="ICS" lenderCode="ics" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                    <Card style={{display: state.ui.activeSegment === 'boi' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="BOI" lenderCode="boi" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                    <Card style={{display: state.ui.activeSegment === 'nua' ? 'block' : 'none'}}>
                        <LenderTabs mortgage={mortgage} lenders={lenders} lenderName="Nua" lenderCode="nua" currentTest={state.currentTest} noCurrentSubmission={noCurrentSubmission}/></Card>
                </div>
                {/*<TestQueuer lenders={lenders.map(l=>l.key)} mortgageID={mortgage.id}/>*/}
            </div>
        </>);

}


function TestQueuer({lenders, mortgageID}){
    console.log({lenders})
    const [form] = Form.useForm();

    const onFinish = async (values) => {
       console.log({values})
        const data = {
            twoApplicants: true,
            numberInHousehold: 4,
            numberOfAdultsBoarding: 0,
            numberOfDependentChildren:2,
            dependents:[{age: 13},{age: 11}],
            applicant1: {
                works: true,
                maritalStatus: 'MARRIED',
                age: 32,
                jobs:[
                    {
                        sector: 'PUBLIC',
                        basic: {
                            yearMostRecent:130000,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:true,
                        },
                        lastP60: 130000,
                        yearlyAVCContribution: 1000,
                        yearlyPensionContribution:1000,
                        allowances:{
                            yearMostRecent:0,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:false,
                        },
                        overtime:{
                            yearMostRecent:0,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:false,
                        },
                        bonus:{
                            yearMostRecent:0,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:false,
                        },
                        commission:{
                            yearMostRecent:0,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:false,
                        },
                        other:{
                            yearMostRecent:0,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:false,
                        },
                    }
                ],
                pra:{
                    rent: 2500,
                    mortgage: 0,
                    savings:2200,
                    clearingLoans: 0,
                    other: 0,
                },
                monthlyOutgoings:{
                    loans: 100,
                    maintenance: 0,
                    childcare: 300,
                },
                creditFacilityLimits:{
                    creditCards: 0,
                    storeCards: 0,
                    overdrafts: 0
                },
                portfolio:[
                    // {
                    //     isMortgaged: false,
                    //     outstandingMortgage: 0,
                    //     rate: 0,
                    //     rateFixedYears: 5,
                    //     tracker: false,
                    //     interestOnly: false,
                    //     remainingTermInMonths: 0,
                    //     yearlyRentalIncome: 0,
                    //     propertyValue: 0,
                    //     foreign: false,
                    //     nonStandard: false,
                    //     numberOfBedrooms: 3,
                    //
                    // }
                ],
            },
            applicant2: {
                works: true,
                maritalStatus: 'MARRIED',
                age: 32,
                jobs:[
                    {
                        sector: 'PRIVATE',
                        basic: {
                            yearMostRecent:111000,
                            yearBefore: 0,
                            yearBeforeThat: 0,
                            guaranteed:true,
                        },
                        lastP60: 110000,
                        yearlyAVCContribution: 0,
                        yearlyPensionContribution:0,
                    }
                ],
                pra:{
                    rent: 2500,
                    savings:2200,
                },
                monthlyOutgoings:{
                    loans: 0,
                    maintenance: 0,
                    childcare: 0,
                },
                creditFacilityLimits:{
                    creditCards: 0,
                    storeCards: 0,
                    overdrafts: 0
                },
                portfolio:[
                ],
            },
            // existing:{ // Movers
            //     nonTrackerMortgageAmount:0,
            //     trackerMortgageAmount:0,
            //     tracker: false,
            //     propertyValue:0,
            //     outstandingMortgage:0,
            //     remainingTermInMonths:0,
            //     retaining: false,
            //     rate: 0,
            //     rateFixedYears: 5,
            //     numberOfBedrooms: 3,
            // },

            selfBuild: false,
            // selfBuild:{
            //     costToBuild: 0,
            //     siteValue   :0,
            //
            // },
            deposit: 0, // for movers only
            mortgageType: 'PURCHASE',
            loanRequired: 320000,
            termRequired: 30,
            propertyValue: 650000,
            propertyType: 'DETACHED',
            applicationType: 'FIRSTTIME',
            fixedRateYears: 3,
            berScore: 'A1',
            propertyIdentified: false,
            oneBedProperty: false,
        }

        const postData = {
            mortgageID,
            fileName: 'my-auto-test.xls',
            lenders: values.lenderCodes,
            data
        }
        console.log({postData})
        const result = await API.post('sussdUserAPI','/tests/mortgage',{
            body: postData
        })
        console.log({
            result
        });
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
                name="lenderCodes"
                label="Select ENUMs"
                rules={[{ required: true, message: 'Please select at least one ENUM!' }]}
            >
                <Select mode="multiple" placeholder="Select ENUMs">
                    {lenders.map((enumValue) => (
                        <Select.Option key={enumValue} value={enumValue}>
                            {enumValue.toUpperCase()}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}
export default VerifiedLenderTests;

// const onValuesChangeHandler = (changedValues, allValues) => {
//
//     const updateSalaryChange = () => {
//         let totals = {};
//         [
//             'basic',
//             'allowances',
//             'other',
//             'commission',
//             'overtime',
//             'year1Bonus',
//             'year2Bonus',
//             'year3Bonus',
//             'accommodationCosts',
//             'clearingLoans',
//             'savings',
//             'otherPRA',
//             'pension',
//             'loans',
//         ].forEach(key => {
//             let total = 0
//             if (allValues[`${key}1`]) {
//                 total += allValues[`${key}1`]
//             }
//             if (allValues[`${key}2`]) {
//                 total += allValues[`${key}2`]
//             }
//             totals[key] = total
//             if (allValues[`${key}Total`] !== total) {
//                 form.setFieldValue(`${key}Total`, total)
//             }
//         })
//         let variableTotal = 0;
//         let praTotal = 0;
//         [
//             'allowances',
//             'other',
//             'commission',
//             'overtime',
//             'year1Bonus'
//         ].forEach(key => {
//             variableTotal += totals[key] || 0
//         });
//         [
//             'otherPRA',
//             'accommodationCosts',
//             'clearingLoans',
//             'savings'
//         ].forEach(key => {
//             praTotal += totals[key] || 0
//         })
//         form.setFieldValue('variableTotal', variableTotal)
//         form.setFieldValue('praTotal', praTotal)
//         let lti = Math.round(allValues.provisionalLoan / (totals.basic + variableTotal) * 100) / 100
//         form.setFieldValue('lti', lti)
//         let cost = calcMonthlyPayment(allValues.provisionalLoan, allValues.provisionalTerm, rate)
//         form.setFieldValue('monthlyCost', cost)
//     }
//     const updateLenderSpecificForms = async () => {
//         let values = await form.getFieldsValue()
//         let betterValues = {
//             ...initialValues, ...values,
//             term          : values.provisionalTerm,
//             propertyValue : values.provisionalValue,
//             mortgageAmount: values.provisionalLoan
//         }
//
//         lenders.forEach(lender => {
//             if (state.selectedLenders.includes(lender.key)) {
//                 if (lender.api.hasOwnProperty('setForm')) {
//                     lender.api.setForm(betterValues)
//                 }
//
//             }
//         })
//     }
//     updateSalaryChange()
//     updateLenderSpecificForms()
// };
//
// const onTestHistorySelect = (value) => {
//
//     let selected = mortgage.lenderTests.find(e => e.id === value)
//
//     let test = {
//         id     : selected.id,
//         data   : selected.data,
//         results: selected.results
//     }
//
//     test.data.figures.variable1
//         = selected.data.figures.allowances1 + selected.data.figures.other1 + selected.data.figures.commission1 + selected.data.figures.overtime1 + selected.data.figures.year1Bonus1
//     if (mortgage.twoApplicants) {
//         test.data.figures.variable2
//             = selected.data.figures.allowances2 + selected.data.figures.other2 + selected.data.figures.commission2 + selected.data.figures.overtime2 + selected.data.figures.year1Bonus2
//     }
//
//     form.setFieldsValue(test.data.figures)
//     onValuesChangeHandler(test.data.figures, test.data.figures)
//
//     setCurrentTest(test)
// }

//UI BUILDERS
// const lenderOptionsOldCanDelete = lenders.map(lender => {
//     let myCurrentTest = currentTest?.data?.tests?.[lender.key]
//     let myCurrentResults = currentTest?.results?.[lender.key]
//     let loading = myCurrentResults?.loading
//     let failed = myCurrentResults?.failed
//     let passed = myCurrentResults?.passed
//     let selected = state.selectedLenders.includes(lender.key)
//
//     //loading= true
//     let icon = null
//     let avatarStyle = {backgroundColor: b}
//     let checkboxToken = {
//         controlInteractiveSize: 30,
//         borderRadiusSM        : 15
//     }
//
//     let segmentStyleProps = {}
//     if (loading) {
//         icon = <LoadingOutlined/>
//         avatarStyle = {backgroundColor: gr}
//         checkboxToken.colorBgContainer = gr
//         checkboxToken.colorBorder = gr
//         checkboxToken.colorPrimary = gr
//         checkboxToken.colorPrimaryBorder = gr
//
//         // colorBorder: blue[4],
//         // colorPrimary: blue[4],
//         // colorPrimaryBorder: blue[4],
//     }
//     else {
//         if (!selected) {
//             avatarStyle = {backgroundColor: gr}
//         }
//         else {
//             avatarStyle = {backgroundColor: b}
//             if (failed) {
//                 segmentStyleProps = {
//                     style: {
//                         backgroundColor: r
//                     }
//                 }
//                 avatarStyle = {backgroundColor: r}
//                 checkboxToken.colorBgContainer = r
//                 checkboxToken.colorBorder = r
//                 checkboxToken.colorPrimary = r
//                 checkboxToken.colorPrimaryBorder = r
//             }
//             else if (passed) {
//                 avatarStyle = {backgroundColor: g}
//                 checkboxToken.colorBgContainer = g
//                 checkboxToken.colorBorder = g
//                 checkboxToken.colorPrimary = g
//                 checkboxToken.colorPrimaryBorder = g
//             }
//             else if (myCurrentTest && !passed) {
//                 avatarStyle = {backgroundColor: r}
//                 checkboxToken.colorBgContainer = r
//                 checkboxToken.colorBorder = r
//                 checkboxToken.colorPrimary = r
//                 checkboxToken.colorPrimaryBorder = r
//             }
//
//         }
//         icon = (<ConfigProvider
//             theme={{
//                 token: checkboxToken,
//             }}
//         >
//             <Checkbox className="square-check" style={{padding: 8}} checked={state.selectedLenders.includes(lender.key)} onChange={e => {
//                 if (e.target.checked) {
//                     setState(s => {
//                         return {
//                             ...s,
//                             selectedLenders: [
//                                 ...s.selectedLenders,
//                                 lender.key
//                             ]
//                         }
//                     })
//                 }
//                 else {
//                     setState(s => {
//                         return {
//                             ...s,
//                             selectedLenders: s.selectedLenders.filter(key => key !== lender.key)
//                         }
//                     })
//                 }
//
//             }}/>
//
//         </ConfigProvider>)
//     }
//     return {
//         label: (<div style={{padding: 8}}>
//             <Avatar size="large" style={avatarStyle} icon={icon}/>
//             <div>{lender.label}</div>
//         </div>),
//         value: lender.key, ...segmentStyleProps
//     }
// })
// const segmentedSectionsOldCanDelete = [
//     {
//         label: (<div style={{margin: '0 10px'}}>
//
//
//             <Form.Item noStyle
//                        shouldUpdate={(prevValues, currentValues) => prevValues.lti !== currentValues.lti || prevValues.ltv !== currentValues.ltv || prevValues.provisionalLoan !== currentValues.provisionalLoan || prevValues.provisionalValue !== currentValues.provisionalValue || prevValues.provisionalTerm !== currentValues.provisionalTerm}>
//                 {({getFieldValue}) => {
//                     let loan = getFieldValue('provisionalLoan')
//                     let price = getFieldValue('provisionalValue')
//                     // const {requiredSavings} = balanceOfFundsFunds(mortgage, {
//                     //     loan,
//                     //     price
//                     // })
//                     // let mismatch = ownFunds - requiredSavings
//                     // let hasError = mismatch < 0
//
//                     let htbNotSet = mortgage.applicationType === ApplicationTypes.FIRSTTIME && mortgage.requirement.helpToBuyScheme !== BoolLike.FALSE && !mortgage.requirement.helpToBuySchemeAmount
//                     let hasWarning = !!htbNotSet
//
//                     let ltv = Math.round(loan / price * 1000) / 10
//                     let lti = getFieldValue('lti')
//                     let factor = 3.5
//                     if (mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME) {
//                         factor = 4
//                     }
//                     let hasError = ltv > 90 || lti > factor || loan < 150000
//
//                     return (<>
//                         <div className="d-row a-center gap-3">
//                             <Avatar size="small" style={{backgroundColor: hasError ? red[4] : hasWarning ? orange[4] : green[4]}}></Avatar>
//                             <h4 className="m-0">Mortgage</h4>
//                         </div>
//
//                         <table style={{textAlign: 'left'}} cellPadding={0} cellSpacing={3}>
//                             <tbody>
//                             <tr>
//                                 <td>LTV</td>
//                                 <td>
//                                     {ltv > 90 ? <span className="text-danger">{ltv} %</span> : <span className="text-success">{ltv} %</span>}
//                                 </td>
//                                 <td></td>
//                                 <td>
//                                     Loan
//                                 </td>
//                                 <td style={{textAlign: 'right'}}>
//                                     {loan < 150000 ? <span className="text-danger">{loan?.toEuro()}</span> : loan?.toEuro()}
//                                 </td>
//
//                             </tr>
//                             <tr>
//                                 <td>LTI</td>
//                                 <td>
//                                     {lti > factor ? <span className="text-danger">{lti}</span> : <span className="text-success">{lti}</span>}
//                                 </td>
//                                 <td></td>
//                                 <td>{mismatch < 0 ? <>Short Funds </> : <>Excess Funds</>}</td>
//                                 <td style={{textAlign: 'right'}}>
//                                     {mismatch < 0 ? <span className="text-danger">{Math.abs(mismatch)?.toEuro()} </span> : <span className="text-success">{mismatch?.toEuro()}</span>}
//                                 </td>
//                             </tr>
//                             {htbNotSet && (<tr>
//                                 <td colSpan={5}>
//                                     <span style={{color: orange[5]}}>Help to Buy not set!</span>
//                                 </td>
//                             </tr>)}
//                             </tbody>
//                         </table>
//                     </>)
//                 }}
//             </Form.Item>
//
//
//         </div>),
//         value: 'Funding'
//     },
//     {
//         label: (<div style={{margin: '0 10px'}}>
//
//             <Form.Item noStyle
//                        shouldUpdate={(prevValues, currentValues) => (prevValues.basicTotal !== currentValues.basicTotal) || (prevValues.variableTotal !== prevValues.variableTotal)}>
//                 {({getFieldValue}) => {
//                     let totalBasic = getFieldValue('basicTotal')
//                     let totalVariable = getFieldValue('variableTotal')
//                     let basicWarning = mortgage.figures.verified.basic1.warning || mortgage.figures.verified?.basic2?.warning
//                     let warning = basicWarning || totalVariable > 0
//                     let danger = !totalBasic
//                     return (<>
//                         <div className="d-row a-center gap-3">
//                             <Avatar size="small" style={{backgroundColor: danger ? red[4] : warning ? orange[4] : green[4]}}></Avatar>
//                             <h4 className="m-0">Salary</h4>
//                         </div>
//
//                         <table style={{textAlign: 'left'}} className="m-0">
//                             <tbody>
//                             <tr>
//                                 <td>
//                                     Basic
//                                 </td>
//                                 <td>
//                                     {totalBasic ? <span className="text-success">{totalBasic.toEuro()}</span> : <span className="text-danger">No Basic!</span>}
//
//                                 </td>
//                             </tr>
//                             {totalVariable > 0 && (<tr>
//                                 <td style={{color: orange[5]}}>Variable</td>
//                                 <td style={{color: orange[5]}}>
//                                     {totalVariable?.toEuro()}
//                                 </td>
//                             </tr>)}
//                             {basicWarning && (<tr>
//                                 <td colSpan={2}>
//                                     <span style={{color: orange[5]}}>Unconfirmed Basic!</span>
//                                 </td>
//                             </tr>)}
//                             </tbody>
//                         </table>
//                     </>)
//                 }}
//             </Form.Item>
//         </div>),
//         value: 'Salary'
//     },
//     {
//         label: (<div style={{margin: '0 10px'}}>
//
//
//             <Form.Item noStyle
//                        shouldUpdate={(prevValues, currentValues) => prevValues.praTotal !== currentValues.praTotal || prevValues.monthlyCost !== currentValues.monthlyCost}>
//                 {({getFieldValue}) => {
//                     let praTotal = getFieldValue('praTotal')
//                     let monthlyCost = getFieldValue('monthlyCost')
//                     let excess = praTotal - monthlyCost
//                     let error = excess < 0
//                     return (<>
//                         <div className="d-row a-center gap-3">
//                             <Avatar size="small" style={{backgroundColor: error ? red[4] : green[4]}}></Avatar>
//                             <h4 className="m-0">PRA</h4>
//                         </div>
//                         <table style={{textAlign: 'left'}} className="m-0">
//                             <tbody>
//                             <tr>
//                                 <td>
//                                     Total
//                                 </td>
//                                 <td>
//                                     <span className="text-success">{praTotal?.toEuro()}</span>
//                                 </td>
//                             </tr>
//                             {error && (<tr>
//                                 <td>
//                                     Deficit
//                                 </td>
//                                 <td>
//                                     <span className="text-danger">{Math.abs(excess)?.toEuro()}</span>
//                                 </td>
//                             </tr>)}
//                             </tbody>
//                         </table>
//                     </>)
//                 }}
//             </Form.Item>
//         </div>),
//         value: 'PRA'
//     }
// ]

// UI COMPONENTS
// const LoadLenderTestSelectOldCanDelete = () => {
//     function Label({test}) {
//         let date = dayjs(test.createdAt)
//         let dateString
//         if (date.isAfter(dayjs().subtract(1, 'hour'))) {
//             dateString = date.fromNow()
//         }
//         else {
//             dateString = date.format('D MMM YYYY HH:mm')
//         }
//
//         let lenderResults = test.results
//         let lenderData = test.data
//         if (!lenderResults || !lenderData) {
//             return <div>{dateString}</div>
//         }
//         return (<div className="d-row a-center gap-15">
//             <div style={{
//                 width     : 125,
//                 flexShrink: 0
//             }}>{dateString}</div>
//             <div style={{
//                 width     : 60,
//                 flexShrink: 0
//             }}><Tag color='default'>{lenderData.figures.mortgageAmount?.toEuro()}</Tag></div>
//             <div className="d-row a-center gap-6">
//
//                 {lenders.flatMap(lender => {
//                     let result = lenderResults[lender.key]
//                     let color = 'default'
//                     if (!!result) {
//                         color = result.passed ? 'green' : 'red'
//                     }
//                     return [
//                         <Tag key={lender.key} color={color}>{lender.label}</Tag>
//
//                     ]
//                 })}
//             </div>
//         </div>)
//     }
//
//     return (<Select
//         placeholder="Test History"
//         onSelect={onTestHistorySelect}
//         style={{width: '100%'}}
//         value={currentTest.id}
//         options={mortgage.lenderTests.sort((a, b) => {
//             return new Date(b.createdAt) - new Date(a.createdAt)
//         }).map(test => {
//             return {
//                 label: <Label test={test}/>,
//                 value: test.id
//             }
//         })}/>)
// }
// const DifferenceToDefault = () => {
//     const [tableOpen, setTableOpen] = useState(false)
//     let differences = []
//     if (Object.keys(currentTest).length > 0) {
//         Object.keys(initialValues).map(key => {
//             let value = currentTest?.data?.figures?.[key]
//             if (value !== initialValues[key]) {
//                 differences.push({
//                     key,
//                     label       : key,
//                     initialValue: initialValues[key],
//                     currentValue: value
//                 })
//             }
//         })
//     }
//     if (differences.length === 0) {
//         return null
//     }
//     let table = (<div>
//         <Descriptions column={1} bordered size="small" items={differences.map(item => {
//             let name = item.label
//             if (name === 'provisionalLoan') {
//                 name = 'Loan Amount'
//             }
//             if (name === 'provisionalTerm') {
//                 name = 'Term'
//             }
//             if (name === 'provisionalValue') {
//                 name = 'Property Value'
//             }
//             return {
//                 key     : item.key,
//                 label   : name,
//                 children: (<div className="d-row a-center gap-15">
//                     <span>{item.initialValue}</span>
//                     <ArrowRightOutlined/>
//                     <span>{item.currentValue}</span>
//                 </div>)
//             }
//         })}/>
//     </div>)
//     return (<Collapse size="small">
//             <Collapse.Panel showArrow={false} header={`${differences.length} differences detected`} key="1">
//                 {table}
//             </Collapse.Panel>
//         </Collapse>
//
//     )
//
// }
// const MoreOptions = ({
//     id,
//     lender,
//     results,
// }) => {
//     const saveActiveSubmission = async () => {
//         console.log({
//             id,
//             id2: currentTest.id
//         })
//         if (!currentTest.id) {
//             return
//         }
//
//         let test = mortgage.lenderTests.find(e => e.id === id)
//         let myResult = {
//             ...test.results[lender],
//             testId: id,
//         }
//         //TODO Correct figures with overrides (not collected currently)
//         let myInput = {
//             figures: test.data.figures,
//             test   : test.data.tests[lender]
//         }
//         let mutation = {}
//         // If no active submission, create one
//         let cfiAdminMutation = mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('affordabilityTested', 'fake-submission-id')
//         let formData = {
//             lenderCode                : lender,
//             provisionalLoan           : myInput.figures.provisionalLoan,
//             provisionalTerm           : myInput.figures.provisionalTerm,
//             provisionalValue          : myInput.figures.provisionalValue,
//             provisionalXlsOutput      : JSON.stringify(myResult),
//             provisionalXlsInput       : JSON.stringify(myInput),
//             completedFlowItemsForAdmin: cfiAdminMutation.value,
//         }
//         if (!mortgage.activeSubmission) {
//             mutation.create = [
//                 {
//                     target: 'submissions',
//                     pairs : {
//                         ...formData,
//                         mortgageID: mortgage.id,
//                     }
//                 }
//             ]
//         }
//         else {
//             mutation.update = [
//                 {
//                     target: `submissions[${mortgage.activeSubmission.id}]`,
//                     pairs : {
//                         ...formData,
//                     }
//                 }
//             ]
//         }
//         await mortgage.mutate(mutation)
//     }
//     const updateActiveSubmissionFigures = async () => {
//         console.log({
//             id,
//             id2: currentTest.id
//         })
//         if (!currentTest.id) {
//             return
//         }
//
//         let test = mortgage.lenderTests.find(e => e.id === id)
//         let myResult = {
//             ...test.results[lender],
//             testId: id,
//         }
//         //TODO Correct figures with overrides (not collected currently)
//         let myInput = {
//             figures: test.data.figures,
//             test   : test.data.tests[lender]
//         }
//
//         let formData = {
//             lenderCode          : lender,
//             provisionalLoan     : myInput.figures.provisionalLoan,
//             provisionalTerm     : myInput.figures.provisionalTerm,
//             provisionalValue    : myInput.figures.provisionalValue,
//             provisionalXlsOutput: JSON.stringify(myResult),
//             provisionalXlsInput : JSON.stringify(myInput),
//         }
//         let mutation = {}
//         mutation.update = [
//             {
//                 target: `submissions[${mortgage.activeSubmission.id}]`,
//                 pairs : {
//                     ...formData,
//                 }
//             }
//         ]
//         await mortgage.mutate(mutation)
//     }
//
//     const fakeSubmission = {
//         lender,
//         data  : {
//             figures: currentTest?.data?.figures,
//             test   : currentTest?.data?.tests?.[lender]
//         },
//         result: currentTest?.results?.[lender]
//     }
//     const {open: openTest2} = useSussdCoverNote({submission: fakeSubmission})
//     if (!results) {
//         return null
//     }
//     const items = results.map(item => {
//         return {
//             key     : item.key,
//             label   : item.label,
//             children: (<div className="d-row j-end a-center">
//                 {!!item.hasOwnProperty('passed') && (<span style={{
//                     marginRight    : 'auto',
//                     display        : "inline-block",
//                     backgroundColor: item.passed ? green.primary : volcano.primary,
//                     height         : 10,
//                     width          : 10,
//                     borderRadius   : 5
//                 }}/>)}
//                 <span style={{marginLeft: 8}}>{item.value}</span>
//             </div>)
//         }
//     })
//     const onLenderAndResultClick = async () => {
//         console.log({
//             id,
//             id2: currentTest.id
//         })
//         if (!currentTest.id) {
//             return
//         }
//
//         let test = mortgage.lenderTests.find(e => e.id === id)
//         let myResult = {
//             ...test.results[lender],
//             testId: id,
//         }
//         //TODO Correct figures with overrides (not collected currently)
//         let myInput = {
//             figures: test.data.figures,
//             test   : test.data.tests[lender]
//         }
//         let mutation = {}
//         // If no active submission, create one
//         let cfiAdminMutation = mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('affordabilityTested', 'fake-submission-id')
//         let formData = {
//             lenderCode                : lender,
//             provisionalLoan           : myInput.figures.provisionalLoan,
//             provisionalTerm           : myInput.figures.provisionalTerm,
//             provisionalValue          : myInput.figures.provisionalValue,
//             provisionalXlsOutput      : JSON.stringify(myResult),
//             provisionalXlsInput       : JSON.stringify(myInput),
//             completedFlowItemsForAdmin: cfiAdminMutation.value,
//         }
//         if (!mortgage.activeSubmission) {
//             mutation.create = [
//                 {
//                     target: 'submissions',
//                     pairs : {
//                         ...formData,
//                         mortgageID: mortgage.id,
//                     }
//                 }
//             ]
//         }
//         else {
//             mutation.update = [
//                 {
//                     target: `submissions[${mortgage.activeSubmission.id}]`,
//                     pairs : {
//                         ...formData,
//                     }
//                 }
//             ]
//         }
//         await mortgage.mutate(mutation)
//     }
//     const generateCoverNoteWithTheseFigures = () => {
//         openTest2()
//     }
//     let lenderName = lenders.find(e => e.key === lender).label
//
//     return (<div>
//
//         <div className="d-col mt-15 gap-15">
//             <div className="d-row a-center gap-9">
//                 <div className=" d-col gap-15">
//                     {(!noCurrentSubmission && mortgage.activeSubmission.lenderCode === lender) && (<Alert type="info" message={<div className="d-col gap-9">
//                         <div>Replace the figures on the current submission</div>
//                         <div>Doing this action will overwrite the existing figures</div>
//                         <div>If you should not overwrite the existing figures, discontinue this submission and setup a new one</div>
//                         <ButtonJumboAsync className="me-auto" onClick={updateActiveSubmissionFigures} icon={<SaveOutlined style={{fontSize: 16}}/>} label="Replace Figures"
//                                           subText="overwite the current submissions figures"/>
//                     </div>}/>)}
//                     {noCurrentSubmission && (<Alert type="info" message={<div className="d-col gap-9">
//                         <div>Save these figures as a new submission</div>
//                         <div>Doing this action will create a new submission with these figures</div>
//                         <ButtonJumboAsync className="me-auto" onClick={saveActiveSubmission} icon={<SaveOutlined style={{fontSize: 16}}/>} label="Save as New Submission"
//                                           subText="set these figures to be used for the submission"/>
//                     </div>}/>)}
//                     <Alert type="info" message={<div className="d-col gap-9">
//                         <div>Create an on-the-fly Cover Note using these figures</div>
//                         <div>This is just for testing the waters and the Cover Note does not get saved</div>
//                         <div>Generating the Cover Note does not save figures used in it</div>
//                         <ButtonJumboAsync className="me-auto" onClick={generateCoverNoteWithTheseFigures} icon={<SaveOutlined style={{fontSize: 16}}/>} label="Generate Cover Note"
//                                           subText="without changing the curring submission"/>
//                     </div>}/>
//                 </div>
//             </div>
//         </div>
//     </div>)
// }
// const ResultComponent = ({
//     id,
//     lender,
//     results
// }) => {
//
//     if (!results) {
//         return null
//     }
//     const items = results.map(item => {
//         return {
//             key     : item.key,
//             label   : item.label,
//             children: (<div className="d-row j-end a-center">
//                 {!!item.hasOwnProperty('passed') && (<span style={{
//                     marginRight    : 'auto',
//                     display        : "inline-block",
//                     backgroundColor: item.passed ? green.primary : volcano.primary,
//                     height         : 10,
//                     width          : 10,
//                     borderRadius   : 5
//                 }}/>)}
//                 <span style={{marginLeft: 8}}>{item.value}</span>
//             </div>)
//         }
//     })
//     // if items is an off number, add a dummy item before the last one
//     if (items.length % 2 !== 0) {
//         let lastItem = items.pop()
//         items.push({
//             key     : 'dummy',
//             label   : '',
//             children: null
//         })
//         items.push(lastItem)
//     }
//
//     let lenderName = lenders.find(e => e.key === lender).label
//     return (<div>
//
//         <Descriptions style={{minWidth: 730}} size="small" column={2} bordered items={items}/>
//
//     </div>)
// }
// const LenderTab = ({
//     name,
//     title
// }) => {
//     if (!name) {
//         return null
//     }
//     let api = lenders.find(e => e.key === name).api
//     const items = [
//         {
//             key     : '0',
//             label   : 'Results',
//             children: <ResultComponent id={currentTest.id} lender={name} results={currentTest?.results?.[name]?.items}/>,
//         },
//         {
//             key     : '1',
//             label   : 'Xls Config',
//             children: <QuestionTabs readOnly={true} dataPoints={api.dataPoints} setAnswer={api.setAnswer} form={api.form}/>,
//         },
//         {
//             key     : '2',
//             label   : 'More Options',
//             children: <MoreOptions id={currentTest.id} lender={name} results={currentTest?.results?.[name]?.items}/>,
//         }
//     ];
//     return (<>
//         {/*{selected !== 'live' && <GetResult api={api}/>}*/}
//         <div className="d-row gap-12">
//             <h4 style={{
//                 margin: '13px 4px',
//                 color : blue[4]
//             }}>{title}</h4>
//             <Tabs className="w-100 m-0" items={items} defaultActiveKey={['0']}/>
//         </div>
//
//
//     </>)
// }
//
// const ApplicantRow = ({
//     label,
//     name,
//     type,
//     warning1,
//     warning2
// }) => {
//     return (<tr>
//         <td>{label}</td>
//         <td>
//             <div className="d-row gap-3">
//                 <CustomInputNumber name={`${name}1`}/>
//                 {warning1 && (<Popover content={warning1}><ExclamationCircleOutlined style={{
//                     fontSize: 20,
//                     color   : orange[5]
//                 }}/></Popover>)}
//             </div>
//
//         </td>
//         {mortgage.twoApplicants && <td>
//             <div className="d-row gap-3">
//                 <CustomInputNumber name={`${name}2`}/>
//                 {warning2 && (<Popover content={warning2}><ExclamationCircleOutlined style={{
//                     fontSize: 20,
//                     color   : orange[5]
//                 }}/></Popover>)}
//             </div>
//
//         </td>}
//         {mortgage.twoApplicants && <td>
//             <CustomInputNumber disabled={true} name={`${name}Total`}/>
//
//         </td>}
//     </tr>)
// }
// const FundingDetails = useMemo(() => {
//     return <div className="d-col gap-15">
//
//         <div className="d-row gap-15 mx-9 ">
//             <Form.Item className="mb-0"
//                        label="Loan Amount"
//                        name="provisionalLoan"
//                        rules={[
//                            {
//                                required: true,
//                                message : 'Please input your username!'
//                            }
//                        ]}
//             >
//                 <InputNumber style={{
//                     width   : '100%',
//                     minWidth: 114
//                 }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//             </Form.Item>
//
//             <Form.Item className="mb-0"
//                        label="Term"
//                        name="provisionalTerm"
//                        rules={[
//                            {
//                                required: true,
//                                message : 'Please input your email!'
//                            }
//                        ]}
//             >
//                 <InputNumber style={{
//                     minWidth: 30
//                 }} prefix={<CalendarOutlined/>}/>
//             </Form.Item>
//             <Form.Item className="mb-0"
//                        label="Property Value"
//                        name="provisionalValue"
//                        rules={[
//                            {
//                                required: true,
//                                message : 'Please input your email!'
//                            }
//                        ]}
//             >
//                 <InputNumber style={{
//                     width   : '100%',
//                     minWidth: 114
//                 }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//             </Form.Item>
//             <Form.Item noStyle name="loans1">
//                 <Input type="hidden"/>
//             </Form.Item>
//
//             {mortgage.twoApplicants && (<Form.Item noStyle name="loans2">
//                 <Input type="hidden"/>
//             </Form.Item>)}
//         </div>
//
//         <Form.Item noStyle
//                    shouldUpdate={(prevValues, currentValues) => prevValues.provisionalLoan !== currentValues.provisionalLoan || prevValues.provisionalValue !== currentValues.provisionalValue || prevValues.provisionalTerm !== currentValues.provisionalTerm}>
//             {({getFieldValue}) => {
//                 let loan = getFieldValue('provisionalLoan')
//                 let price = getFieldValue('provisionalValue')
//                 const {requiredSavings} = balanceOfFundsFunds(mortgage, {
//                     loan,
//                     price,
//                 });
//                 let mismatch = ownFunds - requiredSavings
//                 return <>
//
//                     <div className="d-row gap-15">
//                         <div className="d-row gap-15">
//                             <Costs override={{
//                                 loan,
//                                 price
//                             }}/>
//                             <Funds override={{
//                                 loan,
//                                 price
//                             }}/>
//                         </div>
//                         <Card>
//                             <div className="d-col gap-15">
//                                 <h4>Live Funds <small>(not saved with figures)</small></h4>
//                                 <Form.Item className="mb-0"
//                                            label="Funds Available"
//                                 >
//                                     <CustomInputNumber disabled={true} value={ownFunds}/>
//                                 </Form.Item>
//                                 <Form.Item className="mb-0"
//                                            label="Required"
//                                 >
//                                     <InputNumber disabled={true} style={{
//                                         width   : '100%',
//                                         minWidth: 114
//                                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€" value={requiredSavings}/>
//                                 </Form.Item>
//                                 <Form.Item className="mb-0"
//                                            label={mismatch < 0 ? <span className="text-danger">Funds Shortfall</span> : <span className="text-success"> Excess Funds</span>}
//                                 >
//                                     <InputNumber disabled={true} style={{
//                                         width   : '100%',
//                                         minWidth: 114,
//                                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€" value={Math.abs(mismatch)}/>
//                                 </Form.Item>
//
//                             </div>
//                         </Card>
//                     </div>
//
//                 </>;
//
//             }}
//         </Form.Item>
//     </div>
// }, [])
// const SalaryDetails = useMemo(() => {
//
//     return <div>
//         <table>
//             <thead>
//             <tr>
//                 <th></th>
//                 <th>{mortgage.twoApplicants && "Applicant 1"}</th>
//                 {mortgage.twoApplicants && <th>Applicant 2</th>}
//                 {mortgage.twoApplicants && <th>Total</th>}
//             </tr>
//             </thead>
//             <tbody>
//             <tr>
//                 <td>Sector</td>
//                 <td>
//                     <CustomInput name="sector1" disabled={true}/>
//                 </td>
//                 {mortgage.twoApplicants && <td>
//                     <CustomInput name="sector2" disabled={true}/>
//                 </td>}
//                 {mortgage.twoApplicants && <td>
//                 </td>}
//             </tr>
//             <ApplicantRow label="Basic Salary" name="basic" type="euro" warning1={mortgage.figures.verified.basic1.warning} warning2={mortgage.figures.verified?.basic2?.warning}/>
//             <ApplicantRow label="Shift Allowance" name="allowances" type="euro"/>
//             <ApplicantRow label="Other Allowance" name="other" type="euro"/>
//             <ApplicantRow label="Commission" name="commission" type="euro"/>
//             <ApplicantRow label="Overtime" name="overtime" type="euro"/>
//             <ApplicantRow label="Year 1 Bonus" name="year1Bonus" type="euro"/>
//             <tr>
//                 <td>Total Variable</td>
//                 <td>{!mortgage.twoApplicants && <CustomInputNumber disabled={true} name="variableTotal"/>}</td>
//                 {mortgage.twoApplicants && <td></td>}
//                 {mortgage.twoApplicants && <td><CustomInputNumber disabled={true} name="variableTotal"/></td>}
//             </tr>
//             <ApplicantRow label="Year 2 Bonus" name="year2Bonus" type="euro"/>
//             <ApplicantRow label="Year 3 Bonus" name="year3Bonus" type="euro"/>
//             <ApplicantRow label="Pension" name="pension" type="euro"/>
//             </tbody>
//         </table>
//     </div>
// }, [])
// const PraDetails = useMemo(() => {
//     function ApplicantItems({applicant}) {
//         let appIndex = applicant === 1 ? 'applicant1' : 'applicant2'
//         let app = mortgage[appIndex]
//         return <div>
//             <h4>{app.firstName} PRA</h4>
//             <div className="d-row gap-15 f-wrap">
//
//                 <Form.Item className="mb-0"
//                            label="Accommodation Costs"
//                            name={`accommodationCosts${applicant}`}
//                 >
//                     <InputNumber style={{
//                         width   : '100%',
//                         minWidth: 114
//                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//
//                 </Form.Item>
//
//                 <Form.Item className="mb-0"
//                            label="Loan Discontinuing"
//                            name={`clearingLoans${applicant}`}>
//                     <InputNumber style={{
//                         width   : '100%',
//                         minWidth: 114
//                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//                 </Form.Item>
//
//                 <Form.Item className="mb-0"
//                            label="Other PRA"
//                            name={`oatherPRA${applicant}`}>
//                     <InputNumber style={{
//                         width   : '100%',
//                         minWidth: 114
//                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//
//                 </Form.Item>
//
//                 <Form.Item className="mb-0"
//                            label="Savings Rate"
//                            name={`savings${applicant}`}>
//                     <InputNumber style={{
//                         width   : '100%',
//                         minWidth: 114
//                     }} formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
//                 </Form.Item>
//
//             </div>
//
//         </div>
//     }
//     return (<div>
//         <table>
//             <thead>
//             <tr>
//                 <td>Total PRA</td>
//                 <td>
//                     <CustomInputNumber name="praTotal" disabled={true}/>
//                 </td>
//             </tr>
//             <tr>
//                 <td>Payments at {rate}%</td>
//                 <td>
//                     <CustomInputNumber name="monthlyCost" disabled={true}/>
//                 </td>
//             </tr>
//
//             <tr>
//                 <th></th>
//                 <th>{mortgage.twoApplicants && "Applicant 1"}</th>
//                 {mortgage.twoApplicants && <th>Applicant 2</th>}
//                 {mortgage.twoApplicants && <th>Total</th>}
//             </tr>
//             </thead>
//             <tbody>
//             <ApplicantRow label="Accommodation Costs" name="accommodationCosts" type="euro"/>
//             <ApplicantRow label="Loan Discontinuing" name="clearingLoans" type="euro"/>
//             <ApplicantRow label="Other PRA" name="otherPRA" type="euro"/>
//             <ApplicantRow label="Savings Rate" name="savings" type="euro"/>
//             </tbody>
//             <tfoot>
//
//             </tfoot>
//         </table>
//     </div>);
// }, [])
// const SubmissionWarning = () => {
//     if (!mortgage.lenderTests.length) {
//         return <div className="my-15">
//             <Alert message="Figures Not Tested" type="warning" showIcon description="Do the lender test and mark result as figures to be used for the submission"/>
//         </div>
//     }
//     if (noCurrentSubmission) {
//         return <div className="my-15">
//             <Alert message="Submission Figures Not Set" type="warning" showIcon description="Do the lender test and mark result as figures to be used for the submission"/>
//         </div>
//     }
//
// }