import React from 'react';
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import dayjs from "dayjs";
import {ApplicationTypes, AssetTypes, DebtTypes, EmploymentTypes, MortgageTypes} from "../../../../../../models";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Badge} from "antd";
const { Buffer } = require('buffer');

function InitialForm(props) {
    const mortgage = useMortgage()
    let colSpan = mortgage.twoApplicants ? 3 : 2
    let today = dayjs()
    const figures = mortgage.figures.verified
    const str = JSON.stringify(figures);
    const buffer = Buffer.from(str);

    function Tr({children, cols}) {
        return <tr>
            <td>{children}</td>
            {cols().map((item, i) => {
                return <td key={`${children}${i}`}>{item}</td>
            })}
        </tr>
    }

    function ifData(item, euro = false){
        return item ? euro ? item.toEuro() : item : 'No data'
    }

    let ltv = mortgage.requirement.loanRequired && mortgage.properties[0].propertyValue ?  `${((mortgage.requirement.loanRequired * 100) / mortgage.properties[0].propertyValue).toFixed(2)}%` : 'No Data'
    return (
        <table className="custom-table">
            <tbody>
            <tr>
                <th colSpan={mortgage.twoApplicants ? 3: 2}> Loan Details</th>
            </tr>
            <tr>
                <td>
                    Loan Amount
                </td>
                <td  colSpan={mortgage.twoApplicants ? 2: 1}>
                    {ifData(mortgage.requirement.loanRequired, true)}
                </td>
            </tr>

            <tr>
                <td>
                    Property Value
                </td>
                <td  colSpan={mortgage.twoApplicants ? 2: 1}>
                    {ifData(mortgage.properties[0].propertyValue,true)}
                </td>
            </tr>
            <tr>
                <td>
                    LTV
                </td>
                <td  colSpan={mortgage.twoApplicants ? 2: 1}>
                    {ltv }
                </td>
            </tr>
            <tr>
                <td>
                    Mortgage Type
                </td>
                <td  colSpan={mortgage.twoApplicants ? 2: 1}>
                    {
                        <>
                            {mortgage.mortgageType === MortgageTypes.REFINANCE && 'Switcher'}
                            {mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME && 'First Time Buyer'}
                            {mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.MOVINGHOME && 'Moving Home'}
                            {mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.SECONDTIME && 'Buying Another Home'}
                            {mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.LANDLORD && 'Buy To Let'}
                        </>
                    }
                </td>
            </tr>

            <tr>
                <th colSpan={colSpan}> Personal</th>
            </tr>

            <Tr cols={() => {
                let cols = [capitaliseWords((mortgage.applicant1.personal.accommodationSituation || 'No data').toLowerCase())]
                if (mortgage.twoApplicants) {
                    if (mortgage.requirement.cohabiting) {
                        cols.push('Cohabiting')
                    } else {
                        cols.push(mortgage.applicant2.personal.accommodationSituation)
                    }
                }
                return cols
            }}>Home Situation</Tr>
            <Tr cols={() => {
                let cols = []
                let d1 = mortgage.applicant1.personal.dateOfBirth
                if (!!d1) {
                    let dj1 = dayjs(mortgage.applicant1.personal.dateOfBirth, "YYYY-MM-DD")
                    cols.push(`${dj1.format('D MMM YYYY')} (${today.diff(dj1, 'years')} years)`)
                }
                if (mortgage.twoApplicants) {
                    let d2 = mortgage.applicant2.personal.dateOfBirth
                    if (!!d2) {
                        let dj2 = dayjs(mortgage.applicant2.personal.dateOfBirth, "YYYY-MM-DD")
                        cols.push(`${dj2.format('D MMM YYYY')} (${today.diff(dj2, 'years')} years)`)
                    } else {
                        cols.push('No Data')
                    }
                }
                return cols
            }}>
                Date of Birth
            </Tr>

            <Tr cols={() => {
                let cols = [capitaliseWords((mortgage.applicant1.personal.maritalStatus || 'No data').toLowerCase())]
                if (mortgage.twoApplicants) {
                    cols.push(capitaliseWords((mortgage.applicant2.personal.maritalStatus || 'No data').toLowerCase()))
                }
                return cols
            }}>
                Marital Status
            </Tr>

            <Tr cols={() => {
                let cols = []
                let kids1 = (mortgage.applicant1.personal.dependentDOBs || []).map(item => {
                    let dob = dayjs(item, "YYYY-MM-DD")
                    return today.diff(dob, 'year') + 'yr'
                })
                cols.push(kids1.length ? kids1.join(', ') : 'None')
                if (mortgage.twoApplicants) {
                    let kids2 = (mortgage.applicant2.personal.dependentDOBs || []).map(item => {
                        let dob = dayjs(item, "YYYY-MM-DD")
                        return today.diff(dob, 'year') + 'yr'
                    })
                    cols.push(kids2.length ? kids2.join(', ') : 'None')
                }
                return cols
            }}>
                Dependents
            </Tr>

            <tr>
                <th colSpan={colSpan}> Employment & Income (per year)</th>
            </tr>

            <Tr cols={() => {
                let cols = []
                if (mortgage.applicant1.employmentIncome?.length && !!mortgage.applicant1.employmentIncome[0].employmentType) {
                    cols.push(capitaliseWords( mortgage.applicant1.employmentIncome.map(item => item.employmentType || 'No data').join(' / ') ))
                }
                else {
                    cols.push(capitaliseWords((mortgage.applicant1.employment.employmentType || 'No data').toLowerCase()))
                }
                if (mortgage.twoApplicants) {
                    if (mortgage.applicant2.employmentIncome?.length  && !!mortgage.applicant2.employmentIncome[0].employmentType) {
                        cols.push(capitaliseWords( mortgage.applicant2.employmentIncome.map(item => item.employmentType || 'No data').join(' / ') ))
                    }
                    else {
                        cols.push(capitaliseWords((mortgage.applicant2.employment.employmentType || 'No data').toLowerCase()))
                    }
                }
                return cols
            }}>
                Employment Status
            </Tr>

            <Tr cols={() => {
                let cols = []
                if (mortgage.applicant1.employmentIncome?.length  && !!mortgage.applicant1.employmentIncome[0].employmentType) {
                    cols.push( mortgage.applicant1.employmentIncome.map(item => (item.grossBasic || 0).toEuro()).join(' / ') )
                }
                else {
                    cols.push((mortgage.applicant1.income.grossBasic || 0).toEuro())
                }
                if (mortgage.twoApplicants) {
                    if (mortgage.applicant2.employmentIncome?.length  && !!mortgage.applicant2.employmentIncome[0].employmentType) {
                        cols.push( mortgage.applicant2.employmentIncome.map(item => (item.grossBasic || 0).toEuro()).join(' / ') )
                    }
                    else {
                        cols.push((mortgage.applicant2.income.grossBasic || 0).toEuro())
                    }
                }
                return cols
            }}>
                Gross Basic Pay
            </Tr>

            <Tr cols={() => {
                const additionalIncomes = (n) => {
                    let incs = []
                    let legacyEmployment = !mortgage[`applicant1`].employmentIncome?.length || !mortgage[`applicant1`].employmentIncome[0].employmentType
                    if (!legacyEmployment){
                        let len = mortgage[`applicant${n}`].employmentIncome.length
                        mortgage[`applicant${n}`].employmentIncome.forEach(item => {
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(item.employmentType)) {
                                let incomes = item.incomes || []
                                let basis =  item.employmentType===EmploymentTypes.SELFEMPLOYED ? 'Self Employed': capitaliseWords((item.employmentBasis||'Unknown').toLowerCase())
                                incomes.forEach(inc => {
                                    if (inc.amount && inc.amount > 0){
                                        incs.push(<span key={inc.name} className="d-row j-end">
                                         {inc.amount.toEuro()}
                                            {inc.guaranteed && (<Badge size="small" count={"G"} style={{ backgroundColor: '#6ecc4e', marginLeft: 3 }} />)}
                                            <span className="ms-auto">{len>1 ? basis:''} {capitaliseWords(inc.name)}</span>
                                        </span>)
                                    }
                                })
                            }
                        })
                    }
                    else {
                        if (!!mortgage[`applicant${n}`].employment && !!mortgage[`applicant${n}`].income) {
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(mortgage[`applicant${n}`].employment.employmentType) && mortgage[`applicant${n}`].income.incomeChecks?.length) {
                                incs = mortgage[`applicant${n}`].income.incomeChecks.map(check => {

                                    return <span key={check} className="d-row j-end">

                                       {mortgage[`applicant${n}`].income[check.toLowerCase()]?.toEuro()}
                                        <span className="ms-auto">{capitaliseWords(check.toLowerCase())}</span>
                                   </span>
                                })
                            }
                        }
                    }
                    return incs
                }
                let cols = []
                let col1 = additionalIncomes(1)
                cols.push(col1.length ? col1 : '')
                if (mortgage.twoApplicants) {
                    let col2 = additionalIncomes(2)
                    cols.push(col2.length ? col2 : '')
                }
                return cols
            }}>
                Additional Income
            </Tr>

            <tr>
                <th colSpan={colSpan}> Outgoings (per month)</th>
            </tr>

            <Tr cols={() => {
                const justLoans = (n) => {
                    let loans = []
                    if (mortgage[`applicant${n}`].financial.hasLoans) {
                        loans = mortgage[`applicant${n}`].debts.filter(d => d.debtType === DebtTypes.LOAN).map(d => {
                            return <span key={d.id} className="d-row j-end">

                                       {(d.monthlyPayment || 0).toEuro()}
                                <span className="ms-auto">{capitaliseWords(d.lender)}</span>
                                   </span>
                        })
                    }
                    return loans
                }
                let cols = []
                let col1 = justLoans(1)
                cols.push(col1.length ? col1 : '')
                if (mortgage.twoApplicants) {
                    let col2 = justLoans(2)
                    cols.push(col2.length ? col2 : '')
                }
                return cols
            }}>
                Loan Repayments
            </Tr>


            <Tr cols={() => {
                let cols = [(mortgage.applicant1.financial?.monthlyAccommodationCosts || 0).toEuro()]
                if (mortgage.twoApplicants) {
                    if (!mortgage.requirement.cohabiting) {
                        cols.push((mortgage.applicant2.financial?.monthlyAccommodationCosts || 0).toEuro())
                    } else {
                        cols.push('Cohabiting')
                    }
                }
                return cols
            }}>
                Accommodation Costs
            </Tr>

            <Tr cols={() => {
                let cols = [(mortgage.applicant1.financial?.monthlyChildcareCosts || 0).toEuro()]
                if (mortgage.twoApplicants) {
                    cols.push((mortgage.applicant2.financial?.monthlyChildcareCosts || 0).toEuro())
                }
                return cols
            }}>
                Child-minding Costs
            </Tr>

            <Tr cols={() => {
                let cols = [(mortgage.applicant1.financial?.maintenanceCosts || 0).toEuro()]
                if (mortgage.twoApplicants) {
                    cols.push((mortgage.applicant2.financial?.maintenanceCosts || 0).toEuro())
                }
                return cols
            }}>
                Spousal Maintenance
            </Tr>

            <tr>
                <th colSpan={colSpan}> Savings (per month)</th>
            </tr>


            <Tr cols={() => {
                const justSavings = (n) => {
                    let savings = []
                    if (mortgage[`applicant${n}`].financial.hasSavings) {
                        savings = mortgage[`applicant${n}`].assets.filter(d => d.assetType === AssetTypes.SAVING).map(d => {
                            return <span key={d.id} className="d-row j-end">

                                       {(d.increases || 0).toEuro()}
                                <span className="ms-auto">{capitaliseWords(d.institution)}</span>
                                   </span>
                        })
                    } else if (mortgage[`applicant${n}`].financial.hasCurrentAccountSavings) {
                        savings = [<span key={`cacacumulation${n}`} className="d-row j-end">

                                       {(mortgage[`applicant${n}`].financial.monthlySavings || 0).toEuro()}
                            <span className="ms-auto"> Current a/c accumulation</span>
                                   </span>]
                    }
                    return savings
                }
                let cols = []
                let col1 = justSavings(1)
                cols.push(col1.length ? col1 : '')
                if (mortgage.twoApplicants) {
                    let col2 = justSavings(2)
                    cols.push(col2.length ? col2 : '')
                }
                return cols
            }}>
                Savings
            </Tr>

            </tbody>


        </table>
    );
}

export default InitialForm;