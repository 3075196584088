import React, {useContext} from 'react';
import {MyAdminTasks} from "../../../../providers/MyAdminTasksProvider";
import OverflowedCard from "../../_common/OverflowedCard";
import {List} from "antd";
import TaskItem from "./TaskItem";
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";

function Tasks(props) {
    const {tasks} = useContext(MyAdminTasks)
    const {me} = useBackendUsers()
    const myTasks = tasks.filter(it=>it.currentOwner === me.sub || it.author === me.sub).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    return (
        <OverflowedCard title="Tasks" bodyClass="p-0">
            <List dataSource={myTasks} renderItem={(item)=>(
                <List.Item>
                    <TaskItem task={item}/>
                </List.Item>
                )}/>
        </OverflowedCard>
    );
}

export default Tasks;