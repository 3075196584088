import React from 'react';
import {green, volcano, orange} from "@ant-design/colors";
import {Button, Descriptions} from "antd";

function LenderResults({mortgage, results, lenderName, spreadsheetUrl}) {
    if (!results) {
        return null
    }
    // prepare results for description
    const items = results.map(item => {
        return {
            key: item.key, label: item.label, children: (<div className="d-row j-end a-center">
                {!!item.hasOwnProperty('passed') && (<span style={{
                    marginRight: 'auto', display: "inline-block", backgroundColor: item.passed ? green.primary : item.review? orange.primary :  volcano.primary, height: 10, width: 10, borderRadius: 5
                }}/>)}
                <span style={{marginLeft: 8}}>{item.value}</span>
            </div>)
        }
    })
    // if items is an off number, add a dummy item before the last one
    if (items.length % 2 !== 0) {
        let lastItem = items.pop()
        items.push({
            key: 'dummy', label: '', children: null
        })
        items.push(lastItem)
    }


    return (<div>

        <Descriptions style={{minWidth: 730}} size="small" column={2} bordered items={items}/>
        {
            spreadsheetUrl && (
                <a target="_blank" href={spreadsheetUrl}>
                    <Button size="small"  className="mt-9"> Spreadsheet </Button>
                </a>
            )
        }
    </div>)
}

export default LenderResults;