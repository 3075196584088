import React, {useState} from 'react';
import {Badge, Card, Empty, List, Tag, Typography} from "antd";
import dayjs from "dayjs";
import {grey} from "@ant-design/colors";
import {FileImageOutlined, FileOutlined, FilePdfOutlined, FileTextOutlined} from "@ant-design/icons";
import useS3 from "../../../../../providers/useS3";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";

function ItemMessageHistory(props) {
    const s3 = useS3()
    const backendUsers = useBackendUsers()
    function TextMessage({message}) {
        function MyCard(){
            return (<Card
                style={{
                    backgroundColor: message.mine ? '#f1f1f1' : '#f3f8fd',
                }}
                className="my-3"  styles={{body:{padding:6}}}>
                {message.component.content}
            </Card>)
        }
        if (message.mine) return <MyCard/>
        const user = backendUsers.getBySub(message.author)
        return (
            <Badge color={user.color} count={user.firstName} >
                <MyCard/>
            </Badge>
        )

    }

    function UploadMessage({message}) {
        const name = message.component.key.split('/').pop()
        const handleUploadClick = async () => {
            await props.openUpload(message.component.key, message.component.contentType)
        }
        const FileIcon = () => {
            if (message.component.thumbUrl) return (
                <div
                    style={{
                        width: '90px', // Set the width of the div
                        height: '90px', // Set the height of the div
                        backgroundImage: `url(${message.component.thumbUrl})`, // Set the background image using the data URI
                        backgroundSize: 'contain', // Set the background size
                        backgroundRepeat: 'no-repeat', // Set the background repeat
                    }}
                >
                    {/* Optional content inside the div */}
                </div>
            )

            let fileType = message.component.contentType || '';
            let icon;

            if (fileType.startsWith('application/pdf')) {
                icon = <FilePdfOutlined />;
            } else if (fileType.startsWith('image/')) {
                icon = <FileImageOutlined />;
            } else if (fileType.startsWith('text/')) {
                icon = <FileTextOutlined />;
            } else {
                icon = <FileOutlined />;
            }
            return (
               <div className="d-col a-center j-center">
                   <div style={{fontSize: 30, color: grey[1]}}>
                        {icon}
                   </div>
                   <Typography.Text ellipsis size="small">{name}</Typography.Text>
               </div>
            )

        };
        const colAlign = message.mine ? 'end' : 'start'
        return (
            <div className={`d-col w-100 a-${colAlign}`}>
                <Card
                    hoverable
                    onClick={handleUploadClick}
                    style={{
                        width: 130,
                        height: 130,
                        display: 'flex', alignItems:'center', justifyContent:'center',
                        color: grey[1],
                    }}
                    styles={{body:{ padding: 3,
                            width: '100%',}}}

                    className="my-3">
                    <FileIcon/>
                </Card>
            </div>
        )
    }


    function Bubble({message}) {
        const style = {
            marginLeft: message.mine ? 'auto' : '0',
            marginRight: message.mine ? '0' : 'auto',
            paddingLeft: message.mine ? '40px': 0,
            paddingRight: message.mine ? 0 : '40px',
        }

        return (
            <div className="d-row">
                <div style={style}>
                    {message.component.type === 'text' && <TextMessage message={message}/>}
                    {message.component.type === 'upload' && <UploadMessage message={message}/>}
                </div>
            </div>
        )
    }
    if (!props.messages.length) return <Empty description=""/>
    return props.messages.map((message, i) => {
        // Check if it's the first message or the current message's date is different from the previous one
        const isFirstMessageOfDay = i === 0 || !dayjs(message.createdAt).isSame( props.messages[i - 1].createdAt, 'day');

        // Render the separator line if it's not the first message of the day
        const separatorLine = isFirstMessageOfDay ? (<div style={{color:grey[1]}} className="py-3 d-row j-center a-center"> • <small className="mx-3">{dayjs(message.createdAt).format('D MMM YYYY')}</small> • </div>) : null

        return (
            <React.Fragment key={`message-${message.id}`}>
                {separatorLine}
                <Bubble message={message} />
            </React.Fragment>
        );
    });
}

export default ItemMessageHistory;