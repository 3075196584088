import generateQuery from "./generate-query";
import {updateAdminMortgageNotes} from "./mutations";

function replaceContentBetweenBraces(inputString, receivedReplacement) {
    // Regular expression to match content between the second '{' and its corresponding '}'
    const replacement = `{${receivedReplacement}}`
    const regex = /{[^{}]*{[^{}]*}[^{}]*}/g;    // Find all matches of the regular expression
    const matches = inputString.match(regex);
    if (matches && matches.length >= 2) {
        // Replace the second match with the replacement string
        const replacedString = inputString.replace(regex, (match, index) => {
            if (index === 1) {
                return replacement;
            }
            return match;
        });
        return replacedString;
    }    // If there are not at least two matches, return the original string
    return inputString;
}
function generateMutation(mutationName, responseItems) {

    switch (mutationName) {

        case "CreatePublicCouncils":

            return /* GraphQL */ `
                mutation CreatePublicCouncils(
                    $input: CreatePublicCouncilsInput!
                    $condition: ModelPublicCouncilsConditionInput
                ) {
                    createPublicCouncils(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdatePublicCouncils":

            return /* GraphQL */ `
                mutation UpdatePublicCouncils(
                    $input: UpdatePublicCouncilsInput!
                    $condition: ModelPublicCouncilsConditionInput
                ) {
                    updatePublicCouncils(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeletePublicCouncils":

            return /* GraphQL */ `
                mutation DeletePublicCouncils(
                    $input: DeletePublicCouncilsInput!
                    $condition: ModelPublicCouncilsConditionInput
                ) {
                    deletePublicCouncils(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreatePublicLenderRate":

            return /* GraphQL */ `
                mutation CreatePublicLenderRate(
                    $input: CreatePublicLenderRateInput!
                    $condition: ModelPublicLenderRateConditionInput
                ) {
                    createPublicLenderRate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdatePublicLenderRate":

            return /* GraphQL */ `
                mutation UpdatePublicLenderRate(
                    $input: UpdatePublicLenderRateInput!
                    $condition: ModelPublicLenderRateConditionInput
                ) {
                    updatePublicLenderRate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeletePublicLenderRate":

            return /* GraphQL */ `
                mutation DeletePublicLenderRate(
                    $input: DeletePublicLenderRateInput!
                    $condition: ModelPublicLenderRateConditionInput
                ) {
                    deletePublicLenderRate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminPdfOverlayTemplate":

            return /* GraphQL */ `
                mutation CreateAdminPdfOverlayTemplate(
                    $input: CreateAdminPdfOverlayTemplateInput!
                    $condition: ModelAdminPdfOverlayTemplateConditionInput
                ) {
                    createAdminPdfOverlayTemplate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminPdfOverlayTemplate":

            return /* GraphQL */ `
                mutation UpdateAdminPdfOverlayTemplate(
                    $input: UpdateAdminPdfOverlayTemplateInput!
                    $condition: ModelAdminPdfOverlayTemplateConditionInput
                ) {
                    updateAdminPdfOverlayTemplate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminPdfOverlayTemplate":

            return /* GraphQL */ `
                mutation DeleteAdminPdfOverlayTemplate(
                    $input: DeleteAdminPdfOverlayTemplateInput!
                    $condition: ModelAdminPdfOverlayTemplateConditionInput
                ) {
                    deleteAdminPdfOverlayTemplate(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminPdfOverlayTemplateItem":

            return /* GraphQL */ `
                mutation CreateAdminPdfOverlayTemplateItem(
                    $input: CreateAdminPdfOverlayTemplateItemInput!
                    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
                ) {
                    createAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminPdfOverlayTemplateItem":

            return /* GraphQL */ `
                mutation UpdateAdminPdfOverlayTemplateItem(
                    $input: UpdateAdminPdfOverlayTemplateItemInput!
                    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
                ) {
                    updateAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminPdfOverlayTemplateItem":

            return /* GraphQL */ `
                mutation DeleteAdminPdfOverlayTemplateItem(
                    $input: DeleteAdminPdfOverlayTemplateItemInput!
                    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
                ) {
                    deleteAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminLenderFiles":

            return /* GraphQL */ `
                mutation CreateAdminLenderFiles(
                    $input: CreateAdminLenderFilesInput!
                    $condition: ModelAdminLenderFilesConditionInput
                ) {
                    createAdminLenderFiles(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminLenderFiles":

            return /* GraphQL */ `
                mutation UpdateAdminLenderFiles(
                    $input: UpdateAdminLenderFilesInput!
                    $condition: ModelAdminLenderFilesConditionInput
                ) {
                    updateAdminLenderFiles(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminLenderFiles":

            return /* GraphQL */ `
                mutation DeleteAdminLenderFiles(
                    $input: DeleteAdminLenderFilesInput!
                    $condition: ModelAdminLenderFilesConditionInput
                ) {
                    deleteAdminLenderFiles(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminBackendUsers":

            return /* GraphQL */ `
                mutation CreateAdminBackendUsers(
                    $input: CreateAdminBackendUsersInput!
                    $condition: ModelAdminBackendUsersConditionInput
                ) {
                    createAdminBackendUsers(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminBackendUsers":

            return /* GraphQL */ `
                mutation UpdateAdminBackendUsers(
                    $input: UpdateAdminBackendUsersInput!
                    $condition: ModelAdminBackendUsersConditionInput
                ) {
                    updateAdminBackendUsers(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminBackendUsers":

            return /* GraphQL */ `
                mutation DeleteAdminBackendUsers(
                    $input: DeleteAdminBackendUsersInput!
                    $condition: ModelAdminBackendUsersConditionInput
                ) {
                    deleteAdminBackendUsers(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminLender":

            return /* GraphQL */ `
                mutation CreateAdminLender(
                    $input: CreateAdminLenderInput!
                    $condition: ModelAdminLenderConditionInput
                ) {
                    createAdminLender(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminLender":

            return /* GraphQL */ `
                mutation UpdateAdminLender(
                    $input: UpdateAdminLenderInput!
                    $condition: ModelAdminLenderConditionInput
                ) {
                    updateAdminLender(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminLender":

            return /* GraphQL */ `
                mutation DeleteAdminLender(
                    $input: DeleteAdminLenderInput!
                    $condition: ModelAdminLenderConditionInput
                ) {
                    deleteAdminLender(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminEmails":

            return /* GraphQL */ `
                mutation CreateAdminEmails(
                    $input: CreateAdminEmailsInput!
                    $condition: ModelAdminEmailsConditionInput
                ) {
                    createAdminEmails(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminEmails":

            return /* GraphQL */ `
                mutation UpdateAdminEmails(
                    $input: UpdateAdminEmailsInput!
                    $condition: ModelAdminEmailsConditionInput
                ) {
                    updateAdminEmails(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminEmails":

            return /* GraphQL */ `
                mutation DeleteAdminEmails(
                    $input: DeleteAdminEmailsInput!
                    $condition: ModelAdminEmailsConditionInput
                ) {
                    deleteAdminEmails(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminEmailReplyRequest":

            return /* GraphQL */ `
                mutation CreateAdminEmailReplyRequest(
                    $input: CreateAdminEmailReplyRequestInput!
                    $condition: ModelAdminEmailReplyRequestConditionInput
                ) {
                    createAdminEmailReplyRequest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminEmailReplyRequest":

            return /* GraphQL */ `
                mutation UpdateAdminEmailReplyRequest(
                    $input: UpdateAdminEmailReplyRequestInput!
                    $condition: ModelAdminEmailReplyRequestConditionInput
                ) {
                    updateAdminEmailReplyRequest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminEmailReplyRequest":

            return /* GraphQL */ `
                mutation DeleteAdminEmailReplyRequest(
                    $input: DeleteAdminEmailReplyRequestInput!
                    $condition: ModelAdminEmailReplyRequestConditionInput
                ) {
                    deleteAdminEmailReplyRequest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminEmailAttachments":

            return /* GraphQL */ `
                mutation CreateAdminEmailAttachments(
                    $input: CreateAdminEmailAttachmentsInput!
                    $condition: ModelAdminEmailAttachmentsConditionInput
                ) {
                    createAdminEmailAttachments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminEmailAttachments":

            return /* GraphQL */ `
                mutation UpdateAdminEmailAttachments(
                    $input: UpdateAdminEmailAttachmentsInput!
                    $condition: ModelAdminEmailAttachmentsConditionInput
                ) {
                    updateAdminEmailAttachments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminEmailAttachments":

            return /* GraphQL */ `
                mutation DeleteAdminEmailAttachments(
                    $input: DeleteAdminEmailAttachmentsInput!
                    $condition: ModelAdminEmailAttachmentsConditionInput
                ) {
                    deleteAdminEmailAttachments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminUserCalculations":

            return /* GraphQL */ `
                mutation CreateAdminUserCalculations(
                    $input: CreateAdminUserCalculationsInput!
                    $condition: ModelAdminUserCalculationsConditionInput
                ) {
                    createAdminUserCalculations(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminUserCalculations":

            return /* GraphQL */ `
                mutation UpdateAdminUserCalculations(
                    $input: UpdateAdminUserCalculationsInput!
                    $condition: ModelAdminUserCalculationsConditionInput
                ) {
                    updateAdminUserCalculations(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminUserCalculations":

            return /* GraphQL */ `
                mutation DeleteAdminUserCalculations(
                    $input: DeleteAdminUserCalculationsInput!
                    $condition: ModelAdminUserCalculationsConditionInput
                ) {
                    deleteAdminUserCalculations(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminSystemToDo":

            return /* GraphQL */ `
                mutation CreateAdminSystemToDo(
                    $input: CreateAdminSystemToDoInput!
                    $condition: ModelAdminSystemToDoConditionInput
                ) {
                    createAdminSystemToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminSystemToDo":

            return /* GraphQL */ `
                mutation UpdateAdminSystemToDo(
                    $input: UpdateAdminSystemToDoInput!
                    $condition: ModelAdminSystemToDoConditionInput
                ) {
                    updateAdminSystemToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminSystemToDo":

            return /* GraphQL */ `
                mutation DeleteAdminSystemToDo(
                    $input: DeleteAdminSystemToDoInput!
                    $condition: ModelAdminSystemToDoConditionInput
                ) {
                    deleteAdminSystemToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminTextractedStatement":

            return /* GraphQL */ `
                mutation CreateAdminTextractedStatement(
                    $input: CreateAdminTextractedStatementInput!
                    $condition: ModelAdminTextractedStatementConditionInput
                ) {
                    createAdminTextractedStatement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminTextractedStatement":

            return /* GraphQL */ `
                mutation UpdateAdminTextractedStatement(
                    $input: UpdateAdminTextractedStatementInput!
                    $condition: ModelAdminTextractedStatementConditionInput
                ) {
                    updateAdminTextractedStatement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminTextractedStatement":

            return /* GraphQL */ `
                mutation DeleteAdminTextractedStatement(
                    $input: DeleteAdminTextractedStatementInput!
                    $condition: ModelAdminTextractedStatementConditionInput
                ) {
                    deleteAdminTextractedStatement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageAccountSettings":

            return /* GraphQL */ `
                mutation CreateAdminMortgageAccountSettings(
                    $input: CreateAdminMortgageAccountSettingsInput!
                    $condition: ModelAdminMortgageAccountSettingsConditionInput
                ) {
                    createAdminMortgageAccountSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageAccountSettings":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageAccountSettings(
                    $input: UpdateAdminMortgageAccountSettingsInput!
                    $condition: ModelAdminMortgageAccountSettingsConditionInput
                ) {
                    updateAdminMortgageAccountSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageAccountSettings":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageAccountSettings(
                    $input: DeleteAdminMortgageAccountSettingsInput!
                    $condition: ModelAdminMortgageAccountSettingsConditionInput
                ) {
                    deleteAdminMortgageAccountSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageTasks":

            return /* GraphQL */ `
                mutation CreateAdminMortgageTasks(
                    $input: CreateAdminMortgageTasksInput!
                    $condition: ModelAdminMortgageTasksConditionInput
                ) {
                    createAdminMortgageTasks(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageTasks":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageTasks(
                    $input: UpdateAdminMortgageTasksInput!
                    $condition: ModelAdminMortgageTasksConditionInput
                ) {
                    updateAdminMortgageTasks(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageTasks":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageTasks(
                    $input: DeleteAdminMortgageTasksInput!
                    $condition: ModelAdminMortgageTasksConditionInput
                ) {
                    deleteAdminMortgageTasks(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageTaskComponent":

            return /* GraphQL */ `
                mutation CreateAdminMortgageTaskComponent(
                    $input: CreateAdminMortgageTaskComponentInput!
                    $condition: ModelAdminMortgageTaskComponentConditionInput
                ) {
                    createAdminMortgageTaskComponent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageTaskComponent":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageTaskComponent(
                    $input: UpdateAdminMortgageTaskComponentInput!
                    $condition: ModelAdminMortgageTaskComponentConditionInput
                ) {
                    updateAdminMortgageTaskComponent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageTaskComponent":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageTaskComponent(
                    $input: DeleteAdminMortgageTaskComponentInput!
                    $condition: ModelAdminMortgageTaskComponentConditionInput
                ) {
                    deleteAdminMortgageTaskComponent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageTaskToDo":

            return /* GraphQL */ `
                mutation CreateAdminMortgageTaskToDo(
                    $input: CreateAdminMortgageTaskToDoInput!
                    $condition: ModelAdminMortgageTaskToDoConditionInput
                ) {
                    createAdminMortgageTaskToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageTaskToDo":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageTaskToDo(
                    $input: UpdateAdminMortgageTaskToDoInput!
                    $condition: ModelAdminMortgageTaskToDoConditionInput
                ) {
                    updateAdminMortgageTaskToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageTaskToDo":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageTaskToDo(
                    $input: DeleteAdminMortgageTaskToDoInput!
                    $condition: ModelAdminMortgageTaskToDoConditionInput
                ) {
                    deleteAdminMortgageTaskToDo(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageNotes":

            return /* GraphQL */ `
                mutation CreateAdminMortgageNotes(
                    $input: CreateAdminMortgageNotesInput!
                    $condition: ModelAdminMortgageNotesConditionInput
                ) {
                    createAdminMortgageNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageNotes":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageNotes(
                    $input: UpdateAdminMortgageNotesInput!
                    $condition: ModelAdminMortgageNotesConditionInput
                ) {
                    updateAdminMortgageNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageNotes":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageNotes(
                    $input: DeleteAdminMortgageNotesInput!
                    $condition: ModelAdminMortgageNotesConditionInput
                ) {
                    deleteAdminMortgageNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageStageTask":

            return /* GraphQL */ `
                mutation CreateAdminMortgageStageTask(
                    $input: CreateAdminMortgageStageTaskInput!
                    $condition: ModelAdminMortgageStageTaskConditionInput
                ) {
                    createAdminMortgageStageTask(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageStageTask":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageStageTask(
                    $input: UpdateAdminMortgageStageTaskInput!
                    $condition: ModelAdminMortgageStageTaskConditionInput
                ) {
                    updateAdminMortgageStageTask(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageStageTask":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageStageTask(
                    $input: DeleteAdminMortgageStageTaskInput!
                    $condition: ModelAdminMortgageStageTaskConditionInput
                ) {
                    deleteAdminMortgageStageTask(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageStageNotifications":

            return /* GraphQL */ `
                mutation CreateAdminMortgageStageNotifications(
                    $input: CreateAdminMortgageStageNotificationsInput!
                    $condition: ModelAdminMortgageStageNotificationsConditionInput
                ) {
                    createAdminMortgageStageNotifications(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageStageNotifications":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageStageNotifications(
                    $input: UpdateAdminMortgageStageNotificationsInput!
                    $condition: ModelAdminMortgageStageNotificationsConditionInput
                ) {
                    updateAdminMortgageStageNotifications(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageStageNotifications":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageStageNotifications(
                    $input: DeleteAdminMortgageStageNotificationsInput!
                    $condition: ModelAdminMortgageStageNotificationsConditionInput
                ) {
                    deleteAdminMortgageStageNotifications(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageStageNotified":

            return /* GraphQL */ `
                mutation CreateAdminMortgageStageNotified(
                    $input: CreateAdminMortgageStageNotifiedInput!
                    $condition: ModelAdminMortgageStageNotifiedConditionInput
                ) {
                    createAdminMortgageStageNotified(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageStageNotified":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageStageNotified(
                    $input: UpdateAdminMortgageStageNotifiedInput!
                    $condition: ModelAdminMortgageStageNotifiedConditionInput
                ) {
                    updateAdminMortgageStageNotified(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageStageNotified":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageStageNotified(
                    $input: DeleteAdminMortgageStageNotifiedInput!
                    $condition: ModelAdminMortgageStageNotifiedConditionInput
                ) {
                    deleteAdminMortgageStageNotified(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageDocumentContent":

            return /* GraphQL */ `
                mutation CreateAdminMortgageDocumentContent(
                    $input: CreateAdminMortgageDocumentContentInput!
                    $condition: ModelAdminMortgageDocumentContentConditionInput
                ) {
                    createAdminMortgageDocumentContent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageDocumentContent":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageDocumentContent(
                    $input: UpdateAdminMortgageDocumentContentInput!
                    $condition: ModelAdminMortgageDocumentContentConditionInput
                ) {
                    updateAdminMortgageDocumentContent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageDocumentContent":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageDocumentContent(
                    $input: DeleteAdminMortgageDocumentContentInput!
                    $condition: ModelAdminMortgageDocumentContentConditionInput
                ) {
                    deleteAdminMortgageDocumentContent(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageSettings":

            return /* GraphQL */ `
                mutation CreateAdminMortgageSettings(
                    $input: CreateAdminMortgageSettingsInput!
                    $condition: ModelAdminMortgageSettingsConditionInput
                ) {
                    createAdminMortgageSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageSettings":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageSettings(
                    $input: UpdateAdminMortgageSettingsInput!
                    $condition: ModelAdminMortgageSettingsConditionInput
                ) {
                    updateAdminMortgageSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageSettings":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageSettings(
                    $input: DeleteAdminMortgageSettingsInput!
                    $condition: ModelAdminMortgageSettingsConditionInput
                ) {
                    deleteAdminMortgageSettings(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminLastViewedMortgage":

            return /* GraphQL */ `
                mutation CreateAdminLastViewedMortgage(
                    $input: CreateAdminLastViewedMortgageInput!
                    $condition: ModelAdminLastViewedMortgageConditionInput
                ) {
                    createAdminLastViewedMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminLastViewedMortgage":

            return /* GraphQL */ `
                mutation UpdateAdminLastViewedMortgage(
                    $input: UpdateAdminLastViewedMortgageInput!
                    $condition: ModelAdminLastViewedMortgageConditionInput
                ) {
                    updateAdminLastViewedMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminLastViewedMortgage":

            return /* GraphQL */ `
                mutation DeleteAdminLastViewedMortgage(
                    $input: DeleteAdminLastViewedMortgageInput!
                    $condition: ModelAdminLastViewedMortgageConditionInput
                ) {
                    deleteAdminLastViewedMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageLenderTest":

            return /* GraphQL */ `
                mutation CreateAdminMortgageLenderTest(
                    $input: CreateAdminMortgageLenderTestInput!
                    $condition: ModelAdminMortgageLenderTestConditionInput
                ) {
                    createAdminMortgageLenderTest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageLenderTest":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageLenderTest(
                    $input: UpdateAdminMortgageLenderTestInput!
                    $condition: ModelAdminMortgageLenderTestConditionInput
                ) {
                    updateAdminMortgageLenderTest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageLenderTest":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageLenderTest(
                    $input: DeleteAdminMortgageLenderTestInput!
                    $condition: ModelAdminMortgageLenderTestConditionInput
                ) {
                    deleteAdminMortgageLenderTest(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageLenderTestPlaygroundTest":

            return /* GraphQL */ `
                mutation CreateAdminMortgageLenderTestPlaygroundTest(
                    $input: CreateAdminMortgageLenderTestPlaygroundTestInput!
                    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
                ) {
                    createAdminMortgageLenderTestPlaygroundTest(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageLenderTestPlaygroundTest":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageLenderTestPlaygroundTest(
                    $input: UpdateAdminMortgageLenderTestPlaygroundTestInput!
                    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
                ) {
                    updateAdminMortgageLenderTestPlaygroundTest(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageLenderTestPlaygroundTest":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageLenderTestPlaygroundTest(
                    $input: DeleteAdminMortgageLenderTestPlaygroundTestInput!
                    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
                ) {
                    deleteAdminMortgageLenderTestPlaygroundTest(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageUploadStatementBasicInfo":

            return /* GraphQL */ `
                mutation CreateAdminMortgageUploadStatementBasicInfo(
                    $input: CreateAdminMortgageUploadStatementBasicInfoInput!
                    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
                ) {
                    createAdminMortgageUploadStatementBasicInfo(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageUploadStatementBasicInfo":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageUploadStatementBasicInfo(
                    $input: UpdateAdminMortgageUploadStatementBasicInfoInput!
                    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
                ) {
                    updateAdminMortgageUploadStatementBasicInfo(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageUploadStatementBasicInfo":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageUploadStatementBasicInfo(
                    $input: DeleteAdminMortgageUploadStatementBasicInfoInput!
                    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
                ) {
                    deleteAdminMortgageUploadStatementBasicInfo(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageSectionVerification":

            return /* GraphQL */ `
                mutation CreateAdminMortgageSectionVerification(
                    $input: CreateAdminMortgageSectionVerificationInput!
                    $condition: ModelAdminMortgageSectionVerificationConditionInput
                ) {
                    createAdminMortgageSectionVerification(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageSectionVerification":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageSectionVerification(
                    $input: UpdateAdminMortgageSectionVerificationInput!
                    $condition: ModelAdminMortgageSectionVerificationConditionInput
                ) {
                    updateAdminMortgageSectionVerification(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageSectionVerification":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageSectionVerification(
                    $input: DeleteAdminMortgageSectionVerificationInput!
                    $condition: ModelAdminMortgageSectionVerificationConditionInput
                ) {
                    deleteAdminMortgageSectionVerification(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageUploadVerifiedPoint":

            return /* GraphQL */ `
                mutation CreateAdminMortgageUploadVerifiedPoint(
                    $input: CreateAdminMortgageUploadVerifiedPointInput!
                    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
                ) {
                    createAdminMortgageUploadVerifiedPoint(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageUploadVerifiedPoint":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageUploadVerifiedPoint(
                    $input: UpdateAdminMortgageUploadVerifiedPointInput!
                    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
                ) {
                    updateAdminMortgageUploadVerifiedPoint(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageUploadVerifiedPoint":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageUploadVerifiedPoint(
                    $input: DeleteAdminMortgageUploadVerifiedPointInput!
                    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
                ) {
                    deleteAdminMortgageUploadVerifiedPoint(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageSectionVerificationNote":

            return /* GraphQL */ `
                mutation CreateAdminMortgageSectionVerificationNote(
                    $input: CreateAdminMortgageSectionVerificationNoteInput!
                    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
                ) {
                    createAdminMortgageSectionVerificationNote(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageSectionVerificationNote":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageSectionVerificationNote(
                    $input: UpdateAdminMortgageSectionVerificationNoteInput!
                    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
                ) {
                    updateAdminMortgageSectionVerificationNote(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageSectionVerificationNote":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageSectionVerificationNote(
                    $input: DeleteAdminMortgageSectionVerificationNoteInput!
                    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
                ) {
                    deleteAdminMortgageSectionVerificationNote(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageSubmission":

            return /* GraphQL */ `
                mutation CreateAdminMortgageSubmission(
                    $input: CreateAdminMortgageSubmissionInput!
                    $condition: ModelAdminMortgageSubmissionConditionInput
                ) {
                    createAdminMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageSubmission":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageSubmission(
                    $input: UpdateAdminMortgageSubmissionInput!
                    $condition: ModelAdminMortgageSubmissionConditionInput
                ) {
                    updateAdminMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageSubmission":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageSubmission(
                    $input: DeleteAdminMortgageSubmissionInput!
                    $condition: ModelAdminMortgageSubmissionConditionInput
                ) {
                    deleteAdminMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageSubmissionIssue":

            return /* GraphQL */ `
                mutation CreateAdminMortgageSubmissionIssue(
                    $input: CreateAdminMortgageSubmissionIssueInput!
                    $condition: ModelAdminMortgageSubmissionIssueConditionInput
                ) {
                    createAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageSubmissionIssue":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageSubmissionIssue(
                    $input: UpdateAdminMortgageSubmissionIssueInput!
                    $condition: ModelAdminMortgageSubmissionIssueConditionInput
                ) {
                    updateAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageSubmissionIssue":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageSubmissionIssue(
                    $input: DeleteAdminMortgageSubmissionIssueInput!
                    $condition: ModelAdminMortgageSubmissionIssueConditionInput
                ) {
                    deleteAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateAdminMortgageProtectionInsurance":

            return /* GraphQL */ `
                mutation CreateAdminMortgageProtectionInsurance(
                    $input: CreateAdminMortgageProtectionInsuranceInput!
                    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
                ) {
                    createAdminMortgageProtectionInsurance(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateAdminMortgageProtectionInsurance":

            return /* GraphQL */ `
                mutation UpdateAdminMortgageProtectionInsurance(
                    $input: UpdateAdminMortgageProtectionInsuranceInput!
                    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
                ) {
                    updateAdminMortgageProtectionInsurance(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteAdminMortgageProtectionInsurance":

            return /* GraphQL */ `
                mutation DeleteAdminMortgageProtectionInsurance(
                    $input: DeleteAdminMortgageProtectionInsuranceInput!
                    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
                ) {
                    deleteAdminMortgageProtectionInsurance(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserPdfId":

            return /* GraphQL */ `
                mutation CreateUserPdfId(
                    $input: CreateUserPdfIdInput!
                    $condition: ModelUserPdfIdConditionInput
                ) {
                    createUserPdfId(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserPdfId":

            return /* GraphQL */ `
                mutation UpdateUserPdfId(
                    $input: UpdateUserPdfIdInput!
                    $condition: ModelUserPdfIdConditionInput
                ) {
                    updateUserPdfId(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserPdfId":

            return /* GraphQL */ `
                mutation DeleteUserPdfId(
                    $input: DeleteUserPdfIdInput!
                    $condition: ModelUserPdfIdConditionInput
                ) {
                    deleteUserPdfId(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageAppointment":

            return /* GraphQL */ `
                mutation CreateUserMortgageAppointment(
                    $input: CreateUserMortgageAppointmentInput!
                    $condition: ModelUserMortgageAppointmentConditionInput
                ) {
                    createUserMortgageAppointment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageAppointment":

            return /* GraphQL */ `
                mutation UpdateUserMortgageAppointment(
                    $input: UpdateUserMortgageAppointmentInput!
                    $condition: ModelUserMortgageAppointmentConditionInput
                ) {
                    updateUserMortgageAppointment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageAppointment":

            return /* GraphQL */ `
                mutation DeleteUserMortgageAppointment(
                    $input: DeleteUserMortgageAppointmentInput!
                    $condition: ModelUserMortgageAppointmentConditionInput
                ) {
                    deleteUserMortgageAppointment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageSubmission":

            return /* GraphQL */ `
                mutation CreateUserMortgageSubmission(
                    $input: CreateUserMortgageSubmissionInput!
                    $condition: ModelUserMortgageSubmissionConditionInput
                ) {
                    createUserMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageSubmission":

            return /* GraphQL */ `
                mutation UpdateUserMortgageSubmission(
                    $input: UpdateUserMortgageSubmissionInput!
                    $condition: ModelUserMortgageSubmissionConditionInput
                ) {
                    updateUserMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageSubmission":

            return /* GraphQL */ `
                mutation DeleteUserMortgageSubmission(
                    $input: DeleteUserMortgageSubmissionInput!
                    $condition: ModelUserMortgageSubmissionConditionInput
                ) {
                    deleteUserMortgageSubmission(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageQuery":

            return /* GraphQL */ `
                mutation CreateUserMortgageQuery(
                    $input: CreateUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    createUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageQuery":

            return /* GraphQL */ `
                mutation UpdateUserMortgageQuery(
                    $input: UpdateUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    updateUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageQuery":

            return /* GraphQL */ `
                mutation DeleteUserMortgageQuery(
                    $input: DeleteUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    deleteUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgage":

            return /* GraphQL */ `
                mutation CreateUserMortgage(
                    $input: CreateUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    createUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgage":

            return /* GraphQL */ `
                mutation UpdateUserMortgage(
                    $input: UpdateUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    updateUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgage":

            return /* GraphQL */ `
                mutation DeleteUserMortgage(
                    $input: DeleteUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    deleteUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageInvitation":

            return /* GraphQL */ `
                mutation CreateUserMortgageInvitation(
                    $input: CreateUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    createUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageInvitation":

            return /* GraphQL */ `
                mutation UpdateUserMortgageInvitation(
                    $input: UpdateUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    updateUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageInvitation":

            return /* GraphQL */ `
                mutation DeleteUserMortgageInvitation(
                    $input: DeleteUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    deleteUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageDocuments":

            return /* GraphQL */ `
                mutation CreateUserMortgageDocuments(
                    $input: CreateUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    createUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageDocuments":

            return /* GraphQL */ `
                mutation UpdateUserMortgageDocuments(
                    $input: UpdateUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    updateUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageDocuments":

            return /* GraphQL */ `
                mutation DeleteUserMortgageDocuments(
                    $input: DeleteUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    deleteUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProgress":

            return /* GraphQL */ `
                mutation CreateUserMortgageProgress(
                    $input: CreateUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    createUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProgress":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProgress(
                    $input: UpdateUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    updateUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProgress":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProgress(
                    $input: DeleteUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    deleteUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation CreateUserMortgageProgressNotes(
                    $input: CreateUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    createUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProgressNotes(
                    $input: UpdateUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    updateUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProgressNotes(
                    $input: DeleteUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    deleteUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation CreateUserMortgageDynamicSection(
                    $input: CreateUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    createUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation UpdateUserMortgageDynamicSection(
                    $input: UpdateUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    updateUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation DeleteUserMortgageDynamicSection(
                    $input: DeleteUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    deleteUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageUploads":

            return /* GraphQL */ `
                mutation CreateUserMortgageUploads(
                    $input: CreateUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    createUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageUploads":

            return /* GraphQL */ `
                mutation UpdateUserMortgageUploads(
                    $input: UpdateUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    updateUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageUploads":

            return /* GraphQL */ `
                mutation DeleteUserMortgageUploads(
                    $input: DeleteUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    deleteUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageRequirement":

            return /* GraphQL */ `
                mutation CreateUserMortgageRequirement(
                    $input: CreateUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    createUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageRequirement":

            return /* GraphQL */ `
                mutation UpdateUserMortgageRequirement(
                    $input: UpdateUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    updateUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageRequirement":

            return /* GraphQL */ `
                mutation DeleteUserMortgageRequirement(
                    $input: DeleteUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    deleteUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProperty":

            return /* GraphQL */ `
                mutation CreateUserMortgageProperty(
                    $input: CreateUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    createUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProperty":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProperty(
                    $input: UpdateUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    updateUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProperty":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProperty(
                    $input: DeleteUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    deleteUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageExisting":

            return /* GraphQL */ `
                mutation CreateUserMortgageExisting(
                    $input: CreateUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    createUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageExisting":

            return /* GraphQL */ `
                mutation UpdateUserMortgageExisting(
                    $input: UpdateUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    updateUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageExisting":

            return /* GraphQL */ `
                mutation DeleteUserMortgageExisting(
                    $input: DeleteUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    deleteUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageNeeds":

            return /* GraphQL */ `
                mutation CreateUserMortgageNeeds(
                    $input: CreateUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    createUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageNeeds":

            return /* GraphQL */ `
                mutation UpdateUserMortgageNeeds(
                    $input: UpdateUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    updateUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageNeeds":

            return /* GraphQL */ `
                mutation DeleteUserMortgageNeeds(
                    $input: DeleteUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    deleteUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation CreateUserMortgageSolicitor(
                    $input: CreateUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    createUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation UpdateUserMortgageSolicitor(
                    $input: UpdateUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    updateUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation DeleteUserMortgageSolicitor(
                    $input: DeleteUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    deleteUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation CreateUserMortgagePropertyOnMortgage(
                    $input: CreateUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    createUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation UpdateUserMortgagePropertyOnMortgage(
                    $input: UpdateUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    updateUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation DeleteUserMortgagePropertyOnMortgage(
                    $input: DeleteUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    deleteUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicant":

            return /* GraphQL */ `
                mutation CreateUserApplicant(
                    $input: CreateUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    createUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicant":

            return /* GraphQL */ `
                mutation UpdateUserApplicant(
                    $input: UpdateUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    updateUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicant":

            return /* GraphQL */ `
                mutation DeleteUserApplicant(
                    $input: DeleteUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    deleteUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantLastHere":

            return /* GraphQL */ `
                mutation CreateUserApplicantLastHere(
                    $input: CreateUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    createUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantLastHere":

            return /* GraphQL */ `
                mutation UpdateUserApplicantLastHere(
                    $input: UpdateUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    updateUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantLastHere":

            return /* GraphQL */ `
                mutation DeleteUserApplicantLastHere(
                    $input: DeleteUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    deleteUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation CreateUserApplicantCurrentAccount(
                    $input: CreateUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    createUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation UpdateUserApplicantCurrentAccount(
                    $input: UpdateUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    updateUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation DeleteUserApplicantCurrentAccount(
                    $input: DeleteUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    deleteUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantBusinessCurrentAccount":

            return /* GraphQL */ `
                mutation CreateUserApplicantBusinessCurrentAccount(
                    $input: CreateUserApplicantBusinessCurrentAccountInput!
                    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
                ) {
                    createUserApplicantBusinessCurrentAccount(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantBusinessCurrentAccount":

            return /* GraphQL */ `
                mutation UpdateUserApplicantBusinessCurrentAccount(
                    $input: UpdateUserApplicantBusinessCurrentAccountInput!
                    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
                ) {
                    updateUserApplicantBusinessCurrentAccount(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantBusinessCurrentAccount":

            return /* GraphQL */ `
                mutation DeleteUserApplicantBusinessCurrentAccount(
                    $input: DeleteUserApplicantBusinessCurrentAccountInput!
                    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
                ) {
                    deleteUserApplicantBusinessCurrentAccount(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantTax":

            return /* GraphQL */ `
                mutation CreateUserApplicantTax(
                    $input: CreateUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    createUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantTax":

            return /* GraphQL */ `
                mutation UpdateUserApplicantTax(
                    $input: UpdateUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    updateUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantTax":

            return /* GraphQL */ `
                mutation DeleteUserApplicantTax(
                    $input: DeleteUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    deleteUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantPersonal":

            return /* GraphQL */ `
                mutation CreateUserApplicantPersonal(
                    $input: CreateUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    createUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantPersonal":

            return /* GraphQL */ `
                mutation UpdateUserApplicantPersonal(
                    $input: UpdateUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    updateUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantPersonal":

            return /* GraphQL */ `
                mutation DeleteUserApplicantPersonal(
                    $input: DeleteUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    deleteUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantIncome":

            return /* GraphQL */ `
                mutation CreateUserApplicantIncome(
                    $input: CreateUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    createUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantIncome":

            return /* GraphQL */ `
                mutation UpdateUserApplicantIncome(
                    $input: UpdateUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    updateUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantIncome":

            return /* GraphQL */ `
                mutation DeleteUserApplicantIncome(
                    $input: DeleteUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    deleteUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantFinancial":

            return /* GraphQL */ `
                mutation CreateUserApplicantFinancial(
                    $input: CreateUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    createUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantFinancial":

            return /* GraphQL */ `
                mutation UpdateUserApplicantFinancial(
                    $input: UpdateUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    updateUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantFinancial":

            return /* GraphQL */ `
                mutation DeleteUserApplicantFinancial(
                    $input: DeleteUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    deleteUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantEmployment":

            return /* GraphQL */ `
                mutation CreateUserApplicantEmployment(
                    $input: CreateUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    createUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantEmployment":

            return /* GraphQL */ `
                mutation UpdateUserApplicantEmployment(
                    $input: UpdateUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    updateUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantEmployment":

            return /* GraphQL */ `
                mutation DeleteUserApplicantEmployment(
                    $input: DeleteUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    deleteUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantEmploymentIncome":

            return /* GraphQL */ `
                mutation CreateUserApplicantEmploymentIncome(
                    $input: CreateUserApplicantEmploymentIncomeInput!
                    $condition: ModelUserApplicantEmploymentIncomeConditionInput
                ) {
                    createUserApplicantEmploymentIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantEmploymentIncome":

            return /* GraphQL */ `
                mutation UpdateUserApplicantEmploymentIncome(
                    $input: UpdateUserApplicantEmploymentIncomeInput!
                    $condition: ModelUserApplicantEmploymentIncomeConditionInput
                ) {
                    updateUserApplicantEmploymentIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantEmploymentIncome":

            return /* GraphQL */ `
                mutation DeleteUserApplicantEmploymentIncome(
                    $input: DeleteUserApplicantEmploymentIncomeInput!
                    $condition: ModelUserApplicantEmploymentIncomeConditionInput
                ) {
                    deleteUserApplicantEmploymentIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantAsset":

            return /* GraphQL */ `
                mutation CreateUserApplicantAsset(
                    $input: CreateUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    createUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantAsset":

            return /* GraphQL */ `
                mutation UpdateUserApplicantAsset(
                    $input: UpdateUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    updateUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantAsset":

            return /* GraphQL */ `
                mutation DeleteUserApplicantAsset(
                    $input: DeleteUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    deleteUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantDebt":

            return /* GraphQL */ `
                mutation CreateUserApplicantDebt(
                    $input: CreateUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    createUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantDebt":

            return /* GraphQL */ `
                mutation UpdateUserApplicantDebt(
                    $input: UpdateUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    updateUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantDebt":

            return /* GraphQL */ `
                mutation DeleteUserApplicantDebt(
                    $input: DeleteUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    deleteUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantBusinessDebt":

            return /* GraphQL */ `
                mutation CreateUserApplicantBusinessDebt(
                    $input: CreateUserApplicantBusinessDebtInput!
                    $condition: ModelUserApplicantBusinessDebtConditionInput
                ) {
                    createUserApplicantBusinessDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantBusinessDebt":

            return /* GraphQL */ `
                mutation UpdateUserApplicantBusinessDebt(
                    $input: UpdateUserApplicantBusinessDebtInput!
                    $condition: ModelUserApplicantBusinessDebtConditionInput
                ) {
                    updateUserApplicantBusinessDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantBusinessDebt":

            return /* GraphQL */ `
                mutation DeleteUserApplicantBusinessDebt(
                    $input: DeleteUserApplicantBusinessDebtInput!
                    $condition: ModelUserApplicantBusinessDebtConditionInput
                ) {
                    deleteUserApplicantBusinessDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantProperty":

            return /* GraphQL */ `
                mutation CreateUserApplicantProperty(
                    $input: CreateUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    createUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantProperty":

            return /* GraphQL */ `
                mutation UpdateUserApplicantProperty(
                    $input: UpdateUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    updateUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantProperty":

            return /* GraphQL */ `
                mutation DeleteUserApplicantProperty(
                    $input: DeleteUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    deleteUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation CreateUserApplicantAlternateEmailAddresses(
                    $input: CreateUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    createUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation UpdateUserApplicantAlternateEmailAddresses(
                    $input: UpdateUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    updateUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation DeleteUserApplicantAlternateEmailAddresses(
                    $input: DeleteUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    deleteUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantActivity":

            return /* GraphQL */ `
                mutation CreateUserApplicantActivity(
                    $input: CreateUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    createUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantActivity":

            return /* GraphQL */ `
                mutation UpdateUserApplicantActivity(
                    $input: UpdateUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    updateUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantActivity":

            return /* GraphQL */ `
                mutation DeleteUserApplicantActivity(
                    $input: DeleteUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    deleteUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantHealth":

            return /* GraphQL */ `
                mutation CreateUserApplicantHealth(
                    $input: CreateUserApplicantHealthInput!
                    $condition: ModelUserApplicantHealthConditionInput
                ) {
                    createUserApplicantHealth(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantHealth":

            return /* GraphQL */ `
                mutation UpdateUserApplicantHealth(
                    $input: UpdateUserApplicantHealthInput!
                    $condition: ModelUserApplicantHealthConditionInput
                ) {
                    updateUserApplicantHealth(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantHealth":

            return /* GraphQL */ `
                mutation DeleteUserApplicantHealth(
                    $input: DeleteUserApplicantHealthInput!
                    $condition: ModelUserApplicantHealthConditionInput
                ) {
                    deleteUserApplicantHealth(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;
    }
}


const generateMutationByRemoval = (mutation, removeResponseItems) => {
    let string = mutation
    removeResponseItems.forEach(item => {
        string = string.replace(`${item}\n`, '')
    })
    return string
}

export default generateMutation