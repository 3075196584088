import React, {useState} from 'react';
import {Popconfirm} from "antd";
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";

function Deleter({uploadId}) {
    const [deleting, setDeleting] = useState(false)
    const mortgage = useMortgage()
    const handleDelete = async ()=>{
        setDeleting(true)
        await mortgage.mutate({delete: {target: `uploads`, id: uploadId}})
        setDeleting(false)
    }

    if (deleting){
        return <LoadingOutlined/>
    }
    return (
        <Popconfirm title={'Are you sure?'} onConfirm={handleDelete} >
            <DeleteOutlined/>
        </Popconfirm>
    );
}

export default Deleter;