import React, {useContext, useState} from 'react';
import {ConfigProvider, Popconfirm, Table, Tag} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {
    CheckCircleOutlined,
    CheckOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UploadOutlined
} from "@ant-design/icons";
import {green, red} from "@ant-design/colors";
import ConditionEditPanel from "./ConditionEditPanel";
import ConditionPanels from "./ConditionPanels";
import useApplicationUploads from "../../../providers/mortgage/useApplicationUploads";
import {UploadStatuses} from "../../../../models";

function ConditionsTable(props) {
    const mortgage = useMortgage()
    const onDeleteClick = async (id) => {

        await mortgage.mutate({
            delete: {
                target: 'dynamicSections',
                id
            }
        })
    }

    const sections = useApplicationUploads(props.stage, true)
    const columns = [
        {
            title: 'Stage',
            dataIndex: 'sectionStage',
            key: 'sectionStage',
        },
        {
            title: 'Type',
            dataIndex: 'sectionType',
            key: 'sectionType',
        },
        {
            title: 'Title',
            dataIndex: 'sectionTitle',
            key: 'sectionTitle',
        },
        {
            title: 'Pending Uploads',
            key: 'pending',
            render: (text,item) => {
                let myPending = item.uploads.filter(it=>it.status === UploadStatuses.PENDING)
                return myPending.length > 0  ? (
                    <Tag color="geekblue">{myPending.length} Pending</Tag>
                ) : null
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text,item) => {
                return (
                    <div className="d-row gap-15 j-between">
                        {
                            !!item?.record?.sectionChecked && (
                                <span style={{color: green[5]}}><CheckCircleOutlined className="me-6" /> Checked </span>
                            )
                        }
                        <Popconfirm
                            okText="Delete"
                            onConfirm={(e) => {
                                e.stopPropagation();
                                onDeleteClick(item.id)
                            }}
                            onCancel={(e) => {
                                e.stopPropagation()
                            }}
                            icon={
                                <QuestionCircleOutlined
                                    style={{
                                        color: red.primary,
                                    }}
                                />
                            }
                            title="Are you sure you want to delete this condition?">
                            <DeleteOutlined onClick={(e) => e.stopPropagation()}/>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]
    let data = []
    if (mortgage && sections?.sections?.length) {
        data = sections.sections.map(item => ({...item, key: item.name})) //mortgage.dynamicSections.map(item => ({...item, key: item.id}))
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        rowExpandedBg: '#f6f9fe'
                    },
                },
            }}
        >
            <Table className="radius-0 uploads-table" bordered expandable={{
                expandRowByClick: true,
                expandedRowClassName: ()=>"p-0",
                expandedRowRender: (record) => <ConditionPanels section={record} />
            }} pagination={false} columns={columns} dataSource={data}/>
        </ConfigProvider>
    );
}

export default ConditionsTable;