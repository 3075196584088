import React, {useContext} from 'react';
import useMortgage from "../../providers/mortgage/useMortgage"
import { Skeleton} from "antd";

import NewConditionForm from "./conditions-builder/NewConditionForm";

function BuildConditions(props) {
    const mortgage = useMortgage()


    return (
        <Skeleton loading={!mortgage || mortgage==='fetching'}>
            <NewConditionForm close={props.close}/>
        </Skeleton>
    );
}
// new section:




export default BuildConditions;