import React from 'react';
import mortgageStrings from "../../../assets/scripts/mortgage-strings";
import {blue, cyan, green, gridCell, lime, orange, pink, purple} from "../../../app/providers/useSussdPdf";
import {capitaliseFirst, numberToWords, toEuroString} from "../../../assets/scripts/string-functions";
import {
    getApplicantClaimedAnnualOtherIncome, getCurrentAccountDetails, getSavingsAccountDetails
} from "../../../assets/scripts/mortgage-figures";
import {BuildType, EmploymentTypes, MortgageTypes} from "../../../models";
import dayjs from "dayjs";
import {convertHtmlToPlainText} from "../../../assets/scripts/string-functions";
import {App} from "antd";

let accountNoteColors = [
    "pink",
    "cyan",
    "orange",
    "purple"
]

function SussdApplicationForm({
    mortgage,
    uploadSections
}) {
    const app = App.useApp()
    const mStrings = mortgageStrings(mortgage, app)
    // PARTS OF THE DOCUMENT
    function ApplicationType() {
        return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
            8,
            16
        ]}>
            <row>
                <cell>
                    <string color="green">Application Type</string>
                </cell>
                <cell>
                    <string>{mStrings.applicationType()}</string>
                </cell>
            </row>
        </grid>)
    }

    function ApplicationDetails() {
        if (mortgage.twoApplicants) {
            return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                8,
                8,
                8
            ]}>
                <row>
                    <cell>
                        <string color="green">Name</string>
                    </cell>
                    <cell>
                        <string>{`${mStrings.app1.fullName()} (${mStrings.app1.nationality()} Citizen)`}</string>
                    </cell>
                    <cell>
                        <string>{`${mStrings.app2.fullName()} (${mStrings.app2.nationality()} Citizen)`}</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Age</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.age()}</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app2.age()}</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Marital Status</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.maritalStatus()}</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.maritalStatus()}</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Home Status</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.homeStatus()}</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app2.homeStatus()}</string>
                    </cell>
                </row>
            </grid>)
        }
        else {
            return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                8,
                16
            ]}>
                <row>
                    <cell>
                        <string color="green">Name</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.fullName()} ({mStrings.app1.nationality()} Citizen)</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Age</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.age()} years</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Marital Status</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.maritalStatus()}</string>
                    </cell>
                </row>
                <row>
                    <cell>
                        <string color="green">Home Status</string>
                    </cell>
                    <cell>
                        <string>{mStrings.app1.homeStatus()}</string>
                    </cell>
                </row>
            </grid>)
        }

    }

    function DependentDetails() {
        let children = mStrings.totalDependents()
        let combined = mortgage.twoApplicants ? 'Combined Number of Dependents' : 'Number of Dependents'
        let childrenString = capitaliseFirst(numberToWords(children))
        let sum = mortgage.applicant1.financial.hasChildcareCosts ? mortgage.applicant1.financial.monthlyChildcareCosts : 0
        if (mortgage.twoApplicants) {
            sum
                += mortgage.applicant2.financial.hasChildcareCosts ? mortgage.applicant2.financial.monthlyChildcareCosts : 0
        }
        let string = sum > 0 ? toEuroString(sum) + ' per month' : 'None'

        let ChilcareCosts = (<row>
            <cell>
                <string color="green">Childcare Costs</string>
            </cell>
            <cell>
                <string>{string}</string>
            </cell>
        </row>)

        return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
            8,
            16
        ]}>
            <row>
                <cell>
                    <string color="green">{combined}</string>
                </cell>
                <cell>
                    <string>{childrenString}</string>
                </cell>
            </row>
            {children > 0 && ChilcareCosts}
        </grid>)
    }
    function CurrentAccount() {
        let {
            dataSource,
            totalCurrentAverage,
            totalCurrentDifference,
            totalCurrentClosing,
            totalCurrentOpening
        } = getCurrentAccountDetails(mortgage)
        let colorIndex = -1
        dataSource = dataSource.map(row => {
            if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                colorIndex++
                row.adjustment.color = accountNoteColors[colorIndex]
            }
            return row
        })
        return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
            3,
            3,
            6,
            3,
            3,
            3,
            3
        ]}>

            <row>
                <cell>
                    <string color="green">Account Holder</string>
                </cell>
                <cell>
                    <string color="green">Institution</string>
                </cell>
                <cell>
                    <string color="green">Date Range</string>
                </cell>
                <cell>
                    <string color="green">Opening Balance</string>
                </cell>
                <cell>
                    <string color="green">Closing Balance</string>
                </cell>
                <cell>
                    <string color="green">Total Difference</string>
                </cell>
                <cell>
                    <string color="green">Average Monthly</string>
                </cell>
            </row>
            {dataSource.map((row, index) => {
                return <row>
                    <cell>
                        <string>{row.owner}</string>
                    </cell>
                    <cell>
                        <string>{row.account}</string>
                    </cell>
                    <cell>
                        <string>{row.range}</string>
                        <string>{`(${row.diffInMonths} months)`}</string>
                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.openingBalance)}</string>

                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.closingBalance)}</string>
                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.totalDifference)}</string>
                    </cell>
                    <cell>
                        <grid columns={[
                            2,
                            22
                        ]} cell-padding-x="0" cell-padding-y="0">
                            <row>
                                <cell>
                                    <sentence>
                                        {!!row.salaried && <string color="blue">*</string>}
                                        {!!row.accommodationDD && <string color="lime">*</string>}
                                        {(!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") &&
                                            <string color={row.adjustment.color}>*</string>}
                                    </sentence>
                                </cell>
                                <cell>
                                    <string align="right">{toEuroString(row.averageMonthly)}</string>
                                </cell>
                            </row>
                        </grid>
                    </cell>
                </row>
            })}
            <row>
                <cell>

                </cell>
                <cell>

                </cell>
                <cell>
                    <string align="right" color="green">TOTALS</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalCurrentOpening)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalCurrentClosing)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalCurrentDifference)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalCurrentAverage)}</string>
                </cell>
            </row>
        </grid>)
    }
    function CurrentAccountComments() {
        let {dataSource} = getCurrentAccountDetails(mortgage)
        let colorIndex = -1
        dataSource = dataSource.map(row => {
            if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                colorIndex++
                row.adjustment.color = accountNoteColors[colorIndex]
            }
            return row
        })
        dataSource = dataSource.map(section => {
            let finder = it => {
                if (it.name === section.name) {
                    if (section.index) {
                        return it.index === section.index
                    }
                    return !it.index
                }
                return false
            }

            let uploadSection = uploadSections.find(finder)
            if (uploadSection) {
                section.queries = uploadSection.queries
            }
            return section
        })

        let needsExplaining = dataSource.filter(it => {
            return !!it.adjustment && !!it.adjustment.explanation && it.adjustment.explanation !== "";
        })
        let hasQueries = dataSource.filter(it => {
            if (!it?.queries?.length || it.queries.every(it => !!it.excludedOn)) {
                return false
            }
            return true
        })
        // console.log({dataSource})
        // let grid = {
        //     type      : 'grid',
        //     rows      : [],
        //     columnGrid: [
        //         0.5,
        //         23.5
        //     ],
        // }
        //
        // if (dataSource.find(it => !!it.salaried)) {
        //     grid.rows.push([
        //         gridCell('*', {
        //             color     : blue,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell('Salaried Account', {
        //             textAlign: 'left',
        //             paddingY : 0
        //         })
        //     ])
        // }
        // if (dataSource.find(it => !!it.accommodationDD)) {
        //     grid.rows.push([
        //         gridCell('*', {
        //             color     : lime,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell('Accommodation DD', {
        //             textAlign: 'left',
        //             paddingY : 0
        //         })
        //     ])
        // }
        // needsExplaining.forEach((item, index) => {
        //     let color = accountNoteColors[index]
        //     grid.rows.push([
        //         gridCell('*', {
        //             color,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell(item.adjustment.explanation, {paddingY: 0}),
        //     ])
        // })
        // grid.rows.push([
        //     gridCell(hasQueries.length > 0 ? `Notable Items` : `No Notable Items`, {fontWeight: 'bold'}),
        //     gridCell(''),
        // ])
        // hasQueries.forEach((item, index) => {
        //     let innerGrid = generateQueryGrid(item)
        //     grid.rows.push([
        //         gridCell(`•`),
        //         gridCell(innerGrid, {paddingY: 0}),
        //     ])
        // })
        //
        // let rows = [
        //     [
        //         gridCell('Comments', {color: green}),
        //         gridCell(grid),
        //     ]
        // ]
        return <grid vertical-bars="inside" columns={[
            3,
            21
        ]}>

            <row>
                <cell>
                    <string>COMMENTS</string>
                </cell>
                <cell>
                    {!!dataSource.find(it => !!it.salaried) && (
                        <bullet dot={"*"} color={"blue"}>Salaried Account</bullet>)}
                    {!!dataSource.find(it => !!it.accommodationDD) && (
                        <bullet dot={"*"} color={"lime"}>Accommodation Direct Debit</bullet>)}
                    {needsExplaining.map((it, index) => {
                        return <bullet dot={"*"} color={it.adjustment.color}>{it.adjustment.explanation}</bullet>
                    })}
                    {hasQueries.length > 0 ? <bullet>
                        <string>Notable items:</string>
                        {hasQueries.map((item, index) => {
                            return <>

                                <string font-weight="bold">{`${item.owner}'s ${item.account}`}</string>
                                <grid columns={[
                                    5,
                                    5,
                                    14
                                ]} cell-padding-x="0" cell-padding-y="1">
                                    {item.queries.filter(q => !q.excludedOn).map((query, i) => {
                                        let myDate = !!query.date ? dayjs(query.date, "YYYY-MM-DD").format("D MMM YYYY") : ''
                                        return <row>
                                            <cell>{query.description}</cell>
                                            <cell>{myDate}</cell>
                                            <cell>
                                                {(query.lenderAnswer || []).map((it, i) => {
                                                    return <string>{it}</string>
                                                })}
                                            </cell>
                                        </row>
                                    })}
                                </grid>
                            </>
                        })}
                    </bullet> : <bullet>No notable items</bullet>}


                </cell>
            </row>
        </grid>
    }
    function SavingsAccount() {
        let {
            dataSource,
            totalSavingsAverage,
            totalSavingsDifference,
            totalSavingsClosing,
            totalSavingsOpening
        } = getSavingsAccountDetails(mortgage)
        let colorIndex = -1
        dataSource = dataSource.map(row => {
            if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                colorIndex++
                row.adjustment.color = accountNoteColors[colorIndex]
            }
            return row
        })
        return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
            3,
            3,
            6,
            3,
            3,
            3,
            3
        ]}>

            <row>
                <cell>
                    <string color="green">Account Holder</string>
                </cell>
                <cell>
                    <string color="green">Institution</string>
                </cell>
                <cell>
                    <string color="green">Date Range</string>
                </cell>
                <cell>
                    <string color="green">Opening Balance</string>
                </cell>
                <cell>
                    <string color="green">Closing Balance</string>
                </cell>
                <cell>
                    <string color="green">Total Difference</string>
                </cell>
                <cell>
                    <string color="green">Average Monthly</string>
                </cell>
            </row>
            {dataSource.map((row, index) => {
                return <row>
                    <cell>
                        <string>{row.owner}</string>
                    </cell>
                    <cell>
                        <string>{row.account}</string>
                    </cell>
                    <cell>
                        <string>{row.range}</string>
                        <string>{`(${row.diffInMonths} months)`}</string>
                    </cell>
                    <cell>
                        <grid columns={[
                            2,
                            22
                        ]} cell-padding-x="0" cell-padding-y="0">
                            <row>
                                <cell>
                                    <sentence>
                                        {!!row.salaried && <string color="blue">*</string>}
                                        {!!row.accommodationDD && <string color="lime">*</string>}
                                        {(!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") &&
                                            <string color={row.adjustment.color}>*</string>}
                                    </sentence>
                                </cell>
                                <cell>
                                    <string align="right">{toEuroString(row.openingBalance)}</string>
                                </cell>
                            </row>
                        </grid>
                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.closingBalance)}</string>
                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.totalDifference)}</string>
                    </cell>
                    <cell>
                        <string align="right">{toEuroString(row.averageMonthly)}</string>
                    </cell>
                </row>
            })}
            <row>
                <cell>

                </cell>
                <cell>

                </cell>
                <cell>
                    <string align="right" color="green">TOTALS</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalSavingsOpening)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalSavingsClosing)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalSavingsDifference)}</string>
                </cell>
                <cell>
                    <string align="right">{toEuroString(totalSavingsAverage)}</string>
                </cell>
            </row>
        </grid>)
    }
    function SavingsAccountComments() {
        let {dataSource} = getSavingsAccountDetails(mortgage)
        let colorIndex = -1
        dataSource = dataSource.map(row => {
            if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                colorIndex++
                row.adjustment.color = accountNoteColors[colorIndex]
            }
            return row
        })
        dataSource = dataSource.map(section => {
            let finder = it => {
                if (it.name === section.name) {
                    if (section.index) {
                        return it.index === section.index
                    }
                    return !it.index
                }
                return false
            }

            let uploadSection = uploadSections.find(finder)
            if (uploadSection) {
                section.queries = uploadSection.queries
            }
            return section
        })

        let needsExplaining = dataSource.filter(it => {
            return !!it.adjustment && !!it.adjustment.explanation && it.adjustment.explanation !== "";
        })
        let hasQueries = dataSource.filter(it => {
            if (!it?.queries?.length || it.queries.every(it => !!it.excludedOn)) {
                return false
            }
            return true
        })
        // console.log({dataSource})
        // let grid = {
        //     type      : 'grid',
        //     rows      : [],
        //     columnGrid: [
        //         0.5,
        //         23.5
        //     ],
        // }
        //
        // if (dataSource.find(it => !!it.salaried)) {
        //     grid.rows.push([
        //         gridCell('*', {
        //             color     : blue,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell('Salaried Account', {
        //             textAlign: 'left',
        //             paddingY : 0
        //         })
        //     ])
        // }
        // if (dataSource.find(it => !!it.accommodationDD)) {
        //     grid.rows.push([
        //         gridCell('*', {
        //             color     : lime,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell('Accommodation DD', {
        //             textAlign: 'left',
        //             paddingY : 0
        //         })
        //     ])
        // }
        // needsExplaining.forEach((item, index) => {
        //     let color = accountNoteColors[index]
        //     grid.rows.push([
        //         gridCell('*', {
        //             color,
        //             fontSize  : 11,
        //             fontWeight: 'bold',
        //             vAlign    : 'center',
        //             paddingY  : 0
        //         }),
        //         gridCell(item.adjustment.explanation, {paddingY: 0}),
        //     ])
        // })
        // grid.rows.push([
        //     gridCell(hasQueries.length > 0 ? `Notable Items` : `No Notable Items`, {fontWeight: 'bold'}),
        //     gridCell(''),
        // ])
        // hasQueries.forEach((item, index) => {
        //     let innerGrid = generateQueryGrid(item)
        //     grid.rows.push([
        //         gridCell(`•`),
        //         gridCell(innerGrid, {paddingY: 0}),
        //     ])
        // })
        //
        // let rows = [
        //     [
        //         gridCell('Comments', {color: green}),
        //         gridCell(grid),
        //     ]
        // ]
        return <grid vertical-bars="inside" columns={[
            3,
            21
        ]}>

            <row>
                <cell>
                    <string>COMMENTS</string>
                </cell>
                <cell>
                    {!!dataSource.find(it => !!it.salaried) && (
                        <bullet dot={"*"} color={"blue"}>Salaried Account</bullet>)}
                    {!!dataSource.find(it => !!it.accommodationDD) && (
                        <bullet dot={"*"} color={"lime"}>Accommodation Direct Debit</bullet>)}
                    {needsExplaining.map((it, index) => {
                        return <bullet dot={"*"} color={it.adjustment.color}>{it.adjustment.explanation}</bullet>
                    })}
                    {hasQueries.length > 0 ? <bullet>
                        <string>Notable items:</string>
                        {hasQueries.map((item, index) => {
                            return <>

                                <string font-weight="bold">{`${item.owner}'s ${item.account}`}</string>
                                <grid columns={[
                                    5,
                                    5,
                                    14
                                ]} cell-padding-x="0" cell-padding-y="1">
                                    {item.queries.filter(q => !q.excludedOn).map((query, i) => {
                                        let myDate = !!query.date ? dayjs(query.date, "YYYY-MM-DD").format("D MMM YYYY") : ''
                                        return <row>
                                            <cell>{query.description}</cell>
                                            <cell>{myDate}</cell>
                                            <cell>
                                                {(query.lenderAnswer || []).map((it, i) => {
                                                    return <string>{it}</string>
                                                })}
                                            </cell>
                                        </row>
                                    })}
                                </grid>
                            </>
                        })}
                    </bullet> : <bullet>No notable items</bullet>}


                </cell>
            </row>
        </grid>
    }
    function Recommendation() {
        let existing = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'recommendation')
        console.log({existing})
        let recommendation = existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : "NO COMMENT"
        return <grid columns={[24]} vertical-bars="true">
            <row>
                <cell>{recommendation}</cell>
            </row>
        </grid>

    }

    return (<document height="a4" group-gap={10}>

        <group>
            <header>Application Form</header>
            <border color="green" height={4}/>
            <ApplicationType/>
            <border height={2}/>
        </group>

        <group>
        <container bg-color="shaded" padding-x={7} padding-y={4}>
                <string color="green" font-weight="bold">CLIENT DETAILS</string>
            </container>
            <ApplicationDetails/>
            <border height={2}/>
        </group>

        <group>
            <container bg-color="shaded" padding-x={7} padding-y={4}>
                <string color="green" font-weight="bold">DEPENDENT DETAILS</string>
            </container>
            <DependentDetails/>
            <border height={2}/>
        </group>



        <group>

            <container bg-color="shaded" padding-x={7} padding-y={4}>
                <string color="green" font-weight="bold">REVIEW OF CURRENT ACCOUNTS</string>
            </container>
            <CurrentAccount/>
            <CurrentAccountComments/>
            <border height={2}/>
        </group>

        <group>

            <container bg-color="shaded" padding-x={7} padding-y={4}>
                <string color="green" font-weight="bold">REVIEW OF SAVING ACCOUNTS</string>
            </container>
            <SavingsAccount/>
            <SavingsAccountComments/>
            <border height={2}/>
        </group>

        <group>


            <container bg-color="shaded" padding-x={7} padding-y={4}>
                <string color="green" font-weight="bold">RECOMMENDATION:</string>
            </container>
            <Recommendation/>
            <border height={2}/>
        </group>


    </document>)
}

export default SussdApplicationForm;