import React, {useContext, useState} from 'react';
import {Button, Checkbox, Form, Input, Skeleton} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {Auth} from "aws-amplify";
import {FileAddOutlined, LoadingOutlined} from "@ant-design/icons";

function NoteDrawerContent(props) {
    const [form] = Form.useForm()
    const [state,setState] = useState({saving:false})
    const mortgage = useMortgage()
    const handleFormFinish = async (e)=>{
        const valid = await form.validateFields()
        const user = await Auth.currentAuthenticatedUser()
        if (valid){
            setState(s=>({...s, saving: true}))

            await mortgage.mutate({
                create:{
                    target: 'notes',
                    pairs: {
                        author: user.attributes.sub,
                        body: valid.noteText.split(/\r?\n/),
                        usermortgageID: mortgage.id,
                        pinned: valid.pinned || false
                    }
                }
            })
            setState(s=>({...s, saving: false}))
            form.resetFields()
            props.close()
        }

    }
    return (
        <div className="pt-15 pe-15">
            <Skeleton loading={!mortgage}>
                <Form form={form} onFinish={handleFormFinish}>
                    <Form.Item
                        name="noteText"
                        rules={[{required: true, message: `A note is required`}]}
                        className="mb-6">
                        <Input.TextArea placeholder="Create a note..." rows={12}/>
                    </Form.Item>

                    <div className="d-row j-end gap-12 a-center">

                        <Form.Item
                            name="pinned"
                            valuePropName="checked"
                            className="mb-0"
                        >
                            <Checkbox>Pin</Checkbox>
                        </Form.Item>
                        <Form.Item className="mb-0">
                            <Button type="primary" htmlType="submit">
                                {!state.saving && <FileAddOutlined/>}
                                {state.saving && <LoadingOutlined/>}
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Skeleton>
        </div>

    );
}

export default NoteDrawerContent;