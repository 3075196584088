import React, {useState} from 'react';
import {Button} from "antd";
import {FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";

function useFullscreen(props) {
    const [isFull, setIsFull] = useState(false);
    const toggleFullScreen = () => {
        setIsFull(s=>!s)
    };
    function FullscreenButton(props) {
        return <Button className="ms-auto" onClick={toggleFullScreen}>
            {isFull ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        </Button>
    }
    return {
        className: isFull ? 'full-screen' : '',
        Button: FullscreenButton,
        isFull
    }
}

export default useFullscreen;