import React from 'react';
import PropTypes from 'prop-types';
import UploadCollapsedPanel from "../application-stages/_common/uploads/standardised-upload/UploadCollapsedPanel";

ConditionUploads.propTypes = {
    section: PropTypes.object.isRequired
};

function ConditionUploads(props) {

    return (
        <UploadCollapsedPanel {...props} />

    );
}

export default ConditionUploads;