import React, {useContext, useEffect, useMemo, useRef} from 'react';
import useMortgage from "./useMortgage";
import useBackendUsers from "./useBackendUsers";
import {applicationUploadsSections} from "./useApplicationUploads";
import dayjs from "dayjs";
import {Mortgage} from "./MortgageProvider";
export const ApplicationUploadSections = React.createContext({});
function ApplicationUploadsProvider(props) {
    const mortgage = useContext(Mortgage)
    const {me} = useBackendUsers()
    const [sections,setSections] = React.useState({
        setup      : [],
        apply      : [],
        preapproval: [],
        approval   : [],
    })
    let setSectionTimer = useRef(null)

    // DELAY THE CALCULATION OF SECTIONS UNTIL THE MORTGAGE IS LOADED
    useEffect(()=>{
        clearTimeout(setSectionTimer.current)
        setSectionTimer.current = setTimeout(()=>{
            setSections(applicationUploadsSections(mortgage, me))
        }, 10)
        return () => {
            clearTimeout(setSectionTimer.current)
        }
    },[mortgage])
    let timer = useRef(null)

    // UNCHECK COMPLETED ON SECTIONS THAT ARE HAVE PROBLEMS

    let allProblems = sections.apply.flatMap(it => it.problems)

    useEffect(() => {
        clearTimeout(timer.current)
        if (sections.apply.length) {
            timer.current = setTimeout(async () => {
                let now = dayjs()
                let updates = []
                sections.apply.forEach(it => {
                    // uncheck completed if there are any problems (no uploads is not a problem)
                    if (it.problems.length && !!it?.record?.sectionChecked){
                        updates.push({
                            target: `progress[${it.record.id}]`,
                            pairs : {
                                sectionChecked  : null,
                                sectionCheckedBy: null,
                            }
                        })
                        console.log("!!!!!!!! MARKING SECTIONS INCOMPLETE !!!!!!!!")
                    }

                    // if there are active uploads, and there are no problems, set the section to complete
                    if (!it.problems.length && it.record && !it.record.sectionChecked && it.verified.isVerifiable && !!it.verified.uploads.active.length){
                        updates.push({
                            target: `progress[${it.record.id}]`,
                            pairs : {
                                sectionChecked  : now.toISOString(),
                                sectionCheckedBy: "system"
                            }
                        })
                        console.log("!!!!!!!! MARKING SECTIONS COMPLETE !!!!!!!!")
                    }

                    // console.log({it})
                    // if (it?.verified.isVerifiable) {
                    //     if (it.record && it.record.sectionChecked && it.record.lastDocumentDate && (!it.record.lastDocumentDateDismissed && !it?.data?.isClosed) ) {
                    //
                    //         let [amount, unit] = it.verified.minimums.maxAge
                    //         let lastDocDate = dayjs(it.record.lastDocumentDate, 'YYYY-MM-DD')
                    //         if (dayjs.isDayjs(lastDocDate)) {
                    //             if (now.diff(lastDocDate, unit, true) > amount) {
                    //
                    //
                    //             }
                    //         }
                    //     }
                    // }
                })
                console.log({updates})
                if (updates.length) {

                    await mortgage.mutate({update: updates})
                }
            }, 1000)
        }
        return () => {
            clearTimeout(timer.current)
        }
    }, [allProblems]);
    return (<ApplicationUploadSections.Provider value={sections}>
        {props.children}
    </ApplicationUploadSections.Provider>);
}

export default ApplicationUploadsProvider;