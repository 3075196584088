import React from 'react';
import {useNavigate} from "react-router-dom";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import {DatePicker, Form, Input, InputNumber, Modal} from "antd";
import useMortgageDocumentsService from "../../../../providers/mortgage/useMortgageDocumentsService";
import {ReplyRequestTypes} from "../../../../../models";
import {getAttachmentPath} from "../../../emails-manager/AttachmentButtonGroups";
import {LoadingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {currencyFormatter, currencyParser} from "../../../../../assets/scripts/parser-functions";

function LoanOfferModal(props) {
    const navigate = useNavigate()
    const [saving,setSaving] = React.useState(false)
    const mortgage = useMortgage()
    const [form] = Form.useForm()
    const [open, setOpen] = React.useState(true)
    const documentService = useMortgageDocumentsService()
    const handleLoanOfferSubmit= async () => {
        if (!saving) {
            const update = []
            const create = []
            const values = {}
            values.date = await form.getFieldValue('date')
            values.expiry = await form.getFieldValue('expiry')
            let drawdown = await form.getFieldValue('drawdown')
            let term = await form.getFieldValue('finalTerm')
            let rate = await form.getFieldValue('finalRate')
            let product = await form.getFieldValue('finalProduct')
            let loan = await form.getFieldValue('finalLoan')

            let notes = await form.getFieldValue('notes')
            if (notes) {
                values.notes = notes
            }
            if (drawdown){
                values.drawdown = drawdown
            }
            if (term){
                values.term = term
            }
            if (rate){
                values.rate = rate
            }
            if (product){
                values.product = product
            }
            if (loan){
                values.loan = loan
            }
            if (!values.date) {
                console.log('date is required')
                return false
            }
            if (!values.expiry) {
                console.log('expiry is required')
                return false
            }

            // add update mortgage's requirements with the Loan Offer date and expiry date
            update.push({
                target: `submissions[${mortgage.activeSubmission.id}]`,
                pairs: {
                    loanOfferDate: values.date.format("YYYY-MM-DD"),
                    loanOfferExpiryDate: values.expiry.format("YYYY-MM-DD"),
                    loanOfferEmailId: props.email.id,
                    drawdownDate: values.drawdown.format("YYYY-MM-DD"),
                    loanOfferNotes: values.notes ? values.notes.split('\n') : [],
                    finalLoan: values.loan,
                    finalTerm: values.term,
                    finalRate: values.rate,
                    finalProduct: values.product
                }
            })

            update.push({
                target: 'applicationStage',
                value: 'APPROVAL'
            })
            // create a reply request to store the approval in principle email

            // create.push({
            //     target: 'replyRequests',
            //     pairs: {
            //         receivedOn: props.email.datetimeReceived,
            //         mortgageId: mortgage.id,
            //         emailId: props.email.id,
            //         replyTo: values.replyTo,
            //         noteBody: values.notes ? values.notes.split('\n') : [],
            //         requestType: ReplyRequestTypes.APPROVAL,
            //     }
            // })
            // copy the attachment to the user's documents in the lender-documents folder
            const source = getAttachmentPath(props.attachment, props.email.v2Attachments)
            setSaving(true)
            const result = await documentService.copyAttachmentToUserDocs({
                folder: 'lender-documents',
                title: 'Loan Offer',
                isPrivate: false,
                fullFilename: 'loan-offer.pdf',
                stage: 'APPROVAL',
                bucketKey: source,
                submissionID: mortgage.activeSubmission.id
            })
            const result4 = await mortgage.mutate({
                update,
                //create
            })
            setSaving(false)
            navigate(`/client/${mortgage.id}/approval-management`)
        }
    }
    return (
        <Modal
            okButtonProps={{icon: saving ? <LoadingOutlined/>:null}}
            afterClose={props.onCancel}
            destroyOnClose
            open={open}
            title="Mark as Loan Offer"
            onCancel={()=>setOpen(false)}
            onOk={handleLoanOfferSubmit}
        >
            <Form
                form={form}
                requiredMark={false}
                initialValues={{
                   // replyTo: props.email.from,
                    drawdown: mortgage.activeSubmission.drawdownDate ? dayjs(mortgage.activeSubmission.drawdownDate, "YYYY-MM-DD") : null,
                    finalLoan: mortgage.activeSubmission.finalLoan,
                    finalTerm: mortgage.activeSubmission.finalTerm,
                    finalRate: mortgage.activeSubmission.finalRate,
                    finalProduct: mortgage.activeSubmission.finalProduct,
                }}
            >
                <div className="d-row gap-15 j-equal mt-15">
                    <Form.Item name="date" label="Loan Offer Date" required>
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                    <Form.Item name="expiry" label="Expry Date" required>
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                </div>

                <Form.Item name="drawdown" label="Expected Drawdown Date" required>
                    <DatePicker format={"D MMM YYYY"}/>
                </Form.Item>

                <Form.Item name="finalLoan" label="Confirmed Loan Amount" required>
                    <InputNumber formatter={currencyFormatter} parser={currencyParser} prefix="€"/>
                </Form.Item>
                <Form.Item name="finalTerm" label="Confirmed Term" required>
                    <InputNumber suffix="years"/>
                </Form.Item>
                <Form.Item name="finalRate" label="Confirmed Rate" required>
                    <InputNumber suffix="%" precision={2}/>
                </Form.Item>
                <Form.Item name="finalProduct" label="Confirmed Product" required>
                    <Input/>
                </Form.Item>

                <Form.Item name="notes" label="Notes">
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LoanOfferModal;