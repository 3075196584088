import React, {useEffect, useState} from 'react';
import {Col, Row} from "antd";
import {useParams} from "react-router-dom";
import {performGQL} from "../../api-functions/functions";
import EmailsManager from "../../components/EmailsManager";
import AdminDrawerProvider from "../../providers/AdminDrawerProvider";

function EmailsPage(props) {
    // FETCH PAGINATED RECEIVED EMAILS THAT ARE NOT ACTIONED (actionedBy="null" + isReceivedInt=1)

    function getReceivedUnactionedEmails(nextToken){
        const emailsByActionedSorted = /* GraphQL */ `
            query EmailsByActionedSorted(
                $actionedBy: ID!
                $sortDate: ModelStringKeyConditionInput
                $sortDirection: ModelSortDirection
                $filter: ModelAdminEmailsFilterInput
                $limit: Int
                $nextToken: String
            ) {
                emailsByActionedSorted(
                    actionedBy: $actionedBy
                    sortDate: $sortDate
                    sortDirection: $sortDirection
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                ) {
                    items {
                        id
                        from
                        to
                        bcc
                        subject
                        hasAttachments
                        sortDate
                        datetimeReceived
                        datetimeSent
                        messageId
                        originalId
                        isReceived
                        readOn
                        readBy
                        actionedOn
                        actionedBy
                        userapplicantID
                        createdAt
                    }
                    nextToken
                    startedAt
                }
            }
        `;

        const variables = {
            actionedBy: "null",
            limit: 20,
            sortDirection: 'DESC',
            filter: {isReceived: {eq: true}}
        }
        if (nextToken){
            variables.nextToken = nextToken
        }
        return performGQL(variables, emailsByActionedSorted)
    }

    return (
        <AdminDrawerProvider>
            <EmailsManager path="/emails" mortgage={false} getEmails={getReceivedUnactionedEmails}/>
        </AdminDrawerProvider>

    );
}

export default EmailsPage;