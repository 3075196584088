import {addBunchToMap} from "../overlay-utils";

const elements ={
    "elements": [
        {
            "name": "account-number-1",
            "type": "TEXT"
        },
        {
            "name": "account-number-2",
            "type": "TEXT"
        },
        {
            "name": "account-number-3",
            "type": "TEXT"
        },
        {
            "name": "account-number-4",
            "type": "TEXT"
        },
        {
            "name": "account-number-5",
            "type": "TEXT"
        },
        {
            "name": "account-number-6",
            "type": "TEXT"
        },
        {
            "name": "account-number-7",
            "type": "TEXT"
        },
        {
            "name": "account-number-8",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-1",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-1",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-2",
            "type": "TEXT"
        },
        {
            "name": "applicant-name-2",
            "type": "TEXT"
        }
    ],
    "id": "ee9a21e2-d9d4-40c7-8a75-203c4ad50707"
}
export default function (mortgage, config) {
    const accountNumber = () => {
        let map = {}
        let errors = []
        const digits = mortgage.requirement.mortgageAccountNo
        if (!digits) {
            errors.push('No mortgage loan account number')
        }
        else {
            addBunchToMap(map, 'account-number-', digits.toUpperCase().split(''))
        }
        return {map}
    }
    const applicant1Name = () => {
        let map = {}
        let errors = []
        const name = mortgage.applicant1.fullName.toUpperCase()
        if (!name) {
            errors.push('No name for applicant 1')
        } else {
            map['applicant-name-1'] = name
        }
        return {map, errors}
    }
    const applicant2Name = () => {
        let map = {}
        let errors = []
        const name = mortgage.applicant2.fullName.toUpperCase()
        if (!name) {
            errors.push('No name for applicant 2')
        } else {
            map['applicant-name-2'] = name
        }
        return {map, errors}
    }

    const sections = [
        accountNumber(),
        applicant1Name(),
        applicant2Name()
    ]
    return {sections}
}