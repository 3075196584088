import React from 'react';
import {Button, Checkbox, Popconfirm, Table} from "antd";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import useAdminStages from "../../../../../providers/mortgage/useAdminStages";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import useLenderDocumentGenerator from "../../../hooks/useLenderDocumentGenerator";
import ConfigureOverlayModalLauncher from "./ConfigureOverlayModalLauncher";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import { CloseCircleOutlined, FilePdfOutlined, LoadingOutlined} from "@ant-design/icons";
import { red, green} from "@ant-design/colors";

function DocumentsTable({items,stage,done}) {
    const mortgage = useMortgage()
    const stages = useAdminStages()
    const lenderDocGenerator  = useLenderDocumentGenerator()
    const documentsService = useMortgageDocumentsService()
    const [checkedItems, setCheckedItems] = React.useState(items.map(it => it.folder + it.name ))
    const [generating, setGenerating] = React.useState([])
    const handleRowCheckChange = (e) => {
        if (e.target.checked) {
            setCheckedItems([...checkedItems, e.target.name])
        } else {
            setCheckedItems(checkedItems.filter(it => it !== e.target.name))
        }
    }

    const handleAllCheckedChange = (e) => {
        if (e.target.checked) {
            setCheckedItems(items.map(it => it.folder + it.name))
        } else {
            setCheckedItems([])
        }
    }
    const generateChecked = async () => {
        setGenerating(checkedItems)
        const promised = checkedItems.map(it => {
            let item =  items.find(item => item.folder+item.name === it)
            return lenderDocGenerator.generate({...item, stage})
        })
        let result = await Promise.all(promised)
        const create = []
        const errors = []
        result.forEach(tempRow=>{
            if (tempRow){
               create.push({
                   target: 'UserMortgageDocuments',
                   pairs: tempRow
               })
            }
            else{
                errors.push(tempRow)
            }
        })
        if(create.length > 0){
            await mortgage.mutate({create})
        }
        if (errors.length === 0) {
            await stages.setComplete(stage, done)
        }
        setGenerating([])
    }

    const deleteGenerated = async (document) => {
        const rows = mortgage.UserMortgageDocuments.filter(it => it.folder === document.folder && it.fileName === document.fileName && it.stage === stage)
        await mortgage.mutate({
            delete: [
                ...rows.map(it => ({target: 'UserMortgageDocuments', id: it.id}))
            ]
        })
    }

    const openGenerated = async (document) => {
        await documentsService.open(document)
    }

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (text, record) => {
                return capitaliseWords(record.name.replace(/-/g, ' '))
            }
        },
        {
            title: 'Destination',
            key: 'destination',
            render: (text, record) => {
                return capitaliseWords(record.folder.replace(/-/g, ' '))
            },

        },
        {
            title: 'Generated',
            key: 'generated',
            align: 'center',
            render: (text, record) => {
                if (record.generated) {
                        return (
                            <FilePdfOutlined style={{cursor:'pointer', color: green[5]}} onClick={() => openGenerated(record.generated)}>Open</FilePdfOutlined>
                        )
                }
                return <CloseCircleOutlined style={{color:red[5]}}/>
            }
        },
        {
            title: <Checkbox onChange={handleAllCheckedChange} checked={checkedItems.length === items.length}>Check
                All</Checkbox>,
            key: 'type',
            render: (text, record) => {
                let defaultChecked = checkedItems.includes(record.folder+record.name)
                return <Checkbox checked={defaultChecked} name={record.folder+record.name}
                                 onChange={handleRowCheckChange}/>
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                if (generating.includes(record.folder+record.name )){
                    return <LoadingOutlined/>
                }
                if (record.generated) {
                    return (
                        <div className="d-row gap-9">
                            <Popconfirm title="Are you sure?" onConfirm={() => deleteGenerated(record.generated)}>
                                <Button danger size="small">Delete</Button>
                            </Popconfirm>
                        </div>
                    )
                }
                if (record.overlay || record.fill) {
                    return <ConfigureOverlayModalLauncher record={record}/>
                }
                return null
            },
        },
    ];

    const summaryRow = (
        <Table.Summary.Row key={"footer"}>
            <Table.Summary.Cell index={0} colSpan={3}>

            </Table.Summary.Cell>
            <Table.Summary.Cell index={0} colSpan={2}>
                {/* Your custom footer content */}
                <Button size="small" onClick={generateChecked}>Generate</Button>
            </Table.Summary.Cell>
        </Table.Summary.Row>
    );
    return (
        <Table columns={columns} dataSource={items} pagination={false} summary={() => summaryRow}/>
    )
}

export default DocumentsTable;