import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    closestCenter,
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {SortableItem} from './SortableItem';
import {Item} from './task-item/Item';
import {MyAdminTasks} from "../../../providers/MyAdminTasksProvider";

function SortableSheet(props) {
    const [activeId, setActiveId] = useState(null);
    const [sortableItems, setSortableItems] = useState([]);
    const [notSortableItems, setNotSortableItems] = useState([]);
    const {operations} = useContext(MyAdminTasks);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const mySort = ((a,b) => {
        // Check if sortPosition is present in both objects
        if (a.userSortPosition.hasOwnProperty(props.mySub) && b.userSortPosition.hasOwnProperty(props.mySub)) {
            // Sort by sortPosition in descending order
            return b.userSortPosition[props.mySub] - a.userSortPosition[props.mySub];
        } else if (a.userSortPosition.hasOwnProperty(props.mySub)) {
            // If only a has sortPosition, it comes before b
            return -1;
        } else if (b.userSortPosition.hasOwnProperty(props.mySub)) {
            // If only b has sortPosition, it comes before a
            return 1;
        } else {
            // If none have sortPosition, sort by createdAt in descending order (newest first)
            return new Date(b.createdAt) - new Date (a.createdAt);
        }
    })
    function getFloatBetweenZeroAndOneExclusive(index, arrayLength) {
        // Calculate the interpolation factor based on the index
        const interpolationFactor = index / (arrayLength - 1);

        // Map the interpolation factor to the range [0.9, 0.1] and round to 2 decimal places
        const floatValue = (0.9 - (0.8 * interpolationFactor)).toFixed(2);

        return parseFloat(floatValue); // Convert the result back to a number
    }
    let createTaskTime = null
    const fix = async () => {
        clearTimeout(createTaskTime)
        createTaskTime = setTimeout(async () => {
            const needsUpdating = sortableItems
                .filter(it => it.currentOwner === props.mySub)
                .filter(it => !it.userSortPosition.hasOwnProperty(props.mySub))

            const promises = needsUpdating.map((it, i) => {
                let copy = it.userSortPosition || {}
                copy[props.mySub] = getFloatBetweenZeroAndOneExclusive(i, needsUpdating.length)
                return operations.update({
                    id: it.id,
                    _version: it._version,
                    userSortPosition: JSON.stringify(copy)
                })
            })
            await Promise.all(promises)
        }, 1000)

        return () => {
            clearTimeout(createTaskTime)
        }
    }
    useEffect(() => {
        setSortableItems(props.tasks.filter(it=>!it.completedOn))
        setNotSortableItems(props.tasks.filter(it=>it.completedOn))
        if (props.sorting) {
            fix()
        }
    }, [props.sorting]);

    const sItems = (props.sorting ? sortableItems : props.tasks.filter(it=>!it.completedOn) || []).sort(mySort)
    // get unique folder names from items
    return (
        <>

        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={sItems}
                strategy={verticalListSortingStrategy}
            >
                {sItems.map(task => <SortableItem
                    sorting={props.sorting}
                    folders={props.folders}
                    key={task.id}
                    id={task.id}
                    task={task}
                    completed={!!task.completedOn}
                    disabled={!props.sorting || !!task.completedOn}
                    />
                )}
            </SortableContext>
            <DragOverlay>
                {activeId ? <Item id={activeId} task={sItems.find(task => task.id === activeId)}/> : null}
            </DragOverlay>
        </DndContext>
            <hr/>
            {
                notSortableItems.sort(mySort).map(task => <Item key={task.id} task={task} />)
            }
        </>
    );

    function handleDragStart(event) {
        const {active} = event;
        setActiveId(active.id);
    }
    function findMidpoint(min, max) {
        // Calculate the midpoint between min and max
        let midpoint = (min + max) / 2;
        let winningMidpoint = 0
        // Loop until the rounded midpoint is outside the range [min, max]
        while (midpoint < 1 && midpoint > 0 && midpoint > min && midpoint < max) {

            // Round the midpoint up by one decimal point
            winningMidpoint = midpoint
            midpoint = parseFloat(roundUpToOneDecimalPlace(midpoint));

        }

        // Return the rounded midpoint
        return winningMidpoint;
    }
    function roundUpToOneDecimalPlace(number) {
        const currentDecimals = (number.toString().split('.')[1] || '')
        const decimalPlaces = currentDecimals.length
        if (decimalPlaces > 1) {
            return parseFloat(number).toFixed(decimalPlaces - 1);
        } else {
            return 0
        }
    }


    function handleDragEnd(event) {
        const {active, over} = event;
        if (over) {
            if (active.id !== over.id) {
                setSortableItems((items) => {
                    const activeIndex = items.findIndex(it => it.id === active.id);
                    const overIndex = items.findIndex(it => it.id === over.id);
                    const newArray = arrayMove(items, activeIndex, overIndex);
                    const movedItem = newArray[overIndex]
                    const prevItem = newArray[overIndex - 1]
                    const prevItemPosition = prevItem ? parseFloat(prevItem.userSortPosition[props.mySub]) : 0.9
                    const nextItem = newArray[overIndex + 1]
                    const nextItemPosition = nextItem ? parseFloat(nextItem.userSortPosition[props.mySub]) : 0.1
                    const newPosition = findMidpoint(nextItemPosition, prevItemPosition)
                    const existingPositions = movedItem.userSortPosition || {}
                    const newPositions = {...existingPositions, [props.mySub]: newPosition}
                    // update the item in state
                    let copyItems = newArray
                    operations.update({
                        id: movedItem.id,
                        _version: movedItem._version,
                        userSortPosition: JSON.stringify(newPositions)
                    })
                    // update the item in system
                    return newArray.map(it=>{
                        if (it.id === movedItem.id) {
                            return {...it, userSortPosition: newPositions}
                        }
                        return it
                    });
                });
            }
        }
        setActiveId(null);
    }
}

export default SortableSheet;