import React from 'react';
import {Alert, Card} from "antd";
import {geekblue, gray, green, magenta, purple, red} from "@ant-design/colors";
import {TableHeading} from "../FiguresUsed";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {useMortgageFigures} from "../useMortgageFigures";
import {balanceOfFundsFunds} from "../../funding/BalanceOfFunds";

function Summary() {
    const mortgage = useMortgage()

    const loan = mortgage.requirement.loanRequired

    let ltv = Math.round(loan/mortgage.properties[0].propertyValue * 1000) / 10
    return (<>
        <div className="d-row j-equal gap-15">

            <div className="d-col gap-15">
                <Card styles={{body: {padding: 8}}}>
                <TableHeading title="Value" color={gray.primary} total={mortgage.properties[0].propertyValue}/>
                </Card>
                <Card styles={{body: {padding: 8}}}>
                    <TableHeading title="Loan" color={magenta.primary} total={loan}/>
                </Card>
            </div>
            <Card styles={{body: {padding: 8}}}>
                <div className="d-row j-between a-start">
                    <div style={{
                        color   : geekblue.primary,
                        fontSize: 14,
                        fontWeight: 'bold'
                    }}>LTV</div>
                    <div style={{
                        color   : geekblue.primary,
                        fontSize: 36,
                        lineHeight:1
                    }}>{ltv} %</div>
                </div>
            </Card>
        </div>


    </>);

}

export default Summary;