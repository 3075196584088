import React from 'react';
import {lime, orange, pink, purple} from "../../../app/providers/useSussdPdf";
import {capitaliseFirst, convertHtmlToPlainText, numberToWords, toCurrencyString, toEuroString} from "../../../assets/scripts/string-functions";
import {ApplicationTypes, BuildType, EmploymentBases, EmploymentTypes, MortgageTypes, PersonalAccommodationSituations} from "../../../models";
import dayjs from "dayjs";
import {balanceOfFundsCosts, balanceOfFundsFunds} from "../../../app/components/client/application-stages/_common/funding/BalanceOfFunds";
import countries from "../../../assets/scripts/countries.json";
import {legacyIncome} from "../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";

let accountNoteColors = [
        "pink",
        "cyan",
        "orange",
        "purple"
]

function SussdCoverNote({
        mortgage,
        euroCurrentAccounts,
        euroSavingsAccounts,
        nonEuroCurrentAccounts,
        nonEuroSavingsAccounts,
        euroInvestments,
        nonEuroInvestments,
        app,
        submission
})
{
        //const mSstrings = mortgageStrings(mortgage, app)
        const getAppStrings = (n) => {
                let appKey = `app${n}`
                let applicant = mortgage[`applicant${n}`]
                let country = countries.find(it => it.alpha_2_code === applicant.personal.nationality)
                let nationality = country ? country.nationality : 'NO COUNTRY SPECIFIED!!'
                let legacy = legacyIncome(mortgage)
                let employment = legacy ? applicant.employment : applicant.employmentIncome[0]
                let isEmployed = [
                        EmploymentTypes.EMPLOYED,
                        EmploymentTypes.SELFEMPLOYED
                ].includes(employment.employmentType)
                return {
                        nameAndNationality: `${applicant.fullName} (${nationality} Citizen)`,
                        age               : applicant.age.toString() + ' years',
                        maritalStatus     : capitaliseFirst((applicant.personal.maritalStatus || '').toLowerCase()),
                        homeStatus        : (() => {
                                if (mortgage.twoApplicants && mortgage.requirement.cohabiting && n === 2) {
                                        return 'Living together'
                                }
                                switch (applicant.personal.accommodationSituation) {
                                        case PersonalAccommodationSituations.RENTED :
                                                return 'Renting'
                                        case PersonalAccommodationSituations.RELATIVES :
                                                return 'Lives with relatives'
                                        case PersonalAccommodationSituations.OWNED:
                                                return 'Owns home';
                                        default:
                                                return "???????"
                                }
                        })(),
                        employment        : {
                                status           : (() => {
                                        if (employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                                                return 'Self Employed'
                                        }
                                        else if (employment.employmentType === EmploymentTypes.EMPLOYED) {
                                                switch (employment.employmentBasis) {
                                                        case EmploymentBases.FULLTIME:
                                                                return 'Full time employed';
                                                        case EmploymentBases.PARTTIME:
                                                                return 'Part time employed';
                                                        case EmploymentBases.CONTRACT:
                                                                return 'Contract employed';
                                                        case EmploymentBases.TEMPFULLTIME:
                                                                return 'Temporary Full-time Employment';
                                                        case EmploymentBases.TEMPPARTTIME:
                                                                return 'Temporary Part-time Employment';
                                                }
                                        }
                                        else {
                                                return capitaliseFirst(employment.employmentType.toLowerCase())
                                        }
                                })(),
                                occupation       : isEmployed && employment.positionHeld ? employment.positionHeld : '',
                                employerName     : isEmployed && employment.currentEmployerName ? employment.currentEmployerName : '',
                                startDate        : isEmployed && employment.currentEmployerStartDate ? dayjs(employment.currentEmployerStartDate, 'YYYY-MM-DD').format('D MMMM YYYY') : '',
                                annualGrossBasic : isEmployed ? toEuroString(figures[`basic${n}`]) : '',
                                monthlyGrossBasic: isEmployed ? toEuroString(figures[`basic${n}`] / 12) : '',
                                variableDetails  : (() => {
                                        let claimedVariable = mortgage.figures.unverified[`variable${n}`]
                                        let testedVariable = figures[`variable${n}`]
                                        let appVariableString = 'None';
                                        if (claimedVariable > 0) {
                                                appVariableString = !testedVariable ? 'Not utilised' : testedVariable
                                        }
                                        return appVariableString
                                })(),
                                variableComments : (() => {
                                        let existing = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === `app${n}VariableIncome`)
                                        return existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : 'NO COMMENT'
                                })(),

                        }
                }
        }
        let figures = {
                ...submission.data.figures,
                savingsTotal           : (submission.data.figures.savings1 || 0) + (submission.data.figures?.savings2 || 0),
                accommodationCostsTotal: (submission.data.figures.accommodationCosts1 || 0) + (submission.data.figures?.accommodationCosts2 || 0),
                clearingLoansTotal     : (submission.data.figures.clearingLoans1 || 0) + (submission.data.figures?.clearingLoans2 || 0),
                otherPRATotal          : (submission.data.figures.otherPRA1 || 0) + (submission.data.figures?.otherPRA2 || 0),
                basicTotal             : (submission.data.figures.basic1 || 0) + (submission.data.figures?.basic2 || 0),
        }
        let override = {
                price: figures.provisionalValue,
                loan : figures.provisionalLoan
        }
        let {
                    stampDuty,
                    legalFees,
                    propertyValuation,
                    totalCosts
            } = balanceOfFundsCosts(mortgage, override)
        let {
                    gifts,
                    helpToBuy,
                    firstHome,
                    requiredSavings,
                    bookingDepositPaid,
                    contractDepositPaid
            } = balanceOfFundsFunds(mortgage, override)

        let strings = {
                applicationType: (() => {
                        let applicationType = 'Refinance'
                        if (mortgage.mortgageType !== MortgageTypes.REFINANCE) {
                                switch (mortgage.applicationType) {
                                        case ApplicationTypes.FIRSTTIME:
                                                applicationType = 'First Time Buyer';
                                                if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
                                                        applicationType += '- Self Build'
                                                }
                                                break;
                                        case ApplicationTypes.MOVINGHOME:
                                                applicationType = 'Moving Home';
                                                break;
                                        case ApplicationTypes.SECONDTIME:
                                                applicationType = 'Second Time Buyer';
                                                break;
                                        case ApplicationTypes.LANDLORD:
                                                applicationType = 'Buy To Let';
                                                break;
                                }
                        }
                        return applicationType
                })(),
                children       : (() => {

                        let count = mortgage.applicant1.personal.countDependents
                        if (mortgage.twoApplicants) {
                                count += mortgage.applicant2.personal.countDependents
                        }
                        return {
                                numberOfDependentsCombined: count,
                                totalInWords              : capitaliseFirst(numberToWords(count)),
                                totalChildcareCosts       : (() => {
                                        let sum = mortgage.applicant1.financial.hasChildcareCosts ? mortgage.applicant1.financial.monthlyChildcareCosts : 0
                                        if (mortgage.twoApplicants) {
                                                sum += mortgage.applicant2.financial.hasChildcareCosts ? mortgage.applicant2.financial.monthlyChildcareCosts : 0
                                        }
                                        return sum > 0 ? toEuroString(sum) + ' per month' : 'None'
                                })(),
                                numberOfDependentsLabel   : mortgage.twoApplicants ? 'Combined Number of Dependents' : 'Number of Dependents'
                        }
                })(),
                property       : {
                        description       : (() => {
                                if (mortgage.properties[0].addressKnown) {
                                        return mortgage.properties[0].address
                                }
                                return 'Not yet identified'
                        })(),
                        descriptionLabel  : (() => {
                                let detailsOfPropertyLabel = 'Details of Property'
                                if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
                                        detailsOfPropertyLabel = 'Details of Property to Purchase'
                                        if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
                                                detailsOfPropertyLabel = 'Location of Site'
                                        }
                                }
                                return detailsOfPropertyLabel
                        })(),
                        propertyValue     : toEuroString(figures.provisionalValue),
                        propertyValueLabel: (() => {
                                let actualPropertyValueLabel = 'Property Value'
                                if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
                                        if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
                                                actualPropertyValueLabel = 'Estimated Final Property Value'
                                        }
                                }
                                return actualPropertyValueLabel
                        })()
                },
                loan           : {
                        sought: toEuroString(figures.provisionalLoan),
                        term  : figures.provisionalTerm + ' years',
                        ltv   : (Math.round(figures.provisionalLoan / (figures.provisionalValue) * 100)) + "%",
                        lti   : (Math.round(figures.provisionalLoan / (figures.basicTotal + figures.variableTotal) * 100) / 100) + "×",
                        bof   : (() => {
                                let costRows = [
                                        {
                                                key  : 'Purchase Price',
                                                value: toEuroString(figures.provisionalValue)
                                        },
                                        {
                                                key  : 'Stamp Duty',
                                                value: toEuroString(stampDuty)
                                        },
                                        {
                                                key  : 'Legal Fees',
                                                value: toEuroString(legalFees)
                                        },
                                        {
                                                key  : 'Valuation',
                                                value: toEuroString(propertyValuation)
                                        }
                                ]
                                const fundRows = [
                                        {
                                                key  : 'Mortgage',
                                                value: toEuroString(figures.provisionalLoan)
                                        },
                                        {
                                                key  : 'Own Contribution',
                                                value: toEuroString(requiredSavings)
                                        },
                                ]
                                if (!!gifts) {
                                        fundRows.push({
                                                key  : 'Gifts',
                                                value: toEuroString(gifts)
                                        })
                                }
                                if (!!helpToBuy) {
                                        fundRows.push({
                                                key  : 'Help to Buy Scheme',
                                                value: toEuroString(helpToBuy)
                                        })
                                }
                                if (!!firstHome) {
                                        fundRows.push({
                                                key  : 'First Home Scheme',
                                                value: toEuroString(firstHome)
                                        })
                                }

                                if (!!bookingDepositPaid) {
                                        fundRows.push({
                                                key  : 'Booking Deposit Paid',
                                                value: toEuroString(bookingDepositPaid)
                                        })
                                }

                                if (!!contractDepositPaid) {
                                        fundRows.push({
                                                key  : 'Contract Deposit Paid',
                                                value: toEuroString(contractDepositPaid)
                                        })
                                }

                                const maxLength = Math.max(costRows.length, fundRows.length);
                                while (costRows.length < maxLength) {
                                        costRows.push({
                                                key  : '',
                                                value: ''
                                        });
                                }
                                while (fundRows.length < maxLength) {
                                        fundRows.push({
                                                key  : '',
                                                value: ''
                                        });
                                }
                                costRows.push({
                                        key  : 'TOTAL',
                                        value: toEuroString(totalCosts)
                                })
                                fundRows.push({
                                        key  : 'TOTAL',
                                        value: toEuroString(totalCosts)
                                })

                                return fundRows.map((row, i) => {
                                        return [
                                                costRows[i].key,
                                                costRows[i].value,
                                                row.key,
                                                row.value
                                        ]
                                })
                        })(), //stressedRepayment: mSstrings.stressedRepayment(),
                },
                pra            : (() => {
                        let existing = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'repaymentCapacity')

                        let monthlySavingsLabel = mortgage.twoApplicants ? 'Combined Monthly Savings' : 'Monthly Savings'
                        let monthlyAccommodationLabel = mortgage.twoApplicants ? 'Combined Monthly Accommodation' : 'Monthly Accommodation'
                        let monthlyLoansDiscontinuingLabel = mortgage.twoApplicants ? 'Combined Monthly Loans Discontinuing' : 'Monthly Loans Discontinuing'
                        let total = 0
                        let rows = []

                        if (figures.savingsTotal > 0) {
                                rows.push({
                                        key  : monthlySavingsLabel,
                                        value: figures.savingsTotal
                                })
                        }
                        if (figures.accommodationCostsTotal > 0) {
                                rows.push({
                                        key  : monthlyAccommodationLabel,
                                        value: figures.accommodationCostsTotal
                                })
                        }
                        if (figures.clearingLoansTotal > 0) {
                                rows.push({
                                        key  : monthlyLoansDiscontinuingLabel,
                                        value: figures.clearingLoansTotal
                                })
                        }
                        if (figures.otherPRATotal > 0) {
                                rows.push({
                                        key  : 'Other PRA',
                                        value: figures.otherPRATotal
                                })
                        }

                        rows.forEach(row => {
                                total += row.value
                        })
                        rows.push({
                                key  : 'Proven Repayment Ability',
                                value: total
                        })
                        rows = rows.map(row => {
                                return {
                                        ...row,
                                        value: toEuroString(row.value)
                                }
                        })
                        //TODO - add in the stressed repayment
                        return {
                                rows,
                                comment: existing ? convertHtmlToPlainText(existing.html) : 'NO COMMENT'
                        }
                })(),
                app1           : getAppStrings(1),
                recommendation : (() => {
                        let existing = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'recommendation')
                        return existing && existing.html !== "" ? convertHtmlToPlainText(existing.html) : "NO COMMENT"
                })(),
                results        : (() => {
                        switch (submission.lender) {
                                case 'ptsb':
                                        return [
                                                [
                                                        {'Mortgage Payment': toEuroString(submission.result.items.find(i => i.key === 'Mortgage Payment').value)},
                                                        {'Mortgage Interest Rate': submission.result.items.find(i => i.key === 'Interest Rate').value}
                                                ],
                                                [
                                                        {'Stressed Mortgage Payment': toEuroString(submission.result.items.find(i => i.key === 'Stressed Mortgage Payment').value)},
                                                        {'Cash For Living': toEuroString(submission.result.items.find(i => i.key === 'Cash For Living').value)}
                                                ],
                                                [
                                                        {'Proven Repayment Ability': submission.result.items.find(i => i.key === 'Proven Repayment Ability').value},
                                                        {'Total Net Monthly Income': toEuroString(submission.result.items.find(i => i.key === 'Total Net Monthly Income').value)}
                                                ],
                                                [
                                                        {'Max Amount Available For Loans': toEuroString(submission.result.items.find(i => i.key === 'Max Amount Available For Loans').value)},
                                                        {'Max Nets': submission.result.items.find(i => i.key === 'Max Nets').value}
                                                ],
                                                [
                                                        {'MDI Requirement': toEuroString(submission.result.items.find(i => i.key === 'MDI Requirement').value)},
                                                        {'Actual Nets': submission.result.items.find(i => i.key === 'Actual Nets').value}
                                                ]
                                        ]
                                case 'ics':
                                        return [
                                                [
                                                        {'LTV Limit': submission.result.items.find(i => i.key === 'LTV Limit').value},
                                                        {'Income Multiple Limit': submission.result.items.find(i => i.key === 'Income Multiple Limit').value}
                                                ],
                                                [
                                                        {'LTV': submission.result.items.find(i => i.key === 'LTV').value},
                                                        {'Income Multiple': submission.result.items.find(i => i.key === 'Income Multiple').value}
                                                ],
                                                [
                                                        {'NDI Required': toEuroString(submission.result.items.find(i => i.key === 'NDI Required').value)},
                                                        {'MSR Limit': submission.result.items.find(i => i.key === 'MSR Limit').value},
                                                ],
                                                [
                                                        {'NDI Available': toEuroString(submission.result.items.find(i => i.key === 'NDI Available').value)},
                                                        {'MSR': submission.result.items.find(i => i.key === 'MSR').value}
                                                ],
                                                [
                                                        {'NDI Surplus/Shortfall': toEuroString(submission.result.items.find(i => i.key === 'NDI Surplus/Shortfall').value)},
                                                        {'': ''}
                                                ],
                                        ]
                                case 'haven':
                                        return [
                                                [
                                                        {'Estimated Monthly Payment': toEuroString(submission.result.items.find(i => i.key === 'Estimated Monthly Payment').value)},
                                                        {'Required NDI': toEuroString(submission.result.items.find(i => i.key === 'Required NDI').value)},

                                                ],
                                                [
                                                        {'Stressed Mortgage Payment': toEuroString(submission.result.items.find(i => i.key === 'Stressed Payments').value)},
                                                        {'Actual NDI': toEuroString(submission.result.items.find(i => i.key === 'Actual NDI').value)},

                                                ],
                                                [
                                                        {'Repayment Capacity': toEuroString(submission.result.items.find(i => i.key === 'Repayment Capacity').value)},
                                                        {'NDI %': submission.result.items.find(i => i.key === 'NDI %').value},
                                                ],
                                                [
                                                        {'Repayment Deficit/Surplus': toEuroString(submission.result.items.find(i => i.key === 'Repayment Deficit/Surplus').value)},
                                                        {'Loan To Income': submission.result.items.find(i => i.key === 'Loan To Income').value}
                                                ],
                                        ]
                                default:
                                        return []
                        }
                })()
        }
        if (mortgage.twoApplicants) {
                strings.app2 = getAppStrings(2)
        }

        // HELPER FUNCTIONS
        function ApplicantRow({
                label,
                apps
        })
        {
                return (<row>
                        <cell>
                                <string color="green">{label}</string>
                        </cell>
                        {apps.map((app, i) => {
                                return <cell>
                                        <string>{app}</string>
                                </cell>
                        })}
                </row>)
        }

        // LENDER SPECIFIC PARTS OF THE DOCUMENT
        function RepaymentAbilityHaven() {
                let combined_savings = mortgage.twoApplicants ? 'Combined Monthly Savings' : 'Monthly Savings'
                let combined_accomodation = mortgage.twoApplicants ? 'Combined Monthly Accomodation' : 'Monthly Accomodation'
                let praRows = strings.praRows()
                let tot = 0
                let existing1 = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'repaymentCapacity')
                let comment1 = existing1 ? convertHtmlToPlainText(existing1.html) : 'NO COMMENT'
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        <row>
                                <cell>
                                        <string color="green">Estimated Stressed Mortgage Payment</string>
                                </cell>
                                <cell>
                                        <string>{strings.stressed()}</string>
                                </cell>
                        </row>

                        {praRows.map((row, i) => {
                                tot += row.value
                                return <row>
                                        <cell>
                                                <string color="green">{row.key}</string>
                                        </cell>
                                        <cell>
                                                <string>{toEuroString(row.value)}</string>
                                        </cell>
                                </row>
                        })}
                        <row>
                                <cell>
                                        <string color="green">Proven Repayment Ability</string>
                                </cell>
                                <cell>
                                        <string>{strings.repaymentCapacity()}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">
                                                Comments
                                        </string>
                                </cell>
                                <cell>
                                        <string>{comment1}</string>
                                </cell>
                        </row>
                </grid>)
        }
        function RepaymentAbilityPTSB() {
                console.log({strings})
                let existing1 = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'repaymentCapacity')
                let comment1 = existing1 ? convertHtmlToPlainText(existing1.html) : 'NO COMMENT'
                let combined_savings = mortgage.twoApplicants ? 'Combined Monthly Savings' : 'Monthly Savings'
                let combined_accomodation = mortgage.twoApplicants ? 'Combined Monthly Accomodation' : 'Monthly Accomodation'
                let loans_discontinuing = mortgage.twoApplicants ? 'Combined Monthly Loans Discontinuing' : 'Monthly Loans Discontinuing'
                let praClearingLoans = strings.praClearingLoans()
                console.log({praClearingLoans})
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        <row>
                                <cell>
                                        <string color="green">{combined_savings}</string>
                                </cell>
                                <cell>
                                        <string>{strings.praCombinedSavings()}</string>
                                </cell>
                        </row>
                        {strings.praCombinedAccommodation() && (<row>
                                <cell>
                                        <string color="green">{combined_accomodation}</string>
                                </cell>
                                <cell>
                                        <string>{strings.praCombinedAccommodation()}</string>
                                </cell>
                        </row>)}

                        {strings.praClearingLoans() && (<row>
                                <cell>
                                        <string color="green">{loans_discontinuing}</string>
                                </cell>
                                <cell>
                                        <string>{strings.praClearingLoans()}</string>
                                </cell>
                        </row>)}
                        {strings.praOther() && (<row>
                                <cell>
                                        <string color="green">Other PRA</string>
                                </cell>
                                <cell>
                                        <string>{strings.praOther()}</string>
                                </cell>
                        </row>)}
                        <row>
                                <cell>
                                        <string color="green">Proven Repayment Ability</string>
                                </cell>
                                <cell>
                                        <string>{strings.pra()}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">Estimated Mortgage Payment</string>
                                </cell>
                                <cell>
                                        <string>{strings.mortgagePayment()}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">
                                                Comments
                                        </string>
                                </cell>
                                <cell>
                                        <string>{comment1}</string>
                                </cell>
                        </row>
                </grid>)
        }
        function XlsTests() {
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        4,
                        8,
                        4
                ]}>
                        {strings.results.map((row, i) => {
                                return (<row>
                                        <cell>
                                                <string color="green">{Object.keys(row[0])[0]}</string>
                                        </cell>
                                        <cell>
                                                <string>{Object.values(row[0])[0]}</string>
                                        </cell>
                                        <cell>
                                                <string color="green">{Object.keys(row[1])[0]}</string>
                                        </cell>
                                        <cell>
                                                <string>{Object.values(row[1])[0]}</string>
                                        </cell>
                                </row>)
                        })}
                </grid>)
        }
        // function LoanAnalysisPTSB() {
        //     return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
        //         8,
        //         4,
        //         8,
        //         4
        //     ]}>
        //         {strings.results.map((row, i) => {
        //             return (<row>
        //                 <cell>
        //                     <string color="green">{Object.keys(row[0])[0]}</string>
        //                 </cell>
        //                 <cell>
        //                     <string>{Object.values(row[0])[0]}</string>
        //                 </cell>
        //                 <cell>
        //                     <string color="green">{Object.keys(row[1])[0]}</string>
        //                 </cell>
        //                 <cell>
        //                     <string>{Object.values(row[1])[0]}</string>
        //                 </cell>
        //             </row>)
        //         })}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Term Sought</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.loan.term}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Stressed Mortgage Payment</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        /!*<string align="right">{strings.stressed()}</string>*!/*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Mortgage Payment</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.mortgagePayment()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Proposed LTV</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.ltv()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Interest Rate</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.rate()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Total Net Monthly Income</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.monthlyIncome()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Proven Repayment Ability</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.pra()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Maximum Amount Available for Loans</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.maxForLoans()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Cash for Living</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.cashForLiving()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Actual Nets</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.actualNets()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Maximum Nets</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.maxNets()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">MDI Requirement</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.mdiRequirement()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //         {/*<row>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green">Loan to Income</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right">{strings.loanToIncome()}</string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string color="green"></string>*/}
        //         {/*    </cell>*/}
        //         {/*    <cell>*/}
        //         {/*        <string align="right"></string>*/}
        //         {/*    </cell>*/}
        //         {/*</row>*/}
        //     </grid>)
        // }
        // function LoanAnalysisHaven() {
        //     return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
        //         8,
        //         4,
        //         8,
        //         4
        //     ]}>
        //         <row>
        //             <cell>
        //                 <string color="green">Loan to Income</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{strings.loanToIncome().toFixed(2)}</string>
        //             </cell>
        //             <cell>
        //                 <string color="green">Within CBI LTI Guidelines</string>
        //             </cell>
        //             <cell>
        //                 <string
        //                     align="right">{strings.xlsResults.message === "Within CBI LTI Guidelines" ? "Yes" : "No"}</string>
        //             </cell>
        //         </row>
        //         <row>
        //             <cell>
        //                 <string color="green">Required NDI</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{toEuroString(strings.xlsResults.requiredNdi)}</string>
        //             </cell>
        //             <cell>
        //                 <string color="green">Stressed Payments</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{toEuroString(strings.xlsResults.stressedPayments)}</string>
        //             </cell>
        //         </row>
        //         <row>
        //             <cell>
        //                 <string color="green">Actual NDI</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{toEuroString(strings.xlsResults.actualNdi)}</string>
        //             </cell>
        //             <cell>
        //                 <string color="green">LTV</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{strings.ltv()}</string>
        //             </cell>
        //         </row>
        //         <row>
        //             <cell>
        //                 <string color="green">NDI %</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{strings.xlsResults.ndi}</string>
        //             </cell>
        //             <cell>
        //                 <string color="green">Repayment Deficit/Surplus</string>
        //             </cell>
        //             <cell>
        //                 <string align="right">{toEuroString(strings.xlsResults.repaymentDeficit)}</string>
        //             </cell>
        //         </row>
        //     </grid>)
        // }

        // STANDARD PARTS OF THE DOCUMENT
        function ApplicationType() {
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        <row>
                                <cell>
                                        <string color="green">Application Type</string>
                                </cell>
                                <cell>
                                        <string>{strings.applicationType}</string>
                                </cell>
                        </row>
                </grid>)
        }
        function ApplicationDetails() {
                return <grid vertical-bars="inside" horizontal-bars="inside" columns={mortgage.twoApplicants ? [
                        8,
                        8,
                        8
                ] : [
                        8,
                        16
                ]}>
                        <ApplicantRow label="Name" apps={mortgage.twoApplicants ? [
                                strings.app1.nameAndNationality,
                                strings.app2.nameAndNationality
                        ] : [strings.app1.nameAndNationality]}/>
                        <ApplicantRow label="Age" apps={mortgage.twoApplicants ? [
                                strings.app1.age,
                                strings.app2.age
                        ] : [strings.app1.age]}/>
                        <ApplicantRow label="Marital Status" apps={mortgage.twoApplicants ? [
                                strings.app1.maritalStatus,
                                strings.app2.maritalStatus
                        ] : [strings.app1.maritalStatus]}/>
                        <ApplicantRow label="Home Status" apps={mortgage.twoApplicants ? [
                                strings.app1.homeStatus,
                                strings.app2.homeStatus
                        ] : [strings.app1.homeStatus]}/>
                </grid>
        }
        function DependentDetails() {
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        <row>
                                <cell>
                                        <string color="green">{strings.children.numberOfDependentsLabel}</string>
                                </cell>
                                <cell>
                                        <string>{strings.children.totalInWords}</string>
                                </cell>
                        </row>
                        {strings.children.numberOfDependentsCombined > 0 && <row>
                                <cell>
                                        <string color="green">Childcare Costs</string>
                                </cell>
                                <cell>
                                        <string>{strings.children.totalChildcareCosts}</string>
                                </cell>
                        </row>}
                </grid>)
        }
        function EmploymentDetails() {
                // let claimedVariable1 = mortgage.figures.unverified.variable1
                // //let claimedVariable1 = getApplicantClaimedAnnualOtherIncome(mortgage, 1)
                // let testedVariable1 = figures.variable1
                // //let testedVariable1 = strings.app1VariableIncome()
                // let app1VariableString = 'None';
                // if (claimedVariable1 > 0) {
                //     app1VariableString = !testedVariable1 ? 'Not utilised' : testedVariable1
                // }
                //
                // let claimedVariable2 = mortgage.figures.unverified.variable2
                // //let claimedVariable2 = getApplicantClaimedAnnualOtherIncome(mortgage, 2)
                // //let testedVariable2 = strings.app2VariableIncome()
                // let testedVariable2 = figures.variable2
                //
                // let employment = mortgage.applicant2.employment
                // if (mortgage.applicant2.employmentIncome?.length && !!mortgage.applicant2.employmentIncome[0].employmentType) {
                //     employment = mortgage.applicant2.employmentIncome[0]
                // }
                // let employed = [
                //     EmploymentTypes.SELFEMPLOYED,
                //     EmploymentTypes.EMPLOYED
                // ].includes(employment.employmentType)
                //
                // let app2VariableString = employed ? 'None' : '';
                // if (claimedVariable2 > 0) {
                //     app2VariableString = !testedVariable2 ? 'Not utilised' : testedVariable2
                // }
                //
                // let existing1 = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'app1VariableIncome')
                // let comment1 = existing1 && existing1.html !== "" ? convertHtmlToPlainText(existing1.html) : 'NO COMMENT'
                // let existing2 = mortgage.docContents.find(it => it.document === 'coverLetter' && it.paragraph === 'app2VariableIncome')
                // let comment2 = existing2 && existing2.html !== "" ? convertHtmlToPlainText(existing2.html) : 'NO COMMENT'

                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={mortgage.twoApplicants ? [
                        8,
                        8,
                        8
                ] : [
                        8,
                        16
                ]}>
                        <ApplicantRow label="Employment Status" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.status,
                                strings.app2.employment.status
                        ] : [strings.app1.employment.status]}/>
                        <ApplicantRow label="Occupation" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.occupation,
                                strings.app2.employment.occupation
                        ] : [strings.app1.employment.occupation]}/>
                        <ApplicantRow label="Employers Name" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.employerName,
                                strings.app2.employment.employerName
                        ] : [strings.app1.employment.employerName]}/>
                        <ApplicantRow label="Start Date of Employment" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.startDate,
                                strings.app2.employment.startDate
                        ] : [strings.app1.employment.startDate]}/>
                        <ApplicantRow label="Gross Basic Annual Salary" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.annualGrossBasic,
                                strings.app2.employment.annualGrossBasic
                        ] : [strings.app1.employment.annualGrossBasic]}/>
                        <ApplicantRow label="Gross Basic Monthly Salary" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.monthlyGrossBasic,
                                strings.app2.employment.monthlyGrossBasic
                        ] : [strings.app1.employment.monthlyGrossBasic]}/>
                        <ApplicantRow label="Annual Additional Income" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.variableDetails,
                                strings.app2.employment.variableDetails
                        ] : [strings.app1.employment.variableDetails]}/>
                        <ApplicantRow label="Comments" apps={mortgage.twoApplicants ? [
                                strings.app1.employment.variableComments,
                                strings.app2.employment.variableComments
                        ] : [strings.app1.employment.variableComments]}/>
                </grid>)

        }
        function PropertyDetails() {
                let buildingSelf = false
                let detailsOfPropertyLabel = 'Details of Property'
                let actualPropertyValueLabel = 'Property Value'

                if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
                        detailsOfPropertyLabel = 'Details of Property to Purchase'
                        if (mortgage.properties[0].buildType === BuildType.SELFBUILD) {
                                buildingSelf = true
                                detailsOfPropertyLabel = 'Location of Site'
                                //  actualPropertyValueLabel = 'Estimated Final Property Value'
                        }
                }

                let selfBuildRows = buildingSelf ? [
                        <row>
                                <cell>
                                        <string color="green">Site Value:</string>
                                </cell>
                                <cell>
                                        <string>{toEuroString(mortgage.properties[0].siteValue)}</string>
                                </cell>
                        </row>,
                        <row>
                                <cell>
                                        <string color="green">Construction Costs:</string>
                                </cell>
                                <cell>
                                        <string>{toEuroString(mortgage.properties[0].constructionCosts)}</string>
                                </cell>
                        </row>
                ] : []
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        <row>
                                <cell>
                                        <string color="green">{strings.property.descriptionLabel}</string>
                                </cell>
                                <cell>
                                        <string>{strings.property.description}</string>
                                </cell>
                        </row>
                        {selfBuildRows}
                        <row>
                                <cell>
                                        <string color="green">{strings.property.propertyValueLabel}</string>
                                </cell>
                                <cell>
                                        <string>{strings.property.propertyValue}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">Mortgage Amount Sought</string>
                                </cell>
                                <cell>
                                        <string>{strings.loan.sought}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">Term</string>
                                </cell>
                                <cell>
                                        <string>{strings.loan.term}</string>
                                </cell>
                        </row>
                        <row>
                                <cell>
                                        <string color="green">LTV</string>
                                </cell>
                                <cell>
                                        <string>{strings.loan.ltv}</string>
                                </cell>
                        </row>
                        {/*<row>*/}
                        {/*    <cell>*/}
                        {/*        <string color="green">LTI</string>*/}
                        {/*    </cell>*/}
                        {/*    <cell>*/}
                        {/*        <string>{strings.loan.lti}</string>*/}
                        {/*    </cell>*/}
                        {/*</row>*/}
                </grid>)
        }
        function BalanceOfFunds() {

                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        4,
                        8,
                        4
                ]}>
                        {strings.loan.bof.map((row, i) => (<row>
                                <cell>
                                        <string color="green">{row[0]}</string>
                                </cell>
                                <cell>
                                        <string align="right">{row[1]}</string>
                                </cell>
                                <cell>
                                        <string color="green">{row[2]}</string>
                                </cell>
                                <cell>
                                        <string align="right">{row[3]}</string>
                                </cell>
                        </row>))}
                </grid>)
        }
        function RepaymentAbility() {
                return (<grid vertical-bars="inside" horizontal-bars="inside" columns={[
                        8,
                        16
                ]}>
                        {strings.pra.rows.map((row, i) => {
                                return <row>
                                        <cell>
                                                <string color="green">{row.key}</string>
                                        </cell>
                                        <cell>
                                                <string>{row.value}</string>
                                        </cell>
                                </row>
                        })}

                        <row>
                                <cell>
                                        <string color="green">
                                                Comments
                                        </string>
                                </cell>
                                <cell>
                                        <string>{strings.pra.comment}</string>
                                </cell>
                        </row>
                </grid>)
        }
        function AccountsAndComments({item}) {
                let colorIndex = -1
                let dataSource = item.dataSource.map(row => {
                        if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                                colorIndex++
                                row.adjustment.color = accountNoteColors[colorIndex]
                        }
                        return row
                })
                return (<>
                        <grid vertical-bars="inside" horizontal-bars="inside" columns={[
                                3,
                                3,
                                6,
                                3,
                                3,
                                3,
                                3
                        ]}>

                                <row>
                                        <cell>
                                                <string color="green">Account Holder</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Institution</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Date Range</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Opening Balance</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Closing Balance</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Total Difference</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Avg Monthly Increase</string>
                                        </cell>
                                </row>
                                {dataSource.map((row, index) => {
                                        return <row>
                                                <cell>
                                                        <string>{row.owner}</string>
                                                </cell>
                                                <cell>
                                                        <string>{row.account}</string>
                                                </cell>
                                                <cell>
                                                        <string>{row.range}</string>
                                                        <string>{`(${row.diffInMonths} months)`}</string>
                                                </cell>
                                                <cell>
                                                        <string align="right">{toCurrencyString(row.openingBalance, item.currency)}</string>

                                                </cell>
                                                <cell>
                                                        <string align="right">{toCurrencyString(row.closingBalance, item.currency)}</string>
                                                </cell>
                                                <cell>
                                                        <string align="right">{toCurrencyString(row.totalDifference, item.currency)}</string>
                                                </cell>
                                                <cell>
                                                        <grid columns={[
                                                                2,
                                                                22
                                                        ]} cell-padding-x="0" cell-padding-y="0">
                                                                <row>
                                                                        <cell>
                                                                                <sentence>
                                                                                        {!!row.salaried && <string color="blue">*</string>}
                                                                                        {!!row.accommodationDD && <string color="lime">*</string>}
                                                                                        {(!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") &&
                                                                                        <string color={row.adjustment.color}>*</string>}
                                                                                </sentence>
                                                                        </cell>
                                                                        <cell>
                                                                                <string align="right">{toCurrencyString(row.averageMonthly, item.currency)}</string>
                                                                        </cell>
                                                                </row>
                                                        </grid>
                                                </cell>
                                        </row>
                                })}
                                <row>
                                        <cell>

                                        </cell>
                                        <cell>

                                        </cell>
                                        <cell>
                                                <string align="right" color="green">TOTALS</string>
                                        </cell>
                                        <cell>
                                                <string align="right">{toCurrencyString(item.totalOpeningBalance, item.currency)}</string>
                                        </cell>
                                        <cell>
                                                <string align="right">{toCurrencyString(item.totalClosingBalance, item.currency)}</string>
                                        </cell>
                                        <cell>
                                                <string align="right">{toCurrencyString(item.totalTotalDifference, item.currency)}</string>
                                        </cell>
                                        <cell>
                                                <string align="right">{toCurrencyString(item.averageMonthlySavings, item.currency)}</string>
                                        </cell>
                                </row>
                        </grid>
                        <grid vertical-bars="inside" columns={[
                                3,
                                21
                        ]}>

                                <row>
                                        <cell>
                                                <string>COMMENTS</string>
                                        </cell>
                                        <cell>
                                                {!!dataSource.find(it => !!it.salaried) && (<bullet dot={"*"} color={"blue"}>Salaried Account</bullet>)}
                                                {!!dataSource.find(it => !!it.accommodationDD) && (<bullet dot={"*"} color={"lime"}>Accommodation Direct Debit</bullet>)}
                                                {item.needsExplaining.map((it, index) => {
                                                        return (<bullet dot={"*"} color={it.adjustment.color}>
                                                                {!!it.adjustment.explanation ? it.adjustment.explanation : '!!! Add an explanation to the adjustment in the Funding Tab'}
                                                        </bullet>)
                                                })}
                                                {item.notableItems.length > 0 ? <bullet>
                                                        <string>Notable items:</string>
                                                        {item.notableItems.map((item, index) => {

                                                                return <>

                                                                        <string font-weight="bold">{`${item.owner}'s ${item.account}`}</string>
                                                                        <grid columns={[
                                                                                5,
                                                                                5,
                                                                                14
                                                                        ]} cell-padding-x="0" cell-padding-y="1">
                                                                                {item.queries.filter(q => !q.excludedOn).map((query, i) => {
                                                                                        let description = query.description
                                                                                        if (query.hasOwnProperty('amount')) {
                                                                                                if (query.description.toLowerCase().includes('deposit') || query.description.toLowerCase().includes('transfer in')) {
                                                                                                        description = `CR ${toCurrencyString(query.amount, item.currency)}`
                                                                                                }
                                                                                                else if (query.description.toLowerCase().includes('outgoing') || query.description.toLowerCase().includes('withdrawal') || query.description.toLowerCase().includes('transfer out')) {
                                                                                                        description = `DR ${toCurrencyString(query.amount, item.currency)}`
                                                                                                }
                                                                                        }

                                                                                        let myDate = !!query.date ? dayjs(query.date, "YYYY-MM-DD").format("D MMM YYYY") : ''
                                                                                        return <row>
                                                                                                <cell>{description}</cell>
                                                                                                <cell>{myDate}</cell>
                                                                                                <cell>
                                                                                                        {(query.lenderAnswer || []).map((it, i) => {
                                                                                                                return <string>{it}</string>
                                                                                                        })}
                                                                                                </cell>
                                                                                        </row>
                                                                                })}
                                                                        </grid>
                                                                </>
                                                        })}
                                                </bullet> : <bullet>No notable items</bullet>}


                                        </cell>
                                </row>
                        </grid>
                </>)
        }
        function AccountGroup({
                title,
                item
        })
        {
                if (!item.dataSource.length) {
                        return <></>
                }
                if (!item.currency) {
                        item.currency = '€'
                }
                return (<>
                        <group>
                                <container bg-color="shaded" padding-x={7} padding-y={4}>
                                        <string color="green" font-weight="bold">REVIEW OF {item.currency} {title}</string>
                                </container>
                                <AccountsAndComments item={item}/>
                                <border height={2}/>
                        </group>
                </>)
        }
        function InvestmentAndComments({item}) {
                let colorIndex = -1
                let dataSource = item.dataSource.map(row => {
                        if (!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") {
                                colorIndex++
                                row.adjustment.color = accountNoteColors[colorIndex]
                        }
                        return row
                })
                return (<>
                        <grid vertical-bars="inside" horizontal-bars="inside" columns={[
                                3,
                                12,
                                3,
                                6,
                        ]}>

                                <row>
                                        <cell>
                                                <string color="green">Account Holder</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Institution</string>
                                        </cell>
                                        <cell>
                                                <string color="green">Current Value</string>
                                        </cell>
                                        <cell>


                                        </cell>
                                </row>
                                {dataSource.map((row, index) => {
                                        return <row>
                                                <cell>
                                                        <string>{row.owner}</string>
                                                </cell>
                                                <cell>
                                                        <string>{row.account}</string>
                                                </cell>
                                                <cell>
                                                        <string align="right">{toCurrencyString(row.closingBalance, item.currency)}</string>
                                                </cell>
                                                <cell>
                                                        <grid columns={[
                                                                2,
                                                                22
                                                        ]} cell-padding-x="0" cell-padding-y="0">
                                                                <row>
                                                                        <cell>
                                                                                <sentence>
                                                                                        {!!row.salaried && <string color="blue">*</string>}
                                                                                        {!!row.accommodationDD && <string color="lime">*</string>}
                                                                                        {(!!row.adjustment && !!row.adjustment.explanation && row.adjustment.explanation !== "") &&
                                                                                        <string color={row.adjustment.color}>*</string>}
                                                                                </sentence>
                                                                        </cell>
                                                                        <cell>

                                                                        </cell>
                                                                </row>
                                                        </grid>
                                                </cell>
                                        </row>
                                })}
                                <row>
                                        <cell>

                                        </cell>
                                        <cell>
                                                <string align="right" color="green">TOTALS</string>
                                        </cell>
                                        <cell>
                                                <string align="right">{toCurrencyString(item.totalClosingBalance, item.currency)}</string>
                                        </cell>
                                        <cell>

                                        </cell>
                                </row>
                        </grid>
                        <grid vertical-bars="inside" columns={[
                                3,
                                21
                        ]}>

                                <row>
                                        <cell>
                                                <string>COMMENTS</string>
                                        </cell>
                                        <cell>
                                                {!!dataSource.find(it => !!it.salaried) && (<bullet dot={"*"} color={"blue"}>Salaried Account</bullet>)}
                                                {!!dataSource.find(it => !!it.accommodationDD) && (<bullet dot={"*"} color={"lime"}>Accommodation Direct Debit</bullet>)}
                                                {item.needsExplaining.map((it, index) => {
                                                        return <bullet dot={"*"} color={it.adjustment.color}>{it.adjustment.explanation}</bullet>
                                                })}
                                                {item.notableItems.length > 0 ? <bullet>
                                                        <string>Notable items:</string>
                                                        {item.notableItems.map((item, index) => {
                                                                return <>

                                                                        <string font-weight="bold">{`${item.owner}'s ${item.account}`}</string>
                                                                        <grid columns={[
                                                                                5,
                                                                                5,
                                                                                14
                                                                        ]} cell-padding-x="0" cell-padding-y="1">
                                                                                {item.queries.filter(q => !q.excludedOn).map((query, i) => {
                                                                                        let myDate = !!query.date ? dayjs(query.date, "YYYY-MM-DD").format("D MMM YYYY") : ''
                                                                                        return <row>
                                                                                                <cell>{query.description}</cell>
                                                                                                <cell>{myDate}</cell>
                                                                                                <cell>
                                                                                                        {(query.lenderAnswer || []).map((it, i) => {
                                                                                                                return <string>{it}</string>
                                                                                                        })}
                                                                                                </cell>
                                                                                        </row>
                                                                                })}
                                                                        </grid>
                                                                </>
                                                        })}
                                                </bullet> : <bullet>No notable items</bullet>}


                                        </cell>
                                </row>
                        </grid>
                </>)
        }
        function InvestmentGroup({item}) {
                if (!item.dataSource.length) {
                        return <></>
                }
                if (!item.currency) {
                        item.currency = '€'
                }
                return (<>
                        <group>
                                <container bg-color="shaded" padding-x={7} padding-y={4}>
                                        <string color="green" font-weight="bold">OTHER {item.currency} WEALTH</string>
                                </container>
                                <InvestmentAndComments item={item}/>
                                <border height={2}/>
                        </group>
                </>)
        }

        function Recommendation() {

                return <grid columns={[24]} vertical-bars="true">
                        <row>
                                <cell>{strings.recommendation}</cell>
                        </row>
                </grid>

        }

        return (<document height="a4" group-gap={10}>

                <group>
                        <header>Cover Note</header>
                        <border color="green" height={4}/>
                        <ApplicationType/>
                        <border height={2}/>
                </group>

                <group>
                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">CLIENT DETAILS</string>
                        </container>
                        <ApplicationDetails/>
                        <border height={2}/>
                </group>

                <group>
                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">DEPENDENT DETAILS</string>
                        </container>
                        <DependentDetails/>
                        <border height={2}/>
                </group>

                <group>


                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">EMPLOYMENT AND INCOME</string>
                        </container>
                        <EmploymentDetails/>
                        <border height={2}/>
                </group>

                <group>
                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">PROPERTY DETAILS</string>
                        </container>
                        <PropertyDetails/>
                        <border height={2}/>
                </group>

                <group>

                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">REPAYMENT ABILITY</string>
                        </container>
                        <RepaymentAbility/>
                        {/*{mortgage.activeSubmission.lenderCode === 'ptsb' && <RepaymentAbilityPTSB/>}*/}
                        {/*{[*/}
                        {/*    'haven',*/}
                        {/*    'ics'*/}
                        {/*].includes(mortgage.activeSubmission.lenderCode) && <RepaymentAbilityHaven/>}*/}
                        <border height={2}/>
                </group>

                <group>
                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">XLS TEST RESULTS</string>

                        </container>
                        <XlsTests/>
                        <border height={2}/>
                </group>


                {(mortgage.mortgageType !== MortgageTypes.PURCHASE || mortgage.properties[0].buildType !== BuildType.SELFBUILD) && (<>
                        <group>
                                <container bg-color="shaded" padding-x={7} padding-y={4}>
                                        <string color="green" font-weight="bold">BALANCE OF FUNDS</string>
                                </container>
                                <BalanceOfFunds/>
                                <border height={2}/>
                        </group>
                </>)}

                {/*<group>*/}
                {/*    <container bg-color="shaded" padding-x={7} padding-y={4}>*/}
                {/*        <string color="green" font-weight="bold">CALCULATOR RESULTS</string>*/}
                {/*    </container>*/}
                {/*    {mortgage.activeSubmission.lenderCode === 'ptsb' && <LoanAnalysisPTSB/>}*/}
                {/*    {mortgage.activeSubmission.lenderCode === 'haven' && <LoanAnalysisHaven/>}*/}
                {/*    {mortgage.activeSubmission.lenderCode === 'ics' && <LoanAnalysisHaven/>}*/}
                {/*    <border height={2}/>*/}
                {/*</group>*/}

                <AccountGroup title={'CURRENT ACCOUNTS'} item={euroCurrentAccounts}/>
                <AccountGroup title={'SAVINGS ACCOUNTS'} item={euroSavingsAccounts}/>
                {nonEuroCurrentAccounts.map(item => <AccountGroup title={'CURRENT ACCOUNTS'} item={item}/>)}
                {nonEuroSavingsAccounts.map(item => <AccountGroup title={'SAVINGS ACCOUNTS'} item={item}/>)}
                <InvestmentGroup item={euroInvestments}/>
                {nonEuroInvestments.map(item => <InvestmentGroup item={item}/>)}

                <group>
                        <container bg-color="shaded" padding-x={7} padding-y={4}>
                                <string color="green" font-weight="bold">RECOMMENDATION:</string>
                        </container>
                        <Recommendation/>
                        <border height={2}/>
                </group>


        </document>)
}

export default SussdCoverNote;