import React from 'react';
import MyTimeslots from "../../components/my-timeslots/MyTimeslots";
import PageHeader from "../../components/PageHeader";

function TimeSlots(props) {
    return (
        <>
            <PageHeader title="My Timeslots" className="m-15" back={"./../../"}/>
            <MyTimeslots></MyTimeslots>
        </>

    );
}

export default TimeSlots;