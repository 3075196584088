import React, {useEffect, useState} from 'react';
import {Input, Upload, Button, Form} from 'antd';
import useMessages from "../useMessages";
import ItemMessageHistory from "./ItemMessageHistory";

const {Dragger} = Upload;

const ItemMessaging = (props) => {
    const {
        messages,
        saveTextMessage,
        uploadRequest,
        openUpload,
        uploadingFileList,
        setUploadingFileList
    } = useMessages(props)
    const scrollAreaRef = React.useRef(null);
    const [form] = Form.useForm()
    const handleSendMessage = ({textMessage}) => {
        if (!textMessage) return
        if (textMessage.trim() !== '') {
            saveTextMessage(textMessage)
            form.resetFields()
        }
    };

    useEffect(() => {
        // Scroll the div to the bottom on component mount
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="d-col h-100">
            <div className="pb-9">
                <div ref={scrollAreaRef} style={{maxHeight: 600, overflowY: 'auto', paddingBottom: 24}}>
                    <ItemMessageHistory messages={messages} openUpload={openUpload}/>
                </div>
            </div>

            <Dragger
                className="chat-upload mt-auto"
                multiple
                customRequest={uploadRequest}
                listType='picture-card'
                fileList={uploadingFileList}
                onChange={({fileList}) => setUploadingFileList(fileList)}
            >

                    <Form
                        form={form}
                        onFinish={handleSendMessage}
                    >
                        <div className="d-row a-end gap-3">
                            <div
                                className="grow"
                                onClick={e => e.stopPropagation()}
                                onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation()
                                    e.preventDefault();
                                    form.submit()
                                }
                            }}>
                                <Form.Item noStyle name="textMessage">
                                    <Input.TextArea style={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        padding: 0,
                                        margin: 0,
                                        backgroundColor: 'transparent'
                                    }}/>
                                </Form.Item>

                            </div>
                            <div onClick={e => e.stopPropagation()}>
                                <Button htmlType="submit">Send</Button>
                            </div>
                        </div>
                    </Form>

            </Dragger>
        </div>
    );
};

export default ItemMessaging;