import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Checkbox, Form, Input, Select} from "antd";
import coverLetter from "../../../config-files/mortgage/documents/document-contents/cover-letter";
import avantCoverLetter from "../../../config-files/mortgage/documents/document-contents/avant-cover-letter";
import ReactDOMServer from 'react-dom/server';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import useMortgage from "../../providers/mortgage/useMortgage";
import statementOfSuitability from "../../../config-files/mortgage/documents/document-contents/statement-of-suitability";
import useSussdPdfObjects from "../../providers/useSussdPdfObjects";
import SuitabilityLetter from "../../../config-files/mortgage/documents/SuitabilityLetter";

const documentTemplates = [
        coverLetter,
        avantCoverLetter,
        statementOfSuitability,
        {
                key     : 'costSummary',
                label   : 'Cost Summary',
                contents: []
        }
]

function ComposeDocument({config}) {
        const mortgage = useMortgage()
        let liveBody = useRef()
        const [form] = Form.useForm()
        const useTitleRef = useRef()
        const [state, setState] = useState({
                document            : config?.document,
                paragraph           : config?.paragraph,
                initialBody         : '<p>Dear [Recipient\'s Name],</p>\n' + '    \n' + '    <p>I hope this email finds you well. I am writing to provide an update on the current project status and outline the next steps we need to take.</p>\n' + '    \n' + '    <p>Here are the tasks in priority order:</p>\n' + '    \n' + '    <ol>\n' + '        <li>Complete the initial review of the documentation by <strong>January 30th</strong>.</li>\n' + '        <li>Submit feedback and suggestions to the team for discussion.</li>\n' + '        <li>Prepare the final presentation draft by <strong>February 5th</strong>.</li>\n' + '    </ol>\n' + '    \n' + '    <p>If you have any questions or need further clarification, please don\'t hesitate to reach out. I appreciate your prompt attention to these tasks and look forward to your feedback.</p>\n' + '    \n' + '    <p>Best regards,</p>\n' + '    <p>[Your Name]</p>',
                manualDocSaveClicked: false,
                manualName: ''
        })

        const setDocument = (val) => {
                setState(s => ({
                        ...s,
                        document : val,
                        paragraph: null,
                }))
        }
        const setParagraph = (val) => {

                const document = documentTemplates.find(item => item.key === state.document)
                const Generate = document.contents.find(item => item.key === val).generate

                let existing = mortgage.docContents?.length && mortgage.docContents.find(item => item.document === state.document && item.paragraph === val)
                liveBody.current = existing ? existing.html : ReactDOMServer.renderToStaticMarkup(<Generate mortgage={mortgage}/>);
                setState(s => ({
                        ...s,
                        initialBody: liveBody.current,
                        paragraph  : val
                }))
        }
        function SelectDocument() {
                return <div className="d-row a-center"><Select onSelect={setDocument} value={state.document}>
                        {documentTemplates.map(item => <Select.Option key={item.key} value={item.key}>{item.label}</Select.Option>)}
                        <Select.Option value={'BLANK_LETTER'}>Blank Sussd Letter</Select.Option>
                </Select></div>
        }
        function SelectParagraph() {
                if (!state.document) {
                        return null
                }
                if (state.document === 'BLANK_LETTER') {
                        return <div className="d-row a-center grow">
                                <Form form={form}>
                                        <Form.Item className="mb-0" rules={[{required: true, message:"Enter a Title"}]} name="manualDocName" >
                                                <Input style={{width:'100%'}} placeholder={"Give this doc a title"}/>
                                        </Form.Item>
                                </Form>

                                <Checkbox className="ms-15 " ref={useTitleRef}><span style={{whiteSpace:'nowrap'}}>Use Title On Doc</span></Checkbox>
                        </div>
                }
                return <div className="d-row a-center grow"><Select onSelect={setParagraph} value={state.paragraph}>
                        {documentTemplates.find(item => item.key === state.document).contents.map(content => <Select.Option key={content.key} value={content.key}>{content.title}</Select.Option>)}
                </Select></div>
        }

        const handleRestore = () => {
                const document = documentTemplates.find(item => item.key === state.document)
                const Generate = document.contents.find(item => item.key === state.paragraph).generate

                liveBody.current = ReactDOMServer.renderToStaticMarkup(<Generate mortgage={mortgage}/>);
                setState(s => ({
                        ...s,
                        initialBody: liveBody.current
                }))
        }

        const handleSave = async () => {
                console.log({body: liveBody.current})
                //is there an existing paragraph?
                if (state.document === 'BLANK_LETTER') {
                        try{
                                let valid = await form.validateFields()
                                console.log(valid)

                                setState(s => ({
                                        ...s,
                                        manualDocSaveClicked: true,
                                        manualName: valid.manualDocName
                                }))
                        }
                        catch (e) {
                                
                        }
                       
                        return
                }

                let existing = mortgage.docContents?.length && mortgage.docContents.find(item => item.document === state.document && item.paragraph === state.paragraph)

                //if so, update it
                if (existing) {
                        mortgage.mutate({
                                update: {
                                        target: `docContents[${existing.id}]`,
                                        pairs : {
                                                html: liveBody.current
                                        }
                                }
                        })
                }
                else {
                        mortgage.mutate({
                                create: {
                                        target: `docContents`,
                                        pairs : {
                                                document  : state.document,
                                                paragraph : state.paragraph,
                                                html      : liveBody.current,
                                                mortgageID: mortgage.id,
                                        }
                                }
                        })
                }
        }
        console.log({state})
        return (<>
                <div className="d-row  gap-6 mb-15">
                        <SelectDocument/>
                        <SelectParagraph/>
                        <div className="d-row a-center j-end">
                                <Button onClick={handleRestore}>Restore</Button>
                                <Button onClick={handleSave}>Save</Button>
                        </div>
                </div>
                <CKEditor
                editor={Editor}
                data={state.initialBody}
                onReady={editor => {
                        console.log('ready')

                }}
                onChange={(event, editor) => {
                        console.log('change')
                        //const data = editor.getData();

                }}
                onBlur={(event, editor) => {
                        console.log('blur')
                        liveBody.current = editor.getData();
                        setState(s => ({...s, manualDocSaveClicked: false}))
                        //   setState(s => ({...s, initialBody: liveBody.current}))
                }}
                onFocus={(event, editor) => {
                        console.log('focus')
                }}
                />
                {state.manualDocSaveClicked && <ManualDocumentLoadAndSave useTitle={useTitleRef.current.input.checked} name={state.manualName} html={liveBody.current}
                                                                          close={() => setState(s => ({
                                                                                  ...s,
                                                                                  manualDocSaveClicked: false
                                                                          }))}/>}
        </>);
}

function ManualDocumentLoadAndSave({
        html,
        name,
        useTitle,
        close
})
{
        console.log({
                html,
                name
        })

        const otf = useSussdPdfObjects(OnTheFlyComponent, {
                htmlString: html,
                name      : useTitle ? name : ''
        })
        console.log({otf})
        const count = useRef(0)
        useEffect(() => {
                if (html && name && otf.ready) {
                        otf.open()
                        setTimeout(close,500)
                }
        }, [
                otf.ready,
                html,
                name
        ]);

        return <>
                <pre>{JSON.stringify(otf)}</pre>
        </>
}
function OnTheFlyComponent({
        htmlString,
        name
})
{
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlString, "text/html");
        let DOC = (<document height="a4" group-gap="15">
                <group>
                        <header>{name}</header>
                        <border height={3} color="green"/>
                </group>
                <gap size={21}/>
                {Array.from(htmlDoc.body.childNodes).flatMap((node, index) => {
                        if (node.nodeName === "P") {
                                const textContent = node.textContent.trim();
                                if (textContent) {
                                        return [
                                                <string key={index}>{textContent}</string>,

                                        ]
                                }
                        }
                        else if (node.nodeName === "UL" || node.nodeName === "OL") {
                                const isOrdered = node.nodeName === "OL";
                                return [
                                        ...Array.from(node.children).map((li, index) => {
                                                const bulletProps = {}
                                                if (isOrdered) {
                                                        bulletProps.dot = `${index + 1}.`
                                                }
                                                return <bullet  {...bulletProps}>{li.textContent.trim()}</bullet>
                                        }),

                                ];
                        }
                        return null;
                }).filter(i => !!i)}
                <group>
                        <kevinsignature/>
                        <string>___________________________</string>
                        <string>Kevin Coakley</string>
                        <gap/>
                        <string>Director</string>
                        <string>Suss'd Mortgages</string>
                </group>
        </document>)
        console.log({DOC})
        return DOC
}
export default ComposeDocument;