import dayjs from "dayjs";

export default function (mortgage, config) {

    const elements = {
        "additional-credit-details": "str",
        "additional-credit-no": true,
        "additional-credit-yes": false,
        "applicant-1-name": mortgage.applicant1.fullName,
        "applicant-1-sign-date": (dayjs()).format('D MMM YYYY'),
        "applicant-2-name": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
        "applicant-2-sign-date": (dayjs()).format('D MMM YYYY'),
        "creditors-up-to-date-details": "str",
        "creditors-up-to-date-no": false,
        "creditors-up-to-date-yes": true,
        "mortgage-account-number": mortgage.id,
        "satisfied-to-proceed-no": false,
        "satisfied-to-proceed-yes": true
    }
    const accountNumber = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'mortgage-account-number',
            label: 'Mortgage Account Number',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('mortgage-account-number')) {
            map['mortgage-account-number'] = config['mortgage-account-number']
        } else {
            if (!mortgage.requirement.mortgageAccountNo) {
                errors.push('No mortgage account number')
            } else {
                map['mortgage-account-number'] = mortgage.requirement.mortgageAccountNo
            }
        }
        return {map, ask, errors}
    }

    const applicantNames = () => {
        let map = {}
        map['applicant-1-name'] = mortgage.applicant1.fullName.toUpperCase()
        if (mortgage.twoApplicants) {
            map['applicant-2-name'] = mortgage.applicant2.fullName.toUpperCase()
        }
        return {map}
    }

    const additionalCredit = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'additional-credit',
            label: 'Additional Credit',
            type: 'CHECK',
            group: 'ADDITIONAL CREDIT'
        })
        ask.push({
            name: 'additional-credit-details',
            label: 'Additional Credit Details',
            type: 'TEXT',
            group: 'ADDITIONAL CREDIT'
        })
        if (config.hasOwnProperty('additional-credit')) {
            if (config["additional-credit"]) {
                map['additional-credit-yes'] = true
                map['additional-credit-no'] = false
                map['additional-credit-details'] = config['additional-credit-details']
            }
        }
        return {map}
    }


    const creditorsUpToDate = ()=>{
        let map = {}
        let ask = []
        ask.push({
            name: 'creditors-up-to-date',
            label: 'Creditors Up To Date',
            type: 'CHECK',
            group: 'CREDITORS'
        })
        ask.push({
            name: 'creditors-up-to-date-details',
            label: 'Creditors Up To Date Details',
            type: 'TEXT',
            group: 'CREDITORS'
        })
        if (config.hasOwnProperty('creditors-up-to-date')) {
            if (config["creditors-up-to-date"]) {
                map['creditors-up-to-date-yes'] = true
                map['creditors-up-to-date-no'] = false
                map['creditors-up-to-date-details'] = config['creditors-up-to-date-details']
            }
        }
        return {map}
    }
    const proceed = () => {
        let map = {}
        map['satisfied-to-proceed-yes'] = true
        map['satisfied-to-proceed-no'] = false
        return {map}
    }
    const sections = [
        accountNumber(),
        applicantNames(),
        additionalCredit(),
        creditorsUpToDate(),
        proceed(),



    ]
    return {sections}
}