import React from 'react';
import {EmploymentTypes, Frequencies} from "../../../../../../models";
import {isInPast, monthYearFormat} from "./validations";
import {correctValueForDb} from "../../../../../providers/mortgage/MortgageProvider";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";
import {differenceInYears} from "date-fns";
import {Button, Checkbox, Col, Form, Input, InputNumber, Row} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../../assets/scripts/parser-functions";
import ButtonAsync from "../../../../elements/ButtonAsync";

export function firstJob(props) {
    return {
        label : "First Job",
        text  : "Is this " + props.you.your + " first job?",
        name  : "firstJob",
        answer: {
            type   : "choice",
            choices: [
                {
                    value: true,
                    text : "Yes"
                },
                {
                    value: false,
                    text : "No"
                }
            ]
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }

            let currentEmployerStartDate = form.getFieldValue('v')
            if (currentEmployerStartDate) {
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : "A selection is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].firstJob`
    }
}
export function employmentType(props) {
    return {
        label       : "Employment Status",
        text        : "What is " + props.you.your + " current employment status?",
        name        : "employmentType",
        answer      : {
            type   : "select",
            choices: [
                {
                    text : "Employed",
                    value: "EMPLOYED"
                },
                {
                    text : "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text : "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text : "Student",
                    value: "STUDENT"
                }
            ]
        },
        model       : "employment",
        rules       : [
            {
                required: true,
                message : "A selection is required"
            },
        ],
        target      : `applicant${props.applicant}.employmentIncome[${props.index}].employmentType`,
        saveOnChange: true
    }
}
export function employmentBasis(props) {
    return {
        text  : "On what basis " + props.you.areYou + " employed?",
        label : "Employment Basis",
        name  : "employmentBasis",
        answer: {
            type   : "select",
            choices: [
                {
                    text : "Full-time",
                    value: "FULLTIME"
                },
                {
                    text : "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text : "Part-time",
                    value: "PARTTIME"
                },
                {
                    text : "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
                {
                    text : "Contract Basis",
                    value: "CONTRACT"

                }
            ]
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : "A selection is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].employmentBasis`
    }
}
export function positionHeld(props) {
    return {
        name  : "positionHeld",
        label : "Position",
        text  : "What is " + props.you.your + " position there?",
        answer: {
            type: "text"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : "A job title is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].positionHeld`
    }
}
export function currentEmployerName(props) {
    return {
        label : "Employer",
        text  : "With whom " + props.you.areYou + " currently employed?",
        name  : "currentEmployerName",
        answer: {
            type: "text"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : "A company name is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerName`
    }
}
export function currentEmployerAddress(props) {
    return {
        label : "Employer Address",
        text  : "What is " + props.you.your + " employer's address?",
        name  : "currentEmployerAddress",
        answer: {
            type: "address"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : "An address is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerAddress`
    }
}
export function currentEmployerStartDate(props) {
    //TODO: change to MMYYYY
    return {
        label : "Employment Start Date",
        text  : "When did " + props.you.you + " start employment there",
        name  : "currentEmployerStartDate",
        answer: {
            type: "ddmmyyyy",
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A date is required`
            },
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerStartDate`
    }
}
export function currentEmployerPhone(props) {
    return {
        text  : "What is " + props.you.your + " employer's contact number?",
        name  : "currentEmployerPhone",
        label : "Phone Number",
        answer: {
            type: "phone"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A phone number is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerPhone`
    }
}
export function previousEmployerName(props) {
    return {
        label : "Previous Employer",
        text  : "With whom " + props.you.wereYou + " previously employed?",
        name  : "previousEmployerName",
        answer: {
            type: "text"
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A company name is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerName`
    }
}
export function previousEmployerAddress(props) {
    return {
        label : "Previous Employer Address",
        text  : "What was " + props.you.your + " previous employer's address?",
        name  : "previousEmployerAddress",
        answer: {
            type: "address"
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {

                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `An address is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerAddress`
    }
}
export function previousEmployerStartDate(props) {
    return {
        label : "Previous Employment Start Date",
        text  : "When did " + props.you.you + " start employment there",
        name  : "previousEmployerStartDate",
        answer: {
            type: "ddmmyyyy",
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A date is required`
            },
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerStartDate`
    }
}
export function previousEmployerEndDate(props) {
    return {
        label : "Previous Employment End Date",
        text  : "When did " + props.you.you + " finish employment there",
        name  : "previousEmployerEndDate",
        answer: {
            type: "ddmmyyyy",
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A date is required`
            },
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerEndDate`
    }
}
export function previousEmploymentBasis(props) {
    return {
        text  : "On what basis " + props.you.wereYou + " employed?",
        label : "Previous Employment Basis",
        name  : "previousEmploymentBasis",
        answer: {
            type   : "select",
            choices: [
                {
                    text : "Full-time",
                    value: "FULLTIME"
                },
                {
                    text : "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text : "Part-time",
                    value: "PARTTIME"
                },
                {
                    text : "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
                {
                    text : "Contract Basis",
                    value: "CONTRACT"

                }
            ]
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmploymentBasis`
    }
}
export function previousPositionHeld(props) {
    return {
        label : "Previous Position",
        text  : "What was " + props.you.your + " position there?",
        name  : "previousPositionHeld",
        answer: {
            type: "text"
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED) {
                return true
            }
            let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
            if (currentEmployerStartDate) {
                if (form.getFieldValue('firstJob') === true) {
                    return true
                }
                let d = correctValueForDb('ddmmyyyy', currentEmployerStartDate)
                let w = differenceInYears(new Date(), new Date(d))
                return w >= 3
            }
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A job title is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousPositionHeld`
    }
}

export function limitedCompany(props) {
    return {
        text  : "Is " + props.you.your + " business a limited company?",
        label : "Limited Company",
        name  : "limitedCompany",
        answer: {
            type   : "choice",
            choices: [
                {
                    value: true,
                    text : "Yes"
                },
                {
                    value: false,
                    text : "No"
                }
            ]
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].limitedCompany`
    }
}
export function isDirector(props) {
    return {
        text  : capitaliseFirst(props.you.areYou) + " a director or shareholder in the company?",
        label : "Director/Shareholder",
        name  : "isDirector",
        answer: {
            type   : "choice",
            choices: [
                {
                    value: true,
                    text : "Yes"
                },
                {
                    value: false,
                    text : "No"
                }
            ]
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED) {
                return true
            }
            return form.getFieldValue('limitedCompany') === false;
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A selection is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].isDirector`
    }
}
export function percentageOfShares(props) {
    return {
        label : "Percentage Of Shares",
        text  : "What percentage of shares " + props.you.doYou + " hold in the company?",
        name  : "percentageOfShares",
        answer: {
            type: "float"
        },
        hides : (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED) {
                return true
            }
            if (form.getFieldValue('limitedCompany') === false) {
                return true
            }
            return form.getFieldValue('isDirector') === false;
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A percentage is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'This must be a value greater than zero'
            },
            {
                type   : 'number',
                max    : 100,
                message: 'This must be a value maximum 100'
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].percentageOfShares`
    }
}
export function nameOfBusiness(props) {
    return {
        label : "Business Name",
        text  : "What is the business's trading name?",
        name  : "nameOfBusiness",
        answer: {
            type: "text"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A business name is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].nameOfBusiness`
    }
}
export function natureOfBusiness(props) {
    return {
        label : "Nature of Business",
        text  : "What is the nature of " + props.you.your + " business?",
        name  : "natureOfBusiness",
        answer: {
            type: "text"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A description is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].natureOfBusiness`
    }
}
export function businessAddress(props) {
    return {
        label : "Business Address",
        text  : "What is the business's address?",
        name  : "businessAddress",
        answer: {
            type: "address"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `An address is required`
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].businessAddress`
    }
}
export function businessEstablished(props) {
    return {
        label : "Business Established",
        text  : "When was the business established?",
        name  : "businessEstablished",
        answer: {
            type: "mmyyyy"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A date is required`
            },
            {validator: monthYearFormat},
            {validator: isInPast},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].businessEstablished`

    }
}
export function accountantName(props) {
    return {
        label : "Accountant",
        text  : "Who is " + props.you.your + " accountant?",
        name  : "accountantName",
        answer: {
            type: "text"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `An accountant name is required`
            }
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantName`
    }
}
export function accountantAddress(props) {
    return {
        label : "Accountant Address",
        text  : "What is " + props.you.your + " accountant's address?",
        name  : "accountantAddress",
        answer: {
            type: "address"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `An address is required`
            }
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantAddress`
    }
}
export function accountantPhone(props) {
    return {
        label : "Accountant's Phone",
        text  : "What is their phone number?",
        name  : "accountantPhone",
        answer: {
            type: "phone"
        },
        hides : (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model : "employment",
        rules : [
            {
                required: true,
                message : `A phone number is required`
            }
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantPhone`
    }
}
export function grossBasic(props) {
    return {
        label : "Gross Basic Pay",
        text  : "What is " + props.you.your + " gross basic salary?",
        name  : "grossBasic",
        answer: {
            type: "euro",
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `An basic pay amount is required`
            },
            {
                type   : 'number',
                min    : 0,
                message: 'Must be greater than €-1'
            },
            {
                type   : 'number',
                max    : 1000000,
                message: 'Must be less than €1,000,000'
            }
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].grossBasic`
    }
}
export function overtime(props) {
    return {
        label : "Overtime Amount",
        text  : "How much over-time pay " + props.you.doYou + " earn in a year?",
        name  : "overtime",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OVERTIME')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `An overtime amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 1000000,
                message: 'Must be less than €1,000,000'
            }
        ],
        target: `applicant${props.applicant}.income.overtime`
    }
}
export function bonus(props) {
    return {
        label : "Bonus Amount",
        text  : "How much bonus " + props.you.doYou + " receive in a year?",
        name  : "bonus",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('BONUS')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A bonus amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 1000000,
                message: 'Must be less than €1,000,000'
            }
        ],
        target: `applicant${props.applicant}.income.bonus`
    }
}
export function commission(props) {
    return {
        label : "Commission Amount",
        text  : "How much commission " + props.you.doYou + " receive in a year?",
        name  : "commission",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('COMMISSION')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A commission amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 1000000,
                message: 'Must be less than €1,000,000'
            }
        ],
        target: `applicant${props.applicant}.income.commission`
    }
}
export function maintenance(props) {
    return {
        label : "Maintenance Amount",
        text  : "How much maintenance " + props.you.doYou + " receive in a year?",
        name  : "maintenance",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('MAINTENANCE')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A maintenance amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.maintenance`
    }
}
export function welfare(props) {
    return {
        label : "Social Welfare Amount",
        text  : "How much social welfare " + props.you.doYou + " receive in a year?",
        name  : "welfare",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('WELFARE')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A welfare amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.welfare`
    }
}
export function secondjob(props) {
    return {
        label : "Second Job",
        text  : "How much " + props.you.doYou + " receive (before tax) from the second job in a year?",
        name  : "secondjob",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('SECONDJOB')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A second job amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.secondjob`
    }
}
export function rentalIncome(props) {
    return {
        label : "Rental Income",
        text  : "How much " + props.you.doYou + " receive from all rentals in a year?",
        name  : "rentalIncome",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('RENT')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A rental amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.rentalIncome`
    }
}
export function dividends(props) {
    return {
        label : "Dividends Amount",
        text  : "How much " + props.you.doYou + " receive from all dividends in a year?",
        name  : "dividends",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('DIVIDENDS')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A dividends amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.dividends`
    }
}
export function investments(props) {
    return {
        label : "Investments Amount",
        text  : "How much " + props.you.doYou + " receive from all investments in a year?",
        name  : "investments",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('INVESTMENTS')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A investments amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.investments`
    }
}
export function other(props) {
    return {
        label : "Other Incomes Amount",
        text  : "How much " + props.you.doYou + " receive in a year from this?",
        name  : "other",
        answer: {
            type: "euro",
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `The other amount is required`
            },
            {
                type   : 'number',
                min    : 1,
                message: 'Must be greater than zero'
            },
            {
                type   : 'number',
                max    : 100000,
                message: 'Must be less than €100,000'
            }
        ],
        target: `applicant${props.applicant}.income.other`
    }
}
export function otherSourceDetails(props) {
    return {
        label : "Other Incomes Details",
        text  : "Supply details from where this income comes",
        name  : "otherSourceDetails",
        answer: {
            type: "textarea"
        },
        hides : (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model : "income",
        rules : [
            {
                required: true,
                message : `A description is required`
            },
        ],
        target: `applicant${props.applicant}.income.otherSourceDetails`
    }
}
export function nonEuroCurrency(props) {
    return {
        label : "Non Euro Earned",
        text  : capitaliseFirst(props.you.doYou) + " receive any income in a non-euro currency?",
        name  : "nonEuroCurrency",
        answer: {
            type   : "choice",
            choices: [
                {
                    value: true,
                    text : "Yes"
                },
                {
                    value: false,
                    text : "No"
                }
            ]
        },
        model : "income",
        rules : [
            {
                required: true,
                message : "A selection is required"
            },
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].nonEuroCurrency`
    }
}
export function employmentTitle(job) {
    if (job.employmentType === 'EMPLOYED') {

        return capitaliseFirst((job.employmentBasis || 'Unknown').toLowerCase())
    }
    return capitaliseFirst(job.employmentType)
}
export function income(props) {
    let items = {
        bonus         : 'Bonus',
        commission    : 'Commission',
        overtime      : 'Overtime',
        shiftAllowance: 'Shift Allowance',
        otherAllowance: 'Other Allowance'
    }
    return {
        name  : 'incomes',
        target: `applicant${props.applicant}.employmentIncome[${props.index}].incomes`,
        answer: {
            type: 'custom'
        },
        custom: (form) => {
            const saveAdditionalIncome = async () => {
                const values = form.getFieldValue('incomes')

                await props.mortgage.mutate({
                    update: {
                        target: `applicant${props.applicant}.employmentIncome[${props.index}].incomes`,
                        value : JSON.stringify(values)
                    }
                })
            }
            return (

                <div>
                    <ButtonAsync size="small" block className="mb-6" onClick={saveAdditionalIncome}> Save Changes To
                        Additional Income</ButtonAsync>
                    {Object.keys(items).map((name, i) => {
                        return (<div key={name} className="styler" style={{marginBottom: 20}}>
                            <Row align="middle">
                                <Col xs={12}>
                                    {items[name]}
                                    <Form.Item noStyle name={[
                                        'incomes',
                                        i,
                                        'name'
                                    ]}>
                                        <Input type="hidden"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={10}>
                                    <Form.Item noStyle name={[
                                        'incomes',
                                        i,
                                        'amount'
                                    ]}>
                                        <InputNumber addonBefore="€"
                                                     style={{width: '100%'}}
                                                     precision={0}
                                                     formatter={currencyFormatter}
                                                     parser={currencyParser}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={2} className="text-end">
                                    <Form.Item noStyle name={[
                                        'incomes',
                                        i,
                                        'guaranteed'
                                    ]} valuePropName="checked">
                                        <Checkbox/>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>)
                    })}

                </div>

            )
        }

    }
}
export function payFrequency(props) {
    return {
        name  : 'payFrequency',
        label : 'Pay Frequency',
        answer: {
            type   : 'select',
            choices: [
                Frequencies.WEEKLY,
                Frequencies.MONTHLY,
                Frequencies.FORTNIGHTLY
            ].map(it => ({
                value: it,
                label: capitaliseFirst(it.toLowerCase())
            }))
        },
        target: `applicant${props.applicant}.employmentIncome[${props.index}].payFrequency`,
    }
}
export const publicSector = (props) => {
    return {
        name  : 'publicSector',
        label : 'Public Service Employment',
        text  : `Are ${props.you.you} a public service employee, employed by the state?`,
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : 'Yes',
                    value: true
                },
                {
                    text : 'No',
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.employmentIncome[${props.index}].publicSector`,
    }
}
export const applicantEmploymentIncomeSteps = ({
    mortgage,
    applicant,
    index
}) => {
    const betterProps = {
        mortgage,
        applicant,
        index,
        you: mortgage[`applicant${applicant}`].strings
    }

    const job = mortgage[`applicant${applicant}`].employmentIncome.find(inc => inc.id === index)
    let multipleJobs = mortgage[`applicant${applicant}`].employmentIncome.length > 1
    let primary = mortgage[`applicant${applicant}`].employment.primaryEmploymentId

    let questions = []
    if (multipleJobs && (!primary || primary === index)) {
        questions.push({
            name  : 'primaryEmploymentId',
            label : 'Primary Employment',
            answer: {
                type   : 'select',
                choices: mortgage[`applicant${applicant}`].employmentIncome.map((job, i) => {
                    return {
                        value: job.id,
                        text: employmentTitle(job)
                    }
                })
            },
            target: `applicant${applicant}.employment.primaryEmploymentId`,
        })
    }

    switch (job.employmentType) {

        case EmploymentTypes.SELFEMPLOYED:
            questions = [...questions,
                employmentType(betterProps),
                limitedCompany(betterProps),
                isDirector(betterProps),
                percentageOfShares(betterProps),
                nameOfBusiness(betterProps),
                natureOfBusiness(betterProps),
                businessAddress(betterProps),
                businessEstablished(betterProps),
                accountantName(betterProps),
                accountantAddress(betterProps),
                accountantPhone(betterProps),
                grossBasic(betterProps),
                payFrequency(betterProps),
                income(betterProps),
            ]
            break;
        default:
            questions = [
                ...questions,
                employmentType(betterProps),
                employmentBasis(betterProps),
                publicSector(betterProps),
                positionHeld(betterProps),
                currentEmployerName(betterProps),
                currentEmployerAddress(betterProps),
                currentEmployerPhone(betterProps),
                currentEmployerStartDate(betterProps),
                firstJob(betterProps),
                previousEmployerName(betterProps),
                previousEmployerAddress(betterProps),
                previousEmployerStartDate(betterProps),
                previousEmployerEndDate(betterProps),
                previousEmploymentBasis(betterProps),
                previousPositionHeld(betterProps),
                grossBasic(betterProps),
                payFrequency(betterProps),
                income(betterProps),
            ]

    }
    return [
        {
            label: 'Employment & Income',
            title: employmentTitle(job),
            index,
            questions
        }
    ]
}
export const applicantEmploymentIncomes = ({
    mortgage,
    applicant
}) => {
    return mortgage[`applicant${applicant}`].employmentIncome.filter(inc => !inc._deleted).map(inc => ({
        to      : inc.id,
        index   : inc.id,
        title   : employmentTitle(inc),
        subtitle: '',
        footer  : ``,
        delete  : {
            delete: `applicant${applicant}.employmentIncome`,
            id    : inc.id
        },
    }))
}
function ApplicantEmploymentIncome(props) {
    const steps = applicantEmploymentIncomeSteps(props)
    const questions = steps.flatMap(step => {
        return step.questions
    })
    const repeater = `employment-income-${props.applicant}`
    return <SectionForm title="Applicant Employments" repeater={repeater}
                        key={`ApplicantDebts${props.index}`} {...props} questions={questions}/>
}

export default ApplicantEmploymentIncome;