import React, {useEffect, useRef, useState} from 'react';
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import useS3 from "../../providers/useS3";
import {Skeleton} from "antd";
import {PDFDocument, PDFTextField, PDFCheckBox, PDFRadioGroup, PDFDropdown, PDFOptionList, rgb} from 'pdf-lib';
import ButtonAsync from "../elements/ButtonAsync";

function LenderSourceDocLabels({file}) {
    const pdfDocument = useRef(null)
    const pdfPages = useRef(null)
    const getRandomDarkColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return rgb(r / 255, g / 255, b / 255);

    }
    const s3 = useS3()
    const loadFile = async () => {
        const result = await s3.getS3Url({
            path  : file.bucketKey,
            config: {
                contentType: 'application/pdf',
                level      : "private",
                identityId : SYSTEMIDENTITYID
            }
        })
        const pdfBytes = await fetch(result).then(res => res.arrayBuffer());
        console.log({
            result,
            pdfBytes,
            buck: file.bucketKey
        })
        pdfDocument.current = await PDFDocument.load(pdfBytes);
    }
    const drawLabels = async () => {
        const form = pdfDocument.current.getForm();
        const fields = form.getFields();
        const pages = pdfDocument.current.getPages()
        const names = []
        pages.forEach(page => {
            let radioGroupFields = [];
            fields.forEach(field => {
                const fieldName = field.getName()
                names.push(fieldName)
                /*
                IMPORTANT: Checkboxes dont seem to have a ref on the page
                 */
                const thisPage = pdfDocument.current.findPageForAnnotationRef(field.ref); // Get field's page object
                if (thisPage === page) {

                    const widgets = field.acroField.getWidgets();

                    widgets.forEach(widget => {
                        const fieldRect = widget.getRectangle();
                        page.drawText(fieldName, {
                            x    : fieldRect.x,
                            y    : fieldRect.y,
                            size : 12,
                            color: getRandomDarkColor()
                        });
                    });
                }
            })
        })
        // const field = form.getTextField('Text Field 74');
        // field.setText('You fucker!');

        console.log('running', {names})


    }
    const openFile = async ()=>{
        const pdfBytes = await pdfDocument.current.save();
        const blob = new Blob([pdfBytes], {type: 'application/pdf'});
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
    }

    const go = async ()=>{
        await loadFile()
        await drawLabels()
        await openFile()
    }
    return <ButtonAsync onClick={go}>Labels</ButtonAsync>
}

export default LenderSourceDocLabels;