import React from 'react';
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";


export function incomeChecks(props){
    return {
        label: "Extra Incomes Types",
        text: "In addition to the main income, which of the following types of income "+ (props.you.doYou) + " receive?",
        subtext: "(if any)",
        name: "incomeChecks",
        optional: true,
        answer: {
            type: "multichoice",

            choices: [
                {
                    value: "OVERTIME",
                    text: "Overtime"
                }, {
                    value: "BONUS",
                    text: "Bonus"
                }, {
                    value: "COMMISSION",
                    text: "Commission"
                }, {
                    value: "MAINTENANCE",
                    text: "Maintenance"
                },{
                    value: "WELFARE",
                    text: "Social Welfare"
                }, {
                    value: "SECONDJOB",
                    text: "2nd Job"
                }, {
                    value: "RENT",
                    text: "Rental Incomes"
                }, {
                    value: "DIVIDENDS",
                    text: "Dividends"
                }, {
                    value: "OTHER",
                    text: "Other"
                }
            ]
        },
        model: "income",
        rules: [],
        target: `applicant${props.applicant}.income.incomeChecks`
    }

}
export function grossBasic(props) {
    return {
        label: "Gross Basic Pay",
        text: "What is " + props.you.your + " gross basic salary?",
        name: "grossBasic",
        answer: {
            type: "euro",
        },
        model: "income",
        rules: [
            {required: true, message: `An basic pay amount is required`},
            {type: 'number', min: 0, message: 'Must be greater than €-1'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.grossBasic`
    }
}
export function overtime(props) {
    return {
        label: "Overtime Amount",
        text: "How much over-time pay " + props.you.doYou + " earn in a year?",
        name: "overtime",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('OVERTIME')
        },
        model: "income",
        rules: [
            {required: true, message: `An overtime amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.overtime`
    }
}
export function bonus(props) {
    return {
        label: "Bonus Amount",
        text: "How much bonus " + props.you.doYou + " receive in a year?",
        name: "bonus",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('BONUS')
        },
        model: "income",
        rules: [
            {required: true, message: `A bonus amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.bonus`
    }
}
export function commission(props) {
    return {
        label: "Commission Amount",
        text: "How much commission " + props.you.doYou + " receive in a year?",
        name: "commission",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('COMMISSION')
        },
        model: "income",
        rules: [
            {required: true, message: `A commission amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.commission`
    }
}
export function maintenance(props) {
    return {
        label: "Maintenance Amount",
        text: "How much maintenance " + props.you.doYou + " receive in a year?",
        name: "maintenance",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('MAINTENANCE')
        },
        model: "income",
        rules: [
            {required: true, message: `A maintenance amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.maintenance`
    }
}
export function welfare(props) {
    return {
        label: "Social Welfare Amount",
        text: "How much social welfare " + props.you.doYou + " receive in a year?",
        name: "welfare",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('WELFARE')
        },
        model: "income",
        rules: [
            {required: true, message: `A welfare amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.welfare`
    }
}
export function secondjob(props) {
    return {
        label: "Second Job",
        text: "How much " + props.you.doYou + " receive (before tax) from the second job in a year?",
        name: "secondjob",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('SECONDJOB')
        },
        model: "income",
        rules: [
            {required: true, message: `A second job amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.secondjob`
    }
}
export function rentalIncome(props) {
    return {
        label: "Rental Income",
        text: "How much " + props.you.doYou + " receive from all rentals in a year?",
        name: "rentalIncome",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('RENT')
        },
        model: "income",
        rules: [
            {required: true, message: `A rental amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.rentalIncome`
    }
}
export function dividends(props) {
    return {
        label: "Dividends Amount",
        text: "How much " + props.you.doYou + " receive from all dividends in a year?",
        name: "dividends",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('DIVIDENDS')
        },
        model: "income",
        rules: [
            {required: true, message: `A dividends amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.dividends`
    }
}
export function investments(props) {
    return {
        label: "Investments Amount",
        text: "How much " + props.you.doYou + " receive from all investments in a year?",
        name: "investments",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('INVESTMENTS')
        },
        model: "income",
        rules: [
            {required: true, message: `A investments amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.investments`
    }
}
export function other(props) {
    return {
        label: "Other Incomes Amount",
        text: "How much " + props.you.doYou + " receive in a year from this?",
        name: "other",
        answer: {
            type: "euro",
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model: "income",
        rules: [
            {required: true, message: `The other amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.other`
    }
}
export function otherSourceDetails(props) {
    return {
        label: "Other Incomes Details",
        text: "Supply details from where this income comes",
        name: "otherSourceDetails",
        answer: {
            type: "textarea"
        },
        hides: (form)=>{
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model: "income",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `applicant${props.applicant}.income.otherSourceDetails`
    }
}
export function nonEuroCurrency(props) {
    return {
        label: "Non Euro Earned",
        text: capitaliseFirst(props.you.doYou) + " receive any income in a non-euro currency?",
        name: "nonEuroCurrency",
        answer: {
            type: "choice",
            choices: [{
                value: true,
                text: "Yes"
            }, {
                value: false,
                text: "No"
            }]
        },
        model: "income",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.income.nonEuroCurrency`
    }
}

export function irishTaxResident(props) {
    return {
        label: "Irish Tax Resident",
        text: capitaliseFirst(props.you.areYou) + " a tax resident of Ireland?",
        name: "irishTaxResident",
        answer: {
            type: "choice",
            choices: [{
                value: true,
                text: "Yes"
            }, {
                value: false,
                text: "No"
            }]
        },
        model: "tax",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.tax.irishTaxResident`
    }
}
export function ppsNumber(props) {
    const validatePPS = (_, value) => {
        if (value && !/^\d{7}[a-zA-Z]{1,2}$/.test(value)){
            return Promise.reject('Enter a valid PPS Number');
        }
        return Promise.resolve();
    };
    return {
        label: "PPS Number",
        text: "What is " + props.you.your + " PPS Number?",
        name: "ppsNumber",
        answer: {
            type: "text",
            validation: (v) => {
                return /^\d{7}[a-zA-Z]{1,2}$/.test(v) ? false : "Please enter a valid PPS Number"
            },
            style: {textTransform:'uppercase'}
        },
        hides: (form)=>{
            return form.getFieldValue('irishTaxResident')!==true
        },
        model: "tax",
        rules: [
            {required: true, message: "A PPS Number is required"},
        ],
        target: `applicant${props.applicant}.tax.ppsNumber`
    }
}
export function usCitizen(props) {
    return {
        label: "US Citizen",
        text: capitaliseFirst(props.you.areYou) + " a citizen of the USA?",
        name: "usCitizen",
        answer: {
            type: "choice",
            choices: [{
                value: true,
                text: "Yes"
            }, {
                value: false,
                text: "No"
            }]
        },
        model: "tax",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.tax.usCitizen`
    }
}
export function usTaxId(props) {
    return {
        label: "USA Tax ID",
        text: "What is " + props.you.your + " USA tax payer identification number",
        name: "usTaxId",
        answer: {
            type: "text"
        },
        hides: (form)=>{
            return form.getFieldValue('usCitizen') !== true
        },
        model: "tax",
        rules: [
            {required: true, message: "A tax id is required"},
        ],
        target: `applicant${props.applicant}.tax.usTaxId`
    }
}
export function otherTaxResident(props) {
    return {
        label: "Foreign Tax Resident",
        text: capitaliseFirst(props.you.areYou) + " a tax resident of any other country?",
        name: "otherTaxResident",
        answer: {
            type: "choice",
            choices: [
                {
                    value: true,
                    text: "Yes"
                }, {
                    value: false,
                    text: "No"
                }
            ]
        },
        model: "tax",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.tax.otherTaxResident`
    }
}
export function otherTaxCountry(props) {
    return {
        label: "Foreign Tax Country",
        text: "What country is that?",
        name: "otherTaxCountry",
        answer: {
            type: "country"
        },
        hides: (form)=>{
            return form.getFieldValue('otherTaxResident') !== true
        },
        model: "tax",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.tax.otherTaxCountry`
    }
}
export function otherTaxId(props) {
    return {
        label: "Foreign Tax ID",
        text: "What is " + props.you.your + " foreign tax payer identification number",
        name: "otherTaxId",
        answer: {
            type: "text"
        },
        hides: (form)=>{
            return form.getFieldValue('otherTaxResident') !== true
        },
        model: "tax",
        rules: [
            {required: true, message: "A tax id is required"},
        ],
        target: `applicant${props.applicant}.tax.otherTaxId`
    }
}

export const applicantIncomeSteps = ({mortgage,applicant}) =>{
    const betterProps = {
        mortgage,
        applicant,
        you: mortgage[`applicant${applicant}`].strings
        //you: you(applicant === 1 ? false : mortgage.applicant2?.firstName ? mortgage.applicant2.firstName : 'the other applicant')
    }
    const result =  [
        {
            label: 'Sources',
            title: `Tell us about ${betterProps.you.your} income`,
            questions: [
                incomeChecks(betterProps),
            ]
        },
        {
            label: 'Amounts',
            title: `Tell us about ${betterProps.you.your} income`,
            questions: [
                grossBasic(betterProps),
                overtime(betterProps),
                bonus(betterProps),
                commission(betterProps),
                maintenance(betterProps),
                welfare(betterProps),
                secondjob(betterProps),
                rentalIncome(betterProps),
                dividends(betterProps),
                other(betterProps),
                otherSourceDetails(betterProps),
                nonEuroCurrency(betterProps)
            ]
        },
        {
            label: 'Tax Details',
            title: `Tell us about ${betterProps.you.your} tax info`,
            questions: [
                irishTaxResident(betterProps),
                ppsNumber(betterProps),
                usCitizen(betterProps),
                usTaxId(betterProps),
                otherTaxResident(betterProps),
                otherTaxCountry(betterProps),
                otherTaxId(betterProps)
            ]
        }
    ]
    return result
}
function ApplicantIncome(props) {
    const steps = applicantIncomeSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    return <SectionForm title="Applicant Income" key="ApplicantIncome" {...props} questions={questions}/>

}

export default ApplicantIncome;