import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Alert, Button, Card, Checkbox, ConfigProvider, Dropdown, Form, Popconfirm, Segmented, Skeleton, Table, Tag} from "antd";
import PropTypes from "prop-types";
import UploadCollapsedPanelRight from "./UploadCollapsedPanelRight";
import {joinWithBr} from "../../../../../../../assets/scripts/string-functions";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {ProgressViewState, SectionTypes, UploadStatuses} from "../../../../../../../models";
import InternalNote from "../v2/section-details/InternalNote";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EyeInvisibleOutlined, SettingOutlined, SyncOutlined} from "@ant-design/icons";
import {gray, green} from "@ant-design/colors";
import useBackendUsers from "../../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import ButtonAsync from "../../../../../elements/ButtonAsync";
import DataButton from "../v2/uploaded-file/upload-buttons/DataButton";
import OpenButton from "../v2/uploaded-file/upload-buttons/OpenButton";
import {DataButtonContext, DataButtonProvider} from "../v2/DataButtonToggleProvider";
import QueryButton from "../v2/uploaded-file/upload-buttons/QueryButton";
import "./upload-row-expired.css"

UploadCollapsedPanel.propTypes = {
        section: PropTypes.object.isRequired
};

function UploadCollapsedPanel(props) {
        const [activeTab, setActiveTab] = React.useState(props.section.verified.isVerifiable ? 'Summary' : 'Files');
        const mortgageMain = useMortgage()
        const mortgage = useMemo(() => {
                return mortgageMain
        }, [])
        const {
                      me,
                      getBySub
              } = useBackendUsers()
        const createRecord = async () => {
                if (!props.section?.record) {
                        const create = {
                                target: `progress`,
                                pairs : {
                                        applicationStage: props.section.stage,
                                        sectionType     : SectionTypes.UPLOAD,
                                        sectionName     : props.section.name,
                                        mortgageID      : mortgage.id
                                }
                        }
                        if (props.section?.index) {
                                create.pairs.sectionIndex = props.section.index
                        }
                        await mortgage.mutate({create})
                }
        }
        const getSegmentedLabel = (label) => {
                return <div className="px-6 py-18">{label}</div>
        }
        const handleCompletedClick = async () => {
                let pairs = {}
                if (props.section.record.sectionChecked) {
                        pairs.sectionChecked = null
                        pairs.sectionCheckedBy = null
                }
                else {
                        pairs.sectionChecked = new Date().toISOString()
                        pairs.sectionCheckedBy = me.sub
                }
                await mortgage.mutate({
                        update: {
                                target: `progress[${props.section.record.id}]`,
                                pairs
                        }
                })
        }
        let forcedComplete = props.section.record?.viewState === ProgressViewState.COMPLETE
        let sectionCanBeCompleted = !props.section.problems.length && !forcedComplete

        // if (props.section?.verified.isVerifiable) {
        //         if (props.section.record && props.section.record.lastDocumentDate) {
        //
        //                 let [amount, unit] = props.section.verified.minimums.maxAge
        //                 let lastDocDate = dayjs(props.section.record.lastDocumentDate, 'YYYY-MM-DD')
        //
        //                 if (dayjs.isDayjs(lastDocDate)) {
        //                         let diff = dayjs().diff(lastDocDate, unit, true)
        //                         if (diff > amount) {
        //                                 sectionCanBeCompleted = !!((props.section.data && props.section.data.isClosed) || (props.section.record && props.section.record.lastDocumentDateDismissed));
        //                         }
        //                 }
        //         }
        // }
        let changeStatusTimer = null
        let automaticUpdates = []
        mortgageMain.mortgageUploads.apply.forEach(section => {
                if (section.verified.isVerifiable) {
                        section.verified.uploads.active.forEach(upload => {
                                if (upload.status === UploadStatuses.PENDING && upload.verified.answered.all) {
                                        automaticUpdates.push({
                                                target: `uploads[${upload.id}].status`,
                                                value : UploadStatuses.ACCEPTABLE
                                        })

                                }
                        })
                        // if there's an iban in data, but not in application, update application
                        if (!section?.data?.accountNumber && section.verified.config.template.items.find(it => [
                                'iban',
                                'accountNumber'
                        ].includes(it.name)))
                        {

                                let uploadWithAnIban = section.verified.uploads.active.find(upload => {
                                        return !!upload.verified.values?.iban || !!upload.verified.values?.accountNumber
                                })
                                if (uploadWithAnIban) {
                                        let target = section.applicant === 1 ? 'applicant1' : section.applicant === 2 ? 'applicant2' : ''
                                        target += `.${section.data.typeName}`
                                        target += `[${section.data.id}]`
                                        target += `.accountNumber`
                                        automaticUpdates.push({
                                                target,
                                                value: uploadWithAnIban.verified.values.iban || uploadWithAnIban.verified.values.accountNumber
                                        })

                                }
                        }
                }
        })
        useEffect(() => {
                clearTimeout(changeStatusTimer)
                const autoSetStatusIfAllAnswered = async () => {
                        if (automaticUpdates.length) {
                                await mortgage.mutate({update: automaticUpdates})
                        }
                }
                changeStatusTimer = setTimeout(autoSetStatusIfAllAnswered, 500)
                return () => {
                        clearTimeout(changeStatusTimer)
                }
        }, [automaticUpdates.length]);

        if (!props.section?.record || !props.section.record._version) {

                return <div className="p-15">

                        <Button onClick={createRecord}>Create Section </Button>

                </div>
        }
        const options = []
        if (props.section.verified.isVerifiable) {
                options.push({
                        label: getSegmentedLabel('Summary'),
                        value: 'Summary',
                })
        }
        options.push({
                label: getSegmentedLabel('Files'),
                value: 'Files',
        })
        if (!props.section.custom) {
                options.push({
                        label: getSegmentedLabel('Application Form'),
                        value: 'Application Form',
                })
        }

        function Files() {
                return <UploadCollapsedPanelRight {...props} key={props.section.name + props.section.uploads.length}/>
        }
        function ApplicationForm({section}) {
                let ApplicationQuestions = props.section.verified.ApplicationQuestions
                return <div className="px-15 pt-15 pb-3" style={{borderBottom: '1px solid #cfcfcf'}}>
                        <ApplicationQuestions/>
                </div>
        }
        function Messages({section}) {
                console.log({section})
                function MessageLiWithAction({errorString}) {
                        let action = null
                        console.log({errorString})
                        if (typeof errorString === 'string' && errorString.includes('already in app')) {
                                action = (<ButtonAsync size="small" onClick={() => {
                                        section.verified.mortgage.mutate({
                                                update: {
                                                        target: `progress[${section.record.id}]`,
                                                        pairs : {
                                                                lastDocumentDateDismissed: !this.section.record.lastDocumentDateDismissed
                                                        }
                                                }
                                        })
                                }}>{section.record.lastDocumentDateDismissed ? 'Unblock Msg' : 'Block Msg'}</ButtonAsync>)
                        }
                        if (typeof errorString === 'string' && errorString.startsWith('Missing ')) {
                                console.log('errorString', errorString, section)
                                if (JSON.stringify(section?.record?.alertBody) === JSON.stringify([errorString])) {
                                        action = '(Request Created)'
                                }
                                else {
                                        action = (<ButtonAsync size="small" onClick={() => {
                                                section.verified.mortgage.mutate({
                                                        update: {
                                                                target: `progress[${section.record.id}]`,
                                                                pairs : {
                                                                        alertTitle    : `We need another ${section.title}`,
                                                                        alertBody     : [errorString],
                                                                        alertDismissed: false,
                                                                        alertAuthor   : me.sub,
                                                                }
                                                        }
                                                })
                                        }}>Create Request Another Upload</ButtonAsync>)
                                }
                        }
                        return <li key={errorString} className="d-row gap-6">

                                <div>{errorString}</div>
                                {action}
                        </li>

                }

                let problems = section.problems || []
                if (section.verified.isVerifiable && section.verified.uploads.active.length === 0) {
                        problems.push({
                                label: "No Active Uploads",
                        })
                }
                if (problems.length === 0) {
                        return null
                }
                console.log({problems})

                return (<Alert className="m-15" size="small" message={problems.map(it => {
                        console.log({it})
                        return (<div key={it.label}>
                                <strong>{it.label}</strong>
                                {!!it.errors && (<ul className="d-col gap-3 m-0 ps-18">
                                        {it.errors.map((that, i) => (it.label === 'Document Errors') ? <MessageLiWithAction key={'2.' + that} errorString={that}/> : <li key={that}>{that} </li>)}
                                </ul>)}

                        </div>)
                })} type="error"/>)
                // const SummaryMessages = props.section.verified.SummaryMessages
                // return <SummaryMessages/>
        }
        const settingsMenu = {

                items: [
                        {
                                key  : 'complete',
                                label: 'Force Section Completed',
                                icon: <CheckCircleOutlined />,
                                onClick: () => {
                                        let progress = props.section.record
                                        mortgage.mutate({
                                                update: [
                                                        {
                                                                target: `progress[${progress.id}]`,
                                                                pairs: {
                                                                        viewState: ProgressViewState.COMPLETE,
                                                                        viewStateBy: me.sub,
                                                                        viewStateOn: new Date().toISOString()
                                                                }
                                                        }
                                                ],
                                        })
                                }
                        },
                        {
                                key  : 'hidden',
                                label: 'Remove Section',
                                icon: <EyeInvisibleOutlined />,
                                onClick: () => {
                                        let progress = props.section.record
                                        mortgage.mutate({
                                                update: [
                                                        {
                                                                target: `progress[${progress.id}]`,
                                                                pairs: {
                                                                        viewState: ProgressViewState.HIDDEN,
                                                                        viewStateBy: me.sub,
                                                                        viewStateOn: new Date().toISOString()
                                                                }
                                                        }
                                                ],
                                        })
                                }
                        },
                        {
                                key  : 'hidden',
                                label: 'Auto (Normal)',
                                icon: <SyncOutlined />,
                                onClick: () => {
                                        let progress = props.section.record
                                        mortgage.mutate({
                                                update: [
                                                        {
                                                                target: `progress[${progress.id}]`,
                                                                pairs: {
                                                                        viewState: ProgressViewState.AUTO,
                                                                        viewStateBy: me.sub,
                                                                        viewStateOn: new Date().toISOString()
                                                                }
                                                        }
                                                ],
                                        })
                                }
                        }
                ]
        }
        return (<Card style={{
                borderWidth: 2,
                borderColor: gray[5]
        }} styles={{body: {padding: 0}}}>
                <div className="d-col">
                        <div className="d-row j-between">
                                <Segmented options={options} onChange={(e) => setActiveTab(e)} value={activeTab}/>
                                <div className="d-row gap-3 a-center me-9">
                                        {sectionCanBeCompleted && (<>
                                                <Button.Group className="m-6">
                                                        <ConfigProvider
                                                        theme={{
                                                                components: {
                                                                        Button: {
                                                                                colorPrimary: green[5], // Set the primary color for the button
                                                                        },
                                                                },
                                                        }}
                                                        >
                                                                <ButtonAsync type={!!props.section.record?.sectionChecked ? 'primary' : ''}
                                                                             onClick={handleCompletedClick}>{!!props.section.record?.sectionChecked ? <>
                                                                        <CheckOutlined/> Marked Completed</> : "Mark Complete"} </ButtonAsync>
                                                        </ConfigProvider>

                                                        {!!props.section.record?.sectionChecked && <Button disabled={true}>by {getBySub(props.section.record.sectionCheckedBy)?.firstName}</Button>}


                                                </Button.Group>
                                        </>
                                        )}
                                        {forcedComplete && (
                                        <Button.Group className="m-6">
                                                <ConfigProvider
                                                theme={{
                                                        components: {
                                                                Button: {
                                                                        colorPrimary: green[5], // Set the primary color for the button
                                                                },
                                                        },
                                                }}
                                                >
                                                        <Button type='primary'><CheckOutlined/> Forced Completed </Button>
                                                </ConfigProvider>

                                                <Button disabled={true}>by {getBySub(props.section.record.viewStateBy)?.firstName}</Button>

                                        </Button.Group>
                                        )}
                                        <Dropdown
                                        menu={settingsMenu}
                                        >

                                                <SettingOutlined />

                                        </Dropdown>
                                </div>


                        </div>

                        <Messages {...props}/>
                        <div className={activeTab === 'Summary' ? 'd-block' : 'd-none'}>
                                <DataButtonProvider>
                                        <Summary section={props.section}/>
                                </DataButtonProvider>

                        </div>
                        <div className={activeTab === 'Files' ? 'd-block' : 'd-none'}>
                                <Files {...props}/>
                        </div>
                        <div className={activeTab === 'Application Form' ? 'd-block' : 'd-none'}>
                                <ApplicationForm {...props}/>
                        </div>
                        <div className="d-row gap-15 p-6">
                                {props.section.custom && (<div className="mt-auto d-col">
                                        <Alert message={(<div className="d-col gap-15">
                                                <div>This section was manually created. If you don't need it, you can delete it</div>
                                                <div className={"d-row gap-15"}>
                                                        <Popconfirm onConfirm={() => {
                                                                let dynamicId = props.section.custom
                                                                let progress = props.section.record

                                                                mortgage.mutate({
                                                                        delete: [
                                                                                {
                                                                                        target: 'progress',
                                                                                        id    : progress.id
                                                                                },
                                                                                {
                                                                                        target: 'dynamicSections',
                                                                                        id    : dynamicId
                                                                                }
                                                                        ],
                                                                })

                                                        }} title={"Are you sure you want to delete this created section?"}>
                                                                <Button>Delete Section<DeleteOutlined/></Button>
                                                        </Popconfirm>
                                                </div>
                                        </div>)}/>

                                </div>)}
                                <div className="grow">
                                        <InternalNote section={props.section}/>
                                </div>
                        </div>


                </div>


                {/*<div className="d-row">*/}
                {/*    <div className="d-col">*/}
                {/*        {props.extra}*/}
                {/*    </div>*/}
                {/*    <UploadCollapsedPanelRight {...props} key={props.section.name + props.section.uploads.length}/>*/}
                {/*</div>*/}
                {props.section.record.note?.length && (

                <Alert
                className="m-6"
                message="User Note"
                description={<div>{joinWithBr(props.section.record.note.map(n => <div key={n}>{n}</div>))}</div>}
                type="warning"
                showIcon
                />)}
        </Card>

        );
}
function Summary({section}) {
        const {
                      close,
                      open,
                      toggle,
                      isOpen
              } = useContext(DataButtonContext)

        let uploadsSummary = section?.verified?.useUploadsSummary
        if (!uploadsSummary) {
                return <Skeleton/>
        }

        let {
                    columns,
                    dataSource,
                    rowClassName
            } = uploadsSummary()
        const rows = dataSource.map(row => {
                return {
                        ...row,
                        actions: <>
                                <div className="d-row gap-9 j-end" key={`dBr-${row.upload.id}`}>
                                        <DataButton
                                        key={`dB-${row.upload.id}`}
                                        upload={row.upload}
                                        title={section.title}
                                        popoverOpen={isOpen(row.upload.id)}
                                        onToggle={toggle}
                                        closePopover={close}

                                        />

                                        <QueryButton uploadId={row.upload.id} section={section}/>
                                        <OpenButton uploadId={row.upload.id} section={section}/>
                                </div>
                        </>
                }
        })

        return <div style={{borderBottom: '1px solid #cfcfcf'}}>
                <span/>
                <Table rowHoverable={false} rowClassName={rowClassName} className="my-3" columns={columns} dataSource={rows} pagination={false}/>
        </div>
}

export default UploadCollapsedPanel;