import React, {useContext, useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {performGQL} from "../../../../api-functions/functions";

import {Mortgages} from "../../../../providers/MortgagesProvider";
import {List, Tag, Typography} from "antd";
import {Link} from "react-router-dom";

import {LONGDATETIME12} from "../../../../../assets/scripts/constants";
import {joinWithConjunction} from "../../../../../assets/scripts/string-functions";
import {ApplicantActivities} from "../../../../providers/ApplicantActivitiesProvider";
import dayjs from "dayjs";
import {DesktopOutlined, MobileOutlined, TabletOutlined} from "@ant-design/icons";
import MortgageTag from "../../../elements/mortgage/MortgageTag";
import {gray} from "@ant-design/colors";
import OverflowedCard from "../../_common/OverflowedCard";

// Stop receiving data updates from the subscription
function UserActivity(props) {

    const mortgages = useContext(Mortgages)
    const activities = useContext(ApplicantActivities)

    function reorderEntries(actions) {
        // Define the order of actions
        const actionOrder = [
            "joined application",
            "started application",
            "updated application",
            "added document to uploads",
            "completed affordability section",
            "completed application",
            "completed uploads"
        ];

        const comparator = (a, b) => {
            const indexA = actionOrder.indexOf(a.trim());
            const indexB = actionOrder.indexOf(b.trim());
            return indexA - indexB;
        };

        // Sort and return the reordered actions
        return actions.sort(comparator);
    }
    let boxes = []
    activities.forEach(activity => {
        if (activity.actionedBy === 'null') {
            const obj = {
                key: `${activity.applicantID}-${activity.id}`,
                id: activity.id,
                _version: activity._version,
                applicantId: activity.applicantID,
                things: [],
                date: activity.createdAt,
                userAgent: activity.userAgent
            }
            if (activity.actions) {
                const data = JSON.parse(activity.actions)
                if (data.hasOwnProperty('mortgage')) {
                    Object.keys(data.mortgage).forEach(mortgageId => {
                        const map = data.mortgage[mortgageId]
                        Object.keys(map).forEach(key => {
                            obj.things.push({
                                mortgageId,
                                label: key,
                                date: map[key]
                            })
                        })
                    })
                }
            }
            if (obj.things.length) {
                boxes.push(obj)
            }
        }
    })
    const data = boxes.map(item => {
        let mortgage = mortgages.ALL.find(it => it.id === item.things[0].mortgageId)
        let device = false
        if (!!item.userAgent){
            let parts = item.userAgent.split('|')
            let screenString = parts[0]
            let parts2 = screenString.split('×')
            let width = parseInt(parts2[0].trim())
            let height = parseInt(parts2[1].trim())
            let orientation = width > height ? 'landscape' : 'portrait'
            device = width < 768 ? <MobileOutlined/> : width < 992 ? <TabletOutlined/> : <DesktopOutlined/>
        }

        return !!mortgage ? {
            ...item,
            mortgage,
            device
        } : false
    }).filter(it => !!it).sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
    })

    return (
    <OverflowedCard title="User Avtivty" >
    <List
        dataSource={data}
        renderItem={(item) => {
            const d = item.date ?( dayjs(item.date))?.fromNow() : 'Not sure when'
            return (
                <List.Item>

                    <div className="d-col grow">

                        <div className="d-row gap-15 mb-3">
                            <MortgageTag mortgage={item.mortgage}/>
                            {item.device}
                            <Typography.Text  className="ms-auto" style={{ color:gray[4]}}>{d}</Typography.Text>
                        </div>
                        <Typography.Text>  {joinWithConjunction(reorderEntries(item.things.map(it => it.label)), 'and')} </Typography.Text>
                    </div>
                </List.Item>
            )
        }
        }
    />
    </OverflowedCard>
    )

}

export default UserActivity;