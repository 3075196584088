import React, {useEffect, useRef, useState} from 'react';
import {Badge } from "antd";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {
    EllipsisOutlined,

    FilePdfOutlined,
     QuestionCircleFilled,

    SolutionOutlined,

} from "@ant-design/icons";
import { green, orange, red} from "@ant-design/colors";
import useApplicationUploads from "../../../../../providers/mortgage/useApplicationUploads";
import V2Uploads from "../../_common/uploads/v2/V2Uploads";

function UploadsForOnBoarding() {

    const mortgage = useMortgage()
    const {setup: sections} = useApplicationUploads()
    let namesEncountered = []
    const nameInGroup = (name, group) => {
        namesEncountered = [
            ...new Set([
                ...namesEncountered,
                ...group
            ])
        ]
        return !!group.some(root => name.startsWith(root));
    };
    const getApplicantStatementGroup = (n) => {
        return sections.reduce((acc, obj) => {
            if (obj.applicant === n) {
                const {
                    statementGroup,
                    ...rest
                } = obj;
                if (!!statementGroup) {
                    if (!acc[statementGroup]) {
                        acc[statementGroup] = [];
                        namesEncountered.push(obj.name)
                    }
                    acc[statementGroup].push({statementGroup, ...rest});
                }
            }
            return acc;
        }, {});
    }

    const groups = [
        {
            label   : "Statements",
            name    : "statements-1",
            icon    : <FilePdfOutlined/>,
            sections: getApplicantStatementGroup(1)
        },
        {
            label   : "Employment",
            name    : "employment-1",
            icon    : <SolutionOutlined/>,
            sections: sections.filter(s => nameInGroup(s.name, [
                'employment-summary-1',
                'payslips-1',
                'salary-cert-1',
                'employment-contract-1',
                'accountant-letter-1',
                'business-statements-1',
                'form11-1',
                'accounts-1',
            ]))
        }
    ]

    if (mortgage.twoApplicants) {
        groups.push({
            label   : "Statements",
            name    : "statements-2",
            icon    : <FilePdfOutlined/>,
            sections: getApplicantStatementGroup(2)
        }, {
            label   : "Employment",
            name    : "employment-2",
            icon    : <SolutionOutlined/>,
            sections: sections.filter(s => nameInGroup(s.name, [
                'employment-summary-2',
                'payslips-2',
                'salary-cert-2',
                'employment-contract-2',
                'accountant-letter-2',
                'business-statements-2',
                'form11-2',
                'accounts-2',
            ]))
        },)
    }
    groups.push({
        name    : "other",
        label   : "Other",
        icon    : <EllipsisOutlined/>,
        sections: sections.filter(s => !nameInGroup(s.name, namesEncountered))
    })


    return <V2Uploads groups={groups} stage="APPLY"/>

}
export function useUploadsForOnBoarding() {

}
export default UploadsForOnBoarding;