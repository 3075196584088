import React, {useContext, useEffect, useState} from 'react';
import {Drawer, FloatButton} from "antd";
import {
    CalendarOutlined,
    EditOutlined, FileTextOutlined,
    MailOutlined,
    QuestionCircleOutlined, UnorderedListOutlined, VerticalAlignBottomOutlined,
    VerticalAlignTopOutlined
} from "@ant-design/icons";
import NoteDrawerContent from "../components/tools-drawer/note/NoteDrawerContent";
import TaskDrawerContent from "../components/tools-drawer/task/TaskDrawerContent";
import ComposeEmail from "../components/tools-drawer/ComposeEmail";
import ComposeDocument from "../components/tools-drawer/ComposeDocument";
import BuildConditions from "../components/tools-drawer/BuildConditions";
import QueryDrawerContent from "../components/tools-drawer/query/QueryDrawerContent";
import {Auth} from "aws-amplify";
import {BackendUsers} from "./BackendUsersProvider";
export const AdminDrawer = React.createContext({})
function AdminDrawerProvider(props) {
    const backendUsers=useContext(BackendUsers)
    const [state, setState] = useState({
        open: false,
        type: 'email',
        config: {},
        defaultFromAccount:null
    })
    const setUserDefaultMailAccount = async ()=>{
        const user = await Auth.currentAuthenticatedUser()
        const me = backendUsers.find(u => u.sub === user.attributes.sub)
        setState(s=>({...s, defaultFromAccount: me}))
    }
    useEffect(() => {
        if (backendUsers && !state.defaultFromAccount){
            setUserDefaultMailAccount()
        }

    }, [backendUsers]);
    const close = () => {
        setState(s => ({...s, open: false}))
    }
    const composeEmail = (config, chatGptChoices) => {
        setState(s => ({...s, type: 'email', config, open: true, chatGptChoices}))
    }
    function Title() {
        switch (state.type) {
            case "email":
                return state.config.reply ? 'Reply to email' : 'Compose email'
            default:
        }
    }
    return <AdminDrawer.Provider value={{
        composeEmail,
    }}>
        {props.children}
        <Drawer

            title={<Title/>}
            placement="right"
            closable={true}
            onClose={close}
            open={state.open}
            size="large"
            mask={false}

        >
            {state.type === 'email' &&
                <ComposeEmail close={close} config={state.config} defaultFromAccount={state.defaultFromAccount} chatGptChoices={state.chatGptChoices}/>}
        </Drawer>
    </AdminDrawer.Provider>
}

export default AdminDrawerProvider;