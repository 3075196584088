import React from 'react';
import {Item} from "./task-item/Item";

function CompletedPanel({tasksByMeCompleted}) {
    return (
        <div className="mt-15 d-col gap-6">
            {
                tasksByMeCompleted.map(task => {
                    return <Item task={task} noMargin/>
                })
            }
        </div>
    )



}

export default CompletedPanel;