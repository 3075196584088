import React from 'react';
import {Tag} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";

function ItemOrigins({task}) {
    return (
        <div className="d-row">
            <Tag className="m-0 br-tr-0 br-br-0" style={{backgroundColor: "transparent"}}
                 color={task.tag.author.color}>{task.tag.author.name}</Tag>
            <Tag className="m-0 br-tl-0 br-bl-0 br-tr-0 br-br-0 blw-0 brw-0"
                 style={{backgroundColor: "transparent"}}><ArrowRightOutlined/></Tag>
            <Tag className="m-0 br-tl-0 br-bl-0" style={{backgroundColor: "transparent"}}
                 color={task.tag.target.color}>{task.tag.target.name}</Tag>
        </div>
    );
}

export default ItemOrigins;