import React, {useEffect} from 'react';
import {listAllAndSetItems, performGQL} from "../api-functions/functions";
import {listUserPdfIds} from "../../graphql/queries";
import {createUserPdfId} from "../../graphql/mutations";

export const PdfIdentifyContext = React.createContext(null);

function PdfIdentifyProvider({children}) {
    const [data,setData] = React.useState([]);

    useEffect(() => {
        listAllAndSetItems({},listUserPdfIds,setData)
    },[])

    const create = async (data) => {
        let newRow = await performGQL({input:data}, createUserPdfId);
        console.log("saveNew", newRow);
        setData(s=>[...s,newRow])
    }
    return (
        <PdfIdentifyContext.Provider value={{
            create,
            data: (data||[]).map(d=>({...d, data: JSON.parse(d.data)}))
        }}>
            {children}
        </PdfIdentifyContext.Provider>
    );
}

export default PdfIdentifyProvider;