import React, {useEffect} from 'react';
import {listAllAndSetItems} from "../api-functions/functions";
import {
    adminEmailReplyRequestsByRepliedBy,
} from "../../graphql/queries";
import useSubscription from "./useSubscription";
import {
    onCreateAdminEmailReplyRequest,
    onDeleteAdminEmailReplyRequest,
    onUpdateAdminEmailReplyRequest
} from "../../graphql/subscriptions";

export const ReplyRequests = React.createContext({})
function ReplyRequestsProvider(props) {
    const [state, setState] = React.useState([])

    const addItems = (items)=>{
        setState(s=>[...s,...items])
    }
    const load = async () => {
       await listAllAndSetItems({repliedBy: 'null'}, adminEmailReplyRequestsByRepliedBy, addItems)
    }
    useEffect(() => {
        load()
    }, [])

    useSubscription({
        subscription: {
            name: 'AdminEmailReplyRequest',
            onCreate: onCreateAdminEmailReplyRequest,
            onUpdate: onUpdateAdminEmailReplyRequest,
            onDelete: onDeleteAdminEmailReplyRequest
        },
        setState
    })
    return (
        <ReplyRequests.Provider value={state}>
            {props.children}
        </ReplyRequests.Provider>
    );
}

export default ReplyRequestsProvider;