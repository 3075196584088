import React from 'react';
import {avant} from "../../../../../../../assets/scripts/lender-calculators";
import {Descriptions} from "antd";
import {green, volcano} from "@ant-design/colors";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import useXls, {useXlsNew} from "../useXls";

function useAvantXls(useVerifiedFigures=false) {
    const mortgage = useMortgage()
    const api = avant({mortgage,useVerifiedFigures})
    const getProcessedResults = (results)=>{
        const testItems = []
        let items=[]
        if (!!results) {
            for (const [key, value] of Object.entries(results)) {
                const regex = /^Pass [A-Z]/;
                if (regex.test(key)) {
                    testItems.push({key, value})
                }
                else {
                    items.push({
                        key,
                        label: key,
                        value: value
                    })
                }
            }
        }
        items = items.map(item => {
            let newItem = {...item}
            let passKey = `Pass ${item.key}`
            let test = testItems.find(testItem => testItem.key.toLowerCase() === passKey.toLowerCase())
            if (test){
                newItem.passed = test.value.trim() === "G"
            }
            return newItem
        })
        return items
    }
    return useXls(api,getProcessedResults, mortgage)
}
export function useAvantXlsNew(useVerifiedFigures=false) {
    const mortgage = useMortgage()
    const api = avant({mortgage,useVerifiedFigures})
    const getProcessedResults = (data)=>{
        const testItems = []
        let items=[]
        if (!!data) {
            for (const [key, value] of Object.entries(data)) {
                const regex = /^Pass [A-Z]/;
                if (regex.test(key)) {
                    testItems.push({key, value})
                }
                else {
                    items.push({
                        key,
                        label: key,
                        value: value
                    })
                }
            }
        }
        items = items.map(item => {
            let newItem = {...item}
            let passKey = `Pass ${item.key}`
            let test = testItems.find(testItem => testItem.key.toLowerCase() === passKey.toLowerCase())
            if (test){
                newItem.passed = test.value.trim() === "G"
            }
            return newItem
        })
        return items
    }
    return useXlsNew(api,getProcessedResults, mortgage)
}

export default useAvantXls;