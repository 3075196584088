import React, {memo, useEffect, useState} from 'react';
import {Skeleton, List as AntList, Avatar, Button, Typography, Switch, Select} from "antd";
import {
    CheckCircleOutlined,
    ExclamationOutlined, EyeInvisibleOutlined, EyeOutlined,
    MailOutlined,
    MessageOutlined,
    PaperClipOutlined,
    SendOutlined, UserOutlined
} from "@ant-design/icons";

import {Link, useParams} from "react-router-dom";
import {magenta, green, purple} from "@ant-design/colors";
import dayjs from "dayjs";
import useBackendUsers from "../../providers/mortgage/useBackendUsers";

function List(props) {
    const {me} = useBackendUsers()
    const [filter, setFilter] = useState(me.is.support ? 'help@sussd.ie' : 'all')
    const loadMore = () => {
        props.loadMore()
    }
    const myItems = filter === 'all' ? props.items : props.items.filter(item => item.to.includes(filter))
    return (
        <>
        <div className="d-row p-15">
            <Select value={filter} onSelect={setFilter} style={{width: 200}} options={[
                {label: 'All', value: 'all'},
                {label: 'help@sussd.ie', value: 'help@sussd.ie'},
            ]} placeholder="Filter"/>
        </div>
        <AntList
            className="demo-loadmore-list"
            itemLayout="horizontal"
            loadMore={props.hasMore ? <Button onClick={loadMore} className="m-15">Load More</Button> : null}
            dataSource={myItems}
            renderItem={(item) => (
                <AntList.Item
                    //actions={[<a key="list-loadmore-edit">actions</a>]}
                >
                    <Skeleton avatar title={false} loading={item?.loading} active>
                        <Description item={item} active={props?.activeId === item.id}/>
                    </Skeleton>
                </AntList.Item>
            )}
        />
        </>
    );
}
function Description({item, active}) {
    let d = dayjs(item.createdAt).format("D MMM YYYY, h:mm a")
    return <Link to={`/emails/${item.id}`} className="d-row grow">
        <div className={`email-highlighter-bar ${active ? 'active' : ''}`}/>
        <div className="d-col px-15 grow">
            <div className="d-row a-center">
                {/*<ViewedCircle item={item}/>*/}
                <AttachmentCircle item={item}/>
                <ApplicantCircle item={item}/>
                <div className="d-col ms-auto text-end text-sm">
                    <Typography.Text className=" text-sm">{d}</Typography.Text>
                </div>
            </div>
            <Typography.Title style={{margin: 0}} level={5}>{item.subject}</Typography.Title>
            <Typography.Text className=" text-sm">{item.from}</Typography.Text>
        </div>
    </Link>
}
function AttachmentCircle({item}) {
    if (!item.hasAttachments) return null
    return <Avatar size="small" style={{backgroundColor: green.primary, flexShrink: 0, marginRight: 9}}
                   icon={<PaperClipOutlined/>}/>

}
function ApplicantCircle({item}) {
    if (item.userapplicantID==="null") return null
    return <Avatar size="small" style={{backgroundColor: magenta.primary, flexShrink: 0, marginRight: 9}}
                   icon={<UserOutlined/>}/>
}
function ViewedCircle({item}) {
    let icon
    let bg
    if (item.isReceived) {
        if (item?.readOn) {
            bg = purple.primary
            icon = <EyeOutlined/>
        } else {
            icon = <EyeInvisibleOutlined/>
            bg = magenta.primary
        }
    }
    return <Avatar size="small" style={{backgroundColor: bg, flexShrink: 0, marginRight: 9}} icon={icon}/>
}
export default List;