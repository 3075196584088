import React, {useState} from 'react';
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {App, Button, Upload} from "antd";
import NewUploadRow from "../v2/uploaded-file/NewUploadRow";
import dayjs from "dayjs";
import {DataButtonProvider} from "../v2/DataButtonToggleProvider";

const {Dragger} = Upload;


export const sortDateSorter = (a, b) => {
    if (!a.sortDate || !dayjs.isDayjs(a.sortDate)) {
        return -1
    }
    if (!b.sortDate || !dayjs.isDayjs(b.sortDate)) {
        return 1
    }
    return a.sortDate.isBefore(b.sortDate) ? -1 : a.sortDate.isAfter(b.sortDate) ? 1 : 0;
}

function UploadsTable(props) {

    const app = App.useApp()
    // const customSort = (a, b) => {
    //     // Check if status is 'REJECTED'
    //     if ((a.status === 'REJECTED' || !!a.exclude ) && (b.status !== 'REJECTED' || b.exclude)) {
    //         return -1; // 'REJECTED' should come before other statuses
    //     } else if ((a.status !== 'REJECTED' && !a.exclude ) && ( b.status === 'REJECTED' && !b.exclude)) {
    //         return 1; // Other statuses should come after 'REJECTED'
    //     } else if (initArrangement.includes(a.id)) {
    //         const indexA = initArrangement.indexOf(a.id);
    //         const indexB = initArrangement.indexOf(b.id);
    //         return indexA - indexB;
    //     } else {
    //         // If statuses are the same or neither is 'REJECTED', sort by createdAt
    //         return new Date(a.createdAt) - new Date(b.createdAt);
    //     }
    // };

    const handleUploadComplete = (event) => {

        // setFileList(s => s.map(file => {
        //     if (file.uid === event.uid) {
        //         const result = prepareUploadForStandardUpload(event.item)
        //         return {
        //             ...result,
        //             uid: file.uid,
        //         }
        //     }
        //     return file
        // }))
    }
    const handleUploadError = (event) => {

        // setFileList(s => s.map(file => {
        //     if (file.uid === event.uid) {
        //         return {
        //             ...file,
        //             error: true
        //         }
        //     }
        //     return file
        // }))
        app.notification.error({message: 'File Upload Failed'})
    }
    const handoverFileToS3 = async (file, onProgress) => {

        try {
            const settings = {
                file,
                progressCallback: (progress) => {

                    let percent = Math.floor((progress.loaded / progress.total) * 100)
                    onProgress({percent})
                    //setFileProgress(s => ({...s, [file.uid]: percent}))
                },
                completeCallback: (event) => {

                    handleUploadComplete({
                        ...event,
                        uid: file.uid
                    })

                    //props.onComplete(props.model)
                },
                errorCallback   : (err) => {
                    //setState({error: true})
                    handleUploadError({
                        ...err,
                        uid: file.uid
                    })
                    console.error('Unexpected error while uploading', err);
                }
            }
            // pass to the upload handler
            await props.handleUpload(settings)

        }
        catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };
    const customRequest = async ({
        file,
        onSuccess,
        onError,
        onProgress
    }) => {
        try {

            await handoverFileToS3(file, onProgress);
            onSuccess();
        }
        catch (error) {
            onError(error);
        }
    }
    const customItemRender = (originNode, file) => {

        return <NewUploadRow
            key={`new-upload-row${file.id}`}
            file={file}
            section={props.section}
            sections={props.sections}
        />
    };


    const draggerProps = {
        accept       : ".pdf, .png,.jpg",
        name         : 'file',
        multiple     : false,
        fileList     : props.files.reverse(),
        itemRender   : customItemRender,
        customRequest: customRequest,
    };

    return (<>
        <DataButtonProvider>
        <Dragger className="standard" {...draggerProps}>
            {!props.viewingExpired && (<div className="px-24">
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <Button style={{
                    marginLeft : 'auto',
                    marginRight: 'auto',
                    display    : 'flex',
                    alignItems : 'center'
                }}
                        icon={<UploadOutlined/>}>Click&nbsp;<span
                    className="d-mobile-none">&nbsp;/&nbsp;Drag</span>&nbsp;to&nbsp;Upload</Button>
            </div>)}


        </Dragger>
        </DataButtonProvider>
    </>);
}

export default UploadsTable;