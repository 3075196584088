import React, {useState} from 'react';
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import {Checkbox} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

function Excluder({uploadId}) {
    const [excluding, setExcluding] = useState(false)
    const mortgage = useMortgage()
    const row = mortgage.uploads.find(it=>it.id === uploadId)
    const handleChange = async (e) => {
        setExcluding(true)
        await mortgage.mutate({update: {target: `uploads[${uploadId}].exclude`, value: e.target.checked}})
        setExcluding(false)
    }
    if (!row) return null
    return (
        <Checkbox defaultChecked={row.exclude} onChange={handleChange}> Exclude from bundle {excluding && <LoadingOutlined/>} </Checkbox>
    );
}

export default Excluder;