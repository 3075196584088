import React from 'react';
import QueryItems from "../../_common/queries/QueryItems";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function OnboardingQueries(props) {
    const mortgage = useMortgage()
    const queries = mortgage ? mortgage.queries.filter(it => it.stage === 'APPLY') : []
    return (
        <QueryItems queries={queries}/>
    );
}

export default OnboardingQueries;