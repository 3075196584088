import {checklistItem} from "../index";

function documentChecklist(mortgage) {
    const allNames = [
        "P1 Fully complete form 1",
        "P1 Photo ID 1",
        "P1 Proof address 1",
        "P1 Proof PPSN 1",
        "P1 Stamped salary cert 1",
        "P1 Two months payslips 1",
        "P1 6 months acc statements 1",
        "P1 6 months savings acc 1",
        "P1 BER Cert 1",
        "P1 BER Cert 2",
        "P1 6 months savings acc 2",
        "P1 6 months acc statements 2",
        "P1 Two months payslips 2",
        "P1 Stamped salary cert 2",
        "P1 Proof PPSN 2",
        "P1 Proof address 2",
        "P1 Photo ID 2",
        "P1 Fully complete form 2",
        "P1 Borrower app of own accord YN",
        "P1 Applicants non resident YN",
        "P2 Revenue emp summary 1",
        "P2 Divorce agreement 1",
        "P2 Statement rental 1",
        "P2 Shared equity cert 1",
        "P2 Housing scheme app cert 1",
        "P2 Help to buy app 1",
        "P2 Financial acc 1",
        "P2 Form 11 revenue 1",
        "P2 Rev notice assess",
        "P2 Current acc statements 1",
        "P2 Tax up to date 1",
        "P2 Architect Surveyor 1",
        "P2 Planning permission 1",
        "P2 Initial valuation 1",
        "P2 Memo included 1",
        "P2 Repayment capacity 1",
        "P2 Funding demonstrated 1",
        "P2 Funding demonstrated 2",
        "P2 Repayment capacity 2",
        "P2 Memo included 2",
        "P2 Initial valuation 2",
        "P2 Planning permission 2",
        "P2 Architect Surveyor 2",
        "P2 Tax up to date 2",
        "P2 Current acc statements 2",
        "P2 Rev notice assess 1",
        "P2 Form 11 revenue 2",
        "P2 Financial acc 2",
        "P2 Help to buy app 2",
        "P2 Housing scheme app cert 2",
        "P2 Shared equity cert 2",
        "P2 Statement rental 2",
        "P2 Divorce agreement 2",
        "P2 Revenue emp summary 2",
        "P2 Advisor name",
        "P2 Date DD",
        "P2 Date MM",
        "P2 Date YYYY",
    ]

    // Bundling
    function createPack(){
        let pages = []

        // add page 1 from the document dividers file and fill the info required
        let page1 = null

        // add page 2 from the document dividers file

        // add all relevant uploads for personal accounts

        // add page 3 from the document dividers file

        // add all relevant uploads for consent forms, authorisations and declarations

        // add page 4 from the document dividers file

        // add all relevant uploads for income

        // add page 5 from the document dividers file

        // add all relevant uploads for identity and proof of address

        // add page 6 from the document dividers file

        // add all relevant uploads for Credit Memo, Copy ofAssessment Calculator, GiftLetter, Other statements etc

    }

    // Standard Documents
    const completedDeclarationForm = () => {
        let section = mortgage.mortgageUploads.apply.find(it => it.name === 'declaration-form')
        return {
            requirementTexts: ['Fully-completed declaration form'],
            tasks:[
                {
                    title: 'Generate Declaration Form',
                    description: 'Generate the declaration form for the client to sign',
                    type: 'generateOverlay',
                    overlay: 'declaration-form',
                },
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name: 'declaration-form',
                    section
                },
                isRequired: true,
                fill      : 'P1 Fully complete form 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name: 'declaration-form',
                    section
                },
                isRequired: true,
                fill      : 'P1 Fully complete form 2'
            }) : null
        }
    }
    const validIdentification = () => {
        return {
            requirementTexts: [
                'Photo ID (passport or Irish / UK driving licence)',
                'Please ensure all 4 corners of passport or driving licence are clearly visible'
            ],
            primaryCheck    : checklistItem({
                section      : {
                    name   : 'identification-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'identification-1')
                },
                isRequired   : true,
                stampRequired: true,
                fill         : 'P1 Photo ID 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section      : {
                    name   : 'identification-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'identification-2')
                },
                isRequired   : true,
                stampRequired: true,
                fill         : 'P1 Photo ID 2'
            }) : null
        }
    }
    const validProofOfAddress = () => {
        return {
            requirementTexts: [
                'Proof of address (a utility bill or original / electronic financial institution statement, less than 6 months old)',
            ],
            primaryCheck    : checklistItem({
                section      : {
                    name   : 'proof-of-address-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'proof-of-address-1')
                },
                maxAge       : [
                    6,
                    'month'
                ],
                stampRequired: true,
                isRequired   : true,
                fill         : 'P1 Proof address 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section      : {
                    name   : 'proof-of-address-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'proof-of-address-2')
                },
                isRequired   : true,
                stampRequired: true,
                maxAge       : [
                    6,
                    'month'
                ],
                fill         : 'P1 Proof address 2'
            }) : null
        }
    }
    const validProofOfPPSN = () => {
        return {
            requirementTexts: [
                'Proof of PPSN (Public Service card is not acceptable)',
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'proof-of-ppsn-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'proof-of-ppsn-1')
                },
                isRequired: true,
                fill      : 'P1 Proof PPSN 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'proof-of-ppsn-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'proof-of-ppsn-2')
                },
                isRequired: true,
                fill      : 'P1 Proof PPSN 2'
            }) : null
        }
    }
    const validSalaryCertificate = () => {
        return {
            requirementTexts: [
                'Stamped salary certificate dated within the last 3 months',
            ],
            primaryCheck    : checklistItem({
                section      : {
                    name   : 'salary-certificate-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'salary-certificate-1')
                },
                isRequired   : true,
                maxAge       : [
                    3,
                    'month'
                ],
                stampRequired: true,
                fill         : 'P1 Stamped salary cert 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section      : {
                    name   : 'salary-certificate-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'salary-certificate-2')
                },
                isRequired   : true,
                maxAge       : [
                    3,
                    'month'
                ],
                stampRequired: true,
                fill         : 'P1 Stamped salary cert 2'
            }) : null
        }
    }
    const validPayslips = () => {
        return {
            requirementTexts: [
                'Most recent 2 months consecutive payslips',
            ],
            primaryCheck    : checklistItem({
                section     : {
                    name   : 'payslips-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'payslips-1')
                },
                isRequired  : true,
                minStartDate: null,
                minEndDate  : null,
                maxAge      : [
                    1,
                    'month'
                ],
                fill        : 'P1 Two months payslips 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section       : {
                    name   : 'payslips-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'payslips-2')
                },
                isRequired    : true,
                numberRequired: 2,
                maxAge        : [
                    1,
                    'month'
                ],

            }) : null
        }
    }
    const validBankStatements = () => {
        return {
            requirementTexts: [
                'Most recent 6 months personal current account statements',
            ],
            primaryCheck    : checklistItem({
                section       : {
                    name   : 'current-accounts-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'current-accounts-1')
                },
                isRequired    : true,
                numberRequired: 6,
                maxAge        : [
                    1,
                    'month'
                ],
                fill          : 'P1 6 months acc statements 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section       : {
                    name   : 'current-accounts-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'current-accounts-2')
                },
                isRequired    : true,
                numberRequired: 6,
                maxAge        : [
                    1,
                    'month'
                ],
                fill          : 'P1 6 months acc statements 2'
            }) : null
        }
    }
    const validSavingsStatements = () => {
        return {
            requirementTexts: [
                'Most recent 6 months savings account statements showing build-up of funds',
            ],
            primaryCheck    : checklistItem({
                section       : {
                    name   : 'assets-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'assets-1')
                },
                isRequired    : true,
                numberRequired: 6,
                maxAge        : [
                    1,
                    'month'
                ],
                fill          : 'P1 6 months savings acc 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section       : {
                    name   : 'assets-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'assets-2')
                },
                isRequired    : true,
                numberRequired: 6,
                maxAge        : [
                    1,
                    'month'
                ],
                fill          : 'P1 6 months savings acc 2'
            }) : null
        }
    }
    const validBerCertificate = () => {
        return {
            requirementTexts: [
                'BER Cert: For FTB and Mover (Property Specific)',
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'ber-certificate-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'ber-certificate-1')
                },
                isRequired: true,
                fill      : 'P1 BER Cert 1'
            }),
            secondaryCheck  : null
        }
    }

    // Other Possible Documents
    const validEds = () => {
        return {
            requirementTexts: [
                'Revenue employment detail summary – proof of 3 years’ variable pay',
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'employment-summary-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'employment-summary-1')
                },
                isRequired: true,
                fill      : 'P2 Revenue emp summary 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'employment-summary-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'employment-summary-2')
                },
                isRequired: true,
                fill      : 'P2 Revenue emp summary 2'
            }) : null
        }
    }
    const separationDivorceAgreement = () => {
        return {
            requirementTexts: [
                'Separation / Divorce Agreement (if applicable)',
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'separation-agreement-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'separation-agreement-1')
                },
                isRequired: false,
                fill      : 'P2 Divorce agreement 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'separation-agreement-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'separation-agreement-2')
                },
                isRequired: false,
                fill      : 'P2 Divorce agreement 2'
            }) : null
        }
    }
    const statementShowingRentalIncome = () => {
        return {
            requirementTexts: [
                'Account statement showing rental income / Revenue return / Chapter 4',
            ],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'rental-income-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'rental-income-1')
                },
                isRequired: false,
                fill      : 'P2 Statement rental 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'rental-income-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'rental-income-2')
                },
                isRequired: false,
                fill      : 'P2 Statement rental 2'
            }) : null
        }
    }
    const fhsApproval = () => {
        return {
            requirementTexts: ['Shared Equity Scheme approval cert / FHS'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'fhs-approval-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'fhs-approval-1')
                },
                isRequired: false,
                fill      : 'P2 Shared equity cert 1'
            }),
            secondaryCheck  : null
        }
    }
    const htbApproval = () => {
        return {
            requirementTexts: ['Help-to-buy approval, expiry date and amount documents'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'htb-approval-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'htb-approval-1')
                },
                isRequired: false,
                fill      : 'P2 Help to buy app 1'
            }),
            secondaryCheck  : null
        }
    }
    const affordableHousingApproval = () => {
        return {
            requirementTexts: ['Affordable housing scheme approval confirmation'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'affordable-housing-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'affordable-housing-1')
                },
                isRequired: false,
                fill      : 'P2 Housing scheme app cert 1'
            }),
            secondaryCheck  : null
        }
    }

    // Self Employed Documents
    const twoYearsAuditedAccounts = () => {
        return {
            requirementTexts: ['Most recent 2 years audited accounts'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'audited-accounts-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'audited-accounts-1')
                },
                isRequired: false,
                fill      : 'P2 Financial acc 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'audited-accounts-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'audited-accounts-2')
                },
                isRequired: true,
                fill      : 'P2 Financial acc 2'
            }) : null
        }
    }
    const twoYearsForm11 = () => {
        return {
            requirementTexts: ['Last 2 years’ Form 11 submitted to Revenue'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'form-11-1',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'form-11-1')
                },
                isRequired: false,
                fill      : 'P2 Form 11 revenue 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'form-11-2',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'form-11-2')
                },
                isRequired: true,
                fill      : 'P2 Form 11 revenue 2'
            }) : null
        }
    }
    const twoYearsNoticeOfAssessment = () => {
        return {
            requirementTexts: ['Last 2 years’ Revenue Notice of Assessments / Chapter 4'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Rev notice assess 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Rev notice assess 1'
            }) : null
        }
    }
    const sixMonthsBusinessBankStatements = () => {
        return {
            requirementTexts: ['Last 6 months’ business current account statements'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Current acc statements 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Current acc statements 2'
            }) : null
        }
    }
    const accountantsLetter = () => {
        return {
            requirementTexts: ['Written confirmation from accountant that the company’s tax affairs are up to date (A Tax clearance certificate is not accepted)'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Tax up to date 1'
            }),
            secondaryCheck  : mortgage.twoApplicants ? checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Tax up to date 2'
            }) : null
        }
    }

    // Self Build Documents
    const initialReport = () => {
        return {
            requirementTexts: ['Architect’s / Surveyor’s / Engineer’s initial report'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Architect Surveyor 1'
            }),
            secondaryCheck  : null
        }
    }
    const planningPermission = () => {
        return {
            requirementTexts: ['Planning permission reference number'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Planning permission 1'
            }),
            secondaryCheck  : null
        }
    }
    const initialValuation = () => {
        return {
            requirementTexts: ['Initial valuation (based off plans)'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: false,
                fill      : 'P2 Initial valuation 1'
            }),
            secondaryCheck  : null
        }
    }

    // Reminders
    const reminderCalculator = () => {
        return {
            requirementTexts: ['Have you included the memo and calculator?'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Memo included 1'
            }),
            secondaryCheck  : null
        }
    }
    const reminderRepaymentCapacity = () => {
        return {
            requirementTexts: ['Has repayment capacity been demonstrated in the last 6 months?'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Repayment capacity 1'
            }),
            secondaryCheck  : null
        }
    }
    const reminderFunding = () => {
        return {
            requirementTexts: ['Has funding been clearly demonstrated and necessary documentation provided?'],
            primaryCheck    : checklistItem({
                section   : {
                    name   : 'xxx',
                    section: mortgage.mortgageUploads.apply.find(it => it.name === 'xxx')
                },
                isRequired: true,
                fill      : 'P2 Funding demonstrated 1'
            }),
            secondaryCheck  : null
        }
    }

    const list = []
    console.log({mortgage})
    if (!!mortgage.mortgageUploads) {
        list.push(...[
            completedDeclarationForm(),
            validIdentification(),
            validProofOfAddress(),
            validProofOfPPSN(),
            validSalaryCertificate(),
            validPayslips(),
            validBankStatements(),
            validSavingsStatements(),
            validBerCertificate(),
            validEds(),
            ])
        if (mortgage.particulars.anyoneDivorcedOrSeparated) {
            list.push(separationDivorceAgreement())
        }
        if (mortgage.particulars.anyoneHasRentalIncome){
            list.push(statementShowingRentalIncome())
        }
        if (mortgage.particulars.utilisingFirstHomeScheme){
            list.push(fhsApproval())
        }
        if (mortgage.particulars.utilisingHelpToBuyScheme){
            list.push(htbApproval())
        }
        if (mortgage.particulars.anyoneSelfEmployed){
            list.push(...[
                twoYearsAuditedAccounts(),
                twoYearsForm11(),
                twoYearsNoticeOfAssessment(),
                sixMonthsBusinessBankStatements(),
                accountantsLetter()
            ])
        }
        if (mortgage.particulars.isSelfBuild){
            list.push(...[
                initialReport(),
                planningPermission(),
                initialValuation()
            ])
        }
        list.push(...[
            reminderCalculator(),
            reminderRepaymentCapacity(),
            reminderFunding()
        ])
    }
    const errors = []
    const warnings = []

    list.forEach(item => {
        if (item.primaryCheck && item.primaryCheck.error) {
            errors.push(item.primaryCheck.error)
        }
        if (item.secondaryCheck && item.secondaryCheck.error) {
            errors.push(item.secondaryCheck.error)
        }
        if (item.primaryCheck && item.primaryCheck.warning) {
            warnings.push(item.primaryCheck.warning)
        }
        if (item.secondaryCheck && item.secondaryCheck.warning) {
            warnings.push(item.secondaryCheck.warning)
        }
    })

    const ok = errors.length === 0

    return {
        ok,
        list,
        errors,
        warnings
    }
}
export default documentChecklist;