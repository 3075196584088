import React, {useContext, useState} from 'react';
import {Card, List, Select, Tag, Typography} from "antd";
import useTodos from "./useTodos";
import {Link} from "react-router-dom";
import MortgageTag from "../../../elements/mortgage/MortgageTag";
import {gray} from "@ant-design/colors";
import OverflowedCard from "../../_common/OverflowedCard";
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";
import {MortgagesMessaging, useMessages} from "../../../../providers/mortgages-messaging/MortgagesMessagingProvider";

function TodoCard(props) {
    const{forAdvisor,forSupport, forRest} = useMessages()
    const {me} = useBackendUsers()
    const {toDos,allAdvisorProblems,allRestProblems,allSupportProblems} = useTodos()
    let defaultState = me.is.advisor ? 'advisorMessages' : me.is.support ? 'supportMessages' : 'restMessages'
    const [selected,setSelected]    = useState(defaultState)
    let items
    switch(selected){
        case 'advisorMessages':
            items = forAdvisor
            break
        case 'supportMessages':
            items = forSupport
            break
        case 'restMessages':
            items = forRest
            break
        case 'allAdvisorProblems':
            items = allAdvisorProblems
            break
        case 'allRestProblems':
            items = allRestProblems
            break
        case 'allSupportProblems':
            items = allSupportProblems
            break
        default:
            items = toDos
    }
    const renderItem = item => (
        <List.Item key={item.key}>

            <div className="d-col grow">

                <div className="d-row j-between gap-15 mb-3">
                    <Typography.Text style={{fontSize: 16, color: gray[4]}}>{item.title}</Typography.Text>
                </div>
                <MortgageTag mortgage={item.mortgage}/>

                <Typography.Text>  {item.message} </Typography.Text>
            </div>

        </List.Item>
    )

    let title = <div className={'d-row j-between gap-15'}>
        Mortgage Administration
        <Select value={selected} onChange={setSelected} style={{width: '100%'}} options={[
            {value:'advisorMessages', label:'Advisor (New)'},
            {value:'supportMessages', label:'Support (New)'},
            {value:'restMessages', label:'Rest (New)'},
            {value:'allAdvisorProblems', label:'Advisor'},
            {value:'allRestProblems', label:'Rest'},
            {value:'allSupportProblems', label:'Support'},
            {value:'toDos', label:'Normal'}
        ]}/>
    </div>
    return (

        <OverflowedCard title={title}>

            <List
                dataSource={items}
                renderItem={renderItem}
            />

        </OverflowedCard>
    );
}

export default TodoCard;