import React from 'react';
import useMortgage from "../../../../providers/mortgage/useMortgage";
import {Skeleton} from "antd";
import useApprovalTimelineItems from "./useApprovalTimelineItems";
import LoanOffer from "./parts/LoanOffer";
import StagesLayout from "../_common/StagesLayout";
import Funding from "../_common/funding/Funding";
import ApprovalCustomerDocuments from "./parts/ApprovalCustomerDocuments";
import ApprovedCustomerUploads from "./parts/ApprovedCustomerUploads";
import ApprovalAdminDocuments from "./parts/ApprovalAdminDocuments";
import MpiInfo from "../_common/mortgage-protection-insurance/MpiInfo";

function ManagementApproval(props) {
    const mortgage = useMortgage()
    let [activeKey, setActiveKey] = React.useState('Loan Offer')
    if (!mortgage) {
        return <div className="m-15"><Skeleton active loading/></div>
    }

    const tabs = [
        {
            key: 'Loan Offer',
            label: 'Loan Offer',
            children: <LoanOffer/>,
        },
        {
            key: 'Mortgage Protection',
            label: 'Mortgage Protection',
            children: <>
                <MpiInfo/>
                </>,
        },
        {
            key: 'Customer Docs',
            label: 'Customer Docs',
            children: <ApprovalCustomerDocuments/>,
        },
        {
            key: 'Customer Uploads',
            label: 'Customer Uploads',
            children: <ApprovedCustomerUploads/>,
        },
        {
            key: 'Funding',
            label: 'Funding',
            children: <Funding/>,
        },
        {
            key: 'Admin Documents',
            label: 'Prepare & Submit',
            children: <ApprovalAdminDocuments/>
        },

    ];
    return <StagesLayout title="Approval - Drawing Down" tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey}/>
   
}

export default ManagementApproval;