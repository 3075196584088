import React, {useState} from 'react';
import {AutoComplete, Button, Form, Input, InputNumber, Select} from "antd";
import {DateString} from "../../client/application/forms/SectionQuestion";
import SlashDate from "../../custom-inputs/SlashDate";
import {format, isValid} from "date-fns";
import {LONGDATE} from "../../../../assets/scripts/constants";
import QueryForm from "./QueryForm";
import ExistingQueries from "./ExistingQueries";

function QueryDrawerContent(props) {



    return (
        <div className="d-col gap-15">
            <ExistingQueries config={props.config}/>
            <h3>Create a Query</h3>
            <QueryForm config={props.config} close={props.close}/>
        </div>

    )
}

export default QueryDrawerContent;