import React from 'react';
import {Col, Row} from "antd";
import TaskList from "../../components/client/tasks/TaskList";
import TaskWrite from "../../components/client/tasks/TaskWrite";
import {useParams} from "react-router-dom";

function TasksPage(props) {
    const params = useParams()
    return (
        <Row>
            <Col md={12}>
                <TaskList/>
            </Col>
            <Col md={12}>
                {/*<div  className="p-sticky">*/}
                {/*    <TaskWrite id={params?.id}/>*/}
                {/*</div>*/}

            </Col>
        </Row>
    );
}

export default TasksPage;