import React from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";
import {LONGDATE} from "../../../../../../assets/scripts/constants";
import QueryItemForm from "./QueryItemForm";
import {Avatar, Collapse, Table} from "antd";
import {CheckCircleOutlined, CheckOutlined, DownCircleOutlined} from "@ant-design/icons";
import {green, volcano} from "@ant-design/colors";
import QueryItemTabs from "./QueryItemTabs";

function QueryItems({queries}) {
    const columns = [
        {title       : 'Created Date',
            dataIndex: 'createdAt',
            key      : 'createdAt'
        },
        {
            title    : 'Query',
            dataIndex: 'query',
            key      : 'query'
        },
        {
            title : 'Answered',
            render: (value, record) => {
                if (record.lenderAnswered) {
                    return <CheckCircleOutlined style={{color: green[5]}}/>
                }
                if (record.userAnswered) {
                    return <CheckCircleOutlined style={{color: volcano[5]}}/>
                }
                return ''
            },
            key   : 'answered',
            align : 'center'
        },
        {
            title : '',
            render: (value, record) => {
                if (record.lenderAnswered) {
                    return 'Admin has answered'
                }
                if (record.userAnswered) {
                    return 'Admin answer needed'
                }
                return 'Waiting for answer'
            },
            key   : 'message'
        },
    ]

    const sections = {}
    queries.forEach(it => {
        if (!sections.hasOwnProperty(it.title)) {
            sections[it.title] = []
        }
        sections[it.title].push(it)
    })
    let items = Object.keys(sections).map(key => {
        const innerItems = sections[key].map(item => {
            return {
                ...item,
                key           : `query-${item.id}`,
                createdAt     : dayjs(item.createdAt).format(LONGDATE),
                userAnswered  : !!item.userAnswer && item.userAnswer.length > 0,
                lenderAnswered: !!item.lenderAnswer && item.lenderAnswer.length > 0,
            }
        })
        let anyWithUserAnswer = innerItems.filter(it => it.userAnswered).length > 0
        let allWithLenderAnswer = innerItems.filter(it => it.lenderAnswered).length === innerItems.length
        let tag = allWithLenderAnswer ?
            <Avatar size="small" icon={<CheckOutlined/>} style={{backgroundColor: green[5]}}/> : anyWithUserAnswer ?
                <Avatar size="small" icon={<CheckOutlined/>} style={{backgroundColor: volcano[5]}}/> : null

        return {
            key  : `group-${key}`,
            label: (<div className="d-row gap-15">
                    <strong>Queries on {key}</strong>
                </div>),
            extra: tag, //children: <Collapse accordion className="radius-0" items={innerItems} expandIcon={()=><DownCircleOutlined/>}/>,
            children: (<Table
                pagination={false}
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => <QueryItemTabs item={record}/>
                }}
                dataSource={innerItems}
            />)
        }
    })

    return (

        <>
            <Collapse className="radius-0" items={items} defaultActiveKey={['1']} rootClassName="content-m-0"/>
        </>);
}

export default QueryItems;