import {ptsb} from "../../../../../../../assets/scripts/lender-calculators";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {ApplicationTypes, MortgageTypes} from "../../../../../../../models";
import useXls, {useXlsNew} from "../useXls";
function usePtsbXls() {
    const mortgage = useMortgage()
    const api = ptsb({mortgage})
    const getProcessedResults = (results)=>{
        let items= !!results ? Object.keys(results).map(key=>{
            return {
                key: key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items)=>{
            switch(key){
                //actualNets <= mexNets
                case 'Actual Nets':
                    let actualNets = parseFloat(items.find(it=>it.key === 'Actual Nets').value)
                    let maxNets = parseFloat(items.find(it=>it.key === 'Max Nets').value)
                    return actualNets <= maxNets
                case 'LTI Message':
                    let ltiMessage = items.find(it=>it.key === 'LTI Message').value
                    return ltiMessage.trim() === ""
                case 'Term Sought':
                    let termSought = parseInt(items.find(it=>it.key === 'Term Sought').value)
                    let maxTerm = items.find(it=>it.key === 'Max Term Allowed').value
                    return maxTerm === "" || termSought<= parseInt(maxTerm)

                case 'Proposed LTV':
                    let ltvMessage = items.find(it=>it.key==='LTV Breach').value
                    return ltvMessage.trim() === ""

                case 'Proven Repayment Ability':
                    let pra = items.find(it=>it.key==='Proven Repayment Ability').value
                    return parseFloat(pra) >= 85
                case 'Loan To Income':
                    let ltiMessage1 = items.find(it=>it.key === 'LTI Message').value
                    if (ltiMessage1.trim() !== "") return false
                    let lti = items.find(it=>it.key === 'Loan To Income').value
                    let max = mortgage.mortgageType === MortgageTypes.REFINANCE || mortgage.applicationType === ApplicationTypes.FIRSTTIME ? 4 : 3.5
                    return parseFloat(lti) <= max
                case 'MDI Requirement':
                    let mdir = items.find(it=>it.key === 'MDI Requirement').value
                    return mdir.trim() !== ""
                default: return null
            }
        }
        items.forEach(item=>{
            let test = doTest(item.key, items)
            if (test !== null){
                item.passed = test
            }
        })
        items = items.filter(item=>item.key!=='LTV Breach')
        return items
    }
    return useXls(api,getProcessedResults, mortgage)
}
export function usePtsbXlsNew(){
    const mortgage = useMortgage()
    const api = ptsb({mortgage})
    const getProcessedResults = (results)=>{
        let items= !!results ? Object.keys(results).map(key=>{
            return {
                key: key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items)=>{
            switch(key){
                //actualNets <= mexNets
                case 'Actual Nets':
                    let actualNets = parseFloat(items.find(it=>it.key === 'Actual Nets').value)
                    let maxNets = parseFloat(items.find(it=>it.key === 'Max Nets').value)
                    return actualNets <= maxNets
                case 'LTI Message':
                    let ltiMessage = items.find(it=>it.key === 'LTI Message').value
                    return ltiMessage.trim() === ""
                case 'Term Sought':
                    let termSought = parseInt(items.find(it=>it.key === 'Term Sought').value)
                    let maxTerm = items.find(it=>it.key === 'Max Term Allowed').value
                    return maxTerm === "" || termSought<= parseInt(maxTerm)

                case 'Proposed LTV':
                    let ltvMessage = items.find(it=>it.key==='LTV Breach').value
                    return ltvMessage.trim() === ""

                case 'Proven Repayment Ability':
                    let pra = items.find(it=>it.key==='Proven Repayment Ability').value
                    return parseFloat(pra) >= 85
                case 'Loan To Income':
                    let ltiMessage1 = items.find(it=>it.key === 'LTI Message').value
                    if (ltiMessage1.trim() !== "") return false
                    let lti = items.find(it=>it.key === 'Loan To Income').value
                    let max = mortgage.mortgageType === MortgageTypes.REFINANCE || mortgage.applicationType === ApplicationTypes.FIRSTTIME ? 4 : 3.5
                    return parseFloat(lti) <= max
                case 'MDI Requirement':
                    let mdir = items.find(it=>it.key === 'MDI Requirement').value
                    return mdir.trim() !== ""
                default: return null
            }
        }
        items.forEach(item=>{
            let test = doTest(item.key, items)
            if (test !== null){
                item.passed = test
            }
        })
        items = items.filter(item=>item.key!=='LTV Breach')
        return items
    }
    return useXlsNew(api,getProcessedResults, mortgage)
}

export default usePtsbXls;