import React, {useContext, useEffect, useState} from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Lenders} from "../../../../../providers/LendersProvider";
import {Button, Checkbox, Form} from "antd";

function ProposalEditor(props) {
    const mortgage = useMortgage()
    const {lenders} = useContext(Lenders)
    const [formNotSaved, setFormNotSaved] = useState(false)
    const [form] = Form.useForm();
    const [selectedLenders, setSelectedLenders] = useState([]);
    const origRef = React.useRef(null)
    const onFinish = async (values) => {
        const include = selectedLenders.map((lender) => ({lender}));

        const existing = mortgage.requirement.proposals
        existing.include = include
        await mortgage.mutate({
            update: {
                target: `requirement.proposals`,
                value: JSON.stringify(existing)
            }
        })
    };
    useEffect(() => {
        if (mortgage.requirement.proposals.hasOwnProperty('include')) {
            origRef.current = mortgage.requirement.proposals.include.map(i => i.lender)
            form.setFieldValue('include', mortgage.requirement.proposals.include.map(i => i.lender))
            setSelectedLenders(mortgage.requirement.proposals.include.map(i => i.lender))
            setFormNotSaved(false)
        }
    }, [mortgage.requirement.proposals])

    const onChange = (checkedValues) => {
        if (JSON.stringify(checkedValues) === JSON.stringify(origRef.current)) {
            setFormNotSaved(false)
        } else {
            setFormNotSaved(true)
        }
        setSelectedLenders(checkedValues);
    };

    return (
        <div style={{backgroundColor: "#e0e0e0"}} className="p-9">
            <Form
                form={form}
                name="lendersForm"
                layout="inline"
                onFinish={onFinish}
            >
                <Form.Item name="include" label="Include On Proposal">
                    <Checkbox.Group options={lenders.map(l => ({label: l.lender, value: l.lenderCode}))}
                                    onChange={onChange}/>
                </Form.Item>
                <Form.Item>
                    <Button size="small" type={formNotSaved ? "primary":"default"} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ProposalEditor;