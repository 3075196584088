import React, {useContext} from 'react';
import {CheckOutlined, DeleteOutlined, RollbackOutlined, RotateRightOutlined, UserOutlined} from "@ant-design/icons";
import {Menu, Tooltip} from "antd";
import {green, volcano} from "@ant-design/colors";
import {ToolDrawer} from "../../../providers/mortgage/ToolDrawerProvider";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {Auth} from "aws-amplify";
import {LONGDATE} from "../../../../assets/scripts/constants";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import {ReplyRequestTypes} from "../../../../models";
import {useNavigate} from "react-router-dom";
export function extractBrokerAssistNextActionAndComments(emailContent) {
    const nextActionRegex = /<b>Next Action<\/b>\s*is:\s*<span style='color:red'>(.*?)<\/span>/;
    const commentsRegex = /Comments<\/b>\s*:.*?<span style='color:red'>([\s\S]*?)<\/span>/;

    const nextActionMatch = emailContent.match(nextActionRegex);
    const commentsMatch = emailContent.match(commentsRegex);

    const nextAction = nextActionMatch ? nextActionMatch[1] : null;
    const comments = commentsMatch ? commentsMatch[1] : null;

    return {nextAction, comments};
}

function ActionBar({email, ...props}) {
    const mortgage = useMortgage()
    const toolDrawer = useContext(ToolDrawer)
    const backendUsers = useBackendUsers()
    const navigate = useNavigate()

    const composeReply = async () => {

        const user = await Auth.currentAuthenticatedUser()
        const addresses = email.to.filter(to => !to.includes('@sussd.ie'))
        //prepend the from address to addressed
        addresses.unshift(email.from)
        const config = {
            to: addresses,
            subject: `RE: ${email.subject}`,
            reply: email,
            mortgage,
        }
        if (email.cc && email.cc.length > 0) {
            config.cc = email.cc
        }
        toolDrawer.composeEmail(config)
    }

    const buildResponse = async (requestType) => {
        const {comments} = extractBrokerAssistNextActionAndComments(email.html);
        // check if there's an exiting replyRequest
        let existing = mortgage.replyRequests.find(request => request.emailId === email.id)
        if (existing && !existing.requestType){
            await mortgage.mutate({
                update: {
                    target: `replyRequests[${existing.id}].requestType`,
                    value: requestType
                }
            })
            existing.requestType = requestType
        }
        if (!existing) {
            // create a new replay response
            let result = await mortgage.mutate({
                create: {
                    target: 'replyRequests',
                    pairs: {
                        emailId: email.id,
                        receivedOn: email.datetimeReceived,
                        requestType,
                        replyTo: email.cc.length ? email.cc[0] : null,
                        mortgageId: mortgage.id,
                        requestBody: comments.split(/<br\s*\/?>/)
                    }
                }
            })
            existing = result[0]
        }
        const to = requestType === ReplyRequestTypes.PREAPPROVAL ? 'pre-approval-management' : 'approval-management'
        navigate(`/client/${mortgage.id}/${to}`)
    }

    const handleClick = (e) => {

        switch (e.key) {
            case 'reply':
                return composeReply()
            case 'action':
                return props.markActioned(email)
            case 'aipEmail':
                return buildResponse(ReplyRequestTypes.PREAPPROVAL)
            case 'offerEmail':
                return buildResponse(ReplyRequestTypes.APPROVAL)
        }
    }

    const items = []

    items.push({
        label: <ActionLabel email={email}/>,
        key: 'action'
    })
    items.push({
        label: 'Reply',
        key: 'reply',
        icon: <RollbackOutlined/>,
    })
    if (!email.userapplicantID) {
        items.push({
            label: 'Link with Applicant',
            key: 'reply3',
            icon: <UserOutlined/>
        })
    }
    if (email.from === 'no-reply@brokerassist.ie') {
        let showBuildResponseButton = false
        let existing = mortgage.replyRequests.find(request => request.emailId === email.id)

        if (!existing) {
            // check if email is in live replyRequests
            const {nextAction, comments} = extractBrokerAssistNextActionAndComments(email.html);

            if (nextAction === 'Await Broker Feedback') {
                showBuildResponseButton = true
            }
        } else {
            showBuildResponseButton = true
        }
        if (showBuildResponseButton) {
            items.push({
                label: <span style={{color: existing ? green[5] : volcano[5]}}>Mark As</span>,
                key: 'build',
                icon: <RotateRightOutlined/>,
                children:[
                    {
                        label: 'AIP Email',
                        key: 'aipEmail'
                    },
                    {
                        label: 'Offer Email',
                        key: 'offerEmail'
                    }
                ]
            })
        }
    }
    items.push({
        label: 'More',
        key: 'more',
        children: [
            {
                label: 'Delete',
                key: 'delete',
                icon: <DeleteOutlined/>
            }
        ],
    })

    function ActionLabel() {
        if (email.actionedBy === 'null') {
            return <>Action</>
        }
        const user = backendUsers.getBySub(email.actionedBy).firstName
        const time = dayjs(email.actionedOn).format(LONGDATE)
        return <Tooltip title={`${user} ${time}`}>
            <CheckOutlined style={{color: green.primary}}/> <span style={{color: green.primary}}> Actioned</span>
        </Tooltip>
    }

    return <div className="mb-6" style={{borderTop: '1px solid #f0f0f0'}}>
        <Menu onClick={handleClick} mode="horizontal" items={items}/>
    </div>
}

export default ActionBar;