import React, {useEffect, useState} from 'react';
import {listAllAndSetItems} from "../api-functions/functions";
import {adminPdfOverlayTemplatesByLender, listAdminLenders, publicLenderRatesByLenderID} from "../../graphql/queries";

export const Lenders = React.createContext({});
function LendersProvider(props) {
    const [lenders, setLenders] = useState([])

    const setRates = (rates) => {
        setLenders(s => {
            return s.map(it => {
                const newRates = rates.filter(t => t.lenderID === it.id && !it._deleted )
                if (newRates.length){
                    return {
                        ...it,
                        rates: newRates
                    }
                }
                return it
            })
        })
    }
    const setTemplates = (templates) => {
        setLenders(s => {
            return s.map(it => {
                const newTemplates = templates.filter(t => t.lenderID === it.id && !it._deleted)
                if (newTemplates.length) {
                    return {
                        ...it,
                        overlayTemplates: newTemplates
                    }
                }
                return it
            })
        })
    }
    const setAndContinueToPopulate= (responseObject, operation, variables, settingFunction)=>{
        const {items, nextToken} = responseObject
        if (nextToken) {
            listAllAndSetItems({
                ...variables,
                nextToken
            }, operation,  settingFunction)
        }
        return items.filter(it=>!it._deleted) || []
    }

    const customSetLenders = (lenders) => {
        let slimLenders = []
        lenders.filter(it=>!it._deleted).forEach(it => {
            const {rates, overlayTemplates, ...rest} = it
            slimLenders.push({
                ...rest,
                rates:setAndContinueToPopulate(rates, publicLenderRatesByLenderID, {lenderID: it.id}, setRates),
                overlayTemplates: setAndContinueToPopulate(overlayTemplates, adminPdfOverlayTemplatesByLender, {lender: it.id}, setTemplates)
            })
        })
        setLenders(s=>[...s,...slimLenders])
    }

    let timer = null
    useEffect(() => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            if (window.location.hostname === 'localhost') {
                localStorage.setItem('lenders', JSON.stringify(lenders))
            }
        }, 4000)
    }, [lenders]);

    useEffect(() => {
        if (window.location.hostname === 'localhost'){
            const existing = localStorage.getItem('lenders')
            if (existing){
                setLenders(JSON.parse(existing))
                return
            }
        }
        listAllAndSetItems({}, listAdminLenders, customSetLenders)
        //lenders(setLenders)
    }, []);
    const updateOrAddLenderOverlayTemplate = (doc)=>{

        setLenders(s=>s.map(it=>{

            if (it.id === doc.lender){
                if (doc._deleted){
                    return {
                        ...it,
                        overlayTemplates: it.overlayTemplates.filter(r=>r.id !== doc.id)
                    }
                }

                let updating = !!it.overlayTemplates.find(r=> r.id === doc.id)
                if (!updating){
                    return {
                        ...it,
                        overlayTemplates: [...it.overlayTemplates, doc]
                    }
                }
                return {
                    ...it,
                    overlayTemplates: it.overlayTemplates.map(inner=>{
                        if (inner.id === doc.id){
                            return doc
                        }
                        return inner
                    })
                }
            }
            return it
        }))
    }
    const updateOrAddLenderRate = (rate)=>{
        setLenders(s=>s.map(it=>{
            if (it.id === rate.lenderID){
                if (rate._deleted) {
                    return {
                        ...it,
                        rates: it.rates.filter(r => r.id !== rate.id)
                    }
                }
                let updating = !!it.rates.find(r=> r.id === rate.id)
                if (!updating){
                    return {
                        ...it,
                        rates: [...it.rates, rate]
                    }
                }
                return {
                    ...it,
                    rates: it.rates.map(inner=>{
                        if (inner.id === rate.id){
                            return rate
                        }
                        return inner
                    })
                }
            }
            return it
        }))
    }

    return (
        <Lenders.Provider value={{
            lenders: lenders.filter(it=>it.lenderCode !== 'sussd'),
            updateOrAddLenderOverlayTemplate,
            updateOrAddLenderRate
        }}>
            {props.children}
        </Lenders.Provider>
    );
}

export default LendersProvider;