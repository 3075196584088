import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PageHeader from "../PageHeader";
import {Button, Skeleton, Upload} from "antd";
import LenderSourceDocUpload from "./LenderSourceDocUpload";
import LenderSourceDocOverlay from "./LenderSourceDocOverlay";
import {TemplateTypes} from "../../../models";
import CompanyDocs from "./CompanyDocs";
import LenderSourceDocLabels from "./LenderSourceDocLabels";

LenderSourceDocs.propTypes = {
    
};

function LenderSourceDocs(props) {
    const [editing,setEditing] = useState(null)
    if (!props.lender){
        return <div className="m-15"><Skeleton loading active/> </div>
    }
    return (
        <div>
            <PageHeader className="p-15" title={`${props.lender.lender} Documents`} back={"./../"}></PageHeader>

            <div className="d-row m-15 gap-15">
                <CompanyDocs lender={props.lender} setEditing={setEditing} updateOrAddLenderOverlayTemplate={props.updateOrAddLenderOverlayTemplate}/>
                {(editing && editing.templateType === TemplateTypes.OVERLAID) && (
                    <LenderSourceDocOverlay key={`lsdo${editing?.id}`} lender={props.lender} file={editing}/>
                )}
                {(editing && editing.templateType === 'LABEL') && (
                    <LenderSourceDocLabels key={`lddsdo${editing?.id}`} lender={props.lender} file={editing}/>
                )}
            </div>

        </div>
    );
}

export default LenderSourceDocs;