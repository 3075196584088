import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {boi} from "../../../../../../../assets/scripts/lender-calculators/boi";
import useXls, {useXlsNew} from "../useXls";

export function useBoiXlsNew() {

    const mortgage = useMortgage()
    const api = boi({mortgage})
    const getProcessedResults = (results) => {
        console.log({results})
        let items = !!results ? Object.keys(results).map(key => {
            return {
                key  : key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items) => {
            switch (key) {
                case 'NDI Surplus / Shortfall':
                    let v1 = parseFloat(items.find(it => it.key === 'NDI Surplus / Shortfall').value.replace(/\D/g, ''))
                    return v1  >= 0
                case '% Proven DRA':
                    return parseFloat(items.find(it => it.key === '% Proven DRA').value.replace(/\D/g, '')) >= 100
                case 'DRA Shortfall':
                    return items.find(it => it.key === 'DRA Shortfall').value === "€0"
                case 'Credit Policy Results':
                    return items.find(it => it.key === 'Credit Policy Results').value === "Meets affordability criteria"
                case 'CBoI Limits':
                    return items.find(it => it.key === 'CBoI Limits').value === "Within CBI macro prudential limits"
                default:
                    return null
            }
        }
        items.forEach(item => {
            let test = doTest(item.key, items)
            if (test !== null) {
                item.passed = test
            }
        })
        return items
    }
    return useXlsNew(api, getProcessedResults, mortgage)
}
export function useBoiXls() {

    const mortgage = useMortgage()
    const api = boi({mortgage})
    const getProcessedResults = (results) => {
        console.log({results})
        let items = !!results ? Object.keys(results).map(key => {
            return {
                key  : key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items) => {
            switch (key) {
                case 'NDI Surplus / Shortfall':
                    let v1 = parseFloat(items.find(it => it.key === 'NDI Surplus / Shortfall').value.replace(/\D/g, ''))
                    return v1  >= 0
                case '% Proven DRA':
                    return parseFloat(items.find(it => it.key === '% Proven DRA').value.replace(/\D/g, '')) >= 100
                case 'DRA Shortfall':
                    return items.find(it => it.key === 'DRA Shortfall').value === "€0"
                case 'Credit Policy Results':
                    return items.find(it => it.key === 'Credit Policy Results').value === "Meets affordability criteria"
                case 'CBoI Limits':
                    return items.find(it => it.key === 'CBoI Limits').value === "Within CBI macro prudential limits"
                default:
                    return null
            }
        }
        items.forEach(item => {
            let test = doTest(item.key, items)
            if (test !== null) {
                item.passed = test
            }
        })
        return items
    }
    return useXls(api, getProcessedResults, mortgage)
}