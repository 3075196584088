import React, {useContext, useState} from 'react';
import {Badge, Card, DatePicker, Popconfirm, Typography} from "antd";
import {
    CaretRightOutlined, CheckOutlined,
    DeleteOutlined, EditOutlined,
    PushpinOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";

import {LONGDATE} from "../../../../assets/scripts/constants";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {green, red} from "@ant-design/colors"
import dayjs from "dayjs";
import {Auth} from "aws-amplify";
import {Link} from "react-router-dom";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
function TaskListItem({item}) {
    const users = useBackendUsers()
    const mortgage = useMortgage()
    function Pinned() {
        const prop = item.pinned ? {style: {color: red.primary}} : {}
        return (
            <PushpinOutlined key="pin" onClick={handlePinClick} {...prop}/>
        )
    }
    function DeleteConfirm() {
        return <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this taks?"
            okText="Delete"
            onConfirm={handleDeleteClick}
            icon={
                <QuestionCircleOutlined
                    style={{
                        color: red.primary,
                    }}
                />
            }
        >
            <DeleteOutlined/>
        </Popconfirm>
    }

    const handlePinClick = async () => {
        await mortgage.mutate({
            update: {
                target: `tasks[${item.id}].pinned`,
                value: !item.pinned
            }
        })
    }
    const handleDoneClick = async () => {
        let pairs={}
        if (item?.completedBy !== "null"){
            pairs.completedBy="null"
            pairs.completedOn = null
        }
        else{
            const user = await Auth.currentAuthenticatedUser()
            pairs.completedBy=user.attributes.sub
            pairs.completedOn = new Date().toISOString()
        }
        await mortgage.mutate({
            update: {
                target: `tasks[${item.id}]`,
                pairs
            }
        })
    }
    const handleDeleteClick = async (e) => {
        await mortgage.mutate({
            delete: {
                target: `tasks`,
                id : item.id
            }
        })
    }

    const handlePostponeChanged = async (datetime) => {
        await mortgage.mutate({
            update: {
                target: `tasks[${item.id}].due`,
                value: datetime
            }
        })
    }
    function PostponeDate() {
        const [open, setOpen] = useState(false)
        const range = (start, end) => {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        };
        const handleOkClick = (e)=>{
            setOpen(false)
            handlePostponeChanged(e.toISOString())
        }
        const disabledDateTime = () => ({
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 5, 19, 20, 21, 22, 23],
            disabledMinutes: () => range(0, 60).filter(i => i % 10 !== 0),
        });
        if (open) {
            return <DatePicker
                onOk={handleOkClick}
                format={"D MMM YYYY HH:mm"}
                defaultValue={dayjs(item.due)}
                disabledTime={disabledDateTime}
                open={true}
                showTime={{
                    format: 'HH:mm',
                    hideDisabledOptions: true,
                }}

            />
        }
        return <span className=" px-6" onClick={() => setOpen(true)}>Due: {dayjs(item.due).format( LONGDATE)}</span>
    }
    function DoneTick(){
        const colorStyle = item.completedBy!=="null" ? {style:{color: green.primary}} : {}
        return <CheckOutlined onClick={handleDoneClick} {...colorStyle} />
    }
    function Ribbon({children}){
        const text = <>
            {users.getBySub(item.author).firstName}
            <CaretRightOutlined/>
            {users.getBySub(item.target).firstName}
        </>
        const parsedDate = dayjs(item.due);
        const currentDate = dayjs();
        const color = item?.completedBy!=="null" ? 'green': (parsedDate.isAfter(currentDate)) ? 'gray':'volcano'

        return <Badge.Ribbon color={color} styles={{width: '100%'}} text={text}>
            {children}
        </Badge.Ribbon>
    }

    return (
        <Ribbon>
            <Card
                styles={{body:{paddingTop:30}}}
                size="small"
                actions={[
                    <div className="mt-3 d-row j-evenly a-center">
                        <DeleteConfirm key="delete"/>
                        <Pinned key="pinned"/>
                        <Link style={{width:'auto'}} to={`/client/${mortgage.id}/tasks/${item.id}`}><EditOutlined/></Link>
                        <DoneTick/>
                    </div> ,
                    <PostponeDate/>,

                ]}
            >
                <Typography.Paragraph ellipsis={{rows: 4, expandable: true, symbol: 'more'}}>
                    {item.body.map(parg =>
                        <React.Fragment key={`note-text-${parg}`}>{parg}<br/></React.Fragment>)
                    }
                </Typography.Paragraph>
            </Card>
        </Ribbon>

    )

}

export default TaskListItem;