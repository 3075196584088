import dayjs from "dayjs";

export default function (mortgage, config) {
    const elements = {
        "mortgage-account-number": mortgage.id,
        "applicant-1-name": mortgage.applicant1.fullName,
        "applicant-2-name": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
        "satisfied-to-proceed-yes": true,
        "satisfied-to-proceed-no": false,
        "income-employment-change-no": true,
        "income-employment-change-yes": false,
        "applicant-1-sign-date": (dayjs()).format('D MMM YYYY'),
        "applicant-2-sign-date": (dayjs()).format('D MMM YYYY'),
    }

    const accountNumber = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'mortgage-account-number',
            label: 'Mortgage Account Number',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('mortgage-account-number')) {
            map['mortgage-account-number'] = config['mortgage-account-number']
        } else {
            if (!mortgage.requirement.mortgageAccountNo) {
                errors.push('No mortgage account number')
            } else {
                map['mortgage-account-number'] = mortgage.requirement.mortgageAccountNo
            }
        }
        return {map, ask, errors}
    }

    const incomeChange = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'income-employment-change',
            label: 'Has income changing/changed?',
            type: 'CHECK',
            group: 'INCOME'
        })
        ask.push({
            name: 'income-change-details',
            label: 'Details',
            type: 'TEXT',
            group: 'INCOME'
        })
        if (config.hasOwnProperty('income-employment-change')) {
            map['income-employment-change-no'] = false
            map['income-employment-change-yes'] = true
        } else {
            map['income-employment-change-no'] = true
            map['income-employment-change-yes'] = false
        }
        if (config.hasOwnProperty('income-change-details')) {
            map['income-change-details'] = config['income-change-details']
        }
        return {map, ask}
    }

    const proceed = () => {
        let map = {}
        map['satisfied-to-proceed-yes'] = true
        map['satisfied-to-proceed-no'] = false
        return {map}
    }

    const applicantNames = () => {
        let map = {}
        map['applicant-1-name'] = mortgage.applicant1.fullName.toUpperCase()
        if (mortgage.twoApplicants) {
            map['applicant-2-name'] = mortgage.applicant2.fullName.toUpperCase()
        }
        return {map}
    }

    const sections = [
        accountNumber(),
        applicantNames(),
        incomeChange(),
        proceed()

    ]
    return {sections}
}