import React, {useEffect} from 'react';
import {App, Badge, Descriptions, Skeleton, Tag, Tooltip} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage"

import {LONGDATE, LONGDATETIME12} from "../../../../assets/scripts/constants"
import {
    CheckCircleFilled, EditOutlined, ExclamationCircleOutlined, MailOutlined, QuestionCircleFilled, UserOutlined
} from "@ant-design/icons";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";
import {orange} from "@ant-design/colors";
import dayjs from "dayjs";
import {capitaliseFirst} from "../../../../assets/scripts/string-functions";
import {ApplicationTypes, BoolLike, MortgageTypes} from "../../../../models";

function ClientHeader(props) {
    const app = App.useApp()
    const users = useBackendUsers()
    const mortgage = useMortgage()
    console.log({mortgage})

    let items = []
    function DependentsChildren() {
        if (!mortgage) {
            return null
        }
        let count = (mortgage.applicant1.personal.countDependents || 0) + (mortgage.applicant2.personal.countDependents || 0)
        let warning
        if (mortgage.twoApplicants && mortgage.applicant1.personal.countDependents === mortgage.applicant2.personal.countDependents && mortgage.applicant1.personal.countDependents > 0) {
            warning = (<Tooltip key={"warning-dependents"} title={`Applicants each have ${mortgage.applicant1.personal.countDependents} depenants`}>
                <>
                    <ExclamationCircleOutlined className="ms-6" style={{
                        color   : orange[4],
                        fontSize: 22
                    }}/>
                    Duplicate?
                </>
            </Tooltip>)
        }
        return <>
            {count}
            {warning}
        </>
    }

    function StatusTag({mortgage}) {
        if (mortgage._deleted) {
            return <Tag color="red">Deleted</Tag>
        }
        switch (mortgage.applicationStatus) {
            case 'ACTIVE':
                return <Tag color="green">Active</Tag>
            case 'SUSPENDED':
                return <Tag color="red">Suspended</Tag>
            case 'COMPLETED':
                return <Tag color="green">Completed</Tag>
            case 'OFFLINE':
                return <Tag color="orange">Offline</Tag>
            case 'DELAYED':
                return <Tag color="magenta">Delayed - {dayjs(mortgage.applicationFutureDate).format('D MMM YYYY')}</Tag>
        }
    }
    if (mortgage) {
        let app1LastHere = mortgage.applicant1.lastHereTime?.time
        if (!app1LastHere) {
            // app1LastHere = mortgage.applicant1?.lastHere || mortgage.lastSeen
        }
        let app2LastHere = mortgage.applicant2.lastHereTime?.time
        if (!app2LastHere) {
            // app2LastHere = mortgage.applicant2?.lastHere
        }
        let mortgageType
        let ftbTags = []
        if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
            switch (mortgage.applicationType) {
                case ApplicationTypes.FIRSTTIME:
                    mortgageType = 'First Time Buyer'
                    switch (mortgage.requirement.helpToBuyScheme) {
                        case BoolLike.TRUE:
                            ftbTags.push(<Tag color="green" key="htn-yes">HTB <CheckCircleFilled/></Tag>)
                            break
                        case BoolLike.NOTSURE:
                            ftbTags.push(<Tag color="orange" key="htb-notsure">HTB <QuestionCircleFilled/></Tag>)
                            break
                    }
                    switch (mortgage.requirement.firstHomeScheme) {
                        case BoolLike.TRUE:
                            ftbTags.push(<Tag color="green" key="fhs-yes">FHS <CheckCircleFilled/></Tag>)
                            break
                        case BoolLike.NOTSURE:
                            ftbTags.push(<Tag color="orange" key="fhs-notsure">FHS <QuestionCircleFilled/></Tag>)
                            break
                    }
                    break
                case ApplicationTypes.MOVINGHOME:
                    mortgageType = 'Mover'
                    break
                case ApplicationTypes.SECONDTIME:
                    mortgageType = 'Second Time Buyer'
                    break
                case ApplicationTypes.LANDLORD:
                    mortgageType = 'Investor Purchase'
                    break
            }
        }
        else {
            switch (mortgage.applicationType) {
                case ApplicationTypes.LANDLORD:
                    mortgageType = 'Investor Refinance'
                    break
                case ApplicationTypes.OWNHOME:
                    mortgageType = 'Switcher'
                    break
            }
        }
        if (mortgage.requirement.applicationElsewhere) {
            ftbTags.push(<Tag key="tag-applicationElsewhere" color="red">App Elsewhere!</Tag>)
        }
        items = [
            {
                key     : 'a1',
                label   : 'Applicants',
                children: (<div className="d-row j-equal">
                        <span className="d-row gap-9 a-center">
                            {mortgage.applicant1.fullName}
                            {!!mortgage.applicant1.age ? <Badge color="cyan" size="small" count={mortgage.applicant1.age + "yr"}/> : ''}
                            {!!mortgage.requirement.contactMethod && <Tag color="volcano" size="small">{mortgage.requirement.contactMethod}</Tag>}
                        </span>
                    {mortgage.twoApplicants && (<span className="d-row gap-9 a-center">
                                {mortgage.applicant2.fullName}
                        {!!mortgage.applicant2.age ? <Badge color="cyan" size="small" count={mortgage.applicant2.age + "yr"}/> : ''}
                            </span>)}

                </div>),
            },
            {
                key     : 'a2',
                label   : 'Started',
                children: dayjs(mortgage.createdAt).format('D MMM YYYY'),
            },
            {
                key     : 'a3',
                label   : 'Advisor',
                children: 'Kevin',
            },

            {
                key     : 'b1',
                label   : 'Email',
                children: (<div className="d-row j-equal">
                    <span>{mortgage.applicant1.email} {!!mortgage.applicant1.identityID ? <UserOutlined/> : null}</span>
                    {mortgage.twoApplicants && (
                        <span>{mortgage.applicant2.email} {!!mortgage.applicant2.identityID ? <UserOutlined/> : mortgage.invitations.length > 0 ? <MailOutlined/> : null}</span>)}
                </div>),
            },
            {
                key     : 'b2',
                label   : 'Dependants',
                children: <DependentsChildren/>
            },
            {
                key     : 'b3',
                label   : 'Status',
                children: <StatusTag mortgage={mortgage}/>,
            },

            {
                key     : 'c1',
                label   : 'Phone',
                children: (<div className="d-row j-equal">
                    <span>{mortgage.applicant1.mobile}</span>
                    {mortgage.twoApplicants && (<span>{mortgage.applicant2.mobile}</span>)}
                </div>),
            },
            {
                key     : 'c2',
                label   : 'Mortgage Type',
                children: <>{mortgageType} {ftbTags}</>,
            },
            {
                key     : 'c3',
                label   : 'Stage',
                children: <Tag color="gold" key="c3tag">{mortgage.applicationStage}</Tag>,
            },

            {
                key     : 'd1',
                label   : 'Last Online',
                children: (<div className="d-row j-equal" key="d1-child">
                        <span>
                            {app1LastHere ? capitaliseFirst(dayjs(app1LastHere).fromNow()) : 'Never'}</span>
                    {mortgage.twoApplicants && (<span>{app2LastHere ? capitaliseFirst(dayjs(app2LastHere).fromNow()) : 'Never'}</span>)}
                </div>),
            },
            {
                key     : 'd2',
                label   : 'Loan Details',
                children: (<>
                    {mortgage.activeSubmission?.lenderCode && <Tag key={'d2tag'} color="cyan">{mortgage.activeSubmission?.lenderCode}</Tag>}
                    {!mortgage.activeSubmission?.lenderCode && mortgage.requirement.lenderCode && <Tag key={'d2tag'} color="red">{mortgage.requirement.lenderCode}</Tag>}
                    {mortgage.requirement?.loanRequired?.toEuro()} over {mortgage.requirement?.loanTerm || '?'} years

                </>)
            },
            {
                key     : 'd3',
                label   : 'Friendly Name',
                children: <>{mortgage?.friendlyName}</>
            },
        ]
    }
    useEffect(() => {
        if (mortgage?.notes?.length) {
            let pinnedNotes = mortgage.notes.filter(note => note.pinned)
            if (pinnedNotes.length) {
                pinnedNotes.forEach(note => {
                    app.notification.open({
                        icon       : <EditOutlined/>,
                        duration   : 0,
                        message    : `by ${users.getBySub(note.author).firstName} on ${dayjs(note.createdAt).format(LONGDATETIME12)}`,
                        description: note.body.map((part, i) => <React.Fragment
                            key={`note-desc-${i}`}>{part}<br/></React.Fragment>)
                    })
                })
            }
        }
    }, [mortgage.notes])
    return (<>
        <Skeleton loading={!mortgage} className="p-15 m-15">
            <Descriptions column={3} className="radius-0" size="small" title={false} bordered items={items}/>
        </Skeleton>
        <Skeleton loading={!mortgage} className="p-15 m-15"/>
    </>)
}

export default ClientHeader;