import React, {useContext, useMemo} from 'react';
import {cyan, geekblue, gray, green, lime, orange, red} from "@ant-design/colors";
import {ClockCircleOutlined, DownOutlined, FacebookOutlined, FlagFilled, SettingOutlined} from "@ant-design/icons";
import {Alert, Avatar, Button, Card, Dropdown, Form, Input, Select, Timeline} from "antd";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {MortgageActiveAgents} from "../../../../../../models";
import dayjs from "dayjs";
import {LONGDATETIME12} from "../../../../../../assets/scripts/constants";
import useSuitabilityLetter from "../../../../../../config-files/mortgage/documents/useSuitabilityLetter";
import useSussdCoverNote from "../../../../../../config-files/mortgage/documents/useSussdCoverNote";
import useSussdApplicationForm from "../../../../../../config-files/mortgage/documents/useSussdApplicationForm";
import {Mortgages} from "../../../../../providers/MortgagesProvider";
import {MessageAlertContent, useMessages} from "../../../../../providers/mortgages-messaging/MortgagesMessagingProvider";
import {ToolDrawer} from "../../../../../providers/mortgage/ToolDrawerProvider";

function StageTimeline({
    title, // items=[],
    extras = null,
    setActiveKey
})
{
    const mortgage = useMortgage()
    const backendUsers = useBackendUsers()
    const {
              open,
              test,
              reset
          } = useSuitabilityLetter()



    const {
              forEveryone,
              getHistoryByMortgageId
          } = useMessages()
    const toolsDrawer = useContext(ToolDrawer)

    const cN = useSussdCoverNote({})
    const aF = useSussdApplicationForm()
    if (!cN || !aF) {
        return null
    }
    const {open: openTest2} = cN
    const {open: openTest3} = aF
    const mine = getHistoryByMortgageId(mortgage.id)




    function TimeLineLabel({item}) {
        const prop = item.onClick && typeof item.onClick === "function" ? {
            onClick: item.onClick,
            style  : {cursor: 'pointer'}
        } : {}
        return (<>
            <div className="d-col" style={{paddingLeft: '50%'}}>
                <span {...prop}>
                    {item.title}
                </span>
            </div>
            {item.isActive && (<Alert style={{marginLeft: '-26px'}} className="mt-15" message={<MessageAlertContent toolsDrawer={toolsDrawer} setActiveKey={setActiveKey} item={item} mortgage={mortgage}/>} type="warning" showIcon={false}/>)}
        </>)
    }

    const timelineFlows = mine.flatMap(item => {

        return item.flows.map(flowsArray => {
            let activeFlow = flowsArray.find(flow => flow.due && !flow.done)

            let activeEncountered = false
            let array =  flowsArray.map(flow => {
                let key = `${item.mortgage.id}-${flow.title}`
                if (!activeEncountered && flow.due) {
                    let done = flow.done?.on ? dayjs(flow.done.on).format(LONGDATETIME12) : ''
                    if (flow.title === activeFlow?.title) {
                        flow.isActive = true
                        activeEncountered = true
                    }
                    const obj = {
                        color   : gray[4], // label: item.label,
                        children: <TimeLineLabel key={key + 'child'} item={flow}/>,
                        label   : <div>{done}</div>,
                        key     : key + 'step',
                    }
                    if (flow.due) {
                        obj.color = red[5]
                    }
                    if (flow.done) {
                        obj.color = green[4]
                    }
                    if (flow?.done?.faked){
                        obj.color = lime[3]
                    }
                    return obj
                }
                return null
            }).filter(item => !!item)
            if (!activeFlow){
                array.push({
                    color   : orange[4],
                    dot: <ClockCircleOutlined/>,
                    children: <TimeLineLabel item={{
                        title: 'Waiting',
                    }}/>,
                    key     : item.key + 'wait',
                    label: ' '
                })
            }
            return array
        })
    })

    // const stepsOld = items.map(item => {
    //     const obj = {
    //         color   : gray[4], // label: item.label,
    //         children: <TimeLineLabel key={item.key + 'child'} item={item}/>,
    //         key     : item.key + 'step',
    //     }
    //     if (item.is?.due) {
    //         obj.color = orange[5]
    //     }
    //     if (item.is?.done) {
    //         obj.color = green[4]
    //     }
    //     if (item.type === 'wait') {
    //         obj.color = orange[4]
    //         obj.dot = <ClockCircleOutlined/>
    //     }
    //     return obj
    // })

    const handleMessageChange = async (e) => {
        const update = {
            target: '',
            pairs : {
                adminMessage  : e.target.value,
                adminMessageBy: backendUsers.me.sub,
                adminMessageOn: new Date().toISOString()
            }
        }
        await mortgage.mutate({update})
    }

    const handleSelect = async (value) => {
        const update = {
            target: 'activeAgent',
            value
        }
        await mortgage.mutate({update})
    }

    return (<Card style={{
        minWidth: 350,
        maxWidth: 400
    }} title={<Form.Item className="mb-15" label={"Mortgage Message"}>
        <div className="d-row gap-3 a-center">
            <Input onBlur={handleMessageChange} defaultValue={mortgage.adminMessage}/>
            {!!mortgage.adminMessageBy && (<Avatar size="small" style={{flexShrink: 0}}
                                                   icon={backendUsers.getBySub(mortgage.adminMessageBy).firstName[0]}/>)}
            <Select onSelect={handleSelect} defaultValue={mortgage.activeAgent} style={{width: 120}} options={[
                {
                    label: <><FlagFilled style={{color: red.primary}}/> Broker </>,
                    value: MortgageActiveAgents.BROKER
                },
                {
                    label: <><FlagFilled style={{color: orange.primary}}/> Lender </>,
                    value: MortgageActiveAgents.LENDER
                },
                {
                    label: <><FlagFilled style={{color: cyan.primary}}/> User </>,
                    value: MortgageActiveAgents.USER
                },
            ]}/>
        </div>
        {!!mortgage.adminMessageOn && (<div className="d-row gap-3" style={{fontWeight: 'normal'}}>
            <small>Message last updated</small>
            <small>{dayjs(mortgage.adminMessageOn).format(LONGDATETIME12)}</small>
        </div>)}
    </Form.Item>}>

        {timelineFlows.map((items, index) => {

            return (<Timeline key={index} items={items}/>)
        })}


        <Button onClick={open}>Open Letter of Suitability</Button>
        {/*<Button onClick={reset}>Reset</Button>*/}
        {/*<Button onClick={openTest2}>Open Cover Note</Button>*/}
        {/*<Button onClick={openTest3}>Application Form</Button>*/}
        {extras}
    </Card>)
}

export default StageTimeline;