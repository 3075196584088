import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {accumulateMortgageFigures, mortgageFiguresColumns, TableHeading} from "../FiguresUsed";
import {Table} from "antd";
import {orange, volcano} from "@ant-design/colors";

function Credit(props) {
    const mortgage = useMortgage()
    const figures = mortgage.figures.unverified
    let prefixes = [
        'credit',
    ]
    let rows = prefixes.map(key => {
        let row = {
            key: key,
            label: key,
            app1value: figures[`${key}1`]
        }
        if (mortgage.twoApplicants) {
            row.app2value = figures[`${key}2`]
        }
        return row
    }).filter(row => {
        if (row.app2value) {
            return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning && row.app2value.value === 0 && !row.app2value.error && !row.app2value.warning)
        }
        return !(row.app1value.value === 0 && !row.app1value.error && !row.app1value.warning)
    })
    let totalCredit = accumulateMortgageFigures(rows)
    return (<Table locale={{ emptyText: 'None' }} title={() => <TableHeading title="Cards/Credit" color={orange.primary} total={totalCredit}/>}
                   size="small"
                   dataSource={rows} columns={mortgageFiguresColumns(mortgage)}
                   pagination={false}/>);
}

export default Credit;