import React, {useState} from 'react';
import {LoadingOutlined} from "@ant-design/icons";
import {gray, green} from "@ant-design/colors";
import {Button, App, Input, Space} from "antd";
import {API} from "aws-amplify";
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../../../../../../../providers/mortgage/useMortgageUploadService";

function Stamper({uploadId}) {
    const [stamping, setStamping] = useState(false)
    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const app = App.useApp()
    const row = mortgage.uploads.find(item => item.id === uploadId)

    const handleStamp = async (placement) => {
        const {path, config} = uploadService.get(row)
        let realPath = path
        //if already stamped, remove the -stamped from the filename
        if (row.stampedOn) {
            const parts = path.split('/')
            const filename = parts.pop()
            const newFilename = filename.replace('-stamped', '')
            parts.push(newFilename)
            realPath = parts.join('/')
        }

        let fullpath = `${config.level}/${config.identityId}/${realPath}`

        try {
            const result = await API.post("sussdAdminAPI", "/operations/uploads/stamp2", {
                body: {
                    key: fullpath,
                    placement
                }
            })
            if (result.success) {
                await mortgage.mutate({
                    update: {
                        target: `uploads[${row.id}].stampedOn`,
                        value: new Date().toISOString()
                    }
                })
            }
            setStamping(false)
            return result
        } catch (e) {
            setStamping(false)
            app.message.error(e.message)
        }
    }
    const handleStampClick = async (placement) => {
        await handleStamp(placement)
    }

    let style = {width: 50, height: 50, display: 'flex', padding: 3, fontSize: 9}
    let box = {border: '1px solid lightgray', padding:'1px 6px' }
    if (!row) return null

    return (
        <>
            <span>Stamp {stamping && <LoadingOutlined/>}</span>
            <div className="d-row gap-3">
                <Button className="j-start a-start" style={style} disabled={stamping} size="small" onClick={() => handleStampClick('tl')}>
                   <div style={box}> TL </div>
                </Button>
                <Button className="j-end a-start" style={style} disabled={stamping} size="small" onClick={() => handleStampClick('tr')}>
                    <div style={box}> TR</div>
                </Button>
            </div>
            <div className="d-row gap-3">

                <Button className="j-start a-end" style={style} disabled={stamping} size="small" onClick={() => handleStampClick('bl')}>
                    <div style={box}> BL</div>
                </Button>
                <Button className="j-end a-end" style={style} disabled={stamping} size="small"
                        onClick={() => handleStampClick('br')}>
                    <div style={box}> BR </div>
                </Button>
            </div>
        </>
    )
}


export default Stamper;