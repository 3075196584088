import {dataPoint, get, getNullCells} from "./index";
import {ApplicationTypes, DebtTypes, MortgageTypes, PersonalMaritalStatuses} from "../../../models";
import dayjs from "dayjs";
import {legacyIncome} from "../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";
import {API} from "aws-amplify";


function nua({mortgage}) {
    const applicantInputs = [

        'age',
        'paye_basicSalary',
        'paye_carAllowance',
        'paye_shiftAllowanceYear1',
        'paye_shiftAllowanceYear2',
        'paye_overtimeYear1',
        'paye_overtimeYear2',
        'paye_bonusYear1',
        'paye_bonusYear2',
        'paye_commissionYear1',
        'paye_commissionYear2',
        'self_earningsYear1',
        'self_earningsYear2',
        'self_salaryYear1',
        'self_salaryYear2',
        'self_dividendsYear1',
        'self_dividendsYear2',
        'self_netProfitYear1',
        'self_netProfitYear2',
        'other_maintenance',
        'other_socialWelfare',
        'other_variable',
        'other_guaranteed',

    ]
    const mortgageInputs = [
        'mortgageType',
        'applicationType',
        'requirement',
        'properties',
        'annualRegularOutgoings',
        'annualDebtService'
    ]
    const createApplicantDataPoints = (n) => {
        if (!mortgage.twoApplicants && n === 2) {
            return []
        }
        let appString = `applicant${n}`
        let applicant = mortgage[appString]
        return [
            dataPoint('Age', `${appString}.age`, {
                type: 'number',
            }, () => {
                let dob = dayjs(applicant.personal.dateOfBirth, 'YYYY-MM-DD')
                return dayjs().diff(dob, 'years')
            },),
            dataPoint('Marital Status', `${appString}.maritalStatus`, {
                type   : 'select',
                options: [
                    '0: single',
                    '1: married',
                    '2: divorced',
                    '3: widowed'
                ],
            }, () => {
                switch (applicant.personal.maritalStatus) {
                    case PersonalMaritalStatuses.REMARRIED:
                    case PersonalMaritalStatuses.MARRIED:
                    case PersonalMaritalStatuses.SEPARATED:
                        return '1: married'
                    case PersonalMaritalStatuses.SINGLE:
                        return '0: single'
                    case PersonalMaritalStatuses.DIVORCED:
                        return '2: divorced'
                    case PersonalMaritalStatuses.WIDOWED:
                        return '3: widowed'
                }
            }),
            // PAYE
            dataPoint('PAYE Basic', `${appString}.paye_basicSalary`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`basic${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`basic${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`basic${n}`]
                }
            }),
            dataPoint('PAYE Car Allowance', `${appString}.paye_carAllowance`, {
                type: 'number',
            }, () => {
                return 0
            },),
            dataPoint('PAYE Shift Allowance / Other Year 1', `${appString}.paye_shiftAllowanceYear1`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`allowances${n}`].total + mortgage.figures.unverified[`other${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`allowances${n}`] + formData[`other${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`allowances${n}`] + newFormData[`other${n}`]
                }
            }),
            dataPoint('PAYE Shift Allowance / Other Year 2', `${appString}.paye_shiftAllowanceYear2`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`allowances${n}`].total + mortgage.figures.unverified[`other${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`allowances${n}`] + formData[`other${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`allowances${n}`] + newFormData[`other${n}`]
                }
            }),
            dataPoint('PAYE Overtime Year 1', `${appString}.paye_overtimeYear1`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`overtime${n}`].total
                }
                return 0
            }, (formatData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formatData[`overtime${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`overtime${n}`]
                }
            }),
            dataPoint('PAYE Overtime Year 2', `${appString}.paye_overtimeYear2`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`overtime${n}`].total
                }
                return 0
            }, (formatData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formatData[`overtime${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`overtime${n}`]
                }
            }),
            dataPoint('PAYE Bonus Year 1', `${appString}.paye_bonusYear1`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`year1Bonus${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`year1Bonus${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`year1Bonus${n}`]
                }
                return 0
            }),
            dataPoint('PAYE Bonus Year 2', `${appString}.paye_bonusYear2`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`year2Bonus${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`year2Bonus${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`year2Bonus${n}`]
                }
                return 0
            }),
            dataPoint('PAYE Commission Year 1', `${appString}.paye_commissionYear1`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`commission${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`commission${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`commission${n}`]
                }
            }),
            dataPoint('PAYE Commission Year 2', `${appString}.paye_commissionYear2`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return mortgage.figures.unverified[`commission${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value !== 'self') {
                    return formData[`commission${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] !== 'self') {
                    return newFormData[`commission${n}`]
                }
            }),
            // SELF EMPLOYED
            dataPoint('Self Employed Earnings Year 1', `${appString}.self_earningsYear1`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Self Employed Earnings Year 2', `${appString}.self_earningsYear2`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Self Employed Salary Year 1', `${appString}.self_salaryYear1`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value === 'self') {
                    return mortgage.figures.unverified[`basic${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value === 'self') {
                    return formData[`basic${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] === 'self') {
                    return newFormData[`basic${n}`]
                }
            }),
            dataPoint('Self Employed Salary Year 2', `${appString}.self_salaryYear2`, {
                type: 'number',
            }, () => {
                if (mortgage.figures.unverified[`sector${n}`].value === 'self') {
                    return mortgage.figures.unverified[`basic${n}`].total
                }
                return 0
            }, (formData) => {
                if (mortgage.figures.unverified[`sector${n}`].value === 'self') {
                    return formData[`basic${n}`]
                }
                return 0
            }, (newFormData) => {
                if (newFormData[`sector${n}`] === 'self') {
                    return newFormData[`basic${n}`]
                }
            }),
            dataPoint('Self Employed Dividends Year 1', `${appString}.self_dividendsYear1`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Self Employed Dividends Year 2', `${appString}.self_dividendsYear2`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Self Employed Net Profit Year 1', `${appString}.self_netProfitYear1`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Self Employed Net Profit Year 2', `${appString}.self_netProfitYear2`, {
                type: 'number',
            }, () => {
                return 0
            }),

            // OTHER
            dataPoint('Maintenance Received', `${appString}.other_maintenance`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Social Welfare', `${appString}.other_socialWelfare`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Variable Income', `${appString}.other_variable`, {
                type: 'number',
            }, () => {
                return 0
            }),
            dataPoint('Other Guaranteed Income', `${appString}.other_guaranteed`, {
                type: 'number',
            }, () => {
                return 0
            }),

            // OUTGOINGS
            dataPoint('Annual Regular Outgoings', `${appString}.annualRegularOutgoings`, {
                type: 'number',
            }, () => {
                // what's this
                // childcare cost?
                // accommodation?
                // maintenance paid
                let sum = 0
                sum += mortgage.figures.unverified[`childmindingCosts${n}`].total
                sum += mortgage.figures.unverified[`accommodationCosts${n}`].total
                sum += mortgage.figures.unverified[`maintenancePaid${n}`].total
                return sum
            }, (formData) => {
                let sum = 0
                sum += formData[`childmindingCosts${n}`]
                sum += formData[`accommodationCosts${n}`]
                sum += formData[`maintenancePaid${n}`]
                return sum
            }, (newFormData) => {
                let sum = 0
                sum += newFormData[`childmindingCosts${n}`]
                sum += newFormData[`accommodationCosts${n}`]
                sum += newFormData[`maintenancePaid${n}`]
                return sum
            }),
            dataPoint('Annual Debt Service', `${appString}.annualDebtService`, {
                type: 'number',
            }, () => {
                // what's this
                // monthly repayments
                let sum = 0
                sum += mortgage.figures.unverified[`loans${n}`].total
                return sum
            }, (formData) => {
                let nonClearingLoans = applicant.debts.filter(it => it.debtType===DebtTypes.LOAN && !formData.clearingLoans1.includes(it.id))
                return nonClearingLoans.reduce((a, b) => a + b.monthlyPayment, 0)
            }, (newFormData) => {
                let sum = 0
                sum += newFormData[`loans${n}`]
                return sum
            }),

        ]
    }
    const dataPoints = {
        'General'    : [
            dataPoint('Application Type', 'applicationType', {
                type   : 'select',
                options: [
                    '0: FTB',
                    '1: SSB',
                    '2: Switcher'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select a borrower type'
                    },
                ]
            }, () => {
                switch (mortgage.mortgageType) {
                    case MortgageTypes.REFINANCE:
                        return '2: Switcher'
                    case MortgageTypes.PURCHASE:
                        switch (mortgage.applicationType) {
                            case ApplicationTypes.FIRSTTIME:
                                return '0: FTB'
                            case ApplicationTypes.MOVINGHOME:
                                return '1: SSB'
                            case ApplicationTypes.SECONDTIME:
                                return '1: SSB'
                            case ApplicationTypes.LANDLORD:
                                return '1: SSB'
                        }
                        break
                }
            },),
            dataPoint('Property Value', 'propertyValue', {
                type: 'number',
            }, () => {
                return mortgage.properties[0].propertyValue
            }, (formData) => {
                return formData.propertyValue
            }, (newFormData) => {
                return newFormData.provisionalValue
            }),
            dataPoint('Loan Amount', 'loanAmount', {
                type: 'number',
            }, () => {
                return mortgage.requirement.loanRequired
            }, (formData) => {
                return formData.mortgageAmount
            }, (newFormData) => {
                return newFormData.provisionalLoan
            }),
            dataPoint('Loan Term', 'loanTerm', {
                type: 'number',
            }, () => {
                return mortgage.requirement.loanTerm
            }, (formData) => {
                return formData.term
            }, (newFormData) => {
                return newFormData.provisionalTerm
            }),
            dataPoint('Children Ages', 'childrenAges', {
                type: 'arrayText',
            }, () => {
                let dep1 = mortgage.applicant1.personal.countDependents
                let today = dayjs()
                let ages = dep1 > 0 ?( mortgage.applicant1.personal.dependentDOBs ||[]).map(dob => {
                    let djs = dayjs(dob, 'YYYY-MM-DD')
                    return today.diff(djs, 'years')
                }) : []
                if (mortgage.twoApplicants) {
                    let dep2 = mortgage.applicant2.personal.countDependents
                    let ages2 = dep2 > 0 ? (mortgage.applicant2.personal.dependentDOBs||[]).map(dob => {
                        let djs = dayjs(dob, 'YYYY-MM-DD')
                        return today.diff(djs, 'years')
                    }) : []
                    ages = ages.concat(ages2)
                }
                return ages
            },),
            dataPoint('Properties', 'properties', {
                type: 'arrayObject',
            }, () => {
                let otherProperties = []
                mortgage.applicant1.properties.forEach(p => {
                    if (p.isRented || p.isMortgaged) {
                        let rpa = p.monthlyRepayments || 0
                        let stressed = rpa * 1.2 * 12
                        otherProperties.push({
                            annualRentalIncome           : p.rentPerAnnum,
                            annualStressedMortgagePayment: stressed
                        })
                    }
                })
                if (mortgage.twoApplicants) {
                    mortgage.applicant2.properties.forEach(p => {
                        if (p.isRented || p.isMortgaged) {
                            let rpa = p.monthlyRepayments || 0
                            let stressed = rpa * 1.2 * 12
                            otherProperties.push({
                                annualRentalIncome           : p.rentPerAnnum,
                                annualStressedMortgagePayment: stressed
                            })
                        }
                    })
                }
                return otherProperties
            },)
        ],
        'Applicant 1': createApplicantDataPoints(1),
        'Applicant 2': createApplicantDataPoints(2),
    }
    return {
        name: 'nua',
        dataPoints,
        get : async (values, shouldSaveOutput) => {
            // does the test with the provided values
            return await API.post("sussdAdminAPI", "/operations/browser/nua-mortgage-test", {
                body: {values}
            })
        }
    }
}

export default nua;