import React, {useContext, useState} from 'react';
import {AutoComplete, Button, Checkbox, Form, Input, InputNumber, Tag} from "antd";
import {format, isValid} from "date-fns";
import {LONGDATE} from "../../../../assets/scripts/constants";
import SlashDate from "../../custom-inputs/SlashDate";
import {LoadingOutlined, PaperClipOutlined} from "@ant-design/icons";
import {ApplicationStages} from "../../../../models";
import {BackendUsers} from "../../../providers/BackendUsersProvider";
import {Auth} from "aws-amplify";
import useMortgage from "../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";

function QueryForm(props) {
    const [form] = Form.useForm()
    const [state, setState] = useState({saving: false})
    const mortgage = useMortgage()
    form.setFieldsValue(props.config)
    const handleFormChange = (o,v)=>{
        let suggestion = ''
        if (typeof o !== 'object' || !o.hasOwnProperty('userQueryText')){
            if (v.date && v.description && v.title && v.amount){
                const sqlDate = v.date.split(' / ').reverse().join('-')
                let date = new Date(sqlDate)
                if (isValid(date)){
                    suggestion = `Provide an explanation of the ${v.description?.toLowerCase()} of ${v.amount.toEuro()} on the ${dayjs(date).format(LONGDATE)} on ${props.config.ownerName}'s ${v.title}`
                }
                form.setFieldValue('userQueryText',suggestion)
            }
        }
    }

    const handleFormFinish = async () => {
        try{
            const user = await Auth.currentAuthenticatedUser()
            const valid = await form.validateFields()
            setState(s=>({...s,saving: true}))
            const create = {
                target: 'queries',
                pairs: {
                    mortgageID: mortgage.id,
                    createdBy: user.attributes.sub,
                    title: valid.title,
                    description: valid.description,
                    query: (valid.userQueryText||'').split('\n'),
                    userAnswered:0,
                    adminAnswered:0,
                    private: valid.private,
                    stage: props.config?.stage || mortgage.applicationStage,
                }
            }
            if (props.config?.section){
                create.pairs.section = props.config.section
            }
            if (props.config?.index){
                create.pairs.index = props.config.index
            }
            if (valid.amount){
                create.pairs.amount = valid.amount
            }
            if (valid.date){
                const sqlDate = valid.date.split(' / ').reverse().join('-')
                let date = new Date(sqlDate)
                if (isValid(date)){
                    create.pairs.date = sqlDate
                }
            }

            if (valid.lenderAnswerText){
                create.pairs.adminAnswered = 1
                create.pairs.lenderAnswer = valid.lenderAnswerText.split('\n')
                create.pairs.lenderAnswerBy = user.attributes.sub
                create.pairs.lenderAnswerOn = (new Date()).toISOString()
            }
            if (props.config.referenceType){
                create.pairs.referenceType = props.config.referenceType
            }
            if (props.config.referenceId){
                create.pairs.referenceId = props.config.referenceId
            }
            if (props.config.section){
                create.pairs.section = props.config.section
            }
            console.log({create})
            let result = await  mortgage.mutate({create})
            setState(s=>({...s,saving: false}))
           // form.setFieldsValue({})
            await form.resetFields()
            props.close()



        }
        catch (e) {
            console.log({e})
        }
    }
    return (
        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            onFinish={handleFormFinish}
            key={`QueryWriter`}
            form={form}
            requiredMark={false}
            onValuesChange={handleFormChange}
        >
            {/*<div  className="d-row j-equal gap-15">*/}
                <Form.Item
                    name="title"
                    label="Title">
                    <AutoComplete
                        options={[
                            { value: 'AIB Bank Statements'},
                            { value: 'Revolut Bank Statements'}
                        ]}
                        filterOption={(inputValue, option) =>
                            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="date"
                    label="Date">
                    <SlashDate/>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Query Description">
                    <AutoComplete
                        options={[
                            { value: 'Large deposit'},
                            { value: 'Unpaid Item'},
                            { value: 'Large outgoing'}
                        ]}
                        filterOption={(inputValue, option) =>
                            option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Amount">
                    <InputNumber style={{width:'100%'}} prefix="€"/>
                </Form.Item>
            {/*</div>*/}
            <Form.Item
                name="userQueryText"
                label="User Query">
                <Input.TextArea/>
            </Form.Item>
            <Form.Item
                name="private"
                label="Hide from user"
                valuePropName="checked"
            >
                <Checkbox/>
            </Form.Item>

            <Form.Item
                name="exclude"
                label="Exclude from cover letter"
                valuePropName="checked"
            >
                <Checkbox/>

            </Form.Item>

            <Form.Item
                name="lenderAnswerText"
                label="Lender Answer">
                <Input.TextArea/>
            </Form.Item>
            <div className="d-row j-end">
                <Button htmlType="submit"> Save {state.saving && <LoadingOutlined className="ms-6"/>} </Button>
            </div>
        </Form>
    )
}

export default QueryForm;