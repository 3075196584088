import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getRates} from "../../api-functions/lenders";
import {Button, Table, Tag, Typography} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";
import LenderRateEditor from "./LenderRateEditor";
import PageHeader from "../PageHeader";

LenderRatesTable.propTypes = {
    lender: PropTypes.object.isRequired
};

function LenderRatesTable(props) {
    const [state, setState] = useState({
        rates: [],
        editing: null
    })
    const removeRate = async (id)=>{

        setState(s=>({
            ...s,
            rates: s.rates.filter(rate=>rate.id !== id)
        }))
    }
    const expandRate = rate => ({
        ...rate,
        key: `rateTableRow${rate.id}`,
        product: rate.product ? rate.product : 'N/A',
        rateLabel: `${rate.rate.toFixed(2)}%`,
        termLabel: rate.term === 0 ? 'Variable' : `${rate.term} yr`,
        buyToLetLabel: rate.buyToLet ? <CheckCircleOutlined/> : '',
        maxLTVLabel: `≤ ${rate.maxLTV}%`,
        minimumLabel: rate?.minimum ? `€${rate.minimum.toLocaleString('en-US')}` : '',
        promotionsLabel: rate?.promotions ? rate.promotions.join(', ') : '',
        conditionsLabel: rate?.conditions ? rate.conditions.join(', ') : ''
    })

    const setRates = (rates) => {
        setState(s => ({
            ...s,
            rates: [...s.rates, ...rates.filter(it=>!it._deleted)].sort((a, b) => {
                if (a.product < b.product) return -1;
                if (a.product > b.product) return 1;
                return a.rate - b.rate;
            }).map((rate, i) => {
                return {
                    ...rate,
                    index: `#${i + 1}`
                }
            })
        }))
    }
    const updateRate = (rate)=>{
        setState(s=>({
            ...s,
            rates: s.rates.map(inner=>{
                if (inner.id === rate.id){
                    Object.assign(inner, rate)

                }
                return inner
            })
        }))

    }
    useEffect(() => {
        if (!state.rates.length) {
            getRates(props.lender.id, setRates)
        }
    }, []);
    const columns = [
        {
            title: '',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            sorter: (a, b) => a.product.localeCompare(b.product),
        },
        {
            title: 'Term',
            dataIndex: 'termLabel',
            key: 'term',
            sorter: (a, b) => a.term - b.term,
        },
        {
            title: 'Max LTV',
            dataIndex: 'maxLTVLabel',
            key: 'maxLTV',
            sorter: (a, b) => a.maxLTV - b.maxLTV,
        },
        {
            title: 'Rate',
            dataIndex: 'rateLabel',
            key: 'rate',
            sorter: (a, b) => a.rate - b.rate,
        },
        {
            title: 'Min Loan',
            dataIndex: 'minimumLabel',
            key: 'minLoan',
            sorter: (a, b) => a.minimum - b.minimum,
        },
        {
            title: 'Max Loan',
            dataIndex: 'maximum',
            key: 'maximum',
            sorter: (a, b) => a.maximum - b.maximum,
        },
        {
            title: 'Borrower Type',
            dataIndex: 'forBorrowerTypes',
            key: 'forBorrowerTypes',
            render: (text) => {
                return !!text && text.map(type => <Tag key={type}>{type}</Tag>)
            }
        },
        {
            title: 'BTL',
            dataIndex: 'buyToLetLabel',
            key: 'btl',
        },
        {
            title: 'Interest Only',
            dataIndex: 'interestOnly',
            key: 'interestOnly',
        },
        {
            title: 'Promotions',
            dataIndex: 'promotionsLabel',
            key: 'promotions',
        },
        {
            title: 'Conditions',
            dataIndex: 'conditions',
            key: 'conditionsLabel',
        },

    ];
    let x = `
    First Time Buyer \tA \t1 Year HVM BER A \t3.3 \t4.2
First Time Buyer \tB \t1 Year HVM BER B \t3.35 \t4.2
First Time Buyer \tC \t1 Year HVM BER C \t3.4 \t4.2
First Time Buyer \tD \t1 Year HVM BER D \t3.45 \t4.2
`
    return (<>

            <Table title={()=><PageHeader title={`${props.lender.lender} Rates`} back={"./../"}/>} footer={()=>(
                <Button
                    onClick={ ()=>setState(s => ({...s, editing: {}}))}
                    type="primary"
                >
                    Add a rate
                </Button>
            )} pagination={{ pageSize: 12 }}

                   onRow={(record, rowIndex) => {
                       return {
                           onClick: event => {
                               setState(s => ({...s, editing: record}))
                           }
                       };
                   }}
                   dataSource={state.rates.map(rate=>expandRate(rate))} columns={columns}
            />

            {!!state.editing && (
                <LenderRateEditor
                    updateRate={updateRate}
                    removeRate={removeRate}
                    setRates={setRates}
                    key={`model-${state.editing.id}`}
                    lender={props.lender}
                    rate={state.editing}
                    onModalClose={()=>setState(s=>({...s, editing: null}))}
                />
            )}
        </>
    )
}

export default LenderRatesTable;