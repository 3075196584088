import React, {useContext} from 'react';
import useMortgage from "../../providers/mortgage/useMortgage"
import {useParams} from "react-router-dom";
import MortgageProvider from "../../providers/mortgage/MortgageProvider";
import SavingsAnalysis from "../../components/client/application-stages/_common/funding/SavingsAnalysis";
import ApplicationUploadsProvider from "../../providers/mortgage/ApplicationUploadsProvider";
import {CacheProvider} from "../../../api/mortgage/OnDemandCache";

function ClientViewPage(props) {
    const mortgage = useMortgage()

    let src = null

    if (!!props?.user) {
        src = `https://apply.sussd.ie/mortgage/${mortgage.id}?admin-viewer=${props.user.attributes.email}`
    }

    if (window.location.protocol === 'http:') {
        src = `http://localhost:9001/mortgage/${mortgage.id}?admin-viewer=${props.user.attributes.email}`
    }
    return (<iframe className="grow" style={{
            width : '100%',
            border: 0
        }} src={src}/>);
}

export function ClientViewPageFromParams(props) {
    const params = useParams()
    const mortgage = {id: params.mortgageId}
    let src = null

    if (!!props?.user) {
        src = `https://apply.sussd.ie/mortgage/${mortgage.id}?admin-viewer=${props.user.attributes.email}`
        if (window.location.protocol === 'http:') {
            src = `http://localhost:9001/mortgage/${mortgage.id}?admin-viewer=${props.user.attributes.email}`
        }
    }
    return (<div style={{
            width : '100vw',
            height: '100vh'
        }}>
            <iframe className="grow" style={{
                width : '100%',
                height: '100%',
                border: 0
            }} src={src}/>
        </div>);
}

export function ClientViewFundingFromParams(props) {
    const params = useParams()
    return (<MortgageProvider key={params.mortgageId} id={params.mortgageId}>
            <CacheProvider>
                <ApplicationUploadsProvider>
                    <div className="d-col m-15 gap-15">
                        <SavingsAnalysis/>
                    </div>

                </ApplicationUploadsProvider>
            </CacheProvider>
        </MortgageProvider>)
}

export default ClientViewPage;