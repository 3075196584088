export default function (mortgage, config) {
    const fields = [
        {
            "name": "app1-name",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "app2-name",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "broker-name",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "details1",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "details2",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "details3",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "intermediary-name",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "mortgage-account",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "reason1",
            "type": "TEXT",
            "page": 1
        },
        {
            "name": "reason2",
            "type": "TEXT",
            "page": 1
        }
    ]

    const accountNumber = () => {
        let map = {}
        let ask = []
        let errors = []
        if (!config.hasOwnProperty('mortgage-account') && !mortgage.requirement.mortgageAccountNo) {
            errors.push('No account number')
        }
        else {
            map['mortgage-account'] = config.hasOwnProperty('mortgage-account') ? config['mortgage-account'] : mortgage.requirement.mortgageAccountNo
        }
        ask.push({
            name : 'mortgage-account',
            label: 'Mortgage Account',
            type : 'TEXT',
            group: 'ACCOUNT'
        })
        return {
            map,
            ask,
            errors
        }
    }
    const applicantNames = () => {
        let map = {}
        map['app1-name'] = mortgage.applicant1.fullName
        if (mortgage.twoApplicants) {
            map['app2-name'] = mortgage.applicant2.fullName
        }
        return {map}
    }
    const intermediaryName = () => {
        let map = {}
        map['intermediary-name'] = `Truss Mortgages Ltd T/A/ Suss'd Mortgages`
        return {map}
    }
    function splitParagraph(text, maxLength) {
        const words = text.split(' '); // Split the paragraph into words
        const parts = [];
        let currentPart = '';

        for (let word of words) {
            // Check if adding the next word exceeds the maxLength
            if ((currentPart + word).length <= maxLength) {
                currentPart += (currentPart ? ' ' : '') + word; // Add word to the current part
            }
            else {
                parts.push(currentPart); // Push the current part to parts array
                currentPart = word; // Start a new part with the current word
            }
        }

        // Add the last part if any content is left
        if (currentPart) {
            parts.push(currentPart);
        }

        return parts;
    }
    const reason = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name : 'reason',
            label: 'Reason',
            type : 'TEXTAREA',
            group: 'REASON'
        })
        if (config.hasOwnProperty('reason')) {
            let parts = splitParagraph(config.reason, 120)
            map['reason1'] = parts[0]
            if (parts.length > 1) {
                map['reason2'] = parts[1]
            }
            if (parts.length > 2) {
                map['reason3'] = parts[2]
            }
        }
        else {
            errors.push('No reason supplied')
        }

        return {
            map,
            errors,
            ask
        }
    }
    const details = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name : 'details',
            label: 'Details',
            type : 'TEXTAREA',
            group: 'DETAILS'
        })
        if (config.hasOwnProperty('details')) {
            //split by words
            let parts = splitParagraph(config['details'], 120)
            map['details1'] = parts[0]
            if (parts.length > 1) {
                map['details2'] = parts[1]
            }
            if (parts.length > 2) {
                map['details3'] = parts[2]
            }
        }
        else {
            errors.push('No details supplied')
        }

        return {
            map,
            errors,
            ask
        }
    }
    const brokerName = () => {
        let map = {}
        map['broker-name'] = `KEVIN COAKLEY`
        return {map}
    }

    const sections = [
        accountNumber(),
        applicantNames(),
        intermediaryName(),
        details(),
        reason(),
        brokerName()

    ]

    return {sections}
}