import React from 'react';
import DocumentsTable from "../../_common/documents/DocumentsTable";
import {ApplicationStages} from "../../../../../../models";
import useApplyAdminDocuments from "./admin-documents/useApplyAdminDocuments";

function ApplyAdminDocuments(props) {
    const items = useApplyAdminDocuments()
    return <DocumentsTable items={items} stage={ApplicationStages.APPLY} done="createApplyAdminDocuments"/>
}

export default ApplyAdminDocuments;