import React, {useEffect, useState} from 'react';
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import {gray, green, red} from "@ant-design/colors";
import {Button, Card, Input, InputNumber, Modal, Slider} from "antd";
import useMortgageUploadService from "../../../../../../../../../providers/mortgage/useMortgageUploadService";
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import {API} from "aws-amplify";
import {CloseCircleOutlined, FileDoneOutlined, FileExclamationOutlined, FileImageOutlined, FilePdfOutlined, FileUnknownOutlined, LoadingOutlined} from "@ant-design/icons";
import {UploadStatuses} from "../../../../../../../../../../models";

function ToPdffer({upload}) {
    const [open, setOpen] = useState(false)
    const mortgage = useMortgage()
    const row = mortgage.uploads.find(item => item.id === upload.id)
    let btnProps = (row && row.contentType !== 'application/pdf') ? {onClick: () => setOpen(s => true)} : {}

    const style = {
        fontSize: 24, color: gray[3]
    }
    let icon = <FileUnknownOutlined style={style}/>
    if (!row){
        return null
    }
    if (row.error) {
        style.color = red[3]
        icon=  <CloseCircleOutlined style={style}/>
    }
    if (row.status?.toUpperCase() === UploadStatuses.UPLOADING || !!upload?.originFileObj) {
        icon= <LoadingOutlined style={style}/>
    }
    else if (row.status === UploadStatuses.REJECTED) {
        icon= <FileExclamationOutlined style={style}/>
    }
    else if (row?.contentType?.includes("pdf")) {
        btnProps.disabled = true
        icon= <FilePdfOutlined style={style}/>
    }
    else if (row.pdffed) {
        icon= <FileDoneOutlined style={style}/>
    }
    else if (row?.contentType?.includes("image")) {
        btnProps.danger = true
        btnProps.type="primary"
        style.color = "white"
        icon= <FileImageOutlined style={style}/>
    }
    return (<div>
         <Button {...btnProps} style={{padding: 21}} icon={icon} />
        <PdfModal open={open} setOpen={setOpen} upload={row}/>
    </div>)
}

function PdfModal({
    open,
    setOpen,
    upload
})
{
    const [busy, setBusy] = useState(false)
    const uploadService = useMortgageUploadService()
    const [state, setstate] = useState({
        pictureLoaded: false,
        imageWidth   : 0,
        percentage   : 100
    })
    const picture = React.useRef()
    const a4Width = 595.28; // A4 width in points
    const a4Height = 841.89; // A4 height in points
    let a4Ratio = a4Width / a4Height
    const width = 320;
    const height = width / a4Ratio
    const getPicture = async () => {
        let {
                image,
                base64,
                imageBytes,
                fileType
            } = await uploadService.getFile({...upload, pdffed: false})
        let imageData = await image()
        picture.current = {
            base64: await base64(),
            imageBytes: await imageBytes(),
            fileType
        }
        let newWidth = imageData.width
        setstate(s => ({
            ...s,
            pictureLoaded: true,
            imageWidth   : newWidth
        }))
    }
    const handleWidthChange = (val) => {

        let newWidth = val
        if (newWidth > 300) {
            newWidth = 300
        }
        if (newWidth < -100) {
            newWidth = -100
        }
        setstate(s => ({
            ...s,
            percentage: newWidth
        }))
    }

    const handleSavePdf = async (width) => {
        // Create a new PDF document
        setBusy(true)
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);

        // Add a blank page to the document
        const page = pdfDoc.addPage([a4Width, a4Height]); // A4 size

        // Fetch the image and embed it into the document
        const imageBytes = picture.current.imageBytes;
        let image
        switch (picture.current.fileType) {
            case 'png':
                image = await pdfDoc.embedPng(imageBytes);
                break;
            case 'jpg':
                image = await pdfDoc.embedJpg(imageBytes);
                break;
            default:
                throw new Error('Unsupported image type');
        }

        const aspectRatio = image.height / image.width;
        const height = width * aspectRatio;

        page.drawImage(image, {
            x: 0,
            y: a4Height - height, // Position the image at the top of the page
            width: width,
            height: height,
        });
        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        await uploadService.covertToPDF(upload, pdfBlob)
        setOpen(false)
        setBusy(false)
    }
    useEffect(() => {
        if (open && !state.pictureLoaded) {
            getPicture()
        }

    }, [
        open,
        state.pictureLoaded
    ]);

    let relativeWith = 0
    if (state.pictureLoaded) {
        if (state.imageWidth > width) {
            relativeWith = width
        }
        else {
            relativeWith = state.imageWidth
        }
    }
    let fraction = state.percentage / 100
    let displayWidth = relativeWith * fraction
    let percent = displayWidth / width
    let actualWidth = a4Width * percent


    let imageSrc = state.pictureLoaded ? `data:image/png;base64,${picture.current.base64}` : null

    return <Modal style={{ zIndex: 1050 }} open={open} destroyOnClose={true} okText={busy? <>OK <LoadingOutlined/></>: 'OK'} onOk={()=>handleSavePdf(actualWidth)} onCancel={() => setOpen(false)}>
        <div className="d-row gap-15 me-24"><h4 className="m-0">Change image size</h4>
            <Slider
                style={{width: '100%'}}
                min={-100}
                max={300}
                value={state.percentage}
                onChange={handleWidthChange}
            />
        </div>
        <Card className="mx-auto mt-15" style={{
            width,
            height,
            backgroundColor: 'rgb(250,250,250)',
            overflow: 'hidden'
        }} styles={{body: {padding: 0}}}>
            {imageSrc && <img src={imageSrc} alt="Uploaded" style={{
                width: `${displayWidth}px`,
                height: 'auto'
            }}/>}
        </Card>
    </Modal>
}
export default ToPdffer;