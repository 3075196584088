import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from "@ant-design/icons";
import {Button} from "antd";

function ButtonAsync(props) {
    const [loading,setLoading] = useState(false)
    const {onClick, icon, children, ...rest} = props
    const onButtonClick= async (e)=>{
        setLoading(e)
    }
    const handleClick= async ()=>{
        await onClick(loading)
        setLoading(false)
    }
    useEffect(() => {
        if (loading){
            handleClick()
        }
    }, [loading]);

    let buttonIcon = loading ? <LoadingOutlined/> : icon || null
    return (

            <Button key={props.id} icon={buttonIcon} onClick={onButtonClick} {...rest}> {children}  </Button>


    )
}

export default ButtonAsync;