import React, {useState} from 'react';
import {Button, Card, Descriptions, Popover} from "antd";
import Stamper from "./popover-items/Stamper";
import Omitter from "./popover-items/Omitter";
import StatusChanger from "./popover-items/StatusChanger";
import Renamer from "./popover-items/Renamer";
import {capitaliseWords} from "../../../../../../../../../assets/scripts/string-functions";
import Excluder from "./popover-items/Excluder";
import Deleter from "./popover-items/Deleter";
import Mover from "./popover-items/Mover";
import Scanner from "./popover-items/Scanner";
import dayjs from "dayjs";
import ToPdffer from "./popover-items/ToPdffer";

function PopoverButton({
    uploadId,
    section,
    sections
})
{

    const [open, setOpen] = useState(false)
    const upload = section.uploads.find(it => it.id === uploadId)
    function MiniCard({children}) {
        return <Card style={{backgroundColor: '#e5e5e5'}} styles={{body: {padding: 6}}}>
            <div className="d-col gap-6">  {children} </div>
        </Card>
    }
    function Content() {
        return (<div className="d-row gap-15">
                {upload.basicInfo && (<MiniCard>
                        <table cellPadding={2}>
                            <tbody>
                            <tr>
                                <td>No. of Pages:</td>
                                <td>{upload.basicInfo?.numberOfPages}</td>
                            </tr>
                            <tr>
                                <td>Institution</td>
                                <td>{upload.basicInfo?.institution}</td>
                            </tr>
                            <tr>
                                <td>Account Type</td>
                                <td>{upload.basicInfo?.accountType}</td>
                            </tr>
                            <tr>
                                <td>IBAN:</td>
                                <td>{upload.basicInfo?.iban}</td>
                            </tr>
                            <tr>
                                <td>Range:</td>
                                <td>{upload.basicInfo?.startDate && dayjs(upload.basicInfo.startDate).format('D MMM YYYY')}
                                    <span className="mx-1"> - </span>
                                    {upload.basicInfo?.endDate && dayjs(upload.basicInfo.endDate).format('D MMM YYYY')}</td>
                            </tr>
                            <tr>
                                <td>Opening Balance:</td>
                                <td>{upload.basicInfo?.openingBalance && upload.basicInfo.openingBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Closing Balance:</td>
                                <td>{upload.basicInfo?.closingBalance && upload.basicInfo.closingBalance.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>{upload.basicInfo?.accountAddress && upload.basicInfo.accountAddress.map(line => <div key={line}>{line}</div>)}</td>
                            </tr>
                            <tr>
                                <td>Omit:</td>
                                <td>{upload.basicInfo?.omit && upload.basicInfo.omit.join(', ')}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>{upload.basicInfo?.omit && upload.basicInfo.error}</td>
                            </tr>
                            </tbody>

                        </table>
                    </MiniCard>)}
                <div className="d-col">
                    <MiniCard>
                        <Mover uploadId={uploadId} sections={sections}/>
                    </MiniCard>
                    <div className="d-row gap-15 pt-6">
                        <div className="d-col gap-6">


                                <MiniCard>
                                    <Stamper uploadId={uploadId}/>
                                </MiniCard>
                            <div className="mt-auto d-row j-between">
                                <Deleter uploadId={uploadId}/>
                                <Scanner uploadId={uploadId} section={section}/>


                            </div>

                        </div>
                        <div className="d-col gap-6">

                            <MiniCard>
                                <Omitter uploadId={uploadId}/>
                            </MiniCard>
                            <MiniCard>
                                <Renamer uploadId={uploadId} autoName={capitaliseWords(section.title)}/>
                            </MiniCard>

                        </div>
                        <div className="d-col gap-6">
                            <MiniCard>
                                <StatusChanger uploadId={uploadId}/>

                            </MiniCard>
                            <div className="mt-auto">
                                <Excluder uploadId={uploadId}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
    let props = open ? {type: 'primary'} : {}
    return (<Popover open={open} placement="topLeft" title={false} content={<Content/>}>
            <Button {...props} size="small" onClick={() => setOpen(!open)}>Edit</Button>
        </Popover>)
}

export default PopoverButton;