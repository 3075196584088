import React, {useContext} from 'react';
import {Avatar, Skeleton, List, Button} from "antd";
import {
    ArrowRightOutlined,
    DoubleRightOutlined,
    ExclamationOutlined,
    EyeInvisibleOutlined,
    PaperClipOutlined
} from "@ant-design/icons";
import {Link, useParams} from "react-router-dom";
import {grey, magenta, purple, red} from "@ant-design/colors";

import {LONGDATE} from "../../../../assets/scripts/constants";
import useMortgage from "../../../providers/mortgage/useMortgage"
import dayjs from "dayjs";

function EmailsList(props) {
    const params = useParams()
    const mortgage = useMortgage()
    const loadMore = (e) => {
        //console.log(e)
    }



    /* COMPONENTS */
    function EmailAttachmentIcon({item}) {
        if (!item.hasAttachments) return null
        return <Avatar style={{backgroundColor: grey[0]}} size="small" icon={<PaperClipOutlined/>}/>
    }
    function ViewedIcon({item}) {
        if (item.isReceived && !item?.readBy) {
            return <Avatar style={{backgroundColor: purple[3]}} size="small" icon={<EyeInvisibleOutlined/>}/>
        }
        return null
    }
    function ActionedIcon({item}) {
        if (item.actionedBy !== "null") return null
        return <Avatar style={{backgroundColor: red[3]}} size="small" icon={<ExclamationOutlined/>}/>
    }
    function IconsGroup({item}) {
        return <div className="d-row gap-3" >
            <ViewedIcon item={item}/>
            <ActionedIcon item={item}/>
            <EmailAttachmentIcon item={item}/>
        </div>
    }
    function DetailsGroup({item}) {
        let d = dayjs(item.createdAt).format( LONGDATE)

        return <div className="d-row j-between" style={{color: grey[7]}}>
            <div className="d-col">
                <span className="mb-6" style={{fontSize: 15}}>{item.subject}</span>
                <span style={{fontSize: 12}}>{d}</span>
            </div>

            <IconsGroup item={item}/>
        </div>
    }
    function ListItem({item}) {
        const styleProp = {fontSize: 11}
        if (item.isReceived){
            styleProp.color =  magenta[5]
            styleProp.transform = 'rotate(45deg)'
        }
        else{
            styleProp.color =  purple[5]
            styleProp.transform = 'rotate(315deg)'
        }
        return <Link to={`/client/${mortgage.id}/emails/${item.id}`}>
            <DetailsGroup item={item}/>

            <>
                {item.from}
                <ArrowRightOutlined className="mx-6" style={styleProp}/>
                {item.to[0]}
            </>
        </Link>
    }
    return (
        <Skeleton avatar title={false} loading={props.emails === null} active>
            {props.emails && (
                <List
                    itemLayout="vertical"
                    loadMore={props.getMore ? <Button className="m-15" onClick={props.getMore} >Load More</Button> : null}
                    dataSource={props.emails}
                    renderItem={(item) => {
                        const styleProp = params?.emailId === item.id ? {style: {backgroundColor: '#fafafa'}} : {}

                        return (
                            <List.Item  {...styleProp} className="px-12">
                                <ListItem item={item}/>
                            </List.Item>
                        )
                    }}
                />
            )}

        </Skeleton>
    );
}

export default EmailsList;