import {App, Button, Checkbox, Form, Input, Modal, Tabs} from "antd";

import {useMemo, useState} from "react";
import {getMappings} from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {SettingOutlined} from "@ant-design/icons";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function ConfigureOverlayModalLauncher({record, readOnly}) {
    const app = App.useApp()
    const mortgage = useMortgage()
    const existingData = mortgage.data ? JSON.parse(mortgage.data) : {}
    let fileName = record.fileName || `${record.name}.pdf`
    const config = existingData?.overlayData?.[fileName] || {}
    const [open, setOpen] = useState(false)
    const dataMap = getMappings(app, record, mortgage, config)
    if (dataMap.error){
        console.log(dataMap.error)
    }
    if (!dataMap.sections) {
        dataMap.sections = []
    }

    let missingItems = dataMap.sections.filter(it => !!it.ask).flatMap(section => section.ask)


    let groups = {}
    missingItems.forEach(it => {
        if (!groups[it.group]) {
            groups[it.group] = []
        }
        groups[it.group].push(it)
    })

    const handleFinish = async (config) => {
        if (!!readOnly) return

        const existingData = mortgage.data ? JSON.parse(mortgage.data) : {}
        if (!existingData.hasOwnProperty('overlayData')) {
            existingData.overlayData = {}
        }
        existingData.overlayData[fileName] = config
        await mortgage.mutate({
            update: {
                target: 'data',
                value: JSON.stringify(existingData)
            }
        })
        //setConfig(record.fileName, config)
        setOpen(false)
    }
    function GroupQuestion({item}) {
        const restProps = item.type.toLowerCase() === 'check' ? {valuePropName: 'checked'} : {}
        return (
            <Form.Item
                initialValue={config[item.name]}
                label={item.label}
                name={item.name}
                {...restProps}
            >
                {item.type.toLowerCase() === 'text' && (
                    <Input/>
                )}
                {item.type.toLowerCase() === 'textarea' && (
                    <Input.TextArea rows={3}/>
                )}
                {item.type.toLowerCase() === 'check' && (
                    <Checkbox/>
                )}
            </Form.Item>

        )
    }

    const modal = useMemo(() => {
        const items = Object.keys(groups).map((group, i) => ({
            key: group,
            label: `${i + 1}. ${group}`,
            children: (
                <>
                    <div className="my-9">
                        {groups[group].map(it => <GroupQuestion key={it.name} item={it}/>)}
                    </div>
                </>
            )
        }))
        let name = record.label || capitaliseWords(record.name.replace(/-/g, ' '))
        return (
            <Modal width={800} mask onCancel={() => setOpen(false)} open={open}
                   title={`Configure Overlay (${name})`} footer={null}>


                <Form
                    onFinish={handleFinish}
                    labelWrap={true}
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    layout="horizontal"
                >
                    <Tabs tabPosition="left" defaultActiveKey="1" items={items}/>
                    <div className="d-row j-end">
                        <Button htmlType="submit" disabled={!!readOnly}>Save</Button>
                    </div>

                </Form>

            </Modal>
        )
    }, [open])
    if (!missingItems.length) {
        return null
    }
    return (
        <>
            {modal}

            <Button icon={<SettingOutlined/>} onClick={() => setOpen(true)}/>
        </>
    )

}
export default ConfigureOverlayModalLauncher;