import React from 'react';
import {CalendarOutlined} from "@ant-design/icons";
import Costs from "../../../verified-figures/balance-of-funds/Costs";
import Funds from "../../../verified-figures/balance-of-funds/Funds";
import {CustomInputNumber} from "./CustomInputs";

function EditMortgage({loan,value}) {
    return <div className="d-row gap-15">
        <div className="d-row gap-15">
            <div className="d-row gap-15">
                <Costs override={{
                    loan,
                    price: value
                }}/>
                <Funds override={{
                    loan,
                    price: value
                }}/>
            </div>
        </div>
        <div className="d-col gap-15 ">
            <table>
                <tbody>
                <tr>
                    <td>Loan Amount</td>
                    <td>
                        <CustomInputNumber name="provisionalLoan"/>
                    </td>
                </tr>
                <tr>
                    <td>Term</td>
                    <td>
                        <CustomInputNumber name="provisionalTerm" as={"int"} prefix={<CalendarOutlined/>}/>
                    </td>
                </tr>
                <tr>
                    <td>Property Value</td>
                    <td>
                        <CustomInputNumber name="provisionalValue"/>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    </div>
}

export default EditMortgage;