import React, {useContext, useEffect, useState} from 'react';
import Nav from "./nav/Nav";
import {MyAdminTasks} from "../../providers/MyAdminTasksProvider";
import useBackendUsers from "../../providers/mortgage/useBackendUsers";
import SortableSheet from "./sheet/SortableSheet";
import {useParams} from "react-router-dom";
import {Card} from "antd";
import CompletedPanel from "./sheet/CompletedPanel";

function MyTasks(props) {
    const {taskId: activeTaskId} = useParams()
    const backendUsers = useBackendUsers()
    const [filterByDirection, setFilterByDirection] = useState('for');
    const [filterByFolder, setFilterByFolder] = useState('All');
    const [sorting, setSorting] = useState(false)
    const {tasks, operations} = useContext(MyAdminTasks);
    if (!backendUsers.me) return null
    const folders = Object.keys(tasks.reduce((acc, item) => {
        if (item.folder) {
            acc[item.folder] = true;
        }
        if (item.userFolder.hasOwnProperty(backendUsers.me.sub)) {
            acc[item.userFolder[backendUsers.me.sub]] = true;
        }
        return acc;
    }, {}));
    const filteredTasks = tasks.filter(task => !task.closedOn).filter(task => {

        switch (filterByDirection) {
            case 'for':
                return task.currentOwner === backendUsers.me.sub
            case 'by':
                return task.author === backendUsers.me.sub
        }
    }).filter(task => {
        switch (filterByFolder) {
            case 'All':
                return true
            default:
                return task.folder === filterByFolder || task.userFolder?.[backendUsers.me.sub] === filterByFolder
        }
    })
    const oldestTask = filteredTasks.reduce((oldest, current) => {
        // If oldest is null or current.startDate is older than oldest.startDate, update oldest
        if (!oldest || current.createdAt < oldest.createdAt) {
            return current;
        }
        // Otherwise, return oldest without updating
        return oldest;
    }, null);
    const oldestTime = oldestTask ? new Date(oldestTask.createdAt).getTime() : null
    const currentTime = new Date().getTime() + 1000000000
    const longestTime = currentTime - oldestTime

    const expandedTasks = filteredTasks.map(task => {
        const diff = currentTime - new Date(task.createdAt).getTime()
        const tag = {
            author: {
                color: backendUsers.getBySub(task.author).color,
                name: (backendUsers.me.sub === task.author ? 'Me' : backendUsers.getBySub(task.author).firstName)
            },
            target: {
                color: task.target ? backendUsers.getBySub(task.target).color : 'default',
                name: task.target ? (backendUsers.me.sub === task.target ? 'Me' : backendUsers.getBySub(task.target).firstName) : 'No User'
            }
        }
        return {
            ...task,
            left: 100 - ((diff / longestTime) * 100),
            tag,
            isActive: activeTaskId === task.id
        }
    })
    const tasksByMeCompleted = expandedTasks.filter(task => !!task.completedOn && task.author === backendUsers.me.sub )
    const restCompletedTasks = expandedTasks.filter(task => !!task.completedOn && task.author !== backendUsers.me.sub)
    const notCompleteTasks = expandedTasks.filter(task => !task.completedOn)


    const returningTasks = (filterByDirection === 'for') ? [...notCompleteTasks, ...restCompletedTasks] : [...restCompletedTasks, ...notCompleteTasks]

    return (
        <div className="d-col h-100">
            <Nav folders={folders} operations={operations} sorting={sorting} setSorting={setSorting}
                 filterByFolder={filterByFolder} setFilterByFolder={setFilterByFolder}
                 filterByDirection={filterByDirection} setFilterByDirection={setFilterByDirection}/>
            <div>
                <div className="d-row w-100">
                    <div style={{width: 350}}>
                        <div style={{position:'fixed', top: 60,  left: 265, width: 300}}>
                            <CompletedPanel tasksByMeCompleted={tasksByMeCompleted}/>
                        </div>
                    </div>
                    <div style={{width: 'calc(100% - 365px)'}}>
                        {expandedTasks.length && (
                            <div className="px-3">
                                <SortableSheet
                                    mySub={backendUsers.me.sub}
                                    folders={folders}
                                    tasks={returningTasks}
                                    sorting={sorting}/>
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
}

export default MyTasks;