import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {correctValueForSys, getItemFromPath, Mortgage} from "./MortgageProvider";
import {Form, Typography} from "antd";
import {
    mortgageProperties,
    mortgagePropertiesSteps
} from "../../components/client/application/forms/sections/MortgageProperties";
import {mortgageSetupSteps} from "../../components/client/application/forms/sections/MortgageSetup";
import {mortgageDepositSteps} from "../../components/client/application/forms/sections/MortgageDeposit";
import {mortgageNeedsSteps} from "../../components/client/application/forms/sections/MortgageNeeds";
import {applicantPersonalSteps} from "../../components/client/application/forms/sections/ApplicantPersonal";
import {applicantEmploymentSteps} from "../../components/client/application/forms/sections/ApplicantEmployment";
import {applicantIncomeSteps} from "../../components/client/application/forms/sections/ApplicantIncome";
import {applicantDebts, applicantDebtSteps} from "../../components/client/application/forms/sections/ApplicantDebts";
import {applicantAssets, applicantAssetSteps} from "../../components/client/application/forms/sections/ApplicantAssets";
import {
    applicantAccounts,
    applicantAccountSteps
} from "../../components/client/application/forms/sections/ApplicantAccounts";
import {
    applicantProperties,
    applicantPropertySteps
} from "../../components/client/application/forms/sections/ApplicantProperties";

import useMortgage from "./useMortgage";
import {
    applicantEmploymentIncomes,
    applicantEmploymentIncomeSteps
} from "../../components/client/application/forms/sections/ApplicantEmploymentIncome";
import {capitaliseFirst} from "../../../assets/scripts/string-functions";
import {legacyIncome} from "../../components/client/application-stages/_common/lender-tests/useXlsTests";

useApplicationForms.propTypes = {};

export const getQuestionsPercentCompleted = (mortgage, questions) => {
    let initialValues = questions.reduce((acc, question) => {
        let value = getItemFromPath(mortgage, question.target)
        acc[question.name] = correctValueForSys(mortgage, question, value)
        return acc
    }, {})
    const fakeForm = {
        getFieldValue: (name) => {
            return initialValues[name]
        }
    }
    let requiredQuestions = questions.filter(question => {
        if (question?.hides) {
            if (question.hides(fakeForm)) {
                return false
            }
        }
        return !question?.optional;
    })
    let ofWhichUnanswered = requiredQuestions.filter(question => {
        let v = fakeForm.getFieldValue(question.name)
        return [null, undefined].includes(v)
    })
    return (1 - (ofWhichUnanswered.length / requiredQuestions.length)) * 100
}


function useApplicationForms() {

    const [questionSections, setQuestionSections] = useState([])
    const mortgage = useMortgage()
    const [form] = Form.useForm()

    let legacy = legacyIncome(mortgage)
    // QUESTION SECTIONS
    const repeater = {
        add: {
            applicant: {
                'employment-income': (applicant, id) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        create: {
                            target: `applicant${applicant}.employmentIncome`,
                            pairs: {
                                applicantID: mortgage[`applicant${applicant}`].id,
                            }
                        }
                    })
                },
                'current-accounts': (applicant) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        create: {
                            target: `applicant${applicant}.accounts`,
                            pairs: {
                                applicantID: mortgage[`applicant${applicant}`].id,
                            }
                        }
                    })
                },
                debts: (applicant) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        create: {
                            target: `applicant${applicant}.debts`,
                            pairs: {
                                applicantID: mortgage[`applicant${applicant}`].id,
                                observeMID: mortgage.id,
                            }
                        }
                    })
                },
                assets: (applicant) => {
                    return mortgage.mutate({
                        create: {
                            target: `applicant${applicant}.assets`,
                            pairs: {
                                applicantID: mortgage[`applicant${applicant}`].id,
                                observeMID: mortgage.id,
                            }
                        }
                    })
                },
                properties: (applicant) => {
                    return mortgage.mutate({
                        create: {
                            target: `applicant${applicant}.properties`,
                            pairs: {
                                applicantID: mortgage[`applicant${applicant}`].id,
                                observeMID: mortgage.id,
                            }
                        }
                    })
                },
            },
            mortgage: {
                properties: () => {
                    return mortgage.mutate({
                        create: {
                            target: `properties`,
                            pairs: {
                                mortgageID: mortgage.id,
                            }
                        }
                    })
                }
            }
        },
        remove: {
            applicant: {
                'employment-income': (applicant, id) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        delete: {
                            target: `applicant${applicant}.employmentIncome`,
                            id
                        }
                    })
                },
                'current-accounts': (applicant, id) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        delete: {
                            target: `applicant${applicant}.accounts`,
                            id
                        }
                    })
                },
                debts: (applicant, id) => {
                    //console.log(applicant)
                    return mortgage.mutate({
                        delete: {
                            target: `applicant${applicant}.debts`,
                            id
                        }
                    })
                },
                assets: (applicant, id) => {
                    return mortgage.mutate({
                        delete: {
                            target: `applicant${applicant}.assets`,
                            id
                        }
                    })
                },

                properties: (applicant, id) => {
                    return mortgage.mutate({
                        delete: {
                            target: `applicant${applicant}.properties`,
                            id
                        }
                    })
                },
            },
            mortgage: {
                properties: (id) => {
                    return mortgage.mutate({
                        delete: {
                            target: `properties`,
                            id
                        }
                    })
                }
            }
        }
    }
    const mortgageSections = () => {
        // const propertySections = mortgage.properties.map(property => {
        //     let title = wordLimit(property.address || 'New property')
        //     return {
        //         title,
        //         name: 'properties',
        //         steps: mortgagePropertiesSteps({mortgage: mortgage, applicant: false, index: property.id}),
        //         applicant: false,
        //         index: property.id
        //     }
        // })
        let props = {mortgage: mortgage, applicant: false}
        const propertySection = {
            title: `Properties Included`,
            name: `properties`,
            children: addPercentToChildren(mortgageProperties(props).map(property => mortgagePropertiesSteps({
                ...props,
                index: property.index
            }))),
            add: true,
            applicant: false
        }
        const setupSection = {
            title: 'Mortgage Setup',
            name: 'setup',
            steps: mortgageSetupSteps({mortgage: mortgage, applicant: false}),
            applicant: false,
        }
        const depositSection = {
            title: 'Deposit & Gifts',
            name: 'deposit',
            steps: mortgageDepositSteps({mortgage: mortgage, applicant: false}),
            applicant: false,
        }
        // const needsSection = {
        //     title: 'Mortgage Needs',
        //     name: 'needs',
        //     steps: mortgageNeedsSteps({mortgage: mortgage, applicant: false}),
        //     applicant: false,
        // }
        return [setupSection, depositSection, propertySection]
    }
    const applicantSections = (n) => {
        let props = {mortgage: mortgage, applicant: n}
        let sections = [{
            title: `Personal`,
            name: `personal-${n}`,
            steps: applicantPersonalSteps(props),
            applicant: n
        }]
        //TODO: Add employment income

        if (!legacy) {
            if (mortgage[`applicant${n}`].employmentIncome?.length) {
                let t1 = applicantEmploymentIncomes(props)
                sections.push({
                    title    : `Employment & Income`,
                    name     : `employment-income-${n}`,
                    children : addPercentToChildren(t1.map(emp => {
                        return applicantEmploymentIncomeSteps({
                            ...props,
                            index: emp.index
                        })
                    })),
                    add      : true,
                    applicant: n
                })
            }
        }
        else{
            if (mortgage[`applicant${n}`].employment) {
                sections.push({
                        title: `Employment`,
                        name: `employment-${n}`,
                        steps: applicantEmploymentSteps(props),
                        applicant: n
                    },
                    {
                        title: `Income`,
                        name: `income-${n}`,
                        steps: applicantIncomeSteps(props),
                        applicant: n
                    }
                )
            }
        }

        sections.push({
                title: `Current Accounts`,
                name: `current-accounts-${n}`,
                children: addPercentToChildren(applicantAccounts(props).map(account => applicantAccountSteps({
                    ...props,
                    index: account.index
                }))),
                add: true,
                applicant: n
            },
            {
                title: `Debts`,
                name: `debts-${n}`,
                children: addPercentToChildren(applicantDebts(props).map(debt => applicantDebtSteps({
                    ...props,
                    index: debt.index
                }))),
                add: true,
                applicant: n
            },
            {
                title: `Assets`,
                name: `assets-${n}`,
                children: addPercentToChildren(applicantAssets(props).map(asset => applicantAssetSteps({
                    ...props,
                    index: asset.index
                }))),
                add: true,
                applicant: n
            },
            {
                title: `Properties`,
                name: `properties-${n}`,
                children: addPercentToChildren(applicantProperties(props).map(property => applicantPropertySteps({
                    ...props,
                    index: property.index
                }))),
                add: true,
                applicant: n
            })
        return sections
    }
    const addRepeatingSection = async (sectionName) => {
        const match = sectionName.match(/(.*)-(1|2)$/);
        let applicant = false
        let property = sectionName
        if (match) {
            applicant = match[2]
            property = match[1]
            // if (match[1].includes('-')) {
            //     property = match[1].split('-').map((s, i) => {
            //         return i !== 0 ? capitaliseFirst(s) : s
            //     }).join('')
            // }

        }
        if (applicant) {
            await repeater.add.applicant[property](applicant)
        } else {
            await repeater.add.mortgage[property]()
        }
    }
    const removeRepeatingSection = async (sectionName, id) => {
        const match = sectionName.match(/(.*)-(1|2)$/);
        let applicant = false
        let property = sectionName

        if (match) {
            applicant = match[2]
            property = match[1]

            // if (match[1].includes('-')) {
            //     property = match[1].split('-').map((s, i) => {
            //         return i !== 0 ? capitaliseFirst(s) : s
            //     }).join('')
            // }
        }

        if (applicant) {
            await repeater.remove.applicant[property](applicant, id)
        } else {
            await repeater.remove.mortgage[property](id)
        }
    }
    const initialFormComplete = () => {
        if (!mortgage) return false
        let data1 = JSON.parse(mortgage.applicant1.data || '{}')
        if (mortgage.twoApplicants && mortgage.invitations.length) {
            if (mortgage.applicant2.subjectId) {
                let data2 = JSON.parse(mortgage.applicant2.data || '{}')
                return !!data1.affordabilityComplete && !!data2.affordabilityComplete
            }
        } else {
            return !!data1.affordabilityComplete
        }
        return false
        //
        // if (!mortgage.mortgageType) {
        //     console.log(1);
        //     return false
        // }
        // if (!mortgage.applicationType) {
        //     console.log(2);
        //     return false
        // }
        // if (![true, false].includes(mortgage.requirement.hasLoans)) {
        //     console.log(3);
        //     return false
        // }
        // if (![true, false].includes(mortgage.requirement.hasCards)) {
        //     console.log(4);
        //     return false
        // }
        // if (![true, false].includes(mortgage.twoApplicants)) {
        //     console.log(5);
        //     return false
        // }
        //
        // if (isNaN(mortgage.applicant1.financial.monthlyAccommodationCosts)) {
        //     console.log(6);
        //     return false
        // }
        // if (mortgage.applicant1.personal.countDependents > 0) {
        //     if (!mortgage.applicant1.personal.dependentDOBs) {
        //         console.log(7);
        //         return false
        //     }
        //     if (isNaN(mortgage.applicant1.financial.monthlyChildcareCosts)) {
        //         console.log(8);
        //         return false
        //     }
        // }
        // if (isNaN(mortgage.applicant1.income.grossBasic)) {
        //     console.log(9);
        //     return false
        // }
        // if (![true, false].includes(mortgage.applicant1.financial.paysMaintenance)) {
        //     console.log(10);
        //     return false
        // }
        // if (mortgage.applicant1.financial.paysMaintenance) {
        //     if (isNaN(mortgage.applicant1.financial.maintenanceCosts)) {
        //         console.log(11);
        //         return false
        //     }
        // }
        // if (mortgage.twoApplicants) {
        //     if (![true, false].includes(mortgage.applicant2.financial.hasIncome)) {
        //         console.log(12);
        //         return false
        //     }
        //     if (![true, false].includes(mortgage.requirement.cohabiting)) {
        //         console.log(13);
        //         return false
        //     }
        //
        //     if (!mortgage.requirement.cohabiting) {
        //         if (isNaN(mortgage.applicant2.financial.monthlyAccommodationCosts)) {
        //             console.log(14);
        //             return false
        //         }
        //     }
        //     if (mortgage.applicant2.financial.hasIncome) {
        //         if (isNaN(mortgage.applicant2.income.grossBasic)) {
        //             console.log(15);
        //             return false
        //         }
        //     }
        //     if (![true, false].includes(mortgage.applicant2.financial.paysMaintenance)) {
        //         console.log(16);
        //         return false
        //     }
        //     if (mortgage.applicant2.financial.paysMaintenance) {
        //         if (isNaN(mortgage.applicant2.financial.maintenanceCosts)) {
        //             console.log(17);
        //             return false
        //         }
        //     }
        // }
        // return true
    }

// UPLOADS SECTIONS

// GET THE PROGRESS ROW FOR THE SECTION

    const addPercentToChildren = (children) => {
        return children.map(child => {
            const item = child[0]
            item.percent = calcQuestionsPercent(item.questions)
            return item
        })
    }
    const setFormFromQuestions = (questions) => {
        questions.forEach(question => {
            let value = getItemFromPath(mortgage, question.target)
            value = correctValueForSys(mortgage, question, value)
            form.setFieldValue(question.name, value)
        })
    }
    const calcQuestionsPercent = (questions) => {
        setFormFromQuestions(questions)
        let requiredQuestions = questions.filter(question => {
            if (question?.hides) {
                if (question.hides(form)) {
                    return false
                }
            }
            return !question?.optional;
        })
        let ofWhichUnanswered = requiredQuestions.filter(question => {
            let v = form.getFieldValue(question.name)
            return [null, undefined].includes(v)
        })
        return (1 - (ofWhichUnanswered.length / requiredQuestions.length)) * 100
    }
    const calcSectionPercent = (section) => {

        if (section?.steps) {
            // First, add all answers to form, then check against questions, group all questions into a giant set
            let questions = section.steps.flatMap(step => step.questions)
            return calcQuestionsPercent(questions)
        }
        if (section?.children) {
            if (section.name.startsWith('debts-') && mortgage[`applicant${section.applicant}`].financial.hasDebts === false) return 100
            if (section.name.startsWith('assets-') && mortgage[`applicant${section.applicant}`].financial.hasAssets === false) return 100
            if (section.applicant && section.name.startsWith('properties-') && mortgage[`applicant${section.applicant}`].financial.hasProperties === false) return 100

            let percentTotal = 0
            section.children.forEach(steps => {

                let questions = steps.questions
                if (!questions) {
                    console.warn(steps)
                }
                percentTotal += calcQuestionsPercent(questions)
            })
            return percentTotal / section.children.length
        }

        return 0
    }
    const getProgress4Section = (section) => {
        // AS OF YET, ONLY USED FOR UPLOADS
        return mortgage.progress.find(item => {
            if (item.applicationStage === section.stage && item.sectionName === section.name) {
                if (section?.index) {
                    return item.sectionIndex === section.index
                }
                return true
            }
            return false
        })
    }
    const getSectionDetails = (section) => {
        // 100 - if section is checked OK
        // 66 - if any uploads that are not rejected
        // 33 - if only rejected uploads
        // 0 - if not started
        let progress = getProgress4Section(section)

        if (progress?.sectionCheckedBy) {
            return ['Complete', 100, true]
        }

        let percent = calcSectionPercent(section)

        if (percent === 100) {
            return ['Complete', 100, true,]
        }
        if (percent > 0) {
            return ['Active', percent, true,]
        }
        return ['Not Started', 0, false,]
    }

// Set status and whatnot
    let prevMortgageState = useRef()
    useEffect(() => {
        if (mortgage) {
            if (prevMortgageState.current !== JSON.stringify(mortgage)) {
                prevMortgageState.current = JSON.stringify(mortgage)
                let s = [
                    ...mortgageSections(),
                    ...applicantSections(1)
                ]
                if (mortgage.twoApplicants) {
                    s = [
                        ...s,
                        ...applicantSections(2)
                    ]
                }
                s = s.map(section => {
                    let [status, percent, percentShown] = getSectionDetails(section)
                    if (section?.steps || section?.children) {
                        return {
                            ...section,
                            status,
                            percent,
                            percentShown
                        }
                    }
                    return {
                        ...section,
                        percent
                    }
                })

                setQuestionSections(s)
            }
        }

    }, [mortgage])
    return {
        sections: questionSections,
        repeater: {
            add: addRepeatingSection,
            remove: removeRepeatingSection
        },
        initialFormComplete
    };
}

export default useApplicationForms;