import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Row, Spin, Typography} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, MailOutlined} from "@ant-design/icons";
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub} from "@aws-amplify/core";
import {Link, useNavigate} from "react-router-dom";

async function signIn({username, password}) {
    return await Auth.signIn(username, password);
}
function SignInPage(props) {
    const [signInForm] = Form.useForm()
    const [forgotForm] = Form.useForm()
    const [state, setState] = useState({
        busy: false,
        screen: 'signIn', // 'newPassword', 'forgotPassword',
        badEmail: false,
        badPassword: false
    })
    const navigate = useNavigate()
    const validators = {
        password: (_, password) => {
            const settings = {
                passwordPolicyMinLength: 8,
                passwordPolicyCharacters: [
                    "REQUIRES_LOWERCASE",
                    "REQUIRES_NUMBERS",
                    "REQUIRES_SYMBOLS",
                    "REQUIRES_UPPERCASE"
                ]
            };
            const {passwordPolicyMinLength, passwordPolicyCharacters} = settings;

            // Test minimum length
            if (password.length < passwordPolicyMinLength) {
                return Promise.reject("Password is too short")
            }

            // Test character requirements
            const requiredCharacterMap = {
                REQUIRES_LOWERCASE: /[a-z]/,
                REQUIRES_NUMBERS: /[0-9]/,
                REQUIRES_SYMBOLS: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?]/,
                REQUIRES_UPPERCASE: /[A-Z]/
            };

            for (const characterRule of passwordPolicyCharacters) {
                if (!requiredCharacterMap[characterRule].test(password)) {
                    return Promise.reject(`Password must include ${characterRule.split('_').join(' ').toLowerCase()}`)
                }
            }
            return Promise.resolve()
        },
        generalEmail: (_, email) => {
            if (email === state.badEmail) {
                return Promise.reject("User not found")
            }
            return Promise.resolve()
        },
        generalPassword: (_, password) => {
            if (password === state.badPassword) {
                return Promise.reject("Invalid credentials")
            }
            return Promise.resolve()
        }
    }
    const forgotPassword = () => {
        setState(s => ({...s, screen: 'forgotPassword'}))
    }
    const cancelForgotPassword = () => {
        setState(s => ({...s, screen: 'signIn'}))
    }
    const sendForgotPasswordEmail = async () => {
        setState(s => ({...s, busy: true}))
        const valid = await forgotForm.validateFields()
        try {
            const result = await Auth.forgotPassword(valid.email)
            setState(s => ({...s, busy: false, screen: 'newPassword'}))
        } catch (e) {
            if (e.code === 'UserNotFoundException') {
                setState(s => ({...s, busy: false, badEmail: valid.email}))
            }
            setState(s => ({...s, busy: false}))
        }

    }
    const signInUser = async () => {
        setState(s => ({...s, busy: true}))
        const valid = await signInForm.validateFields()
        const init = {
            username: valid.email,
            password: valid.password
        }


        try {
            await signIn(init)
            setState(s => ({...s, busy: false}))
        } catch (e) {
            if (e.code === 'NotAuthorizedException') {
                setState(s => ({...s, busy: false, badPassword: valid.password}))
            } else if (e.code === 'UserNotFoundException') {
                setState(s => ({...s, busy: false, badEmail: valid.email}))
            } else {
                setState(s => ({...s, busy: false}))
            }
        }
    }

    const signInWithGoogle = async () => {
        let customState = JSON.stringify({
            registration: false,
        })
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google, customState})
    }

    function SignInForm() {
        const formProps = {
            form: signInForm,
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        return <Form {...formProps} onFinish={signInUser}>
            <Form.Item
                shouldUpdate
                validateFirst
                label="Email Address"
                name="email"
                className="centered-form-item"
                rules={[
                    {required: true, message: `An email is required`},
                    {type: 'email', message: 'Please enter a valid email address'},
                    {validator: validators.generalEmail}
                ]}
            >

                <Input size="large" prefix={<MailOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>

            <Form.Item
                shouldUpdate
                validateFirst
                label="Password"
                name="password"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.password},
                    {validator: validators.generalPassword}
                ]}
            >
                <Input.Password
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>


            <div style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                <Button type="dashed" size="small" style={{marginRight: 'auto'}} onClick={forgotPassword}>
                    Forgot my password
                </Button>

                <Button size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                    {state.busy && <>Signing In <Spin size="small"/></>}
                    {!state.busy && <> Sign In</>}
                </Button>
            </div>

        </Form>
    }
    function ForgotForm() {
        const formProps = {
            form: forgotForm,
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        return <Form {...formProps} onFinish={sendForgotPasswordEmail}>
            <Form.Item
                shouldUpdate
                validateFirst
                label="Email Address"
                name="email"
                className="centered-form-item"
                rules={[
                    {required: true, message: `An email is required`},
                    {type: 'email', message: 'Please enter a valid email address'},
                    {validator: validators.generalEmail}
                ]}
            >

                <Input size="large" prefix={<MailOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>
            <div style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                <Button type="dashed" size="small" style={{marginRight: 'auto'}} onClick={cancelForgotPassword}>
                    Cancel
                </Button>

                <Button size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                    {state.busy && <>Resetting Password <Spin size="small"/></>}
                    {!state.busy && <> Reset Password</>}
                </Button>
            </div>

        </Form>
    }
    function ConfirmForm() {
        return <Form
            // onFinish={confirmUser}
            //  form={confirmationForm}
            style={{display: 'flex', flexDirection: 'column'}}>
            <Form.Item
                name="code"
                rules={[{validator: validators.invalidCode}]}
            >
                <Input size="large" autoFocus/>
            </Form.Item>
            <Form.Item
                validateFirst
                label="Password"
                name="password"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.password}
                ]}
            >
                <Input.Password
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>

            <Form.Item
                validateFirst
                label="Password Again"
                name="password-repeat"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.passwordsMatch}
                ]}
            >

                <Input.Password
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                />
            </Form.Item>
            <Button style={{marginTop: 24}} size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                {state.busy && <>Confirming <Spin size="small"/></>}
                {!state.busy && <> Confirm</>}
            </Button>
        </Form>
    }
    useEffect(() => {
        const listener = Hub.listen('auth', (data) => {
            const event = data.payload.event;
            if (event === "signOut") {
               // console.log('user signed out...');
            }
            if (event === "signIn") {
               // console.log('user signed in...');
                const user = data.payload.data;
              //  console.log(user);
                navigate('/')
            }
            if (event === "confirmSignUp") {
              //  console.log('confirm SignUp...');
            }
            if (event === 'autoSignIn') {
                const user = data.payload.data;
               // console.log(user);
                navigate('/apply')
                // assign user
            } else if (event === 'autoSignIn_failure') {
              //  console.log('autoSignIn_failure...');
                // navigate('/login')
            }
        });
        return () => {
            listener()
        }
    }, [])

    useEffect(() => {
        if (state.badEmail || state.badPassword) {
            signInForm.validateFields().then(values => {
                //console.log(values)
            }).catch(e => {
                //console.log(e)
            })
            forgotForm.validateFields().then(values => {
                //console.log(values)
            }).catch(e => {
                //console.log(e)
            })
        }
    }, [state.badEmail, state.badPassword])

    return (
        <>

            <Row justify="center" style={{height:'100vh'}}>
                <Col xs={24} md={18} lg={12} xl={8} className="d-row a-center j-center">

                    <Card bordered={false} style={{position: 'relative'}}>

                        <div style={{position: 'relative'}}>
                            {state.screen === 'signIn' && (
                                <SignInForm/>
                            )}
                            {state.screen === 'forgotPassword' && (
                                <>
                                    <Typography.Title style={{marginBottom: 24}} level={5}>Enter your email address to start the
                                        password reset process</Typography.Title>
                                    <ForgotForm/>
                                </>
                            )}
                            {state.screen === 'newPassword' && (
                                <>
                                    <Typography.Title style={{textAlign: 'center'}} level={5}>We've just sent a
                                        confirmation code
                                        to </Typography.Title>
                                    <Typography.Title level={4}>Enter the code and new password below </Typography.Title>
                                    <ConfirmForm/>
                                </>
                            )}

                        </div>

                    </Card>

                </Col>
            </Row>

        </>
    )
}

export default SignInPage;