
import useSussdPdfObjects from "../../../app/providers/useSussdPdfObjects";
import useMortgage from "../../../app/providers/mortgage/useMortgage";
import useApplicationUploads from "../../../app/providers/mortgage/useApplicationUploads";
import SussdApplicationForm from "./SussdApplicationForm";
function useSussdApplicationForm() {
    const mortgage = useMortgage();
    const {apply} = useApplicationUploads()
    return useSussdPdfObjects(SussdApplicationForm, {mortgage, uploadSections: apply})

}
export default useSussdApplicationForm;