import React from 'react';
import useFullscreen from "../../../full-screen/useFullscreen";
import PageHeader from "../../../PageHeader";
import StageTimeline from "./stage-timeline/StageTimeline";
import {Alert, Tabs} from "antd";

function StagesLayout({title,timeline, tabs,activeKey,setActiveKey, extras}) {
    const {className,Button: FullscreenButton, isFull} = useFullscreen()

    function Extra(){
        return <div className="d-row gap-15">

            <FullscreenButton/>

        </div>
    }

    return (
        <div key="apply">
            <Alert className="m-15" closable={false} message={<PageHeader title={title} extra={<Extra/>}/>}/>

            <div className="m-15 d-row gap-15">
                <StageTimeline items={timeline} extras={extras} setActiveKey={setActiveKey}/>
                <div className={`${className} grow` }>
                    <div className={isFull ? 'p-9':''}>
                        <Tabs style={{width:'100%'}} className="wrapping" tabBarStyle={{marginBottom:0}} type="card"  activeKey={activeKey} items={tabs} onChange={setActiveKey}/>
                        {isFull &&(
                            <div style={{position:"fixed", right:0, top:0}} className="p-12">
                                <FullscreenButton/>
                            </div>
                        )
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default StagesLayout;