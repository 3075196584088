import {App as AntApp, ConfigProvider} from "antd";
import {Route, BrowserRouter, Routes, useNavigate} from "react-router-dom";
import AdminDashboard from "./pages/dashboard/Dashboard";
import ClientDashboard from "./pages/client/Dashboard";
import DashboardEmailsPage from "./pages/dashboard/EmailsPage"
import {useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import ClientsListPage from "./pages/dashboard/ClientsListPage";
import MortgagesProvider from "./providers/MortgagesProvider";
import SignInPage from "./auth/SignInPage";
import PermissionWrapper from "./auth/PermissionWrapper";
import BackendUsersProvider from "./providers/BackendUsersProvider";
import ClientEmailsPage from "./pages/client/MortgageEmailsPage"
import NotesPage from "./pages/client/NotesPage";
import TasksPage from "./pages/client/TasksPage";
import SettingsPage from "./pages/client/SettingsPage";
import FinancialSummaryPage from "./pages/client/FinancialSummaryPage";
import DocumentBundlingPage from "./pages/client/DocumentBundlingPage";
import DocumentCreationPage from "./pages/client/DocumentCreationPage";
import ClientDashboardPage from "./pages/client/DashboardPage";
import BankingAnalysisPage from "./pages/client/BankingAnalysisPage";
import LendersPage from "./pages/dashboard/LendersPage";
import ClientViewPage, {ClientViewFundingFromParams, ClientViewPageFromParams} from "./pages/client/ClientViewPage";
import ApplicationFormPage from "./pages/client/ApplicationFormPage";
import ApplicationUploadPage from "./pages/client/ApplicationUploadPage";
import CreateMutationsFile from "./components/stevo/CreateMutationsFile";

import TimeLine from "./components/client/dashboard/TimeLine";
import ConditionsPage from "./pages/client/ConditionsPage";
import Queries from "./components/client/queries/Queries";
import Overview from "./components/client/overview/Overview";
//import ApplicationManagement from "./pages/client/ApplicationManagement";
import Stats from "./pages/dashboard/Stats";
import ClientBars from "./pages/dashboard/ClientBars";
import AdminEmailsProvider from "./providers/AdminEmailsProvider";
import ApplicationDocumentsPage from "./pages/client/ApplicationDocumentsPage";
import ApplicantActivitiesProvider from "./providers/ApplicantActivitiesProvider";
import LastViewedMortgagesProvider from "./providers/LastViewedMortgagesProvider";
import SignoutPage from "./auth/SignoutPage";
import SidebarProvider from "./providers/SidebarProvider";
import LendersProvider from "./providers/LendersProvider";
import CreateNewMortgage from "./pages/dashboard/CreateNewMortgage";
import OutstandingItemsProvider from "./providers/mortgage/OutstandingItemsProvider";
import ReplyRequestsProvider from "./providers/ReplyRequestsProvider";

import ManagementApply from "./components/client/application-stages/apply/ManagementApply";
import ManagementPreApproval from "./components/client/application-stages/pre-approval/ManagementPreApproval";
import ManagementApproval from "./components/client/application-stages/approval/ManagementApproval";
import TimeSlots from "./pages/dashboard/TimeSlots";
import UpcomingAppointmentProvider from "./providers/UpcomingAppointmentProvider";
import MyAdminTasks from "./pages/dashboard/MyAdminTasks";
import MyAdminTasksProvider from "./providers/MyAdminTasksProvider";
import ManagementOnBoarding from "./components/client/application-stages/on-boarding/ManagementOnBoarding";
import Prep from "./components/client/broker-assist-prep/Prep";
import Landing from "./components/dashboard/landing-page/Landing";
import PdfGen from "./components/stevo/PdfGen";
import PendingUploads from "./components/stevo/PendingUploads";
import ActiveMortgagesStageTasksProvider from "./providers/ActiveMortgagesStageTasksProvider";
import Ocr from "./components/stevo/OCR";
//import PdfRecogniser from "./components/stevo/PdfRecogniser";
import PdfIdentifyProvider from "./providers/PdfIdentifyProvider";
import CreateSecondMortgage from "./pages/dashboard/CreateSecondMortgage";
//import PdfRecogniserV2 from "./components/stevo/PdfRecogniserV2";
import PdfRecogniserV3 from "./components/stevo/pdf-recogniser/PdfRecogniserV3";
import ManagementSign from "./components/client/application-stages/sign/ManagementSign";
import MortgagesMessagingProvider from "./providers/mortgages-messaging/MortgagesMessagingProvider";
import {VerifiedLenderTestFiguresProvider} from "./components/client/application-stages/_common/lender-tests/v2/VerifiedLenderTests";
import TestPage from "../_project/TestPage";

let hotReloadCounter = 0;

if (module.hot) {
    module.hot.accept(() => {

        hotReloadCounter++; // Increment on every hot reload
    });
}

export const getHotReloadCounter = () => hotReloadCounter;

function App(props) {

    const [state, setState] = useState({haveUser: false})
    const user = useRef(null)
    const setUser = async () => {
        try {
            user.current = await Auth.currentAuthenticatedUser()
            if (user) {
                setState(s => ({...s, haveUser: true}))
            }
        } catch (e) {
            setState(s => ({...s, haveUser: true}))
        }
    }
    useEffect(() => {
        setUser()
    }, [user])
    if (!state.haveUser) {
        return null
    }
    function MainApp(props) {
        return (
            // <VerifiedLenderTestFiguresProvider>
            <PermissionWrapper>
                <LendersProvider>
                    <PdfIdentifyProvider>
                    <ActiveMortgagesStageTasksProvider>

                        <MortgagesProvider>
                            <OutstandingItemsProvider>
                                <MortgagesMessagingProvider>
                                <ReplyRequestsProvider>

                                    <ApplicantActivitiesProvider>
                                        <UpcomingAppointmentProvider>

                                            <BackendUsersProvider>
                                                <LastViewedMortgagesProvider>

                                                    <AdminEmailsProvider>
                                                        <MyAdminTasksProvider>
                                                            <SidebarProvider>


                                                                <AdminDashboard {...props}/>


                                                            </SidebarProvider>
                                                        </MyAdminTasksProvider>
                                                    </AdminEmailsProvider>

                                                </LastViewedMortgagesProvider>
                                            </BackendUsersProvider>
                                        </UpcomingAppointmentProvider>
                                    </ApplicantActivitiesProvider>


                                </ReplyRequestsProvider>
                                </MortgagesMessagingProvider>
                            </OutstandingItemsProvider>
                        </MortgagesProvider>
                    </ActiveMortgagesStageTasksProvider>
                    </PdfIdentifyProvider>
                </LendersProvider>
            </PermissionWrapper>
            // </VerifiedLenderTestFiguresProvider>
        )
    }

    return <AntApp>


        <BrowserRouter>
            <Routes>
                <Route path="stevo" element={<PdfGen/>}/>
                <Route path="/signin" element={<SignInPage/>}/>
                <Route path="/signout" element={<SignoutPage/>}/>
                <Route path={'/user-app/:mortgageId'} element={<MainApp sidebar={false}/>}>
                    {
                        !!user.current && <Route index element={<ClientViewPageFromParams user={user.current}/>}/>
                    }

                </Route>
                <Route path={'/user-funding/:mortgageId'} element={<MainApp sidebar={false}/>}>
                    {
                        !!user.current && <Route index element={<ClientViewFundingFromParams user={user.current}/>}/>
                    }

                </Route>
                <Route path={'/'} element={<MainApp/>}>
                    {/*DASHBOARD ROUTES*/}
                    {/*<Route path="emails" element={<MortgageEmailsPage/>}/>*/}
                    <Route index element={<Landing/>}/>
                    <Route path="pending-uploads" element={<PendingUploads/>}/>
                    <Route path="emails/:emailId/reply" element={<DashboardEmailsPage/>}/>
                    <Route path="emails/:emailId" element={<DashboardEmailsPage/>}/>
                    <Route path="emails" element={<DashboardEmailsPage/>}/>
                    <Route path="lenders/:lenderId?/:section?" element={<LendersPage/>}/>

                    <Route path="new-mortgage-test-page" element={<TestPage/>}/>
                    <Route path="clients/:categoryType" element={<ClientsListPage/>}/>
                    <Route path="stats" element={<Stats/>}/>
                    <Route path="client-bars" element={<ClientBars/>}/>
                    <Route path="my-settings/timeslots" element={<TimeSlots/>}/>
                    <Route path="my-tasks/:taskId?" element={<MyAdminTasks/>}/>
                    <Route path="client/:mortgageId" element={<ClientDashboard/>}>

                        <Route index element={<ClientDashboardPage/>}/>
                        <Route path="create-new-mortgage" element={<CreateSecondMortgage/>}/>
                        <Route path="prep" element={<Prep/>}/>
                        <Route path="overview" element={<Overview/>}/>
                        <Route path="application-forms/:sectionName?/:index?" element={<ApplicationFormPage/>}/>
                        <Route path="application-uploads/:sectionName?/:index?" element={<ApplicationUploadPage/>}/>

                        <Route path="setup-management" element={<ManagementOnBoarding key="setupManagement"/>}/>


                        <Route path="apply-management" element={<ManagementApply key="applyManagement"/>}/>

                        <Route path="application-tasks" element={<ConditionsPage stage="APPLY"/>}/>
                        <Route path="application-queries" element={<Queries stage="APPLY"/>}/>
                        <Route path="application-documents" element={<ApplicationDocumentsPage/>}/>
                        {/*<Route path="application-management" element={<ApplicationManagement/>}/>*/}

                        <Route path="sign-management" element={<ManagementSign key="signManagement"/>}/>

                        <Route path="pre-approval-tasks" element={<ConditionsPage stage="PREAPPROVAL"/>}/>
                        <Route path="pre-approval-queries" element={<Queries stage="PREAPPROVAL"/>}/>
                        <Route path="pre-approval-management" element={<ManagementPreApproval/>}/>
                        <Route path="approval-tasks" element={<ConditionsPage stage="APPROVAL"/>}/>
                        <Route path="approval-queries" element={<Queries stage="APPROVAL"/>}/>
                        <Route path="approval-management" element={<ManagementApproval/>}/>

                        <Route path="timeline/:stageName" element={<TimeLine large/>}/>
                        <Route path="emails/:emailId/reply" element={<ClientEmailsPage/>}/>
                        <Route path="emails/:emailId" element={<ClientEmailsPage/>}/>
                        <Route path="emails" element={<ClientEmailsPage/>}/>
                        <Route path="notes" element={<NotesPage/>}/>
                        <Route path="tasks/:id?" element={<TasksPage/>}/>
                        <Route path="settings" element={<SettingsPage/>}/>
                        <Route path="document-bundling" element={<DocumentBundlingPage/>}/>
                        <Route path="document-creation" element={<DocumentCreationPage/>}/>
                        <Route path="banking-analysis" element={<BankingAnalysisPage/>}/>
                        <Route path="financial-summary" element={<FinancialSummaryPage/>}/>
                        <Route path="drawdown" element={<>Empty</>}/>
                        <Route path="client-view" element={<ClientViewPage user={user.current}/>}/>
                        <Route path="new-client-view" element={<ClientViewPage user={user.current} new={true}/>}/>
                    </Route>
                    <Route path="create-definitions" element={<CreateMutationsFile/>}/>
                    <Route path="create-offline-mortgage" element={<CreateNewMortgage/>}/>

                    <Route path="ocr" element={<Ocr/>}/>
                    <Route path="recognise" element={<PdfRecogniserV3/>}/>


                </Route>
                <Route path='*' element={<div className="d-row a-center j-center"><h1>Not Found!</h1></div>}/>

            </Routes>
        </BrowserRouter>
    </AntApp>
}
export default App