import React from 'react';
import SavingsAnalysis from "./SavingsAnalysis";
import BalanceOfFunds from "./BalanceOfFunds";
import {BoolLike} from "../../../../../../models";
import MortgageForm from "../../../../data-capture/MortgageForm";

export const monthsBetweenDates = (dayJsStart, dayJsEnd) => {
    const oneDayAfter = dayJsEnd.add(1, 'day')
    return oneDayAfter.diff(dayJsStart, 'month');
}
export const firstHomeSchemeDetailsQuestions = [
    {
        label: "First Time Buyer Scheme",
        text: "Are you availing of the First Time Buyer Scheme - a shared equity scheme facilitated by the county council?",
        name: "firstHomeScheme",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Yes",
                    value: BoolLike.TRUE
                },
                {
                    text: "No",
                    value: BoolLike.FALSE
                },
                {
                    text: "Don't know",
                    value: BoolLike.NOTSURE
                }
            ]
        },
        model: "requirement",
        rules: [],
        target: `requirement.firstHomeScheme`
    },
    {
        label: "Amount",
        text: "What is the amount of the First Time Buyer Scheme?",
        name: "firstHomeSchemeAmount",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return form.getFieldValue('firstHomeScheme') !== BoolLike.TRUE
        },
        model: "requirement",
        rules: [
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
        ],
        target: `requirement.firstHomeSchemeAmount`
    }
]
export const helpToBuySchemeDetailsQuestions = [
    {
        label: "Help to Buy Scheme",
        text: "Are you availing of the Help To Buy Scheme?",
        name: "helpToBuyScheme",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Yes",
                    value: BoolLike.TRUE
                },
                {
                    text: "No",
                    value: BoolLike.FALSE
                },
                {
                    text: "Don't know",
                    value: BoolLike.NOTSURE
                }
            ]
        },
        model: "requirement",
        rules: [],
        target: `requirement.helpToBuyScheme`
    },
    {
        label: "Amount",
        text: "What is the amount of the First Time Buyer Scheme?",
        name: "helpToBuySchemeAmount",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return form.getFieldValue('helpToBuyScheme') !== BoolLike.TRUE
        },
        model: "requirement",
        rules: [
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
        ],
        target: `requirement.helpToBuySchemeAmount`
    },
]
export const extendedHelpToBuySchemeDetailsQuestions = [
    ...helpToBuySchemeDetailsQuestions,
    {
        name: 'helpToBuySchemeAccessCode',
        label: 'Access Code',
        text: "Access Code",
        answer: {
            type: "text",
        },
        hides: (form) => {
            return form.getFieldValue('helpToBuyScheme') !== BoolLike.TRUE
        },
        model: "requirement",
        rules: [],
        target: `requirement.helpToBuySchemeAccessCode`
    },
    {
        name: 'helpToBuySchemeApplicationCode',
        label: 'Application Code',
        text: "Application Code",
        answer: {
            type: "text",
        },
        hides: (form) => {
            return form.getFieldValue('helpToBuyScheme') !== BoolLike.TRUE
        },
        model: "requirement",
        rules: [],
        target: `requirement.helpToBuySchemeApplicationCode`

    },
    {
        name: 'helpToBuySchemeDeveloperName',
        label: 'Developer Name',
        text: "Developer Name",
        answer: {
            type: "text",
        },
        hides: (form) => {
            return form.getFieldValue('helpToBuyScheme') !== BoolLike.TRUE
        },
        model: "requirement",
        rules: [],
        target: `requirement.helpToBuySchemeDeveloperName`
    }
]

export function FirstHomeSchemeDetails() {
    const questions = firstHomeSchemeDetailsQuestions
    return (
        <MortgageForm questions={questions} title="First Home Scheme"/>

    )
}

export function HelpToBuySchemeDetails() {
    const questions = extendedHelpToBuySchemeDetailsQuestions
    return (
        <MortgageForm questions={questions} title="Help To Buy Scheme"/>

    )
}
function DepositDetails() {
    const questions = [
        {
            label: "Gifts Included",
            text: "Will your deposit include gifts from relatives/ or friends?",
            name: "receivingGifts",
            answer: {
                type: "choice",
                choices: [
                    {
                        text: "Yes",
                        value: true,
                    },
                    {
                        text: "No",
                        value: false,
                    }
                ]
            },
            model: "requirement",
            rules: [],
            target: `requirement.receivingGifts`
        },
        {
            label: 'Total Gifts',
            name: 'depositGifts',
            text: 'Value of all gifts',
            model: 'requirement',
            answer: {
                type: 'euro',
                step: 1000
            },
            hides: (form) => {
                return form.getFieldValue('receivingGifts') === false
            },
            rules: [

                {type: 'number', min: 1, message: "The minimum value here is €1"},
                {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
            ],
            target: `requirement.depositGifts`
        },
        {
            label: 'Total Claimed Funds',
            name: 'depositSavings',
            text: 'Own savings amount',
            model: 'requirement',
            answer: {
                type: 'euro',
                step: 1000
            },
            rules: [

                {type: 'number', min: 1, message: "The minimum value here is €1"},
                {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
            ],
            target: `requirement.depositSavings`
        }
    ]
    return (
        <MortgageForm questions={questions} title="Source of Funds Details"/>

    )
}

function Funding(props) {

    return (
        <>

            <div className="d-col gap-15">
                {/*<div className="d-col gap-15">*/}
                    <SavingsAnalysis/>
                    {/*<BalanceOfFunds/>*/}
                {/*</div>*/}
                <div className="d-row gap-15 j-equal">
                    <DepositDetails/>

                    <FirstHomeSchemeDetails/>
                    <HelpToBuySchemeDetails/>
                </div>
            </div>
        </>

    );
}

export default Funding;