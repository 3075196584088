import React from 'react';
import {Popover} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {orange} from "@ant-design/colors";
import {CustomInputNumber} from "./CustomInputs";

function ApplicantsRowBuilder({twoApplicants, label, name, type, warning1, warning2, totalValue}) {
    return (<tr>
        <td>{label}</td>
        <td>
            <div className="d-row gap-3">
                <CustomInputNumber name={`${name}1`}/>
                {warning1 && (<Popover content={warning1}><ExclamationCircleOutlined style={{
                    fontSize: 20, color: orange[5]
                }}/></Popover>)}
            </div>

        </td>
        {twoApplicants && <td>
            <div className="d-row gap-3">
                <CustomInputNumber name={`${name}2`}/>
                {warning2 && (<Popover content={warning2}><ExclamationCircleOutlined style={{
                    fontSize: 20, color: orange[5]
                }}/></Popover>)}
            </div>

        </td>}
        {twoApplicants && <td>
            <CustomInputNumber disabled={true} value={totalValue}/>

        </td>}
    </tr>)
}

export default ApplicantsRowBuilder;