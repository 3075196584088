import React from 'react';
import {AutoComplete, Card, Checkbox, Upload} from "antd";
import {Auth} from "aws-amplify";
import {InboxOutlined} from "@ant-design/icons";
import useMortgageDocumentsService from "../../../providers/mortgage/useMortgageDocumentsService";
import {capitaliseWords} from "../../../../assets/scripts/string-functions";

function DocumentUploader(props) {
    const [newFolderName, setNewFolderName] = React.useState('')
    const [isPrivate, setIsPrivate] = React.useState(true)
    const [fileList,setFileList] = React.useState([])
    const [percent,setPercent] = React.useState({})
    const documentService = useMortgageDocumentsService()
    const handleChange = ({file, fileList}) => {
        setFileList(fileList)
    }
    const { Dragger } = Upload;
    const customRequest = async ({file, onSuccess, onError, onProgress}) => {
        try {
            const config = {
                progressCallback: (progress) => {
                    setFileList(s=>{
                        return s.map(file=>{
                            if (file.uid === file.uid){
                                file.progress = Math.floor((progress.loaded / progress.total) * 100)
                            }
                            return file
                        })
                    })
                    onProgress({percent})
                },
                completeCallback: (event) => {
                    setFileList(s=>{
                        return s.filter(it=>it.uid !== file.uid)
                    })
                },
                errorCallback: (err) => {
                    //setState({error: true})
                    setFileList(s=>{
                       return s.map(file=>{
                           if (file.uid === file.uid){
                               file.error = true
                           }
                           return file
                       })
                    })
                    console.error('Unexpected error while uploading', err);
                }
            }
            let folder = newFolderName.toLowerCase().replace(' ','-')
            let title = capitaliseWords(file.name.split('.').slice(0, -1).join('.'))
            await documentService.put({file, config, stage: props.stage, isPrivate, folder, isDynamic: false, title   })
            // pass to the upload handler
            onSuccess();
        } catch (error) {
            onError(error);
        }
    }
    const myProps = {
        accept: ".pdf",
        name: 'file',
        multiple: true,
        fileList: fileList,
        onChange: handleChange,
        customRequest: customRequest,
    };
    return (
            <Card title={` Upload ${props.stage} Document`}>
                <div className="mb-15 d-row gap-15 a-center">
                    <div className="d-row gap-15 a-center">
                        Folder:
                        <AutoComplete value={newFolderName} onChange={setNewFolderName} style={{width:400}} title="Folder" options={[
                            {value:'Lender Documents'},
                            {value: 'Submission Documents'},
                            {value: 'Sussd Documents'},
                            {value: 'Customer Docusign'},
                            {value: 'Downloads'},
                            {value: 'Insurance Documents'},
                            {value:'Bundled Uploads'},
                            {value:'Manual Uploads'}]}/>
                    </div>
                    <Checkbox checked={isPrivate} onChange={e=>setIsPrivate(e.target.checked)}>Private</Checkbox>
                </div>
                {newFolderName !== '' && (
                    <Dragger {...myProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                        </p>
                    </Dragger>
                )}
            </Card>
        )
}

export default DocumentUploader;