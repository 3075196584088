import React from 'react';

function useLenderVariables({lenderCode}) {
    let variables = {
        referenceIdRequired: false,
        monthsForAipToExpire: 6,

    }
    switch(lenderCode){
        case 'haven':
            variables.monthsForAipToExpire = 12
            break
        case 'ptsb':
            variables.referenceIdRequired =  true
            break
        case 'avant':

            break
        default:

    }
    console.log({lenderCode, variables})
    return variables

}

export default useLenderVariables;