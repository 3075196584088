import React, {useState} from 'react';
import {geekblue} from "@ant-design/colors";
import {Button, Checkbox, Form, Input, Select, Table, Tabs} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage";
import {Auth} from "aws-amplify";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {ApplicationStages} from "../../../../models";

function ReplyRequestContent(props) {
    const {close, config} = props
    const mortgage = useMortgage()
    function TextArea() {
        const handleChange = (event) => {
            mortgage.mutate({
                update: {
                    target: `replyRequests[${config.request.id}].noteBody`,
                    value: event.target.value.split('\n')
                }
            })
        };
        let defaultValue =  config.request.noteBody ? config.request.noteBody.join('\n'):''
        return (
            <Input.TextArea defaultValue={defaultValue} rows={4} onBlur={handleChange}
                            placeholder="Type something..."/>
        )
    }
    function QueryTab() {
        const [state, setState] = useState({saving: false})
        const [form] = Form.useForm()
        const handleFormFinish = async () => {
            const user = await Auth.currentAuthenticatedUser()
            const valid = await form.validateFields()
            setState(s => ({...s, saving: true}))
            const create = {
                target: 'queries',
                pairs: {
                    mortgageID: mortgage.id,
                    emailReplyID: config.request.id,
                    createdBy: user.attributes.sub,
                    description: valid.description,
                    title: valid.title,
                    query: valid.userQueryText.split('\n'),
                    private: valid.private,
                    stage: mortgage.applicationStage
                }
            }
            await mortgage.mutate({create})
            setState(s => ({...s, saving: false}))
            form.setFieldsValue({})
        }
        return (
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                onFinish={handleFormFinish}
                key={`QueryWriter`}
                form={form}
                requiredMark={false}
            >
                {/*<div  className="d-row j-equal gap-15">*/}
                <Form.Item
                    rules={[{required: true, message: `A title is required`}]}
                    name="title"
                    label="Card Title">
                    <Input/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: `A description is required`}]}
                    name="description"
                    label="Card Description">
                    <Input/>
                </Form.Item>
                <Form.Item
                    rules={[{required: true, message: `A query is required`}]}
                    name="userQueryText"
                    label="Your Question">
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item
                    name="private"
                    label="Hide from user"
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>

                <div className="d-row j-end">
                    <Button htmlType="submit" className="ms-auto"> Save {state.saving &&
                        <LoadingOutlined className="ms-6"/>} </Button>
                </div>
            </Form>
        )
    }
    function TaskTab() {
        const [state, setState] = useState({saving: false})
        const [form] = Form.useForm()
        const handleFormFinish = async () => {
            const valid = await form.validateFields()
            setState(s => ({...s, saving: true}))
            const specialInstructions = valid.requirements
            let name = valid.title.toLowerCase().replace(/ /g, '-')
            const create = {
                target: 'dynamicSections',
                pairs: {
                    sectionStage: mortgage.applicationStage,
                    sectionType: 'UPLOAD',
                    sectionName: name,
                    sectionTitle: valid.title,
                    sectionInstructions: JSON.stringify({
                        children: valid.children,
                        time: valid.time,
                        instructions: {
                            specialRequirements: valid.requirements,
                            downloads: valid.templates,
                        }
                    }),
                    emailReplyID: config.request.id,
                    mortgageID: mortgage.id,
                    observeMID: mortgage.id,
                }
            }
            await mortgage.mutate({create})
            form.setFieldsValue({})
            setState(s => ({...s, saving: false}))
        }
        return (
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                key={`ConditionWriter`}
                form={form}
                requiredMark={false}
                initialValues={{
                    time: '2 mins',
                }}
                onFinish={handleFormFinish}>
                <div className="d-col gap-15">
                    <Form.Item
                        label="Time in mins"
                        name="time"
                        rules={[{required: true, message: `A time is required`}]}
                        className="mb-0">
                        <Input style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {required: true, message: `A title is required`},
                            {
                                validator: (rule, value) => {
                                    return new Promise((resolve, reject) => {

                                        let name = value.toLowerCase().replace(/ /g, '-')
                                        // check does this exist already
                                        const exists = mortgage.dynamicSections.find(item => item.sectionName === name)
                                        if (exists) {
                                            reject(`A section with the name ${name} already exists`)
                                        }
                                        resolve()
                                    })
                                }
                            }

                        ]}
                        className="mb-0">
                        <Input style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="children"
                        rules={[{required: true, message: `A description is required`}]}
                        className="mb-0">
                        <Input.TextArea style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Special Instructions"
                        className="mb-0">
                        <Form.List name="requirements">
                            {(fields, {add, remove}) => (
                                <div className="d-col gap-6">
                                    {fields.map((field) => (
                                        <div className="d-row gap-6">
                                            <Form.Item
                                                className="mb-0 grow"
                                                {...field}>
                                                <Input style={{width: '100%'}}/>
                                            </Form.Item>
                                            <CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </div>
                                    ))}

                                    <Button type="dashed" onClick={() => add()} block>
                                        + Add Special Instruction
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Form.Item>
                    {/*<Form.Item*/}
                    {/*    label="Downloads"*/}
                    {/*    className="mb-0">*/}
                    {/*    <Form.List name="templates">*/}
                    {/*        {(fields, {add, remove}) => (*/}
                    {/*            <div className="d-col gap-6">*/}
                    {/*                {fields.map((field) => (*/}
                    {/*                    <div className="d-row gap-6">*/}
                    {/*                        <Form.Item*/}
                    {/*                            className="mb-0 grow"*/}
                    {/*                            {...field}>*/}
                    {/*                            <Input style={{width: '100%'}}/>*/}
                    {/*                        </Form.Item>*/}
                    {/*                        <CloseOutlined*/}
                    {/*                            onClick={() => {*/}
                    {/*                                remove(field.name);*/}
                    {/*                            }}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                ))}*/}

                    {/*                <Button type="dashed" onClick={() => add()} block>*/}
                    {/*                    + Add Template*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    </Form.List>*/}
                    {/*</Form.Item>*/}
                    <div className="d-row j-end">
                        <Button htmlType="submit"> Save {state.saving && <LoadingOutlined className="ms-6"/>} </Button>
                    </div>
                </div>
            </Form>
        );
    }
    function CombinedTabled(){
        const queries = mortgage.queries.filter(q=>q.emailReplyID === config.request.id)
        const tasks = mortgage.dynamicSections.filter(q=>q.emailReplyID === config.request.id)

        const columns = [
            {key: 'type', title: 'Type', dataIndex: 'type'},
            {key: 'title', title: 'Title', dataIndex: 'title'},
            {key: 'description', title: 'Description', dataIndex: 'description'},
        ]
        const data = [
            ...queries.map(q=>({key: q.id, type:'Query', title: q.title, description: q.description})),
            ...tasks.map(q=>{
                let desc = JSON.parse(q.sectionInstructions)?.children
                return {key: q.id, type:'Task', title: q.sectionTitle, description: desc}
            }),
        ]

        return <Table columns={columns} dataSource={data} pagination={false}/>
    }
    const tabsItems = [
        {
            label: 'Query',
            key: '1',
            children: <QueryTab/>
        },
        {
            label: 'Tasks',
            key: '2',
            children: <TaskTab/>,
        },
    ]

    return (
        <div>
            Original Message:
            <div className="mt-6 mb-12" style={{color: geekblue.primary}}>
                {config.request.requestBody.map((line, i) => <div key={i}>{line}</div>)}
            </div>
            Your Notes:
            <div className="mt-6 mb-12">
                <TextArea/>
            </div>

            <div className="mt-6 mb-12">
                <CombinedTabled/>
            </div>

            <div className="mt-6 mb-12">
                <Tabs defaultActiveKey="1" items={tabsItems}/>
            </div>
        </div>
    );
}

export default ReplyRequestContent;