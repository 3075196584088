import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";

PageHeader.propTypes = {
    title: PropTypes.string.isRequired
};

function PageHeader(props) {
    return (
        <div className={`d-row gap-15 a-center ${props.className}`}>
            {
                !!props.back && (
                    <Link to={props.back}><Avatar icon={<ArrowLeftOutlined/>}/></Link>
                )

            }
            <Typography.Title className="my-0" level={4}>{props.title}</Typography.Title>

                <div className="ms-auto">
                    {props.extra}
                </div>
        </div>

    );
}

export default PageHeader;