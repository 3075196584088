import React, {useState} from 'react';
//import Tesseract from 'tesseract.js'
import * as pdfjs from 'pdfjs-dist'
import {Collapse, Descriptions, Table, Tag, Typography} from "antd";
import dayjs from "dayjs";

let tX = 4
let tY = 5

const parser = (pageItems, StatementType, pageNoInFile) => {
    const findHeaderRowIndex = () => {
        return pageItems.findIndex((item, i) => {
            if (pageItems.length < (i + StatementType.headerColumns.length)) {
                return false
            }
            let nextValues = new Array(StatementType.headerColumns.length).fill(0).map((_, j) => {
                return pageItems[i + j].str
            })
            return nextValues.every((string, j) => {
                return string && string === StatementType.headerColumns[j]
            })
        })
    }
    const findFirstTransactionRowIndex = () => {
        let startAfter = findHeaderRowIndex()
        if (startAfter > -1) {
            return pageItems.findIndex((item, i) => {
                let tests = StatementType.dateRegex.map(regex => regex.test(item.str))
                let looksLikeDate = tests.some(it => !!it)
                return i > startAfter && looksLikeDate
            })
        }
        return -1
    }
    const getRawRows = (from) => {
        let descriptionCellIndex = pageItems.findIndex((item, i) => i > from && item.str && item.str.length > 1);
        let descriptionCell = pageItems[descriptionCellIndex]
        // get all the cells that is the same x axis as description cell
        let sameX = pageItems.filter((item, i) => i > from && item.transform[tX] === descriptionCell.transform[tX])
        // get all the cells that are on the same y axis of each sameX cell
        let rawRows = sameX.map(i => pageItems.filter((item, j) => item.transform[tY] === i.transform[tY]))
        // organise the information in the rows into an object
        return rawRows.map((cells, i) => {
            let row = {}
            cells.forEach((item, j) => {
                if (item.str.trim() !== '') {
                    Object.keys(StatementType.colCoords).forEach(key => {
                        let [start, end] = StatementType.colCoords[key]
                        if (item.transform[tX] >= start && item.transform[tX] < end) {
                            if (!row[key]) {
                                row[key] = item.str
                            } else {
                                row[key] += " " + item.str
                            }
                        }
                    })
                }
            })
            return row
        })
    }
    const doRowCleanUp = (allRows) => {
        let currentDate
        let rows = []
        // clean descriptions
        allRows.forEach((row, i) => {
            if (!!row.date) {
                currentDate = row.date
            }
            if (!!row.debit || !!row.credit || i === 0) {
                row.date = dayjs(currentDate, StatementType.dateFormat, true)
                row.pageNoInFile = pageNoInFile
                rows.push(row)
            } else {
                if (!!row.balance) {
                    rows[rows.length - 1].balance = row.balance
                }
                if (!row.date) {
                    if (!rows[rows.length - 1].details.includes("\n" + row.details)) {
                        rows[rows.length - 1].details += "\n" + row.details
                    }
                } else {
                    console.log('what to do here', row)
                }
            }
        })
        return rows
    }
    const getBankDetails = () => {
        let iban = StatementType.identify.iban(pageItems)
        let accountName = StatementType.identify.accountName(pageItems)
        let accountAddress = StatementType.identify.accountAddress(pageItems)
        let pageSequence = StatementType.identify.pageSequence(pageItems)
        return {
            iban,
            accountName,
            accountAddress,
            pageSequence
        }
    }
    const getTransactions = () => {
        let firstTransactionIndex = findFirstTransactionRowIndex()
        if (firstTransactionIndex > -1) {
            let raw = getRawRows(firstTransactionIndex)
            return doRowCleanUp(raw)
        }
        return null
    }

    const getStatementData = () => {
        let transactions = getTransactions()
        let details = getBankDetails()
        if (details) {
            return {
                ...details,
                transactions
            }
        }
        return null
    }
    return {
        getTransactions,
        getBankDetails,
        getStatementData
    }
}

const getAverageDaysBetweenTransactions = (rows) => {
    // get the rows on each page and calculate the average days between transactions
    let dates = rows.map(row => row.date)
    return averageGapBetweenDates(dates)
    //
    // const pages = rows.reduce((acc, row) => {
    //     if (!acc[row.pageNo]) {
    //         acc[row.pageNo] = []
    //     }
    //     acc[row.pageNo].push(row)
    //     return acc
    // }, {})
    //
    // let averageOnPages = Object.keys(pages).map((index, i) => {
    //     let rows = pages[i]
    //     let dates = rows.map(row => row.date)
    //     return averageGapBetweenDates(dates)
    // })
    // return averageOnPages.reduce((acc, item) => acc + item, 0) / averageOnPages.length
}

const getPossibleMissingPages = (rows) => {
    let average = getAverageDaysBetweenTransactions(rows)
    let problemBeforePageNo = []
    const pages = rows.reduce((acc, row) => {
        if (!acc[row.pageNo]) {
            acc[row.pageNo] = []
        }
        acc[row.pageNo].push(row)
        return acc
    }, {})

    let prevClosing = false
    Object.values(pages).forEach((page, i) => {
        let first = page[0].date
        let last = page[page.length - 1].date
        if (!!prevClosing && prevClosing.add(average + 5, 'days').isBefore(first)) {
            problemBeforePageNo.push(i)
        }
        prevClosing = last
    })
    if (!problemBeforePageNo.length) {
        return 'No'
    }
    return problemBeforePageNo.map(i => `${i - 1}-${i}`).join(', ')
}

const averageGapBetweenDates = (dates) => {
    // Sort dates in ascending order
    dates.sort((a, b) => a.valueOf() - b.valueOf());
    let totalGapMs = 0;

    // Calculate total difference between consecutive dates in milliseconds
    for (let i = 1; i < dates.length; i++) {
        const diffMs = dates[i].valueOf() - dates[i - 1].valueOf();
        totalGapMs += diffMs;
    }

    // Calculate average gap in days
    const averageGapDays = totalGapMs / (dates.length - 1) / (1000 * 60 * 60 * 24);

    return averageGapDays;
};

const getRepeatingPayees = (rows) => {
    function longestCommonSubstring(str1, str2) {
        str1 = str1.toLowerCase();
        str2 = str2.toLowerCase();
        let maxLength = 0;
        let endIndex = 0;
        let table = Array(str1.length + 1).fill(null).map(() => Array(str2.length + 1).fill(0));

        for (let i = 1; i <= str1.length; i++) {
            for (let j = 1; j <= str2.length; j++) {
                if (str1[i - 1] === str2[j - 1]) {
                    table[i][j] = table[i - 1][j - 1] + 1;
                    if (table[i][j] > maxLength) {
                        maxLength = table[i][j];
                        endIndex = i;
                    }
                }
            }
        }

        return str1.substring(endIndex - maxLength, endIndex).toUpperCase();
    }
    const repeatedDebits = {}
    rows.forEach(row => {
        if (!!row.debit && parseFloat(row.debit.replace(/[^0-9.]/g, '')) > 10) {

            let description = row.details.split("\n")[0].slice(-12).toLowerCase()
            if (!repeatedDebits[description]) {
                repeatedDebits[description] = []
            }
            repeatedDebits[description].push(row)
        }
    })
    Object.keys(repeatedDebits).forEach(key => {
        if (repeatedDebits[key].length === 1) {
            delete repeatedDebits[key]
        }
    })
    let betterRepeatedDetails = {}
    Object.keys(repeatedDebits).forEach(key => {
        let str1 = repeatedDebits[key][0].details.split("\n")[0]
        let str2 = repeatedDebits[key][1].details.split("\n")[0]
        let common = longestCommonSubstring(str1, str2)
        betterRepeatedDetails[common] = repeatedDebits[key]
    })
    let items = Object.keys(betterRepeatedDetails).map(key => {
        return {
            key,
            label: key,
            children: (
                <div className="d-col">
                    {betterRepeatedDetails[key].map((row, j) => {
                        return (
                            <div key={j} className="d-row gap-9">
                                <div style={{width: 100, flexShrink: 0}}> {row.date.format('DD MMM YYYY')}</div>
                                <div style={{width: 100, textAlign: 'right'}}>{row.debit}</div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    })
    return <Collapse accordion items={items}/>
    // return Object.keys(betterRepeatedDetails).map(key => {
    //     return (
    //         <div key={key} className="d-col" style={{borderBottom: '1px solid gray'}}>
    //             {betterRepeatedDetails[key].map((row, j) => {
    //                 return (
    //                     <div key={j} className="d-row gap-9">
    //                         <div style={{width: 100, flexShrink: 0}}> {row.date.format('DD MMM YYYY')}</div>
    //                         <div style={{width: 100, textAlign: 'right'}}>{row.debit}</div>
    //                     </div>
    //                 )
    //             })}
    //         </div>
    //     )
    // })
}

const getRegularDebits = (rows) => {
    // find all the occurrences of the same debit amount
    const repeatedDebits = {}
    rows.forEach(row => {
        if (!!row.debit) {
            if (!repeatedDebits[row.debit]) {
                repeatedDebits[row.debit] = []
            }
            repeatedDebits[row.debit].push(row)
        }
    })
    // let debits = rows.reduce((acc, row) => {
    //     if (!!row.debit) {
    //         if (!acc[row.debit]) {
    //             acc[row.debit] = []
    //         }
    //         acc[row.debit].push(row)
    //     }
    //     return acc
    // })
    Object.keys(repeatedDebits).forEach(key => {
        let digits = key.replace(/[^0-9.]/g, '')
        let pf = parseFloat(digits)
        if (pf < 50 || repeatedDebits[key].length < 2) {
            delete repeatedDebits[key]
        }
    })
    let sortable = Object.keys(repeatedDebits).map(key => {
        return {
            amount: key,
            float: parseFloat(key.replace(/[^0-9.]/g, '')),
            rows: repeatedDebits[key]
        }
    }).sort((a, b) => b.float - a.float)
    const items = sortable.map((item, i) => {
        return {
            key: item.amount,
            label: item.amount,
            children: (
                <div className="d-col">

                    {item.rows.map((row, j) => {
                        return (
                            <div key={j} className="d-row gap-9">
                                <div style={{width: 100, flexShrink: 0}}> {row.date.format('DD MMM YYYY')}</div>
                                <div>
                                    {row.details.split('\n')[0]}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    })
    return <Collapse accordion items={items}/>
    // return sortable.map((item, i) => {
    //     return (
    //         <div key={i} className="d-row gap-9">
    //             <div style={{width: 100}}>{item.amount}</div>
    //             <div className="d-col">
    //
    //                 {item.rows.map((row, j) => {
    //                     return (
    //                         <div key={j} className="d-row gap-9">
    //                             <div style={{width: 100, flexShrink: 0}}> {row.date.format('DD MMM YYYY')}</div>
    //                             <div>
    //                                 {row.details.split('\n').map(t => (
    //                                     <div>{t.trim()}</div>
    //                                 ))}
    //                             </div>
    //                         </div>
    //                     )
    //                 })}
    //             </div>
    //         </div>
    //     )
    // })
}

const getAllCredits = (rows) => {
    console.log(rows.filter(row => !!row.credit).map(row => row.details))
    return rows.filter(row => !!row.credit).map(row => {
        console.log(row.details)
        console.log(row.details.split("\n")[0])
        let string = row.details.split("\n")[0]
        return (
            <div key={row.date.format('DD MMM YYYY') + row.details} className="d-row gap-9">
                <div style={{width: 100}}>{row.date.format('DD MMM YYYY')}</div>
                <div style={{width: 250}}>
                    <Typography.Text ellipsis>{string}</Typography.Text>
                </div>
                <div style={{width: 100, textAlign: 'right'}}>{row.credit}</div>
            </div>

        )
    })
}

const findRevolutAccount = (rows) => {
    const allDetails = [...new Set(rows.filter(row => row.details.includes('Revolut') && !row.details.includes('Revolut Rate')).map(row => row.details))]
    return [...new Set(allDetails.map(o => {
        let parts = o.split('Revolut')
        parts.splice(0, 1)
        return 'Revolut' + parts.join('').trim()
    }))]
}

const findDirectDebits = (statementType, rows) => {

    return [...new Set(rows.filter(row => statementType.identify.directDebit(row.details)).map(row => row.details))]
}

const getLargeDebits = (rows) => {
    return rows.filter(row => !!row.debit && parseFloat(row.debit.replace(/[^0-9.]/g, '')) >= 300).map((row, i) => {
        return (
            <div key={i} className="d-row gap-9">
                <div style={{width: 100}}>{row.date.format('DD MMM YYYY')}</div>
                <div style={{width: 150}}>
                    <Typography.Text ellipsis>{row.details.split("\n")[0]}</Typography.Text>
                </div>
                <div style={{width: 100, textAlign: 'right'}}>{row.debit}</div>
            </div>
        )
    })

}

const statementTypes = {
    'AIB': {
        dateRegex: [/^(0?[1-9]|[1-2][0-9]|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/],
        dateFormat: ['D MMM YYYY'],
        headerColumns: ['Date', ' ', 'Details', ' ', 'Debit €', ' ', 'Credit €', ' ', 'Balance €'],
        colCoords: {
            date: [20, 75],
            details: [75, 254],
            debit: [254, 310],
            credit: [310, 366],
            balance: [366, 435]
        },
        identify: {
            statementType: (page) => {
                console.log({page})
                return (page.findIndex(o => o.str === 'Statement of Account with Allied Irish Banks, p.l.c.') > -1 && page.findIndex(o => o.str === 'Personal Bank Account') > -1)
            },
            iban: (page) => {
                let ibanIndex = page.findIndex(o => o.str.startsWith('IBAN:'))
                if (ibanIndex > -1) {
                    let parts = page[ibanIndex].str.split('IBAN:')
                    return parts[1].trim()
                }
                return null
            },
            pageSequence: (page) => {
                let sequenceIndex = page.findIndex(o => o.str === 'Page Number')
                if (sequenceIndex > -1) {
                    let y = page[sequenceIndex].transform[tY]
                    let x = page[sequenceIndex].transform[tX]
                    let numberIndex = page.findIndex((o, i) => i > sequenceIndex && o.transform[tY] < y && o.transform[tX] === x)
                    if (numberIndex > -1) {
                        return page[numberIndex].str
                    }
                }
                return null

            },
            accountName: (page, returnIndex = false) => {
                let labelIndex = page.findIndex(o => o.str === 'Account Name')
                if (!labelIndex) {
                    return null
                }
                let y = page[labelIndex].transform[tY]
                let x = page[labelIndex].transform[tX]
                let nameIndex = page.findIndex((o, i) => i > labelIndex && o.transform[tY] < y && o.transform[tX] === x)
                if (nameIndex > -1) {
                    if (returnIndex) {
                        return nameIndex
                    }
                    return page[nameIndex].str
                }
                return null
            },
            accountAddress: (page) => {
                let accountNameIndex = statementTypes.AIB.identify.accountName(page, true)

                if (accountNameIndex > -1) {
                    let accountName = page[accountNameIndex].str

                    let addressColNameIndex = page.findIndex((o, i) => o.str.toLowerCase().includes(accountName.toLowerCase()) && i !== accountNameIndex)

                    if (addressColNameIndex > -1) {
                        let x = page[addressColNameIndex].transform[tX]
                        let y = page[addressColNameIndex].transform[tY]

                        let lines = []
                        for (let i = 0; i < 5; i++) {
                            let itemIndex = page.findIndex(o => o.transform[tX] === x && o.transform[tY] < y)

                            if (itemIndex > -1) {
                                let item = page[itemIndex]
                                y = item.transform[tY]

                                lines.push(page[itemIndex].str)
                            }
                        }

                        return lines.filter(o => o.trim() !== '').join('\n')
                    }
                }
                return null
            },
            directDebit: (description) => {
                return description.startsWith('D/D')
            }
        }

    },
    'Revolut': {
        dateRegex: [
            /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (0?[1-9]|[12][0-9]|3[01]), \d{4}$/,
            /^(0?[1-9]|[1-2][0-9]|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/
        ],
        dateFormat: ['MMM D, YYYY', 'D MMM YYYY'],
        headerColumns: ['Date', ' ', 'Description', ' ', 'Money out', ' ', 'Money in', ' ', 'Balance'],
        colCoords: {
            date: [38, 120],
            details: [120, 330],
            debit: [330, 412],
            credit: [412, 485],
            balance: [485, 595]
        },
        identify: {
            statementType: (page) => {
                return (page.findIndex(o => o.str === 'Revolut Bank UAB') > -1 && page.findIndex(o => o.str.includes('EUR Statement')) > -1)
            },
            iban: (page) => {
                let ibanIndex = page.findIndex(o => o.str.includes('IBAN'))
                if (ibanIndex > -1) {
                    let ibanCell = page[ibanIndex]
                    let y = ibanCell.transform[tY]
                    let iban = page.filter((o, i) => i > ibanIndex && o.str.trim() !== '' && o.transform[tY] === y).sort((a, b) => a.transform[tX] - b.transform[tX])
                    if (iban.length) {
                        return iban[0].str
                    }
                }
                return null
            },
            pageSequence: (page) => {
                let rowIndex = page.findIndex(o => o.str === '© 2024 Revolut Bank UAB')
                if (rowIndex > -1) {
                    let y = page[rowIndex].transform[tY]

                    let otherItemsOnThisRow = page.filter((o, i) => i < rowIndex && o.transform[tY] === y).filter(it => it.str.trim() !== '').sort((a, b) => a.transform[tX] - b.transform[tX])
                    if (otherItemsOnThisRow) {
                        let itemsString = otherItemsOnThisRow.map(o => o.str).join(' ').trim()

                        const regex = /Page (\d+) of (\d+)/;
                        const match = itemsString.match(regex)
                        if (match) {
                            // Convert match group 1 to integer
                            //const o = parseInt(match[2], 10); // Convert match group 2 to integer
                            return parseInt(match[1], 10)
                        }
                    }
                }
                return null
            },
            accountName: (page) => {
                let colIndex = page.findIndex(o => o.str === 'Balance summary')
                if (colIndex > -1) {
                    let y = page[colIndex].transform[tY]
                    let x = page[colIndex].transform[tX]
                    let otherItemsInThisCol = page.filter((o, i) => o.transform[tY] > y && o.transform[tX] === x)
                    if (otherItemsInThisCol.length) {
                        let ps = otherItemsInThisCol.filter(o => o.str.trim() !== '').sort((a, b) => b.transform[tY] - a.transform[tY])
                        // check if joint account
                        let x2 = ps[0].transform[tX]
                        let y2 = ps[0].transform[tY]
                        let otherItemsInThisRow = page.filter(o => o.transform[tY] === y2 && o.transform[tX] > x2).filter(o => o.str.trim() !== '')
                        if (otherItemsInThisRow.length) {
                            return ps[0].str + ' & ' + otherItemsInThisRow[0].str
                        }
                        return ps[0].str
                    }
                }
                return null
            },
            accountAddress: (page) => {
                let colIndex = page.findIndex(o => o.str === 'Balance summary')
                if (colIndex > -1) {
                    let y = page[colIndex].transform[tY]
                    let x = page[colIndex].transform[tX]
                    let otherItemsInThisCol = page.filter((o, i) => o.transform[tY] > y && o.transform[tX] === x)
                    if (otherItemsInThisCol.length) {
                        let ps = otherItemsInThisCol.filter(o => o.str.trim() !== '').sort((a, b) => b.transform[tY] - a.transform[tY])
                        ps.splice(0, 1)
                        return ps.map(o => o.str).join('\n')
                    }
                }
                return null
            },
            directDebit: (description) => {
                return false
            }
        },

    },
    'Bank of Ireland': {
        dateRegex: [/^(0?[1-9]|[1-2][0-9]|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/],
        dateFormat: ['DD MMM YYYY'],
        headerColumns: ['Date', ' ', 'Transaction details', ' ', 'Payments - out', ' ', 'Payments - in', ' ', 'Balance'],
        colCoords: {
            date: [76, 140],
            details: [140, 320],
            debit: [320, 390],
            credit: [390, 475],
            balance: [475, 550]
        },
        identify: {
            statementType: (page) => {
                return (page.findIndex(o => o.str.includes('Bank Identifier Code BOFI')) > -1)
            },
            iban: (page) => {
                let ibanIndex = page.findIndex(o => o.str.startsWith('IBAN'))
                let x = page[ibanIndex].transform[tX]
                let y = page[ibanIndex].transform[tY]
                let iban = page.filter(o => o.transform[tX] > x && o.transform[tY] === y).filter(o => o.str.trim() !== '').sort((a, b) => a.transform[tX] - b.transform[tX])
                if (iban.length) {
                    return iban[0].str
                }
                return null
            },
            pageSequence: (page) => {
                const regex = /Page (\d+) of (\d+)/;
                let rowIndex = page.findIndex(o => o.str.match(regex))
                if (rowIndex > -1) {
                    let match = page[rowIndex].str.match(regex)
                    if (match) {
                        return parseInt(match[1], 10)
                    }
                }
                return null
            },
            accountName: (page, returnIndex = false) => {
                let rowIndex = page.findIndex(o => o.str === 'Your account name')
                if (rowIndex) {
                    let y = page[rowIndex].transform[tY]
                    let x = page[rowIndex].transform[tX]
                    let otherItemsInThisRowIndex = page.findIndex(o => o.transform[tY] === y && o.transform[tX] > x && o.str.trim() !== '')
                    if (otherItemsInThisRowIndex > -1) {
                        if (returnIndex) {
                            return otherItemsInThisRowIndex
                        }
                        return page[otherItemsInThisRowIndex].str
                    }
                }
            },
            accountAddress: (page) => {
                let nameIndex = statementTypes['Bank of Ireland'].identify.accountName(page, true)
                if (nameIndex > -1) {
                    let name = page[nameIndex].str
                    let addressIndex = page.findIndex(o => o.str.toLowerCase().includes(name.toLowerCase()) && o.transform[tX] < page[nameIndex].transform[tX])
                    if (addressIndex > -1) {
                        let x = page[addressIndex].transform[tX]
                        let y = page[addressIndex].transform[tY]

                        let otherItemsInThisCol = page.filter(o => o.transform[tX] === x && o.transform[tY] < y && o.str.trim() !== '').sort((a, b) => b.transform[tY] - a.transform[tY])
                        console.log({otherItemsInThisCol})
                        if (otherItemsInThisCol.length) {
                            return otherItemsInThisCol.map(o => o.str).join('\n')
                        }
                    }
                }
                return null
            },
            directDebit: (description) => {
                return description.endsWith('DD')
            }
        }
    }
}
const Ocr = () => {


    const [statementPages, setStatementPages] = useState([]);
    const [pagesToOmit, setPagesToOmit] = useState([]);


    const extractTextFromPDF = async (file) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        return new Promise((resolve, reject) => {
            reader.onload = async () => {
                const pdfData = new Uint8Array(reader.result);
                try {
                    const pdf = await pdfjs.getDocument({data: pdfData}).promise;
                    console.log({pdf})
                    const numPages = pdf.numPages;
                    let pages = []
                    for (let i = 1; i <= numPages; i++) {
                        const page = await pdf.getPage(i);
                        console.log({page})
                        const content = await page.getTextContent();
                        pages.push(content.items)
                    }
                    resolve(pages);
                } catch (error) {
                    reject(error);
                }
            };

            reader.onerror = () => {
                reject('Error reading file.');
            };
        });
    };
    function getSizeInKilobytes(obj) {
        // Convert the object to a JSON string
        let jsonString = JSON.stringify(obj);

        // Calculate the byte length of the string
        let byteLength = new TextEncoder().encode(jsonString).length;

        // Convert bytes to kilobytes
        let kilobytes = byteLength / 1024;

        return kilobytes;
    }
    const scanPage = (pageItems, pageNoInFile) => {
        let statementType
        for (let key in statementTypes) {
            if (statementTypes[key].identify.statementType(pageItems)) {
                statementType = key
                break
            }
        }

        if (!statementType) {
            return null
        }

        const parsed = parser(pageItems, statementTypes[statementType], pageNoInFile)

        const statement = parsed.getStatementData()
        if (!statement) {
            return null
        }
        statement.bank = statementType
        return statement

    }
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                let accounts = {}
                const pages = await extractTextFromPDF(file);
                let previous = {
                    iban: false,
                    accountName: false,
                    accountAddress: false
                }
                let pagesToOmit = []
                pages.forEach((page, i) => {
                    let statementPage = scanPage(page, i)
                    if (statementPage) {
                        if (!previous.iban && statementPage.iban) {
                            previous.iban = statementPage.iban
                        }
                        if (!previous.accountName && statementPage.accountName) {
                            previous.accountName = statementPage.accountName
                        }
                        if (!previous.accountAddress && statementPage.accountAddress) {
                            previous.accountAddress = statementPage.accountAddress
                        }
                        if (!statementPage.iban) {
                            statementPage.iban = previous.iban
                        }
                        if (!statementPage.accountName) {
                            statementPage.accountName = previous.accountName
                        }
                        if (!statementPage.accountAddress) {
                            statementPage.accountAddress = previous.accountAddress
                        }
                        if (!!statementPage.transactions && statementPage.transactions.length > 0) {
                            if (!accounts[statementPage.iban]) {
                                accounts[statementPage.iban] = []
                            }
                            accounts[statementPage.iban].push(statementPage)
                        } else {
                            pagesToOmit.push(i + 1)
                            console.log(`not a statement page (${i + 1})`)
                        }
                    } else {
                        pagesToOmit.push(i + 1)
                        console.log(`not a statement page (${i + 1})`)
                    }
                })
                setPagesToOmit(pagesToOmit)
                setStatementPages(accounts)
            } catch (error) {
                console.error('Error extracting text:', error);
            }
        }
    };

    /*
    STEPS:
    1. read the pages
    2. foreach page, identify the bank, account number, statement date, grab sequence identifier and rows after transaction header
    3. process rows
    4. sort the results into bank accounts
     */

    const columns = [
        {title: 'Page', dataIndex: 'pageNoInFile', key: 'pageNo'},
        {
            title: 'Date',
            dataIndex: 'date',
            render: (text) => {
                return text.format('D MMM YYYY')
            },
            key: 'date',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            render: (text) => {
                return text.split('\n').map((item, i) => {
                    return <div key={i}>{item}</div>
                })
            }
        },
        {
            title: 'Debit €',
            dataIndex: 'debit',
            key: 'debit',
            align: 'right',
            render: (text) => {
                if (!text) return null
                return parseFloat(text.replace(/[^0-9.]/g, '')) > 300 ? <><Tag color="red">Large
                    Debit</Tag> {text} </> : text
            }
        },
        {
            title: 'Credit €',
            align: 'right',
            dataIndex: 'credit',
            render: (text) => {
                if (!text) return null
                return parseFloat(text.replace(/[^0-9.]/g, '')) > 10 ? <><Tag
                    color="green">Credit</Tag> {text} </> : text
            },
            key: 'credit',
        },
        {
            title: 'Balance €',
            align: 'right',
            dataIndex: 'balance',
            key: 'balance',
        }
    ]
    return (
        <div>
            <input type="file" onChange={handleFileUpload}/>


            {Object.keys(statementPages).length > 1 && (
                <h3 style={{color: 'red'}}>{Object.keys(statementPages).length} Accounts!</h3>
            )}
            {Object.keys(statementPages).map((key, i) => {

                    let allTransactions = statementPages[key].flatMap(o => o.transactions || []).map((o, i) => {
                        return {
                            ...o,
                            key: i + key
                        }
                    })
                    let range = `${allTransactions[0].date.format('DD MMM YYYY')} - ${allTransactions[allTransactions.length - 1].date.format('DD MMM YYYY')}`
                    return (
                        <div style={{maxWidth: 1200}}>
                            <h2 className="p-15">Overview</h2>
                            <Descriptions bordered column={2} items={[
                                {label: 'Account', children: statementPages[key][0].bank},
                                {label: 'Account Name', children: statementPages[key][0].accountName},
                                {label: 'IBAN', children: key},
                                {label: 'Account Address', children: statementPages[key][0].accountAddress},
                                {
                                    label: 'Possible Missing Dates Between Pages',
                                    children: getPossibleMissingPages(allTransactions)
                                },
                                {
                                    label: 'Closing Balance',
                                    children: allTransactions[allTransactions.length - 1].balance
                                },
                                {
                                    label: 'Revolut Accounts',
                                    children: findRevolutAccount(allTransactions).map((item, i) => <div
                                        key={i}>{item}</div>)
                                },
                                {key: 'toOmit', label: 'Omit Pages', children: pagesToOmit.join(', ')},
                                {key: 'range', label: 'Range', children: range},
                                {
                                    key: 'DD',

                                    label: 'Direct Debits',
                                    children: findDirectDebits(statementTypes[statementPages[key][0].bank], allTransactions).map((item, i) =>
                                        <div key={i}>{item}</div>)
                                },
                                {
                                    key: 'largeDebits',
                                    label: 'Large Debits',
                                    children: getLargeDebits(allTransactions)
                                },
                                {
                                    key: 'blank',
                                    label: '',
                                    children: ''
                                },
                                {
                                    key: 'payees',
                                    label: 'Repeating Payees',
                                    children: getRepeatingPayees(allTransactions),
                                    span: 2,
                                },
                                // {
                                //     key: 'regDebits',
                                //     label: 'Regular Debits',
                                //     span: 2,
                                //     children: getRegularDebits(allTransactions)
                                // },
                                {
                                    label: 'Credits',
                                    span: 2,
                                    children: getAllCredits(allTransactions)
                                },
                            ]}/>

                            {/*<h2 className="p-15">Extracted Statement</h2>*/}
                            <Table dataSource={allTransactions} columns={columns} pagination={false}/>
                        </div>
                    )
                }
            )}
        </div>
    );
};
/*
import textract from 'textract';
function Ocr(props) {
    const [text,setText] = useState('Choose a file')
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };
    const extractTextFromFile = (file) => {
        return new Promise((resolve, reject) => {
            textract.fromFileWithPath(file.path, (error, text) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(text);
                }
            });
        });
    };
    const handleFileAdd = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const text = await extractTextFromFile(file);
                console.log('Extracted Text:');
                console.log(text);
                // Handle extracted text as needed (e.g., save to state, display in UI)
            } catch (error) {
                console.error('Error extracting text:', error);
            }
            //
            // const fileContent = await readFile(file);
            // const worker = await Tesseract.createWorker("eng", 1, {
            //     logger: m => console.log(m),
            // });
            // console.log({worker})
            // await worker.load();
            // await worker.loadLanguage('eng');
            // await worker.initialize('eng');
            // const {data: {text}} = await worker.recognize(file);
            // setText(text);
            // await worker.terminate();
        }
    };
    return (

        <div className="d-row">
            <input type="file" onChange={handleFileAdd}/>
            <div style={{padding:'3rem'}}>
                {text}
            </div>
        </div>
    );
}
*/
export default Ocr;