import React from 'react';
import MortgageForm from "../../../../data-capture/MortgageForm";
import {getQuestionsPercentCompleted} from "../../../../../providers/mortgage/useApplicationForms";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {Alert, Card, Col, Row} from "antd";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import RoyalLondonQuote from "./RoyalLondonQuote";

export function weight(props) {
    return {
        label : "Approximate Weight",
        text  : "How much " + props.you.doYou + " weigh approximately?",
        name  : "weight",
        answer: {
            type: "weight",
        },
        model : "health",
        target: `applicant${props.applicant}.health.weight`
    }
}
export function height(props) {
    return {
        label : "Approximate Height",
        text  : "What is " + props.you.your + " height?",
        name  : "height",
        answer: {
            type: "height",
        },
        model : "health",
        target: `applicant${props.applicant}.health.height`
    }
}
export function smoker(props) {
    return {
        label : "Smoker",
        text  : `${capitaliseFirst(props.you.doYou)} currently smoke?`,
        name  : "smoker",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.smoker`
    }
}
export function everSmoked(props) {
    return {
        label : "Ever Smoked",
        text  : `${capitaliseFirst(props.you.haveYou)} ever smoked?`,
        name  : "everSmoked",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.everSmoked`,
        hides : (form) => form.getFieldValue('smoker') !== false
    }
}
export function cigarettesSmoked(props) {
    return {
        label : "Cigarettes Smoked",
        text  : capitaliseFirst(props.you.doYou) + " smoke cigarettes?`,",
        name  : "cigarettesSmoked",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.health.cigarettesSmoked`,
        hides : (form) => form.getFieldValue('smoker') !== true
    }
}
export function cigarettesPerDay(props) {
    return {
        label : "Cigarettes Per Day",
        text  : `How many cigarettes ${props.you.doYou} smoke per day?`,
        name  : "cigarettesPerDay",
        answer: {
            type: 'int',
        },
        target: `applicant${props.applicant}.health.cigarettesPerDay`,
        hides : (form) => form.getFieldValue('smoker') !== true || form.getFieldValue('cigarettesSmoked') !== true
    }
}
export function cigarSmoked(props) {
    return {
        label : "Cigars Smoked",
        text  : `${capitaliseFirst(props.you.doYou)} smoke cigars?`,
        name  : "cigarSmoked",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.health.cigarSmoked`,
        hides : (form) => form.getFieldValue('smoker') !== true
    }
}
export function cigarsPerDay(props) {
    return {
        label : "Cigars Per Day",
        text  : `How many cigars ${props.you.doYou} smoke per day?`,
        name  : "cigarsPerDay",
        answer: {
            type: 'int',
        },
        target: `applicant${props.applicant}.health.cigarsPerDay`,
        hides : (form) => form.getFieldValue('smoker') !== true || form.getFieldValue('cigarSmoked') !== true
    }
}
export function pipeSmoked(props) {
    return {
        label : "Pipe Smoked",
        text  : `${capitaliseFirst(props.you.doYou)} smoke a pipe?`,
        name  : "pipeSmoked",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.health.pipeSmoked`,
        hides : (form) => form.getFieldValue('smoker') !== true
    }
}
export function pipeGramsPerDay(props) {

    return {
        label : "Pipe Grams Per Day",
        text  : `How many grams of tobacco ${props.you.doYou} smoke per day?`,
        name  : "pipeGramsPerDay",
        answer: {
            type: 'int',
        },
        target: `applicant${props.applicant}.health.pipeGramsPerDay`,
        hides : (form) => form.getFieldValue('smoker') !== true || form.getFieldValue('pipeSmoked') !== true
    }

}
export function electronicCigaretteSmoked(props) {
    return {
        label : "Electronic Cigarette Smoked",
        text  : `${capitaliseFirst(props.you.doYou)} smoke electronic cigarettes?`,
        name  : "electronicCigaretteSmoked",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.health.electronicCigaretteSmoked`,
        hides : (form) => form.getFieldValue('smoker') !== true
    }
}
export function otherNicotineReplacementUsed(props) {
    return {
        label : "Other Nicotine Replacement Used",
        text  : `${capitaliseFirst(props.you.doYou)} use any other nicotine replacement products?`,
        name  : "otherNicotineReplacementUsed",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        target: `applicant${props.applicant}.health.otherNicotineReplacementUsed`,
        hides : (form) => form.getFieldValue('smoker') !== true
    }
}

export function stoppedSmokingDate(props) {
    return {
        label : "Stopped Smoking Date",
        text  : `When did ${props.you.you} stop smoking?`,
        name  : "stoppedSmokingDate",
        answer: {
            type: 'ddmmyyyy',
        },
        model : "health",
        target: `applicant${props.applicant}.health.stoppedSmokingDate`,
        hides : (form) => form.getFieldValue('smoker') !== false || form.getFieldValue('everSmoked') !== true
    }
}
export function alcoholUnits(props) {
    return {
        label : "Alcohol Units",
        text  : `How many units of alcohol ${props.you.doYou} drink per week?`,
        name  : "alcoholUnits",
        answer: {
            type: 'int',
        },
        model : "health",
        target: `applicant${props.applicant}.health.alcoholUnits`
    }
}
export function dangerousActivities(props) {
    return {
        label : "Dangerous Activities",
        text  : `${capitaliseFirst(props.you.doYou)} participate in any dangerous activities?`,
        name  : "dangerousActivities",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.dangerousActivities`
    }
}
export function familyDeath(props) {
    return {
        label : "Family Death",
        text  : `Has any of ${props.you.your} immediate family (parents or siblings) died from any serious illness under the age of 60?`,
        name  : "familyDeath",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.familyDeath`
    }
}
export function diagnosedConditions(props) {
    return {
        label : "Diagnosed Conditions",
        text  : `${capitaliseFirst(props.you.haveYou)} been diagnosed with any medical conditions?`,
        name  : "diagnosedConditions",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.diagnosedConditions`
    }
}
export function diagnosedConditionsDetails(props) {
    return {
        label : "Diagnosed Conditions List",
        text  : `Please provide details of diagnosed conditions`,
        name  : "diagnosedConditionsDetails",
        answer: {
            type: 'textarea',
        },
        model : "health",
        target: `applicant${props.applicant}.health.diagnosedConditionsDetails`,
        hides : (form) => form.getFieldValue('diagnosedConditions') !== true
    }
}
export function prescribedMedication(props) {
    return {
        label : "Prescribed Medication",
        text  : `${capitaliseFirst(props.you.areYou)} currently taking any medication?`,
        name  : "prescribedMedication",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.prescribedMedication`
    }
}
export function prescribedMedicationDetails(props) {
    return {
        label : "Prescribed Medication List",
        text  : `Please provide details of any prescribed medication along with the date it was prescribed`,
        name  : "prescribedMedicationDetails",
        answer: {
            type: 'textarea',
        },
        model : "health",
        target: `applicant${props.applicant}.health.prescribedMedicationDetails`,
        hides : (form) => form.getFieldValue('prescribedMedication') !== true
    }
}
export function hasGp(props) {
    return {
        label : "GP",
        text  : `${capitaliseFirst(props.you.doYou)} have a GP?`,
        name  : "hasGp",
        answer: {
            type   : 'choice',
            choices: [
                {
                    text : "Yes",
                    value: true
                },
                {
                    text : "No",
                    value: false
                }
            ]
        },
        model : "health",
        target: `applicant${props.applicant}.health.hasGp`
    }
}
export function gpName(props) {
    return {
        label : "GP Name",
        text  : `Please provide the name of ${props.you.your} GP`,
        name  : "gpName",
        answer: {
            type: 'text',
        },
        model : "health",
        target: `applicant${props.applicant}.health.gpName`,
        hides : (form) => form.getFieldValue('hasGp') !== true
    }
}
export function gpAddress(props) {
    return {
        label : "GP Address",
        text  : `Please provide the address of ${props.you.your} GP`,
        name  : "gpAddress",
        answer: {
            type: 'address',
        },
        model : "health",
        target: `applicant${props.applicant}.health.gpAddress`,
        hides : (form) => form.getFieldValue('hasGp') !== true
    }
}
export function yearsWithGp(props) {
    return {
        label : "Years With GP",
        text  : `Approximately how many years ${props.you.haveYou} been with this GP?`,
        name  : "yearsWithGp",
        answer: {
            type: 'int',
        },
        model : "health",
        target: `applicant${props.applicant}.health.yearsWithGp`,
        hides : (form) => form.getFieldValue('hasGp') !== true
    }
}
export function gpLastVisit(props) {
    return {
        label : "Last GP Visit",
        text  : `When did ${props.you.you} last visit a GP?`,
        name  : "gpLastVisit",
        answer: {
            type: 'ddmmyyyy',
        },
        model : "health",
        target: `applicant${props.applicant}.health.gpLastVisit`,
    }
}
export function gpLastVisitYearsAgo(props) {
    return {
        label : "Years Since Last GP Visit",
        text  : `How many years ago did ${props.you.you} last visit a GP?`,
        name  : "gpLastVisitYearsAgo",
        answer: {
            type: 'int',
        },
        model : "health",
        target: `applicant${props.applicant}.health.gpLastVisitYearsAgo`,
    }
}
function healthQuestionsAndPercent(mortgage, applicant) {
    let you = mortgage[`applicant${applicant}`].strings
    let betterProps = {
        mortgage,
        applicant,
        you
    }
    let questions = [
        weight(betterProps),
        height(betterProps),
        smoker(betterProps),
        cigarettesSmoked(betterProps),
        cigarettesPerDay(betterProps),
        cigarSmoked(betterProps),
        cigarsPerDay(betterProps),
        pipeSmoked(betterProps),
        pipeGramsPerDay(betterProps),
        electronicCigaretteSmoked(betterProps),
        otherNicotineReplacementUsed(betterProps),
        everSmoked(betterProps),
        stoppedSmokingDate(betterProps),
        alcoholUnits(betterProps),
        dangerousActivities(betterProps),
        familyDeath(betterProps),
        diagnosedConditions(betterProps),
        diagnosedConditionsDetails(betterProps),
        prescribedMedication(betterProps),
        prescribedMedicationDetails(betterProps),
        hasGp(betterProps),
        gpName(betterProps),
        gpAddress(betterProps),
        yearsWithGp(betterProps),
      //  gpLastVisit(betterProps),
        gpLastVisitYearsAgo(betterProps),
    ]

    let percent = getQuestionsPercentCompleted(mortgage, questions)
    return {
        questions,
        percent
    }
}
function MpiInfo() {
    const mortgage = useMortgage()
    let percent
    let app1 = healthQuestionsAndPercent(mortgage,1)
    percent = app1.percent
    let app2
    if (mortgage.twoApplicants) {
        app2 = healthQuestionsAndPercent(mortgage,2)
        percent = (app1.percent + app2.percent) / 2
    }

    return (<Card>
            {percent !== 100 && (<Alert className="mb-15" message={`Medical details missing`} type="warning"/>)}
            {percent === 100 && (<Alert className="mb-15" message={"Medical details completed"} type="success"/>)}
            <Row gutter={[15,15]}>
                <Col>
                    <MortgageForm questions={app1.questions} title={mortgage.applicant1.firstName}/>
                </Col>
                <Col>
                    {mortgage.twoApplicants && (
                        <MortgageForm questions={app2?.questions} title={mortgage.applicant2.firstName}/>)}
                </Col>
            </Row>

        </Card>

    )

}

export default MpiInfo;