import useMortgage from "../../../../../providers/mortgage/useMortgage";
import Employment from "../../_common/verified-figures/employment/Employment";
import {EmploymentTypes} from "../../../../../../models";
import {legacyIncome} from "../../_common/lender-tests/useXlsTests";

export function customerApprovalDocumentsToCreate(mortgage) {
    const items = []
    const submissions = mortgage.submissions.filter(a => !a.invalidatedReason).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
    })

    let legacy = legacyIncome(mortgage)
    let self
    let paye
    if (legacy){
        self = mortgage.applicant1.employment.employeeType === EmploymentTypes.SELFEMPLOYED
        if (!self && mortgage.twoApplicants) {
            self = mortgage.applicant2.employment.employeeType === EmploymentTypes.SELFEMPLOYED
        }
        paye = mortgage.applicant1.employment.employeeType === EmploymentTypes.EMPLOYED
        if (!paye && mortgage.twoApplicants) {
            paye = mortgage.applicant2.employment.employeeType === EmploymentTypes.EMPLOYED
        }
    }
    else{
        self = mortgage.applicant1.employmentIncome.findIndex(a => a.employmentType === EmploymentTypes.SELFEMPLOYED) > -1
        if (!self && mortgage.twoApplicants) {
            self = mortgage.applicant2.employmentIncome.findIndex(a => a.employmentType === EmploymentTypes.SELFEMPLOYED) > -1
        }
        paye = mortgage.applicant1.employmentIncome.findIndex(e => e.employmentType === EmploymentTypes.EMPLOYED) > -1
        if (!paye && mortgage.twoApplicants) {
            paye = mortgage.applicant2.employmentIncome.findIndex(e => e.employmentType === EmploymentTypes.EMPLOYED) > -1
        }
    }


    const activeSubmission = submissions.length ? submissions[0] : null
    // 1. Lender Checklist
    if (!activeSubmission) {
        return items
    }
    switch (activeSubmission.lenderCode) {
        case 'avant':
            items.push({
                name  : 'direct-debit-mandate',
                fill  : true,
                folder: 'downloads'
            })
            break;
        case 'ptsb':
            if (self) {
                items.push({
                    folder : 'docusign',
                    name   : 'pre-drawdown-self-employed',
                    overlay: true,
                })
            }
            if (paye) {
                items.push({
                    folder : 'docusign',
                    name   : 'pre-drawdown-paye',
                    overlay: true,
                })
            }
            break;
        case 'haven':
            items.push({
                name   : 'change-of-correspondence-address',
                overlay: true,
                folder : 'downloads',
            })
            items.push({
                name   : 'deed-of-assignment',
                overlay: true,
                folder : 'downloads',
            })

            break;
    }
    return items.map(item => {
        return {
            ...item,
            key      : item.name,
            private  : false,
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === item.folder && doc.name === item.name && doc.submissionID === activeSubmission.id),
        }
    })
}

function useCustomerApprovalDocuments(props) {
    const mortgage = useMortgage()
    return customerApprovalDocumentsToCreate(mortgage)
}

export default useCustomerApprovalDocuments;