import React from 'react';
import useMortgage from "../../../providers/mortgage/useMortgage";
import QueriesInSectionCollapse from "../../client/application-stages/_common/queries/QueriesInSectionCollapse";

function ExistingQueries({config}) {
    console.log({config})
    const mortgage = useMortgage()
    const filter = query=>{
        if (query.stage === config.stage && query.section === config.section){
            if (!!query.index){
                return query.index === config.index
            }
            return true
        }
        return false
    }
    const otherQueries = mortgage.queries.filter(filter)
    if (!otherQueries.length){
        return null
    }

    return <QueriesInSectionCollapse queries={otherQueries} referenceId={config.referenceId}/>
}

export default ExistingQueries;