import {legacyIncome} from "../lender-tests/useXlsTests";
import {expandArrayResult} from "./FiguresUsed";
import {
    ApplicationTypes, AssetTypes, BoolLike, BuildType, DebtTypes, EmploymentTypes, Frequencies, MortgageTypes, PersonalAccommodationSituations
} from "../../../../../../models";
import {lastYear1, lastYear2, lastYear3} from "../../../../../../assets/scripts/verifiedFigures";
import {useContext} from "react";
import {ApplicationUploadSections} from "../../../../../providers/mortgage/ApplicationUploadsProvider";
import {verifiedAccounts} from "../funding/verifiedAccounts";

class applicantVerifiedFigures {
    constructor(mortgage, appN) {
        this.mortgage = mortgage
        this.legacy = legacyIncome(mortgage)
        this.n = appN
        this.applicant = mortgage[`applicant${appN}`]
        this.applicantIsEmployed = this.isEmployed()
    }
    isEmployed = () => {
        if (this.legacy) {
            return [
                EmploymentTypes.EMPLOYED,
                EmploymentTypes.SELFEMPLOYED
            ].includes(this.applicant.employment.employmentType)
        }
        return !!this.applicant.employmentIncome.find(it => [
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(it.employmentType))
    }
    getVerifiedSalaryCertValue = (job) => {
        let verifiedSectionSalaryCertificate = this.mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${this.n}` && ((!job.id && !s.index) || (s.index === job.id)))
        let certs = []
        if (verifiedSectionSalaryCertificate) {
            let verifiedSalaryCertificateUploads = verifiedSectionSalaryCertificate.verified.uploads.active
            verifiedSalaryCertificateUploads.forEach(verifiedUpload => {
                let values = verifiedUpload.verified.values;
                certs.push({
                    date : values.documentDate,
                    basic: values.grossBasic || 0,
                })
            })
        }
        if (!certs.length) {
            return {
                error: 'No salary cert found',
                value: 0
            }
        }
        // get the most recent cert
        let mostRecentCert = certs.reduce((a, b) => a.date > b.date ? a : b, {date: null})
        return {
            value: mostRecentCert.basic
        }
    }
    getVerifiedPayslipValue = (job) => {
        let verifiedSectionPayslips = this.mortgage.mortgageUploads.setup.find(s => s.name === `payslips-${this.n}` && (!job.id || s.index === job.id))
        let payslips = []
        if (verifiedSectionPayslips && verifiedSectionPayslips.verified) {
            let verifiedPayslipsUploads = verifiedSectionPayslips.verified.uploads.active

            verifiedPayslipsUploads.forEach(verifiedUpload => {
                let values = verifiedUpload.verified.values;
                payslips.push({
                    date : values.documentDate,
                    gross: values.grossPay || 0,
                    basic: values.grossBasic || 0,
                    net  : values.netPay || 0,
                })
            })
        }
        if (!payslips.length) {
            return {
                error: 'No payslips found',
                value: 0
            }
        }
        let averagePayslipBasic = payslips.reduce((acc, payslip) => acc + payslip.basic, 0) / payslips.length
        let mostRecentPayslip = payslips.reduce((a, b) => a.date > b.date ? a : b, {date: null})
        return {
            value: averagePayslipBasic
            // value: mostRecentPayslip.basic
        }

    }

    fields = {
        basic: () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            const returnBasic = (job) => {
                let verifiedSalaryCertValue = this.getVerifiedSalaryCertValue(job)

                if (verifiedSalaryCertValue.error) {
                    let verifiedPayslipValue = this.getVerifiedPayslipValue(job)
                    if (verifiedPayslipValue.error) {
                        return {
                            error: 'No salary cert or payslip found',
                            value: 0
                        }
                    }
                    let amt = 0
                    switch (job.payFrequency) {
                        case Frequencies.WEEKLY:
                            amt = verifiedPayslipValue.value * (365 / 7)
                            break;
                        case Frequencies.MONTHLY:
                            amt = verifiedPayslipValue.value * 12
                            break;
                        case Frequencies.FORTNIGHTLY:
                            amt = verifiedPayslipValue.value * (365 / 14)
                            break;
                        default:
                            return {
                                error: 'Pay frequency not set',
                                value: 0
                            }
                    }
                    // round amt to 0 decimal places
                    amt = Math.round(amt)
                    return {
                        warning: 'No salary cert found - using payslip average',
                        value  : amt
                    }
                }
                return {
                    value: verifiedSalaryCertValue.value
                }
            }

            if (this.legacy) {
                return returnBasic({
                    ...this.applicant.employment,
                    id: null
                })
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(job => {
                return returnBasic(job)
            }))
        }

    }
}

class applicantApplicationFigures {
    constructor(mortgage, appN) {
        this.mortgage = mortgage
        this.legacy = legacyIncome(mortgage)
        this.n = appN
        this.applicant = mortgage[`applicant${appN}`]
        this.applicantIsEmployed = this.isEmployed()
    }
    bonusMaker = (year) => {
        return () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if ((this.applicant.employment.incomeChecks || []).includes('BONUS')) {
                    if (!this.applicant.income.bonus) {
                        return {
                            error: 'No bonus'
                        }
                    }
                    return {value: this.applicant.income.bonus}
                }
                return {
                    value: 0
                }
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => ({value: it.incomes ? it.incomes.find(income => income.name === 'bonus' && income.guaranteed)?.amount || 0 : 0})))
        }
    }
    isEmployed = () => {
        if (this.legacy) {
            return [
                EmploymentTypes.EMPLOYED,
                EmploymentTypes.SELFEMPLOYED
            ].includes(this.applicant.employment.employmentType)
        }
        return !!this.applicant.employmentIncome.find(it => [
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(it.employmentType))
    }
    fields = {
        sector            : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if (!this.applicant.employment.employmentType) {
                    return {
                        error: 'No employment type'
                    }
                }
                return {value: this.applicant.employment.employmentType === EmploymentTypes.SELFEMPLOYED ? 'self' : 'private'}
            }
            let emp
            if (this.applicant.employmentIncome.length > 1) {
                if (!this.applicant.employment.primaryEmploymentId) {
                    return {
                        error: 'Primary employment not selected',
                        value: ''
                    }
                }
                emp = this.applicant.employmentIncome.find(it => it.id === this.applicant.employment.primaryEmploymentId)
            }
            else {
                emp = this.applicant.employmentIncome[0]
            }
            return {
                value: emp.employmentType === EmploymentTypes.SELFEMPLOYED ? 'self' : emp.publicSector ? 'public' : 'private'
            }
        },
        basic             : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if (!this.applicant.income.grossBasic) {
                    return {
                        error: 'No gross basic',
                        value: 0
                    }
                }
                return {value: this.applicant.income.grossBasic}
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => {
                if (!it.grossBasic) {
                    return {
                        error: 'No gross basic',
                        value: 0
                    }
                }
                return {
                    value: it.grossBasic
                }
            }))
        },
        overtime          : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }

            if (this.legacy) {
                if ((this.applicant.employment.incomeChecks || []).includes('OVERTIME')) {
                    if (!this.applicant.income.overtime) {
                        return {
                            error: 'No overtime',
                            value: 0
                        }
                    }
                    return {value: this.applicant.income.overtime}
                }
                return {
                    value: 0
                }
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => ({
                value: it.incomes ? it.incomes.find(income => income.name === 'overtime' && income.guaranteed)?.amount || 0 : 0
            })))
        },
        allowances        : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if ((this.applicant.employment.incomeChecks || []).includes('ALLOWANCES')) {
                    if (!this.applicant.income.allowances) {
                        return {
                            error: 'No allowances'
                        }
                    }
                    return {value: this.applicant.income.allowances}
                }
                return {
                    value: 0
                }
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => ({value: it.incomes ? it.incomes.find(income => income.name === 'shiftAllowance' && income.guaranteed)?.amount || 0 : 0})))

        },
        other             : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if ((this.applicant.employment.incomeChecks || []).includes('OTHER')) {
                    if (!this.applicant.income.other) {
                        return {
                            error: 'No other'
                        }
                    }
                    return {value: this.applicant.income.other}
                }
                return {
                    value: 0
                }
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => ({value: it.incomes ? it.incomes.find(income => income.name === 'otherAllowance' && income.guaranteed)?.amount || 0 : 0})))

        },
        commission        : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            if (this.legacy) {
                if ((this.applicant.employment.incomeChecks || []).includes('COMMISSION')) {
                    if (!this.applicant.income.commission) {
                        return {
                            error: 'No commission'
                        }
                    }
                    return {value: this.applicant.income.commission}
                }
                return {
                    value: 0
                }
            }
            return expandArrayResult('add', this.applicant.employmentIncome.map(it => ({value: it.incomes ? it.incomes.find(income => income.name === 'commission' && income.guaranteed)?.amount || 0 : 0})))

        },
        year1Bonus        : this.bonusMaker(lastYear1),
        year2Bonus        : this.bonusMaker(lastYear2),
        year3Bonus        : this.bonusMaker(lastYear3),
        clearingLoans     : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  :0
                }
            }
            let loans = this.applicant.debts.filter(debt => debt.debtType === DebtTypes.LOAN && !!debt.clearedBeforeMortgage)
            return expandArrayResult('add', loans.map(debt => {
                if (!debt.monthlyPayment) {
                    return {error: 'Missing monthly payment'}
                }
                return {value: debt.monthlyPayment}
            }))
        },
        pension           : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            return {
                message: 'We dont collect this',
                value  : 0
            }
        },
        accommodationCosts: () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (this.mortgage.requirement.cohabiting && this.n === 2) {
                return {
                    message: 'Cohabiting',
                    value  : 0
                }
            }
            if (!this.applicant.personal.accommodationSituation) {
                return {
                    error: 'No accommodation situation',
                    value: 0
                }
            }
            if (!this.applicant.financial.monthlyAccommodationCosts) {
                return {
                    error: 'No monthly accommodation costs',
                    value: 0
                }
            }
            let rent = 0
            if (this.applicant.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                rent += this.applicant.financial.monthlyAccommodationCosts
            }
            else if (this.applicant.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && this.applicant.financial.contributeToRelatives) {
                rent += this.applicant.financial.monthlyAccommodationCosts
            }
            else if (this.mortgage.mortgageType === MortgageTypes.REFINANCE || this.mortgage.applicationType === ApplicationTypes.MOVINGHOME) {
                rent += this.applicant.financial.monthlyAccommodationCosts
            }

            return {value: rent}
        },
        otherPRA          : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicantIsEmployed) {
                return {
                    message: 'Not employed',
                    value  :0
                }
            }
            return {
                value  : 0,
                message: 'Not collected'
            }
        },
        credit            : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            let credit = this.applicant.debts.filter(debt => [
                DebtTypes.CREDITCARD,
                DebtTypes.STORECARD
            ].includes(debt.debtType))
            return expandArrayResult('add', credit.map(debt => {
                if (!debt.creditLimit) {
                    return {error: 'Missing credit limit'}
                }
                return {value: debt.creditLimit}
            }))
        },
        maintenancePaid   : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (this.applicant.financial.paysMaintenance) {
                if (!this.applicant.financial.maintenanceCosts) {
                    return {
                        error: 'Mission maintenance costs',
                        value: 0
                    }
                }
                return {value: this.applicant.financial.maintenanceCosts}
            }
            return {value: 0}
        },
        loans             : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            let loans = this.applicant.debts.filter(debt => debt.debtType === DebtTypes.LOAN && !debt.clearedBeforeMortgage)
            return expandArrayResult('add', loans.map(debt => {
                if (!debt.monthlyPayment) {
                    return {error: 'Missing monthly payment'}
                }
                return {value: debt.monthlyPayment}
            }))
        },
        childmindingCosts : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (!this.applicant.financial.hasChildcareCosts) {
                return {
                    value: 0
                }
            }
            if (this.applicant.financial.hasChildcareCosts && !this.applicant.financial.monthlyChildcareCosts) {
                return {
                    error: 'Missing childcare costs',
                    value: 0
                }
            }
            return {
                value: this.applicant.financial.monthlyChildcareCosts
            }
        },
        funds             : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            if (this.n === 2) {
                return {
                    value  : 0,
                    message: 'We dont collect separately'
                }
            }
            if (!this.mortgage.requirement.depositSavings) {
                return {
                    warning: 'No deposit supplied',
                    value  : 0
                }
            }
            return {
                value: this.mortgage.requirement.depositSavings
            }
        },
        savings           : () => {
            if (this.n === 2 && !this.mortgage.twoApplicants) {
                return {
                    message: 'No applicant 2',
                    value  : 0
                }
            }
            let savings = []
            if (this.applicant.financial.hasCurrentAccountSavings) {
                if (!this.applicant.financial.monthlySavings) {
                    savings.push({
                        error: 'Missing current account savings',
                        value: 0
                    })
                }
                else {
                    savings.push({
                        value: this.applicant.financial.monthlySavings
                    })
                }
            }
            let filter = asset => {
                if (asset.assetType === AssetTypes.SAVING) {
                    if ([
                        Frequencies.MONTHLY,
                        Frequencies.WEEKLY,
                        Frequencies.FORTNIGHTLY
                    ].includes(asset.increasesFrequency))
                    {
                        return true
                    }
                }
                return false
            }
            let allSavings = this.applicant.assets.filter(filter)
            if (allSavings.length) {
                savings.push(...allSavings.map(asset => {
                    if (!asset.increasesFrequency) {
                        return {
                            error: 'Missing saving frequency',
                            value: 0
                        }
                    }
                    if (!asset.increases) {
                        return {
                            error: 'Missing saving amount',
                            value: 0
                        }
                    }
                    switch (asset.increasesFrequency) {
                        case Frequencies.MONTHLY:
                            return {
                                value: asset.increases
                            }
                        case Frequencies.FORTNIGHTLY:
                            return {
                                value: asset.increases * 26 / 12
                            }
                        default:
                            return {
                                value: asset.increases * 52 / 12
                            }
                    }
                }))
            }
            return expandArrayResult('add', savings)
        }
    }
}

class mortgageApplicationFigures {
    constructor(mortgage) {
        this.mortgage = mortgage
    }
    fields = {
        helpToBuy: () => {
            if (this.mortgage.properties[0].propertyValue > 500000) {
                return {
                    value  : 0,
                    message: 'Property > €500k'
                }
            }
            if (this.mortgage.properties[0].buildType !== BuildType.NEW) {
                return {
                    value  : 0,
                    message: 'Not new build'
                }
            }
            if (this.mortgage.requirement.helpToBuyScheme !== BoolLike.FALSE) {
                let response = {
                    value: this.mortgage.requirement.helpToBuySchemeAmount || this.mortgage.twoApplicants ? 30000 : 25000
                }
                if (this.mortgage.requirement.helpToBuyScheme === BoolLike.NOTSURE) {
                    response.warning = 'Not sure about help to buy'
                }
                return response
            }
            return {
                value  : 0,
                message: 'Not utilised'
            }
        },
        firstHome: () => {
            if (this.mortgage.requirement.firstHomeScheme !== BoolLike.FALSE) {
                let response = {
                    value: this.mortgage.requirement.firstHomeSchemeAmount || 0
                }
                if (response.value === 0) {
                    response.warning = 'No first home amount'
                }
                if (this.mortgage.requirement.firstHomeScheme === BoolLike.NOTSURE) {
                    response.warning = 'Not sure about first home'
                }
                return response
            }
            return {
                value  : 0,
                message: 'Not utilised'
            }
        },
        gift     : () => {
            if (![
                true,
                false
            ].includes(this.mortgage.requirement.receivingGifts))
            {
                return {
                    value: 0,
                    error: 'If gifts not answered'
                }
            }
            if (this.mortgage.requirement.receivingGifts) {
                return {
                    value: this.mortgage.requirement.depositGifts || 0
                }
            }
            return {
                value  : 0,
                message: 'Not utilised'
            }
        }
    }

}

export function useMortgageFigures(mortgage, viewingVerified) {

    const {apply} = useContext(ApplicationUploadSections);
    const accumulator = {};

    const addFieldsToAccumulator = (fields, suffix = '') => {
        Object.keys(fields).forEach(key => {
            accumulator[`${key}${suffix}`] = fields[key]();
        });
    };

    const addVerifiedFundsToAccumulator = (applicantNumber) => {
        const allStatements = verifiedAccounts(mortgage, apply.filter(it => it.applicant === applicantNumber));
        if (allStatements.hasOwnProperty('€')) {
            const statements = allStatements['€']

            accumulator[`savings${applicantNumber}`] = expandArrayResult('add', statements.dataSource.filter(it => !it.excluded).map(it => {
                if (it.error) {
                    return {
                        error: it.error,
                        value: 0
                    };
                }
                return {value: it.averageMonthly};
            }));
            accumulator[`funds${applicantNumber}`] = {value: statements.totalClosingBalance}
        }
    };

    addFieldsToAccumulator(new applicantApplicationFigures(mortgage, 1).fields, '1');
    if (viewingVerified) {
        addFieldsToAccumulator(new applicantVerifiedFigures(mortgage, 1).fields, '1');
    }
    if (mortgage.twoApplicants) {
        addFieldsToAccumulator(new applicantApplicationFigures(mortgage, 2).fields, '2');
        if (viewingVerified) {
            addFieldsToAccumulator(new applicantVerifiedFigures(mortgage, 2).fields, '2');
        }
    }

    addFieldsToAccumulator(new mortgageApplicationFigures(mortgage).fields);

    if (viewingVerified) {
        addVerifiedFundsToAccumulator(1);
        if (mortgage.twoApplicants) {
            addVerifiedFundsToAccumulator(2);
        }
    }
    const addTotals = (figures) => {
        let keys = Object.keys(figures)
        keys.forEach(key => {
            if (figures[key].hasOwnProperty('sum')) {
                figures[key].total = figures[key].sum
            }
            else {
                figures[key].total = figures[key].value
            }
        })
    }
    addTotals(accumulator)
    // add variable total

    let variable1  = accumulator.overtime1.total + accumulator.allowances1.total + accumulator.other1.total + accumulator.commission1.total + accumulator.year1Bonus1.total
    let variableTotal=variable1
    accumulator.variable1 = {value: variable1, total: variable1}
    if (mortgage.twoApplicants) {
        let variable2 = accumulator.overtime2.total + accumulator.allowances2.total + accumulator.other2.total + accumulator.commission2.total + accumulator.year1Bonus2.total
        variableTotal += variable2
        accumulator.variable2 =  {value: variable2, total: variable2}
    }
    accumulator.variableTotal = {value: variableTotal, total: variableTotal}

    // add savings total
    let savingsTotal = accumulator.savings1.total || 0
    if (mortgage.twoApplicants) {
        savingsTotal += accumulator.savings2.total || 0
    }
    accumulator.savingsTotal = {value: savingsTotal, total: savingsTotal}

    // add accommodation costs total
    let accommodationCostsTotal = accumulator.accommodationCosts1.total || 0
    if (mortgage.twoApplicants) {
        accommodationCostsTotal += accumulator.accommodationCosts2.total || 0
    }
    accumulator.accommodationCostsTotal = {value: accommodationCostsTotal, total: accommodationCostsTotal}

    // add clearing loans total
    let clearingLoansTotal = accumulator.clearingLoans1.total || 0
    if (mortgage.twoApplicants) {
        clearingLoansTotal += accumulator.clearingLoans2.total || 0
    }
    accumulator.clearingLoansTotal = {value: clearingLoansTotal, total: clearingLoansTotal}

    // add other PRA total
    let otherPRATotal = accumulator.otherPRA1.total || 0
    if (mortgage.twoApplicants) {
        otherPRATotal += accumulator.otherPRA2.total || 0
    }
    accumulator.otherPRATotal = {value: otherPRATotal, total: otherPRATotal}

    // add basic total
    let basicTotal = accumulator.basic1.total || 0
    if (mortgage.twoApplicants) {
        basicTotal += accumulator.basic2.total || 0
    }
    accumulator.basicTotal = {value: basicTotal, total: basicTotal}

    // add funds total
    let fundsTotal = accumulator.funds1.total || 0
    if (mortgage.twoApplicants) {
        fundsTotal += accumulator.funds2.total || 0
    }
    accumulator.fundsTotal = {value: fundsTotal, total: fundsTotal}

    return accumulator;
}