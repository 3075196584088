import React from 'react';
import useSussdCoverNote from "../../../../../../../../config-files/mortgage/documents/useSussdCoverNote";
import {green, volcano} from "@ant-design/colors";
import {Alert} from "antd";
import ButtonJumboAsync from "../../../../../../elements/ButtonJumboAsync";
import {FileTextOutlined, SaveOutlined} from "@ant-design/icons";

function LenderMoreOptions({
    mortgage,
    lender,
    lenderName,
    results,
    currentTest,
    noCurrentSubmission
})
{
    const saveActiveSubmission = async () => {
        if (!currentTest.id) {
            return
        }

        let test = mortgage.lenderTests.find(e => e.id === currentTest.id)
        let myResult = {
            ...test.results[lender],
            testId: currentTest.id,
        }
        //TODO Correct figures with overrides (not collected currently)
        let myInput = {
            figures: test.data.figures,
            test   : test.data.tests[lender]
        }
        let mutation = {}
        // If no active submission, create one
        let cfiAdminMutation = mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('affordabilityTested', 'fake-submission-id')
        let formData = {
            lenderCode                : lender,
            provisionalLoan           : myInput.figures.provisionalLoan,
            provisionalTerm           : myInput.figures.provisionalTerm,
            provisionalValue          : myInput.figures.provisionalValue,
            provisionalXlsOutput      : JSON.stringify(myResult),
            provisionalXlsInput       : JSON.stringify(myInput),
            completedFlowItemsForAdmin: cfiAdminMutation.value,
        }
        if (!mortgage.activeSubmission) {
            mutation.create = [
                {
                    target: 'submissions',
                    pairs : {
                        ...formData,
                        mortgageID: mortgage.id,
                    }
                }
            ]
        }
        else {
            mutation.update = [
                {
                    target: `submissions[${mortgage.activeSubmission.id}]`,
                    pairs : {
                        ...formData,
                    }
                }
            ]
        }
        await mortgage.mutate(mutation)
    }
    const updateActiveSubmissionFigures = async () => {
        if (!currentTest.id) {
            return
        }
        let test = mortgage.lenderTests.find(e => e.id === currentTest.id)
        let myResult = {
            ...test.results[lender],
            testId: currentTest.id,
        }
        //TODO Correct figures with overrides (not collected currently)
        let myInput = {
            figures: test.data.figures,
            test   : test.data.tests[lender]
        }

        let formData = {
            provisionalLoan     : myInput.figures.provisionalLoan,
            provisionalTerm     : myInput.figures.provisionalTerm,
            provisionalValue    : myInput.figures.provisionalValue,
            provisionalXlsOutput: JSON.stringify(myResult),
            provisionalXlsInput : JSON.stringify(myInput),
        }
        let mutation = {}
        mutation.update = [
            {
                target: `submissions[${mortgage.activeSubmission.id}]`,
                pairs : {
                    ...formData,
                }
            }
        ]
        await mortgage.mutate(mutation)
    }
    // const onLenderAndResultClick = async () => {
    //     if (!currentTest.id) {
    //         return
    //     }
    //
    //     let test = mortgage.lenderTests.find(e => e.id === currentTest.id)
    //     let myResult = {
    //         ...test.results[lender],
    //         testId: currentTest.id,
    //     }
    //     //TODO Correct figures with overrides (not collected currently)
    //     let myInput = {
    //         figures: test.data.figures,
    //         test   : test.data.tests[lender]
    //     }
    //     let mutation = {}
    //     // If no active submission, create one
    //     let cfiAdminMutation = mortgage.completedFlowItemsForAdmin.getMarkCompleteUpdateMutation('affordabilityTested', 'fake-submission-id')
    //     let formData = {
    //         lenderCode                : lender,
    //         provisionalLoan           : myInput.figures.provisionalLoan,
    //         provisionalTerm           : myInput.figures.provisionalTerm,
    //         provisionalValue          : myInput.figures.provisionalValue,
    //         provisionalXlsOutput      : JSON.stringify(myResult),
    //         provisionalXlsInput       : JSON.stringify(myInput),
    //         completedFlowItemsForAdmin: cfiAdminMutation.value,
    //     }
    //     if (!mortgage.activeSubmission) {
    //         mutation.create = [
    //             {
    //                 target: 'submissions',
    //                 pairs : {
    //                     ...formData,
    //                     mortgageID: mortgage.id,
    //                 }
    //             }
    //         ]
    //     }
    //     else {
    //         mutation.update = [
    //             {
    //                 target: `submissions[${mortgage.activeSubmission.id}]`,
    //                 pairs : {
    //                     ...formData,
    //                 }
    //             }
    //         ]
    //     }
    //     await mortgage.mutate(mutation)
    // }
    const generateCoverNoteWithTheseFigures = () => {
        openTest2()
    }
    const fakeSubmission = {
        lender,
        data  : {
            figures: currentTest?.data?.figures,
            test   : currentTest?.data?.tests?.[lender]
        },
        result: currentTest?.results?.[lender]
    }
    const {open: openTest2} = useSussdCoverNote({submission: fakeSubmission})
    if (!results) {
        return null
    }
    let thisIsAlreadyInSubmission = mortgage?.activeSubmission?.provisionalXlsOutput?.testId === currentTest.id
    // const items = results.map(item => {
    //     return {
    //         key     : item.key,
    //         label   : item.label,
    //         children: (<div className="d-row j-end a-center">
    //             {!!item.hasOwnProperty('passed') && (<span style={{
    //                 marginRight    : 'auto',
    //                 display        : "inline-block",
    //                 backgroundColor: item.passed ? green.primary : volcano.primary,
    //                 height         : 10,
    //                 width          : 10,
    //                 borderRadius   : 5
    //             }}/>)}
    //             <span style={{marginLeft: 8}}>{item.value}</span>
    //         </div>)
    //     }
    // })

    return (<div>

        <div className="d-col mt-15 gap-15">
            <div className="d-row a-center gap-9">
                <div className=" d-col gap-15">
                    {/* Replace the figures on the current submission  */}

                        <Alert type="info" message={<div className="d-col gap-9">
                            <div>Create an on-the-fly Cover Note using these figures</div>
                            <div>This is just for testing the waters and the Cover Note does not get saved</div>
                            <ButtonJumboAsync className="me-auto" onClick={generateCoverNoteWithTheseFigures} icon={<FileTextOutlined style={{fontSize: 16}}/>} label="Generate Cover Note"
                                              subText="without changing the curring submission"/>
                        </div>}/>


                    {(!thisIsAlreadyInSubmission && !noCurrentSubmission && mortgage.activeSubmission.lenderCode === lender) && (<Alert type="info" message={<div className="d-col gap-9">
                        <div>Replace the figures on the current submission</div>
                        <div>Doing this action will overwrite the loan amount sought, term,property value and existing figures, but leave the lender lender and submission state in place</div>
                        <div>If you should not overwrite the existing figures, discontinue this submission and setup a new one</div>
                        <ButtonJumboAsync className="me-auto" onClick={updateActiveSubmissionFigures} icon={<SaveOutlined style={{fontSize: 16}}/>} label="Replace Figures"
                                          subText="overwite the current submissions figures"/>
                    </div>}/>)}
                    {noCurrentSubmission && (<Alert type="info" message={<div className="d-col gap-9">
                        <div>Save these figures as a new submission</div>
                        <div>Doing this action will create a new submission with these figures, including the loan amount sought, term and property value</div>
                        <ButtonJumboAsync className="me-auto" onClick={saveActiveSubmission} icon={<SaveOutlined style={{fontSize: 16}}/>} label="Save as New Submission"
                                          subText="set these figures to be used for the submission"/>
                    </div>}/>)}

                </div>
            </div>
        </div>
    </div>)
}

export default LenderMoreOptions;