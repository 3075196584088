import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, ConfigProvider, DatePicker, Form, Input, InputNumber, Popconfirm} from "antd";
import {joinWithBr, joinWithNl} from "../../../../../../assets/scripts/string-functions";
import dayjs from "dayjs";

import {LONGDATE} from "../../../../../../assets/scripts/constants";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import useMortgageUploadService from "../../../../../providers/mortgage/useMortgageUploadService";
import {DeleteOutlined, LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {Auth} from "aws-amplify";
import 'dayjs/locale/en-ie'
import {red} from "@ant-design/colors";
import SlashDate from "../../../../custom-inputs/SlashDate";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";

dayjs.locale('en-ie')
QueryItemForm.propTypes = {
    item: PropTypes.object.isRequired
};

function QueryItemForm(props) {
    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const {getBySub}= useBackendUsers()
    const [state, setState] = useState({editing: false, saving: false})
    const dateDefault = props.item.date ? dayjs(props.item.date, "YYYY-MM-DD") : null

    const initialValues = {
        date: dateDefault,
        title: props.item.title,
        description: props.item.description,
        query: props.item.query.join('\n'),
        private: !!props.item.private,
        exclude: !!props.item.excludedBy,
        amount: props.item.amount
    }

    const handleDelete = async () => {

            await mortgage.mutate({
                delete: {
                    target: `queries`,
                    id: props.item.id
                }
            })

    }
    const handleLenderAnswerSave = async (form) => {

        setState(s => ({...s, saving: true}))
        const user = await Auth.currentAuthenticatedUser()
        const update = {
            target: `queries[${props.item.id}]`,
            pairs: {
                query: form.query.split('\n'),
                description: form.description,
                title: form.title,
                date: form.date ? form.date.format('YYYY-MM-DD') : null,
                amount: form.amount ? form.amount : null,
                excludedOn: form.exclude ? (new Date()).toISOString() : null,
                excludedBy: form.exclude ? user.attributes.sub : null,
                private: form.private
            }
        }
        await mortgage.mutate({update})
        setState(s => ({...s, saving: false}))
    }
    function LinkToItem() {
        switch (props.item.referenceType) {
            case "UPLOAD":
                const upload = mortgage.uploads.find(it => it.id === props.item.referenceId)
                if (upload) {
                    const handleUploadOpen = async () => {
                        await uploadService.open(upload)
                    }
                    return (
                        <Form.Item
                            label="File"
                        >
                            <a onClick={handleUploadOpen}>{upload.fileName}</a>
                        </Form.Item>
                    )
                }
                return null

            default:
                return null
        }

    }

    function DeleteConfirm() {
        return <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this query?"
            okText="Delete"
            onConfirm={handleDelete}
            icon={
                <QuestionCircleOutlined
                    style={{
                        color: red.primary,
                    }}
                />
            }
        >
            <Button danger>Delete</Button>
        </Popconfirm>
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Form: {
                        labelColor: '#8c8c8c'
                    },
                },
            }}
        >

            <Form
                requiredMark={false}
                initialValues={initialValues}
                onFinish={handleLenderAnswerSave}
                labelCol={{span:8}}
                wrapperCol={{span:16}}
            >
                <div className="d-row j-equal grow gap-24 f-wrap">
                    <div>
                        <Form.Item
                            label="Card Title"
                            name="title">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="date"
                            label="Date">
                            <DatePicker format={"D MMM YYYY"}/>
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            label="Amount">
                            <InputNumber style={{width: '100%'}} prefix="€"/>
                        </Form.Item>
                        <Form.Item
                            label="Card Description"
                            name="description">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Query Text"
                            name="query">
                            <Input.TextArea/>
                        </Form.Item>
                        <LinkToItem/>

                    </div>

                    <div>
                        <div className="d-row j-end">
                            <div className="me-auto">
                                Created By: {getBySub(props.item.createdBy).firstName} on {dayjs(props.item.createdAt).format(LONGDATE)}
                            </div>
                            <DeleteConfirm/>
                        </div>
                        <Form.Item
                            name="exclude"
                            valuePropName="checked"
                        >
                            <Checkbox> Exclude from cover letter</Checkbox>

                        </Form.Item>
                        <Form.Item
                            name="private"
                            valuePropName="checked"
                        >
                            <Checkbox
                                //    defaultChecked={!!props.item.private}
                            >Hide from user</Checkbox>

                        </Form.Item>


                        <Button htmlType="submit"> Save
                            {state.saving && <LoadingOutlined className="ms-6"/>}
                        </Button>
                    </div>
                </div>
            </Form>
        </ConfigProvider>
    );
}

export default QueryItemForm;