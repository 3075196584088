import React, {useEffect} from 'react';
import useSubscription from "./useSubscription";
import {onCreateAdminLastViewedMortgage} from "../../graphql/subscriptions";
import {performGQL} from "../api-functions/functions";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {byLastViewedBySorted, syncAdminLastViewedMortgages} from "../../graphql/queries";
import useBackendUsers from "./mortgage/useBackendUsers";

export const LastViewedMortgages = React.createContext({})

function LastViewedMortgagesProvider(props) {
    const backendUsers = useBackendUsers()

    const [state, setState] = React.useState([])

    const loadLastViewedMortgages = async () => {
        try {
            let today = new Date();
            today.setDate(today.getDate() - 3);
            const lastViewedMortgageItems = await performGQL({
                lastViewedBy: backendUsers.me.sub,
                lastViewedOn: {gt: today.toISOString()},
                sortDirection: 'DESC',
                limit: 30
            }, byLastViewedBySorted)
            setState(lastViewedMortgageItems.items)
        } catch (e) {
            console.warn(e)
        }
    }
    const setMyItems = (updatingFunction) => {
        // updatingFunction is a function that expects the previous state and returns the next state
        setState(prevState =>
            // First, get the new state based on the previous state
            updatingFunction(prevState).filter(item=>item.lastViewedBy === backendUsers.me.sub)
        );
    }



    useEffect(() => {
        loadLastViewedMortgages()
    }, []);
    useSubscription({
        subscription: {
            name: 'AdminLastViewedMortgage',
            onCreate: onCreateAdminLastViewedMortgage
        },
        setState: setMyItems
    })
    let uniqueIds = [...new Set(state.map(item => item.mortgageID))];
    let uniqueViewings = uniqueIds.map(id => state.find(item => item.mortgageID === id)).sort((a, b) => new Date(b.lastViewedOn) - new Date(a.lastViewedOn));
    return (
        <LastViewedMortgages.Provider value={
            uniqueViewings
        }>{props.children}</LastViewedMortgages.Provider>
    );
}

export default LastViewedMortgagesProvider;