import dayjs from "dayjs";

export const filterObjectForNull = (obj, filterForNullExceptions = []) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined) {
            acc[key] = value;
        } else {
            filterForNullExceptions.forEach(exception => {
                if (exception === key) {
                    acc[key] = value
                }
            })
        }
        return acc;
    }, {});
}
export const mostRecentDate = (date1, date2) => {
    let array = []
    if (date1) {
        array.push(dayjs(date1))
    }
    if (date2) {
        array.push(dayjs(date2))
    }
    array = array.filter(d => !!d)
    switch (array.length) {
        case 0:
            return null
        case 1:
            return array[0]
        default:
            return array[0].isAfter(array[1]) ? array[0] : array[1]
    }
}
export const getStageTaskComplete = (mortgage, stage, name) => {
    if (mortgage && mortgage.stageTasks) {
        let task =  mortgage.stageTasks.find(item => item.stage === stage && item.name === name)
        if (task && !!task.completedOn) {
            let {completedOn, completedBy, id} = task
            return  {on: completedOn, by: completedBy, id}
        }
        return false
    }
    return false
}