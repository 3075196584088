

export function baseRowCellFactory ({name,value, type, initialState }){
    const cell = {
        value,
        setValue: (value) => {

        },
        toString: () => {
            return String(value)
        },
        valueOf() {
            return value;
        }
    }

    switch (type){
        case 'AWSDateTime':
            cell.formItem = () => {
                return {
                    ant: 'Datepicker',
                    props: {
                        format: 'YYYY-MM-DD HH:mm:ss',
                        showTime: true,
                        value: initialState[name]
                    }
                }
            }
            break
        case 'AWSDate':
            cell.formItem = () => {
                return Number(initialState[name])
            }
            break
        case 'String':
            cell.formItem = () => {
                return String(initialState[name])
            }
            break
        case '[String]':
            cell.formItem = () => {
                return initialState[name]
            }
            break
        default:
            break
    }

    return cell
}