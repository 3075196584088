import React from 'react';
import SectionForm from "../SectionForm";
export function receivingGifts(props) {
    return {
        label: "Gifts Included",
        text: "Will your deposit include gifts from relatives/ or friends?",
        subtext: "You'll be asked to confirm this by uploading an affidavit from the giver",
        name: "receivingGifts",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true,
                },
                {
                    text: "No",
                    value: false,
                }
            ]
        },
        model: "requirement",
        rules: [
            {required: true, message: `An selection is required`},
        ],
        target: `requirement.receivingGifts`
    }
}
export function depositGifts(props) {
    return {
        label: 'Total Gifts',
        name: 'depositGifts',
        text: props.mortgage.twoApplicants ? 'What is value of all gifts you\'ll both receive?' : 'What is value of all gifts you\'ll receive?',
        model: 'requirement',
        answer: {
            type: 'euro',
            step: 1000
        },
        hides: (form) => {
            return form.getFieldValue('receivingGifts') === false
        },
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
        ],
        target: `requirement.depositGifts`
    }
}
export function depositSavings(props) {
    return {
        label: 'Total Own Funds',
        name: 'depositSavings',
        text: props.mortgage.twoApplicants ? 'How much will you both pay as deposit?' : 'How much will you pay as deposit?',
        model: 'requirement',
        answer: {
            type: 'euro',
            step: 1000
        },
        rules: [
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
        ],
        target: `requirement.depositSavings`

    }
}
export const mortgageDepositSteps=(props)=>{
    return [
        {
            label: 'Deposit Source',
            title: `Source Of Deposit`,
            questions: [
                receivingGifts(props),
                depositGifts(props),
                depositSavings(props),
            ]
        }
    ]
}
function MortgageDeposit(props) {
    const steps = mortgageDepositSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    return <SectionForm title="Deposit"  key="MortgageDeposit" {...props} questions={questions}/>
}

export default MortgageDeposit;