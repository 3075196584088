import React from 'react';
import FiguresUsed from "./FiguresUsed";

function VerifiedFigures(props) {


    return (<div className="d-col gap-15">

        <FiguresUsed useVerified={false}/>
    </div>);
}

export default VerifiedFigures;