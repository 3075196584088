import React from 'react';
import {Card} from "antd";
import {TableHeading} from "../FiguresUsed";
import {gray, green, red} from "@ant-design/colors";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {balanceOfFundsFunds} from "../../funding/BalanceOfFunds";

function OwnContribution({verified}) {
    const mortgage = useMortgage()
    let figures = verified ? mortgage.figures.verified : mortgage.figures.unverified
    const {funds1, funds2} = figures

    const {requiredSavings} = balanceOfFundsFunds(mortgage)
    let ownFunds = funds1.value
    if (funds2){
        ownFunds += funds2.value
    }
    let mismatch =  ownFunds - requiredSavings

    return ( <Card styles={{body: {padding: 8}}}>
        <TableHeading title="Required Contrib" color={gray.primary} total={requiredSavings}/>
        <div className="d-row j-between">
            <span>Available Funds:</span>
            <span>{Math.round(ownFunds).toEuro()}</span>
        </div>
        <div className="d-row j-between">
            {mismatch > 0 ?
                <span style={{color: green[6]}} >Excess Funds:</span> :
                <span  style={{color: red.primary}} >Funds Shortfall:</span>
            }
            <span style={{color: mismatch > 0 ? '' : red.primary, fontWeight:'bold'}}>
             {Math.round(Math.abs(mismatch)).toEuro()}
            </span>
        </div>

    </Card>);
}

export default OwnContribution;