import React, {useContext} from 'react';
import {UpcomingAppointments} from "../../../../providers/UpcomingAppointmentProvider";
import {List, Popconfirm, Tag, Typography} from "antd";
import {Link} from "react-router-dom";

import {Mortgages} from "../../../../providers/MortgagesProvider";
import {ClockCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import {performGQL} from "../../../../api-functions/functions";
import {deleteUserMortgageAppointment, updateUserMortgageAppointment} from "../../../../../graphql/mutations";
import OverflowedCard from "../../_common/OverflowedCard";
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
function ScheduledAppointments(props) {
    const appointments = useContext(UpcomingAppointments)
    const mortgages = useContext(Mortgages)

    const deleteAppointment = async (id) => {
        let _version = appointments.find(a => a.id === id)._version
        await performGQL({input: {id, _version }}, deleteUserMortgageAppointment)
    }

    const sorted = appointments.sort((a, b) => {
        return new Date(a.appointmentDate) - new Date(b.appointmentDate)
    }).filter(it=>it.expired!==1)

    return ( <OverflowedCard title=" Upcoming appointments"> <List
            bordered={false}
            header={null}
            dataSource={sorted}
            renderItem={(item) => {
                let mortgage = mortgages.ALL.find(m => m.id === item.mortgageID)
                let applicationTitle = mortgage ? mortgage.applicationTitle : 'Not Loaded'
                let date = new Date(item.appointmentDate)
                let time = date.getTime()
                let now = new Date().getTime()
                let props = {}
                let color = 'secondary'
                if (time < now) {
                    color = 'danger'
                }
                else if (time - now < 1000 * 60 * 60) {
                    color = 'warning'
                }

                let djs = dayjs(date)

                let dateStr = djs.format('D MMMM h:mm a')
                if (djs.isToday()) {
                    dateStr = 'Today ' +djs.format('h:mm a')
                    let diff = time - now
                    if (diff < 1000 * 60 * 60 * 2) { // 2 hrs
                        props.mark = true
                    }
                }
                if (djs.isTomorrow()) {
                    dateStr = 'Tomorrow ' +djs.format('h:mm a')
                }
                return (
                    <List.Item className="d-row j-between">

                            <Link to={`/client/${item.mortgageID}`} style={{width: 400, color: "gray"}}>
                            <div className="d-col">
                                <Typography.Text {...props} type={color}>{applicationTitle}</Typography.Text>
                                <div className="d-row">
                                    <small><ClockCircleOutlined/> {dateStr}</small>

                                </div>
                            </div>
                            </Link>
                            <Popconfirm title={`Are you sure you want to delete the appointment?`}
                                        onConfirm={() => {
                                            deleteAppointment(item.id)
                                        }} okText="Yes" cancelText="No">
                                <DeleteOutlined/>
                            </Popconfirm>
                    </List.Item>
                )
            }
            }
        /></OverflowedCard>
    )
}

export default ScheduledAppointments;