import React, {useContext} from 'react';
import useMortgage from "../../../providers/mortgage/useMortgage"
import {Card, List, Skeleton} from "antd";
import TaskListItem from "./TaskListItem";
import TaskItem from "../../dashboard/landing-page/tasks/TaskItem";
import {expandTasks} from "../../../providers/MyAdminTasksProvider";

function TaskList(props) {
    const mortgage = useMortgage()
    const myTasks = expandTasks(mortgage.tasks, [mortgage])

    return (
        <div className="p-15">
        <Skeleton loading={!mortgage}>

            <List bordered dataSource={myTasks} renderItem={(item)=>(
                <List.Item>
                    <TaskItem task={item} withHasTheBall/>
                </List.Item>
            )}/>

            {/*<List*/}
            {/*    className="w-100"*/}
            {/*    size="large"*/}
            {/*    bordered={false}*/}
            {/*    itemLayout="vertical"*/}
            {/*    dataSource={mortgage?.tasks?.sort((a, b) => new Date(b.due) - new Date(a.due))}*/}
            {/*    renderItem={(item) => (*/}
            {/*        <List.Item key={`notelistitem-${item.id}`} style={{border:0}}>*/}
            {/*            <TaskListItem item={item}/>*/}
            {/*        </List.Item>*/}
            {/*    )}*/}

            {/*/>*/}
        </Skeleton>
        </div>
    );
}

export default TaskList;