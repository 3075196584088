import {Storage} from "@aws-amplify/storage";

function useS3() {
    const getS3Url = async ({
        path,
        config
    }) => {

        const url = await Storage.get(path, config);
        return url
    }
    const openDocument = (url) => {
        window.open(url, 'document', "height=900,width=1030,toolbar=0,location=0,menubar=0");
    }
    const getFile = async (url) => {
        return await fetch(url).then(res => {
            console.log({res})
            return res.arrayBuffer()
        })
    }
    const put = async (path, file, config) => {
        return await Storage.put(path, file, config)
    }
    const remove = async (path, config) => {
        return await Storage.remove(path, config)
    }

    const copy = async (source, destination) => {
        return await Storage.copy(source, destination)
    }

    return {
        put,
        copy,
        getS3Url,
        openDocument,
        remove,
        getFile
    }
}

export default useS3;