import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import EmailButton from "../EmailButton";
import {ApplicationStatuses} from "../../../../../../../models";
//import useAipReceivedEmail from "../../../../../../../config-files/mortgage/emails/useAipReceivedEmail";

function AipReceivedButton(props) {
    // const emails = useAipReceivedEmail()
    // return <EmailButton emailChain={emails}/>
}

export default AipReceivedButton;