import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {listAllAndSetItems, performGQL} from "../api-functions/functions";
import {userApplicantActivitiesByActionedBy} from "../../graphql/queries";
import useSubscription from "./useSubscription";
import {onCreateUserApplicantActivity, onDeleteUserApplicantActivity, onUpdateUserApplicantActivity
} from "../api-functions/mortgages/getInitialMortgages";
import {deleteUserApplicantActivity, updateUserApplicantActivity} from "../../graphql/mutations";

export const ApplicantActivities = React.createContext({});

ApplicantActivitiesProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function ApplicantActivitiesProvider(props) {
    const [applicantActivities,setApplicantActivities] = useState([])

    const setActivities = (activities) => {
        //add / update activities
        const filteredActivities = activities
        setApplicantActivities(s=>{
            if (!s.length){
                return filteredActivities
            }
            const newActivities = [...s]
            filteredActivities.forEach(activity=>{
                const index = newActivities.findIndex(a=>a.id===activity.id)
                if (index!==-1){
                    newActivities[index] = activity
                }
                else{
                    newActivities.push(activity)
                }
            })
            return newActivities
        })
    }

    const load = async () => {
        await listAllAndSetItems({actionedBy: 'null'}, userApplicantActivitiesByActionedBy, setActivities)
    }

    const deleteOld = async ()=>{
        const old = applicantActivities.filter(a=> new Date(a.createdAt) < new Date(new Date().getTime() - 1000*60*60*24*7))
        const promises = old.map(a=>{
            return performGQL({
                input: {
                    id: a.id,
                    actionedBy: "auto",
                    _version: a._version
                }
            }, updateUserApplicantActivity)
        })
        await Promise.all(promises)
    }
    useEffect(() => {
        load()
    }, [])
    useEffect(() => {
        if (applicantActivities.length > 20){
            setTimeout(deleteOld, 10000)
        }
    }, [applicantActivities.length]);


    useSubscription({
        subscription: {
            name: 'UserApplicantActivity',
            onUpdate: onUpdateUserApplicantActivity,
            onCreate: onCreateUserApplicantActivity,
            //onDelete: onDeleteUserApplicantActivity,
        },
        setState: setApplicantActivities
    })
    return (
        <ApplicantActivities.Provider value={applicantActivities.filter(a=>!!a.actions)}>
            {props.children}
        </ApplicantActivities.Provider>
    );
}

export default ApplicantActivitiesProvider;