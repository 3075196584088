import React, {useEffect} from 'react';
import {listAllAndSetItems, performGQL} from "../api-functions/functions";
import useSubscription from "./useSubscription";
export const ActiveMortgagesStageTasks = React.createContext({});
const userMortgagesStageTasksByApplicationStatus = /* GraphQL */ `
    query UserMortgagesByApplicationStatus(
        $applicationStatus: ApplicationStatuses!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgagesByApplicationStatus(
            applicationStatus: $applicationStatus
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                _deleted
                stageTasks {
                    items {
                        id
                        mortgageID
                        stage
                        name
                        deleted
                        due
                        completedOn
                        completedBy
                        createdAt
                        updatedAt
                        _version
                        _deleted
                        _lastChangedAt
                        __typename
                    }
                    nextToken
                    startedAt
                    __typename
                }
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
const adminMortgageStageTasksByMortgageID = /* GraphQL */ `
    query AdminMortgageStageTasksByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelAdminMortgageStageTaskFilterInput
        $limit: Int
        $nextToken: String
    ) {
        adminMortgageStageTasksByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                stage
                name
                deleted
                due
                completedOn
                completedBy
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
const onCreateAdminMortgageStageTask = /* GraphQL */ `
    subscription OnCreateAdminMortgageStageTask(
        $filter: ModelSubscriptionAdminMortgageStageTaskFilterInput
    ) {
        onCreateAdminMortgageStageTask(filter: $filter) {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
function ActiveMortgagesStageTasksProvider(props) {
    const [history, setHistory] = React.useState([])
    const getMoreTasksForMortgage = async (vars)=>{
        const {items, nextToken: newNextToken} = await performGQL( vars, adminMortgageStageTasksByMortgageID);
        if (items.length) {
            setHistory(s=>{
                return [...s, ...items.filter(it=>!it._deleted)]
            })
        }
        if (newNextToken) {
            await getMoreTasksForMortgage({
                ...vars,
                nextToken: newNextToken
            })
        }
    }

    const mortgageToStageTasks = (mortgage) => {
        let {items, nextToken} = mortgage.stageTasks
        if (nextToken){
            let vars = {
                mortgageID: mortgage.id,
                nextToken
            }
            getMoreTasksForMortgage(vars)
        }
        return items.filter(it=>!it._deleted)
    }

    useEffect(() => {
        // Load the tasks
        listAllAndSetItems({applicationStatus: 'ACTIVE'}, userMortgagesStageTasksByApplicationStatus, (mortgages)=>{
            setHistory(s=>{
                return [...s, ...mortgages.flatMap(m=>mortgageToStageTasks(m))]
            })
        })
        return () => {
            // Cleanup
        }
    },[])

    useSubscription({
            subscription: {
                name: 'AdminMortgageStageTasks',
                onCreate: onCreateAdminMortgageStageTask,
            },
            setState: setHistory
        }
    )

    return (
        <ActiveMortgagesStageTasks.Provider
            value={{history}}>
            {props.children}
        </ActiveMortgagesStageTasks.Provider>
    );
}

export default ActiveMortgagesStageTasksProvider;