import React, {useState} from 'react';
import {capitaliseWords} from "../../../../../../../../../../assets/scripts/string-functions";
import {LoadingOutlined} from "@ant-design/icons";
import {Button, Input} from "antd";
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";

function Renamer({uploadId, autoName}) {
    const [renaming, setRenaming] = useState(false)
    const mortgage = useMortgage()
    const handleRenamePredefined = async () => {
        await handleRename(autoName)
        //await props.handleRename(id, capitaliseWords(props.section.title))
    }
    const handleBlur = async (e) => {
        await handleRename(e.target.value)
    }
    const handleRename = async (name) => {
        setRenaming(true)
        await mortgage.mutate({update: {target: `uploads[${uploadId}].logicalName`, value: name}})
        setRenaming(false)
    }
    return (
        <>
            <span>Rename Upload {renaming && <LoadingOutlined/>}</span>
            <Button onClick={handleRenamePredefined}>To "{autoName}"</Button>
            <Input onBlur={handleBlur} placeholder="Or type a new name ..."/>
        </>
    );
}

export default Renamer;