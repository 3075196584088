import React, {useEffect} from 'react';
import {Form, Input, InputNumber, Select, Tabs} from "antd";

function QuestionTabs({dataPoints, parentName = null, setAnswer, form, readOnly = false}) {
    const items = Object.keys(dataPoints).map((sectionName, index) => {

        let key = parentName ? parentName + '|' + sectionName : sectionName
        return {
            key: key,
            label: sectionName,
            children: (Array.isArray(dataPoints[sectionName]) ?
                <Questions readOnly={readOnly} setAnswer={setAnswer} items={dataPoints[sectionName]} form={form}/> :
                <QuestionTabs readOnly={readOnly} parentName={key} dataPoints={dataPoints[sectionName]} form={form} setAnswer={setAnswer}/>)
        }
    })

    return (
        <div className="d-row j-center">
            <Tabs
                style={{width: '100%'}}
                size="small"
                tabPosition="left"
                items={items}
            />
        </div>

    );
}
function Questions({items, setAnswer, form, readOnly = false}) {
    let readOnlyProp = readOnly ? {disabled: true} : {}
    return (

            <Form
                form={form}
                requiredMark={false}
                validateTrigger="onBlur"
                className={"question-tabs"}
                labelCol={{span: 10}}
                // wrapperCol={{span:12}}
                onValuesChange={(changedValueObj, allValues) => {

                    let key = Object.keys(changedValueObj)[0]
                    let value = changedValueObj[key]
                    setAnswer(key, value)
                }}
                size="small"
                layout={"horizontal"}
            >
                {
                    items.map((item, index) => {
                        let name = item.coord
                        switch (item.question.type) {
                            case 'select':
                                return (
                                    <Form.Item

                                        key={name}
                                        style={{marginBottom: 4}}
                                        name={name}
                                        label={item.name}
                                        rules={item.question.rules}
                                    >
                                        <Select {...readOnlyProp} key={name}
                                                options={item.question.options.map(it => ({value: it, label: it}))}/>
                                    </Form.Item>
                                )
                            case 'number':
                                return (
                                    <Form.Item
                                        key={name}
                                        style={{marginBottom: 4}}
                                        name={name}
                                        label={item.name}
                                        rules={item.question.rules}
                                    >
                                        <InputNumber {...readOnlyProp} style={{width: '100%'}} key={item.coord}/>
                                    </Form.Item>
                                )
                            case 'text':
                                return (
                                    <Form.Item
                                        key={name}
                                        style={{marginBottom: 4}}
                                        name={name}
                                        label={item.name}
                                        rules={item.question.rules}
                                    >
                                        <Input {...readOnlyProp} style={{width: '100%'}} key={item.coord}/>
                                    </Form.Item>
                                )
                            case 'arrayText':
                                return (
                                    <Form.Item
                                        key={name}
                                        style={{marginBottom: 4}}
                                        name={name}
                                        label={item.name}
                                        rules={item.question.rules}
                                    >
                                        <Select {...readOnlyProp} mode="tags" key={name}/>
                                    </Form.Item>
                                )
                            case 'arrayObject':
                                return (
                                    <Form.Item
                                        key={name}
                                        style={{marginBottom: 4}}
                                        name={name}
                                        label={item.name}
                                        rules={item.question.rules}
                                    >
                                    <Input {...readOnlyProp} />
                                    </Form.Item>
                                )
                        }
                        return <div key={index}
                                    style={{padding: 10, borderBottom: '1px solid #f0f0f0'}}>{item.question.type}</div>
                    })
                }
            </Form>
        )

}



export default QuestionTabs;