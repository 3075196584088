import React, {useContext, useEffect, useState} from 'react';
import {
    AccountBookOutlined,
    AreaChartOutlined,
    BankOutlined,
    BarChartOutlined,
    BlockOutlined,
    CalculatorOutlined,
    CheckSquareOutlined,
    DashboardOutlined,
    DesktopOutlined,
    DownloadOutlined,
    EditOutlined,
    EyeOutlined,
    FieldTimeOutlined,
    FileAddOutlined,
    FileOutlined,
    FileTextOutlined,
    FolderOutlined,
    MailOutlined,
    MergeCellsOutlined,
    PartitionOutlined, PlusOutlined,
    QuestionCircleOutlined,
    ScanOutlined,
    SearchOutlined,
    SendOutlined,
    SettingOutlined,
    ToolOutlined,
    UnorderedListOutlined,
    UploadOutlined,
    UserAddOutlined,
    UserOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import {Badge, Button, ConfigProvider, Menu, Space} from 'antd';
import {Link, useLocation, useParams} from "react-router-dom";
import {categories, Mortgages} from "../../providers/MortgagesProvider";
import Search from "./Search";
import {Sidebar} from "../../providers/SidebarProvider";
import {AdminEmails} from "../../providers/AdminEmailsProvider";
import TasksBar from "./TasksBar";

function Badger({item, children}) {
    if (!item) {
        return children
    }
    return (
        <Badge className="badger" offset={[18, 0]} count={item.count} color={item.color}>
            {children}
        </Badge>
    );

}
function Content(props) {
    const mortgages = useContext(Mortgages)
    const adminEmails = useContext(AdminEmails)
    const sidebar = useContext(Sidebar)
    const params = useParams()
    const location = useLocation()
    const isClientPage = params.mortgageId || false
    const [state, setState] = useState({
        activeDashboardKey: 'dashboard',
        countEmails: 0
    })
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    const getDashboardItems = () => {
        const dashboardItems = []
        dashboardItems.push(getItem(<Link to='/'>Dashboard</Link>, '/', <DashboardOutlined/>))
        dashboardItems.push(getItem(<Link to={`/stats`}>Statistics</Link>, '/stats', <BarChartOutlined/>))
        dashboardItems.push(getItem(<Link to={`/client-bars`}>Client Bars</Link>, '/client-bars', <AreaChartOutlined/>))
        dashboardItems.push(
            getItem(
                <Link to='/emails'>
                    <Badger item={{color: 'orange', count: state.countEmails}}>
                        Emails
                    </Badger>
                </Link>,
                '/emails',
                <MailOutlined/>
            )
        )
        dashboardItems.push(getItem('Clients', 'clients', <UserOutlined/>, categories.map(category => {
                return getItem(
                    <Link to={`/clients/${category.to}`}>
                        <Badger item={{color: category.color, count: mortgages[category.key].length || 0}}>
                            {category.label}
                        </Badger>
                    </Link>,
                    category.key)
            }
        )))
        dashboardItems.push(getItem(<Link to={`/lenders`}>Lenders</Link>, '/lenders', <BankOutlined/>))
        dashboardItems.push(getItem(<Link to={`/create-offline-mortgage`}>Create New
            Mortgage</Link>, '/create-offline-mortgage', <UserAddOutlined/>))

        dashboardItems.push(getItem(<Link to={'/create-definitions'}>Create Table Defs</Link>, '/create-definitions',
            <UnorderedListOutlined/>))
        dashboardItems.push(getItem('My Settings', 'my-settings', <UserOutlined/>, [
            getItem(<Link to={`/my-settings/timeslots`}>Timeslots</Link>, 'my-settings.timeslots', <FieldTimeOutlined/>)

        ]))
        dashboardItems.push(getItem(<Link to={`/pending-uploads`}>Uploads Pending</Link>, '/pending-uploads', <UploadOutlined/>))
        dashboardItems.push(getItem(<Link to={`/recognise`}>PDF Recognise</Link>, '/recognise', <EyeOutlined/>))
        return dashboardItems
    }
    const getClientItems = () => {
        let root = `/client/${params.mortgageId}`
        const clientItems = []
        clientItems.push(getItem(<Link to={`${root}/application-forms`}>Application Data</Link>, 'clientForms',
            <AccountBookOutlined/>))
        /*
        clientItems.push(getItem('Setup', 'clientSetup', <UserOutlined/>, [
            getItem(<Link to={`${root}/overview`}>Initial Form</Link>, 'clientSetup.initial', <CheckSquareOutlined/>),
            getItem(<Link to={`${root}/lender-calculators`}>Lender Tests</Link>, 'clientSetup.test', <PartitionOutlined/>),
        ]))
*/
        clientItems.push(getItem('Stages', 'stages', <FieldTimeOutlined/>, [
            getItem(<Link to={`${root}/setup-management`}>On-Boarding</Link>, 'stages.on-boarding', <BlockOutlined/>),
            getItem(<Link to={`${root}/apply-management`}>Apply</Link>, 'stages.apply', <BlockOutlined/>),
            getItem(<Link to={`${root}/sign-management`}>Signing</Link>, 'stages.sign', <BlockOutlined/>),
            getItem(<Link to={`${root}/pre-approval-management`}>Pre-Approval</Link>, 'stages.pre-approval',
                <BlockOutlined/>),
            getItem(<Link to={`${root}/approval-management`}>Approval</Link>, 'stages.approval', <BlockOutlined/>),
        ]))
        clientItems.push(getItem(<Link
                to={`${root}/application-documents`}>Documents</Link>, 'clientApplication.CustomerDocuments',
            <FolderOutlined/>))
        /*
         clientItems.push(getItem('Documents', 'clientDocuments', <FileTextOutlined/>, [
             getItem(<Link to={`${root}/document-bundling`}>Bundling</Link>, 'clientDocuments.Bundle', <MergeCellsOutlined/>),
             getItem(<Link to={`${root}/document-creation`}>Create</Link>, 'clientDocuments.Create', <FileAddOutlined/>)
         ]))

         */
        clientItems.push(getItem(<Link to={`${root}/new-client-view`}>Client View</Link>, 'clientTools.newClientView', <DesktopOutlined/>))
        clientItems.push(getItem('Tools', 'clientTools', <ToolOutlined/>, [
            getItem(<Link to={`${root}/create-new-mortgage`}>Start New Mortgage</Link>, 'clientTools.startNew', <PlusOutlined/>)
        ]))
        clientItems.push(
            getItem(
                <Link to={`${root}/emails`}>
                    <Badger item={sidebar?.badges?.emails}> Emails </Badger>
                </Link>,
                'clientEmails',
                <MailOutlined/>
            )
        )
        clientItems.push(getItem(
            <Link to={`${root}/notes`}>
                <Badger item={sidebar?.badges?.notes}>Notes</Badger>
            </Link>, 'clientNotes', <EditOutlined/>))
        clientItems.push(getItem(
            <Link to={`${root}/tasks`}>
                <Badger item={sidebar?.badges?.tasks}> Tasks </Badger>
            </Link>, 'clientTasks', <CheckSquareOutlined/>))
        clientItems.push(getItem(<Link to={`${root}/settings`}>Settings</Link>, 'clientSettings', <SettingOutlined/>))
        clientItems.push(getItem(<Link to={`${root}/prep`}>Checklist</Link>, 'Checklist', <FileOutlined/>))
        return clientItems
    }
    const dashboardItems = getDashboardItems()
    const clientItems = getClientItems()

    const handleClientClick = (e) => {
        setState(s => ({...s, activeClientKey: e.key}))
    }

    useEffect(() => {
        if (params.mortgageId) {
            setState(s => {
                return {
                    ...s,
                    activeDashboardKey: null,
                }
            })
        } else {
            setState(s => ({
                ...s,
                activeDashboardKey: params?.categoryType ? categories.find(obj => obj.to === params.categoryType).key : location.pathname,
            }))
        }
    }, [params]);
    useEffect(() => {
        let emails = (adminEmails.items || []).filter(email => email.actionedBy === 'null' && !!email.isReceived)
        setState(s => ({
            ...s,
            countEmails: emails.length
        }))
    }, [adminEmails])
    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemSelectedBg: 'rgb(133,185,180)',
                        itemSelectedColor: 'white',
                    },
                },
            }}
        >
            <Search/>
            <TasksBar clientId={isClientPage}/>


            <div className={`mt-6 sidebar-panel-switcher ${isClientPage ? 'on-client' : ''}`}>
                <div className="d-row j-evenly" style={{minHeight: 'calc(100vh - 168px)'}}>
                    <Menu
                        selectedKeys={[state.activeDashboardKey]}
                        defaultOpenKeys={['dashboard']}
                        mode="inline"
                        items={dashboardItems}
                    />
                    <Menu
                        //onClick={handleClientClick}
                        key={isClientPage ? `sidebar-menu-${sidebar.id}` : `sidebar-client`}
                        selectedKeys={[isClientPage ? [sidebar.clientKey] : []]}
                        defaultOpenKeys={['stages']}
                        mode="inline"
                        items={clientItems}
                    />
                </div>
            </div>

        </ConfigProvider>
    );
}

export default Content;