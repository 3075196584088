import dayjs from "dayjs";
import {addBunchToMap} from "../overlay-utils";

const elements = [
    {
        "name": "app1-name",
        "type": "TEXT"
    },
    {
        "name": "app2-name",
        "type": "TEXT"
    },
    {
        "name": "date",
        "type": "TEXT"
    },
    {
        "name": "mortgage-ac-number",
        "type": "TEXT"
    }
]
export default function (mortgage, config) {
    const applicantNames = () => {
        let map = {}
        let errors=[]
        let app1Name = mortgage.applicant1.fullName
        if (!app1Name) errors.push('No applicant 1 name')
        map['app1-name'] = app1Name
        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.fullName
            if (!app2Name) errors.push('No applicant 2 name')
            map['app2-name'] = app2Name
        }
        return {map, errors}
    }
    const todaysDate = () => {
        let map = {}
        let str = config.hasOwnProperty('todaysDate') ? config.todaysDate : dayjs().format('DD/MM/YYYY')
        map['date'] = str
        return {map}
    }

    const mortgageAccountNumber = () => {
        let map = {}
        let errors=[]
        let str = config.hasOwnProperty('mortgageAccountNumber') ? config.mortgageAccountNumber : mortgage.requirement.mortgageAccountNo
        if (str){
            map['mortgage-ac-number'] = str
        }
        else{
            errors.push('No mortgage account number')
        }
        return {map}
    }
    const sections = [
        applicantNames(),
        todaysDate(),
        mortgageAccountNumber(),
    ]
    return {sections}
}