import React from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import MortgageForm from "../../../../data-capture/MortgageForm";
import {Alert, Button, Card} from "antd";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {getQuestionsPercentCompleted} from "../../../../../providers/mortgage/useApplicationForms";
import useCustomerPreApprovalDocuments from "../useCustomerPreApprovalDocuments";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import CustomUploadModal from "../../_common/uploads/custom-upload/CustomUploadModal";
import AipReceivedButton from "../../_common/email-buttons/received/AipReceivedButton";
import EmailButton from "../../_common/email-buttons/EmailButton";
import ButtonAsync from "../../../../elements/ButtonAsync";
//import useFoundAPropertyEmail from "../../../../../../config-files/mortgage/emails/useFoundAPropertyEmail";

function Details(props) {
    const mortgage = useMortgage()


    const {open, copyToSubmissionFolder} = useMortgageDocumentsService()

   // const properFoundEmailChain = useFoundAPropertyEmail()
    let questions = []
    let doc, percent
    if (mortgage.activeSubmission){
        questions = [
            {
                name: 'mortgageAccountNo',
                label: 'Mortgage Account Number',
                text: "Account Number",
                answer: {
                    type: "text",
                    copyTarget: 'caseNumber'
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].mortgageAccountNo`
            },
            {
                name: 'aipDate',
                label: 'Approval in Principle Date',
                text: "Approval in Principle Date",
                answer: {
                    type: "datepicker",
                    onChange: (form)=>{
                        let d = form.getFieldValue('aipDate')
                        let x = d.add(6, 'month')
                        form.setFieldValue('aipExpiryDate', x)

                    }
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].aipDate`
            },
            {
                name: 'aipExpiryDate',
                label: 'Approval in Principle Expiry',
                text: "Approval in Principle Expiry",
                answer: {
                    type: "datepicker",
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].aipExpiryDate`
            },
            {
                name: 'aipMaxLoan',
                label: 'Max Loan',
                text: "Max Loan",
                answer: {
                    type: "euro",
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].aipMaxLoan`

            },
            {
                name: 'aipNotes',
                label: 'Notes',
                text: "Notes",
                answer: {
                    type: "textarea",
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].aipNotes`
            }
        ]
        if (['avant','ptsb'].includes(mortgage.activeSubmission.lenderCode)){
            questions.push( {
                name: 'aipPropertyReference',
                label: 'Property Reference',
                text: "Property Reference",
                answer: {
                    type: "text",
                },
                model: "requirement",
                rules: [],
                target: `submissions[${mortgage.activeSubmission.id}].aipPropertyReference`
            })
        }
        doc = mortgage.UserMortgageDocuments.find(doc => doc.name === 'approval-in-principle' && doc.folder === 'lender-documents' && doc.submissionID === mortgage.activeSubmission.id)
        percent = getQuestionsPercentCompleted(mortgage, questions)
    }




    let docsToDownloadAndSign = useCustomerPreApprovalDocuments().filter(i=>i.folder !== 'lender-documents')

    if (!mortgage.activeSubmission) return null
    return (
        <Card>
            <div className="d-col gap-15">

                {
                    !doc && (<Alert  message={"Approval in principle not received"} type="warning"/>)
                }

                {
                    !!doc && (<Alert  message={<>
                        Approval in principle received
                        <a className="mx-15" onClick={() => open(doc)}>View Document</a>
                        <a className="mx-15" onClick={() => {
                            open({...doc, submissionID: null})
                        }}>Not there? Try here</a>
                        {mortgage.activeSubmission?.id && (
                        <ButtonAsync onClick={() => {
                            copyToSubmissionFolder(doc)
                        }}>If there - Correct It</ButtonAsync> )}
                    </>} type="success"/>)}
                {percent === 100 && (<Alert message={"AIP details completed"} type="success"/>)}
                {(mortgage.activeSubmission.aipMaxLoan && mortgage.activeSubmission.provisionalLoan && mortgage.activeSubmission.aipMaxLoan < mortgage.activeSubmission.provisionalLoan) && (<Alert  message={`Loan requested (${mortgage.activeSubmission.provisionalLoan?.toEuro()}) is more that max. loan`} type="warning"/>)
                }


                <MortgageForm questions={questions} title="Mortgage Details"/>

                <Alert message={<>
                    Is there anything in the AIP for the customer to upload, apart from the pre-loaded?
                    <div className="d-row">


                        <ul>
                            <li>Payslips</li>
                            <li>Bank Statements</li>
                            <li>Evidence of deposit funds</li>
                            <li>Booking deposit receipt</li>
                        </ul>

                        <ul>
                            {docsToDownloadAndSign.map(doc => {
                                    return (
                                        <li key={doc.key}>
                                            {capitaliseWords(doc.name.replace(/-/g, ' '))}
                                        </li>
                                    )
                                }
                            )}

                        </ul>

                    </div>

                </>} type="info"/>
                <div className="d-row j-end gap-3">
                    <div className="me-auto">
                        <CustomUploadModal applicant={false}/>
                    </div>

                    {/*<EmailButton emailChain={properFoundEmailChain} stage={"PREAPPROVAL"}/>*/}
                    <AipReceivedButton />
                </div>



            </div>

        </Card>




    )
}

export default Details;