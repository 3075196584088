import {addBunchToMap} from "../overlay-utils";

const elements = [
    {
        "name": "acccount-number-a-1",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-2",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-3",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-4",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-5",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-6",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-7",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-a-8",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-1",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-2",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-3",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-4",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-5",
        "type": "TEXT"
    },
    {
        "name": "acccount-number-s-6",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-act-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-declaration-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-health-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-3",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-4",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-5",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-6",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-7",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-ppsn-8",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-act-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-declaration-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-d-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-d-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-m-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-m-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-y-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-health-sign-y-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-2",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-3",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-4",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-5",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-6",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-7",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-ppsn-8",
        "type": "TEXT"
    },
    {
        "name": "applicant-name-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-name-2",
        "type": "TEXT"
    },
    {
        "name": "known-circumstances-change-details",
        "type": "TEXT"
    },
    {
        "name": "known-circumstances-change-yes",
        "type": "CHECK"
    },
    {
        "name": "known-circumstances-change-yes",
        "type": "CHECK"
    },
    {
        "name": "loan-required",
        "type": "TEXT"
    },
    {
        "name": "mortgage-type",
        "type": "TEXT"
    },
    {
        "name": "property-address-1",
        "type": "TEXT"
    },
    {
        "name": "property-address-2",
        "type": "TEXT"
    },
    {
        "name": "property-address-3",
        "type": "TEXT"
    },
    {
        "name": "property-value",
        "type": "TEXT"
    },
    {
        "name": "term-required",
        "type": "TEXT"
    }
]
function splitIntoThree(arr) {
    // Determine the total number of elements
    const totalElements = arr.length;

    // Calculate the length of each sub-array
    const subArrayLength = Math.ceil(totalElements / 3);

    // Initialize the result array
    let result = [];

    // Iterate and split the array into three sub-arrays
    for (let i = 0; i < 3; i++) {
        // Calculate the start and end index for slicing
        let start = i * subArrayLength;
        let end = start + subArrayLength;

        // Slice the array and add to the result
        result.push(arr.slice(start, Math.min(end, totalElements)));
    }

    // Filter out any empty arrays
    return result.filter(subArray => subArray.length > 0);
}
export default function (mortgage, config) {
    const applicantNames = () => {
        let map = {}
        let errors=[]
        let app1Name = mortgage.applicant1.fullName
        if (!app1Name) errors.push('No applicant 1 name')
        map['applicant-name-1'] = app1Name
        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.fullName
            if (!app2Name) errors.push('No applicant 2 name')
            map['applicant-name-2'] = app2Name
        }
        return {map, errors}
    }
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let errors=[]
        const digits = mortgage.requirement.mortgageAccountNo
        if (!digits) errors.push('No mortgage account number')
        else{
            addBunchToMap(map, 'acccount-number-a-', digits.split(''))

        }
        return {map, errors}
    }
    const mortgageLoanSortCode = () => {
        let map = {}
        let ask = []
        ask.push({
            label: 'Mortgage Account Sort Code',
            name: 'mortgageLoanSortCode',
            type: 'TEXT',
            group: 'DOCUMENT CONFIG'
        })
        const digits = config.mortgageLoanSortCode
        if (digits){
            addBunchToMap(map, 'acccount-number-s-', digits.split(''))

        }
        return {map, ask}
    }
    const addressOfProperty = () => {
        let map = {}
        let address = mortgage.properties[0].address
        if( address){
            let parts = address.split(',').map(it=>it.trim())
            let rows = splitIntoThree(parts)
            rows.forEach((row,i)=>{
                map[`property-address-${i+1}`] = rows[i].join(', ')
            })
        }
        return {map}
    }

    const applicantPpsn = ()=>{
        let map = {}
        let errors=[]
        let digits = mortgage.applicant1.tax.ppsNumber?.toUpperCase()
        if( !digits){
            errors.push('No applicant 1 PPSN')
        }
        addBunchToMap(map, 'applicant-1-ppsn-', digits.split(''))
        if (mortgage.twoApplicants){
            digits = mortgage.applicant2.tax.ppsNumber?.toUpperCase()
            if( !digits){
                errors.push('No applicant 2 PPSN')
            }
            addBunchToMap(map, 'applicant-2-ppsn-', digits.split(''))
        }
        return {map, errors}
    }
    const valueOfProperty = ()=>{
        let map = {}
        let errors=[]
        let amount = mortgage.properties[0].propertyValue
        if (!amount){
            errors.push('Missing Property Value')
        }
        map['property-value'] = amount.toLocaleString('en-US')
        return {map, errors}
    }
    const mortgageType = ()=>{
        let map = {}
        let errors=[]
        let type = mortgage.mortgageType
        if (!type){
            errors.push('Missing Mortgage Type')
        }
        map['mortgage-type'] = type
        return {map, errors}
    }
    const loanRequired = ()=>{
        let map = {}
        let errors=[]
        let amount = mortgage.requirement.loanRequired
        if (!amount){
            errors.push('Missing Property Value')
        }
        map['loan-required'] = amount.toLocaleString('en-US')
        return {map, errors}
    }
    const repaymentTerm  = ()=>{
        let map = {}
        let errors=[]
        let amount = mortgage.requirement.loanTerm
        if (!amount){
            errors.push('No Loan Term')
        }
        map['term-required'] = amount + " YEARS"
        return {map, errors}
    }
    const futureChangedInCircumstances = ()=>{
        let map = {}
        let ask = []
        ask.push({
            label: 'Future change in circumstances',
            name: 'futureChangedInCircumstances',
            type: 'CHECK',
            group: 'DOCUMENT CONFIG'
        })
        ask.push({
            label: 'Details',
            name: 'knownCircumstancesChangeDetails',
            type: 'TEXTAREA',
            group: 'DOCUMENT CONFIG'
        })
        const checked = !!config.futureChangedInCircumstances
        map['known-circumstances-change-yes'] = checked
        map['known-circumstances-change-no'] = !checked
        if (checked){
            map['known-circumstances-change-details'] = config.knownCircumstancesChangeDetails
        }
        return {map, ask}
    }
    const sections =[
        applicantNames(),
        mortgageLoanAccountNumber(),
        mortgageLoanSortCode(),
        addressOfProperty(),
        applicantPpsn(),
        valueOfProperty(),
        mortgageType(),
        loanRequired(),
        mortgageType(),
        repaymentTerm(),
        futureChangedInCircumstances()
        ]
    return {sections}
}