import {addBunchToMap} from "../overlay-utils";

const elements = [
    {
        "name": "account-1",
        "type": "TEXT"
    },
    {
        "name": "account-2",
        "type": "TEXT"
    },
    {
        "name": "account-3",
        "type": "TEXT"
    },
    {
        "name": "account-4",
        "type": "TEXT"
    },
    {
        "name": "account-5",
        "type": "TEXT"
    },
    {
        "name": "account-6",
        "type": "TEXT"
    },
    {
        "name": "account-7",
        "type": "TEXT"
    },
    {
        "name": "account-8",
        "type": "TEXT"
    },
    {
        "name": "account-9",
        "type": "TEXT"
    },
    {
        "name": "city-1",
        "type": "TEXT"
    },
    {
        "name": "city-10",
        "type": "TEXT"
    },
    {
        "name": "city-11",
        "type": "TEXT"
    },
    {
        "name": "city-12",
        "type": "TEXT"
    },
    {
        "name": "city-2",
        "type": "TEXT"
    },
    {
        "name": "city-3",
        "type": "TEXT"
    },
    {
        "name": "city-4",
        "type": "TEXT"
    },
    {
        "name": "city-5",
        "type": "TEXT"
    },
    {
        "name": "city-6",
        "type": "TEXT"
    },
    {
        "name": "city-7",
        "type": "TEXT"
    },
    {
        "name": "city-8",
        "type": "TEXT"
    },
    {
        "name": "city-9",
        "type": "TEXT"
    },
    {
        "name": "country-1",
        "type": "TEXT"
    },
    {
        "name": "country-10",
        "type": "TEXT"
    },
    {
        "name": "country-11",
        "type": "TEXT"
    },
    {
        "name": "country-12",
        "type": "TEXT"
    },
    {
        "name": "country-2",
        "type": "TEXT"
    },
    {
        "name": "country-3",
        "type": "TEXT"
    },
    {
        "name": "country-4",
        "type": "TEXT"
    },
    {
        "name": "country-5",
        "type": "TEXT"
    },
    {
        "name": "country-6",
        "type": "TEXT"
    },
    {
        "name": "country-7",
        "type": "TEXT"
    },
    {
        "name": "country-8",
        "type": "TEXT"
    },
    {
        "name": "country-9",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-1",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-10",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-11",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-12",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-13",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-14",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-15",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-16",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-17",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-18",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-19",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-2",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-20",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-21",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-22",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-23",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-24",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-3",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-4",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-5",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-6",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-7",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-8",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-1-9",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-1",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-10",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-11",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-12",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-13",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-14",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-15",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-16",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-17",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-18",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-19",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-2",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-20",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-21",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-22",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-23",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-24",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-3",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-4",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-5",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-6",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-7",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-8",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-2-9",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-1",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-10",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-11",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-12",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-13",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-14",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-15",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-16",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-17",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-18",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-19",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-2",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-20",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-21",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-22",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-23",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-24",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-3",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-4",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-5",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-6",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-7",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-8",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-3-9",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-1",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-10",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-11",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-12",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-13",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-14",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-15",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-16",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-17",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-18",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-19",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-2",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-20",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-21",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-22",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-23",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-24",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-3",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-4",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-5",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-6",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-7",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-8",
        "type": "TEXT"
    },
    {
        "name": "debtor-address-4-9",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-1",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-10",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-11",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-12",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-13",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-14",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-15",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-16",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-17",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-18",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-19",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-2",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-20",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-21",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-22",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-23",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-24",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-3",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-4",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-5",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-6",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-7",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-8",
        "type": "TEXT"
    },
    {
        "name": "debtor-name-9",
        "type": "TEXT"
    },
    {
        "name": "iban-1",
        "type": "TEXT"
    },
    {
        "name": "iban-10",
        "type": "TEXT"
    },
    {
        "name": "iban-11",
        "type": "TEXT"
    },
    {
        "name": "iban-12",
        "type": "TEXT"
    },
    {
        "name": "iban-13",
        "type": "TEXT"
    },
    {
        "name": "iban-14",
        "type": "TEXT"
    },
    {
        "name": "iban-15",
        "type": "TEXT"
    },
    {
        "name": "iban-16",
        "type": "TEXT"
    },
    {
        "name": "iban-17",
        "type": "TEXT"
    },
    {
        "name": "iban-18",
        "type": "TEXT"
    },
    {
        "name": "iban-19",
        "type": "TEXT"
    },
    {
        "name": "iban-2",
        "type": "TEXT"
    },
    {
        "name": "iban-20",
        "type": "TEXT"
    },
    {
        "name": "iban-21",
        "type": "TEXT"
    },
    {
        "name": "iban-22",
        "type": "TEXT"
    },
    {
        "name": "iban-23",
        "type": "TEXT"
    },
    {
        "name": "iban-24",
        "type": "TEXT"
    },
    {
        "name": "iban-3",
        "type": "TEXT"
    },
    {
        "name": "iban-4",
        "type": "TEXT"
    },
    {
        "name": "iban-5",
        "type": "TEXT"
    },
    {
        "name": "iban-6",
        "type": "TEXT"
    },
    {
        "name": "iban-7",
        "type": "TEXT"
    },
    {
        "name": "iban-8",
        "type": "TEXT"
    },
    {
        "name": "iban-9",
        "type": "TEXT"
    },
    {
        "name": "post-code-1",
        "type": "TEXT"
    },
    {
        "name": "post-code-10",
        "type": "TEXT"
    },
    {
        "name": "post-code-11",
        "type": "TEXT"
    },
    {
        "name": "post-code-12",
        "type": "TEXT"
    },
    {
        "name": "post-code-2",
        "type": "TEXT"
    },
    {
        "name": "post-code-3",
        "type": "TEXT"
    },
    {
        "name": "post-code-4",
        "type": "TEXT"
    },
    {
        "name": "post-code-5",
        "type": "TEXT"
    },
    {
        "name": "post-code-6",
        "type": "TEXT"
    },
    {
        "name": "post-code-7",
        "type": "TEXT"
    },
    {
        "name": "post-code-8",
        "type": "TEXT"
    },
    {
        "name": "post-code-9",
        "type": "TEXT"
    }
]

export default function (mortgage, config) {
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let errors = []
        const digits =  mortgage.requirement.mortgageAccountNo
        if (!digits){
            errors.push('No mortgage loan account number')
        }
        else {
            addBunchToMap(map, 'account-', digits.toUpperCase().split(''))
        }
        return {map}
    }
    const addressLine1 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 1',
            name: 'addressLine1',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine1')) {
            errors.push('No address line 1')
        } else {
            addBunchToMap(map, 'debtor-address-1-', config.addressLine1.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const addressLine2 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 2',
            name: 'addressLine2',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine2')) {
            //errors.push('No address line 2')
        } else {
            addBunchToMap(map, 'debtor-address-2-', config.addressLine2.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const addressLine3 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 3',
            name: 'addressLine3',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine3')) {
            //errors.push('No address line 3')
        } else {
            addBunchToMap(map, 'debtor-address-3-', config.addressLine3.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const addressLine4 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 4',
            name: 'addressLine4',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine4')) {
            //errors.push('No address line 4')
        } else {
            addBunchToMap(map, 'debtor-address-4-', config.addressLine4.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const city = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'City',
            name: 'city',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('city')) {
            errors.push('No city')
        } else {
            addBunchToMap(map, 'city-', config.city.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const country = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Country',
            name: 'country',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('country')) {
            errors.push('No country')
        } else {
            addBunchToMap(map, 'country-', config.country.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const debtorName = () => {
        let map = {}
        let ask = []
        let errors = []
        // let account = mortgage.applicant1.accounts.find(a=>a.id === mortgage.requirement.ddBankAccountID)
        // if (!account){
        //     account = mortgage.applicant1.accounts.find(a=>a.id === mortgage.requirement.bankAccountID)
        // }
        // if (!account){
        //     errors.push('Is back account set?')
        // }
        // else {
        //     addBunchToMap(map, 'account-', account.accountNumber.toUpperCase().split(''))
        // }


        ask.push({
            label: 'Debtor Name',
            name: 'debtorName',
            type: 'TEXT',
            group: 'DEBTOR NAME'
        })
        if (!config.hasOwnProperty('debtorName')) {
            errors.push('No debtor name')
        } else {
            addBunchToMap(map, 'debtor-name-', config.debtorName.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const iban = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'IBAN',
            name: 'iban',
            type: 'TEXT',
            group: 'IBAN'
        })
        if (!config.hasOwnProperty('iban')) {
            errors.push('No IBAN')
        } else {
            addBunchToMap(map, 'iban-', config.iban.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }
    const postCode = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Post Code',
            name: 'postCode',
            type: 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('postCode')) {
            errors.push('No post code')
        } else {
            addBunchToMap(map, 'post-code-', config.postCode.toUpperCase().split(''))
        }
        return {map, ask, errors}
    }

    const sections = [
        mortgageLoanAccountNumber(),
        debtorName(),
        addressLine1(),
        addressLine2(),
        addressLine3(),
        addressLine4(),
        city(),
        postCode(),
        country(),
        iban()
    ]

    return {sections}
}
