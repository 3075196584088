import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Select} from "antd";
import {LoadingOutlined,  QuestionCircleOutlined} from "@ant-design/icons";
import {createRate, deleteRate, updateRate} from "../../api-functions/lenders";
import {red} from "@ant-design/colors";

LenderRateEditor.propTypes = {
    rate: PropTypes.object.isRequired,
    lender: PropTypes.object.isRequired
};

function LenderRateEditor(props) {
    const [state, setState] = useState({open: true, saving: false, deleting: false})
    const [form] = Form.useForm()
    const onClose = () => {
        setState(s => ({...s, open: false}))
    }
    const promotions = (!!props.lender.defaultPromotions) ? props.lender.defaultPromotions.map(j => JSON.parse(j)) : false
    const conditions = (!!props.lender.defaultConditions) ? props.lender.defaultConditions.map(j => JSON.parse(j)) : false

    const handleFinish = async () => {
        const valid = await form.validateFields()
        setState(s=>({...s,saving: true}))
        if (valid?.promotions){
            valid.promotions = [...new Set(valid.promotions)]
        }
        if (valid?.conditions){
            valid.conditions = [...new Set(valid.conditions)]
        }
        if (props.rate?.id){

           const result = await updateRate({...valid, id: props.rate.id, _version: props.rate._version})

            props.updateRate(result)
            onClose()
        }
        else{
            await createRate({
                ...valid,
                lenderID: props.lender.id,
                lenderCode: props.lender.lenderCode
            },props.setRates)
            onClose()
        }
    }

    const handleDelete = async ()=>{
        setState(s=>({...s,deleting: true}))
        await deleteRate(props.rate.id, props.rate._version)
        onClose()
        props.removeRate(props.rate.id)

    }

    const options = [
        {
            label: "First Time Buyer",
            value: "FTB",
        },
        {
            label: "Home Mover",
            value: "MOVER",
        },
        {
            label: "Remortgage",
            value: "SWITCHER",
        },
        {
            label: "Buy To Let",
            value: "BTL",
        },
    ];

    return (
        <Modal afterClose={props.onModalClose} width={900} onCancel={onClose} open={state.open} title="Edit Rate" footer={null}>
            <Form requiredMark={false} validateTrigger="onBlur"  initialValues={props.rate} form={form} layout="horizontal"
                  onFinish={handleFinish}>
                <Form.Item
                    label="Product"
                    name="product"
                    style={{
                        width: '100%',
                    }}
                    rules={[
                        {required: true, message: `Product Required`},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Restricted to"
                    name="forBorrowerTypes"
                >
                    <Select
                        mode="multiple"
                        style={{
                            width: '100%',
                        }}
                        placeholder="select Boowwer Type Restrictions"
                        options={options}
                    />
                </Form.Item>
                <Row justify="start" gutter={15}>
                    <Col>

                        <Form.Item
                            label="Rate %"
                            name="rate"
                            rules={[
                                {required: true, message: `Rate required`},
                                {type: 'number', min: 1, message: "Minimum 1"},
                                {type: 'number', max: 20, message: "Maximum 20"},
                                {
                                    pattern: /^\d+(\.\d{1,2})?$/,
                                    message: 'Rate must have up to two decimal places',
                                }
                            ]}
                        >
                            <InputNumber precision={2}/>
                        </Form.Item>
                        <Form.Item
                            label="Maximum LTV"
                            name="maxLTV"
                            rules={[
                                {required: true, message: `LTV required`},
                                {type: 'number', min: 20, message: "Minimum 20"},
                                {type: 'number', max: 100, message: "Maximum 100"}
                            ]}
                        >
                            <InputNumber precision={0}/>
                        </Form.Item>
                        <Form.Item
                            label="Fixed Term"
                            name="term"
                            rules={[
                                {required: true, message: `Term required`},
                                {type: 'number', min: 0, message: "Minimum 0"},
                                {type: 'number', max: 35, message: "Maximum 35"}
                            ]}
                        >
                            <InputNumber precision={0}/>
                        </Form.Item>
                        <Form.Item
                            label="Minimum Loan"
                            name="minimum"
                            rules={[
                                {required: true, message: `Minimum Required`},
                                {type: 'number', min: 0, message: "Minimum 0"},
                                {type: 'number', max: 1000000, message: "Maximum 1000000"}
                            ]}
                        >
                            <InputNumber precision={0}/>
                        </Form.Item>
                        <Form.Item
                            label="Maximum Loan"
                            name="maximum"

                        >
                            <InputNumber precision={0}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Buy To Let"
                            name="buyToLet"
                            valuePropName="checked"
                        >
                            <Checkbox/>
                        </Form.Item>
                        <Form.Item
                            label="Interest Only"
                            name="interestOnly"
                            valuePropName="checked"
                        >
                            <Checkbox/>
                        </Form.Item>
                        {
                            !!promotions && (
                                <Form.Item
                                    name="promotions"
                                    label="Promotions"
                                >
                                    <Checkbox.Group style={{display: 'flex', flexDirection: 'column'}}>

                                        {
                                            promotions.map(item => <Checkbox key={item.key} size='large'
                                                                             value={item.key}>{item.key}</Checkbox>)

                                        }

                                    </Checkbox.Group>

                                </Form.Item>
                            )
                        }
                        {
                            !!conditions && (
                                <Form.Item
                                    name="conditions"
                                    label="Conditions"
                                >
                                    <Checkbox.Group style={{display: 'flex', flexDirection: 'column'}}>

                                        {
                                            conditions.map(item => <Checkbox key={item.key} size='large'
                                                                             value={item.key}>{item.key}</Checkbox>)

                                        }

                                    </Checkbox.Group>

                                </Form.Item>
                            )
                        }


                    </Col>
                </Row>

                <hr color={'#d9d9d9'} className="mb-15"/>
                <div className="d-row gap-15 j-end">
                    <Popconfirm
                        title="Certain you want to delete this rate?"
                        okText="Delete"
                        onConfirm={handleDelete}
                        icon={ <QuestionCircleOutlined style={{color: red.primary,}}/>}
                    >
                        <Button danger dashed className="me-auto"> Delete { state.deleting && <LoadingOutlined className="ms-6"/>} </Button>

                    </Popconfirm>

                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="primary" htmlType="submit">Save { state.saving && <LoadingOutlined className="ms-6"/>} </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default LenderRateEditor;