import React from 'react';
import useMortgage from "../../../providers/mortgage/useMortgage";
import {Card, Table, Typography} from "antd";
import PageHeader from "../../PageHeader";
import {
    ApplicationTypes,
    AssetTypes,
    BoolLike,
    DebtTypes,
} from "../../../../models";
import dayjs from "dayjs";
import countries from "../../../../assets/scripts/countries";
import {balanceOfFundsCosts} from "../application-stages/_common/funding/BalanceOfFunds";
import {
    claimedAverageMonthlySavings, getApplicantAccommodationCosts,
    getApplicantIncomeFromJobs, getCurrentAccountsDataSource, getSavingsDataSource
} from "../../../../assets/scripts/mortgage-figures";
import useApplicationUploads from "../../../providers/mortgage/useApplicationUploads";

//import {savingsDataSource} from "../../../../assets/scripts/verifiedFigures";
import {verifiedAccounts, verifiedInvestments} from "../application-stages/_common/funding/./verifiedAccounts";

function Prep(props) {
    const mortgage = useMortgage()
    //const {apply: sections} = useApplicationUploads()

    let sections = mortgage.mortgageUploads.apply
    let accountByCurrency = verifiedAccounts(mortgage, sections)
    let investmentsByCurrency = verifiedInvestments(mortgage, sections)
    console.log({ sections, accountByCurrency, investmentsByCurrency})
    if (!sections.length){
        return null
    }
    const makeDate = (date) => {
        return date ? dayjs(date, "YYYY-MM-DD").format("D MMM YYYY") : "Unknown"
    }
    const columns = [
        {
            title    : 'Name',
            dataIndex: 'name',
            key      : 'name',
            width    : '33%'
        },
        {
            title    : 'App 1',
            render   : (text, record) => {
                if (Array.isArray(text)) {
                    return text.map((item, i) => (<div key={item}>{item}</div>))
                }
                return text
            },
            dataIndex: 'app1',
            key      : 'app1',
            width    : mortgage.twoApplicants ? '34%' : '67%'
        }
    ];
    const legacy = !mortgage.applicant1.employmentIncome?.length || !mortgage.applicant1.employmentIncome[0].employmentType

    if (mortgage.twoApplicants) {
        columns.push({
            title    : 'App 2',
            render   : (text, record) => {
                if (Array.isArray(text)) {
                    return text.map((item, i) => (<div key={item}>{item}</div>))
                }
                return text
            },
            dataIndex: 'app2',
            key      : 'app2',
            width    : '33%'
        })
    }
    const Cards = ({sectionsObject}) => {
        let cardTitles = Object.keys(sectionsObject)
        return cardTitles.map((title, i) => {
            let questions = sectionsObject[title]

            let dataSource = questions.map((item, j) => {
                return {
                    key  : j,
                    name : item.name,
                    value: item.value
                }
            })
            return (
                <Card title={title}>
                    <Table size="small" dataSource={dataSource} columns={columns}
                           pagination={false} showHeader={false}/>
                </Card>
            )
        })

    }

    // Mortgage && Applicant Answers
    function mortgageProperty() {
        let property = mortgage.properties[0]
        let price = property.propertyValue
        let {stampDuty} = balanceOfFundsCosts(mortgage)
        return [
            {
                name : "Do you currently have a specific property in mind",
                value: property.addressKnown ? "Yes" : "No",
            },
            {
                name : "What is the estimated value of the property you are looking to buy",
                value: !property.addressKnown ? mortgage.properties[0].propertyValue : '',
            },
            {
                name : "What is the specific purchase price of this property",
                value: property.addressKnown ? mortgage.properties[0].propertyValue : '',
            },
            {
                name : "Stamp duty",
                value: stampDuty,
            },
            {
                name : "What is your target purchase date",
                value: "???",
            }
        ]
    }
    function appRegistration(applicant) {
        let probableDuplicates = (function () {
            let app1 = mortgage.applicant1.personal.countDependents
            let app2 = mortgage.applicant2.personal.countDependents
            return (app1 === app2) ? ' (Probable Duplicates)' : ''
        })()

        return [
            {
                name : "Title",
                value: applicant.personal.title
            },
            {
                name : "First Names",
                value: applicant.firstName
            },
            {
                name : "Surname",
                value: applicant.surname
            },
            {
                name : "Mobile Number",
                value: applicant.mobile
            },
            {
                name : "Email Address",
                value: applicant.email
            },
            {
                name : "Date of Birth",
                value: makeDate(applicant.personal.dateOfBirth)
            },
            {
                name : "Number of Dependents",
                value: applicant.personal.countDependents
            },
            {
                name : "Age of Dependents",
                value: (applicant.personal.dependentDOBs || []).map(dob => dayjs().diff(dayjs(dob, 'YYYY-MM-DD'), 'year')).join(", ") + probableDuplicates
            },
            {
                name : "Childcare Commitments",
                value: applicant.financial.hasChildcareCosts ? applicant.financial.monthlyChildcareCosts.toEuro() : "No",
            },
            {
                name : "Any Occupants Over 17",
                value: mortgage.properties[0].adultsBoarding ? "Yes" : "No",
            },
            {
                name : "First Time Buyer",
                value: mortgage.applicationType === ApplicationTypes.FIRSTTIME ? "Yes" : "No",
            },
        ]
    }
    function appPersonal(applicant) {
        let maritalStatus = ""
        switch (applicant.personal.maritalStatus) {
            case "SINGLE" :
                maritalStatus = "Single";
                break
            case "COHABITING":
                maritalStatus = "Single";
                break
            case "MARRIED":
                maritalStatus = "Married";
                break
            case "REMARRIED":
                maritalStatus = "Married";
                break
            case "SEPARATED":
                maritalStatus = "Separated";
                break
            case "DIVORCED":
                maritalStatus = "Divorced";
                break
            case "WIDOWED":
                maritalStatus = "Widowed";
                break
            default:
                maritalStatus = "Unknown"
        }
        let country = countries.find(country => country.alpha_2_code === applicant.personal.countryOfBirth)
        let countryOfBirth = country ? country.en_short_name : 'Unknown'

        country = countries.find(country => country.alpha_2_code === applicant.personal.countryOfResidence)
        let countryOfResidence = country ? country.en_short_name : 'Unknown'

        country = countries.find(country => country.alpha_2_code === applicant.personal.nationality)
        let nationality = country ? country.nationality : 'Unknown'
        return [
            {
                name : "Gender",
                value: applicant.isMale ? "Male" : "Female",
            },
            {
                name : "Marital Status",
                value: maritalStatus
            },
            {
                name : "Mother's Maiden Name",
                value: applicant.personal.mothersMaidenName
            },
            {
                name : "Country of Birth",
                value: countryOfBirth // if not ireland echo country
            },
            {
                name : "Nationality",
                value: nationality // if not irish echo country
            },
            {
                name : "Place of Birth ((Town/City)",
                value: applicant.personal.placeOfBirth
            },
            {
                name : "Country of Residence",
                value: countryOfResidence // if not ireland echo country
            },
            {
                name : "Current Address",
                value: (function () {
                    if (applicant.id === mortgage.applicant1.id) {
                        return applicant.personal.addressCurrent
                    }
                    if (mortgage.twoApplicants && applicant.id === mortgage.applicant2.id && mortgage.requirement.cohabiting) {
                        return mortgage.applicant1.personal.addressCurrent
                    }
                    return applicant.personal.addressCurrent
                })()
            },
            {
                name : "Moved In Date at Current Address",
                value: makeDate(applicant.personal.addressCurrentStartDate)
            },
            {
                name : "Previous Address",
                value: (function () {
                    if (applicant.personal.addressCurrentStartDate) {
                        if (dayjs().diff(dayjs(applicant.personal.addressCurrentStartDate, 'YYYY-MM-DD'), 'year') < 3) {
                            return applicant.personal.addressPrevious || "Unknown!"
                        }
                    }

                })()
            },
            {
                name : "Residency Status",
                value: applicant.personal.accommodationSituation
            },
            {
                name : "Monthly Rent/Mortgage Payment ??? talk about showing one payment or 2 payments",
                value: applicant.financial.monthlyAccommodationCosts // if not rent - field for mortgage payment
            },

        ]
    }
    function appIncome(applicant) {
        let employment = legacy ? applicant.employment : applicant.employmentIncome[0]

        let employmentStatus = ""
        switch (employment.employmentType) {
            case "EMPLOYED" :
                employmentStatus = "PAYE";
                break
            case "SELFEMPLOYED":
                employmentStatus = "SELF EMPLOYED"
            default:
                employmentStatus = "UNEMPLOYED"
        }

        let employedMoreThan3Years = false
        if (employment.currentEmployerStartDate) {
            let d = dayjs(employment.currentEmployerStartDate, 'YYYY-MM-DD')
            let diff = dayjs().diff(d, 'year')
            employedMoreThan3Years = diff >= 3
        }

        let salaried = applicant.accounts.find(it => it.salaried)
        let salariedInstitution = salaried ? salaried.institution : "Unknown"

        let rows = [
            {
                name : "PAYE, Self Employed or Unemployed",
                value: employmentStatus
            },
            {
                name : "Occupational Status",
                value: employment.employmentBasis
            },
            {
                name : "Occupation",
                value: employment.positionHeld
            },
            {
                name : "Current Employer Name",
                value: employment.currentEmployerName
            },
            {
                name : "Current Employer Phone Number",
                value: employment.currentEmployerPhone
            },
            {
                name : "Current Employer Address",
                value: employment.currentEmployerAddress
            },
            {
                name : "Current Employer Start Date",
                value: makeDate(employment.currentEmployerStartDate)
            },
            {
                name : "Previous Employment",
                value: employedMoreThan3Years ? "No" : "Yes" // only if current employment less than 3 years
            },
            {
                name : "Previous Employer Name",
                value: employment.previousEmployerName  // only if current employment less than 3 years
            },
            {
                name : "Previous Occupation",
                value: employment?.previousPositionHeld
            },
            {
                name : "Prev Employment Status",
                value: employment?.previousEmploymentBasis
            },
            {
                name : "Prev Employer Address",
                value: employment?.previousEmployerAddress  // only if current employment less than 3 years
            },
            {
                name : "Previous Employment Start Date",
                value: makeDate(employment.previousEmployerStartDate),  // only if current employment less than 3 years
            },
            {
                name : "Previous Employment End Date",
                value: makeDate(employment.previousEmployerEndDate)  // only if current employment less than 3 years
            },
        ]

        if (legacy) {
            rows.push({
                name : "Gross Basic Income ??? if not employed show  employment status like unemployed",
                value: applicant.income.grossBasic || employmentStatus
            })
        }
        else {
            rows.push({
                name : "Gross Basic Income ??? if not employed show  employment status like unemployed",
                value: employment.grossBasic || employmentStatus
            })
        }
        rows.push(
            {
                name : "Institution where Salary Paid",
                value: salariedInstitution
            },
            {
                name : "Main Source of Income",
                value: "Salary",
            }
        )
        if (legacy) {
            rows.push(
                {
                    name : "Annual Bonus",
                    value: applicant.income.incomeChecks?.includes('BONUS') ? applicant.income.bonus : 0 // echo her bonus, overtime, allowance etc
                },
                {
                    name : "Bonus Frequency",
                    value: "?? Yearly", //
                },
                {
                    name : "Overtime",
                    value: applicant.income.incomeChecks?.includes('OVERTIME') ? applicant.income.overtime : 0
                },
                {
                    name : "Commission",
                    value: applicant.income.incomeChecks?.includes('COMMISSION') ? applicant.income.commission : 0
                },
            )
        }
        else {
            let totBonus = 0
            let bonus = employment.incomes.find(a => a.name === "bonus")
            if (!!bonus && bonus.guaranteed) {
                totBonus += bonus.amount
            }

            let totOvertime = 0
            let overtime = employment.incomes.find(a => a.name === "overtime")
            if (!!overtime && overtime.guaranteed) {
                totOvertime += overtime.amount
            }

            let totCommission = 0
            let commission = employment.incomes.find(a => a.name === "commission")
            if (!!commission && commission.guaranteed) {
                totCommission += commission.amount
            }

            rows.push(
                {
                    name : "Annual Bonus (guaranteed only)",
                    value: totBonus
                },
                {
                    name : "Bonus Frequency",
                    value: "Yearly",
                },
                {
                    name : "Overtime (guaranteed only)",
                    value: totOvertime
                },
                {
                    name : "Commission (guaranteed only)",
                    value: totCommission
                },
            )
        }
        return rows
    }
    function appAssets(applicant) {
        let savings = applicant.assets.filter(it => it.assetType === AssetTypes.SAVING).sort((a, b) => a.balance - b.balance)
        let bestSaving = savings.length ? savings[0] : null

        let overdrafts = applicant.debts.filter(it => it.debtType === DebtTypes.OVERDRAFT).length

        let iban = false
        if (bestSaving) {
            let section = mortgage.progress.find(it => it.sectionType === 'UPLOAD' && it.sectionName === 'assets-1' && it.sectionIndex === bestSaving.id)
            if (section && section.metaData) {
                let data = JSON.parse(section.metaData)
                if (data.hasOwnProperty('iban')) {
                    iban = data.iban
                }
            }
        }

        return [
            {
                name : "Do you have any Property assets",
                value: applicant.properties.length > 0 ? "Yes" : "No"
            },
            {
                name : "If YES to property - Type of property",
                value: "???"
            },
            {
                name : "If YES to property - Property Address",
                value: "???"
            },
            {
                name : "If YES to property - Property Value",
                value: "???"
            },
            {
                name : "If YES to property - Rental Income",
                value: "???"
            },
            {
                name : "If YES to property - Outstanding Mortgage",
                value: "???"
            },
            {
                name : "If YES to property - Arrears",
                value: "???"
            },
            {
                name : "If YES to property - Property Description",
                value: "???"
            },
            {
                name : "If YES to property - Joint with Co-Applicant",
                value: "???"
            },
            {
                name : "Do you have any active credit cards? (Both single and shared)",
                value: applicant.debts.filter(d => d.debtType === DebtTypes.CREDITCARD).length > 0 ? "Yes" : "No"
            },
            {
                name : "If YES to Credit Cards (for each) - Lender",
                value: "???"
            },
            {
                name : "If YES to Credit Cards (for each) - Current Balance",
                value: "???"
            },
            {
                name : "If YES to Credit Cards (for each) - Credit Limit",
                value: "???"
            },
            {
                name : "If YES to Credit Cards (for each) - Account Number",
                value: "???"
            },
            {
                name : "If YES to Credit Cards (for each) - Plan to clear the Loan",
                value: "???"
            },
            {
                name : "If YES to Credit Cards (for each) - Is it joint with Co-Applicant",
                value: "???"
            },
            {
                name : "Do you have any personal loans? (Both single and shared)",
                value: applicant.debts.filter(d => d.debtType === DebtTypes.LOAN).length > 0 ? "Yes" : "No",
            },
            {
                name : "If YES to Personal Loans (for each) - Lender",
                value: "???"
            },
            {
                name : "If YES to Personal Loans (for each) - Current Balance",
                value: "???"
            },
            {
                name : "If YES to Personal Loans (for each) - Monthly Repayment",
                value: "???"
            },
            {
                name : "If YES to Personal Loans (for each) - Final Payment Date",
                value: "???"
            },
            {
                name : "If YES to Personal Loans (for each) - Plan to clear the Loan",
                value: "???"
            },
            {
                name : "If YES to Personal Loans (for each) - Is it joint with Co-Applicant",
                value: "???"
            },
            {
                name : "Do you have any other commitments? (Both single and shared)",
                value: "???",
            },
            {
                name : "Do you have any Savings or Investments? (Both single and shared)",
                value: applicant.assets.length > 0 ? "Yes" : "No",
            },
            {
                name : "Savings or investments",
                value: applicant.assets.map(asset => (`${asset.institution} ${asset.balance}`))
            },
            {
                name : "Savings Balance",
                value: bestSaving ? bestSaving.balance : 0,
            },
            {
                name : "Bank Name",
                value: bestSaving ? bestSaving.institution : '',
            },
            {
                name : "Bank IBAN",
                value: iban,
            },
            {
                name : "Savings Frequency",
                value: bestSaving ? bestSaving.increasesFrequency : '',
            },
            {
                name : "Regular Savings Amount",
                value: "???"
            },
            {
                name : "Joint Savings with with Co-Applicant",
                value: "??? Yes/No",
            },
            {
                name : "Do you have any Overdrafts? (Both single and shared)",
                value: overdrafts ? "Yes" : "No",
            },
            {
                name : "If YES to Overdraft (for each) - Lender",
                value: "???",
            },
            {
                name : "If YES to Overdraft (for each) - Current Balance",
                value: "??? ",
            },
            {
                name : "If YES to Overdraft (for each) - Account Number",
                value: "???",
            },
            {
                name : "If YES to Overdraft (for each) - Average Monthly Repayment",
                value: "???",
            },
            {
                name : "If YES to Overdraft (for each) - Plan to clear the Loan",
                value: "???",
            },
        ]
    }
    function appTax(applicant) {
        return [
            {
                name : "PPS Number",
                value: applicant.tax.ppsNumber
            },
            {
                name : "Tax Resident in Ireland",
                value: applicant.tax.irishTaxResident ? "Yes" : "No",
            },
            {
                name : "US Citizen",
                value: applicant.tax.usCitizen ? "Yes" : "No",
            },
            {
                name : "Previous Credit Agreement or Application to Lending Agency",
                value: "No",
            },
            {
                name : "Non-euro Supporting Assets",
                value: applicant.income.nonEuroCurrency ? "Yes" : "No",
            },
            {
                name : "Negative Credit Record",
                value: applicant.financial.negativeCredit ? "Yes" : "No",
            },
            {
                name : "Conviction Prosecutions",
                value: mortgage.needs.policeRecord ? "Yes" : "No",
            },
        ]
    }

    function appProperty(applicant) {
        return [
            {
                name : "Do you currently have a specific property in mind",
                value: mortgage.properties[0].addressKnown ? "Yes" : "No",
            },
            {
                name : "What is the estimated value of the property you are looking to buy",
                value: mortgage.properties[0].addressKnown ? mortgage.properties[0].propertyValue : '',
            },
            {
                name : "What is the specific purchase price of this property",
                value: !mortgage.properties[0].addressKnown ? mortgage.properties[0].propertyValue : '',
            },
            {
                name : "How much are you looking to borrow",
                value: mortgage.requirement.loanRequired.toEuro()
            },
            {
                name : "What is the source of your deposit",
                value: (function () {
                    let list = []
                    if (mortgage.requirement.receivingGifts) {
                        list.push("Gifts")
                    }
                    if (mortgage.requirement.helpToBuyScheme === BoolLike.TRUE) {
                        list.push("Help to Buy")
                    }
                    if (mortgage.requirement.depositSavings > 0) {
                        list.push("Own Savings")
                    }
                    return list.join(', ')
                })()
            },
            {
                name : "If Gift - echo Deposit amount from gift",
                value: mortgage.requirement.receivingGifts ? mortgage.requirement.depositGifts.toEuro() : ''
            },
            {
                name : "??? If Gift - echo Who is the donor?",
                value: "???"
            },
            {
                name : "??? If Help to Buy - echo Deposit amount from Help to Buy",
                value: mortgage.requirement.helpToBuyScheme === BoolLike.TRUE ? (mortgage.requirement.helpToBuySchemeAmount || 0).toEuro() : ''
            },
            {
                name : "??? If Inheritance - echo Deposit amount from inheritance",
                value: "???"
            },
            {
                name : "??? If Release of equity - echo Deposit amount from release of equity",
                value: "???"
            },
            {
                name : "??? If Sales and Shares - echo Deposit amount from shares",
                value: "???"
            },
            {
                name : "??? If Savings - echo amount",
                value: mortgage.figures.verified[`applicant${applicant}`]
            },
            {
                name : "??? If Savings - echo Deposit amount from savings",
                value: "???"
            },
            {
                name : "How long would you like the duration of the loan to be?  - echo Term",
                value: mortgage.requirement.loanTerm
            },

        ]
    }

    // Make Applicant Cards
    function applicantCards() {
        const makeDataSource = (answersFunction) => {
            let dataSource = []
            let answers1 = answersFunction(mortgage.applicant1)
            let answers2 = false
            if (mortgage.twoApplicants) {
                answers2 = answersFunction(mortgage.applicant2)
            }

            answers1.forEach((row, i) => {
                let source = {
                    key : i,
                    name: row.name,
                    app1: row.value,
                }
                if (mortgage.twoApplicants) {
                    source.app2 = answers2[i].value
                }

                dataSource.push(source)
            })

            return dataSource
        }
        return [
            {
                title     : "Registration Details",
                dataSource: makeDataSource(appRegistration)
            },
            {
                title     : "Personal Details",
                dataSource: makeDataSource(appPersonal)
            },
            {
                title     : "Income & Expenses",
                dataSource: makeDataSource(appIncome)
            },
            {
                title     : "Assets, Savings & Loans",
                dataSource: makeDataSource(appAssets)
            },
            {
                title     : "Tax & Credit",
                dataSource: makeDataSource(appTax)
            },
            {
                title     : "New Property Details",
                dataSource: makeDataSource(appProperty)
            },
        ]
    }

    const mSource = accountByCurrency['€']

    // let current = getCurrentAccountsDataSource(mortgage)
    // let savings = getSavingsDataSource(mortgage)
    //
    // let sSource = [...current,...savings] //savingsDataSource(mortgage, sections, true)
    //

    const statementDatasource = mSource.dataSource.filter(it=>!it.excluded).map((item, i) => {
        let range, monthsInRange, missingMonths

        if (item.range){
            monthsInRange = item.diffInMonths
            range = item.range
            //let parts2 = item.range.split(' - ')
           // let lastDate = dayjs(parts2[1].trim(), 'DD MMM YYYY')
            missingMonths = dayjs().diff(item.endDate, 'month')
        }
        else{
            range= 'Not supplied'
            monthsInRange = 0
            missingMonths = 0
        }

        return {
            key      : item.key,
            owner     : item.owner,
            account:    item.account,
            range,
            months: monthsInRange,
            missing  : missingMonths > 0 ? <span style={{fontWeight: 'bold', color: "red"}}>{missingMonths}</span> : ``,
            comment: ``,
            salaried: item.salaried,
            accommodationDD: item.accommodationDD,
            applicant : item.applicant,
        }
    })
    const mortgageDatasource = () => {
        let dataSource = []
        let answers = mortgageProperty()
        answers.forEach((row, i) => {
            let source = {
                key : i,
                name: row.name,
                app1: row.value,
                app2: ''
            }
            dataSource.push(source)
        })
        return dataSource
    }
    const overviewDatasource = () => {
        const grossBasicRows = (applicant) => {
            let jobs = getApplicantIncomeFromJobs(mortgage, applicant, sections)
            return jobs.flatMap(((job, i) => {
                console.log({job})
                    let diffSalaryCert = job.declared - job.salaryCertificateYearly
                    let diffPayslips = job.declared - job.payslipAverageGrossYearly
                    if (job.errors) {
                        return [
                            {
                                key        : `job-${applicant}-${i}-error`,
                                description: `Applicant ${applicant} ${job.basis || ''} Gross Salary`,
                                application: job.declared,
                                calculator: ``,
                                evidence   : ``,
                                difference : ``,
                                comment    : job.errors.map(e => <Typography.Text key={e} type="danger" className="d-block">{e}</Typography.Text>)
                            }
                        ]
                    }
                    let haveSalariedAccount  = statementDatasource.find(it => it.salaried && it.applicant === applicant)
                    return [
                        {
                            key        : `job-${applicant}-${i}-cert`,
                            description: `Applicant ${applicant} ${job.basis || ''} Gross Salary`,
                            application: job.declared,
                            evidence   : <div className="d-row">Salary Certificate: <span
                                className="ms-auto"> {job.salaryCertificateYearly} </span></div>,
                            calculator: ``,
                            difference : diffSalaryCert > 0 ? <span style={{color: "red"}}>- {diffSalaryCert}</span> : <span>+ {Math.abs(diffSalaryCert)}</span>,
                            comment: !haveSalariedAccount ? <Typography.Text type="danger" className="d-block">No Salaried Account</Typography.Text> : ``
                        },
                        {
                            key        : `job-${applicant}-${i}-payslips`,
                            description: ``,
                            application: ``,
                            evidence   : <div className="d-row">Payslip Average: <span className="ms-auto"> {job.payslipAverageGrossYearly} </span></div>,
                            calculator: ``,
                            difference : diffPayslips > 0 ? <span style={{color: "red"}}>- {diffPayslips}</span> : <span>+ {Math.abs(diffPayslips)}</span>,
                            comment:``
                        },
                    ]
                }
            ))
        }
        const savingsRows = (applicant) => {
            let [app1,app2] = claimedAverageMonthlySavings(mortgage)
            let appClaimed = applicant === 1 ? app1 : app2
           //let filtered = sections.filter(it => it.applicant === applicant)
            let averageMonthlySavings = mortgage.figures.verified[`savings${applicant}`].total

            //let {dataSource, averageMonthlySavings, totalClosingBalance,totalOpeningBalance,  totalTotalDifference, maxMonths} = verifiedAccounts(mortgage, filtered)
            //let {averageMonthlySavings} = savingsDataSource(mortgage, filtered)
            let diff = parseInt(appClaimed - averageMonthlySavings)
            return [
                {
                    key        : `savings-${applicant}`,
                    description: `Applicant ${applicant} Average Savings`,
                    application: appClaimed,
                    evidence   : averageMonthlySavings,
                    calculator: ``,
                    difference : diff > 0 ? <span style={{color: "red"}}>- {diff}</span> : <span>+ {Math.abs(diff)}</span>,
                    comment    : ``
                }
            ]
        }
        const accommodationRows = (applicant) => {
            const cost = getApplicantAccommodationCosts(mortgage, applicant)
            if (cost.errors){
                return [
                    {
                        key        : `accommodation-${applicant}`,
                        description: `Applicant ${applicant} Monthly Accommodation Costs`,
                        application: cost,
                        evidence   : ``,
                        calculator: ``,
                        difference : ``,
                        comment    : cost.errors.map(e => <Typography.Text type="danger">{e}</Typography.Text>)
                    }
                ]
            }
            let application = applicant===2 && mortgage.requirement.cohabiting ? 'Cohabiting': cost
            let haveAccommodationDD = statementDatasource.find(it => it.accommodationDD && it.applicant === applicant)
            return [
                {
                    key        : `accommodation-${applicant}`,
                    description: `Applicant ${applicant} Monthly Accommodation Costs`,
                    application,
                    evidence   : ``,
                    calculator: ``,
                    difference : ``,
                    comment    : !haveAccommodationDD ? <Typography.Text type="danger" className="d-block">No Accommodation DD</Typography.Text> : ``
                }
            ]
        }
        let items = []
        items.push(...grossBasicRows(1))
        items.push(...savingsRows(1))
        items.push(...accommodationRows(1))
        if (mortgage.twoApplicants) {
            items.push(...grossBasicRows(2))
            items.push(...savingsRows(2))
            items.push(...accommodationRows(2))
        }
        return items
    }
    const overviewCols = [
        {
            title    : '',
            dataIndex: 'description',
        },
        {
            title    : 'Application Form',
            dataIndex: 'application',
        },
        {
            title    : 'Evidence',
            dataIndex: 'evidence',
        },
        {
            title    : 'Difference',
            dataIndex: 'difference',
        },
        {
            title    : 'Lender Calculator',
            dataIndex: 'calculator',
        },
        {
            title    : 'Comment',
            dataIndex: 'comment',
        }
    ]

    const statementsCols=[
        {
            title    : 'Owner',
            dataIndex: 'owner',
            key      : 'owner',
        },
        {
            title    : 'Account',
            dataIndex: 'account',
            key      : 'account',
        },
        {
            title    : 'Range',
            dataIndex: 'range',
            key      : 'range',
        },
        {
            title    : 'Months In Range',
            dataIndex: 'months',
            key      : 'months',
        },
        {
            title    : 'Missing Months',
            dataIndex: 'missing',
            key      : 'missing',
        },
        {
            title    : 'Comment',
            dataIndex: 'comment',
            key      : 'comment',
        }
    ]



    return (
        <div className="p-15 d-col gap-15">
            <PageHeader title="Broker Assist Prep"/>
            <Card title="Financial Overview">
                <Table dataSource={overviewDatasource()} columns={overviewCols} pagination={false}/>
            </Card>
            <Card title="Statement Overview">
                <Table dataSource={statementDatasource} columns={statementsCols} pagination={false}/>
            </Card>
            {
                applicantCards().map(card => {
                    return (
                        <Card key={card.title} title={card.title}>
                            <Table dataSource={card.dataSource} columns={columns} pagination={false}
                                   showHeader={false}/>
                        </Card>
                    )
                })
            }

            <Card title="Mortgage / Property">
                <Table dataSource={mortgageDatasource()} columns={columns} pagination={false} showHeader={false}/>
            </Card>

        </div>
    );

}

export default Prep;