import React, {useContext} from 'react';
import useMortgage from "../../../providers/mortgage/useMortgage"
import {ToolDrawer} from "../../../providers/mortgage/ToolDrawerProvider";
import useApplicationForms from "../../../providers/mortgage/useApplicationForms";
import useApplicationUploads from "../../../providers/mortgage/useApplicationUploads";
import useAdminStages from "../../../providers/mortgage/useAdminStages";
import {useNavigate} from "react-router-dom";
import {joinWithConjunction} from "../../../../assets/scripts/string-functions";
import {ReplyRequestTypes} from "../../../../models";

function useTimelineStages(props) {
    const mortgage = useMortgage()
    const stages = useAdminStages()
    const application = useApplicationForms()
    const uploads = useApplicationUploads()
    const preapprovalUploads = useApplicationUploads("PREAPPROVAL", true)
    const toolDrawer = useContext(ToolDrawer)
    const navigate = useNavigate()

    const applyTimelineItems = () => {
        const _stage = 'APPLY'
        const sectionsFinished = application.sections.filter(section => section.percent !== 100).length === 0
        const uploadsFinished = uploads.apply.filter(section => section.percent !== 100).length === 0
        const uploadsNotChecked = uploads.apply.filter(section=>!section.record?.sectionChecked)
        const unAnsweredQueries = mortgage ? mortgage.queries.filter(item=>{
            return !item.excludedOn && !item.lenderAnswer
        }) : []
        const anyQueries = mortgage ? mortgage.queries.filter(item=>{
            return item.stage === _stage
        }) : []


        const items = [
            {
                key: 'initialFormsCompleted',
                label: 'Complete initial form',
                done: application.initialFormComplete() ? {by: 'User', on: true} : false,
                onClick: (e) => {
                    navigate(`/client/${mortgage.id}/overview`)
                }
            },
            {
                key: 'lenderChecksPassed',
                label: 'Confirm lender test passed',
                done: stages.getComplete(_stage, 'lenderChecksPassed'),
                onClick: (e) => {
                    navigate(`/client/${mortgage.id}/lender-calculators`)
                }
            },
            {
                key: 'sendQualificationEmail',
                label: 'Send qualification email',
                done: stages.getComplete(_stage, 'sendQualificationEmail'),
                onClick: (e) => {
                    const config = {
                        to: [mortgage.applicant1.email],
                        subject: 'You personalised mortgage proposal',
                        onSent: () => {
                            stages.setComplete(_stage, 'sendQualificationEmail')
                        }
                    }
                    toolDrawer.composeEmail(config)
                }
            }
        ]
        if (!sectionsFinished || !uploadsFinished) {
            let poss = []
            if (!sectionsFinished){
                poss.push('information')
            }
            if (!uploadsFinished){
                poss.push('uploads')
            }
            items.push({
                key: _stage + '/waitingForMissingItems',
                label: `Waiting for missing ${joinWithConjunction(poss, 'and')}`,
                type: 'wait'
            })
        }
        items.push({
            key: 'checkApplicationUploads',
            label: 'Check application uploads & raise queries',
            done: uploadsNotChecked.length === 0,
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/application-uploads`)
            }
        })
        items.push({
            key: 'constructQueryAnswers',
            label: 'Check query answers',
            done: anyQueries.length > 0 && unAnsweredQueries.length === 0,
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/queries`)
            }
        })
        items.push({
            key: 'confirmLender',
            label: 'Confirm lender & issue T&C',
            done: stages.getComplete(_stage, 'sendTermsAndConditionsEmail'),
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/application-management`)
            }
        })
        items.push({
            key: 'submitApplication',
            label: 'Submit application',
            done: stages.getComplete(_stage, 'sendSubmitApplicationEmail'),
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/apply/application-management`)
            }
        })
        return items
    }
    const getPreapprovalSteps = () => {
        const _stage = 'PREAPPROVAL'
        const preapprovalReceived = false
        const propertyAlreadyConfirmed = false
        const propertyConfirmed = false
        const conditionsFinished = false
        const steps = [
            {
                key: _stage + 'Submit application',
                labelTodo: 'Submit application',
                labelDone: 'Application Submitted',
                checks: [
                    {
                        key: 'submitApplication',
                        label: `Bundle and submit application`,
                        completed: stages.getComplete(_stage, 'submitApplication'),
                    },
                ]
            }]
        if (!preapprovalReceived) {
            steps.push({
                key: _stage + 'Waiting for pre-approved confirmation',
                labelWait: 'Waiting for pre-approved confirmation',
                checks: []
            })
        }
        steps.push({
            key: _stage + 'Implement conditions',
            labelTodo: 'Implement conditions',
            labelDone: 'Conditions Implemented',
            checks: [
                {
                    key: 'enterConditions',
                    label: `Enter the conditions`,
                    completed: stages.getComplete(_stage, 'enterConditions'),
                }
            ]
        })
        // if property is not known in advance, add an email to the Implement Conditions step and a waiting step afterward
        if (!propertyAlreadyConfirmed) {
            steps[steps.length - 1].checks.push({
                key: 'sendWaitingForPropertyEmail',
                label: `Send awaiting property email`,
                completed: stages.getComplete(_stage, 'sendWaitingForPropertyEmail'),
            })
            if (!propertyConfirmed) {
                steps.push({
                    key: _stage + 'Waiting for property details',
                    labelWait: 'Waiting for property details',
                    checks: stages.getReminderHistory(_stage, 'waitingForPropertyDetails')
                })
            }
            steps.push({
                key: _stage + 'Confirm the property',
                labelTodo: 'Confirm the property',
                labelDone: 'Confirm the property',
                checks: [
                    {
                        key: 'enterPropertyDetails',
                        label: `Enter the property details`,
                        completed: stages.getComplete(_stage, 'enterPropertyDetails'),
                    }
                ]
            })
        }

        steps.push({
            key: _stage + 'Send portal instructions',
            labelTodo: 'Send pre-approval conditions email',
            labelDone: 'Pre-approval conditions email sent',
            checks: [
                {
                    key: 'sendPreApprovalConditionsEmail',
                    label: 'Send conditions email',
                    completed: stages.getComplete('APPLY', 'sendPreApprovalConditionsEmail'),
                    onClick: (e) => {
                        const config = {
                            to: [mortgage.applicant1.email],
                            subject: 'Your Pre-Approval Conditions',
                            onSent: () => {
                                stages.setComplete('APPLY', 'sendPreApprovalConditionsEmail')
                            }
                        }
                        toolDrawer.composeEmail(config)
                    }
                }
            ]
        })

        if (!conditionsFinished) {
            steps.push({
                key: _stage + 'Waiting for completion of conditions',
                labelWait: 'Waiting for completion of conditions',
                checks: stages.getReminderHistory(_stage, 'waitingForConditionsCompletion')
            })
        }
        return steps
    }
    const getApprovalSteps = () => {
        const _stage = 'APPROVAL'
        const confirmationOfValuation = false
        const steps = []
        if (!confirmationOfValuation) {
            steps.push({
                key: _stage + 'Waiting for confirmation of valuation',
                labelWait: 'Waiting for confirmation of valuation',
                checks: []
            })
        }
        steps.push({
            key: _stage + 'Implement conditions',
            labelTodo: 'Standard documentation',
            labelDone: 'Standard documentation',
            checks: []
        })
        return steps
    }

    const preapprovalTimelineItems = ()=>{
        const _stage = 'PREAPPROVAL'
        const aipTasksRequest = mortgage.replyRequests.find(item=>item.requestType === ReplyRequestTypes.PREAPPROVAL)
        const items = []
        if(!aipTasksRequest){
            items.push({
                key: _stage + 'addTypeToReplyRequest',
                label: 'Confirm AIP email received',
                done: false,
                onClick: (e) => {
                    navigate(`/client/${mortgage.id}/application-uploads`)
                }
            })
        }
        const propertyReferenceRequired = ['avant','ptsb'].includes(mortgage.activeSubmission.lenderCode)
        items.push({
            key: _stage + 'enterApprovedInPrincipleDetails',
            label: 'Enter AIP details',
            done: !!mortgage.requirement.aipDate && (!propertyReferenceRequired || !!mortgage.properties[0].propertyReference),
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/application-uploads`)
            }
        })
        items.push({
            key: _stage + 'createPreApprovedCustomerTasks',
            label: 'Create AIP tasks for customer',
            done: !!aipTasksRequest && aipTasksRequest.itemsLoaded,
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/application-uploads`)
            }
        })
        const propertyAddressWasntKnown = stages.exists(_stage, 'sendSettleOnPropertyEmail')
        if (!mortgage.properties[0].addressKnown || propertyAddressWasntKnown) {
            items.push({
                key: _stage + 'sendSettleOnPropertyEmail',
                label: 'Send awaiting property email',
                done: stages.getComplete(_stage, 'sendSettleOnPropertyEmail'),
                onClick: (e) => {
                    const config = {
                        to: [mortgage.applicant1.email],
                        subject: 'You\'ve been approved in principle!',
                        onSent: () => {
                            stages.setComplete(_stage, 'sendSettleOnPropertyEmail')
                        }
                    }
                    toolDrawer.composeEmail(config)
                }
            })
        }
        if (!mortgage.properties[0].addressKnown) {
            items.push({
                key: _stage + '/waitingForPropertyAddress',
                label: `Waiting for property address`,
                type: 'wait'
            })
        }
        items.push({
            key: _stage + 'sendInstructValuationEmail',
            label: 'Send valuation instructions email',
            done: stages.getComplete(_stage, 'sendInstructValuationEmail'),
            onClick: (e) => {
                const config = {
                    to: [mortgage.applicant1.email],
                    subject: propertyAddressWasntKnown ? 'It\'s time to get your property valued!' : 'You\'ve been approved in principle!',
                    onSent: () => {
                        stages.setComplete(_stage, 'sendInstructValuationEmail')
                    }
                }
                toolDrawer.composeEmail(config)
            }
        })
        if (!mortgage.properties[0].valuationDate) {
            items.push({
                key: _stage + '/waitingForPropertyValuation',
                label: `Waiting for property valuation`,
                type: 'wait'
            })
        }
        if (!mortgage.properties[0].valuationDate) {
            items.push({
                key: _stage + '/waitingForPropertyValuation',
                label: `Waiting for property valuation`,
                type: 'wait'
            })
        }
        items.push({
            key: _stage + 'enterValuationDetails',
            label: 'Enter Valuation details',
            done: !!mortgage.properties[0].valuationDate && !!mortgage.properties[0].reinstatementValue,
            onClick: (e) => {
                navigate(`/client/${mortgage.id}/application-uploads`)
            }
        })
        const outstandingUploads = preapprovalUploads.sections.find(item=>!item.sectionChecked)
        if (outstandingUploads) {
            items.push({
                key: _stage + '/waitingForOutstandingCustomerTasks',
                label: `Waiting for customer to complete tasks`,
                type: 'wait'
            })
        }
        items.push({
            key: _stage + 'submitLoanOfferRequest',
            label: 'Submit loan offer request',
            done: !!aipTasksRequest && !!aipTasksRequest.repliedOn,
        })
    }

    return {
        APPLY: applyTimelineItems(),
        PREAPPROVAL: [],
        APPROVAL: []
    }
}

export default useTimelineStages;