import {dataPoint, get, getNullCells} from "./index";
import dayjs from "dayjs";
import {
    ApplicationTypes,
    AssetTypes,
    DebtTypes,
    EmploymentBases,
    EmploymentTypes,
    Frequencies,
    MortgageTypes,
    PersonalAccommodationSituations,
    PersonalMaritalStatuses,
} from "../../../models";
import commonAnswers from "./_commonAnswers";
import {
    getMortgageFigures
} from "../../../app/components/client/application-stages/_common/lender-tests/usePlayground";
import {clearedBeforeMortgage} from "../../../app/components/client/application/forms/sections/ApplicantDebts";

//const url = 'https://script.google.com/macros/s/AKfycbwWnI_GOrfcavy4Hg29uYJox_rdQXkqT_-04cFDx9Z3P9072ZYVn04xlJoE4o1jM240/exec' // 1.25
//const url = 'https://script.google.com/macros/s/AKfycbwQIWGFXEGls5XBhbaDQT9WkFpQUmgbvKrXSL-l-OJ9Us3jQ4y7w7iWHlyu48b5XsEC/exec' // 1.30
// const url = 'https://script.google.com/macros/s/AKfycbyoqwT_HR0p0w6zolq8l5s1Ha3T9qlHcyTlmHkSO6u0TxO0bO3tpGW8Qy81VSObddjf/exec' // 1.33
//const url = 'https://script.google.com/macros/s/AKfycbwBt0KzohAknc3aFiNwCYqkD0NlYnCcFWfQrDTu7E9fzZRpK-HzxUqAmPFh1PKsQvcN/exec' // 1.35
const url = 'https://script.google.com/macros/s/AKfycbwXZPaBcaEOiizLh6njxEbxRjdWL0cIH7DG3HaG62vi7rw8jrNObPEnDnOctxv82g2nuA/exec' // 1.38
const spreadsheetId = '1S5xJq2yVOi0ktJ0mI8t_Vuh1dnb_5KN-3sAOCGZ4lj8' //1.38
const sheetName = 'Calculator' //1.25
//const spreadsheetId = '1WDY2lY-eKxSu952w-OGSDpAchjqrVyL6HvuYcTeEAIw' // 1.30
//const spreadsheetId = '1TWvXp3-NlPzTs-4XF7av7EYuxr46iQNxfNvMp0p6pk4' // 1.33
//const spreadsheetId = '1DFmGZt6sSO1c_60Vv3ZElhMcj4qTnTgz3ZlmcQeVbFQ' // 1.35
export const icsRules = (data) => {
    return {
        ...data,
        bonus1: (data.commission1 || 0) + (data.bonus1 || 0),
        bonus2: (data.commission2 || 0) + (data.bonus2 || 0),
    }
}
function ics({mortgage}) {
    const borrowerTypes = [
        'FTB',
        'SSB',
        'Switcher with ER (FTB)',
        'Switcher with ER (SSB)',
        'Top Up (FTB)',
        'Top Up (SSB)',
        'Switcher with no ER (FTB)',
        'Switcher with no ER (SSB)',
    ]
    const rateOptions = [
        'Variable Rate',
        '3 year fixed',
        '5 year fixed'
    ]
    const maritalStatuses = [
        'Single',
        'Married',
        'Separated / Divorced',
        'Widowed'
    ]
    const employmentTypes = [
        'Not Employed',
        'PAYE',
        'Civil Servant',
        'Self employed'
    ]
    const answers = commonAnswers(mortgage)
    const data = getMortgageFigures(mortgage)
    const legacy = !mortgage.applicant1.employmentIncome?.length || !mortgage.applicant1.employmentIncome[0].employmentType
    const dataPoints = {
        "General": [
            dataPoint(
                'Borrower Type',
                'F10',
                {
                    type: 'select',
                    options: borrowerTypes,
                    rules: [
                        {required: true, message: 'Please select a borrower type'},
                    ]
                },
                () => {
                    switch (mortgage.mortgageType) {
                        case MortgageTypes.REFINANCE:
                            switch (mortgage.applicationType) {
                                case ApplicationTypes.OWNHOME:
                                    return 'Switcher with no ER (FTB)'
                                case ApplicationTypes.LANDLORD:
                                    return 'Switcher with no ER (SSB)'//???
                            }
                            break
                        case MortgageTypes.PURCHASE:
                            switch (mortgage.applicationType) {
                                case ApplicationTypes.FIRSTTIME:
                                    return 'FTB'
                                case ApplicationTypes.MOVINGHOME:
                                    return ''
                                case ApplicationTypes.SECONDTIME:
                                    return ''
                                case ApplicationTypes.LANDLORD:
                                    return ''
                            }
                            break
                    }
                },
            ),
            dataPoint(
                'Number of Borrowers',
                'F12',
                {
                    type: 'select',
                    options: ["1", "2"],
                },
                () => {
                    return mortgage.twoApplicants ? "2" : "1"
                },
            ),
            dataPoint(
                'Rate',
                'L6',
                {
                    type: 'select',
                    options: rateOptions
                },
                () => {
                    return '3 year fixed'
                },
            ),
            dataPoint(
                'Purchase Price/Value',
                'L8',
                {
                    type: 'number',
                },
                () => {
                    return mortgage.properties[0].propertyValue
                },
                (formData) => {
                    return formData.propertyValue
                },
                (newFormData)=>{
                    return newFormData.provisionalValue
                }
            ),
            dataPoint(
                'Mortgage Requested',
                'L10',
                {
                    type: 'number',
                },
                () => {
                    return mortgage.requirement.loanRequired
                },
                (formData) => {
                    return formData.mortgageAmount
                },
                (newFormData)=>{
                    return newFormData.provisionalLoan
                }
            ),
            dataPoint(
                'Term Requested (Years)',
                'L12',
                {
                    type: 'number',
                },
                () => {
                    return mortgage.requirement.loanTerm
                },
                (formData) => {
                    return formData.term
                },
                (newFormData)=>{
                    return newFormData.provisionalTerm
                }
            ),
        ],
        "Applicant 1": [
            dataPoint(
                'Marital Status',
                'H18',
                {
                    type: 'select',
                    options: maritalStatuses
                },
                () => {
                    switch (mortgage.applicant1.personal.maritalStatus) {
                        case PersonalMaritalStatuses.REMARRIED:
                        case PersonalMaritalStatuses.MARRIED:
                            return 'Married'
                        case PersonalMaritalStatuses.SINGLE:
                            return 'Single'
                        case PersonalMaritalStatuses.DIVORCED:
                        case PersonalMaritalStatuses.SEPARATED:
                            return 'Separated / Divorced'
                        case PersonalMaritalStatuses.WIDOWED:
                            return 'Widowed'
                    }
                }
            ),
            dataPoint(
                'Adult Dependant',
                'H22',
                {
                    type: 'select',
                    options: ['Yes', 'No']
                },
                () => {
                    return 'No'
                }
            ),
            dataPoint(
                'Number of Children',
                'H24',
                {
                    type: 'select',
                    options: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                },
                () => {
                    return isNaN(mortgage.applicant1.personal.countDependents) ? 0 : mortgage.applicant1.personal.countDependents
                }
            ),
            dataPoint(
                'Employment Type',
                'H26',
                {
                    type: 'select',
                    options: employmentTypes
                },
                () => {
                    switch (data.sector1) {
                        case 'public':
                            return 'Civil Servant'
                        case 'private':
                            return 'PAYE'
                        case 'self':
                            return 'Self employed'
                    }
                },
                (formData) => {
                    if (formData.sector1 === 'public') {
                        return 'Civil Servant'
                    }
                    if (formData.sector1 === 'private') {
                        return 'PAYE'
                    }
                    if (formData.sector1 === 'self') {
                        return 'Self employed'
                    }
                    return 'Not Employed'
                },
                (newFormData)=>{
                    if (newFormData.sector1 === 'public') {
                        return 'Civil Servant'
                    }
                    if (newFormData.sector1 === 'private') {
                        return 'PAYE'
                    }
                    if (newFormData.sector1 === 'self') {
                        return 'Self employed'
                    }
                    return 'Not Employed'
                }
            ),
        ],
        "Income 1": [
            dataPoint(
                'Basic',
                'H30',
                {
                    type: 'number',
                },
                () => {
                    return data.basic1
                },
                (formData) => {
                    return formData.basic1
                },
                (newFormData)=>{
                    return newFormData.basic1
                }
            ),
            dataPoint(
                'Overtime',
                'H32',
                {
                    type: 'number',
                },
                () => {
                    return data.overtime1
                },
                (formData) => {
                    return formData.overtime1
                },
                (newFormData)=>{
                    return newFormData.overtime1
                }
            ),
            dataPoint(
                'Overtime Proven 2 years',
                'J32',
                {
                    type: 'select',
                    options: ['Yes', 'No']
                },
                () => {
                    return 'Yes'
                }
            ),
            dataPoint(
                'Allowances',
                'H34',
                {
                    type: 'number',
                },
                () => {
                    return data.allowances1
                },
                (formData) => {
                    return formData.allowances1
                },
                (newFormData)=>{
                    return newFormData.allowances1
                }
            ),
            dataPoint(
                'Bonus & Commission',
                'H36',
                {
                    type: 'number',
                },
                () => {
                    return data.bonus1 + data.commission1
                },
                (formData) => {
                    return formData.bonus1 + formData.commission1
                },
                (newFormData)=>{
                    return newFormData.year1Bonus1 + newFormData.commission1
                }
            ),
            dataPoint(
                'Bonus & Commission Proven 2 years',
                'J36',
                {
                    type: 'select',
                    options: ['Yes', 'No']
                },
                () => {
                    return 'Yes'
                }
            ),
            dataPoint(
                'Other Income',
                'H38',
                {
                    type: 'number',
                },
                () => {
                    return data.other1
                },
                (formData) => {
                    return formData.other1
                },
                (newFormData)=>{
                    return newFormData.other1
                }
            ),
        ],
        "Outgoings 1": [
            dataPoint(
                'Financial Commitments (Monthly)',
                'H40',
                {
                    type: 'number',
                },
                () => {
                    let applicable = data.loans1.filter(it=>!it.clearedBeforeMortgage)
                    let sum = applicable.reduce((total, app) => total + app.monthlyPayment, 0);
                    return sum
                },
                (formData) => {
                    return formData.loans1
                    // let excluded = formData.clearingLoans1
                    // let applicable = data.loans1.filter(it=>!excluded.includes(it.id))
                    //
                    // let sum = applicable.reduce((total, app) => total + app.monthlyPayment, 0);
                    // return sum
                },
                (newFormData)=>{
                    let sum = newFormData.loans1;
                    return sum
                }
            ),
        ],
    }
    if (mortgage.twoApplicants) {
        dataPoints["Applicant 2"] = [
            dataPoint(
                'Marital Status',
                'L18',
                {
                    type: 'select',
                    options: maritalStatuses
                },
                () => {
                    switch (mortgage.applicant2.personal.maritalStatus) {
                        case PersonalMaritalStatuses.REMARRIED:
                        case PersonalMaritalStatuses.MARRIED:
                            return 'Married'
                        case PersonalMaritalStatuses.SINGLE:
                            return 'Single'
                        case PersonalMaritalStatuses.DIVORCED:
                        case PersonalMaritalStatuses.SEPARATED:
                            return 'Separated / Divorced'
                        case PersonalMaritalStatuses.WIDOWED:
                            return 'Widowed'
                    }
                }
            ),
            dataPoint(
                'Number of Children',
                'L24',
                {
                    type: 'select',
                    options: [0, 1, 2, 3, 4, 5, 6, 7, 8]
                },
                () => {
                    return isNaN(mortgage.applicant2.personal.countDependents) ? 0 : mortgage.applicant2.personal.countDependents
                }
            ),
            dataPoint(
                'Employment Type',
                'L26',
                {
                    type: 'select',
                    options: employmentTypes
                },
                () => {
                    switch (data.sector2) {
                        case 'public':
                            return 'Civil Servant'
                        case 'private':
                            return 'PAYE'
                        case 'self':
                            return 'Self employed'
                    }
                },
                (formData) => {
                    if (formData.sector2 === 'public') {
                        return 'Civil Servant'
                    }
                    if (formData.sector2 === 'private') {
                        return 'PAYE'
                    }
                    if (formData.sector2 === 'self') {
                        return 'Self employed'
                    }
                    return 'Not Employed'
                },
                (newFormData) => {
                    if (newFormData.sector2 === 'public') {
                        return 'Civil Servant'
                    }
                    if (newFormData.sector2 === 'private') {
                        return 'PAYE'
                    }
                    if (newFormData.sector2 === 'self') {
                        return 'Self employed'
                    }
                    return 'Not Employed'
                }
            ),
        ]
        dataPoints["Income 2"] = [
            dataPoint(
                'Basic',
                'L30',
                {
                    type: 'number',
                },
                () => {
                    return data.basic2
                },
                (formData) => {
                    return formData.basic2
                },
                (newFormData) => {
                    return newFormData.basic2
                }
            ),
            dataPoint(
                'Overtime',
                'L32',
                {
                    type: 'number',
                },
                () => {
                    return data.overtime2
                },
                (formData) => {
                    return formData.overtime2
                },
                (newFormData) => {
                    return newFormData.overtime2
                }
            ),
            dataPoint(
                'Overtime Proven 2 years',
                'N32',
                {
                    type: 'select',
                    options: ['Yes', 'No']
                },
                () => {
                    return 'Yes'
                }
            ),
            dataPoint(
                'Allowances',
                'L34',
                {
                    type: 'number',
                },
                () => {
                    return data.allowances2
                },
                (formData) => {
                    return formData.allowances2
                },
                (newFormData) => {
                    return newFormData.allowances2
                }
            ),
            dataPoint(
                'Bonus & Commission',
                'L36',
                {
                    type: 'number',
                },
                () => {
                    return data.bonus2 + data.commission2
                },
                (formData) => {
                    return formData.bonus2 + formData.commission2
                },
                (newFormData) => {
                    return newFormData.year1Bonus2 + newFormData.commission2
                }
            ),
            dataPoint(
                'Bonus & Commission Proven 2 years',
                'N36',
                {
                    type: 'select',
                    options: ['Yes', 'No']
                },
                () => {
                    return 'Yes'
                }
            ),
            dataPoint(
                'Other Income',
                'J38',
                {
                    type: 'number',
                },
                () => {
                    return data.other2
                },
                (formData) => {
                    return formData.other2
                },
                (newFormData) => {
                    return newFormData.other2
                }
            ),
        ]
        dataPoints["Outgoings 2"] = [
            dataPoint(
                'Financial Commitments (Monthly)',
                'L40',
                {
                    type: 'number',
                },
                () => {
                    let applicable = data.loans2.filter(it=>!it.clearedBeforeMortgage)
                    let sum = applicable.reduce((total, app) => total + app.monthlyPayment, 0);
                    return sum
                },
                (formData)=>{
                    return formData.loans2
                },
                (newFormData)=>{
                    let sum = newFormData.loans2;

                    return sum
                }
            ),
        ]
    }
    // if (mortgage.twoApplicants) {
    //     dataPoints["Applicant 2"] = [
    //         dataPoint('Marital Status', 'L18', {
    //             type   : 'select',
    //             options: maritalStatuses
    //         }, () => {
    //             switch (mortgage.applicant2.personal.maritalStatus) {
    //                 case PersonalMaritalStatuses.REMARRIED:
    //                 case PersonalMaritalStatuses.MARRIED:
    //                     return 'Married'
    //                 case PersonalMaritalStatuses.SINGLE:
    //                     return 'Single'
    //                 case PersonalMaritalStatuses.DIVORCED:
    //                 case PersonalMaritalStatuses.SEPARATED:
    //                     return 'Separated / Divorced'
    //                 case PersonalMaritalStatuses.WIDOWED:
    //                     return 'Widowed'
    //             }
    //         }),
    //         dataPoint('Number of Children', 'L24', {
    //             type   : 'select',
    //             options: [
    //                 0,
    //                 1,
    //                 2,
    //                 3,
    //                 4,
    //                 5,
    //                 6,
    //                 7,
    //                 8
    //             ]
    //         }, () => {
    //             return (mortgage.applicant2.personal.countDependents || 0)
    //         }),
    //         dataPoint('Employment Type', 'L26', {
    //             type   : 'select',
    //             options: employmentTypes
    //         }, () => {
    //             switch (data.sector2) {
    //                 case 'public':
    //                     return 'Civil Servant'
    //                 case 'private':
    //                     return 'PAYE'
    //                 case 'self':
    //                     return 'Self employed'
    //                 default:
    //                     return 'Not Employed'
    //             }
    //         }, (formData) => {
    //             if (formData.sector2 === 'public') {
    //                 return 'Civil Servant'
    //             }
    //             if (formData.sector2 === 'private') {
    //                 return 'PAYE'
    //             }
    //             if (formData.sector2 === 'self') {
    //                 return 'Self employed'
    //             }
    //             return 'Not Employed'
    //         }),
    //         dataPoint('Basic', 'L30', {
    //             type: 'number',
    //         }, () => {
    //             return data.basic2
    //         }, (formData) => {
    //             return icsRules(formData).basic2
    //         }),
    //         dataPoint('Overtime', 'L32', {
    //             type: 'number',
    //         }, () => {
    //             return data.overtime2
    //         }, (formData) => {
    //             return icsRules(formData).overtime2
    //         }),
    //         dataPoint('Overtime Proven 2 years', 'N32', {
    //             type   : 'select',
    //             options: [
    //                 'Yes',
    //                 'No'
    //             ]
    //         }, () => {
    //             return 'Yes'
    //         }),
    //         dataPoint('Allowances', 'L34', {
    //             type: 'number',
    //         }, () => {
    //             return data.allowances2
    //         }, (formData) => {
    //             return icsRules(formData).allowances2
    //         }),
    //         dataPoint('Bonus & Commission', 'L36', {
    //             type: 'number',
    //         }, () => {
    //             return data.bonus2 + data.commission2
    //         }, (formData) => {
    //             return icsRules(formData).bonus2
    //         }),
    //         dataPoint('Bonus & Commission Proven 2 years', 'N36', {
    //             type   : 'select',
    //             options: [
    //                 'Yes',
    //                 'No'
    //             ]
    //         }, () => {
    //             return 'Yes'
    //         }),
    //         dataPoint('Other Income', 'L38', {
    //             type: 'number',
    //         }, () => {
    //             return data.other2
    //         }, (formData) => {
    //             return icsRules(formData).other2
    //         }),
    //         dataPoint('Financial Commitments (Monthly)', 'L40', {
    //             type: 'number',
    //         }, () => {
    //             let applicable = data.loans2.filter(it => !it.clearedBeforeMortgage)
    //             let sum = applicable.reduce((total, app) => total + app.monthlyPayment, 0);
    //             return sum
    //         }, (formData) => {
    //             return icsRules(formData).maintenancePaid1 + icsRules(formData).loans1
    //             // let excluded = formData.clearingLoans2
    //             // let applicable = data.loans2.filter(it => !excluded.includes(it.id))
    //             //
    //             // let sum = applicable.reduce((total, app) => total + app.monthlyPayment, 0);
    //             // return sum
    //         }),
    //     ]
    // }
    const dataToReceive = {
        'LTV'      : 'D58',
        'LTV Limit': 'F58',
        'LTV Test' : 'H58',

        'Income Multiple'      : 'D62',
        'Income Multiple Limit': 'F62',
        'Income Multiple Test' : 'H62',

        'MSR'      : 'D66',
        'MSR Limit': 'F66',
        'MSR Test' : 'H66',

        'NDI Available'        : 'D70',
        'NDI Required'         : 'H70',
        'NDI Surplus/Shortfall': 'D72',
        'NDI Test'             : 'H72',
    }
    return {
        name: 'ics',
        dataPoints,
        get : (values, shouldSaveOutput) => {
            const blankedData = getNullCells(dataPoints)
            console.log({ICS:values})
            return get(url, spreadsheetId, sheetName, {...blankedData, ...values}, dataToReceive, mortgage, shouldSaveOutput)
        }
    }
}
export default ics