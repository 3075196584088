import React, {useContext} from 'react';
import useMortgage from "../../../../../../../../providers/mortgage/useMortgage";
import {Button} from "antd";
import {capitaliseWords} from "../../../../../../../../../assets/scripts/string-functions";
import {ToolDrawer} from "../../../../../../../../providers/mortgage/ToolDrawerProvider";

function QueryButton({
    uploadId,
    section
})
{

    const mortgage = useMortgage()
    const upload = mortgage.uploads.find(item => item.id === uploadId)
    const mortgageUploadSection = [
        ...mortgage.mortgageUploads.apply,
        ...mortgage.mortgageUploads.preapproval,
        ...mortgage.mortgageUploads.approval
    ].find(item => {
        return !!item.uploads.find(item => item.id === uploadId)
    })
    let mortgageUpload = mortgageUploadSection.uploads.find(item => item.id === uploadId)
    const tools = useContext(ToolDrawer)
    const handleComposeQuery = () => {

        const config = {
            referenceType: 'UPLOAD',
            referenceId  : uploadId,
            section      : upload.section,
            stage        : upload.stage,
            title        : capitaliseWords(section.title),
        }
        if (section.index) {
            config.index = section.index
        }

        if ([
            1,
            2
        ].includes(section.applicant))
        {
            config.ownerName = mortgage[`applicant${section.applicant}`].firstName
        }

        tools.composeQuery(config)
    }
    let buttonProps = {}
    if (mortgageUpload.hasOwnProperty('referencedQueries')) {
        if (mortgageUpload.referencedQueries.are.missingUserAnswers) {
            buttonProps.type='primary'
            buttonProps.style = {
                backgroundColor: '#ffb54d', // Warning background color
                borderColor    : '#ffb54d', // Warning border color
                color          : '#fff', // Text color
            }
        }
        else if (mortgageUpload.referencedQueries.are.missingLenderAnswers) {
            buttonProps.type='primary'
            buttonProps.danger = true
        }
        else{
            buttonProps.type='primary'
            buttonProps.style = {
                backgroundColor: '#52c41a', // Success background color
                borderColor    : '#52c41a', // Success border color
                color          : '#fff', // Text color
            }
        }
    }
    return (<Button size="small" {...buttonProps} onClick={handleComposeQuery}>Query</Button>);
}

export default QueryButton;