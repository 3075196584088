import React, {useState} from 'react';
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import {LoadingOutlined} from "@ant-design/icons";
import {Form, Input, Select} from "antd";
import {capitaliseWords} from "../../../../../../../../../../assets/scripts/string-functions";
import useMortgageUploadService from "../../../../../../../../../providers/mortgage/useMortgageUploadService";

function Mover({uploadId, sections}) {


    const [moving, setMoving] = useState(false)
    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const row = mortgage.uploads.find(item => item.id === uploadId)
    const handleMove = async (str) => {
        let [stage, section, index] = str.split('|')
        if (index === "") index = null
        setMoving(true)
        await uploadService.move(uploadId,{ stage, section, index})
        setMoving(false)
    }
    const mySections = sections.reduce((acc, item) => {
        const key = item.applicant ? `App ${item.applicant}` : 'Other';
        acc[key] = acc[key] || [];
        acc[key].push(item);
        return acc;
    }, {});

    let options = Object.keys(mySections).map(key => {
        return {
            label: key + ' Sections',
            title: key,
            options: mySections[key].map(item => {
                return {
                    label: capitaliseWords(item.title),
                    value: `${item.stage}|${item.name}|${item.index || ''}`
                }
            })
        }
    })
    const filter = (inputValue, option) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase())
    }
    if (!row) return null
    return (
        <>
            <span>Move File {moving && <LoadingOutlined/>}</span>

            <Select
                showSearch
                placeholder="Select a section"
                filterOption={filter}
                options={options}
                onSelect={handleMove}
            />

        </>

    );
}

export default Mover;