import React, {useState} from 'react';
import {AutoComplete, Button, Form, Input, Modal, Select} from "antd";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {AssetTypes, DebtTypes, SectionTypes} from "../../../../../../../models";
import {LoadingOutlined} from "@ant-design/icons";

function AddAccountModal({applicant, stage}) {
    const [open, setOpen] = useState(false);
    const [accountType,setAccountType] = useState('current')
    const [busy,setBusy] = useState(false)
    const mortgage = useMortgage()
    const [form] = Form.useForm();
    let mortgageApplicant = mortgage[`applicant${applicant}`]
    const options = ['AIB','Avant Money','Bank of Ireland', 'Credit Union', 'EBS', 'Permanent TSB', 'Revolut', 'Barclays Bank Ireland' ].map(item=>({value:item}))
    const handleFinish = async (e)=>{
        setBusy(true)
        const {bank, accountType} = form.getFieldsValue()
        let target
        let pairs = {
            applicantID: mortgageApplicant.id,
            observeMID: mortgage.id
        }
        let sectionName
        switch(accountType){
            case 'current':
                target = `applicant${applicant}.accounts`;
                delete(pairs.observeMID)
                pairs.institution = bank
                sectionName = 'current-accounts-' + applicant
                break;
            case 'savings':
                target = `applicant${applicant}.assets`;
                pairs.assetType = AssetTypes.SAVING
                pairs.observeMID = mortgage.id
                pairs.institution = bank
                sectionName = 'assets-' + applicant
                break;
            case 'credit':
                target = `applicant${applicant}.debts`;
                pairs.debtType = DebtTypes.CREDITCARD;
                pairs.observeMID = mortgage.id
                pairs.lender = bank
                sectionName = 'debts-' + applicant
                break;
            case 'loan':
                target = `applicant${applicant}.debts`;
                pairs.debtType = DebtTypes.LOAN;
                pairs.observeMID = mortgage.id
                pairs.lender = bank
                sectionName = 'debts-' + applicant
                break;
            case 'investment':
                target = `applicant${applicant}.assets`;
                pairs.assetType = AssetTypes.INVESTMENT;
                pairs.observeMID = mortgage.id
                pairs.institution = bank
                sectionName = 'assets-' + applicant
                break;
            case 'overdraft':
                target = `applicant${applicant}.debts`;
                pairs.debtType = DebtTypes.OVERDRAFT;
                pairs.observeMID = mortgage.id
                pairs.lender = bank
                sectionName = 'debts-' + applicant
                break;
        }
        const create = {
            target,
            pairs
        }
        let result = await mortgage.mutate({create})

        let sectionCreate = {
            target: 'progress',
            pairs: {
                mortgageID: mortgage.id,
                applicationStage: stage,
                sectionType: SectionTypes.UPLOAD,
                sectionName,
                sectionIndex: result[0].id,
            }
        }
        await mortgage.mutate({create: sectionCreate})
        setBusy(false)
        setOpen(false)
    }
    let okText = busy ? <>Save <LoadingOutlined className="ms-3"/></> : 'Save'
    return (
        <>
            <Button onClick={()=>setOpen(true)}>Add Account</Button>
            <Modal open={open} onCancel={()=>setOpen(false)} title={"Add a new account"} okText={okText} onOk={handleFinish}>
                <p>Add an account for {mortgageApplicant.firstName} that isn't already captured</p>

                <Form form={form} onFinish={handleFinish}>
                    <Form.Item name="accountType" label="Account Type">
                        <Select value={accountType} onChange={setAccountType} options={[
                            {label: 'Current Account', value: 'current'},
                            {label: 'Savings Account', value: 'savings'},
                            {label: 'Credit Card', value: 'credit'},
                            {label: 'Loan Account', value: 'loan'},
                            {label: 'Investment Account', value: 'investment'},
                            {label: 'Overdraft Account', value: 'overdraft'},
                        ]}/>
                    </Form.Item>
                    <Form.Item name="bank" label="Institution">
                        <AutoComplete title="Insisution Name" options={options}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
}

export default AddAccountModal;