import React, {useState} from 'react';
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";
import {LoadingOutlined} from "@ant-design/icons";
import {Button, Input, Popover, Tag} from "antd";
import {UploadStatuses} from "../../../../../../../../../../models";
import useBackendUsers from "../../../../../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import {LONGDATE, LONGDATETIME12} from "../../../../../../../../../../assets/scripts/constants";

function StatusChanger({uploadId}) {
    const mortgage = useMortgage()
    const [changing, setChanging] = useState(false)
    const {getBySub, me} = useBackendUsers()
    const row = mortgage.uploads.find(item => item.id === uploadId)

    const options = {
        "Acceptable": UploadStatuses.ACCEPTABLE,
        "For Advisor Review": UploadStatuses.REVIEWING,
        "Rejected": UploadStatuses.REJECTED,
        "Pending": UploadStatuses.PENDING,
        "Expired / Not Needed": UploadStatuses.EXPIRED
    }
    const handleExaminedChange = async () => {
        setChanging(true)
        await mortgage.mutate({
            update: {
                target: `uploads[${uploadId}]`,
                pairs:{
                    examined: !row.examined
                }
            }
        })
        setChanging(false)
    }
    const handleStatusChange = async (status) => {
        setChanging(true)

        await mortgage.mutate({
            update: {
                target: `uploads[${uploadId}]`,
                pairs:{
                    status,
                    statusOn: dayjs().toISOString(),
                    statusBy: me.sub,
                }
            }
        })
        setChanging(false)
    }
    const handleRejectedReason = async (e) => {
        const target = `uploads[${uploadId}].reason`
        const value = e.target.value
        await mortgage.mutate({
            update: {
                target,
                value
            }
        })
    }
    function Buttons(){
        return Object.keys(options).map((key, index) => {
            let props ={}

            if (options[key] === row.status){
                switch(options[key]){
                    case UploadStatuses.ACCEPTABLE: props={className:'button-success'}; break;
                    case UploadStatuses.REVIEWING: props={className:'button-orange'}; break;
                    case UploadStatuses.REJECTED: props={danger:true}; break;
                    case UploadStatuses.PENDING: props={className:'button-orange'}; break;
                    case UploadStatuses.EXPIRED: props={danger:true}; break;
                }
            }
            return <Button key={key} {...props} disabled={changing} size="small" onClick={()=>handleStatusChange(options[key])}>{key}</Button>
        })
    }
    if (!row) return null

    let ticker = getBySub(row.statusBy)
    let statusOn = row.statusOn ? dayjs(row.statusOn) : false


    return (
        <>
            <div className="d-row j-between">
                <span>Status {changing && <LoadingOutlined/>}</span>
                {statusOn && (
                    <Popover content={statusOn.format(LONGDATETIME12)} trigger="hover">
                        <Tag color={ticker.color}>{ticker.firstName}</Tag>
                    </Popover>
                )}

            </div>

            <Buttons/>
            <Button className={row.examined ? 'button-success' : ''} disabled={changing} size="small" onClick={()=>handleExaminedChange()}>Examined</Button>
            {row.status === UploadStatuses.REJECTED && (
                    <Input.TextArea onBlur={handleRejectedReason} placeholder="Reason for Rejection" />
                )
            }
        </>

    );
}

export default StatusChanger;