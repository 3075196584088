import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import * as pdfjs from "pdfjs-dist";
import {Button, Card, Checkbox, Collapse, Form, Input, Modal, Select} from "antd";
import {CloseCircleOutlined, DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {geekblue, gray, green, red, volcano} from "@ant-design/colors";
import countries from "../../../../assets/scripts/countries.json";
import {PdfIdentifyContext} from "../../../providers/PdfIdentifyProvider";
import useValueFromTextExtractor from "../useValueFromTextExtractor";
import usePdfRecogniserV3 from "./usePdfRecogniserV3";
import {getConfigurationTemplates} from "../../../../assets/scripts/verifiable-section/verifiableSection";
//pdfjs.GlobalWorkerOptions.workerSrc = 'node_modules/pdfjs-dist/build/pdf.worker.min.js';
let tX = 4
let tY = 5
let scale = 2
function PdfRecogniserV3() {
    const [pdfTemplate, setPdfTemplate] = useState(null)
    const {payslipConfig} = getConfigurationTemplates()
    const templates = [
        {
            label: 'Bank Statement',
            value: 'statement',
        },
        {
            label: 'Card Statement',
            value: 'credit',
        },
        {
            label: 'Payroll Slip',
            value: 'payslip',
            items: payslipConfig.template.items
        }
    ]
    const selectPdfOptions = templates.map(it => ({
        label: it.label,
        value: it.value
    }))
    let identifierConditionsOptions = [
        {
            label: 'Equals',
            value: 'equalTo'
        },
        {
            label: 'Starts with',
            value: 'startsWith'
        },
        {
            label: 'Ends with',
            value: 'endsWith'
        },
        {
            label: 'Includes',
            value: 'includes'
        },
    ]
    const pdfTemplateConfig = templates.find(it => it.value === pdfTemplate)
    const [contextForm] = Form.useForm()
    const [tables,setTables] = useState([])
    const idContext = useContext(PdfIdentifyContext)
    const handleFinish = (values) => {
        console.log({values})
    }
    const {
              handleReset,
              mouseCoords,
              FileInput,
              props,
              dimensions,
              ...recogniser
          } = usePdfRecogniserV3()

    const resetTables = ()=>{
        let tablesValue = contextForm.getFieldValue('tables')
        let tables = !!tablesValue ? tablesValue.map(it=>!!it && JSON.parse(it.area)).filter(it=>!!it) : []
        setTables(tables)
    }

    return (<>

            <div className="d-row">
                <Form form={contextForm} onFinish={handleFinish} >
                <div className="d-col a-start gap-15 m-15">

                    <Form.Item className="mb-0" name="statementType" initialValue="statement" label="Start a new template">
                        <Select style={{width: 200}} options={selectPdfOptions} onSelect={setPdfTemplate}
                                value={pdfTemplate}/>
                    </Form.Item>
                    {!!pdfTemplate && !dimensions?.length && (<FileInput/>)}

                    {!!dimensions?.length && (<>
                        <Form.Item  className="mb-0" name="friendly" label="Friendly Name">
                            <Input/>
                        </Form.Item>
                        <Form.Item noStyle name="dimensions" initialValue={JSON.stringify(dimensions)}>
                            <Input type="hidden"/>
                        </Form.Item>
                        <Form.List
                            name="identifiers"
                        >
                            {(fields, {
                                add,
                                remove
                            }, {errors}) => (<div className="d-col gap-3">
                                <Form.Item label="Identify Document">
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined/>}
                                    >
                                        Add Condition
                                    </Button>
                                </Form.Item>
                                {fields.map((field, index) => {
                                    let grabName = `identifiers.area.${index}`
                                    return (<Card key={field.key}>
                                        <div className="d-col grow gap-3">
                                            <GrabberTool grabName={grabName} recogniser={recogniser} fieldName={[
                                                field.name,
                                                'area'
                                            ]} formName="identifiers" withText={true}/>
                                            <div className="d-row grow gap-3 a-center ">
                                                <Form.Item noStyle name={[
                                                    field.name,
                                                    'not'
                                                ]}>
                                                    <Select style={{width: '16%'}} title="Not"
                                                            options={[
                                                                {
                                                                    label: 'Does',
                                                                    value: false
                                                                },
                                                                {
                                                                    label: 'Not',
                                                                    value: true
                                                                }
                                                            ]}/>
                                                </Form.Item>
                                                <Form.Item noStyle name={[
                                                    field.name,
                                                    'condition'
                                                ]}>
                                                    <Select style={{width: '40%'}} title="Condition"
                                                            options={identifierConditionsOptions}/>
                                                </Form.Item>
                                                <Form.Item noStyle name={[
                                                    field.name,
                                                    'string'
                                                ]}>
                                                    <Input style={{width: '40%'}} type="text"/>
                                                </Form.Item>

                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />

                                            </div>

                                        </div>
                                    </Card>)
                                })}

                            </div>)}
                        </Form.List>
                        <Form.List
                            name="tables"
                        >
                            {(fields, {
                                add,
                                remove
                            }, {errors}) => (<div className="d-col gap-3">
                                {fields.map((field, index) => {
                                    let grabName = `tables.area.${index}`
                                    return (
                                        <div className="d-row j-between gap-9">

                                        <TableTool resetTables={resetTables} tables={tables} contextForm={contextForm} key={field.key} grabName={grabName} recogniser={recogniser} fieldName={[
                                                field.name,
                                                'area'
                                            ]} formName="identifiers" withText={true}/>
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => {
                                                    remove(field.name);
                                                    resetTables()
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                                <Form.Item label="Create Tables">
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined/>}
                                    >
                                        Select Table
                                    </Button>
                                </Form.Item>
                            </div>)}
                        </Form.List>
                        {pdfTemplate && !dimensions?.length && <FileInput/>}
                        {!!pdfTemplateConfig && pdfTemplateConfig.items.map((item, index) => {
                            let grabName = `area.${item.name}`
                            return <GrabberTool resetTables={resetTables} tables={tables} formName={['area',item.name]} contextForm={contextForm} label={item.label} grabName={grabName} recogniser={recogniser} withText={true}/>
                        })}
                        <Button htmlType="submit">Submit</Button>
                    </>)}



                </div>
                </Form>
                <div {...props.drawing} >
                    <canvas {...props.canvas} />
                    <div {...props.shading} />
                </div>
            </div>

        <div className="d-row gap-3" style={{
            position: 'fixed',
            top     : 5,
            right   : 10,
            zIndex  : 1000
        }}>
            <Button onClick={handleReset}>Reset</Button>
            <span style={{
                width    : 70,
                textAlign: 'right'
            }}>X: {mouseCoords.x}</span>
            <span style={{
                width    : 70,
                textAlign: 'right'
            }}>Y: {mouseCoords.y}</span>
        </div>


    </>)
}

const GrabberTool = ({
    grabName,
    formName,
    withText = false,
    recogniser,
    label,
    contextForm,
    resetTables,
    tables
}) => {
    const [tool,setTool] = useState(null)
    const [table,setTable] = useState(null)
    const [row,setRow] = useState(null)
    const [cell,setCell] = useState(null)
    function TextSelector() {
        if (recogniser.grabbing === grabName) {
            return (<div style={{
                color     : volcano.primary,
                fontWeight: 'bold'
            }}>Select Area Now</div>)
        }
        else {
            if (recogniser.grabbed.hasOwnProperty(grabName)) {
                if (recogniser.grabbed[grabName].text === '') {
                    return (<div className="d-row j-between a-center">
                            <span style={{
                                color     : volcano.primary,
                                fontWeight: 'bold',
                            }}>NO TEXT FOUND THERE</span>
                        <DeleteOutlined onClick={() => {
                            recogniser.clearGrabber(grabName)
                            setTool(null)
                        }}/>
                    </div>)
                }
                else {
                    return (<>
                        <div className="d-row j-between a-center gap-6">

                            <TransformTextModal contextForm={contextForm} formName={formName} withText={withText}
                                                current={recogniser.grabbed[grabName]}/>
                            <DeleteOutlined onClick={() => {
                                recogniser.clearGrabber(grabName)
                                setTool(null)
                            }}/>
                        </div>

                    </>)
                }
            }
            else {
                return (<Button style={{width: 144}} onClick={() => {
                    recogniser.grab(grabName)
                    setTool('text')
                }}>Select Text </Button>)
            }
        }
    }
    function TableLookup(){

        useEffect(() => {
            if (tables?.length && table !== null && row !== null && cell !== null) {
                contextForm.setFieldValue(formName, JSON.stringify({
                    table: {
                        table,
                        row,
                        cell
                    }
                }))
            }
        }, [table, row, cell]);
        if (!tables?.length) {
            return null
        }
        console.log({recogniser, table})
        // useEffect(() => {
        //     const fValue = contextForm.getFieldValue('tables')
        //     let tables = []
        //     if (fValue) {
        //         tables = fValue.map(it=>!!it && JSON.parse(it.area))
        //     }
        //     console.log({tables})
        // }, []);

        if (table!==null){
            if (row!==null) {
                if (cell !== null) {
                    let value = parseFloat(tables[table].rows.find(r=>r[0]===row)[cell].replace(/[^0-9.]/g, ''))
                    return <div className="d-row a-center gap-6">
                        <span>Cell: {value}</span>
                        <CloseCircleOutlined onClick={() => {
                            setCell(null)
                        }}/>
                        <Form.Item noStyle name={formName}>
                            <Input type="hidden"/>
                        </Form.Item>
                    </div>
                }
                return <div className="d-row a-center gap-6">
                    <span>Cell:</span>
                    {tables[table].rows[0].map((cell,i)=>{
                            if (i===0){
                                return null
                            }
                            return <Button style={{width: 40}} onClick={() => {
                                setCell(i)
                            }}>C{i+1}</Button>
                        })}
                    <CloseCircleOutlined onClick={() => {
                        setRow(null)
                    }}/>
                </div>
            }
            console.log({help: tables[table].rows})
            return <div className="d-row a-center gap-6">
                <span>Row: <Select style={{width:144}} options={tables[table].rows.map(row=>({label: row[0], value: row[0]}))} value={row} onSelect={setRow}/></span>
                <CloseCircleOutlined onClick={() => {
                    setTable(null)
                }}/>
            </div>
        }


        return tables.map((t,i)=>{
            return <Button style={{width: 40}} onClick={() => {
                setTable(i)
                setTool('table')
            }}>Tbl {i+1}</Button>
        })


    }
    return <div className="d-row a-center gap-6">
        {label}:
        {['text',null].includes(tool) && <TextSelector/>}
        {['table',null].includes(tool) && <TableLookup/>}
    </div>

}
function TransformTextModal({
    current,
    formName,
    contextForm,
})
{
    const init = contextForm && contextForm.getFieldValue(formName)
    console.log({formName,init})
    const {
              formComponent,
              transformers,
              transformedValue
          } = useValueFromTextExtractor({
        text         : current.text,
        initialValues: JSON.parse(init || '[]')
    })
    const [open, setOpen] = useState(false)
    const handleOk = () => {
        contextForm.setFieldValue(formName, JSON.stringify({
            coords: current.coords,
            transformers
        }))
        setOpen(false)
    }
    useEffect(() => {
        contextForm.setFieldValue(formName, JSON.stringify({
            coords: current.coords,
        }))
    }, []);
    let resultString = null
    if (!transformedValue) {
        resultString = <span style={{
            fontWeight: 'bold',
            color     : geekblue.primary
        }}>{current.text}</span>
    }
    else {
        if (typeof transformedValue === 'string' || typeof transformedValue === 'number' ) {
            resultString = <span style={{
                fontWeight: 'bold',
                color     : green.primary
            }}>{transformedValue}</span>
        }
        else {
            resultString = <span style={{
                fontWeight: 'bold',
                color     : red.primary
            }}>Result not a string</span>
        }
    }

    return (<>
        {resultString}
        <EditOutlined onClick={() => setOpen(true)}/>
        <Modal title={`Extract Value: ${current.text}`} open={open} onCancel={() => setOpen(false)} onOk={handleOk}>
            {formComponent}
        </Modal>
        <Form.Item  noStyle name={formName}>
            <Input type="hidden"/>
        </Form.Item>
    </>)

}

function TableTool ({recogniser, fieldName, grabName, contextForm, resetTables, tables}) {
    const [table,setTable] = useState([])
    useEffect(() => {
        recogniser.grabTable(grabName)
    }, []);
    useEffect(() => {
        if (recogniser.grabbed.hasOwnProperty(grabName)) {
            let grabbed = recogniser.grabbed[grabName]
            if (grabbed.rows.length > 0) {
                let simplifiedRows = grabbed.rows.map(row => row.map(cell => cell.str.trim()))
                let betterResult = {
                    ...grabbed,
                    rows: simplifiedRows
                }
                console.log({fieldName})
                contextForm.setFieldValue(['tables', ...fieldName], JSON.stringify(betterResult))
                recogniser.clearGrabber(grabName)
                setTable(betterResult)
                resetTables()
            }
        }
    }, [recogniser.grabbed]);

    if (recogniser.grabbing?.table === grabName) {
        return (<div style={{
            color     : volcano.primary,
            fontWeight: 'bold'
        }}>Select Area Now</div>)
    }
    else {
        if (table) {
            console.log({table})
            if (table.length === 0) {
                return (<div style={{
                    color     : volcano.primary,
                    fontWeight: 'bold'
                }}>NO TABLE FOUND THERE</div>)
            }
            else {

                let rowCount = table.rows.length
                let colCount = table.rows[0].length
                let funny = table.rows.find(row=>row.length !== colCount)
                if (funny) {
                    return (<div style={{
                        color     : volcano.primary,
                        fontWeight: 'bold'
                    }}>Table is not uniform</div>)
                }

                return (<div className="d-row a-center gap-9">
                        <span>Table {fieldName[0] + 1}: </span>
                        <div style={{
                            color     : green.primary,
                            fontWeight: 'bold'
                        }}>{rowCount} Rows × {colCount} Columns
                        </div>
                        <Form.Item noStyle name={fieldName}>
                            <Input type="hidden" />
                        </Form.Item>
                    </div>

                )
            }
        }
        else {
            return (<Button onClick={() => {
                recogniser.grabTable(grabName)}}>Select Table Again</Button>)
        }
    }
}
export default PdfRecogniserV3;