import {dataPoint, set, get, getNullCells, getInitialValues} from './index'
import {useState} from "react";
import {
    ApplicationTypes, AssetTypes, DebtTypes, EmploymentTypes, Frequencies, MortgageTypes, PersonalAccommodationSituations, PersonalMaritalStatuses
} from "../../../models";
import dayjs from "dayjs";
import newConditionForm from "../../../app/components/tools-drawer/conditions-builder/NewConditionForm";

export const avantCoverNoteCoords = {
    propertyValue: 'E13',
    loanRequired : 'E11',
    loanTerm     : 'E9',
}
export const avantRules = (data) => {
    return {
        ...data,
        allowances1: (data.allowances1 || 0) + (data.other1 || 0),
        allowances2: (data.allowances2 || 0) + (data.other2 || 0),
        bonus1     : (data.bonus1 || 0) + (data.commission1 || 0),
        bonus2     : (data.bonus2 || 0) + (data.commission2 || 0),
    }
}
const sheetName = 'Calculator'
//const url = 'https://script.google.com/macros/s/AKfycbyenq26eTQkV9ltQ1v_dOiLlalG_bo90dkI7KR9M-DIcplK1dnADkw5E8unG0qpibiilA/exec'
//const spreadsheetId = '1unV4gEUyQQ_Awbdx4DTZ-lJo6yXPQbvvtxIFsFsfTfE'
//const url = 'https://script.google.com/macros/s/AKfycbxCthP7PEnTund_RMSfWOLihYdWzwqypgOCSXuJ7FWhBTf_HK86PqMzoNH2CQeg-RYb2A/exec'
const url = 'https://script.google.com/macros/s/AKfycbzBGLST8KxMdEf8C4s24-0yQlLRnD1ufBROkY9K4GEdkKR-pQrqNjIl9IS7q2HrBTWq/exec' //3.9
//const spreadsheetId = '1CeEvzP8kBEmPOJUiAS77xaIkwIzXSFf01ScyZk0V-OY'
const spreadsheetId = '1qvTrJ-E9PQp5ztYW2BoKBLXhwFI4ejGS85Jz0ur-WUM' //3.9
function avant({
    mortgage,
    useVerifiedFigures
})
{

    const retainingOrExistingOptions = [
        'Retained PDH',
        'Existing BTL'
    ]
    const dataPoints = {
        "Loan Details"            : [
            dataPoint('Product', 'J7', {
                type   : 'select',
                options: [
                    '3-year fixed',
                    '4-year fixed',
                    '5-year fixed',
                    '7-year fixed',
                    '10-year fixed',
                    'One Mortgage 15 years',
                    'One Mortgage 20 years',
                    'One Mortgage 25 years',
                    'One Mortgage 30 years'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your product'
                    },
                ]
            }, () => {
                return '3-year fixed'
            },),
            dataPoint('Type of Operation', 'E7', {
                type   : 'select',
                options: [
                    'First Time Buyer (FTB)',
                    'Mover',
                    'Straight switcher',
                    'Switcher with Equity Release',
                    'Mortgage Top Up'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your type of operation'
                    },
                ]
            }, () => {
                if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
                    return 'Straight switcher'
                }
                if (mortgage.applicationType === ApplicationTypes.FIRSTTIME) {
                    return 'First Time Buyer (FTB)'
                }
                if (mortgage.applicationType === ApplicationTypes.MOVINGHOME) {
                    return 'Mover'
                }
                return ''
            },),
            dataPoint('Loan Term', 'E9', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please input your loan term'
                    },
                    {
                        type   : 'number',
                        min    : 1,
                        max    : 40,
                        message: 'Please input a number between 1 and 40'
                    }
                ]
            }, () => {
                return mortgage.requirement.loanTerm || ''
            }, (formData) => {
                return formData.term
            }, (newFormData) => {
                return newFormData.provisionalTerm
            }),
            dataPoint('Loan Amount', 'E11', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please input your loan amount'
                    },
                    {
                        type   : 'number',
                        min    : 50000,
                        max    : 10000000,
                        message: 'Please input a number between 50000 and 10000000'
                    }
                ]
            }, () => {
                return mortgage.requirement.loanRequired || ''
            }, (formData) => {
                return formData.mortgageAmount
            }, (newFormData) => {
                return newFormData.provisionalLoan
            }),
            dataPoint('Property Value', 'E13', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please input your property value'
                    },
                    {
                        type   : 'number',
                        min    : 50000,
                        max    : 10000000,
                        message: 'Please input a number between 50000 and 10000000'
                    }
                ]
            }, () => {
                return mortgage.properties[0].propertyValue
            }, (formData) => {
                return formData.propertyValue
            }, (newFormData) => {
                return newFormData.provisionalValue
            }),
            dataPoint('Balance on Existing Mortgage', 'E15', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 10000000,
                        message: 'Please input a number between 0 and 10000000'
                    }
                ]
            }, () => {
                return ''
            },),
            dataPoint('Remaining Term on Existing Mortgage', 'E16', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 40,
                        message: 'Please input a number between 0 and 40'
                    }
                ]
            }, () => {
                return ''
            },),
            dataPoint('10% Surplus for Over Runs', 'E17', {
                type   : 'select',
                options: [
                    'Y',
                    'N'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your ten percent surplus for over runs'
                    },
                ]
            }, () => {
                return 'N'
            },),
        ],
        "Applicant 1 Details"     : [
            dataPoint('Age', 'E22', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please input your age'
                    },
                    {
                        type   : 'number',
                        min    : 18,
                        max    : 100,
                        message: 'Please input a number between 18 and 100'
                    }
                ]
            }, () => {
                if (!mortgage.applicant1.personal.dateOfBirth) {
                    return ''
                }
                const today = dayjs();
                const birthDate = dayjs(mortgage.applicant1.personal.dateOfBirth);
                const yearsDiff = today.diff(birthDate, 'year');
                return yearsDiff
            },),
            dataPoint('Marital Status', 'E24', {
                type   : 'select',
                options: [
                    'Single',
                    'Married',
                    'Widowed'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your marital status'
                    },
                ]
            }, () => {
                switch (mortgage.applicant1.personal.maritalStatus) {
                    case PersonalMaritalStatuses.REMARRIED:
                    case PersonalMaritalStatuses.MARRIED :
                        return 'Married'
                    case PersonalMaritalStatuses.WIDOWED :
                        return 'Widowed'
                    default:
                        return 'Single'
                }
            },),
            dataPoint('(Civil Servant) Basic PAYE ', 'E28', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                if (formData.sector1 === 'public') {
                    return formData.basic1
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector1 === 'public') {
                    return newFormData.basic1
                }
                return 0
            }),
            dataPoint('or Basic PAYE', 'E27', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED) {
                    return mortgage.applicant1.income.grossBasic
                }
                return 0
            }, (formData) => {
                if (formData.sector1 === 'private') {
                    return formData.basic1
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector1 === 'private') {
                    return newFormData.basic1
                }
                return 0
            }),
            dataPoint('or Basic Self Employed', 'E29', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                    return mortgage.applicant1.income.grossBasic
                }
                return 0
            }, (formData) => {
                if (formData.sector1 === 'self') {
                    return formData.basic1
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector1 === 'self') {
                    return newFormData.basic1
                }
                return 0
            }),
            dataPoint('Guaranteed Allowances', 'E30', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                return formData.allowances1 || 0
            }, (newFormData) => {
                let tot = 0
                if (newFormData.allowances1) {
                    tot += newFormData.allowances1
                }
                if (newFormData.other1) {
                    tot += newFormData.other1
                }
                return tot
            }),
            dataPoint('Guaranteed Overtime', 'E31', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (!mortgage.applicant1.income.incomeChecks) {
                    return ''
                }
                return mortgage.applicant1.income.incomeChecks.includes('OVERTIME') ? mortgage.applicant1.income.overtime : ''
            }, (formData) => {
                return formData.overtime1 || 0
            }, (newFormData) => {
                return newFormData.overtime1 || 0
            }),
            dataPoint('Bonus (This Year)', 'C35', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (!mortgage.applicant1.income.incomeChecks) {
                    return ''
                }
                return mortgage.applicant1.income.incomeChecks.includes('BONUS') ? mortgage.applicant1.income.bonus : ''
            }, (formData) => {
                return formData.bonus1 || 0
            }, (newFormData) => {
                return newFormData.year1Bonus1 || 0
            }),
            dataPoint('Bonus (Last Year)', 'C34', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            }, (formData) => {
                return formData.bonus1 || 0
            }, (newFormData) => {
                return newFormData.year2Bonus1 || 0
            }),
            dataPoint('Bonus (Two Years Ago)', 'C33', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            }, (formData) => {
                return formData.bonus1 || 0
            }, (newFormData) => {
                return newFormData.year3Bonus1 || 0
            }),
            dataPoint('Rental Income', 'E36', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return mortgage.applicant1.properties.filter(it => it.isRented).reduce((acc, it) => {
                    return acc + it.rentPerAnnum
                }, 0)
            }),
        ],
        "Applicant 2 Details"     : [
            dataPoint('Age', 'J22', {
                type : 'number',
                rules: [
                    {
                        required: true,
                        message : 'Please input your age'
                    },
                    {
                        type   : 'number',
                        min    : 18,
                        max    : 100,
                        message: 'Please input a number between 18 and 100'
                    }
                ]
            }, () => {
                if (!mortgage.twoApplicants) {
                    return ''
                }
                if (!mortgage.applicant2.personal.dateOfBirth) {
                    return ''
                }
                const today = dayjs();
                const birthDate = dayjs(mortgage.applicant2.personal.dateOfBirth);
                const yearsDiff = today.diff(birthDate, 'year');
                return yearsDiff
            },),
            dataPoint('Marital Status', 'J24', {
                type   : 'select',
                options: [
                    'Single',
                    'Married',
                    'Widowed'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your marital status'
                    },
                ]
            }, () => {
                if (!mortgage.twoApplicants) {
                    return ''
                }
                switch (mortgage.applicant2.personal.maritalStatus) {
                    case PersonalMaritalStatuses.REMARRIED:
                    case PersonalMaritalStatuses.MARRIED :
                        return 'Married'
                    case PersonalMaritalStatuses.WIDOWED :
                        return 'Widowed'
                    default:
                        return 'Single'
                }
            },),
            dataPoint('(Civil Servant) Basic PAYE', 'J28', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                if (formData.sector2 === 'public') {
                    return formData.basic2
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector2 === 'public') {
                    return newFormData.basic2
                }
                return 0
            }),
            dataPoint('or Basic PAYE', 'J27', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) {
                    return mortgage.applicant2.income.grossBasic
                }
                return 0
            }, (formData) => {
                if (formData.sector2 === 'private') {
                    return formData.basic2
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector2 === 'private') {
                    return newFormData.basic2
                }
            }),

            dataPoint('or Basic Self Employed', 'J29', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                    return mortgage.applicant2.income.grossBasic
                }
                return 0
            }, (formData) => {
                if (formData.sector2 === 'self') {
                    return formData.basic2
                }
                return 0
            }, (newFormData) => {
                if (newFormData.sector2 === 'self') {
                    return newFormData.basic2
                }
                return 0
            }, dataPoint('Guaranteed Allowances', 'J30', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return 0
            }, (formData) => {
                return formData.allowances2 || 0
            }, (newFormData) => {
                let tot = 0
                if (newFormData.allowances2) {
                    tot += newFormData.allowances2
                }
                if (newFormData.other2) {
                    tot += newFormData.other2
                }
                return tot
            })),
            dataPoint('Guaranteed Overtime', 'J31', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (!mortgage.twoApplicants || !mortgage.applicant2.income.incomeChecks) {
                    return ''
                }
                return mortgage.applicant2.income.incomeChecks.includes('OVERTIME') ? mortgage.applicant2.income.overtime : ''
            }, (formData) => {
                return formData.overtime2 || 0
            }, (newFormData) => {
                return newFormData.overtime2 || 0
            }),
            dataPoint('Bonus (This Year)', 'H35', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                if (!mortgage.twoApplicants || !mortgage.applicant2.income.incomeChecks) {
                    return ''
                }
                return mortgage.applicant2.income.incomeChecks.includes('BONUS') ? mortgage.applicant2.income.bonus : ''
            }, (formData) => {
                return formData.bonus2 || 0
            }, (newFormData) => {
                return newFormData.year1Bonus2 || 0
            }),
            dataPoint('Bonus (Last Year)', 'H34', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            }, (formData) => {
                return formData.bonus2 || 0
            }, (newFormData) => {
                return newFormData.year2Bonus2 || 0
            }),
            dataPoint('Bonus (Two Years Ago)', 'H33', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            }, (formData) => {
                return formData.bonus2 || 0
            }, (newFormData) => {
                return newFormData.year3Bonus2 || 0
            }),
            dataPoint('Rental Income', 'J36', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return mortgage.applicant2.properties.filter(it => it.isRented).reduce((acc, it) => {
                    return acc + it.rentPerAnnum
                }, 0)
            })
        ],
        "Monthly Expenses"        : [
            dataPoint('Dependent Adults', 'E43', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 10,
                        message: 'Please input a number between 0 and 10'
                    }
                ]
            }, () => {
                return ''
            },),
            dataPoint('Dependent Children', 'E44', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 10,
                        message: 'Please input a number between 0 and 10'
                    }
                ]
            }, () => {
                let number = mortgage.applicant1.personal.countDependents
                if (mortgage.twoApplicants) {
                    number += mortgage.applicant2.personal.countDependents
                }
                return number > 0 ? number : ''
            },),
            dataPoint('Childcare', 'J47', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000,
                        message: 'Please input a number between 0 and 1000'
                    }
                ]
            }, () => {
                let cost = 0
                if (mortgage.applicant1.personal.countDependents > 0) {
                    cost += mortgage.applicant1.financial.monthlyChildcareCosts
                }
                if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
                    cost += mortgage.applicant2.financial.monthlyChildcareCosts
                }
                return cost > 0 ? cost : ''
            },),

            dataPoint('Maintenance', 'J46', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000,
                        message: 'Please input a number between 0 and 1000'
                    }
                ]
            }, () => {
                let maint = 0
                if (mortgage.applicant1.financial.paysMaintenance) {
                    maint += mortgage.applicant1.financial.maintenanceCosts
                }
                if (mortgage.applicant2.financial.paysMaintenance) {
                    maint += mortgage.applicant2.financial.maintenanceCosts
                }
                return maint > 0 ? maint : ''
            },),
            dataPoint('Loan Commitments', 'J43', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 100000,
                        message: 'Please input a number between 0 and 100000'
                    }
                ]
            }, () => {
                // Does this include business leans

                const filter = debt => debt.debtType === DebtTypes.LOAN && !debt.clearedBeforeMortgage
                let tot = 0
                mortgage.applicant1.debts.filter(filter).forEach(it => {
                    tot += it.monthlyPayment
                })

                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(it => {
                        tot += it.monthlyPayment
                    })
                }

                return tot > 0 ? tot : ''
            }, (formData) => {
                const filter = debt => debt.debtType === DebtTypes.LOAN
                let tot = 0
                mortgage.applicant1.debts.filter(filter).forEach(it => {
                    if (!formData.clearingLoans1.includes(it.id)) {
                        tot += it.monthlyPayment
                    }
                })

                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(it => {
                        if (!formData.clearingLoans2.includes(it.id)) {
                            tot += it.monthlyPayment
                        }
                    })
                }

                return tot
            }),
            dataPoint('Revolving Credit Limits', 'J44', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 100000,
                        message: 'Please input a number between 0 and 100000'
                    }
                ]
            }, () => {
                const filter = debt => [
                    DebtTypes.CREDITCARD,
                    DebtTypes.STORECARD,
                    DebtTypes.OVERDRAFT
                ].includes(debt.debtType) && !debt.isClosed
                let tot = 0
                mortgage.applicant1.debts.filter(filter).forEach(it => {
                    tot += it.creditLimit
                })

                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(it => {
                        tot += it.creditLimit
                    })
                }

                return tot > 0 ? tot : ''
            },),

            dataPoint('Max RC Balance Utilisation', 'J45', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 100,
                        message: 'Please input a number between 0 and 100'
                    }
                ]
            }, () => {
                return ''
            },),
        ],
        "Proven Repayment Ability": [
            dataPoint('Existing Mortgage', 'E57', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            },),
            dataPoint('Rent', 'E58', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {

                let rent = 0
                if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                    rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                }
                if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                    rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                }

                if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant1.financial.contributeToRelatives) {
                    rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                }
                if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant2.financial.contributeToRelatives) {
                    rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                }
                return rent > 0 ? rent : ''
            }, null, (newFormData) => {
                let sum = newFormData.accommodationCosts1 || 0
                if (mortgage.twoApplicants) {
                    sum += newFormData.accommodationCosts2 || 0
                }
                return sum
            }),
            dataPoint('Savings', 'E59', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                let savings = 0
                let filter = asset => asset.assetType === AssetTypes.SAVING && [
                    Frequencies.MONTHLY,
                    Frequencies.WEEKLY
                ].includes(asset.increasesFrequency)
                mortgage.applicant1.assets.filter(filter).forEach(asset => {
                    if (asset.increasesFrequency === Frequencies.MONTHLY) {
                        savings += asset.increases
                    }
                    else {
                        savings += (asset.increases * 52 / 12)
                    }
                })
                if (mortgage.twoApplicants) {
                    mortgage.applicant2.assets.filter(filter).forEach(asset => {
                        if (asset.increasesFrequency === Frequencies.MONTHLY) {
                            savings += asset.increases
                        }
                        else {
                            savings += (asset.increases * 52 / 12)
                        }
                    })
                }
                return savings > 0 ? savings : ''
            }, (formData) => {
                let sum = formData.savings1 || 0
                if (mortgage.twoApplicants) {
                    sum += formData.savings2 || 0
                }
                return sum
            }, (newFormData) => {
                let sum = newFormData.savings1 || 0
                if (mortgage.twoApplicants) {
                    sum += newFormData.savings2 || 0
                }
                return sum
            }),
            dataPoint('Loan to Clear', 'E60', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                const filter = debt => debt.debtType === DebtTypes.LOAN && !!debt.clearedBeforeMortgage
                let tot = 0
                mortgage.applicant1.debts.filter(filter).forEach(it => {
                    tot += it.monthlyPayment
                })

                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(it => {
                        tot += it.monthlyPayment
                    })
                }

                return tot > 0 ? tot : ''
            }, (formData) => {
                let clearingLoans = 0
                let filter = debt => debt.debtType === DebtTypes.LOAN
                mortgage.applicant1.debts.filter(filter).forEach(debt => {
                    if (formData.clearingLoans1.includes(debt.id)) {
                        clearingLoans += debt.monthlyPayment
                    }
                })
                if (mortgage.twoApplicants) {
                    mortgage.applicant2.debts.filter(filter).forEach(debt => {
                        if (formData.clearingLoans2.includes(debt.id)) {
                            clearingLoans += debt.monthlyPayment
                        }
                    })
                }
                return clearingLoans
            }, (newFormData) => {
                let clearingLoans = newFormData.clearingLoans1 || 0
                if (mortgage.twoApplicants) {
                    clearingLoans += newFormData.clearingLoans2 || 0
                }
                return clearingLoans
            }),
            dataPoint('Other', 'E61', {
                type : 'number',
                rules: [
                    {
                        type   : 'number',
                        min    : 0,
                        max    : 1000000,
                        message: 'Please input a number between 0 and 1000000'
                    }
                ]
            }, () => {
                return ''
            }, null, (newFormData) => {
                let other = newFormData.otherPRA1 || 0
                if (mortgage.twoApplicants) {
                    other += newFormData.otherPRA2 || 0
                }
                return other
            })

        ],
        "Other"                   : [
            dataPoint('Property Identified', 'E64', {
                type   : 'select',
                options: [
                    'Yes',
                    'No'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your property identified'
                    },
                ]
            }, () => {
                return mortgage.properties[0].addressKnown ? 'Yes' : 'No'
            },),
            dataPoint('Property Type', 'E65', {
                type   : 'select',
                options: [
                    'House',
                    'Apartment/Duplex'
                ],
                rules  : [
                    {
                        required: true,
                        message : 'Please select your property type'
                    },
                ]
            }, () => {
                return mortgage.properties[0].propertyType && ![
                    'APARTMENT',
                    'FLAT'
                ].includes(mortgage.properties[0].propertyType) ? 'House' : 'Apartment/Duplex'
            },),
            dataPoint('Property Address', 'E66:G69', {
                type : 'text',
                rules: [
                    {
                        required: true,
                        message : 'Please input your property address'
                    },
                ]
            }, () => {
                return mortgage.properties[0].addressKnown ? mortgage.properties[0].address || '' : ''
            },)
        ],
        "Buy To Let": {
            "Property 1": [
                dataPoint('Remaining Mortgage', 'E50', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 1000000,
                            message: 'Please input a number between 0 and 1000000'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Rate', 'E51', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 10,
                            message: 'Please input a number between 0 and 10'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Remaining Term Months', 'E52', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 480,
                            message: 'Please input a number between 0 and 480'
                        }
                    ]
                }, () => {
                    return ''
                }),
                dataPoint('Retaining or Existing', 'E53', {
                    type   : 'select',
                    options: retainingOrExistingOptions,
                    rules  : [
                        {
                            required: true,
                            message : 'Please select your retaining or existing'
                        },
                    ]
                }, () => {
                    return ''
                },)
            ],
            "Property 2": [
                dataPoint('Remaining Mortgage', 'G50', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 1000000,
                            message: 'Please input a number between 0 and 1000000'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Rate', 'G51', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 10,
                            message: 'Please input a number between 0 and 10'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Remaining Term Months', 'G52', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 480,
                            message: 'Please input a number between 0 and 480'
                        }
                    ]
                }, () => {
                    return ''
                }),
                dataPoint('Retaining or Existing', 'G53', {
                    type   : 'select',
                    options: retainingOrExistingOptions,
                    rules  : [
                        {
                            required: true,
                            message : 'Please select your retaining or existing'
                        },
                    ]
                }, () => {
                    return ''
                },)
            ],
            "Property 3": [
                dataPoint('Remaining Mortgage', 'I50:J50', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 1000000,
                            message: 'Please input a number between 0 and 1000000'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Rate', 'I51:J51', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 10,
                            message: 'Please input a number between 0 and 10'
                        }
                    ]
                }, () => {
                    return ''
                },),
                dataPoint('Remaining Term Months', 'I52:J52', {
                    type : 'number',
                    rules: [
                        {
                            type   : 'number',
                            min    : 0,
                            max    : 480,
                            message: 'Please input a number between 0 and 480'
                        }
                    ]
                }, () => {
                    return ''
                }),
                dataPoint('Retaining or Existing', 'I53:J53', {
                    type   : 'select',
                    options: retainingOrExistingOptions,
                    rules  : [
                        {
                            required: true,
                            message : 'Please select your retaining or existing'
                        },
                    ]
                }, () => {
                    return ''
                },)
            ]
        },
    }
    const dataToReceive = {
        'LTV'                          : 'N6',
        'Pass LTV'                     : 'O6',
        'Expected Payment'             : 'J12',
        'Stressed Payment'             : 'J13',
        'Max Permitted LTV'            : 'N7',
        'Gross Loan To Income'         : 'N9',
        'Pass Gross Loan To Income'    : 'O9',
        'Net Loan To Income'           : 'N11',
        'Pass Net Loan To Income'      : 'O11',
        'Debt Service Ratio'           : 'N13',
        'Pass Debt Service Ratio'      : 'O13',
        'Net Disposable Income'        : 'N15',
        'Pass Net Disposable Income'   : 'O15',
        'Proven Repayment Ability'     : 'N17',
        'Pass Proven Repayment Ability': 'O17',
        'Max Loan Amount'              : 'N23',
        'Value For Max LTV'            : 'N24',
        'Message'                      : 'M19:N20',
        'Pass Message'                 : 'O19',
    }

    return {
        name         : 'avant',
        dataPoints,
        get          : (values, shouldSaveOutput) => {

            const blankedData = getNullCells(dataPoints)
            return get(url, spreadsheetId, sheetName, {...blankedData, ...values}, dataToReceive, mortgage, shouldSaveOutput)
        },
        initialValues: (data) => getInitialValues(dataPoints, data),
    }
}

export default avant;