import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";
import {Badge, Tag} from "antd";
import {Frequencies} from "../../../../../../../models";
import {legacyIncome} from "../../lender-tests/useXlsTests";

export const jobPayslips = (section) => {

    let anyAVC = false
    let anyPension = false
    let payslips = []
    if (!section.verified){
        console.warn({NotVerified:section})
    }

    let activeUploads = section.verified.uploads.active
    activeUploads.forEach(verifiedUpload => {
        let values = verifiedUpload.verified.values
        payslips.push({
            date : values.documentDate,
            gross: values.grossPay || 0,
            basic: values.grossBasic || 0,
            net  : values.netPay || 0,
        })
        if (!anyAVC && !!values.avcDeduction) {
            anyAVC = true
        }
        if (!anyPension && !!values.pensionDeduction) {
            anyPension = true
        }
    })

    let amts = payslips.map(p => p.gross)
    let uploadAverage = parseInt(amts.reduce((acc, cur) => acc + cur, 0) / amts.length)
    let averageGrossBasic;

    switch (section.data.payFrequency) {
        case Frequencies.MONTHLY:
            averageGrossBasic = uploadAverage * 12;
            break;
        case Frequencies.WEEKLY:
            averageGrossBasic = uploadAverage * 52;
            break;
        case Frequencies.FORTNIGHTLY:
            averageGrossBasic = uploadAverage * 26;
            break;
        case Frequencies.QUARTERLY:
            averageGrossBasic = uploadAverage * 4;
            break;
    }

    return {
        payslips, anyAVC , anyPension, averageGrossBasic, uploadAverage
    }
}
export const jobSalaryCert = (section, payFrequency) => {
    console.log({section, payFrequency})
    let certs = []
    let activeUploads = section.verified.uploads.active
    activeUploads.forEach(verifiedUpload => {
        let values = verifiedUpload.verified.values
        certs.push({
            date : values.documentDate,
            basic: values.grossBasic || 0,
        })
    })
    let mostRecentCert = certs.reduce((a, b) => a.date > b.date ? a : b, {date: null})
    let grossBasic = mostRecentCert.basic
    let expectedBasic = null
    switch (payFrequency) {
        case Frequencies.WEEKLY:
            expectedBasic =    `expected ${( grossBasic / (365 / 7)).toEuro()} weekly`
            break;
        case Frequencies.FORTNIGHTLY:
            expectedBasic = `expected ${(grossBasic / (365 / 14)).toEuro()} fortnightly`
            break;
        case Frequencies.MONTHLY:
            expectedBasic = `expected ${( grossBasic / 12).toEuro() } monthly`
            break;
        case Frequencies.QUARTERLY:
            expectedBasic = `expected ${(grossBasic / 4).toEuro()} quarterly`
            break;
        default:
            expectedBasic = 'Frequency not set'
            break;
    }
    return {grossBasic, expectedBasic}
}

function PayslipHistory({applicant}) {
    const mortgage = useMortgage()
    let legacy = legacyIncome(mortgage)
    let verifiedSectionPayslips
    let verifiedSectionSalaryCertificate
    let jobs = []
    // const addExpectedGrossBasic = ({payFrequency})=>{
    //     let mostRecentCert = certs.reduce((a, b) => a.date > b.date ? a : b, {date: null})
    //     certGrossBasic = mostRecentCert.basic
    //     switch (payFrequency) {
    //         case Frequencies.WEEKLY:
    //             expectedGrossBasic =    `expected ${( certGrossBasic / (365 / 7)).toEuro()} weekly`
    //             break;
    //         case Frequencies.FORTNIGHTLY:
    //             expectedGrossBasic = `expected ${(certGrossBasic / (365 / 14)).toEuro()} fortnightly`
    //             break;
    //         case Frequencies.MONTHLY:
    //             expectedGrossBasic = `expected ${( certGrossBasic / 12).toEuro() } monthly`
    //             break;
    //         case Frequencies.QUARTERLY:
    //             expectedGrossBasic = `expected ${(certGrossBasic / 4).toEuro()} quarterly`
    //             break;
    //         default:
    //             expectedGrossBasic = 'Frequency not set'
    //             break;
    //     }
    //     return mostRecentCert.basic
    // }


    if (legacy) {
        //PAYSLIP
        jobs.push(mortgage[`applicant${applicant}`].employment)
        verifiedSectionPayslips = mortgage.mortgageUploads.setup.find(s => s.name === `payslips-${applicant}` && !s.index)
        verifiedSectionSalaryCertificate = mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${applicant}` && !s.index)
        let jobsSalaryCert =  verifiedSectionSalaryCertificate ? jobSalaryCert(verifiedSectionSalaryCertificate, mortgage[`applicant${applicant}`].employment.payFrequency) : {}
        jobs = [ {
            employer  : mortgage[`applicant${applicant}`].employment.currentEmployerName,
           ...jobPayslips(verifiedSectionPayslips),
            ...jobsSalaryCert
        }]

    }
    else{
        jobs = mortgage[`applicant${applicant}`].employmentIncome.map((job, index) => {
            let verifiedSectionPayslips = mortgage.mortgageUploads.setup.find(s => s.name === `payslips-${applicant}` && s.index === job.id)
            let verifiedSectionSalaryCertificate = mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${applicant}` && s.index === job.id)
            let jobsSalaryCert =  verifiedSectionSalaryCertificate ? jobSalaryCert(verifiedSectionSalaryCertificate, job.payFrequency) : {}
            console.log({job})
            return {
                employer  : job.currentEmployerName,
                ...jobPayslips(verifiedSectionPayslips),
                ...jobsSalaryCert
            }
        })
    }

    const sixMonthsAgo = dayjs().subtract(6, 'month').startOf('month');
    const today = dayjs();
    const months = Array.from({length: 7}, (_, i) => sixMonthsAgo.add(i, 'month'));

    return (<div>
        {jobs.map((job, index) => (<>
            <div className="d-row gap-15 a-center">
                <h3>{job.employer} Payslips</h3>
                {job.grossBasic && <Tag color="green">Salary Cert: {job.grossBasic.toEuro()} ({job.expectedBasic})</Tag>}
                {job.anyAVC && <Tag color="geekblue">AVC</Tag>}
                {job.anyPension && <Tag color="orange">Pension</Tag>}

            </div>


            <div key={index} style={{
                position    : 'relative',
                background  : '#f0f0f0',
                marginBottom: '20px',
                height      : '70px'
            }}>

                {months.map((month, idx) => (<div
                    key={idx}
                    style={{
                        position  : 'absolute',
                        left      : `${((month.diff(sixMonthsAgo, 'day')) / today.diff(sixMonthsAgo, 'day')) * 100}%`,
                        height    : '100%',
                        borderLeft: '1px solid #000', //opacity   : 0.5
                    }}
                >
                        <span style={{
                            position: 'absolute',
                            top     : '100%',
                            left    : '-10px'
                        }}>{month.format('MMM')}</span>
                </div>))}
                {job.payslips.map((payslip, idx) => {
                    if (!payslip.date) {
                        return null;
                    }
                    const date = dayjs(payslip.date.$d);
                    const offset = Math.max(0, date.diff(sixMonthsAgo, 'day'));
                    const totalDays = today.diff(sixMonthsAgo, 'day');

                    return (<div
                        title={date.format('D MMM YYYY')}
                        key={idx}
                        style={{
                            position    : 'absolute',
                            left        : `${(offset / totalDays) * 100}%`,
                            transform   : 'translateX(-50%)',
                            background  : '#1890ff',
                            color       : '#fff',
                            borderRadius: '3px'
                        }}
                    >
                        <div style={{
                            padding : '3px',
                            fontSize: '12px'
                        }}>{payslip.basic.toFixed(0)}</div>
                        <div style={{
                            fontSize               : '12px',
                            background             : '#024f93',
                            padding                : '3px',
                            borderBottomLeftRadius : '3px',
                            borderBottomRightRadius: '3px'
                        }}>{payslip.gross.toFixed(0)}</div>
                    </div>);
                })}
            </div>

        </>))}

    </div>)

}

export default PayslipHistory;