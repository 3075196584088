import React, {useState} from 'react';
import {capitaliseFirst} from "../../../assets/scripts/string-functions";
import {Switch} from "antd";

function CreateMutationsFile() {
    const [mutations, setMutations] = useState([]);
    const [models, setModels] = useState([]);
    const [usersOnly, setUsersOnly] = useState(true)
    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileContent = await readFile(file);
            const parsedMutations = parseMutations(fileContent);
            setMutations(parsedMutations);
        }
    };
    const handleSchemaChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileContent = await readFile(file);

            const parsedModels = parseModels(fileContent);
            setModels(parsedModels);
        }
    };
    const parseModels = (fileContent) => {
        let parts = fileContent.split('enum')
        let interesting = parts[0]
        let attributes = {}
        parts = interesting.split('type ')
        parts.forEach(part => {

            let modelParts = part.split('@model')
            let name = modelParts[0].trim()
            let code = part.split('{')
            let myCode = code[code.length -1].replace('}','').trim()
            let lines = myCode.split('\n')
            let importantLines = []

            lines.forEach(line=>{
                if (line.includes('!')){
                    let pts = line.split(':')
                    let prop = pts[0].trim()
                    if (prop!== 'id'){
                        importantLines.push(pts[0].trim())
                    }
                }
            })
            if (importantLines.length){
                attributes[name]=importantLines
            }
        })

        return attributes

    }

    const parseMutations = (fileContent) => {
        //const regex = /export const (\w+) = \/\* GraphQL \*\/ `([\s\S]*?)`/g;
        const regex = /export const (\w+) = (\/\* GraphQL \*\/ `([\s\S]*?)`)/g;
        const mutations = [];
        let match;
        while ((match = regex.exec(fileContent)) !== null) {
            const [, mutationName, gql] = match;
            mutations.push({mutationName, gql});
        }
        return mutations;
    };
    function replaceContentBetweenBraces(inputString,) {
        const replacementText = "\n${responseItems}\n"

        let openBraceIndex = -1;
        let closeBraceIndex = -1;
        let braceCount = 0;
        let i;

        for (i = 0; i < inputString.length; i++) {
            if (inputString[i] === '{') {
                braceCount++;
                if (braceCount === 2) {
                    openBraceIndex = i;
                }
            } else if (inputString[i] === '}') {
                braceCount--;
                if (braceCount === 1) {
                    closeBraceIndex = i;
                }
            }
        }

        if (openBraceIndex !== -1 && closeBraceIndex !== -1) {
            const textBetweenBraces = inputString.substring(openBraceIndex + 1, closeBraceIndex);
            const replacedString = inputString.replace(textBetweenBraces, replacementText);
            return replacedString;
        } else {
            return inputString;
        }
    }
    let code = ``

    if (mutations.length) {
        code = `switch (mutationName) {\n`



        code += mutations.filter(mutation => {
            if (usersOnly) {
                return mutation.mutationName.startsWith('createUser') || mutation.mutationName.startsWith('updateUser') || mutation.mutationName.startsWith('deleteUser')
            }
            return true

        }).map((mutation, index) => {
            const replacementMutationString = replaceContentBetweenBraces(mutation.gql)
            return `
        case "${capitaliseFirst(mutation.mutationName)}":\n
        return ${replacementMutationString};\n`
        }).join('')
        code += `}\n`
    }


    const string = `
type UserMortgageQuery @model @auth(rules: [
    {allow: owner, operations: [read, create, update, delete]},
    {allow: groups, groupsField: "readAccessGroup", operations: [read]},
    {allow: groups, groupsField: "editAccessGroup", operations: [read,update]},
    {allow: groups, groups: ["admins"], operations: [read, create, update, delete]},
    {allow: groups, groups: ["supporters"], operations: [read, create, update, delete]}]) {
    id: ID!
    mortgageID: ID! @index(name: "byUserMortgage")
    createdBy: String
    stage: ApplicationStages
    referenceType: String
    referenceId: String
    section: String
    title: String
    date: AWSDate
    description: String
    amount: Float
    query: [String]
    userAnswer: [String]
    userAnswerOn: AWSDateTime
    userAnswerBy: String
    lenderAnswer: [String]
    lenderAnswerOn: AWSDateTime
    lenderAnswerBy: String
    excludedOn: AWSDateTime
    excludedBy: String
    private: Boolean
    readAccessGroup: [String]
    editAccessGroup: [String]
}`
    function extractAttributesWith1(schemaString) {
        // Regular expression to find attributes followed by ':', where the value contains '1'
        const regex = /(\w+):[^,]*!/g;
        let match;
        const attributes = [];

        while ((match = regex.exec(schemaString)) !== null) {
            // The captured group (attribute name) is at index 1
            attributes.push(match[1]);
        }

        return attributes;
    }
    function extractContentBetweenBraces(str) {
        const match = str.match(/{([^{}]*)(?:(?!{).)*}$/);

        if (match && match.length > 1) {

            return match[1]; // Returns the content between the first pair of {}
        } else {
            return null; // No match found or no content between braces
        }
    }

    return (<div>
        <div className="d-row">
            <input type="file" onChange={handleFileChange}/>

            UsersOnly:
            <Switch onChange={() => {
                setUsersOnly(s => !s)
            }} checked={usersOnly}/>

        </div>
        <div className="d-row">
            <input type="file" onChange={handleSchemaChange}/>

        </div>
        <div>
                <pre>
                    {code}
                </pre>
                <pre>
                    {JSON.stringify(models, null, 2)}
                </pre>
        </div>
    </div>);
}

export default CreateMutationsFile;
