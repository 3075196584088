import React, {useMemo} from 'react';
import {Link} from "react-router-dom";
import {Tag} from "antd";
import {categories} from "../../../providers/MortgagesProvider";
import {capitaliseFirst} from "../../../../assets/scripts/string-functions";
import {FlagFilled, LoadingOutlined} from "@ant-design/icons";
import {cyan, gray, orange, red} from "@ant-design/colors";
import {MortgageActiveAgents} from "../../../../models";

function MortgageTag({mortgage, to}) {

    return useMemo(() => {
        if (!mortgage) return <Tag className="m-0" style={{backgroundColor: 'transparent'}}><LoadingOutlined
            className="me-3"/> Loading ...</Tag>
        const link = to ? to : `/client/${mortgage.id}`
        const stage = categories.find(it => it.key === mortgage.applicationStage)
        const color = stage ? stage.color : 'default'

        const label = capitaliseFirst(mortgage.applicationStage?.toLowerCase())
        let flagColor = "red"
        switch (mortgage.activeAgent) {
            case MortgageActiveAgents.USER: flagColor = cyan.primary; break;
            case MortgageActiveAgents.BROKER: flagColor = red.primary; break;
            case MortgageActiveAgents.LENDER: flagColor = orange.primary; break;
        }
        let mValue = Math.round((mortgage?.requirement?.loanRequired||0 / 1000))
        return (
            <Link to={link}>
                <span className="d-col">
                    <span className="d-row">
                        <Tag className="br-tr-0 br-br-0 m-0"
                             style={{backgroundColor: 'transparent'}}>{mortgage.applicationTitle}</Tag>

                          <Tag className="br-tr-0 br-br-0 m-0 br-tl-0 br-bl-0 blw-0 brw-0"
                               style={{backgroundColor: 'transparent', color: gray[5]}}>{mValue}k</Tag>
                         <Tag className="br-tr-0 br-br-0 m-0 br-tl-0 br-bl-0 blw-0 brw-0"
                              style={{backgroundColor: 'transparent'}}><FlagFilled className="ms-3" style={{color:flagColor}}/></Tag>
                        <Tag className="br-tl-0 br-bl-0 m-0" color={color}
                             style={{backgroundColor: 'transparent'}}>{label}</Tag>
                    </span>
                    <span className="d-row">
                        <Tag className="br-tr-0 br-tl-0 m-0 btw-0"
                             style={{backgroundColor: 'transparent', fontSize: 10, color: gray[5]}}>
                            {mortgage.adminMessage}
                        </Tag>
                    </span>
                </span>

            </Link>
        );
    }, [mortgage?.applicationStatus])
}

export default MortgageTag;