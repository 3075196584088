import applicant from '../applicant'
import baseRowModel, {makeChainable} from "../../../system/model/row/baseRowModel";
import {updateUserMortgage} from "../../../../graphql/mutations";
import {onUpdateUserMortgage} from "../../../../graphql/subscriptions";

function mortgage({
    request,
    initialState
})
{
    const graphQl = {
        mutation    : {
            update: updateUserMortgage,
        },
        subscription: {
            update: onUpdateUserMortgage,
        }
    }
    const attribs = {
        writable  : {
            mortgageType   : 'String',
            twoApplicants  : 'Boolean',
            activeAgent    : 'String',
            adminMessage   : 'String',
            adminMessageBy : 'String',
            adminMessageOn : 'AWSDateTime',
            advisorID      : 'String',
            applicationFutureDate: 'AWSDate',
            applicationStage: 'String',
            applicationStatus: 'String',
            applicationType: 'String',
            data: 'AWSJSON',
            friendlyName: 'String',
            qualification:'String',
            qualificationConfirmed: 'AWSDateTime',
            uploadsPercentSetup: 'Int',
            uploadsPercentApply: 'Int',
            completedFlowItems: 'AWSJSON',
            completedFlowItemsForAdmin:'AWSJSON',
            editAccessGroup: '[String]',
            readAccessGroup: '[String]',
        },
        readable  : [
            'createdAt',
        ],
        subscribable: [
            'mortgageType',
            'applicationType',
            'twoApplicants',
            'activeAgent',
        ],
        deletable: false,
        fetchOnLoad: true,
        name:'mortgage'
    }

    // hasOne relationships
    const applicant1 = () => applicant({
        request,
        initialState: initialState.applicant1
    })
    const applicant2 = () => applicant({
        request,
        initialState: initialState.applicant2
    })

    // hasMany relationships

    const model = baseRowModel({
        request,
        initialState,
        graphQl,
        attribs
    })


    const descriptions = {
        firstTimeBuyers: null,
        mover          : null,
        switcher       : null,
    }


    return makeChainable( {
        ...model.fields,
        applicant1,
        applicant2,
        descriptions
    })
}

export default mortgage