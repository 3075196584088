import React from 'react';
import RoyalLondonQuote from "./RoyalLondonQuote";
import ZurichLifeQuote from "./ZurichLifeQuote";

function Quotes(props) {
    return (<div className="d-col gap-15 j-equal">
        <RoyalLondonQuote/>
        <ZurichLifeQuote/>

    </div>);
}

export default Quotes;