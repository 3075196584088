import React from 'react';
import {AuditOutlined, CloseOutlined, QuestionOutlined} from "@ant-design/icons";
import useMortgage from "../../../../../../../providers/mortgage/useMortgage";
import {Tag} from "antd";

function ChangedIcons({uploadId}) {
    let mortgage = useMortgage()
    let upload = mortgage.uploads.find(item => item.id === uploadId)
    const filter = query=>{
        return query.referenceId === uploadId
    }
    let hasQueries = mortgage.queries.filter(filter)
    if (!upload) return null
    let queryColor = 'green'

    if (hasQueries.length){
        let missingUserAnswers = hasQueries.some(it=>!it.private && !it.userAnswered)
        let missingLenderAnswers = hasQueries.some(it=>!it.excludedOn && !it.adminAnswered)
        queryColor = missingUserAnswers ? 'orange' : missingLenderAnswers ? 'red' : 'green'
    }
    let notStamped = !upload.stampedOn && (upload.section.startsWith('identification') || upload.section.startsWith('proof-of-address'))

    return (
        <div className="d-row gap-6">
            {!!upload.exclude && <Tag color="red">Excluded</Tag>}
            {!!upload.stampedOn && <AuditOutlined title="Stamped" style={{color: 'green'}}/>}
            {notStamped && <Tag color="red">No Stamp!</Tag>}
            {hasQueries.length > 0 && <QuestionOutlined title="Queries" style={{color: queryColor}}/>}
        </div>
    );
}

export default ChangedIcons;