export default function (mortgage, config) {
    const accountNumber = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'mortgage-account-number',
            label: 'Mortgage Account Number',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('mortgage-account-number')) {
            map['mortgage-account-number'] = config['mortgage-account-number']
        } else {
            if (!mortgage.requirement.mortgageAccountNo) {
                errors.push('No mortgage account number')
            } else {
                map['mortgage-account-number'] = mortgage.requirement.mortgageAccountNo
            }
        }
        return {map, ask, errors}
    }
    const iban = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'iban',
            label: 'IBAN',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('iban')) {
            map['iban'] = config['iban']
        } else {
            errors.push('No IBAN')
        }

        return {map, ask, errors}
    }
    const bic = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'bic',
            label: 'BIC',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('bic')) {
            map['bic'] = config['bic']
        } else  {
            errors.push('No BIC')
        }

        return {map, ask, errors}
    }

    const address = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            name: 'account-holder',
            label: 'Account Holder',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'address-1',
            label: 'Address Line 1',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'address-2',
            label: 'Address Line 2',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'address-3',
            label: 'Address Line 3',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'address-4',
            label: 'Address Line 4',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'post-code',
            label: 'Post Code',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        ask.push({
            name: 'country',
            label: 'Country',
            type: 'TEXT',
            group: 'MORTGAGE'
        })
        if (config.hasOwnProperty('account-holder')){
            map['account-holder'] = config['account-holder']
        }
        else{
            errors.push('No account holder')
        }

        if (config.hasOwnProperty('address-1')){
            map['holder-address-1'] = config['address-1']
            map['holder-address-2'] = config['address-2']
            map['holder-address-3'] = config['address-3']
            map['holder-address-4'] = config['address-4']
        }
        else{
            errors.push('No address')
        }
        if (config.hasOwnProperty('post-code')){
            map['holder-address-post-code'] = config['post-code']
        }
        else{
            errors.push('No post code')
        }

        if (config.hasOwnProperty('country')){
            map['holder-address-country'] = config['country']
        }
        else {
            map['holder-address-country'] = 'IRELAND'
        }
        return {map, ask, errors}
    }

    const sections = [
        accountNumber(),
        iban(),
        bic(),
        address(),
    ]
    return {sections}

}