import React, {useContext, PureComponent} from 'react';
import {Mortgages} from "../../../providers/MortgagesProvider";
import {Link} from "react-router-dom";
import {Card, Dropdown, Typography} from "antd";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {ApplicationStatuses} from "../../../../models";

function LenderByMonth(props) {
    const mortgages = useContext(Mortgages)
    const all = mortgages.ALL.filter(it=>it.applicationStatus !== ApplicationStatuses.SUSPENDED && !!it.requirement)
    const genData = (collection) => {
        const loanValues = []
        const noLoanValue = []
        collection.forEach(mortgage => {
            if (!mortgage.requirement) {

            }
            else if (!mortgage.requirement.loanRequired) {
                noLoanValue.push(mortgage)
            }
            else{
                loanValues.push(mortgage.requirement.loanRequired)
            }
        })
        const total = loanValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        let avg = ((total / loanValues.length) || 0)
        if (avg){
            avg = parseInt(avg.toFixed(0))
        }
        let length = loanValues.length
        return {length, total, avg, noLoanValue}
    }

    function DataCard({title, items}){
        const {length, total, avg, noLoanValue} = genData(items)
        const probs = noLoanValue.map(mortgage=>({
            key:mortgage.id,
            label: (<Link to={`/client/${mortgage.id}`}>{mortgage.applicationTitle}</Link>)
        }))
        return (
            <Card title={title}>

                <div className="d-row j-between"><span>Total:</span> <strong>{total.toEuro()}</strong></div>
                <div className="d-row j-between"><span>Avg:</span> <strong>{avg.toEuro()}</strong></div>
                <div className="d-row j-between"><span>Count:</span> <strong>{length}</strong></div>
                {noLoanValue.length > 0 && (
                    <Dropdown menu={{items: probs}} >
                        <a onClick={(e) => e.preventDefault()}>
                            {noLoanValue.length} without loan amount
                        </a>
                    </Dropdown>
                )}
            </Card>
        )
    }

    const getMortgageValuesForMonth = (lender, year, month) => {
        const monthString = month < 9 ? `0${month + 1}` : `${month + 1}`;
        const dateString = `${year}-${monthString}-01`;
        const filteredMortgages = all.filter(mortgage => {
            if (!mortgage.activeSubmission){
                if (!mortgage.requirement.lenderCode){
                    return false
                }
                if (mortgage.requirement.lenderCode !== lender){
                    return false
                }
                if (!mortgage.requirement.drawdownDate){
                    return false
                }
            }
            else{
                if (lender !== mortgage.activeSubmission.lenderCode){
                    return false
                }
                if (!mortgage.activeSubmission.drawdownDate){
                    return false
                }
            }

            
            const drewDown = mortgage.activeSubmission ? new Date(mortgage.activeSubmission.drawdownDate) : new Date(mortgage.requirement.drawdownDate);
            const testMonth = drewDown.getMonth()+1;
            const formattedMonth = testMonth < 10 ? `0${testMonth}` : `${testMonth}`;
            const drewDownString = `${drewDown.getFullYear()}-${formattedMonth}-01`;

            return drewDownString === dateString;
        });
        const {length, total, avg, noLoanValue} = genData(filteredMortgages)
        return {
            total,
            avg,
            length,
            noLoanValue
        }
    }
    function getMonthsSinceOctober2023() {
        const startYear = 2023;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // Note: getMonth() returns 0 for January, 1 for February, etc.
        const months = [];
        for (let year = startYear; year <= currentYear; year++) {
            const startMonth = year === startYear ? 9 : 0; // Start from Oct for the first year
            const endMonth = year === currentYear ? currentMonth : 11; // Go up to the current month for the current year
            for (let month = startMonth; month <= endMonth; month++) {
                months.push(
                    {
                        name: new Date(year, month, 1).toLocaleString('default', { month: 'short', year: 'numeric' }),
                        haven: getMortgageValuesForMonth('haven', year, month).total,
                       // boi: getMortgageValuesForMonth('boi', year, month).total,
                       // ics: getMortgageValuesForMonth('ics', year, month).total,
                        fi: getMortgageValuesForMonth('fi', year, month).total,
                        avant: getMortgageValuesForMonth('avant', year, month).total,
                        ptsb: getMortgageValuesForMonth('ptsb', year, month).total
                    }
                );
            }
        }

        return months;
    }
    const data = getMonthsSinceOctober2023()
    return (
        <>
            <div className="d-row j-center"> <Typography.Title>By Lender Per Month </Typography.Title> </div>
            <div className="d-row j-center">
                <BarChart
                    width={1100}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/*<Bar dataKey="boi" stackId="a" fill="#8884d8" />*/}
                    <Bar dataKey="avant" stackId="a" fill="#82ca9d" />
                    <Bar dataKey="fi" stackId="a" fill="#d89614" />
                    {/*<Bar dataKey="ics" stackId="a" fill="#f7878f" />*/}
                    <Bar dataKey="haven" stackId="a" fill="#49c1b4" />
                    <Bar dataKey="ptsb" stackId="a" fill="#8cbdea" />
                </BarChart>
            </div>
        </>
    );
}

export default LenderByMonth;