import React, {useEffect, useRef} from 'react';
import dayjs from "dayjs";

const OnDemandCache = React.createContext({})
export function CacheProvider({children}) {
    const [state, setState] = React.useState({})

    // const row={
    //     ...item
    //     cacheKey: '...',
    // }

    const create = (key, maxMinutes) => {
        setState(s => ({
                ...s,
                [key]: {
                    items          : [],
                    timeItemsLoaded: false,
                    created        : dayjs(),
                    maxMinutes
                }
            }))
    }

    const getCache = (key) => {
        let item = state[key]
        if (!item) {
            return null
        }
        if (dayjs().diff(item.created, 'minute') > item.maxMinutes) {
            delete state[key]
            return null
        }
        const setItems = (items, nextToken) => {

            setState(s => ({
                ...s,
                [key]: {
                    items          : [
                        ...s[key].items,
                        ...items
                    ],
                    timeItemsLoaded: dayjs(),
                    nextToken
                }
            }))
        }
        const replaceItem = (id,item)=>{

            setState(s => ({
                ...s,
                [key]: {
                    ...s[key],
                    items          : s[key].items.map(i=>i.id===id?item:i),
                }
            }))

        }
        const addItem = (item) => {
            setState(s => ({
                ...s,
                [key]: {
                    ...s[key],
                    items          : [...s[key].items, item],
                }
            }))
        }
        const addChildItem = (parentId, childProperty, childItem) => {
            console.log({parentId, childProperty, childItem})
            setState(s => ({
                ...s,
                [key]: {
                    ...s[key],
                    items          : s[key].items.map(row=>{
                        if (row.id === parentId){
                            console.log({row})
                            console.log({intrerest: row[childProperty]})
                            let rowItems = row[childProperty].items || []
                            return {
                                ...row,
                                [childProperty]: {
                                    ...row[childProperty],
                                    items: [...row[childProperty].items, childItem]
                                }
                            }
                        }
                        return row
                    }),
                }
            }))
        }
        return {
            ...item,
            setItems,
            replaceItem,
            addItem,
            addChildItem
        }
    }

    return (<OnDemandCache.Provider
        value={{
            create,
            getCache,
            items: state,
        }}>
        {children}
    </OnDemandCache.Provider>);
}
export function useCache(key, maxMinutes) {
    const cache = React.useContext(OnDemandCache)
    let myCache = cache.getCache(key)
    useEffect(() => {
        if (!myCache) {
            cache.create(key, maxMinutes)
        }
    }, [cache.items]);
    return myCache
}
export default useCache;