import {listAllAndSetItems} from "../functions";

export async function getMyTimeSlots(backendUserId, setTimeSlots ){
    const adminBackendUserTimeslotsByBackendUserId = /* GraphQL */ `
        query AdminBackendUserTimeslotsByBackendUserId(
            $backendUserId: ID!
            $sortDirection: ModelSortDirection
            $filter: ModelAdminBackendUserTimeslotsFilterInput
            $limit: Int
            $nextToken: String
        ) {
            adminBackendUserTimeslotsByBackendUserId(
                backendUserId: $backendUserId
                sortDirection: $sortDirection
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    backendUserId
                    month
                    slotTime
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                startedAt
                __typename
            }
        }
    `;

    await listAllAndSetItems({backendUserId: backendUserId}, adminBackendUserTimeslotsByBackendUserId, setTimeSlots)

}