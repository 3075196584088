import React from 'react';
import {Card, List, Typography} from "antd";
import {gray} from "@ant-design/colors";
import MortgageTag from "../../elements/mortgage/MortgageTag";

function OverflowedCard({children, title, bodyClass="px-15",...props}) {


    return (
        <Card title={title} styles={{body:{padding: 0, overflow: 'auto'}}} className="h-100 d-col" {...props}>

                <div className={bodyClass}>
                    {children}
                </div>


        </Card>
    );
}

export default OverflowedCard;