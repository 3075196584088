function generateQuery (queryName, responseItems){
    switch (queryName) {
        //DONT DELETE THE TOP 3
        case "GetUserMortgage": return /* GraphQL */ `
            query GetUserMortgage($id: ID!) {
                getUserMortgage(id: $id) {
                    ${responseItems}
                }
            }
        `;
        case "GetUserApplicant": return /* GraphQL */ `
            query GetUserApplicant($id: ID!) {
                getUserApplicant(id: $id) {
                    ${responseItems}
                }
            }
        `;
        case "GetAdminEmails" : return /* GraphQL */ `
            query GetAdminEmails($id: ID!) {
                getAdminEmails(id: $id) {
                    ${responseItems}
                }
            }
        `;

        case "CreateUserMortgageQuery":

            return /* GraphQL */ `
                mutation CreateUserMortgageQuery(
                    $input: CreateUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    createUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageQuery":

            return /* GraphQL */ `
                mutation UpdateUserMortgageQuery(
                    $input: UpdateUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    updateUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageQuery":

            return /* GraphQL */ `
                mutation DeleteUserMortgageQuery(
                    $input: DeleteUserMortgageQueryInput!
                    $condition: ModelUserMortgageQueryConditionInput
                ) {
                    deleteUserMortgageQuery(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgage":

            return /* GraphQL */ `
                mutation CreateUserMortgage(
                    $input: CreateUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    createUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgage":

            return /* GraphQL */ `
                mutation UpdateUserMortgage(
                    $input: UpdateUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    updateUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgage":

            return /* GraphQL */ `
                mutation DeleteUserMortgage(
                    $input: DeleteUserMortgageInput!
                    $condition: ModelUserMortgageConditionInput
                ) {
                    deleteUserMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageInvitation":

            return /* GraphQL */ `
                mutation CreateUserMortgageInvitation(
                    $input: CreateUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    createUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageInvitation":

            return /* GraphQL */ `
                mutation UpdateUserMortgageInvitation(
                    $input: UpdateUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    updateUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageInvitation":

            return /* GraphQL */ `
                mutation DeleteUserMortgageInvitation(
                    $input: DeleteUserMortgageInvitationInput!
                    $condition: ModelUserMortgageInvitationConditionInput
                ) {
                    deleteUserMortgageInvitation(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageDocuments":

            return /* GraphQL */ `
                mutation CreateUserMortgageDocuments(
                    $input: CreateUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    createUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageDocuments":

            return /* GraphQL */ `
                mutation UpdateUserMortgageDocuments(
                    $input: UpdateUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    updateUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageDocuments":

            return /* GraphQL */ `
                mutation DeleteUserMortgageDocuments(
                    $input: DeleteUserMortgageDocumentsInput!
                    $condition: ModelUserMortgageDocumentsConditionInput
                ) {
                    deleteUserMortgageDocuments(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProgress":

            return /* GraphQL */ `
                mutation CreateUserMortgageProgress(
                    $input: CreateUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    createUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProgress":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProgress(
                    $input: UpdateUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    updateUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProgress":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProgress(
                    $input: DeleteUserMortgageProgressInput!
                    $condition: ModelUserMortgageProgressConditionInput
                ) {
                    deleteUserMortgageProgress(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation CreateUserMortgageProgressNotes(
                    $input: CreateUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    createUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProgressNotes(
                    $input: UpdateUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    updateUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProgressNotes":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProgressNotes(
                    $input: DeleteUserMortgageProgressNotesInput!
                    $condition: ModelUserMortgageProgressNotesConditionInput
                ) {
                    deleteUserMortgageProgressNotes(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation CreateUserMortgageDynamicSection(
                    $input: CreateUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    createUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation UpdateUserMortgageDynamicSection(
                    $input: UpdateUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    updateUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageDynamicSection":

            return /* GraphQL */ `
                mutation DeleteUserMortgageDynamicSection(
                    $input: DeleteUserMortgageDynamicSectionInput!
                    $condition: ModelUserMortgageDynamicSectionConditionInput
                ) {
                    deleteUserMortgageDynamicSection(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageUploads":

            return /* GraphQL */ `
                mutation CreateUserMortgageUploads(
                    $input: CreateUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    createUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageUploads":

            return /* GraphQL */ `
                mutation UpdateUserMortgageUploads(
                    $input: UpdateUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    updateUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageUploads":

            return /* GraphQL */ `
                mutation DeleteUserMortgageUploads(
                    $input: DeleteUserMortgageUploadsInput!
                    $condition: ModelUserMortgageUploadsConditionInput
                ) {
                    deleteUserMortgageUploads(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageRequirement":

            return /* GraphQL */ `
                mutation CreateUserMortgageRequirement(
                    $input: CreateUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    createUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageRequirement":

            return /* GraphQL */ `
                mutation UpdateUserMortgageRequirement(
                    $input: UpdateUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    updateUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageRequirement":

            return /* GraphQL */ `
                mutation DeleteUserMortgageRequirement(
                    $input: DeleteUserMortgageRequirementInput!
                    $condition: ModelUserMortgageRequirementConditionInput
                ) {
                    deleteUserMortgageRequirement(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageProperty":

            return /* GraphQL */ `
                mutation CreateUserMortgageProperty(
                    $input: CreateUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    createUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageProperty":

            return /* GraphQL */ `
                mutation UpdateUserMortgageProperty(
                    $input: UpdateUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    updateUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageProperty":

            return /* GraphQL */ `
                mutation DeleteUserMortgageProperty(
                    $input: DeleteUserMortgagePropertyInput!
                    $condition: ModelUserMortgagePropertyConditionInput
                ) {
                    deleteUserMortgageProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageExisting":

            return /* GraphQL */ `
                mutation CreateUserMortgageExisting(
                    $input: CreateUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    createUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageExisting":

            return /* GraphQL */ `
                mutation UpdateUserMortgageExisting(
                    $input: UpdateUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    updateUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageExisting":

            return /* GraphQL */ `
                mutation DeleteUserMortgageExisting(
                    $input: DeleteUserMortgageExistingInput!
                    $condition: ModelUserMortgageExistingConditionInput
                ) {
                    deleteUserMortgageExisting(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageNeeds":

            return /* GraphQL */ `
                mutation CreateUserMortgageNeeds(
                    $input: CreateUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    createUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageNeeds":

            return /* GraphQL */ `
                mutation UpdateUserMortgageNeeds(
                    $input: UpdateUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    updateUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageNeeds":

            return /* GraphQL */ `
                mutation DeleteUserMortgageNeeds(
                    $input: DeleteUserMortgageNeedsInput!
                    $condition: ModelUserMortgageNeedsConditionInput
                ) {
                    deleteUserMortgageNeeds(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation CreateUserMortgageSolicitor(
                    $input: CreateUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    createUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation UpdateUserMortgageSolicitor(
                    $input: UpdateUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    updateUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgageSolicitor":

            return /* GraphQL */ `
                mutation DeleteUserMortgageSolicitor(
                    $input: DeleteUserMortgageSolicitorInput!
                    $condition: ModelUserMortgageSolicitorConditionInput
                ) {
                    deleteUserMortgageSolicitor(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation CreateUserMortgagePropertyOnMortgage(
                    $input: CreateUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    createUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation UpdateUserMortgagePropertyOnMortgage(
                    $input: UpdateUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    updateUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserMortgagePropertyOnMortgage":

            return /* GraphQL */ `
                mutation DeleteUserMortgagePropertyOnMortgage(
                    $input: DeleteUserMortgagePropertyOnMortgageInput!
                    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
                ) {
                    deleteUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicant":

            return /* GraphQL */ `
                mutation CreateUserApplicant(
                    $input: CreateUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    createUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicant":

            return /* GraphQL */ `
                mutation UpdateUserApplicant(
                    $input: UpdateUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    updateUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicant":

            return /* GraphQL */ `
                mutation DeleteUserApplicant(
                    $input: DeleteUserApplicantInput!
                    $condition: ModelUserApplicantConditionInput
                ) {
                    deleteUserApplicant(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantLastHere":

            return /* GraphQL */ `
                mutation CreateUserApplicantLastHere(
                    $input: CreateUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    createUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantLastHere":

            return /* GraphQL */ `
                mutation UpdateUserApplicantLastHere(
                    $input: UpdateUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    updateUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantLastHere":

            return /* GraphQL */ `
                mutation DeleteUserApplicantLastHere(
                    $input: DeleteUserApplicantLastHereInput!
                    $condition: ModelUserApplicantLastHereConditionInput
                ) {
                    deleteUserApplicantLastHere(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation CreateUserApplicantCurrentAccount(
                    $input: CreateUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    createUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation UpdateUserApplicantCurrentAccount(
                    $input: UpdateUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    updateUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantCurrentAccount":

            return /* GraphQL */ `
                mutation DeleteUserApplicantCurrentAccount(
                    $input: DeleteUserApplicantCurrentAccountInput!
                    $condition: ModelUserApplicantCurrentAccountConditionInput
                ) {
                    deleteUserApplicantCurrentAccount(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantTax":

            return /* GraphQL */ `
                mutation CreateUserApplicantTax(
                    $input: CreateUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    createUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantTax":

            return /* GraphQL */ `
                mutation UpdateUserApplicantTax(
                    $input: UpdateUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    updateUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantTax":

            return /* GraphQL */ `
                mutation DeleteUserApplicantTax(
                    $input: DeleteUserApplicantTaxInput!
                    $condition: ModelUserApplicantTaxConditionInput
                ) {
                    deleteUserApplicantTax(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantPersonal":

            return /* GraphQL */ `
                mutation CreateUserApplicantPersonal(
                    $input: CreateUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    createUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantPersonal":

            return /* GraphQL */ `
                mutation UpdateUserApplicantPersonal(
                    $input: UpdateUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    updateUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantPersonal":

            return /* GraphQL */ `
                mutation DeleteUserApplicantPersonal(
                    $input: DeleteUserApplicantPersonalInput!
                    $condition: ModelUserApplicantPersonalConditionInput
                ) {
                    deleteUserApplicantPersonal(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantIncome":

            return /* GraphQL */ `
                mutation CreateUserApplicantIncome(
                    $input: CreateUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    createUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantIncome":

            return /* GraphQL */ `
                mutation UpdateUserApplicantIncome(
                    $input: UpdateUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    updateUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantIncome":

            return /* GraphQL */ `
                mutation DeleteUserApplicantIncome(
                    $input: DeleteUserApplicantIncomeInput!
                    $condition: ModelUserApplicantIncomeConditionInput
                ) {
                    deleteUserApplicantIncome(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantFinancial":

            return /* GraphQL */ `
                mutation CreateUserApplicantFinancial(
                    $input: CreateUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    createUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantFinancial":

            return /* GraphQL */ `
                mutation UpdateUserApplicantFinancial(
                    $input: UpdateUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    updateUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantFinancial":

            return /* GraphQL */ `
                mutation DeleteUserApplicantFinancial(
                    $input: DeleteUserApplicantFinancialInput!
                    $condition: ModelUserApplicantFinancialConditionInput
                ) {
                    deleteUserApplicantFinancial(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantEmployment":

            return /* GraphQL */ `
                mutation CreateUserApplicantEmployment(
                    $input: CreateUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    createUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantEmployment":

            return /* GraphQL */ `
                mutation UpdateUserApplicantEmployment(
                    $input: UpdateUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    updateUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantEmployment":

            return /* GraphQL */ `
                mutation DeleteUserApplicantEmployment(
                    $input: DeleteUserApplicantEmploymentInput!
                    $condition: ModelUserApplicantEmploymentConditionInput
                ) {
                    deleteUserApplicantEmployment(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantAsset":

            return /* GraphQL */ `
                mutation CreateUserApplicantAsset(
                    $input: CreateUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    createUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantAsset":

            return /* GraphQL */ `
                mutation UpdateUserApplicantAsset(
                    $input: UpdateUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    updateUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantAsset":

            return /* GraphQL */ `
                mutation DeleteUserApplicantAsset(
                    $input: DeleteUserApplicantAssetInput!
                    $condition: ModelUserApplicantAssetConditionInput
                ) {
                    deleteUserApplicantAsset(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantDebt":

            return /* GraphQL */ `
                mutation CreateUserApplicantDebt(
                    $input: CreateUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    createUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantDebt":

            return /* GraphQL */ `
                mutation UpdateUserApplicantDebt(
                    $input: UpdateUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    updateUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantDebt":

            return /* GraphQL */ `
                mutation DeleteUserApplicantDebt(
                    $input: DeleteUserApplicantDebtInput!
                    $condition: ModelUserApplicantDebtConditionInput
                ) {
                    deleteUserApplicantDebt(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantProperty":

            return /* GraphQL */ `
                mutation CreateUserApplicantProperty(
                    $input: CreateUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    createUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantProperty":

            return /* GraphQL */ `
                mutation UpdateUserApplicantProperty(
                    $input: UpdateUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    updateUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantProperty":

            return /* GraphQL */ `
                mutation DeleteUserApplicantProperty(
                    $input: DeleteUserApplicantPropertyInput!
                    $condition: ModelUserApplicantPropertyConditionInput
                ) {
                    deleteUserApplicantProperty(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation CreateUserApplicantAlternateEmailAddresses(
                    $input: CreateUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    createUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation UpdateUserApplicantAlternateEmailAddresses(
                    $input: UpdateUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    updateUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantAlternateEmailAddresses":

            return /* GraphQL */ `
                mutation DeleteUserApplicantAlternateEmailAddresses(
                    $input: DeleteUserApplicantAlternateEmailAddressesInput!
                    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
                ) {
                    deleteUserApplicantAlternateEmailAddresses(
                        input: $input
                        condition: $condition
                    ) {
                        ${responseItems}
                    }
                }
            `;

        case "CreateUserApplicantActivity":

            return /* GraphQL */ `
                mutation CreateUserApplicantActivity(
                    $input: CreateUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    createUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "UpdateUserApplicantActivity":

            return /* GraphQL */ `
                mutation UpdateUserApplicantActivity(
                    $input: UpdateUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    updateUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;

        case "DeleteUserApplicantActivity":

            return /* GraphQL */ `
                mutation DeleteUserApplicantActivity(
                    $input: DeleteUserApplicantActivityInput!
                    $condition: ModelUserApplicantActivityConditionInput
                ) {
                    deleteUserApplicantActivity(input: $input, condition: $condition) {
                        ${responseItems}
                    }
                }
            `;
    }
}
export default generateQuery


