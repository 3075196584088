import React from 'react';
import {App, Button, Table} from "antd";
import PageHeader from "../../components/PageHeader";
import useDocumentsRequired from "../../providers/mortgage/useDocumentsRequired";
import {API} from "aws-amplify";
import useMortgage from "../../providers/mortgage/useMortgage";
import {fileNameAndExtension} from "../../../assets/scripts/string-functions";
import useMortgageDocumentsService from "../../providers/mortgage/useMortgageDocumentsService";

function DocumentCreationPage(props) {
    const app = App.useApp()
    const mortgage = useMortgage()
    const documents = useDocumentsRequired("APPLY")
    const documentService = useMortgageDocumentsService()
    const columns = [
        {
            title: 'Document Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            render: (_, item) => {
                return <Button onClick={() => createDocument(item)}>Create</Button>
            }
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (_, item) => {
                //console.log(item)
                if (item.document){
                    return <Button onClick={() => openDocument(item.document)}>Link</Button>
                }

            }
        },
    ];
    //console.log({props})
    const openDocument = async (document)=>{
        await documentService.open(document)
    }
    const createDocument = async (template) => {
        if (!mortgage.applicant1.identityID) {
            app.message.error('No applicant folder. The applicant signup is incomplete');
        } else {
            //console.log(template)
            const {id, name, elements, lender, title, folder} = template
            const init = {
                body: {
                    id,
                    name,
                    elements,
                    targetKey: `private/${mortgage.applicant1.identityID}/files/mortgage/${mortgage.id}/documents/${folder}/${title}.pdf`
                }
            }
            //console.log({init})
            const result = await API.post("sussdAdminAPI", "/operations/pdfs/overlay", init)
            //console.log(result)
            if (result.success) {
                // ADD UPLOAD DETAILS TO UserMortgageDocuments
                const pairs = {
                    title,
                    fileName: title,
                    fileExtension: 'pdf',
                    folder,
                    isDynamic: true,
                    identityID: mortgage.applicant1.identityID,
                    mortgageID: mortgage.id,
                    readAccessGroup: [`${mortgage.id}-app1`, `${mortgage.id}-app2`],
                    editAccessGroup: []
                }
                await mortgage.mutate({
                    create: {
                        target: 'UserMortgageDocuments',
                        pairs
                    }
                })
            }
        }

    }
    return (
        <>
            <PageHeader title="Lender DocumentsTable" back="./../" className="m-15"/>
            <Table dataSource={documents} columns={columns}/>
        </>

    )
}

export default DocumentCreationPage;