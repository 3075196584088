
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {haven} from "../../../../../../../assets/scripts/lender-calculators";

import {ApplicationTypes, MortgageTypes} from "../../../../../../../models";
import useXls, {useXlsNew} from "../useXls";
import {toEuroString} from "../../../../../../../assets/scripts/string-functions";

function useHavenXls(useVerifiedFigures = false) {
    const mortgage = useMortgage()
    const api = haven(mortgage)
    const getProcessedResults = (results)=>{
        let items= !!results ? Object.keys(results).map(key=>{
            return {
                key: key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items)=>{
            switch(key){
                //actualNets <= mexNets
                case 'Loan To Income':{
                    let ftb = mortgage.applicationType === ApplicationTypes.FIRSTTIME
                    let lti = parseFloat(items.find(it=>it.key === 'Loan To Income').value)
                    let exception = items.find(it=>it.key === 'Exception').value
                    if (ftb){
                        if (exception==='YES'){
                            return true
                        }
                        return lti <= 4
                    }
                    else{
                        if (exception==='YES'){
                            return true
                        }
                        return lti <= 3.5
                    }
                }
                case 'Message':{
                    let message = items.find(it=>it.key === 'Message').value || ''
                    return message.split(' ').includes('Within')
                }
                case 'LTV':{
                    let ltv = parseFloat(items.find(it=>it.key==='LTV').value)
                    return ltv <= 90
                }
                case 'NDI %':
                    let ndi = items.find(it=>it.key==='NDI %').value
                    return parseFloat(ndi) >= 0
                case 'Repayment Deficit/Surplus':
                    let ds = items.find(it=>it.key === 'Repayment Deficit/Surplus').value
                    return parseFloat(ds) >= 0
                default: return null
            }
        }
        items.forEach(item=>{
            let test = doTest(item.key, items)
            if (test !== null){
                item.passed = test
            }
        })

        let standardPayments = items.find(item=>item.key === 'MonthlyPaymentStandard').value.replace(/\D/g, '')
        let add = items.find(item=>item.key === 'MonthlyPaymentStandardAdd').value.replace(/\D/g, '')
        items.push({
            key: 'Estimated Monthly Payment',
            label: 'Estimated Monthly Payment',
            value: parseFloat(standardPayments) + parseFloat(add)
        })
        items = items.filter(item=>!['MonthlyPaymentStandard','MonthlyPaymentStandardAdd', 'LTV Breach'].includes(item.key))
        return items
    }
    return useXls(api,getProcessedResults, mortgage)
}
export function useHavenXlsNew(){
    const mortgage = useMortgage()
    const api = haven(mortgage)
    const getProcessedResults = (results)=>{
        let items= !!results ? Object.keys(results).map(key=>{
            return {
                key: key,
                label: key,
                value: results[key]?.trim()
            }
        }) : []
        const doTest = (key, items)=>{
            switch(key){
                //actualNets <= mexNets
                case 'Loan To Income':{
                    let ftb = mortgage.applicationType === ApplicationTypes.FIRSTTIME
                    let lti = parseFloat(items.find(it=>it.key === 'Loan To Income').value)
                    let exception = items.find(it=>it.key === 'Exception').value
                    if (ftb){
                        if (exception==='YES'){
                            return true
                        }
                        return lti <= 4
                    }
                    else{
                        if (exception==='YES'){
                            return true
                        }
                        return lti <= 3.5
                    }
                }
                case 'Message':{
                    let message = items.find(it=>it.key === 'Message').value || ''
                    return message.split(' ').includes('Within')
                }
                case 'LTV':{
                    let ltv = parseFloat(items.find(it=>it.key==='LTV').value)
                    return ltv <= 90
                }
                case 'NDI %':
                    let ndi = items.find(it=>it.key==='NDI %').value
                    return parseFloat(ndi) >= 0
                case 'Repayment Deficit/Surplus':
                    let ds = items.find(it=>it.key === 'Repayment Deficit/Surplus').value
                    return parseFloat(ds) >= 0
                default: return null
            }
        }
        items.forEach(item=>{
            let test = doTest(item.key, items)
            if (test !== null){
                item.passed = test
            }
        })

        let standardPayments = items.find(item=>item.key === 'MonthlyPaymentStandard').value.replace(/\D/g, '')
        let add = items.find(item=>item.key === 'MonthlyPaymentStandardAdd').value.replace(/\D/g, '')
        items.push({
            key: 'Estimated Monthly Payment',
            label: 'Estimated Monthly Payment',
            value: toEuroString( parseFloat(standardPayments) + parseFloat(add) )
        })
        return items.filter(item=>!['MonthlyPaymentStandard','MonthlyPaymentStandardAdd', 'LTV Breach'].includes(item.key)).map(item=>{
            if (['Repayment Capacity','Repayment Deficit/Surplus'].includes(item.key) ){
                item.value = toEuroString(item.value)
            }
            return item
        })
    }
    return useXlsNew(api,getProcessedResults, mortgage)

}
export default useHavenXls;