import {
    ApplicationTypes, BuildType, EmploymentBases, EmploymentTypes, MortgageTypes, PersonalAccommodationSituations
} from "../../models";
import {capitaliseFirst, capitaliseWords, toEuroString} from "./string-functions";
import dayjs from "dayjs";
import {
    balanceOfFundsCosts,
    balanceOfFundsFunds
} from "../../app/components/client/application-stages/_common/funding/BalanceOfFunds";
import {
    getApplicantClaimedAnnualOtherIncome,
    getApplicantMonthlyBasicIncome,
    getApplicantMonthlyGrossIncome, getApplicantMonthlySavings,
    lastSuccessfulHavenTest,
    lastSuccessfulPTSBTest,
    lastSuccessfulAvantTest
} from "./mortgage-figures";
import countries from "../scripts/countries.json"



function mortgageStrings(mortgage, app) {
    let {price, stampDuty, legalFees, propertyValuation, totalCosts} = balanceOfFundsCosts(mortgage)
    let {loan, gifts, helpToBuy, firstHome, bookingDepositPaid, contractDepositPaid, requiredSavings} = balanceOfFundsFunds(mortgage)
    const applicantStrings = (n) => {
        let mortgageApplicant = mortgage[`applicant${n}`]
        let employment = mortgageApplicant.employment
        if (mortgageApplicant.employmentIncome?.length && !!mortgageApplicant.employmentIncome[0].employmentType) {
            employment = mortgageApplicant.employmentIncome[0]
        }
        let monthlyBasic = getApplicantMonthlyBasicIncome(mortgage, n)
        let monthlyGross = getApplicantMonthlyGrossIncome(mortgage, n)
        let annualVariable = getApplicantClaimedAnnualOtherIncome(mortgage, n)
        return {
            fullName: () => {
                return mortgageApplicant.fullName
            },
            employmentStatus: () => {
                if (employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                    return 'Self Employed'
                } else if (employment.employmentType === EmploymentTypes.EMPLOYED) {
                    switch (employment.employmentBasis) {
                        case EmploymentBases.FULLTIME:
                            return 'Full time employed';
                        case EmploymentBases.PARTTIME:
                            return 'Part time employed';
                        case EmploymentBases.CONTRACT:
                            return 'Contract employed';
                        case EmploymentBases.TEMPFULLTIME:
                            return 'Temporary Full-time Employment';
                        case EmploymentBases.TEMPPARTTIME:
                            return 'Temporary Part-time Employment';
                    }
                } else {
                    return capitaliseFirst(employment.employmentType.toLowerCase())
                }
            },
            occupation: () => {
                return employment.positionHeld || ''
            },
            employerName: () => {
                return employment.currentEmployerName || ''
            },
            employmentStartDate: () => {
                return employment.currentEmployerStartDate ? dayjs(employment.currentEmployerStartDate, 'YYYY-MM-DD').format('D MMMM YYYY') : ''
            },
            maritalStatus: () => {
                return capitaliseFirst((mortgageApplicant.personal.maritalStatus || '').toLowerCase())
            },
            accommodation: () => {
                switch (mortgageApplicant.personal.accommodationSituation) {
                    case PersonalAccommodationSituations.RENTED :
                        return 'Renting'
                    case PersonalAccommodationSituations.RELATIVES :
                        return 'Lives with relatives'
                    case PersonalAccommodationSituations.OWNED:
                        return 'Owns home';
                    default:
                        return "???????"
                }
            },
            age: () => {
                return mortgageApplicant.age.toString() + ' years'
            },
            numberOfDependants: () => {
                return mortgageApplicant.personal.countDependents.toLocaleString('en-us')
            },
            homeStatus: () => {
                if (mortgage.twoApplicants && mortgage.requirement.cohabiting && n === 2) {
                    return 'Living together'
                }
                switch (mortgageApplicant.personal.accommodationSituation) {
                    case PersonalAccommodationSituations.RENTED :
                        return 'Renting'
                    case PersonalAccommodationSituations.RELATIVES :
                        return 'Lives with relatives'
                    case PersonalAccommodationSituations.OWNED:
                        return 'Owns home';
                    default:
                        return "???????"
                }
            },
            grossSalary: () => {
                if ([EmploymentTypes.EMPLOYED,EmploymentTypes.SELFEMPLOYED].includes(employment.employmentType)){
                    return toEuroString(mortgage.figures.verified[`basic${n}`].value)
                //    return employment.hasOwnProperty('incomes') ? toEuroString(employment.grossBasic) : toEuroString(mortgageApplicant.income.grossBasic)
                }
                return ''
            },
            grossSalaryMonthly: () => {
                if ([EmploymentTypes.EMPLOYED,EmploymentTypes.SELFEMPLOYED].includes(employment.employmentType)){
                    return toEuroString(mortgage.figures.verified[`basic${n}`].value / 12)
                  //  return employment.hasOwnProperty('incomes') ? toEuroString(employment.grossBasic) : toEuroString(mortgageApplicant.income.grossBasic)
                }
                return ''
               // return monthlyGross > 0 ? toEuroString(monthlyGross) : ''
            },
            monthlySalary: () => {
                return monthlyBasic > 0 ? toEuroString(monthlyBasic) : ''
            },
            basicMonthlyGuaranteed: () => {
                return monthlyBasic > 0
            },
            variableYearly: () => {
                return annualVariable > 0 ? toEuroString(annualVariable) : 'N/A'
            },
            variableMonthlyNotGuaranteed: () => {
                return annualVariable > 0
            },
            nationality: () => {
                let country = countries.find(it => it.alpha_2_code === mortgageApplicant.personal.nationality)
                if (!country) return 'NO COUNTRY SPECIFIED!!'
                return country.nationality
            }
        }

    }
    const lenderStrings = (lender) => {
        let xlsTestData
        let xlsResults

        switch (lender) {
            case "avant" :
                xlsResults = lastSuccessfulAvantTest(mortgage);
                return {
                    lenderTitle: 'Avant Money',
                    cashForLiving: () => {
                        return toEuroString(xlsResults.netDisposableIncome)
                    },
                    ltv: () => {
                        return xlsResults.ltv
                    },
                    mortgagePayment: () => {
                        return toEuroString(xlsResults.expectedPayment)
                    },
                    stressed: () => {
                        return toEuroString(xlsResults.stressedPayments)
                    },
                    xlsResults
                }
            case "ptsb" :
                xlsResults = lastSuccessfulPTSBTest(mortgage, app);

                let savings = xlsResults.itemsSent.praSavings === null || isNaN(xlsResults.itemsSent.praSavings) || xlsResults.itemsSent.praSavings === "" ? 0 : parseInt(xlsResults.itemsSent.praSavings)
                let rent = xlsResults.itemsSent.praRent === null  || isNaN(xlsResults.itemsSent.praRent) || xlsResults.itemsSent.praRent === "" ? 0 : parseInt(xlsResults.itemsSent.praRent)
                let otherPRA = xlsResults.itemsSent.praOther === null || isNaN(xlsResults.itemsSent.praOther) || xlsResults.itemsSent.praOther === "" ? 0 : parseInt(xlsResults.itemsSent.praOther)
                let mort = xlsResults.itemsSent.praRent === null || isNaN(xlsResults.itemsSent.praMortgage) ||  xlsResults.itemsSent.praMortgage === "" ? 0 : parseInt(xlsResults.itemsSent.praMortgage)

                let clearingLoans = isNaN(xlsResults.itemsSent.praClearingLoans) || xlsResults.itemsSent.praClearingLoans === "" ? 0 : parseInt(xlsResults.itemsSent.praClearingLoans)



                let pra = savings + rent + mort + clearingLoans + otherPRA

                return {
                    lenderTitle: 'Permanent TSB',
                    pra: () => {
                        return `${toEuroString(pra)} (${xlsResults.pra})`
                    },
                    mortgagePayment: () => {
                        return toEuroString(xlsResults.mortgage)
                    },
                    stressed: () => {
                        return toEuroString(xlsResults.stressed)
                    },
                    interestRate: () => {
                        return xlsResults.rate
                    },
                    actualNets: () => {
                        return xlsResults.actualNets
                    },
                    cashForLiving: () => {
                        return toEuroString(xlsResults.cashForLiving)
                    },
                    loanToIncome: () => {
                        return xlsResults.loanToIncome
                    },
                    ltv: () => {
                        return xlsResults.ltv
                    },
                    monthlyIncome: () => {
                        return toEuroString(xlsResults.monthlyIncome)
                    },
                    maxNets: () => {
                        return xlsResults.maxNets
                    },
                    mdiRequirement: () => {
                        return toEuroString(xlsResults.mdiRequirement)
                    },
                    maxForLoans: () => {
                        return toEuroString(xlsResults.maxForLoans)
                    },
                    annuallVariableIncome: () => {
                        return toEuroString(xlsResults.annuallVariableIncome)
                    },
                    rate: () => {
                        return xlsResults.rate
                    },
                    praCombinedSavings: () => {
                        return toEuroString(savings)
                    },
                    praCombinedAccommodation: () => {
                        if (rent + mort === 0) return false
                        return toEuroString(rent + mort)
                    },
                    praClearingLoans: () => {
                        if (clearingLoans === 0) return false
                        return toEuroString(clearingLoans)
                    },
                    praOther: () => {
                        if (otherPRA === 0) return false
                        return toEuroString(otherPRA)
                    },
                    app1VariableIncome: () => {
                        return xlsResults.app1VariableIncome > 0 ? toEuroString(xlsResults.app1VariableIncome) : false
                    },
                    app2VariableIncome: () => {
                        return xlsResults.app2VariableIncome > 0 ? toEuroString(xlsResults.app2VariableIncome) : false
                    },
                    xlsResults
                }
            case "haven" :
                xlsResults = lastSuccessfulHavenTest(mortgage, app);
                if (!xlsResults) {
                    return null
                }

                return {
                    lenderTitle: 'Haven Mortgages',
                    message: () => {
                        return xlsResults.message
                    },
                    loanToIncome: () => {
                        return parseFloat(xlsResults.lti)
                    },
                    ltv: () => {
                        return xlsResults.ltv
                    },
                    actualNDI: () => {
                        return toEuroString(xlsResults.actualNdi)
                    },
                    exception: () => {
                        return capitaliseWords(xlsResults.exception)
                    },
                    requiredNDI: () => {
                        return toEuroString(xlsResults.requiredNdi)
                    },
                    ndi: () => {
                        return xlsResults.ndi
                    },
                    stressed: () => {
                        return toEuroString(xlsResults.stressedPayments)
                    },
                    repaymentCapacity: () => {
                        return toEuroString(xlsResults.repaymentCapacity)
                    },
                    repaymentDeficit: () => {
                        return toEuroString(xlsResults.repaymentDeficit)
                    },
                    app1VariableIncome: () => {
                        return xlsResults.itemsSent.additionalIncome1 > 0 ? toEuroString(xlsResults.itemsSent.additionalIncome1) : false

                    },
                    app2VariableIncome: () => {
                        return xlsResults.itemsSent.additionalIncome2 > 0 ? toEuroString(xlsResults.itemsSent.additionalIncome2) : false

                    },
                    mortgagePayment: () => {
                        return toEuroString(xlsResults.monthlyPayment)
                    },
                    praRows: () => {
                        let praRows = []
                        console.log('xlsResults.itemsSent', xlsResults.itemsSent)
                        Object.keys(xlsResults.itemsSent).forEach(key => {
                            if (key.startsWith('repaymentCapacity')) {
                                if (key.endsWith('Name')) {
                                    let root = key.replace('Name', '')
                                    let name = xlsResults.itemsSent[key]
                                    let value = xlsResults.itemsSent[`${root}Value`]
                                    if (name!=="" && value!==""){
                                        praRows.push({key: name, value})
                                    }

                                }
                            }
                        })
                        return praRows
                    },
                    cashForLiving: () => {
                        return toEuroString(xlsResults.repaymentDeficit)
                    },
                    xlsResults
                }

            default:
        }
    }

    let property = mortgage.properties[0]
    if (!mortgage.activeSubmission.lenderCode){
        console.log('No lender code')
    }
    let resultLenderStrings = lenderStrings(mortgage.activeSubmission.lenderCode)
    if (!resultLenderStrings) {
        return null
    }
    return {
        // MORTGAGE
        applicantNames: () => {
            return mortgage.applicant1.fullName + (mortgage.twoApplicants ? ' and ' + mortgage.applicant2.fullName : '')
        },
        applicationType: () => {
            let applicationType = 'Refinance'
            if (mortgage.mortgageType !== MortgageTypes.REFINANCE) {
                switch (mortgage.applicationType) {
                    case ApplicationTypes.FIRSTTIME:
                        applicationType = 'First Time Buyer';
                        if (mortgage.properties[0].buildType === BuildType.SELFBUILD){
                            applicationType += '- Self Build'
                        }
                        break;
                    case ApplicationTypes.MOVINGHOME:
                        applicationType = 'Moving Home';
                        break;
                    case ApplicationTypes.SECONDTIME:
                        applicationType = 'Second Time Buyer';
                        break;
                    case ApplicationTypes.LANDLORD:
                        applicationType = 'Buy To Let';
                        break;
                }
            }
            return applicationType
        },
        totalDependents: () => {
            let count = mortgage.applicant1.personal.countDependents
            if (mortgage.twoApplicants) {
                count += mortgage.applicant2.personal.countDependents
            }
            return count
        },
        propertyDescription: () => {
            if (mortgage.properties[0].addressKnown) {
                return mortgage.properties[0].address
            }
            return 'Not yet identified'
            // let detailsOfProperty = []
            // if (property.bedrooms) {
            //     detailsOfProperty.push(property.bedrooms + ' bedroom')
            // }
            // if (property.bathrooms) {
            //     detailsOfProperty.push(property.bathrooms + ' bathroom')
            // }
            // if (property.propertyType) {
            //     detailsOfProperty.push(capitaliseWords(property.propertyType.toLowerCase()))
            // }
            // return joinWithConjunction(detailsOfProperty, '') + ' home'
        },
        combinedAnnualSalary: () => {
            let combinedAnnualSalary = (mortgage.applicant1.income.grossBasic || 0) + (mortgage.twoApplicants ? mortgage.applicant2.income.grossBasic || 0 : 0)
            return toEuroString(combinedAnnualSalary || 0)
        },
        combinedAnnualOtherIncome: () => {
            let combinedAnnualOtherIncome = getApplicantClaimedAnnualOtherIncome(mortgage, 1)

            if (mortgage.twoApplicants) {
                combinedAnnualOtherIncome += getApplicantClaimedAnnualOtherIncome(mortgage, 2)
            }

            return toEuroString(combinedAnnualOtherIncome)
        },
        combinedBasicSalaryMonthly: () => {
            let combinedBasicSalaryMonthly = getApplicantMonthlyBasicIncome(mortgage, 1)
            if (mortgage.twoApplicants) {
                combinedBasicSalaryMonthly += getApplicantMonthlyBasicIncome(mortgage, 2)
            }
            return toEuroString(combinedBasicSalaryMonthly)
        },
        averageCombinedMonthlySavings: () => {

           // return toEuroString(resultLenderStrings.xlsResults.itemsSent.praSavings)
            let averageCombinedMonthlySavings = getApplicantMonthlySavings(mortgage, 1)
            if (mortgage.twoApplicants){
                averageCombinedMonthlySavings += getApplicantMonthlySavings(mortgage, 2)
            }
            return toEuroString (averageCombinedMonthlySavings)
        },
        combinedAccommodationCostMonthly: () => {

           // return toEuroString(resultLenderStrings.xlsResults.itemsSent.praRent +

            let combinedAccommodationCostMonthly = mortgage.applicant1.financial.monthlyAccommodationCosts
            if (mortgage.twoApplicants && !mortgage.requirement.cohabiting){
                combinedAccommodationCostMonthly += mortgage.applicant2.financial.monthlyAccommodationCosts
            }
            return toEuroString (combinedAccommodationCostMonthly)
        },
        purchasePrice: () => {
            return toEuroString(resultLenderStrings.xlsResults.itemsSent.propertyValue)
        },
        propertyValue: () => {
            return toEuroString(resultLenderStrings.xlsResults.itemsSent.propertyValue)
        },
        rate: () => {
            return lenderStrings.rate()
        },
        term: () => {
            return (resultLenderStrings.xlsResults.itemsSent.loanTerm + ' years')
        },
        stampDuty: () => {
            return toEuroString(stampDuty)
        },

        legalFees: () => {
            return toEuroString(legalFees)
        },
        totalCosts: () => {
            return toEuroString(totalCosts)
        },
        requiredSavings: () => {
            return toEuroString(requiredSavings)
        },
        mortgageAmount: () => {
            return toEuroString(loan)
        },
        totalSavings: () => {
            return toEuroString(requiredSavings)
        },
        giftsIncluded: () => {
            return gifts > 0 ? toEuroString(gifts) : 0
        },
        firstHomeScheme: () => {

            return firstHome > 0 ? toEuroString(firstHome) : 0
        },
        helpToBuyScheme: () => {
            return helpToBuy > 0 ? toEuroString(helpToBuy) : 0
        },
        bookingDepositPaid: () => {
            return bookingDepositPaid > 0 ? toEuroString(bookingDepositPaid) : 0
        },
        contractDepositPaid:()=>{
            return contractDepositPaid > 0 ? toEuroString(contractDepositPaid) : 0
        },
        ...resultLenderStrings,
        app1: applicantStrings(1),
        app2: (()=>mortgage.twoApplicants ? applicantStrings(2) : null)(),
    }
}
export default mortgageStrings