import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";

function SignoutPage(props) {
    const nav = useNavigate()
    useEffect(() => {
        (async function checkUser() {
            try {
                await Auth.signOut()
                nav('/signin')
            } catch (e) {

            }
        })()
    }, []);
    return null
}

export default SignoutPage;