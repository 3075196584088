export function baseRowUpdaterFactory (request, graphQLQuery) {
    // the queue makes sure that the updates sequentially ensuring _version property is always current
    let updateQueue = [];
    let isProcessingUpdate = false;
    const processUpdateQueue = async () => {
        if (isProcessingUpdate) return;
        isProcessingUpdate = true;
        while (updateQueue.length > 0) {
            const updateFunction = updateQueue.shift();
            await updateFunction();
        }
        isProcessingUpdate = false;
    };

    const enqueueUpdate = (updateFunction) => {
        updateQueue.push(updateFunction);
        processUpdateQueue();
    }

    // this object holds all the updates that are made in quick succession
    let propertiesToUpdate = {};
    let updateTimer = null;
    const debounceUpdate = (newData) => {
        // Merge new data into the update object
        propertiesToUpdate = { ...propertiesToUpdate, ...newData };

        // Clear the existing timer if there is one
        if (updateTimer) {
            clearTimeout(updateTimer);
        }

        // Set a new timer to enqueue the update after 2 seconds
        updateTimer = setTimeout(() => {
            enqueueUpdate(async () => {
                //await performGQL(updateUserApplicantActivity, updateObject);
                // do update here
            });
            // Reset the update object and timer
            propertiesToUpdate = {};
            updateTimer = null;
        }, 2000);
    };
    return {
        update: debounceUpdate,
        is:{
            busy: isProcessingUpdate
        }
    }
}

