import React from 'react';

import InitialForm from "./parts/InitialForm";

import Funding from "../_common/funding/Funding";
import StagesLayout from "../_common/StagesLayout";
import Qualification from "./parts/Qualification";
import UploadsForOnBoarding from "./parts/UploadsForOnBoarding";
import OnboardingQueries from "./parts/OnboardingQueries";
import VerifiedFigures from "../_common/verified-figures/VerifiedFigures";
import useCurrentOutstandingItemsEmail from "../../../../../config-files/mortgage/emails/useCurrentOutstandingItemsEmail";
import {Button} from "antd";
import HaveAChat from "../_common/have-a-chat/HaveAChat";
import MissingVerifiedUploads from "../_common/uploads/MissingVerifiedUploads";

function ManagementOnBoarding(props) {
    let [activeKey, setActiveKey] = React.useState('Initial Form')
    const {composeOutstandingEmail} = useCurrentOutstandingItemsEmail()


    const tabs = [
        {
            key: 'Initial Form',
            label: 'Initial Form',
            children: <InitialForm/>,
        },
        // {
        //     key: 'Initial Qualification',
        //     label: 'Initial Qualification',
        //     children: <QualificationGo useVerifiedFigures={false}/>,
        // },
        {
            key: 'Lender Test',
            label: 'Lender Test',
            children: <Qualification/>,
        },
        {
            key: 'Uploads',
            label: 'Uploads',
           children: <UploadsForOnBoarding/>,
        },
        {
            key:'Upload Problems',
            label:'Upload Problems',
            children: <MissingVerifiedUploads stage={'setup'}/>
        },
        {
            key: 'Figures',
            label: 'Figures',
            children: <VerifiedFigures/>,
        },
        {
            key: 'Queries',
            label: 'Queries',
            children: <OnboardingQueries/>,
        },
        {
            key: 'Funding',
            label: 'Funding',
            children: <Funding/>,
        },
        {
            key: 'Chat',
            label: 'Have a Chat',
            children: <HaveAChat/>,
        },
        // {
        //     key: 'Verified Qualification',
        //     label: 'Verified Qualification',
        //     children: <QualificationGo useVerifiedFigures={true}/>,
        // },
    ];
    return <StagesLayout title="On-Boarding" tabs={tabs} activeKey={activeKey} setActiveKey={setActiveKey}  extras={[
        <Button key="Send Outstanding Items Email" onClick={()=>composeOutstandingEmail('SETUP')}> Send Outstanding Items Email</Button>
    ]}/>
}

export default ManagementOnBoarding;