import React, {useState} from 'react';
import {Alert, Button, Form, Input, Modal, Select} from "antd";
import {ApplicationStages} from "../../../../../../../models";
import {CloseOutlined} from "@ant-design/icons";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";

function CustomUploadModal(props) {
    const [form] = Form.useForm();
    const [state, setState] = useState({saving: false})
    const mortgage = useMortgage()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleFormFinish = async () => {
        try {
            const valid = await form.validateFields()
            setState(s => ({
                ...s,
                saving: true
            }))

            const specialInstructions = valid.requirements
            let name = valid.title.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '')
            if (valid.applicant) {
                name = `${name}-${valid.applicant}`
            }
            const create = {
                target: 'dynamicSections',
                pairs : {
                    sectionStage       : valid.stage,
                    sectionType        : 'UPLOAD',
                    sectionName        : name,
                    sectionTitle       : valid.title,
                    sectionInstructions: JSON.stringify({
                        children    : valid.children,
                        time        : valid.time,
                        instructions: {
                            specialRequirements: valid.requirements,
                            downloads          : valid.templates,
                        }
                    }),
                    mortgageID         : mortgage.id,
                    observeMID         : mortgage.id,
                }
            }
            await mortgage.mutate({create})
            form.setFieldsValue({})
            setState(s => ({
                ...s,
                saving: false
            }))
            handleCancel()
        }
        catch (e) {
            console.log(e)
        }
    }
    return (<>
        <Button type="primary" onClick={showModal}>
            Add Custom Section
        </Button>
        <Modal title="Add a custom upload section" open={isModalOpen} onOk={handleFormFinish} onCancel={handleCancel}>
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                validateTrigger={'onBlur'}
                key={`ConditionWriter`}
                form={form}
                requiredMark={false}
                initialValues={{
                    stage: mortgage.applicationStage === 'SETUP' ? 'APPLY' : mortgage.applicationStage,
                    time : '2 mins',

                }}
            >
                <Form.Item
                    label="Which Applicant"
                    name="applicant"
                    rules={[{required: true}]}
                >
                    <Select options={[
                        {
                            label: 'General',
                            value: 0
                        },
                        {
                            label: 'Applicant 1',
                            value: 1
                        },
                        {
                            label: 'Applicant 2',
                            value: 2
                        }
                    ]}
                    />
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message : `A title is required`
                        },
                        {
                            validator: (rule, value) => {
                                return new Promise((resolve, reject) => {
                                    //reject if the value has anything other than letters and spaces
                                    if (!/^[a-zA-Z\s]*$/.test(value)) {
                                        reject('Only letters and spaces are allowed')
                                    }
                                    // get the field value of applicant select
                                    let applicant = form.getFieldValue('applicant');
                                    let name = value.toLowerCase().replace(/ /g, '-')
                                    if (applicant) {
                                        name = `${name}-${applicant}`
                                    }
                                    let stage = form.getFieldValue('stage')
                                    // check does this exist already
                                    const exists1 = mortgage.dynamicSections.find(item => item.sectionStage === stage && item.sectionName === name)
                                    if (exists1) {
                                        reject(`A section with the name ${name} already exists`)
                                    }
                                    const exists2 = mortgage.progress.find(item => item.applicationStage === stage && item.sectionName === name)
                                    if (exists2) {
                                        reject(`A section with the name ${name} already exists`)
                                    }
                                    resolve()
                                })
                            }
                        }

                    ]}
                >
                    <Input style={{width: '100%'}} placeholder="eg. Certificate of Occupation"/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="children"
                    rules={[
                        {
                            required: true,
                            message : `A description is required`
                        }
                    ]}
                >
                    <Input.TextArea style={{width: '100%'}} placeholder="eg. Upload a certificate of occupation available from from your soclicitor"/>
                </Form.Item>
                <Form.Item
                    label="Stage"
                    name="stage"
                    rules={[
                        {
                            required: true,
                            message : `A stage is required`
                        }
                    ]}
                >
                    <Select style={{width: '100%'}}>
                        {[
                            ApplicationStages.APPLY,
                            ApplicationStages.PREAPPROVAL,
                            ApplicationStages.APPROVAL
                        ].map(u => <Select.Option value={u} key={`${u}`}>
                            {u}
                        </Select.Option>)}
                    </Select>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    label="Time in mins"*/}
                {/*    name="time"*/}
                {/*    rules={[{required: true, message: `A time is required`}]}*/}
                {/*    >*/}
                {/*    <Input style={{width: '100%'}}/>*/}
                {/*</Form.Item>*/}

                <Form.Item
                    label="Special Instructions"
                >
                    <Form.List name="requirements">
                        {(fields, {
                            add,
                            remove
                        }) => (<div className="d-col gap-6">
                            {fields.map((field) => (<div className="d-row gap-6">
                                <Form.Item
                                    className="mb-0 grow"
                                    {...field}>
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>
                                <CloseOutlined
                                    onClick={() => {
                                        remove(field.name);
                                    }}
                                />
                            </div>))}

                            <Button type="dashed" onClick={() => add()} block>
                                + Add Special Instruction
                            </Button>
                        </div>)}
                    </Form.List>
                </Form.Item>
                <Form.Item
                    label="Downloads"
                >
                    <Form.List name="templates">
                        {(fields, {
                            add,
                            remove
                        }) => (<div className="d-col gap-6">
                            {fields.map((field) => (<div className="d-row gap-6">
                                <Form.Item
                                    className="mb-0 grow"
                                    {...field}>
                                    <Input style={{width: '100%'}}/>
                                </Form.Item>
                                <CloseOutlined
                                    onClick={() => {
                                        remove(field.name);
                                    }}
                                />
                            </div>))}

                            <Button type="dashed" onClick={() => add()} block>
                                + Add Template
                            </Button>
                        </div>)}
                    </Form.List>
                </Form.Item>
            </Form>
        </Modal>
    </>);
}

export default CustomUploadModal;