import React, {useMemo} from 'react';
import {Button, Card, Form, Input, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

function useValueFromTextExtractor({text, initialValues}) {
    console.log({initialValues, text})
    const transformersToChain = (transformers)=>{
        let currValue = text
        if (!transformers){
            return [text]
        }
        return transformers.map(item=>{
            switch (item?.action){
                case 'split':
                    let v = currValue.split(item.value)
                    currValue = v
                    return v
                case 'getLast':
                    let last = currValue[currValue.length-1]
                    currValue = last
                    return last
                case 'toDate':
                    let date = dayjs(currValue, item.value).format('D MMM YYYY')
                    currValue = date
                    return currValue
                case 'toNumber':
                    let number = parseFloat(currValue.replace(/[^0-9.]/g, ''))
                    number = Math.round(number * 100) / 100
                    currValue = number
                    return number
                case 'remove':
                    let removed = currValue.replace(item.value,'')
                    currValue = removed
                    return removed
                case 'trim':
                    let trimmed = currValue.trim()
                    currValue = trimmed
                    return trimmed
                case 'getItem':
                    let itemAtIndex = currValue[parseInt(item.value)-1]
                    currValue = itemAtIndex
                    return itemAtIndex
                default:
                    return currValue
            }
        })
    }
    const [state, setstate] = React.useState({
        chain: transformersToChain(initialValues.transformers),
        transformers: initialValues.transformers || []
    });
    let transformers = {
        array:[
            {
                label: 'getItem',
                value: 'getItem',
                hasAttr: true,
            },
            {
                label: 'getLast',
                value: 'getLast',
            }
        ],
        string:[
            {
                label: 'split',
                value: 'split',
                hasAttr: true,
            },
            {
                label: 'toNumber',
                value: 'toNumber',
            },
            {
                label: 'toDate',
                value: 'toDate',
                hasAttr: true,
            },
            {
                label: 'remove',
                value: 'remove',
                hasAttr: true,
            },
            {
                label: 'trim',
                value: 'trim',
            },
        ]
    }
    const getOptionsForIndex = (i)=>{
        console.log({check: state.chain[i], i})
        if (!state.chain[i] && state.chain[i] !== 0){
            return transformers.string
        }
        if (typeof state.chain[i] === 'string' || typeof state.chain[i] === 'number'){
            return transformers.string
        }
        return transformers.array
    }

    const transformValue = (_,values)=>{
        console.log({values})
        let chain = transformersToChain(values.transformers)
        setstate({chain, transformers : values.transformers})
    }
    let formComponent =  <Form  key={text} onValuesChange={transformValue} initialValues={initialValues} >
            <Form.List
                name="transformers"
            >
                {(fields, {
                    add,
                    remove
                }, {errors}) => (<div className="d-col gap-3">
                    {fields.map((field, index) => {
                        console.log({field})
                        let name = field.name
                        console.log({index})
                        const opts = getOptionsForIndex(index)
                        console.log({opts})
                        return (
                            <div className="mt-15 d-col grow gap-3"  key={field.key}>
                                <div className="d-row grow gap-3 a-center ">
                                    <Form.Item noStyle name={[name,'action']}>
                                        <Select style={{width: '40%'}} title="Action"
                                                options={opts}/>
                                    </Form.Item>
                                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => {
                                        if (!prevValues.transformers && !!curValues.transformers) {
                                            console.log({shouldUpdate:true})
                                            return true
                                        }
                                        console.log({shouldUpdate:prevValues.transformers[index]?.action !== curValues.transformers[index]?.action})
                                        return prevValues.transformers[index]?.action !== curValues.transformers[index]?.action
                                    }}>
                                        {({getFieldValue}) => {
                                            let field = getFieldValue('transformers')
                                            console.log({field})
                                            let action = field[index]?.action
                                            let item = [...transformers.string,...transformers.array].find(item=>item.value === action)
                                            if (item?.hasAttr) {
                                                return (

                                                    <Form.Item noStyle name={[
                                                        name,
                                                        'value'
                                                    ]}>
                                                        <Input style={{width: '40%'}}/>
                                                    </Form.Item>

                                                )
                                            }
                                            return null
                                        }}
                                    </Form.Item>

                                    <MinusCircleOutlined
                                        className="ms-auto dynamic-delete-button"
                                        onClick={() => {
                                            remove(name);
                                        }}
                                    />

                                </div>
                                {['string','number'].includes(typeof state.chain[index] ) && (<div>{state.chain[index]}</div>)}
                                {Array.isArray(state.chain[index]) && (<div><strong>[</strong>{state.chain[index]?.join(' , ')}<strong>]</strong></div>)}
                            </div>
                        )
                    })}
                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined/>}
                        >
                            Add Transformation
                        </Button>
                    </Form.Item>
                </div>)}
            </Form.List>

        </Form>
    console.log(state)
    return {
        formComponent,
        transformers: state.transformers,
        transformedValue: state.chain[state.chain.length-1]
    };
}

export default useValueFromTextExtractor;