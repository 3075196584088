import React from 'react';
import useS3 from "./useS3";
import useMortgageUploadService from "./mortgage/useMortgageUploadService";
import * as pdfjsLib from 'pdfjs-dist'
import {PdfIdentifyContext} from "./PdfIdentifyProvider";
import dayjs from "dayjs";

function useStatementScanning() {
    let tX = 4
    let tY = 5
    const s3 = useS3()
    const uploads = useMortgageUploadService()
    const {data} = React.useContext(PdfIdentifyContext)
    const findText = (items, area) => {
        console.log({items})
        let matches = items.filter(item => {
            if (item.str.trim() === '') return false
            let x1 = item.transform[tX]
            let x2 = item.transform[tX] + item.width
            let y1 = item.transform[tY]
            let y2 = item.transform[tY] + item.height
            return x1 > area.minX && x2 < area.maxX && y1 > area.minY && y2 < area.maxY
        })
        if (!matches.length) return false
        return matches.map(m => m.str).join('\n')
    }
    const getResizedArea = (scale, area)=>{
        console.log({scale,area})
        return {
            minX: area.minX * scale,
            minY: area.minY * scale,
            maxX: area.maxX * scale,
            maxY: area.maxY * scale
        }
    }
    const getScale = (origW, origH, newW, newH) => {
        let scaleX = ( newW / origW ).toFixed(2)
        let scaleY = ( newH / origH ).toFixed(2)
        if (scaleX !== scaleY){
            console.log('Scale X and scale y don\'t match original', {scaleX, scaleY})
        }
        return scaleX
    }
    const identify = async ({items, width, height}) => {
        for (let i = 0; i < data.length; i++) {
            let [w,h] = data[i].data.dimensions
            let scale = getScale(w,h,width,height)

            let idents = data[i].data.identifiers
            let match = idents.every(identifier => {
                let area = getResizedArea(scale, identifier.area)
                let match = findText(items, area)
                switch (identifier.condition) {
                    case 'equalTo':
                        return identifier.not ? !(match && match.trim() === identifier.string.trim()) : !!(match && match.trim() === identifier.string.trim());
                    case 'startsWith':
                        return identifier.not ? !(match && match.trim().startsWith(identifier.string.trim())) : !!(match && match.trim().startsWith(identifier.string.trim()));
                    case 'endsWith':
                        return identifier.not ? !(match && match.trim().endsWith(identifier.string.trim())) : !!(match && match.trim().endsWith(identifier.string.trim()));
                    case 'includes':
                        return identifier.not ? !(match && match.trim().includes(identifier.string.trim())) : !!(match && match.trim().includes(identifier.string.trim()));
                }
            })
            console.log({match})
            if (match) return {...data[i], scale}
        }
        return false
    }
    const getPages = async (upload) => {
        const {path, config} = uploads.get(upload)
        config.download = true
        const pdfUrl = await s3.getS3Url({path, config})
        const pdfData = await pdfUrl.Body.arrayBuffer();
        const pdf = await pdfjsLib.getDocument({data: pdfData}).promise;
        const numPages = pdf.numPages;
        let pages = []
        for (let i = 1; i <= numPages; i++) {
            pages.push(new Promise(async resolve => {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({scale: 1.0});
                const content = await page.getTextContent();
                resolve({items: content.items, width: viewport.width, height: viewport.height})
            }))
        }
        return Promise.all(pages)
    }

    const doRowCleanUp = (allRows) => {
        const possibleFormats = ["D MMM YYYY", "DD MMM YYYY", "MMM D YYYY", "MMM D, YYYY"]
        let currentDate
        let rows = []
        // clean descriptions
        allRows.forEach((row, i) => {
            if (!!row.date) {
                currentDate = row.date
            }
            if (!!row.debit || !!row.credit || i === 0) {
                row.date = dayjs(currentDate, possibleFormats, true)
                rows.push(row)
            } else {
                if (!!row.balance) {
                    rows[rows.length - 1].balance = row.balance
                }
                if (!row.date) {
                    if (!rows[rows.length - 1].details.includes("\n" + row.details)) {
                        rows[rows.length - 1].details += "\n" + row.details
                    }
                } else {
                    console.log('what to do here', row)
                }
            }
        })
        return rows
    }
    const scanForTransactions = (identified, items, pageIndex, scale) => {
        const getAllYsAfter = (y) => {
            let ys =  items.filter(item => item.transform[tY] < y).map(item => item.transform[tY])
            let sorted = [...new Set(ys)].sort((a, b) => b - a )
            return sorted
        }
        const getTransactionsHeaderY = () => {
            let headerColumns = identified.data.header
            let start = items.findIndex((item, i) => {
                if (items.length < (i + headerColumns.length)) {
                    return false
                }
                let nextValues = new Array(headerColumns.length).fill(0).map((_, j) => {
                    return items[i + j].str
                })
                return nextValues.every((string, j) => {
                    return string && string === headerColumns[j]
                })
            })
            if (start === -1) return false
            return items[start].transform[tY]
            //console.log({Header: items[start].transform[tY]})
           // return start + headerColumns.length
        }
        const getNextRowYAfter = (y) => {
            let detailsItemsOnRow = items.find((item, i) => {
                if (item.str.trim() === '') return false
                if (item.transform[tY] >= y) return false
                let area = getResizedArea(scale,identified.data.columns.details)
                return !(item.transform[tX] < area.minX || item.transform[tX] + item.width > area.maxX);
            })
            if (!detailsItemsOnRow) return false
            return detailsItemsOnRow.transform[tY]
        }
        const getRowOnY = (y) => {
            const onRow = (item) => item.transform[tY] === y && item.str.trim() !== ''
            const onCol = (item, col) => {
                col = getResizedArea(scale,col)
                return item.transform[tX] >= col.minX && item.transform[tX] + item.width <= col.maxX
            }
            let dateIndex = items.findIndex(item => {
                return onRow(item) && onCol(item, identified.data.columns.date)
            })
            let debitIndex = items.findIndex(item => {
                return onRow(item) && onCol(item, identified.data.columns.debits)
            })
            let creditIndex = items.findIndex(item => {
                return onRow(item) && onCol(item, identified.data.columns.credits)
            })
            let balanceIndex = items.findIndex(item => {
                return onRow(item) && onCol(item, identified.data.columns.balance)
            })
            let details = items.filter(item => {
                return onRow(item) && onCol(item, identified.data.columns.details)
            }).sort((a, b) => a.transform[tX] - b.transform[tX]).map(item => item.str).join(' ')
            return {

                    pageIndex,
                    date: dateIndex > -1 ? items[dateIndex].str : false,
                    debit: debitIndex > -1 ? items[debitIndex].str : false,
                    credit: creditIndex > -1 ? items[creditIndex].str : false,
                    balance: balanceIndex > -1 ? items[balanceIndex].str : false,
                    details
            }
        }
        let afterY = getTransactionsHeaderY()
        if (!afterY) return false
        let nextYs = getAllYsAfter(afterY)
       // let currentY = getNextRowYAfter(afterY)
        let currentY = nextYs.shift()
        let rows = []
        while (!!currentY) {
            let rowData = getRowOnY(currentY)
            rows.push(rowData)

            //currentY = getNextRowYAfter(currentY)
            currentY = nextYs.shift()

        }
        return doRowCleanUp(rows)
    }
    const extract = (identified, pages) => {
        const resolveSequence = sequence => {
            let x = sequence
            if (identified.data.xofy){
                let [a,b] = sequence.split('of')
                x = a.replace(/\D/g,'')
            }
            return parseInt(x)
        }
        let result = pages.map((page, i) => {
            let scale = getScale(identified.data.dimensions[0], identified.data.dimensions[1], page.width, page.height)
            return {
                pageNumber: i + 1,
                sequence: findText(page.items, getResizedArea(scale,identified.data.areas.sequence)),
                transactions: scanForTransactions(identified, page.items, i , scale),
            }
        })
        let pagesWithTransactions = result.filter(page => !!page.transactions)
        let sequenceArray = pagesWithTransactions.map(page => resolveSequence(page.sequence))
        let isSequential = sequenceArray.every((val, i, arr) => i === 0 || val === arr[i - 1] + 1)
        let omit = result.flatMap((page, i) => {
            return (page.transactions) ? [] : [i + 1]
        })
        let allTransactions = pagesWithTransactions.flatMap(page => page.transactions)
        let firstTransaction = allTransactions[0]
        let lastTransaction = allTransactions[allTransactions.length - 1]
        let startDate = firstTransaction.date
        let endDate = lastTransaction.date
        console.log({firstTransaction,lastTransaction})

        let openingBalance = parseFloat(firstTransaction.balance.replace(/[^0-9.]/g, ''))
        let closingBalance = parseFloat(lastTransaction.balance.replace(/[^0-9.]/g, ''))
        return {
            isSequential,
            omit,
            openingBalance,
            closingBalance,
            startDate,
            endDate,
            transactions: allTransactions
        }
    }
    return {
        read: async (upload) => {
            let pages = await getPages(upload)
            let firstPage = pages[0]
            let identified = await identify(firstPage)
            console.log({identified})
            if (identified) {
                return {
                    institution: identified.institution,
                    friendlyName: identified.friendlyName,
                    pdfType: identified.pdfType,
                    accountType: identified.subType,
                    foreign: identified.data.foreign,
                    currency: identified.data.currency,
                    accountAddress: findText(firstPage.items, getResizedArea(identified.scale,identified.data.areas.address)),
                    iban: findText(firstPage.items, getResizedArea(identified.scale,identified.data.areas.iban)),
                    numberOfPages: pages.length,
                    ...extract(identified, pages)
                }
            }
            return false
        }
    }
}
export default useStatementScanning;