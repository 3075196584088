import {Form, Input, InputNumber} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../../../../assets/scripts/parser-functions";
import React from "react";

export const CustomInputNumber = ({
    name, disabled, value, as, prefix
}) => {
    const props={
        FormItem:{
            noStyle: true,
            name
        },
        Input:{
            disabled,
            style:{
                width: '100%', minWidth: 114
            },
        }
    }
    if(prefix){
        props.Input.prefix=prefix
    }
    switch(as){
        case "int":
            break;
        default:
            props.Input = {
                ...props.Input,
                prefix: '€',
                formatter: currencyFormatter,
                parser: currencyParser
            }
    }
    if (!name) {
        return <InputNumber {...props.Input} value={value}/>
    }
    return <Form.Item {...props.FormItem}>
        <InputNumber {...props.Input}/>
    </Form.Item>
}
export const CustomInput = ({
    name, disabled, value
}) => {
    const props = {
        FormItem: {
            noStyle: true,
            name
        },
        Input: {
            disabled,
            style: {
                width: '100%', minWidth: 114
            },
        }
    }
    return <Form.Item {...props.FormItem}>
        <Input {...props.Input}/>
    </Form.Item>
}