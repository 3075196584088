/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPublicCouncils = /* GraphQL */ `
  query GetPublicCouncils($id: ID!) {
    getPublicCouncils(id: $id) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPublicCouncils = /* GraphQL */ `
  query ListPublicCouncils(
    $filter: ModelPublicCouncilsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicCouncils(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        shortName
        maxPrice
        maxApartment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPublicCouncils = /* GraphQL */ `
  query SyncPublicCouncils(
    $filter: ModelPublicCouncilsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPublicCouncils(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fullName
        shortName
        maxPrice
        maxApartment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPublicLenderRate = /* GraphQL */ `
  query GetPublicLenderRate($id: ID!) {
    getPublicLenderRate(id: $id) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      maximum
      lenderCode
      promotions
      conditions
      forBorrowerTypes
      minBER
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPublicLenderRates = /* GraphQL */ `
  query ListPublicLenderRates(
    $filter: ModelPublicLenderRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicLenderRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderID
        rate
        maxLTV
        term
        discount
        minimum
        maximum
        lenderCode
        promotions
        conditions
        forBorrowerTypes
        minBER
        buyToLet
        product
        interestOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPublicLenderRates = /* GraphQL */ `
  query SyncPublicLenderRates(
    $filter: ModelPublicLenderRateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPublicLenderRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lenderID
        rate
        maxLTV
        term
        discount
        minimum
        maximum
        lenderCode
        promotions
        conditions
        forBorrowerTypes
        minBER
        buyToLet
        product
        interestOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const publicLenderRatesByLenderID = /* GraphQL */ `
  query PublicLenderRatesByLenderID(
    $lenderID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPublicLenderRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicLenderRatesByLenderID(
      lenderID: $lenderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderID
        rate
        maxLTV
        term
        discount
        minimum
        maximum
        lenderCode
        promotions
        conditions
        forBorrowerTypes
        minBER
        buyToLet
        product
        interestOnly
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminPdfOverlayTemplate = /* GraphQL */ `
  query GetAdminPdfOverlayTemplate($id: ID!) {
    getAdminPdfOverlayTemplate(id: $id) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminPdfOverlayTemplates = /* GraphQL */ `
  query ListAdminPdfOverlayTemplates(
    $filter: ModelAdminPdfOverlayTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminPdfOverlayTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender
        lenderCode
        stage
        fileName
        templateType
        mortgageType
        companyFolder
        documentNotes
        archive
        bucketKey
        clientSigns
        docusign
        defaultFolder
        defaultPrivate
        items {
          items {
            id
            name
            pageNo
            x
            y
            type
            style
            templateId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminPdfOverlayTemplates = /* GraphQL */ `
  query SyncAdminPdfOverlayTemplates(
    $filter: ModelAdminPdfOverlayTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminPdfOverlayTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lender
        lenderCode
        stage
        fileName
        templateType
        mortgageType
        companyFolder
        documentNotes
        archive
        bucketKey
        clientSigns
        docusign
        defaultFolder
        defaultPrivate
        items {
          items {
            id
            name
            pageNo
            x
            y
            type
            style
            templateId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminPdfOverlayTemplatesByLender = /* GraphQL */ `
  query AdminPdfOverlayTemplatesByLender(
    $lender: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminPdfOverlayTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminPdfOverlayTemplatesByLender(
      lender: $lender
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender
        lenderCode
        stage
        fileName
        templateType
        mortgageType
        companyFolder
        documentNotes
        archive
        bucketKey
        clientSigns
        docusign
        defaultFolder
        defaultPrivate
        items {
          items {
            id
            name
            pageNo
            x
            y
            type
            style
            templateId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminPdfOverlayTemplatesByLenderCode = /* GraphQL */ `
  query AdminPdfOverlayTemplatesByLenderCode(
    $lenderCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminPdfOverlayTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminPdfOverlayTemplatesByLenderCode(
      lenderCode: $lenderCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender
        lenderCode
        stage
        fileName
        templateType
        mortgageType
        companyFolder
        documentNotes
        archive
        bucketKey
        clientSigns
        docusign
        defaultFolder
        defaultPrivate
        items {
          items {
            id
            name
            pageNo
            x
            y
            type
            style
            templateId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminPdfOverlayTemplateItem = /* GraphQL */ `
  query GetAdminPdfOverlayTemplateItem($id: ID!) {
    getAdminPdfOverlayTemplateItem(id: $id) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminPdfOverlayTemplateItems = /* GraphQL */ `
  query ListAdminPdfOverlayTemplateItems(
    $filter: ModelAdminPdfOverlayTemplateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminPdfOverlayTemplateItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        pageNo
        x
        y
        type
        style
        templateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminPdfOverlayTemplateItems = /* GraphQL */ `
  query SyncAdminPdfOverlayTemplateItems(
    $filter: ModelAdminPdfOverlayTemplateItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminPdfOverlayTemplateItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        pageNo
        x
        y
        type
        style
        templateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminPdfOverlayTemplateItemsByTemplateId = /* GraphQL */ `
  query AdminPdfOverlayTemplateItemsByTemplateId(
    $templateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminPdfOverlayTemplateItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminPdfOverlayTemplateItemsByTemplateId(
      templateId: $templateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        pageNo
        x
        y
        type
        style
        templateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminLenderFiles = /* GraphQL */ `
  query GetAdminLenderFiles($id: ID!) {
    getAdminLenderFiles(id: $id) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminLenderFiles = /* GraphQL */ `
  query ListAdminLenderFiles(
    $filter: ModelAdminLenderFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminLenderFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender
        folder
        description
        fileName
        bucketKey
        contentType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminLenderFiles = /* GraphQL */ `
  query SyncAdminLenderFiles(
    $filter: ModelAdminLenderFilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminLenderFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lender
        folder
        description
        fileName
        bucketKey
        contentType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminLenderFilesByLender = /* GraphQL */ `
  query AdminLenderFilesByLender(
    $lender: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminLenderFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminLenderFilesByLender(
      lender: $lender
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lender
        folder
        description
        fileName
        bucketKey
        contentType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminBackendUsers = /* GraphQL */ `
  query GetAdminBackendUsers($id: ID!) {
    getAdminBackendUsers(id: $id) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminBackendUsers = /* GraphQL */ `
  query ListAdminBackendUsers(
    $filter: ModelAdminBackendUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminBackendUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        surname
        sub
        email
        phone
        mailAccount
        color
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminBackendUsers = /* GraphQL */ `
  query SyncAdminBackendUsers(
    $filter: ModelAdminBackendUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminBackendUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        surname
        sub
        email
        phone
        mailAccount
        color
        lastActive
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminLender = /* GraphQL */ `
  query GetAdminLender($id: ID!) {
    getAdminLender(id: $id) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          maximum
          lenderCode
          promotions
          conditions
          forBorrowerTypes
          minBER
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminLenders = /* GraphQL */ `
  query ListAdminLenders(
    $filter: ModelAdminLenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminLenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminLenders = /* GraphQL */ `
  query SyncAdminLenders(
    $filter: ModelAdminLenderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminLenders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminEmails = /* GraphQL */ `
  query GetAdminEmails($id: ID!) {
    getAdminEmails(id: $id) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminEmails = /* GraphQL */ `
  query ListAdminEmails(
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminEmails = /* GraphQL */ `
  query SyncAdminEmails(
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByFrom = /* GraphQL */ `
  query AdminEmailsByFrom(
    $from: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByFrom(
      from: $from
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsBySortDate = /* GraphQL */ `
  query AdminEmailsBySortDate(
    $sortDate: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsBySortDate(
      sortDate: $sortDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByDatetimeReceived = /* GraphQL */ `
  query AdminEmailsByDatetimeReceived(
    $datetimeReceived: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByDatetimeReceived(
      datetimeReceived: $datetimeReceived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByDatetimeSent = /* GraphQL */ `
  query AdminEmailsByDatetimeSent(
    $datetimeSent: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByDatetimeSent(
      datetimeSent: $datetimeSent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByMessageId = /* GraphQL */ `
  query AdminEmailsByMessageId(
    $messageId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByMessageId(
      messageId: $messageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const emailsByActionedByBySortDate = /* GraphQL */ `
  query EmailsByActionedByBySortDate(
    $isReceivedInt: Int!
    $sortDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailsByActionedByBySortDate(
      isReceivedInt: $isReceivedInt
      sortDate: $sortDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByReadOn = /* GraphQL */ `
  query AdminEmailsByReadOn(
    $readOn: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByReadOn(
      readOn: $readOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByActionedOn = /* GraphQL */ `
  query AdminEmailsByActionedOn(
    $actionedOn: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByActionedOn(
      actionedOn: $actionedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const emailsByActionedSorted = /* GraphQL */ `
  query EmailsByActionedSorted(
    $actionedBy: ID!
    $sortDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailsByActionedSorted(
      actionedBy: $actionedBy
      sortDate: $sortDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailsByUserApplicant = /* GraphQL */ `
  query AdminEmailsByUserApplicant(
    $userapplicantID: ID!
    $sortDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailsByUserApplicant(
      userapplicantID: $userapplicantID
      sortDate: $sortDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from
        to
        cc
        bcc
        subject
        hasAttachments
        v2Attachments
        html
        attachments
        sortDate
        datetimeReceived
        datetimeSent
        messageId
        originalId
        isReceived
        isReceivedInt
        readOn
        readBy
        replyRequestId
        appsyncVersion
        actionedOn
        actionedBy
        AdminEmailAttachments {
          items {
            id
            contentType
            fileSize
            content
            fileName
            adminemailsID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userapplicantID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminEmailReplyRequest = /* GraphQL */ `
  query GetAdminEmailReplyRequest($id: ID!) {
    getAdminEmailReplyRequest(id: $id) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminEmailReplyRequests = /* GraphQL */ `
  query ListAdminEmailReplyRequests(
    $filter: ModelAdminEmailReplyRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminEmailReplyRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receivedOn
        mortgageId
        emailId
        replyTo
        requestType
        requestBody
        itemsLoaded
        noteBody
        assocEmails
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        repliedBy
        repliedOn
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminEmailReplyRequests = /* GraphQL */ `
  query SyncAdminEmailReplyRequests(
    $filter: ModelAdminEmailReplyRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminEmailReplyRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        receivedOn
        mortgageId
        emailId
        replyTo
        requestType
        requestBody
        itemsLoaded
        noteBody
        assocEmails
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        repliedBy
        repliedOn
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailReplyRequestsByMortgageId = /* GraphQL */ `
  query AdminEmailReplyRequestsByMortgageId(
    $mortgageId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailReplyRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailReplyRequestsByMortgageId(
      mortgageId: $mortgageId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receivedOn
        mortgageId
        emailId
        replyTo
        requestType
        requestBody
        itemsLoaded
        noteBody
        assocEmails
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        repliedBy
        repliedOn
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailReplyRequestsByRepliedBy = /* GraphQL */ `
  query AdminEmailReplyRequestsByRepliedBy(
    $repliedBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailReplyRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailReplyRequestsByRepliedBy(
      repliedBy: $repliedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receivedOn
        mortgageId
        emailId
        replyTo
        requestType
        requestBody
        itemsLoaded
        noteBody
        assocEmails
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        repliedBy
        repliedOn
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminEmailAttachments = /* GraphQL */ `
  query GetAdminEmailAttachments($id: ID!) {
    getAdminEmailAttachments(id: $id) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminEmailAttachments = /* GraphQL */ `
  query ListAdminEmailAttachments(
    $filter: ModelAdminEmailAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminEmailAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        fileSize
        content
        fileName
        adminemailsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminEmailAttachments = /* GraphQL */ `
  query SyncAdminEmailAttachments(
    $filter: ModelAdminEmailAttachmentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminEmailAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentType
        fileSize
        content
        fileName
        adminemailsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminEmailAttachmentsByAdminemailsID = /* GraphQL */ `
  query AdminEmailAttachmentsByAdminemailsID(
    $adminemailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminEmailAttachmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminEmailAttachmentsByAdminemailsID(
      adminemailsID: $adminemailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        fileSize
        content
        fileName
        adminemailsID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminUserCalculations = /* GraphQL */ `
  query GetAdminUserCalculations($id: ID!) {
    getAdminUserCalculations(id: $id) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminUserCalculations = /* GraphQL */ `
  query ListAdminUserCalculations(
    $filter: ModelAdminUserCalculationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminUserCalculations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        loan
        term
        monthly
        ftb
        income
        htbs
        rebate
        fhs
        cc
        type
        shared
        price
        source
        applied
        ber
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminUserCalculations = /* GraphQL */ `
  query SyncAdminUserCalculations(
    $filter: ModelAdminUserCalculationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminUserCalculations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        loan
        term
        monthly
        ftb
        income
        htbs
        rebate
        fhs
        cc
        type
        shared
        price
        source
        applied
        ber
        sessionID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminSystemToDo = /* GraphQL */ `
  query GetAdminSystemToDo($id: ID!) {
    getAdminSystemToDo(id: $id) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminSystemToDos = /* GraphQL */ `
  query ListAdminSystemToDos(
    $filter: ModelAdminSystemToDoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminSystemToDos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        author
        target
        completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminSystemToDos = /* GraphQL */ `
  query SyncAdminSystemToDos(
    $filter: ModelAdminSystemToDoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminSystemToDos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        author
        target
        completed
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminTextractedStatement = /* GraphQL */ `
  query GetAdminTextractedStatement($id: ID!) {
    getAdminTextractedStatement(id: $id) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminTextractedStatements = /* GraphQL */ `
  query ListAdminTextractedStatements(
    $filter: ModelAdminTextractedStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminTextractedStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        key
        forms
        tables
        formArray
        tableArray
        mortgageID
        inProgress
        jobID
        uploadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminTextractedStatements = /* GraphQL */ `
  query SyncAdminTextractedStatements(
    $filter: ModelAdminTextractedStatementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminTextractedStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        bucket
        key
        forms
        tables
        formArray
        tableArray
        mortgageID
        inProgress
        jobID
        uploadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminTextractedStatementsByMortgageID = /* GraphQL */ `
  query AdminTextractedStatementsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminTextractedStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminTextractedStatementsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        key
        forms
        tables
        formArray
        tableArray
        mortgageID
        inProgress
        jobID
        uploadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminTextractedStatementsByJobID = /* GraphQL */ `
  query AdminTextractedStatementsByJobID(
    $jobID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminTextractedStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminTextractedStatementsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        key
        forms
        tables
        formArray
        tableArray
        mortgageID
        inProgress
        jobID
        uploadID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageAccountSettings = /* GraphQL */ `
  query GetAdminMortgageAccountSettings($id: ID!) {
    getAdminMortgageAccountSettings(id: $id) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageAccountSettings = /* GraphQL */ `
  query ListAdminMortgageAccountSettings(
    $filter: ModelAdminMortgageAccountSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageAccountSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageAccountSettings = /* GraphQL */ `
  query SyncAdminMortgageAccountSettings(
    $filter: ModelAdminMortgageAccountSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageAccountSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageTasks = /* GraphQL */ `
  query GetAdminMortgageTasks($id: ID!) {
    getAdminMortgageTasks(id: $id) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageTasks = /* GraphQL */ `
  query ListAdminMortgageTasks(
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageTasks = /* GraphQL */ `
  query SyncAdminMortgageTasks(
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTasksByAuthor = /* GraphQL */ `
  query AdminMortgageTasksByAuthor(
    $author: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTasksByAuthor(
      author: $author
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTasksByTarget = /* GraphQL */ `
  query AdminMortgageTasksByTarget(
    $target: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTasksByTarget(
      target: $target
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTasksByCompletedBy = /* GraphQL */ `
  query AdminMortgageTasksByCompletedBy(
    $completedBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTasksByCompletedBy(
      completedBy: $completedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTasksByCurrentOwner = /* GraphQL */ `
  query AdminMortgageTasksByCurrentOwner(
    $currentOwner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTasksByCurrentOwner(
      currentOwner: $currentOwner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTasksByUsermortgageID = /* GraphQL */ `
  query AdminMortgageTasksByUsermortgageID(
    $usermortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTasksByUsermortgageID(
      usermortgageID: $usermortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        due
        deleted
        target
        targetStarted
        targetLastActive
        completedOn
        completedBy
        paused
        closedOn
        closedBy
        folder
        userFolder
        userSortPosition
        tags
        currentOwner
        lastMessageBy
        lastMessageOn
        lastMessage
        toDos {
          items {
            id
            taskID
            completedOn
            completedBy
            toDoItem
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        pauses
        components {
          items {
            id
            taskID
            component
            author
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageTaskComponent = /* GraphQL */ `
  query GetAdminMortgageTaskComponent($id: ID!) {
    getAdminMortgageTaskComponent(id: $id) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageTaskComponents = /* GraphQL */ `
  query ListAdminMortgageTaskComponents(
    $filter: ModelAdminMortgageTaskComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageTaskComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        component
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageTaskComponents = /* GraphQL */ `
  query SyncAdminMortgageTaskComponents(
    $filter: ModelAdminMortgageTaskComponentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageTaskComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskID
        component
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTaskComponentsByTaskID = /* GraphQL */ `
  query AdminMortgageTaskComponentsByTaskID(
    $taskID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTaskComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTaskComponentsByTaskID(
      taskID: $taskID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        component
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageTaskToDo = /* GraphQL */ `
  query GetAdminMortgageTaskToDo($id: ID!) {
    getAdminMortgageTaskToDo(id: $id) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageTaskToDos = /* GraphQL */ `
  query ListAdminMortgageTaskToDos(
    $filter: ModelAdminMortgageTaskToDoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageTaskToDos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        completedOn
        completedBy
        toDoItem
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageTaskToDos = /* GraphQL */ `
  query SyncAdminMortgageTaskToDos(
    $filter: ModelAdminMortgageTaskToDoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageTaskToDos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskID
        completedOn
        completedBy
        toDoItem
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageTaskToDosByTaskID = /* GraphQL */ `
  query AdminMortgageTaskToDosByTaskID(
    $taskID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageTaskToDoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageTaskToDosByTaskID(
      taskID: $taskID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskID
        completedOn
        completedBy
        toDoItem
        author
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageNotes = /* GraphQL */ `
  query GetAdminMortgageNotes($id: ID!) {
    getAdminMortgageNotes(id: $id) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageNotes = /* GraphQL */ `
  query ListAdminMortgageNotes(
    $filter: ModelAdminMortgageNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        deleted
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageNotes = /* GraphQL */ `
  query SyncAdminMortgageNotes(
    $filter: ModelAdminMortgageNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        body
        author
        pinned
        deleted
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageNotesByUsermortgageID = /* GraphQL */ `
  query AdminMortgageNotesByUsermortgageID(
    $usermortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageNotesByUsermortgageID(
      usermortgageID: $usermortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        author
        pinned
        deleted
        usermortgageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageStageTask = /* GraphQL */ `
  query GetAdminMortgageStageTask($id: ID!) {
    getAdminMortgageStageTask(id: $id) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageStageTasks = /* GraphQL */ `
  query ListAdminMortgageStageTasks(
    $filter: ModelAdminMortgageStageTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageStageTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        stage
        name
        deleted
        due
        completedOn
        completedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageStageTasks = /* GraphQL */ `
  query SyncAdminMortgageStageTasks(
    $filter: ModelAdminMortgageStageTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageStageTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        stage
        name
        deleted
        due
        completedOn
        completedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageStageTasksByMortgageID = /* GraphQL */ `
  query AdminMortgageStageTasksByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageStageTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageStageTasksByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        stage
        name
        deleted
        due
        completedOn
        completedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageStageNotifications = /* GraphQL */ `
  query GetAdminMortgageStageNotifications($id: ID!) {
    getAdminMortgageStageNotifications(id: $id) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageStageNotifications = /* GraphQL */ `
  query ListAdminMortgageStageNotifications(
    $filter: ModelAdminMortgageStageNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageStageNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        stage
        name
        history {
          items {
            id
            sentTime
            sentBy
            data
            notificationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageStageNotifications = /* GraphQL */ `
  query SyncAdminMortgageStageNotifications(
    $filter: ModelAdminMortgageStageNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageStageNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        stage
        name
        history {
          items {
            id
            sentTime
            sentBy
            data
            notificationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageStageNotificationsByMortgageID = /* GraphQL */ `
  query AdminMortgageStageNotificationsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageStageNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageStageNotificationsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        stage
        name
        history {
          items {
            id
            sentTime
            sentBy
            data
            notificationId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageStageNotified = /* GraphQL */ `
  query GetAdminMortgageStageNotified($id: ID!) {
    getAdminMortgageStageNotified(id: $id) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageStageNotifieds = /* GraphQL */ `
  query ListAdminMortgageStageNotifieds(
    $filter: ModelAdminMortgageStageNotifiedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageStageNotifieds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentTime
        sentBy
        data
        notificationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageStageNotifieds = /* GraphQL */ `
  query SyncAdminMortgageStageNotifieds(
    $filter: ModelAdminMortgageStageNotifiedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageStageNotifieds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sentTime
        sentBy
        data
        notificationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageStageNotifiedsByNotificationId = /* GraphQL */ `
  query AdminMortgageStageNotifiedsByNotificationId(
    $notificationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageStageNotifiedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageStageNotifiedsByNotificationId(
      notificationId: $notificationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sentTime
        sentBy
        data
        notificationId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageDocumentContent = /* GraphQL */ `
  query GetAdminMortgageDocumentContent($id: ID!) {
    getAdminMortgageDocumentContent(id: $id) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageDocumentContents = /* GraphQL */ `
  query ListAdminMortgageDocumentContents(
    $filter: ModelAdminMortgageDocumentContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageDocumentContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        paragraph
        folder
        document
        sequence
        html
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageDocumentContents = /* GraphQL */ `
  query SyncAdminMortgageDocumentContents(
    $filter: ModelAdminMortgageDocumentContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageDocumentContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        paragraph
        folder
        document
        sequence
        html
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageDocumentContentsByMortgageID = /* GraphQL */ `
  query AdminMortgageDocumentContentsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageDocumentContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageDocumentContentsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        paragraph
        folder
        document
        sequence
        html
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageSettings = /* GraphQL */ `
  query GetAdminMortgageSettings($id: ID!) {
    getAdminMortgageSettings(id: $id) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageSettings = /* GraphQL */ `
  query ListAdminMortgageSettings(
    $filter: ModelAdminMortgageSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageSettings = /* GraphQL */ `
  query SyncAdminMortgageSettings(
    $filter: ModelAdminMortgageSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminLastViewedMortgage = /* GraphQL */ `
  query GetAdminLastViewedMortgage($id: ID!) {
    getAdminLastViewedMortgage(id: $id) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminLastViewedMortgages = /* GraphQL */ `
  query ListAdminLastViewedMortgages(
    $filter: ModelAdminLastViewedMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminLastViewedMortgages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        lastViewedOn
        lastViewedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminLastViewedMortgages = /* GraphQL */ `
  query SyncAdminLastViewedMortgages(
    $filter: ModelAdminLastViewedMortgageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminLastViewedMortgages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        lastViewedOn
        lastViewedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const byLastViewedBySorted = /* GraphQL */ `
  query ByLastViewedBySorted(
    $lastViewedBy: ID!
    $lastViewedOn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminLastViewedMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byLastViewedBySorted(
      lastViewedBy: $lastViewedBy
      lastViewedOn: $lastViewedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        lastViewedOn
        lastViewedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageLenderTest = /* GraphQL */ `
  query GetAdminMortgageLenderTest($id: ID!) {
    getAdminMortgageLenderTest(id: $id) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageLenderTests = /* GraphQL */ `
  query ListAdminMortgageLenderTests(
    $filter: ModelAdminMortgageLenderTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageLenderTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageLenderTests = /* GraphQL */ `
  query SyncAdminMortgageLenderTests(
    $filter: ModelAdminMortgageLenderTestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageLenderTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lenderCode
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageLenderTestsByMortgageID = /* GraphQL */ `
  query AdminMortgageLenderTestsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageLenderTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageLenderTestsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  query GetAdminMortgageLenderTestPlaygroundTest($id: ID!) {
    getAdminMortgageLenderTestPlaygroundTest(id: $id) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageLenderTestPlaygroundTests = /* GraphQL */ `
  query ListAdminMortgageLenderTestPlaygroundTests(
    $filter: ModelAdminMortgageLenderTestPlaygroundTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageLenderTestPlaygroundTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageLenderTestPlaygroundTests = /* GraphQL */ `
  query SyncAdminMortgageLenderTestPlaygroundTests(
    $filter: ModelAdminMortgageLenderTestPlaygroundTestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageLenderTestPlaygroundTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageLenderTestPlaygroundTestsByMortgageID = /* GraphQL */ `
  query AdminMortgageLenderTestPlaygroundTestsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageLenderTestPlaygroundTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageLenderTestPlaygroundTestsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        results
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  query GetAdminMortgageUploadStatementBasicInfo($id: ID!) {
    getAdminMortgageUploadStatementBasicInfo(id: $id) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageUploadStatementBasicInfos = /* GraphQL */ `
  query ListAdminMortgageUploadStatementBasicInfos(
    $filter: ModelAdminMortgageUploadStatementBasicInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageUploadStatementBasicInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageUploadStatementBasicInfos = /* GraphQL */ `
  query SyncAdminMortgageUploadStatementBasicInfos(
    $filter: ModelAdminMortgageUploadStatementBasicInfoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageUploadStatementBasicInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageSectionVerification = /* GraphQL */ `
  query GetAdminMortgageSectionVerification($id: ID!) {
    getAdminMortgageSectionVerification(id: $id) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageSectionVerifications = /* GraphQL */ `
  query ListAdminMortgageSectionVerifications(
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageSectionVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageSectionVerifications = /* GraphQL */ `
  query SyncAdminMortgageSectionVerifications(
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageSectionVerifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationsByProgressID = /* GraphQL */ `
  query AdminMortgageSectionVerificationsByProgressID(
    $progressID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationsByProgressID(
      progressID: $progressID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationsByMortgageID = /* GraphQL */ `
  query AdminMortgageSectionVerificationsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationsByVerificationBy = /* GraphQL */ `
  query AdminMortgageSectionVerificationsByVerificationBy(
    $verificationBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationsByVerificationBy(
      verificationBy: $verificationBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationsBySupervisorCheckRequired = /* GraphQL */ `
  query AdminMortgageSectionVerificationsBySupervisorCheckRequired(
    $supervisorCheckRequired: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationsBySupervisorCheckRequired(
      supervisorCheckRequired: $supervisorCheckRequired
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationsBySupervisorCheckBy = /* GraphQL */ `
  query AdminMortgageSectionVerificationsBySupervisorCheckBy(
    $supervisorCheckBy: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationsBySupervisorCheckBy(
      supervisorCheckBy: $supervisorCheckBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        progressID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  query GetAdminMortgageUploadVerifiedPoint($id: ID!) {
    getAdminMortgageUploadVerifiedPoint(id: $id) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageUploadVerifiedPoints = /* GraphQL */ `
  query ListAdminMortgageUploadVerifiedPoints(
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageUploadVerifiedPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageUploadVerifiedPoints = /* GraphQL */ `
  query SyncAdminMortgageUploadVerifiedPoints(
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageUploadVerifiedPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageUploadVerifiedPointsByUploadID = /* GraphQL */ `
  query AdminMortgageUploadVerifiedPointsByUploadID(
    $uploadID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageUploadVerifiedPointsByUploadID(
      uploadID: $uploadID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageUploadVerifiedPointsByMortgageID = /* GraphQL */ `
  query AdminMortgageUploadVerifiedPointsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageUploadVerifiedPointsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageUploadVerifiedPointsByVerificationBy = /* GraphQL */ `
  query AdminMortgageUploadVerifiedPointsByVerificationBy(
    $verificationBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageUploadVerifiedPointsByVerificationBy(
      verificationBy: $verificationBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageUploadVerifiedPointsBySupervisorCheckRequired = /* GraphQL */ `
  query AdminMortgageUploadVerifiedPointsBySupervisorCheckRequired(
    $supervisorCheckRequired: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageUploadVerifiedPointsBySupervisorCheckRequired(
      supervisorCheckRequired: $supervisorCheckRequired
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageUploadVerifiedPointsBySupervisorCheckBy = /* GraphQL */ `
  query AdminMortgageUploadVerifiedPointsBySupervisorCheckBy(
    $supervisorCheckBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageUploadVerifiedPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageUploadVerifiedPointsBySupervisorCheckBy(
      supervisorCheckBy: $supervisorCheckBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        mortgageID
        name
        verifiedValue
        verifiedValueType
        verificationOn
        verificationBy
        verificationNotes {
          items {
            id
            verificationID
            authorID
            string
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        supervisorCheckRequired
        supervisorCheckOn
        supervisorCheckBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageSectionVerificationNote = /* GraphQL */ `
  query GetAdminMortgageSectionVerificationNote($id: ID!) {
    getAdminMortgageSectionVerificationNote(id: $id) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageSectionVerificationNotes = /* GraphQL */ `
  query ListAdminMortgageSectionVerificationNotes(
    $filter: ModelAdminMortgageSectionVerificationNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageSectionVerificationNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationID
        authorID
        string
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageSectionVerificationNotes = /* GraphQL */ `
  query SyncAdminMortgageSectionVerificationNotes(
    $filter: ModelAdminMortgageSectionVerificationNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageSectionVerificationNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        verificationID
        authorID
        string
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSectionVerificationNotesByVerificationID = /* GraphQL */ `
  query AdminMortgageSectionVerificationNotesByVerificationID(
    $verificationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSectionVerificationNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSectionVerificationNotesByVerificationID(
      verificationID: $verificationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        verificationID
        authorID
        string
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageSubmission = /* GraphQL */ `
  query GetAdminMortgageSubmission($id: ID!) {
    getAdminMortgageSubmission(id: $id) {
      id
      mortgageID
      provisionalXlsInput
      provisionalXlsOutput
      finalXlsInput
      finalXlsOutput
      lenderRecommendation
      productRecommendation
      termRecommendation
      secondaryProduct
      secondaryRate
      invalidatedReason
      aipNotes
      loanOfferNotes
      completedFlowItemsForAdmin
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageSubmissions = /* GraphQL */ `
  query ListAdminMortgageSubmissions(
    $filter: ModelAdminMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        provisionalXlsInput
        provisionalXlsOutput
        finalXlsInput
        finalXlsOutput
        lenderRecommendation
        productRecommendation
        termRecommendation
        secondaryProduct
        secondaryRate
        invalidatedReason
        aipNotes
        loanOfferNotes
        completedFlowItemsForAdmin
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageSubmissions = /* GraphQL */ `
  query SyncAdminMortgageSubmissions(
    $filter: ModelAdminMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        provisionalXlsInput
        provisionalXlsOutput
        finalXlsInput
        finalXlsOutput
        lenderRecommendation
        productRecommendation
        termRecommendation
        secondaryProduct
        secondaryRate
        invalidatedReason
        aipNotes
        loanOfferNotes
        completedFlowItemsForAdmin
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSubmissionsByMortgageID = /* GraphQL */ `
  query AdminMortgageSubmissionsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSubmissionsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        provisionalXlsInput
        provisionalXlsOutput
        finalXlsInput
        finalXlsOutput
        lenderRecommendation
        productRecommendation
        termRecommendation
        secondaryProduct
        secondaryRate
        invalidatedReason
        aipNotes
        loanOfferNotes
        completedFlowItemsForAdmin
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageSubmissionIssue = /* GraphQL */ `
  query GetAdminMortgageSubmissionIssue($id: ID!) {
    getAdminMortgageSubmissionIssue(id: $id) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageSubmissionIssues = /* GraphQL */ `
  query ListAdminMortgageSubmissionIssues(
    $filter: ModelAdminMortgageSubmissionIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageSubmissionIssues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        submissionID
        emailID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageSubmissionIssues = /* GraphQL */ `
  query SyncAdminMortgageSubmissionIssues(
    $filter: ModelAdminMortgageSubmissionIssueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageSubmissionIssues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        submissionID
        emailID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageSubmissionIssuesBySubmissionID = /* GraphQL */ `
  query AdminMortgageSubmissionIssuesBySubmissionID(
    $submissionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageSubmissionIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageSubmissionIssuesBySubmissionID(
      submissionID: $submissionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        submissionID
        emailID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAdminMortgageProtectionInsurance = /* GraphQL */ `
  query GetAdminMortgageProtectionInsurance($id: ID!) {
    getAdminMortgageProtectionInsurance(id: $id) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAdminMortgageProtectionInsurances = /* GraphQL */ `
  query ListAdminMortgageProtectionInsurances(
    $filter: ModelAdminMortgageProtectionInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminMortgageProtectionInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        provider
        quoteGenerated
        quoteGeneratedOn
        quoteGeneratedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAdminMortgageProtectionInsurances = /* GraphQL */ `
  query SyncAdminMortgageProtectionInsurances(
    $filter: ModelAdminMortgageProtectionInsuranceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdminMortgageProtectionInsurances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        provider
        quoteGenerated
        quoteGeneratedOn
        quoteGeneratedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageProtectionInsurancesByMortgageID = /* GraphQL */ `
  query AdminMortgageProtectionInsurancesByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageProtectionInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageProtectionInsurancesByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        provider
        quoteGenerated
        quoteGeneratedOn
        quoteGeneratedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const adminMortgageProtectionInsurancesByQuoteGenerated = /* GraphQL */ `
  query AdminMortgageProtectionInsurancesByQuoteGenerated(
    $quoteGenerated: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminMortgageProtectionInsuranceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminMortgageProtectionInsurancesByQuoteGenerated(
      quoteGenerated: $quoteGenerated
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        provider
        quoteGenerated
        quoteGeneratedOn
        quoteGeneratedBy
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageLenderTestQueue = /* GraphQL */ `
  query GetUserMortgageLenderTestQueue($id: ID!) {
    getUserMortgageLenderTestQueue(id: $id) {
      id
      lenderCode
      mortgageID
      messageID
      data
      input
      output
      passed
      variableUsed
      processing
      completed
      processingStarted
      processingCompleted
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageLenderTestQueues = /* GraphQL */ `
  query ListUserMortgageLenderTestQueues(
    $filter: ModelUserMortgageLenderTestQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageLenderTestQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        mortgageID
        messageID
        data
        input
        output
        passed
        variableUsed
        processing
        completed
        processingStarted
        processingCompleted
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageLenderTestQueues = /* GraphQL */ `
  query SyncUserMortgageLenderTestQueues(
    $filter: ModelUserMortgageLenderTestQueueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageLenderTestQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lenderCode
        mortgageID
        messageID
        data
        input
        output
        passed
        variableUsed
        processing
        completed
        processingStarted
        processingCompleted
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const byProcessingMortgageID = /* GraphQL */ `
  query ByProcessingMortgageID(
    $processing: Int!
    $mortgageID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageLenderTestQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byProcessingMortgageID(
      processing: $processing
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        mortgageID
        messageID
        data
        input
        output
        passed
        variableUsed
        processing
        completed
        processingStarted
        processingCompleted
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageLenderTestQueuesByCompletedAndProcessing = /* GraphQL */ `
  query UserMortgageLenderTestQueuesByCompletedAndProcessing(
    $completed: Int!
    $processing: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageLenderTestQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageLenderTestQueuesByCompletedAndProcessing(
      completed: $completed
      processing: $processing
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        mortgageID
        messageID
        data
        input
        output
        passed
        variableUsed
        processing
        completed
        processingStarted
        processingCompleted
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserPdfId = /* GraphQL */ `
  query GetUserPdfId($id: ID!) {
    getUserPdfId(id: $id) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUserPdfIds = /* GraphQL */ `
  query ListUserPdfIds(
    $filter: ModelUserPdfIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPdfIds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pdfType
        institution
        friendlyName
        subType
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserPdfIds = /* GraphQL */ `
  query SyncUserPdfIds(
    $filter: ModelUserPdfIdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserPdfIds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pdfType
        institution
        friendlyName
        subType
        data
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageAppointment = /* GraphQL */ `
  query GetUserMortgageAppointment($id: ID!) {
    getUserMortgageAppointment(id: $id) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageAppointments = /* GraphQL */ `
  query ListUserMortgageAppointments(
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageAppointments = /* GraphQL */ `
  query SyncUserMortgageAppointments(
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageAppointments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageAppointmentsByBackendUserID = /* GraphQL */ `
  query UserMortgageAppointmentsByBackendUserID(
    $backendUserID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageAppointmentsByBackendUserID(
      backendUserID: $backendUserID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageAppointmentsByExpired = /* GraphQL */ `
  query UserMortgageAppointmentsByExpired(
    $expired: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageAppointmentsByExpired(
      expired: $expired
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageAppointmentsByAppointmentDate = /* GraphQL */ `
  query UserMortgageAppointmentsByAppointmentDate(
    $appointmentDate: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageAppointmentsByAppointmentDate(
      appointmentDate: $appointmentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageAppointmentsByMortgageID = /* GraphQL */ `
  query UserMortgageAppointmentsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageAppointmentsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        backendUserID
        appointmentType
        expired
        appointmentDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageSubmission = /* GraphQL */ `
  query GetUserMortgageSubmission($id: ID!) {
    getUserMortgageSubmission(id: $id) {
      id
      lenderCode
      provisionalProduct
      provisionalRateId
      provisionalRate
      provisionalTerm
      provisionalLoan
      provisionalValue
      provisionalSecondaryProduct
      provisionalSecondaryRate
      provisionalSecondaryRateId
      provisionalXlsInput
      provisionalXlsOutput
      finalProduct
      finalRate
      finalRateId
      finalTerm
      finalLoan
      finalValue
      finalSecondaryProduct
      finalSecondaryRate
      finalSecondaryRateId
      finalXlsInput
      finalXlsOutput
      soft
      aipEmailId
      aipMaxLoan
      aipPropertyReference
      aipExpiryDate
      aipDate
      aipNotes
      aipSettings
      loanOfferEmailId
      loanOfferDate
      loanOfferExpiryDate
      loanOfferNotes
      loanOfferSettings
      drawdownDate
      completedFlowItems
      completedFlowItemsForAdmin
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      valuation2ReinstatementValue
      reinstatement2Value
      valuation2Value
      hasValuation2Issues
      valuation2Issues
      surveyRequired
      surveyDate
      lenderRecommendation
      productRecommendation
      termRecommendation
      mortgageAccountNo
      invalidatedReason
      submissionStage
      documents {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageSubmissions = /* GraphQL */ `
  query ListUserMortgageSubmissions(
    $filter: ModelUserMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        provisionalProduct
        provisionalRateId
        provisionalRate
        provisionalTerm
        provisionalLoan
        provisionalValue
        provisionalSecondaryProduct
        provisionalSecondaryRate
        provisionalSecondaryRateId
        provisionalXlsInput
        provisionalXlsOutput
        finalProduct
        finalRate
        finalRateId
        finalTerm
        finalLoan
        finalValue
        finalSecondaryProduct
        finalSecondaryRate
        finalSecondaryRateId
        finalXlsInput
        finalXlsOutput
        soft
        aipEmailId
        aipMaxLoan
        aipPropertyReference
        aipExpiryDate
        aipDate
        aipNotes
        aipSettings
        loanOfferEmailId
        loanOfferDate
        loanOfferExpiryDate
        loanOfferNotes
        loanOfferSettings
        drawdownDate
        completedFlowItems
        completedFlowItemsForAdmin
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        valuation2ReinstatementValue
        reinstatement2Value
        valuation2Value
        hasValuation2Issues
        valuation2Issues
        surveyRequired
        surveyDate
        lenderRecommendation
        productRecommendation
        termRecommendation
        mortgageAccountNo
        invalidatedReason
        submissionStage
        documents {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageSubmissions = /* GraphQL */ `
  query SyncUserMortgageSubmissions(
    $filter: ModelUserMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lenderCode
        provisionalProduct
        provisionalRateId
        provisionalRate
        provisionalTerm
        provisionalLoan
        provisionalValue
        provisionalSecondaryProduct
        provisionalSecondaryRate
        provisionalSecondaryRateId
        provisionalXlsInput
        provisionalXlsOutput
        finalProduct
        finalRate
        finalRateId
        finalTerm
        finalLoan
        finalValue
        finalSecondaryProduct
        finalSecondaryRate
        finalSecondaryRateId
        finalXlsInput
        finalXlsOutput
        soft
        aipEmailId
        aipMaxLoan
        aipPropertyReference
        aipExpiryDate
        aipDate
        aipNotes
        aipSettings
        loanOfferEmailId
        loanOfferDate
        loanOfferExpiryDate
        loanOfferNotes
        loanOfferSettings
        drawdownDate
        completedFlowItems
        completedFlowItemsForAdmin
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        valuation2ReinstatementValue
        reinstatement2Value
        valuation2Value
        hasValuation2Issues
        valuation2Issues
        surveyRequired
        surveyDate
        lenderRecommendation
        productRecommendation
        termRecommendation
        mortgageAccountNo
        invalidatedReason
        submissionStage
        documents {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageSubmissionsByMortgageID = /* GraphQL */ `
  query UserMortgageSubmissionsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageSubmissionsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lenderCode
        provisionalProduct
        provisionalRateId
        provisionalRate
        provisionalTerm
        provisionalLoan
        provisionalValue
        provisionalSecondaryProduct
        provisionalSecondaryRate
        provisionalSecondaryRateId
        provisionalXlsInput
        provisionalXlsOutput
        finalProduct
        finalRate
        finalRateId
        finalTerm
        finalLoan
        finalValue
        finalSecondaryProduct
        finalSecondaryRate
        finalSecondaryRateId
        finalXlsInput
        finalXlsOutput
        soft
        aipEmailId
        aipMaxLoan
        aipPropertyReference
        aipExpiryDate
        aipDate
        aipNotes
        aipSettings
        loanOfferEmailId
        loanOfferDate
        loanOfferExpiryDate
        loanOfferNotes
        loanOfferSettings
        drawdownDate
        completedFlowItems
        completedFlowItemsForAdmin
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        valuation2ReinstatementValue
        reinstatement2Value
        valuation2Value
        hasValuation2Issues
        valuation2Issues
        surveyRequired
        surveyDate
        lenderRecommendation
        productRecommendation
        termRecommendation
        mortgageAccountNo
        invalidatedReason
        submissionStage
        documents {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        issues {
          items {
            id
            submissionID
            emailID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageQuery = /* GraphQL */ `
  query GetUserMortgageQuery($id: ID!) {
    getUserMortgageQuery(id: $id) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageQueries = /* GraphQL */ `
  query ListUserMortgageQueries(
    $filter: ModelUserMortgageQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageQueries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        emailReplyID
        createdBy
        referenceType
        referenceId
        stage
        section
        index
        title
        date
        description
        amount
        query
        userAnswer
        userAnswerOn
        userAnswerBy
        userAnswered
        adminAnswered
        lenderAnswer
        lenderAnswerOn
        lenderAnswerBy
        excludedOn
        excludedBy
        private
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageQueries = /* GraphQL */ `
  query SyncUserMortgageQueries(
    $filter: ModelUserMortgageQueryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageQueries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        emailReplyID
        createdBy
        referenceType
        referenceId
        stage
        section
        index
        title
        date
        description
        amount
        query
        userAnswer
        userAnswerOn
        userAnswerBy
        userAnswered
        adminAnswered
        lenderAnswer
        lenderAnswerOn
        lenderAnswerBy
        excludedOn
        excludedBy
        private
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageQueriesByMortgageID = /* GraphQL */ `
  query UserMortgageQueriesByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageQueriesByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        emailReplyID
        createdBy
        referenceType
        referenceId
        stage
        section
        index
        title
        date
        description
        amount
        query
        userAnswer
        userAnswerOn
        userAnswerBy
        userAnswered
        adminAnswered
        lenderAnswer
        lenderAnswerOn
        lenderAnswerBy
        excludedOn
        excludedBy
        private
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageQueriesByEmailReplyID = /* GraphQL */ `
  query UserMortgageQueriesByEmailReplyID(
    $emailReplyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageQueriesByEmailReplyID(
      emailReplyID: $emailReplyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        emailReplyID
        createdBy
        referenceType
        referenceId
        stage
        section
        index
        title
        date
        description
        amount
        query
        userAnswer
        userAnswerOn
        userAnswerBy
        userAnswered
        adminAnswered
        lenderAnswer
        lenderAnswerOn
        lenderAnswerBy
        excludedOn
        excludedBy
        private
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const mortgageQueriesByPending = /* GraphQL */ `
  query MortgageQueriesByPending(
    $userAnswered: Int!
    $adminAnswered: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mortgageQueriesByPending(
      userAnswered: $userAnswered
      adminAnswered: $adminAnswered
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        emailReplyID
        createdBy
        referenceType
        referenceId
        stage
        section
        index
        title
        date
        description
        amount
        query
        userAnswer
        userAnswerOn
        userAnswerBy
        userAnswered
        adminAnswered
        lenderAnswer
        lenderAnswerOn
        lenderAnswerBy
        excludedOn
        excludedBy
        private
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgage = /* GraphQL */ `
  query GetUserMortgage($id: ID!) {
    getUserMortgage(id: $id) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          propertyAge
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          isClosed
          isNewAccount
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          pdffed
          seenOn
          seenBy
          omit
          documentDate
          exclude
          examined
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          lastDocumentDateDismissed
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          viewState
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      completedFlowItemsForAdmin
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      submissions {
        items {
          id
          lenderCode
          provisionalProduct
          provisionalRateId
          provisionalRate
          provisionalTerm
          provisionalLoan
          provisionalValue
          provisionalSecondaryProduct
          provisionalSecondaryRate
          provisionalSecondaryRateId
          provisionalXlsInput
          provisionalXlsOutput
          finalProduct
          finalRate
          finalRateId
          finalTerm
          finalLoan
          finalValue
          finalSecondaryProduct
          finalSecondaryRate
          finalSecondaryRateId
          finalXlsInput
          finalXlsOutput
          soft
          aipEmailId
          aipMaxLoan
          aipPropertyReference
          aipExpiryDate
          aipDate
          aipNotes
          aipSettings
          loanOfferEmailId
          loanOfferDate
          loanOfferExpiryDate
          loanOfferNotes
          loanOfferSettings
          drawdownDate
          completedFlowItems
          completedFlowItemsForAdmin
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          valuation2ReinstatementValue
          reinstatement2Value
          valuation2Value
          hasValuation2Issues
          valuation2Issues
          surveyRequired
          surveyDate
          lenderRecommendation
          productRecommendation
          termRecommendation
          mortgageAccountNo
          invalidatedReason
          submissionStage
          documents {
            items {
              id
              title
              description
              identityID
              identityID2
              folder
              fileName
              fileExtension
              isDynamic
              name
              stage
              private
              submissionID
              mortgageID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminSubmissions {
        items {
          id
          mortgageID
          provisionalXlsInput
          provisionalXlsOutput
          finalXlsInput
          finalXlsOutput
          lenderRecommendation
          productRecommendation
          termRecommendation
          secondaryProduct
          secondaryRate
          invalidatedReason
          aipNotes
          loanOfferNotes
          completedFlowItemsForAdmin
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      uploadsPercentSetup
      uploadsPercentApply
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const listUserMortgages = /* GraphQL */ `
  query ListUserMortgages(
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgages = /* GraphQL */ `
  query SyncUserMortgages(
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagesByApplicant1ID = /* GraphQL */ `
  query UserMortgagesByApplicant1ID(
    $applicant1ID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagesByApplicant1ID(
      applicant1ID: $applicant1ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagesByApplicant2ID = /* GraphQL */ `
  query UserMortgagesByApplicant2ID(
    $applicant2ID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagesByApplicant2ID(
      applicant2ID: $applicant2ID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagesByApplicationStatus = /* GraphQL */ `
  query UserMortgagesByApplicationStatus(
    $applicationStatus: ApplicationStatuses!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagesByApplicationStatus(
      applicationStatus: $applicationStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagesByCaseNumber = /* GraphQL */ `
  query UserMortgagesByCaseNumber(
    $caseNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagesByCaseNumber(
      caseNumber: $caseNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        twoApplicants
        mortgageType
        applicationType
        subs
        data
        activeAgent
        playgroundTests {
          items {
            id
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        appointments {
          items {
            id
            backendUserID
            appointmentType
            expired
            appointmentDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        replyRequests {
          items {
            id
            receivedOn
            mortgageId
            emailId
            replyTo
            requestType
            requestBody
            itemsLoaded
            noteBody
            assocEmails
            queries {
              nextToken
              startedAt
              __typename
            }
            tasks {
              nextToken
              startedAt
              __typename
            }
            repliedBy
            repliedOn
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1ID
        applicant2ID
        lenderTests {
          items {
            id
            lenderCode
            mortgageID
            results
            data
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        requirement {
          id
          applicationElsewhere
          loanRequired
          cohabiting
          loanTerm
          rateId
          rate
          monthlyPayment
          finalRate
          finalTerm
          firstTimeBuyers
          combinedDependants
          combinedGrossEarnings
          combinedLoanRepayments
          depositSavings
          depositGifts
          depositPaid
          helpToBuyScheme
          helpToBuySchemeAmount
          helpToBuySchemeAccessCode
          helpToBuySchemeApplicationCode
          helpToBuySchemeDeveloperName
          firstHomeScheme
          firstHomeSchemeCouncil
          firstHomeSchemeType
          firstHomeSchemeAmount
          lenderId
          lender {
            id
            lender
            address
            approverFirstName
            approverSurname
            approverEmail
            approverPhone
            lenderCode
            rates {
              nextToken
              startedAt
              __typename
            }
            minimumLoan
            minimumTerm
            maximumLoan
            maximumTerm
            onlineAccount
            overlayTemplates {
              nextToken
              startedAt
              __typename
            }
            files {
              nextToken
              startedAt
              __typename
            }
            defaultConditions
            defaultPromotions
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          lenderCode
          lenderProduct
          contactMethod
          proposals
          soft
          drawdownDate
          aipExpiryDate
          aipDate
          loanOfferDate
          loanOfferExpiryDate
          ddBankAccountID
          mortgageAccountNo
          propertyReference
          price
          hasLoans
          hasCards
          receivingGifts
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        property {
          id
          intendedUse
          addressKnown
          propertyType
          address
          newlyBuilt
          bedrooms
          bathrooms
          spendAmount
          purchasePrice
          propertyValue
          monthlyRent
          residingHere
          yearOfPurchase
          adultsBoarding
          adultsBoardingCount
          berScore
          purchaseStage
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        properties {
          items {
            id
            addressKnown
            address
            propertyType
            buildType
            newlyBuilt
            bedrooms
            bathrooms
            berScore
            propertyValue
            propertyPrice
            siteOwned
            siteValue
            constructionCosts
            constructionStarted
            origPurchasePrice
            yearOfPurchase
            propertyAge
            residingHere
            adultsBoarding
            adultsBoardingCount
            isRented
            monthlyRent
            isMortgaged
            isClosed
            isNewAccount
            remainingTerm
            lender
            accountNumber
            monthlyPayment
            currentRate
            outstanding
            bookingDepositPaid
            contractDepositPaid
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            surveyRequired
            surveyDate
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        existing {
          id
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          topUpRequired
          topUpPurpose
          topUpAmount
          readAccessGroup
          editAccessGroup
          propertyId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        needs {
          id
          circumstanceChanges
          circumstanceChangesDetails
          healthIssues
          healthIssuesDetails
          policeRecord
          policeRecordDetails
          incomeChange
          expenditureChange
          moveLikely
          plannedPayOff
          beyondRetirement
          speedEssential
          consolidatingDebt
          furtherRequirements
          furtherRequirementsDetails
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        uploads {
          items {
            id
            contentType
            fileName
            logicalName
            basicInfo {
              id
              institution
              accountType
              accountName
              accountAddress
              friendlyName
              iban
              omit
              numberOfPages
              openingBalance
              closingBalance
              currency
              foreign
              startDate
              endDate
              documentDate
              verifiedData
              error
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            verifiedData {
              nextToken
              startedAt
              __typename
            }
            fileSize
            fileExtension
            stage
            section
            index
            analysis
            status
            statusBy
            statusOn
            mortgageID
            observeMID
            reason
            identityID
            stampedOn
            pdffed
            seenOn
            seenBy
            omit
            documentDate
            exclude
            examined
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        progress {
          items {
            id
            applicationStage
            sectionType
            sectionName
            sectionIndex
            percent
            mortgageID
            percentUpdatedAt
            alertTitle
            alertBody
            alertAuthor
            alertDismissed
            alertDone
            alertPending
            lastDocumentDate
            lastDocumentDateDismissed
            isNewAccount
            metaData
            verifiedData
            sectionChecked
            sectionCheckedBy
            expiresDate
            note
            internalNote
            internalNoteBy
            internalNoteOn
            submittedOn
            submittedBy
            outOfDate
            arrangement
            excludeFromCalculations
            viewState
            notes {
              nextToken
              startedAt
              __typename
            }
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        applicant1 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicant2 {
          id
          subjectId
          identityID
          email
          mobile
          firstName
          surname
          isMale
          provider
          lastHere
          userApplicantPersonalId
          userApplicantIncomeId
          userApplicantFinancialId
          userApplicantEmploymentId
          userApplicantTaxId
          userApplicantLastHereId
          data
          lastHereTime {
            id
            time
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          accounts {
            items {
              id
              institution
              applicantID
              salaried
              joint
              accommodationDD
              accountName
              accountAddress
              currency
              isClosed
              isNewAccount
              accountNumber
              lastDocumentDate
              hasOverdraft
              overdraftLimit
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessAccounts {
            items {
              id
              institution
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          personal {
            id
            title
            dateOfBirth
            maritalStatus
            maidenName
            mothersMaidenName
            nationality
            countryOfBirth
            placeOfBirth
            countryOfResidence
            irishResidenceStartDate
            otherCountryOfResidenceInPast3Years
            countDependents
            dependentAges
            dependentDOBs
            accommodationSituation
            accommodationSituationOther
            addressCurrent
            addressCurrentYears
            addressCurrentStartDate
            addressPrevious
            visaRequired
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          income {
            id
            grossBasic
            hasOvertime
            overtime
            hasBonus
            bonus
            hasCommission
            commission
            hasOther
            other
            otherSource
            otherSourceDetails
            nonEuroCurrency
            maintenance
            welfare
            secondjob
            rentalIncome
            dividends
            payFrequency
            selfIncomeSource
            salariedInstitution
            incomeChecks
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          financial {
            id
            hasAccommodationCosts
            monthlyAccommodationCosts
            hasChildcareCosts
            contributeToRelatives
            negativeCredit
            hasAssets
            hasDebts
            hasIncome
            hasCurrentAccountSavings
            hasSavings
            hasLoans
            hasProperties
            paysMaintenance
            maintenanceCosts
            monthlyChildcareCosts
            monthlySavings
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employment {
            id
            primaryEmploymentId
            employmentType
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerYearsThere
            currentEmployerContact
            previousEmployerName
            previousEmployerAddress
            previousEmployerYearsThere
            positionHeld
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            employmentBasis
            limitedCompany
            isDirector
            percentageOfShares
            currentEmployerStartDate
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerStartDate
            previousEmployerEndDate
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          employmentIncome {
            items {
              id
              applicantID
              employmentType
              employmentBasis
              publicSector
              positionHeld
              currentEmployerName
              currentEmployerAddress
              currentEmployerPhone
              currentEmployerContact
              currentEmployerStartDate
              firstJob
              previousEmploymentBasis
              previousPositionHeld
              previousEmployerName
              previousEmployerAddress
              previousEmployerStartDate
              previousEmployerEndDate
              nameOfBusiness
              natureOfBusiness
              businessAddress
              accountantName
              accountantAddress
              accountantPhone
              businessEstablished
              limitedCompany
              isDirector
              percentageOfShares
              selfIncomeSource
              grossBasic
              incomes
              nonEuroCurrency
              payFrequency
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tax {
            id
            irishTaxResident
            ppsNumber
            usCitizen
            usTaxId
            otherTaxResident
            otherTaxCountry
            otherTaxId
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          assets {
            items {
              id
              assetType
              institution
              description
              balance
              increasesFrequency
              increases
              currency
              isClosed
              isNewAccount
              accountNumber
              accountName
              accountAddress
              lastDocumentDate
              applicantID
              joint
              observeMID
              nameAndAddress
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          properties {
            items {
              id
              propertyType
              address
              estimatedValue
              isRented
              rentPerAnnum
              rentPerMonth
              isMortgaged
              isClosed
              isNewAccount
              accountNumber
              originalAmount
              outstanding
              monthlyRepayments
              remainingTerm
              endDate
              currency
              lastDocumentDate
              lender
              inArrears
              arrears
              applicantID
              bedrooms
              bathrooms
              isRestructured
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          debts {
            items {
              id
              debtType
              lender
              outstanding
              originalAmount
              monthlyPayment
              creditLimit
              inArrears
              isClosed
              isNewAccount
              arrears
              endDate
              accountNumber
              lastDocumentDate
              currency
              clearedBeforeMortgage
              applicantID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          businessDebts {
            items {
              id
              debtType
              lender
              outstanding
              monthlyPayment
              creditLimit
              inArrears
              arrears
              endDate
              clearedBeforeMortgage
              applicantID
              accountNumber
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          activity {
            items {
              id
              actions
              userAgent
              actionedOn
              actionedBy
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          emails {
            items {
              id
              from
              to
              cc
              bcc
              subject
              hasAttachments
              v2Attachments
              html
              attachments
              sortDate
              datetimeReceived
              datetimeSent
              messageId
              originalId
              isReceived
              isReceivedInt
              readOn
              readBy
              replyRequestId
              appsyncVersion
              actionedOn
              actionedBy
              userapplicantID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          alternateEmailAddresses {
            items {
              id
              email
              applicantID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          health {
            id
            weight
            height
            smoker
            everSmoked
            cigarettesSmoked
            cigarettesPerDay
            cigarSmoked
            cigarsPerDay
            pipeSmoked
            pipeGramsPerDay
            electronicCigaretteSmoked
            otherNicotineReplacementUsed
            stoppedSmokingDate
            alcoholUnits
            dangerousActivities
            familyDeath
            diagnosedConditions
            diagnosedConditionsDetails
            prescribedMedication
            prescribedMedicationDetails
            hasGp
            gpName
            gpAddress
            gpLastVisit
            gpLastVisitYearsAgo
            yearsWithGp
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        applicationStage
        applicationFutureDate
        applicationStatus
        notes {
          items {
            id
            body
            author
            pinned
            deleted
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        queries {
          items {
            id
            mortgageID
            emailReplyID
            createdBy
            referenceType
            referenceId
            stage
            section
            index
            title
            date
            description
            amount
            query
            userAnswer
            userAnswerOn
            userAnswerBy
            userAnswered
            adminAnswered
            lenderAnswer
            lenderAnswerOn
            lenderAnswerBy
            excludedOn
            excludedBy
            private
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        settings {
          id
          initialContactOn
          initialContactBy
          mortgageID
          contactTimes
          markedLead
          markedLeadBy
          markedSetupDone
          markedSetupDoneBy
          markedApplyDone
          markedApplyDoneBy
          markedComplete
          markedCompleteBy
          markedPreApproved
          markedPreApprovedBy
          markedApproved
          markedApprovedBy
          markedDocsSigned
          markedDocsSignedBy
          markedSubmitted
          markedSubmittedBy
          markedSuspended
          markedSuspendedBy
          offlineLead
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        solicitor {
          id
          firmName
          firmAddress
          contactName
          contactEmail
          contactPhone
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        dynamicSections {
          items {
            id
            sectionStage
            sectionType
            sectionName
            sectionIndex
            sectionTitle
            sectionApplicant
            sectionInstructions
            mortgageID
            emailReplyID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tasks {
          items {
            id
            body
            author
            pinned
            due
            deleted
            target
            targetStarted
            targetLastActive
            completedOn
            completedBy
            paused
            closedOn
            closedBy
            folder
            userFolder
            userSortPosition
            tags
            currentOwner
            lastMessageBy
            lastMessageOn
            lastMessage
            toDos {
              nextToken
              startedAt
              __typename
            }
            pauses
            components {
              nextToken
              startedAt
              __typename
            }
            usermortgageID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stageTasks {
          items {
            id
            mortgageID
            stage
            name
            deleted
            due
            completedOn
            completedBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        invitations {
          items {
            id
            mortgageID
            applicant2ID
            firstName
            email
            inviter
            inviterFirstName
            sent
            accepted
            invalidated
            inviteType
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        docContents {
          items {
            id
            mortgageID
            paragraph
            folder
            document
            sequence
            html
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        lastSeen
        lastFlowItem
        lastFlowItemTime
        completedFlowItems
        completedFlowItemsForAdmin
        qualification
        qualificationConfirmed
        adminMessage
        adminMessageBy
        adminMessageOn
        advisorID
        caseNumber
        friendlyName
        UserMortgageDocuments {
          items {
            id
            title
            description
            identityID
            identityID2
            folder
            fileName
            fileExtension
            isDynamic
            name
            stage
            private
            submissionID
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        notifications {
          items {
            id
            mortgageID
            stage
            name
            history {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        submissions {
          items {
            id
            lenderCode
            provisionalProduct
            provisionalRateId
            provisionalRate
            provisionalTerm
            provisionalLoan
            provisionalValue
            provisionalSecondaryProduct
            provisionalSecondaryRate
            provisionalSecondaryRateId
            provisionalXlsInput
            provisionalXlsOutput
            finalProduct
            finalRate
            finalRateId
            finalTerm
            finalLoan
            finalValue
            finalSecondaryProduct
            finalSecondaryRate
            finalSecondaryRateId
            finalXlsInput
            finalXlsOutput
            soft
            aipEmailId
            aipMaxLoan
            aipPropertyReference
            aipExpiryDate
            aipDate
            aipNotes
            aipSettings
            loanOfferEmailId
            loanOfferDate
            loanOfferExpiryDate
            loanOfferNotes
            loanOfferSettings
            drawdownDate
            completedFlowItems
            completedFlowItemsForAdmin
            reinstatementValue
            valuationValue
            valuationInstructed
            valuationDate
            valuationExpiryDate
            anotherValuationNeeded
            hasValuationIssues
            valuationIssues
            valuation2Instructed
            valuation2Date
            valuation2ExpiryDate
            valuation2ReinstatementValue
            reinstatement2Value
            valuation2Value
            hasValuation2Issues
            valuation2Issues
            surveyRequired
            surveyDate
            lenderRecommendation
            productRecommendation
            termRecommendation
            mortgageAccountNo
            invalidatedReason
            submissionStage
            documents {
              nextToken
              startedAt
              __typename
            }
            issues {
              nextToken
              startedAt
              __typename
            }
            mortgageID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        adminSubmissions {
          items {
            id
            mortgageID
            provisionalXlsInput
            provisionalXlsOutput
            finalXlsInput
            finalXlsOutput
            lenderRecommendation
            productRecommendation
            termRecommendation
            secondaryProduct
            secondaryRate
            invalidatedReason
            aipNotes
            loanOfferNotes
            completedFlowItemsForAdmin
            issues {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        uploadsPercentSetup
        uploadsPercentApply
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userMortgageRequirementId
        userMortgagePropertyId
        userMortgageExistingId
        userMortgageNeedsId
        userMortgageSettingsId
        userMortgageSolicitorId
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageInvitation = /* GraphQL */ `
  query GetUserMortgageInvitation($id: ID!) {
    getUserMortgageInvitation(id: $id) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageInvitations = /* GraphQL */ `
  query ListUserMortgageInvitations(
    $filter: ModelUserMortgageInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        applicant2ID
        firstName
        email
        inviter
        inviterFirstName
        sent
        accepted
        invalidated
        inviteType
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageInvitations = /* GraphQL */ `
  query SyncUserMortgageInvitations(
    $filter: ModelUserMortgageInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        mortgageID
        applicant2ID
        firstName
        email
        inviter
        inviterFirstName
        sent
        accepted
        invalidated
        inviteType
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageInvitationsByMortgageID = /* GraphQL */ `
  query UserMortgageInvitationsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageInvitationsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        applicant2ID
        firstName
        email
        inviter
        inviterFirstName
        sent
        accepted
        invalidated
        inviteType
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageInvitationsByEmail = /* GraphQL */ `
  query UserMortgageInvitationsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageInvitationsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mortgageID
        applicant2ID
        firstName
        email
        inviter
        inviterFirstName
        sent
        accepted
        invalidated
        inviteType
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageDocuments = /* GraphQL */ `
  query GetUserMortgageDocuments($id: ID!) {
    getUserMortgageDocuments(id: $id) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      submissionID
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageDocuments = /* GraphQL */ `
  query ListUserMortgageDocuments(
    $filter: ModelUserMortgageDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        identityID
        identityID2
        folder
        fileName
        fileExtension
        isDynamic
        name
        stage
        private
        submissionID
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageDocuments = /* GraphQL */ `
  query SyncUserMortgageDocuments(
    $filter: ModelUserMortgageDocumentsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        identityID
        identityID2
        folder
        fileName
        fileExtension
        isDynamic
        name
        stage
        private
        submissionID
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageDocumentsBySubmissionID = /* GraphQL */ `
  query UserMortgageDocumentsBySubmissionID(
    $submissionID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageDocumentsBySubmissionID(
      submissionID: $submissionID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        identityID
        identityID2
        folder
        fileName
        fileExtension
        isDynamic
        name
        stage
        private
        submissionID
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageDocumentsByMortgageID = /* GraphQL */ `
  query UserMortgageDocumentsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageDocumentsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        identityID
        identityID2
        folder
        fileName
        fileExtension
        isDynamic
        name
        stage
        private
        submissionID
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageProgress = /* GraphQL */ `
  query GetUserMortgageProgress($id: ID!) {
    getUserMortgageProgress(id: $id) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      lastDocumentDateDismissed
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      viewState
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageProgresses = /* GraphQL */ `
  query ListUserMortgageProgresses(
    $filter: ModelUserMortgageProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationStage
        sectionType
        sectionName
        sectionIndex
        percent
        mortgageID
        percentUpdatedAt
        alertTitle
        alertBody
        alertAuthor
        alertDismissed
        alertDone
        alertPending
        lastDocumentDate
        lastDocumentDateDismissed
        isNewAccount
        metaData
        verifiedData
        sectionChecked
        sectionCheckedBy
        expiresDate
        note
        internalNote
        internalNoteBy
        internalNoteOn
        submittedOn
        submittedBy
        outOfDate
        arrangement
        excludeFromCalculations
        viewState
        notes {
          items {
            id
            text
            readBy
            readTime
            userMortgageProgressID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageProgresses = /* GraphQL */ `
  query SyncUserMortgageProgresses(
    $filter: ModelUserMortgageProgressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        applicationStage
        sectionType
        sectionName
        sectionIndex
        percent
        mortgageID
        percentUpdatedAt
        alertTitle
        alertBody
        alertAuthor
        alertDismissed
        alertDone
        alertPending
        lastDocumentDate
        lastDocumentDateDismissed
        isNewAccount
        metaData
        verifiedData
        sectionChecked
        sectionCheckedBy
        expiresDate
        note
        internalNote
        internalNoteBy
        internalNoteOn
        submittedOn
        submittedBy
        outOfDate
        arrangement
        excludeFromCalculations
        viewState
        notes {
          items {
            id
            text
            readBy
            readTime
            userMortgageProgressID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageProgressesByMortgageID = /* GraphQL */ `
  query UserMortgageProgressesByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageProgressesByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationStage
        sectionType
        sectionName
        sectionIndex
        percent
        mortgageID
        percentUpdatedAt
        alertTitle
        alertBody
        alertAuthor
        alertDismissed
        alertDone
        alertPending
        lastDocumentDate
        lastDocumentDateDismissed
        isNewAccount
        metaData
        verifiedData
        sectionChecked
        sectionCheckedBy
        expiresDate
        note
        internalNote
        internalNoteBy
        internalNoteOn
        submittedOn
        submittedBy
        outOfDate
        arrangement
        excludeFromCalculations
        viewState
        notes {
          items {
            id
            text
            readBy
            readTime
            userMortgageProgressID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageProgressNotes = /* GraphQL */ `
  query GetUserMortgageProgressNotes($id: ID!) {
    getUserMortgageProgressNotes(id: $id) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageProgressNotes = /* GraphQL */ `
  query ListUserMortgageProgressNotes(
    $filter: ModelUserMortgageProgressNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageProgressNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        readBy
        readTime
        userMortgageProgressID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageProgressNotes = /* GraphQL */ `
  query SyncUserMortgageProgressNotes(
    $filter: ModelUserMortgageProgressNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageProgressNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        readBy
        readTime
        userMortgageProgressID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageProgressNotesByUserMortgageProgressID = /* GraphQL */ `
  query UserMortgageProgressNotesByUserMortgageProgressID(
    $userMortgageProgressID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageProgressNotesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageProgressNotesByUserMortgageProgressID(
      userMortgageProgressID: $userMortgageProgressID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        readBy
        readTime
        userMortgageProgressID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageDynamicSection = /* GraphQL */ `
  query GetUserMortgageDynamicSection($id: ID!) {
    getUserMortgageDynamicSection(id: $id) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageDynamicSections = /* GraphQL */ `
  query ListUserMortgageDynamicSections(
    $filter: ModelUserMortgageDynamicSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageDynamicSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sectionStage
        sectionType
        sectionName
        sectionIndex
        sectionTitle
        sectionApplicant
        sectionInstructions
        mortgageID
        emailReplyID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageDynamicSections = /* GraphQL */ `
  query SyncUserMortgageDynamicSections(
    $filter: ModelUserMortgageDynamicSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageDynamicSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sectionStage
        sectionType
        sectionName
        sectionIndex
        sectionTitle
        sectionApplicant
        sectionInstructions
        mortgageID
        emailReplyID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageDynamicSectionsByMortgageID = /* GraphQL */ `
  query UserMortgageDynamicSectionsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageDynamicSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageDynamicSectionsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sectionStage
        sectionType
        sectionName
        sectionIndex
        sectionTitle
        sectionApplicant
        sectionInstructions
        mortgageID
        emailReplyID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageDynamicSectionsByEmailReplyID = /* GraphQL */ `
  query UserMortgageDynamicSectionsByEmailReplyID(
    $emailReplyID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageDynamicSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageDynamicSectionsByEmailReplyID(
      emailReplyID: $emailReplyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sectionStage
        sectionType
        sectionName
        sectionIndex
        sectionTitle
        sectionApplicant
        sectionInstructions
        mortgageID
        emailReplyID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageUploads = /* GraphQL */ `
  query GetUserMortgageUploads($id: ID!) {
    getUserMortgageUploads(id: $id) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      pdffed
      seenOn
      seenBy
      omit
      documentDate
      exclude
      examined
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageUploads = /* GraphQL */ `
  query ListUserMortgageUploads(
    $filter: ModelUserMortgageUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageUploads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        fileName
        logicalName
        basicInfo {
          id
          institution
          accountType
          accountName
          accountAddress
          friendlyName
          iban
          omit
          numberOfPages
          openingBalance
          closingBalance
          currency
          foreign
          startDate
          endDate
          documentDate
          verifiedData
          error
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        verifiedData {
          items {
            id
            uploadID
            mortgageID
            name
            verifiedValue
            verifiedValueType
            verificationOn
            verificationBy
            verificationNotes {
              nextToken
              startedAt
              __typename
            }
            supervisorCheckRequired
            supervisorCheckOn
            supervisorCheckBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        fileSize
        fileExtension
        stage
        section
        index
        analysis
        status
        statusBy
        statusOn
        mortgageID
        observeMID
        reason
        identityID
        stampedOn
        pdffed
        seenOn
        seenBy
        omit
        documentDate
        exclude
        examined
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageUploads = /* GraphQL */ `
  query SyncUserMortgageUploads(
    $filter: ModelUserMortgageUploadsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageUploads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        contentType
        fileName
        logicalName
        basicInfo {
          id
          institution
          accountType
          accountName
          accountAddress
          friendlyName
          iban
          omit
          numberOfPages
          openingBalance
          closingBalance
          currency
          foreign
          startDate
          endDate
          documentDate
          verifiedData
          error
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        verifiedData {
          items {
            id
            uploadID
            mortgageID
            name
            verifiedValue
            verifiedValueType
            verificationOn
            verificationBy
            verificationNotes {
              nextToken
              startedAt
              __typename
            }
            supervisorCheckRequired
            supervisorCheckOn
            supervisorCheckBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        fileSize
        fileExtension
        stage
        section
        index
        analysis
        status
        statusBy
        statusOn
        mortgageID
        observeMID
        reason
        identityID
        stampedOn
        pdffed
        seenOn
        seenBy
        omit
        documentDate
        exclude
        examined
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageUploadsByStatus = /* GraphQL */ `
  query UserMortgageUploadsByStatus(
    $status: UploadStatuses!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageUploadsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        fileName
        logicalName
        basicInfo {
          id
          institution
          accountType
          accountName
          accountAddress
          friendlyName
          iban
          omit
          numberOfPages
          openingBalance
          closingBalance
          currency
          foreign
          startDate
          endDate
          documentDate
          verifiedData
          error
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        verifiedData {
          items {
            id
            uploadID
            mortgageID
            name
            verifiedValue
            verifiedValueType
            verificationOn
            verificationBy
            verificationNotes {
              nextToken
              startedAt
              __typename
            }
            supervisorCheckRequired
            supervisorCheckOn
            supervisorCheckBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        fileSize
        fileExtension
        stage
        section
        index
        analysis
        status
        statusBy
        statusOn
        mortgageID
        observeMID
        reason
        identityID
        stampedOn
        pdffed
        seenOn
        seenBy
        omit
        documentDate
        exclude
        examined
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageUploadsByMortgageID = /* GraphQL */ `
  query UserMortgageUploadsByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageUploadsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageUploadsByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        fileName
        logicalName
        basicInfo {
          id
          institution
          accountType
          accountName
          accountAddress
          friendlyName
          iban
          omit
          numberOfPages
          openingBalance
          closingBalance
          currency
          foreign
          startDate
          endDate
          documentDate
          verifiedData
          error
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        verifiedData {
          items {
            id
            uploadID
            mortgageID
            name
            verifiedValue
            verifiedValueType
            verificationOn
            verificationBy
            verificationNotes {
              nextToken
              startedAt
              __typename
            }
            supervisorCheckRequired
            supervisorCheckOn
            supervisorCheckBy
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        fileSize
        fileExtension
        stage
        section
        index
        analysis
        status
        statusBy
        statusOn
        mortgageID
        observeMID
        reason
        identityID
        stampedOn
        pdffed
        seenOn
        seenBy
        omit
        documentDate
        exclude
        examined
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageRequirement = /* GraphQL */ `
  query GetUserMortgageRequirement($id: ID!) {
    getUserMortgageRequirement(id: $id) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageRequirements = /* GraphQL */ `
  query ListUserMortgageRequirements(
    $filter: ModelUserMortgageRequirementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageRequirements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageRequirements = /* GraphQL */ `
  query SyncUserMortgageRequirements(
    $filter: ModelUserMortgageRequirementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageRequirements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageProperty = /* GraphQL */ `
  query GetUserMortgageProperty($id: ID!) {
    getUserMortgageProperty(id: $id) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageProperties = /* GraphQL */ `
  query ListUserMortgageProperties(
    $filter: ModelUserMortgagePropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageProperties = /* GraphQL */ `
  query SyncUserMortgageProperties(
    $filter: ModelUserMortgagePropertyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagePropertiesByMortgageID = /* GraphQL */ `
  query UserMortgagePropertiesByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgagePropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagePropertiesByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageExisting = /* GraphQL */ `
  query GetUserMortgageExisting($id: ID!) {
    getUserMortgageExisting(id: $id) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageExistings = /* GraphQL */ `
  query ListUserMortgageExistings(
    $filter: ModelUserMortgageExistingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageExistings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageExistings = /* GraphQL */ `
  query SyncUserMortgageExistings(
    $filter: ModelUserMortgageExistingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageExistings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgageExistingsByPropertyId = /* GraphQL */ `
  query UserMortgageExistingsByPropertyId(
    $propertyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgageExistingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgageExistingsByPropertyId(
      propertyId: $propertyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageNeeds = /* GraphQL */ `
  query GetUserMortgageNeeds($id: ID!) {
    getUserMortgageNeeds(id: $id) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageNeeds = /* GraphQL */ `
  query ListUserMortgageNeeds(
    $filter: ModelUserMortgageNeedsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageNeeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageNeeds = /* GraphQL */ `
  query SyncUserMortgageNeeds(
    $filter: ModelUserMortgageNeedsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageNeeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgageSolicitor = /* GraphQL */ `
  query GetUserMortgageSolicitor($id: ID!) {
    getUserMortgageSolicitor(id: $id) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgageSolicitors = /* GraphQL */ `
  query ListUserMortgageSolicitors(
    $filter: ModelUserMortgageSolicitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgageSolicitors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgageSolicitors = /* GraphQL */ `
  query SyncUserMortgageSolicitors(
    $filter: ModelUserMortgageSolicitorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgageSolicitors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserMortgagePropertyOnMortgage = /* GraphQL */ `
  query GetUserMortgagePropertyOnMortgage($id: ID!) {
    getUserMortgagePropertyOnMortgage(id: $id) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      propertyAge
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      isClosed
      isNewAccount
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserMortgagePropertyOnMortgages = /* GraphQL */ `
  query ListUserMortgagePropertyOnMortgages(
    $filter: ModelUserMortgagePropertyOnMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMortgagePropertyOnMortgages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        addressKnown
        address
        propertyType
        buildType
        newlyBuilt
        bedrooms
        bathrooms
        berScore
        propertyValue
        propertyPrice
        siteOwned
        siteValue
        constructionCosts
        constructionStarted
        origPurchasePrice
        yearOfPurchase
        propertyAge
        residingHere
        adultsBoarding
        adultsBoardingCount
        isRented
        monthlyRent
        isMortgaged
        isClosed
        isNewAccount
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        bookingDepositPaid
        contractDepositPaid
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        surveyRequired
        surveyDate
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserMortgagePropertyOnMortgages = /* GraphQL */ `
  query SyncUserMortgagePropertyOnMortgages(
    $filter: ModelUserMortgagePropertyOnMortgageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserMortgagePropertyOnMortgages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        addressKnown
        address
        propertyType
        buildType
        newlyBuilt
        bedrooms
        bathrooms
        berScore
        propertyValue
        propertyPrice
        siteOwned
        siteValue
        constructionCosts
        constructionStarted
        origPurchasePrice
        yearOfPurchase
        propertyAge
        residingHere
        adultsBoarding
        adultsBoardingCount
        isRented
        monthlyRent
        isMortgaged
        isClosed
        isNewAccount
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        bookingDepositPaid
        contractDepositPaid
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        surveyRequired
        surveyDate
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userMortgagePropertyOnMortgagesByMortgageID = /* GraphQL */ `
  query UserMortgagePropertyOnMortgagesByMortgageID(
    $mortgageID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMortgagePropertyOnMortgageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userMortgagePropertyOnMortgagesByMortgageID(
      mortgageID: $mortgageID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        addressKnown
        address
        propertyType
        buildType
        newlyBuilt
        bedrooms
        bathrooms
        berScore
        propertyValue
        propertyPrice
        siteOwned
        siteValue
        constructionCosts
        constructionStarted
        origPurchasePrice
        yearOfPurchase
        propertyAge
        residingHere
        adultsBoarding
        adultsBoardingCount
        isRented
        monthlyRent
        isMortgaged
        isClosed
        isNewAccount
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        bookingDepositPaid
        contractDepositPaid
        reinstatementValue
        valuationValue
        valuationInstructed
        valuationDate
        valuationExpiryDate
        anotherValuationNeeded
        hasValuationIssues
        valuationIssues
        surveyRequired
        surveyDate
        valuation2Instructed
        valuation2Date
        valuation2ExpiryDate
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicant = /* GraphQL */ `
  query GetUserApplicant($id: ID!) {
    getUserApplicant(id: $id) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          isClosed
          isNewAccount
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          isClosed
          isNewAccount
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          rentPerMonth
          isMortgaged
          isClosed
          isNewAccount
          accountNumber
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          isClosed
          isNewAccount
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicants = /* GraphQL */ `
  query ListUserApplicants(
    $filter: ModelUserApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicants = /* GraphQL */ `
  query SyncUserApplicants(
    $filter: ModelUserApplicantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantsBySubjectId = /* GraphQL */ `
  query UserApplicantsBySubjectId(
    $subjectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantsBySubjectId(
      subjectId: $subjectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantsByEmail = /* GraphQL */ `
  query UserApplicantsByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantLastHere = /* GraphQL */ `
  query GetUserApplicantLastHere($id: ID!) {
    getUserApplicantLastHere(id: $id) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantLastHeres = /* GraphQL */ `
  query ListUserApplicantLastHeres(
    $filter: ModelUserApplicantLastHereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantLastHeres(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantLastHeres = /* GraphQL */ `
  query SyncUserApplicantLastHeres(
    $filter: ModelUserApplicantLastHereFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantLastHeres(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantLastHeresByTime = /* GraphQL */ `
  query UserApplicantLastHeresByTime(
    $time: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantLastHereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantLastHeresByTime(
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantCurrentAccount = /* GraphQL */ `
  query GetUserApplicantCurrentAccount($id: ID!) {
    getUserApplicantCurrentAccount(id: $id) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      isClosed
      isNewAccount
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantCurrentAccounts = /* GraphQL */ `
  query ListUserApplicantCurrentAccounts(
    $filter: ModelUserApplicantCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantCurrentAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution
        applicantID
        salaried
        joint
        accommodationDD
        accountName
        accountAddress
        currency
        isClosed
        isNewAccount
        accountNumber
        lastDocumentDate
        hasOverdraft
        overdraftLimit
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantCurrentAccounts = /* GraphQL */ `
  query SyncUserApplicantCurrentAccounts(
    $filter: ModelUserApplicantCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantCurrentAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        institution
        applicantID
        salaried
        joint
        accommodationDD
        accountName
        accountAddress
        currency
        isClosed
        isNewAccount
        accountNumber
        lastDocumentDate
        hasOverdraft
        overdraftLimit
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantCurrentAccountsByApplicantID = /* GraphQL */ `
  query UserApplicantCurrentAccountsByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantCurrentAccountsByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution
        applicantID
        salaried
        joint
        accommodationDD
        accountName
        accountAddress
        currency
        isClosed
        isNewAccount
        accountNumber
        lastDocumentDate
        hasOverdraft
        overdraftLimit
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  query GetUserApplicantBusinessCurrentAccount($id: ID!) {
    getUserApplicantBusinessCurrentAccount(id: $id) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantBusinessCurrentAccounts = /* GraphQL */ `
  query ListUserApplicantBusinessCurrentAccounts(
    $filter: ModelUserApplicantBusinessCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantBusinessCurrentAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantBusinessCurrentAccounts = /* GraphQL */ `
  query SyncUserApplicantBusinessCurrentAccounts(
    $filter: ModelUserApplicantBusinessCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantBusinessCurrentAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        institution
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantBusinessCurrentAccountsByApplicantID = /* GraphQL */ `
  query UserApplicantBusinessCurrentAccountsByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantBusinessCurrentAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantBusinessCurrentAccountsByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        institution
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantTax = /* GraphQL */ `
  query GetUserApplicantTax($id: ID!) {
    getUserApplicantTax(id: $id) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantTaxes = /* GraphQL */ `
  query ListUserApplicantTaxes(
    $filter: ModelUserApplicantTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantTaxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantTaxes = /* GraphQL */ `
  query SyncUserApplicantTaxes(
    $filter: ModelUserApplicantTaxFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantTaxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantPersonal = /* GraphQL */ `
  query GetUserApplicantPersonal($id: ID!) {
    getUserApplicantPersonal(id: $id) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantPersonals = /* GraphQL */ `
  query ListUserApplicantPersonals(
    $filter: ModelUserApplicantPersonalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantPersonals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantPersonals = /* GraphQL */ `
  query SyncUserApplicantPersonals(
    $filter: ModelUserApplicantPersonalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantPersonals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantIncome = /* GraphQL */ `
  query GetUserApplicantIncome($id: ID!) {
    getUserApplicantIncome(id: $id) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantIncomes = /* GraphQL */ `
  query ListUserApplicantIncomes(
    $filter: ModelUserApplicantIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantIncomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantIncomes = /* GraphQL */ `
  query SyncUserApplicantIncomes(
    $filter: ModelUserApplicantIncomeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantIncomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantFinancial = /* GraphQL */ `
  query GetUserApplicantFinancial($id: ID!) {
    getUserApplicantFinancial(id: $id) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantFinancials = /* GraphQL */ `
  query ListUserApplicantFinancials(
    $filter: ModelUserApplicantFinancialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantFinancials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantFinancials = /* GraphQL */ `
  query SyncUserApplicantFinancials(
    $filter: ModelUserApplicantFinancialFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantFinancials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantEmployment = /* GraphQL */ `
  query GetUserApplicantEmployment($id: ID!) {
    getUserApplicantEmployment(id: $id) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantEmployments = /* GraphQL */ `
  query ListUserApplicantEmployments(
    $filter: ModelUserApplicantEmploymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantEmployments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantEmployments = /* GraphQL */ `
  query SyncUserApplicantEmployments(
    $filter: ModelUserApplicantEmploymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantEmployments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantEmploymentIncome = /* GraphQL */ `
  query GetUserApplicantEmploymentIncome($id: ID!) {
    getUserApplicantEmploymentIncome(id: $id) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantEmploymentIncomes = /* GraphQL */ `
  query ListUserApplicantEmploymentIncomes(
    $filter: ModelUserApplicantEmploymentIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantEmploymentIncomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantID
        employmentType
        employmentBasis
        publicSector
        positionHeld
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerContact
        currentEmployerStartDate
        firstJob
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerName
        previousEmployerAddress
        previousEmployerStartDate
        previousEmployerEndDate
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        limitedCompany
        isDirector
        percentageOfShares
        selfIncomeSource
        grossBasic
        incomes
        nonEuroCurrency
        payFrequency
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantEmploymentIncomes = /* GraphQL */ `
  query SyncUserApplicantEmploymentIncomes(
    $filter: ModelUserApplicantEmploymentIncomeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantEmploymentIncomes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        applicantID
        employmentType
        employmentBasis
        publicSector
        positionHeld
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerContact
        currentEmployerStartDate
        firstJob
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerName
        previousEmployerAddress
        previousEmployerStartDate
        previousEmployerEndDate
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        limitedCompany
        isDirector
        percentageOfShares
        selfIncomeSource
        grossBasic
        incomes
        nonEuroCurrency
        payFrequency
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantEmploymentIncomesByApplicantID = /* GraphQL */ `
  query UserApplicantEmploymentIncomesByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantEmploymentIncomeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantEmploymentIncomesByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantID
        employmentType
        employmentBasis
        publicSector
        positionHeld
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerContact
        currentEmployerStartDate
        firstJob
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerName
        previousEmployerAddress
        previousEmployerStartDate
        previousEmployerEndDate
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        limitedCompany
        isDirector
        percentageOfShares
        selfIncomeSource
        grossBasic
        incomes
        nonEuroCurrency
        payFrequency
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantAsset = /* GraphQL */ `
  query GetUserApplicantAsset($id: ID!) {
    getUserApplicantAsset(id: $id) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      isClosed
      isNewAccount
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantAssets = /* GraphQL */ `
  query ListUserApplicantAssets(
    $filter: ModelUserApplicantAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetType
        institution
        description
        balance
        increasesFrequency
        increases
        currency
        isClosed
        isNewAccount
        accountNumber
        accountName
        accountAddress
        lastDocumentDate
        applicantID
        joint
        observeMID
        nameAndAddress
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantAssets = /* GraphQL */ `
  query SyncUserApplicantAssets(
    $filter: ModelUserApplicantAssetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        assetType
        institution
        description
        balance
        increasesFrequency
        increases
        currency
        isClosed
        isNewAccount
        accountNumber
        accountName
        accountAddress
        lastDocumentDate
        applicantID
        joint
        observeMID
        nameAndAddress
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantAssetsByApplicantID = /* GraphQL */ `
  query UserApplicantAssetsByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantAssetsByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetType
        institution
        description
        balance
        increasesFrequency
        increases
        currency
        isClosed
        isNewAccount
        accountNumber
        accountName
        accountAddress
        lastDocumentDate
        applicantID
        joint
        observeMID
        nameAndAddress
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantDebt = /* GraphQL */ `
  query GetUserApplicantDebt($id: ID!) {
    getUserApplicantDebt(id: $id) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      isClosed
      isNewAccount
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantDebts = /* GraphQL */ `
  query ListUserApplicantDebts(
    $filter: ModelUserApplicantDebtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantDebts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debtType
        lender
        outstanding
        originalAmount
        monthlyPayment
        creditLimit
        inArrears
        isClosed
        isNewAccount
        arrears
        endDate
        accountNumber
        lastDocumentDate
        currency
        clearedBeforeMortgage
        applicantID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantDebts = /* GraphQL */ `
  query SyncUserApplicantDebts(
    $filter: ModelUserApplicantDebtFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantDebts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        debtType
        lender
        outstanding
        originalAmount
        monthlyPayment
        creditLimit
        inArrears
        isClosed
        isNewAccount
        arrears
        endDate
        accountNumber
        lastDocumentDate
        currency
        clearedBeforeMortgage
        applicantID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantDebtsByApplicantID = /* GraphQL */ `
  query UserApplicantDebtsByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantDebtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantDebtsByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debtType
        lender
        outstanding
        originalAmount
        monthlyPayment
        creditLimit
        inArrears
        isClosed
        isNewAccount
        arrears
        endDate
        accountNumber
        lastDocumentDate
        currency
        clearedBeforeMortgage
        applicantID
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantBusinessDebt = /* GraphQL */ `
  query GetUserApplicantBusinessDebt($id: ID!) {
    getUserApplicantBusinessDebt(id: $id) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantBusinessDebts = /* GraphQL */ `
  query ListUserApplicantBusinessDebts(
    $filter: ModelUserApplicantBusinessDebtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantBusinessDebts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debtType
        lender
        outstanding
        monthlyPayment
        creditLimit
        inArrears
        arrears
        endDate
        clearedBeforeMortgage
        applicantID
        accountNumber
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantBusinessDebts = /* GraphQL */ `
  query SyncUserApplicantBusinessDebts(
    $filter: ModelUserApplicantBusinessDebtFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantBusinessDebts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        debtType
        lender
        outstanding
        monthlyPayment
        creditLimit
        inArrears
        arrears
        endDate
        clearedBeforeMortgage
        applicantID
        accountNumber
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantBusinessDebtsByApplicantID = /* GraphQL */ `
  query UserApplicantBusinessDebtsByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantBusinessDebtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantBusinessDebtsByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        debtType
        lender
        outstanding
        monthlyPayment
        creditLimit
        inArrears
        arrears
        endDate
        clearedBeforeMortgage
        applicantID
        accountNumber
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantProperty = /* GraphQL */ `
  query GetUserApplicantProperty($id: ID!) {
    getUserApplicantProperty(id: $id) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      rentPerMonth
      isMortgaged
      isClosed
      isNewAccount
      accountNumber
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantProperties = /* GraphQL */ `
  query ListUserApplicantProperties(
    $filter: ModelUserApplicantPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyType
        address
        estimatedValue
        isRented
        rentPerAnnum
        rentPerMonth
        isMortgaged
        isClosed
        isNewAccount
        accountNumber
        originalAmount
        outstanding
        monthlyRepayments
        remainingTerm
        endDate
        currency
        lastDocumentDate
        lender
        inArrears
        arrears
        applicantID
        bedrooms
        bathrooms
        isRestructured
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantProperties = /* GraphQL */ `
  query SyncUserApplicantProperties(
    $filter: ModelUserApplicantPropertyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyType
        address
        estimatedValue
        isRented
        rentPerAnnum
        rentPerMonth
        isMortgaged
        isClosed
        isNewAccount
        accountNumber
        originalAmount
        outstanding
        monthlyRepayments
        remainingTerm
        endDate
        currency
        lastDocumentDate
        lender
        inArrears
        arrears
        applicantID
        bedrooms
        bathrooms
        isRestructured
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantPropertiesByApplicantID = /* GraphQL */ `
  query UserApplicantPropertiesByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantPropertiesByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyType
        address
        estimatedValue
        isRented
        rentPerAnnum
        rentPerMonth
        isMortgaged
        isClosed
        isNewAccount
        accountNumber
        originalAmount
        outstanding
        monthlyRepayments
        remainingTerm
        endDate
        currency
        lastDocumentDate
        lender
        inArrears
        arrears
        applicantID
        bedrooms
        bathrooms
        isRestructured
        observeMID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  query GetUserApplicantAlternateEmailAddresses($id: ID!) {
    getUserApplicantAlternateEmailAddresses(id: $id) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  query ListUserApplicantAlternateEmailAddresses(
    $filter: ModelUserApplicantAlternateEmailAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantAlternateEmailAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  query SyncUserApplicantAlternateEmailAddresses(
    $filter: ModelUserApplicantAlternateEmailAddressesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantAlternateEmailAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantAlternateEmailAddressesByEmail = /* GraphQL */ `
  query UserApplicantAlternateEmailAddressesByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantAlternateEmailAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantAlternateEmailAddressesByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantAlternateEmailAddressesByApplicantID = /* GraphQL */ `
  query UserApplicantAlternateEmailAddressesByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantAlternateEmailAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantAlternateEmailAddressesByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantActivity = /* GraphQL */ `
  query GetUserApplicantActivity($id: ID!) {
    getUserApplicantActivity(id: $id) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantActivities = /* GraphQL */ `
  query ListUserApplicantActivities(
    $filter: ModelUserApplicantActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actions
        userAgent
        actionedOn
        actionedBy
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantActivities = /* GraphQL */ `
  query SyncUserApplicantActivities(
    $filter: ModelUserApplicantActivityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actions
        userAgent
        actionedOn
        actionedBy
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantActivitiesByActionedBy = /* GraphQL */ `
  query UserApplicantActivitiesByActionedBy(
    $actionedBy: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantActivitiesByActionedBy(
      actionedBy: $actionedBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actions
        userAgent
        actionedOn
        actionedBy
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const userApplicantActivitiesByApplicantID = /* GraphQL */ `
  query UserApplicantActivitiesByApplicantID(
    $applicantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserApplicantActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userApplicantActivitiesByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        actions
        userAgent
        actionedOn
        actionedBy
        applicantID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUserApplicantHealth = /* GraphQL */ `
  query GetUserApplicantHealth($id: ID!) {
    getUserApplicantHealth(id: $id) {
      id
      weight
      height
      smoker
      everSmoked
      cigarettesSmoked
      cigarettesPerDay
      cigarSmoked
      cigarsPerDay
      pipeSmoked
      pipeGramsPerDay
      electronicCigaretteSmoked
      otherNicotineReplacementUsed
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listUserApplicantHealths = /* GraphQL */ `
  query ListUserApplicantHealths(
    $filter: ModelUserApplicantHealthFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserApplicantHealths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUserApplicantHealths = /* GraphQL */ `
  query SyncUserApplicantHealths(
    $filter: ModelUserApplicantHealthFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserApplicantHealths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
