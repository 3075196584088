import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import List from "./emails-manager/List";
import Read from "./emails-manager/Read";
import SlidingDivider from "./SlidingDivider";
import {Auth} from "aws-amplify";
import Write from "./emails-manager/Write";
import updateActioned from "../api-functions/emails/updateActioned";
import {useNavigate, useParams} from "react-router-dom";
import deleteEmail from "../api-functions/emails/deleteEmail";
import {AdminEmails} from "../providers/AdminEmailsProvider";
import {Button} from "antd";
import {AdminDrawer} from "../providers/AdminDrawerProvider";

function EmailsManager(props) {
    const params = useParams()
    const adminEmails = useContext(AdminEmails)
    const adminDrawer = useContext(AdminDrawer)
    function ComposeButton() {
        return (
            <div className="d-row j-center mt-24">
                <Button size="large" onClick={() => {
                    adminDrawer.composeEmail({})
                }}>Compose</Button>
            </div>
        )
    }

    return (
        <SlidingDivider
            sticky={true}
            left={params?.emailId ? <Read key={params?.emailId} emailId={params?.emailId}/> : <ComposeButton/>}
            right={<List loadMore={adminEmails.fetchMoreEmails} hasMore={adminEmails.hasMore} items={adminEmails.items} activeId={params.emailId}/>}/>
    );
}

export default EmailsManager;