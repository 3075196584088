import React, {useMemo, useState} from 'react';
import {Avatar, Badge, Button, Card, Collapse, ConfigProvider, Segmented, Tag} from "antd";
import {cyan, green, lime, orange, red} from "@ant-design/colors";
import {capitaliseWords} from "../../../../../../../assets/scripts/string-functions";
import UploadCollapsedPanel from "../standardised-upload/UploadCollapsedPanel";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {ProgressViewState, UploadStatuses} from "../../../../../../../models";
import AddAccountModal from "./AddAccountModal";
import Problems from "./Problems";
import CustomUploadModal from "../custom-upload/CustomUploadModal";
import {CheckOutlined, EuroCircleFilled, EyeInvisibleOutlined, EyeOutlined, QuestionCircleFilled} from "@ant-design/icons";
import useBackendUsers from "../../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import {LONGDATE} from "../../../../../../../assets/scripts/constants";

export function RowExtra({section}) {
        const mortgage = useMortgage()
        const backendUsers = useBackendUsers()

        if (!section || !section.record) {
                return <Tag className="m-0">No Uploads</Tag>
        }
        let completed = section?.record && (section.record.sectionChecked || section.record.viewState === ProgressViewState.COMPLETE)

        let completedBy = section?.record && section?.record.sectionCheckedBy && section?.record.sectionCheckedBy !== 'null' ? backendUsers.getBySub(section.record.sectionCheckedBy).firstName : null
        if (section?.record.viewState === ProgressViewState.COMPLETE) {
                completedBy = section.record.viewStateBy && section.record.viewStateBy !== 'null' ? backendUsers.getBySub(section.record.viewStateBy).firstName : null
        }
        let completedOn = section?.record && section?.record.sectionChecked ? dayjs(section.record.sectionChecked).format(LONGDATE) : null
        if (section?.record.viewState === ProgressViewState.COMPLETE) {
                completedOn = section?.record && section?.record.viewStateOn ? dayjs(section.record.viewStateOn).format(LONGDATE) : null
        }
        let uploads = section.uploads.length
        let pending = section.uploads.filter(upload => upload.status === UploadStatuses.PENDING).length
        let reviewing = section.uploads.filter(upload => upload.status === UploadStatuses.REVIEWING).length
        let returned = section.uploads.filter(upload => upload.status === UploadStatuses.REJECTED).length
        //let missingFigures = isMissingFigures(section)

        let activeMoreRequested = section.record.alertBody && section.record.alertBody.length ? section.record.alertBody.join(' ') : null
        let salariedAccount = !!section.applicant ? mortgage[`applicant${section.applicant}`].accounts.find(a => !!a.salaried) : false
        let isSalariedBankAccount = salariedAccount && salariedAccount.id === section.index
        let accommodationAccount = !!section.applicant ? mortgage[`applicant${section.applicant}`].accounts.find(a => !!a.accommodationDD) : false
        let isAccommodationBankAccount = accommodationAccount && accommodationAccount.id === section.index
        let unAccepted = section.uploads.filter(upload => upload.status !== UploadStatuses.ACCEPTABLE).length > 0
        let internalNote = section.record.internalNote?.length ? section.record.internalNote.join(' ') : null
        let missingUserAnswers = section.queries.some(it => !it.private && !it.userAnswered)
        let missingLenderAnswers = section.queries.some(it => !it.excludedOn && !it.adminAnswered && (it.private || it.userAnswered))
        let queryColor = missingUserAnswers ? 'orange' : missingLenderAnswers ? 'red' : 'green'
        let sectionVerifyErrors = section.verified.isVerifiable ? section.verified.getErrors().filter(errorString => {
                if (typeof errorString === 'string' && errorString.includes('already in app')) {
                        return !section.record.lastDocumentDateDismissed
                }
                return true
        }) : false
        if (sectionVerifyErrors) {
                sectionVerifyErrors = sectionVerifyErrors[0]
        }
        return (<div className="d-row a-center gap-15">
                {!!internalNote && (<Tag color="purple" className="m-0">NOTE: {internalNote}</Tag>)}
                {!!sectionVerifyErrors && (<Tag color="red" className="m-0">{sectionVerifyErrors}</Tag>)}
                {section.queries.length > 0 && (<Tag color={queryColor} className="m-0">Queries{missingLenderAnswers ? ': Check Response' : ''}</Tag>)}
                {!!reviewing && (<Tag color="red" className="m-0">Needs Review</Tag>)}
                {!uploads && (<Tag color="default" className="m-0">No Uploads</Tag>)}
                {/*{!!uploads && !!missingFigures && (<Tag color="volcano" className="m-0">Missing Figures</Tag>)}*/}
                {!!uploads && !!isSalariedBankAccount && (<Tag color="cyan" className="m-0">Salaried Bank Account</Tag>)}
                {!!uploads && !!isAccommodationBankAccount && (<Tag color="blue" className="m-0">Accommodation Bank Account</Tag>)}
                {!!returned && (<Tag color="red" className="m-0">{returned} Returned</Tag>)}
                {(!completed && !!section.record.alertBody) && (<Tag color="gold" className="m-0"
                                                                     style={{
                                                                             maxWidth: 800,
                                                                             overflow: 'hidden'
                                                                     }}> {activeMoreRequested} </Tag>)}
                {!!pending && (<Tag className="m-0" color={"purple"}>{pending} Pending</Tag>)}
                {(!completed && !!uploads && unAccepted) && (<Tag className="m-0" color={"cyan"}>{uploads} Uploads</Tag>)}
                {!!completed && (<>
                        <Tag className="m-0">Checked by {completedBy} on {completedOn}</Tag>
                        <Avatar size="small" icon={<CheckOutlined/>} style={{backgroundColor: green[4]}}/>
                </>)}
                {section?.record?.viewState === ProgressViewState.HIDDEN && <Tag className="m-0" color="default">Removed <EyeInvisibleOutlined/></Tag>}
        </div>)
}
function V2Uploads({
        groups,
        stage,
        actions = null,
        extra
})
{

        const mortgage = useMortgage()
        const [selectedGroup, setSelectedGroup] = useState(groups[0].name)
        const [viewingHidden, setViewingHidden] = useState(false)
        const betterGroups = groups.map(group => {
                if (!viewingHidden) {
                        if (Array.isArray(group.sections)) {
                                return {
                                        ...group,
                                        sections: group.sections.filter(section => section?.record?.viewState !== ProgressViewState.HIDDEN)
                                }
                        }
                        if (typeof group.sections === 'object') {
                                let newSections = {}
                                Object.keys(group.sections).forEach(key => {
                                        newSections[key] = group.sections[key].filter(section => section?.record?.viewState !== ProgressViewState.HIDDEN)
                                })
                                return {
                                        ...group,
                                        sections: newSections
                                }

                        }

                }
                return group
        })
        let allSections = []
        const getSectionsQueryAlertColor = (section) => {
                if (section?.record?.viewState !== ProgressViewState.HIDDEN){
                        let hasQueries = section.queries
                        let queryColor = false
                        if (hasQueries.length) {
                                let missingUserAnswers = hasQueries.some(it => !it.private && !it.userAnswered)
                                let missingLenderAnswers = hasQueries.some(it => !it.excludedOn && !it.adminAnswered)
                                queryColor = missingUserAnswers ? 'orange' : missingLenderAnswers ? 'red' : 'green'
                                return queryColor
                        }
                }

                return false
        }
        const getQueryAlertColorFromSections = (sections) => {
                let colors = sections.flatMap(section => {
                        if (section?.record?.viewState !== ProgressViewState.HIDDEN) {
                                let color = getSectionsQueryAlertColor(section)
                                if (color) {
                                        return [color]
                                }
                        }
                        return []
                })
                return colors
        }

        betterGroups.forEach((group, i) => {
                let colors = []
                if (Array.isArray(group.sections)) {
                        colors.push(...getQueryAlertColorFromSections(group.sections))
                }
                else {
                        Object.keys(group.sections).forEach(key => {
                                colors.push(...getQueryAlertColorFromSections(group.sections[key]))
                        })
                }
                if (colors.length) {
                        let color
                        if (colors.includes('red')) {
                                color = red[4]
                        }
                        else if (colors.includes('orange')) {
                                color = orange[4]
                        }
                        else if (colors.includes('green')) {
                                color = green[4]
                        }
                        group.label = <Badge size="small" color="red" count={<QuestionCircleFilled style={{color}}/>}>{group.label}</Badge>
                }
        })

        const options = betterGroups.map(item => {
                let mySections = Array.isArray(item.sections) ? item.sections : Object.values(item.sections).reduce((acc, curr) => acc.concat(curr), []);
                allSections = [
                        ...allSections,
                        ...mySections
                ]
                let allChecked = mySections.every(a => !!a.record && !!a.record.sectionChecked)
                let anyValid = mySections.some(s => {
                        return s.uploads.length > 0 && s.uploads.some(u => [UploadStatuses.ACCEPTABLE].includes(u.status))
                })
                let pendingUploads = mySections.some(s => {
                        return s.uploads.length > 0 && s.uploads.some(u => [
                                UploadStatuses.PENDING,
                                UploadStatuses.REVIEWING
                        ].includes(u.status))
                })
                let hasVerificationErrors = mySections.some(s => {
                        if (s?.verified.isVerifiable) {
                                let errors = s.verified.getErrors().filter(errorString => {
                                        if (typeof errorString === 'string' && errorString.includes('already in app')) {
                                                return !s.record.lastDocumentDateDismissed
                                        }
                                        return true
                                })
                                return errors.length > 0
                        }
                        return false
                })
                let betterLabel = item.label
                if (hasVerificationErrors) {
                        betterLabel = <div>
                                <Badge key={`data-ission${item.name}`} size="small" count={<EuroCircleFilled style={{color: red[4]}}/>}> &nbsp;</Badge>

                                {item.label}

                        </div>
                }

                let avProps = {}
                if (anyValid) {
                        avProps = {
                                style: {
                                        backgroundColor: cyan[3],
                                        border         : `1px solid ${cyan[5]}`
                                }
                        }
                }
                if (pendingUploads) {
                        avProps = {
                                style: {
                                        backgroundColor: orange[3],
                                        border         : `1px solid ${orange[5]}`
                                }
                        }
                }
                else if (allChecked) {
                        avProps = {
                                style: {
                                        backgroundColor: green[3],
                                        border         : `1px solid ${green[5]}`
                                }
                        }
                }
                return {
                        label   : (<div style={{padding: 4}}>
                                <Avatar {...avProps}>
                                        {item.icon}
                                </Avatar>
                                <div>{betterLabel}</div>
                        </div>),
                        value   : item.name,
                        disabled: item.disabled
                }
        })

        let sorter = (a, b) => {
                if (!!a.record && !!a.record.sectionChecked && !!b.record && !!b.record.sectionChecked) {
                        return 0;
                }
                else if (!!a.record && !!a.record.sectionChecked) {
                        return 1;
                }
                else {
                        return -1;
                }
        }
        let mySelectedGroup = betterGroups.length > 1 ? selectedGroup : betterGroups[0].name

        let filtered = betterGroups.find(item => item.name === mySelectedGroup).sections

        if (Array.isArray(filtered)) {
                filtered.sort(sorter)
        }
        else {

                let newItems = []
                let keys = Object.keys(filtered)
                keys.forEach(key => {
                        newItems.push({
                                key   : key + 'header',
                                label : key,
                                header: true,
                        })

                        newItems.push(...filtered[key].sort(sorter))
                })

                filtered = newItems
        }

        const items = filtered.map(o => {
                if (o?.data?.accountNumber) {
                        return {
                                ...o,
                                title: o.title + ` - ##${o.data.accountNumber.replace(/ /g, '').slice(-6)}`
                        }
                }
                if (o?.data?.iban) {
                        return {
                                ...o,
                                title: o.title + ` - ##${o.data.iban.replace(/ /g, '').slice(-6)}`
                        }
                }
                if (o?.record?.metaData) {
                        let d = JSON.parse(o.record.metaData)
                        if (d.hasOwnProperty('iban')) {
                                return {
                                        ...o,
                                        title: o.title + ` - ##${d.iban.replace(/ /g, '').slice(-6)}`
                                }
                        }
                }
                return o
        }).map(o => {
                if (!!o.header) {
                        return {
                                key      : o.key,
                                label    : <span style={{
                                        color     : cyan[7],
                                        fontWeight: 'bold'
                                }}>{o.label}</span>,
                                disabled : true,
                                showArrow: false,
                                children : false,
                                style    : {
                                        backgroundColor: '#e0eeec'
                                }
                        }
                }
                return {
                        key     : `mk-${o.name}/${o?.index || ''}`,
                        label   : capitaliseWords(o.title),
                        children: <div className="p-6"><UploadCollapsedPanel key={`mk-${o.name}/${o?.index || ''} panel`} sections={allSections} section={o}/></div>,
                        extra   : <RowExtra section={o}/>, // summary: <VerifiedInfo section={o} uploadSummary={true}/>,
                        // applicationForm: <VerifiedInfo section={o} applicationQuestions={true}/>,
                        // messages: <VerifiedInfo section={o} messages={true}/> ,
                        // internalNote: <VerifiedInfo section={o} internalNote={true}/>,
                }
        })

        let promptApp = 0
        if (selectedGroup.endsWith('1')) {
                promptApp = 1
        }
        else if (selectedGroup.endsWith('2')) {
                promptApp = 2
        }
        return (<>
                {betterGroups.length > 1 && (<div className="d-row a-center gap-3">
                        <div className="d-col">
                                {!!mortgage.twoApplicants && betterGroups.length > 1 && (<div className="d-row">
                                        <div style={{
                                                textAlign        : 'center',
                                                width            : 'calc(50% - 34px)',
                                                border           : '1px solid #4faecc',
                                                borderBottomWidth: 0
                                        }}>
                                                Applicant 1
                                        </div>
                                        <div style={{
                                                textAlign        : 'center',
                                                width            : 'calc(50% - 34px)',
                                                border           : '1px solid #4faecc',
                                                borderLeftWidth  : 0,
                                                borderBottomWidth: 0
                                        }}>
                                                Applicant 2
                                        </div>
                                </div>)}
                                <Segmented
                                onChange={setSelectedGroup}
                                options={options}/>
                        </div>
                        <div className="d-col gap-3">
                                <Card style={{
                                        backgroundColor: cyan[3],
                                        border         : `1px solid ${cyan[5]}`
                                }} styles={{
                                        body: {
                                                padding   : 2,
                                                textAlign : 'center',
                                                fontWeight: 'bold',
                                                fontSize  : 10,
                                                color     : 'white'
                                        }
                                }}> Active </Card>
                                <Card style={{
                                        backgroundColor: orange[3],
                                        border         : `1px solid ${orange[5]}`
                                }} styles={{
                                        body: {
                                                padding   : 2,
                                                textAlign : 'center',
                                                fontWeight: 'bold',
                                                fontSize  : 10,
                                                color     : 'white'
                                        }
                                }}> Pending </Card>
                                <Card style={{
                                        backgroundColor: green[3],
                                        border         : `1px solid ${green[5]}`
                                }} styles={{
                                        body: {
                                                padding   : 2,
                                                textAlign : 'center',
                                                fontWeight: 'bold',
                                                fontSize  : 10,
                                                color     : 'white'
                                        }
                                }}> Completed </Card>
                        </div>
                        <div className="ms-auto">
                                <div className="d-row a-center gap-6">
                                        <Button title="Show / Hide Removed Sections" onClick={() => setViewingHidden(s => !s)} size="large" type={viewingHidden ? 'primary' : 'default'}>
                                                {!viewingHidden ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                                        </Button>
                                        <div> {actions} </div>
                                </div>

                        </div>
                </div>)}
                <ConfigProvider theme={{
                        components: {
                                Collapse: {
                                        contentPadding: 0,
                                },
                        },
                }}>
                        <Collapse rootClassName="highlighter" destroyInactivePanel={true} className="radius-0"
                                  accordion
                                  items={items}/>
                </ConfigProvider>
                <div className="d-row gap-15 mt-15">
                        {selectedGroup === 'statements-1' && <AddAccountModal applicant={1} stage={stage}/>}
                        {selectedGroup === 'statements-2' && <AddAccountModal applicant={2} stage={stage}/>}
                        <div className={"d-row j-end w-100"}>
                                <div className="me-auto">
                                        {selectedGroup === 'other' && <CustomUploadModal applicant={false} key={`custom-upload-modal`}/>}
                                </div>

                                {extra}

                        </div>

                </div>
                <Prompt applicant={promptApp}/>
                <Problems/>
        </>)

}

function Prompt({applicant}) {
        let LIs = []
        const mortgage = useMortgage()
        const figures = mortgage.figures.unverified
        if (!applicant) {
                return null
        }

        let salaryAlreadyFound = mortgage[`applicant${applicant}`].accounts.find(it => !!it.salaried)
        if (!salaryAlreadyFound) {
                let salary = figures[`basic${applicant}`].value

                if (Array.isArray(salary)) {
                        salary.forEach(s => {
                                LIs.push(`Is a salary paid into this account (monthly gross salary of about €${(s.value / 12).toFixed(2)})?`)
                        })
                }
                else {
                        LIs.push(`Is a salary paid into this account (monthly gross salary of about €${(salary / 12).toFixed(2)})?`)
                }
                LIs.push('Are there any large deposits besides salary or any other regular deposits?')
        }
        else {
                LIs.push('Are there any large deposits or any other regular deposits paid into the account and if so, summarize with date, description and amount of deposit?')
        }

        let alreadyFoundRent = mortgage[`applicant${applicant}`].accounts.find(it => !!it.accommodationDD)
        let cohabiting = mortgage.twoApplicants && mortgage.requirement.cohabiting

        if (!alreadyFoundRent) {
                if (cohabiting) {
                        LIs.push(`Is rent (or a portion thereof) paid from this account (total monthly rent is €${figures[`accommodationCosts1`].value}?`)
                }
                else {
                        LIs.push(`Is rent paid from this account (monthly rent is €${figures[`accommodationCosts${applicant}`].value})?`)
                }
        }

        return (<div>
                <p>The attached bank statements will be used for a mortgage application. Please review and check for the following:</p>
                <ul>
                        {LIs.map((li, i) => <li key={i}>{li}</li>)}
                        <li>Are any loans, direct debits, credit cards or standing orders paid from this account and if so, summarize with date, description and amount of payment.</li>
                        <li>Are there any debits more than €100 and if so, summarize with date, description and amount of payment.</li>
                        <li>Has the account ever gone into overdraft</li>
                        <li>Are there any unpaid items</li>
                        <li>Is there any evidence of a gambling problem</li>
                        <li>Are there any other transactions that would indicate financial irresponsibility</li>
                        <li>Are the pages in sequence (any missing dates)</li>
                </ul>
        </div>)
}

export default V2Uploads;