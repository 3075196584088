import {SUSSDPORTALNAME} from "../../../../assets/scripts/constants";
import dayjs from "dayjs";
import {ApplicationStatuses, MortgageQualificationStatus} from "../../../../models";
import {setupAffordabilityErrors} from "../../../../assets/scripts/outstanding-items/setupAffordabilityErrors";

export const setupIncompleteEmail = (mortgage) => {
    let {app1Errors,app2Errors, app0Errors} = setupAffordabilityErrors(mortgage)
    let tableStructure = []

    Object.keys(app1Errors).filter((section) =>{
        let array = app1Errors[section]
        return array.length > 0
    }).forEach((section) => {
        let array = app1Errors[section]
        tableStructure.push(['Applicant 1', section, array])
    })
    if (mortgage.twoApplicants){
        Object.keys(app2Errors).filter((section) =>{
            let array = app2Errors[section]
            return array.length > 0
        }).forEach((section) => {
            let array = app2Errors[section]
            tableStructure.push(['Applicant 2', section, array])
        })
    }
    Object.keys(app0Errors).filter((section) =>{
        let array = app0Errors[section]
        return array.length > 0
    }).forEach((section) => {
        let array = app0Errors[section]
        tableStructure.push(['Mortgage Setup', section, array])
    })
    console.log({tableStructure})
    const rows = tableStructure.map(([applicant, section, errors]) => {
        console.log({applicant, section, errors})
        return `<tr>
<td style="padding: 8px; border: 1px solid #ddd; vertical-align: top;">${applicant}</td>
<td style="padding: 8px; border: 1px solid #ddd; vertical-align: top;">${section}</td>
<td style="padding: 8px; border: 1px solid #ddd; vertical-align: top;"><ul style="margin-top: 0; margin-bottom: 0; padding-bottom: 0; padding-top:0">${errors.map(err=>  `<li>${err}</li>`)}</ul></td>
</tr>`
    }).join('')
    console.log({rows})
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Your Initial Assessment with Suss'd is Incomplete`,
        body   : `
<p>Dear ${mortgage.applicant1.firstName}</p>
<p>We've noticed that your financial assessment with Suss'd is still awaiting some details. Completing your financial assessment is a crucial step that allows us to tailor the perfect mortgage plan for you, ensuring it aligns with your financial goals and capabilities. At your convenience, you can complete the remaining sections of the assessment, namely;</p>
<table style="width: 100%; border-collapse: collapse; font-family: Arial, sans-serif;">
<thead>
<tr style="background-color: #f2f2f2;">
<th style="padding: 8px; border: 1px solid #ddd;"></th>
<th style="padding: 8px; border: 1px solid #ddd;" >Section</th>
<th style="padding: 8px; border: 1px solid #ddd;">Outstanding Items</th>
</tr>
</thead>
<tbody>
${rows}
</tbody>
</table>
<p>To continue with your assessment, please click on link below:</p>
<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Complete Your Assessment</a></p>
<p>If you need any assistance or have any questions during this process, our dedicated team is on standby to support you. We're committed to providing a seamless experience.</p>
<p>Thank you for choosing Suss'd. We're excited to help you take this significant step towards your future home.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`
    }
}

export const incompleteReminderEmail = (mortgage) => {
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Gentle Reminder: Complete Your Initial Assessment with Suss'd`,
        onSent : () => {
            console.log('incompleteReminderEmail sent')
        },
        body   : `
<p>Dear ${mortgage.applicant1.firstName}</p>
<p>To follow up on the email we sent a few days ago, your mortgage assessment is not complete.</p>
<p>To enable us to determine your eligibility, the loan amount you qualify for and connect you with the most appropriate banking partner for your situation, you need to complete the remaining sections of the financial assessment.</p>
<p>You can access the assessment through the link below:</p>
<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Complete Your Assessment</a></p>
<p>If you need any assistance or have any questions during this process, our dedicated team is on standby to support you. We're committed to providing a seamless experience.</p>
<p>Thank you for choosing Suss'd. We're excited to help you take this significant step towards your future home.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`
    }
}

export const qualificationEmail = (mortgage) => {
    function rangeRequired(months, withDays = true) {
        const lastDayOfLastMonth = dayjs().subtract(1, 'month').endOf('month');

        // Get the first day of six months before last month
        const sixMonthsBeforeLastMonth = lastDayOfLastMonth.subtract(months - 1, 'month').startOf('month');

        // Format the dates as strings

        let format = withDays ? 'D MMMM YYYY' : 'MMMM YYYY'
        const lastDayStr = lastDayOfLastMonth.format(format);
        const firstDayStr = sixMonthsBeforeLastMonth.format(format);

        return `${firstDayStr} to ${lastDayStr}`;
    }
    function listPreviousYears() {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const twoYearsAgo = currentYear - 2;
        const threeYearsAgo = currentYear - 3;
        return `${threeYearsAgo}, ${twoYearsAgo}, ${lastYear}`;
    }
    const employmentSummaryYears = listPreviousYears()

    if (mortgage.qualification === MortgageQualificationStatus.QUALIFIED) {
        let OtherAnnualIncome1ListItem = mortgage.figures.unverified.variable1.total > 0 ? `<li>Annual Other Income: ${mortgage.figures.unverified.variable1.total?.toEuro()}</li>` : ''
        let OtherAnnualIncome2ListItem = (mortgage.twoApplicants &&  mortgage.figures.unverified.variable2.total > 0) ? `<li>Annual Other Income: ${mortgage.figures.unverified.variable2.total?.toEuro()}</li>` : ''
        return {
            from         : 'help',
            to           : [mortgage.applicant1.email],
            subject      : 'Your Mortgage Evaluation with Suss\'d',
            attachmentIds: ['SALARY_CERT'],
            body         : `
<p>Dear ${mortgage.applicant1.firstName}</p>
<p>We hope this email finds you well.</p>
<p>Thank you for completing the initial financial evaluation. Based on the information you’ve shared and our preliminary assessment, you are eligible for the following mortgage options;</p>
<ul>
    <li>Property value: ${mortgage.properties[0].propertyValue.toEuro()}</li>
    <li>Mortgage Loan Amount: ${mortgage.requirement.loanRequired.toEuro()}</li>
    <li>Term: ${mortgage.requirement.loanTerm} years</li>
</ul>
<p>Your mortgage options are available to review in your ${SUSSDPORTALNAME}. </p>

<p>Your eligibility for this mortgage offer is based on the following information provided in your application: </p>
<ul>
    <li >${mortgage.twoApplicants ? mortgage.applicant1.firstName + `'s` : 'Your'} Financials:
        <ul>
            <li>Annual Income: ${mortgage.figures.unverified.basic1.total?.toEuro()}</li>
             ${OtherAnnualIncome1ListItem}
            <li>Average Monthly Savings: ${mortgage.figures.unverified.savings1.total?.toEuro()}</li>
        </ul>
    </li> 
</ul>
${mortgage.twoApplicants ? `
<ul>
    <li>${mortgage.applicant2.firstName}'s Financials:
        <ul>
            <li>Annual Income: ${mortgage.figures.unverified.basic2.total?.toEuro()}</li>
            ${OtherAnnualIncome2ListItem}
            <li>Average Monthly Savings: ${mortgage.figures.unverified.savings2.total?.toEuro()}</li>
        </ul>
    </li> 
</ul>
` : ``}
    
<p>To proceed to the next stage of the application process, we now need to verify your income and savings rate. In order to do so, we kindly request that you upload the following documents to the <a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">${SUSSDPORTALNAME}</a></p>
<ul>
    <li>Six consecutive months Current Account Statements: covering the period from ${rangeRequired(6)}</li>
    <li>All payslips recieved in past three Months: covering ${rangeRequired(3, false)}</li>
    <li>Employment Detail Summary for ${employmentSummaryYears} (Available in Revenue's ROS system)</li>
</ul>

<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Upload Documents</a></p>
<p>Once we receive these uploads, we will review in full and reconfirm the mortgage options available to you.</p>

<p>While it is not required at this stage, we have attached a Salary Certificate for your employer to complete. As these processes can sometimes take time, we recommend getting started on it sooner rather than later. Please note that this certificate will be necessary before submitting any application to a lender.</p>
<p>Should you have any questions or require assistance with the document upload process, please don't hesitate to reach out to us.</p>
<p>We sincerely appreciate the opportunity to assist you with your mortgage journey and look forward to finalising the next steps with you.</p>
                                        <p>Best regards,<br />The Suss'd Team</p>`,
        }
    }
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Your Mortgage Evaluation with Suss'd`,
        onSent : async () => {
            console.log('qualificationEmail sent')
        },
        body   : `
<p>Dear ${mortgage.applicant1.firstName}</p>
<p>Thanks for completing the initial application.</p>
<p>We regret to inform you that based on the information provided in your mortgage application, we are unable to proceed with your request for a mortgage loan of ${mortgage.requirement.loanRequired.toEuro()} at this time.</p>
<p>Using the amounts from your accommodation costs and regular savings, you demonstrate that you can repay approx. € $$$$$$ monthly. Unfortunately this won't be sufficient to qualify for a mortgage.</p>
<p>If I am picking this up wrong, please let me know.</p> 
<p>To determine the amount of mortgage you can qualify for:</p>
<ul>
    <li>4 × Gross Salary </li>
    <li>Projected repayments are less than your existing accommodation cost + average monthly savings</li>                                              
</ul>                      
<p>We are sorry we cant be of more help at this time. As said, if we have picked up your details incorrectly please do let us know.</p>
<p>Best regards,<br />The Suss'd Team</p>`
    }
}

export const qualificationReminderEmail = (mortgage) => {
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Gentle Reminder: Complete Your Financial Verification with Suss'd`,
        body   : `
<p>Dear ${mortgage.applicant1.firstName}</p>
<p>To follow up on the email we sent a few days ago, your mortgage financial verificaion is not complete.</p>
<p>To enable us to confirm your eligibility, you need to complete the remaining sections of the financial verification by uploading the required documents.</p>
<p>You can access the financial verification through the link below:</p>
<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Complete Your Financial Verification</a></p>
<p>If you need any assistance or have any questions during this process, our dedicated team is on standby to support you. We're committed to providing a seamless experience.</p>
<p>Thank you for choosing Suss'd. We're excited to help you take this significant step towards your future home.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`
    }
}

export const discussionEmail = (mortgage) => {
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Let's Discuss Your Mortgage Options`,
        body   : `<p>Dear ${mortgage.applicant1.firstName}</p>
<p>We hope this email finds you well.</p>
<p>We have verified your figures and would now like to set up and appointment to have an informal chat with your financial advisor about your mortgage options.</p>
<p>... </p>
<p>You can select from one of the available slots on your ${SUSSDPORTALNAME} to easily set up the appoitment</p>
<p>Thanks for choosing Suss'd and we look forward to working with you in the future.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`
    }
}

export const discussionReminderEmail = (mortgage) => {
    return {
        from   : 'help',
        to     : [mortgage.applicant1.email],
        subject: `Reminder: Let's Discuss Your Mortgage Options`,
        body   : `<p>Dear ${mortgage.applicant1.firstName}</p>`
    }
}