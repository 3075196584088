import {monthsBetweenDates} from "./Funding";
import dayjs from "dayjs";
import {AssetTypes} from "../../../../../../models";

export function roundToInt(num) {
    if (isNaN(num)) {
        num = 0
    }
    return Number(parseFloat(num).toFixed(0))
}

export function verifiedInvestments(mortgage, sections) {
    let investmentLike = sections.filter(item => {
        return item.name.startsWith('assets') && item.isInvestment;
    })
    const allInvestmentsSource = investmentLike.map(item => {
        let app = mortgage[`applicant${item.applicant}`]
        let investment = app.assets.find(it => it.id === item.index)
        let currency = investment.currency
        let isEuro = !currency || currency.toLowerCase().trim().startsWith('eur') || currency.trim() === '€'
        let myCurrency = isEuro ? '€' : currency
        let defaultObject = {
            name           : item.name,
            index          : item.index,
            id             : item?.record?.id || `error-${item.name}-${item.index}`,
            key            : item.name + item.index,
            owner          : item.applicant === 1 ? mortgage.applicant1.firstName : item.applicant === 2 ? mortgage.applicant2.firstName : '?',
            applicant      : item.applicant,
            account        : item.title,
            queries        : item.queries,
            closingBalance : 0,
            currency       : myCurrency,
            openingBalance : '',
            totalDifference: '',
        }
        const makeError = (message) => {
            return {
                ...defaultObject,
                error: `${message}`
            }
        }
        if (!item.record) {
            return makeError('No record')
        }

        let uploadRows = item.verified.getActiveRows()
        if (!uploadRows.length) {
            return makeError('No active uploads')
        }
        let last = uploadRows[uploadRows.length - 1]
        if ((!last.closingBalance && last.closingBalance !== 0) || isNaN(last.closingBalance)) {
            return makeError('No end balance')
        }

        return {
            ...defaultObject,
            closingBalance: roundToInt(last.closingBalance),
            currency      : myCurrency
        }

    })
    const investmentByCurrency = allInvestmentsSource.reduce((acc, item) => {
        if (!acc[item.currency]) {
            acc[item.currency] = {
                dataSource: []
            }
        }
        acc[item.currency].dataSource.push(item)
        return acc
    }, {})
    Object.keys(investmentByCurrency).forEach(key => {
        investmentByCurrency[key].totalClosingBalance = roundToInt(investmentByCurrency[key].dataSource.reduce((acc, item) => acc + (item.closingBalance || 0), 0))
    })
    return investmentByCurrency
}

export function verifiedAccounts(mortgage, sections) {
    let accountLike = sections.filter(item => {
        if (item.name.startsWith('current-accounts')) {
            return true
        }
        return item.name.startsWith('assets') && !item.isInvestment;
    })

    const allAccountsSource = accountLike.map(item => {
        let app = mortgage[`applicant${item.applicant}`]
        let data = JSON.parse(item?.record?.metaData || '{}')
        let account = item.data
        let currency = account.currency
        let isEuro = !currency || currency.toLowerCase().trim().startsWith('eur') || currency.trim() === '€'
        let myCurrency = isEuro ? '€' : currency
        let accommodationDD = false
        let salaried = false
        if (item.name.startsWith('current-accounts')) {
            if (account.accommodationDD) {
                accommodationDD = true
            }
            if (account.salaried) {
                salaried = true
            }
        }
        let defaultObject = {
            name           : item.name,
            index          : item.index,
            id             : item?.record?.id || `error-${item.name}-${item.index}`,
            key            : item.name + item.index,
            owner          : item.applicant === 1 ? mortgage.applicant1.firstName : item.applicant === 2 ? mortgage.applicant2.firstName : '?',
            applicant      : item.applicant,
            account        : item.title,
            queries        : item.queries,
            isNew          : item?.data?.isNewAccount,
            isClosed       : item?.data?.isClosed,
            openingBalance : 0,
            closingBalance : 0,
            totalDifference: 0,
            averageMonthly : 0,
            totalToInvestments: 0,
            currency       : myCurrency,
            accommodationDD,
            salaried
        }
        const makeError = (message) => {
            return {
                ...defaultObject,
                error: `${message}`
            }
        }
        if (!item.record) {
            return makeError('No record')
        }
        if (!item.verified) {
            return makeError('No verified data')
        }
        let uploadRows = item.verified.getActiveRows()
        if (!uploadRows.length) {
            return makeError('No active uploads')
        }
        let first = uploadRows[0]
        let last = uploadRows[uploadRows.length - 1]

        if (!first.startDate || !dayjs.isDayjs(first.startDate)) {
            return makeError('No start date')
        }
        if (!last.endDate || !dayjs.isDayjs(last.endDate)) {
            return makeError('No end date')
        }
        if (!first.openingBalance && first.openingBalance !== 0) {
            return makeError('No start balance')
        }
        if (!last.closingBalance && last.closingBalance !== 0) {
            return makeError('No end balance')
        }
        // DATE RANGE
        const startDate = first.startDate
        const endDate = last.endDate
        const diffInMonths = monthsBetweenDates(startDate, endDate)
        const rangeString = `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`

        // BALANCES
        // set the read-only endVBalance - this is the value that will be used for money available
        let endBalance = last.closingBalance

        // set the adjusted end balance - this is the value that will be used for calculations of monthly savings
        let adjustedEndBalance = last.closingBalance

        // if there is a balanceAdjustment saved in the metaData, adjust the adjustedEndBalance in the direction specified
        if (!!data.balanceAdjustment) {
            if (data.balanceAdjustment.adjust === 'up') {
                adjustedEndBalance += data.balanceAdjustment.amount
            }
            if (data.balanceAdjustment.adjust === 'down') {
                adjustedEndBalance -= data.balanceAdjustment.amount
            }
        }
        // if there are transfers into investment accounts, adjust the adjustedEndBalance up, to undo these transfers out
        let transfersToInvestmentsFromThisAccount = 0
        let toInvestments = []
        uploadRows.forEach(row => {
            if (row.totalToInvestments){
                transfersToInvestmentsFromThisAccount += row.totalToInvestments
                toInvestments.push(row.toInvestments)
            }
        })
        if (transfersToInvestmentsFromThisAccount> 0){
            adjustedEndBalance += transfersToInvestmentsFromThisAccount
        }

        const diffInEuro = endBalance - first.openingBalance
        const diffInEuroAdjusted = adjustedEndBalance - first.openingBalance
        const excluded = item.record.excludeFromCalculations

        // AVERAGE MONTHLY
        let averageMonthly = excluded || diffInMonths === 0 ? 0 : Math.floor(diffInEuroAdjusted / diffInMonths)
        return {
            ...defaultObject,
            range          : rangeString,
            startDate,
            startDateRow   : first.startDateRow,
            endDate,
            endDateRow     : last.endDateRow,
            diffInMonths,
            openingBalance : roundToInt(excluded ? 0 : first.openingBalance),
            closingBalance : roundToInt(excluded ? 0 : endBalance),
            totalDifference: roundToInt(excluded ? 0 : diffInEuro),
            averageMonthly : roundToInt(averageMonthly),
            excluded,
            adjustment     : !!data.balanceAdjustment ? data.balanceAdjustment : null,
            toInvestments
        }
    })
    const accountByCurrency = allAccountsSource.reduce((acc, item) => {
        if (!acc[item.currency]) {
            acc[item.currency] = {
                dataSource: []
            }
        }
        acc[item.currency].dataSource.push(item)
        return acc
    }, {})
    Object.keys(accountByCurrency).forEach(key => {
        accountByCurrency[key].totalOpeningBalance = roundToInt(accountByCurrency[key].dataSource.reduce((acc, item) => acc + (item.openingBalance) || 0, 0))
        accountByCurrency[key].totalClosingBalance = roundToInt(accountByCurrency[key].dataSource.reduce((acc, item) => acc + (item.closingBalance || 0), 0))
        accountByCurrency[key].totalTotalDifference = roundToInt(accountByCurrency[key].dataSource.reduce((acc, item) => acc + (item.totalDifference || 0), 0))
        accountByCurrency[key].averageMonthlySavings = roundToInt(accountByCurrency[key].dataSource.reduce((acc, item) => acc + (item.averageMonthly || 0), 0))
    })
    return accountByCurrency
}