import React, {useContext, useMemo, useState} from 'react';
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";
import {MyAdminTasks} from "../../../../providers/MyAdminTasksProvider";
import {AutoComplete, Col, Form, Input, Modal, Row, Select, Space, Typography} from "antd";
import {AimOutlined, EditOutlined, FolderOutlined, LoadingOutlined} from "@ant-design/icons";
import {gray} from "@ant-design/colors";
import ButtonAsync from "../../../elements/ButtonAsync";
import ItemToDo from "../../../my-admin-tasks/sheet/task-item/task-item-modal/ItemToDo";
import ItemControls from "../../../my-admin-tasks/sheet/task-item/task-item-modal/ItemControls";
import ItemMessaging from "../../../my-admin-tasks/sheet/task-item/task-item-modal/ItemMessaging";

function ItemModal({task, open, onCancel, children}) {
    const backendUsers = useBackendUsers()
    const {operations} = useContext(MyAdminTasks);
    const [folderForm] = Form.useForm()
    const [state, setState] = useState({
        currentOwnerBusy: false,
        titleEditing: !task.body || task.body.length === 0,
        titleBusy: false
    })
    const handlePinToggle = async (e) => {
        await operations.update({
            id: task.id,
            pinned: !task.pinned,
            _version: task._version
        })
    }
    const handleFolderClick = async () => {
        const folder = folderForm.getFieldValue('folder')
        if (!folder) return
        const existing = {...task.userFolder}
        existing[backendUsers.me.sub] = folder
        await operations.update({
            id: task.id,
            folder,
            userFolder: JSON.stringify(existing),
            _version: task._version
        })
    }
    const handleCurrentOwnerChange = async (value) => {

        setState(s => ({...s, currentOwnerBusy: true}))
        let existingPosition = task.userSortPosition || {}
        existingPosition[value] = 1
        await operations.update({
            id: task.id,
            currentOwner: value,
            userSortPosition: JSON.stringify(existingPosition),
            _version: task._version
        })
        setState(s => ({...s, currentOwnerBusy: false}))
    }
    const handleTargetChange = async (value) => {

        setState(s => ({...s, currentOwnerBusy: true}))
        await operations.update({
            id: task.id,
            target: value,
            currentOwner: value,
            userSortPosition: JSON.stringify({[value]:0.95}),
            _version: task._version
        })
        setState(s => ({...s, currentOwnerBusy: false}))
    }
    const handleEditTitle = () => {
        setState(s => ({...s, titleEditing: true}))
    }
    const handleSaveTitle = async (e) => {

        setState(s => ({...s, titleBusy: true}))
        await operations.update({
            id: task.id,
            body: [e.target.value],
            _version: task._version
        })
        setState(s => ({...s, titleBusy: false, titleEditing: false}))
    }

    let value = task.body || [].join(' ')



    return (
        <Modal footer={false} open={open} width={850} closable onCancel={onCancel} onClick={(e)=>e.stopPropagation()}>
                <div className="me-24 mb-15">
                    {state.titleEditing ? (
                        <Input
                            placeholder="Title for your task..."
                            size="large"
                            rootClassName="grow"
                            defaultValue={value}
                            onPressEnter={handleSaveTitle}
                            onBlur={handleSaveTitle}
                            suffix={state.titleBusy ? <LoadingOutlined/> : null}
                        />
                    ) : (
                        <div className="d-row gap-9 a-center">

                            {
                                !state.titleBusy && <EditOutlined className="mt-3" onClick={handleEditTitle}/>
                            }
                            <Typography.Title className="m-0" level={4}
                                              style={{color: gray[3]}}>{value}</Typography.Title>
                        </div>
                    )}
                </div>
                <div className="d-row j-end gap-15 a-center">
                    <div className="d-col me-auto">
                        {children}
                    </div>
                </div>
                {!!task.target && (
                    <Form.Item label="Has the ball" className="mt-15 mb-6" initialValue={task.currentOwner}>
                        <Select onSelect={handleCurrentOwnerChange}
                                suffixIcon={state.currentOwnerBusy ? <LoadingOutlined/> : <AimOutlined/>}
                                style={{width: 120}}
                                options={backendUsers.users.map(u => {
                                    return {label: u.firstName, value: u.sub}
                                })}/>
                    </Form.Item>
                )}
                {!task.target && (
                    <Form.Item label="For" className="mt-15 mb-6">
                        <Select onSelect={handleTargetChange}
                                suffixIcon={state.currentOwnerBusy ? <LoadingOutlined/> : <AimOutlined/>}
                                style={{width: 120}}
                                options={backendUsers.users.map(u => {
                                    return {label: u.firstName, value: u.sub}
                                })}
                        />
                    </Form.Item>
                )}

            <Row className="mt-15 pt-15" gutter={24} style={{borderTop: '1px solid #eee'}}>
                <Col xs={24} md={9} style={{borderRight: '1px solid #e7e7e7'}}>
                    <div className="d-col h-100">
                        {/*<ItemToDo taskID={task.id}/>*/}
                        <div className="mt-auto pt-15">
                            <ItemControls task={task} onCancel={onCancel}/>
                        </div>
                    </div>


                </Col>
                <Col xs={24} md={15}>
                    <ItemMessaging taskID={task.id}/>
                </Col>
            </Row>

        </Modal>
    );
}

export default ItemModal;