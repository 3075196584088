import React, {useRef} from 'react';
import {App} from "antd";
import useS3 from "../useS3";
import useMortgage from "./useMortgage";
import {fileNameAndExtension, sanitizeFileName} from "../../../assets/scripts/string-functions";
import {TemplateTypes, UploadStatuses} from "../../../models";
import {API} from "aws-amplify";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import templates from "../../../assets/scripts/overlay-maps";

export const getUserDocumentKey = ({fileName, mortgageID, folder, submissionID= false}) => {
    if (submissionID){
        return `files/mortgage/${mortgageID}/documents/submission:${submissionID}/${folder}/${fileName}`
    }
    return `files/mortgage/${mortgageID}/documents/${folder}/${fileName}`
}
function useMortgageDocumentsService(props) {
    const missingInfo = useRef([])


    const app = App.useApp()
    const s3 = useS3()
    const mortgage = useMortgage()
    /*

        * Mortgage Document Definition
             title: String
    description: String
    identityID: String
    folder: String
    fileName: String
    fileExtension: String
    isDynamic: Boolean
    mortgageID: ID! @index(name: "byUserMortgage")
    readAccessGroup: [String]
    editAccessGroup: [String]


targetKey: `private/${mortgage.applicant1.identityID}/files/mortgage/${mortgage.id}/documents/lender-docs/${lender}/${title}.pdf`
     */


    const createDocumentRow = async ({stage, title, folder, fullFilename, isDynamic, isPrivate, submissionID}) => {

        // fileName includes '.pdf'
        const {fileName, fileExtension} = fileNameAndExtension(fullFilename)
        // ADD UPLOAD DETAILS TO UserMortgageDocuments
        let identityID = mortgage.applicant1.identityID
        if (!identityID) {
            app.notification.error({message: `You can't access the customers uploads folder. Have them sign into the app`})
            return false
        }
        let identityID2 = (mortgage.twoApplicants && !!mortgage.applicant2.identityID) ? mortgage.applicant2.identityID : null

        const readAccessGroup = isPrivate?[] : [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
        const editAccessGroup = []

        const pairs = {
            stage,
            fileName: fullFilename,
            name: fileName,
            folder,
            identityID,
            identityID2,
            title,
            private: isPrivate,
            isDynamic,
            mortgageID: mortgage.id,
            readAccessGroup,
            editAccessGroup
        }
        if (!!submissionID) {
            pairs.submissionID = submissionID
        }
        let newDocResult = await mortgage.mutate({
            create: {
                target: `UserMortgageDocuments`,
                pairs
            }
        })
        //console.log('CREATED DOCUMENT ')
        let newDocRow = newDocResult[0]
        return newDocRow
    }
    const copyFileToUserDocuments = async (sourcePath, stage, title, folder, fullFilename, isPrivate) => {
        const docObject = {
            stage,
            label: title,
            defaultFolder: folder,
            fileName: fullFilename,
            defaultPrivate: isPrivate,
            bucketKey: sourcePath,
            templateType: TemplateTypes.COPIED
        }
        try{
            await addToUserDocuments(docObject, null)
            return true
        }
        catch (e) {
            return false
        }
    }

    const addToUserDocuments = async (lenderDoc, config) => {

        //ADD FILE TO S3

        //console.log('File uploaded successfully:', result);
        //return result.key; // Return the key or the S3 URL of the uploaded file
        //console.log({newUploadRow})

            switch (lenderDoc.templateType) {

                case TemplateTypes.COPIED :
                    const promises = []
                    const newUploadRow = await createDocumentRow({
                        stage: lenderDoc.stage,
                        title: lenderDoc.label,
                        folder: lenderDoc.defaultFolder,
                        fullFilename: lenderDoc.fileName,
                        isDynamic: lenderDoc.templateType !== TemplateTypes.COPIED,
                        isPrivate: lenderDoc.defaultPrivate
                    })

                    const destinationKey = getUserDocumentKey(newUploadRow)
                    //console.log({destinationKey})
                    if (!!newUploadRow.identityID) {
                        promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                            body: {
                                source: `private/${SYSTEMIDENTITYID}/${lenderDoc.bucketKey}`,
                                destination: `private/${newUploadRow.identityID}/${destinationKey}`
                            }
                        }))
                    }
                    if (!!newUploadRow.identityID2) {
                        promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                            body: {
                                source: `private/${SYSTEMIDENTITYID}/${lenderDoc.bucketKey}`,
                                destination: `private/${newUploadRow.identityID2}/${destinationKey}`
                            }
                        }))
                    }
                    const result = await Promise.all(promises)
                    //console.log(result)
                    break
                case TemplateTypes.OVERLAID:
                    const dataMap = getMappings(app, lenderDoc, mortgage, config)
                    console.log({dataMap})
                    // get missing items
                    // console.log({dataMap})
                    // let missingItems = dataMap.sections.filter(it=>!!it.ask).flatMap(section => {
                    //     section.ask.forEach(item => {
                    //         missingInfo.current.push(item)
                    //     })
                    // })


                    const sectionsMap = dataMap.sections.reduce((acc, section) => {
                        return { ...acc, ...section.map };
                    }, {});
                    const errorsMap = dataMap.sections.reduce((acc, section) => {
                        return [ ...acc, ...section.errors || []];
                    }, []);


                    if (errorsMap.length){
                        errorsMap.forEach(error => {
                            app.notification.error({message: error})
                            return false
                        })
                    }
                    else{
                       // return
                        // *****************************************************
                        const newOverlayRow = await createDocumentRow({
                            stage: lenderDoc.stage,
                            title: lenderDoc.label,
                            folder: lenderDoc.defaultFolder,
                            fullFilename: lenderDoc.fileName,
                            isDynamic: lenderDoc.templateType !== TemplateTypes.COPIED,
                            isPrivate: lenderDoc.defaultPrivate
                        })
                        const identityIds = []
                        if (!!newOverlayRow.identityID) {
                            identityIds.push(newOverlayRow.identityID)
                        }
                        if (!!newOverlayRow.identityID2) {
                            identityIds.push(newOverlayRow.identityID2)
                        }
                        const targetKeys = identityIds.map(id => `private/${id}/files/mortgage/${mortgage.id}/documents/${newOverlayRow.folder}/${newOverlayRow.fileName}`)

                        if (targetKeys.length) {
                            const result = await API.post("sussdAdminAPI", "/operations/pdfs/overlay", {
                                body: {
                                    id: lenderDoc.id,
                                    elements: sectionsMap,
                                    targetKeys
                                }
                            })
                            if (!result.success) {
                                await mortgage.mutate({
                                    delete: [{
                                        target: `UserMortgageDocuments`,
                                        id: newOverlayRow.id
                                    }]
                                })
                                app.notification.error({message: `Failed to create documents`})
                                throw new Error('Failed to create documents')
                            }
                        }
                        return true
                    }

                default:
            }
    }
    const getUserDocumentPathAndConfig = (document, downloadDontOpen) => {
        //console.log(document)
        let config = {
            level: 'private',
            download: downloadDontOpen || false,
            contentType: 'application/pdf'
        }
        let path
        // modern uploads have a fileExtension & identityId attributes, and are stored in private folder
        if (document.identityID) {
            config.identityId = document?.identityID
            path = getUserDocumentKey(document)
        }
        // legacy : User uploads, no file name in s3, in protected folder, must supply content type
        console.log({path,config})
        return {path, config}

    }
    const deleteUserDocument = async (id) => {
        let upload = mortgage.UserMortgageDocuments.find(upload => upload.id === id)
        let oldPathAndConfig = getUserDocumentPathAndConfig(upload)
        try {
            let result1 = await s3.remove(oldPathAndConfig.path, {level: oldPathAndConfig.config.level})
            let result = await mortgage.mutate({
                delete: [{
                    target: `UserMortgageDocuments`,
                    id: id
                }]
            })
            return result
        } catch (e) {
            console.log(e)
        }

    }
    const openDocument = async (document) => {

        const {path, config} = getUserDocumentPathAndConfig(document)
        //console.log({upload: document, path, config})
        const url = await s3.getS3Url({path, config})
        //console.log(url)
        s3.openDocument(url)
    }

    const putFile = async ({file, config, stage, isPrivate, folder, isDynamic, title   }) => {
        if (!mortgage.applicant1.identityID) {
            app.notification.error({message: `You can't access the customers uploads folder. Have them sign into the app`})
        }
        const identityIds = [mortgage.applicant1.identityID]
        if (!isPrivate && mortgage.twoApplicants && !!mortgage.applicant2.identityID) {
            identityIds.push( mortgage.applicant2.identityID )
        }
        const promises = identityIds.map(identityID => {
            const expandedConfig = {
                progressCallback: config.progressCallback,
                completeCallback: (event) => {
                    config.completeCallback(event)
                    createDocumentRow({stage, title, folder, fullFilename: file.name, isDynamic, isPrivate})

                },
                errorCallback: config.errorCallback,
                contentType: file.type,
                level: "private",
                identityId: identityID,
                resumable: true,
            }
            let path = getUserDocumentKey({fileName: file.name, mortgageID: mortgage.id, folder})

            return s3.put(path, file,expandedConfig)
        })
        await Promise.all(promises)
    }

    const createBundle = async (config, document) => {

        let result = {success: false}
        try {
            result = await API.post("sussdAdminAPI", "/operations/uploads/bundle", {
                body: config
            })
        } catch (e) {
            app.notification.error({message: e.message})
        }
        if (result.success) {
            //create the Mortgage Upload
            const readAccessGroup = [`${mortgage.id}-app1`, `${mortgage.id}-app2`]
            const editAccessGroup = []

            const {fileName, fileExtension} = fileNameAndExtension(document.fileName)

            const pairs = {
                title: document.title,
                name: fileName,
                identityID: document.identityID,
                folder: document.folder,
                fileName: document.fileName,
                stage: document.stage,
                private: true,
                mortgageID: mortgage.id,
                readAccessGroup,
                editAccessGroup
            }
            if ( document.submissionID) {
                pairs.submissionID = document.submissionID
            }
            let newDocResult = await mortgage.mutate({
                create: {
                    target: `UserMortgageDocuments`,
                    pairs
                }
            })

        } else {
            app.notification.error({message: `Failed to bundle files`})
        }
    }

    const copyAttachmentToUserDocs = async ({folder, fullFilename, title, isPrivate, stage, bucketKey, submissionID}) => {
        const promises = []
        const newUploadRow = await createDocumentRow({
            stage,
            title,
            folder,
            fullFilename,
            isDynamic: false,
            isPrivate,
            submissionID
        })
        const destinationKey = getUserDocumentKey(newUploadRow)
        if (!!newUploadRow.identityID) {
            promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                body: {
                    source: `private/${SYSTEMIDENTITYID}/${bucketKey}`,
                    destination: `private/${newUploadRow.identityID}/${destinationKey}`
                }
            }))
        }
        if (!!newUploadRow.identityID2) {
            promises.push(API.post("sussdAdminAPI", "/operations/s3/copyFile", {
                body: {
                    source: `private/${SYSTEMIDENTITYID}/${bucketKey}`,
                    destination: `private/${newUploadRow.identityID2}/${destinationKey}`
                }
            }))
        }
        const result = await Promise.all(promises)
    }

    const copyToSubmissionFolder = async (document) => {
        const destinationKey = getUserDocumentKey(document)
        const sourceKey = getUserDocumentKey({...document, submissionID: false})
        console.log({sourceKey, destinationKey, document})
        // await API.post("sussdAdminAPI", "/operations/s3/copyFile", {
        //     body: {
        //         source: `private/${document.identityID}/${sourceKey}`,
        //         destination: `private/${document.identityID}/${destinationKey}`
        //     }
        // })
    }

    return {
       // add: addToUserDocuments,
        get: getUserDocumentPathAndConfig,
        delete: deleteUserDocument,
        open: openDocument,
        createBundle: createBundle,
        copy: copyFileToUserDocuments,
        put: putFile,
        copyAttachmentToUserDocs,
        copyToSubmissionFolder
    };
}

// OVERLAY PDF TEMPLATES WITH REAL DATA
export function getMappings(app,lenderDoc, mortgage, config) {
    function withoutDotPdf(str) {
        if (!str) return str
        const index = str.lastIndexOf('.pdf');
        if (index === -1) {
            return str; // '.pdf' not found in the string
        }
        return str.slice(0, index) + str.slice(index + 4);
    }
    const name = lenderDoc?.name || withoutDotPdf(lenderDoc.fileName)
    let  func
    if (templates.sussd.hasOwnProperty(name)) {
        func = templates.sussd[name]
    }
    else {
        func = templates[mortgage.activeSubmission.lenderCode][name]
    }
    if (!func) {
        return {error: `No mapping for ${mortgage.activeSubmission.lenderCode} ${name} - have you added to \\assets\\scripts\\overlay-maps\\index.js`}
    }
    try {
        if (!config) {
            config = {}
        }
        const dataFromMortgage = func(mortgage, config)

        return dataFromMortgage
    } catch (e) {
        console.log(e)
        app.notification.error({message: e.message})
        return {error: e.message}
    }
}
export default useMortgageDocumentsService;