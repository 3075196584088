import React from 'react';
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";
export function institution(props) {
    return {
        text: "What is the account number related to this debt",
        label: "Institution",
        name: "institution",
        answer: {
            type: "text",
            optional: true,
        },
        model: "accounts",
        rules: [
            {required: true, message: `An institution is required`},
        ],
        target: `applicant${props.applicant}.accounts[${props.index}].institution`
    }
}
export function joint(props){
    return {
        text: "Is this a joint account?",
        label: "Joint Account",
        name: "joint",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ]
        },
        model: "accounts",
        target: `applicant${props.applicant}.accounts[${props.index}].joint`
    }
}
export function accountTitle(account) {
    if (!account.institution){
        return 'New Current Account'
    }
    return capitaliseFirst(account.institution) + ' Current Account'
}

export const applicantAccountSteps = ({mortgage, applicant, index}) => {
    const betterProps = {
        mortgage,
        applicant,
        index,
        you: mortgage[`applicant${applicant}`].strings
    }
    const account = mortgage[`applicant${applicant}`].accounts.find(account => account.id === index)
    return [{
        label: 'Current Account',
        title: accountTitle(account),
        index,
        questions: [
            institution(betterProps),
            joint(betterProps),
        ]
    }]
}
export const applicantAccounts = ({mortgage, applicant}) => {
    return mortgage[`applicant${applicant}`].accounts.filter(account => !account._deleted).map(account => ({
        to: account.id,
        index: account.id,
        title: accountTitle(account),
        footer: ``,
        delete: {delete: `applicant${applicant}.accounts`, id: account.id},
    }))
}

function ApplicantAccounts(props) {
    const steps = applicantAccountSteps(props)

    const questions = steps.flatMap(step=>{
        return step.questions
    })

    const repeater = `current-accounts-${props.applicant}`
    return <SectionForm title="Applicant Current Account" repeater={repeater} key={`ApplicantAccounts${props.index}`} {...props} questions={questions}/>

}

export default ApplicantAccounts;