import React, {forwardRef, useState} from 'react';
import {Badge, Card, Tag, Typography} from "antd";
import dayjs from "dayjs";
import ItemModal from "./task-item-modal/ItemModal";
import {Link, useNavigate} from "react-router-dom";
import { gold, gray} from "@ant-design/colors";
import {AimOutlined, ArrowRightOutlined, StarFilled} from "@ant-design/icons";
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";
import ItemOrigins from "./task-item-modal/ItemOrigins";

export const Item = forwardRef(({task, folders, sorting, completed, noMargin, ...props}, ref) => {
    const backendUser = useBackendUsers()

    const navigate = useNavigate()
    let date = dayjs(task.createdAt).format('D MMM, H:mm a')
    const [state, setState] = useState({
        open: task.isActive,

    })

    const paused = !!task.pauses.find(pause => !pause.completedOn)

    const handleClose = () => {
        setState({open: false})
        if (task.isActive) {
            navigate('/my-tasks')
        }
    }

    function BasicContent(props) {
        return (
            <>
                <div className="d-row gap-6 f-wrap a-center">
                    <ItemOrigins task={task} />
                    {
                        !!task.completedOn && <Tag color="green">Completed</Tag>
                    }
                    {
                        !!paused && <Tag color="orange">Paused</Tag>
                    }
                    {
                        !paused && !task.completedOn && !!task.targetStarted && <Tag color="geekblue">Working ...</Tag>
                    }

                    <small className="d-block">{date}</small>
                    <span className="ms-auto">
                        {
                            props.hasOwnProperty('withHasTheBall') && <HasTheBallCard/>
                        }
                    </span>
                </div>

                {
                    !!task.mortgage && (

                        <div style={{paddingTop: 3}}>
                            <Link style={{color: gray[1]}}
                                  to={`/client/${task.mortgage.id}`}><small>{task.mortgage.title}</small></Link>
                        </div>
                    )
                }


            </>
        )
    }
    let string = task.body && task.body.length ? task.body.join(' ') : 'New Task ...'

    function HasTheBallCard(){
        if (task.completedOn){
            return null
        }
        const user = backendUser.getBySub(task.currentOwner)
        let color = user ? user.color : 'default'
        let name = user ? user.firstName : 'No ball owner'
        return (
            <Tag color={color} className="d-row a-center gap-6" style={{backgroundColor:'transparent'}}> <AimOutlined/> {name} </Tag>
        )
    }

    function FolderBadge(){
        if(task.userFolder.hasOwnProperty(backendUser.me.sub)){
            return (
                <Badge color="gray" count={task.userFolder[backendUser.me.sub]}/>
            )
        }
        if (task.folder){
            return (
                <Badge color="gray" count={task.folder}/>
            )
        }
        return null
    }

    return (
        <div {...props} ref={ref}>
            <Card
                hoverable
                styles={{body:{userSelect: 'none', padding: 9,}}}
                 className="my-3"
                style={{
                    border: task.pauses.find(it=>!it.completedOn) ? '1px solid gold' : task.targetStarted && !task.completedOn ? '1px solid #adc6ff': '',
                    marginLeft: noMargin ? 0:`${task.left}%`,
                    cursor: completed || !sorting ? 'default' : 'move'
                }}
                onClick={() => setState({open: true})}>
                {/*<div style={{float: 'right'}}>{task.userSortPosition?.[backendUser.me.sub]}</div>*/}

                <BasicContent withHasTheBall/>


                <Typography.Text ellipsis>{string}</Typography.Text>
                {
                    !!task.lastMessage && (
                        <div className="d-row gap-12">
                            {
                                task.lastMessageBy !== backendUser.me.sub && (
                                    <StarFilled style={{color: gold[3]}}/>
                                )
                            }


                            <span className="d-row w-100">“<Typography.Text style={{color: gray.primary}} ellipsis>{task.lastMessage}</Typography.Text>”</span>
                        </div>

                    )
                }

                <div>
                    <FolderBadge/>
                </div>



            </Card>
            <ItemModal folders={folders} task={task} open={state.open} onCancel={handleClose}>
                <BasicContent/>
            </ItemModal>

        </div>
    )
});