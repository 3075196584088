import React, {useContext} from 'react';
import TodoCard from "./admin-todo/TodoCard";
import LastViewed from "./last-viewed-mortgage/LastViewed";
import AttentionMortgages from "./attention-mortgages/AttentionMortgages";
import OnlineNow from "./online-now/OnlineNow";
import UserActivity from "./recent-user-activity/UserActivity";
import ActiveSubmissions from "./active-submissions/ActiveSubmissions";
import {Mortgages} from "../../../providers/MortgagesProvider";
import ScheduledAppointments from "./upcoming-calls/ScheduledAppointments";
import Tasks from "./tasks/Tasks";

function Landing(props) {
    return (
        <div className="p-15 d-col gap-15" style={{height: 'calc(100vh - 30px)'}}>
            <div className="d-row grow j-equal gap-15" style={{height: 'calc(67% - 7.5px)'}}>
                <TodoCard/>
                <Tasks/>
                <div className="d-col gap-15 h-100 a-stretch">
                    <LastViewed  maxHeight='calc(50% - 7.5px)'/>
                    <OnlineNow/>

                </div>

            </div>
            <div className="d-row gap-15 j-equal" style={{height: 'calc(33% - 7.5px)'}}>
                <UserActivity/>
                {/*<ActiveSubmissions/>*/}
                <AttentionMortgages/>
                <ScheduledAppointments/>

            </div>
        </div>

    );
}

export default Landing;