import React, {useEffect} from 'react';
import {listAllAndSetItems} from "../api-functions/functions";
import {userMortgageAppointmentsByAppointmentDate, userMortgageAppointmentsByExpired} from "../../graphql/queries";
import useSubscription from "./useSubscription";
import {
    onCreateUserMortgageAppointment,
    onDeleteUserMortgageAppointment,
    onUpdateUserMortgageAppointment
} from "../../graphql/subscriptions";

export const UpcomingAppointments = React.createContext({});
function UpcomingAppointmentProvider(props) {
    const [appointments, setAppointments] = React.useState([])
    const addAppointments = (appointments) => {
        //add / update activities
        setAppointments(s=>{
            if (!s.length){
                return appointments
            }
            const newAppointments = [...s]
            appointments.forEach(appointment=>{
                const index = newAppointments.findIndex(a=>a.id===appointment.id)
                if (index!==-1){
                    newAppointments[index] = appointment
                }
                else{
                    newAppointments.push(appointment)
                }
            })
            return newAppointments
        })
    }
    const load = async () => {
        await listAllAndSetItems({
            expired:0,
            filter:{
                _deleted:{ne:true}
            }
        }, userMortgageAppointmentsByExpired, addAppointments)
    }
    useEffect(() => {
        load()
    }, [])
    useSubscription({
        subscription: {
            name: 'UserMortgageAppointment',
            onUpdate: onUpdateUserMortgageAppointment,
            onCreate: onCreateUserMortgageAppointment,
            onDelete: onDeleteUserMortgageAppointment,
        },
        setState: setAppointments
    })

    return (
        <UpcomingAppointments.Provider value={appointments}>
            {props.children}
        </UpcomingAppointments.Provider>
    );
}

export default UpcomingAppointmentProvider;