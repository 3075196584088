import React, {useContext, useEffect, useRef, useState} from 'react';
import EmailsList from "./EmailsList";
import {getApplicantEmails} from "../../../api-functions/emails/getApplicantEmails";
import useMortgage from "../../../providers/mortgage/useMortgage"
import SlidingDivider from "../../SlidingDivider";
import EmailReader from "./EmailReader";
import {Auth} from "aws-amplify";
import updateActioned from "../../../api-functions/emails/updateActioned";
import {AdminEmails} from "../../../providers/AdminEmailsProvider";

function MortgageEmails(props) {
    const mortgage = useMortgage()
    const mortgageLoaded = useRef(false);
    const adminEmails = useContext(AdminEmails)
    const [state,setState] = useState({
        app1:{
            items: 'loading',
            nextToken: null
        },
        app2:{
            items: 'loading',
            nextToken: null
        }
    })

    const markedActioned = async (email) =>{

        let liveEmail = email

        let pairs ={
            actionedOn: null,
            actionedBy: "null"
        }
        if (liveEmail.actionedBy === "null"){
            const user = await Auth.currentAuthenticatedUser()
            pairs = {
                actionedBy: user.attributes.sub,
                actionedOn: new Date().toISOString()
            }
        }
        const update = {id: liveEmail.id, _version: liveEmail._version, ...pairs}
        const result = await updateActioned(update)

    }

    // const loadInitialEmailsForApplicant = async (n)=>{
    //     const app = await getApplicantEmails(mortgage[`applicant${n}`].id)
    //     setState(s=> {
    //         const copy = {...s}
    //         if (!Array.isArray(copy[`app${n}`].items)){
    //             copy[`app${n}`].items = []
    //         }
    //         copy[`app${n}`] = {
    //             items: [...copy[`app${n}`].items, ...app.items],
    //             nextToken: app.nextToken
    //         }
    //         return copy
    //     })
    // }

    const moreEmails = async ()=>{
        let app1 = null
        let app2 = null
        if (state.app1.items === 'loading'){
            app1 = await getApplicantEmails(mortgage.applicant1ID)
        }
        else if (!!state.app1.nextToken) {
            app1 = await getApplicantEmails(mortgage.applicant1ID, state.app1.nextToken)
        }
        if (state.app2.items === 'loading'){
            app2 = await getApplicantEmails(mortgage.applicant2ID)
        }
        else if (!!state.app2.nextToken) {
            app2 = await getApplicantEmails(mortgage.applicant2ID, state.app2.nextToken)
        }

        setState(s=>{
            if (!!app1 && !!app2){

                return {
                    ...s,
                    app1: {
                        items: s.app1.items === 'loading' ? app1.items : [...s.app1.items, ...app1.items],
                        nextToken: app1.nextToken
                    },
                    app2: {
                        items: s.app2.items === 'loading' ? app2.items : [...s.app2.items, ...app2.items],
                        nextToken: app2.nextToken
                    }
                }
            }
            if (!!app1){
                return {
                    ...s,
                    app1: {
                        items: s.app1.items === 'loading' ? app1.items : [...s.app1.items, ...app1.items],
                        nextToken: app1.nextToken
                    }
                }
            }
            if (!!app2){
                return {
                    ...s,
                    app2: {
                        items: s.app2.items === 'loading' ? app2.items : [...s.app2.items, ...app2.items],
                        nextToken: app2.nextToken
                    }
                }
            }
        })
    }

    useEffect(() => {
        if (!!mortgage && !mortgageLoaded.current) {
            mortgageLoaded.current = true;
            moreEmails()
        }
    }, [mortgage]);


    let dataSource = null
    if (state.app1.items !== 'loading' && state.app2.items !== 'loading') {
        dataSource = ['app1','app2'].flatMap(key => {
            return state[key].items.map(it=>{
                let live = adminEmails.items.find(e=>e.id === it.id)
                if (live){
                    return live
                }
                return it
            })
        })
        const liveAppEmails = adminEmails.items.filter(it=>[mortgage.applicant1ID,mortgage.applicant2ID].includes(it.userapplicantID))
        if (liveAppEmails.length){
            liveAppEmails.forEach(email=>{
                const exists = dataSource.find(item => item.id === email.id);
                // If the object does not exist, add it to the array
                if (!exists) {
                    dataSource.push(email);
                }
            })
        }

        const sortFunction = (emails)=> {
            const unreadEmails = emails.filter(email => !email.read)
                .sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate));

            const readEmails = emails.filter(email => email.read)
                .sort((a, b) => new Date(b.sortDate) - new Date(a.sortDate));

            return [...unreadEmails, ...readEmails];
        }
        dataSource = sortFunction(dataSource)
    }

    const getMore = state.app1.nextToken || state.app2.nextToken ? moreEmails : null



    return (
        <SlidingDivider
            sticky={true}
            left={<EmailReader emails={dataSource} markActioned={markedActioned}/>}
            right={<EmailsList emails={dataSource} getMore={getMore}/>}/>
    );
}
export default MortgageEmails;