import {performGQL} from "../functions";
const listAdminBackendUsers = /* GraphQL */ `
    query ListAdminBackendUsers(
        $filter: ModelAdminBackendUsersFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listAdminBackendUsers(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                firstName
                surname
                sub
                color
                email
                phone
                mailAccount
                lastActive
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`;
function getBackendUsers(){
    const variables = {}
    return performGQL(variables, listAdminBackendUsers)
}
export default getBackendUsers