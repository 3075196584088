import React from 'react';

import useMortgage from "../../providers/mortgage/useMortgage";
import ManagementApply from "../../components/client/application-stages/apply/ManagementApply";
import ManagementOnBoarding from "../../components/client/application-stages/on-boarding/ManagementOnBoarding";
import ManagementPreApproval from "../../components/client/application-stages/pre-approval/ManagementPreApproval";
import ManagementApproval from "../../components/client/application-stages/approval/ManagementApproval";
import ManagementSign from "../../components/client/application-stages/sign/ManagementSign";

function DashboardPage(props) {
    const mortgage = useMortgage()
    switch(mortgage.applicationStage){
        case 'APPLY':
            return <ManagementApply/>
        case 'SETUP':
            return <ManagementOnBoarding/>
        case 'SIGN':
            return <ManagementSign/>
        case 'PREAPPROVAL':
            return <ManagementPreApproval/>
        case 'APPROVAL':
            return <ManagementApproval/>
        default:
            return <ManagementOnBoarding/>
    }
}

export default DashboardPage;