import React, {useContext, useEffect, useState} from 'react';
import {Button, Cascader, Form, Input, Select, Space, Switch, TreeSelect} from "antd";
import {CarryOutOutlined, LoadingOutlined, SendOutlined} from "@ant-design/icons";
import {CKEditor} from "@ckeditor/ckeditor5-react"
import Editor from "ckeditor5-custom-build";
import {API} from "aws-amplify";
import {capitaliseWords} from "../../../assets/scripts/string-functions";
import {getUserDocumentKey} from "../../providers/mortgage/useMortgageDocumentsService";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";

const {SHOW_PARENT} = TreeSelect;
const otherEmailSources = {
    SALARY_CERT: {
        title: 'Salary Certificate',
        source: `private/${SYSTEMIDENTITYID}/emailable-files/salary-certificate.pdf`,
        name: 'salary-certificate.pdf'
    }
}


function ComposeEmail({close, config, defaultFromAccount, chatGptChoices}) {
    const [value, setValue] = useState(config.attachmentIds || []); // attachment ids
    const [sent, setSent] = useState(false)
    const [form] = Form.useForm()
    const helpAccount = {label: 'Help', value: 'help', email: 'help@sussd.ie'}
    const emailChangedRef = React.useRef(null)

    const [state, setState] = useState({
        body: '',
        sending: false,
        reply: config.reply,
        fromAccountOptions: []
    })
    const switchToCompose = () => {
        setState(s => ({...s, reply: null}))
        emailChangedRef.current = null
    }
    const getFromAccountOptions = (emailIfReply) => {
        let options = []
        const me = defaultFromAccount
        if (!!emailIfReply) {

            // a reply can only have 1 sender - the recipient of the email
            const allowedAccounts = [helpAccount, {label: me.firstName, value: me.mailAccount, email: me.email}]
            const email = emailIfReply
            for (let recipientAddress of email.to) {
                const found =  allowedAccounts.find(it=>it.email === recipientAddress)
                if (found){
                    options.push(found)
                    break
                }
            }
        } else {
            // a new email can be sent from any account
            options = [{label: me.firstName, value: me.mailAccount, email: me.email},helpAccount]
        }
        return options
    }
    const handleFormFinish = async (e) => {

        const valid = await form.validateFields()
        valid.documentAttachments = value.length ? value : false
        setState((s => ({...s, sending: true})))
        const mailConfig = {
            body: state.body,
            to: valid.to,
            cc: valid.cc || [],
            bcc: valid.bcc || [],
            account: valid.from,
            subject: valid.subject,
            attachments :[]
        }
        if (!!config.mortgage && !!valid.documentAttachments) {
            let possibleHardCodedAttachments = Object.keys(otherEmailSources)
            mailConfig.attachments = valid.documentAttachments.map(id=>{
                if (possibleHardCodedAttachments.includes(id) ) {
                    return {
                        source: otherEmailSources[id].source,
                        name: otherEmailSources[id].name
                    }
                }
                else{
                    const doc = config.mortgage.UserMortgageDocuments.find(it=>it.id === id)
                    if (doc){
                        const path = getUserDocumentKey(doc)
                        return {
                            source: `private/${doc.identityID}/${path}`,
                            name: doc.fileName
                        }
                    }
                }

            })
        }

        if (state.reply){
            mailConfig.message_id = state.reply.messageId
        }
        if (window.location.protocol === 'http:') {
            await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, 2000)
            })
        }
        else{
            const mailResult = await API.post("sussdAdminAPI", "/exchange/send/none", {
                body: mailConfig
            })
        }



        // await the sending of the email

        setState((s => ({...s, sending: false})))
        setSent(true)
        close()
    }


    const setBody = (str) => {
        setState(s => ({...s, body: str}))
    }

    function AttachmentTree() {
        const uniqueFolders = [...new Set(config.mortgage.UserMortgageDocuments.map(item => item.folder))];
        const folderToTitle = (name) => {
            return capitaliseWords(name.split('-').join(' '))
        }
        const treeData = uniqueFolders.map(folder => ({
            value: folder,
            title: folderToTitle(folder),
            icon: <CarryOutOutlined/>,
            disableCheckbox: true,
            children: config.mortgage.UserMortgageDocuments.filter(item => item.folder === folder).map(item => ({
                value: item.id,
                title: item.title,
             //   fileName: item.fileName,
                icon: <CarryOutOutlined/>,
                isLeaf: true
            }))
        }))
        treeData.push({
            value: 'templates',
            title: 'Templates',
            icon: <CarryOutOutlined/>,
            disableCheckbox: true,
            children: Object.keys(otherEmailSources).map(key=>({
                value: key,
                title: otherEmailSources[key].title,
         //       source: otherEmailSources.salaryCert,
                icon: <CarryOutOutlined/>,
                isLeaf: true
            }))
        })
        const onChange = (newValue, oldValue) => {

            setValue(newValue);
        };

        return (

            <TreeSelect
                treeCheckable={true}
                treeLine={true}
                style={{width: '100%',}}
                treeData={treeData}
                treeIcon={false}
                value={value}
                onChange={onChange}
            />

        );
    }
    useEffect(() => {
        if (!!sent){
            if (config.onSent) {
                config.onSent()
            }
            setSent(false)
        }
    }, [sent]);

    useEffect(() => {
        let testerString = JSON.stringify({to: config.to, subject: config.subject, reply: config.reply, chatGptChoices})
        if (emailChangedRef.current !== testerString) {

            emailChangedRef.current = testerString
            form.setFieldValue('to', config.to)
            form.setFieldValue('subject', config.subject)
            form.setFieldValue('cc', config.cc)
            let body = config?.body ? config.body.replace(/\n/g, ''): ''
            if (chatGptChoices){
                let firstChatGptChoice = chatGptChoices? chatGptChoices[0].message.content : []
                // split by new line and wrap items in <p>
                body = chatGptChoices.map(it=>`<p>${it.message}</p>`).join('')
            }

            setState(s => ({
                ...s,
                reply: config.reply,
                body

            }))
            setSent(false)
        }
    }, [config.to, config.subject, config.reply]);

    const fromOptions = getFromAccountOptions(state.reply)
    if (fromOptions.length === 0) {
        return (
            <p>Only the recipient of this email can reply</p>
        )
    }
    if (!config.from){
        config.from = fromOptions[0].value
    }
    const disabled = state.reply ? {disabled:true} : {}

    return (
        <div className="d-col gap-12">
            <Form initialValues={config}
                  form={form} onFinish={handleFormFinish} className="d-col gap-12">
                <div className="d-row gap-12">
                    <Form.Item

                        className="grow mb-0"
                        name="to"
                        label="To">
                        <Select {...disabled} mode="tags" className="grow" placeholder="Email addresses..."/>

                    </Form.Item>
                    <Button htmlType="submit">
                        {state.sending === false && <>Send <SendOutlined/></>}
                        {state.sending === true && <>Sending <LoadingOutlined/></>}
                    </Button>
                </div>
                <div className="d-row gap-12">
                    <Form.Item
                        className="grow mb-0"
                        name="cc"
                        label="Cc:">
                        <Select {...disabled} mode="tags" className="grow" placeholder="Email addresses..."
                                />

                    </Form.Item>
                </div>
                <div className="d-row gap-12">
                    <Form.Item
                        className="grow mb-0"
                        name="bcc"
                        label="Bcc:">
                        <Select {...disabled} mode="tags" className="grow" placeholder="Email addresses..."
                                />

                    </Form.Item>
                    <Form.Item
                        className="mb-0"
                        name="from"
                        label="From">
                        <Select
                            options={fromOptions}
                        />

                    </Form.Item>
                </div>
                <Form.Item
                    className="mb-0"
                    name="subject"
                    label="Subject">
                    <Input placeholder="Subject ..."/>
                </Form.Item>
                {!!config.mortgage && (
                    <>
                        {!!state.reply && (
                            <div className="d-row gap-9">
                                Attachments not available in reply mode
                                <Button size={"small"} onClick={switchToCompose}>Change to Compose</Button>

                            </div>
                        )}
                        {!state.reply && (
                            <div className="d-row a-center gap-12">
                                <Form.Item
                                    className="mb-0 w-100"
                                    name="documentAttachments"
                                    label="Attachments">
                                    <AttachmentTree/>
                                </Form.Item>
                            </div>
                        )}
                    </>
                )}

            </Form>

            <CKEditor
                editor={Editor}
                data={state.body}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.


                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    setBody(data)

                }}
                onBlur={(event, editor) => {

                    //liveBody = editor.getData();
                }}
                onFocus={(event, editor) => {

                }}
            />
        </div>
    );
}

export default ComposeEmail;