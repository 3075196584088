import React from 'react';
import SectionForm from "../SectionForm";
export function circumstanceChanges(props) {
    return {
        label: "Circumstance Changes",
        text: "Are you aware of any known future changes to your circumstances which may affect your ability to repay the proposed mortgage?",
        name: "circumstanceChanges",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.circumstanceChanges`
    }
}
export function circumstanceChangesDetails(props) {
    return {
        label: "Circumstance Changes Details",
        text: "Supply some details about this",
        name: "circumstanceChangesDetails",
        answer: {
            type: "textarea"
        },
        hides: (form)=>{
            return form.getFieldValue('circumstanceChanges') !== true
        },
        model: "needs",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `needs.circumstanceChangesDetails`
    }
}
export function healthIssues(props) {
    return {
        label: "Health Issues",
        text: "Are you aware of any health issues that may affect your ability to work and meet the repayments of this facility?",
        name: "healthIssues",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.healthIssues`
    }
}
export function healthIssuesDetails(props) {
    return {
        label: "Health Issues Details",
        text: "Supply some details about this",
        name: "healthIssuesDetails",
        answer: {
            type: "textarea"
        },
        hides: (form) =>{
            return form.getFieldValue('healthIssues') !== true
        },
        model: "needs",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `needs.healthIssuesDetails`
    }
}
export function policeRecord(props) {
    return {
        name: "policeRecord",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Police Record",
        text: "Have you received a formal police caution in the last 5 years, ever been convicted or have any prosecutions pending",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.policeRecord`
    }
}
export function policeRecordDetails(props) {
    return {
        label: "Police Record Details",
        text: "Supply some details about this police record",
        name: "policeRecordDetails",
        answer: {
            type: "textarea"
        },
        hides: (form) => {
            return form.getFieldValue('policeRecord') !== true
        },
        model: "needs",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `needs.policeRecordDetails`
    }
}
export function incomeChange(props) {
    return {
        name: "incomeChange",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Incomes Change",
        text: "Might your income change in the foreseeable future?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.incomeChange`
    }
}
export function expenditureChange(props) {
    return {
        name: "expenditureChange",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Expenditure Change",
        text: "Might your expenditure change significantly in the foreseeable future?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.expenditureChange`
    }
}
export function moveLikely(props) {
    return {
        name: "moveLikely",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Move Likely",
        text: "Are you likely to move property within the mortgage term?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.moveLikely`
    }
}
export function plannedPayOff(props) {
    return {
        name: "plannedPayOff",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Planned Payoff",
        text: "Do you have any plans to pay off some or all of the mortgage in the foreseeable future?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.plannedPayOff`
    }
}
export function beyondRetirement(props) {
    return {
        name: "beyondRetirement",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Term Exceeds Retirement",
        text: "Will the term of the mortgage extend beyond your anticipated retirement age?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.beyondRetirement`
    }
}
export function speedEssential(props) {
    return {
        name: "speedEssential",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Speed Essential",
        text: "Is speed essential to the mortgage completion?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.speedEssential`
    }
}
export function consolidatingDebt(props) {
    return {
        name: "consolidatingDebt",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Consolidating Debt",
        text: "Are you consolidating any additional debts as part of this mortgage?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.consolidatingDebt`
    }
}
export function furtherRequirements(props) {
    return {
        name: "furtherRequirements",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Further Requirements",
        text: "Are there any further requirement or details that you would like us to record?",
        model: "needs",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `needs.furtherRequirements`
    }
}
export function furtherRequirementsDetails(props) {
    return {
        label: "Further Requirement Details",
        text: "Supply some details about this",
        name: "furtherRequirementsDetails",
        answer: {
            type: "textarea"
        },
        hides: (form)=>{
            return form.getFieldValue('furtherRequirements') !== true
        },
        model: "needs",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `needs.furtherRequirementsDetails`
    }
}

export const mortgageNeedsSteps=(props)=>{
    return [
        {
            label: 'Mortgage Requirements',
            title: `Mortgage Requirements`,
            questions: [
                circumstanceChanges(props),
                circumstanceChangesDetails(props),
                incomeChange(props),
                expenditureChange(props),
                moveLikely(props),
            ]
        },
        {
            label: 'Any Issues',
            title: `Tell us about any issues`,
            questions: [
                healthIssues(props),
                healthIssuesDetails(props),

                policeRecord(props),
                policeRecordDetails(props),
            ]
        },
        {
            label: 'Mortgage',
            title: `Tell us about the mortgage requirements`,
            questions: [
                plannedPayOff(props),
                beyondRetirement(props),
                speedEssential(props),
                consolidatingDebt(props),
                furtherRequirements(props),
                furtherRequirementsDetails(props),
            ]
        }
    ]
}
function MortgageNeeds(props) {
    const steps = mortgageNeedsSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })

    return <SectionForm   title="Mortgage Needs" key="MortgageNeeds" {...props} questions={questions}/>
}

export default MortgageNeeds;