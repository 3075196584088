import {EmploymentTypes, PersonalMaritalStatuses} from "../../../../models";
import dayjs from "dayjs";
import {getCurrentAccountsDataSource} from "../../mortgage-figures";
import {sectionMetaData} from "../../mortgage-data";

export default function (mortgage, config) {
    let legacyEmployment = !mortgage[`applicant1`].employmentIncome?.length || !mortgage[`applicant1`].employmentIncome[0].employmentType

    const coverLetter = () => {
        let map = {}
        map['broker-cover-letter'] = true
        return {map}
    }
    const signedApplicationForms = () => {
        let map = {}
        map['signed-application-forms'] = true
        return {map}
    }
    const declarationOfIdentityForm = () => {
        let map = {}
        map['declaration-of-identity-form'] = true
        return {map}
    }
    const affordabilityCalculator = () => {
        let map = {}
        map['affordability-calculator'] = true
        return {map}
    }
    const recentBankStatements = () => {
        let map = {}
        let rows= getCurrentAccountsDataSource(mortgage)

        let salary1 = rows.find(r=>!!r.salaried && r.applicant === 1)
        if (salary1){
            let str = salary1.range.split(' - ')
            let start1 = dayjs(str[0], 'DD MMM YYYY')
            let end1 = dayjs(str[1], 'DD MMM YYYY')
            map['app1-bank-statements-date-to'] = start1.format('DD/MM/YYYY')
            map['app1-bank-statements-date-from'] = end1.format('DD/MM/YYYY')
        }
        if (mortgage.twoApplicants){
            let salary2 = rows.find(r=>!!r.salaried && r.applicant === 2)
            if (salary2){
                let str = salary2.range.split(' - ')
                let start2 = dayjs(str[0], 'DD MMM YYYY')
                let end2 = dayjs(str[1], 'DD MMM YYYY')
                map['app2-bank-statements-date-to'] = start2.format('DD/MM/YYYY')
                map['app2-bank-statements-date-from'] = end2.format('DD/MM/YYYY')
            }
        }

        map['recent-bank-statements'] = true
        return {map}
    }
    const evidenceOfDeposit = () => {
        let map = {}
        map['evidence-of-deposit'] = true
        return {map}
    }
    const paye = () => {
        let map = {}
        let ask = []
        let paye, salaryCert1, salaryCert2, payslips1, payslips2
        if (legacyEmployment){
            paye = mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED || mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED
            if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED){
                salaryCert1 = sectionMetaData(mortgage, 'salary-cert-1')
                payslips1 = sectionMetaData(mortgage, 'payslips-1')
            }
            if (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED){
                salaryCert2 = sectionMetaData(mortgage, 'salary-cert-2')
                payslips2 = sectionMetaData(mortgage, 'payslips-2')
            }
        }
        else{
            paye = mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED)
            if (mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED)){
                salaryCert1 = sectionMetaData(mortgage, 'salary-cert-1',mortgage.applicant1.employmentIncome[0].id)
                payslips1 = sectionMetaData(mortgage, 'payslips-1',mortgage.applicant1.employmentIncome[0].id)
            }
            if (mortgage.twoApplicants && mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED)){
                salaryCert2 = sectionMetaData(mortgage, 'salary-cert-2',mortgage.applicant2.employmentIncome[0].id)
                payslips2 = sectionMetaData(mortgage, 'payslips-2',mortgage.applicant2.employmentIncome[0].id)
            }
        }

        if (paye) {
            let signedDate1 = salaryCert1?.signedDate ? dayjs(salaryCert1.signedDate, "YYYY-MM-DD") : null
            if (signedDate1){
                map['app1-salary-certificate-date'] = signedDate1.format('DD/MM/YYYY')
            }
            let dates1 = !!payslips1 && !!payslips1.payAmountsDate ? payslips1.payAmountsDate.filter(i=>!!i) : []

            if (dates1.length){
                let firstDate1 = dayjs(dates1[dates1.length - 1], "YYYY-MM-DD")
                let lastDate1 = dayjs(dates1[0], "YYYY-MM-DD")
                map['app1-payslips-date-from'] = firstDate1.format('DD/MM/YYYY')
                map['app1-payslips-date-to'] = lastDate1.format('DD/MM/YYYY')
            }



            if (mortgage.twoApplicants){
                let signedDate2 = salaryCert2?.signedDate ? dayjs(salaryCert2.signedDate, "YYYY-MM-DD") : null
                if (signedDate2){
                    map['app2-salary-certificate-date'] = signedDate2.format('DD/MM/YYYY')
                }
                let dates2 = !!payslips2 && !!payslips2.payAmountsDate ? payslips2.payAmountsDate.filter(i=>!!i) : []
                if (dates2.length) {
                    let firstDate2 = dayjs(dates2[dates2.length - 1], "YYYY-MM-DD")
                    let lastDate2 = dayjs(dates2[0], "YYYY-MM-DD")
                    map['app2-payslips-date-from'] = firstDate2.format('DD/MM/YYYY')
                    map['app2-payslips-date-to'] = lastDate2.format('DD/MM/YYYY')
                }
            }

            ask.push({
                name: 'payeContract',
                label: 'Fixed Term Contract',
                type: 'CHECK',
                group: 'EMPLOYMENT'
            })

            map['salary-certificate'] = true
            map['paye-eds'] = true
            map['paye-payslips'] = true
            map['paye-contract'] = (config.hasOwnProperty('payeContract') && !!config.payeContract) ? config.payeContract : 'N/A'
        }
        return {map, ask}
    }
    const selfEmployed = () => {
        let map = {}
        let ask = []
        let self = false
        if (legacyEmployment){
            self = mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED || mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED
        }
        else{
            self = mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED) || mortgage.twoApplicants && mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED)
        }
        if (self) {
            ask.push({
                name: 'self-employed-contract',
                label: 'Self Employed Contract (if exclusive to one company)',
                type: 'CHECK',
                group: 'EMPLOYMENT'
            })
            map['self-employed-certified-accounts'] = true
            map['self-employed-business-statements'] = true
            map['self-employed-accountants-letter'] = true
            map['self-employed-contract'] = (config.hasOwnProperty('self-employed-contract') && !!config['self-employed-contract']) ? config['self-employed-contract'] : 'N/A'
        }
        return {map, ask}
    }
    const aml = () => {
        let map = {}
        map["aml-id"] = true
        map["aml-address"] = true
        if (config.hasOwnProperty('amlIdComment') && !!config.amlIdComment) {
            map["aml-id-comment"] = config.amlIdComment
        }
        if (config.hasOwnProperty('amlAddressComment') && !!config.amlAddressComment) {
            map["aml-address-comment"] = config.amlAddressComment
        }
        let ask = []
        ask.push({
            name: 'amlIdComment',
            label: 'Proof of Identity Comment',
            type: 'TEXT',
            group: 'AML'
        })
        ask.push({
            name: 'amlAddressComment',
            label: 'Proof of Address Comment',
            type: 'TEXT',
            group: 'AML'
        })
        return {map, ask}
    }

    const marriageCert = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'marriage-cert',
            label: 'Marriage Certificate',
            type: 'CHECK',
            group: 'OTHER'
        })
        map['marriage-cert'] = (config.hasOwnProperty('marriageCert') && !!config.marriageCert) ? config.marriageCert : false
        map['marriage-cert-comment'] = (config.hasOwnProperty('marriageCertComment') && !!config.marriageCertComment) ? config.marriageCertComment : 'N/A'
        return {map, ask}
    }
    const form11 = ()=>{
        let map = {}
        let ask = []
        ask.push({
            name: 'form11',
            label: 'Form 11 For Rental Income',
            type: 'CHECK',
            group: 'OTHER'
        })
        ask.push({
            name: 'form11Comment',
            label: 'Form 11 Comment',
            type: 'TEXT',
            group: 'OTHER'
        })
        if (config.hasOwnProperty('form11') && !!config.form11){
            map['form11'] = config.form11
        }
        map['form-11-comment'] =  (config.hasOwnProperty('form11Comment') && !!config.form11Comment) ? config.form11Comment : 'N/A'

        return {map,ask}
    }

    const loanStatements = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'loan-statements',
            label: 'Loan Statements',
            type: 'CHECK',
            group: 'OTHER'
        })
        ask.push({
            name: 'loan-statements-comment',
            label: 'Loan Statements Comment',
            type: 'TEXT',
            group: 'OTHER'
        })
        map['loan-statements'] = (config.hasOwnProperty('loanStatements') && !!config.loanStatements) ? config.loanStatements : false
        map['loan-statements-comment'] = (config.hasOwnProperty('loanStatementsComment') && !!config.loanStatementsComment) ? config.loanStatementsComment : 'N/A'
        return {map, ask}
    }

    const equityRelease = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'equity-release',
            label: 'Equity Release',
            type: 'CHECK',
            group: 'OTHER'
        })
        ask.push({
            name: 'equity-release-comment',
            label: 'Equity Release Comment',
            type: 'TEXT',
            group: 'OTHER'
        })
        map['equity-release'] = (config.hasOwnProperty('equityRelease') && !!config.equityRelease) ? config.equityRelease : false
        map['equity-release-comment'] = (config.hasOwnProperty('equityReleaseComment') && !!config.equityReleaseComment) ? config.equityReleaseComment : 'N/A'
        return {map, ask}
    }
    const divorceOrSeparation = () => {
        let map = {}
        let ask = []
        let val = mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.DIVORCED ||
            (mortgage.twoApplicants && mortgage.applicant2.personal.maritalStatus === PersonalMaritalStatuses.DIVORCED) ||
            mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED ||
            (mortgage.twoApplicants && mortgage.applicant2.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED)
        if (val) {
            ask.push({
                name: 'divorceOrSeprationComment',
                label: 'Divorce or Separation Comment',
                type: 'TEXT',
                group: 'OTHER'
            })
            map['divorce-or-sepration'] = true
            map['divorce-or-sepration-comment'] = (config.hasOwnProperty('divorceOrSeprationComment') && !!config.divorceOrSeprationComment) ? config.divorceOrSeprationComment : 'N/A'
        }
        else{
            map['divorce-or-sepration-comment']='N/A'
        }
        return {map, ask}
    }
    const brokerDetails = ()=>{
        let map = {}
        let ask = []
        ask.push({
            name: 'signDate',
            label: 'Signature Date',
            type: 'TEXT',
            group: 'OTHER'
        })
        map['broker-name'] = 'Kevin Coakley'
        map['broker-firm'] = 'Truss Mortgages Ltd'
        map['broker-firm-2'] = 'T/A Suss\'d Mortgages'
        map['date-string'] = (config.hasOwnProperty('dateString') && !!config.dateString) ? config.dateString : dayjs().format('D MMMM YYYY')
        return {map, ask}
    }
    const validationChecks = () => {
        let map = {}
        map['valid-address'] = true
        map['valid-address-format'] = true
        map['valid-application-form'] = true
        map['valid-contact-number'] = true
        map['valid-id-in-date'] = true
        map['valid-loan-amounts'] = true
        map['valid-name-spelling'] = true
        map['valid-nationality'] = true
        map['valid-pps'] = true
        if (config.hasOwnProperty('addressComment')){
            map['valid-address-comment'] = config.addressComment
        }
        if (config.hasOwnProperty('addressFormatComment')){
            map['valid-address-format-comment'] = config.addressFormatComment
        }
        if (config.hasOwnProperty('applicationFormComment')){
             map['valid-application-form-comment'] = config.applicationFormComment
        }
        if (config.hasOwnProperty('contactNumberComment')){
            map['valid-contact-number-comment'] = config.contactNumberComment
        }
        if (config.hasOwnProperty('idInDateComment')){
            map['valid-id-in-date-comment'] = config.idInDateComment
        }
        if (config.hasOwnProperty('loanAmountsComment')){
            map['valid-loan-amounts-comment'] = config.loanAmountsComment
        }
        if (config.hasOwnProperty('spellingComment')){
            map['valid-name-spelling-comment'] = config.spellingComment
        }
        if (config.hasOwnProperty('nationalityComment')){
            map['valid-nationality-comment'] = config.nationalityComment
        }
        if (config.hasOwnProperty('ppsComment')){
            map['valid-pps-comment'] = config.ppsComment
        }
        let ask = [
            {
                name: 'addressComment',
                label: 'Address Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'addressFormatComment',
                label: 'Address Format Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'applicationFormComment',
                label: 'Application Form Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'contactNumberComment',
                label: 'Contact Number Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'idInDateComment',
                label: 'ID in Date Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'loanAmountsComment',
                label: 'Loan Amounts Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'spellingComment',
                label: 'Name Spelling Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'nationalityComment',
                label: 'Nationality Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            },
            {
                name: 'ppsComment',
                label: 'PPS Comment',
                type: 'TEXT',
                group: 'VALIDATION CHECKS'
            }

        ]
        return {map, ask}
    }
    const sections = [
        coverLetter(),
        signedApplicationForms(),
        declarationOfIdentityForm(),
        affordabilityCalculator(),
        recentBankStatements(),
        evidenceOfDeposit(),
        paye(),
        selfEmployed(),
        aml(),
        marriageCert(),
        form11(),
        loanStatements(),
        equityRelease(),
        divorceOrSeparation(),
        brokerDetails(),
        validationChecks()
    ]
    return {sections}
}