import {dataPoint, get, getInitialValues, getNullCells} from "./index";
import {ApplicationTypes, DebtTypes, EmploymentTypes, MortgageTypes, PersonalMaritalStatuses} from "../../../models";
import {legacyIncome} from "../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";

const url = 'https://script.google.com/macros/s/AKfycbwJ6HXlDtbgKdUX1-OpBFoBlZve4XXvyw03Xv-5UCDIN0FanaCu0KE8q-Oad4UOHFU/exec' // 3.8
const sheetName = 'PDH Indicative Assessment ' // 3.8
const spreadsheetId = '1HBQQTf8oji3OMNKpXf94LYUxJolsY8li7GTPCfcBQb0' // 3.8

const selectOptions = {
    borrowerTypes     : [
        'FTB',
        'Mover',
        'Switcher',
        'Switcher with ER'
    ],
    maritalStatuses   : [
        'Single',
        'Married',
        'Sep/Divorced',
        'Widowed'
    ],
    employmentStatuses: [
        'Employed',
        'Unemployed'
    ],
    employmentTypes   : [
        'PAYE (Normal)',
        'Self Employed',
        'Civil Servant'
    ],
}
const dataToReceive = {
    'LTV'                    : 'C43',
    'Income Multiple'        : 'C44',
    'NDI Surplus / Shortfall': 'C45',
    'Mortgage Payment'       : 'C8',
    'DSR'                    : 'C46',
    'Stressed Payment'       : 'G8',
    'DSR Amount Required'    : 'C47',
    '% Proven DRA'           : 'C48',
    'DRA Shortfall'          : 'C49',
    'Credit Policy Results'  : 'E43:J46',
    'CBoI Limits'            : 'E52:J54',


}
export function boi({mortgage}) {
    const legacy = legacyIncome(mortgage)
    const dataPoints = {
        'Loan'       : [
            dataPoint('Borrower Type', 'C3', {
                type   : 'select',
                options: selectOptions.borrowerTypes
            }, () => {
                switch (mortgage.mortgageType) {
                    case MortgageTypes.REFINANCE:
                        switch (mortgage.applicationType) {
                            case ApplicationTypes.OWNHOME:
                                return 'Switcher'
                            case ApplicationTypes.LANDLORD:
                                return 'Switcher'
                        }
                        break
                    case MortgageTypes.PURCHASE:
                        switch (mortgage.applicationType) {
                            case ApplicationTypes.FIRSTTIME:
                                return 'FTB'
                            case ApplicationTypes.MOVINGHOME:
                                return 'Mover'
                            case ApplicationTypes.SECONDTIME:
                                return 'Mover'
                            case ApplicationTypes.LANDLORD:
                                return 'Mover'
                        }
                        break
                }
            },),
            dataPoint('No. of Borrowers', 'F3', {
                type   : 'select',
                options: [
                    '1',
                    '2'
                ]
            }, () => mortgage.twoApplicants ? '2' : '1'),
            dataPoint('Dependants', 'H3', {
                type   : 'select',
                options: [
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6'
                ]
            }, () => {
                let tot = mortgage.applicant1.personal.countDependents || 0
                if (mortgage.twoApplicants) {
                    tot += mortgage.applicant2.personal.countDependents || 0
                }
                return tot.toString()
            }),
            dataPoint('Rate', 'J3:L4', {
                type   : 'select',
                options: ['3 Year Fixed BER Exempt / No BER yet - 4.25%']
            }, () => '3 Year Fixed BER Exempt / No BER yet - 4.25%'),
            dataPoint('Purchase Price', 'C6', {
                type: 'number'
            }, () => {
                return mortgage.properties[0].propertyValue
            }, (formData) => {
                return formData.propertyValue
            }, (newFormData) => {
                return newFormData.provisionalValue
            }),
            dataPoint('Loan Amount', 'F6', {
                type: 'number'
            }, () => {
                return mortgage.requirement.loanRequired
            }, (formData) => {
                return formData.mortgageAmount
            }, (newFormData) => {
                return newFormData.provisionalLoan
            }),
            dataPoint('Term', 'H6', {
                type: 'number',
            }, () => {
                return mortgage.requirement.loanTerm
            }, (formData) => {
                return formData.term
            }, (newFormData) => {
                return newFormData.provisionalTerm
            }),
            dataPoint('House Hunter', 'E10', {
                type   : 'select',
                options: [
                    'No',
                    'Yes'
                ]
            }, () => 'Yes'),
        ],
        'Applicant 1': [
            dataPoint('Marital Status', 'C13', {
                type   : 'select',
                options: selectOptions.maritalStatuses
            }, () => {
                switch (mortgage.applicant1.personal.maritalStatus) {
                    case PersonalMaritalStatuses.REMARRIED:
                    case PersonalMaritalStatuses.MARRIED:
                        return 'Married'
                    case PersonalMaritalStatuses.SINGLE:
                        return 'Single'
                    case PersonalMaritalStatuses.DIVORCED:
                    case PersonalMaritalStatuses.SEPARATED:
                        return 'Sep/Divorced'
                    case PersonalMaritalStatuses.WIDOWED:
                        return 'Widowed'
                }
            }),
            dataPoint('Employment Status', 'C14', {
                type   : 'select',
                options: selectOptions.employmentStatuses
            }, () => {
                if (legacy) {
                    return [
                        EmploymentTypes.SELFEMPLOYED,
                        EmploymentTypes.EMPLOYED
                    ].includes(mortgage.applicant1.employment.employmentType) ? 'Employed' : 'Unemployed'
                }
                return !!mortgage.applicant1.employmentIncome.find(it => [
                    EmploymentTypes.SELFEMPLOYED,
                    EmploymentTypes.EMPLOYED
                ].includes(it.employmentType)) ? 'Employed' : 'Unemployed'
            }),
            dataPoint('Employment Type', 'C15', {
                type   : 'select',
                options: selectOptions.employmentTypes
            }, () => {
                switch (mortgage.figures.unverified.sector1.value) {
                    case 'public':
                        return 'Civil Servant'
                    case 'private':
                        return 'PAYE (Normal)'
                    case 'self':
                        return 'Self Employed'
                }
            }, (formData) => {
                switch (formData.sector1) {
                    case 'public':
                        return 'Civil Servant'
                    case 'private':
                        return 'PAYE (Normal)'
                    case 'self':
                        return 'Self Employed'
                }
            }, (newFormData) => {
                switch (newFormData.sector1) {
                    case 'public':
                        return 'Civil Servant'
                    case 'private':
                        return 'PAYE (Normal)'
                    case 'self':
                        return 'Self Employed'
                }
            }),
            dataPoint('Basic', 'C17', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.basic1.total
            }, (formData) => {
                return formData.basic1
            }, (newFormData) => {
                return newFormData.basic1
            }),
            dataPoint('Car/Shift Allowance', 'C18', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.allowances1.total + mortgage.figures.unverified.other1.total
            }, (formData) => {
                return formData.allowances1 || 0 + formData.other1 || 0
            }, (newFormData) => {
                return newFormData.allowances1 || 0 + newFormData.other1 || 0
            }),
            dataPoint('Overtime (Guaranteed)', 'C19', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.overtime1.total
            }, (formData) => {
                return formData.overtime1
            }, (newFormData) => {
                return newFormData.overtime1
            }),
            dataPoint('Overtime (Regular)', 'C20', {
                type: 'number'
            }, () => {
                return 0
            }, (formData) => {
                return 0
            }, (newFormData) => {
                return 0
            }),
            dataPoint('Commission (Guaranteed & Regular)', 'C21', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.commission1.total
            }, (formData) => {
                return formData.commission1
            }, (newFormData) => {
                return newFormData.commission1
            }),
            dataPoint('Bonus (Guaranteed)', 'C22', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.year1Bonus1.total
            }, (formData) => {
                return formData.year1Bonus1
            }, (newFormData) => {
                return newFormData.year1Bonus1
            }),
            dataPoint('Bonus (Regular)', 'C23', {
                type: 'number'
            }, () => {
                return 0
            }, (formData) => {
                return 0
            }, (newFormData) => {
                return 0
            }),
            dataPoint('Financial Commitments', 'C24', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.loans1.total
            }, (formData) => {
                // get sum of all loans that are not being cleared
                let nonClearingLoans = mortgage.applicant1.debts.filter(it => it.debtType===DebtTypes.LOAN && !formData.clearingLoans1.includes(it.id))
                return nonClearingLoans.reduce((a, b) => a + b.monthlyPayment, 0)
            }, (newFormData) => {
                return newFormData.loans1
            }),
            dataPoint('Maintenance Costs', 'C25', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.maintenancePaid1.total
            }),
            dataPoint('Childcare Costs', 'C26', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.childmindingCosts1.total
            }),
        ],
        'Applicant 2': [
            dataPoint('Marital Status', 'F13', {
                type   : 'select',
                options: selectOptions.maritalStatuses
            }, () => {
                switch (mortgage.applicant2.personal.maritalStatus) {
                    case PersonalMaritalStatuses.REMARRIED:
                    case PersonalMaritalStatuses.MARRIED:
                        return 'Married'
                    case PersonalMaritalStatuses.SINGLE:
                        return 'Single'
                    case PersonalMaritalStatuses.DIVORCED:
                    case PersonalMaritalStatuses.SEPARATED:
                        return 'Sep/Divorced'
                    case PersonalMaritalStatuses.WIDOWED:
                        return 'Widowed'
                }
            }),
            dataPoint('Employment Status', 'F14', {
                type   : 'select',
                options: selectOptions.employmentStatuses
            }, () => {
                if (legacy) {
                    return [
                        EmploymentTypes.SELFEMPLOYED,
                        EmploymentTypes.EMPLOYED
                    ].includes(mortgage.applicant2.employment.employmentType) ? 'Employed' : 'Unemployed'

                }
                return !!mortgage.applicant2.employmentIncome.find(it => [
                    EmploymentTypes.SELFEMPLOYED,
                    EmploymentTypes.EMPLOYED
                ].includes(it.employmentType)) ? 'Employed' : 'Unemployed'

            }),
            dataPoint('Employment Type', 'F15', {
                type   : 'select',
                options: selectOptions.employmentTypes
            }, () => {
                if (mortgage.twoApplicants){
                    switch (mortgage.figures.unverified.sector2.value) {
                        case 'public':
                            return 'Civil Servant'
                        case 'private':
                            return 'PAYE (Normal)'
                        case 'self':
                            return 'Self Employed'
                    }
                }
                return ''
            }, (formData) => {
                switch (formData.sector2) {
                    case 'public':
                        return 'Civil Servant'
                    case 'private':
                        return 'PAYE (Normal)'
                    case 'self':
                        return 'Self Employed'
                }
            }, (newFormData) => {
                switch (newFormData.sector2) {
                    case 'public':
                        return 'Civil Servant'
                    case 'private':
                        return 'PAYE (Normal)'
                    case 'self':
                        return 'Self Employed'
                }
            }),
            dataPoint('Basic', 'F17', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.basic2?.total || 0
            }, (formData) => {
                return formData.basic2 || 0
            }, (newFormData) => {
                return newFormData.basic2 || 0
            }),
            dataPoint('Car/Shift Allowance', 'F18', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.allowances2?.total || 0 + mortgage.figures.unverified.other2?.total ||0
            }, (formData) => {
                return formData.allowances2 || 0 + formData.other2 || 0
            }, (newFormData) => {
                return newFormData.allowances2 || 0 + newFormData.other2 || 0
            }),
            dataPoint('Overtime (Guaranteed)', 'F19', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.overtime2?.total ||0
            }, (formData) => {
                return formData.overtime2 ||0
            }, (newFormData) => {
                return newFormData.overtime2 ||0
            }),
            dataPoint('Overtime (Regular)', 'F20', {
                type: 'number'
            }, () => {
                return 0
            }, (formData) => {
                return 0
            }, (newFormData) => {
                return 0
            }),
            dataPoint('Commission (Guaranteed & Regular)', 'F21', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.commission2?.total ||0
            }, (formData) => {
                return formData.commission2 ||0
            }, (newFormData) => {
                return newFormData.commission2||0
            }),
            dataPoint('Bonus (Guaranteed)', 'F22', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.year1Bonus2?.total ||0
            }, (formData) => {
                return formData.year1Bonus2||0
            }, (newFormData) => {
                return newFormData.year1Bonus2||0
            }),
            dataPoint('Bonus (Regular)', 'F23', {
                type: 'number'
            }, () => {
                return 0
            }, (formData) => {
                return 0
            }, (newFormData) => {
                return 0
            }),
            dataPoint('Financial Commitments', 'F24', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.loans2?.total ||0
            }, (formData) => {
                // get sum of all loans that are not being cleared
                let nonClearingLoans = mortgage.applicant2.debts.filter(it => it.debtType === DebtTypes.LOAN && !formData.clearingLoans2.includes(it.id))
                return nonClearingLoans.reduce((a, b) => a + b.monthlyPayment, 0)
            }, (newFormData) => {
                return newFormData.loans2 ||0
            }),
            dataPoint('Maintenance Costs', 'F25', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.maintenancePaid2?.total ||0
            }),
            dataPoint('Childcare Costs', 'F26', {
                type: 'number'
            }, () => {
                return mortgage.figures.unverified.childmindingCosts2?.total ||0
            }),
        ],
        'Proven DRA' : [
            dataPoint('Proven DRA Amount', 'C29', {
                type: 'number'
            }, () => {
                let tot = 0
                tot += mortgage.figures.unverified.savings1?.total || 0
                tot += mortgage.figures.unverified.clearingLoans1?.total || 0
                tot += mortgage.figures.unverified.otherPRA1?.total || 0
                tot += mortgage.figures.unverified.accommodationCosts1?.total || 0
                if (mortgage.twoApplicants) {
                    tot += mortgage.figures.unverified.savings2?.total || 0
                    tot += mortgage.figures.unverified.clearingLoans2?.total || 0
                    tot += mortgage.figures.unverified.otherPRA2?.total || 0
                    tot += mortgage.figures.unverified.accommodationCosts2?.total || 0
                }
                console.log('Proven DRA Amount', tot)
                return tot
            }, (formData) => {
                let savings = formData.savings1 + formData.savings2
                let clearingLoans1 = formData.clearingLoans1.map(id=>{
                    return mortgage.applicant1.debts.find(it=>it.id === id).monthlyPayment
                })
                let clearingLoans2 = (formData?.clearingLoans2||[]).map(id=>{
                    return mortgage.applicant2.debts.find(it=>it.id === id).monthlyPayment
                })
                let clearingLoans = clearingLoans1.reduce((a,b)=>a+b,0) + clearingLoans2.reduce((a,b)=>a+b,0)
                // let otherPRA = formData.otherPRA1 || 0 + formData.otherPRA2 || 0
                let accommodation = mortgage.figures.unverified.accommodationCosts1.total || 0 + mortgage.figures.unverified?.accommodationCosts2?.total || 0
                console.log('Proven DRA Amount 2', savings + clearingLoans  + accommodation)
                return savings + clearingLoans  + accommodation
            }, (newFormData) => {
                console.log('NEW FORM DATA', newFormData)
                let savings = (newFormData.savings1 ||0) + (newFormData.savings2||0)
                let clearingLoans = (newFormData.clearingLoans1 ||0) + (newFormData.clearingLoans2 ||0)
                let otherPRA = (newFormData.otherPRA1 ||0) + (newFormData.otherPRA2 ||0)
                let accommodation = (newFormData.accommodationCosts1 ||0) + (newFormData.accommodationCosts2 ||0)

                console.log('Proven DRA Amount 3', savings + clearingLoans + otherPRA + accommodation)
                return savings + clearingLoans + otherPRA + accommodation
            }),
        ]
    }
    return {
        name         : 'boi',
        dataPoints,
        get          : (values, shouldSaveOutput) => {
            const blankedData = getNullCells(dataPoints)
            return get(url, spreadsheetId, sheetName, {...blankedData, ...values}, dataToReceive, mortgage,  shouldSaveOutput)
        },
        initialValues: (data) => getInitialValues(dataPoints, data),
    }
}

