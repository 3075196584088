import React, {useContext} from 'react';
import {
    Card, Carousel,
    Checkbox, Col,
    Collapse,
    ConfigProvider, Row, Steps,
    Timeline,
    Tooltip,
    Typography
} from "antd";
import {ClockCircleOutlined} from "@ant-design/icons";

import {LONGDATE} from "../../../../assets/scripts/constants";
import {geekblue, green, orange} from "@ant-design/colors"
import useTimelineStages from "../hooks/useTimelineStages";
import dayjs from "dayjs";

function TimeLine(props) {
    let defaultOpenKey = false
    let blueDotEncountered = false
    let waitingEncountered = false
    function TimeLineItem({stage, inCompleteChecks}) {
        function CollapseSection() {
            return <table>
                <tbody>
                {stage.checks.map(test => {
                    const onClickProp = test?.onClick ? {onClick: test.onClick} : {}
                    return (
                        <tr key={test.label}>
                            <td>
                                <Checkbox {...onClickProp} checked={test.completed}>
                                    {test.completed?.on && (
                                        <Tooltip
                                            title={test.completed.on ? `${test.completed.by} @ ${dayjs(test.completed.on).format( LONGDATE)}` : ''}>
                                            {test.label}
                                        </Tooltip>
                                    )}
                                    {(!test.completed || !test.completed?.on) && <>{test.label}</>}


                                </Checkbox>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        }
        //set the open panel to the first incomplete one
        if (!defaultOpenKey && inCompleteChecks.length > 0 && !stage.labelWait) {
            defaultOpenKey = stage.key
        }
        const label = stage.labelWait ? stage.labelWait : inCompleteChecks.length === 0 ? stage.labelDone : stage.labelTodo
        const items = [
            {
                key: stage.key,
                showArrow: false,
                label: <Typography.Text className="mt-0">{label}</Typography.Text>,
                children: stage.checks.length ? <CollapseSection/> : null
            }
        ];
        return <Collapse size="small" defaultActiveKey={[defaultOpenKey]} ghost items={items}/>
    }

    const timelines = useTimelineStages()

    const headings = [
        'Application',
        'Approved In Principle',
        'Loan Offer'
    ]

    function TimeLineLabel({item}){
        const prop = item.onClick ? {onClick: item.onClick, style: {cursor: 'pointer'}}:{}
        return <span {...prop}>{item.label}</span>
    }

    const groups = Object.keys(timelines).map((key,i)=>{
        const items = timelines[key]
        return items.map(item=>{
            const obj = {
                color: geekblue[4],
               // label: item.label,
                children: <TimeLineLabel item={item}/>
            }
            if( item.done){
                obj.color = green[4]
            }
            if (item.type === 'wait'){
                obj.color = orange[4]
                obj.dot = <ClockCircleOutlined/>
            }
            return obj
        })
    })

    /* SET CURRENT ACTIVE GROUP MARKER */
    let currentActive = null

    groups.forEach((group,i)=>{
        group.forEach(marker=>{
            if (currentActive === null && marker.incomplete > 0){
                currentActive = i
            }
        })
    })

    return (
        <>
            <Carousel dots={"bg-base"} dotPosition="bottom">
                {groups.map((STEPS,i) => {
                    return (

                        <Card style={{height:'100%'}} key={`card-key-${headings[i]}`} title={headings[i]} headStyle={{textAlign: 'center'}}>
                            <Timeline className="dashboard-timeline" items={STEPS}/>
                        </Card>

                    )
                })}

            </Carousel>

            {/*<div className="my-15" style={{margin: '0 calc(16.6666% - 60px)'}}>*/}
            {/*    <Steps*/}
            {/*        labelPlacement="vertical"*/}
            {/*        current={currentActive}*/}
            {/*        items={[{}, {}, {}]}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className="d-row j-equal gap-15">*/}
            {/*    <ConfigProvider*/}
            {/*        theme={{*/}
            {/*            components: {*/}
            {/*                Collapse: {*/}
            {/*                    headerPadding: '0 16px'*/}
            {/*                },*/}
            {/*            },*/}
            {/*        }}*/}
            {/*    >*/}


            {/*    </ConfigProvider>*/}
            {/*</div>*/}
        </>
    );
}

export default TimeLine;