import React, {useContext} from 'react';
import {Skeleton, List} from "antd";
import useMortgage from "../../../providers/mortgage/useMortgage"
import NoteListItem from "./NoteListItem";

function NotesList(props) {
    const mortgage = useMortgage()
    return (
        <Skeleton loading={!mortgage}>
            <List
                className="w-100"
                size="large"
                itemLayout="vertical"
                dataSource={mortgage?.notes?.sort((a, b) => new Date(a.due) - new Date(a.due))}
                renderItem={(item) => <List.Item style={{border:0}}> <NoteListItem key={`notelistitem-${item.id}`} item={item}/> </List.Item>}
            />
        </Skeleton>
    );
}

export default NotesList;