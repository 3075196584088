import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import UploadsTable from "./UploadsTable";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {fileNameAndExtension} from "../../../../../../../assets/scripts/string-functions";
import {API, Auth} from "aws-amplify";
import app from "../../../../../../App";
import useMortgageUploadService from "../../../../../../providers/mortgage/useMortgageUploadService";
import {Button, Collapse, Form, Input, Switch} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {orange} from "@ant-design/colors";
import Bundler from "../Bundler";
import {UploadStatuses} from "../../../../../../../models";
import {sorterByDateName} from "../../../../../../../assets/scripts/verifiable-section/verifiableSection";
UploadCollapsedPanelRight.propTypes = {};
export const prepareUploadForStandardUpload = (upload) => {
    let name
    if (upload.fileExtension) {
        name = upload.fileName
    } else {
        let {fileName} = fileNameAndExtension(upload.fileName)
        name = fileName
    }
    const o = {
        ...upload,
        uid: upload.id,
        name,
        extension: upload.fileExtension,
        omit: upload.omit || [],
    }
    o.htmlKey = `${upload.stage}/${upload.section}`
    if (upload.index) {
        o.htmlKey += `/${upload.index}`
        o.index = upload.index
    }
    return o
}

function UploadCollapsedPanelRight(props) {

    const mortgage = useMortgage()
    const uploadService = useMortgageUploadService()
    const [viewingExpired,setViewingExpired] = useState(false)
    const handleRename = async (id, name) => {
        const result = await uploadService.rename(id, name)
        return result
    }
    const handleDelete = async (id) => {
        return await uploadService.delete(id)
    }
    const handleUpload = async ({file, progressCallback, completeCallback, errorCallback}) => {
        let expandedProps = {
            file,
            progressCallback,
            completeCallback: async (event) => {
                const user = await Auth.currentAuthenticatedUser()
                if (!props.section.record.submittedOn) {
                    mortgage.mutate({
                        update: {
                            target: `progress[${props.section.record.id}]`,
                            pairs: {
                                submittedOn: new Date().toISOString(),
                                submittedBy: user.attributes.sub
                            }
                        }
                    })
                }
                return completeCallback({...event, item: event.item})

            },
            errorCallback,
            stage: props.section.stage,
            section: props.section.name,
            index: props.section?.index,
        }
        await uploadService.add(expandedProps)
    }
    const handleStatusChange = async (id, status) => {
        await mortgage.mutate({
            update: {
                target: `uploads[${id}].status`,
                value: status
            }
        })
    }
    const handleOmit = async (id, array) => {

        await mortgage.mutate({update: {target: `uploads[${id}].omit`, value: array}})
    }
    const handleStamp = async (id, placement) => {
        const row = mortgage.uploads.find(item => item.id === id)
        const {path, config} = uploadService.get(row)
        let realPath = path
        //if already stamped, remove the -stamped from the filename
        if (row.stampedOn) {
            const parts = path.split('/')
            const filename = parts.pop()
            const newFilename = filename.replace('-stamped', '')
            parts.push(newFilename)
            realPath = parts.join('/')
        }

        let fullpath = `${config.level}/${config.identityId}/${realPath}`

        try {
            const result = await API.post("sussdAdminAPI", "/operations/uploads/stamp2", {
                body: {
                    key: fullpath,
                    placement
                }
            })
            if (result.success) {
                await mortgage.mutate({
                    update: {
                        target: `uploads[${id}].stampedOn`,
                        value: new Date().toISOString()
                    }
                })
            }
            return result
        } catch (e) {
            app.message.error(e.message)
        }
    }
    const handleOpen = async (id) => {
        const upload = mortgage.uploads.find(item => item.id === id)
        await uploadService.open(upload)
    }
    const handleNewArrangement = async (id, arrangement) => {
        return await mortgage.mutate({update: {target: `progress[${id}].arrangement`, value: arrangement}})
    }
    const handleExclude = async (id, exclude) => {
        return await mortgage.mutate({update: {target: `uploads[${id}].exclude`, value: exclude}})
    }

    let expiredFilter = it => {
        if(viewingExpired) return it.status === UploadStatuses.EXPIRED || it.status === UploadStatuses.REJECTED || it.exclude
        return it.status !== UploadStatuses.EXPIRED && it.status !== UploadStatuses.REJECTED && !it.exclude
    }
    const files = props.section.verified.isVerifiable ? (viewingExpired ? props.section.verified.uploads.inactive : props.section.verified.uploads.active).map(i => prepareUploadForStandardUpload(i)).sort(sorterByDateName('sortDate')) : props.section.uploads.filter(expiredFilter).map(i => prepareUploadForStandardUpload(i)).sort(sorterByDateName('createdAt'))
    function RequestAnotherUpload() {
        const [state, setState] = useState({busy: false})
        const [anotherUploadForm] = Form.useForm()
        const handleFinish = async (values) => {

            setState(s => ({...s, busy: true}))
            const user = await Auth.currentAuthenticatedUser()
            const update = {
                target: `progress[${props.section.record.id}]`,
                pairs: {
                    alertAuthor: user.attributes.sub,
                    alertBody: values.alertBody.split('\n'),
                    alertTitle: values.alertTitle,
                    alertDismissed: false
                }
            }
            await mortgage.mutate({update})

            setState(s => ({...s, busy: false}))
        }
        const handleReset = async () => {
            const update = {
                target: `progress[${props.section.record.id}]`,
                pairs: {
                    alertBody: null,
                    alertTitle: null
                }
            }
            await mortgage.mutate({update})
        }
        const handlePreLoad = async (alertTitle,alertBody) => {
            await anotherUploadForm.setFieldsValue({
                alertBody,
                alertTitle
            })
        }
        return (
            <div className="p-15">
                <Form
                    form={anotherUploadForm}

                    onFinish={handleFinish}
                    initialValues={{
                        alertBody: !!props.section.record.alertBody ? props.section.record.alertBody.join('\n') : '',
                        alertTitle: props.section.record.alertTitle || `We need another ${props.section.title}`,
                    }}
                >
                    <Form.Item
                        name="alertTitle"
                        label="Title"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="alertBody"
                        label="Description"
                    >
                        <Input.TextArea/>
                    </Form.Item>
                    <div className="d-row gap-3">

                        <Button htmlType="reset" onClick={handleReset}><DeleteOutlined/></Button>
                        <Button htmlType="submit">Save {state.busy && <LoadingOutlined/>}</Button>
                    </div>

                </Form>
                <div>
                {/*<div className="d-row f-wrap a-start gap-9 px-9">*/}
                {/*    {Object.keys(problems).map(k => {*/}
                {/*        let [title, body] = problems[k]*/}
                {/*        return (*/}
                {/*            <Button key={k} size="small" onClick={()=>handlePreLoad(title,body)}>{title}</Button>*/}
                {/*        )*/}
                {/*    })}*/}

                {/*</div>*/}
                </div>
            </div>
        )
    }
    function ControlledCollapseMoreUploads() {

        const [activeKey, setActiveKey] = useState(null)

        function ButtonAndTick() {
            return (
                <div className="d-row gap-9 a-center">
                    <Button>Request another upload</Button>
                    {
                        !!props.section.record.alertBody && <ExclamationCircleOutlined style={{color: orange[5], fontSize: 22}}/>
                    }
                    <Switch checkedChildren="Expired" unCheckedChildren={"Active"} defaultChecked={viewingExpired} onChange={setViewingExpired}/>
                    <div className="ms-auto">
                        <Bundler section={props.section}/>
                    </div>

                </div>
            )
        }

        const items = [
            {
                key: '1',
                label: <ButtonAndTick/>,
                children: <RequestAnotherUpload/>,
                showArrow: false

            }
        ]
        return <Collapse onChange={setActiveKey} className="radius-0" activeKey={activeKey} items={items}/>
    }
    // let props = {files, section, sections, handleUpload, viewingExpired}
    return (
        <div className="d-col grow">
            {
                !props?.preventRequestMore && (
                    <ControlledCollapseMoreUploads section={props.section}/>
                )
            }
            {/*<div className="p-15" style={{borderBottom: '1px solid #ccc'}}>*/}
            {/*    <Form.Item label="Internal Note" className="mb-0">*/}
            {/*        <InternalNote section={props.section}/>*/}
            {/*    </Form.Item>*/}

            {/*</div>*/}

            <UploadsTable
                key={props.section.record.id + viewingExpired + files.length}
                viewingExpired={viewingExpired}
                section={props.section}
                sections={props.sections} // for ability to move an upload to another section
                files={files}
                handleUpload={handleUpload}
           //     handleStatusChange={handleStatusChange}

           //     rename={handleRename}
          //      delete={handleDelete}
           //     handleOpen={handleOpen}
           //     handleStamp={handleStamp}
            //    handleOmit={handleOmit}
            //    handleNewArrangement={handleNewArrangement}
            //    handleExclude={handleExclude}
            />
        </div>
    );
}

export default UploadCollapsedPanelRight;