import React, {useEffect, useState} from 'react';
import {ConfigProvider, Drawer, theme} from "antd";
import {CloseCircleOutlined, LogoutOutlined, UnorderedListOutlined} from "@ant-design/icons";
import logo from '../../../assets/images/logo-white.svg';
import {Link, Outlet, useNavigate} from "react-router-dom";
import Content from "../../components/sidebar/Content";
import {Auth} from "aws-amplify";

function Dashboard({sidebar=true}) {
    const nav = useNavigate()
    const [state, setState] = useState({sidebarOpen: true})
    const sidebarEffects = {
        close: () => setState(s => ({...s, sidebarOpen: false})),
        open: () => setState(s => ({...s, sidebarOpen: true})),
    }


    function SidebarHeader(props) {
        return <div className="d-row j-between">
            <Link to="/"><img alt="" src={logo} width={150}/></Link>
            <CloseCircleOutlined className="ms-12" onClick={sidebarEffects.close}/>
        </div>
    }

    let signedOut = null


    useEffect(() => {
        signedOut = setInterval(() => {
            Auth.currentAuthenticatedUser().catch(e => {
                nav('/signin')
                clearInterval(signedOut)
            })
        }, 1000 * 60)
        return () => clearInterval(signedOut)
    }, []);

    if (!sidebar) return (
        <Outlet/>
    )


    return (
        <div>
            <ConfigProvider
                theme={{
                    algorithm: theme.darkAlgorithm,
            }}
            >

                <Drawer
                    title={<SidebarHeader/>}
                    footer={
                        <div>
                            <Link to={'/signout'}><LogoutOutlined style={{color: "#FFF"}}/></Link>
                        </div>
                    }
                    placement="left"
                    closeIcon={null}
                    mask={false}
                    width={250}
                    open={state.sidebarOpen}
                    styles={{body:{padding: 0}}}
                >
                    <div style={{width: '100%', overflowX: "hidden"}}>
                        <Content/>
                    </div>


                </Drawer>
                <Drawer
                    title={null}
                    placement="left"
                    closeIcon={null}
                    open={!state.sidebarOpen}
                    width={40}
                    styles={{body:{padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white'}}}
                    rootStyle={{height: 57}}
                    mask={false}
                    onClose={sidebarEffects.close}
                >
                    <UnorderedListOutlined onClick={sidebarEffects.open}/>
                </Drawer>
            </ConfigProvider>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgContainerDisabled: 'rgba(0,0,0,0.015)', // Set your desired background color for disabled inputs
                        colorTextDisabled: 'rgba(0,0,0,0.55)', // Set your desired text color for disabled inputs
                    },
                }}
            >
            <div className={`content ${state.sidebarOpen ? 'sidebar-open' : ''}`}>

               <Outlet/>



            </div>
            </ConfigProvider>
        </div>
    );
}

export default Dashboard;