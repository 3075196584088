import {AssetTypes, Frequencies} from "../../../../../../models";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";
export function assetType(props) {
    return {
        text: "What type of asset is this?",
        label: "Asset Type",
        name: "assetType",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Investment",
                    value: "INVESTMENT"
                },
                {
                    text: "On Deposit",
                    value: "SAVING"
                }
            ]
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].assetType`
    }
}
export function institution(props) {
    return {
        name: "institution",
        answer: {
            type: "text",
        },
        text: (form) => {
            let val = form.getFieldValue('assetType')
            if (val === AssetTypes.INVESTMENT) return "At what bank/institution is this investment held?"
            return "At what bank/institution are these savings held?"
        },
        subtext: "eg. Bank of Ireland, AIB",
        label: "Institution",
        model: "assets",
        rules: [
            {required: true, message: `An institution is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].institution`
    }
}
export function description(props) {
    return {
        name: "description",
        answer: {
            type: "text",
        },
        text: "Description",
        label: "Description",
        model: "assets",
        rules: [
            {required: true, message: `An description is required`},
        ],
        hides:(form)=>{
            return form.getFieldValue('assetType') !== AssetTypes.INVESTMENT
        },
        target: `applicant${props.applicant}.assets[${props.index}].description`
    }
}
export function balance(props) {
    return {
        name: "balance",
        answer: {
            type: "euro",
            validation:{
                min:{
                    value:1,
                    message:'The minimum value here is €1'
                },
                max:{
                    value:2000000,
                    message: 'You probably don\'t need a mortgage'
                }
            }
        },
        label: "Balance",
        text: (form) => {
            let val = form.getFieldValue('assetType')
            if (val === AssetTypes.INVESTMENT) return "What is current value of this investment?"
            return  "What is the balance in the account?"
        },
        model: "assets",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 100, message: "The minimum value here is €100"},
            {type: 'number', max: 2000000, message: "The maximum value here is €2,000,000"}
        ],
        target: `applicant${props.applicant}.assets[${props.index}].balance`
    }
}
export function joint(props){
    return {
        text: "Is this a joint account?",
        label: "Joint Account",
        name: "joint",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ]
        },
        model: "assets",
        target: `applicant${props.applicant}.assets[${props.index}].joint`
    }
}
export function increasesFrequency(props) {
    return {
        text: "How often "+ props.you.doYou +" add funds to these savings",
        label: "Increases Frequency",
        name: "increasesFrequency",
        answer: {
            type: "select",
            choices: [
                {
                    text: "yearly",
                    value: "YEARLY"
                },
                {
                    text: "quarterly",
                    value: "QUARTERLY"
                },
                {
                    text: "monthly",
                    value: "MONTHLY"
                },
                {
                    text: "fortnightly",
                    value: "FORTNIGHTLY"
                },
                {
                    text: "weekly",
                    value: "WEEKLY"
                },
                {
                    text: "occasionally",
                    value: "OCCASIONALLY"
                },
                {
                    text: "hardly ever",
                    value: "STATIC"
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('assetType')!== "SAVING"
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].increasesFrequency`
    }
}
export function increases(props) {
    return {
        text: (form) => {
            let val = form.getFieldValue('increasesFrequency')
            switch(val){
                    case Frequencies.YEARLY: return "How much do you deposit in a typical year?"
                    case Frequencies.QUARTERLY: return "How much do you deposit in a typical quarter"
                    case Frequencies.FORTNIGHTLY: return "How much do you deposit in a typical fortnight?"
                    case Frequencies.MONTHLY: return "How much do you deposit in a typical month"
                    case Frequencies.WEEKLY: return "How much do you deposit in a typical week"
                    case Frequencies.OCCASIONALLY:return "How much would you typically deposit?"
                    case Frequencies.STATIC: return ""
            }
        },
        label: "Increases",
        name: "increases",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 20000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form)=>{
            if (form.getFieldValue('assetType')=== "INVESTMENT") return true
            return form.getFieldValue('increasesFrequency') === "STATIC"
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].increases`
    }
}
export function assetTitle(asset){
    if (!asset.institution){
        return 'New Asset'
    }
    let title = capitaliseFirst(asset.institution)
    if (title && asset.assetType){
        title = title + ' ' + capitaliseFirst(asset.assetType?.toLowerCase())
    }
    else{
        title= 'New Asset'
    }
    return title

}

export const applicantAssetSteps = ({mortgage, applicant, index}) => {
    const betterProps = {
        mortgage,
        applicant,
        index,
        you: mortgage[`applicant${applicant}`].strings
        //you(applicant === 1 ? false : mortgage.applicant2?.firstName ? mortgage.applicant2.firstName : 'the other applicant')
    }
    const asset = mortgage[`applicant${applicant}`].assets.find(asset => asset.id === index)

    return [{
        label: 'Savings',
        index,
        title: assetTitle(asset),
        questions: [
            assetType(betterProps),
            institution(betterProps),
            description(betterProps),
            joint(betterProps),
            balance(betterProps),
            increasesFrequency(betterProps),
            increases(betterProps)
        ]
    }]
}
export const applicantAssets = ({mortgage, applicant}) => {
    return mortgage[`applicant${applicant}`].assets.filter(asset => !asset._deleted).map(asset => ({
        to: asset.id,
        index: asset.id,
        title: asset.assetType,
        subtitle: asset.institution || 'New Asset',
        footer: `€${asset.balance?.toLocaleString('en-us') || 0}`,
        delete: {delete: `applicant${applicant}.assets`, id: asset.id}
    }))
}



function ApplicantAssets(props) {
    const steps = applicantAssetSteps(props)
    const questions = steps.flatMap(step=>{
        return step.questions
    })
    const repeater = `assets-${props.applicant}`
    return <SectionForm title="Applicant Asset" repeater={repeater} key={`ApplicantAssets${props.index}`} {...props} questions={questions}/>

}


export default ApplicantAssets;