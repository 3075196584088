import React, {useContext, useEffect} from 'react';
import {Badge, Button, Card, ConfigProvider, Form, Input, Space, Tag, theme} from "antd";
import {AimOutlined, LoadingOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {geekblue, gray, purple} from "@ant-design/colors";
import useBackendUsers from "../../providers/mortgage/useBackendUsers";
import {MyAdminTasks} from "../../providers/MyAdminTasksProvider";
import ItemModal from "../dashboard/landing-page/tasks/ItemModal";
import dayjs from "dayjs";
function TasksBar(props) {
    const {operations} = useContext(MyAdminTasks)
    const navigate = useNavigate()
    const style = {
        borderRadius: 11,
        width: 22,
        height: 22,
        padding: 0,
        backgroundColor:'transparent',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        fontFamily: 'monospace',
        borderWidth:2
    }
    const [active, setActive] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [task, setTask] = React.useState(false)
    const backendUsers = useBackendUsers()
    const selectedUser = active ? backendUsers.users.find(user => user.email === active) : null
    const inputRef = React.useRef(null)
    const handleTaskFormFinish = async ({body}) => {
        setSaving(true)
        const task = await operations.create({
            body: [body],
            author: backendUsers.me.sub,
            target: selectedUser.sub,
            currentOwner: selectedUser.sub,
            userSortPosition: JSON.stringify({
                [selectedUser.sub]: 0.95
            }),
            usermortgageID: props.clientId ? props.clientId : "null"
        })
        setSaving(false)
        const tag = {
            author: {
                color: backendUsers.getBySub(task.author).color,
                name: (backendUsers.me.sub === task.author ? 'Me' : backendUsers.getBySub(task.author).firstName)
            },
            target: {
                color: task.target ? backendUsers.getBySub(task.target).color : 'default',
                name: task.target ? (backendUsers.me.sub === task.target ? 'Me' : backendUsers.getBySub(task.target).firstName) : 'No User'
            }
        }
        setTask({
            ...task,
            userSortPosition: JSON.parse(task.userSortPosition),
            tag})
    }
    const onCancel = () => {
        setActive(false)
        setSaving(false)
        setTask(false)
    }

    useEffect(() => {
        if (active) {
            inputRef.current.focus()
        }
    }, [active]);
    function BasicContent(props) {
        const user = backendUsers.getBySub(task.currentOwner)
        let date = dayjs(task.createdAt).format('D MMM, H:mm a')
        let author = backendUsers.getBySub(task.author).firstName
        let color = user ? user.color : 'default'
        let name = user ? user.firstName : 'No ball owner'
        return (
            <>
                {
                    !!task.mortgage && (

                        <Link style={{color: gray[1]}}
                              to={`/client/${task.mortgage.id}`}><small>{task.mortgage.title}</small> </Link>

                    )
                }
                <div className="d-row gap-6 a-center w-100">
                    <small className="d-block">{author} on {date}</small>
                    <span className="ms-auto">
                          <Tag color={color} className="d-row a-center gap-6 me-0" style={{backgroundColor:'transparent', marginInlineEnd:0}}> <AimOutlined/> {name} </Tag>
                    </span>
                </div>
            </>
        )
    }
    return (
        <div>
            <ConfigProvider
                theme={{algorithm: theme.defaultAlgorithm}}
            >

                {
                    active && (

                        <div style={{
                            left: 250,
                            width: 300,
                            position: 'absolute',
                            zIndex: 100,
                            transform: 'translateY(-1.5rem)'

                        }}>
                            <Badge count={`For ${selectedUser.firstName}`} color={selectedUser.color}>
                                <Card
                                    shadow={2}
                                    className="br-tl-0 br-bl-0"
                                    styles={{body:{paddingBottom: '0.3rem'}}}
                                >
                                    <Form
                                        onFinish={handleTaskFormFinish}>
                                        <Space.Compact>
                                            <Form.Item noStyle name="body">
                                                <Input ref={inputRef} placeholder="Task Title" style={{width: 240}}/>
                                            </Form.Item>
                                            <Button htmlType="submit">{saving ? <LoadingOutlined/> : <>Go!</>}</Button>
                                        </Space.Compact>
                                    </Form>
                                    <small><a style={{paddingLeft: 7, color: gray[1]}}
                                              onClick={() => setActive(false)}>Cancel</a></small>
                                </Card>
                            </Badge>

                        </div>

                    )
                }
                {
                    !!task && (
                        <ItemModal task={task} open={true} onCancel={onCancel} key={task.id}>
                            <BasicContent/>
                        </ItemModal>
                    )
                }
            </ConfigProvider>

            <div className="m-9 d-row gap-9">
                <Button className="grow" onClick={() => navigate('/my-tasks')}><UnorderedListOutlined/> My Tasks</Button>
                <div className="d-row gap-6 a-center">
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBorderColor: "#c41d7f",
                                }
                            }
                        }}
                    >
                        <Button onClick={() => setActive('marianna@sussd.ie')} style={style}>m </Button>
                    </ConfigProvider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBorderColor: geekblue.primary,
                                }
                            }
                        }}
                    >
                        <Button onClick={() => setActive('kevin@sussd.ie')} style={style}>k</Button>
                    </ConfigProvider>
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBorderColor: purple.primary,
                                }
                            }
                        }}
                    >
                        <Button onClick={() => setActive('stephen@sussd.ie')} style={style}>s</Button>
                    </ConfigProvider>



                </div>
            </div>



        </div>

    );
}

export default TasksBar;