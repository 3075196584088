import React from 'react';
import {ApplicationStages, ReplyRequestTypes} from "../../../../../models";
import {DatePicker, Form, Input, InputNumber, Modal} from "antd";
import {getAttachmentPath} from "../../../emails-manager/AttachmentButtonGroups";
import useMortgageDocumentsService from "../../../../providers/mortgage/useMortgageDocumentsService";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import {LoadingOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import useLenderVariables from "../../../../providers/useLenderVariables";
import {currencyFormatter, currencyParser} from "../../../../../assets/scripts/parser-functions";

function ApprovalInPrincipleModal(props) {
    const navigate = useNavigate()
    const [saving, setSaving] = React.useState(false)
    const mortgage = useMortgage()
    const [form] = Form.useForm()
    const [open, setOpen] = React.useState(true)
    const documentService = useMortgageDocumentsService()
    const referenceIdRequired = mortgage.activeSubmission.lenderCode === 'pstb'
    const handleApprovalInPrincipleSubmit = async () => {
        if (!saving) {

            const update = []
            const create = []

            // add update mortgage's requirements with the AIP date and expiry date
            const values = {}
            values.date = await form.getFieldValue('date')
            values.expiry = await form.getFieldValue('expiry')
            // values.replyTo = await form.getFieldValue('replyTo')
            let notes = await form.getFieldValue('notes')
            let reference = await form.getFieldValue('reference')
            values.maxLoan = await form.getFieldValue('maxLoan')
            values.accountNo = await form.getFieldValue('accountNo')
            if (notes) {
                values.notes = notes
            }
            if (referenceIdRequired) {
                values.reference = reference
            }

            if (!values.date) {
                console.log('date is required')
                return false
            }
            if (!values.expiry) {
                console.log('expiry is required')
                return false
            }
            // if (!values.replyTo) {
            //     console.log('replyTo is required')
            //     return false
            // }
            if (referenceIdRequired && !values.reference) {
                console.log('reference is required')
                return false
            }
            if (!values.maxLoan) {
                console.log('maxLoan is required')
                return false
            }
            if (!values.accountNo){
                console.log('Account No is required')
                return false
            }
            setSaving(true)
            const pairs = {
                aipDate      : values.date.format("YYYY-MM-DD"),
                aipExpiryDate: values.expiry.format("YYYY-MM-DD"),
                aipMaxLoan      : values.maxLoan,
                aipEmailId   : props.email.id,
                aipNotes     : values.notes ? values.notes.split('\n') : [],
                mortgageAccountNo: values.accountNo
            }

            // update.push({
            //     target: `requirement`,
            //     pairs: {
            //         // maxLoan
            //         aipDate: values.date.format("YYYY-MM-DD"),
            //         aipExpiryDate: values.expiry.format("YYYY-MM-DD"),
            //     }
            // })
            if (values?.reference) {
                // update[0].pairs.propertyReference = values.reference
                pairs.propertyReference = values.reference
            }

            update.push({
                target: `submissions[${mortgage.activeSubmission.id}]`,
                pairs
            })

            // move the mortgage to the pre-approval stage
            update.push({
                target: `applicationStage`,
                value : ApplicationStages.PREAPPROVAL
            })

            // create a reply request to store the approval in principle email
            // create.push({
            //     target: 'replyRequests',
            //     pairs: {
            //         receivedOn: props.email.datetimeReceived,
            //         mortgageId: mortgage.id,
            //         emailId: props.email.id,
            //         replyTo: values.replyTo,
            //         noteBody: values.notes ? values.notes.split('\n') : [],
            //         requestType: ReplyRequestTypes.PREAPPROVAL,
            //     }
            // })

            // copy the attachment to the user's documents in the lender-documents folder
            const source = getAttachmentPath(props.attachment, props.email.v2Attachments)

            const result = await documentService.copyAttachmentToUserDocs({
                folder      : 'lender-documents',
                title       : 'Approval in Principle',
                isPrivate   : false,
                fullFilename: 'approval-in-principle.pdf',
                stage       : 'PREAPPROVAL',
                submissionID: mortgage.activeSubmission.id,
                bucketKey   : source
            })

            const result4 = await mortgage.mutate({
                update, //     create
            })

            setSaving(false)
            navigate(`/client/${mortgage.id}/pre-approval-management`)
        }
    }
    const {monthsForAipToExpire} = useLenderVariables({lenderCode: mortgage.activeSubmission.lenderCode})
    const handleFormChange = (val, all) => {
        if (Object.keys(val)[0] === 'date') {
            form.setFieldValue('expiry', val.date.add(monthsForAipToExpire, 'month'))
        }
    }
    return (<Modal
            okButtonProps={{icon: saving ? <LoadingOutlined/> : null}}
            afterClose={props.onCancel}
            destroyOnClose
            open={open}
            title="Mark as Approval In Principle"
            onCancel={() => setOpen(false)}
            onOk={handleApprovalInPrincipleSubmit}
        >
            <Form
                form={form}
                requiredMark={false}
                onValuesChange={handleFormChange}
                initialValues={{
                    // replyTo: props.email.from,
                    soft: mortgage.activeSubmission.soft,
                    accountNo: mortgage.activeSubmission.mortgageAccountNo,
                }}
            >
                <div className="d-row gap-15 j-equal mt-15">
                    <Form.Item name="date" label="AIP Date" required>
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                    <Form.Item name="expiry" label="Expry Date" required>
                        <DatePicker format={"D MMM YYYY"}/>
                    </Form.Item>
                </div>
                {referenceIdRequired && (<Form.Item name="reference" label="Reference No" required>
                    <Input/>
                </Form.Item>)}
                <div className="d-row gap-15 j-equal mt-15">
                    <Form.Item name="maxLoan" label="Max Loan" required>
                        <InputNumber style={{width: '100%'}} prefix="€"
                                     formatter={currencyFormatter}
                                     parser={currencyParser}/>
                    </Form.Item>
                    <Form.Item name="accountNo" label="Account No">
                        <Input/>
                    </Form.Item>
                </div>
                <Form.Item name="notes" label="Notes" required={false}>
                    <Input.TextArea/>
                </Form.Item>

                {/*<Form.Item name="replyTo" label="Reply To" required>*/}
                {/*    <Input/>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>);
}

export default ApprovalInPrincipleModal;