import useSussdPdfObjects from "../../../app/providers/useSussdPdfObjects";
import SussdCoverNote from "./SussdCoverNote";
import useMortgage from "../../../app/providers/mortgage/useMortgage";
import useApplicationUploads from "../../../app/providers/mortgage/useApplicationUploads";
import {verifiedAccounts, verifiedInvestments} from "../../../app/components/client/application-stages/_common/funding/verifiedAccounts";
import {App} from "antd";
import {ApplicationTypes, BuildType, MortgageTypes} from "../../../models";

function useSussdCoverNote({submission}) {
    const mortgage = useMortgage();
    const app = App.useApp();
    const apply = mortgage.mortgageUploads.apply
    //const {apply} = useApplicationUploads()

    function expand(accounts) {
        if (accounts.dataSource.length) {
            accounts.needsExplaining = accounts.dataSource.filter(it => {
                return !!it.adjustment;
            })
            accounts.notableItems = accounts.dataSource.filter(it => {
                return !(!it?.queries?.length || it.queries.every(it => !!it.excludedOn));
            })
        }
        return accounts
    }

    // Accounts
    let currentAccountsSections = apply.filter(it => it.name.startsWith('current-accounts'))
    let savingsAccountsSections = apply.filter(it => it.name.startsWith('assets'))

    let currentAccounts = verifiedAccounts(mortgage, currentAccountsSections)
    let savingsAccounts = verifiedAccounts(mortgage, savingsAccountsSections)
    let investments = verifiedInvestments(mortgage, savingsAccountsSections)

    // Euros
    let euroCurrentAccounts = currentAccounts.hasOwnProperty('€') ? expand(currentAccounts['€']) : {dataSource: []}
    let euroSavingsAccounts = savingsAccounts.hasOwnProperty('€') ? expand(savingsAccounts['€']) : {dataSource: []}
    let euroInvestments = investments.hasOwnProperty('€') ? expand(investments['€']) : {dataSource: []}

    delete (currentAccounts['€'])
    delete (savingsAccounts['€'])
    delete (investments['€'])

    // Non Euros
    let nonEuroCurrentAccounts = Object.keys(currentAccounts).map(key => {
        let obj = expand(currentAccounts[key])
        obj.currency = key
        return obj
    })
    let nonEuroSavingsAccounts = Object.keys(savingsAccounts).map(key => {
        let obj = expand(savingsAccounts[key])
        obj.currency = key
        return obj
    })
    let nonEuroInvestments = Object.keys(investments).map(key => {
        let obj = expand(investments[key])
        obj.currency = key
        return obj
    })


    return useSussdPdfObjects(SussdCoverNote, {
        mortgage,
        euroCurrentAccounts,
        euroSavingsAccounts,
        nonEuroCurrentAccounts,
        nonEuroSavingsAccounts,
        euroInvestments,
        nonEuroInvestments,
        app,
        submission
    })

}
export default useSussdCoverNote;