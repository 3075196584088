import React, {useContext} from 'react';
import {LastViewedMortgages} from "../../../../providers/LastViewedMortgagesProvider";
import {Mortgages} from "../../../../providers/MortgagesProvider";
import {LoadingOutlined} from "@ant-design/icons";
import MortgageTag from "../../../elements/mortgage/MortgageTag";
import {List} from "antd";
import OverflowedCard from "../../_common/OverflowedCard";

function LastViewed(props) {
    const lastViewedMortgages = useContext(LastViewedMortgages)
    const mortgages = useContext(Mortgages)
    const online = mortgages.ONLINENOW
    const h = (online.length) ? '50%' : '100%'
    const data = lastViewedMortgages.map(lastViewing => {
        let liveMortgage = mortgages.ALL.find(it => it.id === lastViewing.mortgageID)
        if (!liveMortgage) {
            return null
        }
        return liveMortgage
    }).filter(it => !!it)
    return (
        <OverflowedCard title="Last Viewed" style={{maxHeight: h}}>
            <List
                header={null}
                footer={null}
                bordered={false}
                dataSource={data}
                renderItem={(item) => (

                    <List.Item>
                        <MortgageTag mortgage={item}/>
                        {/*<Link to={item.href} className="grow">*/}
                        {/*    <Tag color="geekblue">{item.title}</Tag >*/}
                        {/*</Link>*/}
                    </List.Item>

                )}
            />
        </OverflowedCard>
    );
}

export default LastViewed;