import React, {useContext, useEffect, useState} from 'react';
import dayjs from "dayjs";
import {Mortgages} from "../../../../providers/MortgagesProvider";
import {setupIncomplete, toDos as setupToDos} from "../../../../../config-files/mortgage/administrative-todos/setup";
import {toDos as applyToDos} from "../../../../../config-files/mortgage/administrative-todos/apply";
import {toDos as preapprovalToDos} from "../../../../../config-files/mortgage/administrative-todos/preapproval";
import {toDos as approvalToDos} from "../../../../../config-files/mortgage/administrative-todos/approval";
import {toDoList} from "../../../../../config-files/mortgage/administrative-todos";
import {OutstandingItems} from "../../../../providers/mortgage/OutstandingItemsProvider";
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";

export const getNextItemFromEmail = (toDo) => {
    if (toDo.hasOwnProperty('emails')) {
        const lastEmailSent = toDo.emails.findIndex(e => `${e.STAGE}.${e.name}` === toDo.mortgage.lastFlowItem)
        const nextIndex = lastEmailSent + 1
        let nextEmail = toDo.emails[nextIndex]
        if (nextEmail) {
            if (nextEmail.hasOwnProperty('dueAfter')) {
                let shouldSendOn = dayjs(toDo.mortgage.lastFlowItemTime).add(nextEmail.dueAfter, 'days')
                if (shouldSendOn.isAfter(dayjs())) {
                    nextEmail = null
                }
            }
            return nextEmail
        }
    }
    return null
}

const getNotCompletedFromButtons = (toDo) => {
    if (toDo.hasOwnProperty('buttons')) {
        const lastButtonClicked = toDo.buttons.findIndex(e => `${e.STAGE}.${e.name}` === toDo.mortgage.lastFlowItem)
        if (lastButtonClicked === -1) {
            return true
        }
    }
    return null

}
// 1. Get the last done item

// 2. Get the list of stage items

// 3. Loop through the items and check if they are skipped or due and build a list of problems

// 4. Go through the list of problems and find the last done item

// 5. If the last done item is still active, check if it is due

// 6. If the last done item is not active, check if the next item is due

function useFlowItems() {
    const outstandingItems = useContext(OutstandingItems)
    const {me} = useBackendUsers()
    // mortgage admin items for main dashboard - full mortgage not available here !!!

    const mortgages = useContext(Mortgages)
    const [allProblems, setAllProblems] = useState([])
    const [allAdvisorProblems, setAdvisorProblems] = useState([])
    const [allSupportProblems, setAllSupportProblems] = useState([])
    const [allRestProblems, setAllRestProblems] = useState([])
    let debugId = '2946d056-c0ddddde3-422a-8137-ba4bf4bc85bd'
    const evalToDo = (funcs = [], mortgage = {}) => {
        // Do we add this mortgage to the list of problems?
        // find last completed and next to-Do
        let toDos = []

        for (let i = 0; i < funcs.length; i++) {
            let toDo = funcs[i](mortgage, {outstandingItems})
            if (!toDo){
                break
            }
            // if (toDo.hasOwnProperty('for') && !!toDo.for){
            //     let _for = toDo.for
            //     if (me.is.hasOwnProperty(_for) && !me.is[_for]){
            //         continue
            //     }
            // }
            if (toDo.hasOwnProperty('doneIf') && !!toDo.doneIf && toDo.doneIf(mortgage)) {

                continue
            }
            toDos.push(toDo)
        }
        let restProblems = []
        let supportProblems = []
        let advisorProblems = []

        let problems = []
        if (mortgage.id === debugId) {

        }
        let lastDone = null
        let afterLastDone = null

        for (let i = 0; i < toDos.length; i++) {
            let toDo = toDos[i]
            if (mortgage.id === debugId) {

            }
            if (!mortgage.lastFlowItem) {
                afterLastDone = toDo
                break
            }
            if (lastDone) {
                afterLastDone = toDo
                break;
            }
            let flowName = `${toDo.STAGE}.${toDo.name}`
            if (mortgage.lastFlowItem.startsWith(flowName)) {
                lastDone = toDo
            }
        }


        if (mortgage.id === debugId) {

        }
        // find out if the last item is still active
        let lastDoneStillActive = !!lastDone
        if (lastDone) {
            // if (lastDone.hasOwnProperty('doneIf') && !!lastDone.doneIf) {
            //
            //     let done = lastDone.doneIf(mortgage)
            //     if (mortgage.id === debugId) {

            //     }
            //     if (done) {
            //         lastDoneStillActive = false
            //     }
            // }

            if (lastDoneStillActive) {
                if (lastDone.hasOwnProperty('emails') && lastDone.emails.length > 0) {
                    const nextItem = getNextItemFromEmail(lastDone)


                    if (nextItem) {
                        if (mortgage.id === debugId) {

                        }
                        problems.push({...lastDone, nextItem})
                        if (mortgage.id === debugId) {
                        }
                        switch (lastDone.for) {
                            case 'support':
                                supportProblems.push({...lastDone, nextItem})
                                break;
                            case 'advisor':
                                advisorProblems.push({...lastDone, nextItem})
                                break;
                            default:
                                restProblems.push({...lastDone, nextItem})
                        }
                    }
                } else {
                    if (mortgage.id === debugId) {
                    }
                    problems.push(lastDone)
                    switch (lastDone.for) {
                        case 'support':
                            supportProblems.push(lastDone)
                            break;
                        case 'advisor':
                            advisorProblems.push(lastDone)
                            break;
                        default:
                            restProblems.push(lastDone)
                    }
                }
            }
        } else {
            afterLastDone = toDos[0]
        }
        if (mortgage.id === debugId) {
       }

        if (!lastDoneStillActive && !!afterLastDone) {
            let doProblems = true

            if (afterLastDone.builder.hasOwnProperty('dueAfter') && !!afterLastDone.builder.dueAfter) {
                if (afterLastDone.builder.dueAfter.isAfter(dayjs())) {
                    doProblems = false
                }
            }
            if (mortgage.id === debugId) {
            }
            if (doProblems) {
                if (afterLastDone.hasOwnProperty('emails') && afterLastDone.emails.length > 0) {
                    const nextItem = getNextItemFromEmail(afterLastDone)
                    if (nextItem) {
                        problems.push({...afterLastDone, nextItem})
                        switch (afterLastDone.for) {
                            case 'support':
                                supportProblems.push({...afterLastDone, nextItem})
                                break;
                            case 'advisor':
                                advisorProblems.push({...afterLastDone, nextItem})
                                break;
                            default:
                                restProblems.push({...afterLastDone, nextItem})
                        }
                    }
                } else if (afterLastDone.hasOwnProperty('buttons') && afterLastDone.buttons.length > 0) {
                    const nextItem = getNotCompletedFromButtons(afterLastDone)
                    if (mortgage.id === debugId) {
                    }
                    if (nextItem) {
                        if (mortgage.id === debugId) {
                        }
                        problems.push(afterLastDone)
                        switch (afterLastDone.for) {
                            case 'support':
                                supportProblems.push(afterLastDone)
                                break;
                            case 'advisor':
                                advisorProblems.push(afterLastDone)
                                break;
                            default:
                                restProblems.push(afterLastDone)
                        }
                    }
                }
                else {
                    if (mortgage.id === debugId) {
                    }
                    problems.push(afterLastDone)
                    switch (afterLastDone.for) {
                        case 'support':
                            supportProblems.push(afterLastDone)
                            break;
                        case 'advisor':
                            advisorProblems.push(afterLastDone)
                            break;
                        default:
                            restProblems.push(afterLastDone)
                    }
                }
            }

        }
        if (mortgage.id === debugId) {
        }
        return {problems, restProblems, supportProblems, advisorProblems}
    }

    const assembleProblems = () => {
        const myProblems = []
        const mySupportProblems = []
        const myAdvisorProblems = []
        const myRestProblems = []

        mortgages.INCOMPLETE.forEach(mortgage => {
            if (mortgage.id === debugId) {
            }
            if (mortgage.applicant1) {
                let myOutstandingItems = null
                if (outstandingItems.hasOwnProperty(mortgage.id)) {
                    let items = outstandingItems[mortgage.id]
                    if (items.uploads.length > 0 && items.queries.length > 0) {
                        myOutstandingItems = items
                    }
                }
                let {currentToDo} = toDoList(mortgage, [setupIncomplete], {outstandingItems:myOutstandingItems})
                if (currentToDo) {
                    myProblems.push(currentToDo)
                    switch (currentToDo.for) {
                        case 'support':
                            mySupportProblems.push(currentToDo)
                            break;
                        case 'advisor':
                            myAdvisorProblems.push(currentToDo)
                            break;
                        default:
                            myRestProblems.push(currentToDo)
                    }
                }
                // let probs = evalToDo([setupIncomplete], mortgage)
                // if (probs.length > 0) {
                //     problems.push(...probs)
                // }
            }
        })
        let SETUP = [...mortgages.NEW, ...mortgages.SETUP]
        SETUP.forEach(mortgage => {
            if (mortgage.id === debugId) {
            }
            let {problems: probs, restProblems, supportProblems, advisorProblems}  = evalToDo(setupToDos, mortgage)
            if (mortgage.id === debugId) {
            }
            if (probs.length > 0) {
                myProblems.push(...probs)
            }
            if (restProblems.length > 0) {
                myRestProblems.push(...restProblems)
            }
            if (supportProblems.length > 0) {
                mySupportProblems.push(...supportProblems)
            }
            if (advisorProblems.length > 0) {
                myAdvisorProblems.push(...advisorProblems)
            }
        })
        let APPLY = [...mortgages.APPLY, ...mortgages.SIGN]
        APPLY.forEach(mortgage => {
            if (mortgage.id === debugId) {
            }
            let {problems: probs, restProblems, supportProblems, advisorProblems}  = evalToDo(applyToDos, mortgage)
            if (mortgage.id === debugId) {
            }
            if (probs.length > 0) {
                myProblems.push(...probs)
            }
            if (restProblems.length > 0) {
                myRestProblems.push(...restProblems)
            }
            if (supportProblems.length > 0) {
                mySupportProblems.push(...supportProblems)
            }
            if (advisorProblems.length > 0) {
                myAdvisorProblems.push(...advisorProblems)
            }
        })
        mortgages.PREAPPROVAL.forEach(mortgage => {
            if (mortgage.id === debugId) {
            }
            let {problems: probs, restProblems, supportProblems, advisorProblems}  = evalToDo(preapprovalToDos, mortgage)
            if (mortgage.id === debugId) {
            }
            if (probs.length > 0) {
                myProblems.push(...probs)
            }
            if (restProblems.length > 0) {
                myRestProblems.push(...restProblems)
            }
            if (supportProblems.length > 0) {
                mySupportProblems.push(...supportProblems)
            }
            if (advisorProblems.length > 0) {
                myAdvisorProblems.push(...advisorProblems)
            }
        })
        mortgages.APPROVAL.forEach(mortgage => {
            if (mortgage.id === debugId) {
            }
            let {problems: probs, restProblems, supportProblems, advisorProblems}  = evalToDo(approvalToDos, mortgage)
            if (mortgage.id === debugId) {
            }
            if (probs.length > 0) {
                myProblems.push(...probs)
            }
            if (restProblems.length > 0) {
                myRestProblems.push(...restProblems)
            }
            if (supportProblems.length > 0) {
                mySupportProblems.push(...supportProblems)
            }
            if (advisorProblems.length > 0) {
                myAdvisorProblems.push(...advisorProblems)
            }
        })
        setAllProblems(myProblems)
        setAdvisorProblems(myAdvisorProblems)
        setAllSupportProblems(mySupportProblems)
        setAllRestProblems(myRestProblems)
    }
    useEffect(() => {
        assembleProblems()
    }, [mortgages, outstandingItems]);
    return {problems: allProblems, allAdvisorProblems, allSupportProblems, allRestProblems}
}

export default useFlowItems;