import {performGQL} from "../functions";
import {Auth} from "aws-amplify";
import generateMutation from "../../../graphql/generate-mutation";
const deleteAdminEmails = /* GraphQL */ `
    mutation DeleteAdminEmails(
        $input: DeleteAdminEmailsInput!
        $condition: ModelAdminEmailsConditionInput
    ) {
        deleteAdminEmails(input: $input, condition: $condition) {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            appsyncVersion
            actionedOn
            actionedBy
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
async function deleteEmail(input){
    const variables = {
        input
    }

    return await performGQL(variables, deleteAdminEmails)
}
export default deleteEmail