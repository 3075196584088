/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPublicCouncils = /* GraphQL */ `
  mutation CreatePublicCouncils(
    $input: CreatePublicCouncilsInput!
    $condition: ModelPublicCouncilsConditionInput
  ) {
    createPublicCouncils(input: $input, condition: $condition) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePublicCouncils = /* GraphQL */ `
  mutation UpdatePublicCouncils(
    $input: UpdatePublicCouncilsInput!
    $condition: ModelPublicCouncilsConditionInput
  ) {
    updatePublicCouncils(input: $input, condition: $condition) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePublicCouncils = /* GraphQL */ `
  mutation DeletePublicCouncils(
    $input: DeletePublicCouncilsInput!
    $condition: ModelPublicCouncilsConditionInput
  ) {
    deletePublicCouncils(input: $input, condition: $condition) {
      id
      fullName
      shortName
      maxPrice
      maxApartment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPublicLenderRate = /* GraphQL */ `
  mutation CreatePublicLenderRate(
    $input: CreatePublicLenderRateInput!
    $condition: ModelPublicLenderRateConditionInput
  ) {
    createPublicLenderRate(input: $input, condition: $condition) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      maximum
      lenderCode
      promotions
      conditions
      forBorrowerTypes
      minBER
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePublicLenderRate = /* GraphQL */ `
  mutation UpdatePublicLenderRate(
    $input: UpdatePublicLenderRateInput!
    $condition: ModelPublicLenderRateConditionInput
  ) {
    updatePublicLenderRate(input: $input, condition: $condition) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      maximum
      lenderCode
      promotions
      conditions
      forBorrowerTypes
      minBER
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePublicLenderRate = /* GraphQL */ `
  mutation DeletePublicLenderRate(
    $input: DeletePublicLenderRateInput!
    $condition: ModelPublicLenderRateConditionInput
  ) {
    deletePublicLenderRate(input: $input, condition: $condition) {
      id
      lenderID
      rate
      maxLTV
      term
      discount
      minimum
      maximum
      lenderCode
      promotions
      conditions
      forBorrowerTypes
      minBER
      buyToLet
      product
      interestOnly
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminPdfOverlayTemplate = /* GraphQL */ `
  mutation CreateAdminPdfOverlayTemplate(
    $input: CreateAdminPdfOverlayTemplateInput!
    $condition: ModelAdminPdfOverlayTemplateConditionInput
  ) {
    createAdminPdfOverlayTemplate(input: $input, condition: $condition) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminPdfOverlayTemplate = /* GraphQL */ `
  mutation UpdateAdminPdfOverlayTemplate(
    $input: UpdateAdminPdfOverlayTemplateInput!
    $condition: ModelAdminPdfOverlayTemplateConditionInput
  ) {
    updateAdminPdfOverlayTemplate(input: $input, condition: $condition) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminPdfOverlayTemplate = /* GraphQL */ `
  mutation DeleteAdminPdfOverlayTemplate(
    $input: DeleteAdminPdfOverlayTemplateInput!
    $condition: ModelAdminPdfOverlayTemplateConditionInput
  ) {
    deleteAdminPdfOverlayTemplate(input: $input, condition: $condition) {
      id
      lender
      lenderCode
      stage
      fileName
      templateType
      mortgageType
      companyFolder
      documentNotes
      archive
      bucketKey
      clientSigns
      docusign
      defaultFolder
      defaultPrivate
      items {
        items {
          id
          name
          pageNo
          x
          y
          type
          style
          templateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminPdfOverlayTemplateItem = /* GraphQL */ `
  mutation CreateAdminPdfOverlayTemplateItem(
    $input: CreateAdminPdfOverlayTemplateItemInput!
    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
  ) {
    createAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminPdfOverlayTemplateItem = /* GraphQL */ `
  mutation UpdateAdminPdfOverlayTemplateItem(
    $input: UpdateAdminPdfOverlayTemplateItemInput!
    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
  ) {
    updateAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminPdfOverlayTemplateItem = /* GraphQL */ `
  mutation DeleteAdminPdfOverlayTemplateItem(
    $input: DeleteAdminPdfOverlayTemplateItemInput!
    $condition: ModelAdminPdfOverlayTemplateItemConditionInput
  ) {
    deleteAdminPdfOverlayTemplateItem(input: $input, condition: $condition) {
      id
      name
      pageNo
      x
      y
      type
      style
      templateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminLenderFiles = /* GraphQL */ `
  mutation CreateAdminLenderFiles(
    $input: CreateAdminLenderFilesInput!
    $condition: ModelAdminLenderFilesConditionInput
  ) {
    createAdminLenderFiles(input: $input, condition: $condition) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminLenderFiles = /* GraphQL */ `
  mutation UpdateAdminLenderFiles(
    $input: UpdateAdminLenderFilesInput!
    $condition: ModelAdminLenderFilesConditionInput
  ) {
    updateAdminLenderFiles(input: $input, condition: $condition) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminLenderFiles = /* GraphQL */ `
  mutation DeleteAdminLenderFiles(
    $input: DeleteAdminLenderFilesInput!
    $condition: ModelAdminLenderFilesConditionInput
  ) {
    deleteAdminLenderFiles(input: $input, condition: $condition) {
      id
      lender
      folder
      description
      fileName
      bucketKey
      contentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminBackendUsers = /* GraphQL */ `
  mutation CreateAdminBackendUsers(
    $input: CreateAdminBackendUsersInput!
    $condition: ModelAdminBackendUsersConditionInput
  ) {
    createAdminBackendUsers(input: $input, condition: $condition) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminBackendUsers = /* GraphQL */ `
  mutation UpdateAdminBackendUsers(
    $input: UpdateAdminBackendUsersInput!
    $condition: ModelAdminBackendUsersConditionInput
  ) {
    updateAdminBackendUsers(input: $input, condition: $condition) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminBackendUsers = /* GraphQL */ `
  mutation DeleteAdminBackendUsers(
    $input: DeleteAdminBackendUsersInput!
    $condition: ModelAdminBackendUsersConditionInput
  ) {
    deleteAdminBackendUsers(input: $input, condition: $condition) {
      id
      firstName
      surname
      sub
      email
      phone
      mailAccount
      color
      lastActive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminLender = /* GraphQL */ `
  mutation CreateAdminLender(
    $input: CreateAdminLenderInput!
    $condition: ModelAdminLenderConditionInput
  ) {
    createAdminLender(input: $input, condition: $condition) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          maximum
          lenderCode
          promotions
          conditions
          forBorrowerTypes
          minBER
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminLender = /* GraphQL */ `
  mutation UpdateAdminLender(
    $input: UpdateAdminLenderInput!
    $condition: ModelAdminLenderConditionInput
  ) {
    updateAdminLender(input: $input, condition: $condition) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          maximum
          lenderCode
          promotions
          conditions
          forBorrowerTypes
          minBER
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminLender = /* GraphQL */ `
  mutation DeleteAdminLender(
    $input: DeleteAdminLenderInput!
    $condition: ModelAdminLenderConditionInput
  ) {
    deleteAdminLender(input: $input, condition: $condition) {
      id
      lender
      address
      approverFirstName
      approverSurname
      approverEmail
      approverPhone
      lenderCode
      rates {
        items {
          id
          lenderID
          rate
          maxLTV
          term
          discount
          minimum
          maximum
          lenderCode
          promotions
          conditions
          forBorrowerTypes
          minBER
          buyToLet
          product
          interestOnly
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      minimumLoan
      minimumTerm
      maximumLoan
      maximumTerm
      onlineAccount
      overlayTemplates {
        items {
          id
          lender
          lenderCode
          stage
          fileName
          templateType
          mortgageType
          companyFolder
          documentNotes
          archive
          bucketKey
          clientSigns
          docusign
          defaultFolder
          defaultPrivate
          items {
            items {
              id
              name
              pageNo
              x
              y
              type
              style
              templateId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      files {
        items {
          id
          lender
          folder
          description
          fileName
          bucketKey
          contentType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      defaultConditions
      defaultPromotions
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminEmails = /* GraphQL */ `
  mutation CreateAdminEmails(
    $input: CreateAdminEmailsInput!
    $condition: ModelAdminEmailsConditionInput
  ) {
    createAdminEmails(input: $input, condition: $condition) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminEmails = /* GraphQL */ `
  mutation UpdateAdminEmails(
    $input: UpdateAdminEmailsInput!
    $condition: ModelAdminEmailsConditionInput
  ) {
    updateAdminEmails(input: $input, condition: $condition) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminEmails = /* GraphQL */ `
  mutation DeleteAdminEmails(
    $input: DeleteAdminEmailsInput!
    $condition: ModelAdminEmailsConditionInput
  ) {
    deleteAdminEmails(input: $input, condition: $condition) {
      id
      from
      to
      cc
      bcc
      subject
      hasAttachments
      v2Attachments
      html
      attachments
      sortDate
      datetimeReceived
      datetimeSent
      messageId
      originalId
      isReceived
      isReceivedInt
      readOn
      readBy
      replyRequestId
      appsyncVersion
      actionedOn
      actionedBy
      AdminEmailAttachments {
        items {
          id
          contentType
          fileSize
          content
          fileName
          adminemailsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userapplicantID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminEmailReplyRequest = /* GraphQL */ `
  mutation CreateAdminEmailReplyRequest(
    $input: CreateAdminEmailReplyRequestInput!
    $condition: ModelAdminEmailReplyRequestConditionInput
  ) {
    createAdminEmailReplyRequest(input: $input, condition: $condition) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminEmailReplyRequest = /* GraphQL */ `
  mutation UpdateAdminEmailReplyRequest(
    $input: UpdateAdminEmailReplyRequestInput!
    $condition: ModelAdminEmailReplyRequestConditionInput
  ) {
    updateAdminEmailReplyRequest(input: $input, condition: $condition) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminEmailReplyRequest = /* GraphQL */ `
  mutation DeleteAdminEmailReplyRequest(
    $input: DeleteAdminEmailReplyRequestInput!
    $condition: ModelAdminEmailReplyRequestConditionInput
  ) {
    deleteAdminEmailReplyRequest(input: $input, condition: $condition) {
      id
      receivedOn
      mortgageId
      emailId
      replyTo
      requestType
      requestBody
      itemsLoaded
      noteBody
      assocEmails
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      repliedBy
      repliedOn
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminEmailAttachments = /* GraphQL */ `
  mutation CreateAdminEmailAttachments(
    $input: CreateAdminEmailAttachmentsInput!
    $condition: ModelAdminEmailAttachmentsConditionInput
  ) {
    createAdminEmailAttachments(input: $input, condition: $condition) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminEmailAttachments = /* GraphQL */ `
  mutation UpdateAdminEmailAttachments(
    $input: UpdateAdminEmailAttachmentsInput!
    $condition: ModelAdminEmailAttachmentsConditionInput
  ) {
    updateAdminEmailAttachments(input: $input, condition: $condition) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminEmailAttachments = /* GraphQL */ `
  mutation DeleteAdminEmailAttachments(
    $input: DeleteAdminEmailAttachmentsInput!
    $condition: ModelAdminEmailAttachmentsConditionInput
  ) {
    deleteAdminEmailAttachments(input: $input, condition: $condition) {
      id
      contentType
      fileSize
      content
      fileName
      adminemailsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminUserCalculations = /* GraphQL */ `
  mutation CreateAdminUserCalculations(
    $input: CreateAdminUserCalculationsInput!
    $condition: ModelAdminUserCalculationsConditionInput
  ) {
    createAdminUserCalculations(input: $input, condition: $condition) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminUserCalculations = /* GraphQL */ `
  mutation UpdateAdminUserCalculations(
    $input: UpdateAdminUserCalculationsInput!
    $condition: ModelAdminUserCalculationsConditionInput
  ) {
    updateAdminUserCalculations(input: $input, condition: $condition) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminUserCalculations = /* GraphQL */ `
  mutation DeleteAdminUserCalculations(
    $input: DeleteAdminUserCalculationsInput!
    $condition: ModelAdminUserCalculationsConditionInput
  ) {
    deleteAdminUserCalculations(input: $input, condition: $condition) {
      id
      loan
      term
      monthly
      ftb
      income
      htbs
      rebate
      fhs
      cc
      type
      shared
      price
      source
      applied
      ber
      sessionID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminSystemToDo = /* GraphQL */ `
  mutation CreateAdminSystemToDo(
    $input: CreateAdminSystemToDoInput!
    $condition: ModelAdminSystemToDoConditionInput
  ) {
    createAdminSystemToDo(input: $input, condition: $condition) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminSystemToDo = /* GraphQL */ `
  mutation UpdateAdminSystemToDo(
    $input: UpdateAdminSystemToDoInput!
    $condition: ModelAdminSystemToDoConditionInput
  ) {
    updateAdminSystemToDo(input: $input, condition: $condition) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminSystemToDo = /* GraphQL */ `
  mutation DeleteAdminSystemToDo(
    $input: DeleteAdminSystemToDoInput!
    $condition: ModelAdminSystemToDoConditionInput
  ) {
    deleteAdminSystemToDo(input: $input, condition: $condition) {
      id
      text
      author
      target
      completed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminTextractedStatement = /* GraphQL */ `
  mutation CreateAdminTextractedStatement(
    $input: CreateAdminTextractedStatementInput!
    $condition: ModelAdminTextractedStatementConditionInput
  ) {
    createAdminTextractedStatement(input: $input, condition: $condition) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminTextractedStatement = /* GraphQL */ `
  mutation UpdateAdminTextractedStatement(
    $input: UpdateAdminTextractedStatementInput!
    $condition: ModelAdminTextractedStatementConditionInput
  ) {
    updateAdminTextractedStatement(input: $input, condition: $condition) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminTextractedStatement = /* GraphQL */ `
  mutation DeleteAdminTextractedStatement(
    $input: DeleteAdminTextractedStatementInput!
    $condition: ModelAdminTextractedStatementConditionInput
  ) {
    deleteAdminTextractedStatement(input: $input, condition: $condition) {
      id
      bucket
      key
      forms
      tables
      formArray
      tableArray
      mortgageID
      inProgress
      jobID
      uploadID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageAccountSettings = /* GraphQL */ `
  mutation CreateAdminMortgageAccountSettings(
    $input: CreateAdminMortgageAccountSettingsInput!
    $condition: ModelAdminMortgageAccountSettingsConditionInput
  ) {
    createAdminMortgageAccountSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageAccountSettings = /* GraphQL */ `
  mutation UpdateAdminMortgageAccountSettings(
    $input: UpdateAdminMortgageAccountSettingsInput!
    $condition: ModelAdminMortgageAccountSettingsConditionInput
  ) {
    updateAdminMortgageAccountSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageAccountSettings = /* GraphQL */ `
  mutation DeleteAdminMortgageAccountSettings(
    $input: DeleteAdminMortgageAccountSettingsInput!
    $condition: ModelAdminMortgageAccountSettingsConditionInput
  ) {
    deleteAdminMortgageAccountSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageTasks = /* GraphQL */ `
  mutation CreateAdminMortgageTasks(
    $input: CreateAdminMortgageTasksInput!
    $condition: ModelAdminMortgageTasksConditionInput
  ) {
    createAdminMortgageTasks(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageTasks = /* GraphQL */ `
  mutation UpdateAdminMortgageTasks(
    $input: UpdateAdminMortgageTasksInput!
    $condition: ModelAdminMortgageTasksConditionInput
  ) {
    updateAdminMortgageTasks(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageTasks = /* GraphQL */ `
  mutation DeleteAdminMortgageTasks(
    $input: DeleteAdminMortgageTasksInput!
    $condition: ModelAdminMortgageTasksConditionInput
  ) {
    deleteAdminMortgageTasks(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      due
      deleted
      target
      targetStarted
      targetLastActive
      completedOn
      completedBy
      paused
      closedOn
      closedBy
      folder
      userFolder
      userSortPosition
      tags
      currentOwner
      lastMessageBy
      lastMessageOn
      lastMessage
      toDos {
        items {
          id
          taskID
          completedOn
          completedBy
          toDoItem
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      pauses
      components {
        items {
          id
          taskID
          component
          author
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageTaskComponent = /* GraphQL */ `
  mutation CreateAdminMortgageTaskComponent(
    $input: CreateAdminMortgageTaskComponentInput!
    $condition: ModelAdminMortgageTaskComponentConditionInput
  ) {
    createAdminMortgageTaskComponent(input: $input, condition: $condition) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageTaskComponent = /* GraphQL */ `
  mutation UpdateAdminMortgageTaskComponent(
    $input: UpdateAdminMortgageTaskComponentInput!
    $condition: ModelAdminMortgageTaskComponentConditionInput
  ) {
    updateAdminMortgageTaskComponent(input: $input, condition: $condition) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageTaskComponent = /* GraphQL */ `
  mutation DeleteAdminMortgageTaskComponent(
    $input: DeleteAdminMortgageTaskComponentInput!
    $condition: ModelAdminMortgageTaskComponentConditionInput
  ) {
    deleteAdminMortgageTaskComponent(input: $input, condition: $condition) {
      id
      taskID
      component
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageTaskToDo = /* GraphQL */ `
  mutation CreateAdminMortgageTaskToDo(
    $input: CreateAdminMortgageTaskToDoInput!
    $condition: ModelAdminMortgageTaskToDoConditionInput
  ) {
    createAdminMortgageTaskToDo(input: $input, condition: $condition) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageTaskToDo = /* GraphQL */ `
  mutation UpdateAdminMortgageTaskToDo(
    $input: UpdateAdminMortgageTaskToDoInput!
    $condition: ModelAdminMortgageTaskToDoConditionInput
  ) {
    updateAdminMortgageTaskToDo(input: $input, condition: $condition) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageTaskToDo = /* GraphQL */ `
  mutation DeleteAdminMortgageTaskToDo(
    $input: DeleteAdminMortgageTaskToDoInput!
    $condition: ModelAdminMortgageTaskToDoConditionInput
  ) {
    deleteAdminMortgageTaskToDo(input: $input, condition: $condition) {
      id
      taskID
      completedOn
      completedBy
      toDoItem
      author
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageNotes = /* GraphQL */ `
  mutation CreateAdminMortgageNotes(
    $input: CreateAdminMortgageNotesInput!
    $condition: ModelAdminMortgageNotesConditionInput
  ) {
    createAdminMortgageNotes(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageNotes = /* GraphQL */ `
  mutation UpdateAdminMortgageNotes(
    $input: UpdateAdminMortgageNotesInput!
    $condition: ModelAdminMortgageNotesConditionInput
  ) {
    updateAdminMortgageNotes(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageNotes = /* GraphQL */ `
  mutation DeleteAdminMortgageNotes(
    $input: DeleteAdminMortgageNotesInput!
    $condition: ModelAdminMortgageNotesConditionInput
  ) {
    deleteAdminMortgageNotes(input: $input, condition: $condition) {
      id
      body
      author
      pinned
      deleted
      usermortgageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageStageTask = /* GraphQL */ `
  mutation CreateAdminMortgageStageTask(
    $input: CreateAdminMortgageStageTaskInput!
    $condition: ModelAdminMortgageStageTaskConditionInput
  ) {
    createAdminMortgageStageTask(input: $input, condition: $condition) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageStageTask = /* GraphQL */ `
  mutation UpdateAdminMortgageStageTask(
    $input: UpdateAdminMortgageStageTaskInput!
    $condition: ModelAdminMortgageStageTaskConditionInput
  ) {
    updateAdminMortgageStageTask(input: $input, condition: $condition) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageStageTask = /* GraphQL */ `
  mutation DeleteAdminMortgageStageTask(
    $input: DeleteAdminMortgageStageTaskInput!
    $condition: ModelAdminMortgageStageTaskConditionInput
  ) {
    deleteAdminMortgageStageTask(input: $input, condition: $condition) {
      id
      mortgageID
      stage
      name
      deleted
      due
      completedOn
      completedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageStageNotifications = /* GraphQL */ `
  mutation CreateAdminMortgageStageNotifications(
    $input: CreateAdminMortgageStageNotificationsInput!
    $condition: ModelAdminMortgageStageNotificationsConditionInput
  ) {
    createAdminMortgageStageNotifications(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageStageNotifications = /* GraphQL */ `
  mutation UpdateAdminMortgageStageNotifications(
    $input: UpdateAdminMortgageStageNotificationsInput!
    $condition: ModelAdminMortgageStageNotificationsConditionInput
  ) {
    updateAdminMortgageStageNotifications(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageStageNotifications = /* GraphQL */ `
  mutation DeleteAdminMortgageStageNotifications(
    $input: DeleteAdminMortgageStageNotificationsInput!
    $condition: ModelAdminMortgageStageNotificationsConditionInput
  ) {
    deleteAdminMortgageStageNotifications(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      stage
      name
      history {
        items {
          id
          sentTime
          sentBy
          data
          notificationId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageStageNotified = /* GraphQL */ `
  mutation CreateAdminMortgageStageNotified(
    $input: CreateAdminMortgageStageNotifiedInput!
    $condition: ModelAdminMortgageStageNotifiedConditionInput
  ) {
    createAdminMortgageStageNotified(input: $input, condition: $condition) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageStageNotified = /* GraphQL */ `
  mutation UpdateAdminMortgageStageNotified(
    $input: UpdateAdminMortgageStageNotifiedInput!
    $condition: ModelAdminMortgageStageNotifiedConditionInput
  ) {
    updateAdminMortgageStageNotified(input: $input, condition: $condition) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageStageNotified = /* GraphQL */ `
  mutation DeleteAdminMortgageStageNotified(
    $input: DeleteAdminMortgageStageNotifiedInput!
    $condition: ModelAdminMortgageStageNotifiedConditionInput
  ) {
    deleteAdminMortgageStageNotified(input: $input, condition: $condition) {
      id
      sentTime
      sentBy
      data
      notificationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageDocumentContent = /* GraphQL */ `
  mutation CreateAdminMortgageDocumentContent(
    $input: CreateAdminMortgageDocumentContentInput!
    $condition: ModelAdminMortgageDocumentContentConditionInput
  ) {
    createAdminMortgageDocumentContent(input: $input, condition: $condition) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageDocumentContent = /* GraphQL */ `
  mutation UpdateAdminMortgageDocumentContent(
    $input: UpdateAdminMortgageDocumentContentInput!
    $condition: ModelAdminMortgageDocumentContentConditionInput
  ) {
    updateAdminMortgageDocumentContent(input: $input, condition: $condition) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageDocumentContent = /* GraphQL */ `
  mutation DeleteAdminMortgageDocumentContent(
    $input: DeleteAdminMortgageDocumentContentInput!
    $condition: ModelAdminMortgageDocumentContentConditionInput
  ) {
    deleteAdminMortgageDocumentContent(input: $input, condition: $condition) {
      id
      mortgageID
      paragraph
      folder
      document
      sequence
      html
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageSettings = /* GraphQL */ `
  mutation CreateAdminMortgageSettings(
    $input: CreateAdminMortgageSettingsInput!
    $condition: ModelAdminMortgageSettingsConditionInput
  ) {
    createAdminMortgageSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageSettings = /* GraphQL */ `
  mutation UpdateAdminMortgageSettings(
    $input: UpdateAdminMortgageSettingsInput!
    $condition: ModelAdminMortgageSettingsConditionInput
  ) {
    updateAdminMortgageSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageSettings = /* GraphQL */ `
  mutation DeleteAdminMortgageSettings(
    $input: DeleteAdminMortgageSettingsInput!
    $condition: ModelAdminMortgageSettingsConditionInput
  ) {
    deleteAdminMortgageSettings(input: $input, condition: $condition) {
      id
      initialContactOn
      initialContactBy
      mortgageID
      contactTimes
      markedLead
      markedLeadBy
      markedSetupDone
      markedSetupDoneBy
      markedApplyDone
      markedApplyDoneBy
      markedComplete
      markedCompleteBy
      markedPreApproved
      markedPreApprovedBy
      markedApproved
      markedApprovedBy
      markedDocsSigned
      markedDocsSignedBy
      markedSubmitted
      markedSubmittedBy
      markedSuspended
      markedSuspendedBy
      offlineLead
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminLastViewedMortgage = /* GraphQL */ `
  mutation CreateAdminLastViewedMortgage(
    $input: CreateAdminLastViewedMortgageInput!
    $condition: ModelAdminLastViewedMortgageConditionInput
  ) {
    createAdminLastViewedMortgage(input: $input, condition: $condition) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminLastViewedMortgage = /* GraphQL */ `
  mutation UpdateAdminLastViewedMortgage(
    $input: UpdateAdminLastViewedMortgageInput!
    $condition: ModelAdminLastViewedMortgageConditionInput
  ) {
    updateAdminLastViewedMortgage(input: $input, condition: $condition) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminLastViewedMortgage = /* GraphQL */ `
  mutation DeleteAdminLastViewedMortgage(
    $input: DeleteAdminLastViewedMortgageInput!
    $condition: ModelAdminLastViewedMortgageConditionInput
  ) {
    deleteAdminLastViewedMortgage(input: $input, condition: $condition) {
      id
      mortgageID
      lastViewedOn
      lastViewedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageLenderTest = /* GraphQL */ `
  mutation CreateAdminMortgageLenderTest(
    $input: CreateAdminMortgageLenderTestInput!
    $condition: ModelAdminMortgageLenderTestConditionInput
  ) {
    createAdminMortgageLenderTest(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageLenderTest = /* GraphQL */ `
  mutation UpdateAdminMortgageLenderTest(
    $input: UpdateAdminMortgageLenderTestInput!
    $condition: ModelAdminMortgageLenderTestConditionInput
  ) {
    updateAdminMortgageLenderTest(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageLenderTest = /* GraphQL */ `
  mutation DeleteAdminMortgageLenderTest(
    $input: DeleteAdminMortgageLenderTestInput!
    $condition: ModelAdminMortgageLenderTestConditionInput
  ) {
    deleteAdminMortgageLenderTest(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  mutation CreateAdminMortgageLenderTestPlaygroundTest(
    $input: CreateAdminMortgageLenderTestPlaygroundTestInput!
    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
  ) {
    createAdminMortgageLenderTestPlaygroundTest(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  mutation UpdateAdminMortgageLenderTestPlaygroundTest(
    $input: UpdateAdminMortgageLenderTestPlaygroundTestInput!
    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
  ) {
    updateAdminMortgageLenderTestPlaygroundTest(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageLenderTestPlaygroundTest = /* GraphQL */ `
  mutation DeleteAdminMortgageLenderTestPlaygroundTest(
    $input: DeleteAdminMortgageLenderTestPlaygroundTestInput!
    $condition: ModelAdminMortgageLenderTestPlaygroundTestConditionInput
  ) {
    deleteAdminMortgageLenderTestPlaygroundTest(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      results
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  mutation CreateAdminMortgageUploadStatementBasicInfo(
    $input: CreateAdminMortgageUploadStatementBasicInfoInput!
    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
  ) {
    createAdminMortgageUploadStatementBasicInfo(
      input: $input
      condition: $condition
    ) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  mutation UpdateAdminMortgageUploadStatementBasicInfo(
    $input: UpdateAdminMortgageUploadStatementBasicInfoInput!
    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
  ) {
    updateAdminMortgageUploadStatementBasicInfo(
      input: $input
      condition: $condition
    ) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageUploadStatementBasicInfo = /* GraphQL */ `
  mutation DeleteAdminMortgageUploadStatementBasicInfo(
    $input: DeleteAdminMortgageUploadStatementBasicInfoInput!
    $condition: ModelAdminMortgageUploadStatementBasicInfoConditionInput
  ) {
    deleteAdminMortgageUploadStatementBasicInfo(
      input: $input
      condition: $condition
    ) {
      id
      institution
      accountType
      accountName
      accountAddress
      friendlyName
      iban
      omit
      numberOfPages
      openingBalance
      closingBalance
      currency
      foreign
      startDate
      endDate
      documentDate
      verifiedData
      error
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageSectionVerification = /* GraphQL */ `
  mutation CreateAdminMortgageSectionVerification(
    $input: CreateAdminMortgageSectionVerificationInput!
    $condition: ModelAdminMortgageSectionVerificationConditionInput
  ) {
    createAdminMortgageSectionVerification(
      input: $input
      condition: $condition
    ) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageSectionVerification = /* GraphQL */ `
  mutation UpdateAdminMortgageSectionVerification(
    $input: UpdateAdminMortgageSectionVerificationInput!
    $condition: ModelAdminMortgageSectionVerificationConditionInput
  ) {
    updateAdminMortgageSectionVerification(
      input: $input
      condition: $condition
    ) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageSectionVerification = /* GraphQL */ `
  mutation DeleteAdminMortgageSectionVerification(
    $input: DeleteAdminMortgageSectionVerificationInput!
    $condition: ModelAdminMortgageSectionVerificationConditionInput
  ) {
    deleteAdminMortgageSectionVerification(
      input: $input
      condition: $condition
    ) {
      id
      progressID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  mutation CreateAdminMortgageUploadVerifiedPoint(
    $input: CreateAdminMortgageUploadVerifiedPointInput!
    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
  ) {
    createAdminMortgageUploadVerifiedPoint(
      input: $input
      condition: $condition
    ) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  mutation UpdateAdminMortgageUploadVerifiedPoint(
    $input: UpdateAdminMortgageUploadVerifiedPointInput!
    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
  ) {
    updateAdminMortgageUploadVerifiedPoint(
      input: $input
      condition: $condition
    ) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageUploadVerifiedPoint = /* GraphQL */ `
  mutation DeleteAdminMortgageUploadVerifiedPoint(
    $input: DeleteAdminMortgageUploadVerifiedPointInput!
    $condition: ModelAdminMortgageUploadVerifiedPointConditionInput
  ) {
    deleteAdminMortgageUploadVerifiedPoint(
      input: $input
      condition: $condition
    ) {
      id
      uploadID
      mortgageID
      name
      verifiedValue
      verifiedValueType
      verificationOn
      verificationBy
      verificationNotes {
        items {
          id
          verificationID
          authorID
          string
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      supervisorCheckRequired
      supervisorCheckOn
      supervisorCheckBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageSectionVerificationNote = /* GraphQL */ `
  mutation CreateAdminMortgageSectionVerificationNote(
    $input: CreateAdminMortgageSectionVerificationNoteInput!
    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
  ) {
    createAdminMortgageSectionVerificationNote(
      input: $input
      condition: $condition
    ) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageSectionVerificationNote = /* GraphQL */ `
  mutation UpdateAdminMortgageSectionVerificationNote(
    $input: UpdateAdminMortgageSectionVerificationNoteInput!
    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
  ) {
    updateAdminMortgageSectionVerificationNote(
      input: $input
      condition: $condition
    ) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageSectionVerificationNote = /* GraphQL */ `
  mutation DeleteAdminMortgageSectionVerificationNote(
    $input: DeleteAdminMortgageSectionVerificationNoteInput!
    $condition: ModelAdminMortgageSectionVerificationNoteConditionInput
  ) {
    deleteAdminMortgageSectionVerificationNote(
      input: $input
      condition: $condition
    ) {
      id
      verificationID
      authorID
      string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageSubmission = /* GraphQL */ `
  mutation CreateAdminMortgageSubmission(
    $input: CreateAdminMortgageSubmissionInput!
    $condition: ModelAdminMortgageSubmissionConditionInput
  ) {
    createAdminMortgageSubmission(input: $input, condition: $condition) {
      id
      mortgageID
      provisionalXlsInput
      provisionalXlsOutput
      finalXlsInput
      finalXlsOutput
      lenderRecommendation
      productRecommendation
      termRecommendation
      secondaryProduct
      secondaryRate
      invalidatedReason
      aipNotes
      loanOfferNotes
      completedFlowItemsForAdmin
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageSubmission = /* GraphQL */ `
  mutation UpdateAdminMortgageSubmission(
    $input: UpdateAdminMortgageSubmissionInput!
    $condition: ModelAdminMortgageSubmissionConditionInput
  ) {
    updateAdminMortgageSubmission(input: $input, condition: $condition) {
      id
      mortgageID
      provisionalXlsInput
      provisionalXlsOutput
      finalXlsInput
      finalXlsOutput
      lenderRecommendation
      productRecommendation
      termRecommendation
      secondaryProduct
      secondaryRate
      invalidatedReason
      aipNotes
      loanOfferNotes
      completedFlowItemsForAdmin
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageSubmission = /* GraphQL */ `
  mutation DeleteAdminMortgageSubmission(
    $input: DeleteAdminMortgageSubmissionInput!
    $condition: ModelAdminMortgageSubmissionConditionInput
  ) {
    deleteAdminMortgageSubmission(input: $input, condition: $condition) {
      id
      mortgageID
      provisionalXlsInput
      provisionalXlsOutput
      finalXlsInput
      finalXlsOutput
      lenderRecommendation
      productRecommendation
      termRecommendation
      secondaryProduct
      secondaryRate
      invalidatedReason
      aipNotes
      loanOfferNotes
      completedFlowItemsForAdmin
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageSubmissionIssue = /* GraphQL */ `
  mutation CreateAdminMortgageSubmissionIssue(
    $input: CreateAdminMortgageSubmissionIssueInput!
    $condition: ModelAdminMortgageSubmissionIssueConditionInput
  ) {
    createAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageSubmissionIssue = /* GraphQL */ `
  mutation UpdateAdminMortgageSubmissionIssue(
    $input: UpdateAdminMortgageSubmissionIssueInput!
    $condition: ModelAdminMortgageSubmissionIssueConditionInput
  ) {
    updateAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageSubmissionIssue = /* GraphQL */ `
  mutation DeleteAdminMortgageSubmissionIssue(
    $input: DeleteAdminMortgageSubmissionIssueInput!
    $condition: ModelAdminMortgageSubmissionIssueConditionInput
  ) {
    deleteAdminMortgageSubmissionIssue(input: $input, condition: $condition) {
      id
      submissionID
      emailID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAdminMortgageProtectionInsurance = /* GraphQL */ `
  mutation CreateAdminMortgageProtectionInsurance(
    $input: CreateAdminMortgageProtectionInsuranceInput!
    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
  ) {
    createAdminMortgageProtectionInsurance(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAdminMortgageProtectionInsurance = /* GraphQL */ `
  mutation UpdateAdminMortgageProtectionInsurance(
    $input: UpdateAdminMortgageProtectionInsuranceInput!
    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
  ) {
    updateAdminMortgageProtectionInsurance(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAdminMortgageProtectionInsurance = /* GraphQL */ `
  mutation DeleteAdminMortgageProtectionInsurance(
    $input: DeleteAdminMortgageProtectionInsuranceInput!
    $condition: ModelAdminMortgageProtectionInsuranceConditionInput
  ) {
    deleteAdminMortgageProtectionInsurance(
      input: $input
      condition: $condition
    ) {
      id
      mortgageID
      provider
      quoteGenerated
      quoteGeneratedOn
      quoteGeneratedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserMortgageLenderTestQueue = /* GraphQL */ `
  mutation CreateUserMortgageLenderTestQueue(
    $input: CreateUserMortgageLenderTestQueueInput!
    $condition: ModelUserMortgageLenderTestQueueConditionInput
  ) {
    createUserMortgageLenderTestQueue(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      messageID
      data
      input
      output
      passed
      variableUsed
      processing
      completed
      processingStarted
      processingCompleted
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageLenderTestQueue = /* GraphQL */ `
  mutation UpdateUserMortgageLenderTestQueue(
    $input: UpdateUserMortgageLenderTestQueueInput!
    $condition: ModelUserMortgageLenderTestQueueConditionInput
  ) {
    updateUserMortgageLenderTestQueue(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      messageID
      data
      input
      output
      passed
      variableUsed
      processing
      completed
      processingStarted
      processingCompleted
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageLenderTestQueue = /* GraphQL */ `
  mutation DeleteUserMortgageLenderTestQueue(
    $input: DeleteUserMortgageLenderTestQueueInput!
    $condition: ModelUserMortgageLenderTestQueueConditionInput
  ) {
    deleteUserMortgageLenderTestQueue(input: $input, condition: $condition) {
      id
      lenderCode
      mortgageID
      messageID
      data
      input
      output
      passed
      variableUsed
      processing
      completed
      processingStarted
      processingCompleted
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserPdfId = /* GraphQL */ `
  mutation CreateUserPdfId(
    $input: CreateUserPdfIdInput!
    $condition: ModelUserPdfIdConditionInput
  ) {
    createUserPdfId(input: $input, condition: $condition) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUserPdfId = /* GraphQL */ `
  mutation UpdateUserPdfId(
    $input: UpdateUserPdfIdInput!
    $condition: ModelUserPdfIdConditionInput
  ) {
    updateUserPdfId(input: $input, condition: $condition) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUserPdfId = /* GraphQL */ `
  mutation DeleteUserPdfId(
    $input: DeleteUserPdfIdInput!
    $condition: ModelUserPdfIdConditionInput
  ) {
    deleteUserPdfId(input: $input, condition: $condition) {
      id
      pdfType
      institution
      friendlyName
      subType
      data
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUserMortgageAppointment = /* GraphQL */ `
  mutation CreateUserMortgageAppointment(
    $input: CreateUserMortgageAppointmentInput!
    $condition: ModelUserMortgageAppointmentConditionInput
  ) {
    createUserMortgageAppointment(input: $input, condition: $condition) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageAppointment = /* GraphQL */ `
  mutation UpdateUserMortgageAppointment(
    $input: UpdateUserMortgageAppointmentInput!
    $condition: ModelUserMortgageAppointmentConditionInput
  ) {
    updateUserMortgageAppointment(input: $input, condition: $condition) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageAppointment = /* GraphQL */ `
  mutation DeleteUserMortgageAppointment(
    $input: DeleteUserMortgageAppointmentInput!
    $condition: ModelUserMortgageAppointmentConditionInput
  ) {
    deleteUserMortgageAppointment(input: $input, condition: $condition) {
      id
      backendUserID
      appointmentType
      expired
      appointmentDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageSubmission = /* GraphQL */ `
  mutation CreateUserMortgageSubmission(
    $input: CreateUserMortgageSubmissionInput!
    $condition: ModelUserMortgageSubmissionConditionInput
  ) {
    createUserMortgageSubmission(input: $input, condition: $condition) {
      id
      lenderCode
      provisionalProduct
      provisionalRateId
      provisionalRate
      provisionalTerm
      provisionalLoan
      provisionalValue
      provisionalSecondaryProduct
      provisionalSecondaryRate
      provisionalSecondaryRateId
      provisionalXlsInput
      provisionalXlsOutput
      finalProduct
      finalRate
      finalRateId
      finalTerm
      finalLoan
      finalValue
      finalSecondaryProduct
      finalSecondaryRate
      finalSecondaryRateId
      finalXlsInput
      finalXlsOutput
      soft
      aipEmailId
      aipMaxLoan
      aipPropertyReference
      aipExpiryDate
      aipDate
      aipNotes
      aipSettings
      loanOfferEmailId
      loanOfferDate
      loanOfferExpiryDate
      loanOfferNotes
      loanOfferSettings
      drawdownDate
      completedFlowItems
      completedFlowItemsForAdmin
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      valuation2ReinstatementValue
      reinstatement2Value
      valuation2Value
      hasValuation2Issues
      valuation2Issues
      surveyRequired
      surveyDate
      lenderRecommendation
      productRecommendation
      termRecommendation
      mortgageAccountNo
      invalidatedReason
      submissionStage
      documents {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageSubmission = /* GraphQL */ `
  mutation UpdateUserMortgageSubmission(
    $input: UpdateUserMortgageSubmissionInput!
    $condition: ModelUserMortgageSubmissionConditionInput
  ) {
    updateUserMortgageSubmission(input: $input, condition: $condition) {
      id
      lenderCode
      provisionalProduct
      provisionalRateId
      provisionalRate
      provisionalTerm
      provisionalLoan
      provisionalValue
      provisionalSecondaryProduct
      provisionalSecondaryRate
      provisionalSecondaryRateId
      provisionalXlsInput
      provisionalXlsOutput
      finalProduct
      finalRate
      finalRateId
      finalTerm
      finalLoan
      finalValue
      finalSecondaryProduct
      finalSecondaryRate
      finalSecondaryRateId
      finalXlsInput
      finalXlsOutput
      soft
      aipEmailId
      aipMaxLoan
      aipPropertyReference
      aipExpiryDate
      aipDate
      aipNotes
      aipSettings
      loanOfferEmailId
      loanOfferDate
      loanOfferExpiryDate
      loanOfferNotes
      loanOfferSettings
      drawdownDate
      completedFlowItems
      completedFlowItemsForAdmin
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      valuation2ReinstatementValue
      reinstatement2Value
      valuation2Value
      hasValuation2Issues
      valuation2Issues
      surveyRequired
      surveyDate
      lenderRecommendation
      productRecommendation
      termRecommendation
      mortgageAccountNo
      invalidatedReason
      submissionStage
      documents {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageSubmission = /* GraphQL */ `
  mutation DeleteUserMortgageSubmission(
    $input: DeleteUserMortgageSubmissionInput!
    $condition: ModelUserMortgageSubmissionConditionInput
  ) {
    deleteUserMortgageSubmission(input: $input, condition: $condition) {
      id
      lenderCode
      provisionalProduct
      provisionalRateId
      provisionalRate
      provisionalTerm
      provisionalLoan
      provisionalValue
      provisionalSecondaryProduct
      provisionalSecondaryRate
      provisionalSecondaryRateId
      provisionalXlsInput
      provisionalXlsOutput
      finalProduct
      finalRate
      finalRateId
      finalTerm
      finalLoan
      finalValue
      finalSecondaryProduct
      finalSecondaryRate
      finalSecondaryRateId
      finalXlsInput
      finalXlsOutput
      soft
      aipEmailId
      aipMaxLoan
      aipPropertyReference
      aipExpiryDate
      aipDate
      aipNotes
      aipSettings
      loanOfferEmailId
      loanOfferDate
      loanOfferExpiryDate
      loanOfferNotes
      loanOfferSettings
      drawdownDate
      completedFlowItems
      completedFlowItemsForAdmin
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      valuation2ReinstatementValue
      reinstatement2Value
      valuation2Value
      hasValuation2Issues
      valuation2Issues
      surveyRequired
      surveyDate
      lenderRecommendation
      productRecommendation
      termRecommendation
      mortgageAccountNo
      invalidatedReason
      submissionStage
      documents {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      issues {
        items {
          id
          submissionID
          emailID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageQuery = /* GraphQL */ `
  mutation CreateUserMortgageQuery(
    $input: CreateUserMortgageQueryInput!
    $condition: ModelUserMortgageQueryConditionInput
  ) {
    createUserMortgageQuery(input: $input, condition: $condition) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageQuery = /* GraphQL */ `
  mutation UpdateUserMortgageQuery(
    $input: UpdateUserMortgageQueryInput!
    $condition: ModelUserMortgageQueryConditionInput
  ) {
    updateUserMortgageQuery(input: $input, condition: $condition) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageQuery = /* GraphQL */ `
  mutation DeleteUserMortgageQuery(
    $input: DeleteUserMortgageQueryInput!
    $condition: ModelUserMortgageQueryConditionInput
  ) {
    deleteUserMortgageQuery(input: $input, condition: $condition) {
      id
      mortgageID
      emailReplyID
      createdBy
      referenceType
      referenceId
      stage
      section
      index
      title
      date
      description
      amount
      query
      userAnswer
      userAnswerOn
      userAnswerBy
      userAnswered
      adminAnswered
      lenderAnswer
      lenderAnswerOn
      lenderAnswerBy
      excludedOn
      excludedBy
      private
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgage = /* GraphQL */ `
  mutation CreateUserMortgage(
    $input: CreateUserMortgageInput!
    $condition: ModelUserMortgageConditionInput
  ) {
    createUserMortgage(input: $input, condition: $condition) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          propertyAge
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          isClosed
          isNewAccount
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          pdffed
          seenOn
          seenBy
          omit
          documentDate
          exclude
          examined
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          lastDocumentDateDismissed
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          viewState
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      completedFlowItemsForAdmin
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      submissions {
        items {
          id
          lenderCode
          provisionalProduct
          provisionalRateId
          provisionalRate
          provisionalTerm
          provisionalLoan
          provisionalValue
          provisionalSecondaryProduct
          provisionalSecondaryRate
          provisionalSecondaryRateId
          provisionalXlsInput
          provisionalXlsOutput
          finalProduct
          finalRate
          finalRateId
          finalTerm
          finalLoan
          finalValue
          finalSecondaryProduct
          finalSecondaryRate
          finalSecondaryRateId
          finalXlsInput
          finalXlsOutput
          soft
          aipEmailId
          aipMaxLoan
          aipPropertyReference
          aipExpiryDate
          aipDate
          aipNotes
          aipSettings
          loanOfferEmailId
          loanOfferDate
          loanOfferExpiryDate
          loanOfferNotes
          loanOfferSettings
          drawdownDate
          completedFlowItems
          completedFlowItemsForAdmin
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          valuation2ReinstatementValue
          reinstatement2Value
          valuation2Value
          hasValuation2Issues
          valuation2Issues
          surveyRequired
          surveyDate
          lenderRecommendation
          productRecommendation
          termRecommendation
          mortgageAccountNo
          invalidatedReason
          submissionStage
          documents {
            items {
              id
              title
              description
              identityID
              identityID2
              folder
              fileName
              fileExtension
              isDynamic
              name
              stage
              private
              submissionID
              mortgageID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminSubmissions {
        items {
          id
          mortgageID
          provisionalXlsInput
          provisionalXlsOutput
          finalXlsInput
          finalXlsOutput
          lenderRecommendation
          productRecommendation
          termRecommendation
          secondaryProduct
          secondaryRate
          invalidatedReason
          aipNotes
          loanOfferNotes
          completedFlowItemsForAdmin
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      uploadsPercentSetup
      uploadsPercentApply
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const updateUserMortgage = /* GraphQL */ `
  mutation UpdateUserMortgage(
    $input: UpdateUserMortgageInput!
    $condition: ModelUserMortgageConditionInput
  ) {
    updateUserMortgage(input: $input, condition: $condition) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          propertyAge
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          isClosed
          isNewAccount
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          pdffed
          seenOn
          seenBy
          omit
          documentDate
          exclude
          examined
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          lastDocumentDateDismissed
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          viewState
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      completedFlowItemsForAdmin
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      submissions {
        items {
          id
          lenderCode
          provisionalProduct
          provisionalRateId
          provisionalRate
          provisionalTerm
          provisionalLoan
          provisionalValue
          provisionalSecondaryProduct
          provisionalSecondaryRate
          provisionalSecondaryRateId
          provisionalXlsInput
          provisionalXlsOutput
          finalProduct
          finalRate
          finalRateId
          finalTerm
          finalLoan
          finalValue
          finalSecondaryProduct
          finalSecondaryRate
          finalSecondaryRateId
          finalXlsInput
          finalXlsOutput
          soft
          aipEmailId
          aipMaxLoan
          aipPropertyReference
          aipExpiryDate
          aipDate
          aipNotes
          aipSettings
          loanOfferEmailId
          loanOfferDate
          loanOfferExpiryDate
          loanOfferNotes
          loanOfferSettings
          drawdownDate
          completedFlowItems
          completedFlowItemsForAdmin
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          valuation2ReinstatementValue
          reinstatement2Value
          valuation2Value
          hasValuation2Issues
          valuation2Issues
          surveyRequired
          surveyDate
          lenderRecommendation
          productRecommendation
          termRecommendation
          mortgageAccountNo
          invalidatedReason
          submissionStage
          documents {
            items {
              id
              title
              description
              identityID
              identityID2
              folder
              fileName
              fileExtension
              isDynamic
              name
              stage
              private
              submissionID
              mortgageID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminSubmissions {
        items {
          id
          mortgageID
          provisionalXlsInput
          provisionalXlsOutput
          finalXlsInput
          finalXlsOutput
          lenderRecommendation
          productRecommendation
          termRecommendation
          secondaryProduct
          secondaryRate
          invalidatedReason
          aipNotes
          loanOfferNotes
          completedFlowItemsForAdmin
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      uploadsPercentSetup
      uploadsPercentApply
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const deleteUserMortgage = /* GraphQL */ `
  mutation DeleteUserMortgage(
    $input: DeleteUserMortgageInput!
    $condition: ModelUserMortgageConditionInput
  ) {
    deleteUserMortgage(input: $input, condition: $condition) {
      id
      twoApplicants
      mortgageType
      applicationType
      subs
      data
      activeAgent
      playgroundTests {
        items {
          id
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      appointments {
        items {
          id
          backendUserID
          appointmentType
          expired
          appointmentDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      replyRequests {
        items {
          id
          receivedOn
          mortgageId
          emailId
          replyTo
          requestType
          requestBody
          itemsLoaded
          noteBody
          assocEmails
          queries {
            items {
              id
              mortgageID
              emailReplyID
              createdBy
              referenceType
              referenceId
              stage
              section
              index
              title
              date
              description
              amount
              query
              userAnswer
              userAnswerOn
              userAnswerBy
              userAnswered
              adminAnswered
              lenderAnswer
              lenderAnswerOn
              lenderAnswerBy
              excludedOn
              excludedBy
              private
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          tasks {
            items {
              id
              sectionStage
              sectionType
              sectionName
              sectionIndex
              sectionTitle
              sectionApplicant
              sectionInstructions
              mortgageID
              emailReplyID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          repliedBy
          repliedOn
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1ID
      applicant2ID
      lenderTests {
        items {
          id
          lenderCode
          mortgageID
          results
          data
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      requirement {
        id
        applicationElsewhere
        loanRequired
        cohabiting
        loanTerm
        rateId
        rate
        monthlyPayment
        finalRate
        finalTerm
        firstTimeBuyers
        combinedDependants
        combinedGrossEarnings
        combinedLoanRepayments
        depositSavings
        depositGifts
        depositPaid
        helpToBuyScheme
        helpToBuySchemeAmount
        helpToBuySchemeAccessCode
        helpToBuySchemeApplicationCode
        helpToBuySchemeDeveloperName
        firstHomeScheme
        firstHomeSchemeCouncil
        firstHomeSchemeType
        firstHomeSchemeAmount
        lenderId
        lender {
          id
          lender
          address
          approverFirstName
          approverSurname
          approverEmail
          approverPhone
          lenderCode
          rates {
            items {
              id
              lenderID
              rate
              maxLTV
              term
              discount
              minimum
              maximum
              lenderCode
              promotions
              conditions
              forBorrowerTypes
              minBER
              buyToLet
              product
              interestOnly
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          minimumLoan
          minimumTerm
          maximumLoan
          maximumTerm
          onlineAccount
          overlayTemplates {
            items {
              id
              lender
              lenderCode
              stage
              fileName
              templateType
              mortgageType
              companyFolder
              documentNotes
              archive
              bucketKey
              clientSigns
              docusign
              defaultFolder
              defaultPrivate
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          files {
            items {
              id
              lender
              folder
              description
              fileName
              bucketKey
              contentType
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          defaultConditions
          defaultPromotions
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        lenderCode
        lenderProduct
        contactMethod
        proposals
        soft
        drawdownDate
        aipExpiryDate
        aipDate
        loanOfferDate
        loanOfferExpiryDate
        ddBankAccountID
        mortgageAccountNo
        propertyReference
        price
        hasLoans
        hasCards
        receivingGifts
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      property {
        id
        intendedUse
        addressKnown
        propertyType
        address
        newlyBuilt
        bedrooms
        bathrooms
        spendAmount
        purchasePrice
        propertyValue
        monthlyRent
        residingHere
        yearOfPurchase
        adultsBoarding
        adultsBoardingCount
        berScore
        purchaseStage
        mortgageID
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      properties {
        items {
          id
          addressKnown
          address
          propertyType
          buildType
          newlyBuilt
          bedrooms
          bathrooms
          berScore
          propertyValue
          propertyPrice
          siteOwned
          siteValue
          constructionCosts
          constructionStarted
          origPurchasePrice
          yearOfPurchase
          propertyAge
          residingHere
          adultsBoarding
          adultsBoardingCount
          isRented
          monthlyRent
          isMortgaged
          isClosed
          isNewAccount
          remainingTerm
          lender
          accountNumber
          monthlyPayment
          currentRate
          outstanding
          bookingDepositPaid
          contractDepositPaid
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          surveyRequired
          surveyDate
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      existing {
        id
        remainingTerm
        lender
        accountNumber
        monthlyPayment
        currentRate
        outstanding
        topUpRequired
        topUpPurpose
        topUpAmount
        readAccessGroup
        editAccessGroup
        propertyId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      needs {
        id
        circumstanceChanges
        circumstanceChangesDetails
        healthIssues
        healthIssuesDetails
        policeRecord
        policeRecordDetails
        incomeChange
        expenditureChange
        moveLikely
        plannedPayOff
        beyondRetirement
        speedEssential
        consolidatingDebt
        furtherRequirements
        furtherRequirementsDetails
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      uploads {
        items {
          id
          contentType
          fileName
          logicalName
          basicInfo {
            id
            institution
            accountType
            accountName
            accountAddress
            friendlyName
            iban
            omit
            numberOfPages
            openingBalance
            closingBalance
            currency
            foreign
            startDate
            endDate
            documentDate
            verifiedData
            error
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          verifiedData {
            items {
              id
              uploadID
              mortgageID
              name
              verifiedValue
              verifiedValueType
              verificationOn
              verificationBy
              supervisorCheckRequired
              supervisorCheckOn
              supervisorCheckBy
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          fileSize
          fileExtension
          stage
          section
          index
          analysis
          status
          statusBy
          statusOn
          mortgageID
          observeMID
          reason
          identityID
          stampedOn
          pdffed
          seenOn
          seenBy
          omit
          documentDate
          exclude
          examined
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      progress {
        items {
          id
          applicationStage
          sectionType
          sectionName
          sectionIndex
          percent
          mortgageID
          percentUpdatedAt
          alertTitle
          alertBody
          alertAuthor
          alertDismissed
          alertDone
          alertPending
          lastDocumentDate
          lastDocumentDateDismissed
          isNewAccount
          metaData
          verifiedData
          sectionChecked
          sectionCheckedBy
          expiresDate
          note
          internalNote
          internalNoteBy
          internalNoteOn
          submittedOn
          submittedBy
          outOfDate
          arrangement
          excludeFromCalculations
          viewState
          notes {
            items {
              id
              text
              readBy
              readTime
              userMortgageProgressID
              observeMID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      applicant1 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicant2 {
        id
        subjectId
        identityID
        email
        mobile
        firstName
        surname
        isMale
        provider
        lastHere
        userApplicantPersonalId
        userApplicantIncomeId
        userApplicantFinancialId
        userApplicantEmploymentId
        userApplicantTaxId
        userApplicantLastHereId
        data
        lastHereTime {
          id
          time
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        accounts {
          items {
            id
            institution
            applicantID
            salaried
            joint
            accommodationDD
            accountName
            accountAddress
            currency
            isClosed
            isNewAccount
            accountNumber
            lastDocumentDate
            hasOverdraft
            overdraftLimit
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessAccounts {
          items {
            id
            institution
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        personal {
          id
          title
          dateOfBirth
          maritalStatus
          maidenName
          mothersMaidenName
          nationality
          countryOfBirth
          placeOfBirth
          countryOfResidence
          irishResidenceStartDate
          otherCountryOfResidenceInPast3Years
          countDependents
          dependentAges
          dependentDOBs
          accommodationSituation
          accommodationSituationOther
          addressCurrent
          addressCurrentYears
          addressCurrentStartDate
          addressPrevious
          visaRequired
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        income {
          id
          grossBasic
          hasOvertime
          overtime
          hasBonus
          bonus
          hasCommission
          commission
          hasOther
          other
          otherSource
          otherSourceDetails
          nonEuroCurrency
          maintenance
          welfare
          secondjob
          rentalIncome
          dividends
          payFrequency
          selfIncomeSource
          salariedInstitution
          incomeChecks
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        financial {
          id
          hasAccommodationCosts
          monthlyAccommodationCosts
          hasChildcareCosts
          contributeToRelatives
          negativeCredit
          hasAssets
          hasDebts
          hasIncome
          hasCurrentAccountSavings
          hasSavings
          hasLoans
          hasProperties
          paysMaintenance
          maintenanceCosts
          monthlyChildcareCosts
          monthlySavings
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employment {
          id
          primaryEmploymentId
          employmentType
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerYearsThere
          currentEmployerContact
          previousEmployerName
          previousEmployerAddress
          previousEmployerYearsThere
          positionHeld
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          employmentBasis
          limitedCompany
          isDirector
          percentageOfShares
          currentEmployerStartDate
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerStartDate
          previousEmployerEndDate
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        employmentIncome {
          items {
            id
            applicantID
            employmentType
            employmentBasis
            publicSector
            positionHeld
            currentEmployerName
            currentEmployerAddress
            currentEmployerPhone
            currentEmployerContact
            currentEmployerStartDate
            firstJob
            previousEmploymentBasis
            previousPositionHeld
            previousEmployerName
            previousEmployerAddress
            previousEmployerStartDate
            previousEmployerEndDate
            nameOfBusiness
            natureOfBusiness
            businessAddress
            accountantName
            accountantAddress
            accountantPhone
            businessEstablished
            limitedCompany
            isDirector
            percentageOfShares
            selfIncomeSource
            grossBasic
            incomes
            nonEuroCurrency
            payFrequency
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        tax {
          id
          irishTaxResident
          ppsNumber
          usCitizen
          usTaxId
          otherTaxResident
          otherTaxCountry
          otherTaxId
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        assets {
          items {
            id
            assetType
            institution
            description
            balance
            increasesFrequency
            increases
            currency
            isClosed
            isNewAccount
            accountNumber
            accountName
            accountAddress
            lastDocumentDate
            applicantID
            joint
            observeMID
            nameAndAddress
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        properties {
          items {
            id
            propertyType
            address
            estimatedValue
            isRented
            rentPerAnnum
            rentPerMonth
            isMortgaged
            isClosed
            isNewAccount
            accountNumber
            originalAmount
            outstanding
            monthlyRepayments
            remainingTerm
            endDate
            currency
            lastDocumentDate
            lender
            inArrears
            arrears
            applicantID
            bedrooms
            bathrooms
            isRestructured
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        debts {
          items {
            id
            debtType
            lender
            outstanding
            originalAmount
            monthlyPayment
            creditLimit
            inArrears
            isClosed
            isNewAccount
            arrears
            endDate
            accountNumber
            lastDocumentDate
            currency
            clearedBeforeMortgage
            applicantID
            observeMID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        businessDebts {
          items {
            id
            debtType
            lender
            outstanding
            monthlyPayment
            creditLimit
            inArrears
            arrears
            endDate
            clearedBeforeMortgage
            applicantID
            accountNumber
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        activity {
          items {
            id
            actions
            userAgent
            actionedOn
            actionedBy
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        emails {
          items {
            id
            from
            to
            cc
            bcc
            subject
            hasAttachments
            v2Attachments
            html
            attachments
            sortDate
            datetimeReceived
            datetimeSent
            messageId
            originalId
            isReceived
            isReceivedInt
            readOn
            readBy
            replyRequestId
            appsyncVersion
            actionedOn
            actionedBy
            AdminEmailAttachments {
              nextToken
              startedAt
              __typename
            }
            userapplicantID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        alternateEmailAddresses {
          items {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        health {
          id
          weight
          height
          smoker
          everSmoked
          cigarettesSmoked
          cigarettesPerDay
          cigarSmoked
          cigarsPerDay
          pipeSmoked
          pipeGramsPerDay
          electronicCigaretteSmoked
          otherNicotineReplacementUsed
          stoppedSmokingDate
          alcoholUnits
          dangerousActivities
          familyDeath
          diagnosedConditions
          diagnosedConditionsDetails
          prescribedMedication
          prescribedMedicationDetails
          hasGp
          gpName
          gpAddress
          gpLastVisit
          gpLastVisitYearsAgo
          yearsWithGp
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      applicationStage
      applicationFutureDate
      applicationStatus
      notes {
        items {
          id
          body
          author
          pinned
          deleted
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      queries {
        items {
          id
          mortgageID
          emailReplyID
          createdBy
          referenceType
          referenceId
          stage
          section
          index
          title
          date
          description
          amount
          query
          userAnswer
          userAnswerOn
          userAnswerBy
          userAnswered
          adminAnswered
          lenderAnswer
          lenderAnswerOn
          lenderAnswerBy
          excludedOn
          excludedBy
          private
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      settings {
        id
        initialContactOn
        initialContactBy
        mortgageID
        contactTimes
        markedLead
        markedLeadBy
        markedSetupDone
        markedSetupDoneBy
        markedApplyDone
        markedApplyDoneBy
        markedComplete
        markedCompleteBy
        markedPreApproved
        markedPreApprovedBy
        markedApproved
        markedApprovedBy
        markedDocsSigned
        markedDocsSignedBy
        markedSubmitted
        markedSubmittedBy
        markedSuspended
        markedSuspendedBy
        offlineLead
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      solicitor {
        id
        firmName
        firmAddress
        contactName
        contactEmail
        contactPhone
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      dynamicSections {
        items {
          id
          sectionStage
          sectionType
          sectionName
          sectionIndex
          sectionTitle
          sectionApplicant
          sectionInstructions
          mortgageID
          emailReplyID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tasks {
        items {
          id
          body
          author
          pinned
          due
          deleted
          target
          targetStarted
          targetLastActive
          completedOn
          completedBy
          paused
          closedOn
          closedBy
          folder
          userFolder
          userSortPosition
          tags
          currentOwner
          lastMessageBy
          lastMessageOn
          lastMessage
          toDos {
            items {
              id
              taskID
              completedOn
              completedBy
              toDoItem
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          pauses
          components {
            items {
              id
              taskID
              component
              author
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          usermortgageID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stageTasks {
        items {
          id
          mortgageID
          stage
          name
          deleted
          due
          completedOn
          completedBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      invitations {
        items {
          id
          mortgageID
          applicant2ID
          firstName
          email
          inviter
          inviterFirstName
          sent
          accepted
          invalidated
          inviteType
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      docContents {
        items {
          id
          mortgageID
          paragraph
          folder
          document
          sequence
          html
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastSeen
      lastFlowItem
      lastFlowItemTime
      completedFlowItems
      completedFlowItemsForAdmin
      qualification
      qualificationConfirmed
      adminMessage
      adminMessageBy
      adminMessageOn
      advisorID
      caseNumber
      friendlyName
      UserMortgageDocuments {
        items {
          id
          title
          description
          identityID
          identityID2
          folder
          fileName
          fileExtension
          isDynamic
          name
          stage
          private
          submissionID
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      notifications {
        items {
          id
          mortgageID
          stage
          name
          history {
            items {
              id
              sentTime
              sentBy
              data
              notificationId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      submissions {
        items {
          id
          lenderCode
          provisionalProduct
          provisionalRateId
          provisionalRate
          provisionalTerm
          provisionalLoan
          provisionalValue
          provisionalSecondaryProduct
          provisionalSecondaryRate
          provisionalSecondaryRateId
          provisionalXlsInput
          provisionalXlsOutput
          finalProduct
          finalRate
          finalRateId
          finalTerm
          finalLoan
          finalValue
          finalSecondaryProduct
          finalSecondaryRate
          finalSecondaryRateId
          finalXlsInput
          finalXlsOutput
          soft
          aipEmailId
          aipMaxLoan
          aipPropertyReference
          aipExpiryDate
          aipDate
          aipNotes
          aipSettings
          loanOfferEmailId
          loanOfferDate
          loanOfferExpiryDate
          loanOfferNotes
          loanOfferSettings
          drawdownDate
          completedFlowItems
          completedFlowItemsForAdmin
          reinstatementValue
          valuationValue
          valuationInstructed
          valuationDate
          valuationExpiryDate
          anotherValuationNeeded
          hasValuationIssues
          valuationIssues
          valuation2Instructed
          valuation2Date
          valuation2ExpiryDate
          valuation2ReinstatementValue
          reinstatement2Value
          valuation2Value
          hasValuation2Issues
          valuation2Issues
          surveyRequired
          surveyDate
          lenderRecommendation
          productRecommendation
          termRecommendation
          mortgageAccountNo
          invalidatedReason
          submissionStage
          documents {
            items {
              id
              title
              description
              identityID
              identityID2
              folder
              fileName
              fileExtension
              isDynamic
              name
              stage
              private
              submissionID
              mortgageID
              readAccessGroup
              editAccessGroup
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              owner
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          mortgageID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adminSubmissions {
        items {
          id
          mortgageID
          provisionalXlsInput
          provisionalXlsOutput
          finalXlsInput
          finalXlsOutput
          lenderRecommendation
          productRecommendation
          termRecommendation
          secondaryProduct
          secondaryRate
          invalidatedReason
          aipNotes
          loanOfferNotes
          completedFlowItemsForAdmin
          issues {
            items {
              id
              submissionID
              emailID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      uploadsPercentSetup
      uploadsPercentApply
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userMortgageRequirementId
      userMortgagePropertyId
      userMortgageExistingId
      userMortgageNeedsId
      userMortgageSettingsId
      userMortgageSolicitorId
      owner
      __typename
    }
  }
`;
export const createUserMortgageInvitation = /* GraphQL */ `
  mutation CreateUserMortgageInvitation(
    $input: CreateUserMortgageInvitationInput!
    $condition: ModelUserMortgageInvitationConditionInput
  ) {
    createUserMortgageInvitation(input: $input, condition: $condition) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageInvitation = /* GraphQL */ `
  mutation UpdateUserMortgageInvitation(
    $input: UpdateUserMortgageInvitationInput!
    $condition: ModelUserMortgageInvitationConditionInput
  ) {
    updateUserMortgageInvitation(input: $input, condition: $condition) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageInvitation = /* GraphQL */ `
  mutation DeleteUserMortgageInvitation(
    $input: DeleteUserMortgageInvitationInput!
    $condition: ModelUserMortgageInvitationConditionInput
  ) {
    deleteUserMortgageInvitation(input: $input, condition: $condition) {
      id
      mortgageID
      applicant2ID
      firstName
      email
      inviter
      inviterFirstName
      sent
      accepted
      invalidated
      inviteType
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageDocuments = /* GraphQL */ `
  mutation CreateUserMortgageDocuments(
    $input: CreateUserMortgageDocumentsInput!
    $condition: ModelUserMortgageDocumentsConditionInput
  ) {
    createUserMortgageDocuments(input: $input, condition: $condition) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      submissionID
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageDocuments = /* GraphQL */ `
  mutation UpdateUserMortgageDocuments(
    $input: UpdateUserMortgageDocumentsInput!
    $condition: ModelUserMortgageDocumentsConditionInput
  ) {
    updateUserMortgageDocuments(input: $input, condition: $condition) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      submissionID
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageDocuments = /* GraphQL */ `
  mutation DeleteUserMortgageDocuments(
    $input: DeleteUserMortgageDocumentsInput!
    $condition: ModelUserMortgageDocumentsConditionInput
  ) {
    deleteUserMortgageDocuments(input: $input, condition: $condition) {
      id
      title
      description
      identityID
      identityID2
      folder
      fileName
      fileExtension
      isDynamic
      name
      stage
      private
      submissionID
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageProgress = /* GraphQL */ `
  mutation CreateUserMortgageProgress(
    $input: CreateUserMortgageProgressInput!
    $condition: ModelUserMortgageProgressConditionInput
  ) {
    createUserMortgageProgress(input: $input, condition: $condition) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      lastDocumentDateDismissed
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      viewState
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageProgress = /* GraphQL */ `
  mutation UpdateUserMortgageProgress(
    $input: UpdateUserMortgageProgressInput!
    $condition: ModelUserMortgageProgressConditionInput
  ) {
    updateUserMortgageProgress(input: $input, condition: $condition) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      lastDocumentDateDismissed
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      viewState
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageProgress = /* GraphQL */ `
  mutation DeleteUserMortgageProgress(
    $input: DeleteUserMortgageProgressInput!
    $condition: ModelUserMortgageProgressConditionInput
  ) {
    deleteUserMortgageProgress(input: $input, condition: $condition) {
      id
      applicationStage
      sectionType
      sectionName
      sectionIndex
      percent
      mortgageID
      percentUpdatedAt
      alertTitle
      alertBody
      alertAuthor
      alertDismissed
      alertDone
      alertPending
      lastDocumentDate
      lastDocumentDateDismissed
      isNewAccount
      metaData
      verifiedData
      sectionChecked
      sectionCheckedBy
      expiresDate
      note
      internalNote
      internalNoteBy
      internalNoteOn
      submittedOn
      submittedBy
      outOfDate
      arrangement
      excludeFromCalculations
      viewState
      notes {
        items {
          id
          text
          readBy
          readTime
          userMortgageProgressID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageProgressNotes = /* GraphQL */ `
  mutation CreateUserMortgageProgressNotes(
    $input: CreateUserMortgageProgressNotesInput!
    $condition: ModelUserMortgageProgressNotesConditionInput
  ) {
    createUserMortgageProgressNotes(input: $input, condition: $condition) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageProgressNotes = /* GraphQL */ `
  mutation UpdateUserMortgageProgressNotes(
    $input: UpdateUserMortgageProgressNotesInput!
    $condition: ModelUserMortgageProgressNotesConditionInput
  ) {
    updateUserMortgageProgressNotes(input: $input, condition: $condition) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageProgressNotes = /* GraphQL */ `
  mutation DeleteUserMortgageProgressNotes(
    $input: DeleteUserMortgageProgressNotesInput!
    $condition: ModelUserMortgageProgressNotesConditionInput
  ) {
    deleteUserMortgageProgressNotes(input: $input, condition: $condition) {
      id
      text
      readBy
      readTime
      userMortgageProgressID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageDynamicSection = /* GraphQL */ `
  mutation CreateUserMortgageDynamicSection(
    $input: CreateUserMortgageDynamicSectionInput!
    $condition: ModelUserMortgageDynamicSectionConditionInput
  ) {
    createUserMortgageDynamicSection(input: $input, condition: $condition) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageDynamicSection = /* GraphQL */ `
  mutation UpdateUserMortgageDynamicSection(
    $input: UpdateUserMortgageDynamicSectionInput!
    $condition: ModelUserMortgageDynamicSectionConditionInput
  ) {
    updateUserMortgageDynamicSection(input: $input, condition: $condition) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageDynamicSection = /* GraphQL */ `
  mutation DeleteUserMortgageDynamicSection(
    $input: DeleteUserMortgageDynamicSectionInput!
    $condition: ModelUserMortgageDynamicSectionConditionInput
  ) {
    deleteUserMortgageDynamicSection(input: $input, condition: $condition) {
      id
      sectionStage
      sectionType
      sectionName
      sectionIndex
      sectionTitle
      sectionApplicant
      sectionInstructions
      mortgageID
      emailReplyID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageUploads = /* GraphQL */ `
  mutation CreateUserMortgageUploads(
    $input: CreateUserMortgageUploadsInput!
    $condition: ModelUserMortgageUploadsConditionInput
  ) {
    createUserMortgageUploads(input: $input, condition: $condition) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      pdffed
      seenOn
      seenBy
      omit
      documentDate
      exclude
      examined
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageUploads = /* GraphQL */ `
  mutation UpdateUserMortgageUploads(
    $input: UpdateUserMortgageUploadsInput!
    $condition: ModelUserMortgageUploadsConditionInput
  ) {
    updateUserMortgageUploads(input: $input, condition: $condition) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      pdffed
      seenOn
      seenBy
      omit
      documentDate
      exclude
      examined
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageUploads = /* GraphQL */ `
  mutation DeleteUserMortgageUploads(
    $input: DeleteUserMortgageUploadsInput!
    $condition: ModelUserMortgageUploadsConditionInput
  ) {
    deleteUserMortgageUploads(input: $input, condition: $condition) {
      id
      contentType
      fileName
      logicalName
      basicInfo {
        id
        institution
        accountType
        accountName
        accountAddress
        friendlyName
        iban
        omit
        numberOfPages
        openingBalance
        closingBalance
        currency
        foreign
        startDate
        endDate
        documentDate
        verifiedData
        error
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      verifiedData {
        items {
          id
          uploadID
          mortgageID
          name
          verifiedValue
          verifiedValueType
          verificationOn
          verificationBy
          verificationNotes {
            items {
              id
              verificationID
              authorID
              string
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          supervisorCheckRequired
          supervisorCheckOn
          supervisorCheckBy
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      fileSize
      fileExtension
      stage
      section
      index
      analysis
      status
      statusBy
      statusOn
      mortgageID
      observeMID
      reason
      identityID
      stampedOn
      pdffed
      seenOn
      seenBy
      omit
      documentDate
      exclude
      examined
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageRequirement = /* GraphQL */ `
  mutation CreateUserMortgageRequirement(
    $input: CreateUserMortgageRequirementInput!
    $condition: ModelUserMortgageRequirementConditionInput
  ) {
    createUserMortgageRequirement(input: $input, condition: $condition) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageRequirement = /* GraphQL */ `
  mutation UpdateUserMortgageRequirement(
    $input: UpdateUserMortgageRequirementInput!
    $condition: ModelUserMortgageRequirementConditionInput
  ) {
    updateUserMortgageRequirement(input: $input, condition: $condition) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageRequirement = /* GraphQL */ `
  mutation DeleteUserMortgageRequirement(
    $input: DeleteUserMortgageRequirementInput!
    $condition: ModelUserMortgageRequirementConditionInput
  ) {
    deleteUserMortgageRequirement(input: $input, condition: $condition) {
      id
      applicationElsewhere
      loanRequired
      cohabiting
      loanTerm
      rateId
      rate
      monthlyPayment
      finalRate
      finalTerm
      firstTimeBuyers
      combinedDependants
      combinedGrossEarnings
      combinedLoanRepayments
      depositSavings
      depositGifts
      depositPaid
      helpToBuyScheme
      helpToBuySchemeAmount
      helpToBuySchemeAccessCode
      helpToBuySchemeApplicationCode
      helpToBuySchemeDeveloperName
      firstHomeScheme
      firstHomeSchemeCouncil
      firstHomeSchemeType
      firstHomeSchemeAmount
      lenderId
      lender {
        id
        lender
        address
        approverFirstName
        approverSurname
        approverEmail
        approverPhone
        lenderCode
        rates {
          items {
            id
            lenderID
            rate
            maxLTV
            term
            discount
            minimum
            maximum
            lenderCode
            promotions
            conditions
            forBorrowerTypes
            minBER
            buyToLet
            product
            interestOnly
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        minimumLoan
        minimumTerm
        maximumLoan
        maximumTerm
        onlineAccount
        overlayTemplates {
          items {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            companyFolder
            documentNotes
            archive
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            items {
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        files {
          items {
            id
            lender
            folder
            description
            fileName
            bucketKey
            contentType
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        defaultConditions
        defaultPromotions
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      lenderCode
      lenderProduct
      contactMethod
      proposals
      soft
      drawdownDate
      aipExpiryDate
      aipDate
      loanOfferDate
      loanOfferExpiryDate
      ddBankAccountID
      mortgageAccountNo
      propertyReference
      price
      hasLoans
      hasCards
      receivingGifts
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageProperty = /* GraphQL */ `
  mutation CreateUserMortgageProperty(
    $input: CreateUserMortgagePropertyInput!
    $condition: ModelUserMortgagePropertyConditionInput
  ) {
    createUserMortgageProperty(input: $input, condition: $condition) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageProperty = /* GraphQL */ `
  mutation UpdateUserMortgageProperty(
    $input: UpdateUserMortgagePropertyInput!
    $condition: ModelUserMortgagePropertyConditionInput
  ) {
    updateUserMortgageProperty(input: $input, condition: $condition) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageProperty = /* GraphQL */ `
  mutation DeleteUserMortgageProperty(
    $input: DeleteUserMortgagePropertyInput!
    $condition: ModelUserMortgagePropertyConditionInput
  ) {
    deleteUserMortgageProperty(input: $input, condition: $condition) {
      id
      intendedUse
      addressKnown
      propertyType
      address
      newlyBuilt
      bedrooms
      bathrooms
      spendAmount
      purchasePrice
      propertyValue
      monthlyRent
      residingHere
      yearOfPurchase
      adultsBoarding
      adultsBoardingCount
      berScore
      purchaseStage
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageExisting = /* GraphQL */ `
  mutation CreateUserMortgageExisting(
    $input: CreateUserMortgageExistingInput!
    $condition: ModelUserMortgageExistingConditionInput
  ) {
    createUserMortgageExisting(input: $input, condition: $condition) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageExisting = /* GraphQL */ `
  mutation UpdateUserMortgageExisting(
    $input: UpdateUserMortgageExistingInput!
    $condition: ModelUserMortgageExistingConditionInput
  ) {
    updateUserMortgageExisting(input: $input, condition: $condition) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageExisting = /* GraphQL */ `
  mutation DeleteUserMortgageExisting(
    $input: DeleteUserMortgageExistingInput!
    $condition: ModelUserMortgageExistingConditionInput
  ) {
    deleteUserMortgageExisting(input: $input, condition: $condition) {
      id
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      topUpRequired
      topUpPurpose
      topUpAmount
      readAccessGroup
      editAccessGroup
      propertyId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageNeeds = /* GraphQL */ `
  mutation CreateUserMortgageNeeds(
    $input: CreateUserMortgageNeedsInput!
    $condition: ModelUserMortgageNeedsConditionInput
  ) {
    createUserMortgageNeeds(input: $input, condition: $condition) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageNeeds = /* GraphQL */ `
  mutation UpdateUserMortgageNeeds(
    $input: UpdateUserMortgageNeedsInput!
    $condition: ModelUserMortgageNeedsConditionInput
  ) {
    updateUserMortgageNeeds(input: $input, condition: $condition) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageNeeds = /* GraphQL */ `
  mutation DeleteUserMortgageNeeds(
    $input: DeleteUserMortgageNeedsInput!
    $condition: ModelUserMortgageNeedsConditionInput
  ) {
    deleteUserMortgageNeeds(input: $input, condition: $condition) {
      id
      circumstanceChanges
      circumstanceChangesDetails
      healthIssues
      healthIssuesDetails
      policeRecord
      policeRecordDetails
      incomeChange
      expenditureChange
      moveLikely
      plannedPayOff
      beyondRetirement
      speedEssential
      consolidatingDebt
      furtherRequirements
      furtherRequirementsDetails
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgageSolicitor = /* GraphQL */ `
  mutation CreateUserMortgageSolicitor(
    $input: CreateUserMortgageSolicitorInput!
    $condition: ModelUserMortgageSolicitorConditionInput
  ) {
    createUserMortgageSolicitor(input: $input, condition: $condition) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgageSolicitor = /* GraphQL */ `
  mutation UpdateUserMortgageSolicitor(
    $input: UpdateUserMortgageSolicitorInput!
    $condition: ModelUserMortgageSolicitorConditionInput
  ) {
    updateUserMortgageSolicitor(input: $input, condition: $condition) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgageSolicitor = /* GraphQL */ `
  mutation DeleteUserMortgageSolicitor(
    $input: DeleteUserMortgageSolicitorInput!
    $condition: ModelUserMortgageSolicitorConditionInput
  ) {
    deleteUserMortgageSolicitor(input: $input, condition: $condition) {
      id
      firmName
      firmAddress
      contactName
      contactEmail
      contactPhone
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserMortgagePropertyOnMortgage = /* GraphQL */ `
  mutation CreateUserMortgagePropertyOnMortgage(
    $input: CreateUserMortgagePropertyOnMortgageInput!
    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
  ) {
    createUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      propertyAge
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      isClosed
      isNewAccount
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserMortgagePropertyOnMortgage = /* GraphQL */ `
  mutation UpdateUserMortgagePropertyOnMortgage(
    $input: UpdateUserMortgagePropertyOnMortgageInput!
    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
  ) {
    updateUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      propertyAge
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      isClosed
      isNewAccount
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserMortgagePropertyOnMortgage = /* GraphQL */ `
  mutation DeleteUserMortgagePropertyOnMortgage(
    $input: DeleteUserMortgagePropertyOnMortgageInput!
    $condition: ModelUserMortgagePropertyOnMortgageConditionInput
  ) {
    deleteUserMortgagePropertyOnMortgage(input: $input, condition: $condition) {
      id
      addressKnown
      address
      propertyType
      buildType
      newlyBuilt
      bedrooms
      bathrooms
      berScore
      propertyValue
      propertyPrice
      siteOwned
      siteValue
      constructionCosts
      constructionStarted
      origPurchasePrice
      yearOfPurchase
      propertyAge
      residingHere
      adultsBoarding
      adultsBoardingCount
      isRented
      monthlyRent
      isMortgaged
      isClosed
      isNewAccount
      remainingTerm
      lender
      accountNumber
      monthlyPayment
      currentRate
      outstanding
      bookingDepositPaid
      contractDepositPaid
      reinstatementValue
      valuationValue
      valuationInstructed
      valuationDate
      valuationExpiryDate
      anotherValuationNeeded
      hasValuationIssues
      valuationIssues
      surveyRequired
      surveyDate
      valuation2Instructed
      valuation2Date
      valuation2ExpiryDate
      mortgageID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicant = /* GraphQL */ `
  mutation CreateUserApplicant(
    $input: CreateUserApplicantInput!
    $condition: ModelUserApplicantConditionInput
  ) {
    createUserApplicant(input: $input, condition: $condition) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          isClosed
          isNewAccount
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          isClosed
          isNewAccount
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          rentPerMonth
          isMortgaged
          isClosed
          isNewAccount
          accountNumber
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          isClosed
          isNewAccount
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicant = /* GraphQL */ `
  mutation UpdateUserApplicant(
    $input: UpdateUserApplicantInput!
    $condition: ModelUserApplicantConditionInput
  ) {
    updateUserApplicant(input: $input, condition: $condition) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          isClosed
          isNewAccount
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          isClosed
          isNewAccount
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          rentPerMonth
          isMortgaged
          isClosed
          isNewAccount
          accountNumber
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          isClosed
          isNewAccount
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicant = /* GraphQL */ `
  mutation DeleteUserApplicant(
    $input: DeleteUserApplicantInput!
    $condition: ModelUserApplicantConditionInput
  ) {
    deleteUserApplicant(input: $input, condition: $condition) {
      id
      subjectId
      identityID
      email
      mobile
      firstName
      surname
      isMale
      provider
      lastHere
      userApplicantPersonalId
      userApplicantIncomeId
      userApplicantFinancialId
      userApplicantEmploymentId
      userApplicantTaxId
      userApplicantLastHereId
      data
      lastHereTime {
        id
        time
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      accounts {
        items {
          id
          institution
          applicantID
          salaried
          joint
          accommodationDD
          accountName
          accountAddress
          currency
          isClosed
          isNewAccount
          accountNumber
          lastDocumentDate
          hasOverdraft
          overdraftLimit
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessAccounts {
        items {
          id
          institution
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      personal {
        id
        title
        dateOfBirth
        maritalStatus
        maidenName
        mothersMaidenName
        nationality
        countryOfBirth
        placeOfBirth
        countryOfResidence
        irishResidenceStartDate
        otherCountryOfResidenceInPast3Years
        countDependents
        dependentAges
        dependentDOBs
        accommodationSituation
        accommodationSituationOther
        addressCurrent
        addressCurrentYears
        addressCurrentStartDate
        addressPrevious
        visaRequired
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      income {
        id
        grossBasic
        hasOvertime
        overtime
        hasBonus
        bonus
        hasCommission
        commission
        hasOther
        other
        otherSource
        otherSourceDetails
        nonEuroCurrency
        maintenance
        welfare
        secondjob
        rentalIncome
        dividends
        payFrequency
        selfIncomeSource
        salariedInstitution
        incomeChecks
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      financial {
        id
        hasAccommodationCosts
        monthlyAccommodationCosts
        hasChildcareCosts
        contributeToRelatives
        negativeCredit
        hasAssets
        hasDebts
        hasIncome
        hasCurrentAccountSavings
        hasSavings
        hasLoans
        hasProperties
        paysMaintenance
        maintenanceCosts
        monthlyChildcareCosts
        monthlySavings
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employment {
        id
        primaryEmploymentId
        employmentType
        currentEmployerName
        currentEmployerAddress
        currentEmployerPhone
        currentEmployerYearsThere
        currentEmployerContact
        previousEmployerName
        previousEmployerAddress
        previousEmployerYearsThere
        positionHeld
        nameOfBusiness
        natureOfBusiness
        businessAddress
        accountantName
        accountantAddress
        accountantPhone
        businessEstablished
        employmentBasis
        limitedCompany
        isDirector
        percentageOfShares
        currentEmployerStartDate
        previousEmploymentBasis
        previousPositionHeld
        previousEmployerStartDate
        previousEmployerEndDate
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      employmentIncome {
        items {
          id
          applicantID
          employmentType
          employmentBasis
          publicSector
          positionHeld
          currentEmployerName
          currentEmployerAddress
          currentEmployerPhone
          currentEmployerContact
          currentEmployerStartDate
          firstJob
          previousEmploymentBasis
          previousPositionHeld
          previousEmployerName
          previousEmployerAddress
          previousEmployerStartDate
          previousEmployerEndDate
          nameOfBusiness
          natureOfBusiness
          businessAddress
          accountantName
          accountantAddress
          accountantPhone
          businessEstablished
          limitedCompany
          isDirector
          percentageOfShares
          selfIncomeSource
          grossBasic
          incomes
          nonEuroCurrency
          payFrequency
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      tax {
        id
        irishTaxResident
        ppsNumber
        usCitizen
        usTaxId
        otherTaxResident
        otherTaxCountry
        otherTaxId
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      assets {
        items {
          id
          assetType
          institution
          description
          balance
          increasesFrequency
          increases
          currency
          isClosed
          isNewAccount
          accountNumber
          accountName
          accountAddress
          lastDocumentDate
          applicantID
          joint
          observeMID
          nameAndAddress
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      properties {
        items {
          id
          propertyType
          address
          estimatedValue
          isRented
          rentPerAnnum
          rentPerMonth
          isMortgaged
          isClosed
          isNewAccount
          accountNumber
          originalAmount
          outstanding
          monthlyRepayments
          remainingTerm
          endDate
          currency
          lastDocumentDate
          lender
          inArrears
          arrears
          applicantID
          bedrooms
          bathrooms
          isRestructured
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      debts {
        items {
          id
          debtType
          lender
          outstanding
          originalAmount
          monthlyPayment
          creditLimit
          inArrears
          isClosed
          isNewAccount
          arrears
          endDate
          accountNumber
          lastDocumentDate
          currency
          clearedBeforeMortgage
          applicantID
          observeMID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      businessDebts {
        items {
          id
          debtType
          lender
          outstanding
          monthlyPayment
          creditLimit
          inArrears
          arrears
          endDate
          clearedBeforeMortgage
          applicantID
          accountNumber
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activity {
        items {
          id
          actions
          userAgent
          actionedOn
          actionedBy
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      emails {
        items {
          id
          from
          to
          cc
          bcc
          subject
          hasAttachments
          v2Attachments
          html
          attachments
          sortDate
          datetimeReceived
          datetimeSent
          messageId
          originalId
          isReceived
          isReceivedInt
          readOn
          readBy
          replyRequestId
          appsyncVersion
          actionedOn
          actionedBy
          AdminEmailAttachments {
            items {
              id
              contentType
              fileSize
              content
              fileName
              adminemailsID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          userapplicantID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      alternateEmailAddresses {
        items {
          id
          email
          applicantID
          readAccessGroup
          editAccessGroup
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      health {
        id
        weight
        height
        smoker
        everSmoked
        cigarettesSmoked
        cigarettesPerDay
        cigarSmoked
        cigarsPerDay
        pipeSmoked
        pipeGramsPerDay
        electronicCigaretteSmoked
        otherNicotineReplacementUsed
        stoppedSmokingDate
        alcoholUnits
        dangerousActivities
        familyDeath
        diagnosedConditions
        diagnosedConditionsDetails
        prescribedMedication
        prescribedMedicationDetails
        hasGp
        gpName
        gpAddress
        gpLastVisit
        gpLastVisitYearsAgo
        yearsWithGp
        readAccessGroup
        editAccessGroup
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantLastHere = /* GraphQL */ `
  mutation CreateUserApplicantLastHere(
    $input: CreateUserApplicantLastHereInput!
    $condition: ModelUserApplicantLastHereConditionInput
  ) {
    createUserApplicantLastHere(input: $input, condition: $condition) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantLastHere = /* GraphQL */ `
  mutation UpdateUserApplicantLastHere(
    $input: UpdateUserApplicantLastHereInput!
    $condition: ModelUserApplicantLastHereConditionInput
  ) {
    updateUserApplicantLastHere(input: $input, condition: $condition) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantLastHere = /* GraphQL */ `
  mutation DeleteUserApplicantLastHere(
    $input: DeleteUserApplicantLastHereInput!
    $condition: ModelUserApplicantLastHereConditionInput
  ) {
    deleteUserApplicantLastHere(input: $input, condition: $condition) {
      id
      time
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantCurrentAccount = /* GraphQL */ `
  mutation CreateUserApplicantCurrentAccount(
    $input: CreateUserApplicantCurrentAccountInput!
    $condition: ModelUserApplicantCurrentAccountConditionInput
  ) {
    createUserApplicantCurrentAccount(input: $input, condition: $condition) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      isClosed
      isNewAccount
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantCurrentAccount = /* GraphQL */ `
  mutation UpdateUserApplicantCurrentAccount(
    $input: UpdateUserApplicantCurrentAccountInput!
    $condition: ModelUserApplicantCurrentAccountConditionInput
  ) {
    updateUserApplicantCurrentAccount(input: $input, condition: $condition) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      isClosed
      isNewAccount
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantCurrentAccount = /* GraphQL */ `
  mutation DeleteUserApplicantCurrentAccount(
    $input: DeleteUserApplicantCurrentAccountInput!
    $condition: ModelUserApplicantCurrentAccountConditionInput
  ) {
    deleteUserApplicantCurrentAccount(input: $input, condition: $condition) {
      id
      institution
      applicantID
      salaried
      joint
      accommodationDD
      accountName
      accountAddress
      currency
      isClosed
      isNewAccount
      accountNumber
      lastDocumentDate
      hasOverdraft
      overdraftLimit
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  mutation CreateUserApplicantBusinessCurrentAccount(
    $input: CreateUserApplicantBusinessCurrentAccountInput!
    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
  ) {
    createUserApplicantBusinessCurrentAccount(
      input: $input
      condition: $condition
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  mutation UpdateUserApplicantBusinessCurrentAccount(
    $input: UpdateUserApplicantBusinessCurrentAccountInput!
    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
  ) {
    updateUserApplicantBusinessCurrentAccount(
      input: $input
      condition: $condition
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantBusinessCurrentAccount = /* GraphQL */ `
  mutation DeleteUserApplicantBusinessCurrentAccount(
    $input: DeleteUserApplicantBusinessCurrentAccountInput!
    $condition: ModelUserApplicantBusinessCurrentAccountConditionInput
  ) {
    deleteUserApplicantBusinessCurrentAccount(
      input: $input
      condition: $condition
    ) {
      id
      institution
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantTax = /* GraphQL */ `
  mutation CreateUserApplicantTax(
    $input: CreateUserApplicantTaxInput!
    $condition: ModelUserApplicantTaxConditionInput
  ) {
    createUserApplicantTax(input: $input, condition: $condition) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantTax = /* GraphQL */ `
  mutation UpdateUserApplicantTax(
    $input: UpdateUserApplicantTaxInput!
    $condition: ModelUserApplicantTaxConditionInput
  ) {
    updateUserApplicantTax(input: $input, condition: $condition) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantTax = /* GraphQL */ `
  mutation DeleteUserApplicantTax(
    $input: DeleteUserApplicantTaxInput!
    $condition: ModelUserApplicantTaxConditionInput
  ) {
    deleteUserApplicantTax(input: $input, condition: $condition) {
      id
      irishTaxResident
      ppsNumber
      usCitizen
      usTaxId
      otherTaxResident
      otherTaxCountry
      otherTaxId
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantPersonal = /* GraphQL */ `
  mutation CreateUserApplicantPersonal(
    $input: CreateUserApplicantPersonalInput!
    $condition: ModelUserApplicantPersonalConditionInput
  ) {
    createUserApplicantPersonal(input: $input, condition: $condition) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantPersonal = /* GraphQL */ `
  mutation UpdateUserApplicantPersonal(
    $input: UpdateUserApplicantPersonalInput!
    $condition: ModelUserApplicantPersonalConditionInput
  ) {
    updateUserApplicantPersonal(input: $input, condition: $condition) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantPersonal = /* GraphQL */ `
  mutation DeleteUserApplicantPersonal(
    $input: DeleteUserApplicantPersonalInput!
    $condition: ModelUserApplicantPersonalConditionInput
  ) {
    deleteUserApplicantPersonal(input: $input, condition: $condition) {
      id
      title
      dateOfBirth
      maritalStatus
      maidenName
      mothersMaidenName
      nationality
      countryOfBirth
      placeOfBirth
      countryOfResidence
      irishResidenceStartDate
      otherCountryOfResidenceInPast3Years
      countDependents
      dependentAges
      dependentDOBs
      accommodationSituation
      accommodationSituationOther
      addressCurrent
      addressCurrentYears
      addressCurrentStartDate
      addressPrevious
      visaRequired
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantIncome = /* GraphQL */ `
  mutation CreateUserApplicantIncome(
    $input: CreateUserApplicantIncomeInput!
    $condition: ModelUserApplicantIncomeConditionInput
  ) {
    createUserApplicantIncome(input: $input, condition: $condition) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantIncome = /* GraphQL */ `
  mutation UpdateUserApplicantIncome(
    $input: UpdateUserApplicantIncomeInput!
    $condition: ModelUserApplicantIncomeConditionInput
  ) {
    updateUserApplicantIncome(input: $input, condition: $condition) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantIncome = /* GraphQL */ `
  mutation DeleteUserApplicantIncome(
    $input: DeleteUserApplicantIncomeInput!
    $condition: ModelUserApplicantIncomeConditionInput
  ) {
    deleteUserApplicantIncome(input: $input, condition: $condition) {
      id
      grossBasic
      hasOvertime
      overtime
      hasBonus
      bonus
      hasCommission
      commission
      hasOther
      other
      otherSource
      otherSourceDetails
      nonEuroCurrency
      maintenance
      welfare
      secondjob
      rentalIncome
      dividends
      payFrequency
      selfIncomeSource
      salariedInstitution
      incomeChecks
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantFinancial = /* GraphQL */ `
  mutation CreateUserApplicantFinancial(
    $input: CreateUserApplicantFinancialInput!
    $condition: ModelUserApplicantFinancialConditionInput
  ) {
    createUserApplicantFinancial(input: $input, condition: $condition) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantFinancial = /* GraphQL */ `
  mutation UpdateUserApplicantFinancial(
    $input: UpdateUserApplicantFinancialInput!
    $condition: ModelUserApplicantFinancialConditionInput
  ) {
    updateUserApplicantFinancial(input: $input, condition: $condition) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantFinancial = /* GraphQL */ `
  mutation DeleteUserApplicantFinancial(
    $input: DeleteUserApplicantFinancialInput!
    $condition: ModelUserApplicantFinancialConditionInput
  ) {
    deleteUserApplicantFinancial(input: $input, condition: $condition) {
      id
      hasAccommodationCosts
      monthlyAccommodationCosts
      hasChildcareCosts
      contributeToRelatives
      negativeCredit
      hasAssets
      hasDebts
      hasIncome
      hasCurrentAccountSavings
      hasSavings
      hasLoans
      hasProperties
      paysMaintenance
      maintenanceCosts
      monthlyChildcareCosts
      monthlySavings
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantEmployment = /* GraphQL */ `
  mutation CreateUserApplicantEmployment(
    $input: CreateUserApplicantEmploymentInput!
    $condition: ModelUserApplicantEmploymentConditionInput
  ) {
    createUserApplicantEmployment(input: $input, condition: $condition) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantEmployment = /* GraphQL */ `
  mutation UpdateUserApplicantEmployment(
    $input: UpdateUserApplicantEmploymentInput!
    $condition: ModelUserApplicantEmploymentConditionInput
  ) {
    updateUserApplicantEmployment(input: $input, condition: $condition) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantEmployment = /* GraphQL */ `
  mutation DeleteUserApplicantEmployment(
    $input: DeleteUserApplicantEmploymentInput!
    $condition: ModelUserApplicantEmploymentConditionInput
  ) {
    deleteUserApplicantEmployment(input: $input, condition: $condition) {
      id
      primaryEmploymentId
      employmentType
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerYearsThere
      currentEmployerContact
      previousEmployerName
      previousEmployerAddress
      previousEmployerYearsThere
      positionHeld
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      employmentBasis
      limitedCompany
      isDirector
      percentageOfShares
      currentEmployerStartDate
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerStartDate
      previousEmployerEndDate
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantEmploymentIncome = /* GraphQL */ `
  mutation CreateUserApplicantEmploymentIncome(
    $input: CreateUserApplicantEmploymentIncomeInput!
    $condition: ModelUserApplicantEmploymentIncomeConditionInput
  ) {
    createUserApplicantEmploymentIncome(input: $input, condition: $condition) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantEmploymentIncome = /* GraphQL */ `
  mutation UpdateUserApplicantEmploymentIncome(
    $input: UpdateUserApplicantEmploymentIncomeInput!
    $condition: ModelUserApplicantEmploymentIncomeConditionInput
  ) {
    updateUserApplicantEmploymentIncome(input: $input, condition: $condition) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantEmploymentIncome = /* GraphQL */ `
  mutation DeleteUserApplicantEmploymentIncome(
    $input: DeleteUserApplicantEmploymentIncomeInput!
    $condition: ModelUserApplicantEmploymentIncomeConditionInput
  ) {
    deleteUserApplicantEmploymentIncome(input: $input, condition: $condition) {
      id
      applicantID
      employmentType
      employmentBasis
      publicSector
      positionHeld
      currentEmployerName
      currentEmployerAddress
      currentEmployerPhone
      currentEmployerContact
      currentEmployerStartDate
      firstJob
      previousEmploymentBasis
      previousPositionHeld
      previousEmployerName
      previousEmployerAddress
      previousEmployerStartDate
      previousEmployerEndDate
      nameOfBusiness
      natureOfBusiness
      businessAddress
      accountantName
      accountantAddress
      accountantPhone
      businessEstablished
      limitedCompany
      isDirector
      percentageOfShares
      selfIncomeSource
      grossBasic
      incomes
      nonEuroCurrency
      payFrequency
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantAsset = /* GraphQL */ `
  mutation CreateUserApplicantAsset(
    $input: CreateUserApplicantAssetInput!
    $condition: ModelUserApplicantAssetConditionInput
  ) {
    createUserApplicantAsset(input: $input, condition: $condition) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      isClosed
      isNewAccount
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantAsset = /* GraphQL */ `
  mutation UpdateUserApplicantAsset(
    $input: UpdateUserApplicantAssetInput!
    $condition: ModelUserApplicantAssetConditionInput
  ) {
    updateUserApplicantAsset(input: $input, condition: $condition) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      isClosed
      isNewAccount
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantAsset = /* GraphQL */ `
  mutation DeleteUserApplicantAsset(
    $input: DeleteUserApplicantAssetInput!
    $condition: ModelUserApplicantAssetConditionInput
  ) {
    deleteUserApplicantAsset(input: $input, condition: $condition) {
      id
      assetType
      institution
      description
      balance
      increasesFrequency
      increases
      currency
      isClosed
      isNewAccount
      accountNumber
      accountName
      accountAddress
      lastDocumentDate
      applicantID
      joint
      observeMID
      nameAndAddress
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantDebt = /* GraphQL */ `
  mutation CreateUserApplicantDebt(
    $input: CreateUserApplicantDebtInput!
    $condition: ModelUserApplicantDebtConditionInput
  ) {
    createUserApplicantDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      isClosed
      isNewAccount
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantDebt = /* GraphQL */ `
  mutation UpdateUserApplicantDebt(
    $input: UpdateUserApplicantDebtInput!
    $condition: ModelUserApplicantDebtConditionInput
  ) {
    updateUserApplicantDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      isClosed
      isNewAccount
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantDebt = /* GraphQL */ `
  mutation DeleteUserApplicantDebt(
    $input: DeleteUserApplicantDebtInput!
    $condition: ModelUserApplicantDebtConditionInput
  ) {
    deleteUserApplicantDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      originalAmount
      monthlyPayment
      creditLimit
      inArrears
      isClosed
      isNewAccount
      arrears
      endDate
      accountNumber
      lastDocumentDate
      currency
      clearedBeforeMortgage
      applicantID
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantBusinessDebt = /* GraphQL */ `
  mutation CreateUserApplicantBusinessDebt(
    $input: CreateUserApplicantBusinessDebtInput!
    $condition: ModelUserApplicantBusinessDebtConditionInput
  ) {
    createUserApplicantBusinessDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantBusinessDebt = /* GraphQL */ `
  mutation UpdateUserApplicantBusinessDebt(
    $input: UpdateUserApplicantBusinessDebtInput!
    $condition: ModelUserApplicantBusinessDebtConditionInput
  ) {
    updateUserApplicantBusinessDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantBusinessDebt = /* GraphQL */ `
  mutation DeleteUserApplicantBusinessDebt(
    $input: DeleteUserApplicantBusinessDebtInput!
    $condition: ModelUserApplicantBusinessDebtConditionInput
  ) {
    deleteUserApplicantBusinessDebt(input: $input, condition: $condition) {
      id
      debtType
      lender
      outstanding
      monthlyPayment
      creditLimit
      inArrears
      arrears
      endDate
      clearedBeforeMortgage
      applicantID
      accountNumber
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantProperty = /* GraphQL */ `
  mutation CreateUserApplicantProperty(
    $input: CreateUserApplicantPropertyInput!
    $condition: ModelUserApplicantPropertyConditionInput
  ) {
    createUserApplicantProperty(input: $input, condition: $condition) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      rentPerMonth
      isMortgaged
      isClosed
      isNewAccount
      accountNumber
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantProperty = /* GraphQL */ `
  mutation UpdateUserApplicantProperty(
    $input: UpdateUserApplicantPropertyInput!
    $condition: ModelUserApplicantPropertyConditionInput
  ) {
    updateUserApplicantProperty(input: $input, condition: $condition) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      rentPerMonth
      isMortgaged
      isClosed
      isNewAccount
      accountNumber
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantProperty = /* GraphQL */ `
  mutation DeleteUserApplicantProperty(
    $input: DeleteUserApplicantPropertyInput!
    $condition: ModelUserApplicantPropertyConditionInput
  ) {
    deleteUserApplicantProperty(input: $input, condition: $condition) {
      id
      propertyType
      address
      estimatedValue
      isRented
      rentPerAnnum
      rentPerMonth
      isMortgaged
      isClosed
      isNewAccount
      accountNumber
      originalAmount
      outstanding
      monthlyRepayments
      remainingTerm
      endDate
      currency
      lastDocumentDate
      lender
      inArrears
      arrears
      applicantID
      bedrooms
      bathrooms
      isRestructured
      observeMID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  mutation CreateUserApplicantAlternateEmailAddresses(
    $input: CreateUserApplicantAlternateEmailAddressesInput!
    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
  ) {
    createUserApplicantAlternateEmailAddresses(
      input: $input
      condition: $condition
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  mutation UpdateUserApplicantAlternateEmailAddresses(
    $input: UpdateUserApplicantAlternateEmailAddressesInput!
    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
  ) {
    updateUserApplicantAlternateEmailAddresses(
      input: $input
      condition: $condition
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantAlternateEmailAddresses = /* GraphQL */ `
  mutation DeleteUserApplicantAlternateEmailAddresses(
    $input: DeleteUserApplicantAlternateEmailAddressesInput!
    $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
  ) {
    deleteUserApplicantAlternateEmailAddresses(
      input: $input
      condition: $condition
    ) {
      id
      email
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantActivity = /* GraphQL */ `
  mutation CreateUserApplicantActivity(
    $input: CreateUserApplicantActivityInput!
    $condition: ModelUserApplicantActivityConditionInput
  ) {
    createUserApplicantActivity(input: $input, condition: $condition) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantActivity = /* GraphQL */ `
  mutation UpdateUserApplicantActivity(
    $input: UpdateUserApplicantActivityInput!
    $condition: ModelUserApplicantActivityConditionInput
  ) {
    updateUserApplicantActivity(input: $input, condition: $condition) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantActivity = /* GraphQL */ `
  mutation DeleteUserApplicantActivity(
    $input: DeleteUserApplicantActivityInput!
    $condition: ModelUserApplicantActivityConditionInput
  ) {
    deleteUserApplicantActivity(input: $input, condition: $condition) {
      id
      actions
      userAgent
      actionedOn
      actionedBy
      applicantID
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createUserApplicantHealth = /* GraphQL */ `
  mutation CreateUserApplicantHealth(
    $input: CreateUserApplicantHealthInput!
    $condition: ModelUserApplicantHealthConditionInput
  ) {
    createUserApplicantHealth(input: $input, condition: $condition) {
      id
      weight
      height
      smoker
      everSmoked
      cigarettesSmoked
      cigarettesPerDay
      cigarSmoked
      cigarsPerDay
      pipeSmoked
      pipeGramsPerDay
      electronicCigaretteSmoked
      otherNicotineReplacementUsed
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateUserApplicantHealth = /* GraphQL */ `
  mutation UpdateUserApplicantHealth(
    $input: UpdateUserApplicantHealthInput!
    $condition: ModelUserApplicantHealthConditionInput
  ) {
    updateUserApplicantHealth(input: $input, condition: $condition) {
      id
      weight
      height
      smoker
      everSmoked
      cigarettesSmoked
      cigarettesPerDay
      cigarSmoked
      cigarsPerDay
      pipeSmoked
      pipeGramsPerDay
      electronicCigaretteSmoked
      otherNicotineReplacementUsed
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteUserApplicantHealth = /* GraphQL */ `
  mutation DeleteUserApplicantHealth(
    $input: DeleteUserApplicantHealthInput!
    $condition: ModelUserApplicantHealthConditionInput
  ) {
    deleteUserApplicantHealth(input: $input, condition: $condition) {
      id
      weight
      height
      smoker
      everSmoked
      cigarettesSmoked
      cigarettesPerDay
      cigarSmoked
      cigarsPerDay
      pipeSmoked
      pipeGramsPerDay
      electronicCigaretteSmoked
      otherNicotineReplacementUsed
      stoppedSmokingDate
      alcoholUnits
      dangerousActivities
      familyDeath
      diagnosedConditions
      diagnosedConditionsDetails
      prescribedMedication
      prescribedMedicationDetails
      hasGp
      gpName
      gpAddress
      gpLastVisit
      gpLastVisitYearsAgo
      yearsWithGp
      readAccessGroup
      editAccessGroup
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
