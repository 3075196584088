import React, {useContext, useEffect, useState} from 'react';
import {Lenders} from "../../../../../providers/LendersProvider";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
// import {ToolDrawer} from "../../../../../providers/mortgage/ToolDrawerProvider";
// import useAdminStages from "../../../../../providers/mortgage/useAdminStages";
// import {Button, Card, Form, Input, Select, Tag} from "antd";
// import {CheckCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import MortgageForm from "../../../../data-capture/MortgageForm";

function LenderSelection() {
    const lendersContext = useContext(Lenders)
    const mortgage = useMortgage()
    if (!mortgage.activeSubmission){
        return "No active submittion"
    }
    const lenderQuestion = {
        label : "Provisional Lender",
        text  : "Lender Code",
        name  : "lenderCode",
        answer: {
            type   : "select",
            choices: [
                ...lendersContext.lenders.map(lender => ({
                    value: lender.lenderCode,
                    text : lender.lender
                }))
            ]
        },
        model : "requirement",
        target: `submissions[${mortgage.activeSubmission.id}].lenderCode`
    }
    const softQuestion = {
        label : 'Soft AIP',
        text  : 'Soft Approval',
        name  : 'soft',
        answer: {
            type   : 'choice',
            choices: [
                {
                    value: true,
                    text : 'Yes'
                },
                {
                    value: false,
                    text : 'No'
                }
            ]
        },
        model : 'requirement',
        target: `submissions[${mortgage.activeSubmission.id}].soft`
    }

    return <MortgageForm questions={[
        lenderQuestion,
        softQuestion
    ]}/>
}
//     const toolDrawer = useContext(ToolDrawer)
//     const stages = useAdminStages()
//     const [form] = Form.useForm()
//     const sorter = ((a, b) => {
//         if (a.term < b.term) return -1;
//         if (a.term > b.term) return 1;
//
//         if (a.maxLTV < b.maxLTV) return -1;
//         if (a.maxLTV > b.maxLTV) return 1;
//
//         if (a.rate < b.rate) return -1;
//         if (a.rate > b.rate) return 1;
//
//         return 0;
//     })
//     const [state, setState] = useState({lenders: [], selectedLenderId: mortgage.requirement.lenderId, saving: false})
//
//     const handleComposeEmail = () => {
//         const lender = state.lenders.find(it => it.id === mortgage.requirement.lenderId)
//         const config = {
//             to: [mortgage.applicant1.email],
//             subject: `${lender.lender} Terms & Conditions`,
//             onSent: () => {
//                 stages.setComplete('APPLY', 'sendTermsAndConditionsEmail')
//             }
//         }
//         toolDrawer.composeEmail(config)
//     }
//
//     const handleValuesChange = (values, form) => {
//         if (values.hasOwnProperty('lender')) {
//             setState(s => ({
//                 ...s,
//                 selectedLenderId: values.lender,
//             }))
//         }
//     }
//     const expandRate = rate => ({
//         ...rate,
//         key: `rateTableRow${rate.id}`,
//         rateLabel: `${rate.rate.toFixed(2)}%`,
//         termLabel: rate.term === 0 ? 'Variable' : `${rate.term} yr`,
//         buyToLetLabel: rate.buyToLet ? <CheckCircleOutlined/> : '',
//         maxLTVLabel: `<= ${rate.maxLTV}%`,
//         minimumLabel: rate?.minimum ? `€${rate.minimum.toLocaleString('en-US')}` : '',
//         promotionsLabel: rate?.promotions ? rate.promotions.join(', ') : '',
//         conditionsLabel: rate?.conditions ? rate.conditions.join(', ') : ''
//     })
//
//     let rates = []
//     if (!!lendersContext.lenders && state.selectedLenderId) {
//         const lender = lendersContext.lenders.find(it => it.id === state.selectedLenderId)
//         if (lender) {
//             rates = lender.rates.map(r => expandRate(r))
//         }
//     }
//
//     const clearLenderProduct = async ()=>{
//         await mortgage.mutate({
//             update: {
//                 target: `requirement.lenderProduct`,
//                 value : null
//             }
//         })
//     }
//     const handleFinish = async (form) => {
//
//         setState(s => ({...s, saving: true}))
//         const len = lendersContext.lenders.find(it => it.id === state.selectedLenderId)
//         const update = {
//             target: `requirement`,
//             pairs: {
//                 lenderId: form.lender,
//                 lenderCode: len.lenderCode,
//             }
//         }
//         if (form.hasOwnProperty('lenderProduct')) {
//             update.pairs.lenderProduct = form.lenderProduct
//         } else if (form.hasOwnProperty('rate')) {
//             const rate = rates.find(r => r.id === form.rate)
//             let type = rate.term === 0 ? 'Variable' : `${rate.term}yr`
//             update.pairs.rate = rate.rate
//             update.pairs.rateId = form.rate
//             update.pairs.lenderProduct = `${rate.rate}% ${type} ${rate.conditionsLabel} LTV ${rate.maxLTVLabel}`
//         }
//         await mortgage.mutate({update})
//         setState(s => ({...s, saving: false}))
//     }
//     useEffect(() => {
//         if (!!mortgage.requirement.lenderProduct) {
//             form.setFieldValue('lenderProduct', mortgage.requirement.lenderProduct)
//         }
//     }, [mortgage.requirement.lenderProduct]);
//     if (!lendersContext.lenders.length) {
//         return null
//     }
//
//
//
//     return (
//         <Card title="Lender Selection">
//             {!!lendersContext.lenders && (
//                 <Form
//                     form={form}
//                     initialValues={{
//                         lender: mortgage.requirement.lenderId,
//                         rate: mortgage.requirement.rateId,
//                         lenderProduct: mortgage.requirement.lenderProduct
//                     }}
//                     onFinish={handleFinish}
//                     onValuesChange={handleValuesChange}
//                     className="m-15"
//                     layout="horizontal"
//                 >
//
//                     <Form.Item
//                         label="Lender"
//                         name="lender"
//                         rules={[
//                             {required: true, message: 'A lender is required'}
//                         ]}
//                     >
//                         <Select
//                             options={lendersContext.lenders.map(item => ({value: item.id, label: item.lender}))}/>
//                     </Form.Item>
//
//
//                     {/*{*/}
//                     {/*    !!mortgage.requirement.lenderProduct && (*/}
//                     {/*        <div className="d-row w-100 gap-15">*/}
//                     {/*            <Form.Item*/}
//                     {/*                className="grow"*/}
//                     {/*                label="Current Product"*/}
//                     {/*                name="lenderProduct"*/}
//                     {/*            >*/}
//                     {/*                <Input/>*/}
//                     {/*            </Form.Item>*/}
//                     {/*            <Button onClick={clearLenderProduct}>Clear</Button>*/}
//                     {/*        </div>*/}
//                     {/*    )*/}
//                     {/*}*/}
//
//                     {/*{*/}
//                     {/*    (!mortgage.requirement.lenderProduct && rates.length > 0) && (*/}
//                     {/*        <Form.Item*/}
//                     {/*            label="Rate"*/}
//                     {/*            name="rate"*/}
//                     {/*            rules={[*/}
//                     {/*                {required: true, message: 'A rate is required'}*/}
//                     {/*            ]}>*/}
//                     {/*            <Select popupMatchSelectWidth={400} options={rates.sort(sorter).map(item => ({*/}
//                     {/*                value: item.id,*/}
//                     {/*                label: <div className="d-row j-end gap-15">*/}
//
//                     {/*                    <Tag>{item.term} yr</Tag>*/}
//                     {/*                    <Tag className="me-auto">{item.rate}%</Tag>*/}
//                     {/*                    {!!item.conditions?.length && <Tag>{item.conditionsLabel}</Tag>}*/}
//                     {/*                    {!!item.minimum && <Tag>Min {item.minimumLabel}</Tag>}*/}
//                     {/*                    <Tag>{item.maxLTVLabel}</Tag>*/}
//
//
//                     {/*                </div>*/}
//                     {/*            }))}/>*/}
//                     {/*        </Form.Item>*/}
//                     {/*    )*/}
//                     {/*}*/}
//
//                     <Button htmlType="submit">
//                         Save
//                         {state.saving && (<LoadingOutlined className="ms-6"/>)}
//                     </Button>
//
//                 </Form>
//             )}
//         </Card>
//     )
// }
function Lender(props) {
    return (
        <LenderSelection />
    );
}

export default Lender;