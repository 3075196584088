import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Item} from './task-item/Item';

export function SortableItem(props) {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: props.id,
        axis: 'y',
        disabled: props.disabled,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <Item ref={setNodeRef} style={style} {...attributes} {...listeners}
              task={props.task}
              folders={props.folders}
              sorting={props.sorting}
              completed={props.completed}
        />

    );
}