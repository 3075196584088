import React from 'react';
import {Form, InputNumber, Popover} from "antd";
import {currencyFormatter, currencyParser} from "../../../../../../../../assets/scripts/parser-functions";
import {CustomInputNumber} from "./CustomInputs";
import ApplicantsRowBuilder from "./ApplicantsRowBuilder";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {orange} from "@ant-design/colors";

function EditPra({
    mortgage,
    rate,
    figures
})
{
    const childcareCostsRow = () => {
        const appChildcare = (applicant) => {
            const needsChildmindingCosts = applicant.personal.countDependants > 0 && applicant.financial.hasChildcareCosts
            if (needsChildmindingCosts) {
                return applicant.financial.monthlyChildcareCosts
            }
            return false
        }
        const applicant1Childcare = appChildcare(mortgage.applicant1)
        const applicant2Childcare = mortgage.twoApplicants && appChildcare(mortgage.applicant2)

        if (!applicant1Childcare && !applicant2Childcare) {
            return null
        }

        const totalChildcare = applicant1Childcare + applicant2Childcare

        return (<tr>
            <td>Childminding Costs</td>
            <td>
                <CustomInputNumber value={applicant1Childcare} disabled={true}/>
            </td>
            {mortgage.twoApplicants && <td>
                <CustomInputNumber value={applicant2Childcare} disabled={true}/>
            </td>}
            {mortgage.twoApplicants && <td>
                <CustomInputNumber disabled={true} value={totalChildcare}/>
            </td>}
        </tr>)
    }

    const maintenanceCostsRow = () => {
        const appMaintenance = (applicant) => {
            if (applicant.financial.paysMaintenance) {
                return applicant.financial.maintenanceCosts
            }
            return false
        }
        const applicant1Maintenance = appMaintenance(mortgage.applicant1)
        const applicant2Maintenance = mortgage.twoApplicants && appMaintenance(mortgage.applicant2)

        if (!applicant1Maintenance && !applicant2Maintenance) {
            return null
        }

        const totalMaintenance = applicant1Maintenance + applicant2Maintenance

        return (<tr>
            <td>Maintenance Costs</td>
            <td>
                <CustomInputNumber value={applicant1Maintenance} disabled={true}/>
            </td>
            {mortgage.twoApplicants && <td>
                <CustomInputNumber value={applicant2Maintenance} disabled={true}/>
            </td>}
            {mortgage.twoApplicants && <td>
                <CustomInputNumber disabled={true} value={totalMaintenance}/>
            </td>}
        </tr>)
    }

    let childcareCosts = childcareCostsRow()
    let maintenanceCosts = maintenanceCostsRow()

    return (<div>
        <table>
            <thead>
            <tr>
                <th></th>
                <th>{mortgage.twoApplicants && "Applicant 1"}</th>
                {mortgage.twoApplicants && <th>Applicant 2</th>}
                {mortgage.twoApplicants && <th>Total</th>}
            </tr>
            </thead>
            <tbody>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Accommodation Costs" name="accommodationCosts" type="euro" totalValue={figures.accommodationCostsTotal}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Loan Discontinuing" name="clearingLoans" type="euro" totalValue={figures.clearingLoansTotal}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Other PRA" name="otherPRA" type="euro" totalValue={figures.otherPRATotal}/>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Savings Rate" name="savings" type="euro" totalValue={figures.savingsTotal}/>
            <tr>
                <td> {!mortgage.twoApplicants && 'Total PRA'}</td>
                <td>
                    {!mortgage.twoApplicants && <CustomInputNumber disabled={true} value={figures.praTotal}/>}
                </td>
                {mortgage.twoApplicants && <td>
                    Total PRA
                </td>}
                {mortgage.twoApplicants && <td>
                    <CustomInputNumber disabled={true} value={figures.praTotal}/>
                </td>}
            </tr>

            </tbody>

            <thead>
            <tr>
                <th>&nbsp;</th>
                <th></th>
                {mortgage.twoApplicants && <th></th>}
                {mortgage.twoApplicants && <th></th>}
            </tr>
            </thead>
            <tbody>
            <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Loan Commitments" name="loans" type="euro" totalValue={figures.loansTotal}/>
            {mortgage.applicant1.personal.countDependants > 0 &&
                <ApplicantsRowBuilder twoApplicants={mortgage.twoApplicants} label="Dependants" name="dependants" type="euro" totalValue={figures.dependantsTotal}/>}
            </tbody>

            {childcareCosts}
            {maintenanceCosts}
        </table>
    </div>);
}

export default EditPra;