import {buildToDo, isStepDone, updateCompletedFlowItems} from "./index";
import dayjs from "dayjs";

/*
How to handle if valuation does not come in - send email or what

We need to identify attachemnt as a valuation - is 2nd required + reinsatement value + other details

Customer Documents - create DD Mandate - is this going to APPLY?

Select Interest Rate - Have a Chat again?

I see someone can ask for an extension - whats the flow for this?





*/

export const sendAIPReceivedEmail = (mortgage, {}) => {
    let dueAfter = dayjs().add(1, 'day')
    // if (mortgage.completedFlowItems.hasOwnProperty('SetupUploadsVerified')) {
    //     dueAfter = dayjs(mortgage.completedFlowItems.SetupUploadsVerified).add(5, 'day')
    // }

    return buildToDo('PREAPPROVAL', 'sendAIPReceivedEmail', {
        mortgage,
        dueAfter,
        toDo: {
            for:'support',
            title: `Send AIP Received Email`,
            emails: [
                {
                    name: 'initial',
                    title: "Send AIP Received Email",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Congratulations! Your AIP has been received',
                            body: '',//currentApplyOutstandingItemsEmailBody(mortgage)
                            /*
                            DONT KNOW PROPERTY
                            Email to find property - how to handle paying deposit


                            KNOW PROPETYY
                            Email to instruct valuation + solicitor details
                            Pre-approval Uploads
                             */
                            from : 'help'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: "Send Missing Items Reminder",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
                            body: '',//currentApplyOutstandingItemsEmailBody(mortgage)
                            from : 'help'
                        }
                    },
                    dueAfter: 6,
                }
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','verifyUploads.button.verified')
                // return m.completedFlowItems.hasOwnProperty('PreApprovalUploadsVerified')
            }
        }
    })
}

export const sendMissingItemsEmail = (mortgage, {}) => {
    //let dueAfter = dayjs().subtract(1, 'day')
    // if (mortgage.completedFlowItems.hasOwnProperty('SetupUploadsVerified')) {
    //     dueAfter = dayjs(mortgage.completedFlowItems.SetupUploadsVerified).add(5, 'day')
    // }

    return buildToDo('PREAPPROVAL', 'sendMissingItemsEmail', {
        mortgage,
        // dueAfter,
        toDo: {
            for:'support',
            title: `Send missing items email`,
            emails: [
                {
                    name: 'initial',
                    title: "Send Missing Items Email",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
                            body: '',//currentApplyOutstandingItemsEmailBody(mortgage)
                            from : 'help'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: "Send Missing Items Reminder",
                    email: () => {
                        return {
                            to: [mortgage.applicant1.email],
                            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
                            body: '',//currentApplyOutstandingItemsEmailBody(mortgage)
                            from : 'help'
                        }
                    },
                    dueAfter: 6,
                }
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','verifyUploads.button.verified')
              //  return m.completedFlowItems.hasOwnProperty('PreApprovalUploadsVerified')
            }
        }
    })
}
export const verifyUploads = (mortgage) => {

    return buildToDo('APPLY', 'verifyUploads', {
        mortgage,
        toDo: {
            title: `Verify uploads & record info`,
            tab: 'Uploads',
            buttons: [
                {
                    label: "Uploads Verified",
                    name: 'verified',
                    onClick: async () => {
                       // await updateCompletedFlowItems(mortgage, 'PreApprovalUploadsVerified')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','verifyUploads.button.verified')
              //  return m.completedFlowItems.hasOwnProperty('PreApprovalUploadsVerified')
            }
        }
    })
}


// !!!!! make sure that a medical is not required for this mortgage - if they ahs any issues


export const packageOfferRequest = (mortgage) => {
    return buildToDo('PREAPPROVAL', 'packageOfferRequest', {
        mortgage,
        toDo: {
            title: 'Kevin - Package Offer Request',
            tab: 'Bundle & Submit',
            for:'advisor',
            buttons: [
                {
                    label: "Packaged",
                    name: 'packaged',
                    onClick: async () => {
                        // await updateCompletedFlowItems(mortgage, 'OfferRequestPackaged')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','packageOfferRequest.button.packaged')
            //   return m.completedFlowItems?.hasOwnProperty('OfferRequestPackaged')
            }
        }
    })
}
export const submitOfferRequest = (mortgage) => {
    return buildToDo('PREAPPROVAL', 'submitOfferRequest', {
        mortgage,
        toDo: {
            title: 'Kevin - Submit Offer Request',
            tab: 'Bundle & Submit',
            for:'advisor',
            buttons: [
                {
                    label: "Submitted",
                    name: 'submitted',
                    onClick: async () => {
                        // await updateCompletedFlowItems(mortgage, 'OfferRequestSubmitted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','submitOfferRequest.button.submitted')
               // return m.completedFlowItems?.hasOwnProperty('OfferRequestSubmitted')
            }
        }
    })
}
export const havenReceivedOfferRequest = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'PREAPPROVAL', 'submitOfferRequest.button.submitted')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(1,'day') : 0
    const build = buildToDo('PREAPPROVAL', 'havenReceivedOfferRequest', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Received',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Received By Haven",
                    name: 'receivedByLender',
                    onClick: async () => {
                      //  await updateCompletedFlowItems(mortgage, 'OfferRequestReceived')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','havenReceivedOfferRequest.button.receivedByLender')
            //    return m.completedFlowItems?.hasOwnProperty('OfferRequestReceived')
            }
        }
    })

    return build
}
export const havenAcceptedOfferRequest = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'PREAPPROVAL', 'havenReceivedOfferRequest.button.receivedByLender')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(3,'day') : 0
    return buildToDo('PREAPPROVAL', 'havenAcceptedOfferRequest', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Accepted',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Accepted By Haven",
                    name: 'acceptedByLender',
                    onClick: async () => {
                     //   await updateCompletedFlowItems(mortgage, 'OfferRequestAccepted')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','havenAcceptedOfferRequest.button.acceptedByLender')
              //  return m.completedFlowItems?.hasOwnProperty('OfferRequestAccepted')
            }
        }
    })
}
export const havenConfirmedAcceptableOfferRequest = (mortgage) => {
    let isPrevDone = isStepDone(mortgage, 'PREAPPROVAL', 'havenAcceptedOfferRequest.button.acceptedByLender')
    let dueAfter = isPrevDone ? dayjs(isPrevDone.on).add(3,'day') : 0
    return buildToDo('PREAPPROVAL', 'havenConfirmedAcceptableOfferRequest', {
        mortgage,
        dueAfter,
        toDo: {
            title: 'Has Haven Confirmed',
            tab: 'Bundle & Submit',
            buttons: [
                {
                    label: "Confirmed By Haven",
                    name: 'confirmedByLender',
                    onClick: async () => {
                      // await updateCompletedFlowItems(mortgage, 'OfferRequestConfirmed')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'PREAPPROVAL','havenConfirmedAcceptableOfferRequest.button.confirmedByLender')
              //  return m.completedFlowItems?.hasOwnProperty('OfferRequestConfirmed')
            }
        }
    })
}

export const toDos = [
    sendAIPReceivedEmail,
    sendMissingItemsEmail,
    verifyUploads,
    packageOfferRequest,
    submitOfferRequest,
    havenReceivedOfferRequest,
    havenAcceptedOfferRequest,
    havenConfirmedAcceptableOfferRequest
]