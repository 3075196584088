
export function sectionMetaData(mortgage, sectionName, sectionIndex){
    let record
    if (!!sectionIndex){
        record = mortgage.progress.find(p => p.sectionName === sectionName && p.sectionIndex === sectionIndex)
    }
    else {
        record = mortgage.progress.find(p => p.sectionName === sectionName && !p.sectionIndex)
    }
    if (!record){
        console.log({sectionName, sectionIndex, mortgage})
    }
    return JSON.parse(record.metaData || '{}')
}