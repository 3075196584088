import React, {useContext, useEffect, useMemo, useState} from 'react';
import {AutoComplete, Button, Col, Form, Input, Modal, Row, Select, Space, Typography} from "antd";
import ItemToDo from "./ItemToDo";
import ItemMessaging from "./ItemMessaging";
import ItemControls from "./ItemControls";
import {MyAdminTasks} from "../../../../../providers/MyAdminTasksProvider";
import {AimOutlined, EditOutlined, FolderOutlined, LoadingOutlined, PushpinOutlined} from "@ant-design/icons";
import ButtonAsync from "../../../../elements/ButtonAsync";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";
import {gray} from "@ant-design/colors";

function ItemModal(props) {
    const backendUsers = useBackendUsers()
    const {operations} = useContext(MyAdminTasks);
    const [folderForm] = Form.useForm()
    const [state, setState] = useState({
        currentOwnerBusy: false,
        titleEditing: !props.task.body || props.task.body.length === 0,
        titleBusy: false
    })
    if (!props.open) return null
    const handlePinToggle = async (e) => {
        await operations.update({
            id: props.task.id,
            pinned: !props.task.pinned,
            _version: props.task._version
        })
    }
    const handleFolderClick = async () => {
        const folder = folderForm.getFieldValue('folder')
        if (!folder) return
        const existing = {...props.task.userFolder}
        existing[backendUsers.me.sub] = folder
        await operations.update({
            id: props.task.id,
            folder,
            userFolder: JSON.stringify(existing),
            _version: props.task._version
        })
    }
    const handleCurrentOwnerChange = async (value) => {
        setState(s => ({...s, currentOwnerBusy: true}))
        let existingPosition = props.task.userSortPosition || {}
        existingPosition[value] = 1
        await operations.update({
            id: props.task.id,
            currentOwner: value,
            userSortPosition: JSON.stringify(existingPosition),
            _version: props.task._version
        })
        setState(s => ({...s, currentOwnerBusy: false}))
    }
    const handleTargetChange = async (value) => {
        setState(s => ({...s, currentOwnerBusy: true}))
        await operations.update({
            id: props.task.id,
            target: value,
            currentOwner: value,
            userSortPosition: JSON.stringify({[value]:0.95}),
            _version: props.task._version
        })
        setState(s => ({...s, currentOwnerBusy: false}))
    }
    const handleEditTitle = () => {
        setState(s => ({...s, titleEditing: true}))
    }
    const handleSaveTitle = async (e) => {
        console.log({e})
        setState(s => ({...s, titleBusy: true}))
        await operations.update({
            id: props.task.id,
            body: [e.target.value],
            _version: props.task._version
        })
        setState(s => ({...s, titleBusy: false, titleEditing: false}))
    }

    function ModalHeader() {
        return useMemo(() => {
            let value = props.task.body || [].join(' ')
            return (
                <>
                    <div className="me-24 mb-15">
                        {state.titleEditing ? (
                            <Input
                                placeholder="Title for your task..."
                                size="large"
                                rootClassName="grow"
                                defaultValue={value}
                                onPressEnter={handleSaveTitle}
                                onBlur={handleSaveTitle}
                                suffix={state.titleBusy ? <LoadingOutlined/> : null}
                            />
                        ) : (
                            <div className="d-row gap-9 a-center">

                                {
                                    !state.titleBusy && <EditOutlined className="mt-3" onClick={handleEditTitle}/>
                                }
                                <Typography.Title className="m-0" level={4}
                                                  style={{color: gray[3]}}>{value}</Typography.Title>
                            </div>
                        )}
                    </div>
                    <div className="d-row j-end gap-15 a-center">
                        <div className="d-col me-auto">
                            {props.children}
                        </div>

                        {/*<ButtonAsync icon={<PushpinOutlined/>} type={props.task.pinned ? 'primary' : 'default'}*/}
                        {/*                 onClick={handlePinToggle}> {props.task.pinned ? 'Pinned' : 'Pin'} </ButtonAsync>*/}
                        <Form
                            form={folderForm}
                            initialValues={{folder: props.task.userFolder && props.task.userFolder.hasOwnProperty(backendUsers.me.sub) ? props.task.userFolder[backendUsers.me.sub] : props.task.folder}}
                            className="me-24"
                        >
                            <Space.Compact>
                                <Form.Item noStyle name="folder">
                                    <AutoComplete style={{width: 150}} placeholder="Folder ..."
                                                  options={(props.folders || []).map(f => ({label: f, value: f}))}
                                    />
                                </Form.Item>
                                <ButtonAsync icon={<FolderOutlined/>} onClick={handleFolderClick}> Put in
                                    Folder</ButtonAsync>
                            </Space.Compact>

                        </Form>
                    </div>
                    {!!props.task.target && (
                        <Form.Item label="Has the ball" className="mt-15 mb-6">
                            <Select onSelect={handleCurrentOwnerChange}
                                    suffixIcon={state.currentOwnerBusy ? <LoadingOutlined/> : <AimOutlined/>}
                                    defaultValue={props.task.currentOwner}
                                    style={{width: 120}}
                                    options={backendUsers.users.map(u => {
                                        return {label: u.firstName, value: u.sub}
                                    })}/>
                        </Form.Item>
                    )}
                    {!props.task.target && (
                        <Form.Item label="For" className="mt-15 mb-6">
                            <Select onSelect={handleTargetChange}
                                    suffixIcon={state.currentOwnerBusy ? <LoadingOutlined/> : <AimOutlined/>}
                                    style={{width: 120}}
                                    options={backendUsers.users.map(u => {
                                        return {label: u.firstName, value: u.sub}
                                    })}
                            />
                        </Form.Item>
                    )}
                </>
            )
        },[])
    }


    return (
        <Modal footer={false} open={true} width={850} closable onCancel={props.onCancel}>
            <ModalHeader/>

            <Row className="mt-15 pt-15" gutter={24} style={{borderTop: '1px solid #eee'}}>
                <Col xs={24} md={9} style={{borderRight: '1px solid #e7e7e7'}}>
                    <div className="d-col h-100">
                        <ItemToDo taskID={props.task.id}/>
                        <div className="mt-auto pt-15">
                            <ItemControls task={props.task}/>
                        </div>
                    </div>


                </Col>
                <Col xs={24} md={15}>
                    <ItemMessaging taskID={props.task.id}/>
                </Col>
            </Row>

        </Modal>
    );
}

export default ItemModal;