import React, {useContext, useEffect, useState} from 'react';
import {listAllAndSetItems, performGQL} from "../../../../api-functions/functions";
import {adminMortgageTaskComponentsByTaskID, adminMortgageTaskToDosByTaskID} from "../../../../../graphql/queries";
import useBackendUsers from "../../../../providers/mortgage/useBackendUsers";
import {
    createAdminMortgageTaskComponent,
    createAdminMortgageTaskToDo,
    updateAdminMortgageTasks
} from "../../../../../graphql/mutations";
import dayjs from "dayjs";
import {SYSTEMIDENTITYID} from "../../../../../assets/scripts/constants";
import useS3 from "../../../../providers/useS3";
import {MyAdminTasks} from "../../../../providers/MyAdminTasksProvider";

function useMessages(props) {
    const s3 = useS3()
    const adminTasks = useContext(MyAdminTasks)
    const backendUsers = useBackendUsers()
    const [messages, setMessages] = React.useState([]);
    const [uploadingFileList, setUploadingFileList] = useState([]);
    const customSetMessages = (newMessages) => {
        setMessages(s=>{
            return [...s, ...newMessages.filter(m=>!m._deleted).filter(m => !s.find(t => t.id === m.id)).map(m=>{
                return {
                    ...m,
                    component: JSON.parse(m.component),
                    mine: backendUsers.me.sub === m.author
                }
            })]
        })
    }
    const load = async ()=>{
        await listAllAndSetItems({taskID: props.taskID}, adminMortgageTaskComponentsByTaskID, customSetMessages)
    }
    const updateTaskWithLastMessage = (object)=> {
        const task = adminTasks.tasks.find(t=>t.id === props.taskID)
        if (task){
            adminTasks.operations.update({
                id: props.taskID,
                lastMessageBy: backendUsers.me.sub,
                lastMessageOn: new Date().toISOString(),
                lastMessage: object.type === 'text' ? object.content : `[${object.type}]`,
                _version: task._version
            })
        }
    }

    const saveMessage = async (object) => {
        updateTaskWithLastMessage(object)
        const query = createAdminMortgageTaskComponent
        const variables = {
            input: {
                component: JSON.stringify(object),
                taskID: props.taskID,
                author: backendUsers.me.sub
            }
        }
        const result = await performGQL(variables, query)

        if (result) {
            customSetMessages([result])
        }
    }
    const moveUploadToMessages = async (file, key) => {

        const object = {
            type: 'upload',
            key,
            contentType: file.type,
        }
        const liveFile = uploadingFileList.find(it=>it.uid === file.uid)
        if (liveFile) {
            object.thumbUrl = liveFile.thumbUrl
        }
        await saveMessage(object)
        setUploadingFileList(s=>s.filter(it=>it.uid !== file.uid))
    }
    const saveTextMessage = async (text)=>{
        await saveMessage({
            type: 'text',
            content: text
        })
    }
    const uploadRequest = async ({file, onError, onSuccess, onProgress}) => {

        const date = dayjs().format('YYYY-MM-DD')
        const path = `task-uploads/${props.taskID}/${date}/${file.uid}/${file.name}`
        const config = {
            progressCallback: (progress) => {
                // Call the onProgress function to report upload progress

                onProgress({percent: Math.round((progress.loaded / progress.total) * 100)});
            },
            completeCallback: (event) => {
                onSuccess(event)
                moveUploadToMessages(file, path)
            },
            errorCallback: (event) => {

                onError(event)
            },
            contentType: file.type,
            level: "private",
            identityId: SYSTEMIDENTITYID,
            resumable: true,
        }
        const result = await s3.put(path, file, config)

    }
    const openUpload = async (key, contentType) => {
        const config = {
            level: "private",
            identityId: SYSTEMIDENTITYID,
            contentType
        }

        const url = await s3.getS3Url({path:key, config})

        s3.openDocument(url)
    }

    useEffect(() => {
        if (backendUsers.me) {
            load()
        }
    }, [backendUsers.me]);



    return {uploadRequest, openUpload, uploadingFileList, setUploadingFileList, messages: messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)), saveTextMessage}
}

export default useMessages;