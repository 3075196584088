import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import Employment from "../../../_common/verified-figures/employment/Employment";

function useApprovalAdminDocuments(props) {

    // 1. Items to copy into customer lender documents folder
    // 2. Items to create for use as download template for customer uploads
    // 3. Items to create to be docusigned by customer

    const mortgage = useMortgage()
    const items = []
    let folder = 'submission-documents'
    // 1. Lender Checklist
    switch (mortgage.requirement.lenderCode) {
        case 'avant':
            // home insurance if not an apartment

            // direct -debit mandate

            // mortgage protection policy certificate


            break;
        case 'ptsb':


            break;
        case 'haven':

            break;
    }


    return items.map(item => {
        return {
            ...item,
            key: item.name,
            folder: item.folder ? item.folder : folder,
            private: true,
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === folder && doc.name === item.name),
        }
    })

}

export default useApprovalAdminDocuments;