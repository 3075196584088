import React, {useEffect, useState} from 'react';
import {gray, green, volcano} from "@ant-design/colors";
import QuestionTabs from "./_common/QuestionTabs";
import {Alert, Button, Collapse, Spin, Tabs} from "antd";

import useMortgage from "../../../../../providers/mortgage/useMortgage";

import usePlayground, {getMortgageFigures} from "./usePlayground";
import dayjs from "dayjs";
import {ApplicationTypes} from "../../../../../../models";
import ProposalEditor from "./ProposalEditor";

function Index(props) {
    const mortgage = useMortgage()
    const {Playground, lenders, selected} = usePlayground({mortgage})
    const items = lenders.map(lender => ({
        ...lender,
        label: <Spanner api={lender.api}>{lender.key}</Spanner>,
        children: <LenderTab api={lender.api} selected={selected}/>
    }))

    function Spanner({children, api = false}) {
        let bgColor = gray.primary
        console.log({api})
        if (selected!=='live' && api) {
            bgColor = api.allPassed ? green.primary : volcano.primary
        }
        return <div className="mx-24">
            <div className="d-row a-center">
                {children}
                {api?.state?.loading && <Spin className="ms-3" size="small"/>}
                {!api?.state?.loading && (
                    <span style={{
                        marginLeft: 8,
                        display: "inline-block",
                        backgroundColor: bgColor,
                        height: 10,
                        width: 10,
                        borderRadius: 5
                    }}/>
                )}

            </div>
        </div>
    }

    const warnings = []
    let app1dob = dayjs(mortgage.applicant1.personal.dateOfBirth)
    let app1age = dayjs().diff(app1dob, 'years')
    let minAge = app1age
    if (mortgage.twoApplicants){
        let app2dob = dayjs(mortgage.applicant2.personal.dateOfBirth)
        let app2age = dayjs().diff(app2dob, 'years')
        minAge = Math.min(app1age, app2age)
    }
    if (minAge + mortgage.requirement.loanTerm > 65) {
        warnings.push(`Term exceeds retirement age`)
    }
    const data = getMortgageFigures(mortgage)
    let factor = mortgage.applicationType === ApplicationTypes.FIRSTTIME ? 4 : 3.5
    if ( ((data.basic1 + (data.basic2 || 0) ) * factor) < mortgage.requirement.loanAmount) {
        warnings.push(`Loan exceeds CBI limit`)
    }



    return (
        <>

        <ProposalEditor/>
            <div className="d-row">

                <div style={{backgroundColor: "#e0e0e0", width: 400}} className="p-9">
                    <span style={{color: 'red'}}>{warnings.join(', ')}</span>
                    <Playground/>
                </div>
                <Tabs
                    rootClassName="mt-6 w-100"
                tabBarStyle={{marginBottom: 0}}
                tabBarGutter={12}
                defaultActiveKey="ptsb"
                centered
                size="large"
                items={items}
            />
        </div>
        </>
    );
}
function GetResult({api}) {

    if (api.state.error) {
        return <>
            <Alert message={api.state.error} description={<Button onClick={api.getResults}>Test again</Button>}
                   type="error"/>
        </>
    }
    if (!api.state.loading && api.state.results) {
        return api.resultComponent()
    }
    return null
}

function LenderTab({api, selected}) {
    if (!api) {
        return null
    }
    const items = [
        {
            key: '1',
            label: 'Config',
            children: <QuestionTabs dataPoints={api.dataPoints} setAnswer={api.setAnswer} form={api.form}/>,
        }
    ];
    return (
        <>
            {selected !== 'live' && <GetResult api={api}/>}
            <Collapse className="mt-3 w-100" items={items} defaultActiveKey={['0']} />

        </>
    )
}
export default Index;