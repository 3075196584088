import {AssetTypes, DebtTypes, EmploymentTypes, Frequencies, PersonalAccommodationSituations} from "../../models";
import {capitaliseFirst, toEuroString} from "./string-functions";
import {ptsb} from "./lender-calculators"; //DON'T DELETE !!!         I have no idea what this is supposed to be, but webpack fails without it.
import {ptsbCoverNoteCoords} from "./lender-calculators/ptsb";
import {havenCoverNoteCoords} from "./lender-calculators/haven";
import {
    getMortgageFigures
} from "../../app/components/client/application-stages/_common/lender-tests/usePlayground";

import {savingsDataSource} from "./verifiedFigures";
import {avantCoverNoteCoords} from "./lender-calculators/avant";
import {verifiedAccounts} from "../../app/components/client/application-stages/_common/funding/verifiedAccounts";
import {jobPayslips, jobSalaryCert} from "../../app/components/client/application-stages/_common/have-a-chat/employment/PayslipHistory";



export const getApplicantIncomeFromJobs = (mortgage, applicant, sections) => {
    let legacyEmployment = !mortgage[`applicant${applicant}`].employmentIncome?.length || !mortgage[`applicant${applicant}`].employmentIncome[0].employmentType
    let jobs = []
    const payslipAverageGrossYearly = (payslipSection) => {
        let payslipData = payslipSection ? JSON.parse(payslipSection.metaData || '{}') : {}
        if (payslipData.hasOwnProperty('payFrequency') && payslipData.hasOwnProperty('payAmountsGross')) {
            let amts = Object.values(payslipData.payAmountsGross.filter(it => !!it))
            let average = parseInt(amts.reduce((acc, cur) => acc + cur, 0) / amts.length)
            switch (payslipData.payFrequency) {
                case Frequencies.MONTHLY:
                    return average * 12;
                case Frequencies.WEEKLY:
                    return average * 52;
                case Frequencies.FORTNIGHTLY:
                    return average * 26;
                case Frequencies.QUARTERLY:
                    return average * 4;
            }
        }
        return 0
    }
    const salaryCertificateYearly = (salarySection) => {
        let salaryCertData = salarySection ? JSON.parse(salarySection.metaData || '{}') : {}
        if (salaryCertData.hasOwnProperty('grossPay')) {
            return salaryCertData.grossPay
        }
        return 0
    }

    if (legacyEmployment) {
        // PAYSLIP
        let works = [
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(mortgage[`applicant${applicant}`].employment.employmentType)
        if (works) {

            //let payslipSection = mortgage.progress.find(it => it.sectionType === 'UPLOAD' && it.sectionName === `payslips-${applicant}` && !it.sectionIndex)
            //let salarySection = mortgage.progress.find(it => it.sectionType === 'UPLOAD' && it.sectionName === `salary-cert-${applicant}` && !it.sectionIndex)
            let payslipSection = mortgage.mortgageUploads.apply.find(s => s.name === `payslips-${applicant}` && !s.index)
            let salarySection = mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${applicant}` && !s.index)

            let uploadSectionPayslip = sections.find(it => it.name === `payslips-${applicant}` && !it.index)
            let uploadStringPayslip = uploadSectionPayslip?.uploads?.length > 0 ? `(${uploadSectionPayslip.uploads.length} uploads)` : 'No Uploads'

            let uploadSectionSalaryCert = sections.find(it => it.name === `salary-cert-${applicant}` && !it.index)
            let uploadStringSalaryCert = uploadSectionSalaryCert?.uploads?.length > 0 ? `(${uploadSectionSalaryCert.uploads.length} uploads)` : 'No Uploads'

            let work = {
                errors     : [],
                withUploads: true
            };
            console.log({payslipSection, salarySection})
            if (!salarySection.record || !salarySection.record.sectionChecked) {
                work.errors.push("Salary Cert uploads have not been completed - " + uploadStringSalaryCert)
            }
            if (!payslipSection.record || !payslipSection.record.sectionChecked) {
                work.errors.push("Payslips uploads have not been completed - " + uploadStringPayslip)
            }
            let x = jobPayslips(payslipSection)
            let y = jobSalaryCert(salarySection, mortgage[`applicant${applicant}`].employment.payFrequency)
            console.log({x,y})
            if (!work.errors.length) {
                let declared = mortgage[`applicant${applicant}`].income.grossBasic
                let employment = mortgage[`applicant${applicant}`].employment
                work = {
                    basis                    : employment.employmentType === EmploymentTypes.SELFEMPLOYED ? "Self Employment" : capitaliseFirst(employment.employmentBasis),
                    payslipAverageGrossYearly: x.averageGrossBasic, //ToDO
                    salaryCertificateYearly  : y.grossBasic, //ToDO
                    declared,
                    index                    : false
                }
            }
            jobs.push(work)
        }

    }
    else {
        mortgage[`applicant${applicant}`].employmentIncome.forEach(item => {
            // PAYSLIP
            let works = [
                EmploymentTypes.EMPLOYED,
                EmploymentTypes.SELFEMPLOYED
            ].includes(item.employmentType)
            if (works) {
                let work = {
                    errors: []
                }
                //let payslipSection = mortgage.progress.find(it => it.sectionType === 'UPLOAD' && it.sectionName === `payslips-${applicant}` && it.sectionIndex === item.id)
                //let salarySection = mortgage.progress.find(it => it.sectionType === 'UPLOAD' && it.sectionName === `salary-cert-${applicant}` && it.sectionIndex === item.id)
                let payslipSection = mortgage.mortgageUploads.apply.find(s => s.name === `payslips-${applicant}` && s.index  === item.id)
                let salarySection = mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${applicant}` && s.index === item.id)
                console.log({payslipSection, salarySection})
                let uploadSectionPayslip = sections.find(it => it.name === `payslips-${applicant}` && it.index === item.id)
                let uploadStringPayslip = uploadSectionPayslip?.uploads?.length > 0 ? `(${uploadSectionPayslip.uploads.length} uploads)` : 'No Uploads'

                let uploadSectionSalaryCert = sections.find(it => it.name === `salary-cert-${applicant}` && it.index === item.id)
                let uploadStringSalaryCert = uploadSectionSalaryCert?.uploads?.length > 0 ? `(${uploadSectionSalaryCert.uploads.length} uploads)` : 'No Uploads'

                if (!payslipSection.record || !payslipSection.record.sectionChecked) {
                    work.errors.push("Payslips uploads have not been completed - " + uploadStringPayslip)
                }
                if (!salarySection.record || !salarySection.record.sectionChecked) {
                    work.errors.push("Salary Cert uploads have not been completed - " + uploadStringSalaryCert)
                }

                let x = jobPayslips(payslipSection)
                let y = jobSalaryCert(salarySection, item.payFrequency)
                console.log({x,y})
                if (!work.errors.length) {
                    let declared = item.grossBasic
                    work = {
                        basis                    : item.employmentType === EmploymentTypes.SELFEMPLOYED ? "Self Employment" : capitaliseFirst(item.employmentBasis),
                        payslipAverageGrossYearly: x.averageGrossBasic, //ToDO
                        salaryCertificateYearly  : y.grossBasic, //ToDO
                        declared,
                        index                    : item.id
                    }
                }
                jobs.push(work)
            }
        })
    }
    return jobs
}
export const getApplicantClaimedAnnualOtherIncome = (mortgage, applicant) => {
    let legacyEmployment = !mortgage[`applicant${applicant}`].employmentIncome?.length || !mortgage[`applicant${applicant}`].employmentIncome[0].employmentType
    let tot = 0
    let mApplicant = mortgage[`applicant${applicant}`]
    if (legacyEmployment) {
        if (mApplicant.income.incomeChecks) {
            mApplicant.income.incomeChecks.forEach(type => {
                switch (type) {
                    case 'OVERTIME':
                        tot += mApplicant.income.overtime;
                        break
                    case 'BONUS':
                        tot += mApplicant.income.bonus;
                        break
                    case 'COMMISSION':
                        tot += mApplicant.income.commission;
                        break
                    case 'SECONDJOB':
                        tot += mApplicant.income.secondjob;
                        break
                    case 'RENT':
                        tot += mApplicant.income.rentalIncome;
                        break
                    case 'DIVIDENDS':
                        tot += mApplicant.income.dividends;
                        break
                    case 'OTHER':
                        tot += mApplicant.income.other;
                        break
                }
            })
        }
    }
    else {
        let claimed = claimedIncome(mortgage)

        tot += claimed[`rest${applicant}`]
    }

    return tot
}
export const claimedIncome = (mortgage) => {
    const legacyEmployment = !mortgage.applicant1.employmentIncome?.length || !mortgage.applicant1.employmentIncome[0].employmentType

    if (legacyEmployment) {
        let myObject = {
            basic1     :0, // mortgage.applicant1.income.grossBasic || 0,
            basic2     : 0, //mortgage.twoApplicants ? mortgage.applicant2.income.grossBasic || 0 : 0,
            overtime1  : 0,
            overtime2  : 0,
            allowances1: 0,
            allowances2: 0,
            bonus1     : 0,
            bonus2     : 0,
            commission1: 0,
            commission2: 0,
            other1     : 0,
            other2     : 0,
            sector1    : 'self',
            sector2    : 'self',
            rest1      : 0,
            rest2      : 0
        };

        const addIncome = (app, obj) => {
            let mApplicant = mortgage[`applicant${app}`];
            mApplicant.income.incomeChecks.reduce((acc, cur) => {
                switch (cur) {
                    case 'OVERTIME':
                        acc[`overtime${app}`] = mApplicant.income.overtime;
                        break;
                    case 'BONUS':
                        acc[`bonus${app}`] = mApplicant.income.bonus;
                        break;
                    case 'COMMISSION':
                        acc[`commission${app}`] = mApplicant.income.commission;
                        break;
                    // case 'SECONDJOB':
                    //     tot += mApplicant.income.secondjob;
                    //     break;
                    // case 'RENT':
                    //     tot += mApplicant.income.rentalIncome;
                    //     break;
                    // case 'DIVIDENDS':
                    //     tot += mApplicant.income.dividends;
                    //     break;
                    case 'OTHER':
                        acc[`other${app}`] = mApplicant.income.other;
                        break;
                }
                return acc;
            }, obj);
        };

        // addIncome(1, myObject);
        // if (mortgage.twoApplicants) {
        //     addIncome(2, myObject);
        // }

        return myObject;

    }
    let basic1, basic2, overtime1, overtime2, allowances1, allowances2, bonus1, bonus2, commission1, commission2,
        other1, other2, sector1, sector2

    let cumulative1 = {}
    let jobs1 = []
    mortgage.applicant1.employmentIncome.forEach(it => {
        if ([
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(it.employmentType) && it.incomes) {
            let myInnerObject = {basic: it.grossBasic || 0}
            it.incomes.forEach(income => {

                if (!cumulative1.hasOwnProperty(income.name)) {
                    cumulative1[income.name] = 0
                }
                myInnerObject[income.name] = income.amount
                cumulative1[income.name] += income.amount
            })
            jobs1.push(myInnerObject)
        }
    })
    let cumulative2 = {}
    let jobs2 = []
    mortgage.applicant2.employmentIncome.forEach(it => {
        if ([
            EmploymentTypes.EMPLOYED,
            EmploymentTypes.SELFEMPLOYED
        ].includes(it.employmentType) && it.incomes) {
            let myInnerObject = {basic: it.grossBasic || 0}
            it.incomes.forEach(income => {
                if (!cumulative2.hasOwnProperty(income.name)) {
                    cumulative2[income.name] = 0
                }
                myInnerObject[income.name] = income.amount
                cumulative2[income.name] += income.amount
            })
            jobs2.push(myInnerObject)
        }
    })
    let basics1 = mortgage.applicant1.employmentIncome.map(it => [
        EmploymentTypes.EMPLOYED,
        EmploymentTypes.SELFEMPLOYED
    ].includes(it.employmentType) ? it.grossBasic || 0 : 0)
    basic1 = basics1.reduce((a, b) => a + b, 0)
    let basics2 = mortgage.twoApplicants ? mortgage.applicant2.employmentIncome.map(it =>[
        EmploymentTypes.EMPLOYED,
        EmploymentTypes.SELFEMPLOYED
    ].includes(it.employmentType) ?  it.grossBasic || 0 : 0) : []
    basic2 = basics2.reduce((a, b) => a + b, 0)

    sector1 = mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !!it.publicSector) ? 'public' : mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !it.publicSector) ? 'private' : 'self'
    sector2 = mortgage.twoApplicants ? mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !!it.publicSector) ? 'public' : mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED && !it.publicSector) ? 'private' : 'self' : null

    overtime1 = cumulative1['overtime'] || 0
    overtime2 = cumulative2['overtime'] || 0

    allowances1 = cumulative1['shiftAllowance'] || 0
    allowances2 = cumulative2['shiftAllowance'] || 0

    bonus1 = cumulative1['bonus'] || 0
    bonus2 = cumulative2['bonus'] || 0

    commission1 = cumulative1['commission'] || 0
    commission2 = cumulative2['commission'] || 0

    other1 = cumulative1['otherAllowance'] || 0
    other2 = cumulative2['otherAllowance'] || 0

    let rest1 = overtime1 + allowances1 + bonus1 + commission1 + other1
    let rest2 = overtime2 + allowances2 + bonus2 + commission2 + other2

    return {
        basic1,
        basic2,
        overtime1,
        overtime2,
        allowances1,
        allowances2,
        bonus1,
        bonus2,
        commission1,
        commission2,
        other1,
        other2,
        sector1,
        sector2,
        rest1,
        rest2,
        jobs1,
        jobs2
    }
}
export const claimedAverageMonthlySavings = (mortgage) => {
    let filter = asset => asset.assetType === AssetTypes.SAVING && [
        Frequencies.MONTHLY,
        Frequencies.WEEKLY
    ].includes(asset.increasesFrequency)
    let savings1 = (mortgage.applicant1.assets || []).filter(filter)
    let sum1 = 0
    savings1.forEach(asset => {
        if (asset.increasesFrequency === Frequencies.MONTHLY) {
            sum1 += asset.increases
        }
        else {
            sum1 += (asset.increases * 52 / 12)
        }
    })
    if ((mortgage.applicant1.financial || {}).hasCurrentAccountSavings) {
        sum1 = mortgage.applicant1.financial.monthlySavings
    }

    let sum2 = 0
    if (mortgage.twoApplicants) {
        let savings2 = []
        savings2 = (mortgage.applicant2.assets || []).filter(filter)
        savings2.forEach(asset => {
            if (asset.increasesFrequency === Frequencies.MONTHLY) {
                sum2 += asset.increases
            }
            else {
                sum2 += (asset.increases * 52 / 12)
            }
        })
        if ((mortgage.applicant2.financial || {}).hasCurrentAccountSavings) {
            sum2 = mortgage.applicant2.financial.monthlySavings
        }
    }
    return [
        sum1,
        sum2
    ]
}
export const getApplicantMonthlyBasicIncome = (mortgage, applicant) => {
    let legacyEmployment = !mortgage[`applicant${applicant}`].employmentIncome?.length || !mortgage[`applicant${applicant}`].employmentIncome[0].employmentType
    let sections = []

    if (legacyEmployment) {
        sections = [mortgage.progress.find(it => it.sectionName === `payslips-${applicant}` && !it.sectionIndex)]
    }
    else {
        let monthly = 0
        sections = mortgage[`applicant${applicant}`].employmentIncome.map(item => {
            return mortgage.progress.find(it => it.sectionName === `payslips-${applicant}` && it.sectionIndex === item.id)
        })
        const mostRecentBasics = mortgage[`applicant${applicant}`].employmentIncome.map((job, index) => {
            let verifiedSection = mortgage.mortgageUploads.apply.find(s => s.name === `salary-cert-${applicant}` && s.index === job.id)


            if (verifiedSection) {
                let certs = []
                let verifiedSalaryCertificateUploads = verifiedSection.verified.uploads.active
                verifiedSalaryCertificateUploads.forEach(verifiedUpload => {
                    let values = verifiedUpload.verified.values;
                    certs.push({
                        date : values.documentDate,
                        basic: values.grossBasic || 0,
                    })
                })
                // get the most recent cert
                let mostRecentCert = certs.reduce((a, b) => a.date > b.date ? a : b, {date: null})
                let certGrossBasic = mostRecentCert.basic

                let verifiedUploads = verifiedSection.verified.uploads.active
                let payslips = []
                verifiedUploads.forEach(verifiedUpload => {
                    let values = verifiedUpload.verified.values;
                    payslips.push({
                        date : values.documentDate,
                        gross: values.grossPay || 0,
                        basic: values.grossBasic || 0,
                        net  : values.netPay || 0,
                    })
                })
                // get most recent payslip
                payslips.sort((a, b) => new Date(b.date) - new Date(a.date))
                if (payslips.length){
                    let mostRecent = payslips[0].basic
                    switch (job.payFrequency) {
                        case Frequencies.MONTHLY:
                            monthly += mostRecent;
                            break
                        case Frequencies.WEEKLY:
                            monthly += mostRecent * 52 / 12;
                            break
                        case Frequencies.FORTNIGHTLY:
                            monthly += mostRecent * 26 / 12;
                            break
                        case Frequencies.QUARTERLY:
                            monthly += mostRecent * 4 / 12;
                            break
                    }
                }


            }



            return monthly
        })
        // get sum of most recent basic pays
        let mostRecentTotal = mostRecentBasics.reduce((acc, cur) => acc + cur, 0)
        return mostRecentTotal

    }

    // check if there are undefined items in sections
    if (sections.includes(undefined) || !sections.length) {
        console.log('ERROR!!')
        return 0
    }
    let monthly = 0
    sections.forEach(section => {

        let data = JSON.parse(section.metaData || '{}')

        if (data.hasOwnProperty('payFrequency') && data.hasOwnProperty('payAmounts')) {

            let amts
            if (Array.isArray(data.payAmounts)) {
                amts = data.payAmounts.filter(it => !!it)
            }
            else {


                amts = Object.values(data.payAmounts).filter(it => !!it)
            }
            // payAmount is the collections of basic pays

            let average = amts.reduce((acc, cur) => acc + cur, 0) / amts.length
            switch (data.payFrequency) {
                case Frequencies.MONTHLY:
                    monthly += average;
                    break
                case Frequencies.WEEKLY:
                    monthly += average * 52 / 12;
                    break
                case Frequencies.FORTNIGHTLY:
                    monthly += average * 26 / 12;
                    break
                case Frequencies.QUARTERLY:
                    monthly += average * 4 / 12;
                    break
            }

        }
    })
    return monthly
}
export const getApplicantMonthlyGrossIncome = (mortgage, applicant) => {
    let legacyEmployment = !mortgage[`applicant${applicant}`].employmentIncome?.length || !mortgage[`applicant${applicant}`].employmentIncome[0].employmentType
    let sections = []
    if (legacyEmployment) {
        sections = [mortgage.progress.find(it => it.sectionName === `payslips-${applicant}` && !it.sectionIndex)]
    }
    else {
        sections = mortgage[`applicant${applicant}`].employmentIncome.map(item => {
            return mortgage.progress.find(it => it.sectionName === `payslips-${applicant}` && it.sectionIndex === item.id)
        })
    }
    // check if there are undefined items in sections
    if (sections.includes(undefined) || !sections.length) {


        return 0
    }

    let monthly = 0
    sections.forEach(section => {

        let data = JSON.parse(section.metaData || '{}')

        if (data.hasOwnProperty('payFrequency') && data.hasOwnProperty('payAmountsGross')) {
            let amts = Object.values(data.payAmountsGross.filter(it => !!it))

            let average = amts.reduce((acc, cur) => acc + cur, 0) / amts.length

            switch (data.payFrequency) {
                case Frequencies.MONTHLY:
                    monthly += average;
                    break
                case Frequencies.WEEKLY:
                    monthly += average * 52 / 12;
                    break
                case Frequencies.FORTNIGHTLY:
                    monthly += average * 26 / 12;
                    break
                case Frequencies.QUARTERLY:
                    monthly += average * 4 / 12;
                    break
            }
        }
    })
    return monthly
}
export const getApplicantFinancialSections = (mortgage, applicant, withInvestments = false) => {
    let sectionsRequired = []
    sectionsRequired.push(...getApplicantCurrentAccountSections(mortgage, applicant))
    sectionsRequired.push(...getApplicantSavingsSections(mortgage, applicant, withInvestments))
    return sectionsRequired
}
export const getApplicantCurrentAccountSections = (mortgage, applicant) => {
    let sectionsRequired = []
    let accounts = mortgage[`applicant${applicant}`].accounts
    accounts.forEach(account => {
        let section = mortgage.progress.filter(it => it.sectionIndex === account.id && it.sectionName.startsWith('current-accounts'))
        if (section.length > 1) {
            console.warn('Multiple sections found for account', section)
        }

        sectionsRequired.push({
            name           : `current-accounts-${applicant}`,
            record         : section[0],
            title          : account.institution,
            index          : account.id,
            salaried       : account.salaried,
            accommodationDD: account.accommodationDD,
            applicant,
        })
    })
    return sectionsRequired
}
export const getApplicantSavingsSections = (mortgage, applicant, withInvestments = false) => {
    let sectionsRequired = []
    let savings = mortgage[`applicant${applicant}`].assets.filter(it => {
        if (withInvestments) {
            return true
        }
        return it.assetType === AssetTypes.SAVING
    })
    savings.forEach(saving => {

        let section = mortgage.progress.filter(it => it.sectionIndex === saving.id && it.sectionName.startsWith('assets'))
        if (section.length > 1) {
            console.warn('Multiple sections found for savings', section)
        }
        sectionsRequired.push({
            name  : `assets-${applicant}`,
            record: section[0],
            title : saving.institution || saving.description,
            index : saving.id,
            applicant,
        })
    })

    return sectionsRequired
}
export const getApplicantLoansSections = (mortgage, applicant) => {
    let sectionsRequired = []
    let loans = mortgage[`applicant${applicant}`].debts.filter(it => it.debtType === DebtTypes.LOAN)
    loans.forEach(loan => {
        let section = mortgage.progress.find(it => it.sectionIndex === loan.id)
        sectionsRequired.push({
            ...loan,
            name  : `debts-${applicant}`,
            record: section,
            title : loan.lender,
            index : loan.id,
            applicant,
        })
    })
    return sectionsRequired
}
export const getApplicantMonthlySavings = (mortgage, applicant) => {
    const sections = getApplicantFinancialSections(mortgage, applicant)
    let {
        averageMonthlySavings,
        dataSource,
        totalTotalDifference,
        maxMonths
    } = savingsDataSource(mortgage, sections)
    //averageMonthlySavings = parseInt(totalTotalDifference / maxMonths)
    return averageMonthlySavings
}
export const getCurrentAccountsDataSource = (mortgage) => {
    const rows = []
    const sections1 = getApplicantCurrentAccountSections(mortgage, 1)
    let {dataSource: dataSource1} = verifiedAccounts(mortgage, sections1)

    rows.push(...dataSource1)
   // rows.push(...savingsDataSource(mortgage, sections1).dataSource)
    if (mortgage.twoApplicants) {
        const sections2 = getApplicantCurrentAccountSections(mortgage, 2)
        let {dataSource: dataSource2} = verifiedAccounts(mortgage, sections2)
        rows.push(...dataSource2)
        //rows.push(...savingsDataSource(mortgage, sections2).dataSource)
    }

    return rows
}
export const getSavingsDataSource = (mortgage) => {
    const rows = []
    const sections1 = getApplicantSavingsSections(mortgage, 1)
    const {dataSource: dataSource1} = verifiedAccounts(mortgage, sections1)
    rows.push(...dataSource1)
   // rows.push(...savingsDataSource(mortgage, sections1).dataSource)
    if (mortgage.twoApplicants) {
        const sections2 = getApplicantSavingsSections(mortgage, 2)
        const {dataSource: dataSource2} = verifiedAccounts(mortgage, sections2)
        rows.push(...dataSource2)
       //rows.push(...savingsDataSource(mortgage, sections2).dataSource)
    }

    return rows
}
export const getLoansDataSource = (mortgage) => {
    const sections1 = getApplicantLoansSections(mortgage, 1)
    let dataSource = sections1.map(it => {
        return {
            id            : it.id,
            key           : it.name + it.index,
            owner         : it.applicant === 1 ? mortgage.applicant1.firstName : it.applicant === 2 ? mortgage.applicant2.firstName : '?',
            lender        : it.lender,
            monthlyPayment: it.monthlyPayment,
            originalAmount: it.creditLimit,
            outstanding   : it.outstanding,
            endDate       : it.endDate
        }
    })
    return dataSource

}
export const getCurrentAccountDetails = (mortgage) => {
    let dataSource = getCurrentAccountsDataSource(mortgage).filter(a => !a.excluded)
    let totalCurrentOpening = 0
    let totalCurrentClosing = 0
    let totalCurrentDifference = 0
    let totalCurrentAverage = 0
    dataSource.forEach(a => {
        totalCurrentOpening += a.openingBalance
        totalCurrentClosing += a.closingBalance
        totalCurrentDifference += a.totalDifference
        totalCurrentAverage += a.averageMonthly
    })
    return {
        dataSource,
        totalCurrentOpening,
        totalCurrentClosing,
        totalCurrentDifference,
        totalCurrentAverage
    }
}
export const getSavingsAccountDetails = (mortgage) => {
    const dataSource = getSavingsDataSource(mortgage).filter(a => !a.excluded)
    let totalSavingsOpening = 0
    let totalSavingsClosing = 0
    let totalSavingsDifference = 0
    let totalSavingsAverage = 0
    dataSource.forEach(a => {
        totalSavingsOpening += a.openingBalance
        totalSavingsClosing += a.closingBalance
        totalSavingsDifference += a.totalDifference
        totalSavingsAverage += a.averageMonthly
    })
    return {
        dataSource,
        totalSavingsOpening,
        totalSavingsClosing,
        totalSavingsDifference,
        totalSavingsAverage
    }
}
export const getLoanAccountDetails = (mortgage) => {
    const dataSource = getLoansDataSource(mortgage).filter(a => !a.excluded)
    return {
        dataSource,
    }
}
export const coverNoteFiguresDataSource = (mortgage) => {
    const currentAccounts = getCurrentAccountsDataSource(mortgage).filter(a => !a.excluded)
    const savingsAccounts = getSavingsDataSource(mortgage).filter(a => !a.excluded)
    let totalSavingsClosing = 0
    let totalSavingsDifference = 0
    let totalSavingsAverage = 0
    let totalCurrentClosing = 0
    let totalCurrentDifference = 0
    let totalCurrentAverage = 0
    currentAccounts.forEach(a => {
        totalCurrentClosing += a.closingBalance
        totalCurrentDifference += a.totalDifference
        totalCurrentAverage += a.averageMonthly
    })
    savingsAccounts.forEach(a => {
        totalSavingsClosing += a.closingBalance
        totalSavingsDifference += a.totalDifference
        totalSavingsAverage += a.averageMonthly
    })
    const dataSource = [
        {
            owner          : '',
            account        : 'CURRENT ACCOUNTS',
            range          : '',
            openingBalance : '',
            closingBalance : '',
            totalDifference: '',
            averageMonthly : ''
        },
        ...currentAccounts,
        {
            owner          : '',
            account        : '',
            range          : '',
            openingBalance : 'Totals:',
            closingBalance : totalCurrentClosing,
            totalDifference: totalCurrentDifference,
            averageMonthly : totalCurrentAverage
        }
    ]
    if (savingsAccounts.length > 0) {
        dataSource.push({
            owner          : '',
            account        : '',
            range          : '',
            openingBalance : '',
            closingBalance : '',
            totalDifference: '',
            averageMonthly : ''
        }, {
            owner          : '',
            account        : 'SAVINGS ACCOUNTS',
            range          : '',
            openingBalance : '',
            closingBalance : '',
            totalDifference: '',
            averageMonthly : ''
        }, ...savingsAccounts, {
            owner          : '',
            account        : '',
            range          : '',
            openingBalance : 'Totals:',
            closingBalance : totalSavingsClosing,
            totalDifference: totalSavingsDifference,
            averageMonthly : totalSavingsAverage
        })
    }
    return dataSource
}
export const lastSuccessfulPTSBTest = (mortgage, app) => {
    let tests = mortgage.playgroundTests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    let test = tests[0]
    let results = JSON.parse(test.results||'{}')
    let failed = !results.hasOwnProperty('ptsb') || results.ptsb.some(it => it.hasOwnProperty('passed') && !it.passed)
    if (failed) {
        app.notification.error({message:'PTSB test not passed'})
        return null
    }
    let data = JSON.parse(test.data||'{}')
    let d = data.ptsb
    let itemsSent = {}
    let keys = Object.keys(ptsbCoverNoteCoords)
    keys.forEach(it => {
        let coord = ptsbCoverNoteCoords[it]
        if (d.hasOwnProperty(coord)) {
            itemsSent[it] = d[coord]
        }
        else {
            console.log('d doesn\'t have ' + it + 'at ' + coord)
        }

    })
    let r = results.ptsb

    // let tests = mortgage.playgroundTests.filter(it => {
    //     if (it.results){
    //         let results = JSON.parse(it.results)
    //         if (results.hasOwnProperty('ptsb')) {
    //             let failed = results.ptsb.filter(it => {
    //                 return it.hasOwnProperty('passed') && !it.passed
    //             })
    //             return !failed.length;
    //         }
    //     }
    //
    //     return false
    // })
    // tests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    // if (!tests.length) {
    //     app.notification.error('No successful TPTSB tests found')
    //     return null
    // }
    // let d = JSON.parse(tests[0].data).ptsb
    let variableIncomes = {
        app1: {},
        app2: {}
    }
    // let itemsSent = {}
    // let keys = Object.keys(ptsbCoverNoteCoords)
    const regex = /^([a-zA-Z]+)(\d+)$/;

    keys.forEach(it => {
        let coord = ptsbCoverNoteCoords[it]

        if (d.hasOwnProperty(coord)) {
            try {
                let [_, word, numberString] = it.match(regex);
                let app = `app${numberString}`
                if (word.startsWith('basic')) {
                    throw new Error('Not a basic income')
                }
                variableIncomes[app][word] = d[coord]
            }
            catch (e) {
                itemsSent[it] = d[coord]
            }
        }
    })

    const app1VariableIncome = Object.values(variableIncomes.app1).reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);
    const app2VariableIncome = Object.values(variableIncomes.app2).reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
    }, 0);
   // let r = JSON.parse(tests[0].results).ptsb
    return {
        pra           : r.find(i => i.label === 'Proven Repayment Ability').value,
        mortgage      : r.find(i => i.label === 'Mortgage Payment').value.replace(/[^\d.]/g, ""),
        stressed      : r.find(i => i.label === 'Stressed Mortgage Payment').value.replace(/[^\d.]/g, ""),
        ltv           : r.find(i => i.label === 'Proposed LTV').value,
        rate          : r.find(i => i.label === 'Interest Rate').value,
        cashForLiving : r.find(i => i.label === 'Cash For Living').value.replace(/[^\d.]/g, ""),
        maxNets       : r.find(i => i.label === 'Max Nets').value,
        actualNets    : r.find(i => i.label === 'Actual Nets').value,
        loanToIncome  : r.find(i => i.label === 'Loan To Income').value,
        monthlyIncome : r.find(i => i.label === 'Total Net Monthly Income').value.replace(/[^\d.]/g, ""),
        mdiRequirement: r.find(i => i.label === 'MDI Requirement').value.replace(/[^\d.]/g, ""),
        maxForLoans   : r.find(i => i.label === 'Max Amount Available For Loans').value.replace(/[^\d.]/g, ""),
        app1VariableIncome,
        app2VariableIncome,
        itemsSent
    }
}
export const lastSuccessfulHavenTest = (mortgage, app) => {
    let tests = mortgage.playgroundTests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    let test = tests[0]
    let results = JSON.parse(test.results||'{}')
    let failed = !results.hasOwnProperty('haven') || results.haven.some(it => it.hasOwnProperty('passed') && !it.passed)

    if (failed) {
        app.notification.error({message:'Haven test not passed'})
        return null
    }
    let data = JSON.parse(test.data||'{}')
    let d = data.haven
    let itemsSent = {}
    let keys = Object.keys(havenCoverNoteCoords)
    keys.forEach(it => {
        let coord = havenCoverNoteCoords[it]
        if (d.hasOwnProperty(coord)) {
            itemsSent[it] = d[coord]
        }
        else {
            console.log('d doesn\'t have ' + it + 'at ' + coord)
        }

    })
    let r = results.haven
    // let dtests = mortgage.playgroundTests.filter(it => {
    //     console.log({it})
    //     if (it.results) {
    //         let results = JSON.parse(it.results)
    //         if (results.hasOwnProperty('haven')) {
    //             let failed = results.haven.some(it => {
    //                 return it.hasOwnProperty('passed') && !it.passed
    //             })
    //             console.log({failed})
    //             return failed
    //         }
    //     }
    //
    //     return false
    // })
    // tests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    // console.log({tests})
    // if (!tests.length) {
    //     app.notification.error('No successful Haven tests found')
    //     return null
    // }
    //let d = JSON.parse(tests[0].data).haven

    return {
        message          : r.find(i => i.label === 'Message').value,
        monthlyPayment : r.find(i=> i.label === 'Estimated Monthly Payment').value,
        repaymentCapacity: r.find(i => i.label === 'Repayment Capacity').value.replace(/[^\d.]/g, ""),
        repaymentDeficit : r.find(i => i.label === 'Repayment Deficit/Surplus').value.replace(/[^\d.]/g, ""),
        stressedPayments : r.find(i => i.label === 'Stressed Payments').value.replace(/[^\d.]/g, ""),
        actualNdi        : r.find(i => i.label === 'Actual NDI').value.replace(/[^\d.]/g, ""),
        requiredNdi      : r.find(i => i.label === 'Required NDI').value.replace(/[^\d.]/g, ""),
        exception        : r.find(i => i.label === 'Exception').value,
        ndi              : r.find(i => i.label === 'NDI %').value,
        ltv              : r.find(i => i.label === 'LTV').value,
        lti              : r.find(i => i.label === 'Loan To Income').value,
        itemsSent
    }
    // return {
    //     pra: r.find(i => i.label === 'Proven Repayment Ability').value,
    //     mortgage: r.find(i => i.label === 'Mortgage Payment').value.replace(/[^\d.]/g, ""),
    //     stressed: r.find(i => i.label === 'Stressed Mortgage Payment').value.replace(/[^\d.]/g, ""),
    //     ltv: r.find(i => i.label === 'Proposed LTV').value,
    //     rate: r.find(i => i.label === 'Interest Rate').value,
    //     cashForLiving: r.find(i => i.label === 'Cash For Living').value.replace(/[^\d.]/g, ""),
    //     maxNets: r.find(i => i.label === 'Max Nets').value,
    //     actualNets: r.find(i => i.label === 'Actual Nets').value,
    //     loanToIncome: r.find(i => i.label === 'Loan To Income').value,
    //     monthlyIncome: r.find(i => i.label === 'Total Net Monthly Income').value.replace(/[^\d.]/g, ""),
    //     mdiRequirement: r.find(i => i.label === 'MDI Requirement').value.replace(/[^\d.]/g, ""),
    //     maxForLoans: r.find(i => i.label === 'Max Amount Available For Loans').value.replace(/[^\d.]/g, ""),
    // }
}
export const lastSuccessfulAvantTest = (mortgage, app) => {
    let tests = mortgage.playgroundTests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    let test = tests[0]
    let results = JSON.parse(test.results||'{}')
    let failed = !results.hasOwnProperty('avant') || results.avant.some(it => it.hasOwnProperty('passed') && !it.passed)
    if (failed) {
        app.notification.error({message:'Avant test not passed'})
        return null
    }
    let data = JSON.parse(test.data||'{}')
    let d = data.avant

    // let tests = mortgage.playgroundTests.filter(it => {
    //     if (it.results) {
    //         let results = JSON.parse(it.results)
    //         if (results.hasOwnProperty('avant')) {
    //             let failed = results.avant.filter(it => {
    //                 return it.hasOwnProperty('passed') && !it.passed
    //             })
    //
    //             return !failed.length;
    //         }
    //     }
    //
    //     return false
    // })
    // tests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    //
    // if (!tests.length) {
    //     app.notification.error('No successful Avant tests found')
    //     return null
    // }
    // let d = JSON.parse(tests[0].data).avant
    let itemsSent = {}
    let keys = Object.keys(avantCoverNoteCoords)

    keys.forEach(it => {
        let coord = avantCoverNoteCoords[it]
        if (d.hasOwnProperty(coord)) {
            itemsSent[it] = d[coord]
        }
        else {
            console.log('d doesn\'t have ' + it + 'at ' + coord)
        }

    })
    //let r = JSON.parse(tests[0].results).avant
    let r = results.avant
    return {
        ltv              : r.find(i => i.label === 'LTV').value,
        expectedPayment : r.find(i => i.label === 'Expected Payment').value.replace(/[^\d.]/g, ""),
        stressedPayments: r.find(i => i.label === 'Stressed Payment').value.replace(/[^\d.]/g, ""),
        maxPermittedLTV: r.find(i => i.label === 'Max Permitted LTV').value,
        grossLoanToIncome: r.find(i => i.label === 'Gross Loan To Income').value,
        netLoanToIncome: r.find(i => i.label === 'Net Loan To Income').value,
        debtServicingRatio: r.find(i => i.label === 'Debt Service Ratio').value,
        netDisposableIncome: r.find(i => i.label === 'Net Disposable Income').value.replace(/[^\d.]/g, ""),
        provenRepaymentAbility: r.find(i => i.label === 'Proven Repayment Ability').value,
        maxLoanAmount: r.find(i => i.label === 'Max Loan Amount').value.replace(/[^\d.]/g, ""),
        valueForMaxLTV: r.find(i => i.label === 'Value For Max LTV').value,
        itemsSent
    }

}
export const addCoverNoteFiguresToMap = (mortgage, map) => {
    const dataSource = coverNoteFiguresDataSource(mortgage)

    dataSource.forEach((row, index) => {
        let i = index + 1

        let parts = row.range.split('(')
        let months = parts.length > 1 ? parts[1].replace(')', '').trim() : ''
        map[`row-${i}-account-holder-name`] = row.owner
        map[`row-${i}-bank-name`] = row.account
        map[`row-${i}-date-range`] = parts[0].trim()
        map[`row-${i}-months`] = months
        map[`row-${i}-opening-balance`] = typeof row.openingBalance === 'string' ? row.openingBalance : toEuroString(row.openingBalance)
        map[`row-${i}-closing-balance`] = typeof row.closingBalance === 'string' ? row.closingBalance : toEuroString(row.closingBalance)
        map[`row-${i}-total-difference`] = typeof row.totalDifference === 'string' ? row.totalDifference : toEuroString(row.totalDifference)
        map[`row-${i}-monthly-average`] = typeof row.averageMonthly === 'string' ? row.averageMonthly : toEuroString(row.averageMonthly)
    })
}
export const getApplicantAccommodationCosts = (mortgage, applicant) => {
    if (applicant === 2 && mortgage.requirement.cohabiting) {
        return 0
    }
    if (mortgage[`applicant${applicant}`].personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
        return mortgage[`applicant${applicant}`].financial.monthlyAccommodationCosts
    }
    if (mortgage[`applicant${applicant}`].personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES){
        if (mortgage[`applicant${applicant}`].financial.contributeToRelatives) {
            return mortgage[`applicant${applicant}`].financial.monthlyAccommodationCosts
        }
        return {
            errors: [
                "Lives with relatives but does not contribute to accommodation costs"
            ]
        }
    }

    return {
        errors: [
            "No accommodation costs have been entered"
        ]
    }
}