import React, {useState} from "react";
import useXlsQueue from "./useXlsQueue";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import usePtsbXls from "./ptsb/usePtsbXls";
import useHavenXls from "./haven/useHavenXls";
import useAvantXls from "./avant/useAvantXls";
import useIcsXls from "./ics/useIcsXls";
import {ApplicationTypes, BuildType, MortgageTypes} from "../../../../../../models";
import dayjs from "dayjs";
export function arrayValue(value) {
    if (Array.isArray(value)) {
        return value.reduce((acc, it) => acc + parseFloat(it.value), 0)
    }
    return value

}
let x = {
    Data: {
        values : {
            errors             : [],
            childmindingCosts1  : 0, // repeated for 2
            sector1            : 'private',
            basic1             : 73500,
            overtime1          : 0,
            bonus1             : 0,
            allowances1        : 0,
            other1             : 0,
            commission1        : 0,
            savings1           : 1000,
            loans1             : 0,
            clearingLoans1     : 0,
            credit1            : 0,
            pension1           : 0,
            accommodationCosts1: 850,
            maintenancePaid1   : 0,
            otherPRA1          : 0,
            hasOtherIncome1    : false,
            repaymentCapacity1 : 1850,
        },
        funding: {
            errors    : [],
            savings   : 0,
            gifts     : 0,
            helpToBuy : 0,
            firstHome : 0,
            cashOnHand: 0
        },
    }
}
export const legacyIncome = (mortgage) => {
    if (mortgage.applicant1.employmentIncome?.length && (mortgage.applicant1.employmentIncome[0].employmentType || mortgage.applicant1.employment._version === 1)) {
        return false
    }
    return true

    // return !(mortgage.applicant1.employmentIncome?.length && (mortgage.applicant1.employmentIncome[0].employmentType || mortgage.applicant1.employment._version === 1));
}

export function extraCosts(buildType, price, switcher = false) {

    let propertyValuation = 165
    let lowerPercent = buildType === BuildType.NEW ? 0.01  : 0.01

    let higherPercent = 0.02
    let normal = price
    let at2percent = price - 1000000
    if (at2percent > 0) {
        normal -= at2percent
    }
    let stampDuty = 0
    if (!switcher){
        stampDuty = (normal * lowerPercent)
        if (at2percent > 0) {
            stampDuty += (at2percent * higherPercent)
        }
        stampDuty = Math.round(stampDuty / 10) * 10
    }
    let legalFees = price > 400000 ? 2900 : 2800
    let extraCosts = stampDuty + legalFees + propertyValuation
    let totalCosts = price + extraCosts

    return {
        stampDuty,
        legalFees,
        extraCosts,
        totalCosts,
        propertyValuation
    }
}
export function getReducingMaxLoanAndPrice(loan, cashOnHand, maxLtv) {
    if (!loan || !cashOnHand || !maxLtv) {
        return null
    }
    let value = getMaxPrice(loan, cashOnHand, maxLtv)

    while (!value && loan >= 101000) {
        loan -= 1000
        value = getMaxPrice(loan, cashOnHand, maxLtv)
    }

    return {
        maxLoan : Math.floor(loan / 1000) * 1000,
        maxPrice: Math.floor(value / 1000) * 1000
    }
}
export function getMaxPrice(maxLoan, cashOnHand, maxLtv = 0.9, buildType = BuildType.NEW) {
    let maxValue = maxLoan + cashOnHand
    maxValue -= extraCosts(buildType, maxValue).extraCosts
    let realLtv = maxLoan / maxValue
    if (realLtv > maxLtv) {
        return false
    }
    return Math.ceil(maxValue / 1000) * 1000
}
export function mainAppAndAge(mortgage) {
    let app1dob = dayjs(mortgage.applicant1.personal.dateOfBirth)
    let app1age = dayjs().diff(app1dob, 'years', true)
    let appAge = app1age

    let mainApplicant = mortgage.applicant1
    if (mortgage.twoApplicants) {
        let app2dob = dayjs(mortgage.applicant2.personal.dateOfBirth)
        let app2age = dayjs().diff(app2dob, 'years', true)
        if (app2age > app1age) {
            mainApplicant = mortgage.applicant2
            appAge = app2age
        }
    }
    return {
        mainApplicant,
        age: Math.round(appAge)
    }
}
export const OverrideValues = React.createContext({})
export function OverrideValuesProvider({children}) {
    const [state, setState] = useState({})

    const set = (key, value) => {
        setState(s => ({
            ...s,
            [key]: value
        }))
    }
    const unset = (key) => {
        setState(s => {
            let {
                [key]: _,
                ...rest
            } = s
            return rest
        })
    }

    return <OverrideValues.Provider value={{
        figures: state,
        set,
        unset
    }}>{children}</OverrideValues.Provider>
}
function useXlsTests(useVerifiedFigures, setupOnly = false) {
    const mortgage = useMortgage()
    const lenderTests = {
        PTSB : {
            ...useXlsQueue('PTSB', usePtsbXls()),
        },
        HAVEN: {
            ...useXlsQueue('HAVEN', useHavenXls())
        },
        AVANT: {
            ...useXlsQueue('AVANT', useAvantXls())
        },
        ICS  : {
            ...useXlsQueue('ICS', useIcsXls())
        }
    }
    const generateTestCombinations = (variableProperties, origObject) => {
        const combinations = [];
        const helper = (index, current, tests) => {
            if (index === variableProperties.length) {
                combinations.push({
                    key: JSON.stringify({...origObject, ...current}),
                    tests, ...origObject, ...current
                });
                return;
            }
            for (const value of [
                true,
                false
            ]) {
                const newTests = value ? [
                    ...tests,
                    variableProperties[index]
                ] : tests;
                let newCurrent = {
                    ...current,
                    options: {
                        ...current.options,
                        [variableProperties[index]]: value
                    }
                }
                helper(index + 1, newCurrent, newTests);
            }
        };
        helper(0, {}, []);
        return combinations
    }
    const {verified, unverified: claimed} = mortgage.figures

    const flowTests = {

        varyingPRAs: (loan, value, term, useVerifiedFigures, isOrig = false) => {
            if (!loan || !value || !term) {
                return []
            }
            let figures = useVerifiedFigures ? verified: claimed

           // const {values: figures, errors} = generateLenderMortgageFigures(mortgage, useVerifiedFigures ? uploadSections : false)
            const withVariablePossible = figures.hasOtherIncome1 || figures.hasOtherIncome2
            const withClearingAllLoansPossible = figures.loans1 || figures.loans2

            // requested loan
            let possibleProperties = []
            if (withVariablePossible) {
                possibleProperties.push('withVariable')
            }
            if (withClearingAllLoansPossible) {
                possibleProperties.push('withClearingAllLoans')
            }
            let origObject = {
                loan,
                value,
                term,
                overrideValues: {},
                useVerifiedFigures
            }
            let combinations1 = generateTestCombinations(possibleProperties, {
                ...origObject,
                newBuild: true
            })

            let baseObject1 = combinations1.find(item => item.tests.length === 0)
            let rest1 = combinations1.filter(item => item.tests.length > 0)
            baseObject1.on = {fail: rest1}
            if (!!isOrig) {
                baseObject1.isOrig = true
            }
            // let combinations2 = generateTestCombinations(possibleProperties, {
            //     ...origObject,
            //     newBuild: false
            // });
            // let baseObject2 = combinations2.find(item => item.tests.length === 0)
            // let rest2 = combinations2.filter(item => item.tests.length > 0)
            // baseObject2.on = {fail: rest2}
            return [
                baseObject1,
                // baseObject2
            ]
        },
        lowerTerms : async (loan, value, term, LENDER) => {

        }

    }

    const tests = {
        requestedLoan       : async () => {
            let loan = mortgage.requirement.loanRequired
            let value = mortgage.properties[0].propertyValue
            let term = mortgage.requirement.loanTerm

            let tests = flowTests.varyingPRAs(loan, value, term, useVerifiedFigures, true)
            Object.keys(lenderTests).forEach((LENDER, i) => {
                lenderTests[LENDER].addToQueue(tests)
            })
        },
        ourCalculatedMaxLoan: () => {
            //the max loan based on income
            let figures = useVerifiedFigures ? verified : claimed

            let factor = (mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME) ? 4 : 3.5
            let maxLoan4Test = Math.round((parseInt(arrayValue(figures.basic1.value)) + parseInt(arrayValue(figures.basic2.value))) * factor / 1000) * 1000
            let maxs = getReducingMaxLoanAndPrice(maxLoan4Test, figures.totalFunds.value, 0.9)
            if (!!maxs) {
                let {age} = mainAppAndAge(mortgage)
                let maxTerm = Math.min(67 - age, mortgage.requirement.loanTerm)

                let tests = flowTests.varyingPRAs(maxs.maxLoan, maxs.maxPrice, maxTerm, useVerifiedFigures)
                let origLoan = mortgage.requirement.loanRequired
                if (maxs.maxLoan - 1000 > origLoan) {
                    console.log('cant get a higher loan')
                    let diff = maxs.maxLoan - origLoan
                    let dividor = diff < 10000 ? 1000 : diff < 40000 ? 5000 : diff < 100000 ? 10000 : 50000
                    let options = Math.floor(diff / dividor)
                    let spacing = (maxs.maxLoan - origLoan) / options
                    for (let i = 1; i < options; i++) {
                        let {
                            maxLoan : lessLoan,
                            maxPrice: lessPrice
                        } = getReducingMaxLoanAndPrice(maxs.maxLoan - (spacing * i), figures.totalFunds.value, 0.9)
                        let lessTests = flowTests.varyingPRAs(lessLoan, lessPrice, maxTerm, useVerifiedFigures)
                        tests = [
                            ...tests,
                            ...lessTests
                        ]
                    }
                }
                Object.keys(lenderTests).forEach((LENDER, i) => {
                    lenderTests[LENDER].addToQueue(tests)
                })
            }
            else {
                console.log({
                    maxLoan4Test,
                    maxs
                })
            }
        },
        betterTerms         : () => {

        },
    }
    const testAll = () => {
        console.clear()
        tests.requestedLoan()
        tests.ourCalculatedMaxLoan()
    }
    return {
        testAll,
        queues: lenderTests
    }
}

export default useXlsTests;