import {addBunchToMap} from "../overlay-utils";
import {legacyIncome} from "../../../../app/components/client/application-stages/_common/lender-tests/useXlsTests";
import {BoolLike, EmploymentTypes} from "../../../../models";
import {
    balanceOfFundsCosts, balanceOfFundsFunds
} from "../../../../app/components/client/application-stages/_common/funding/BalanceOfFunds";

let elements = [
    {
        "name": "advice-amended-aip",
        "type": "TEXT"
    },
    {
        "name": "advice-amended-aip-details",
        "type": "TEXT"
    },
    {
        "name": "advice-amended-aip-details-additional-information",
        "type": "TEXT"
    },
    {
        "name": "app1-agreed-price-on-market-value",
        "type": "TEXT"
    },
    {
        "name": "app1-agreed-prrice-on-approcal",
        "type": "TEXT"
    },
    {
        "name": "app1-all-aip-conditions",
        "type": "TEXT"
    },
    {
        "name": "app1-amount-different-on-aip",
        "type": "TEXT"
    },
    {
        "name": "app1-architecs-pi-provided",
        "type": "TEXT"
    },
    {
        "name": "app1-bank-statement-yes",
        "type": "TEXT"
    },
    {
        "name": "app1-cost-of-construction",
        "type": "TEXT"
    },
    {
        "name": "app1-cost-of-instruction",
        "type": "TEXT"
    },
    {
        "name": "app1-engineer-confirmation",
        "type": "TEXT"
    },
    {
        "name": "app1-final-grant-of-planning",
        "type": "TEXT"
    },
    {
        "name": "app1-first-home-scheme",
        "type": "TEXT"
    },
    {
        "name": "app1-first-home-scheme-max-eligibility",
        "type": "TEXT"
    },
    {
        "name": "app1-first-home-scheme-within-mpm",
        "type": "TEXT"
    },
    {
        "name": "app1-first-home-scheme-yes",
        "type": "TEXT"
    },
    {
        "name": "app1-full-name",
        "type": "TEXT"
    },
    {
        "name": "app1-help-to-buy-access-code",
        "type": "TEXT"
    },
    {
        "name": "app1-help-to-buy-acknowledgement-form",
        "type": "TEXT"
    },
    {
        "name": "app1-help-to-buy-developer-name",
        "type": "TEXT"
    },
    {
        "name": "app1-high-net-worth-customer-no",
        "type": "CHECK"
    },
    {
        "name": "app1-high-net-worth-customer-yes",
        "type": "CHECK"
    },
    {
        "name": "app1-letter-of-supervision",
        "type": "TEXT"
    },
    {
        "name": "app1-local-authority-affordable-purchase-scheme",
        "type": "TEXT"
    },
    {
        "name": "app1-max-amount-objection-letter",
        "type": "TEXT"
    },
    {
        "name": "app1-months-non-aib-statement",
        "type": "TEXT"
    },
    {
        "name": "app1-most-recent-payslip-yes",
        "type": "TEXT"
    },
    {
        "name": "app1-no-objection-letter",
        "type": "TEXT"
    },
    {
        "name": "app1-pep-no",
        "type": "CHECK"
    },
    {
        "name": "app1-pep-yes",
        "type": "CHECK"
    },
    {
        "name": "app1-planning-approved-for-garage",
        "type": "TEXT"
    },
    {
        "name": "app1-property-size",
        "type": "TEXT"
    },
    {
        "name": "app1-satisfactory-evidence",
        "type": "TEXT"
    },
    {
        "name": "app1-site-value-input",
        "type": "TEXT"
    },
    {
        "name": "app1-switching",
        "type": "TEXT"
    },
    {
        "name": "app1-up-to-date-certified-accounts-by-accountant",
        "type": "TEXT"
    },
    {
        "name": "app1-using-laaps",
        "type": "TEXT"
    },
    {
        "name": "app1-valid-passport-yes",
        "type": "TEXT"
    },
    {
        "name": "app1-valid-utility-bill-yes",
        "type": "TEXT"
    },
    {
        "name": "app2-agreed-price-on-market-value",
        "type": "TEXT"
    },
    {
        "name": "app2-agreed-prrice-on-approcal",
        "type": "TEXT"
    },
    {
        "name": "app2-all-aip-conditions",
        "type": "TEXT"
    },
    {
        "name": "app2-amount-different-on-aip",
        "type": "TEXT"
    },
    {
        "name": "app2-architecs-pi-provided",
        "type": "TEXT"
    },
    {
        "name": "app2-bank-statement-yes",
        "type": "TEXT"
    },
    {
        "name": "app2-cost-of-construction",
        "type": "TEXT"
    },
    {
        "name": "app2-cost-of-instruction",
        "type": "TEXT"
    },
    {
        "name": "app2-engineer-confirmation",
        "type": "TEXT"
    },
    {
        "name": "app2-final-grant-of-planning",
        "type": "TEXT"
    },
    {
        "name": "app2-first-home-scheme",
        "type": "TEXT"
    },
    {
        "name": "app2-first-home-scheme-max-eligibility",
        "type": "TEXT"
    },
    {
        "name": "app2-first-home-scheme-within-mpm",
        "type": "TEXT"
    },
    {
        "name": "app2-first-home-scheme-yes",
        "type": "TEXT"
    },
    {
        "name": "app2-full-name",
        "type": "TEXT"
    },
    {
        "name": "app2-help-to-buy-access-code",
        "type": "TEXT"
    },
    {
        "name": "app2-help-to-buy-acknowledgement-form",
        "type": "TEXT"
    },
    {
        "name": "app2-help-to-buy-developer-name",
        "type": "TEXT"
    },
    {
        "name": "app2-high-net-worth-customer-no",
        "type": "CHECK"
    },
    {
        "name": "app2-high-net-worth-customer-yes",
        "type": "CHECK"
    },
    {
        "name": "app2-letter-of-supervision",
        "type": "TEXT"
    },
    {
        "name": "app2-local-authority-affordable-purchase-scheme",
        "type": "TEXT"
    },
    {
        "name": "app2-max-amount-objection-letter",
        "type": "TEXT"
    },
    {
        "name": "app2-months-non-aib-statement",
        "type": "TEXT"
    },
    {
        "name": "app2-most-recent-payslip-yes",
        "type": "TEXT"
    },
    {
        "name": "app2-no-objection-letter",
        "type": "TEXT"
    },
    {
        "name": "app2-pep-no",
        "type": "CHECK"
    },
    {
        "name": "app2-pep-yes",
        "type": "CHECK"
    },
    {
        "name": "app2-planning-approved-for-garage",
        "type": "TEXT"
    },
    {
        "name": "app2-property-size",
        "type": "TEXT"
    },
    {
        "name": "app2-satisfactory-evidence",
        "type": "TEXT"
    },
    {
        "name": "app2-site-value-input",
        "type": "TEXT"
    },
    {
        "name": "app2-switching",
        "type": "TEXT"
    },
    {
        "name": "app2-up-to-date-certified-accounts-by-accountant",
        "type": "TEXT"
    },
    {
        "name": "app2-using-laaps",
        "type": "TEXT"
    },
    {
        "name": "app2-valid-passport-yes",
        "type": "TEXT"
    },
    {
        "name": "app2-valid-utility-bill-yes",
        "type": "TEXT"
    },
    {
        "name": "balance-of-funds-breakdown",
        "type": "TEXT"
    },
    {
        "name": "broker-email-address",
        "type": "TEXT"
    },
    {
        "name": "broker-phone-number",
        "type": "TEXT"
    },
    {
        "name": "mortgage-account-number",
        "type": "TEXT"
    },
    {
        "name": "sussd-mortgages",
        "type": "CHECK"
    },
    {
        "name": "valuation-report-reviewed-no",
        "type": "CHECK"
    },
    {
        "name": "valuation-report-reviewed-yes",
        "type": "CHECK"
    }
]

export default function (mortgage, config) {
    function checkSelfEmployedExtraDocs() {
        let myDate = new Date(mortgage.requirement.aipDate)
        const today = new Date();
        const currentYear = today.getFullYear();
        const oct31CurrentYear = new Date(currentYear, 9, 31); // 31 October of the current year
        if (today > oct31CurrentYear && myDate < oct31CurrentYear) {
            return true;
        }
        return false;
    }

    const mortgageLoanAccountNumber = () => {
        let map = {}
        let errors = []
        const digits = mortgage.requirement.mortgageAccountNo
        if (!digits) {
            errors.push('No mortgage loan account number')
        }
        else {
            addBunchToMap(map, 'mortgage-account-number-', digits.toUpperCase().split(''))
        }
        return {map}
    }
    const addressLine1 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 1',
            name : 'addressLine1',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine1')) {
            errors.push('No address line 1')
        }
        else {
            addBunchToMap(map, 'debtor-address-1-', config.addressLine1.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const addressLine2 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 2',
            name : 'addressLine2',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine2')) {
            //errors.push('No address line 2')
        }
        else {
            addBunchToMap(map, 'debtor-address-2-', config.addressLine2.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const addressLine3 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 3',
            name : 'addressLine3',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine3')) {
            //errors.push('No address line 3')
        }
        else {
            addBunchToMap(map, 'debtor-address-3-', config.addressLine3.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const addressLine4 = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Address Line 4',
            name : 'addressLine4',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('addressLine4')) {
            //errors.push('No address line 4')
        }
        else {
            addBunchToMap(map, 'debtor-address-4-', config.addressLine4.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const city = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'City',
            name : 'city',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('city')) {
            errors.push('No city')
        }
        else {
            addBunchToMap(map, 'city-', config.city.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const country = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Country',
            name : 'country',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('country')) {
            errors.push('No country')
        }
        else {
            addBunchToMap(map, 'country-', config.country.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const debtorName = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Debtor Name',
            name : 'debtorName',
            type : 'TEXT',
            group: 'DEBTOR NAME'
        })
        if (!config.hasOwnProperty('debtorName')) {
            errors.push('No debtor name')
        }
        else {
            addBunchToMap(map, 'debtor-name-', config.debtorName.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const iban = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'IBAN',
            name : 'iban',
            type : 'TEXT',
            group: 'IBAN'
        })
        if (!config.hasOwnProperty('iban')) {
            errors.push('No IBAN')
        }
        else {
            addBunchToMap(map, 'iban-', config.iban.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }
    const postCode = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Post Code',
            name : 'postCode',
            type : 'TEXT',
            group: 'DEBTOR ADDRESS'
        })
        if (!config.hasOwnProperty('postCode')) {
            errors.push('No post code')
        }
        else {
            addBunchToMap(map, 'post-code-', config.postCode.toUpperCase().split(''))
        }
        return {
            map,
            ask,
            errors
        }
    }

    // ---

    const header = () => {
        let map = {}
        let ask = []
        let errors = []
        map['sussd-mortgages'] = `Truss Mortgages Ltd T/A Suss'd Mortgages`
        map['broker-phone-number'] = `01 685-4888`
        map['broker-email-address'] = `help@sussd.ie`
        return {
            map,
            ask,
            errors
        }
    }
    const applicant1Name = () => {
        let map = {}
        let errors = []
        const name = mortgage.applicant1.fullName.toUpperCase()
        if (!name) {
            errors.push('No name for applicant 1')
        }
        else {
            map['app1-full-name'] = name
        }
        return {
            map,
            errors
        }
    }
    const applicant2Name = () => {
        let map = {}
        let errors = []
        if (mortgage.twoApplicants) {
            const name = mortgage.applicant2.fullName.toUpperCase()
            if (!name) {
                errors.push('No name for applicant 2')
            }
            else {
                map['app2-full-name'] = name
            }
        }
        return {
            map,
            errors
        }
    }

    const amendAIPAsApproved = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Amend AIP Approved Details',
            name : 'details',
            type : 'TEXT',
            group: 'Section 1'
        })
        map['advice-amended-aip-details']
            = config.hasOwnProperty('details') ? config.details : 'NO CHANGE TO AIP. ALL CONDITIONS MET'
        return {
            map,
            ask,
            errors
        }
    }
    const amendAIPAdditionalInfo = () => {
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Amend AIP Additional Info ',
            name : 'amendAIPAdditionalInfo',
            type : 'TEXT',
            group: 'Section 1'
        })
        map['advice-amended-aip-details-additional-information']
            = config.hasOwnProperty('amendAIPAdditionalInfo') ? config.amendAIPAdditionalInfo : ''
        return {
            map,
            ask,
            errors
        }
    }
    const valuationReportReviewed = () => {
        let map = {}
        let ask = []
        let errors = []
        map['valuation-report-reviewed-yes'] = true
        map['valuation-report-reviewed-no'] = false
        return {
            map,
            ask,
            errors
        }
    }

    const politicallyExposedPerson1 = () => {
        let map = {}
        let ask = []
        let errors = []
        map['app1-pep-yes'] = false
        map['app1-pep-no'] = true
        return {
            map,
            ask,
            errors
        }
    }
    const politicallyExposedPerson2 = () => {
        let map = {}
        let ask = []
        let errors = []
        if (mortgage.twoApplicants) {
            map['app2-pep-yes'] = false
            map['app2-pep-no'] = true
        }

        return {
            map,
            ask,
            errors
        }
    }
    const highNetWorthApp1 = () => {
        let map = {}
        let ask = []
        let errors = []
        map['app1-high-net-worth-customer-yes'] = false
        map['app1-high-net-worth-customer-no'] = true
        return {
            map,
            ask,
            errors
        }
    }
    const highNetWorthApp2 = () => {
        let map = {}
        let ask = []
        let errors = []
        if (mortgage.twoApplicants) {
            map['app2-high-net-worth-customer-yes'] = false
            map['app2-high-net-worth-customer-no'] = true
        }
        return {
            map,
            ask,
            errors
        }
    }
    const identification = () => {
        let map = {}
        let ask = []
        let errors = []
        map['app1-valid-passport-yes'] = true
        map['app1-valid-utility-bill-yes'] = true
        if (mortgage.twoApplicants) {
            map['app2-valid-passport-yes'] = true
            map['app2-valid-utility-bill-yes'] = true
        }
        return {
            map,
            ask,
            errors
        }
    }

    const aipDocsAddressed = () => {
        let map = {}
        let ask = []
        let errors = []
        map['app1-all-aip-conditions'] = true
        if (mortgage.twoApplicants) {
            map['app2-all-aip-conditions'] = true
        }
        return {
            map,
            ask,
            errors
        }
    }

    const payeDocs = () => {
        let map = {}
        let ask = []
        let errors = []
        if (legacyIncome(mortgage)) {
            let paye1 = mortgage.applicant1.employmentType === EmploymentTypes.EMPLOYED
            map['app1-most-recent-payslip-yes'] = !!paye1
            map['app1-bank-statement-yes'] = !!paye1
            let self1 = mortgage.applicant1.employmentType === EmploymentTypes.SELFEMPLOYED
            map['app1-months-non-aib-statement'] = !!self1
            if (mortgage.twoApplicants) {
                let paye2 = mortgage.applicant2.employmentType === EmploymentTypes.EMPLOYED
                map['app2-most-recent-payslip-yes'] = !!paye2
                map['app2-bank-statement-yes'] = !!paye2
                let self2 = mortgage.applicant2.employmentType === EmploymentTypes.SELFEMPLOYED
                map['app2-months-non-aib-statement'] = !!self2
            }
        }
        else {
            let paye1 = mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED)
            map['app1-most-recent-payslip-yes'] = !!paye1
            map['app1-bank-statement-yes'] = !!paye1
            let self1 = mortgage.applicant1.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED)
            map['app1-months-non-aib-statement'] = !!self1
            if (mortgage.twoApplicants) {
                let paye2 = mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.EMPLOYED)
                map['app2-most-recent-payslip-yes'] = !!paye2
                map['app2-bank-statement-yes'] = !!paye2
                let self2 = mortgage.applicant2.employmentIncome.find(it => it.employmentType === EmploymentTypes.SELFEMPLOYED)
                map['app2-months-non-aib-statement'] = !!self2
            }
        }
        return {
            map,
            ask,
            errors
        }
    }
    const funding = () => {
        const {
            price,
            stampDuty,
            legalFees,
            propertyValuation,
            totalCosts
        } = balanceOfFundsCosts(mortgage)

        const costs = [
            {
                key   : 1,
                name  : "Price",
                amount: price
            },
            {
                key   : 2,
                name  : "Stamp Duty",
                amount: stampDuty
            },
            {
                key   : 3,
                name  : "Legal Fees",
                amount: legalFees
            },
            {
                key   : 4,
                name  : "Property Valuation",
                amount: propertyValuation
            }
        ]

        //Funds
        const {
            loan,
            gifts,
            helpToBuy,
            firstHome,
            requiredSavings,
            bookingDepositPaid,
            contractDepositPaid
        } = balanceOfFundsFunds(mortgage)

        const funds = []
        if (loan > 0) {
            funds.push({
                key   : 0,
                name  : "Mortgage",
                amount: loan
            })
        }
        if (gifts > 0) {
            funds.push({
                key   : 1,
                name  : "Gifts",
                amount: gifts
            })
        }
        if (helpToBuy > 0) {
            funds.push({
                key   : 2,
                name  : "Help to Buy",
                amount: helpToBuy
            })
        }
        if (firstHome > 0) {
            funds.push({
                key   : 3,
                name  : "First Home",
                amount: firstHome
            })
        }
        if (bookingDepositPaid > 0){
            funds.push({
                key   : 4,
                name  : "Booking Deposit Paid",
                amount: bookingDepositPaid
            })
        }
        if (contractDepositPaid > 0){
            funds.push({
                key   : 5,
                name  : "Contract Deposit Paid",
                amount: contractDepositPaid
            })
        }
        funds.push({
            key   : 6,
            name  : "Savings",
            amount: requiredSavings
        })

        //Nicely space the cells so totals are at the bottom
        if (funds.length > costs.length) {
            let diff = funds.length - costs.length
            for (let i = 0; i < diff; i++) {
                costs.push({
                    key   : `c.${i}`,
                    name  : "",
                    amount: ""
                })
            }
        }
        else if (costs.length > funds.length) {
            let diff = costs.length - funds.length
            for (let i = 0; i < diff; i++) {
                funds.push({
                    key   : `f.${i}`,
                    name  : "",
                    amount: ""
                })
            }
        }
        costs.push({
            key   : 'tF',
            name  : "TOTAL",
            amount: totalCosts
        })
        funds.push({
            key   : 'tC',
            name  : "TOTAL",
            amount: totalCosts
        })

        let map = {}
        let ask = []
        let errors = []
        costs.forEach((cost, index) => {
            map[`bof-fund-desc-${index + 1}`] = cost.name
            map[`bof-fund-value-${index + 1}`] = cost.amount.toString()
        })
        funds.forEach((fund, index) => {
            map[`bof-cost-desc-${index + 1}`] = fund.name
            map[`bof-cost-value-${index + 1}`] = fund.amount.toString()
        })

        return {
            map,
            ask,
            errors
        }
    }

    const additionalDocsSelfEmployed = ()=>{
        let map = {}
        let ask = []
        let errors = []
        if (checkSelfEmployedExtraDocs()) {
            if (legacyIncome(mortgage)) {
                if (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                    map['app1-up-to-date-certified-accounts-by-accountant'] = true
                }
                if (mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) {
                    map['app2-up-to-date-certified-accounts-by-accountant'] = true
                }
            }
            else{
                if (mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED)){
                    map['app1-up-to-date-certified-accounts-by-accountant'] = true
                }
                if (mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED)){
                    map['app2-up-to-date-certified-accounts-by-accountant'] = true
                }
            }
        }
        return {
            map,
            ask,
            errors
        }
    }

    const helpToBuy = () => {
        let map = {}
        let ask = []
        let errors = []
        if (mortgage.requirement.helpToBuyScheme === BoolLike.TRUE){
            map['app1-help-to-buy-acknowledgement-form'] = true
            map['app1-help-to-buy-access-code'] = true
            map['app1-help-to-buy-developer-name'] = true
        }
        return {
            map,
            ask,
            errors
        }
    }

    const costOfConstruction = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Cost of Construction',
            name : 'costOfConstruction',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-cost-of-construction']
            = config.hasOwnProperty('costOfConstruction') ? config.costOfConstruction : false
        return {
            map,
            ask,
            errors
        }
    }
    const siteValue = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Site Value',
            name : 'siteValue',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-site-value-input']
            = config.hasOwnProperty('siteValue') ? config.siteValue : false
        return {
            map,
            ask,
            errors
        }
    }

    const propertySize = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Property Size',
            name : 'propertySize',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-property-size']
            = config.hasOwnProperty('propertySize') ? config.propertySize : false
        return {
            map,
            ask,
            errors
        }
    }
    const architectsPI = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Architects PI Provided',
            name : 'architectsPI',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-architecs-pi-provided']
            = config.hasOwnProperty('architectsPI') ? config.architectsPI : false
        return {
            map,
            ask,
            errors
        }
    }
    const finalGrant = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Final Grant of Planning',
            name : 'finalGrant',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-final-grant-of-planning']
            = config.hasOwnProperty('finalGrant') ? config.finalGrant : false
        return {
            map,
            ask,
            errors
        }
    }
    const planningApproved = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Planning Approved for Garage',
            name : 'planningApproved',
            type : 'CHECK',
            group: 'Self Build - No work Commenced'
        })
        map['app1-planning-approved-for-garage']
            = config.hasOwnProperty('planningApproved') ? config.planningApproved : false
        return {
            map,
            ask,
            errors
        }
    }
    const costOfConstructionWorksStarted = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Cost of Construction',
            name : 'costOfConstructionWorksStarted',
            type : 'CHECK',
            group: 'Self Build - Work Has Commenced'
        })
        map['app1-cost-of-instruction-w']
            = config.hasOwnProperty('costOfConstructionWorksStarted') ? config.costOfConstructionWorksStarted : false
        return {
            map,
            ask,
            errors
        }
    }
    const letterOfSupervision = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Letter of Supervision',
            name : 'letterOfSupervision',
            type : 'CHECK',
            group: 'Self Build - Work Has Commenced'
        })
        map['app1-letter-of-supervision']
            = config.hasOwnProperty('letterOfSupervision') ? config.letterOfSupervision : false
        return {
            map,
            ask,
            errors
        }
    }

    let engineersConfirmation = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Engineers Confirmation',
            name : 'engineersConfirmation',
            type : 'CHECK',
            group: 'Self Build - Work Has Commenced'
        })
        map['app1-engineer-confirmation']
            = config.hasOwnProperty('engineersConfirmation') ? config.engineersConfirmation : false
        return {
            map,
            ask,
            errors
        }
    }

    let satisfactoryEvidence = ()=>{
        let map = {}
        let ask = []
        let errors = []
        ask.push({
            label: 'Satisfactory Evidence',
            name : 'satisfactoryEvidence',
            type : 'CHECK',
            group: 'Self Build - Work Has Commenced'
        })
        map['app1-satisfactory-evidence']
            = config.hasOwnProperty('satisfactoryEvidence') ? config.satisfactoryEvidence : false
        return {
            map,
            ask,
            errors
        }
    }

    const sections = [
        header(),
        mortgageLoanAccountNumber(),
        applicant1Name(),
        applicant2Name(),
        amendAIPAsApproved(),
        amendAIPAdditionalInfo(),
        valuationReportReviewed(),
        politicallyExposedPerson1(),
        politicallyExposedPerson2(),
        highNetWorthApp1(),
        highNetWorthApp2(),
        identification(),
        aipDocsAddressed(),
        payeDocs(),
        funding(),
        additionalDocsSelfEmployed(),
        helpToBuy(),
        costOfConstruction(),
        siteValue(),
        propertySize(),
        architectsPI(),
        finalGrant(),
        planningApproved(),
        costOfConstructionWorksStarted(),
        letterOfSupervision(),
        engineersConfirmation(),
        satisfactoryEvidence(),

    ]

    return {sections}
}
