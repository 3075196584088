import React from 'react';
import {Badge, Popover, Switch} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {geekblue, gray, orange,  red} from "@ant-design/colors";
import './align-cell.css'

import Costs from "./balance-of-funds/Costs";
import Funds from "./balance-of-funds/Funds";
import Summary from "./summary/Summary";
import Employment from "./employment/Employment";
import RepaymentCapacity from "./repayment-capacity/RepaymentCapacity";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import RecurringCosts from "./recurring-costs/RecurringCosts";
import Credit from "./credit/Credit";
import OwnContribution from "./own-contribution/OwnContribution";

export const expandArrayResult = (arrayAction, array) => {
    if (array.length === 1) {
        return array[0]
    }
    if (array.length === 0) {
        return {
            value: 0
        }
    }
    let amt = 0
    let attr
    switch (arrayAction) {
        case 'add':
            attr='sum'
            amt = array.reduce((acc, item) => {
                acc += item.value
                return acc
            }, 0)
            break;
        default:

    }

    return {
        [attr]: amt,
        value       : array,
        array        : arrayAction
    }
}
export const accumulateMortgageFigures = (figures) => {
    return figures.reduce((acc, row) => {
        let innerVal = (config) => {
            if (typeof config.value === "string"){
                return 0
            }
            if (config.array === 'add') {
                // console.log({sum: config.sum})
                return config.sum
            }
            // console.log({val: config.value})
            return config.value || 0
        }
        if (!!row.app1value) {
            acc += parseInt(innerVal(row.app1value))
        }
        if (!!row.app2value) {
            acc += parseInt(innerVal(row.app2value))
        }
        return acc
    }, 0)
}
export const mortgageFiguresColumns = (mortgage) => {
    let cols = [
        {
            key      : 'label',
            title    : '',
            dataIndex: 'label',
            className: 'align-top',
            render   : (text) => capitaliseFirst(text.split(/(?<=[a-z])(?=[A-Z])/).join(' '))
        },
        {
            key      : 'app1value',
            title    : mortgage.applicant1.firstName,
            dataIndex: 'app1value',
            align    : 'right',
            className: 'align-bottom',
            render   : (value) => <CellRender config={value}/>
        }
    ]
    if (mortgage.twoApplicants){
        cols.push({
            key      : 'app2value',
            title    : mortgage.applicant2.firstName,
            dataIndex: 'app2value',
            align    : 'right',
            className: 'align-bottom',
            render   : (value) => <CellRender config={value}/>
        })
    }
    return cols
}
function FiguresUsed({
    useVerified,
})
{
    const [viewingVerified, setViewingVerified] = React.useState(useVerified)

    return (<div style={{backgroundColor:'#f6f6f6'}}>
        <div className="d-row my-15 j-center">
            <Switch checkedChildren="Verified" unCheckedChildren="Claimed" value={viewingVerified}
                    onChange={setViewingVerified}/>
        </div>
        <div className="d-row gap-15 j-equal">
            <div className="d-col gap-15">

                <OwnContribution verified={viewingVerified}/>
                <div className="d-row j-equal gap-15">
                    <Costs/>
                    <Funds/>

                </div>
                <Employment verified={viewingVerified}/>

            </div>
            <div className="d-col gap-15">
                <Summary/>
                <RepaymentCapacity verified={viewingVerified}/>

                <RecurringCosts/>

                <Credit/>

            </div>

        </div>

    </div>)
}
export function TableHeading({
    title,
    color,
    total
})
{
    return <div className="d-row j-between a-center" style={{color}}>
        <strong>{title}</strong>
        <span style={{
            fontSize  : 18,
            fontWeight: 'bold'
        }}>{total?.toEuro()}</span>
    </div>
}
function BadgeRender({
    value,
    error,
    warning,
    message
})
{
    let status
    let alert = null
    if (error) {
        status = red.primary
        alert = error
    }
    else if (warning) {
        status = orange.primary
        alert = warning
    }
    else {
        status = geekblue.primary
        alert = message
    }
    if (typeof value === "object") {
        console.log({
            errorObjectReceivedAsValue: {
                warning,
                error,
                message,
                value
            }
        })
        return 'wonky'
    }
    let v = isNaN(value) || value === '' ? value : value?.toEuro()
    let style = {
        color: v === "€0" ? gray[1] : '',
    }
    let answer = <span className="d-row" style={style}>{v}</span>
    if (alert) {
        return <div className="d-row j-end" style={{gap: 3}}>
            {answer}
            <Popover title={alert}>
                <Badge size="small" color={status}/>
            </Popover>
        </div>
    }
    return <div className="d-row j-end" style={{paddingRight: 9}}>
        {answer}
    </div>
}
function Methodology({
    type,
    array,
    total
})
{
    let lastIndex = array.length - 1
    let icon = type === "add" ? <PlusOutlined/> : null

    let totStyle = total === 0 ? {color: gray[1]} : {}
    return <>
        <div className="d-col h-100" style={{paddingRight: 9}}>
            <div className="d-row mt-auto ">
                <div className="d-col">
                    {array.map((item, i) => {
                        try {
                            return <div key={i} enterKeyHint={12} className="d-row gap-10 j-end pe-15">
                                <div className="text-end pe-3">{i === lastIndex && icon}</div>
                                <BadgeRender value={item.value} error={item.error} message={item.message}/>
                            </div>
                        }
                        catch (e) {
                            console.log({
                                SITTER: {
                                    type,
                                    array,
                                    total
                                }
                            })
                        }
                    })}
                </div>
                <div className="d-col"></div>
            </div>
            <div className="d-row gap-10 j-end mt-auto" style={{borderTop: '1px solid gray'}}>
                <div style={totStyle}>{total.toEuro()}</div>
            </div>
        </div>
    </>

}
export function CellRender({config}) {
    if (!config){
        return 'no config'
    }
    if (config.array) {
        let total = config.array === 'add' ? config.sum : 4444444
        return <Methodology type={config.array} array={config.value} total={total}/>
        // return <BadgeRender value={total} error={config.value[0].error} message={config.value[0].message}
        //                     warning={config.value[0].warning}/>
    }
    return <BadgeRender value={config.value} error={config.error} message={config.message}
                        warning={config.warning}/>
    // if (config.array.length === 1 || total === 0) {
    //     let v = total?.toEuro()
    //     if (v === "€0") {
    //         return <span style={{color: gray[1]}}>{v}</span>
    //     }
    //     return v
    // }
    // if (value.error) {
    //     return <Badge status="error" text={value.error}/>
    // }
    // if (value.warning) {
    //     return <Badge status="warning" text={value.warning}/>
    // }
    // if (value.array) {
    //     return <Methodology type={value.array} array={value.value} total={value.sum}/>
    // }
    // let v = isNaN(value.value) || value.value === '' ? value.value : value?.value?.toEuro()
    //
    // if (v === "€0") {
    //     return <span style={{color: gray[2]}}>{v}</span>
    // }
    // return v
}

export default FiguresUsed;