import React, {useContext, useEffect, useMemo, useState} from 'react';
import * as pdfjs from "pdfjs-dist";
import {Button, Card, Checkbox, Collapse, Form, Input, Modal, Select} from "antd";
import {DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {gray, green, volcano} from "@ant-design/colors";
import countries from "../../../../assets/scripts/countries.json";
import {PdfIdentifyContext} from "../../../providers/PdfIdentifyProvider";
import useValueFromTextExtractor from "../useValueFromTextExtractor";
//pdfjs.GlobalWorkerOptions.workerSrc = 'node_modules/pdfjs-dist/build/pdf.worker.min.js';
let tX = 4
let tY = 5
let scale = 1.5
function usePdfRecogniser() {
    const idContext = useContext(PdfIdentifyContext)
    const canvasRef = React.useRef(null);
    const outerRef = React.useRef(null);
    const pages = React.useRef([]);
    const [box, setBox] = useState({
        startX  : null,
        startY  : null,
        currentX: null,
        currentY: null,
        drawing : false,
        done    : false
    });
    const [reporting, setReporting] = useState({
        x: 0,
        y: 0
    })
    const [grabbed, setGrabbed] = useState({})
    const [grabbing, setGrabbing] = useState(false)
    const [dimensions, setDimensions] = useState(false)

    // PDF LOADING
    const readPdf = async (file) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        return new Promise((resolve, reject) => {
            reader.onload = async () => {
                try {
                    const pdfData = new Uint8Array(reader.result);
                    const pdf = await pdfjs.getDocument({data: pdfData}).promise;
                    resolve(pdf)
                }
                catch (error) {
                    reject(error);
                }
            };
            reader.onerror = () => {
                reject('Error reading file.');
            };
        });
    };
    const pdfToScreen = async (page) => {
        const context = canvasRef.current.getContext('2d');
        const viewport = page.getViewport({scale});
        canvasRef.current.width = viewport.width;
        canvasRef.current.height = viewport.height;
        outerRef.current.style.width = `${viewport.width}px`;
        outerRef.current.style.height = `${viewport.height}px`;
        await page.render({
            canvasContext: context,
            viewport     : viewport
        }).promise;

    }
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const pdf = await readPdf(file);
                pages.current = [];
                let page1 = await pdf.getPage(1);
                await pdfToScreen(page1);
                const numPages = pdf.numPages;
                for (let i = 1; i <= numPages; i++) {
                    const page = await pdf.getPage(i);
                    let vp = page.getViewport({scale: 1})
                    const content = await page.getTextContent({
                        normalizeWhitespace    : true,
                        disableCombineTextItems: false
                    });
                    pages.current.push({
                        items : content.items,
                        width : vp.width,
                        height: vp.height
                    })
                }
                setDimensions([
                    pages.current[0].width,
                    pages.current[0].height
                ])

            }
            catch (error) {
                console.error('Error extracting text:', error);
            }
        }
    };

    // BOX HIGHLIGHT MANAGEMENT
    const handleReset = () => {
        setBox({
            startX  : null,
            startY  : null,
            currentX: null,
            currentY: null,
            drawing : false,
            done    : false
        })
        setGrabbing(true)
        setGrabbed({})
    }
    const getBox = () => {
        let w = 0, h = 0, t = 0, l = 0
        if (box.drawing || box.done) {
            w = Math.abs(box.currentX - box.startX);
            h = Math.abs(box.currentY - box.startY);
            t = box.startY;
            l = box.startX;
            if (box.currentX < box.startX) {
                l = box.currentX;
                w = Math.abs(box.currentX - box.startX);
            }
            if (box.currentY < box.startY) {
                t = box.currentY;
                h = Math.abs(box.currentY - box.startY);
            }
        }
        return {
            w,
            h,
            t,
            l
        }
    }

    let {
            w,
            h,
            t,
            l
        } = getBox()

    const shadingProps = {
        style: {
            backgroundColor: 'rgba(68,145,216,0.34)',
            position       : 'absolute',
            top            : `${t}px`,
            left           : `${l}px`,
            width          : `${w}px`,
            height         : `${h}px`,
        }
    }
    const canvasProps = {
        style: {
            position: 'relative',
            border  : 0
        },
        ref  : canvasRef
    }
    const drawingProps = {
        onMouseDown: (event) => {

            if (!grabbing) {
                return;
            }
            const rect = canvasRef.current.getBoundingClientRect();
            let startX = event.pageX - rect.left - window.scrollX;//event.clientX - rect.left;
            let startY = event.pageY - rect.top - window.scrollY; // event.clientY - rect.top;
            setBox(b => ({
                startX,
                startY,
                currentX: startX,
                currentY: startY,
                drawing : true,
                done    : false
            }))

        },
        onMouseMove: (event) => {
            if (!grabbing) {
                return;
            }
            const rect = canvasRef.current.getBoundingClientRect();
            const currentX = event.pageX - rect.left - window.scrollX //event.clientX - rect.left;
            const currentY = event.pageY - rect.top - window.scrollY // event.clientY - rect.top;
            setReporting({
                x: Math.round(currentX),
                y: Math.round(currentY)
            })
            if (!box.drawing) {
                return;
            }
            setBox(b => ({
                ...b,
                currentX,
                currentY,
            }))
        },
        onMouseUp  : (event) => {
            if (!grabbing) {
                return;
            }
            if (!box.drawing) {
                return;
            }
            setBox(b => ({
                ...b,
                drawing: false,
                done   : true,
            }))
        },
        ref        : outerRef,
        style      : {
            position  : 'relative',
            flexShrink: 0
        }
    }
    // CONTENT FINDER
    const findContent = () => {
        let minX = (Math.min(box.startX, box.currentX) / scale).toFixed(1)
        let maxX = (Math.max(box.startX, box.currentX) / scale).toFixed(1)
        // Y is measured from the top
        let minY = ((canvasRef.current.height - Math.max(box.startY, box.currentY)) / scale).toFixed(1)
        let maxY = ((canvasRef.current.height - Math.min(box.startY, box.currentY)) / scale).toFixed(1)

        let items = pages.current[0].items
        let content = items.filter(item => {
            //   if (item.str.trim() === '') return false
            let x1 = item.transform[tX]
            let x2 = item.transform[tX] + item.width
            let y1 = item.transform[tY]
            let y2 = item.transform[tY] + item.height
            return x1 > minX && x2 < maxX && y1 > minY && y2 < maxY
        })

        let coords = {
            minX,
            maxX,
            minY,
            maxY
        }
        let text = (content.map(item => item.str).join('\n'))
        return {
            text,
            coords
        }
    }
    const findTable = () => {
        let minX = (Math.min(box.startX, box.currentX) / scale).toFixed(1)
        let maxX = (Math.max(box.startX, box.currentX) / scale).toFixed(1)
        // Y is measured from the top
        let minY = ((canvasRef.current.height - Math.max(box.startY, box.currentY)) / scale).toFixed(1)
        let maxY = ((canvasRef.current.height - Math.min(box.startY, box.currentY)) / scale).toFixed(1)

        let items = pages.current[0].items
        let content = items.filter(item => {
            //   if (item.str.trim() === '') return false
            let x1 = item.transform[tX]
            let x2 = item.transform[tX] + item.width
            let y1 = item.transform[tY]
            let y2 = item.transform[tY] + item.height
            return x1 > minX && x2 < maxX && y1 > minY && y2 < maxY
        })

        let coords = {
            minX,
            maxX,
            minY,
            maxY
        }
        // find all the rows by grouping by the y value and ordering by the x value
        let rows = []
        content.forEach(item => {
            let y = item.transform[tY]
            let row = rows.find(row => row.y === y)
            if (!row) {
                row = {
                    y,
                    items: []
                }
                rows.push(row)
            }
            row.items.push(item)
        })
        rows = rows.map(row => {
            row.items = row.items.sort((a, b) => a.transform[tX] - b.transform[tX])
            return row
        }).sort((a, b) => b.y - a.y)

        let colNumber = rows.reduce((acc, row) => row.items.length > acc ? row.items.length : acc, 0)

        // modifiy the rows so that each row has the same number of columns and add rows that have just 1 item to
        // previous row's items
        let newRows = rows.map((row, index) => {
            if (row.items.length === colNumber) {
                return row
            }
            if (row.items.length === colNumber - 1) {
                let emptyItem = {
                    str      : " ",
                    transform: row.items[0].transform
                }
                row.items.splice(1, 0, emptyItem)
                // then get all immediate next rows with just 1 item and join them to the current row
                let nextRows = rows.slice(index + 1)
                let indexOfNextFullRow = nextRows.findIndex(row => row.items.length === colNumber)
                let nextOneColumnRows = nextRows.filter((row, i) => row.items.length === 1 && i < indexOfNextFullRow)

                let joinedString = nextOneColumnRows.map(row => row.items[0].str).join(' ')

                return {
                    items: row.items.map((item, i) => {
                        if (i === 0) {
                            return {
                                ...item,
                                str: item.str + ' ' + joinedString
                            }
                        }
                        return item
                    })
                }
            }
            if (row.items.length > 1) {
                console.log({error: 'row with more than 1 item found'})
            }
            return null
        }).filter(row => !!row)
        let strippedRows = newRows.map(row => {
            return row.items.filter(item => item.str.trim() !== '')
        })
        return {
            rows: strippedRows,
            coords
        }
    }
    useEffect(() => {
        if (box.done) {
            if (grabbing?.table) {
                setGrabbed({
                    ...grabbed,
                    [grabbing.table]: findTable()
                })
            }
            else {
                setGrabbed({
                    ...grabbed,
                    [grabbing]: findContent()
                })
            }

            closeGrabber()
        }
    }, [box.done])

    function FileInput() {
        return (<Input style={{width: 300}} type="file" onChange={handleFileUpload}/>)
    }
    const closeGrabber = ()=>{
        setGrabbing(false)
        setBox({
            startX  : null,
            startY  : null,
            currentX: null,
            currentY: null,
            drawing : false,
            done    : false
        })
    }
    const clearGrabber = (grabName) => {
        setGrabbed(s => {
            delete s[grabName]
            return s
        })
        closeGrabber()
    }

    return {
        handleReset,
        mouseCoords: {
            x: reporting.x,
            y: reporting.y
        },
        FileInput,
        props      : {
            canvas : canvasProps,
            shading: shadingProps,
            drawing: drawingProps,
        },
        dimensions,
        grabbed,
        grabbing,
        grab       : (name) => setGrabbing(name),
        grabTable  : (name) => setGrabbing({table: name}),
        clearGrabber
    }
}

export default usePdfRecogniser;