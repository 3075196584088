import React, {useContext} from 'react';
import {Button, Card, Form, Input, Popconfirm, Timeline} from "antd";
import {ClockCircleOutlined, DeleteOutlined, SaveOutlined, SmileOutlined} from "@ant-design/icons";
import useBackendUsers from "../../../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
import {MyAdminTasks} from "../../../../../providers/MyAdminTasksProvider";
import {blue, gray} from "@ant-design/colors";
import ButtonAsync from "../../../../elements/ButtonAsync";

function ItemControls(props) {
    const [pausing, setPausing] = React.useState(false)
    const {operations} = useContext(MyAdminTasks);
    const backendUsers = useBackendUsers()
    const dateFormat = (date => dayjs(date).format("D MMM YYYY HH:mm"))
    const userFormat = (sub) => backendUsers.getBySub(sub).firstName
    const [pausingForm] = Form.useForm()
    const items = []
    if (!props.task) return null
    let pauseActive = false
    items.push({
        color: 'blue',
        children: `${userFormat(props.task.author)} created ${dateFormat(props.task.createdAt)}`,
    })
    if (!props.task.targetStarted) {
        items.push({
            color: 'red',
            children: 'Not Started',
        })
    } else {
        items.push({
            color: 'green',
            children: `${userFormat(props.task.target)} started ${dateFormat(props.task.targetStarted)}`,
        })
        props.task.pauses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach(pause => {
            pauseActive = true
            items.push({
                color: 'orange',
                dot: <ClockCircleOutlined/>,
                children: <>
                    <p className="mb-3">{userFormat(pause.author)} paused {dateFormat(pause.createdAt)}</p>
                    {pause.reason.map(r => <small style={{color: gray[2]}} className="d-block" key={r}>{r}</small>)}
                </>,
            })
            if (!!pause.completedOn) {
                pauseActive = false
                items.push({
                    color: 'green',
                    children: `${userFormat(pause.completedBy)} resumed ${dateFormat(pause.completedOn)}`,
                })
            }
        })
        if (!!props.task.completedOn) {
            items.push({
                color: 'green',
                children: `${userFormat(props.task.completedBy)} completed ${dateFormat(props.task.completedOn)}`,
            })
        }
        if (!!props.task.closedOn) {
            items.push({
                color: 'blue',
                children: `${userFormat(props.task.closedBy)} closed ${dateFormat(props.task.closedOn)}`,
            })
        }
    }

    const actions = {
        startWorking: async () => {
            await operations.update({
                id: props.task.id,
                targetStarted: new Date().toISOString(),
                _version: props.task._version,
            })
        },
        resumeWorking: async () => {
            const pauses = ([...props.task.pauses] || []).map(pause => {
                if (!pause.completedOn) {
                    pause.completedOn = new Date().toISOString()
                    pause.completedBy = backendUsers.me.sub
                }
                return pause
            })
            await operations.update({
                id: props.task.id,
                pauses: JSON.stringify(pauses),
                _version: props.task._version,
            })
        },
        unFinishWorking: async () => {
            await operations.update({
                id: props.task.id,
                completedOn: null,
                completedBy: null,
                _version: props.task._version,
            })
        },
        finishWorking: async () => {
            await operations.update({
                id: props.task.id,
                completedOn: new Date().toISOString(),
                completedBy: backendUsers.me.sub,
                _version: props.task._version,
            })
        },
        closeJob: async (e) => {
            await operations.update({
                id: props.task.id,
                closedOn: new Date().toISOString(),
                closedBy: backendUsers.me.sub,
                _version: props.task._version,
            })
            props.onCancel(e)
        },
        handleReasonSaveClick: async () => {
            const reason = pausingForm.getFieldValue('reason')
            if (reason.trim() === '') return
            await actions.pauseWorking({reason})
        },
        pauseWorking: async ({reason}) => {
            const pauses = [...props.task.pauses] || []
            pauses.push({
                createdAt: new Date().toISOString(),
                author: backendUsers.me.sub,
                reason: reason.split('\n')
            })
            await operations.update({
                id: props.task.id,
                pauses: JSON.stringify(pauses),
                _version: props.task._version,
            })
            pausingForm.resetFields()
            setPausing(false)
        },
        deleteTask: async (e) => {
            await operations.delete({
                id: props.task.id,
                _version: props.task._version,
            })
            props.onCancel(e)
        }
    }
    return (
        <>
            <Timeline items={items}/>


            <div className="d-row f-wrap gap-9">
                {
                    pausing && (
                        <Form
                            form={pausingForm}
                            className="grow">
                            <small style={{color: blue.primary}}>Reason for pausing:</small>
                            <Form.Item noStyle name="reason">
                                <Input.TextArea style={{width: '100%'}}/>
                            </Form.Item>
                            <div className="d-row gap-9">
                                <Button className="my-3" onClick={() => setPausing(false)}>Cancel</Button>
                                <ButtonAsync icon={<SaveOutlined/>} className="my-3"
                                             onClick={actions.handleReasonSaveClick}>Save</ButtonAsync>
                            </div>
                        </Form>

                    )
                }
                {
                    !pausing && (
                        <>
                            {
                        props.task.currentOwner === backendUsers.me.sub && (
                            <>
                                {!props.task.targetStarted && (
                                    <ButtonAsync className="my-3" onClick={actions.startWorking}>Start Work</ButtonAsync>
                                )}
                                {!!props.task.targetStarted && !props.task.completedOn && (
                                    <>
                                        {!pauseActive && (
                                            <>
                                                <ButtonAsync className="my-3" onClick={async () => setPausing(true)}>Pause
                                                    Work</ButtonAsync>
                                                <ButtonAsync className="my-3" onClick={actions.finishWorking}>Finish
                                                    Work</ButtonAsync>
                                            </>
                                        )}
                                        {pauseActive && (
                                            <ButtonAsync className="my-3" onClick={actions.resumeWorking}>Resume
                                                Work</ButtonAsync>
                                        )}
                                    </>

                                )}
                                {!!props.task.completedOn && (
                                    <ButtonAsync className="my-3" onClick={actions.unFinishWorking}>Re-open</ButtonAsync>
                                )}
                            </>
                        )
                    }

                            {
                                props.task.author === backendUsers.me.sub && (
                                    <>
                                        {!!props.task.completedOn && (
                                            <ButtonAsync className="my-3" type="primary" onClick={actions.closeJob}>Close Job Totally</ButtonAsync>
                                        )}
                                        {!props.task.targetStarted && (
                                            <Popconfirm title={'Are you sure you want to delete this task?'} onConfirm={actions.deleteTask}>
                                                <DeleteOutlined/>
                                            </Popconfirm>
                                        )}

                                    </>

                                )
                            }
                        </>
                    )
                }
            </div>

        </>

    )
        ;
}

export default ItemControls;