import MyLittleForm, {
    getClientValue
} from "../../../app/components/client/application-stages/_common/uploads/v2/section-details/MyLittleForm";
import {dataPointRow} from "./verifiableSection";
import {UploadStatuses} from "../../../models";

export class VerifiableUpload {
    constructor(upload, that) {
        this.upload = upload
        this.mutate = that.mortgage.mutate
        this.config = that.config
        this.managerGet = that.uploadDataGetter(upload)
        this.customForms = null
        let defaultItems = this.config.template.items
        let customItems = []
        if (that.config.hasOwnProperty('customTemplate')) {
            let template = that.config.customTemplate(that)
            customItems = template.items
            this.customForms = template.uploadDataForms
        }

        this.items = [...defaultItems, ...customItems]
        this.values = this.items.reduce((acc, tItem) => {
            let row = dataPointRow(this.upload, tItem.name)
            acc[tItem.name] = !!row ? getClientValue(row.verifiedValue, row.verifiedValueType) : undefined
            return acc
        }, {})
        this.answered = {
            all : this.items.every(item => !!this.itemAnswered(this.values, item.name) || !!item.optional),
            some: this.items.some(item => !!this.itemAnswered(this.values, item.name)),
            none: this.items.every(item => !this.itemAnswered(this.values, item.name))
        }
    }

    itemAnswered = (values, name) => {
        let r = values[name]
        return ![
            null,
            undefined
        ].includes(r)
    }
    handleScan = (data)=>{
        let itemsToGrab = this.items.filter(it => !!it.scan)
        let promises = itemsToGrab.map(it=>{
            let value = data[it.scan]
            if (value) {
                let manager = this.managerGet(it.name)
                return manager.save(value)
            }
        })
        return Promise.all(promises)
    }
    getLabel = () => {
        return this.config.getRowLabel(this.values)
    }
    UploadForm = () => {
        let restOfForm = []
        if (this.customForms) {
            restOfForm = this.customForms.map(item=>{
                return <MyLittleForm key={`upload-${this.upload.id}-custom-${item}`} item={this.managerGet(item)}/>
            })
        }
        let Form = this.config.UploadDataForm
        return <Form uploadId={this.upload.id} managerGet={this.managerGet} restOfForm={restOfForm}/>
    }

}