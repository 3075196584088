import React from 'react';
import {DatePicker, Form, Input, Modal} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import useMortgageDocumentsService from "../../../../providers/mortgage/useMortgageDocumentsService";
import {getAttachmentPath} from "../../../emails-manager/AttachmentButtonGroups";

function EsisModal(props) {
    const [saving,setSaving] = React.useState(false)
    const [open, setOpen] = React.useState(true)
    const documentService = useMortgageDocumentsService()
    const mortgage = useMortgage()
    const handleSave= async () => {
        if (!saving) {
            setSaving(true)
            const source = getAttachmentPath(props.attachment, props.email.v2Attachments)
            const result = await documentService.copyAttachmentToUserDocs({
                folder: 'lender-documents',
                title: 'European Standard Information Sheet',
                isPrivate: false,
                fullFilename: 'esis.pdf',
                stage: 'APPROVAL',
                bucketKey: source,
                submissionID: mortgage.activeSubmission.id
            })
            setSaving(false)
            setOpen(false)
        }
    }
    return (
        <Modal
            okButtonProps={{icon: saving ? <LoadingOutlined/>:null}}
            afterClose={props.onCancel}
            destroyOnClose
            open={open}
            title="Mark as ESIS"
            onCancel={()=>setOpen(false)}
            onOk={handleSave}
            okText={'Copy to Portal'}
        >
            <p>Copy file to Portal Documents?</p>
        </Modal>
    );
}

export default EsisModal;