import React, {useState} from 'react';
import {Input} from "antd";

function SlashDate(props) {
    const maxLength = 14
    const [state,setState] = useState({
        inputValue:''
    })
    const autoFillValue = (str)=>{
        const correctDay = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 3) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 31) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctMonth = (str, moreParts) => {
            if (str.length === 1) {
                if (moreParts || parseInt(str) > 1) {
                    str = '0' + str
                }
            }
            if (parseInt(str) > 12) {
                str = str.slice(0, -1)
            }
            return str
        }
        const correctYear = (str) => {
            return str
        }
        const parts = str.split('/').map(item => item.trim())
        const newParts = parts.map((part, i) => {
            if (props?.noDays) {
                if (i === 0) {
                    return correctMonth(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctYear(part)
                }
            } else {
                if (i === 0) {
                    return correctDay(part, parts.length > 1)
                }
                if (i === 1) {
                    return correctMonth(part, parts.length > 2)
                }
                if (i === 2) {
                    return correctYear(part)
                }
            }
        })
        // add next part when days part is completed
        if ((newParts.length === 1 && newParts[0].length === 2)) {
            newParts.push('')
        }

        if (!props?.noDays) {
            // add current decade
            if ((newParts.length === 2 && newParts[1].length === 2)) {
                const y = new Date().getFullYear()

                newParts.push(y.toString().slice(0, -1))
            }
        }
        return newParts.join(' / ')
    }
    const handleChange = (e)=>{
        let autoVal = autoFillValue(e.target.value)
        setState(s=>({...s, inputValue:autoVal }))

        props.onChange(e)
    }
    const placeholder = props?.noDays ? 'MM / YYYY' : 'DD / MM / YYYY';
    return <Input placehodler={placeholder} onChange={handleChange} value={state.inputValue} />;
}

export default SlashDate;