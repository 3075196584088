import {baseRowUpdaterFactory} from "./baseRowUpdaterFactory";
import {baseRowCellFactory} from "./baseRowCellFactory";
export function makeChainable(target) {
    return new Proxy(target, {
        get: async function(obj, prop) {
            if (prop in obj) {
                let myVal = obj[prop];
                if (typeof myVal === 'function') {
                    myVal = obj[prop]();
                }
                if (typeof myVal === 'object' && myVal !== null && !myVal.hasOwnProperty('setValue')) {
                    myVal = makeChainable(myVal);
                }
                return myVal;
            }
            return undefined;
        }
    });
}
export function makeChainableCell(target) {
    return new Proxy(target, {
        get: function(obj, prop, receiver) {
            if (typeof obj[prop] !== 'undefined') {

                let myVal = obj[prop]
                if (typeof myVal === 'function') {
                    myVal = myVal()
                }
                console.log({obj, prop, myVal, type: typeof myVal})
                return myVal
            }
            throw new Error(`Property "${prop}" does not exist`);
        },
    });
}

function baseRowModel({request, initialState,graphQl, attribs : {writable, readable, subscribable, deletable}}){
    const updater = baseRowUpdaterFactory(request, graphQl.mutation.update)
    const update = async (data) => {
        try {
            await updater.update(data)
        }
        catch (error) {
            request.addError(error, 'Update failed')
        }
    }
    const _delete = async () => {
        try {

        }
        catch (error) {
            request.addError(error, 'Delete failed')
        }
    }


    let fields = {}
    Object.keys(writable).forEach(item=>{
        fields[item] = baseRowCellFactory({
            initialState,
            name: item,
            value: initialState?.[item],
            type: writable[item]
        })
    })
    readable.forEach(item=>{
        fields[item] = initialState?.[item]
    })


    return {
        data: initialState,
        update,
        delete: _delete,
        fields
    }
}
export default baseRowModel
