import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Menu} from "antd";
import ConditionEditPanel from "./ConditionEditPanel";
import ConditionUploads from "./ConditionUploads";

ConditionPanels.propTypes = {
    
};

function ConditionPanels(props) {
    const [current, setCurrent] = useState('uploads');
    const onClick = (e) => {

        setCurrent(e.key);
    };
    const items = [
        {
            label: 'Uploads',
            key: 'uploads',
        },
        {
            label: 'User Screen Config',
            key: 'config',

        },
    ];

    return (
        <div className="d-col">
            <Menu style={{width:'auto'}} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
            {current === 'config' &&  <ConditionEditPanel section={props.section}/>}
            {current === 'uploads' &&  <ConditionUploads section={props.section} preventRequestMore={true}/>}
        </div>

    );
}

export default ConditionPanels;