import useMortgage from "../../../../app/providers/mortgage/useMortgage";
import dayjs from "dayjs";
import {joinWithConjunction} from "../../../../assets/scripts/string-functions";
import {ApplicationTypes, MortgageTypes} from "../../../../models";

function Dependants({mortgage}) {
    let dobs = []
    let childMinding = 0
    if (mortgage.applicant1.personal.countDependents > 0){
        for( let i = 0; i< mortgage.applicant1.personal.countDependents; i++){
            dobs.push(dayjs(mortgage.applicant1.personal.dependentDOBs[i], "YYYY-MM-DD"))
        }
        if (mortgage.applicant1.financial.hasChildcareCosts){
            childMinding += mortgage.applicant1.financial.monthlyChildcareCosts
        }
    }
    if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0){
        for( let i = 0; i< mortgage.applicant2.personal.countDependents; i++){
            dobs.push(dayjs(mortgage.applicant2.personal.dependentDOBs[i], "YYYY-MM-DD"))
        }
        if (mortgage.applicant2.financial.hasChildcareCosts){
            childMinding += mortgage.applicant2.financial.monthlyChildcareCosts
        }
    }
    if (!dobs.length) return <p>No dependants</p>
    let childMindingString = childMinding ? <><br/>Child minding costs: €{childMinding} per month</> : <><br/>No child minding costs</>
    let ages = dobs.map(dob => dayjs().diff(dob, 'years'))
    let agesString = joinWithConjunction(ages, '&')
    if (dobs.length === 1) return <p>1 dependant - Age {agesString} {childMindingString}</p>
    return <p>{dobs.length} dependants - Ages {agesString} {childMindingString}</p>
}
function OutlinedProposal({mortgage}) {
    function mortgagerType() {
        if (mortgage.mortgageType === MortgageTypes.REFINANCE) {
            return `Switching mortgage from ${mortgage.properties[0].lender}`
        }
        if (mortgage.applicationType === ApplicationTypes.FIRSTTIME) {
            return `First time buyer${mortgage.twoApplicants && 's'}`
        }
        if (mortgage.applicationType === ApplicationTypes.MOVINGHOME) {
            return `Moving home - selling current PDH in [location].`
        }
        if (mortgage.applicationType === ApplicationTypes.SECONDTIME) {
            return `Purchasing 2nd home`
        }
    }



    return <>
        <p>Seeking to borrow {mortgage.requirement.loanRequired.toEuro()} over a {mortgage.requirement.loanTerm} year term</p>
        {mortgagerType()}
    </>
}
function EmploymentDetails() {
    return <></>
}
function GrossIncomeDetails() {
    return <></>
}
function AccountExplanations() {
    return <></>
}
function ExistingDebtCommitments() {
    return <></>
}
function RepaymentCapacity() {
    return <></>
}
function ProposedSecurityDetails() {
    return <></>
}
function InvestmentProperties() {
    return <></>
}
function Recommendation() {
    return <></>
}
/*
The term you have selected is 17 years which is beyond John’s retirement age. This was deemed acceptable because John has a public service pension which ensures he will have income that will enable him to fund mortgage repayments beyond his retirement age.
 */
export default {
    key  : 'avantCoverLetter',
    label: 'Avant Cover Letter', //  folder: 'Lender CustomerDocuments',
    contents: [
        {
            key     : 'dependants',
            title   : 'Dependants',
            generate: Dependants,
            sequence: 0.1,
        },
        {
            key     : 'outlinedProposal',
            title   : 'Outlined Proposal',
            generate: OutlinedProposal,
            sequence: 0.2,
        },
        {
            key     : 'employmentDetails',
            title   : 'Employment Details',
            generate: EmploymentDetails,
            sequence: 0.3,
        },
        {
            key     : 'grossIncomeDetails',
            title   : 'Gross Income Details',
            generate: GrossIncomeDetails,
            sequence: 0.4,
        },
        {
            key     : 'accountExplanations',
            title   : 'Account Explanations',
            generate: AccountExplanations,
            sequence: 0.5,
        },
        {
            key     : 'existingDebtCommitments',
            title   : 'Existing Debt Commitments',
            generate: ExistingDebtCommitments,
            sequence: 0.4,
        },
        {
            key     : 'repaymentCapacity',
            title   : 'Repayment Capacity',
            generate: RepaymentCapacity,
            sequence: 0.5,
        },
        {
            key     : 'proposedSecurityDetails',
            title   : 'Proposed Security Details',
            generate: ProposedSecurityDetails,
            sequence: 0.6,
        },
        {
            key     : 'investmentProperties',
            title   : 'Investment Properties',
            generate: InvestmentProperties,
            sequence: 0.7,
        },
        {
            key     : 'recommendation',
            title   : 'Recommendation',
            generate: Recommendation,
            sequence: 0.6,
        }
    ]
}