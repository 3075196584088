import dayjs from "dayjs";
import {addBunchToMap} from "../overlay-utils";
export default function (mortgage, config) {

    const accountNumber = () => {
        let map = {}
        let errors = []
        let ask = []
        ask.push({
            name: 'mortgage-sort-code',
            label: 'Mortgage Sort Code',
            type: 'TEXT',
            group: 'ACCOUNT'
        })
        ask.push({
            name: 'mortgage-account-number',
            label: 'Account Number',
            type: 'TEXT',
            group: 'ACCOUNT'
        })
        if (config.hasOwnProperty('mortgage-sort-code')) {
            addBunchToMap(map, 'mortgage-account-s-', config['mortgage-sort-code'].split(""))
        }
        if (config.hasOwnProperty('mortgage-account-number')) {
            addBunchToMap(map, 'mortgage-account-a-', config['mortgage-account-number'].split(""))
        } else {
            if (!mortgage.requirement.mortgageAccountNo) {
                errors.push('No account number')
            } else {
                addBunchToMap(map, 'mortgage-account-a-', mortgage.requirement.mortgageAccountNo.split(""))
            }
        }
        return {map, ask, errors}
    }
    const loan = () => {
        let map = {}
        let errors = []
        let ask = []
        ask.push({
            name: 'property-value',
            label: 'Property Value',
            type: 'TEXT',
            group: 'LOAN'
        })
        ask.push({
            name: 'product-name',
            label: 'Product Name',
            type: 'TEXT',
            group: 'LOAN'
        })
        ask.push({
            name: 'confirmed-rate',
            label: 'Confirmed Rate',
            type: 'TEXT',
            group: 'LOAN'
        })
        ask.push({
            name: 'loan-term',
            label: 'Loan Term',
            type: 'TEXT',
            group: 'LOAN'
        })
        ask.push({
            name: 'green-mortgage',
            label: 'Green Mortgage',
            type: 'CHECK',
            group: 'LOAN'
        })
        ask.push({
            name: 'green-ber',
            label: 'BER',
            type: 'TEXT',
            group: 'LOAN'
        })
        ask.push({
            name: 'loan-amount',
            label: 'Loan Amount',
            type: 'TEXT',
            group: 'LOAN'
        })

        // loan amount
        if (config.hasOwnProperty('loan-amount')) {
            map['loan-amount'] = config['loan-amount']
        } else {
            if (!mortgage.requirement.loanRequired) {
                errors.push('No loan required amount')
            } else {
                map['loan-amount'] = mortgage.requirement.loanRequired.toLocaleString("en-US")
            }
        }

        // product
        if (config.hasOwnProperty('product-name') || mortgage.requirement.lenderProduct) {
            let string = config.hasOwnProperty('product-name') ? config['product-name'] : mortgage.requirement.lenderProduct || 'None'
            let stringLength = string.length
            let fs1 = (stringLength > 40) ? string.substring(0, 40) : string
            let fs2 = (stringLength > 40) ? string.substring(40, 80) : ''
            map['product-name'] = fs1
            map['product-name-2'] = fs2
        }
        else{
            errors.push('No product name')
        }



        //property value
        if (config.hasOwnProperty('property-value')) {
            map['property-value'] = config['property-value']
        } else {
            if (!mortgage.properties[0].propertyValue) {
                errors.push('No property value')
            } else {
                map['property-value'] = mortgage.properties[0].propertyValue.toLocaleString("en-US")
            }
        }

        // ber
        if (config.hasOwnProperty('green-ber')) {
            map['proposed-ber'] = config['green-ber']
        } else {
            if (!mortgage.properties[0].berScore) {
                errors.push('No BER')
            } else {
                map['proposed-ber'] = mortgage.properties[0].berScore
            }
        }

        // confirmed rate
        if (config.hasOwnProperty('confirmed-rate')) {
            map['confirmed-rate'] = config['confirmed-rate']
        } else {
            if (!mortgage.requirement.rate) {
                errors.push('No confirmed rate')
            } else {
                map['confirmed-rate'] = mortgage.requirement.rate.toString()
            }
        }

        // loan term
        if (config.hasOwnProperty('loan-term')) {
            map['loan-term'] = config['loan-term']
        } else {
            if (!mortgage.requirement.loanTerm) {
                errors.push('No loan term')
            } else {
                map['loan-term'] = mortgage.requirement.loanTerm.toString()
            }
        }

        // green mortgage
        if (config.hasOwnProperty('green-mortgage')) {
            if (config['green-mortgage']) {
                map['green-mortgage-yes'] = true
                map['green-mortgage-no'] = false
            } else {
                map['green-mortgage-yes'] = false
                map['green-mortgage-no'] = true
            }
        } else if (!mortgage.requirement.lenderProduct) {
            errors.push('No lender product')
        } else {
            if (mortgage.requirement.lenderProduct.toLowerCase().includes('green')) {
                map['green-mortgage-yes'] = true
                map['green-mortgage-no'] = false
            } else {
                map['green-mortgage-yes'] = false
                map['green-mortgage-no'] = true
            }
        }

        return {map, ask, errors}

    }
    const solicitor = () => {
        let map = {}
        let errors = []
        let ask = []
        ask.push({
            name: 'solicitors-firm-name',
            label: 'Solicitors Firm Name',
            type: 'TEXT',
            group: 'SOLICITOR'
        })
        ask.push({
            name: 'solicitors-address-1',
            label: 'Solicitors Address Line 1',
            type: 'TEXT',
            group: 'SOLICITOR'
        })
        ask.push({
            name: 'solicitors-address-2',
            label: 'Solicitors Address Line 2',
            type: 'TEXT',
            group: 'SOLICITOR'
        })
        if (config.hasOwnProperty('solicitors-firm-name')) {
            map['solicitors-firm-name'] = config['solicitors-firm-name']
        } else {
            if (!mortgage.solicitor.firmName) {
                errors.push('No solicitor firm name')
            } else {
                map['solicitors-firm-name'] = mortgage.solicitor.firmName
            }
        }
        if (config.hasOwnProperty('solicitors-address-1')) {
            map['solicitors-address-1'] = config['solicitors-address-1']
        } else {
            if (!mortgage.solicitor.firmAddress) {
                errors.push('No solicitor address')
            } else {
                map['solicitors-address-1'] = mortgage.solicitor.firmAddress
            }
        }
        if (config.hasOwnProperty('solicitors-address-2')) {
            map['solicitors-address-2'] = config['solicitors-address-2']
        }
        return {map, ask, errors}
    }
    const dateString = () => {
        let map = {}
        const djs = dayjs()
        const y = djs.format("YYYY").split("")
        const m = djs.format("MM").split("")
        const d = djs.format("DD").split("")
        addBunchToMap(map, 'sign-date-y-', y)
        addBunchToMap(map, 'sign-date-m-', m)
        addBunchToMap(map, 'sign-date-d-', d)
        return {map}
    }
    const sections = [
        accountNumber(),
        loan(),
        solicitor(),
        dateString()

    ]
    return {sections}
}
