import React from 'react';
import usePreApprovalAdminDocuments from "../admin-documents/usePreApprovalAdminDocuments";
import {capitaliseWords} from "../../../../../../../assets/scripts/string-functions";
import {ApplicationStages} from "../../../../../../../models";
import useCustomerPreApprovalDocuments from "../../useCustomerPreApprovalDocuments";
import useMortgage from "../../../../../../providers/mortgage/useMortgage";

function usePreApprovalAdminUploads(props) {
    const groups = []
    const mortgage = useMortgage()
    let docs = useCustomerPreApprovalDocuments().filter(it => it.folder === 'customer-docusign')

    return  docs.map(doc => ({
            name    : doc.name,
            section : doc.name,
            title   : capitaliseWords(doc.name.replace(/-/g, ' ')),
            children: '',
            stage   : ApplicationStages.PREAPPROVAL,
            upload  : true
        })).map(item => ({
            ...item,
            key      : item.name,
            private  : false,
            folder: 'submission-documents',
            uploaded: mortgage.UserMortgageDocuments.find(doc => doc.folder === 'submission-documents' && doc.name === item.name),
        }))


}

export default usePreApprovalAdminUploads;