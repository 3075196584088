import React, {useContext, useEffect, useState} from 'react';
import {listAllAndSetItems} from "../../api-functions/functions";
import {UploadStatuses} from "../../../models";
import {
    mortgageQueriesByPending,
    userMortgageUploadsByStatus
} from "../../../graphql/queries";
import useSubscription from "../useSubscription";

import {
    onCreateUserMortgageQuery,
    onCreateUserMortgageUploads,
    onDeleteUserMortgageQuery,
    onDeleteUserMortgageUploads,
    onUpdateUserMortgageQuery,
    onUpdateUserMortgageUploads
} from "../../../graphql/subscriptions";

export const OutstandingItems = React.createContext({});
function OutstandingItemsProvider(props) {
    const [uploads, setUploads] = useState([])
    const [queries, setQueries] = useState([])
    const [needsReview, setNeedsReview] = useState([])
    const addToUploads = (items) => {
        setUploads(s => [...s, ...items.filter(it=> !it._deleted)])
    }
    const addToQueries = (items) => {
        setQueries(s => [...s, ...items.filter(it=> !it._deleted)])
    }
    const addToNeedsReview = (items) => {
        setNeedsReview(s => [...s, ...items.filter(it=> !it._deleted)])
    }
    const fetch = () => {
        // fetch pending uploads
        listAllAndSetItems({status: UploadStatuses.PENDING}, userMortgageUploadsByStatus, addToUploads)
        // fetch reviewing uploads
        listAllAndSetItems({status: UploadStatuses.REVIEWING}, userMortgageUploadsByStatus, addToUploads)
        // fetch queries with answers
        listAllAndSetItems({userAnswered: 1, adminAnswered: {eq:0}}, mortgageQueriesByPending, addToQueries)
    }
    useEffect(() => {
        fetch()
    }, []);
    useSubscription({
        subscription: {
            name: 'UserMortgageQuery',
            onUpdate: onUpdateUserMortgageQuery,
            onCreate: onCreateUserMortgageQuery,
            onDelete: onDeleteUserMortgageQuery,
        },
        setState: (updatingFunction) => {

            setQueries(prevState => {
                const newState = updatingFunction(prevState);
                return newState.filter(it => {
                    if (it._deleted) return false
                    return it.userAnswered === 1 && it.adminAnswered === 0;
                })
            });
        }
    })
    useSubscription({
        subscription: {
            name: 'UserMortgageUploads',
            onUpdate: onUpdateUserMortgageUploads,
            onCreate: onCreateUserMortgageUploads,
            onDelete: onDeleteUserMortgageUploads,
        },
        setState: (updatingFunction) => {

            setUploads(prevState => {
                const newState = updatingFunction(prevState);
                return newState.filter(it => !!it && !it._deleted)
            });
        }
    })

    const organisedMortgages = {}
    uploads.forEach(item => {
        const key = item.mortgageID
        if (!organisedMortgages[key]) {
            organisedMortgages[key] = {uploads: [], queries: [], needsReview:[]}
        }
        if (item.status === UploadStatuses.PENDING) {
            organisedMortgages[key].uploads.push(item)
        }
        else if (item.status === UploadStatuses.REVIEWING) {
            organisedMortgages[key].needsReview.push(item)
        }
    })
    queries.forEach(item => {
        const key = item.mortgageID

        if (!organisedMortgages[key]) {
            organisedMortgages[key] = {uploads: [], queries: [], needsReview:[]}
        }
        organisedMortgages[key].queries.push(item)
    })
    return (
        <OutstandingItems.Provider
            value={organisedMortgages}>
            {props.children}
        </OutstandingItems.Provider>
    );
}

export default OutstandingItemsProvider;