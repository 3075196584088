import React, {useContext, useEffect} from 'react';
import {Card, ConfigProvider, Form, Popconfirm} from "antd";
import SectionQuestion from "./SectionQuestion";
import {correctValueForSys, correctValueForDb, getItemFromPath} from "../../../../providers/mortgage/MortgageProvider";
import {DeleteOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {useNavigate} from "react-router-dom";
import useApplicationForms from "../../../../providers/mortgage/useApplicationForms";

function SectionForm(props) {
    const mortgage = props.mortgage
    const application = useApplicationForms()
    const [form] = Form.useForm();
    const navigate = useNavigate()

    const getInitialValues = () => {
        const init = {}
        props.questions.forEach(item => {
            let val = getItemFromPath(mortgage, item.target)
            init[item.name] = correctValueForSys(mortgage, item, val)

        })
        return init
    }

    useEffect(() => {
        const init = getInitialValues()

        form.setFieldsValue(init)
    }, [])
    const onDeleteClick = async ()=>{
        await application.repeater.remove(props.repeater, props.index)
        let link =`/client/${mortgage.id}/application-forms/`
        navigate(link)

    }
    const onValuesChange = (map) => {


        Object.keys(map).forEach(key => {

            // let watched = watchers.find(w => w.name === key)
            let updValue = map[key]
            //
            // if (watched) {
            //     watched.watch(form, updValue)
            // }
            // if the value being saved is an item from an array, change the value to the entire updated array
            if (Array.isArray(updValue)) {
                let index = updValue.findIndex((item) => !!item)

                let newValue = updValue[index]

                let orig = form.getFieldValue(key)

                updValue = orig.map((item, currentIndex) =>
                    currentIndex === index ? typeof newValue === "object" ? {...item, ...newValue} : newValue : item
                );
            }
            form.setFieldValue(key, updValue)
        })
    }
    const saveField = async (question) => {
        setTimeout(async () => {
            try {
                const valid = await form.validateFields([question.name])
                if (valid) {
                    let dbValue = correctValueForDb(question.answer.type, valid[question.name])

                    let mutation = {
                        update: {
                            target: question.target,
                            value: dbValue
                        }
                    }
                    mortgage.mutate(mutation)
                }
            } catch (e) {

            }
        }, 100)
    }

    function Extra(){
        if (!props.repeater) return null
        return <Popconfirm
            title={`Delete this ${props.title}?`}
            description={`Are you sure to delete this?`}
            okText="Delete"
            onConfirm={onDeleteClick}
            icon={
                <QuestionCircleOutlined
                    style={{
                        color: red.primary,
                    }}
                />
            }
        >
            <DeleteOutlined/>
        </Popconfirm>
    }
    return (
        <Card
            title={props.title}
            extra={<Extra/>}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Form: {
                            itemMarginBottom: 12,
                        },
                    },
                }}
            >
                <Form
                    validateTrigger="onBlur"
                    onValuesChange={onValuesChange}
                    initialValues={getInitialValues()}
                    form={form}
                    size="medium"
                    labelAlign="left"
                    layout="horizontal"
                    requiredMark={false}
                    colon={false}
                    labelWrap={true}
                    labelCol={{span: 12}}
                >
                    {props.questions.map(question => {
                        if (question?.custom) {
                            if (typeof question.custom === "function"){
                                return question.custom(form)
                            }
                            return question.custom
                        }
                        return <SectionQuestion
                            onBlur={saveField}
                            style={{marginBottom: 20}}
                            key={question.name}
                            question={question}

                        />

                    })}
                </Form>
            </ConfigProvider>

        </Card>)
}

export default SectionForm;