import {listAllAndSetItems, performGQL} from "./functions";
import {listAdminLenders, publicLenderRatesByLenderID} from "../../graphql/queries";
import {createPublicLenderRate, deletePublicLenderRate, updatePublicLenderRate} from "../../graphql/mutations";

export async function lenders(setItems){
    const variables = {}
    return await listAllAndSetItems( variables, listAdminLenders, setItems )
}

export async function getRates(lenderId, setItems){
    const variables = {
        lenderID: lenderId
    }
    return await listAllAndSetItems( variables, publicLenderRatesByLenderID, setItems )
}
export async function createRate(item, setItems){
    const variables = {
        input: {
            ...item
        }
    }
    const result =  await performGQL(variables, createPublicLenderRate)
    setItems([result])
}
export async function updateRate(item){
    const variables = {
        input: {
            ...item
        }
    }
    return await performGQL(variables, updatePublicLenderRate)
}
export async function deleteRate(id,_version){
    const variables = {
        input: {
            _version,
            id
        }
    }
    await performGQL(variables, deletePublicLenderRate)
}

export async function getLenderDocumentTemplates(lenderId, setItems){
    const adminPdfOverlayTemplatesByLender = /* GraphQL */ `
        query AdminPdfOverlayTemplatesByLender(
            $lender: ID!
            $sortDirection: ModelSortDirection
            $filter: ModelAdminPdfOverlayTemplateFilterInput
            $limit: Int
            $nextToken: String
        ) {
            adminPdfOverlayTemplatesByLender(
                lender: $lender
                sortDirection: $sortDirection
                filter: $filter
                limit: $limit
                nextToken: $nextToken
            ) {
                items {
                    id
                    lender
                    lenderCode
                    stage
                    fileName
                    templateType
                    bucketKey
                    clientSigns
                    docusign
                    defaultFolder
                    defaultPrivate
                    items {
                        items {
                            id
                            name
                            pageNo
                            x
                            y
                            type
                            style
                            templateId
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            __typename
                        }
                        nextToken
                        startedAt
                        __typename
                    }
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                }
                nextToken
                startedAt
                __typename
            }
        }
    `;
    const variables = {
        lender:lenderId
    }
    return await listAllAndSetItems( variables, adminPdfOverlayTemplatesByLender, setItems )
}