import {performGQL} from "../functions";

const query = /* GraphQL */ `
    mutation CreateUserApplicantAlternateEmailAddresses(
        $input: CreateUserApplicantAlternateEmailAddressesInput!
        $condition: ModelUserApplicantAlternateEmailAddressesConditionInput
    ) {
        createUserApplicantAlternateEmailAddresses(
            input: $input
            condition: $condition
        ) {
            id
            email
            applicantID
            readAccessGroup
            editAccessGroup
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
            __typename
        }
    }
`;
async function createAlternativeEmailAddress(row){
    const variables = {
        input: row
    }

    return await performGQL(variables, query)
}
export default createAlternativeEmailAddress
