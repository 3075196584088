import React, {useMemo} from 'react';
import {Progress, Skeleton, Tree} from "antd";
import {CheckSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";
import useMortgage from "../../../../providers/mortgage/useMortgage";
import {useNavigate} from "react-router-dom";
import {capitaliseFirst} from "../../../../../assets/scripts/string-functions";
import {green, magenta} from "@ant-design/colors";
import useApplicationForms from "../../../../providers/mortgage/useApplicationForms";

function SectionsList(props) {
    const application = useApplicationForms()
    const mortgage = useMortgage()
    const navigate = useNavigate()
    // FILLER MAIN SECTIONS
    const applicantSections = (n) => {
        return application.sections.filter(section => section.applicant === n)
    }
    const mortgageSections = (n) => {
        return application.sections.filter(section => section.applicant === false)
    }

    // FUNCTIONALITY
    const handleNavigation = (path) => {
        let link = `/client/${mortgage.id}/application-forms/${path}`

        navigate(link)
    }
    const handleOnSelect =async (keys, info) => {
        const search = '/add-repeater'
        if (keys[0].endsWith(search)){
            let target = keys[0].replace(search,'')
            const result = await application.repeater.add(target)

            return
        }
        if (!info.node?.children){
            handleNavigation(keys[0])
        }
    };

    // TREE ORGANISATION
    const sectionsToTreeData= (sections)=>{
        return sections.map(item => {
            let key =  item.name
            const innerData = {
                title: <TreeTitle section={item}/>,
                key,
            }
            const icon = item.percent === 100 ? <CheckSquareOutlined style={{fontSize: '18px', color: green.primary}}/> : false

            if (icon){
                innerData.icon = icon
            }
            if (item?.children){
                innerData.children = item.children.map(child=>{
                    const icon2 = child.percent === 100 ? <CheckSquareOutlined style={{fontSize: '18px', color: green.primary}}/> : false
                    const innerInnerData = {
                        title: <TreeTitle section={child}/> ,
                        key: `${key}/${child.index}`,
                        isLeaf: true
                    }
                    if (icon2){
                        innerInnerData.icon = icon2
                    }
                    return innerInnerData
                })
                if (item?.add){
                    innerData.children.push({
                        title: 'Add',
                        key: `${key}/add-repeater`,
                        icon: <PlusSquareOutlined style={{fontSize: '18px', color: magenta.primary}}/>,
                        isLeaf: true
                    })
                }
            }
            else{
                innerData.isLeaf = true
            }
            return innerData
        })
    }
    const childrenForApplicant = (n)=>{
        return sectionsToTreeData(applicantSections(n))
    }
    const childrenForMortgage = ()=>{
        return sectionsToTreeData(mortgageSections())
    }

    const data = useMemo(()=>{

        const data1 = [
            {
                title: 'Mortgage',
                key: 'mortgage',
                children: childrenForMortgage()
            },
            {
                title: 'Applicant 1 Details',
                key: 'applicant-1',
                children: childrenForApplicant(1)
            }
        ];

        if (mortgage.twoApplicants){
            data1.push({
                title: 'Applicant 2 Details',
                key: 'applicant-2',
                children: childrenForApplicant(2)
            })
        }
        return data1
    },[mortgage])
    // RETURN
    function TreeTitle({section}){
        let title = capitaliseFirst(section.title)

        if (section?.children){


           // const sum = data.reduce((total, item) => total + item.percent, 0);
            // if all children are complete, show tick on parent

            // otherwise show percent on each child

        }
        else{
            // if complete show tick on section

            // otherwise percent
        }
        return <>
            {title}
            {section.percent !== 100 && (
                <span className={"ms-6"}>
                <Progress
                    showInfo={false}
                    type="circle"
                    strokeWidth={9}
                    percent={section.percent.toFixed(0)}
                    size={19}/>
                    </span>
            )}
        </>


        // return <Progress showInfo={item.percentShown} gapDegree={180} type="dashboard" strokeWidth={6}
        //           percent={item.percent.toFixed(0)} size={28}/>

    }


    if (!application.sections.length){
        return null
    }


    function Treeeeee(){
        return (
            <Tree.DirectoryTree
                defaultExpandedKeys={['mortgage','applicant-1','applicant-2']}
                showLine
                onSelect={handleOnSelect}
                treeData={data}
                style={{backgroundColor:'#f6f6f6', paddingLeft: 15, paddingTop: 15}}
            />
        )
    }

    return (
        <div className="tree-large p-sticky">
            <Skeleton loading={!mortgage}>

                {!!mortgage && (<Treeeeee/>)}
            </Skeleton>
        </div>

    );
}

export default SectionsList;