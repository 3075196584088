import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import dayjs from "dayjs";

function EmploymentHistory({applicant}) {

    const mortgage = useMortgage()
    let edsVerifiedSection = mortgage.mortgageUploads.setup.find(s => s.name === `employment-summary-${applicant}`)
    let edsVerifiedUploads = edsVerifiedSection.verified.uploads.active

    let employments = {}
    edsVerifiedUploads.forEach(verifiedUpload=>{
        let values = verifiedUpload.verified.values;

        ['1','2','3'].forEach(i=>{
            if (values.hasOwnProperty(`start${i}`) && !!values[`start${i}`]) {
                let employer = values[`employer${i}`] || 'No employer entered'
                if (!employments.hasOwnProperty(employer)) {
                    employments[employer] = []
                }
                employments[employer].push({
                    start: values[`start${i}`],
                    end: values[`end${i}`] || dayjs(),
                    pay: values[`pay${i}`] || 0,
                    year: values.year
                })
            }
        })
    })
    const fourYearsAgo = dayjs().startOf('year').subtract(3, 'year');
    const years = Array.from({ length: 5 }, (_, i) => fourYearsAgo.add(i, 'year'));
    return (
        <div>
            {mortgage.twoApplicants && (<h3><strong>{mortgage[`applicant${applicant}`].firstName}</strong></h3>)}
            {Object.keys(employments).map((employer, index) => {

                    return            (
                <div key={index} style={{ marginBottom: '20px' }}>
                    <h3>{employer}</h3>
                    <div style={{ position: 'relative', height: '30px', background: '#f0f0f0', marginBottom: '10px' }}>
                        {years.map((year, idx) => (
                            <div
                                key={idx}
                                style={{
                                    position: 'absolute',
                                    left: `${((year.diff(fourYearsAgo, 'day')) / 1460) * 100}%`,
                                    height: '100%',
                                    width: '25%',
                                    borderLeft: '1px solid #000',
                                }}
                            >
                                <span style={{ position: 'absolute', top: '-17px', right: '3px', fontSize: '11px', color: '#084c80' }}>{employments[employer].find(item=>{
                                    return Number(item.year) === year.year()
                                })?.pay?.toEuro()}</span>
                                <span style={{ position: 'absolute', top: '100%', left: '-10px' }}>{year.year() }</span>
                            </div>
                        ))}
                        {employments[employer].map((employment, idx) => {
                            const startOffset = Math.max(0, employment.start.diff(fourYearsAgo, 'day'));
                            const endOffset = Math.min(1460, employment.end.diff(fourYearsAgo, 'day')); // 1460 days = 4 years

                            return (
                                <div
                                    title={`${employment.start.format('D MMM YYYY')} - ${employment.end.format('D MMM YYYY')}`}
                                    key={idx}
                                    style={{
                                        position: 'absolute',
                                        left: `${(startOffset / 1460) * 100}%`,
                                        width: `${((endOffset - startOffset) / 1460) * 100}%`,
                                        height: '100%',
                                        background: '#1890ff',
                                        opacity: 0.5
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            )}
        </div>
    );
}

export default EmploymentHistory;