import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {MortgageTypes} from "../../../../../../../models";

function useApplyAdminDocuments(props) {

    // 1. Items to copy into customer lender documents folder
    // 2. Items to create for use as download template for customer uploads
    // 3. Items to create to be docusigned by customer

    const mortgage = useMortgage()
    const items = []

    // 1. Lender Checklist
    switch (mortgage.activeSubmission.lenderCode) {
        case 'avant':
            items.push({
                name: 'declaration-of-identity',
                overlay: true,
            })
            // items.push({
            //     name: 'broker-checklist',
            //     overlay: true,
            // })
            items.push({
                name: 'mortgage-application-document-upload-checklist',
                overlay: true,
            })
            items.push({
                name: 'application-cover-note',
                fill: true
            })
            break;
        case 'ptsb':
            items.push({
                name: 'mortgage-application-for-credit',
                overlay: true,

            })
            items.push({
                name: 'minimum-standards-checklist',
                overlay: true,

            })
            items.push({
                name: 'sight-of-original-declaration',
                overlay: true,

            })
            // if (mortgage.twoApplicants){
            //     items.push({
            //         name: 'cover-note-2-apps',
            //         overlay: true,
            //     })
            // }
            // else{
            //     items.push({
            //         name: 'cover-note-1-app',
            //         overlay: true,
            //     })
            // }

            break;
        case 'haven':
            items.push({
                name: 'declaration-and-authorisations',
                overlay: true,

            })
            items.push({
                name: 'approval-in-principle-checklist',
                overlay: true,
            })
            if (mortgage.twoApplicants){
                // items.push({
                //     name: 'cover-note-2-apps',
                //     overlay: true,
                // })
            }
            // else{
            //     items.push({
            //         name: 'cover-note-1-app',
            //         overlay: true,
            //     })
            // }
            break;
    }
    let folder = 'submission-documents'
    return items.map(item => {
        return {
            ...item,
            key: item.name,
            folder,
            private: true,
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === folder && doc.name === item.name),
        }
    })

}

export default useApplyAdminDocuments;