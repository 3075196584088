import React from "react";

export const wordLimit = (string) => {
    if (!string) string = ''
    const parts = string.split(/[ ]+/);
    parts.length = Math.min(parts.length, 3);
    return parts.join(' ').replace(/,\s*$/, "");
}
export const capitaliseFirst = (string) => {
    if (!string) return ""
    if (string.length === 0) return string;
    if (string) {
        return string.toString().charAt(0).toUpperCase() + string.slice(1);
    }
    return ''
}
export const capitaliseWords = (string) => {
    if (!string) return ""
    if (string.length === 0) return string;
    if (string) {
        return string
            .split(/\s+/) // Split the string by whitespace using a regular expression
            .map(word => capitaliseFirst(word))
            .join(' ');
    }
    return ''
}

export const addressToBlocks = (address) => {
    if (!address) return []
    return address.split(',').map(string => string.trim()).filter(string => {
        return string.length > 0
    });
}
export function hyphenToCamelCase(inputString) {
    return inputString
        .split('-')
        .map((word, index) => {
            if (index === 0) {
                return word;
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        })
        .join('');
}
export function sanitizeFileName(fileName) {
    // Define a regular expression pattern to match disallowed characters
    const disallowedCharsRegex = /[/\\:*?"<>|&;%$]/g;
    // Replace disallowed characters with an underscore "_"
    const sanitizedFileName = fileName.replace(disallowedCharsRegex, '_');
    // Additionally, you can trim any leading/trailing spaces
    // Return the sanitized and trimmed file name
    return sanitizedFileName.trim();
}
export function fileNameAndExtension(fileNameString) {
    const lastDotIndex = fileNameString.lastIndexOf('.');

    if (lastDotIndex === -1) {
        // No dot found in the file name, return the entire name as the filename
        return {fileName: fileNameString, fileExtension: ''};
    }

    const fileName = fileNameString.substring(0, lastDotIndex);
    const fileExtension = fileNameString.substring(lastDotIndex + 1);
    return {fileName, fileExtension};
}
export const joinWithConjunction = (array, conjunction) => {
    if (array.length === 0) {
        return '';
    }
    if (array.length === 1) {
        return array[0];
    }
    const lastElement = array[array.length - 1];
    const restOfArray = array.slice(0, -1);
    return `${restOfArray.join(', ')} ${conjunction} ${lastElement}`;
}
export const joinWithBr = (array) => {
    if (array) {
        return array.flatMap((item, i) => {
            if (i === array.length - 1) {
                return <React.Fragment key={item}>{item}</React.Fragment>
            }
            return [<React.Fragment key={i+item}>{item}<br/></React.Fragment>]
        })
    }
    return array
}
export const joinWithNl = (array) => {
    if (!Array.isArray(array)) return array
    return array.join('\n')
}
export const toEuroString = (value, numSpaces = 8) => {
    // replace all non-numerical characters and . with an empty string
    let test = value ? value.toString().replace(/[^0-9.]/g, '') : 0

    var strNumber = parseInt(parseFloat(test).toFixed(0)).toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits:0});

    // Calculate the number of spaces needed
   // var spacesNeeded = Math.max(0, numSpaces - strNumber.length);

    // Pad spaces to the left of the number
   // var paddedNumber = ' '.repeat(spacesNeeded) + '€' + strNumber;
    return '€ ' + strNumber.trim()
        // return paddedNumber;
}
export const toCurrencyString = (value, symbol)=> {
    let test = value ? value : 0
    let str = parseInt(parseFloat(test).toFixed(0)).toLocaleString('en-US', {minimumFractionDigits: 0,maximumFractionDigits:0});
    return symbol + " " +  str
}
export const numberToWords = (num) => {
    if (num === 0){
        return 'none'
    }
    const ones = [
        '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'
    ];
    const teens = [
        'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const tens = [
        '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];
    const thousands = [
        '', 'thousand', 'million'
    ];

    function chunkToWords(chunk) {
        let word = '';

        const hundred = Math.floor(chunk / 100);
        const tenUnit = chunk % 100;
        const ten = Math.floor(tenUnit / 10);
        const unit = tenUnit % 10;

        if (hundred > 0) {
            word += ones[hundred] + ' hundred ';
        }

        if (tenUnit > 0) {
            if (tenUnit < 10) {
                word += ones[tenUnit] + ' ';
            } else if (tenUnit < 20) {
                word += teens[tenUnit - 10] + ' ';
            } else {
                word += tens[ten] + ' ';
                if (unit > 0) {
                    word += ones[unit] + ' ';
                }
            }
        }

        return word.trim();
    }
    if (num === 0) return 'zero';

    let word = '';
    let chunkCount = 0;

    while (num > 0) {
        const chunk = num % 1000;
        if (chunk > 0) {
            word = chunkToWords(chunk) + ' ' + thousands[chunkCount] + ' ' + word;
        }
        num = Math.floor(num / 1000);
        chunkCount++;
    }
    return word.trim();

}
export function convertHtmlToPlainText(htmlString) {
    // Replace closing </p> tags with newline characters
    let text = htmlString.replace(/<\/p>/g, '\n \n');
    // Remove opening <p> tags
    text = text.replace(/<p>/g, '');
    // Remove any remaining HTML tags if there are any (optional)
    text = text.replace(/<\/?[^>]+(>|$)/g, '');
    // Trim any leading/trailing whitespace
    text = text.trim();
    text = text.replace(/&nbsp;/g, ' ')
    return text;
}