import {dataPoint, get, getNullCells} from './index'
import dayjs from "dayjs";
import {
    ApplicationTypes,
    AssetTypes,
    BuildType, DebtTypes, EmploymentTypes,
    Frequencies,
    MortgageTypes,
    PersonalAccommodationSituations, PersonalMaritalStatuses
} from "../../../models";
import {joinWithConjunction} from "../string-functions";

//const url = 'https://script.google.com/macros/s/AKfycbwhmcWJ0KOWzu9c_QZ2TkgU67P1TP2atbBzC_HlZdjM3UQIN44M7j0A5BQ5R_LtiSri/exec'
//const url = 'https://script.google.com/macros/s/AKfycbwCJzMwPnGw80RkmKNHZcZfCkyEjm_42sJjNN5qxuQhHdocNd-GflKrX0rKCEt7CfIQ2A/exec'
//const url = 'https://script.google.com/macros/s/AKfycbz_uCZ0nUHtMBSMDjE1YDVkr10If1kD6L6I5uC5VP-GX_r0NxcCoDD5MgIfAR-RjguUbw/exec' // 2.4
//const url = 'https://script.google.com/macros/s/AKfycbwhmcWJ0KOWzu9c_QZ2TkgU67P1TP2atbBzC_HlZdjM3UQIN44M7j0A5BQ5R_LtiSri/exec'
//const url = 'https://script.google.com/macros/s/AKfycbwCJzMwPnGw80RkmKNHZcZfCkyEjm_42sJjNN5qxuQhHdocNd-GflKrX0rKCEt7CfIQ2A/exec'
//const url = 'https://script.google.com/macros/s/AKfycbz_uCZ0nUHtMBSMDjE1YDVkr10If1kD6L6I5uC5VP-GX_r0NxcCoDD5MgIfAR-RjguUbw/exec' // 2.4
//const url = 'https://script.google.com/macros/s/AKfycbwJmmcktQ99Pir0vmVFw0WagEKd7SrSSOhym0d9IMWPVmGJCkid-JqXYn7_jVC8kREJ/exec' // 2.5
//const url = 'https://script.google.com/macros/s/AKfycbxnzx_ieM_WHdZ6BM9Tz51Z_1boDVmwqxLTBibbwRQbnViDGSJ9oCGDB7Wx-nuqGqXd/exec' //2.6
//const url = 'https://script.google.com/macros/s/AKfycbyChjC82uHa6VSGlaXaPblC3gW86UvhX3EfikNjWLRBoK-bfGHKE4gydajk3dqwlPXUKg/exec' //2.7
//const url ='https://script.google.com/macros/s/AKfycbw-JhItvfmem1-w5VY9f8v1_DabdkPGj4h43v7e3iwabOsvng6hBQIJ7VSdkHT_gHkv/exec' // 2.8
//const url = 'https://script.google.com/macros/s/AKfycbwlaAAgT2qCmt0iqfD6QXdt-LYr-2o8dPyU8qBwmtWNnHDCSyzKAn_aRpNMtvsdPasR5Q/exec' //2.10
//const sheetName = 'Home Loan - MDIR' //2.4
const url = 'https://script.google.com/macros/s/AKfycbwqQn52_a_5_ue1xdGirgKOu33NTiaLnOT4gfJpghg_tB-D6GoipdXETsM2prfP-fuw/exec' //3.1

const sheetName = 'Home Loan Template' // 2.5
//const spreadsheetId = '1Fb_oBXtgsrZiPzWQL9Bmz980ZO_H5MwhbUH4LMeP9Xs'
//const spreadsheetId = '1cDxljE9Dh9o_4WGkRLxjHflb6dGqNHiSjfZPKCoFo9I'
//const spreadsheetId = '1vaT6zFqvaZ0XaMmrlQpjgg81bK4A0-H2ylgWkZhNqwU' // 2.4
//const spreadsheetId = '1qYzizkEvg4Y7Da8T5LXZxnqKj-hbCmJ26eMeXTIEl5c' // 2.5
//const spreadsheetId = '1TJZDvBfdpVgs8hVscYqpC0hF1EqrRKW_JhKSYVASEi0' //2.6
//const spreadsheetId = '1-G3nvd641ljIfpnGK8wObTIQKowhA0yEkclUdT4rKNU' //2.7
//const spreadsheetId = '1VYTdzOAtBOp4Ac66xZpexhhEHhecwv_57_2Cinvmhcc' //2.8

//const spreadsheetId = '1J03jNS2TaNdLKZIjgxNh9HldGuFVLmWuxmuXVzo4pvw' //2.10
const spreadsheetId = '1foEeKp8_R_3nRFz95cTWiU49XSNisJEWZqLRqprH2do' //3.1

export const ptsbCoverNoteCoords = {
    overtime1: 'B57',
    bonus1: 'B59',
    commission1: 'B61',
    other1: 'B63',
    allowances1: 'B63',
    overtime2: 'I57',
    bonus2: 'I59',
    commission2: 'I61',
    other2: 'I63',
    allowances2: 'I63',
    basic1: 'B55',
    basic2: 'I55',
    praSavings: 'E42',
    praRent: 'F42',
    praMortgage: 'G42',
    praClearingLoans: 'H42',
    praOther: 'I42',
    propertyValue:'E32',
    loanRequired: 'E28',
    loanTerm: 'E30',
    childminding: 'K9'
}

const newData = {
    twoApplicants: true,
    numberInHousehold:'',
    numberOfAdultsBoarding: 0,
    numberOfDependentChildren:1,
    dependents:[{age: 13}],
    applicant1: {
        works: true,

        maritalStatus: 'MARRIED',
        age: 35,
        jobs:[
            {
                sector: 'PUBLIC',
                lastP60: 0,
                yearlyAVCContribution: 0,
                yearlyPensionContribution:0,
                basic:{
                        yearMostRecent: 0,
                        yearBefore: 0,
                        yearBeforeThat: 0,
                        guaranteed: true,
                },
                allowances:{
                    yearMostRecent:0,
                    yearBefore: 1000,
                    yearBeforeThat: 1000,
                    guaranteed:true,
                },
                overtime:{
                    yearMostRecent:0,
                    yearBefore: 1000,
                    yearBeforeThat: 1000,
                    guaranteed:true,
                },
                bonus:{
                    yearMostRecent:0,
                    yearBefore: 1000,
                    yearBeforeThat: 1000,
                    guaranteed:true,
                },
                commission:{
                    yearMostRecent:0,
                    yearBefore: 1000,
                    yearBeforeThat: 1000,
                    guaranteed:false,
                },
                other:{
                    yearMostRecent:0,
                    yearBefore: 1000,
                    yearBeforeThat: 1000,
                    guaranteed:true,
                },
            }
        ],
        pra:{
            rent: 0,
            mortgage: 0,
            savings:0,
            clearingLoans: 0,
            other: 0,
        },
        monthlyOutgoings:{
            loans: 0,
            maintenance: 0,
            childcare: 0,
        },
        creditFacilityLimits:{
            creditCards: 0,
            storeCards: 0,
            overdrafts: 0
        },
        portfolio:[
            {
                isMortgaged: true,
                outstandingMortgage: 0,
                rate: 0,
                rateFixedYears: 5,
                tracker: false,
                interestOnly: false,
                remainingTermInMonths: 0,
                yearlyRentalIncome: 0,
                propertyValue: 0,
                foreign: false,
                nonStandard: false,
                numberOfBedrooms: 3,
                annualStressedMortgagePayment: 0,
            }
        ],
    },

    existing:{ // Movers
        nonTrackerMortgageAmount:0,
        trackerMortgageAmount:0,
        tracker: false,
        propertyValue:0,
        outstandingMortgage:0,
        remainingTermInMonths:0,
        retaining: false,
        rate: 0,
        rateFixedYears: 5,
        numberOfBedrooms: 3,
    },
    //selfBuild: false,
    selfBuild:{
        costToBuild: 0,
        siteValue   :0,

    },
    deposit: 0,
    mortgageType: 'REFINANCE',
    loanRequired: 0,
    termRequired: 0,
    propertyValue: 0,
    propertyType: 'FLAT',
    applicationType: 'MOVINGHOME',
    fixedRateYears: 3,
    berScore: 'A1',
    propertyIdentified: true,
    oneBedProperty: false,
}

function ptsb({mortgage}) {
    const titles = [
        'Mr.',
        'Mrs.',
        'Ms.',
        'Dr.',
    ]
    const taxStatuses = ['Single', 'Married X 1 Income', 'Married X 2 Incomes']
    const childrenAges = []
    if (mortgage.applicant1.personal.countDependents > 0) {
        (mortgage.applicant1.personal.dependentDOBs||[]).forEach(dob => {
            const today = dayjs();
            const birthDate = dayjs(dob);
            const yearsDiff = today.diff(birthDate, 'year');
            childrenAges.push(yearsDiff)
        })
    }
    if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
        (mortgage.applicant2.personal.dependentDOBs||[]).forEach(dob => {
            const today = dayjs();
            const birthDate = dayjs(dob);
            const yearsDiff = today.diff(birthDate, 'year');
            childrenAges.push(yearsDiff)
        })
    }
    // DEBTS
    let myDebts = []
    let filter = debt => debt.debtType === DebtTypes.LOAN && !debt.clearedBeforeMortgage
    mortgage.applicant1.debts.filter(filter).forEach(debt => {
        myDebts.push({name: debt.lender, payment: debt.monthlyPayment, id: debt.id})
    })
    if (mortgage.twoApplicants) {
        mortgage.applicant2.debts.filter(filter).forEach(debt => {
            myDebts.push({name: debt.lender, payment: debt.monthlyPayment, id: debt.id})
        })
    }
    if (myDebts.length > 2) {
        myDebts = [
            {
                name: joinWithConjunction(myDebts.map(obj => obj.name), 'and') + ' Loans',
                payment: myDebts.reduce((total, obj) => total + obj.monthlyPayment, 0)
            }
        ]
    }
    // REVOLVING CREDIT
    const myCredit = []
    let legacyEmployment = !mortgage[`applicant1`].employmentIncome?.length || !mortgage[`applicant1`].employmentIncome[0].employmentType

    filter = debt => [DebtTypes.CREDITCARD, DebtTypes.STORECARD, DebtTypes.OVERDRAFT].includes(debt.debtType) && !debt.isClosed
    mortgage.applicant1.debts.filter(filter).forEach(debt => {
        myCredit.push({name: debt.lender, limit: debt.creditLimit})
    })
    if (mortgage.twoApplicants) {
        mortgage.applicant2.debts.filter(filter).forEach(debt => {
            myCredit.push({name: debt.lender, limit: debt.creditLimit})
        })
    }

    const getLiveDebts = (applicant, formData)=> {
        let prop = `clearingLoans${applicant}`
        let debts = mortgage[`applicant${applicant}`].debts.filter(debt => debt.debtType === DebtTypes.LOAN).filter(debt=>{
            return !formData[prop].includes(debt.id)
        }).map(debt=>({name: debt.lender, payment: debt.monthlyPayment, id: debt.id}))
        return debts
    }
    const getMortgageDebts = (formData)=>{
        let debts = getLiveDebts(1, formData)
        if (mortgage.twoApplicants){
            debts = [...debts, ...getLiveDebts(2, formData)]
        }
        if (debts.length > 2) {
            debts = [
                {
                    name: joinWithConjunction(debts.map(obj => obj.name), 'and') + ' Loans',
                    payment: debts.reduce((total, obj) => total + obj.monthlyPayment, 0)
                }
            ]
        }
        return debts
    }
    const mortgageTypeOptions = [
        'Property Purchase',
        'Straight Switcher',
        'Switcher with Equity Release',
        'Equity Release - Further Advance',
        'Equity Release - Top Up',
        'Equity Release - Unencumbered Property',
        'Mortgage Mover'
    ]
    const dataPoints = {
        "Applicant 1": [
            dataPoint(
                'Title',
                'B5',
                {
                    type: 'select',
                    options: titles,
                    rules: [
                        {required: true, message: 'Please select a title'},
                    ]
                },
                () => {
                    switch (mortgage.applicant1.personal.title) {
                        case "MR":
                            return 'Mr.'
                        case "MRS":
                            return 'Mrs.'
                        case "MISS":
                            return 'Ms.'
                        case "DR":
                            return 'Dr.'
                    }
                },
            ),
            dataPoint(
                'Name',
                'C5:F5',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter a name'},
                    ]
                },
                () => {
                    return mortgage.applicant1.fullName
                },
            ),
            dataPoint(
                'Age',
                'C7:F7',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    if (!mortgage.applicant1.personal.dateOfBirth) return ''
                    const today = dayjs();
                    const birthDate = dayjs(mortgage.applicant1.personal.dateOfBirth);
                    const yearsDiff = today.diff(birthDate, 'year');
                    return yearsDiff
                },
            ),
            dataPoint(
                'Tax Status',
                'B51:C51',
                {
                    type: 'select',
                    options: taxStatuses,
                    rules: [
                        {required: true, message: 'Please select a tax status'},
                    ]
                },
                () => {
                    // if not married

                    if ( [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus) ) {
                        //married to the other person
                        if (mortgage.twoApplicants && mortgage.requirement.cohabiting && [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)){
                            let app2employment = legacyEmployment ? mortgage.applicant2.employment : mortgage.applicant2.employmentIncome[0]
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(app2employment.employmentType)) {
                                return 'Married X 2 Incomes'
                            }
                            return 'Married X 1 Income'
                        }

                    }
                    return 'Single'
                },
                (form)=>{
                    if ( [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus) ) {
                        //married to the other person
                        if (mortgage.twoApplicants && mortgage.requirement.cohabiting && [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)){
                            let app2employment = legacyEmployment ? mortgage.applicant2.employment : mortgage.applicant2.employmentIncome[0]
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(app2employment.employmentType)) {
                                return 'Married X 2 Incomes'
                            }
                            return 'Married X 1 Income'
                        }

                    }
                    return 'Single'
                }
            ),
            dataPoint(
                'Basic',
                'B55',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    return mortgage.applicant1.income.grossBasic
                },
                (formData) => {
                    return formData.basic1
                },
                (newFormData)=>{
                    return newFormData.basic1
                }

            ),
            dataPoint(
                'Overtime',
                'B57',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.applicant1.income.incomeChecks) return ''
                    return mortgage.applicant1.income.incomeChecks.includes('OVERTIME') ? mortgage.applicant1.income.overtime : ''
                },
                (formData) => {
                    return formData.overtime1
                },
                (newFormData)=>{
                    return newFormData.overtime1
                }
            ),
            dataPoint(
                'Bonus',
                'B59',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.applicant1.income.incomeChecks) return ''
                    return mortgage.applicant1.income.incomeChecks.includes('BONUS') ? mortgage.applicant1.income.bonus : ''
                },
                (formData) => {
                    return formData.bonus1
                },
                (newFormData)=>{
                    return newFormData.year1Bonus1
                }
            ),
            dataPoint(
                'Commission',
                'B61',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.applicant1.income.incomeChecks) return ''
                    return mortgage.applicant1.income.incomeChecks.includes('COMMISSION') ? mortgage.applicant1.income.commission : ''
                },
                (formData) => {
                    return formData.commission1
                },
                (newFormData)=>{
                    return newFormData.commission1
                }
            ),
            dataPoint(
                'Other',
                'B63',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    let sum = 0
                    if (mortgage.applicant1.income?.incomeChecks?.includes('OTHER')) {
                        sum += mortgage.applicant1.income.other
                    }
                    if (mortgage.applicant1.income?.incomeChecks?.includes('ALLOWANCES')) {
                        sum += mortgage.applicant1.income.allowances
                    }
                    return sum
                },
                (formData) => {
                    let sum = 0
                    if (formData.other1) {
                        sum += formData.other1
                    }
                    if (formData.allowances1) {
                        sum += formData.allowances1
                    }
                    return sum
                },
                (newFormData)=>{
                    let sum = 0
                    if (newFormData.other1) {
                        sum += newFormData.other1
                    }
                    if (newFormData.allowances1) {
                        sum += newFormData.allowances1
                    }
                    return sum
                }
            ),
        ],
        "Applicant 2": [
            dataPoint(
                'Title',
                'G5',
                {
                    type: 'select',
                    options: titles,
                    rules: [
                        {required: true, message: 'Please select a title'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    switch (mortgage.applicant2.personal.title) {
                        case "MR":
                            return 'Mr.'
                        case "MRS":
                            return 'Mrs.'
                        case "MISS":
                            return 'Ms.'
                        case "DR":
                            return 'Dr.'
                    }
                },
            ),
            dataPoint(
                'Name',
                'H5:I5',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter a name'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    return mortgage.applicant2.fullName
                },
            ),
            dataPoint(
                'Age',
                'H7:I7',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    if (!mortgage.applicant2.personal.dateOfBirth) return ''
                    const today = dayjs();
                    const birthDate = dayjs(mortgage.applicant2.personal.dateOfBirth);
                    const yearsDiff = today.diff(birthDate, 'year');
                    return yearsDiff
                },
            ),
            dataPoint(
                'Tax Status',
                'I51:J51',
                {
                    type: 'select',
                    options: taxStatuses,
                    rules: [
                        {required: true, message: 'Please select a tax status'},
                    ]
                },
                () => {


                    if ( [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus) ) {
                        //married to the other person
                        if (mortgage.twoApplicants && mortgage.requirement.cohabiting && [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus)){
                            let app1employment = legacyEmployment ? mortgage.applicant1.employment : mortgage.applicant1.employmentIncome[0]
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(app1employment.employmentType)) {
                                return 'Married X 2 Incomes'
                            }
                            return 'Married X 1 Income'
                        }

                    }
                    return 'Single'
                },
                (form)=>{

                    if ( [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus) ) {
                        //married to the other person
                        if (mortgage.twoApplicants && mortgage.requirement.cohabiting && [PersonalMaritalStatuses.MARRIED,PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant1.personal.maritalStatus)){
                            let app1employment = legacyEmployment ? mortgage.applicant1.employment : mortgage.applicant1.employmentIncome[0]
                            if ([EmploymentTypes.EMPLOYED, EmploymentTypes.SELFEMPLOYED].includes(app1employment.employmentType)) {
                                return 'Married X 2 Incomes'
                            }
                            return 'Married X 1 Income'
                        }

                    }
                    return 'Single'
                }
            ),
            dataPoint(
                'Basic',
                'I55',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    return mortgage.applicant2.income.grossBasic
                },
                (formData) => {
                    return formData.basic2
                },
                (newFormData)=>{
                    return newFormData.basic2
                }
            ),
            dataPoint(
                'Overtime',
                'I57',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    if (!mortgage.applicant2.income.incomeChecks) return ''
                    return mortgage.applicant2.income.incomeChecks.includes('OVERTIME') ? mortgage.applicant2.income.overtime : ''
                },
                (formData) => {
                    return formData.overtime2
                },
                (newFormData)=>{
                    return newFormData.overtime2
                }
            ),
            dataPoint(
                'Bonus',
                'I59',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    if (!mortgage.applicant2.income.incomeChecks) return ''
                    return mortgage.applicant2.income.incomeChecks.includes('BONUS') ? mortgage.applicant2.income.bonus : ''
                },
                (formData) => {
                    return formData.bonus2
                },
                (newFormData)=>{
                    return newFormData.year1Bonus2
                }
            ),
            dataPoint(
                'Commission',
                'I61',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    if (!mortgage.twoApplicants) return ''
                    if (!mortgage.applicant2.income.incomeChecks) return ''
                    return mortgage.applicant2.income.incomeChecks.includes('COMMISSION') ? mortgage.applicant2.income.commission : ''
                },
                (formData) => {
                    return formData.commission2
                },
                (newFormData)=>{
                    return newFormData.commission2
                }
            ),
            dataPoint(
                'Other',
                'I63',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    let sum = 0
                    if (mortgage.applicant2.income?.incomeChecks?.includes('OTHER')) {
                        sum += mortgage.applicant2.income.other
                    }
                    if (mortgage.applicant2.income?.incomeChecks?.includes('ALLOWANCES')) {
                        sum += mortgage.applicant2.income.allowances
                    }
                    return sum
                },
                (formData) => {
                    let sum = 0
                    if (formData.other2) {
                        sum += formData.other2
                    }
                    if (formData.allowances2) {
                        sum += formData.allowances2
                    }
                    return sum
                },
                (newFormData)=>{
                    let sum = 0
                    if (newFormData.other2) {
                        sum += newFormData.other2
                    }
                    if (newFormData.allowances2) {
                        sum += newFormData.allowances2
                    }
                    return sum
                }
            ),
        ],
        "Children": [
            dataPoint(
                'Dependant 1 Age',
                'C9:D9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    return childrenAges[0] || ''
                },
            ),
            dataPoint(
                'Dependant 2 Age',
                'E9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    return childrenAges[1] || ''
                },
            ),
            dataPoint(
                'Dependant 3 Age',
                'F9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    return childrenAges[2] || ''
                },
            ),
            dataPoint(
                'Dependant 4 Age',
                'G9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    return childrenAges[3] || ''
                },
            ),
            dataPoint(
                'Dependant 5 Age',
                'H9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an age'},
                    ]
                },
                () => {
                    return childrenAges[4] || ''
                },
            ),
            dataPoint(
                'Number In Household',
                'K7',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter a number'},
                    ]
                },
                () => {
                    let count = 1
                    if (mortgage.twoApplicants) {
                        count++
                    }
                    if (mortgage.properties[0].adultsBoarding) {
                        count += mortgage.properties[0].adultsBoardingCount
                    }
                    count += childrenAges.length
                    return count
                },
            ),
            dataPoint(
                'Childminding Costs',
                'K9',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    let cost = 0
                    if (mortgage.applicant1.personal.countDependents > 0) {
                        cost += mortgage.applicant1.financial.monthlyChildcareCosts
                    }
                    if (mortgage.twoApplicants && mortgage.applicant2.personal.countDependents > 0) {
                        cost += mortgage.applicant2.financial.monthlyChildcareCosts
                    }
                    return cost > 0 ? cost : ''
                },
            ),
        ],
        "Loan": [
            dataPoint('Mortgage Type', 'E13:G13', {
                type: 'select',
                options: mortgageTypeOptions,
            }, () => {
                return mortgage.mortgageType==='REFINANCE' ? 'Straight Switcher' : 'Property Purchase'
            }),
            dataPoint(
                'Rate Type',
                'H32',
                {
                    type: 'select',
                    options: ['Variable', '2 Year Fixed', '3 Year Fixed', '4 Year Fixed', '5 Year Fixed', '7 Year Fixed', 'Staff Rate'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return '3 Year Fixed'
                },
            ),
            dataPoint(
                'Green Mortgage',
                'E36',
                {
                    type: 'select',
                    options: ['Yes', 'No'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return 'No'
                },
            ),
            dataPoint(
                'BER',
                'H36',
                {
                    type: 'select',
                    options: ['A1', 'A2', 'A3', 'B1', 'B2', 'B3', 'C1', 'C2', 'C3', 'D1', 'D2', 'E1', 'E2', 'F', 'G'],
                    rules: [
                        {required: true, message: 'Please enter a BER'},
                    ]
                },
                () => {
                    return mortgage.properties[0].berScore || ''
                },
            ),
            dataPoint(
                'Application Type',
                'B47:C47',
                {
                    type: 'select',
                    options: ['Single', 'Joint'],
                    rules: [
                        {required: true, message: 'Please select an application type'},
                    ]
                },
                () => {
                    return mortgage.twoApplicants ? 'Joint' : 'Single'
                },
            ),
            dataPoint(
                'Mortgage Required',
                'E28',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    return mortgage.requirement.loanRequired
                },
                (formData)=>{
                    return formData.mortgageAmount
                },
                (newFormData)=>{
                    return newFormData.provisionalLoan
                }
            ),
            dataPoint(
                'Loan Term',
                'E30',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter a term'},
                    ]
                },
                () => {
                    return mortgage.requirement.loanTerm
                },
                (formData)=>{
                    return formData.term
                },
                (newFormData)=>{
                    return newFormData.provisionalTerm
                }
            ),
            dataPoint(
                'Property Value',
                'E32',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    return mortgage.properties[0].propertyValue
                },
                (formData)=>{
                    return formData.propertyValue
                },
                (newFormData)=>{
                    return newFormData.provisionalValue
                }
            ),
            dataPoint(
                'Property Type',
                'E34',
                {
                    type: 'select',
                    options: ['House', 'Apartment'],
                    rules: [
                        {required: true, message: 'Please select a property type'},
                    ]
                },
                () => {
                    return mortgage.properties[0].propertyType && !['APARTMENT', 'FLAT'].includes(mortgage.properties[0].propertyType) ? 'House' : 'Apartment'
                },
            ),
            dataPoint(
                'Purchaser Type',
                'H30',
                {
                    type: 'select',
                    options: ['First Time Buyer', 'Second Time Buyer'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return mortgage.applicationType === ApplicationTypes.FIRSTTIME ? 'First Time Buyer' : 'Second Time Buyer'
                },
            ),

            dataPoint(
                'Self Build',
                'H34',
                {
                    type: 'select',
                    options: ['Yes', 'No'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return mortgage.properties[0].buildType === BuildType.SELFBUILD ? 'Yes' : 'No'
                },
            ),
            dataPoint(
                'Self Build Copy?',
                'K13',
                {
                    type: 'select',
                    options: ['Yes', 'No'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return mortgage.properties[0].buildType === BuildType.SELFBUILD ? 'Yes' : 'No'
                },
            ),
            dataPoint(
                'Straight Switcher',
                'H36',
                {
                    type: 'select',
                    options: ['Yes', 'No'],
                    rules: [
                        {required: true, message: 'Please select an option'},
                    ]
                },
                () => {
                    return mortgage.mortgageType === MortgageTypes.REFINANCE ? 'Yes' : 'No'
                },
            ),
            // dataPoint(
            //     'Equity Release Balance',
            //     'K28',
            //     {
            //         type: 'text',
            //         rules: [
            //             {required: true, message: 'Please enter an amount'},
            //         ]
            //     },
            //     () => {
            //         return ''
            //     },
            // ),

        ],
        "Repayment Ability": [
            dataPoint(
                'Savings',
                'E42',
                {
                    type: 'number',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    let savings = 0
                    let filter = asset => asset.assetType === AssetTypes.SAVING && [Frequencies.MONTHLY, Frequencies.WEEKLY].includes(asset.increasesFrequency)
                    mortgage.applicant1.assets.filter(filter).forEach(asset => {
                        if (asset.increasesFrequency === Frequencies.MONTHLY) {
                            savings += asset.increases
                        } else {
                            savings += (asset.increases * 52 / 12)
                        }
                    })
                    if (mortgage.twoApplicants) {
                        mortgage.applicant2.assets.filter(filter).forEach(asset => {
                            if (asset.increasesFrequency === Frequencies.MONTHLY) {
                                savings += asset.increases
                            } else {
                                savings += (asset.increases * 52 / 12)
                            }
                        })
                    }
                    return savings > 0 ? savings : ''
                },
                (formData)=>{
                    let savings = formData.savings1
                    if (mortgage.twoApplicants){
                        savings += formData.savings2
                    }
                    return savings
                },
                (newFormData)=>{
                    let savings = newFormData.savings1
                    if (mortgage.twoApplicants){
                        savings += newFormData.savings2
                    }
                    return savings
                }
            ),
            dataPoint(
                'Rent',
                'F42',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    let rent = 0
                    if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                        rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                    }
                    if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                        rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                    }

                    if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant1.financial.contributeToRelatives) {
                        rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                    }
                    if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant2.financial.contributeToRelatives) {
                        rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                    }
                    return rent > 0 ? rent : ''
                },
                (formData)=>{
                    let rent = 0
                    if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                        rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                    }
                    if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
                        rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                    }

                    if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant1.financial.contributeToRelatives) {
                        rent += mortgage.applicant1.financial.monthlyAccommodationCosts
                    }
                    if (mortgage.twoApplicants && mortgage.requirement.cohabiting === false && mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && !!mortgage.applicant2.financial.contributeToRelatives) {
                        rent += mortgage.applicant2.financial.monthlyAccommodationCosts
                    }
                    return rent > 0 ? rent : ''
                },
                (newFormData)=>{
                    let rent = newFormData.accommodationCosts1
                    if (mortgage.twoApplicants){
                        rent += newFormData.accommodationCosts2
                    }
                    return rent
                }
            ),
            dataPoint(
                'Existing Mortgage',
                'G42',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    return 0
                },
            ),
            dataPoint(
                'Loan To Clear',
                'H42',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    const filter = debt => debt.debtType === DebtTypes.LOAN && !!debt.clearedBeforeMortgage
                    let tot = 0
                    mortgage.applicant1.debts.filter(filter).forEach(it => {
                        tot += it.monthlyPayment
                    })

                    if (mortgage.twoApplicants) {
                        mortgage.applicant2.debts.filter(filter).forEach(it => {
                            tot += it.monthlyPayment
                        })
                    }

                    return tot
                },
                (formData)=>{
                    let sum = 0
                    mortgage.applicant1.debts.filter(debt => formData.clearingLoans1.includes(debt.id)).forEach(it => {
                        sum += it.monthlyPayment
                    })
                    if (mortgage.twoApplicants){
                        mortgage.applicant2.debts.filter(debt => formData.clearingLoans2.includes(debt.id)).forEach(it => {
                            sum += it.monthlyPayment
                        })
                    }
                    return sum
                },
                (newFormData)=>{
                    let sum = newFormData.clearingLoans1
                    if (mortgage.twoApplicants){
                        sum += newFormData.clearingLoans2
                    }
                    return sum
                }
            ),
            dataPoint(
                'Other',
                'I42',
                {
                    type: 'text',
                    rules: [
                        {required: true, message: 'Please enter an amount'},
                    ]
                },
                () => {
                    return 0
                },
                null,
                (newFormData)=>{
                    let sum = newFormData.otherPRA1
                    if (mortgage.twoApplicants){
                        sum += newFormData.otherPRA2
                    }
                    return sum
                }
            ),
        ],
        "Debt Servicing": {
            "Loan 1": [
                dataPoint(
                    'Details',
                    'E85:H85',
                    {
                        type: 'text',
                        rules: [
                            {required: true, message: 'Please enter some details'},
                        ]
                    },
                    () => {
                        return myDebts.length > 0 ? myDebts[0].name : ''
                    },
                    (formData)=>{
                        const debts = getMortgageDebts(formData)
                        return debts.length > 0 ? debts[0].name : ''
                    },
                    (newFormData)=>{
                        return 'App 1 Loans'
                    }
                ),
                dataPoint(
                    'Amount Monthly',
                    'I85',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return myDebts.length > 0 ? myDebts[0].payment : 0
                    },
                    (formData)=>{
                        const debts = getMortgageDebts(formData)
                        return debts.length > 0 ? debts[0].payment : 0
                    },
                    (newFormData)=>{
                        return newFormData.loans1
                    }
                ),
            ],
            "Loan 2": [
                dataPoint(
                    'Details',
                    'E87:H87',
                    {
                        type: 'text',
                        rules: [
                            {required: true, message: 'Please enter some details'},
                        ]
                    },
                    () => {
                        return myDebts.length > 1 ? myDebts[1].name : ''
                    },
                    (formData)=>{
                        const debts = getMortgageDebts(formData)
                        return debts.length > 1 ? debts[1].name : ''
                    },
                    (newFormData)=>{
                        return 'App 2 Loans'
                    }
                ),
                dataPoint(
                    'Amount Monthly',
                    'I87',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return myDebts.length > 1 ? myDebts[1].payment : 0
                    },
                    (formData)=>{
                        const debts = getMortgageDebts(formData)
                        return debts.length > 1 ? debts[1].payment : 0
                    },
                    (newFormData)=>{
                        return newFormData.loans2
                    }

                ),
            ],
            "Maintenance": [
                dataPoint(
                    'Maintenance Monthly',
                    'I89',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        let maint = 0
                        if (mortgage.applicant1.financial.paysMaintenance) {
                            maint += mortgage.applicant1.financial.maintenanceCosts
                        }
                        if (mortgage.applicant2.financial.paysMaintenance) {
                            maint += mortgage.applicant2.financial.maintenanceCosts
                        }
                        return maint > 0 ? maint : ''
                    },
                )
            ],
            "Revolving 1": [
                dataPoint(
                    'Details',
                    'E92:H92',
                    {
                        type: 'text',
                        rules: [
                            {required: true, message: 'Please enter some details'},
                        ]
                    },
                    () => {
                        return myCredit.length > 0 ? myCredit[0].name : ''
                    },
                ),
                dataPoint(
                    'Credit Limit',
                    'I92',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return myCredit.length > 0 ? myCredit[0].limit : 0
                    },
                ),
            ],
            "Revolving 2": [
                dataPoint(
                    'Details',
                    'E94:H94',
                    {
                        type: 'text',
                        rules: [
                            {required: true, message: 'Please enter some details'},
                        ]
                    },
                    () => {
                        return myCredit.length > 1 ? myCredit[1].name : ''
                    },
                ),
                dataPoint(
                    'Credit Limit',
                    'I94',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return myCredit.length > 1 ? myCredit[1].limit : 0
                    },
                ),
            ],
            "Revolving 3": [
                dataPoint(
                    'Details',
                    'E96:H96',
                    {
                        type: 'text',
                        rules: [
                            {required: true, message: 'Please enter some details'},
                        ]
                    },
                    () => {
                        return myCredit.length > 2 ? myCredit[2].name : ''
                    },
                ),
                dataPoint(
                    'Credit Limit',
                    'I96',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return myCredit.length > 2 ? myCredit[2].limit : 0
                    },
                ),
            ],
            "Home Loan 1": [
                dataPoint(
                    'Balance',
                    'G100',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Remaining Term',
                    'H100',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a term'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Rate',
                    'I100',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a rate'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
            ],
            "Home Loan 2": [
                dataPoint(
                    'Balance',
                    'G101',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Remaining Term',
                    'H101',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a term'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Rate',
                    'I101',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a rate'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
            ],
            "Home Loan 3": [
                dataPoint(
                    'Balance',
                    'G102',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Remaining Term',
                    'H102',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a term'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Rate',
                    'I102',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a rate'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
            ],
            "Home Loan 4": [
                dataPoint(
                    'Balance',
                    'G103',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter an amount'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Remaining Term',
                    'H103',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a term'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
                dataPoint(
                    'Rate',
                    'I103',
                    {
                        type: 'number',
                        rules: [
                            {required: true, message: 'Please enter a rate'},
                        ]
                    },
                    () => {
                        return ''
                    },
                ),
            ],
        }

    }
    const dataToReceive = {
        'Term Sought': 'E30',
        'Max Term Allowed': 'PDH - Affordability Calcs|B312',
        'Mortgage Payment': 'E39',
        'Stressed Mortgage Payment': 'H39',
        'Interest Rate': 'K32',
        'Proposed LTV': 'K30',
        'Proven Repayment Ability': 'C42',
        'Total Net Monthly Income': 'K75',
        'Cash For Living': 'I147',
        'Max Amount Available For Loans': 'K78',
        'Max Nets': 'E141',
        'Actual Nets': 'G141',
        'LTV Breach': 'I31',
        'Loan To Income': 'E137',
        'MDI Requirement': 'E149',
        'LTI Message': 'G137'

        //passNets: actualNets <= mexNets

    }
    return {
        name: 'ptsb',
        dataPoints,
        get: (values, shouldSaveOutput) => {
            const blankedData = getNullCells(dataPoints)

            const setData = {...blankedData, ...values}

            return get(url, spreadsheetId, sheetName, setData, dataToReceive, mortgage, shouldSaveOutput)
        }
    }
}
export default ptsb;