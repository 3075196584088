import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {API, Hub} from "aws-amplify";
import {Button, Modal} from "antd";
import {filterObjectForNull} from "../../assets/scripts/functions";
useSubscription.propTypes = {
    subscriptions: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
};

function useSubscription({subscription, setState, filterForNullExceptions}) {
    const [myState, setMyState] = useState({subscriptionError: false})
    useEffect(() => {
        const errorHandler = (error) => {
            console.warn(error);
            setMyState(s => ({...s, subscriptionError: error.message}))
        };
        const unsubscribes = []
        if (subscription.hasOwnProperty('onUpdate')) {
            const updateSub = API.graphql({query: subscription.onUpdate}).subscribe({
                next: ({provider, value}) => {
                    if (value.hasOwnProperty('errors')){
                        console.log({error:value.errors})
                    }
                    const item = value.data[`onUpdate${subscription.name}`]
                    if (item) {
                        const cleanItem = filterObjectForNull(item,filterForNullExceptions)
                        setState(s => {
                            if (s.find(it => it.id === item.id)) {
                                return s.map(it => {
                                    if (it.id === item.id) {
                                        return {
                                            ...it,
                                            ...cleanItem
                                        }
                                    }
                                    return it
                                })
                            } else {
                                return [...s, item]
                            }
                        })
                    }
                },
                error: errorHandler
            });
            unsubscribes.push(updateSub.unsubscribe)
        }
        if (subscription.hasOwnProperty('onCreate')) {
            // Subscribe to creation
            const createSub = API.graphql({query: subscription.onCreate}).subscribe({
                next: ({provider, value}) => {
                    const newData = value.data[`onCreate${subscription.name}`]

                    setState(s => ([
                        ...s,
                        newData
                    ]))
                },
                error: errorHandler
            });
            unsubscribes.push(createSub.unsubscribe)
        }
        if (subscription.hasOwnProperty('onDelete')) {
            // Subscribe to deletion
            const deleteSub = API.graphql({query: subscription.onDelete}).subscribe({
                next: ({provider, value}) => {

                    const id = value.data[`onDelete${subscription.name}`]?.id
                    if (id){
                        setState(s => {
                            return [...s].filter(it => it.id !== id)
                        })
                    }
                    else{
                        console.log('Delete Subscription Failed!!')
                        console.log({DELETED_VALUE_FROM_SUB:value})
                    }
                },
                error: errorHandler
            });
            unsubscribes.push(deleteSub.unsubscribe)
        }
        return () => {


            unsubscribes.forEach(unsubscribe => {
                try{
                    unsubscribe()
                }
                catch (e) {}
            })
        }
    }, [])

    return (
        <Modal footer={null} title="Subscription Error" open={!!myState.subscriptionError} closable={false}>
            <p><small>{myState.subscriptionError}</small></p>
            <p>Please reload the page and try again</p>
            <Button onClick={() => window.location.reload()}>Reload</Button>
        </Modal>
    )

}

export default useSubscription;