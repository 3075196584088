import React from 'react';

import UploadsForApply from "../../components/client/application-stages/apply/parts/UploadsForApply";
import {useParams} from "react-router-dom";

function ApplicationUploadPage(props) {
    const params = useParams()
    return (
            <UploadsForApply />
    );
}

export default ApplicationUploadPage;