import {useContext} from 'react';
import {ToolDrawer} from "../../../app/providers/mortgage/ToolDrawerProvider";
import useMortgage from "../../../app/providers/mortgage/useMortgage";
import useApplicationUploads, {applicationUploadsSections} from "../../../app/providers/mortgage/useApplicationUploads";
import {ApplicationStages, UploadStatuses} from "../../../models";
import {capitaliseWords} from "../../../assets/scripts/string-functions";
import dayjs from "dayjs";

export const currentApplyOutstandingItemsEmailBody = (mortgage, firstHTMLParagraph = '') => {
    const {apply: applySections} = applicationUploadsSections(mortgage)
    return getStageBody(mortgage, applySections, firstHTMLParagraph)
}

const getStageBody = (mortgage, sections, firstHTMLParagraph) => {

    const outstandingItems = sections.filter(section => {

        if (!section.record) {
            console.log('No record for section', section)
            return true
        }
        let noUploads = section.uploads.filter(upload => ![
            UploadStatuses.REJECTED,
            UploadStatuses.EXPIRED
        ].includes(upload.status)).length === 0
        if (section.title.includes('optional') && noUploads) {
            return false
        }
        if (!!section.record.sectionChecked) {
            return false
        }
        let missingUploads = !!section.record.alertBody && section.record.alertBody.length > 0
        if (section.verified && section.verified.isVerifiable) {
            let errors = section.verified.getErrors()
            console.log({
                errors,
                title: section.title
            })
            if (errors.length > 0) {
                if (errors.some(it => it.includes('already in app'))) {
                    return true
                }
            }
        }
        return noUploads || missingUploads;
    })
    let today = dayjs()

    const outstandingList = outstandingItems.map(section => {
        if (section.applicant === 1) {
            if (!!section.record && !!section.record.alertBody && section.record.alertBody.length > 0) {
                console.log('a')
                return `${mortgage.applicant1.firstName}'s ${capitaliseWords(section.title)} <ul><li><b>NOTE:</b> ${(section.record.alertBody || []).join(' ')}</li></ul>`
            }
            if (!!section?.verified?.isVerifiable) {
                if (section?.record?.lastDocumentDate) {
                    let [maxAge, units] = section.verified.minimums.maxAge
                    let lastDocDate = dayjs(section.record.lastDocumentDate, 'YYYY-MM-DD')
                    if (today.diff(lastDocDate, units, true) > maxAge) {
                        console.log({section})
                        return `${mortgage.applicant1.firstName}'s ${capitaliseWords(section.title)} <ul><li>Uploads from ${lastDocDate.format('D MMM YYYY')} to present</li></ul>`
                    }
                }
            }
            return `${mortgage.applicant1.firstName}'s ${capitaliseWords(section.title)}`
        }
        if (section.applicant === 2) {
            if (!!section.record && !!section.record.alertBody && section.record.alertBody.length > 0) {
                return `${mortgage.applicant2.firstName}'s ${capitaliseWords(section.title)} <ul><li><b>NOTE:</b> ${(section.record.alertBody || []).join(' ')}</li></ul> `
            }
            if (!!section?.verified?.isVerifiable) {
                if (section?.record?.lastDocumentDate) {
                    let [maxAge, units] = section.verified.minimums.maxAge
                    let lastDocDate = dayjs(section.record.lastDocumentDate, 'YYYY-MM-DD')
                    if (today.diff(lastDocDate, units, true) > maxAge) {
                        return `${mortgage.applicant2.firstName}'s ${capitaliseWords(section.title)} <ul><li>Uploads from ${lastDocDate.format('D MMM YYYY')} to present</li></ul>`
                    }
                }
            }
            return `${mortgage.applicant2.firstName}'s ${capitaliseWords(section.title)}`
        }
        if (!section.applicant) {
            if (!!section.record && !!section.record.alertBody && section.record.alertBody.length > 0) {
                return `${capitaliseWords(section.title)} <ul><li><b>NOTE:</b> ${(section.record.alertBody || []).join(' ')}</li></ul> `
            }
            if (!!section?.verified?.isVerifiable) {
                if (section?.record?.lastDocumentDate) {
                    let [maxAge, units] = section.verified.minimums.maxAge
                    let lastDocDate = dayjs(section.record.lastDocumentDate, 'YYYY-MM-DD')
                    if (today.diff(lastDocDate, units, true) > maxAge) {
                        return `${capitaliseWords(section.title)} <ul><li>Uploads from ${lastDocDate.format('D MMM YYYY')} to present</li></ul>`
                    }
                }
            }
            return `${capitaliseWords(section.title)}`
        }
        return section.title
    }).map(item => `<li>${item}</li>`)

    let finalText = outstandingItems.length > 0 ? `
            <p>We wanted to remind you that to move forward with your mortgage application, we kindly ask you to upload the following remaining items:</p>
            <ul>
                ${outstandingList}
            </ul>
            <p>Please <a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Upload Documents</a> when you are ready to proceed</p>
            ` : ''

    const outstandingQueries = mortgage.queries.filter(query => query.stage === 'APPLY' && !query.userAnswered).length > 0
    if (outstandingQueries) {
        finalText
            += outstandingList.length > 0 ? `<p>There are also some queries that need to be answered which you'll find by the "more info needed" when you sign in</p>` : `<p>In order to expedite your mortgage application, I need you to answer some queries about your application. Please sign in to the website to provide the information requested</p>`
    }

    return `
<p>Hi ${mortgage.applicant1.firstName},</p>
${firstHTMLParagraph}
${finalText}
<p>Best Regards,</p>
<p>The Suss'd Team</p>
        `
}

export const currentSetupOutstandingItemsEmailBody = (mortgage, firstHTMLParagraph = '') => {
    const {setup: setupSections} = applicationUploadsSections(mortgage)
    return getStageBody(mortgage, setupSections, firstHTMLParagraph)
}

function useCurrentOutstandingItemsEmail(firstHTMLParagraph = '') {
    const toolDrawer = useContext(ToolDrawer)
    const mortgage = useMortgage()

    const outstandingForApply = async () => {
        let body = currentApplyOutstandingItemsEmailBody(mortgage, firstHTMLParagraph)
        toolDrawer.composeEmail({
            to     : [mortgage.applicant1.email],
            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
            body,
            from   : 'help'
        })
        // get all the items that are required for the application stage
        // and have not been completed
        // and have not been sent to the user

    }
    const outstandingForSetup = async () => {
        let body = currentSetupOutstandingItemsEmailBody(mortgage, firstHTMLParagraph)
        toolDrawer.composeEmail({
            to     : [mortgage.applicant1.email],
            subject: 'Action Required: Outstanding Documents for Your Mortgage Application',
            body,
            from   : 'help'
        })
    }
    const outstandingForPreApproval = () => {

    }
    const outstandingForApproval = () => {

    }

    const composeOutstandingEmail = async (applicationStage) => {
        if ([ApplicationStages.SETUP].includes(applicationStage)) {
            await outstandingForSetup()
        }
        if ([
            ApplicationStages.APPLY,
            ApplicationStages.SIGN
        ].includes(applicationStage))
        {
            await outstandingForApply()
        }
        if ([ApplicationStages.PREAPPROVAL].includes(applicationStage)) {
            await outstandingForPreApproval()
        }
        if ([ApplicationStages.APPROVAL].includes(applicationStage)) {
            await outstandingForApproval()
        }
    }
    return {composeOutstandingEmail}
}

export default useCurrentOutstandingItemsEmail;