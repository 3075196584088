import React, {useState} from 'react';
import {LoadingOutlined} from "@ant-design/icons";
import {Input} from "antd";
import useMortgage from "../../../../../../../../../providers/mortgage/useMortgage";

function Omitter({uploadId}) {
    const mortgage = useMortgage()
    const [omitting, setOmitting] = useState(false)
    const row = mortgage.uploads.find(item => item.id === uploadId)
    const handleOmit = async (e) => {
        setOmitting(true)
        let omitString =  e.target.value
        const parts = omitString.split(/[^0-9-]+/);
        const omitPages = [];
        for (const part of parts) {
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(Number);
                if (!isNaN(start) && !isNaN(end)) {
                    for (let i = start; i <= end; i++) {
                        if (i !== 0) omitPages.push(i);
                    }
                }
            } else {
                const number = Number(part);
                if (!isNaN(number)) {
                    if (number !== 0) omitPages.push(number);
                }
            }
        }
        await mortgage.mutate({update: {target: `uploads[${uploadId}].omit`, value: omitPages}})
        setOmitting(false)
    }
    if (!row) return null
    return (
        <>
            <span>Omit Pages {omitting &&  <LoadingOutlined/> }</span>
            <Input onBlur={handleOmit} defaultValue={(row.omit || []).join(', ')} />
        </>
    )
}

export default Omitter;