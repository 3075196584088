import React from "react";
import {Badge, Card, Popconfirm, Typography} from "antd";
import {DeleteOutlined, PushpinOutlined, QuestionCircleOutlined} from "@ant-design/icons";

import {LONGDATE} from "../../../../assets/scripts/constants";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {red} from "@ant-design/colors"
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";
import dayjs from "dayjs";
function NoteListItem({item}) {
    const users = useBackendUsers()
    const mortgage = useMortgage()
    function Pinned() {
        const prop = item.pinned ? {style:{color:red.primary}} : {}
        return (
            <PushpinOutlined key="pin" onClick={handlePinClick} {...prop}/>
        )
    }
    function DeleteConfirm() {
        return <Popconfirm
            title="Delete the note"
            description="Are you sure to delete this note?"
            okText="Delete"
            onConfirm={handleDeleteClick}
            icon={
                <QuestionCircleOutlined
                    style={{
                        color: red.primary,
                    }}
                />
            }
        >
            <DeleteOutlined/>
        </Popconfirm>
    }

    const handlePinClick = async () => {
        await mortgage.mutate({
            update: {
                target: `notes[${item.id}].pinned`,
                value: !item.pinned
            }
        })
    }
    const handleDeleteClick = async (e) => {
        await mortgage.mutate({
            delete: {
                target: `notes`,
                id : item.id
            }
        })
    }
    return (
        <Badge.Ribbon color="gray" styles={{width: '100%'}} text={users.getBySub(item.author).firstName}>
            <Card
                styles={{body:{paddingTop:30}}}
                size="small"
                actions={[
                    <DeleteConfirm key="delete"/>,
                    <span className=" px-6">{dayjs(item.createdAt).format(LONGDATE)}</span>,
                    <Pinned key="pinned"/>
                ]}
                >
                <Typography.Paragraph ellipsis={{rows: 4, expandable: true, symbol: 'more'}}>{item.body.map(parg =>
                    <React.Fragment key={`note-text-${parg}`}>{parg}<br/></React.Fragment>)}</Typography.Paragraph>
            </Card>
        </Badge.Ribbon>

    )

}

export default NoteListItem;