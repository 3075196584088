import React, {useContext} from 'react';
import {Mortgages} from "../../../../providers/MortgagesProvider";
import {OutstandingItems} from "../../../../providers/mortgage/OutstandingItemsProvider";
import dayjs from "dayjs";
import useFlowItems from "./useFlowItems";



function useTodos(props) {
    const mortgages = useContext(Mortgages)
    const outstandingItems = useContext(OutstandingItems)
    const {problems, allAdvisorProblems, allRestProblems,allSupportProblems} = useFlowItems()
    // FLOW ITEMS:
    // const problems = stageKeys.flatMap(STAGE=>{
    //     let stageScript = ALL[STAGE]
    //     return getMortgagesByKey(STAGE).flatMap(mortgage=>{
    //         if (!mortgage.applicant1){

    //             return []
    //         }
    //         let lastStep = mortgage.lastFlowItem
    //         let mortgageSteps = stageScript(mortgage)

    //         let nextStep = mortgageSteps[0]
    //         if (lastStep){
    //
    //
    //             // if the last step is an emailer check if the emails were sent
    //             // if theres one not sent, check if that's due,
    //             // if it is, return that step
    //
    //
    //
    //
    //
    //             let lastStepIndex = mortgageSteps.findIndex(step=>lastStep.startsWith(`${STAGE}.${step.name}`))
    //             if (lastStepIndex !== -1){
    //                 nextStep = getNextStepAfter(lastStepIndex, mortgageSteps)
    //             }
    //         }
    //         let toDo = nextStep.toDo()
    //         return  [expandToDoNames(nextStep, toDo, STAGE)]
    //     })
    // })

    // PENDING UPLOADS & ANSWERED QUERIES:
    const pendingItems = []
    const activePendingMortgageIds = Object.keys(outstandingItems).filter(mortgageId => mortgages.ACTIVE.find(m => m.id === mortgageId))
    activePendingMortgageIds.forEach(mortgageId => {
        const mortgage = mortgages.ACTIVE.find(m => m.id === mortgageId)
        let uploads = outstandingItems[mortgageId].uploads
        let queries = outstandingItems[mortgageId].queries
        let needsReview = outstandingItems[mortgageId].needsReview
        if (needsReview.length > 0) {
            const sortedArray = needsReview.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const due = sortedArray.length ? dayjs(sortedArray[0].createdAt) : null
            pendingItems.push({
                name: 'itemNeedsReview',
                key: `itemNeedsReview[${mortgageId}]`,
                title: `Uploads need your review`,
                for: 'advisor',
                due,
                mortgage
            })
        }
        if (uploads.length > 0) {
            const sortedArray = uploads.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            const due = sortedArray.length ? dayjs(sortedArray[0].createdAt) : null
            pendingItems.push({
                name: 'itemUploaded',
                key: `itemUploaded[${mortgageId}]`,
                title: `Confirm uploaded items`,
                for: 'support',
                due,
                mortgage
            })
        }
        if (queries.length > 0) {
            const sortedArray = queries.sort((a, b) => new Date(b.userAnswerOn) - new Date(a.userAnswerOn));
            const due = sortedArray.length ? dayjs(sortedArray[0].userAnswerOn) : null
            pendingItems.push({
                name: 'queryAnswered',
                key: `queryAnswered[${mortgageId}]`,
                title: `Attend to answered query`,
                due,
                for: 'advisor',
                mortgage
            })
        }
    })

    // DELAYED
    const upcomingMortgages = mortgages.DELAYED.map(mortgage => ({
        name: 'upcomingMortgage',
        key: `upcomingMortgage[${mortgage.id}]`,
        title: `Delay until ${dayjs(mortgage.applicationFutureDate, 'YYYY-MM-DD').format('DD MMM YYYY')}`,
        due: dayjs(mortgage.applicationFutureDate, 'YYYY-MM-DD'),
        for: 'advisor',
        mortgage
    })).filter(it => it.due.isBefore(dayjs()))

    let internalItems = [
        ...pendingItems,
        ...upcomingMortgages
    ]

    return {
        toDos: [
            ...problems,
            ...internalItems
        ].sort((a, b) => a.due && b.due ? b.due.isBefore(a.due) ? -1 : 1 : 0),
        allAdvisorProblems: [...allAdvisorProblems, ...internalItems.filter(it=>it.for==='advisor')],
        allRestProblems,
        allSupportProblems: [...allSupportProblems, ...internalItems.filter(it=>it.for==='support')]
    }
}

export default useTodos;