import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {
    EllipsisOutlined,
    FileDoneOutlined,
    FilePdfOutlined,
    IdcardOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import useApplicationUploads from "../../../../../providers/mortgage/useApplicationUploads";

import V2Uploads from "../../_common/uploads/v2/V2Uploads";
import {updateCompletedFlowItems} from "../../../../../../config-files/mortgage/administrative-todos";
import useAdminStages from "../../../../../providers/mortgage/useAdminStages";
import {Button} from "antd";
import ButtonAsync from "../../../../elements/ButtonAsync";
import {EmploymentTypes, MortgageTypes} from "../../../../../../models";
import UploadsAllDoneModalConfirm from "./UploadsAllDoneModalConfirm";
import useCurrentOutstandingItemsEmail
    from "../../../../../../config-files/mortgage/emails/useCurrentOutstandingItemsEmail";
import {useEffect} from "react";

function UploadsForApply(props) {
    const mortgage = useMortgage()
    const {apply: sections} = useApplicationUploads()
    let namesEncountered = []
    const nameInGroup = (name, group) => {
        namesEncountered = [...new Set([...namesEncountered, ...group])]
        return !!group.some(root => name.startsWith(root));
    };
    const getApplicantStatementGroup = (n) => {
        let result =  sections.reduce((acc, obj) => {
            if (obj.applicant === n || (n===1 && mortgage.mortgageType === MortgageTypes.REFINANCE && obj.name === 'mortgage-statements')) {
                const {statementGroup, ...rest} = obj;
                if (!!statementGroup) {
                    if (!acc[statementGroup]) {
                        acc[statementGroup] = [];

                    }
                    namesEncountered.push(obj.name)
                    acc[statementGroup].push({statementGroup, ...rest});
                }
            }
            return acc;
        }, {});

        return result
    }

    const groups = [
        {
            label: "Identification",
            name: "identification-1",
            icon: <IdcardOutlined/>,
            sections: sections.filter(s => nameInGroup(s.name, [
                'identification-1',
                'proof-of-address-1',
                'residence-permit-1'
            ]))
        },
        {
            label: "Statements",
            name: "statements-1",
            icon: <FilePdfOutlined/>,
            sections: getApplicantStatementGroup(1) //sections.filter(s => nameInGroup(s.name, ['current-accounts-1', 'assets-1','debts-1']))
        },
        {
            label: "Employment",
            name: "employment-1",
            icon: <SolutionOutlined/>,
            disabled: (()=>{
                let legacyEmployment = !(mortgage.applicant1.employmentIncome && mortgage.applicant1.employmentIncome.length > 0 && mortgage.applicant1.employmentIncome[0].employmentType)
                let type =legacyEmployment ? mortgage.applicant1.employment.employmentType : mortgage.applicant1.employmentIncome[0].employmentType
                return [EmploymentTypes.HOMEMAKER, EmploymentTypes.HOMEMAKER].includes(type)
            })(),
            sections: sections.filter(s => nameInGroup(s.name, [
                'employment-summary-1',
                'payslips-1',
                'salary-cert-1',
                'employment-contract-1',
                'accountant-letter-1',
                'business-statements-1',
                'form11-1',
                'accounts-1',
            ]))
        },
        {
            label: "Certs",
            name: "certs-1",
            icon: <FileDoneOutlined/>,
            sections: sections.filter(s => nameInGroup(s.name, [
                'marriage-cert-1',
                'credit-report-1',
                'foreign-credit-report-1'
            ]))
        },
    ]

    if (mortgage.twoApplicants) {
        groups.push(
            {
                label: "Identification",
                name: "identification-2",
                icon: <IdcardOutlined/>,
                sections: sections.filter(s => nameInGroup(s.name, [
                    'identification-2',
                    'proof-of-address-2',
                    'residence-permit-2'
                ]))
            },
            {
                label: "Statements",
                name: "statements-2",
                icon: <FilePdfOutlined/>,
                sections: getApplicantStatementGroup(2)
            },
            {
                label: "Employment",
                name: "employment-2",
                icon: <SolutionOutlined/>,
                disabled: (()=>{
                    let legacyEmployment = !(mortgage.applicant2.employmentIncome && mortgage.applicant2.employmentIncome.length > 0 && mortgage.applicant2.employmentIncome[0].employmentType)
                    let type =legacyEmployment ? mortgage.applicant2.employment.employmentType : mortgage.applicant2.employmentIncome[0].employmentType
                    return [EmploymentTypes.HOMEMAKER, EmploymentTypes.HOMEMAKER].includes(type)
                })(),
                sections: sections.filter(s => nameInGroup(s.name, [
                    'employment-summary-2',
                    'payslips-2',
                    'salary-cert-2',
                    'employment-contract-2',
                    'accountant-letter-2',
                    'business-statements-2',
                    'form11-2',
                    'accounts-2',
                ]))
            },
            {
                label: "Certs",
                name: "certs-2",
                icon: <FileDoneOutlined/>,
                sections: sections.filter(s => nameInGroup(s.name, [
                    'marriage-cert-2',
                    'credit-report-2',
                    'foreign-credit-report-2'
                ]))
            }
        )
    }

    groups.push({
        name: "other",
        label: "Other",
        icon: <EllipsisOutlined/>,
        sections: sections.filter(s => !nameInGroup(s.name, namesEncountered))
    })

    function Actions() {
        const {composeOutstandingEmail} = useCurrentOutstandingItemsEmail('<p>Thank you for the recent uploads which have now been processed.</p>')

        if (mortgage.completedFlowItems.hasOwnProperty('ApplyUploadsVerified')) return null
        let allDone = groups.every(group => {
            if (!Array.isArray(group.sections) || group.sections.length === 0) return true
            return group.sections.every(section => section.record && section.record.sectionChecked)
        })
        const markUploadsDone = async () => {
            await updateCompletedFlowItems(mortgage, 'ApplyUploadsVerified')
            //await adminStages.setComplete('APPLY', 'verifyUploads.button.verified', true)
        }
        return <>
            {allDone && <UploadsAllDoneModalConfirm onOk={markUploadsDone}/>}
            <div className="d-col ps-15">
                <ButtonAsync onClick={markUploadsDone}> Mark All Uploads Done </ButtonAsync>
                <Button > Send Email Thanks</Button>
            </div>


        </>
    }

    return <V2Uploads groups={groups} stage="APPLY" actions={<Actions/>}/>

     //return <ApplicationUploadList sections={sections} key={"apply-uploads"}/>
//
}

export default UploadsForApply;