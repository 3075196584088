import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import awsConfig from './aws-exports';
import "./assets/styles/helper-style.css"
import "./assets/styles/dashboard.css"
import "./assets/scripts/utilities"
import App from "./app/App"
const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
        ...awsConfig.oauth,
        domain: "oauth2.sussd.ie",
    }
}
Amplify.configure(updatedAwsConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <App/>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
