import React from 'react';
import SectionsDashboard from "../../components/client/application/forms/SectionsDashboard";
import {useParams} from "react-router-dom";
function ApplicationFormPage(props) {
    const params = useParams()
    return (

            <SectionsDashboard sectionName={params.sectionName} sectionIndex={params?.index}/>

    );
}

export default ApplicationFormPage;