import {differenceInYears, intervalToDuration, isFuture} from "date-fns";
import {correctValueForDb, Mortgage} from "../../../../../providers/mortgage/MortgageProvider";
import {PersonalAccommodationSituations} from "../../../../../../models";
import {isInPast, monthYearFormat} from "./validations";
import {capitaliseFirst} from "../../../../../../assets/scripts/string-functions";
import SectionForm from "../SectionForm";
import {useContext, useEffect, useMemo} from "react";

export function isMale(props) {
    return {
        label: "Gender",
        text: "What is " + props.you.your + " gender?",
        name: "isMale",
        target: `applicant${props.applicant}.isMale`,
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Female",
                    value: false
                },
                {
                    text: "Male",
                    value: true
                }
            ]
        },
        model: "applicant",
        rules: [
            {required: true, message: "Select " + props.you.your + " gender"},
        ]
    }
}
export function firstName(props) {
    return {
        label: "First Name",
        text: "What is " + props.you.your + " first name?",
        name: "firstName",
        answer: {
            type: "text",
        },
        model: "applicant",
        target: `applicant${props.applicant}.firstName`,
        rules: [
            {required: true, message: `${capitaliseFirst(props.you.your)} first name is required`},
        ]
    }
}
export function surname(props) {
    return {
        label: "Surname",
        text: "What is " + props.you.your + " surname?",
        name: "surname",
        answer: {
            type: "text",
        },
        model: "applicant",
        target: `applicant${props.applicant}.surname`,
        rules: [
            {required: true, message: `${capitaliseFirst(props.you.your)} surname is required`},
        ]
    }
}
export function addressCurrent(props) {
    return {
        label: "Home Address",
        text: "What is " + props.you.your + " home address?",
        name: "addressCurrent",
        answer: {
            type: "address"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.addressCurrent`,
        rules: [
            {required: true, message: `${capitaliseFirst(props.you.your)} address is required`},
        ]
    }
}
export function addressCurrentYears(props) {
    return {
        label: "Years At Address",
        text: "For how many years " + props.you.haveYou + " lived here?",
        name: "addressCurrentYears",
        answer: {
            type: "int",
            validation: {
                min: {value: 0, message: 'The minimum value here is 0'},
                max: {value: 67, message: "Max value exceeded"}
            }
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.addressCurrentYears`,
        rules: [
            {required: true, message: `A number of years is required`},
            {type: 'number', min: 0, message: 'Minimum number is zero'},
            {type: 'number', max: 40, message: 'Maximum number is 40'}
        ]
    }
}
export function addressCurrentStartDate(props) {
    return {
        label: "Moved In",
        text: "Since what date " + props.you.haveYou + " lived here?",
        name: "addressCurrentStartDate",
        answer: {
            type: "mmyyyy",
            validation: {
                max: {value: new Date(), message: 'Date cannot be in the future'},
            }
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.addressCurrentStartDate`,
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInPast},
        ]
    }
}
export function addressPrevious(props) {
    return {
        label: "Previous Address",
        text: "What was " + props.you.your + " previous address?",
        name: "addressPrevious",
        answer: {
            type: "address"
        },
        hides: (form) => {
            let addressCurrentStartDate = form.getFieldValue('addressCurrentStartDate')
            let value = correctValueForDb('mmyyyy', addressCurrentStartDate)
            if (value) {
                let o = intervalToDuration({
                    start: new Date(),
                    end: new Date(value)
                })
                return o.years >= 2
            }
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.addressPrevious`,
        rules: [
            {required: true, message: `An address is required`},
        ]
    }
}
// export function dependentAges(props) {
//     return {
//         label: "Children Ages",
//         text: "What are their ages?",
//         name: "dependentAges",
//         answer: {
//             type: "agearray",
//         },
//         hides: (form) =>{
//             let value = form.getFieldValue('countDependents')
//
//         }
//         hides2: {
//             countDependents: (v) => {
//                 return parseInt(v) === 0 || isNaN(parseInt(v))
//             }
//         },
//         model: "personal",
//         target: `applicant${props.applicant}.personal.dependentAges`,
//         rules: [
//             {required: true, message: `The dependent ages are required`},
//         ],
//     }
// }
export function dependentDOBs(props) {
    return {
        label: "Dependent DOBs",
        text: (form) => {
            let value = form.getFieldValue('countDependents')
            return value > 1 ? "What is the date of birth for each?" : "What is the date of birth"
        },
        name: "dependentDOBs",
        answer: {
            type: "agearray",
        },
        hides: (form) => {
            let value = form.getFieldValue('countDependents')
            return parseInt(value) === 0 || isNaN(parseInt(value))
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.dependentDOBs`,
        rules: [
            {required: true, message: `Dates of birth are required`},
        ]
    }
}
export function title(props) {
    return {
        label: "Title",
        text: "What title " + props.you.doYou + " use?",
        name: "title",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Mr",
                    value: "MR"
                },
                {
                    text: "Mrs",
                    value: "MRS"
                },
                {
                    text: "Miss",
                    value: "MISS"
                },
                {
                    text: "Dr",
                    value: "DR"
                }
            ]
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.title`,
        rules: [
            {required: true, message: `A title is required`},
        ]
    }
}
export function maidenName(props) {
    return {
        label: "Maiden Name",
        text: "What is " + props.you.your + " maiden name?",
        name: "maidenName",
        answer: {
            type: "text"
        },
        hides: (form) => {
            let mS = form.getFieldValue('maritalStatus')
            if (["SINGLE", "COHABITING"].includes(mS)) {
                return true
            }
            let male = form.getFieldValue('isMale')
            return !!male;
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.maidenName`,
        rules: [
            {required: true, message: `A maiden name is required`},
        ]
    }
}

export function mothersMaidenName(props) {
    return {
        label: "Mother's Maiden Name",
        text: "What is " + props.you.your + " mother's maiden name?",
        name: "mothersMaidenName",
        answer: {
            type: "text"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.mothersMaidenName`,
        rules: [
            {required: true, message: `A maiden name is required`},
        ]
    }
}
export function accommodationSituation(props) {
    return {
        label: "Home Situation",
        text: "What best describes " + props.you.your + " living situation?",
        name: "accommodationSituation",
        answer: {
            type: "select",
            choices: [
                {
                    text: props.you.I + " own" + (props.you.I === "I" ? "" : "s") + " the home",
                    value: "OWNED"
                },
                {
                    text: props.you.I + " rent" + (props.you.I === "I" ? "" : "s") + " the home",
                    value: "RENTED"
                },
                {
                    text: props.you.I + " live" + (props.you.I === "I" ? "" : "s") + " with relatives",
                    value: "RELATIVES"
                },
                {
                    text: "Other",
                    value: "OTHER"
                }
            ]
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.accommodationSituation`,
        rules: [
            {required: true, message: `An accommodation situation is required`},
        ]
    }
}
export function accommodationSituationOther(props) {
    return {
        label: "Living Situation",
        text: "Provide more details about " + props.you.your + " living situation?",
        name: "accommodationSituationOther",
        answer: {
            type: "textarea"
        },
        hides: (form) => {
            let value = form.getFieldValue('accommodationSituation')
            return value !== "OTHER"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.accommodationSituationOther`,
        rules: [
            {required: true, message: `More details are required`},
        ]
    }
}
export function dateOfBirth(props) {
    const validateDate = (_, value) => {
        if (value && !/^\d{2}\s\/\s\d{2}\s\/\s\d{4}$/.test(value)) {
            return Promise.reject('Enter a valid date in the format "DD / MM / YYYY"');
        }
        let str = value.split(' / ').reverse().join('-')
        let vDate = new Date(str)
        let now = new Date();
        let age = differenceInYears(now, vDate)
        if (age < 18) {
            return Promise.reject('You must be over 18 to use this service');
        }
        if (age >= 66) {
            return Promise.reject('You must be 65 or younger to use this service');
        }
        return Promise.resolve();
    };
    return {
        label: "Date of Birth",
        text: "What is " + props.you.your + " date of birth?",
        name: "dateOfBirth",
        answer: {
            type: "ddmmyyyy",
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.dateOfBirth`,
        rules: [
            {required: true, message: `A date required`},
            {validator: validateDate},
        ]
    }
}
export function maritalStatus(props) {
    return {
        label: "Marital Status",
        text: "What is " + props.you.your + " marital status?",
        name: "maritalStatus",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Single",
                    value: "SINGLE"
                },
                {
                    text: "Cohabiting",
                    value: "COHABITING"
                },
                {
                    text: "Married",
                    value: "MARRIED"
                },
                {
                    text: "Re-married",
                    value: "REMARRIED"
                },
                {
                    text: "Separated",
                    value: "SEPARATED"
                },
                {
                    text: "Divorced",
                    value: "DIVORCED"
                },
                {
                    text: "Widowed",
                    value: "WIDOWED"
                }

            ]
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.maritalStatus`,
        rules: [
            {required: true, message: `A marital status is required`},
        ]
    }
}
export function countryOfBirth(props) {
    return {
        label: "Country of Birth",
        text: "What is " + props.you.your + " country of birth?",
        name: "countryOfBirth",
        answer: {
            type: "country"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.countryOfBirth`,
        rules: [
            {required: true, message: `A country is required`},
        ]
    }
}
export function countryOfResidence(props) {
    return {
        name: "countryOfResidence",
        label: "Country of Residence",
        text: "In what country " + props.you.doYou + " reside?",
        answer: {
            type: "country"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.countryOfResidence`,
        rules: [
            {required: true, message: `A country is required`},
        ]
    }
}
export function countDependents(props) {
    return {
        label: "Number Dependents",
        text: "How many dependent children " + props.you.doYou.toString() + " have?",
        name: "countDependents",
        answer: {
            type: "select",
            choices: [
                {
                    text: "None",
                    value: 0
                },
                {
                    text: "One",
                    value: 1
                },
                {
                    text: "Two",
                    value: 2
                },
                {
                    text: "Three",
                    value: 3
                },
                {
                    text: "Four",
                    value: 4
                },
                {
                    text: "Five",
                    value: 5
                },
                {
                    text: "Six",
                    value: 6
                }
            ]
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.countDependents`,
        rules: [
            {required: true, message: `A number of dependents is required`},
        ],
        watch: (form, value) => {
            let existing = form.getFieldValue('dependentDOBs') || []
            if (Array.isArray(existing)) {
                if (value > existing.length) {
                    let additional = Array(value - existing.length).fill(null)
                    form.setFieldValue('dependentDOBs', [...existing, ...additional])
                } else if (value < existing.length) {
                    let removal = existing.length - value
                    let nonEmpty = existing.filter(item => item !== "")
                    if (nonEmpty.length < existing.length) {
                        removal -= (existing.length - nonEmpty.length)
                    }
                    let remaining = nonEmpty.slice(0, nonEmpty.length - removal)
                    form.setFieldValue('dependentDOBs', remaining)
                }
            }
        }
    }
}
export function nationality(props) {
    return {
        label: "Nationality",
        text: "What is " + props.you.your + " nationality?",
        name: "nationality",
        answer: {
            type: "nationality"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.nationality`,
        rules: [
            {required: true, message: `A nationality is required`},
        ]
    }
}
export function negativeCredit(props) {
    return {
        name: "negativeCredit",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        label: "Negative Credit",
        text: capitaliseFirst(props.you.haveYou) + " ever been made bankrupt, made any arrangements with creditors, had any court judgements for debt made against you or been in arrears with any existing or previous loan?",
        model: "financial",
        target: `applicant${props.applicant}.financial.negativeCredit`,
        rules: [
            {required: true, message: `An answer is required`},
        ]
    }
}
export function contributeToRelatives(props) {
    return {
        text: capitaliseFirst(props.you.doYou) + " contribute anything towards " + props.you.your + " upkeep there?",
        label: "Boarding Contribution",
        name: "contributeToRelatives",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('accommodationSituation') !== "RELATIVES"
        },
        model: "financial",
        target: `applicant${props.applicant}.financial.contributeToRelatives`,
        rules: [
            {required: true, message: `An answer is required`},
        ]
    }
}
export function monthlyAccommodationCosts(props) {
    return {
        name: "monthlyAccommodationCosts",
        label: "Accommodation Costs",
        text: (form) => {
            let value = form.getFieldValue('accommodationSituation')
            if (value === PersonalAccommodationSituations.RENTED) {
                return "How much is " + props.you.your + " rent per month?"
            }
            return "How much is " + props.you.your + " contribution per month?"
        },
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            let value = form.getFieldValue('accommodationSituation')
            if (value === PersonalAccommodationSituations.RELATIVES) {
                if (form.getFieldValue('contributeToRelatives')) {
                    return false
                }
            }
            return value !== PersonalAccommodationSituations.RENTED
        },
        model: "financial",
        target: `applicant${props.applicant}.financial.monthlyAccommodationCosts`,
        rules: [
            {required: true, message: `An accommodation cost is required`},
            {type: 'number', min: 1, message: 'This must be a value greater than zero'},
            {type: 'number', max: 10000, message: 'Maximum accommodation costs are €10,000'}
        ]
    }
}
export function hasChildcareCosts(props) {

    return {
        label: "Has Child Minding Costs",
        text: capitaliseFirst(props.you.doYou) + " have any child-minding costs?",
        name: "hasChildcareCosts",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form) => {
            let value = form.getFieldValue('countDependents')
            return parseInt(value) === 0
        },
        model: "financial",
        target: `applicant${props.applicant}.financial.hasChildcareCosts`,
        rules: [
            {required: true, message: `An answer is required`},
        ]
    }
}
export function monthlyChildcareCosts(props) {
    return {
        label: "Child Minding Costs",
        text: "How much are " + props.you.your + " child-minding costs per month?",
        name: "monthlyChildcareCosts",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            let count = form.getFieldValue('countDependents')
            if (parseInt(count) === 0) {
                return true
            }
            let costs = form.getFieldValue('hasChildcareCosts')
            return !costs
        },
        model: "financial",
        target: `applicant${props.applicant}.financial.monthlyChildcareCosts`,
        rules: [
            {required: true, message: `An amount is required`},
        ]
    }
}
export function placeOfBirth(props) {
    return {
        label: "Place of Birth",
        name: "placeOfBirth",
        text: "What is " + props.you.your + " place of birth?",
        subtext: "supply county or city",
        answer: {
            type: "text"
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.placeOfBirth`,
        rules: [
            {required: true, message: `An town/city is required`},
        ]
    }
}
export function mobile(props){
    return {
        label: "Mobile Number",
        text: "What is " + props.you.your + " mobile number?",
        name: "mobile",
        answer: {
            type: "phone"
        },
        model: "applicant",
        target: `applicant${props.applicant}.mobile`,
        rules: [
            {required: true, message: `A mobile number is required`},
        ]
    }
}
export function email(props){
    return {
        label: "Email Address",
        text: "What is " + props.you.your + " email address?",
        name: "email",
        answer: {
            type: "email"
        },
        model: "applicant",
        target: `applicant${props.applicant}.email`,
        rules: [
            {required: true, message: `An email address is required`},
        ]
    }
}
export function otherCountryOfResidenceInPast3Years(props) {
    return {
        name: "otherCountryOfResidenceInPast3Years",
        label: "Other Countries in past 3 yrs",
        text: capitaliseFirst(props.you.haveYou)  + " lived in any other country in the past 3 years?",
        answer: {
            type: "choice",
            choices:[
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides:(form)=>{
            return form.getFieldValue('countryOfResidence') !== 'IE'
        },
        model: "personal",
        target: `applicant${props.applicant}.personal.otherCountryOfResidenceInPast3Years`,
        rules: [
            {required: true, message: `A selection is required`},
        ]
    }
}
export function irishResidenceStartDate(props) {
    //TODO: change to MMYYYY
    return {
        label: "Irish Residency Start Date",
        text: "When did " + props.you.you + " start living in Ireland",
        name: "irishResidenceStartDate",
        answer: {
            type: "mmyyyy",
        },
        hides:(form)=>{
            return form.getFieldValue('otherCountryOfResidenceInPast3Years') !== true || form.getFieldValue('countryOfResidence') !== 'IE'
        },
        model: "personal",
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.personal.irishResidenceStartDate`,
    }
}
export const applicantPersonalSteps = ({mortgage, applicant}) => {
    const betterProps = {
        mortgage,
        applicant,
        you: mortgage[`applicant${applicant}`].strings
        //you(applicant === 1 ? false : mortgage.applicant2?.firstName ? mortgage.applicant2.firstName : 'the other applicant')
    }
    let stepsList = [
        {
            label: 'Personal',
            title: `Tell us about ${betterProps.you.you === 'you' ? 'yourself' : betterProps.you.you}`,
            questions: [
                firstName(betterProps),
                surname(betterProps),
                mobile(betterProps),
                email(betterProps),
                isMale(betterProps),
                title(betterProps),
                maritalStatus(betterProps),
                maidenName(betterProps),
                dateOfBirth(betterProps),
                mothersMaidenName(betterProps),
            ]
        },
        {
            label: 'Dependents',
            title: `Tell us about ${betterProps.you.your} dependent children`,
            questions: [
                countDependents(betterProps),
                dependentDOBs(betterProps),
                hasChildcareCosts(betterProps),
                monthlyChildcareCosts(betterProps),
            ]
        }
    ]
    let isApp2andCohabiting = mortgage.requirement.cohabiting && applicant === 2
    if (!isApp2andCohabiting) {
        stepsList.push(
            {
                label: 'Accommodation',
                title: `Tell us about ${betterProps.you.your} accommodation`,
                questions: [
                    accommodationSituation(betterProps),
                    accommodationSituationOther(betterProps),
                    contributeToRelatives(betterProps),
                    monthlyAccommodationCosts(betterProps),
                    addressCurrent(betterProps),
                    //TODO: Change to MM/YYYY type When did you move in
                    addressCurrentStartDate(betterProps),
                    addressPrevious(betterProps),
                ]
            }
        )
    }
    else{
        stepsList.push(
            {
                label: 'Accommodation',
                title: `Tell us about ${betterProps.you.your} accommodation`,
                questions: [
                    addressCurrentStartDate(betterProps),
                    addressPrevious(betterProps),
                ]
            }
        )
    }
    stepsList = [
        ...stepsList,
        {
            label: 'Residence',
            title: `Tell us about ${betterProps.you.your} residence and nationality`,
            questions: [
                nationality(betterProps),
                countryOfResidence(betterProps),
                otherCountryOfResidenceInPast3Years(betterProps),
                irishResidenceStartDate(betterProps),
                countryOfBirth(betterProps),
                placeOfBirth(betterProps),
            ]
        },
        {
            label: 'Credit History',
            title: `Tell us about ${betterProps.you.your} credit history`,
            questions: [
                negativeCredit(betterProps),
            ]
        }
    ]
    return stepsList
}

function ApplicantPersonal(props) {
    const steps = applicantPersonalSteps(props)
    const questions = steps.flatMap(step => {
        return step.questions
    })
    return <SectionForm title="Applicant Personal" key="ApplicantPersonal" {...props} questions={questions}/>

}

export default ApplicantPersonal;