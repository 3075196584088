import React from 'react';
import {Modal} from "antd";

function UploadsAllDoneModalConfirm({onOk}) {
    let [open,setOpen] = React.useState(true)

    return (
        <Modal open={open} title="Are uploads done now?" onCancel={()=>setOpen(false)} onOk={onOk} okText="Yes, all done" cancelText="Not yet">
            <p>It looks like all uploads sections are complete</p>
            <p>Will we mark that Apply Uploads are all done?</p>
        </Modal>
    );
}

export default UploadsAllDoneModalConfirm;