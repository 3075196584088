import {AssetTypes, DebtTypes, Frequencies, PersonalAccommodationSituations} from "../../../models";

function _commonAnswers(mortgage) {
    const commonAnswers = {
        rent: 0,
        boarding: 0,
        savings: 0,
        clearingLoans: 0,
        liveSavings: (formData)=>{
            let savings = 0
            if (formData.savings1){
                savings += formData.savings1
            }
            if (mortgage.twoApplicants){
                if (formData.savings2){
                    savings += formData.savings2
                }
            }
            return savings
        },
        liveClearingLoans: (formData)=>{
            let clearingLoans = 0
            filter = debt => debt.debtType === DebtTypes.LOAN
            mortgage.applicant1.debts.filter(filter).forEach(debt => {
                if (formData.clearingLoans1.includes(debt.id)){
                    clearingLoans+= debt.monthlyPayment
                }
            })
            if (mortgage.twoApplicants) {
                mortgage.applicant2.debts.filter(filter).forEach(debt => {
                    if (formData.clearingLoans2.includes(debt.id)){
                        clearingLoans+= debt.monthlyPayment
                    }
                })
            }
            return clearingLoans
        }
    }
    if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
        commonAnswers.rent += mortgage.applicant1.financial.monthlyAccommodationCosts
    } else if (mortgage.applicant1.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && mortgage.applicant1.financial.contributeToRelatives) {
        commonAnswers.boarding += mortgage.applicant1.financial.monthlyAccommodationCosts
    }
    if (mortgage.twoApplicants && !mortgage.requirement.cohabiting) {
        if (mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RENTED) {
            commonAnswers.rent += mortgage.applicant2.financial.monthlyAccommodationCosts
        } else if (mortgage.applicant2.personal.accommodationSituation === PersonalAccommodationSituations.RELATIVES && mortgage.applicant2.financial.contributeToRelatives) {
            commonAnswers.boarding += mortgage.applicant2.financial.monthlyAccommodationCosts
        }
    }

    // SAVINGS
    let filter = asset => asset.assetType === AssetTypes.SAVING && [Frequencies.MONTHLY, Frequencies.WEEKLY].includes(asset.increasesFrequency)
    mortgage.applicant1.assets.filter(filter).forEach(asset => {
        if (asset.increasesFrequency === Frequencies.MONTHLY) {
            commonAnswers.savings += asset.increases
        } else {
            commonAnswers.savings += (asset.increases * 52 / 12)
        }
    })
    if (mortgage.twoApplicants) {
        mortgage.applicant2.assets.filter(filter).forEach(asset => {
            if (asset.increasesFrequency === Frequencies.MONTHLY) {
                commonAnswers.savings += asset.increases
            } else {
                commonAnswers.savings += (asset.increases * 52 / 12)
            }
        })
    }

// CLEARING LOANS
    let clearingLoans = 0
    filter = debt => debt.debtType === DebtTypes.LOAN && !!debt.clearedBeforeMortgage
    mortgage.applicant1.debts.filter(filter).forEach(debt => {
        commonAnswers.clearingLoans += debt.monthlyPayment
    })
    if (mortgage.twoApplicants) {
        mortgage.applicant2.debts.filter(filter).forEach(debt => {
            commonAnswers.clearingLoans += debt.monthlyPayment
        })
    }

    return commonAnswers
}

export default _commonAnswers;
