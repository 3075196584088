import React, {useContext, useEffect, useRef} from 'react';
import useMortgage from "./useMortgage";
import {Sidebar} from "../SidebarProvider";
import {AdminEmails} from "../AdminEmailsProvider";
import {UploadStatuses} from "../../../models";
import {Mortgage} from "./MortgageProvider";

function MortgageSidebarUpdater(props) {
    const mortgage = useContext(Mortgage)
    const adminEmails = useContext(AdminEmails)
    const sidebar = useContext(Sidebar)


    const getBadgeEmails = ()=>{
        let count = 0
        if (mortgage && adminEmails.items.length) {
            const emails = adminEmails.items.filter(email => {
                if ([mortgage.applicant1ID, mortgage.applicant2ID].includes(email.userapplicantID)) {
                    return email.actionedBy === 'null'
                }
                return false
            })
            count = emails.length
        }

        return {
            count,
            color: 'purple'
        }

    }

    const getBadgeApplicationUploads = ()=>{
        const applyUploads =  !!mortgage ? mortgage.uploads.filter(upload => upload.status === UploadStatuses.PENDING && upload.stage === 'APPLY') : []
        const nonTaskUploads = applyUploads.filter(upload => {
            const section = mortgage.dynamicSections.find(section => {
                if (section.sectionName === upload.section && section.sectionStage === upload.stage) {
                    if (!!upload.index) {
                        return section.sectionIndex === upload.index
                    }
                    return true
                }
                return false
            })
            return !section;
        })

        return {
            color: 'orange',
            count: nonTaskUploads.length
        }
    }
    const getBadgeTasks = (stage)=>{
        const stageUploads = !!mortgage ? mortgage.uploads.filter(upload => upload.status === UploadStatuses.PENDING && upload.stage === stage) : []
        const taskUploads = stageUploads.filter(upload => {
            const section = mortgage.dynamicSections.find(section => {
                if (section.sectionName === upload.section && section.sectionStage === upload.stage) {
                    if (!!upload.index) {
                        return section.sectionIndex === upload.index
                    }
                    return true
                }
                return false
            })
            return !!section;
        })

        return {
            color: 'orange',
            count: taskUploads.length
        }
    }
    const getNotesBadge = ()=>{
        return {
            color: 'orange',
            count: !!mortgage ? mortgage.notes.length : 0
        }

    }
    const getTasksBadge = ()=>{
        return {
            color: 'orange',
            count: !!mortgage ? mortgage.tasks.length : 0
        }
    }
    const mortgageRef = useRef({})

    useEffect(() => {

        if (mortgage) {
            if (mortgageRef.current !== JSON.stringify(mortgage)) {
                mortgageRef.current = JSON.stringify(mortgage)
                sidebar.setBadges({
                    applyUploads: getBadgeApplicationUploads(),
                    applyTasks: getBadgeTasks('APPLY'),
                    preApprovalTasks: getBadgeTasks('PREAPPROVAL'),
                    approvalTasks: getBadgeTasks('APPROVAL'),
                    emails: getBadgeEmails(),
                    notes: getNotesBadge(),
                    tasks: getTasksBadge()
                })
                sidebar.setMortgage(mortgage)
            }
        }
    }, [mortgage]);
}

export default MortgageSidebarUpdater;