import React, {useState} from 'react';
import {Badge, Collapse, Descriptions, Table, Tag} from "antd";
import {ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {green, red} from "@ant-design/colors";
import {capitaliseWords} from "../../../../../../../../assets/scripts/string-functions";

function Differences({
    liveFigures,
    submissionFigures,
    currentTestFigures
})
{
    const [tableOpen, setTableOpen] = useState(false)
    let dataSource = []
    function fixName(str) {
        return capitaliseWords(str
            .replace(/([A-Z])/g, ' $1') // Add space before capital letters
            .replace(/(\d+)/g, ' $1')   // Add space before numbers
            .trim());                    // Remove leading/trailing spaces
    }
    // get currentTestFigures
    Object.keys(liveFigures).filter(key=>{
        return !(key.endsWith('Total') || ['lti', 'ltv'].includes(key) || key.startsWith('variable'))
    }).map(key => {
        let live = liveFigures[key]
        let submission = !submissionFigures ? null :submissionFigures[key]
        let current = currentTestFigures[key]
        if (current !== live || (submission && current !== submission)){
            let name = key
            if (name === 'provisionalLoan') {
                name = 'Loan Amount'
            }
            if (name === 'provisionalTerm') {
                name = 'Term'
            }
            if (name === 'provisionalValue') {
                name = 'Property Value'
            }
            dataSource.push({
                key,
                label: fixName(name),
                live,
                submission,
                current
            })
        }
    })
    if (dataSource.length === 0) {
        return null
    }
    const valueRenderer = (record, value)=>{
        const genValue = (v)=>{
            if (["Term","Sector 1","Sector 2"].includes(record.label)){
                return v
            }
            return v.toEuro()
        }

        let diff = value - record.live
        if (diff === 0) {
            return genValue(value)
        }
        let arrow = diff > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>

        return (<>
            {genValue(value)}
            <Tag size="small" className="ms-3"> {arrow} {genValue(Math.abs(diff))}</Tag>
        </>)
    }
    let columns = [
        {
            title: 'Figure',
            dataIndex: 'label',
            key    : 'label',
            width  : '25%'
        },
        {
            title: 'Verified Value',
            dataIndex: 'live',
            key    : 'live',
            render : (text, record) => {
                console.log('text', text, record)
                return ["Term","Sector 1","Sector 2"].includes(record.label) ? text : text?.toEuro()
            },
            width  : '25%'
        },
        {
            title: 'Submission Value',
            dataIndex: 'submission',
            key    : 'submission',
            render : (text, record) => {
                return valueRenderer(record, record.submission)
            },
            width  : '25%'
        },
        {
            title: 'Current Value',
            dataIndex: 'current',
            key    : 'current',
            width  : '25%',
            render : (text, record) => {
               return valueRenderer(record, record.current)
            }
        },

    ]
    if (!submissionFigures){
        columns = columns.filter(a => a.key !== 'submission')
    }

    let table = (<div>
        <Table bordered={false} columns={columns} size="small" dataSource={dataSource} pagination={false}/>
    </div>)
    return (<Collapse size="small" >
            <Collapse.Panel showArrow={false} header={`${dataSource.length} difference${dataSource.length>1?'s':''} detected`} key="1">
                {table}
            </Collapse.Panel>
        </Collapse>

    )
}

export default Differences;