import React from 'react';
import PageHeader from "../../components/PageHeader";
import ByStage from "../../components/dashboard/statistics/ByStage";
import LenderByMonth from "../../components/dashboard/statistics/LenderByMonth";
import Forecast from "../../components/dashboard/statistics/Forecast";
import RegistrationsByMonth from "../../components/dashboard/statistics/RegistrationsByMonth";

function Stats(props) {
    return (
        <>
            <PageHeader title="Statistics" className="m-15" back={"./../"}/>
            <ByStage/>
            <Forecast/>
            <RegistrationsByMonth/>
            <LenderByMonth/>
        </>
    );
}

export default Stats;