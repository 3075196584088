import OpenAI from 'openai';

const client = new OpenAI({
    dangerouslyAllowBrowser: true,
    apiKey: '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',
});

export async function askChatGPT(messages) {
    const chatCompletion = await client.chat.completions.create({
        messages,
        model: 'gpt-4o-mini',
    });
    console.log({chatCompletion})
    return chatCompletion.choices

}