import React from 'react';
import Index from "../../_common/lender-tests";
import {Form, Select} from "antd";
import {MortgageQualificationStatus} from "../../../../../../models";
import useMortgage from "../../../../../providers/mortgage/useMortgage";

function Qualification(props) {
    const mortgage = useMortgage()

    let timer = null
    const handleQualificationChange = (changedValues, allValues) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            mortgage.mutate({
                update: {
                    target: 'qualification', value: allValues.qualification
                }
            })
        },1000)
    }
    return (
        <div>
            <div className="p-15 d-row" style={{backgroundColor: '#e0e0e0'}}>
                <Form
                    initialValues={{
                        qualification: mortgage.qualification
                    }}
                    onValuesChange={handleQualificationChange}
                >
                    <Form.Item

                        required
                        label="Qualification Status"
                        name="qualification"
                        className="mb-0"
                    >
                        <Select
                            style={{width: 200}}
                            placeholder="Select Qualification"
                            options={[
                                {value: null, label: "Not Set"},
                                {value: MortgageQualificationStatus.MAYBE, label: "Maybe"},
                                {value: MortgageQualificationStatus.QUALIFIED, label: "Qualified"},
                                {value: MortgageQualificationStatus.UNQUALIFIED, label: "Not Qualified"}
                            ]}/>
                    </Form.Item>
                </Form>
            </div>

            <Index/>
        </div>
    );
}

export default Qualification;