import React from 'react';
import {Modal} from "antd";

function MortgageScheduleCall(props) {
    const [visible, setVisible] = React.useState(true)
    const onOk = () => {
        setVisible(false)
    }
    return (
        <Modal title="Schedule a call" closable={false} onOk={onOk} open={visible}>
            Modal Content Here
        </Modal>
    );
}

export default MortgageScheduleCall;