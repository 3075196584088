import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "antd";
import useMortgage from "./useMortgage";
import dayjs from "dayjs";

UseDocumentsRequired.propTypes = {
    stage: PropTypes.string.isRequired
};

function UseDocumentsRequired(stage) {
    const mortgage = useMortgage()
    const lender = 'ptsb'
    let docs = []
    if (!mortgage) return docs

    const dateToParts = (djs) => {
        const result = {}
        const y = djs.format("YYYY").split("")
        const m = djs.format("MM").split("")
        const d = djs.format("DD").split("")
        result.y1 = y[0]
        result.y2 = y[1]
        result.y3 = y[2]
        result.y4 = y[3]
        result.m1 = m[0]
        result.m2 = m[1]
        result.d1 = d[0]
        result.d2 = d[1]
        //console.log({datesTo: result})
        return result
    }
    const dateParts = dateToParts(dayjs())
    switch (lender) {
        case 'ptsb':
            switch (stage) {
                case "APPLY":
                    docs = [
                        {
                            key: 'minimum-standards-checklist',
                            title: 'Minimum Standards Checklist',
                            name: 'minimum-standards-checklist',
                            id: "2ac014de-b770-4851-99ce-aed950a23bb3",
                            folder: "lender-docs/ptsb",
                            elements: {
                                "completed-mdir-sheet": true,
                                "applicant-name-1": mortgage.applicant1.fullName,
                                "sight-of-original-declaration": true,
                                "ordinance-survey": true,
                                "three-months-creditcard-statements": true,
                                "six-months-pra": true,
                                "proof-of-ppsn": true,
                                "six-month-bank-statements": true,
                                "completed-application-form": true,
                                "employment-details-summary": true,
                                "applicant-name-2": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
                                "cover-letter": true,
                                "minimum-standards-checklist": true,
                                "payslips": true,
                                "stamped-salary-certificate": true,
                                "date-string": (dayjs()).format('D MMM YYYY'),
                                "completed-application-for-credit": true
                            }
                        },
                        {
                            key: 'sight-of-original-declaration',
                            title: 'Sight Of Original Declaration',
                            name: 'sight-of-original-declaration',
                            id: "4848e370-e2e0-427d-af5b-7230164e4b87",
                            folder: "lender-docs/ptsb",
                            elements: {
                                "mortgage-account-number": mortgage.id,
                                "applicant-name-1": mortgage.applicant1.fullName,
                                "applicant-name-2": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
                                "year-1": dateParts.y3,
                                "year-2": dateParts.y4,
                                "month-1": dateParts.m1,
                                "month-2": dateParts.m2,
                                "day-1": dateParts.d1,
                                "day-2": dateParts.d2,
                            }
                        },
                    ]
                    break
                case "PREAPPROVAL":
                    docs = [
                        {
                            key: 'loan-offer-confirmation',
                            name: 'loan-offer-confirmation',
                            title: 'Loan Offer Confirmation',
                            folder: "lender-docs/ptsb",
                            id: "201116a1-4646-46a9-a52f-16a1a965f52e",
                            elements: {
                                "confirmed-rate": "str",
                                "green-mortgage-ber": "str",
                                "green-mortgage-no": true,
                                "green-mortgage-yes": true,
                                "loan-amount": "str",
                                "loan-term": "str",
                                "mortgage-account-a-1": "1",
                                "mortgage-account-a-2": "2",
                                "mortgage-account-a-3": "3",
                                "mortgage-account-a-4": "4",
                                "mortgage-account-a-5": "5",
                                "mortgage-account-a-6": "6",
                                "mortgage-account-a-7": "7",
                                "mortgage-account-a-8": "8",
                                "mortgage-account-s-1": "a",
                                "mortgage-account-s-2": "b",
                                "mortgage-account-s-3": "c",
                                "mortgage-account-s-4": "d",
                                "mortgage-account-s-5": "e",
                                "mortgage-account-s-6": "f",
                                "product-name": "str",
                                "property-value": "str",
                                "proposed-ber": "str",
                                "sign-date-d-1": dateParts.d1,
                                "sign-date-d-2": dateParts.d2,
                                "sign-date-m-1": dateParts.m1,
                                "sign-date-m-2": dateParts.m2,
                                "sign-date-y-1": dateParts.y1,
                                "sign-date-y-2": dateParts.y2,
                                "sign-date-y-3": dateParts.y3,
                                "sign-date-y-4": dateParts.y4,
                                "solicitors-address-1": mortgage.solicitor.firmAddress,
                                "solicitors-address-2": "",
                                "solicitors-firm-name": mortgage.solicitor.firmName
                            },
                        },
                        {
                            key: 'pre-drawdown-paye',
                            name: 'Pre Drawdown Information PAYE',
                            folder: "lender-docs/ptsb",
                            id: "856216bc-2bc3-4107-9972-46cbf46836fe",
                            elements: {
                                "mortgage-account-number": mortgage.id,
                                "applicant-1-name": mortgage.applicant1.fullName,
                                "applicant-2-name": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
                                "satisfied-to-proceed-yes": true,
                                "satisfied-to-proceed-no": false,
                                "income-employment-change-no": true,
                                "income-employment-change-yes": false,
                                "applicant-1-sign-date": (dayjs()).format('D MMM YYYY'),
                                "applicant-2-sign-date": (dayjs()).format('D MMM YYYY'),
                            },
                        },
                        {
                            key: 'pre-drawdown-self-employed',
                            name: 'pre-drawdown-self-employed',
                            route: 'Pre Drawdown Information SELFEMPLOYED',
                            folder: "lender-docs/ptsb",
                            id: "4d99fdb0-21c9-495c-a389-bfa3b8a1ee69",
                            elements: {
                                "additional-credit-details": "str",
                                "additional-credit-no": true,
                                "additional-credit-yes": false,
                                "applicant-1-name": mortgage.applicant1.fullName,
                                "applicant-1-sign-date": (dayjs()).format('D MMM YYYY'),
                                "applicant-2-name": mortgage.twoApplicants ? mortgage.applicant2.fullName : 'N/A',
                                "applicant-2-sign-date": (dayjs()).format('D MMM YYYY'),
                                "creditors-up-to-date-details": "str",
                                "creditors-up-to-date-no": false,
                                "creditors-up-to-date-yes": true,
                                "mortgage-account-number": mortgage.id,
                                "satisfied-to-proceed-no": false,
                                "satisfied-to-proceed-yes": true
                            }
                        },
                        {
                            key: 'direct-debit-mandate',
                            name: 'direct-debit-mandate',
                            title: 'Direct Debit Mandate',
                            folder: "lender-docs/ptsb",
                            id: "fee229c0-b233-41e3-b724-572781ae21aa",
                            elements: {
                                "account-holder": mortgage.applicant1.fullName,
                                "bic": "str",
                                "date-of-signing": (dayjs()).format('D MMM YYYY'),
                                "holder-address-1": mortgage.applicant1.addressCurrent,
                                "holder-address-2": "",
                                "holder-address-3": "",
                                "holder-address-4": "",
                                "holder-address-country": "Ireland",
                                "holder-address-post-code": "",
                                "iban": "str",
                                "mortgage-account-number": mortgage.id
                            }
                        },
                    ];
                    break
                default:
            }
            break
    }
    return docs.map(it => ({
        ...it,
        lender,
        document: mortgage.UserMortgageDocuments.find(item => {
            return it.folder === item.folder && it.title === item.title
        })
    }))
}

export default UseDocumentsRequired;