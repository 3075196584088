import {addBunchToMap} from "../overlay-utils";
import {EmploymentTypes} from "../../../../models";

const elements = [
    {
        "name": "account-1",
        "type": "TEXT"
    },
    {
        "name": "account-2",
        "type": "TEXT"
    },
    {
        "name": "account-3",
        "type": "TEXT"
    },
    {
        "name": "account-4",
        "type": "TEXT"
    },
    {
        "name": "account-5",
        "type": "TEXT"
    },
    {
        "name": "account-6",
        "type": "TEXT"
    },
    {
        "name": "account-7",
        "type": "TEXT"
    },
    {
        "name": "account-8",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-mobile",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-name",
        "type": "TEXT"
    },
    {
        "name": "applicant-1-name",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-mobile",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-name",
        "type": "TEXT"
    },
    {
        "name": "applicant-2-name",
        "type": "TEXT"
    },
    {
        "name": "fixed-1-year",
        "type": "CHECK"
    },
    {
        "name": "fixed-10-year",
        "type": "CHECK"
    },
    {
        "name": "fixed-2-year",
        "type": "CHECK"
    },
    {
        "name": "fixed-3-year",
        "type": "CHECK"
    },
    {
        "name": "fixed-4-year-green",
        "type": "CHECK"
    },
    {
        "name": "fixed-5-year",
        "type": "CHECK"
    },
    {
        "name": "fixed-7-year",
        "type": "CHECK"
    },
    {
        "name": "ltv-variable-equal-or-below-50",
        "type": "CHECK"
    },
    {
        "name": "ltv-variable-over-50",
        "type": "CHECK"
    },
    {
        "name": "ltv-variable-over-80",
        "type": "CHECK"
    }
]

export default function (mortgage, config) {
    const mortgageLoanAccountNumber = () => {
        let map = {}
        let errors = []
        const digits = mortgage.requirement.mortgageAccountNo
        if (!digits) {
            errors.push('No mortgage loan account number')
        } else {
            addBunchToMap(map, 'account-', digits.toUpperCase().split(''))
        }
        return {map}
    }
    const applicant1Mobile = () => {
        let map = {}
        let errors = []
        const mobile = mortgage.applicant1.mobile
        if (!mobile) {
            errors.push('No mobile number for applicant 1')
        } else {
            map['applicant-1-mobile'] = mobile
        }
        return {map, errors}
    }
    const applicant2Mobile = () => {
        let map = {}
        let errors = []
        const mobile = mortgage.applicant2.mobile
        if (!mobile) {
            errors.push('No mobile number for applicant 2')
        } else {
            map['applicant-2-mobile'] = mobile
        }
        return {map, errors}
    }
    const applicant1Name = () => {
        let map = {}
        let errors = []
        const name = mortgage.applicant1.fullName.toUpperCase()
        if (!name) {
            errors.push('No name for applicant 1')
        } else {
            map['applicant-1-name'] = name
        }
        return {map, errors}
    }
    const applicant2Name = () => {
        let map = {}
        let errors = []
        const name = mortgage.applicant2.fullName.toUpperCase()
        if (!name) {
            errors.push('No name for applicant 2')
        } else {
            map['applicant-2-name'] = name
        }
        return {map, errors}
    }
    const variableOver80 = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'ltv-variable-over-80',
            label: 'Variable > 80% LTV',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('ltv-variable-over-80')) {
            map['ltv-variable-over-80'] = config['ltv-variable-over-80']
        }
        else{
            map['ltv-variable-over-80'] = mortgage.requirement.lenderProduct === 'Variable Rate - New LTV >80%'
        }
        return {map, ask}
    }
    const variableOver50 = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'ltv-variable-over-50',
            label: 'Variable > 50% <= 80% LTV',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('ltv-variable-over-50')) {
            map['ltv-variable-over-50'] = config['ltv-variable-over-50']
        }
        else{
            map['ltv-variable-over-50'] = mortgage.requirement.lenderProduct === 'Variable Rate - New LTV >50% ≤80%'
        }
        return {map, ask}
    }
    const variableEqualOrBelow50 = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'ltv-variable-equal-or-below-50',
            label: 'Variable <= 50% LTV',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('ltv-variable-equal-or-below-50')) {
            map['ltv-variable-equal-or-below-50'] = config['ltv-variable-equal-or-below-50']
        }
        else{
            map['ltv-variable-equal-or-below-50'] = mortgage.requirement.lenderProduct === 'Variable Rate - New LTV ≤50%'
        }
        return {map, ask}
    }
    const fixed1Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-1-year',
            label: '1 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-1-year')) {
            map['fixed-1-year'] = config['fixed-1-year']
        }
        else{
            map['fixed-1-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 1 Year'
        }
        return {map, ask}
    }
    const fixed2Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-2-year',
            label: '2 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-2-year')) {
            map['fixed-2-year'] = config['fixed-2-year']
        }
        else{
            map['fixed-2-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 2 Year'
        }
        return {map, ask}
    }
    const fixed3Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-3-year',
            label: '3 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-3-year')) {
            map['fixed-3-year'] = config['fixed-3-year']
        }
        else{
            map['fixed-3-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 3 Year'
        }
        return {map, ask}
    }
    const fixed4YearGreen = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-4-year-green',
            label: '4 Year Green',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-4-year-green')) {
            map['fixed-4-year-green'] = config['fixed-4-year-green']
        }
        else {
            map['fixed-4-year-green'] = mortgage.requirement.lenderProduct === 'Haven Green 4 Year Fixed Rate Mortgage'
        }
        return {map, ask}
    }
    const fixed5Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-5-year',
            label: '5 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-5-year')) {
            map['fixed-5-year'] = config['fixed-5-year']
        }
        else{
            map['fixed-5-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 5 Year'
        }
        return {map, ask}
    }
    const fixed7Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-7-year',
            label: '7 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-7-year')) {
            map['fixed-7-year'] = config['fixed-7-year']
        }
        else{
            map['fixed-7-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 7 Year'
        }
        return {map, ask}
    }
    const fixed10Year = () => {
        let map = {}
        let ask = []
        ask.push({
            name: 'fixed-10-year',
            label: '10 Year Fixed',
            type: 'CHECK',
            group: 'RATE TYPE'
        })
        if (config.hasOwnProperty('fixed-10-year')) {
            map['fixed-10-year'] = config['fixed-10-year']
        }
        else{
            map['fixed-10-year'] = mortgage.requirement.lenderProduct === 'Fixed Rate - New & Existing 10 Year'
        }
        return {map, ask}
    }

    const sections = [
        mortgageLoanAccountNumber(),
        applicant1Name(),
        applicant1Mobile(),
        variableOver80(),
        variableOver50(),
        variableEqualOrBelow50(),
        fixed1Year(),
        fixed2Year(),
        fixed3Year(),
        fixed4YearGreen(),
        fixed5Year(),
        fixed7Year(),
        fixed10Year()
    ]
    if (mortgage.twoApplicants){
        sections.push(applicant2Mobile())
        sections.push(applicant2Name())
    }

    return {
        sections
    }



}