import React, {useState} from 'react';
import {Button, App} from "antd";
import {UploadStatuses} from "../../../../../../models";
import useMortgageUploadService from "../../../../../providers/mortgage/useMortgageUploadService";
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import useMortgageDocumentsService, {
    getUserDocumentKey
} from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {CheckCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {API} from "aws-amplify";
import {green} from "@ant-design/colors";
import {capitaliseWords} from "../../../../../../assets/scripts/string-functions";
import {sortDateSorter, sorterByDateName} from "./standardised-upload/UploadsTable";
import {LONGDATE} from "../../../../../../assets/scripts/constants";

function Bundler(props) {
    const folder = 'bundled-uploads'
    const app = App.useApp()
    const uploadService = useMortgageUploadService()
    const documentService = useMortgageDocumentsService()
    const mortgage = useMortgage()

    const [state, setState] = useState({
        loading: false,
    })
    let fileName = ''
    if (!!props.section.applicant) {
        fileName += mortgage[`applicant${props.section.applicant}`].firstName
        fileName += '-'
    }
    fileName += props.section.title.replace(/\s/g, '-')
    fileName += '.pdf'
    fileName = fileName.toLowerCase()

    const existingBundle = mortgage.UserMortgageDocuments.find(it => it.folder === folder && it.stage === props.section.record.applicationStage && it.fileName === fileName && it.submissionID === mortgage?.activeSubmission?.id)
    const bundleFiles = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!mortgage.applicant1.identityID) {
            app.notification.error({message: `You can't access the customers secure folder. Have them register/sign into the app`})
            return
        }
        setState(s => ({...s, loading: true, success: false}))
        //set config for the merge function on the api

        //create the file name

        const path = getUserDocumentKey({
            fileName,
            mortgageID: mortgage.id,
            folder,
            submissionID: mortgage.activeSubmission.id,
        })
        const config = {
            map: [],
            destination: `private/${mortgage.applicant1.identityID}/${path}`
        }
        //get the uploads from the section

        const uploads = props.section.verified.isVerifiable ? props.section.verified.rows.map(it=>it.upload).sort(sortDateSorter)  : props.section.uploads.filter(it => it.status !== UploadStatuses.REJECTED && it.status!==UploadStatuses.EXPIRED && !it.exclude)


        //reorder them by the section arrangement
        config.map = uploads.map(it => {
            let {path, config: uploadConfig} = uploadService.get(it)
            return {
                id: it.id,
                key: `private/${uploadConfig.identityId}/${path}`,
                omit: it.omit || []
            }
        })
        // config.map = props.section.record.arrangement.reverse().map(id => {
        //     let upl = uploads.find(it => it.id === id)
        //     if (upl) {
        //         let {path, config: uploadConfig} = uploadService.get(upl)
        //         return {
        //             id: upl.id,
        //             key: `private/${uploadConfig.identityId}/${path}`,
        //             omit: upl.omit || []
        //         }
        //     }
        //     return null
        // }).filter(it => !!it)
        let document = {
            title: capitaliseWords(props.section.title),
            identityID: mortgage.applicant1.identityID,
            folder,
            fileName,
            stage: props.section.record.applicationStage,
                submissionID: mortgage.activeSubmission.id,
        }
        await documentService.createBundle(config, document)
        setState(s => ({...s, loading: false, success: true}))
    }

    const openFile = (e) => {
        e.preventDefault()
        e.stopPropagation()
        documentService.open(existingBundle)
    }
    return (
        <div className="d-row gap-15">
            <Button onClick={bundleFiles}>
                Bundle
                {state.loading && <LoadingOutlined className="ms-3"/>}
                {!state.loading && !!existingBundle &&
                    <CheckCircleOutlined style={{color: green.primary}} className="ms-3"/>}
            </Button>
            {!state.loading && !!existingBundle && (
                <Button onClick={openFile}>File</Button>
            )}
        </div>

    );
}

export default Bundler;