import React from 'react';
import MortgageEmails from "../../components/client/emails/MortgageEmails";

function MortgageEmailsPage(props) {
    return (

            <MortgageEmails key={'mortgage-key'}/>

    );
}

export default MortgageEmailsPage;