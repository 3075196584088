import {BuildType, DebtTypes, EmploymentTypes} from "../../../../models";
import countries from "../../countries.json"

const elements_old = [
    {
        "name": "applicant-name-1",
        "type": "TEXT"
    },
    {
        "name": "applicant-name-2",
        "type": "TEXT"
    },
    {
        "name": "completed-application-for-credit",
        "type": "CHECK"
    },
    {
        "name": "completed-application-form",
        "type": "CHECK"
    },
    {
        "name": "completed-mdir-sheet",
        "type": "CHECK"
    },
    {
        "name": "cover-letter",
        "type": "CHECK"
    },
    {
        "name": "date-string",
        "type": "TEXT"
    },
    {
        "name": "employment-details-summary",
        "type": "CHECK"
    },
    {
        "name": "minimum-standards-checklist",
        "type": "CHECK"
    },
    {
        "name": "ordinance-survey",
        "type": "CHECK"
    },
    {
        "name": "payslips",
        "type": "CHECK"
    },
    {
        "name": "proof-of-ppsn",
        "type": "CHECK"
    },
    {
        "name": "sight-of-original-declaration",
        "type": "CHECK"
    },
    {
        "name": "six-month-bank-statements",
        "type": "CHECK"
    },
    {
        "name": "six-months-pra",
        "type": "CHECK"
    },
    {
        "name": "stamped-salary-certificate",
        "type": "CHECK"
    },
    {
        "name": "three-months-creditcard-statements",
        "type": "CHECK"
    }
]
const elements = [
    {
        "name": "broker-firm",
        "type": "TEXT"
    },
    {
        "name": "broker-name",
        "type": "TEXT"
    },
    {
        "name": "completed-application-for-credit",
        "type": "CHECK"
    },
    {
        "name": "completed-application-form",
        "type": "CHECK"
    },
    {
        "name": "completed-mdir-sheet",
        "type": "CHECK"
    },
    {
        "name": "cover-letter",
        "type": "CHECK"
    },
    {
        "name": "credit-facilities",
        "type": "CHECK"
    },
    {
        "name": "credit-report",
        "type": "CHECK"
    },
    {
        "name": "date-string",
        "type": "TEXT"
    },
    {
        "name": "mothers-maiden-name-1",
        "type": "TEXT"
    },
    {
        "name": "mothers-maiden-name-2",
        "type": "TEXT"
    },
    {
        "name": "mothers-maiden-names",
        "type": "CHECK"
    },
    {
        "name": "non-eu-nationals",
        "type": "CHECK"
    },
    {
        "name": "ordinance-survey",
        "type": "CHECK"
    },
    {
        "name": "proof-of-ppsn",
        "type": "CHECK"
    },
    {
        "name": "self-employed",
        "type": "CHECK"
    },
    {
        "name": "sight-of-original-declaration",
        "type": "CHECK"
    },
    {
        "name": "six-month-bank-statements",
        "type": "CHECK"
    },
    {
        "name": "six-months-pra",
        "type": "CHECK"
    },
    {
        "name": "stamped-salary-certificate",
        "type": "CHECK"
    }
]

export default function (mortgage, config) {
    const applicantNames = () => {
        let map = {}
        let errors = []
        let app1Name = mortgage.applicant1.personal.mothersMaidenName
        if (!app1Name) {
            errors.push('No applicant 1 mothers name')
        } else {
            map['mothers-maiden-name-1'] = app1Name.toUpperCase()
        }

        if (mortgage.twoApplicants) {
            let app2Name = mortgage.applicant2.personal.mothersMaidenName
            if (!app2Name) {
                errors.push('No applicant 2 mothers name')
            } else {
                map['mothers-maiden-name-2'] = app2Name.toUpperCase()
            }
        }
        map['mothers-maiden-names'] = true
        return {map, errors}
    }
    const broker = () => {
        let map = {
            'broker-name': 'KEVIN COAKLEY',
            'broker-firm': 'TRUSS MORTGAGES LTD T/A SUSS\'D MORTGAGES'
        }
        let ask = []
        ask.push({
            name: 'date-string',
            label: 'Date (date in past)',
            type: 'TEXT',
            group: 'CHECKS'
        })
        if (config.hasOwnProperty('date-string')) {
            map['date-string'] = config['date-string']
        } else {
            const date = new Date();
            const day = date.getDate();
            const month = date.toLocaleString('default', {month: 'short'}).toUpperCase();
            const year = date.getFullYear();
            map['date-string'] = `${day} ${month} ${year}`
        }
        return {map, ask}
    }
    const checks = () => {
        let legacyEmployment = !mortgage[`applicant1`].employmentIncome?.length || !mortgage[`applicant1`].employmentIncome[0].employmentType
        let paye
        let self
        if (legacyEmployment){
            paye = (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED))
            self = (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED))
        }
        else{
            paye = !!mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED) || (mortgage.twoApplicants && !!mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED))
            self = !!mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED) || (mortgage.twoApplicants && !!mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED))

        }

        let surveyIncluded = mortgage.properties[0].buildType === BuildType.SELFBUILD
        let map = {
            'cover-letter': true,
            'completed-application-form': true,
            'completed-application-for-credit': true,
            'sight-of-original-declaration': true,
            'completed-mdir-sheet': true,
            'proof-of-ppsn': true,
            'six-month-bank-statements': true,
            'six-months-pra': true,
            'credit-facilities': true,
            'paye': paye ? true : 'N/A',
            'self-employed': self ? true : 'N/A',
            'ordinance-survey': surveyIncluded ? true : 'N/A',
        }
        return {map}
    }
    const optional = () => {
        let cr = !!mortgage.uploads.find(u=>['foreign-credit-report-1','foreign-credit-report-2', 'credit-report-1', 'credit-report-2'].includes(u.section) && !u.exclude && u.status !== 'REJECTED')

        let map = {}
        let ask = []
        let errors = []
        // Credit Report
        ask.push({
            name: 'credit-report',
            label: 'Credit Report (outside ROI in past 3 yrs)',
            type: 'CHECK',
            group: 'CHECKS'
        })
        map['credit-report'] = config["credit-report"] ? config["credit-report"] : cr ? true: 'N/A'

        // NON EU nationals
        ask.push({
            name: 'non-eu-nationals',
            label: 'Non EU Nationals',
            type: 'CHECK',
            group: 'CHECKS'
        })
        if (!config.hasOwnProperty('non-eu-nationals')) {
            if (!mortgage.applicant1.personal.nationality) {
                errors.push('No nationality for applicant 1')
            }
            if (mortgage.twoApplicants && !mortgage.applicant2.personal.nationality) {
                errors.push('No nationality for applicant 2')
            }
            if (!errors.length) {
                let isEU = countries.find(c => c.alpha_2_code === mortgage.applicant1.personal.nationality).is_EU
                if (isEU && mortgage.twoApplicants) {
                    isEU = countries.find(c => c.alpha_2_code === mortgage.applicant2.personal.nationality).is_EU
                }
                map['non-eu-nationals'] = !isEU ? true : 'N/A'
            }
        }
        else{
            map['non-eu-nationals'] = config["non-eu-nationals"] ?  config["non-eu-nationals"] : 'N/A'
        }
        return {map, ask, errors}
    }
    const sections = [
        checks(),
        broker(),
        optional(),
        applicantNames()
    ]
    return {sections}

}
