import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {ics} from "../../../../../../../assets/scripts/lender-calculators";
import useXls, {useXlsNew} from "../useXls";
import {toEuroString} from "../../../../../../../assets/scripts/string-functions";

function useIcsXls(useVerifiedFigures = false) {
    const mortgage = useMortgage()
    const api = ics({
        mortgage,
        useVerifiedFigures
    })
    const getProcessedResults = (results) => {
        let items = !!results ? Object.keys(results).map(key => {
            return {
                key  : key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items) => {
            switch (key) {
                case 'LTV':
                    return items.find(it => it.key === 'LTV Test').value === "Pass"
                case 'Income Multiple':
                    return items.find(it => it.key === 'Income Multiple Test').value === "Pass"
                case 'MSR':
                    return items.find(it => it.key === 'MSR Test').value === "Pass"
                case 'NDI Available':
                    return items.find(it => it.key === 'NDI Test').value === "Pass"
                default:
                    return null
            }
        }
        items.forEach(item => {
            let test = doTest(item.key, items)
            if (test !== null) {
                item.passed = test
            }
        })
        return items.filter(it => !it.key.includes('Test'))
    }
    return useXls(api, getProcessedResults, mortgage)
}
export function useIcsXlsNew() {
    console.log('useIcsXlsNew')
    const mortgage = useMortgage()
    const api = ics({mortgage})
    const getProcessedResults = (results) => {
        let items = !!results ? Object.keys(results).map(key => {
            return {
                key  : key,
                label: key,
                value: results[key].trim()
            }
        }) : []
        const doTest = (key, items) => {
            switch (key) {
                case 'LTV':
                    return items.find(it => it.key === 'LTV Test').value === "Pass"
                case 'Income Multiple':
                    return items.find(it => it.key === 'Income Multiple Test').value === "Pass"
                case 'MSR':
                    return items.find(it => it.key === 'MSR Test').value === "Pass"
                case 'NDI Available':
                    return items.find(it => it.key === 'NDI Test').value === "Pass"
                default:
                    return null
            }
        }
        items.forEach(item => {
            let test = doTest(item.key, items)
            if (test !== null) {
                item.passed = test
            }
        })
        return items.filter(it => !it.key.includes('Test')).map(item => {
            if ([
                'NDI Required',
                'NDI Surplus/Shortfall',
                'NDI Available'
            ].includes(item.key))
            {
                return {
                    ...item,
                    value: toEuroString(item.value)
                }
            }
            return item
        })
    }
    return useXlsNew(api, getProcessedResults, mortgage)
}

export default useIcsXls;