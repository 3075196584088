import {API, graphqlOperation} from "aws-amplify";
import getMoreItems from "./mortgage/getMoreItems";

const performGQL = async (variables, operation) => {
    const response = await API.graphql(
        graphqlOperation(operation, variables)
    )
    let key = Object.keys(response.data)[0]
    return response.data[key]
}
const listAllAndSetItems = async (variables, operation, setItems) => {
    const getItems = async (vars)=>{
        const {items, nextToken: newNextToken} = await performGQL(vars, operation);
        if (items.length) {
            setItems(items)
        }
        if (newNextToken) {
            await getItems({
                ...vars,
                nextToken: newNextToken
            })
        }
    }
    return await getItems(variables)
}



export {
    performGQL,
    listAllAndSetItems
}