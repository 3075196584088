import React, {useContext, useEffect, useState} from 'react';
import {Table} from "antd";
import {lenders} from "../../api-functions/lenders";
import {Link, Outlet, Route, Routes, useParams} from "react-router-dom";
import LenderRatesTable from "../../components/lenders/LenderRatesTable";
import PageHeader from "../../components/PageHeader";
import LenderSourceDocs from "../../components/lenders/LenderSourceDocs";
import {Lenders} from "../../providers/LendersProvider";
import ApplicationsWithLender from "../../components/lenders/ApplicationsWithLender";

function LendersPage(props) {
    const {lenders, updateOrAddLenderOverlayTemplate} = useContext(Lenders)
    const params = useParams()
    if (params?.lenderId) {
        const lender = lenders.find(lender => lender.id === params.lenderId)
        switch (params?.section) {
            case 'rates' :
                return <LenderRatesTable lender={lender}/>
            case 'source-docs' :
                return <LenderSourceDocs lender={lender} updateOrAddLenderOverlayTemplate={updateOrAddLenderOverlayTemplate}/>
            case 'applications' :
                return <ApplicationsWithLender lender={lender}/>
        }
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: 'lender',
            key: 'name',
        },
        {
            title: 'Applications',
            //    dataIndex: 'rateLink',
            key: 'applications',
            render: (t, r, i) => <Link to={`/lenders/${r.id}/applications`}> Applications</Link>
        },
        {
            title: 'Rates',
            //    dataIndex: 'rateLink',
            key: 'rateLink',
            render: (t, r, i) => <Link to={`/lenders/${r.id}/rates`}>Rates</Link>
        },
        {
            title: 'Docs',
            //    dataIndex: 'docLink',
            key: 'docLink',
            render: (t, r, i) => <Link to={`/lenders/${r.id}/source-docs`}>Docs </Link>
        },
    ];

    return <>
        <PageHeader title="Lenders" className="m-15"/>

        <Table
            onRow={(record, rowIndex) => {
                return {

                    // click row
                    // You can also define other event handlers like onDoubleClick, onMouseEnter, etc.
                };
            }}
            dataSource={lenders} columns={columns}/>
    </>

}

export default LendersPage;