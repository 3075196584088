import React, {useContext} from 'react';
import {Button, Form, Select, Tag} from "antd";
import {Mortgages} from "../../providers/MortgagesProvider";
import updateApplicantID from "../../api-functions/emails/updateApplicantID";
import {SaveOutlined} from "@ant-design/icons";
import createAlternativeEmailAddress from "../../api-functions/emails/createAltEmail";

function ApplicantLinker({email,updateEmail}) {
    const mortgages = useContext(Mortgages)
    const allData = mortgages.ALL.flatMap(item => {
        let array = [{
            value: `${item.applicant1ID}|${item.id}`,
            label: <>{item.applicant1?.fullName} <Tag>{item?.applicationTitle}</Tag></>,
            search: item.applicant1?.fullName || ''
        }]
        if (item.twoApplicants) {
            array.push({
                value: `${item.applicant2ID}|${item.id}`,
                label: <>{item.applicant2?.fullName} <Tag>{item?.applicationTitle}</Tag></>,
                search: item.applicant2?.fullName || ''
            })
        }
        return array
    })
    const SearchInput = (props) => {
        const filter = (inputValue, option) => {
            return option.search.toLowerCase().includes(inputValue.toLowerCase())
        }
        const onFinish = async (values) => {
            const [applicantID, mortgageID] = values.applicantIDmortgageID.split('|')
            if (applicantID) {
                updateApplicantID({
                    id: email.id,
                    userapplicantID: applicantID,
                    _version: email._version
                })
                if (values.linkType === 'address') {
                    createAlternativeEmailAddress({
                        email: email.from,
                        applicantID: applicantID,
                    })
                }
                updateEmail({userapplicantID: applicantID})
            }
        }
        return (
            <Form onFinish={onFinish} initialValues={{linkType: 'email'}}>
                <div className="d-row gap-6 px-15 mb-6">
                    Link:
                    <Form.Item noStyle name="linkType">
                        <Select
                            style={{width: 150}}
                            size="small"
                            options={[
                                {value: 'email', label: 'This email'},
                                {value: 'address', label: 'This email address'},
                            ]}
                        />
                    </Form.Item>
                    <Form.Item noStyle name="applicantIDmortgageID">
                        <Select
                            showSearch
                            popupMatchSelectWidth={500}
                            size="small"
                            placeholder="Link to applicant"
                            defaultActiveFirstOption={false}
                            filterOption={filter}
                            notFoundContent={<>No Results</>}
                            options={allData}
                        />
                    </Form.Item>
                    <Button size="small" htmlType="submit"><SaveOutlined/></Button>
                </div>
            </Form>
        );
    };
    return (
        <SearchInput/>
    );
}

export default ApplicantLinker;