import React from 'react';
import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import {EmploymentTypes, MortgageTypes} from "../../../../../../../models";

function usePreApprovalAdminDocuments(props) {

    // 1. Items to copy into customer lender documents folder
    // 2. Items to create for use as download template for customer uploads
    // 3. Items to create to be docusigned by customer

    const mortgage = useMortgage()
    if (!mortgage.activeSubmission) return []
    const items = []

    // 1. Lender Checklist
    switch (mortgage.activeSubmission.lenderCode) {
        case 'avant':
            items.push({
                name: 'loan-offer-details',
                overlay:true
            })
            break;
        case 'ptsb':
            items.push({
                name: 'loan-offer-confirmation',
                overlay:true
            })
            if (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED) ) {
                items.push({
                    name: 'pre-drawdown-paye',
                    overlay: true
                })
            }
            if (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED) ) {
                items.push({
                    name: 'pre-drawdown-self-employed',
                    overlay:true
                })
            }
            items.push({
                name: 'amendment-request-form',
                overlay:true
            })
            break;
        case 'haven':
            items.push({
                name: 'loan-offer-checklist',
                overlay:true
            })
            break;
    }

    let folder = 'submission-documents'
    return items.map(item => {
        return {
            ...item,
            key: item.name,
            folder,
            private: true,
            generated: mortgage.UserMortgageDocuments.find(doc => doc.folder === folder && doc.name === item.name),
        }
    })

}

export default usePreApprovalAdminDocuments;