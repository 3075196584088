import dayjs from "dayjs";
import {ApplicationStages, ApplicationStatuses, MortgageQualificationStatus} from "../../../models";
import {buildToDo, isStepDone, updateCompletedFlowItems} from "./index";
import {SUSSDPORTALNAME} from "../../../assets/scripts/constants";
import {claimedAverageMonthlySavings, claimedIncome} from "../../../assets/scripts/mortgage-figures";

//TODO - just calculate the completed items and the current one
// this relies on the MortgagesPrvider, so not the full mortgage object is available

export const setupIncomplete = (mortgage) => {
    let mostRecentDate = dayjs(mortgage.createdAt)
    return buildToDo('SETUP', 'setupIncomplete', {
        mortgage,
        dueAfter: mostRecentDate ? mostRecentDate.add(1, 'days') : null,
        toDo: {
            for: "support",
            title: 'Hanna - Send incomplete email',
            tab: 'Initial Form',
            emails: [
                {
                    name: 'initial',
                    title: 'Send Incomplete Email',
                    email: () => {
                        return {
                            from: 'help',
                            to: [mortgage.applicant1.email],
                            subject: 'Mortgage Setup Incomplete',
                            body: `
                                   <p>We've noticed that your financial assessment with Suss'd is still awaiting some details. Completing your financial assessment is a crucial step that allows us to tailor the perfect mortgage plan for you, ensuring it aligns with your financial goals and capabilities.</p>
<p>At your convenience, you can complete the remaining sections of the assessment. Doing so will help us determine your eligibility, the loan amount you qualify for, and connect you with the most appropriate banking partner for your situation.</p>
<p>To continue with your assessment, please click on link below:</p>
<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Complete Your Assessment</a></p>
<p>If you need any assistance or have any questions during this process, our dedicated team is on standby to support you. We're committed to providing a seamless experience.</p>
<p>Thank you for choosing Suss'd. We're excited to help you take this significant step towards your future home.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: 'Send Incomplete Reminder',
                    email: () => {
                        return {
                            from: 'help',
                            to: [mortgage.applicant1.email],
                            subject: 'Gentle Reminder: Complete Your Mortgage Assessment with Sussd',
                            body: `
                                    <p>Dear ${mortgage.applicant1.firstName}</p>
                                    <p>To follow up on the email we sent a few days ago, your mortgage assessment is not complete.</p>
                                    <p>To enable us to determine your eligibility, the loan amount you qualify for and connect you with the most appropriate banking partner for your situation, you need to complete the remaining sections of the financial assessment.</p>
                                    <p>You can access the assessment through the link below:</p>
                                    <p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Complete Your Assessment</a></p>
                                    <p>If you need any assistance or have any questions during this process, our dedicated team is on standby to support you. We're committed to providing a seamless experience.</p>
<p>Thank you for choosing Suss'd. We're excited to help you take this significant step towards your future home.</p>
<p>Warmest regards,</p>
<p>The Suss'd Team</p>`,
                            onSent: async () => {
                                await mortgage.mutate({
                                    update: {
                                        target: 'applicationStatus',
                                        value: ApplicationStatuses.SUSPENDED
                                    }
                                })
                            },
                        }
                    },
                    dueAfter: 3,
                }
            ],
            doneIf: (m) => {
                return m.twoApplicants ? m.completedFlowItems?.hasOwnProperty('app1AffordabilityComplete') && m.completedFlowItems?.hasOwnProperty('app2AffordabilityComplete') : m.completedFlowItems?.hasOwnProperty('app1AffordabilityComplete')
            }
        }
    })
}

export const verifyQualification = (mortgage) => {
    return buildToDo('SETUP', 'verifyQualification', {
        mortgage,
        toDo: {
            title: `Hanna - Check Qualification`,
            for: "support",
            tab: 'Qualification',
            buttons: [
                {
                    label: "Qualify",
                    name: 'qualified',
                    onClick: async () => {

                        await mortgage.mutate({
                            update: {
                                target: 'qualification',
                                value: MortgageQualificationStatus.QUALIFIED
                            }
                        })

                    }
                },
                // {
                //     name: 'notQualified',
                //     label: "Don't Qualify",
                //     onClick: async () => {

                //         await mortgage.mutate({
                //             update: {
                //                 target: 'qualification',
                //                 value: MortgageQualificationStatus.UNQUALIFIED
                //             }
                //         })
                //     }
                // },
                {
                    name: 'maybe',
                    label: "Maybe",
                    onClick: async () => {

                        await mortgage.mutate({
                            update: {
                                target: 'qualification',
                                value: MortgageQualificationStatus.MAYBE
                            }
                        })
                    }
                }
            ],
            doneIf: (m) => !!m.qualification
        }
    })
}
export const adminVerifyQualification = (mortgage) => {
    return buildToDo('SETUP', 'adminVerifyQualification', {
        mortgage,

        toDo: {
            for: "advisor",
            skipIf: (m) => {
                return m.qualification === MortgageQualificationStatus.QUALIFIED || !m.qualification
            },
            title: `Kevin - Check Qualification`,
            tab: 'Qualification',
            buttons: [
                {
                    label: "Qualify",
                    name: 'qualified',
                    onClick: async () => {

                        await mortgage.mutate({
                            update: {
                                target: 'qualification',
                                value: MortgageQualificationStatus.QUALIFIED
                            }
                        })

                    }
                },
                {
                    name: 'notQualified',
                    label: "Don't Qualify",
                    onClick: async () => {
                        await mortgage.mutate({
                            update: {
                                target: 'qualification',
                                value: MortgageQualificationStatus.UNQUALIFIED
                            }
                        })
                    }
                },
            ],
            doneIf: (m) => !!m.qualificationConfirmed || m.qualification === MortgageQualificationStatus.QUALIFIED || m.qualification === MortgageQualificationStatus.UNQUALIFIED
        }
    })
}

function rangeRequired(months, withDays = true) {
    const lastDayOfLastMonth = dayjs().subtract(1, 'month').endOf('month');

    // Get the first day of six months before last month
    const sixMonthsBeforeLastMonth = lastDayOfLastMonth.subtract(months - 1, 'month').startOf('month');

    // Format the dates as strings

    let format = withDays ? 'D MMMM YYYY' : 'MMMM YYYY'
    const lastDayStr = lastDayOfLastMonth.format(format);
    const firstDayStr = sixMonthsBeforeLastMonth.format(format);

    return `${firstDayStr} to ${lastDayStr}`;
}

export const sendQualificationEmail = (mortgage) => {
    const emails = () => {
        const figures = claimedIncome(mortgage)
        const [savings1, savings2] = claimedAverageMonthlySavings(mortgage)
        function listPreviousYears() {
            const currentYear = new Date().getFullYear();
            const lastYear = currentYear - 1;
            const twoYearsAgo = currentYear - 2;
            const threeYearsAgo = currentYear - 3;
            return `${threeYearsAgo}, ${twoYearsAgo}, ${lastYear}`;
        }
        const employmentSummaryYears = listPreviousYears()

        const qualifiedEmail = [
            {
                name: 'initial',
                title: 'Send Qualified Email',
                email: () => {
                    return {
                        from: 'help',
                        to: [mortgage.applicant1.email],
                        subject: 'Your Mortgage Application',
                        attachmentIds: ['SALARY_CERT'],
                        body: `
                                       <p>Dear ${mortgage.applicant1.firstName}</p>
                                       <p>We hope this email finds you well.</p>
<p>Thank you for completing the initial financial assessment. Based on the information you’ve shared and our preliminary evaluation, you are eligible for the following mortgage options;</p>
<ul>
    <li>Property value: ${mortgage.properties[0].propertyValue.toEuro()}</li>
    <li>Mortgage Loan Amount: ${mortgage.requirement.loanRequired.toEuro()}</li>
    <li>Term: ${mortgage.requirement.loanTerm} years</li>
</ul>
<p>Your mortgage options are available to review in your ${SUSSDPORTALNAME}. </p>

<p>Your eligibility for this mortgage offer is based on the following information provided in your application: </p>
<ul>
    <li>${mortgage.twoApplicants ? mortgage.applicant1.firstName + `'s` : 'Your'} Financials:
        <ul>
            <li>Annual Income: ${figures.basic1?.toEuro()}</li>
            <li>Annual Other Income: ${figures.rest1?.toEuro()}</li>
            <li>Average Monthly Savings: ${savings1?.toEuro()}</li>
        </ul>
    </li> 
</ul>
${mortgage.twoApplicants ? `
<ul>
    <li>${mortgage.applicant2.firstName}'s Financials:
        <ul>
            <li>Annual Income: ${figures.basic2?.toEuro()}</li>
            <li>Annual Other Income: ${figures.rest2?.toEuro()}</li>
            <li>Average Monthly Savings: ${savings2?.toEuro()}</li>
        </ul>
    </li> 
</ul>
` : ``}
    
<p>To proceed to the next stage of the application process, we now need to verify your income and savings rate. In order to do so, we kindly request that you upload the following documents to the ${SUSSDPORTALNAME}</p>
<ul>
    <li>Six consecutive months Current Account Statements: covering the period from ${rangeRequired(6)}</li>
    <li>Payslips for Three Months: covering ${rangeRequired(3, false)}</li>
    <li>Employment Detail Summary for ${employmentSummaryYears} (Available in Revenue's ROS system)</li>
</ul>

<p><a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Upload Documents</a></p>
<p>Once we receive these uploads, we will review in full and reconfirm the mortgage options available to you.</p>

<p>While it is not required at this stage, we have attached a Salary Certificate for your employer to complete. As these processes can sometimes take time, we recommend getting started on it sooner rather than later. Please note that this certificate will be necessary before submitting any application to a lender.</p>
<p>Should you have any questions or require assistance with the document upload process, please don't hesitate to reach out to us.</p>
<p>We sincerely appreciate the opportunity to assist you with your mortgage journey and look forward to finalising the next steps with you.</p>
                                        <p>Best regards,<br />The Suss'd Team</p>`,
                        onSent: async () => {
                            await mortgage.mutate({
                                update: {
                                    target: 'advisorID',
                                    value: 'ac6b7714-82ba-4a43-8d22-b652e9444b00' //Kevin
                                }
                            })
                        }
                    }
                },
            },
            {
                name: 'reminder1',
                title: 'Send Qualified Reminder',
                email: () => {
                    return {
                        from: 'help',
                        to: [mortgage.applicant1.email],
                        subject: 'Friendly Reminder: Upload Required Documents for Your Mortgage Application',
                        body: `
                                       <p>Dear ${mortgage.applicant1.firstName}</p>
                                       <p>This is just a friendly reminder that to proceed to the next stage of the application process, we need to verify your income and savings rate. If you are still interested in proceeding with a mortgage, we kindly request that you <a style="font-weight: bold" href="https://apply.sussd.ie/sign-in">Sign In to Upload Documents</a></p>
                                       <p>Should you have any questions or require assistance with the document upload process, please don't hesitate to reach out to us.</p>
                                        <p>We sincerely appreciate the opportunity to assist you with your mortgage journey and look forward to finalising the next steps with you.</p>
                                        <p>Best regards,<br />The Suss'd Team</p>`,
                        onSent: async () => {
                            await mortgage.mutate({
                                update: {
                                    target: 'applicationStatus',
                                    value: ApplicationStatuses.SUSPENDED
                                }
                            })
                        },
                    }
                },
                dueAfter: 7
            }
        ]
        const unqualifiedEmail = [{
            title: 'Send Not Qualified Email',
            name: 'initial',
            email: () => {
                return {
                    to: [mortgage.applicant1.email],
                    subject: 'Mortgage Application',
                    from: 'help',
                    body: `
                                       <p>Dear ${mortgage.applicant1.firstName}</p>
                                       <p>Thanks for completing the initial application.</p>
<p>We regret to inform you that based on the information provided in your mortgage application, we are unable to proceed with your request for a mortgage loan of ${mortgage.requirement.loanRequired.toEuro()} at this time.</p>
<p>Using the amounts from your accommodation costs and regular savings, you demonstrate that you can repay approx. € $$$$$$ monthly. Unfortunately this won't be sufficient to qualify for a mortgage.</p>
<p>If I am picking this up wrong, please let me know.</p> 
<p>To determine the amount of mortgage you can qualify for:</p>
    <ul>
                                                <li>4 × Gross Salary </li>
                                                <li>Projected repayments are less than your existing accommodation cost + average monthly savings</li>                                              
                                            </ul>
                                        
                                            <p>We am sorry we cant be of more help at this time. As said, if we have picked up your details incorrectly please do let us know.</p>
                                        <p>Best regards,<br />The Suss'd Team</p>`
                }
            },
        }]
        const maybeQualifiedEmail = {
            name: 'maybeQualifiedEmail',
            title: 'Send Maybe Qualified Email',
            email: () => {
                return {
                    to: [mortgage.applicant1.email],
                    subject: 'You have not completed your application',
                    body: 'Please complete your application'
                }
            },
        }
        switch (mortgage.qualification) {
            case MortgageQualificationStatus.QUALIFIED:
                return qualifiedEmail
            case MortgageQualificationStatus.UNQUALIFIED:
                return unqualifiedEmail
            case MortgageQualificationStatus.MAYBE:
            // return maybeQualifiedEmail
            default:
                return null
        }
    }
    const myEmails = emails()

    // isn't the doneIf redundant ?...  won't done be assumed if the last Flow item is not null and not the current one

    // afaics the doneIf is need to see if the last item was part of loop and should that loop be exited
    return buildToDo('SETUP', 'sendQualificationEmail', {
        mortgage,
        toDo: {
            for: "support",
            title: `Hanna - Send qualification email`,
            tab: 'Qualification',
            emails: myEmails ? myEmails : [],
            doneIf: (m) => {
                let test = 'SetupUploadsStarted'
                return m.twoApplicants ? m.completedFlowItems.hasOwnProperty(`app1${test}`) || m.completedFlowItems.hasOwnProperty(`app2${test}`) : m.completedFlowItems.hasOwnProperty(`app1${test}`)
            }
        }
    })
}

// send outstanding items email for setup



export const verifyUploads = (mortgage, props) => {
    let dueAfter = dayjs().add(2, 'day')
    if (!!props && !props.outstandingItems) {
        if (mortgage.completedFlowItems.hasOwnProperty('app1SetupUploadsStarted')) {
            dueAfter = dayjs(mortgage.completedFlowItems.app1SetupUploadsStarted)
        } else if (mortgage.completedFlowItems.hasOwnProperty('app2SetupUploadsStarted')) {
            dueAfter = dayjs(mortgage.completedFlowItems.app2SetupUploadsStarted)
        }
    }

    return buildToDo('SETUP', 'verifyUploads', {
        mortgage,
        dueAfter,
        toDo: {
            title: `Verify uploads`,
            tab: 'Uploads',
            buttons: [
                {
                    label: "Mark Uploads Verified",
                    name: 'verified',
                    onClick: async () => {
                       // await updateCompletedFlowItems(mortgage, 'SetupUploadsVerified')
                    }
                },
            ],
            doneIf: (m) => {
                return !!isStepDone(m, 'SETUP', 'verifyUploads.button.verified')
//                return m.completedFlowItems.hasOwnProperty('SetupUploadsVerified')
            }
        }
    })
}
export const sendMoveToApplyEmail = (mortgage) => {
    return buildToDo('SETUP', 'sendMoveToApplyEmail', {
        mortgage,
        toDo: {
            for:'support',
            title: `Ask to finish application`,
            emails: [
                {
                    name: 'initial',
                    title: "Send Compete Full Application Email",
                    email: () => {
                        // toDo: add in if there are queries, options prepared and stuff about scheduling call
                        let to = [mortgage.applicant1.email]
                        if (mortgage.twoApplicants && !!mortgage.applicant2.identityID) {
                            to.push(mortgage.applicant2.email)
                        }
                        return {
                            onSent: async () => {
                                await mortgage.mutate({
                                    update: {
                                        target: 'applicationStage',
                                        value: ApplicationStages.APPLY
                                    }
                                })
                            },
                            from: 'help',
                            to,
                            subject: 'Complete our full application',
                            body: `
                            <p>Dear ${mortgage.applicant1.firstName}</p>
                            <p>Thank you for successfully uploading the initial documents required for your mortgage application. We have reviewed them and are pleased to confirm that you meet the initial qualification criteria for the mortgage.</p>

<p>To proceed with your application, we now need you to complete the remaining sections of the full application form. Additionally, there are a few more documents that we require to finalize your application.</p>

<p>Please log in to your account to your <a href="https://apply.sussd.ie/sign-in">${SUSSDPORTALNAME}</a> to advance your application.</p>

<p>If you have any questions or need assistance at any stage, please do not hesitate to contact us. We are here to help you through every step of the process.</p>

<p>Thank you once again for your cooperation and promptness.</p>

<p>Best regards,</p>

<p>The Suss'd Team</p>
                            `
                        }
                    },
                },
                {
                    name: 'reminder1',
                    title: "Send Compete Full Application Reminder",
                    email: () => {
                        return {
                            from: 'help',
                            to: [mortgage.applicant1.email],
                            subject: 'Complete our full application',
                            onSent: async () => {
                                await mortgage.mutate({
                                    update: {
                                        target: 'applicationStage',
                                        value: ApplicationStages.APPLY
                                    }
                                })
                            },
                            body: `<p>Thank you for successfully uploading the initial documents required for your mortgage application. We have reviewed them and are pleased to confirm that you meet the initial qualification criteria for the mortgage.</p>

<p>To proceed with your application, we now need you to complete the remaining sections of the full application form. Additionally, there are a few more documents that we require to finalize your application.</p>

<p>Please log in to your account to your <a href="https://apply.sussd.ie/sign-in">${SUSSDPORTALNAME}</a> to advance your application.</p>

<p>If you have any questions or need assistance at any stage, please do not hesitate to contact us. We are here to help you through every step of the process.</p>

<p>Thank you once again for your cooperation and promptness.</p>

<p>Best regards,</p>

<p>The Suss'd Team</p>`
                        }
                    },
                    dueAfter: 6,
                }
            ],
            doneIf: (m) => {
                return m.completedFlowItems.hasOwnProperty(`app1ApplicationStarted`) || m.completedFlowItems.hasOwnProperty(`app12ApplicationStarted`)
            }
        }
    })
}

export const verifyQueryAnswer = (mortgage) => {

    return {
        mortgage,
        name: "verifyQueryAnswer",
        toDo: {

            title: `Verify Query Answers`,
            tab: 'Queries',
        }
    }
}
export const confirmQualification = (mortgage) => {
    return {
        mortgage,
        name: "confirmQualification",
        toDo: {
            title: `Confirm Qualification & Create Recommendation`,
            tab: 'Recommendation',
            buttons: [
                {
                    label: "Confirm Qualification",
                    name: 'confirmed',
                    onClick: async () => {
                        await mortgage.mutate({
                            update: {
                                target: 'qualificationConfirmed',
                                value: (new Date()).toISOString()
                            }
                        })
                    }
                }
            ],
            doneIf: (m) => {
                return !!m.qualificationConfirmed
            }
        }
    }
}
export const sendRecommendationEmail = (mortgage) => {
    return {
        name: "sendRecommendationEmail",
        toDo: {
            mortgage,
            title: `Send Recommendation Email`,
            tab: 'Recommendation',
            emails: [
                {
                    name: 'initial',
                    email: () => {
                        return {
                            subject: 'You have not completed your application',
                            body: 'Please complete your application'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    email: () => {
                        return {
                            subject: 'You have not completed your application',
                            body: 'Please complete your application'
                        }
                    },
                    dueAfter: 3,
                }
            ],
        }
    }
}
export const settleOnLender = (mortgage) => {
    return {
        name: "settleOnLender",
        toDo: {
            mortgage,
            title: `Settle on Lender`,
            tab: 'Lender',
            doneIf: (m) => {
                return !!m.activeSubmission.lenderCode
            }
        },
    }
}
export const sendLenderEmail = (mortgage) => {
    return {
        name: "sendLenderEmail",
        toDo: {
            mortgage,
            title: `Send Lender Email`,
            tab: 'Lender',
            emails: [
                {
                    name: 'initial',
                    email: () => {
                        return {
                            subject: 'You have not completed your application',
                            body: 'Please complete your application'
                        }
                    },
                },
                {
                    name: 'reminder1',
                    email: () => {
                        return {
                            subject: 'You have not completed your application',
                            body: 'Please complete your application'
                        }
                    },
                    dueAfter: 3,
                }
            ]
        }
    }
}
export const toDos = [
    verifyQualification,
    adminVerifyQualification,
    sendQualificationEmail,
    verifyUploads,
    sendMoveToApplyEmail
]

// const setup = (mortgage) => {
//
//     const confirmQualification = () => {
//         return {
//             name: "confirmQualification",
//             toDo: () => {
//                 return {
//                     mortgage,
//                     title: `Confirm Qualification & Create Recommendation`,
//                     tab: 'Recommendation',
//                 }
//             }
//         }
//     }
//     const sendRecommendationEmail = () => {
//         return {
//             name: "sendRecommendationEmail",
//             toDo: () => {
//                 return {
//                     mortgage,
//                     title: `Send Recommendation Email`,
//                     tab: 'Recommendation',
//                     dueAfter: 0,
//                     emails: [
//                         {
//                             name: 'initial',
//                             email: () => {
//                                 return {
//                                     subject: 'You have not completed your application',
//                                     body: 'Please complete your application'
//                                 }
//                             },
//                         },
//                         {
//                             name: 'reminder1',
//                             email: () => {
//                                 return {
//                                     subject: 'You have not completed your application',
//                                     body: 'Please complete your application'
//                                 }
//                             },
//                             dueAfter: 3,
//                         }
//                     ]
//                 }
//             }
//         }
//     }
//     const settleOnLender = () => {
//         return {
//             name: "settleOnLender",
//             toDo: () => {
//                 return {
//                     mortgage,
//                     title: `Settle on Lender`,
//                     tab: 'Lender',
//                 }
//             }
//         }
//     }
//     const sendLenderEmail = () => {
//         return {
//             name: "sendLenderEmail",
//             toDo: () => {
//                 return {
//                     mortgage,
//                     title: `Send Lender Email`,
//                     tab: 'Lender',
//                     dueAfter: 0,
//                     emails: [
//                         {
//                             name: 'initial',
//                             email: () => {
//                                 return {
//                                     subject: 'You have not completed your application',
//                                     body: 'Please complete your application'
//                                 }
//                             },
//                         },
//                         {
//                             name: 'reminder1',
//                             email: () => {
//                                 return {
//                                     subject: 'You have not completed your application',
//                                     body: 'Please complete your application'
//                                 }
//                             },
//                             dueAfter: 3,
//                         }
//                     ]
//                 }
//             }
//         }
//     }
//     const getListSoFar = () => {
//
//         let list = []
//
//         // incomplete exception
//         let setupIncomplete = mortgage.twoApplicants ? mortgage.applicant1.data.hasOwnProperty('affordabilityComplete') && mortgage.applicant2.data.hasOwnProperty('affordabilityComplete') : mortgage.applicant1.data.hasOwnProperty('affordabilityComplete')
//         if (setupIncomplete) {
//
//             list.push(steps[0])
//         } else {
//             for (let i = 0; i < steps.length; i++) {
//                 // is this step completed
//                 list.push(steps[i])
//
//                 if (!steps[i].completed) {
//                     break; // Break out of the loop
//                 }
//             }
//         }
//
//         return list
//     }
//     let boolSetupComplete = mortgage.twoApplicants ? mortgage.applicant1.data?.hasOwnProperty('affordabilityComplete') && mortgage.applicant2.data?.hasOwnProperty('affordabilityComplete') : mortgage.applicant1.data?.hasOwnProperty('affordabilityComplete')
//
//
//     const steps = []
//     if (!boolSetupComplete) {
//         steps.push(setupIncomplete())
//     } else {
//         steps.push(verifyQualification())
//         // steps.push(sendQualificationEmail())
//         // steps.push(verifyUploads())
//         // steps.push(sendUploadQueriesEmail())
//         // steps.push(verifyQueryAnswer())
//         // steps.push(confirmQualification())
//         // steps.push(sendRecommendationEmail())
//         // steps.push(settleOnLender())
//         // steps.push(sendLenderEmail())
//     }
//     return steps.map(step => ({...step, STAGE: 'SETUP'}))
// }
// export default setup
