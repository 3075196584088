import {useContext, useState} from 'react';
import {App, Button, Form, Input, Select} from "antd";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {BackendUsers} from "../../../providers/BackendUsersProvider";
import useMortgage from "../../../providers/mortgage/useMortgage"
import {ApplicationStages, SectionTypes} from "../../../../models";
import useBackendUsers from "../../../providers/mortgage/useBackendUsers";

function NewConditionForm(props) {
    const app = App.useApp
    const mortgage = useMortgage()
    const lender = "haven"
    let funds = mortgage.requirement.depositSavings || 0
    if (mortgage.requirement.receivingGifts) {
        funds += mortgage.requirement.depositGifts
    }
    const backendUsers = useBackendUsers()
    const [form] = Form.useForm()
    const [state, setState] = useState({saving: false})
    const defined = {
        haven: [
            {
                type: 'UPLOAD',
                name: 'balance-of-funds',
                title: 'Proof Of Funds',
                children: `Upload a statement showing the deposit amount of ${funds.toEuro()}`,
                // instructions: {acceptableDocuments:[], specialRequirements:[], examples:[], templates:[]},
                requirements: ['You need to do this', 'You need to do that'],
                time: '2 mins',
                templates: ['document:Some Form']
            },
        ]
    }

    const handleFormFinish = async () => {
        try {
            const valid = await form.validateFields()
            setState(s => ({...s, saving: true}))

            const specialInstructions = valid.requirements
            let name = valid.title.toLowerCase().replace(/ /g, '-')
            const create = {
                target: 'dynamicSections',
                pairs: {
                    sectionStage: valid.stage,
                    sectionType: 'UPLOAD',
                    sectionName: name,
                    sectionTitle: valid.title,
                    sectionInstructions: JSON.stringify({
                        children: valid.children,
                        time: valid.time,
                        instructions: {
                            specialRequirements: valid.requirements,
                            downloads: valid.templates,
                        }
                    }),
                    mortgageID: mortgage.id,
                    observeMID: mortgage.id,
                }
            }
            await mortgage.mutate({create})
            form.setFieldsValue({})
            setState(s => ({...s, saving: false}))
            props.close()
        } catch (e) {

        }
    }
    const handleTemplateSelect = (name) => {

        const definition = defined[lender].find(item => item.name === name)
        form.setFieldsValue({
            templateName: null,
            type: definition.type,
            name: definition.name,
            title: definition.title,
            time: definition.time,
            requirements: definition.requirements,
            templates: definition.templates,
            children: definition.children,
            stage: mortgage.applicationStage
        })

    }
    const templateOptions = defined[lender].filter(item => {
        return !mortgage.dynamicSections.find(innerItem => innerItem.sectionName === item.name)
    })
    return (
        <Form
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            key={`ConditionWriter`}
            form={form}
            requiredMark={false}
            initialValues={{
                stage: mortgage.applicationStage,
                time: '2 mins',

            }}
            onFinish={handleFormFinish}>
            <div className="d-col gap-15">

                <Form.Item
                    label="Load Predefined"
                    name="null:predefined"
                    className="mb-0">
                    <Select style={{width: '100%'}} onSelect={handleTemplateSelect}>
                        {templateOptions.map(u =>
                            <Select.Option value={u.name} key={`${u.name}`}>
                                {u.title}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>


                {/*<Form.Item*/}
                {/*    label="Type"*/}
                {/*    name="type"*/}
                {/*    rules={[{required: true, message: `A type is required`}]}*/}
                {/*    className="mb-0">*/}
                {/*    <Select style={{width: '100%'}}>*/}
                {/*        {[SectionTypes.UPLOAD, SectionTypes.QUESTIONS].map(u =>*/}
                {/*            <Select.Option value={u} key={`${u}`}>*/}
                {/*                {u}*/}
                {/*            </Select.Option>*/}
                {/*        )}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    label="Name"*/}
                {/*    name="name"*/}
                {/*    rules={[{required: true, message: `A name is required`}]}*/}
                {/*    className="mb-0">*/}
                {/*    <Input style={{width: '100%'}}/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    label="Stage"
                    name="stage"
                    rules={[{required: true, message: `A stage is required`}]}
                    className="mb-0">
                    <Select style={{width: '100%'}}>
                        {[ApplicationStages.APPLY, ApplicationStages.SIGN, ApplicationStages.PREAPPROVAL, ApplicationStages.APPROVAL].map(u =>
                            <Select.Option value={u} key={`${u}`}>
                                {u}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Time in mins"
                    name="time"
                    rules={[{required: true, message: `A time is required`}]}
                    className="mb-0">
                    <Input style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {required: true, message: `A title is required`},
                        {
                            validator: (rule, value) => {
                                return new Promise((resolve, reject) => {

                                    let name = value.toLowerCase().replace(/ /g, '-')
                                    // check does this exist already
                                    const exists = mortgage.dynamicSections.find(item => item.sectionName === name)
                                    if (exists) {
                                        reject(`A section with the name ${name} already exists`)
                                    }
                                    resolve()
                                })
                            }}

                    ]}
                        className="mb-0">
                        <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                        label="Description"
                        name="children"
                        rules={[{required: true, message: `A description is required`}]}
                    className="mb-0">
                    <Input.TextArea style={{width: '100%'}}/>
                </Form.Item>
                <Form.Item
                    label="Special Instructions"
                    className="mb-0">
                    <Form.List name="requirements">
                        {(fields, {add, remove}) => (
                            <div className="d-col gap-6">
                                {fields.map((field) => (
                                    <div className="d-row gap-6">
                                        <Form.Item
                                            className="mb-0 grow"
                                            {...field}>
                                            <Input style={{width: '100%'}}/>
                                        </Form.Item>
                                        <CloseOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    </div>
                                ))}

                                <Button type="dashed" onClick={() => add()} block>
                                    + Add Special Instruction
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item
                    label="Downloads"
                    className="mb-0">
                    <Form.List name="templates">
                        {(fields, {add, remove}) => (
                            <div className="d-col gap-6">
                                {fields.map((field) => (
                                    <div className="d-row gap-6">
                                        <Form.Item
                                            className="mb-0 grow"
                                            {...field}>
                                            <Input style={{width: '100%'}}/>
                                        </Form.Item>
                                        <CloseOutlined
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        />
                                    </div>
                                ))}

                                <Button type="dashed" onClick={() => add()} block>
                                    + Add Template
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </Form.Item>
                <div className="d-row j-end">
                    <Button htmlType="submit"> Save {state.saving && <LoadingOutlined className="ms-6"/>} </Button>
                </div>


            </div>
        </Form>
    );
}

export default NewConditionForm;