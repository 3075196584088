import React from 'react';

import {capitaliseWords} from "../../../../../../../assets/scripts/string-functions";
import {ApplicationStages} from "../../../../../../../models";

import useMortgage from "../../../../../../providers/mortgage/useMortgage";
import useCustomerApprovalDocuments from "../useCustomerApprovalDocuments";

function useApprovalAdminUploads(props) {
    const groups = []
    const mortgage = useMortgage()
    let docs = useCustomerApprovalDocuments().filter(it => it.folder === 'docusign')

    docs.push({
        name: 'mortgage-protection-insurance',
    })
    docs.push({
        name: 'property-valuation',
    })

    return  docs.map(doc => ({
            name    : doc.name,
            section : doc.name,
            title   : capitaliseWords(doc.name.replace(/-/g, ' ')),
            children: '',
            stage   : ApplicationStages.APPROVAL,
            upload  : true
        })).map(item => ({
            ...item,
            key      : item.name,
            private  : false,
            folder: 'submission-documents',
            uploaded: mortgage.UserMortgageDocuments.find(doc => doc.folder === 'submission-documents' && doc.name === item.name),
        }))


}

export default useApprovalAdminUploads;