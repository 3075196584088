import React from 'react';
import useMortgage from "../../../../../providers/mortgage/useMortgage";
import {
    address,
    addressKnown,
    berScore,
    bookingDepositPaid,
    contractDepositPaid,
    newlyBuilt,
    propertyType,
    propertyPrice,
} from "../../../application/forms/sections/MortgageProperties";
import MortgageForm from "../../../../data-capture/MortgageForm";
import {getQuestionsPercentCompleted} from "../../../../../providers/mortgage/useApplicationForms";
import {Alert, Button, Card} from "antd";
import useMortgageDocumentsService from "../../../../../providers/mortgage/useMortgageDocumentsService";
import {PropertyTypes} from "../../../../../../models";

export function reinstatementValue(props) {
    return {
        label: "Reinstatement Value",
        text: "What is the reinstatement value of the property?",
        name: "reinstatementValue",
        answer: {
            type: "euro",
        },
        model: "property",
        rules: [
            {type: 'number', min: 50000, message: "The minimum value here is €50,000"},
            {type: 'number', max: 5000000, message: "The maximum value here is €5,000,000"}
        ],
        target: `submissions[${props.index}].reinstatementValue`
    }
}
export function valuationDate(props) {
    return {
        label: "Valuation Date",
        text: "What is the date of the valuation document?",
        name: "valuationDate",
        answer: {
            type: "datepicker",
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].valuationDate`
    }
}
export function valuationExpiryDate(props) {
    return {
        label: "Valuation Expiry",
        text: "What is the date of the valuation expiry?",
        name: "valuationExpiryDate",
        answer: {
            type: "datepicker",
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].valuationExpiryDate`
    }
}
export function valuationValue(props) {
    return {
        label: "Valuation Value",
        text: "What is the value of the property according to the valuation document?",
        name: "valuationValue",
        answer: {
            type: "euro",
        },
        model: "property",
        rules: [
            {type: 'number', min: 50000, message: "The minimum value here is €50,000"},
            {type: 'number', max: 5000000, message: "The maximum value here is €5,000,000"}
        ],
        target: `submissions[${props.index}].valuationValue`
    }
}
export function valuationInstructed(props) {
    return {
        label: "Valuation Instructed",
        text: "Has the valuation been instructed?",
        name: "valuationInstructed",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].valuationInstructed`
    }
}
export function valuationIssues(props) {
    return {
        label: "Valuation Issues",
        text: "Does the valuation report have any issues?",
        name: "valuationIssues",
        answer: {
            type: "textarea",
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].valuationIssues`
    }
}
export function surveyRequired(props) {
    return {
        label: "Survey Required",
        text: "Is a survey required?",
        name: "surveyRequired",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].surveyRequired`
    }

}
export function surveyDate(props) {
    return {
        label: "Survey Date",
        text: "What is the date of the survey?",
        name: "surveyDate",
        answer: {
            type: "datepicker",
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].surveyDate`
    }
}
export function hasValuationIssues(props) {
    return {
        label: "Has Valuation Issues",
        text: "Does the valuation report have any issues?",
        name: "hasValuationIssues",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "property",
        rules: [

        ],
        target: `submissions[${props.index}].hasValuationIssues`
    }
}

function Property() {
    const mortgage = useMortgage()
    const {open} = useMortgageDocumentsService()
    const property = mortgage.properties[0]
    const props = {
        mortgage,
        index: property.id
    }
    const submissionProps = {
        index: mortgage.activeSubmission.id,
        mortgage,
    }
    const propertyQuestions = [
        addressKnown(props),
        address(props),
        bookingDepositPaid(props),
        contractDepositPaid(props),
        propertyPrice(props),
        propertyType(props),
        newlyBuilt(props),
        valuationInstructed(submissionProps),
    ]
    const valuationQuestions = [
        berScore(props),
        ]
    if (![PropertyTypes.FLAT, PropertyTypes.APARTMENT].includes(mortgage.properties[0].propertyType)){
        valuationQuestions.push(
            reinstatementValue(submissionProps),
        )
    }
    valuationQuestions.push(
        valuationDate(submissionProps),
        valuationExpiryDate(submissionProps),
        valuationValue(submissionProps),
        hasValuationIssues(submissionProps),
        {
            ...valuationIssues(submissionProps),
            hides: (form) => !form.getFieldValue('hasValuationIssues')
        },
        surveyRequired(submissionProps),
        {
            ...surveyDate(submissionProps),
            hides: (form) => !form.getFieldValue('surveyRequired')
        }
    )
    let percentProperty = getQuestionsPercentCompleted(mortgage, propertyQuestions)
    let percentValuation = getQuestionsPercentCompleted(mortgage, valuationQuestions)

    let doc = mortgage.UserMortgageDocuments.find(doc => doc.name === 'property-valuation' && doc.folder === 'lender-documents' && doc.submissionID === mortgage.activeSubmission.id)
    let valInstructed = mortgage.activeSubmission.valuationInstructed

    let found = mortgage.properties[0].addressKnown


    return (<Card>
        <div className="d-col gap-15">
            {!found ? (
                    <Alert  message={"Property has not been identified"} type="warning"/>)
                : (
                    <Alert message={"Property has been identified"} type="success"/>)
            }
            {!valInstructed ? (
                    <Alert  message={"Valuation hasn't been instructed"} type="warning"/>)
                : (
                    <Alert message={"Valuation has been instructed"} type="success"/>)
            }
            {!doc && (
                <Alert  message={"Valuation hasn't been received"} type="warning"/>)}
            {(!!doc && percentValuation !== 100) && (
                <Alert  message={"Valuation details are missing"} type="warning"/>)}
            {(!!doc && percentProperty !== 100) && (
                <Alert  message={"Property details are missing"} type="warning"/>)}
            {(percentProperty === 100 && percentValuation ===100) && (
                <Alert  message={"Property details & valuation completed"} type="success"/>)}
            {!!doc && (
                <Alert  message={<>
                    Property valuation received
                    <a className="mx-15" onClick={() => open(doc)}>View Document</a>
                </>} type="success"/>)}

            <div className="d-row gap-15 j-equal">
                <MortgageForm questions={propertyQuestions} title="Property Details"/>
                <MortgageForm questions={valuationQuestions} title="Valuation Details"/>
            </div>
            <div className="d-row j-end">
                <div className={"d-row gap-15 f-wrap"}>

                    <Button>Request Valuation Ref Email</Button>
                    <Button>Property Not Found Email</Button>
                    <Button>Valuation Not Instructed Email</Button>
                    <Button>Valuation Received Email</Button>
                </div>
            </div>
        </div>




    </Card>)
}

export default Property;