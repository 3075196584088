import React from 'react';
import {Alert} from "antd";
import {balanceOfFundsFunds} from "../../../funding/BalanceOfFunds";

function SubmissionWarnings({mortgage, noCurrentSubmission}) {
    if (!mortgage.lenderTests.length) {
        return <div className="my-15">
            <Alert message="Figures Not Tested" type="warning" showIcon description="Do the lender test and mark result as figures to be used for the submission"/>
        </div>
    }
    if (noCurrentSubmission) {
        return <div className="my-15">
            <Alert message="Submission Figures Not Set" type="warning" showIcon description="Do the lender test and mark result as figures to be used for the submission"/>
        </div>
    }
    let loan = mortgage.activeSubmission.provisionalXlsInput.provisionalLoan
    let price = mortgage.activeSubmission.provisionalXlsInput.provisionalValue
    let {helpToBuy} = balanceOfFundsFunds(mortgage, {loan, price})
    let ltv = (loan / price) * 100
    if (helpToBuy > 0 && ltv < 70){
        return <div className="my-15">
            <Alert message="Help to Buy" type="warning" showIcon description="The LTV is below 70% - Help to Buy not permitted"/>
        </div>
    }
}

export default SubmissionWarnings;