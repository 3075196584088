import React, {useEffect, useState} from 'react';
import {Button, Input, Select, Table, Tabs, Tag, Form, Popconfirm, Modal, Checkbox, Card, Row, Col, Upload} from "antd";
import {ApplicationStages, ApplicationTypes, MortgageTypes, TemplateTypes} from "../../../models";
import {performGQL} from "../../api-functions/functions";
import {createAdminPdfOverlayTemplate, deleteAdminPdfOverlayTemplate} from "../../../graphql/mutations";
import useS3 from "../../providers/useS3";
import {SYSTEMIDENTITYID} from "../../../assets/scripts/constants";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import {fileNameAndExtension} from "../../../assets/scripts/string-functions";
import LenderSourceDocLabels from "./LenderSourceDocLabels";

const updateAdminPdfOverlayTemplate = /* GraphQL */ `
    mutation UpdateAdminPdfOverlayTemplate(
        $input: UpdateAdminPdfOverlayTemplateInput!
        $condition: ModelAdminPdfOverlayTemplateConditionInput
    ) {
        updateAdminPdfOverlayTemplate(input: $input, condition: $condition) {
            id
            lender
            lenderCode
            stage
            fileName
            templateType
            mortgageType
            bucketKey
            clientSigns
            docusign
            defaultFolder
            defaultPrivate
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
        }
    }
`;
function CompanyDocs(props) {
    const s3 =useS3()
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [editingForm] = Form.useForm()
    const [addingForm] = Form.useForm()
    const [editingRow, setEditingRow] = useState(null)


    useEffect(() => {
        editingForm.setFieldsValue(editingRow)

    }, [editingRow]);

    const columns = [
        {
            title: 'Filename',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id && !record.bucketKey) {
                    return  <Form.Item name="fileName" noStyle><Input/></Form.Item>
                }
                return record.fileName
            },
            key: 'fileName'
        },
        {
            title: 'Stage',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id) {
                    return (
                        <Form.Item name="stage" noStyle>
                            <Select style={{width: 140}}>
                                <Select.Option value={ApplicationStages.APPLY}> Apply </Select.Option>
                                <Select.Option value={ApplicationStages.PREAPPROVAL}> Pre-Approval </Select.Option>
                                <Select.Option value={ApplicationStages.APPROVAL}> Approval </Select.Option>
                            </Select>
                        </Form.Item>
                    )
                }
                let color = 'geekblue';
                switch (record.stage) {
                    case ApplicationStages.APPLY:
                        color = 'lime';
                        break;
                    case ApplicationStages.PREAPPROVAL:
                        color = 'purple';
                        break;
                    case ApplicationStages.APPROVAL:
                        color = 'pink';
                        break;
                }
                return <Tag color={color}>{record.stage}</Tag>
            },
            key: 'stage'
        },
        {
            title: 'Mortgage Type',
            key: 'mortgageType',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id) {
                    return (
                        <Form.Item name="mortgageType" noStyle>
                            <Select style={{width: 140}}>
                                <Select.Option value={''}> All </Select.Option>
                                <Select.Option value={MortgageTypes.REFINANCE}> Refinance Only </Select.Option>
                                <Select.Option value={MortgageTypes.PURCHASE}> Purchase Only </Select.Option>
                                <Select.Option value={ApplicationTypes.FIRSTTIME}> First Time Only </Select.Option>
                                <Select.Option value={ApplicationTypes.LANDLORD}> Buy To Let Only </Select.Option>
                            </Select>
                        </Form.Item>
                    )
                }
                return record.mortgageType
            }
        },
        {
            title: 'Overlay',
            key: 'overlay',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id) {
                    return (
                        <Form.Item name="templateType" noStyle>
                            <Select style={{width: 140}}>
                                <Select.Option value={TemplateTypes.OVERLAID}> Overlay </Select.Option>
                                <Select.Option value={TemplateTypes.COPIED}> Copy </Select.Option>
                                <Select.Option value={TemplateTypes.FILLED}> Filled </Select.Option>
                            </Select>
                        </Form.Item>
                    )
                }
                if (record.templateType === TemplateTypes.FILLED) {
                    return <LenderSourceDocLabels file={record}/>
                }
                if (record.templateType === TemplateTypes.OVERLAID) {
                    return <Button onClick={() => props.setEditing(record)}>Overlay</Button>
                }

                return ''
            }
        },
        {
            title: 'File',
            key: 'bucketKey',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id) {
                    if (!!record.bucketKey) {
                        return (
                            <Popconfirm title="BE VERY CERTAIN you want to delete this. Delete?" onConfirm={() => actions.handleDeleteUpload(record)}>
                                <Button>Delete Upload</Button>
                            </Popconfirm>
                            )

                    }
                    if (!!record.fileName && !!record.stage) {
                        return <RowUploadButton record={record}/>
                    }
                }
                return !!record.bucketKey ? (
                    <div className="d-row gap-6">
                        <Button onClick={() => actions.handleUploadOpen(record)}>Open</Button>
                    </div>
                ) : ''
            }
        },
        {
            title: 'Edit',
            key: 'edit',
            render: (text, record) => {
                if (editingRow && editingRow.id === record.id) {
                    return (
                        <div className="d-row gap-6">
                            <Form.Item name="defaultFolder" noStyle>
                                <Select title="Move" style={{width: 140}} options={[
                                    {
                                        label: <span>customer</span>,
                                        title: 'customer',
                                        options: [
                                            {
                                                label: <span>Lender Docs</span>,
                                                value: 'lender-documents',
                                            },
                                            {
                                                label: <span>Upload Templates</span>,
                                                value: 'downloads',
                                            },
                                            {
                                                label: <span>Docusign Documents</span>,
                                                value: 'customer-docusign',
                                            },
                                        ],
                                    },
                                    {
                                        label: <span>broker</span>,
                                        title: 'docusign',
                                        options: [
                                            {
                                                label: <span>Submission Docs</span>,
                                                value: 'submission-documents',
                                            },
                                            {
                                                label: <span>Policy Docs</span>,
                                                value: 'policy-documents'
                                            },
                                        ],
                                    },
                                ]}/>
                            </Form.Item>
                            <Popconfirm title="BE VERY CERTAIN you want to delete this. Delete?"
                                        onConfirm={() => actions.handleDeleteRecord(record)}>
                                <Button>Delete Row</Button>
                            </Popconfirm>
                            <Button onClick={() => setEditingRow(null)}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Save</Button>
                        </div>
                    )
                }
                return (
                    <Button onClick={() => actions.toggleEditing(record)}>Edit</Button>
                )
            }
        },
    ];
    const actions = {
        saveDoc : async (row, pairs) => {
            const input = {
                id: row.id,
                _version: row._version,
                ...pairs
            }
            const newRow = await performGQL({input}, updateAdminPdfOverlayTemplate)
            props.updateOrAddLenderOverlayTemplate(newRow)
            return newRow

        },
        toggleEditing : (row) => {
            setEditingRow(s => {
                if (s?.id === row.id) return null
                return row
            })
        },
        handleDeleteRecord: async (record) => {

            if (!!record.bucketKey) {
                await s3.remove(record.bucketKey, {level: "private", identityId: SYSTEMIDENTITYID})
            }
            const row = await performGQL({input: {id: record.id, _version: record._version}}, deleteAdminPdfOverlayTemplate)
            props.updateOrAddLenderOverlayTemplate(row)
        },
        handleUploadOpen: async (record) => {

            if (!!record.bucketKey) {
                const url = await s3.getS3Url({
                    path: record.bucketKey,
                    config: {level: "private", identityId: SYSTEMIDENTITYID}
                })
                 s3.openDocument(url)
            }
        },
        handleDeleteUpload: async (record) => {

            if (!!record.bucketKey) {
                await s3.remove(record.bucketKey, {level: "private", identityId: SYSTEMIDENTITYID})
            }
            const result = await performGQL({
                input: {
                    id: record.id,
                    _version: record._version,
                    bucketKey: null
                }
            }, updateAdminPdfOverlayTemplate)
            props.updateOrAddLenderOverlayTemplate(result)
        },
        handleEditingRowFinished : async (values) => {
            await actions.saveDoc(editingRow, values)
            setEditingRow(null)
        },
        handleAddFormFinish : async () => {
            const values = addingForm.getFieldsValue()
            if (values.fileName){
                values.fileName = values.fileName.toLowerCase().replace(/[^A-Za-z0-9]/g, '-')
            }
            const input = {
                lender: props.lender.id,
                lenderCode: props.lender.lenderCode,
                fileName: values.fileName,
                stage: values.stage,
                defaultFolder: values.defaultFolder,
                templateType: values.hasItems ? TemplateTypes.OVERLAID : TemplateTypes.COPIED,
            }
            const row = await performGQL({input}, createAdminPdfOverlayTemplate)
            await props.updateOrAddLenderOverlayTemplate(row)
            addingForm.resetFields()
            setAddModalOpen(false)
        },
        handleAddFormCancel : () => {
            addingForm.resetFields()
            setAddModalOpen(false)
        },

    }
    const [activeTab1Key, setActiveTab1Key] = React.useState('customer');
    const [activeTab1AKey, setActiveTab1AKey] = React.useState('customer.lender-documents');
    const [activeTab1BKey, setActiveTab1BKey] = React.useState('broker.submission-documents');

    const items = [
        {
            key: 'customer',
            label: 'Customer Documents',
            children: <Tabs items={[
                {
                    key: 'customer.lender-documents',
                    label: `Customer Lender Documents`,
                },
                {
                    key: 'customer.downloads',
                    label: 'Customer Upload Templates',
                },
                {
                    key: 'customer.customer-docusign',
                    label: 'Customer Docusign Documents',
                },
            ]} onChange={setActiveTab1AKey} activeKey={activeTab1AKey}/>,
        },
        {
            key: 'broker',
            label: 'Broker Documents',
            children: <Tabs items={[
                {
                    key: 'broker.submission-documents',
                    label: 'Submission Documents',
                },
                {
                    key: 'broker.policy-documents',
                    label: 'Policy Documents',
                },
            ]} onChange={setActiveTab1BKey} activeKey={activeTab1BKey}/>,
        },
        {
            key: 'rest',
            label: 'Other Documents'
        }
    ];

    let filter
    if (activeTab1Key === 'rest') {
        filter = doc => ['lender-documents', 'downloads', 'customer-docusign', 'submission-documents', 'policy-documents'].indexOf(doc.defaultFolder) === -1
    } else {
        const set = activeTab1Key === 'customer' ? activeTab1AKey : activeTab1BKey
        let folder = set.split('.')[1]

        filter = doc => doc.defaultFolder === folder
    }
    const sort = (a, b) => {
        const stagesOrder = ['APPLY', 'PREAPPROVAL', 'APPROVAL'];

        // First, compare by stage
        const stageComparison = stagesOrder.indexOf(a.stage) - stagesOrder.indexOf(b.stage);
        if (stageComparison !== 0) {
            return stageComparison;
        }

        // If stages are the same, compare alphabetically
        return a.templateType || ''.localeCompare(b.templateType || '');
    };
    const fileList = props.lender.overlayTemplates.filter(filter).sort(sort)
    function RowUploadButton({record}) {
        const [busy,setBusy]  = useState(false)
        const handoverFileToS3 = async (file, record) => {
            // upload to s3 then create template row

            setBusy(true)
            const {fileExtension: actualExtension} = fileNameAndExtension(file.name)
            const {fileName: newName} =  fileNameAndExtension(record.fileName)
            const newFileName = `${newName}.${actualExtension}`
            let path = `lenders/${props.lender.lenderCode}/source-documents/${newFileName}`

            const config = {
                contentType: file.type,
                level: "private",
                identityId: SYSTEMIDENTITYID,
                resumable: true,
                completeCallback: async (event) => {
                    const input = {
                        id: record.id,
                        fileName: newFileName,
                        bucketKey: path,
                        _version: record._version
                    }
                    const row = await performGQL({input}, updateAdminPdfOverlayTemplate)
                    props.updateOrAddLenderOverlayTemplate(row)
                    setBusy(false)
                }
            }
            const s3Result = await s3.put(path, file, config);
        }
        const upload = {
            accept: ".pdf",
            name: 'file',
            multiple: false,
            listType: null,
            itemRender: ()=>null,
            customRequest: async ({file, onSuccess, onError}) => {
                try {
                    await handoverFileToS3(file, record)
                    onSuccess();
                } catch (error) {
                    onError(error);
                }
            }
        };
        return (
            <Upload {...upload}>
                <Button icon={busy ? <LoadingOutlined/> : <UploadOutlined/>}>Upload</Button>
            </Upload>
        )
    }
    return (
        <div className="p-15 w-100">
            <Tabs items={items} activeKey={activeTab1Key} onChange={setActiveTab1Key}/>
            <Form
                form={editingForm}
                onFinish={actions.handleEditingRowFinished}
            >
                <Table footer={() => <Button onClick={() => setAddModalOpen(true)}>Add New Required Doc</Button>}
                       dataSource={fileList} columns={columns} rowKey="id"/>
            </Form>

            <Modal destroyOnClose={true} title={'Add New Required Doc'} open={addModalOpen} okText="Save" onCancel={actions.handleAddFormCancel}
                   onOk={actions.handleAddFormFinish}>

                <Form
                    form={addingForm}
                >

                    <Row gutter={15} className="w-100">
                        <Col sm={12}>
                            <Form.Item name="stage" label="Stage">
                                <Select style={{width: '100%'}}>
                                    <Select.Option value={ApplicationStages.APPLY}> Apply </Select.Option>
                                    <Select.Option value={ApplicationStages.PREAPPROVAL}> Pre-Approval </Select.Option>
                                    <Select.Option value={ApplicationStages.APPROVAL}> Approval </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item name="defaultFolder" label="Folder">
                                <Select style={{width: '100%'}} title="Move" options={[
                                    {
                                        label: <span>customer</span>,
                                        title: 'customer',
                                        options: [
                                            {
                                                label: <span>Lender Docs</span>,
                                                value: 'lender-documents',
                                            },
                                            {
                                                label: <span>Upload Templates</span>,
                                                value: 'downloads',
                                            },
                                            {
                                                label: <span>Docusign Documents</span>,
                                                value: 'customer-docusign',
                                            },
                                        ],
                                    },
                                    {
                                        label: <span>broker</span>,
                                        title: 'docusign',
                                        options: [
                                            {
                                                label: <span>Submission Docs</span>,
                                                value: 'submission-documents',
                                            },
                                            {
                                                label: <span>Policy Docs</span>,
                                                value: 'policy-documents'
                                            },
                                        ],
                                    },
                                ]}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item label={"New Doc Title"} name="fileName">
                        <Input/>
                    </Form.Item>


                    <div className="d-row gap-15">
                        <Form.Item name="hasItems" label="Will have text & checks overlaid" valuePropName="checked">
                            <Checkbox/>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div>

    );
}

export default CompanyDocs;