import React, {useContext, useEffect, useState} from "react";
import {Drawer, FloatButton} from "antd";
import {
    CalendarOutlined,
    EditOutlined,
    FileTextOutlined,
    MailOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined
} from "@ant-design/icons";
import ComposeEmail from "../../components/tools-drawer/ComposeEmail";
import ComposeDocument from "../../components/tools-drawer/ComposeDocument";
import QueryDrawerContent from "../../components/tools-drawer/query/QueryDrawerContent";
import NoteDrawerContent from "../../components/tools-drawer/note/NoteDrawerContent";
import TaskDrawerContent from "../../components/tools-drawer/task/TaskDrawerContent";
import BuildConditions from "../../components/tools-drawer/BuildConditions";
import useMortgage from "./useMortgage";
import {Auth} from "aws-amplify";
import {BackendUsers} from "../BackendUsersProvider";
import ReplyRequestContent from "../../components/tools-drawer/reply-request-manager/ReplyRequestContent";

export const ToolDrawer = React.createContext({})

function ToolDrawerProvider(props) {
    const mortgage = useMortgage()
    const backendUsers = useContext(BackendUsers)
    const [state, setState] = useState({
        open: false,
        type: 'email',
        config: {},
        floatTop: false,
        defaultFromAccount:null
    })
    const setUserDefaultMailAccount = async ()=>{
        const user = await Auth.currentAuthenticatedUser()
        const me = backendUsers.find(u => u.sub === user.attributes.sub)
        setState(s=>({...s, defaultFromAccount: me}))
    }
    useEffect(() => {
        if (backendUsers && !state.defaultFromAccount){
            setUserDefaultMailAccount()
        }

    }, [backendUsers]);

    const toggleFloatPosition = ()=>{
        setState(s=>({...s, floatTop: !s.floatTop}))
    }
    const load = (params) => {
        setState(s => ({...s, open: true}))
    }
    const close = () => {
        setState(s => ({...s, open: false}))
    }
    const composeEmail = (config) => {
        if (!config.mortgage){
            config.mortgage = mortgage
        }
        setState(s => ({
            ...s,
            type: 'email',
            config: config,
            open: true}
        ))
    }
    const composeTask = (config) => {
        setState(s => ({...s, type: 'task', config, open: true}))
    }
    const composeNote = (config) => {
        setState(s => ({...s, type: 'note', config, open: true}))
    }
    const composeDocument = (config) => {
        setState(s => ({...s, type: 'document', config, open: true}))
    }
    const composeQuery = (config) => {
        setState(s => ({...s, type: 'query', config, open: true}))
    }
    const buildConditions = (config) => {
        setState(s => ({...s, type: 'conditions', config, open: true}))
    }
    const requestReply = (config) => {
        setState(s => ({...s, type: 'request', config, open: true}))
    }

    function Title() {
        switch (state.type) {
            case "email":
                return state.config.reply ? 'Reply to email' : 'Compose email'
            case "note":
                return 'Write a note'
            case "query":
                if (state.config.title){
                   return `Queries: ${state.config.title}`
                }
                return 'Queries'
            case "task":
                return 'Create a task'
            case "document":
                return 'Create a document'
            case "request":
                return 'Create a request'
            default:
        }
    }
    return <ToolDrawer.Provider value={{
        load,
        composeEmail,
        composeTask,
        buildConditions,
        composeNote,
        composeDocument,
        composeQuery,
        requestReply
    }}>


        {props.children}
        <FloatButton.Group  className={state.floatTop ? 'float-btns-top':''} shape="square">
            {
                !state.floatTop && (<FloatButton onClick={toggleFloatPosition} tooltip="Move to top"
                                                 icon={<VerticalAlignTopOutlined/>}/>)
            }
            <FloatButton onClick={() => composeEmail({
                to:[mortgage.applicant1.email],
                subject: `Mortgage Application`,
                from: state.defaultFromAccount.mailAccount
            })} tooltip="Compose an email" icon={<MailOutlined/>}/>
            <FloatButton onClick={() => composeNote({})} tooltip="Take a note" icon={<EditOutlined/>}/>
            {/*<FloatButton onClick={() => composeQuery({})} tooltip="Create a query" icon={<QuestionCircleOutlined/>}/>*/}
            {/*<FloatButton onClick={() => composeTask({})} tooltip="Create a task" icon={<CalendarOutlined/>}/>*/}
            <FloatButton onClick={() => composeDocument({document: 'coverLetter'})} tooltip="Create Cover Letter"
                         icon={<FileTextOutlined/>}/>
            {/*<FloatButton onClick={() => buildConditions()} tooltip="Build Conditions" icon={<UnorderedListOutlined/>}/>*/}
            {
                state.floatTop && (<FloatButton onClick={toggleFloatPosition} tooltip="Move to bottom"
                                                 icon={<VerticalAlignBottomOutlined/>}/>)
            }
        </FloatButton.Group>
        <Drawer

            title={<Title/>}
            placement="right"
            closable={true}
            onClose={close}
            open={state.open}
            size="large"
            mask={false}
            zIndex={2060}

        >
            {state.type === 'note' &&
                <NoteDrawerContent close={close} config={state.config}/>}
            {state.type === 'task' &&
                <TaskDrawerContent close={close} config={state.config}/>}

            {state.type === 'email' &&
                <ComposeEmail close={close} config={state.config} defaultFromAccount={state.defaultFromAccount}/>}
            {state.type === 'document' &&
                <ComposeDocument close={close} config={state.config}/>}
            {state.type === 'conditions' &&
                <BuildConditions close={close} config={state.config}/>}
            {state.type === 'query' &&
                <QueryDrawerContent close={close} config={state.config}/>}
            {state.type === 'request' &&
                <ReplyRequestContent close={close} config={state.config}/>}
        </Drawer>
    </ToolDrawer.Provider>
}
export default ToolDrawerProvider