import {createContext, useState} from "react";

export const DataButtonContext = createContext(null);
export const DataButtonProvider = ({children}) => {
    const [popoversOpen, setPopoversOpen] = useState([])
    const close = (id) => {
        setPopoversOpen(s => s.filter(it => it !== id))
    }
    const open = (id) => {
        setPopoversOpen(s => {
            if (s.includes(id)) {
                return s
            }
            return [...s, id]
        })
    }
    const toggle = (id) => {
        setPopoversOpen(s => {
            if (s.includes(id)) {
                return s.filter(it => it !== id)
            }
            return [...s, id]
        })
    }
    const isOpen = (id)=>{
        return popoversOpen.includes(id)
    }

    return <DataButtonContext.Provider value={{close, open, toggle, isOpen}}>
        {children}

    </DataButtonContext.Provider>
}