import {legacyIncome} from "../../components/client/application-stages/_common/lender-tests/useXlsTests";
import {ApplicationTypes, BoolLike, BuildType, EmploymentTypes, MortgageTypes, PersonalMaritalStatuses} from "../../../models";

export function particulars(mortgage){
    function employment(){
        let legacyEmployment = legacyIncome(mortgage)
        let self
        let paye
        if (legacyEmployment){
            paye = (mortgage.applicant1.employment.employmentType === EmploymentTypes.EMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.EMPLOYED))
            self = (mortgage.applicant1.employment.employmentType === EmploymentTypes.SELFEMPLOYED || (mortgage.twoApplicants && mortgage.applicant2.employment.employmentType === EmploymentTypes.SELFEMPLOYED))
        }
        else{
            paye = !!mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED) || (mortgage.twoApplicants && !!mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.EMPLOYED))
            self = !!mortgage.applicant1.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED) || (mortgage.twoApplicants && !!mortgage.applicant2.employmentIncome.find(e=>e.employmentType === EmploymentTypes.SELFEMPLOYED))

        }
        return {
            anyoneSelfEmployed: self,
            anyonePAYE: paye
        }
    }
    function separated(){
        let anyoneDivorcedOrSeparated =  (
            [PersonalMaritalStatuses.DIVORCED, PersonalMaritalStatuses.REMARRIED].includes( mortgage.applicant1.personal.maritalStatus) ||
            (mortgage.twoApplicants && [PersonalMaritalStatuses.DIVORCED, PersonalMaritalStatuses.REMARRIED].includes(mortgage.applicant2.personal.maritalStatus)) ||
            mortgage.applicant1.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED ||
            (mortgage.twoApplicants && mortgage.applicant2.personal.maritalStatus === PersonalMaritalStatuses.SEPARATED))
        return {
            anyoneDivorcedOrSeparated
        }
    }
    function income(){
        let anyoneHasRentalIncome = false
        if (mortgage.applicant1.properties.find(p => p.isRented) || (mortgage.twoApplicants && mortgage.applicant2.properties.find(p => p.isRented))) {
            anyoneHasRentalIncome = true
        }
        return {
            anyoneHasRentalIncome
        }
    }
    function schemes(){
        return {
            utilisingFirstHomeScheme: mortgage.requirement.firstHomeScheme !== BoolLike.FALSE,
            utilisingHelpToBuyScheme: mortgage.requirement.helpToBuyScheme !== BoolLike.FALSE,
        }
    }
    function mortgageType(){
        return {
            isSelfBuild: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.properties[0].buildType === BuildType.SELFBUILD,
            isFirstTimeBuyer: mortgage.mortgageType === MortgageTypes.PURCHASE && mortgage.applicationType === ApplicationTypes.FIRSTTIME
        }
    }

    return {
        ...employment(),
        ...separated(),
        ...income(),
        ...schemes(),
        ...mortgageType()
    }
}