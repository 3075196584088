import React, {useContext} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {categories, Mortgages} from "../../providers/MortgagesProvider";
import {Avatar, Table, Tag, Tooltip, Typography} from "antd";
import {formatDistanceToNow} from "date-fns";
import useBackendUsers from "../../providers/mortgage/useBackendUsers";
import {cyan, geekblue, orange, red} from "@ant-design/colors";
import {FlagFilled} from "@ant-design/icons";
import {MortgageActiveAgents} from "../../../models";

function ClientsListPage(props) {
    const backendUsers = useBackendUsers()
    const mortgages = useContext(Mortgages)
    const params = useParams()
    const navigate = useNavigate()
    const title = (categories.find(item => item.to === params.categoryType)).label + ' Clients'
    //console.log(params)
    const handleRowClick = ({id}) => {
        navigate(`/client/${id}`)
    }
    const items = mortgages[params.categoryType].map(itemOrig => {
        let item = {...itemOrig}
        item.key = `tableRow-${item.id}`
        return item
    }).sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB.getTime() - dateA.getTime();
    });

    const columns = [
        {
            title : 'Name',
            key   : 'applicationTitle',
            render: (text, record) => (<Tooltip title={record.applicant1?.email}>{record.applicationTitle}</Tooltip>),
        },
        {
            title : 'Message',
            render: (text, record) => {
                return <>
                    {!!record.adminMessageBy && (<Avatar size="small" icon={backendUsers.getBySub(record.adminMessageBy).firstName[0]}/>)}
                    <Typography.Text style={{color: geekblue.primary}}>{record.adminMessage}</Typography.Text>
                </>
            },
            key   : 'message',
        },
        {
            title : 'Agent',
            render: (text, record) => {
                switch (record.activeAgent) {
                    case MortgageActiveAgents.BROKER:
                        return <FlagFilled style={{color: red.primary}}/>
                    case MortgageActiveAgents.LENDER:
                        return <FlagFilled style={{color: orange.primary}}/>
                    case MortgageActiveAgents.USER:
                        return <FlagFilled style={{color: cyan.primary}}/>
                    default:
                        return null
                }
            },
            key   : 'activeAgent',
        },
        {
            title : 'Registered On',
            render: (text, record) => formatDistanceToNow(new Date(record.createdAt), {addSuffix: true}).replace("about", "almost"),
            key   : 'registeredAgo',
        },
        {
            title    : 'Mortgage Type',
            dataIndex: 'mortgageType',
            key      : 'mortgageType',
        },
        {
            title : 'Loan Amount',
            render: (text, record) => record.requirement.loanRequired?.toLocaleString('en-us'),
            key   : 'loanAmount'
        },
        {
            title    : 'Application Stage',
            dataIndex: 'applicationStage',
            render   : (text, record) => {
                if (record.submissions.length > 6){
                    return <Tag color={'red'}>Too many submissions ({record.submissions.length})</Tag>
                }
                let percent = null
                let testable = false
                let soft = false

                switch (record.applicationStage) {
                    case 'SIGN':
                    case 'APPLY':
                        if (record.uploadsPercentApply !== null) {
                            percent = record.uploadsPercentApply
                        }
                        testable = true
                        if (record.activeSubmission?.soft && !record.requirement.soft) {
                            return <Tag color={'red'}>Soft Error 1</Tag>
                        }
                        if (!record.activeSubmission?.soft && !!record.requirement.soft) {
                            return <Tag color={'red'}>Soft Error 2</Tag>
                        }
                        if (record.activeSubmission?.soft) {
                            soft = true
                        }
                        break;
                    case 'PREAPPROVAL':
                        if (record.activeSubmission?.soft) {
                            percent = record.uploadsPercentApply
                            testable = true
                            soft = true
                        }
                        if (record.activeSubmission?.soft && !record.requirement.soft) {
                            return <Tag color={'red'}>Soft Error 1</Tag>
                        }
                        if (!record.activeSubmission?.soft && !!record.requirement.soft) {
                            return <Tag color={'red'}>Soft Error 2</Tag>
                        }

                        break;
                    case 'SETUP':
                        testable = true
                        if (record.uploadsPercentSetup !== null) {
                            percent = record.uploadsPercentSetup
                        }
                        break;
                }
                return <>
                    <span className="me-3">{record.applicationStage}</span>

                    {testable && (<>
                        <Tag color={percent >= 80 ? 'green' : 'blue'}>{percent}% {soft && '(Soft)'}</Tag>
                    </>)}
                </>
            },

            key: 'applicationStage',
        },
        {
            title    : 'Application Status',
            dataIndex: 'applicationStatus',
            key      : 'applicationStatus',
        },
    ];
    return (<Table
        title={() => <Typography.Title level={5}>{title}</Typography.Title>}
        onRow={(record) => ({
            onClick: () => handleRowClick(record),
        })}
        dataSource={items}
        columns={columns}/>);
}

export default ClientsListPage;