import React, {useContext, useEffect, useState} from 'react';
import getBackendUsers from "../api-functions/users/getBackendUsers";
import {Auth} from "aws-amplify";
export const BackendUsers = React.createContext()

export const Me = React.createContext()

function MeProvider(props) {
    const [me,setMe] = useState(null)
    const backendUsers = useContext(BackendUsers)
    useEffect(() => {
        if (!me && backendUsers.length){
            (async () => {
                const user = await Auth.currentAuthenticatedUser()
                const groups = user.signInUserSession.idToken.payload['cognito:groups'];
                let is = {
                    support: groups.includes('supporters'),
                    advisor: groups.includes('advisors'),
                    admin: groups.includes('admins'),
                }
                let m = backendUsers.find(item=>item.sub === user.attributes.sub)
                setMe({...m, is}  )
            })()
        }
    }, [backendUsers, me]);
    if (!me) return null

    return (
        <Me.Provider value={me}>
            {props.children}
        </Me.Provider>
    );

}

function BackendUsersProvider(props) {
    const [users,setUsers] = useState(false)

    const fetchUsers = async ()=>{
        const users = await getBackendUsers()

        if (users.items?.length){
            if (window.location.hostname === 'localhost'){
                localStorage.setItem('users', JSON.stringify(users.items))
            }
            setUsers(users.items)
        }
    }

    useEffect(()=>{
        if (!users){
            if (window.location.hostname === 'localhost'){
                let existing = localStorage.getItem('users')
                if (existing){
                    setUsers(JSON.parse(existing))
                    return
                }
            }
            fetchUsers()
        }
    }, [users])
    return (
        <BackendUsers.Provider value={users}>
            <MeProvider>
                {props.children}
            </MeProvider>
        </BackendUsers.Provider>
    );
}

export default BackendUsersProvider;