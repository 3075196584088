import React, {useContext, useMemo} from 'react';
import mortgage from '../../src/_project/models/apps/mortgage';
import {Mortgages} from "../app/providers/MortgagesProvider";
function newMortgageMaker(row) {
    const request = {
        addError: (error, message) => {
            console.log({error, message})
        }
    }
    return mortgage({request, initialState:row})
}

function TestPage() {
    const mortgages = useContext(Mortgages)
    let loaded = mortgages?.SIGN.length > 0
    const mortgage = useMemo(() => {
        if (loaded){
            return newMortgageMaker(mortgages.SIGN[0])
        }
    }, [loaded])
    if (mortgage) {
        console.log(mortgage)
        console.log(mortgage.applicant1)
        console.log(mortgage.applicant1.firstName)
        console.log(""+mortgage.applicant1.firstName)
       console.log(mortgage.applicant1.firstName.setValue)
    }
    return (<div></div>);
}

export default TestPage;